import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IUITKColumnState, UITKSortDirection, UITKTableDataSource, UITKTableSortDirective } from '@uitk/angular';
import { Dialog } from 'src/app/helpers/dialog';
import { OrderAttachmentDto } from 'src/app/dtos/order-attachment-dto.model';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ConfigService } from 'src/app/services/config.service';
import { OrderService } from 'src/app/services/order.service';
import { ModeService } from 'src/app/services/mode.service';
import { SwalAlert } from '../../../../helpers/alert';
import { FileDto } from '../../../../dtos/file-dto-model';
@Component({
  selector: 'app-order-attachments',
  templateUrl: './order-attachments.component.html',
  styleUrls: ['./order-attachments.component.css']
})
export class OrderAttachmentsComponent implements OnInit {
  @Input() dialog = { show: false };
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();
  readonly _sanitizer: DomSanitizer;
  private readonly _configService: ConfigService;
  private readonly _authorizationService: AuthorizationService;

  tableHeader = [
    { name: 'File Name', id: 'fileName' },
    { name: 'File Size', id: 'fileSize' },
    { name: 'Created Date', id: 'createdDate' },
  ];
  orderAttachmentsList = new UITKTableDataSource<OrderAttachmentDto>([]);
  initialData: OrderAttachmentDto[] = [];
  sortColumn: string = 'sourceCode';
  @ViewChild('sortTable') uitkTableSort!: UITKTableSortDirective;
  
  deleteOrderAttachmentPopup = new Dialog<string>();
  cancelOrderAttachmentPopup = new Dialog<string>();
  filePickerPopup = new Dialog<string>();
  fileSelectedForDeletion = '';
  sourceCode : OrderAttachmentDto | null = null;
  currentUser = '';
  isSAorOMUser = false;
  errorMessage: string = '';
  deletedOrderAttachmentIds: number[] = [];
  userRole: string | null = '';
  swalAlert = new SwalAlert();

  constructor(sanitizer: DomSanitizer,
    public readonly orderService: OrderService,
    authorizationService: AuthorizationService,
    public readonly _modeService: ModeService,
    configService: ConfigService,) {    
    this._sanitizer = sanitizer;
    this._configService = configService;
    this._authorizationService = authorizationService;
  }

  ngOnInit(): void {
    this.orderService.getOrderAttachments(this.orderService.orderDto.id).then(x=>{
      this.orderAttachmentsList.data = x;
    });
    this.currentUser = this._configService.getMyMSId().toUpperCase().replace('MS\\', '');
    this.userRole = localStorage.getItem('rolename');    
    this.isSAorOMUser = (this.userRole && this.userRole === 'System Administrator' || this.userRole === 'Order Management');
  }

  ngAfterViewInit() {
    // sets initial sort direction
    this.uitkTableSort.setDirection({ column: 'fileName', direction: UITKSortDirection.ASC });
    this.orderAttachmentsList.sort = this.uitkTableSort;
  }

  onSortChange(event: IUITKColumnState) {
    console.log('Sorting Change: ', event);    
    this.sortColumn = event.column;
    
    this.orderAttachmentsList.data = this.orderAttachmentsList.sortData(this.orderAttachmentsList.data, event.direction, event.column);
  }

  applyFilters(){
    //TODO
  }

  async openDeleteSourceCodePopup(orderAttachment: OrderAttachmentDto): Promise<void> {   
    this.fileSelectedForDeletion = orderAttachment.fileName;
    this.deleteOrderAttachmentPopup.show = true;
    setTimeout(() => {
      if (document.getElementById('yesbtn')) {
        document.getElementById('yesbtn')?.focus();
      }
    });
  }

  disableDelete(orderAttachment: OrderAttachmentDto): boolean{
   let res = !(this.isSAorOMUser || orderAttachment.enteredBy === this.currentUser);
    return res;
  }
  cancelDialog() {
    this.dialog.show = false; 
  }

  discardOrderAttachments(){
    this.orderAttachmentsList.data = this.orderAttachmentsList.data.filter(x => x.id !== 0);    
    this.orderAttachmentsList.data = [...this.orderAttachmentsList.data];

    this.cancelOrderAttachmentPopup.show = false;
    this.dialog.show = false;
  }

  donotDiscardOrderAttachments(){
    this.cancelOrderAttachmentPopup.show = false;

    setTimeout(() => {
      if (document.getElementById('save')) {
        document.getElementById('save')?.focus();
      }
    });
  }

  async openFilePickerDialog(): Promise<void> {
    this.errorMessage = '';
    this.filePickerPopup.show = true;
  }

  newAttachmentAdded(newFile: any | null){
    if(newFile)
    {                  
      this.orderService.getOrderAttachments(this.orderService.orderDto.id).then(x=>{
        this.orderAttachmentsList.data = x;
        this.orderAttachmentsList.data = [...this.orderAttachmentsList.data];
      });
    }

    setTimeout(() => {
      if (document.getElementById('add')) {
        document.getElementById('add')?.focus();
      }
    });
  }

  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  deleteOrderAttachment(){    

    let fileDto = new FileDto();
    fileDto.fileName = this.fileSelectedForDeletion;
    let orderId = this.orderService.orderDto.id;
    this._modeService.addToWaitList('Deleting Order Attachment');
    this.orderService.deleteOrderAttachment(fileDto, orderId).subscribe(() => {  

      this._modeService.removeFromWaitList('Deleting Order Attachment');
      this.orderAttachmentsList.data = this.orderAttachmentsList.data.filter(x => x.fileName !== this.fileSelectedForDeletion);
    
      this.orderAttachmentsList.data = [...this.orderAttachmentsList.data]    
      this.deleteOrderAttachmentPopup.show = false;
    }, (error) => {
      this._modeService.removeFromWaitList('Deleting Order Attachment');
      this.swalAlert.alert("Error attempting to delete the file.");
    });

    setTimeout(() => {
      if (document.getElementById('add')) {
        document.getElementById('add')?.focus();
      }
    });
  }

  cancelDeleteOrderAttachment(){
    this.deleteOrderAttachmentPopup.show = false;    

    setTimeout(() => {
      if (document.getElementById('add')) {
        document.getElementById('add')?.focus();
      }
    });
  }

  addAttachmentFileUrl(orderAttachment: OrderAttachmentDto) {
    if(orderAttachment.file != null) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(orderAttachment.file);

      reader.onload = () => {
        if(reader.result) {
          const blob = new Blob([reader.result], {type: orderAttachment.file?.type});          
          orderAttachment.blobUrl = window.URL.createObjectURL(blob);      
        }
        else {
          this.swalAlert.alert("error occured while trying to open the file.")
        }
      }
    }
    else {
      this.swalAlert.alert("error occured! please upload the file again.")
    }
  }

  haveUnsavedChanges(): boolean{
    let noNewAttachmentsAdded =  this.orderAttachmentsList.data.filter(x => x.id === 0).length === 0;
    let noAttachmentsDeleted = this.deletedOrderAttachmentIds.length === 0;

    return noNewAttachmentsAdded && noAttachmentsDeleted;
  }

  sanitize(url:string){
    return this._sanitizer.bypassSecurityTrustUrl(url);
  }

  isDownloadableFileExtension(fileName: string): boolean{
    let list = fileName.split('.');
    let extension = list[list.length-1];
    if(extension === 'msg'){
      return true;
    }
    else
      return false;
  }

  downloadFileFromAzure(orderAttachment: OrderAttachmentDto){   
    this.orderService.downloadFileFromAzure(orderAttachment).then(blob => {                  
      const url = window.URL.createObjectURL(blob);           
      const link = document.createElement('a');
      link.href = url;
      link.download = orderAttachment.fileName;
      link.click();
      link.remove();    
    });
  }

  downloadOrderAttachmentFile(fileName: string, orderId: number){   
    let fileDto = new FileDto();
    fileDto.fileName = fileName;
    this.orderService.downloadOrderAttachmentFile(fileDto, orderId).then(blob => {                  
      const url = window.URL.createObjectURL(blob);           
      const link = document.createElement('a');
      link.href = url;
      link.download = fileDto.fileName;
      link.click();
      link.remove();    
    });
  }
}