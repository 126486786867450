import { Component, Input, OnInit } from '@angular/core';
import { Mode } from 'src/app/enums/mode.enum';
import { ModeService } from 'src/app/services/mode.service';
import { BillToDto } from '../../../dtos/bill-to-dto.model';
import { OrderByDto } from '../../../dtos/order-by-dto.model';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { ShipToDto } from '../../../dtos/ship-to-dto.model';
import { SourceType } from '../../../enums/source-type.enum';
import { Dialog } from '../../../helpers/dialog';
import { AuthorizationService } from '../../../services/authorization.service';
import { ConfigService } from '../../../services/config.service';
import { OrderService } from '../../../services/order.service';

@Component({
  selector: 'app-contact-change-popup',
  templateUrl: './contact-change-popup.component.html',
  styleUrls: ['./contact-change-popup.component.css']
})

export class ContactChangePopupComponent implements OnInit {
  private readonly _configService: ConfigService;
  private readonly _authorizationService: AuthorizationService;
  @Input() contactDialogModal!: Dialog<string>;
  mrgAndMgdStatusPopupText = 'This Contact is in MRG/MGD status.  An order cannot be placed on this Contact.';
  deleteStatusPopupTextForSAAndOMRole = 'Contact is in Delete Status';
  deleteStatusPopupTextForNonSAAndOMRole = 'Contact is in Delete status, contact Order Management for update.';
  badPhoneStatusPopupTextForSAAndOMRole = 'The Status on this Contact is ‘Bad Phone Number’.';
  badPhoneStatusPopupTextForNonSAAndOMRole = 'The Status on this Contact is Bad Phone Number, you must make it Active. NOTE: You need to verify/update the phone number.';
  badAddressStatusPopupTextForSAAndOMRole = 'The Status on this Contact is ‘Bad Address’.';
  badAddressStatusPopupTextForNonSAAndOMRole = 'The Status on this Contact is Bad Address, you must make it Active. NOTE: You need to verify/update the address.';
  subscriptionRenewalPopupText ='Ship to Contact is changed. You must change the ship to contact on the subscription in Phoenix. Item X has been removed from the cart.';
  mrgAndMgdStatusPopupText1 = 'This Account is in MRG/MGD status.  An order cannot be placed on this Account.';
  deleteStatusPopupTextForSAAndOMRole1 = 'Account is in Delete Status';
  deleteStatusPopupTextForNonSAAndOMRole1 = 'Account is in Delete status, contact Order Management for update.';
  badPhoneStatusPopupTextForSAAndOMRole1 = 'The Status on this Account is ‘Bad Phone Number’.';
  badPhoneStatusPopupTextForNonSAAndOMRole1 = 'The Status on this Account is Bad Phone Number, you must make it Active. NOTE: You need to verify/update the phone number.';
  badAddressStatusPopupTextForSAAndOMRole1 = 'The Status on this Account is ‘Bad Address’.';
  badAddressStatusPopupTextForNonSAAndOMRole1 = 'The Status on this Account is Bad Address, you must make it Active. NOTE: You need to verify/update the address.';
  myDialogContent: string = '';
  myDefaultButtonText: string = 'Ok';
  myGhostButtonText: string = 'Cancel';
  useDelAccountResource = false;
  useBPAccountResource = false;
  useBadAccountResource = false;
  statusCode = '';
  salesforceId = ''
  subscriptionRenewalProductExists = false;
  subscriptionProducts: OrderDetailDto[] = [];
  SourceType = SourceType;

  constructor(
    public orderService: OrderService,
    configService: ConfigService,
    authorizationService: AuthorizationService,
    public modeService: ModeService) {
    this._configService = configService;
    this._authorizationService = authorizationService;    
  }

  ngOnInit(): void {
    this.useDelAccountResource = this._authorizationService.hasResource('OrderEntryUseDELAccount');
    this.useBPAccountResource = this._authorizationService.hasResource('OrderEntryUseBPAccount');
    this.useBadAccountResource = this._authorizationService.hasResource('OrderEntryUseBADAccount');
    console.log('this.useDelAccountResource ' + this.useDelAccountResource);
    console.log('this.useBPAccountResource ' + this.useBPAccountResource);
    console.log('this.useBadAccountResource ' + this.useBadAccountResource);
    console.log('this.orderService.sourceType' + this.orderService.sourceType);
    //this.subscriptionRenewalProductExists = this.orderService.orderDto.orderDetailDtos.filter(x => x.subscriptionDto?.subscriptionType === 'R').length > 0 ? true : false;
    
    //if (this.subscriptionRenewalProductExists) {
    //  this.subscriptionProducts = this.orderService.orderDto.orderDetailDtos.filter(x => x.subscriptionDto?.subscriptionType === 'R');
    //  this.orderService.sourceType = SourceType.ShipToContactChange;
      
    //  if (this.subscriptionProducts.length > 1) {
    //    this.subscriptionRenewalPopupText = 'Ship to Contact is changed. You must change the ship to contact on the subscription in Phoenix. Items ' +
    //      this.subscriptionProducts.map(x => x.productCode).join(',') + ' have been removed from the cart.';
    //  }
    //  else {
    //    this.subscriptionRenewalPopupText = 'Ship to Contact is changed. You must change the ship to contact on the subscription in Phoenix. Item ' +
    //      this.subscriptionProducts.map(x => x.productCode)[0] + ' has been removed from the cart.';
    //  }

    //  this.orderService.orderDto.orderDetailDtos.forEach(element => {
    //    if (this.subscriptionProducts.findIndex(s => s === element) > -1) {
    //      element.orderDetailStatus = 'V';
    //      element.voidReasonCode = 'D';
    //    }
    //  });
    //}

    if (this.orderService.sourceType === SourceType.OrderByContactChange) {
      this.statusCode = this.orderService.orderDto.orderByDto.statusCode;
      this.salesforceId = this.orderService.orderDto.orderByDto.salesforceId;
    }
    else if (this.orderService.shiptoSectionSourceType === SourceType.ShipToContactChange) {
      this.statusCode = this.orderService.orderDto.shipToDto.statusCode;
      this.salesforceId = this.orderService.orderDto.shipToDto.salesforceId;
    }
    else if (this.orderService.sourceType === SourceType.OrderByAccountChange) {
      this.statusCode = this.orderService.orderDto.billToDto.statusCode;
      this.salesforceId = this.orderService.orderDto.billToDto.salesforceId;
    }
  }

  ok() {
    if (this.statusCode === 'MGD' || this.statusCode === 'MRG' || (this.statusCode === 'DEL' && !this.useDelAccountResource)) {
      this.initializeContact();
    }
    else if ((this.statusCode === 'BP' && this.useBPAccountResource) || (this.statusCode === 'BAD' && this.useBadAccountResource)) {
      this.focusOnContactName();
    }
    else if (this.subscriptionRenewalProductExists === true && this.orderService.sourceType === SourceType.ShipToContactChange) {
      this.focusOnContactName();
    }
    else {
      if (this.orderService.sourceType !== SourceType.OrderByAccountChange) {
        window.open(`${this._configService.salesforceUrl}/apex/NewContactPage?id=${this.salesforceId}`);
      }
      else {
        window.open(`${this._configService.salesforceUrl}/apex/Accountform?id=${this.salesforceId}`);
      }
    }
    this.contactDialogModal.close('ok');
  }
  cancel() {
    if (this.subscriptionRenewalProductExists === true && this.orderService.sourceType === SourceType.ShipToContactChange) {
      this.focusOnContactName();
    }
    else if ((this.statusCode === 'BP' && !this.useBPAccountResource) || (this.statusCode === 'BAD' && !this.useBadAccountResource) || (this.statusCode==='DEL')) {
      this.initializeContact();
    }
    else {
      this.focusOnContactName();
    }
    this.contactDialogModal.close('cancel');
  }

  initializeContact() {
    if (this.orderService.sourceType === SourceType.OrderByContactChange) {
      this.orderService.orderDto.orderByDto = new OrderByDto();
      setTimeout(() => {
        if (document.getElementById(`contactId`)) {
          document.getElementById(`contactId`)?.focus();
        }
      });
    }
    else if (this.orderService.shiptoSectionSourceType === SourceType.ShipToContactChange) {
      this.orderService.orderDto.shipToDto = new ShipToDto();
      setTimeout(() => {
        if (document.getElementById(`contactId-shipTo-textbox`)) {
          document.getElementById(`contactId-shipTo-textbox`)?.focus();
        }
      });
    }
    else if (this.orderService.sourceType === SourceType.OrderByAccountChange) {
      this.orderService.orderDto.billToDto = new BillToDto();
      setTimeout(() => {
        if (document.getElementById(`accountId`)) {
          document.getElementById(`accountId`)?.focus();
        }
      });
    }
  }

  closeModal(){
    this.focusOnContactName();
  }

  focusOnContactName() {
    if (this.orderService.sourceType === SourceType.OrderByContactChange) {
      setTimeout(() => {
        if (document.getElementById(`contactName`)) {
          document.getElementById(`contactName`)?.focus();
        }
      });
    }
    else if (this.orderService.sourceType === SourceType.ShipToContactChange) {
      setTimeout(() => {
        if (document.getElementById(`contactName-shipTo-textbox`)) {
          document.getElementById(`contactName-shipTo-textbox`)?.focus();
        }
      });
    }
    else if (this.orderService.sourceType === SourceType.OrderByAccountChange) {
      setTimeout(() => {
        if (document.getElementById(`accountName`)) {
          document.getElementById(`accountName`)?.focus();
        }
      });
    }
  }
}
