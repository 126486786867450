import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ScrollToDirective } from 'src/app/directives/scrollTo.directive';
import { Mode } from '../../../enums/mode.enum';
import { Dialog } from '../../../helpers/dialog';
import { OrderDto } from '../../../dtos/order-dto.model';
import { EmailService } from '../../../services/email.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';
import { OrderDetailsGridComponent } from '../order-details-grid/order-details-grid.component';

@Component({
  selector: 'app-confirmation-emails',
  templateUrl: './confirmation-emails.component.html',
  styleUrls: ['./confirmation-emails.component.css']
})
  
export class ConfirmationEmailsComponent implements OnInit {
  private readonly _emailService: EmailService;
  public readonly _orderService: OrderService;
  private readonly _modeService: ModeService;
  private readonly _elementRef: ElementRef;
  private readonly _orderDetailsGridComponent: OrderDetailsGridComponent;
  @Input() confirmationEmailDialog = new Dialog<string>();
  @Input() orderDto = new OrderDto();
  orderedByContactName = '';
  shipToContactName = '';
  orderedByContactEmail = '';
  shipToContactEmail = '';
  orderOtherEmail = '';
  shipmentOtherEmail = '';
  loggedInUser='';
  shiploggedInUser='';
  loggedInUserName='';
  orderOrderedByCheckbox = false;
  orderShipToCheckbox = false;
  orderOtherCheckbox = false;
  orderloggedInUserCheckbox=false;
  shipmentOrderedByCheckbox = false;
  shipmentShipToCheckbox = false;
  shipmentOtherCheckbox = false;
  shiploggedInUserCheckbox=false
  orderConfirmationEmails = '';
  shipmentConfirmationEmails = '';
  emailPattern = /^^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](\s?)(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
  @ViewChild(ScrollToDirective) dir:any;
  constructor(emailService: EmailService,
    orderService: OrderService,
    modeService: ModeService,
    elementRef: ElementRef,
    orderDetailsGridComponent: OrderDetailsGridComponent) {
    this._emailService = emailService;
    this._orderService = orderService;
    this._modeService = modeService;
    this._elementRef = elementRef;
    this._orderDetailsGridComponent = orderDetailsGridComponent;
  }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
    this.orderedByContactEmail = this.orderDto.orderByDto.email;
    this.loggedInUserName=currentUser.profile.name;
    this.shipToContactEmail = this.orderDto.shipToDto.email;
    this.loggedInUser=currentUser.profile.email;
    this.shiploggedInUser=currentUser.profile.email;
    this.orderedByContactName = (this.orderDto.orderByDto.firstName ?? '') + ' ' + this.orderDto.orderByDto.lastName ?? '';
    this.shipToContactName = (this.orderDto.shipToDto.firstName ?? '') + ' ' + this.orderDto.shipToDto.lastName ?? '';
    if (this.orderDto.billToDto.accountTypeCode === 'C' ||this.orderDto.billToDto.accountTypeCode === 'H'){
      this.orderOrderedByCheckbox = this.shipmentOrderedByCheckbox = true;
    }      
    else{
      this.orderOrderedByCheckbox = this.shipmentShipToCheckbox = true;
    }      
  }

  sendEmail(): void {
    this.updateOrderConfirmationEmailList();
    this.updateShipmentConfirmationEmailList();
    if (this.orderConfirmationEmails !== '' && this.orderConfirmationEmails !== null) {
      this.orderDto.email  = this.orderConfirmationEmails;
    }
    if (this.shipmentConfirmationEmails !== '' && this.shipmentConfirmationEmails !== null) {
      this.orderDto.shipToDto.confirmationEmails = this.shipmentConfirmationEmails;
    }
    this.confirmationEmailDialog.close("Send");
  }

  updateOrderConfirmationEmailList() {
    const orderConfirmationEmailsList: string[] = [];
    if (this.orderOrderedByCheckbox && this.orderedByContactEmail?.length > 0)
      orderConfirmationEmailsList.push(this.orderedByContactEmail);
    if (this.orderShipToCheckbox && this.shipToContactEmail?.length > 0 && orderConfirmationEmailsList.find(email => email === this.shipToContactEmail) === undefined)
      orderConfirmationEmailsList.push(this.shipToContactEmail);
    if (this.orderloggedInUserCheckbox && this.loggedInUser?.length > 0)
      orderConfirmationEmailsList.push(this.loggedInUser);
    if (this.orderOtherCheckbox && this.orderOtherEmail?.length > 0 && orderConfirmationEmailsList.find(email => email === this.orderOtherEmail) === undefined)
      orderConfirmationEmailsList.push(this.orderOtherEmail);
    this.orderConfirmationEmails = orderConfirmationEmailsList.join(';');
  }
  updateShipmentConfirmationEmailList() {
    const shipmentConfirmationEmailsList = [];
    if (this.shipmentOrderedByCheckbox && this.orderedByContactEmail?.length > 0)
      shipmentConfirmationEmailsList.push(this.orderedByContactEmail);
    if (this.shipmentShipToCheckbox && this.shipToContactEmail?.length > 0 && shipmentConfirmationEmailsList.find(email => email === this.shipToContactEmail) === undefined)
      shipmentConfirmationEmailsList.push(this.shipToContactEmail);
    if (this.shiploggedInUserCheckbox && this.loggedInUser?.length > 0)
      shipmentConfirmationEmailsList.push(this.loggedInUser);
    if (this.shipmentOtherCheckbox && this.shipmentOtherEmail?.length > 0 && shipmentConfirmationEmailsList.find(email => email === this.shipmentOtherEmail) === undefined)
      shipmentConfirmationEmailsList.push(this.shipmentOtherEmail);
    this.shipmentConfirmationEmails = shipmentConfirmationEmailsList.join(';');
  }
  
  afterConfirmationEmailPopupHides(): void {
    this._modeService.mode = Mode.Edit;

    if (!this._orderService.orderDto.orderDetailDtos.some(o => o.productId === 0)) {
      this._orderDetailsGridComponent.addNewLineItem();
    }

    if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${this._orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`)) {
      this._elementRef.nativeElement.querySelector(`input[id=productCode-${this._orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`).focus();
    }
    this.dir!.target = "#divLeftOrderDetailsGrid";
    this.dir?.scrollToPosition();
    return;
  }
  setCheckbox(){
    this.shipmentOtherCheckbox = this.shipmentOtherEmail !== '';
    this.orderOtherCheckbox = this.orderOtherEmail !== '';
  }
}
