import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { AccountsReceivableService } from 'src/app/services/accounts-receivable.service';
import { Title } from '@angular/platform-browser';
import { ConfigService } from "../../../../services/config.service";
import { InvoicesService } from '../../../../services/invoices.service';
import { InvoiceDetailsDto } from 'src/app/dtos/invoice-details-dto.model';
import { Dialog } from '../../../../helpers/dialog';
import { ModeService } from '../../../../services/mode.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})

export class InvoicesComponent implements OnInit {
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _invoicesService: InvoicesService;
  private readonly _configService: ConfigService;
  private readonly _authorizationService: AuthorizationService;
  private readonly _title: Title;
  collapsiblePanel = true;
  openPanel = true;
  orderId = 0;
  accountId =0;
  configIsReadySubscription: Subscription | undefined;
  getInvoicesSubscription: Subscription | undefined;
  showSelectedTemplate = new Dialog<string>();
  showTemplateEmail = new Dialog<void>();
  panelBackgroundOpen = 'uitk-l-grid-open';
  panelBackground = this.panelBackgroundOpen;
  helperText = 'My Helper text';
  invoiceDropDownSelectedValue: any = { label: 'All', value: 'All' };
  sendInvoicePdfDialog = { show: false };
  sendCCPdfAccess = false;
  invoicesDropdown = [
    { label: 'All', value: 'All' },
    { label: 'Open', value: 'Open' }
  ];

  constructor(activatedRoute: ActivatedRoute,
    invoicesService: InvoicesService,
    configService: ConfigService,
    authorizationService: AuthorizationService,
    public invoiceService: InvoicesService,
    public accountsReceivableService: AccountsReceivableService,
    title: Title, public modeService: ModeService) {
    this._activatedRoute = activatedRoute;
    this._invoicesService = invoicesService;
    this._configService = configService;
    this._authorizationService = authorizationService;
    this._title = title;
    this._title.setTitle("Invoices: " + this._activatedRoute.snapshot.params.orderId);
  }

  ngOnInit(): void {
    if (this._activatedRoute.snapshot.params.orderId) {
      this.orderId = parseInt(this._activatedRoute.snapshot.params.orderId);

      this.configIsReadySubscription = this._configService.configIsReady.subscribe(() => {
        this._invoicesService.getInvoicesWithOrderId(this.orderId, 'All');
      }
      )
    };

    this._configService.loadConfigurations();
    this.sendCCPdfAccess = this._authorizationService.hasResource('ARInquirySendSelectedToPdfButtonCCMemo');
    this.getInvoicesSubscription = this._invoicesService.getInvoicesEmitterForOrderId.
    subscribe((data: InvoiceDetailsDto[]) => {
      if(data.length > 0)
      {
        this.accountId = data[0].accountId;
        this.accountsReceivableService.accountIdForTemplate = this.accountId;
      }
    });
  }
  reload() {
    this._invoicesService.getInvoicesWithOrderId(this.orderId, 'All');
  }
  onChangeInvoices(event: any) {
    if (event.value.label === 'Open') {
      this._invoicesService.getInvoicesWithOrderId(this.orderId, 'Open');
    }
    else {
      this._invoicesService.getInvoicesWithOrderId(this.orderId, 'All');
    }
  }

  setPanelBGHeight() {
    if (this.panelBackground === this.panelBackgroundOpen) {
      this.panelBackground = 'uitk-l-grid1-close';
    }
    else {
      this.panelBackground =this.panelBackgroundOpen;
    }
  }

  showSendInvoicePdf(): void {
    this.sendInvoicePdfDialog.show = true;
  }
  async showTemplate(): Promise<void> {


    const result = await this.showSelectedTemplate.openAsync();
    if (result === 'openTemplateDialog') {
      await this.showTemplateEmail.openAsync();
    }
  }
}
