
export class AccountSearchInputDto{
    accountId = null;
    accountName = null;   
    streetAddress= null;
    city= null;
    state:string|null= null;
    zipCode= null;
    country:any = {
        value:''
    };
    phone:string | null = null;
    stateUS = {
        value:''
    };
    stateNonUS = null;
  }

  export class AccountSearchFinalDto{
    accountId = 0;
    accountName = null;   
    streetAddress= null;
    city= null;
    state:string|null= null;
    zipCode= null;
    country:string |null = null;
    phone:string |null= null;   
  }
  