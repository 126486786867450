import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'post-login',
  template: `<div></div>`,
})
export class PostLogin implements OnInit {
  public logEvent = {
    FileName: 'PostLogin',
    MethodName: '',
    Message: '',
    LogType: 'UI',
    logLevel: '',
  };

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.logEvent.MethodName = 'NgOnInit';

    this.authenticationService.completeLogin().then(async (token: any) => {
      if (token) {
        await this.setSession(token);
        this.logEvent.Message = 'Logged In successfully';
        this.router.navigate([localStorage.getItem('returnUrl')], { replaceUrl: true });
      } else {
        this.logEvent.Message = 'error fetching token';
        console.log('error fetching token');
      }
    });
  }

  async setSession(authResult: any) {
    this.logEvent.MethodName = 'setSession';

    try {
      sessionStorage.setItem(
        'username',
        authResult.profile.given_name + ' ' + authResult.profile.family_name
      );

      sessionStorage.setItem(
        'domainname',
        authResult.profile.identity_provider
      );
    } catch (error: any) {
      this.logEvent.Message = error.message;
    }
  }
}
