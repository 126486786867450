import { IUITKSelectItemProps } from "@uitk/angular";
import { AccountPricingDto } from "./account-pricing-dto.model";

export class BillToDto {
  id = 0;
  company = '';
  department = '';
  street = '';
  city = '';
  state = '';
  zip = '';
  country = '';
  creditLimit = 0;
  defaultPaymentMethod: string | null= '';
  isPORequired = false;
  allowPaymentPlans = false;
  accountOwnerCode = '';
  specialties: string[] = [];
  salesforceId = '';
  accountTypeCode = '';
  accountPricingDtos: AccountPricingDto[] | null = [];
  defaultSendRenewalNotices = false;
  email=''
  defaultSendUpdates=false;
  statusCode = '';
  createdDate = new Date();
  defaultPaymentTerms = '';
  phone = '';
  groupAccountId='';
  groupAccountName='';
  creditMemoFlag = false;
  taxExemptStatus = false;
  contactFirstName = '';
  contactLastName = '';
  printRepCode = '';
  abbreviatedCompany: string | null= '';
  // These special properties are used for binding to the UI components
  creditLimitDescription = '';
  specifiedPriceList=false;
  selectedState!: IUITKSelectItemProps | null;
  accountTypeDescription='';
  accountStatusDescription='';
  contactId:number|null=null;
  isAccountFlow=false;
  enableAccountLinks=false;
  accountOwnerName = '';
  accountOwneremail='';
  groupAccount='';
}
