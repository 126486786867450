import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-small-tooltip',
  templateUrl: './small-tooltip.component.html',
  styleUrls: ['./small-tooltip.component.css']
})
export class SmallTooltipComponent{
  @Input('data') data: any;
}
