import { Component, Input, OnInit } from '@angular/core';
import { Dialog } from '../../helpers/dialog';

@Component({
  selector: 'app-web-item-allow-only-one-dialog',
  templateUrl: './web-item-allow-only-one-dialog.component.html',
  styleUrls: ['./web-item-allow-only-one-dialog.component.css']
})

export class WebItemAllowOnlyOneDialogComponent {
  @Input() dialog!: Dialog<void>;

  closeAllowSingleItemPopup(): void {
    this.dialog.close();
  }
}
