<div *ngIf="dialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show"
             uitkId="configSetsDialog" [closeBtn]=false
             triggerElementId="configSetsDialog_openBtn"
             id= "configSetsDialog" (onModalHidden)="closeConfigurationDialog()">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Configurations
    </div>
  </uitk-dialog-header>

  <uitk-dialog-content>
    <div class="productDesc">
      <label uitkLabel class="lblConfigSet">{{ orderDetail.productCode }}: {{ orderDetail.productDescription }}</label>
    </div>
    <div class="container-fluid" scrollToTarget>
      <div class="row" *ngIf="hasCptConfigSet">
        <div class="col-12">
          <app-cpt [orderDetailDto]="orderDetail" (enableSave)="enableCptConfigSet = $event"></app-cpt>
        </div>
      </div>

      <div class="row" *ngIf="hasDepositConfigSet">
        <div class="col-12">
          <app-deposit [orderDetailDto]="orderDetail" (enableSave)="enableSaveDepositConfigSet = $event" #depositComponent></app-deposit>
        </div>
      </div>

      <div class="row" *ngIf="hasSubscriptionConfigSet">
 
        <div class="col-12"
        *ngIf="!(orderService.orderDto.orderStatus === 'R' || (orderService.orderDto.orderStatus === ' ' && orderService.orderDto.orderType === 'Q') || orderService.orderDto.orderStatus === 'D' || (orderService.orderDto.orderStatus === '' && orderService.orderDto.id <= 0)|| orderService.orderDto.orderStatus === 'P' || orderService.orderDto.orderStatus === 'M')">
        <app-subscription [orderDetail]="orderDetail" (enableSave)="enableSaveSubscriptionConfigSet = $event" (isTrialChecked)="isTrialChecked($event)">
        </app-subscription>
      </div>
      <div class="col-12"
        *ngIf="orderService.orderDto.orderStatus === 'R'  || orderService.orderDto.orderStatus === 'D' || (orderService.orderDto.orderStatus === '' && orderService.orderDto.id <= 0) || (orderService.orderDto.orderStatus === ' ' && orderService.orderDto.orderType === 'Q') || orderService.orderDto.orderStatus === 'P' || orderService.orderDto.orderStatus === 'M'">
        <app-subscription-order-entry [orderDetail]="orderDetail" (enableSave)="enableSaveSubscriptionConfigSet = $event" (isTrialChecked)="isTrialChecked($event)">
        </app-subscription-order-entry>
      </div>
      </div>

      <div class="row" *ngIf="hasFeeAnalyzerConfigSet">
        <div class="col-12">
          <app-fee-analyzer [orderDetail]="orderDetail" (enableSave)="enableSaveFeeAnalyzerConfigSet = $event"></app-fee-analyzer>
        </div>
      </div>

      <div class="row" *ngIf="hasCfaWebConfigSet">
        <div class="col-12">
          <app-cfa-web [orderDetail]="orderDetail" (enableSave)="enableSaveCfaWebConfigSet = $event"></app-cfa-web>
        </div>
      </div>

      <div class="row" *ngIf="hasDataFileConfigSet">
        <div class="col-12">
          <app-datafile [orderDetailDto]="orderDetail" (enableSave)="enableSaveDataFileConfigSet = $event"></app-datafile>
        </div>
      </div>

      <div class="row" *ngIf="hasChegSetConfigSet">
        <div class="col-12">
          <app-chegset [orderDetail]="orderDetail" (enableSave)="enableSaveChegSetConfigSet = $event"></app-chegset>
        </div>
      </div>

      <div class="row" *ngIf="hasWebAppConfigSet">
        <div class="col-12">
          <label uitkLabel class="lblConfigSet">Web Application</label>
          <app-web-app [orderDetail]="orderDetail" (enableSave)="enableSaveWebAppConfigSet = $event"></app-web-app>
        </div>
      </div>

      <!-- <div class="row" *ngIf="hasShippingConfigSet">
        <div class="col-12">
          <app-shipping [orderDetail]="orderDetail" (enableSave)="enableShippingConfigSet = $event"></app-shipping>
        </div>
      </div> -->
    </div>
  </uitk-dialog-content>

  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" id="configSetSavebtn"
      [disabled]= "!enableSaveSubscriptionConfigSet || !enableSaveFeeAnalyzerConfigSet || !enableSaveDataFileConfigSet || !enableSaveChegSetConfigSet || !enableSaveWebAppConfigSet || !enableSaveCfaWebConfigSet || !enableSaveDepositConfigSet"
      (click)="save()" appAutoFocus>
      Save
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeConfigurationDialog()">
      Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>
