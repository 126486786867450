import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Dialog } from '../../helpers/dialog';
import { OrderDetailDto } from '../../dtos/order-detail-dto.model';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-continue-duplicate-item-dialog',
  templateUrl: './continue-duplicate-item-dialog.component.html',
  styleUrls: ['./continue-duplicate-item-dialog.component.css']
})
export class ContinueDuplicateItemDialogComponent {
  private readonly _orderService: OrderService;
  private readonly _elementRef: ElementRef;
  @Input() dialog!: Dialog<string>;
  @Input() duplicateOrderDetail!: OrderDetailDto;

  constructor(orderService: OrderService, elementRef: ElementRef) {
    this._orderService = orderService;
    this._elementRef = elementRef;
  }

  allowDuplicateItem(): void {
    setTimeout(() => {
      if (this._elementRef.nativeElement.querySelector(`input[id=orderQuantity-${this.duplicateOrderDetail.id}]`)) {
        this._elementRef.nativeElement.querySelector(`input[id=orderQuantity-${this.duplicateOrderDetail.id}]`).focus();
      }
    });

    this.dialog.close('Yes');
    return;
  }

  stopDuplicateItem(): void {
    if (this._orderService.orderDto.orderDetailDtos.filter(i => i.id === this.duplicateOrderDetail.id).length > 0) {
      this._orderService.orderDto.orderDetailDtos.filter(i => i.id === this.duplicateOrderDetail.id)[0].productCode = "";
      const orderDetail = this._orderService.orderDto.orderDetailDtos.filter(i => i.id === this.duplicateOrderDetail.id)[0];
      orderDetail.productDescription = "";
      orderDetail.openQuantity = 0;
      orderDetail.discount = 0;
      orderDetail.listPrice = 0;
      orderDetail.requiredDate = new Date(new Date().toDateString());
      orderDetail.orderDetailStatus = ' ';
      orderDetail.shippedQuantity = 0;
      orderDetail.voidedQuantity = 0;
      orderDetail.accountOwnerCode = "";
      orderDetail.salespersonCode = '000';
      orderDetail.classCode = "";
      orderDetail.glCode = "";
      orderDetail.orderQuantity = 0;
      orderDetail.itemEditable = true;
      orderDetail.unitPrice = 0;
      orderDetail.extendedPrice = 0;
      this._orderService.orderDto.orderDetailDtos.splice(this._orderService.orderDto.orderDetailDtos
        .findIndex(o => o.id === this.duplicateOrderDetail.id), 1);
      this._orderService.orderDto.orderDetailDtos = [...this._orderService.orderDto.orderDetailDtos];
      this._orderService.recalculateFields(this._orderService.orderDto);
    }

    this.dialog.close('No');

    setTimeout(() => {
      if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${this._orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`)) {
        this._elementRef.nativeElement.querySelector(`input[id=productCode-${this._orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`).focus();
      }
    });

    return;
  }
}