import { Injectable } from '@angular/core';
import { Mode } from '../enums/mode.enum';

@Injectable({
  providedIn: 'root'
})

export class ModeService {
  mode: Mode = Mode.Initialize;
  isWaiting: boolean = false;
  waitList: string[] = [];
  waitDescription = '';
  nextFocus: HTMLElement | null = null;

  addToWaitList(entry: string): void {
    if (this.waitList.length === 0) {
      this.isWaiting = true;
    }

    this.waitList.push(entry);
    this.waitList = [...this.waitList];
    this.waitDescription = this.waitList[0];
  }

  removeFromWaitList(entry: string): void {
    this.waitList = this.waitList.filter(item => item !== entry);
    this.waitDescription = this.waitList[0];

    if (this.waitList.length === 0) {
      this.isWaiting = false;
      setTimeout(() => {
        if (this.nextFocus) {
          this.nextFocus.focus();
          this.nextFocus = null;
        }
        });        
    }
  }
}
