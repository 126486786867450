import { Component, OnInit } from '@angular/core';
import { RoleDto } from '../../../dtos/role-dto.model';
import { AuthorizationService } from '../../../services/authorization.service';
import { ConfigService } from '../../../services/config.service';
import { OrderService } from '../../../services/order.service';

@Component({
  selector: 'app-quote-cfd',
  templateUrl: './quote-cfd.component.html',
  styleUrls: ['./quote-cfd.component.css']
})
export class QuoteCfdComponent implements OnInit {

  private readonly _orderService: OrderService;
  private readonly _authorizationService: AuthorizationService;
  private readonly _configService: ConfigService;

  enteredByUserRole : string | null = null;

  constructor(orderService: OrderService, authorizationService: AuthorizationService, configService: ConfigService) {
    this._orderService = orderService;
    this._authorizationService = authorizationService;
    this._configService = configService;
  }

  ngOnInit(): void {
    if(this._orderService.orderDto?.enteredBy){
      this.getRoleBasedOnUserId(this._orderService.orderDto?.enteredBy);
    }
  }
  getRoleBasedOnUserId(userLoginId: string) {
    const msId = this._configService.getMyMSId();
    if (localStorage.getItem('impersonateType') !== null && localStorage.getItem('impersonateType') !== ''
    && this._orderService.orderDto?.enteredBy === msId.toUpperCase()) {
      this.enteredByUserRole = localStorage.getItem('rolename')?.replace(' ','') ?? '';
    }
    else {
      this._authorizationService.getRoleBasedOnUserId(this._configService.apiUrl, userLoginId).subscribe((data: RoleDto) => {
        this.enteredByUserRole = data.role;
      });
    }
  }

}
