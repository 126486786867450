import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { IUITKSelectItemProps, UITKTableDataSource } from '@uitk/angular';
import { Console } from 'console';
import { Subscription } from 'rxjs';
import { ShipToDto } from 'src/app/dtos/ship-to-dto.model';
import { Mode } from '../../../enums/mode.enum';
import { SourceType } from '../../../enums/source-type.enum';
import { Dialog } from '../../../helpers/dialog';
import { ContactDto } from '../../../dtos/contact-dto.model';
import { OrderByDto } from '../../../dtos/order-by-dto.model';
import { BillToService } from '../../../services/bill-to.service';
import { ConfigService } from '../../../services/config.service';
import { ContactService } from '../../../services/contact.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';
import { AccountInformationComponent } from '../account-information/account-information.component';
import { ShipToOrderEntryComponent } from '../ship-to-order-entry/ship-to-order-entry.component';
import { CompleterItem } from 'ng2-completer';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-order-by-order-entry',
  templateUrl: './order-by-order-entry.component.html',
  styleUrls: ['./order-by-order-entry.component.css'],
  providers: [AccountInformationComponent, ShipToOrderEntryComponent]
})

export class OrderByOrderEntryComponent implements OnInit {
  public readonly _contactService: ContactService;
  private readonly _configService: ConfigService;
  private readonly _orderService: OrderService;
  private readonly _billToService: BillToService;
  // @Output() selectedContact = new EventEmitter<ContactDto>();

  @Input() event = new EventEmitter<any>();

  contactSearchKeyword: string = '';
  contacts: ContactDto[] | null = null;
  contact: ContactDto | null = null;
  disableEditViewRefreshLinks = true;
  currentYear = new Date().getFullYear();
  Mode = Mode;
  contactDialogModal = new Dialog<string>();
  orderIsReadySubscription: Subscription | undefined;
  contactListIsReady: Subscription | undefined;
  contactSearchLookupPopup = { show: false };
  loading = false;
  visibleTextValue = '';
  invalidContactIdCheck = false;
  showAdvancedContactSearchDialog = new Dialog<string>();
  activeStatus: string[] = ['A', 'BAD', 'BP'];
  isDataFound = false;
  filteredContactsData = new UITKTableDataSource<ContactDto>([]);
  enteredContact = "";
  swalAlert = new SwalAlert();

  constructor(public orderService: OrderService,
    contactService: ContactService,
    public modeService: ModeService,
    configService: ConfigService,
    billToService: BillToService,
    private readonly accountInformationComponent: AccountInformationComponent,
    private readonly shipToOrderEntryComponent: ShipToOrderEntryComponent) {
    this._contactService = contactService;
    this._configService = configService;
    this._billToService = billToService;
    this._orderService = orderService;
  }

  ngOnInit(): void {

    this.contactListIsReady = this._contactService.triggerGetContactList.subscribe(() => {
      this.showContactDetailsBasedOnTheAccount();
      this.disableEditViewRefreshLinks = false;
    })

    this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(async () => {
      if (this.orderService.orderDto.shipToDto.contactId) {
        this.disableEditViewRefreshLinks = false;
      }
      if (!this.orderService.isSnapshot) {
        const contactList = await this._contactService.getContactsAsync(this.orderService.orderDto.billToDto.id);
        if (contactList) {
          this._contactService.contactDtos = contactList;
          this.disableEditViewRefreshLinks = true;
          this.updateOrderBySectionAfterContactDetailsAreReady();
        }
      }
    });
    this.orderService.onSaveAndClone.subscribe(async () => {
        const contactList = await this._contactService.getContactsAsync(this.orderService.orderDto.billToDto.id);
        if (contactList) {
          this._contactService.contactDtos = contactList;
          this.disableEditViewRefreshLinks = true;
          this.updateOrderBySectionAfterContactDetailsAreReady();
        }
    });
  }

  showContactDetailsBasedOnTheAccount() {
    this._contactService.getContactListForAccount = true;
    this._configService.getAccountFilteredContacts(this._orderService.orderDto.billToDto.id, " ").then(data => {
      this.loading = false;
      this.filteredContactsData.data = data;
      this._contactService.contactDtos = data;
      this.isDataFound = true;
      this.contacts = data.filter(c => this.activeStatus.includes(c.statusCode.trim()) || c.contactId === this.orderService.orderDto.orderByDto.contactId);
      this._contactService.orderByContactList = this._contactService.convertContactsToUitkProps(this.contacts);
      this._contactService.shipToContactList = this._contactService.convertContactsToUitkProps(this.contacts);
      this._contactService.shipToContacts = this._contactService.convertContactsToArray(this.contacts);
      let tempContactsName_orderBy = this._contactService.orderByContactList;
      let tempContactsName_shipTo = this._contactService.shipToContactList;
      this.orderService.orderDto.orderByContactBinding = tempContactsName_orderBy.filter(x => x.id === this.orderService.orderDto.orderByDto.contactId.toString())[0];
      this.orderService.orderDto.shipToContactBinding = tempContactsName_shipTo.filter(x => x.id === this.orderService.orderDto.shipToDto.contactId.toString())[0];
    });
  }

  getContactdetail(newOrderByContact: IUITKSelectItemProps) {
    const selectedContact = this.contacts?.filter(x => x.contactId.toString() === newOrderByContact.id)[0];
    const orderByDto = this.orderService.orderDto.orderByDto;
    if (!orderByDto || !selectedContact) {
      return;
    }
    this.orderService.orderDto.orderByDto.contactId = selectedContact.contactId;
    this.orderService.orderDto.orderByDto.firstName = selectedContact.firstName!;
    this.orderService.orderDto.orderByDto.lastName = selectedContact.lastName!;
    this.orderService.orderDto.orderByDto.email = selectedContact.email;
    this.orderService.orderDto.orderByDto.phone = selectedContact.phone;
    this.orderService.orderDto.orderByDto.statusCodeDescription = this._configService.getPickList('CTCSTATUS')
      .filter(p => p.id === selectedContact.statusCode.trim()).length > 0 ? this._configService.getPickList('CTCSTATUS')
        .filter(p => p.id === selectedContact.statusCode.trim())[0].label : '';
    this.orderService.orderDto.orderByDto.fax = selectedContact.fax;
    this.orderService.orderDto.orderByDto.title = selectedContact.title;
    this.orderService.orderDto.orderByDto.statusCode = selectedContact.statusCode.trim();
  }

  getContactArrayDetail(selected: CompleterItem) {
    if (selected == null) {
      this.orderService.orderDto.orderByDto.contactId = 0;
      this.orderService.orderDto.orderByDto.phone = "";
      this.orderService.orderDto.orderByDto.email = "";
      this.orderService.orderDto.orderByDto.fax = "";
      this.orderService.orderDto.orderByDto.title = "";
      this.orderService.orderDto.orderByDto.statusCodeDescription = "";
      return;
    }
    let contactIdFromSelection = selected.title.substring(selected.title.lastIndexOf("-")+2);
    const selectedContact = this.contacts?.filter(x => x.contactId.toString() === contactIdFromSelection)[0];
    const orderByDto = this.orderService.orderDto.orderByDto;
    if (!orderByDto || !selectedContact) {
      return;
    }
    this.orderService.orderDto.orderByDto.contactId = selectedContact.contactId;
    this.orderService.orderDto.orderByDto.firstName = selectedContact.firstName!;
    this.orderService.orderDto.orderByDto.lastName = selectedContact.lastName!;
    this.orderService.orderDto.orderByDto.email = selectedContact.email;
    this.orderService.orderDto.orderByDto.phone = selectedContact.phone;
    this.orderService.orderDto.orderByDto.statusCodeDescription = this._configService.getPickList('CTCSTATUS')
      .filter(p => p.id === selectedContact.statusCode.trim()).length > 0 ? this._configService.getPickList('CTCSTATUS')
        .filter(p => p.id === selectedContact.statusCode.trim())[0].label : '';
    this.orderService.orderDto.orderByDto.fax = selectedContact.fax;
    this.orderService.orderDto.orderByDto.title = selectedContact.title;
    this.orderService.orderDto.orderByDto.statusCode = selectedContact.statusCode.trim();
  }

  orderByContactIdNgModelChange(contactId: number): void {
    this.invalidContactIdCheck = false;
    this.orderService.orderDto.orderByDto.contactId = contactId;
    const currentSelectedContact = this._contactService.shipToContactList?.filter(x => x.id.toString() === contactId.toString())[0];
    this.enteredContact = "";
    if (currentSelectedContact) {
      this.enteredContact = currentSelectedContact?.label;
    }
  }
  async getContactsWithAccountId(): Promise<void> {
    const accountId = await this.orderService.getAccountIdAsync(this.orderService.orderDto.orderByDto.contactId);
    if (accountId) {
      this.orderService.salesPersonDisabled = false;
      await this._billToService.getBillToInformationAsync(accountId, this.currentYear);
      this.orderService.disableAccountEditViewRefreshLinks = false;
      this._billToService.reloadBillToDto();
      const contactList = await this._contactService.getContactsAsync(accountId);
      if (contactList) {
        this._contactService.contactDtos = contactList;
        await this.updateOrderBySectionAfterContactDetailsAreReady();
        this._contactService.contactDtos = contactList.filter(c => this.activeStatus.includes(c.statusCode.trim()) || c.contactId === this.orderService.orderDto.orderByDto.contactId);
        this._contactService.orderByContactList = this._contactService.convertContactsToUitkProps(this._contactService.contactDtos);
        this._contactService.shipToContacts = this._contactService.convertContactsToArray(this._contactService.contactDtos);
        this.orderService.orderDto.orderByContactBinding = this._contactService.orderByContactList.filter(x => x.id === this.orderService.orderDto.orderByDto.contactId.toString())[0];
      }
    }
    else {
      this.invalidContactIdCheck = true;
    }
  }
  async getContactsWithContactId(): Promise<void> {
    const contactList = await this._contactService.getContactsAsync(this.orderService.orderDto.billToDto.id);
    if (contactList) {
      this._contactService.contactDtos = contactList;
      await this.updateOrderBySectionAfterContactDetailsAreReady();
      this._contactService.contactDtos = contactList.filter(c => this.activeStatus.includes(c.statusCode.trim()) || c.contactId === this.orderService.orderDto.orderByDto.contactId);
      this._contactService.orderByContactList = this._contactService.convertContactsToUitkProps(this._contactService.contactDtos);
      this._contactService.shipToContacts = this._contactService.convertContactsToArray(this._contactService.contactDtos);
      this.orderService.orderDto.orderByContactBinding = this._contactService.orderByContactList.filter(x => x.id === this.orderService.orderDto.orderByDto.contactId.toString())[0]; 
      const currentSelectedContact = this._contactService.shipToContactList?.filter(x => x.id.toString() === this.orderService.orderDto.orderByDto.contactId.toString())[0];
      this.enteredContact = "";
      if (currentSelectedContact) {
        this.enteredContact = currentSelectedContact?.label;
      }
    }
  }
  async onContactIdChange(event: any): Promise<void> {
    this.invalidContactIdCheck = false;
    if (this.orderService.orderDto.shipToDto.contactId && this.orderService.orderDto.orderByDto.contactId && this.orderService.orderDto.shipToDto.contactId !== this.orderService.orderDto.orderByDto.contactId) {
      this.orderService.shiptoIsSelected = false;
    }
    if (this.orderService.orderDto.orderByDto.contactId.toString() === '') {
      this.orderService.orderDto.orderByDto = new OrderByDto();
      this._contactService.contactSearchKeyword = '';
      this.orderService.selectedContact = '';
      this.orderService.orderDto.orderByContactBinding = { id: '', label: '', value: '' };
      if (this.orderService.orderDto.billToDto.id) {
        this.orderService.salesPersonDisabled = false;
      }
      else {
        this.orderService.salesPersonDisabled = true;
      }
    }
    else if (!this.orderService.orderDto.billToDto.id) {
      await this.getContactsWithAccountId();
    }
    else {
      await this.getContactsWithContactId();
    }
    this.orderService.recalculateFields(this.orderService.orderDto);
  }

  async updateOrderBySectionAfterContactDetailsAreReady(): Promise<void> {
    this.contact = this._contactService.contactDtos?.filter(c => c.contactId === parseInt(this.orderService.orderDto.orderByDto.contactId.toString()))[0] ?? null;

    if (this.contact) {
      this.fillOrderByDto(this.contact);
    }
    else {
      if (this.orderService.orderDto.billToDto.id && this.orderService.orderDto.orderByDto.contactId) {
        await this.swalAlert.alert("This Contact is not a part of this Account. Please update the Account ID, or change the Contact.")
        this.orderService.orderDto.orderByDto = new OrderByDto();
      }
      else if (this.orderService.orderDto.orderByDto.contactId) {
        this.invalidContactIdCheck = true;
      }
    }
  }

  navigateToSFNewOrEditContact(salesforceContactId: string) {
    window.open(`${this._configService.salesforceUrl}/apex/NewContactPage?id=${salesforceContactId}`);
  }

  navigateToSFViewContact(salesforceContactId: string) {
    window.open(`${this._configService.salesforceUrl}/apex/ViewContact?id=${salesforceContactId}`);
  }

  navigateToSFAddContact(salesforceAccountId: string) {
    window.open(`${this._configService.salesforceUrl}/apex/NewContactPage?accId=${salesforceAccountId}`);
  }

  async refreshOrderByContactList() : Promise<void> {
    const contactList = await this._contactService.getContactsAsync(this.orderService.orderDto.billToDto.id);
    if (contactList) {
      //this._contactService.contactDtos = contactList;
      this._contactService.contactDtos = contactList.filter(c => this.activeStatus.includes(c.statusCode.trim()));
      this._contactService.orderByContactList = this._contactService.convertContactsToUitkProps(this._contactService.contactDtos);
      this._contactService.shipToContacts = this._contactService.convertContactsToArray(this._contactService.contactDtos);
      this.orderService.orderDto.orderByContactBinding = this._contactService.orderByContactList.filter(x => x.id === this.orderService.orderDto.orderByDto.contactId.toString())[0];
      await this.updateOrderBySectionAfterContactDetailsAreReady();
    }
  }

  

  async contactChangeBR(statusCode: string): Promise<void> {
    if (statusCode === 'BAD' || statusCode === 'BP' || statusCode === 'DEL' || statusCode === 'MGD' || statusCode === 'MRG') {
      this.orderService.sourceType = SourceType.OrderByContactChange;
      await this.contactDialogModal.openAsync();
    }
  }

  showContactSearchLookupPopup(): void {
    if (this._contactService.contactSearchKeyword.trim() === '') {
      this.orderService.selectedContact = '';
      this.contactSearchLookupPopup.show = true;
    }
    else {
      this.onOrderByContactClick(this._contactService.contactSearchKeyword);
    }

  }

  async bindSelectedContact(contactDetails: ContactDto): Promise<void> {
    this.invalidContactIdCheck = false;
    if (!this.orderService.orderDto.billToDto.id) {
      const accountId = await this.orderService.getAccountIdAsync(contactDetails.contactId);
      if (accountId) {
        await this._billToService.getBillToInformationAsync(accountId, this.currentYear);
        this._billToService.reloadBillToDto();
        await this.accountInformationComponent.onAccountIdChange(accountId);
        const contactList = await this._contactService.getContactsAsync(accountId);
        if (contactList) {
          this._contactService.contactDtos = contactList;
          this._contactService.orderByContactList = this._contactService.convertContactsToUitkProps(this._contactService.contactDtos);
          this.orderService.orderDto.orderByContactBinding = this._contactService.orderByContactList.filter(x => x.id === this.orderService.orderDto.orderByDto.contactId.toString())[0];
          await this.updateOrderBySectionAfterContactDetailsAreReady();
        }  

        this.fillOrderByDto(contactDetails);
      }
    }
    else {
      const contactList = await this._contactService.getContactsAsync(this.orderService.orderDto.billToDto.id);
      if (contactList) {
        this._contactService.contactDtos = contactList;
        this._contactService.orderByContactList = this._contactService.convertContactsToUitkProps(this._contactService.contactDtos);
        this.orderService.orderDto.orderByContactBinding = this._contactService.orderByContactList.filter(x => x.id === this.orderService.orderDto.orderByDto.contactId.toString())[0];
        await this.updateOrderBySectionAfterContactDetailsAreReady();
      }  

      this.fillOrderByDto(contactDetails);
    }
  }

  async fillOrderByDto(contact: ContactDto): Promise<void> {
    if ((contact.firstName! + ' ' + contact.lastName!) !== this._contactService.contactSearchKeyword) {
      this.orderService.orderDto.orderByDto.firstName = '';
      this.orderService.orderDto.orderByDto.lastName = '';
      setTimeout(() => {
        this.orderService.orderDto.orderByDto.firstName = contact.firstName!;
        this.orderService.orderDto.orderByDto.lastName = contact.lastName!;
        this._contactService.contactSearchKeyword = this.orderService.orderDto.orderByDto.firstName + ' ' + this.orderService.orderDto.orderByDto.lastName;
      }, 500);
    }
    else {
      this.showContactDetailsBasedOnTheAccount();
    }
    this.orderService.orderDto.orderByDto.contactId = contact.contactId;
    this.orderService.orderDto.orderByDto.email = contact.email;
    this.orderService.orderDto.orderByDto.phone = contact.phone;
    this.orderService.orderDto.orderByDto.title = contact.title;
    this.orderService.orderDto.orderByDto.fax = contact.fax;
    this.orderService.orderDto.orderByDto.statusCode = contact.statusCode.trim();
    this.orderService.orderDto.orderByDto.statusCodeDescription = this._configService.getPickList('CTCSTATUS')
      .filter(p => p.id === contact.statusCode.trim()).length > 0 ? this._configService.getPickList('CTCSTATUS')
        .filter(p => p.id === contact.statusCode.trim())[0].label : '';
    this.orderService.orderDto.orderByDto.middleInitial = contact.middleInitial;
    this.orderService.orderDto.orderByDto.salesforceId = contact.salesforceId;
    this.disableEditViewRefreshLinks = false;
    this._contactService.contactSearchKeyword = this.orderService.orderDto.orderByDto.firstName + ' ' + this.orderService.orderDto.orderByDto.lastName;
    this.orderService.orderDto.orderByContactBinding = this._contactService.orderByContactList.filter(x => x.id === contact.contactId.toString())[0];
    if (this.orderService.orderDto.shipToDto.contactId && this.orderService.orderDto.orderByDto.contactId && this.orderService.orderDto.shipToDto.contactId !== this.orderService.orderDto.orderByDto.contactId) {
      this.orderService.shiptoIsSelected = false;
      // this.orderService.orderDto.shipToDto = new ShipToDto();
    }
    await this.contactChangeBR(this.orderService.orderDto.orderByDto.statusCode);
  }

  generateFullName(firstName: string | null, lastName: string | null): string {
    return [firstName, lastName].filter(Boolean).join(', ');
  }


  async onOrderByContactChange(evt: any) {
    this._contactService.contactSearchKeyword = evt.target.value;
  }
  async onOrderByContactClick(evt: any) {
    this.visibleTextValue = 'Loading Records';
    this.loading = true;
    this._contactService.contactSearchKeyword = evt;
    await this._configService.getAccountFilteredContacts(this.orderService.orderDto.billToDto.id, "Name:" + this._contactService.contactSearchKeyword)
      .then((data: ContactDto[]) => {
        this.loading = false;
        if (data.length === 1 && (data[0].firstName + " " + data[0].lastName).trim().toLowerCase() === this._contactService.contactSearchKeyword.trim().toLowerCase()) {
          this.bindSelectedContact(data[0]);
        } else if (data.length >= 0) {
          this.orderService.selectedContact = this._contactService.contactSearchKeyword;
          this.contactSearchLookupPopup.show = true;
        }
      });
  }
  async onOrderByNameEnter(evt: any) {
    this._contactService.contactSearchKeyword = evt.target.value;
    this.showContactSearchLookupPopup();
  }

  async advanceContactSearch() {
    await this.showAdvancedContactSearchDialog.openAsync();
  }
}
