import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { gridFilterModel } from '../../dtos/grid-filter-model';
import { ToolsService } from '../../services/tools.service';
import { gridSortModel } from '../../dtos/grid-sort-model';

@Component({
  selector: 'app-grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.css']
})
export class GridFilterComponent implements OnInit {
  @ViewChild('filterList') filterList!: ElementRef<HTMLDivElement>;
  @Output() gridFiltersChange = new EventEmitter();
  @Output() filteredDataChange = new EventEmitter();
  
  @Input('gridFilters') gridFilters: gridFilterModel[] = [];
  @Input('gridSort') gridSort!: gridSortModel;
  @Input('fieldName') fieldName: string = '';
  @Input('columnName') columnName : string = '';
  @Input('allData') allData: any[] = [];
  @Input('dataType') dataType?: string = '';
  @Input('filteredData')
  set filteredData(val: any[]) {
    this.filteredDataField = val;
  }
  get filteredData(): any[] {
    return this.filteredDataField;
  }
  @HostListener('document:click', ['$event'])
  clickout() {
    this.openFilterMenu = false;
  }  

  gridFilterIndex = -1;
  filteredDataField: any[] = [];
  openFilterMenu = false;
  filterItem = '';
  distinctItems: string[] = [];

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly datePipe: DatePipe,
    private readonly toolsService : ToolsService
    ) { }

  ngOnInit(): void {
    this.gridFilters.push({ fieldName: this.fieldName, filterValue: '', filterApplied: false, columnName : this.columnName, dataType : this.dataType ? this.dataType : "" });
    this.gridFilterIndex = this.gridFilters.length - 1;
    this.gridFilters = [...this.gridFilters];
    this.gridFiltersChange.emit(this.gridFilters);
  }
  ngOnChanges(changes: SimpleChanges) {
    if(this.gridFilters && this.gridFilters[this.gridFilterIndex] && !this.gridFilters[this.gridFilterIndex].filterApplied){
      this.filterItem = "";
    }
  }
  isDate(_date: string){
      const _regExp  = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
      return _regExp.test(_date);
  }

  openFilter(event: Event): void {
    this.openFilterMenu = !this.openFilterMenu;
    if (this.openFilterMenu) {
      this.buildDistinctList();
    }

    const filterTextBox = this.elementRef.nativeElement.querySelector(`input[name=filterTextBox]`);
    setTimeout(() => { 
      filterTextBox.focus();
      this.renderer.setProperty(filterTextBox, 'selectionStart', 0);
      this.renderer.setProperty(filterTextBox, 'selectionEnd', 100);
    });

    event.stopPropagation();
  }

  buildDistinctList(): void {
    if (this.fieldName && this.filteredData.length > 0) {
      this.distinctItems = [...new Set(this.filteredData.map(item => {
        if (this.isDate(item[this.fieldName])) {
          return this.datePipe.transform(item[this.fieldName]?.toString() ?? '', 'MM/dd/yyyy');
        } else {
          return item[this.fieldName]?.toString() ?? ''
        }
      }))];
      if (this.distinctItems.includes('true')) {
        this.distinctItems.splice(this.distinctItems.indexOf('true'), 1);
        this.distinctItems.push('checked');
      }
      if (this.distinctItems.includes('false')) {
        this.distinctItems.splice(this.distinctItems.indexOf('false'), 1);
        this.distinctItems.push('unchecked');
      }

      this.distinctItems.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    }
  }

  filterChanged(event: Event): void {
    const filterItemIndex = this.distinctItems.findIndex(item => item?.toLowerCase()?.indexOf(this.filterItem.toLowerCase()) === 0);
    this.filterList.nativeElement.scroll(0, filterItemIndex * 24);

    event.stopPropagation();
  }

  clearFilter(event: Event) {
    this.filterItem = '';
    this.openFilterMenu = !this.openFilterMenu;
    this.gridFilters[this.gridFilterIndex].filterValue = '';
    this.gridFilters[this.gridFilterIndex].filterApplied = false;
    this.gridFilters[this.gridFilterIndex].columnName = '';
    this.gridFilters = [...this.gridFilters];
    this.applyFilters();
    event.stopPropagation();
  }

  applyFilter(event: Event, filterValue: string): void {
    
    // If the user presses enter after entering part of the word, set the filter to the full word.
    if (this.distinctItems.some(item => item.toLowerCase() === filterValue.toLowerCase())) {
      this.filterItem = this.distinctItems.filter(item => item.toLowerCase() === filterValue.toLowerCase())[0];
    }
    console.log(event,filterValue)
    this.openFilterMenu = !this.openFilterMenu;
    this.gridFilters[this.gridFilterIndex].filterValue = this.filterItem;
    this.gridFilters[this.gridFilterIndex].filterApplied = true;
    this.gridFilters[this.gridFilterIndex].columnName = this.columnName;
    this.gridFilters = [...this.gridFilters];
    this.applyFilters();
    if(this.gridSort){
      this.applySort();
    }
    event.stopPropagation();
  }

  applyFilters() {
    this.filteredData = this.toolsService.FilterData(this.gridFilters,this.allData);
    this.filteredDataChange.emit(this.filteredData);
    this.gridFiltersChange.emit(this.gridFilters);
  }
  applySort() {
    this.filteredData = this.toolsService.sortData(this.filteredData, this.gridSort);
    this.filteredData = [...this.filteredData];
    this.filteredDataChange.emit(this.filteredData);
  }
  clickItem(event: Event, item: string) {
    this.filterItem = item;
    this.applyFilter(event, item);
    event.stopPropagation();
  }
}
