
export class AccountResultDto{
  accountId = 0;
  accountName = "";
  department= "";
  street= "";
  city= "";
  state= "";
  zip= "";
  country = "";
  paymentTerms = "";
  salesforceId = "";
}
