import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UITKFileItem, UITKFileUploadComponent, UITKFileUploadValidation } from '@uitk/angular';
import { Dialog } from 'src/app/helpers/dialog';
import { OrderAttachmentDto } from 'src/app/dtos/order-attachment-dto.model';
import { ConfigService } from 'src/app/services/config.service';
import { OrderService } from 'src/app/services/order.service';
import { SwalAlert } from '../../../../../helpers/alert';

@Component({
  selector: 'app-order-attachments-file-picker',
  templateUrl: './order-attachments-file-picker.component.html',
  styleUrls: ['./order-attachments-file-picker.component.css']
})
export class OrderAttachmentsFilePickerComponent implements OnInit {
  @Input() filePickerPopup!: Dialog<string>;
  @Input() deleteSourceCodePopup!: Dialog<string>;  
  @Input() filesList: OrderAttachmentDto[] = [];  
  @Input() orderId: number = 0;
  @Input() enteredBy: string = '';

  overrideOrderAttachmentPopup = new Dialog<string>();
  private readonly _configService: ConfigService;
  @Output() newOrderAttachmentAdded = new EventEmitter<File | null>();
  @ViewChild('uitkFileUpload') uitkFileUpload!: UITKFileUploadComponent;
  fileUploadForm = new UntypedFormGroup({
    fileUpload: new UntypedFormControl(''),
  });
  files:File[] = [];
  fileSize: number = 0;
  fileSizeFlag: boolean = false;
  isMultipleFilesSelected = false;
  url = '';
  fileSizeLimit: number = 0;
  swalAlert = new SwalAlert();

  constructor(configService: ConfigService,
    public readonly orderService: OrderService) {        
    this._configService = configService;
  }
  //sourceCode : IUITKSelectItemProps | null = null;
  ngOnInit(): void {
    this.files = [];
    this.fileSizeLimit = this._configService.orderAttachmentSizeLimit;
    this.url = this._configService.apiUrl+'/Order/SaveOrderAttachment?orderId='+this.orderId+'&enteredBy='+this.enteredBy;
  }

  handleOnCancel(event: UITKFileItem) {
    console.log('cancel', event);
  }
  
  handleOnRemove(event: UITKFileItem) {
    console.log('remove', event);
    this.files = this.files.filter(x => x.name != event.file.name);
    this.files = [...this.files];
    this.isMultipleFilesSelected = this.files.length > 1 || this.files.length == 0;
  }

  handleOnSelectFiles(event: UITKFileItem[]) {
    this.files = [];
    let removedFiles = '';
    event.forEach(x => this.files.push(x.file));
    if(this.filesList.filter(x => x.fileName == this.files[0].name).length > 0) {
      this.overrideOrderAttachmentPopup.show = true;      
      setTimeout(() => {
        if (document.getElementById('yesbtnoverride')) {
          document.getElementById('yesbtnoverride')?.focus();
        }
      });
    } else if(this.files[0].size > this.fileSizeLimit){        
      this.filePickerPopup.show = false;
      this.swalAlert.alert("File size limit exceeded, please remove and upload a smaller file.");
    }
    for(let i = 0 ; i < this.files.length ; i++){
      if(this.files[i].size > this.fileSizeLimit){
        this.fileSizeFlag = true;
        removedFiles += this.files[i].name + ', ';
      }
    }
    const fileSizeLimitInMB = Math.ceil(this.fileSizeLimit / 1048576);
    if (this.fileSizeFlag) {
      this.swalAlert.alert(`File size limit of ${fileSizeLimitInMB}MB exceeded for ${removedFiles} Please upload a smaller file.`);
      this.fileSizeFlag  = false;
    }
    console.log('select file', event);
  }
  
  handleOnUpload(event: UITKFileItem) {
    let file = event.file;   
    this.newOrderAttachmentAdded.emit(file);
    console.log('upload', event);
  }
  
  handleOnError(event: UITKFileItem) {
    console.log('error', event);        
  }
  
  handleOnValidation(event: UITKFileUploadValidation) {
    console.log('validation', event);        
  }
    
  close(){
    this.filePickerPopup.show = false;
  }

  onChange(event: any) {
    console.log(event.target.files[0]);      
  }

  overrideOrderAttachment(){
    this.overrideOrderAttachmentPopup.show = false;    
  }

  cancelOverrideDeleteOrderAttachment(){
    this.filePickerPopup.show = false;
    this.newOrderAttachmentAdded.emit(null);
  }
}