<div class="mt-3" id="shipToSection">
  <div class="row mt-3">
    <div class="col-1 d-flex">
      <span
        style="font-size: 24px; font-family: Arial-BoldMT, Arial Bold, Arial, sans-serif; font-weight: 700;font-style: normal">
        Ship To
      </span>
    </div>
    <div class="col-11 d-flex">
      <div class="uitk-icon uitk-icon-add sm-icon align-self-center mx-2">
        <a class="link" target="_blank" (click)="navigateToSFAddContact(orderService.orderDto.billToDto.salesforceId)"
          [ngClass]="{'disabled': !orderService.orderDto.billToDto.id || modeService.mode !== Mode.Edit}">Add</a>
      </div>
      <div class="uitk-icon uitk-icon-refresh sm-icon align-self-center mx-3">
        <a class="link" target="_blank" (click)="refreshShipToContactList()"
          [ngClass]="{ 'disabled': !orderService.orderDto.billToDto.id || modeService.mode !== Mode.Edit}">Refresh</a>
      </div>
      <div class="uitk-icon uitk-icon-edit sm-icon align-self-center mx-3">
        <a class="link" target="_blank"
          (click)="navigateToSFNewOrEditContact(orderService.orderDto.shipToDto.salesforceId)"
          [ngClass]="{'disabled': !orderService.orderDto.billToDto.id || !orderService.orderDto.shipToDto || !orderService.orderDto.shipToDto.contactId || modeService.mode !== Mode.Edit}">Edit</a>
      </div>
      <div class="uitk-icon uitk-icon-search sm-icon searchIcon align-self-center mx-2">
        <a class="link" target="_blank"
        (click)="advanceContactSearch()">
          Contact Search 
        </a>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-sm-12 d-flex">
      <uitk-form-field>
        <input uitkCheckbox id="sameAsOrderBy" type="checkbox" name="sameAsOrderBy" [(ngModel)]="orderService.shiptoIsSelected"
               (change)="sameAsOrderBy()" [disabled]="modeService.mode !== Mode.Edit" scrollToTarget target="#shipToSection" />       
        <label uitkCheckboxLabel id="sameAsOrderByLabel">
          Same as Order By
        </label>
      </uitk-form-field>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Contact ID</label>
      </div>
      <div class="col-7 d-flex contactIdMainSec">
        <div class="idSection">
         <div class="idInnerSec">
          <uitk-form-field>
            <input uitkInput id="contactId-shipTo-textbox"
              [ngModel]="orderService.orderDto.shipToDto.contactId === 0 ? '' : orderService.orderDto.shipToDto.contactId"
              (ngModelChange)="shipToContactIdNgModelChange($event)"
              class="textbox" type="text" (focusout)="onContactIdChange($event)"
              onkeyup="this.value=this.value.replace(/[^0-9]/g, '')" [disabled]="modeService.mode !== Mode.Edit" />
          </uitk-form-field>
        </div>
        <div class="idInnerSec">
          <button name="shipTo-viewBtn" id="shipTo-viewBtn"
            class="uitk-c-button uitk-c-button--ghost uitk-icon uitk-icon-view px-0 py-1 mt-1" type="button"
            (click)="navigateToSFViewContact(orderService.orderDto.shipToDto.salesforceId)"
            [disabled]="!orderService.orderDto.billToDto.id || orderService.shiptoIsSelected || modeService.mode !== Mode.Edit"></button>
        </div>
       </div>  
      </div>
    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Contact Name</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field class="mx-3" id="drpContactName" class="w-100" *ngIf="contactService.shipToContactList.length<3000">
          <uitk-select id="shipToContactName" class="mx-3 w-100" [itemList]="contactService.shipToContactList" [disabled]="orderService.orderDto.billToDto.id==0 || modeService.mode !== Mode.Edit"
            [(ngModel)]="orderService.orderDto.shipToContactBinding"  (ngModelChange)="getContactdetail($event)" defaultLabel="Select" [defaultLabelFlag]=true>
          </uitk-select>
        </uitk-form-field>
        <ng2-completer [datasource]="this._contactService.shipToContacts" [minSearchLength]="2" [inputClass]="'completer-dropdown'" class="mx-3 w-100 ng-completer" 
          (selected)="getContactArrayDetail($event)" [initialValue]="enteredContact" 
          *ngIf="contactService.shipToContactList.length>3000" [(ngModel)]="enteredContact">
      </ng2-completer>
      </div>
    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Status</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field >
          <input uitkInput id="status-shipTo-textbox"
            [(ngModel)]="orderService.orderDto.shipToDto.statusCodeDescription" class="textbox mx-3" type="text"
            disabled />
        </uitk-form-field>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Title Description</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="TitleDesc-shipTo-textbox" [(ngModel)]="orderService.orderDto.shipToDto.title"
            class="textbox mx-3" type="text" disabled />
        </uitk-form-field>
      </div>
    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Phone</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="phoneShipTo" [ngModel]="orderService.orderDto.shipToDto.phone | phoneFormat"
            class="textbox mx-3" type="text" name="phoneInp" disabled />
        </uitk-form-field>
      </div>
    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Email</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="Email-shipTo-textbox" [(ngModel)]="orderService.orderDto.shipToDto.email"
            class="textbox mx-3" type="text" disabled />
        </uitk-form-field>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-4 d-flex">
      <app-changeship-toaddress [shipToDto]="orderService.orderDto.shipToDto" (addressChanged)="addressChange($event)"
        [disableAddButton]="modeService.mode !== Mode.Edit">
      </app-changeship-toaddress>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Account Name</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="AccountName-shipTo-textbox" class="textbox mx-3"
            [(ngModel)]="this.orderService.orderDto.shipToDto.company" type="text" disabled />
        </uitk-form-field>
      </div>
    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Department</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="Department-shipTo-textbox" class="textbox mx-3"
            [(ngModel)]="orderService.orderDto.shipToDto.department" type="text" disabled />
        </uitk-form-field>
      </div>
    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Address</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="Address-shipTo-textbox" class="textbox mx-3"
            [(ngModel)]="orderService.orderDto.shipToDto.street" type="text" disabled />
        </uitk-form-field>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>City</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="city-shipTo-textbox" [(ngModel)]="orderService.orderDto.shipToDto.city"
            class="textbox mx-3" type="text" disabled />
        </uitk-form-field>
      </div>
    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>State</label>
      </div>
      <div class="col-7 d-flex" *ngIf="this.orderService.orderDto.shipToDto.country==='US' && state">
        <uitk-form-field class="w-100">
          <uitk-select uitkId="state-shipTo" [itemList]="stateList" placeholder="Select" [(ngModel)]="state"
            class="stateSelect w-100 mx-3" [disabled]=true>
          </uitk-select>
        </uitk-form-field>
      </div>
      <div class="col-7 d-flex" *ngIf="!(this.orderService.orderDto.shipToDto.country==='US' && state)">
        <uitk-form-field>
          <input uitkInput id="state-shipTo-textbox" [(ngModel)]="this.orderService.orderDto.shipToDto.state"
            class="textbox mx-3" type="text" disabled />
        </uitk-form-field>
      </div>
    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Zip Code</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="zipCode-shipTo-textbox" [(ngModel)]="orderService.orderDto.shipToDto.zip"
            class="textbox mx-3" type="text" disabled />
        </uitk-form-field>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Country</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="country-shipTo-textbox" [(ngModel)]="this.orderService.orderDto.shipToDto.country"
            class="textbox mx-3" type="text" disabled />
        </uitk-form-field>
      </div>
    </div>
  </div>
  <div *ngIf="contactDialogModal.show">
    <app-contact-change-popup [contactDialogModal]="contactDialogModal"></app-contact-change-popup>
  </div>
  <div *ngIf="contactSearchLookupPopup.show">
    <app-contact-search-lookup [contactSearchLookupDialog]="contactSearchLookupPopup" triggerEleId="contactName-shipTo-textbox"
      (selectedContact)="bindSelectedContact($event)">
    </app-contact-search-lookup>
  </div>
  <div *ngIf="showAdvancedContactSearchDialog.show">
    <app-advanced-contact-search-dialog [advancedContactSearchDialog]="showAdvancedContactSearchDialog"
      [component]="'ship-to'">
    </app-advanced-contact-search-dialog>
  </div>
</div>
