import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { gridFilterModel } from '../../dtos/grid-filter-model';
import { ToolsService } from '../../services/tools.service';
import { gridSortModel } from '../../dtos/grid-sort-model';

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.css']
})
export class FilterBarComponent {
  @Input('gridFilters') gridFilters: gridFilterModel[] = [];
  @Input('gridSort') gridSort!: gridSortModel;
  @Input('allData') allData: any[] = [];
  @Input('filteredData') filteredData: any[] = [];

  @Output() gridFiltersChange = new EventEmitter();
  @Output() filteredDataChange = new EventEmitter();
  
  constructor(private readonly toolsService : ToolsService) { }

  removeFilter(gridFilter: { fieldName: string, filterValue: string, filterApplied: boolean, columnName : string }) {
    gridFilter.filterValue = '';
    gridFilter.filterApplied = false;
    gridFilter.columnName = '';
    this.gridFilters = [...this.gridFilters];
    this.gridFiltersChange.emit(this.gridFilters);

    this.applyFilters();
    if(this.gridSort){
      this.applySort();
    }
  }

  applyFilters() {
    this.filteredData = this.toolsService.FilterData(this.gridFilters,this.allData);
  
    this.filteredDataChange.emit(this.filteredData);
    this.filteredDataChange.emit(this.filteredData);
  }
  applySort() {
    this.filteredData = this.toolsService.sortData(this.filteredData, this.gridSort);
    this.filteredData = [...this.filteredData];
    this.filteredDataChange.emit(this.filteredData);
  }
}
