import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { IUITKRadioGroupItem } from '@uitk/angular';
import { Observable, of, Subject } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { ARSLabelDto } from '../dtos/ars-label-dto.model';
import { ConfigService } from './config.service';
import { ModeService } from './mode.service';

@Injectable({
    providedIn: 'root'
})

export class ARSLabelService {
    private readonly _httpClient: HttpClient;
    private readonly _configService: ConfigService;
    selectedARSYesNo!: IUITKRadioGroupItem | null;
    showARSEmailAddressSection = true;
    arsLabelId = 0;
    arsEmailAddress = '';
    showARSLabelErrors = false;
    arsLabelErrors = '';
    arsLabelDtoCache!: ARSLabelDto | null;
    arsLabelCachedObservable!: Observable<ARSLabelDto> | null;
    arsLabelDto: ARSLabelDto = new ARSLabelDto();
    arsLabelIsReady = new Subject();
    isArsLabelChanged = false;
    isArsSaveCalled = false;
    constructor(httpClient: HttpClient, configService: ConfigService, modeService: ModeService) {
        this._httpClient = httpClient;
        this._configService = configService;
    }

    getARSLabelDetails(id: number): Observable<ARSLabelDto> {
        let observable: Observable<ARSLabelDto>;

        if (this.arsLabelDtoCache) {
            observable = of(this.arsLabelDtoCache);
        }
        else if (this.arsLabelCachedObservable) {
            observable = this.arsLabelCachedObservable;
        }
        else {
            this.arsLabelCachedObservable = this._httpClient.get<ARSLabelDto>(`${this._configService.shippingHandlingApiUrl}/Shipping/GetArsLabel?orderId=${id}`)
                .pipe(
                    tap((response: ARSLabelDto) => {
                        this.arsLabelDtoCache = response;
                    }),
                    share(),
                    finalize(() => {
                        this.arsLabelDtoCache = null;
                        this.arsLabelCachedObservable = null;
                    })
                );

            observable = this.arsLabelCachedObservable;
        }

        return observable;
    }

    saveARSLabelDetails(arsLabelDto: ARSLabelDto) {
        return this._httpClient.post<ARSLabelDto>(`${this._configService.shippingHandlingApiUrl}/Shipping/SaveArsLabel`, arsLabelDto);
    }
}