<div *ngIf="orderApprovalDialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="orderApprovalDialog.show" uitkId="orderApprovalDialog"
             [triggerElementId]="orderService.shippingDialogModalTriggerEle" (onModalHidden)="afterApprovalPopupHides()">
  <uitk-dialog-header>
    <div cdkDragHandle>
      {{ myDialogHeader }}
    </div>
  </uitk-dialog-header>

  <uitk-dialog-content scrollToTarget>
    <div class="uitk-l-grid__row mt-3">
      <div class="col-6 d-flex">
        <div class="col-4 d-flex">
          <label uitkLabel>Account ID:</label>
        </div>
        <div class="col-5 d-flex">
          <span>
            {{orderService.orderDto.billToDto.id}}
          </span>
        </div>
      </div>
      <div class="col-6 d-flex">
        <div class="col-5 d-flex">
          <label uitkLabel>Quote/Order#:</label>
        </div>
        <div class="col-4 d-flex">
          <span>
            {{orderService.orderDto.id}}
          </span>
        </div>
      </div>
    </div>
    <div class="uitk-l-grid__row mt-3">
      <div class="col-6">
        <label class="col-4" uitkLabel>Account Name:</label>
        <span class="col-2">{{orderService.orderDto.billToDto.company}}</span>
      </div>
    </div>
    <div class="container-fluid" style="padding-left: 0px;">
      <div class="row">
        <div [ngClass]="approvalSectionPanelClass" class="uitk-l-grid__col">
          <uitk-panel uitkId="ApprovalSectionPanel" [collapsible]="collapsibleApprovalSectionPanel"
                      [open]="openApprovalSectionPanel" (openChange)="setPanelForApprovalSection()">
            <uitk-panel-header-title>
              <span uitkPanelTitleText
                    style="font-size: 1.25rem; font-family: Arial, Helvetica, sans-serif; font-weight: 700;">Approvals</span>
            </uitk-panel-header-title>
            <uitk-panel-content>
              <div class="container-fluid">
                <div id="approval-Required" class="uitk-l-grid__row">
                  <div class="col-6 d-flex">
                    <div class="col-5 d-flex">
                      <label class="align-self-center label col-12" uitkLabel>Approval Required</label>
                    </div>
                    <div class="col-6 d-flex">
                      <span class="align-self-center mx-3">{{ orderApprovalInputDto.approvalRequired }}</span>
                    </div>
                  </div>
                  <div class="col-6 d-flex">
                    <div class="col-5 d-flex">
                      <label class="align-self-center label col-12" uitkLabel>Allowed %</label>
                    </div>
                    <div class="col-6 d-flex">
                      <uitk-form-field class="mx-3">
                        <input uitkInput id="allowedPercentage" name="allowedPercentageInput" readonly
                        [ngModel]="orderApprovalInputDto.allowedPercentage / 100 | percent:'1.2-2'"
            (ngModelChange)="orderApprovalInputDto.allowedPercentage" />
                      </uitk-form-field>
                    </div>
                  </div>
                </div>
                <div id="belowFP-extPrice" class="uitk-l-grid__row mt-3">
                  <div class="col-6 d-flex">
                    <div class="col-5 d-flex">
                      <label class="align-self-center label col-12" uitkLabel>Below Floor Price</label>
                    </div>
                    <div class="col-6 d-flex">
                      <uitk-form-field class="mx-3">
                        <input uitkInput [(ngModel)]="orderApprovalInputDto.itemsBelowFP" id="belowFP" readonly/>
                      </uitk-form-field>
                    </div>
                  </div>
                  <div class="col-6 d-flex">
                    <div class="col-5 d-flex">
                      <label class="align-self-center label col-12" uitkLabel>Total Ext Price</label>
                    </div>
                    <div class="col-6 d-flex">
                      <uitk-form-field class="mx-3">
                        <input uitkInput id="totalExtendedPrice" [(customModel)]="orderApprovalInputDto.totalExtendedPrice" appPriceFormat name="extPriceTotal" readonly/>
                      </uitk-form-field>
                    </div>
                  </div>
                </div>
                <div id="overLP-Margin" class="uitk-l-grid__row mt-3 mb-5">
                  <div class="col-6 d-flex">
                    <div class="col-5 d-flex">
                      <label class="align-self-center label col-12" uitkLabel>Over List Price</label>
                    </div>
                    <div class="col-6 d-flex">
                      <uitk-form-field class="mx-3">
                        <input uitkInput [(ngModel)]="orderApprovalInputDto.itemsAboveLP" id="overListPrice" readonly/>
                      </uitk-form-field>
                    </div>
                  </div>
                  <div class="col-6 d-flex">
                    <div class="col-5 d-flex">
                      <label class="align-self-center label col-12" uitkLabel>Margin</label>
                    </div>
                    <div class="col-6 d-flex">
                      <uitk-form-field class="mx-3">
                        <input uitkInput [(customModel)]="orderApprovalInputDto.margin" appPriceFormat id="margin" name="input_Margin" readonly/>
                      </uitk-form-field>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12 ">
                    <table uitkTable class="uitk-c-table px-4">
                      <thead class="uitk-c-table__head">
                        <tr style="text-align: left">
                          <th *ngFor="let headerName of tableHeader" scope="col">
                            {{ headerName.name }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let data of splApprovalDetails">
                          <td><strong>{{ data.approvalLevel }}</strong></td>
                          <td>{{ data.approver }}</td>
                          <td>{{ data.threshold }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </uitk-panel-content>
          </uitk-panel>
        </div>
      </div>
    </div>



    <div id="items_below_fp">
      <div class="container-fluid" style="padding-left: 0px;">
        <div class="row">
          <div class="col mt-3">
            <div style="border: 1px solid #D0D0CE; border-radius: 2px; background-color: #FFFFFF;">
              <div class="uitk-c-panel__header" style="display: -webkit-inline-box;">
                <div class="col-4">
                  <div class="row">
                    <span style="font-size: 1.25rem; font-family: Arial, Helvetica, sans-serif; font-weight: 700;">
                      Items
                      Below
                      Floor Price
                    </span>
                  </div>
                </div>
                <div class="col-8" style="padding-top: 5px;">
                  <div class="row"><span class="text-left" style="color: #D9001B;">{{ orderApprovalInputDto.itemsBelowFP }}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="items_above_fp">
      <div class="container-fluid" style="padding-left: 0px;">
        <div class="row">
          <div class="col">
            <div style="border: 1px solid #D0D0CE; border-radius: 2px; background-color: #FFFFFF;">
              <div class="uitk-c-panel__header" style="display: -webkit-inline-box;">
                <div class="col-4">
                  <div class="row">
                    <span style="font-size: 1.25rem; font-family: Arial, Helvetica, sans-serif; font-weight: 700;">
                      Items
                      Above
                      List Price
                    </span>
                  </div>
                </div>
                <div class="col-8" style="padding-top: 5px;">
                  <div class="row"><span class="text-left" style="color: #D9001B;">{{ orderApprovalInputDto.itemsAboveLP }}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div id="businessCategory" class="uitk-l-grid__row mt-4">
      <div class="col-6 d-flex">
        <div class="col-5 d-flex">
          <label class="align-self-center label col-12" uitkLabel>Business Category</label>
        </div>
        <div class="col-5 d-flex">
          <uitk-form-field class="mx-3">
            <uitk-dropdown class="approvalSelect" uitkId="business-cat" [items]="businessCategoryList" [enableSelectFlag]="true" [(ngModel)]="selectedBusinessCategory"></uitk-dropdown>
          </uitk-form-field>
        </div>
      </div>

      <div class="col-6 d-flex">
        <div class="col-5 d-flex">
          <label class="align-self-center label col-12" uitkLabel>Sub-Category</label>
        </div>
        <div class="col-5 d-flex">
          <uitk-form-field class="mx-3">
            <uitk-dropdown class="approvalSelect" uitkId="business-cat-sub-cat" [items]="businessSubCategoryList" [enableSelectFlag]="true" [(ngModel)]="selectedBusinessSubCategory"></uitk-dropdown>
          </uitk-form-field>
        </div>
      </div>
    </div>

    <div id="yesOrNo" class="uitk-l-grid__row mt-3">
      <div class="col-6 d-flex">
        <div class="col-5 d-flex">
          <label class="align-self-center label col-12" uitkLabel>Is this a bid situation?</label>
        </div>
        <div class="col-5 d-flex">
          <uitk-form-field class="mx-3">
            <uitk-dropdown class="approvalSelect" uitkId="is-bad-situation" [items]="yesOrNoList" [enableSelectFlag]="true" [(ngModel)]="isBidSituation"></uitk-dropdown>
          </uitk-form-field>
        </div>
      </div>

      <div class="col-6 d-flex">
        <div class="col-5 d-flex">
          <label class="align-self-center label col-12" uitkLabel>Who is the competitor?</label>
        </div>
        <div class="col-5 d-flex">
          <uitk-form-field class="mx-3">
            <uitk-dropdown class="approvalSelect" uitkId="select-competitor" [items]="competitorList" [enableSelectFlag]="true" [(ngModel)]="selectedCompetitor">
            </uitk-dropdown>
          </uitk-form-field>
        </div>
      </div>
    </div>

    <div class="uitk-l-grid__row mt-3" *ngIf="selectedCompetitor?.value == 'OTHER'">
      <div class="col-6 d-flex">
      </div>

      <div class="col-6 d-flex">
        <div class="col-5 d-flex">
          <label class="align-self-center label col-12" uitkLabel>*Competitor's Name</label>
        </div>
        <div class="col-5 d-flex">
          <uitk-form-field class="mx-3">
            <input uitkInput id="copetitorCode"
                   [(ngModel)]="saveOrderExceptionDto.otherCompetitor"
                   class="approvalSelect"/>
          </uitk-form-field>
        </div>
      </div>
    </div>

    <div id="competitor" class="uitk-l-grid__row mt-4">
      <div class="col-6 d-flex">
        <label class="col-12 align-self-center label" uitkLabel>Do you know the competitor’s offer?</label>
      </div>

      <div class="col-6 d-flex">
        <label class="col-12 align-self-center label" uitkLabel>
          Are there any recent UHG/Optum product/service
          issues?
        </label>
      </div>
    </div>

    <div id="competitor-Reason" class="uitk-l-grid__row mt-3">
      <div class="col-6 d-flex">
        <uitk-form-field class="mx-1">
          <uitk-dropdown class="approvalSelect" uitkId="competitor-offer" [items]="yesOrNoList" [enableSelectFlag]="true" [(ngModel)]="knowCompetitorsOffer"></uitk-dropdown>
        </uitk-form-field>
      </div>

      <div class="col-6 d-flex">
        <uitk-form-field class="mx-1">
          <uitk-dropdown class="approvalSelect" uitkId="isIssue-UHG" [items]="yesOrNoList" [enableSelectFlag]="true" [(ngModel)]="areTheirIssues"></uitk-dropdown>
        </uitk-form-field>
      </div>
    </div>

    <div class="uitk-l-grid__row mt-3">
      <div class="col-12 d-flex">
        <label class="col-12 align-self-center label" uitkLabel>
          *If answered yes to any questions above, provide
          detailed notes
        </label>
      </div>
    </div>

    <div class="uitk-l-grid__row">
      <div class="uitk-l-grid__col">
        <uitk-form-field>
          <textarea uitkTextarea style="height:80px;" name="txtBoxAnswerNotes" uitkId="txtBoxAnswerNotes-textarea"
                    [(ngModel)]="saveOrderExceptionDto.comments"></textarea>
        </uitk-form-field>
      </div>
    </div>

  </uitk-dialog-content>

  <uitk-dialog-actions>
    <button appAutoFocus class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveOrderException()"
            [disabled]="disableSave()">
      Save
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeDialog()">
      Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>
