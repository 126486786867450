<div class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkId="viewFilteredAccountsData" [(showModal)]="accountSearchLookupDialog.show" [closeBtn]="true" triggerElementId="accountName">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Account Search
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div class="row">
      <uitk-form-field>
        <input appAutoFocus uitkInput id="account-search-textbox" class="textbox mx-1" type="text" [(ngModel)]="accountSearchKeyword"
          style="width: 250px;" (ngModelChange)="onChangeAccountKey($event)" (keydown.enter)="showAccountDetailsBasedOnKeyword()"/>
      </uitk-form-field>
      <span class="uitk-icon uitk-icon-search accountSearchIcon" (click)="showAccountDetailsBasedOnKeyword()"
        style="width: 30px;"></span>
      <div id="loadingSpinner" class="uitk-l-grid" *ngIf="loading" style="float:left;width: 30px;margin-top:-55px;margin-right: 68%">
        <uitk-loading-indicator uitkId="spinner" spinnerSize="small">
        </uitk-loading-indicator>
      </div>
      <span style="float:left">* To search for phone, format as XXX/XXX-XXXX</span>
    </div>
    <div class="row mt-3">
      <div class="uitk-c-table--responsive container">
        <table uitkTable [dataSource]="filteredAccountsData" id="account-search-details-table" class="uitk-c-table"
          aria-describedby="account-search-details-data">
          <thead class="uitk-c-table__head">
            <tr uitkTableSort #sortAccountSearchTable="uitkTableSort" style="text-align: left">
              <th *ngFor="let headerName of tableHeader" scope="col" uitkTableSortColumn [column]="headerName"
                (sortChange)="onSortChange($event)">
                {{ headerName}}
              </th>
            </tr>
          </thead>
          <tbody id="account-search-details-data" class="uitk-c-table__body">
            <ng-container *uitkTableRowBody="let data">
              <tr class="uitk-c-table__row">
                <td style="cursor: pointer; color: #027db4"
                  (click)="selectAccount(data)">
                  <p class="address" [innerHTML]="data.id | highlightSearch: accountSearchKeyword"></p>
                  </td>
                <td style="cursor: pointer;" (click)="selectAccount(data)">
                  <p class="address" [innerHTML]="data.company | highlightSearch: accountSearchKeyword"></p>
                </td>
                <td style="cursor: pointer;" (click)="selectAccount(data)">
                  <p class="address" [innerHTML]="data.street | highlightSearch: accountSearchKeyword"></p>
                  <p class="address" [innerHTML]="generateCityStateZip(data) | highlightSearch: accountSearchKeyword">
                  </p>
                  <p class="address" [innerHTML]="data.country | highlightSearch: accountSearchKeyword"></p>
                </td>
                <td style="cursor: pointer;" (click)="selectAccount(data)">
                  <p class="address" [innerHTML]="data.phone | phoneFormat | highlightSearch: accountSearchKeyword"></p>
                </td>
              </tr>
            </ng-container>
            <div *ngIf="filteredAccountsData.data.length === 0 && isDataFound">No Results found</div>
          </tbody>
        </table>
      </div>
    </div>
  </uitk-dialog-content>
</uitk-dialog>
  </div>
</div>
