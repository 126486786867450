import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BBLogDto } from '../dtos/bb-log-dto.model';
import { ChangeLogOutputDto } from '../dtos/change-log-output-dto.model';
import { ConfigService } from './config.service';
import { ModeService } from './mode.service';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root'
})
export class BBLogService {
  bbLogIsReady = new Subject();
  bbLogData = new BBLogDto();
  private readonly _httpClient!: HttpClient;
  private readonly _modeService!: ModeService;
  private readonly _configService!: ConfigService;
  swalAlert = new SwalAlert();

  constructor(httpClient: HttpClient, modeService: ModeService, configService: ConfigService) {
    this._httpClient = httpClient;
    this._modeService = modeService;
    this._configService = configService;
}
  getLogData(input:BBLogDto)
  {
    const message="Getting log data for ";
    this._modeService.addToWaitList(message+input.columnName);
    this._httpClient.get<ChangeLogOutputDto[]>(`${this._configService.apiUrl}/Order/GetChangeLog/?primaryId=` + input.primaryId +
      `&tableName=` + input.tableName + `&fieldName=` + input.columnName)
    .subscribe(data=>{
      input.logData=data;
      input.logData.forEach(item=>{
        item.oldValue=item.oldValue.replace("12:00AM","").replace("12:00:00 AM","");
        item.newValue=item.newValue.replace("12:00AM","").replace("12:00:00 AM","");
      })
      this.bbLogData = input;
      this.bbLogIsReady.next();
      this._modeService.removeFromWaitList(message+input.columnName);
    },(error: any) => {
      this._modeService.removeFromWaitList(message+input.columnName);
      this.swalAlert.alert("Error getting log data for "+input.columnName);
    });

  }
}
