<div id="additionalInformation" >
    <div class="uitk-l-grid__row">       
        <div class="uitk-l-grid__col">
            <label uitkLabel>Method</label>
            <p>{{authorizationDto.paymentMethod}}</p>
        </div>    
        <div class="uitk-l-grid__col">
            <label uitkLabel>Terms</label>
            <p>{{authorizationDto.paymentTerms}}</p>
        </div>
        <div class="uitk-l-grid__col">
            <label uitkLabel>Token</label>
            <p>{{authorizationDto.ccToken}}</p>
        </div>
        <div class="uitk-l-grid__col">
            <label uitkLabel>CC Last 4</label>
            <p>{{authorizationDto.ccLastFour}}</p>
        </div>       
        <div class="uitk-l-grid__col">
            <label uitkLabel>Exp Date</label>
            <p>{{authorizationDto.ccExpirationDate}}</p>
        </div>
    </div>
    
    <div class="uitk-c-table--responsive" class="tableFontSize">
        <table uitkTable [dataSource]="authorizationDto.authorizationLines" class="uitk-c-table" aria-describedby="return-data" id="return-table"
            aria-describedby="row-data" #rootTable="uitkTable">

            <thead class="uitk-c-table__head">
                <tr>
                    <th *ngFor="let headerName of tableHeader" scope="col">
                        {{ headerName.name }}
                    </th>
                </tr>
            </thead>
            <tbody id="row-data" class="uitk-c-table__body">
                <ng-container *uitkTableRowBody="let return">
                    <tr class="uitk-c-table__row" >
                        <td>{{ return.productCode }}</td>
                        <td id="description">{{ return.description }}</td>
                        <td>{{ return.returnedQuantity }}</td>
                        <td>{{ return.listPrice | currency:'USD':'symbol':'1.2-2'}}</td>
                        <td>{{ return.discount/100 | percent:'1.3-3' }}</td>
                        <td>{{ return.unitPrice | currency:'USD':'symbol':'1.2-2'}}</td>
                        <td>{{ return.extendedPrice | currency:'USD':'symbol':'1.2-2'}}</td>
                        <td>{{ return.shipDate | date: 'MM/dd/yyyy' }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
