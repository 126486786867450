<div *ngIf="showItemLevelOffers.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="showItemLevelOffers.show" uitkId="itemLevelOffersDialog" triggerElementId="myDialog-1_openBtn"
             [closeBtn]="false" (onModalHidden)="closeDialog()">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Item Level Discount for {{ orderDetail.productCode }}
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content scrollToTarget>
    <div>
      <div *ngIf="itemOfferDto.discountAndPremium === false && itemOfferDto.premiums.length > 0"
           style="padding-bottom: 10px">
        <strong>Select either &nbsp;</strong>
        <input type="radio" id="orderDiscount" name="discountTypeOrder" value="itemdiscount" [(ngModel)]="discountType" checked="checked">
        <label for="orderDiscount" class="radiolabel"> Item Discount </label>
        <strong> OR &nbsp;&nbsp; </strong>
        <input type="radio" id="premium" name="discountTypePremium" value="premium" [(ngModel)]="discountType">
        <label for="premium" class="radiolabel"> Premiums </label>
        <br>
      </div>

      <div *ngIf="itemOfferDto.percentDiscount === true">
        Customer qualifies for {{itemOfferDto.discountAmount}}%
        off the item
      </div>
      <div *ngIf="itemOfferDto.percentDiscount === false">
        Customer qualifies for ${{itemOfferDto.discountAmount}}
        off the item
      </div>
      <div *ngIf="itemOfferDto.premiums.length > 0">
        <span *ngIf="itemOfferDto.discountAndPremium === true" style="color: red;"><strong>AND</strong></span>
        <span *ngIf="itemOfferDto.discountAndPremium === false" style="color: red;"><strong>OR</strong></span>
        <span *ngIf="itemOfferDto.allPremiums === true"> Select from the following Premiums: </span>
        <span *ngIf="itemOfferDto.allPremiums === false"> Select one of the following Premiums: </span>
      </div>
      <div *ngIf="itemOfferDto.premiums.length > 0">
        <div class="uitk-l-grid">
          <div class="uitk-l-grid__row">
            <div class="uitk-l-grid__col--12">
              <div uitkTableContainer>
                <table uitkTable [dataSource]="itemOfferDto.premiums" id="orderLevelOffers-table">
                  <thead>
                    <tr>
                      <th *ngIf="itemOfferDto.allPremiums === true">
                        <input type="checkbox" uitkCheckbox class="checkBoxInGrid"
                               style="position: relative; height: 20px; width: 20px;"
                               name="selectallCheckBox" [(ngModel)]="selectAllPremium" (change)="onClickSelectAll()" 
                               [disabled]="discountType === 'itemdiscount' && !itemOfferDto.discountAndPremium "/>
                      </th>
                      <th *ngIf="itemOfferDto.allPremiums === false"></th>
                      <th *ngFor="let headerName of itemLevelOfferHeader" scope="col">
                        {{ headerName }}
                      </th>
                    </tr>
                  </thead>
                  <tbody id="patient-data">
                    <ng-container *uitkTableRowRenderer="let premium">
                      <tr>
                        <td *ngIf="itemOfferDto.allPremiums === false">
                          <input id="subGrdRadio" type="radio" name="selectRowRadioGroup"
                                 class="uitk-c-radio"
                                 (change)="onRadioButtonSelectionChange(premium)"
                                 [disabled]="discountType === 'itemdiscount' &&
                                 !itemOfferDto.discountAndPremium" [checked]="premium.isSelected" />
                        </td>
                        <td *ngIf="itemOfferDto.allPremiums === true">
                          <input type="checkbox" uitkCheckbox
                                 id="selectRowCheckBox-{{premium.productId}}"
                                 name="selectRowCheckBox-{{premium.productId}}"
                                 (change)="onCheckBoxSelectionChange();"
                                 [(ngModel)]="premium.isSelected" class="checkBoxInGrid"
                                 [disabled]="discountType === 'itemdiscount' && !itemOfferDto.discountAndPremium "
                                 style="position: relative; height: 20px; width: 20px;" />
                        </td>
                        <td>{{ premium.productCode }}</td>
                        <td>{{ premium.description }}</td>
                        <td>{{ premium.quantity }}</td>
                        <td>{{ premium.listPrice | currency:'USD' }}</td>
                        <td>{{ premium.discount/100 | percent }}</td>
                        <td>{{premium.listPrice- premium.listPrice/100 * premium.discount | currency:'USD' }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="acceptOffer()" appAutoFocus>
      {{ acceptOfferText }}
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="doNotAcceptOffer()">
      {{ doNotAcceptOfferText }}
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>
<div *ngIf="webItemAllowOnlyOneDialog.show">
  <app-web-item-allow-only-one-dialog [dialog]="webItemAllowOnlyOneDialog">
  </app-web-item-allow-only-one-dialog>
</div>
<div *ngIf="continueDuplicateItemDialog.show">
  <app-continue-duplicate-item-dialog [dialog]="continueDuplicateItemDialog" [duplicateOrderDetail]="duplicateOrderDetail">
  </app-continue-duplicate-item-dialog>
</div>
<div class="divPopUpContent" *ngIf="configSetDialog.show">
  <app-config-sets [dialog]="configSetDialog" [orderDetail]="premiumOrderDetail">
  </app-config-sets>
</div>