<div class = "overlayForPopups" *ngIf="dialog.show">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" uitkId="sendInvoicePdfDialog" [closeBtn]=false
  triggerElementId="sendSelectedToPdf_openBtn">
  <uitk-dialog-header style="font-size: 25px; line-height: 36px;">
    <div cdkDragHandle>
      <uitk-banner-notification [show]="isNotificationVisible" uitkId="emailNotification" [variant]="notificationVariant">
        <h1 uitkBannerHeading>{{ emailNotificationMessage }}</h1>
      </uitk-banner-notification>
      Send Invoice PDF
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div class="container-fluid" [hidden]="modeService.isWaiting">
      <div class="row">
        <div class="col">
          <p><strong>Send Email to</strong></p><p>The number of selected invoices : {{selectedInvoicesList.length}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <uitk-form-field>
            <input id="bill-to-contact-checkbox" type="checkbox" [(ngModel)]="billToContactcheckbox" uitkCheckbox
              (change)="changeEmail()" [disabled]="disableBillTo" />
            <label uitkCheckboxLabel for="bill-to-contact-checkbox">Bill to Contact</label>
          </uitk-form-field>
        </div>
        <div class="col">
          <uitk-form-field>
            <input id="order-by-contact-checkbox" type="checkbox" [(ngModel)]="orderByContactcheckbox" uitkCheckbox
              (change)="changeEmail()" [disabled]="disableOrderBy" />
            <label uitkCheckboxLabel for="order-by-contact-checkbox">Order by Contact</label>
          </uitk-form-field>
        </div>
        <div class="col">
          <uitk-form-field>
            <input id="ship-to-contact-checkbox" type="checkbox" [(ngModel)]="shipToContactcheckbox" uitkCheckbox
              (change)="changeEmail()" [disabled]="disableShipTo" />
            <label uitkCheckboxLabel for="ship-to-contact-checkbox">Ship to Contact</label>
          </uitk-form-field>
        </div>

        <div class="col">
          <uitk-form-field>
            <input id="logged-in-User-checkbox" type="checkbox" [(ngModel)]="LoggedInUsercheckbox" uitkCheckbox
            (change)="changeEmail()" />
          <label uitkCheckboxLabel for="logged-in-User-checkbox">Logged in User</label>
          </uitk-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p><strong>Email Addresses</strong></p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <textarea uitkTextarea style="height:80px;" name="emailField"
            uitkId="my-send-invoice-pdf-email-textarea" [(ngModel)]="email"
            aria-describedby="my-send-invoice-pdf-email-textarea-ht" #userEmail="ngModel" [pattern]="emailPattern"
            value="" #userEmail1></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col small">
          Multiple emails are separated by a semicolon (;)
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div *ngIf="userEmail.errors || !this.email" id="emailError"
            class="ng-tns-c54-2 uitk-c-notification__container uitk-c-notification__container--error ng-trigger ng-trigger-fadeInOut ng-star-inserted">
            <div class="uitk-c-notification__content-container ng-tns-c54-2">
              <div class="uitk-c-notification__icon ng-tns-c54-2 pt-1">
                <span class="ng-tns-c54-1 uitk-icon uitk-icon-info_filled ng-star-inserted"></span>
              </div>
              <div class="uitk-c-notification__content ng-tns-c54-2" id="warningDemoId_content">
                <div>
                  <span *ngIf="!this.email" class="ng-tns-c54-2"> required</span>
                  <span *ngIf="userEmail.errors" class="ng-tns-c54-2"> Invalid Email</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <p><strong>Subject</strong></p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <uitk-form-field>
            <input uitkInput class="textbox" id="subjectField" type="textbox" 
              [(ngModel)]="this.accountsReceivableService.EmailSubject" />
          </uitk-form-field>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <p><strong>Body</strong></p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <textarea uitkTextarea name="bodyField" uitkId="my-send-invoice-pdf-body-textarea" style="height: 150px;" [(ngModel)]="body" value=""></textarea>
        </div>
      </div>
    </div>
    <div class="container-fluid" [hidden]="!modeService.isWaiting">
      <div class="row">
        <div class="col">
          <div id="loadingIndicator">
            <uitk-loading-indicator uitkId="spinner" spinnerSize="large">
            </uitk-loading-indicator>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col d-flex justify-content-center" >
          <h5>
            {{ modeService.waitDescription }}
          </h5>
        </div>
      </div>
    </div>    
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" [disabled]="modeService.isWaiting" (click)="sendPdf()" appAutoFocus>
      Send
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeSendInvoicePdfDialog()">
      Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>