import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { OrderDetailDto } from '../dtos/order-detail-dto.model';
import { OrderDto } from '../dtos/order-dto.model';
import { PremiumDto } from '../dtos/premium-dto';
import { ShippingHandlingDto, ShippingProducts } from '../dtos/shipping-handling-dto.model';
import { ShippingHandlingResultDto } from '../dtos/shipping-handling-result-dto.model';
import { ConfigService } from './config.service';
import { ModeService } from './mode.service';
import { PricingService } from './pricing.service';

@Injectable({
  providedIn: 'root'
})

export class ShippinghandlingService {
  inProcess = false;
  isError = false;
  premiumShippingItem = new PremiumDto();
  private readonly _httpClient: HttpClient;
  private readonly _configService: ConfigService;

  constructor(
      httpClient: HttpClient,
      configService: ConfigService,
      private readonly modeService: ModeService,
      private readonly pricingService: PricingService) {
    this._httpClient = httpClient;
    this._configService = configService;
  }

  getShippingCharges(shippingObj: any): Observable<ShippingHandlingResultDto> {
    return this._httpClient.post<ShippingHandlingResultDto>(`${this._configService.shippingHandlingApiUrl}/Shipping/GetShippingCharges`, shippingObj);
  }

  async recalculateShippingAndHandlingAsync(orderDto: OrderDto | null): Promise<void> {
    const resultPromise = new Promise((resolve: (value: void) => void) => {
      this.recalculateShippingAndHandling(orderDto, resolve);
    });
    return resultPromise;

  }
  recalculateShippingAndHandling(orderDto: OrderDto | null, resolve: null | ((value: void) => void)= null): void {
    if (this.inProcess) {
      return;
    }

    if (!orderDto) {
      this.checkResolve(resolve);
      return;
    }

    if (orderDto.orderType === 'R') {
      this.checkResolve(resolve);
      return;
    }

    const physicalLineItems = orderDto.orderDetailDtos.filter(m => m.productDto.isPhysical && m.orderDetailStatus !== 'V');
    if (physicalLineItems.length === 0) {
      this.checkResolve(resolve);
      return;
    }

    if (!this._configService.reference) {
      this.checkResolve(resolve);
      return;
    }

    const shippingOrderDetail = orderDto.orderDetailDtos.filter(m => m.classCode === 'SH' && m.orderDetailStatus === ' ')[0];
    if (!shippingOrderDetail && this.premiumShippingItem.productId === 0) {
      this.checkResolve(resolve);
      return;
    }


    this.inProcess = true;
    this.isError=false;
    const shippingItems: ShippingProducts[] = [];
    const shippingHandling: ShippingHandlingDto = new ShippingHandlingDto();    
    shippingHandling.country = orderDto.shipToDto.country;

    const selectedShipTo = this._configService.reference.shippingDtos.filter(m => m.deliveryCode === orderDto.shipmentMethod)[0];
    this.recalculateNext(physicalLineItems,shippingItems,shippingHandling,shippingOrderDetail,orderDto,selectedShipTo,resolve);
    orderDto.orderDetailDtos = [...orderDto.orderDetailDtos];
  }

  checkResolve(resolve:any){
    if (resolve) {
      resolve();
    }
  }

  recalculateNext(physicalLineItems:any,shippingItems:ShippingProducts[],shippingHandling:ShippingHandlingDto,shippingOrderDetail:any,orderDto:OrderDto,selectedShipTo:any,resolve:any){
    physicalLineItems.forEach((m:any) => {
      let shippingItem = new ShippingProducts();
      shippingItem.item = m.productCode;
      shippingItem.quantity = parseInt(m.orderQuantity == null ? m.openQuantity.toString() : m.orderQuantity.toString());
      shippingItem.unitWeight = m.productDto.unitWeight;
      shippingItems.push(shippingItem);
    });

    const message="Calculating shipping and handling";
    shippingHandling.shippingProducts = shippingItems;
    shippingHandling.shippingMethod = selectedShipTo.emailText;
    if(shippingHandling.shippingMethod === 'MISC' && shippingOrderDetail.productCode === '0050'){
      shippingHandling.shippingMethod = 'NEXTDAYAIR';
    }
    else if(shippingHandling.shippingMethod === 'MISC' && shippingOrderDetail.productCode === '0051'){
      shippingHandling.shippingMethod = 'SECONDDAYAIR';
    }
    shippingHandling.zip = orderDto.shipToDto.zip.substring(0, 5);
    this.modeService.addToWaitList(message);
    this.getShippingCharges(shippingHandling).subscribe((data: ShippingHandlingResultDto) => {
      if(this.premiumShippingItem.productId !== 0){
        this.premiumShippingItem.listPrice = data.shippingCharge;
      }
      if (shippingOrderDetail) {
        shippingOrderDetail.listPrice = data.shippingCharge;
        shippingOrderDetail.unitPrice = this.pricingService.round((shippingOrderDetail.listPrice * (100 - shippingOrderDetail.discount) / 100), 2);
        shippingOrderDetail.extendedPrice = shippingOrderDetail.unitPrice;
      }
      this.inProcess = false;
      this.modeService.removeFromWaitList(message);
      if (resolve) {
        resolve();
      }
    }, (error: any) => {
      console.log(error);
      this.inProcess = false;
      this.isError = true;
      this.modeService.removeFromWaitList(message);
      if (resolve) {
        resolve();
      }
    });

  }
}
