import { IUITKSelectItemProps } from "@uitk/angular";

export class OrderByDto
{
  id = 0;
  contactId = 0;
  firstName = '';
  lastName = '';
  allowInformationalEmails = false;
  allowPromotionalEmails = false;
  email = '';
  phone = '';
  salesforceId = '';
  statusCode = '';
  fax = '';
  title = '';
  middleInitial = '';
  statusCodeDescription = '';
}
