import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { BatchReturnsInProcessDto } from '../dtos/batch-returns-in-process-dto.model';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class BatchReturnService {
  private readonly _httpClient: HttpClient;
  private readonly _configService: ConfigService
  batchReturnsInProcessCache!: BatchReturnsInProcessDto[] | null;
  batchReturnsInProcessCachedObservable!: Observable<BatchReturnsInProcessDto[]> | null;

  constructor(httpClient: HttpClient, configService: ConfigService) {
    this._httpClient = httpClient;
    this._configService = configService;
  }

  getAllBatchReturn(userId: string): Observable<BatchReturnsInProcessDto[]> {
    let observable: Observable<BatchReturnsInProcessDto[]>;

    if (this.batchReturnsInProcessCache) {
      observable = of(this.batchReturnsInProcessCache);
    }
    else if (this.batchReturnsInProcessCachedObservable) {
      observable = this.batchReturnsInProcessCachedObservable;
    }
    else {
      this.batchReturnsInProcessCachedObservable = this._httpClient.get<BatchReturnsInProcessDto[]>(`${this._configService.apiUrl}/BatchReturns/GetBatchReturn?userLogin=${userId}`)
        .pipe(
          tap((response: BatchReturnsInProcessDto[]) => {
            this.batchReturnsInProcessCache = response;
          }),
          share(),
          finalize(() => {
            this.batchReturnsInProcessCache = null;
            this.batchReturnsInProcessCachedObservable = null;
          })
        );

      observable = this.batchReturnsInProcessCachedObservable;
    }

    return observable;
  }

  deleteBatchReturn(id: number): Observable<void> {
    return this._httpClient.delete<void>(`${this._configService.apiUrl}/BatchReturns/DeleteBatchReturn/${id}`);
  }

  addToBatchReturn(batchReturnsInProcessDto: BatchReturnsInProcessDto, userId: string): Observable<BatchReturnsInProcessDto> {
    return this._httpClient.post<BatchReturnsInProcessDto>(`${this._configService.apiUrl}/BatchReturns/AddBatchReturn?userLogin=${userId}`, batchReturnsInProcessDto);
  }

  processBatchReturns(userId: string): Observable<void> {
    return this._httpClient.post<void>(`${this._configService.apiUrl}/BatchReturns/ProcessBatchReturn?userId=${userId}`, userId);
  }

  deleteAllBatchReturn(userId: string): Observable<void> {
    return this._httpClient.delete<void>(`${this._configService.apiUrl}/BatchReturns/DeleteAllBatchReturn/${userId}`);
  }
}
