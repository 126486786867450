import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IUITKColumnState, UITKSortDirection, UITKTableDataSource, UITKTableSortDirective } from '@uitk/angular';
import { OrderService } from 'src/app/services/order.service';
import { BillToDto } from '../../../dtos/bill-to-dto.model';
import { ConfigService } from '../../../services/config.service';

@Component({
  selector: 'app-account-search-lookup',
  templateUrl: './account-search-lookup.component.html',
  styleUrls: ['./account-search-lookup.component.css']
})
  
export class AccountSearchLookupComponent implements OnInit {
  private readonly _configService: ConfigService;
  private readonly _orderService:OrderService;
  accountSearchKeyword: string = '';
  @Input() accountSearchLookupDialog = { show: false }; 
  @Output() selectedAccount = new EventEmitter<BillToDto>();
  @ViewChild('sortAccountSearchTable') uitkTableSortAccountSearch!: UITKTableSortDirective;
  tableHeader = ['Account ID', 'Name', 'Address', 'Phone'];
  filteredAccountsData = new UITKTableDataSource<BillToDto>([]);
  isDataFound = false;
  loading: boolean = false;


  constructor(configService: ConfigService, orderService:OrderService) {
    this._configService = configService;
    this._orderService = orderService;
  }

  ngOnInit(): void {
    if(this._orderService.selectedAccount !== null && this._orderService.selectedAccount !== undefined && this._orderService.selectedAccount.trim() !== ''){
      this.accountSearchKeyword = this._orderService.selectedAccount;
      this.showAccountDetailsBasedOnKeyword();
    }
  }

  async showAccountDetailsBasedOnKeyword(): Promise<void> {
    this.filteredAccountsData.data = [];
    this.isDataFound = false;
    this.loading = true;
    const accountSearchKeyword = (this.accountSearchKeyword.indexOf('/') > -1 && this.accountSearchKeyword.indexOf('-') > -1 && this.accountSearchKeyword.indexOf('-') === this.accountSearchKeyword.lastIndexOf('-')) ? this.accountSearchKeyword.replace("-",'').replace("/",'') :  this.accountSearchKeyword;
    await this._configService.getAccountSearchDetails(accountSearchKeyword.trim())
      .then((data: BillToDto[]) => {
        this.loading = false;
        if(!isNaN(Number(this.accountSearchKeyword)) && this.accountSearchKeyword.length >= 10){
          this.filteredAccountsData.data = [];
        }else{
          this.filteredAccountsData.data = data;
        }
        this.isDataFound = true;
        this.tableHeader.forEach(header => {
          this.uitkTableSortAccountSearch.setDirection({ column: header, direction: UITKSortDirection.UNSORTED });
        });

        this.uitkTableSortAccountSearch.setDirection({ column: 'Name', direction: UITKSortDirection.ASC });
        this.filteredAccountsData.data.sort((a, b) => 0 - (a.company > b.company ? -1 : 1));
        this.filteredAccountsData.data = [...this.filteredAccountsData.data];
      });
  }

  selectAccount(account: BillToDto): void {
    this.selectedAccount.emit(account);
    this.accountSearchLookupDialog.show = false;
  }

  onSortChange(event: IUITKColumnState): void {
    this.filteredAccountsData.data = this.filteredAccountsData.sortData(this.filteredAccountsData.data, event.direction, event.column.toLowerCase());

    switch (event.column.toLowerCase().replace(/\s/g, '')) {
      case 'accountid':
        switch (event.direction) {
          case 1:
            this.filteredAccountsData.data.sort((a, b) => 0 - (a.id > b.id ? -1 : 1));
            break;
          case -1:
            this.filteredAccountsData.data.sort((a, b) => 0 - (a.id > b.id ? 1 : -1));
            break;
        }

        break;
      case 'name':
        this.sortByName(event.direction);

        break;
      case 'address':
        this.sortByAddress(event.direction);

        break;
      case 'phone':
        this.sortByPhone(event.direction);

        break;
    }

    this.filteredAccountsData.data = [...this.filteredAccountsData.data];
  }

  private sortByName(direction:number){
    switch (direction) {
      case 1:
        this.filteredAccountsData.data.sort((a, b) => 0 - (a.company > b.company ? -1 : 1));
        break;
      case -1:
        this.filteredAccountsData.data.sort((a, b) => 0 - (a.company > b.company ? 1 : -1));
        break;
    }
  }

  private sortByAddress(direction: number): void {
    switch (direction) {
      case 1:
        this.filteredAccountsData.data.sort((a, b) => 0 - (this.generateAddress(a) > this.generateAddress(b) ? -1 : 1));
        break;
      case -1:
        this.filteredAccountsData.data.sort((a, b) => 0 - (this.generateAddress(a) > this.generateAddress(b) ? 1 : -1));
        break;
    }
  }

  private sortByPhone(direction: number): void {
    switch (direction) {
      case 1:
        this.filteredAccountsData.data.sort((a, b) => 0 - (a.phone > b.phone ? -1 : 1));
        break;
      case -1:
        this.filteredAccountsData.data.sort((a, b) => 0 - (a.phone > b.phone ? 1 : -1));
        break;
    }
  }

  generateCityStateZip(account: BillToDto): string {
    return [account.city, account.state, account.zip].filter(Boolean).join(', ');
  }

  generateAddress(account: BillToDto): string {
    return [account.street, account.city, account.state, account.zip, account.country].filter(Boolean).join(', ');
  }

  onChangeAccountKey(value: string){
    this.accountSearchKeyword = value.trim();
  }
}
