import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular/forms/select/select-item.interface';
import { OrderNoteDto } from '../../../dtos/order-note-dto.model';
import { ChegSetDto } from '../../../dtos/cheg-set-dto';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { ProductDto } from '../../../dtos/product-dto.model';
import { ConfigService } from '../../../services/config.service';
import { ConfigurationService } from '../../../services/configurations.service';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-chegset',
  templateUrl: './chegset.component.html',
  styleUrls: ['./chegset.component.css']
})

export class ChegsetComponent implements OnInit {
  private readonly _pricingService: PricingService;
  private readonly _configService: ConfigService;
  private readonly _httpClient: HttpClient;
  private readonly _orderService: OrderService;
  private readonly _configurationService: ConfigurationService;
  @Input() orderDetail: OrderDetailDto = new OrderDetailDto();
  @Output() enableSave = new EventEmitter<boolean>();
  stateList: IUITKSelectItemProps[] = [];
  carrierList: IUITKSelectItemProps[] = [];
  localityList: IUITKSelectItemProps[] = [];
  selectedLocality: IUITKSelectItemProps = { id: 'Select', label: 'Select', value: 'Select' };
  selectedState: IUITKSelectItemProps = { id: 'Select', label: 'Select', value: 'Select' };
  selectedCarrier: IUITKSelectItemProps = { id: 'Select', label: 'Select', value: 'Select' };
  mainProductDto: ProductDto = new ProductDto();
  licenseProduct: ProductDto = new ProductDto();
  licensePricing:
    { productCode: string, listPrice: number, orderQuantity: number, discount: number, unitPrice: number, extendedPrice: number, id: number,   voidedQuantity : number } =
    { productCode: '', listPrice: 0, orderQuantity: 0, discount: 0, unitPrice: 0, extendedPrice: 0, id: 0 ,  voidedQuantity: 0}
  stateCarrierLocalityList: ChegSetDto[] = [];
  enableChangeEvents = false;
  comments: string | null = null;
  orderDetailDtoBackup :OrderDetailDto[]=[];
  orderNotesDtoBackUp : OrderNoteDto[]=[];
  voidItemDialog = { show: false };
  selectedOrderDetail: OrderDetailDto = new OrderDetailDto();
  swalAlert = new SwalAlert();

  constructor(
    pricingService: PricingService,
    configService: ConfigService,
    httpClient: HttpClient,
    orderService: OrderService,
    configurationService: ConfigurationService) {
      this._pricingService = pricingService;
      this._configService = configService;
      this._httpClient = httpClient;
      this._orderService = orderService;
      this._configurationService = configurationService;
    }

  ngOnInit(): void {
    this.orderDetailDtoBackup = JSON.parse(JSON.stringify(this._orderService.orderDto.orderDetailDtos)) as OrderDetailDto[];
    this.orderNotesDtoBackUp = JSON.parse(JSON.stringify(this._orderService.orderDto.orderNotes)) as OrderNoteDto[];
    this._configurationService.getDataFileAddons(this.orderDetail.productId).subscribe(data => {
      if (data.length === 0) {
        this.swalAlert.alert(`Error: Unable to find additional user record in DataFileAddon for main item with productId = ${this.orderDetail.productId}.`);
        return;
      }

      this.licenseProduct = this._configService.getProductDetails(data[0].addonProductId);
      if (this.licenseProduct === null) {
        this.swalAlert.alert(`Error: Unable to find record in Product table for the additional users product with productId = ${data[0].addonProductId}.`)
      }

      const additionalUsersOrderDetail = this._orderService.orderDto.orderDetailDtos
      .filter(o => o.productId === data[0].addonProductId && o.orderDetailStatus === ' ' && o.parentOrderDetailId === this.orderDetail.id)[0];

      if (additionalUsersOrderDetail) {
        this.licensePricing.listPrice = additionalUsersOrderDetail.listPrice;
        this.licensePricing.orderQuantity = additionalUsersOrderDetail.orderQuantity;
        this.licensePricing.discount = additionalUsersOrderDetail.discount;
        this.licensePricing.unitPrice = additionalUsersOrderDetail.unitPrice;
        this.licensePricing.extendedPrice = additionalUsersOrderDetail.extendedPrice;
        this.licensePricing.productCode = additionalUsersOrderDetail.productCode;
      } else {
        this.licensePricing.orderQuantity = 1;
        this.licensePricing.productCode = this.licenseProduct.productCode;
        this.licensePricing.listPrice = this._pricingService.calculateListPricing(this._orderService.orderDto, this.licensePricing) || this.licenseProduct.listPrice;
        this.licensePricing.orderQuantity = 0;
      }

    }, error => {
      console.log(error);
      this.swalAlert.alert('An error occured while fetching Chegset Addons');
    });

    this.mainProductDto = this._configService.getProductDetails(this.orderDetail.productId);

    this._httpClient.get<ChegSetDto[]>(`${this._configService.apiUrl}/reference/carrierLocality`)
      .subscribe(data => {
        this.stateCarrierLocalityList = data;
        this.comments = this.orderDetail.chegSetDto?.comments ?? '';
        this.getStateList();

        if (this.orderDetail.chegSetDto) {
          const state = this.orderDetail.chegSetDto.state ?? 'Select';
          this.selectedState = { id: state, label: state, value: state };

          const carrier = this.orderDetail.chegSetDto.carrier ?? 'Select';
          setTimeout(() => {
            this.selectedCarrier = { id: carrier, label: carrier, value: carrier };

            const locality = this.orderDetail.chegSetDto?.locality ?? 'Select';
            setTimeout(() => {
              this.selectedLocality = { id: locality, label: locality, value: locality };

              this.toggleSave();
              this.enableChangeEvents = true;
            });
          });
        } else {
          this.enableChangeEvents = true;
          this.toggleSave();
        }
      }, () => {
        this.swalAlert.alert('An error occurred while getting stateCarrierLocality details. Please try again.');
      });
  }

  getStateList(): void {
    const distinctStates: string[] = [...new Set(this.stateCarrierLocalityList.map(item => item.state ?? ''))];
    this.stateList = distinctStates.map(item => ({ id: item, label: item, value: item } as IUITKSelectItemProps));
  }

  onChangeState(state: string): void {
    this.getCarrierList(state);

    if (this.enableChangeEvents) {
      setTimeout(() => {
        this.selectedCarrier = { id: 'Select', label: 'Select', value: 'Select' };
        this.toggleSave();
      });
    }
  }

  getCarrierList(state: string) {
    this.carrierList = [];
    if (state !== 'Select') {
      const filteredCarriers: ChegSetDto[] = this.stateCarrierLocalityList.filter(item => item.state === state);
      const distinctCarriers: string[] = [...new Set(filteredCarriers.map(item => item.carrier ?? ''))];
      this.carrierList = distinctCarriers.map(item => ({ id: item, label: item, value: item } as IUITKSelectItemProps));
      console.log(this.carrierList);
    }
  }

  onChangeCarrier(carrier: string): void {
    this.getLocalityList(carrier);

    if (this.enableChangeEvents) {
      setTimeout(() => {
        this.selectedLocality = { id: 'Select', label: 'Select', value: 'Select' };
        this.toggleSave();
      });
    }
  }

  getLocalityList(carrier: string) {
    this.localityList = [];
    if (carrier !== 'Select') {
      const filteredLocalities: ChegSetDto[] = this.stateCarrierLocalityList.filter(item => item.carrier === carrier);
      const distinctLocalities: string[] = [...new Set(filteredLocalities.map(item => item.locality ?? ''))];
      this.localityList = distinctLocalities.map(item => ({ id: item, label: item, value: item } as IUITKSelectItemProps));
    }
  }

  onChangeLocality(locality: string): void {
    this.selectedLocality.value = locality;
    this.toggleSave();
  }

  toggleSave(): void {
    let saveEnabled = false;

    switch (this.mainProductDto.chegSetOptionType) {
      case 'O':
      case 'N':
        saveEnabled = true;
        break;
      case 'S':
      case 'C':
      case 'L':
        if (this.selectedState.value !== 'Select' || this.selectedCarrier.value !== 'Select' || this.selectedLocality.value !== 'Select') {
          saveEnabled = true;
        }

        break;
      default:
        break;
    }

    this.enableSave.next(saveEnabled);
  }

  onChangeChegSetDiscount() {
    this._pricingService.calculateUnitPrice(this.licensePricing);
    this._pricingService.calculateExtendedPrice(this.licensePricing);
  }

  onChangeChegSetUnitPrice() {
    this._pricingService.calculateDiscount(this.licensePricing);
    this._pricingService.calculateExtendedPrice(this.licensePricing);
  }

  onChangeOrderQuantity() {
    const calculateVoidQuantity = this._orderService.orderDto.orderDetailDtos
    .filter(x => x.productCode === this.licensePricing.productCode );
    if(calculateVoidQuantity.length > 0){
      if(calculateVoidQuantity[0].openQuantity > this.licensePricing.orderQuantity){
        this.licensePricing.voidedQuantity = calculateVoidQuantity[0].voidedQuantity + (calculateVoidQuantity[0].openQuantity - this.licensePricing.orderQuantity)
      }
      else {
        this.licensePricing.voidedQuantity = calculateVoidQuantity[0].voidedQuantity;
      }
    }
    this.licensePricing.listPrice = this._pricingService.calculateListPricing(this._orderService.orderDto, this.licensePricing) || this.licensePricing.listPrice;
    this._pricingService.calculateExtendedPrice(this.licensePricing);

    if (this.licensePricing.orderQuantity <= 0) {

      let unshippeChegSetAddon = this._orderService.orderDto.orderDetailDtos

        .filter(x => x.productCode === this.licensePricing.productCode && x.shipDate === null && x.orderDetailStatus !== "V" && x.orderDetailStatus !== "C" &&
          x.parentOrderDetailId === this.orderDetail.id)[0];
      this.selectedOrderDetail = unshippeChegSetAddon;
      if (unshippeChegSetAddon && this._orderService.orderDto.orderStatus !== 'D' && this._orderService.orderDto.orderType !== 'Q' && this._orderService.orderDto.id > 0) {
        this.voidItemDialog.show = true;
      }
    }
  }
    saveChegSet(): ChegSetDto {
      let chegSetDto = new ChegSetDto();
      if (!this.orderDetail.chegSetDto) {
        chegSetDto = new ChegSetDto();
      }
      chegSetDto.state = this.selectedState.value === 'Select' ? null : this.selectedState.value;
      chegSetDto.carrier = this.selectedCarrier.value === 'Select' ? null : this.selectedCarrier.value;
      chegSetDto.locality = this.selectedLocality.value === 'Select' ? null : this.selectedLocality.value;
      chegSetDto.comments = this.comments === '' ? null : this.comments;
      this.orderDetail.isElectronic = true;
      return chegSetDto;
    }
    
  save(): boolean {
    // Save the cheg set data for the main line item
    this.orderDetail.chegSetDto = this.saveChegSet();
    this.orderDetail.isElectronic = true;

    if (this.mainProductDto.chegSetOptionType === 'O') {
      this.orderDetail.chegSetDto.optionType = 1;
    } else if (this.mainProductDto.chegSetOptionType === 'N') {
      this.orderDetail.chegSetDto.optionType = 2;
    } else {
      this.orderDetail.chegSetDto.optionType = 3;
    }

    // Save the additional users line item
    const licenseOrderDetail = this._orderService.orderDto.orderDetailDtos
    .filter(o => o.parentOrderDetailId === this.orderDetail.id && o.productId === this.licenseProduct.id && o.orderDetailStatus === ' ')[0];

    if (licenseOrderDetail && this.licensePricing.orderQuantity === 0) {
      // If we have an open item, and the license quantity is set to 0, we need to delete the existing additional users line item.
      if(this._orderService.orderDto.orderStatus === 'D' || this._orderService.orderDto.orderType === 'Q' || this._orderService.orderDto.id <= 0){
        this._orderService.deleteOrVoidOrderDetail(licenseOrderDetail);
      }
      this._orderService.recalculateFields(this._orderService.orderDto);
    } else if (this.licensePricing.orderQuantity === 0) {
      // If this is a new cheg set record, and there is no license quantity, then don't do anything.
      this._orderService.recalculateFields(this._orderService.orderDto);
    } else if (licenseOrderDetail) {
      // If they updated the usersOrderDetail, we need to update the record in the order.
      licenseOrderDetail.listPrice = this.licensePricing.listPrice;
      licenseOrderDetail.orderQuantity = this.licensePricing.orderQuantity;
      licenseOrderDetail.openQuantity = this.licensePricing.orderQuantity - licenseOrderDetail.shippedQuantity;
      licenseOrderDetail.discount = this.licensePricing.discount;
      licenseOrderDetail.extendedPrice = this.licensePricing.extendedPrice;
      licenseOrderDetail.unitPrice = this.licensePricing.unitPrice;
      this._orderService.recalculateFields(this._orderService.orderDto);
    } else {
      // If there isn't an existing open order detail record on the order, then add one.
      const newOrderDetailDto: OrderDetailDto = this._orderService.createNewOrderDetail(this._orderService.orderDto, this.licenseProduct.productCode);
      newOrderDetailDto.listPrice = this.licensePricing.listPrice;
      newOrderDetailDto.orderQuantity = this.licensePricing.orderQuantity;
      newOrderDetailDto.openQuantity = this.licensePricing.orderQuantity;
      newOrderDetailDto.discount = this.licensePricing.discount;
      newOrderDetailDto.extendedPrice = this.licensePricing.extendedPrice;
      newOrderDetailDto.unitPrice = this.licensePricing.unitPrice;
      newOrderDetailDto.isElectronic = true;
      newOrderDetailDto.parentOrderDetailId = this.orderDetail.id;

      const noOfaddOns = this._orderService.orderDto.orderDetailDtos.filter(item => item.parentOrderDetailId === this.orderDetail.id).length;
      const index = this._orderService.orderDto.orderDetailDtos.findIndex(item => item === this.orderDetail);
      this._orderService.orderDto.orderDetailDtos.splice(index + 1 + noOfaddOns, 0, newOrderDetailDto);
      this._orderService.recalculateFields(this._orderService.orderDto);
      this._orderService.orderDto.orderDetailDtos = [...this._orderService.orderDto.orderDetailDtos];
    }

    return true;
  }
  onVoidLineItemDialogCancel()
  {
    let unshippedAddon = this._orderService.orderDto.orderDetailDtos

    .filter(x => x.productCode === this.selectedOrderDetail.productCode && x.shipDate === null && x.orderDetailStatus !== "V" && x.orderDetailStatus !== "C" &&
    x.parentOrderDetailId === this.orderDetail.id)[0];
    if(unshippedAddon && unshippedAddon.orderDetailStatus === ' ')
    {
      this.licensePricing.orderQuantity = this.selectedOrderDetail.orderQuantity;
    }
  }
  onVoidLineItemCancel()
  {
    this._orderService.orderDto.orderDetailDtos=this.orderDetailDtoBackup;
    this._orderService.orderDto.orderNotes = this.orderNotesDtoBackUp;
  }
}
