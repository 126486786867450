import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Mode } from '../../../../enums/mode.enum';
import { OrderDetailDto } from '../../../../dtos/order-detail-dto.model';
import { OrderDto } from '../../../../dtos/order-dto.model';
import { OrderNoteDto } from '../../../../dtos/order-note-dto.model';
import { ConfigService } from '../../../../services/config.service';
import { ModeService } from '../../../../services/mode.service';
import { OrderService } from '../../../../services/order.service';
import { ReservationDto } from 'src/app/dtos/reservation-dto.model';
import { DepositDto } from 'src/app/dtos/deposits-dto.model';
import { AccountingService } from 'src/app/services/accounting.service';

@Component({
  selector: 'app-void-order',
  templateUrl: './void-order.component.html',
  styleUrls: ['./void-order.component.css']
})

export class VoidOrderComponent implements OnInit {
  private readonly _configService: ConfigService;
  @ViewChild("depositDialogOk") depositDialogOk!: ElementRef<HTMLInputElement>;
  @ViewChild("authorizationDialogOk") authorizationDialogOk!: ElementRef<HTMLInputElement>;
  @Input() dialog = { show: false };
  Mode = Mode;
  voidOrderValue: any;
  voidReason: any;
  voidProductCode: string = "";
  showVoidModalError = false;
  visibleTextValue = this.modeService.mode === Mode.Wait ? "" : "Saving Data";
  voidOrderList: IUITKSelectItemProps[] = [];
  orderIsReadySubscription: Subscription | undefined;
  voidDepositDialogModal = {
    show: false,
  };
  voidAuthorizedDialogModal = {
    show: false,
  };
  isAnyShipped = false;

  constructor(public modeService: ModeService, configService: ConfigService, public orderService: OrderService, private readonly accountingService:AccountingService) {
    this._configService = configService;
  }

  ngOnInit(): void {
    this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
      this.voidReason = '';
      this.updateVoidProductCode();
      this.isAnyShipped = this.orderService.orderDto.orderDetailDtos.some(a => a.shipDate);
    });
    this.isAnyShipped = this.orderService.orderDto.orderDetailDtos.some(a => a.shipDate);

    // The following code was in the showVoidOrderDialog method
    this.updateVoidProductCode();
    if (this.orderService.orderDto.orderType === ' ' || this.orderService.orderDto.orderType === 'Q') {
    // this.orderService.triggerEditMethod();
    } else {
      this.orderService.triggerEditMethodOnReturn();
    }
    this.voidDepositDialogModal.show = this.orderService.orderDto.orderDetailDtos.some(element =>element.productCode==='0010' && element.orderDetailStatus===' ');

    if(this.voidDepositDialogModal.show)
    {
      return;
    }

    if (this.orderService.orderDto.ccAuthorizationCode === 'A') {
      this.voidAuthorizedDialogModal.show = true;
      setTimeout(() => {
        this.authorizationDialogOk.nativeElement.focus();
      }, 0);
      return;
    }
  }

  updateVoidProductCode(){
    if(this.orderService.orderDto.orderType ==='R')
    {
      this.voidOrderList = this._configService.getPickList("RVOIDREASON");
    }
    else
    {
      this.voidOrderList = this._configService.getPickList("VOIDREASON");
    }

    this.voidProductCode = Array.prototype.map.call(this.orderService.orderDto.orderDetailDtos
      .filter(o => o.orderDetailStatus === ' '), function (item) {
        return item.productCode;
      }).join(", ");
  }

  voidOnChange() {
    this.showVoidModalError = false;
  }

  async saveVoidOrder() {
    debugger;
    if (this.modeService.mode === Mode.View) {
      this.orderService.orderDtoBackup = JSON.parse(JSON.stringify(this.orderService.orderDto)) as OrderDto;
    }
    this.orderService.orderDto.orderDetailDtos=this.orderService.orderDto.orderDetailDtos.filter(a=>a.productCode);
    // Apply deposits 
    this.desposits();
    if (this.voidOrderValue.value === null) {
      this.showVoidModalError = true;
    }
    else {
      this.checklineItemStatus();

    }
  }
  checklineItemStatus() {
    const newOrderDetail: OrderDetailDto[] = [];
    this.orderService.orderDto.orderDetailDtos.forEach(orderDetail => {
      let tempOpenQuantity=orderDetail.openQuantity;
      let tempShippedQuantity=orderDetail.shippedQuantity;
      if(this.orderService.orderDto.orderType === "R")
      {
         tempOpenQuantity=orderDetail.openQuantity*-1;
         tempShippedQuantity=orderDetail.shippedQuantity*-1;
      }
      this.checkOrderStatusIsEmpty(orderDetail,tempShippedQuantity,tempOpenQuantity,newOrderDetail);        

    });
    newOrderDetail.forEach(item => {
      item.id = 0;
      item.voidedQuantity = item.openQuantity;
      item.openQuantity = 0;
      item.shippedQuantity = 0;
      item.extendedPrice = 0;
      item.taxAmount = 0;
      item.taxRate = 0;
      item.orderDetailStatus = 'V';
      item.shipDate = null;
      item.voidDate = new Date(Date.parse(Date()));
      item.voidReasonCode = this.voidOrderValue.value;
      item.orderQuantity = item.openQuantity + item.shippedQuantity;
      this.orderService.orderDto.orderDetailDtos.push(item);
    });
    const lineItemStatus = this.orderService.orderDto.orderDetailDtos.map(item => item.orderDetailStatus)
      .filter((value, index, self) => self.indexOf(value) === index);

    this.orderService.orderDto.orderStatus = 'C';
     if (lineItemStatus.length === 1 && lineItemStatus[0] === 'V') {
       this.orderService.orderDto.orderStatus = 'V';
       this.orderService.orderDto.voidReasonCode = this.voidOrderValue.value;
       this.orderService.orderDto.voidDate = new Date(Date.parse(Date()));
     }
    
    this.orderService.orderDto.holdReasonCode = null;
    this.orderService.orderDto.openAmount = 0;

    const systemNote = new OrderNoteDto();
    systemNote.id = -1;
    systemNote.createdDate = new Date();
    systemNote.userCode = this._configService.getMyUserCode();
    systemNote.userName = this._configService.getMyFullName();
    systemNote.orderId = this.orderService.orderDto.id;
    systemNote.noteType = "VO";
    systemNote.systemNote="Voided Items " + this.voidProductCode;
    systemNote.noteText= this.voidOrderValue.label+". "+(this.voidReason?this.voidReason:"");
    this.SaveVoidContinuePart(systemNote);
  }
  desposits() {
    const depositDtos: DepositDto[] = [];
    const distinctOtis: string[] = this.orderService.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus === ' ' && item.oti).map(item => item.oti ?? '');
    
    for (const oti of distinctOtis) {
      const newOrderDetailDto = this.orderService.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus === ' ' && item.oti === oti)[0];

      const unreservationDto = new DepositDto();
      unreservationDto.accountId = this.orderService.orderDto.billToDto.id;
      unreservationDto.orderId = this.orderService.orderDto.id;
      unreservationDto.userCode = 'AIMP';
      unreservationDto.reservedAmount = Math.abs(newOrderDetailDto.listPrice);
      unreservationDto.oti = oti;
      unreservationDto.action = 'Unreservation';
      depositDtos.push(unreservationDto);
    }

    if (depositDtos.length > 0) {
      this.accountingService.applyDeposits(depositDtos);
    }
  }

  checkOrderStatusIsEmpty(orderDetail:OrderDetailDto,tempShippedQuantity:any,tempOpenQuantity:any,newOrderDetail:OrderDetailDto[]){
    if (orderDetail.orderDetailStatus === ' ' && orderDetail.shipDate === null) {
      orderDetail.orderDetailStatus = 'V';
      orderDetail.voidedQuantity = orderDetail.openQuantity;
      orderDetail.openQuantity = 0;
      orderDetail.voidDate = new Date(Date.parse(Date()));
      orderDetail.voidReasonCode = this.voidOrderValue.value;
      orderDetail.extendedPrice = 0;
      orderDetail.taxAmount = 0;
      orderDetail.taxRate = 0;
      orderDetail.orderQuantity = orderDetail.openQuantity + orderDetail.shippedQuantity;
      if (orderDetail.subscriptionDto !== null && 'A,W,F,S'.indexOf(orderDetail.subscriptionDto.subscriptionStatus) > -1) {
        orderDetail.subscriptionDto.subscriptionStatus = 'V';
      }
    }
    else if (orderDetail.orderDetailStatus === ' ' && orderDetail.shipDate !== null && tempOpenQuantity > 0) {
     this.fillOrderDetailObject(orderDetail,tempShippedQuantity,newOrderDetail);
    }
  }
  SaveVoidContinuePart(systemNote:OrderNoteDto){
    if(this.orderService.orderDto.orderNotes ==  null)
    {
      const orderNotes: OrderNoteDto[]=[];
      orderNotes.push(systemNote)
      this.orderService.orderDto.orderNotes=orderNotes;
    }
    else
    {
      this.orderService.orderDto.orderNotes.push(systemNote);
    }
    this.dialog.show = false;
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];

    if (this._configService.orderEntryFeature && ( this.orderService.orderDto.orderType === "E")) {
      this.orderService.triggerSaveMethod(true);
    }
    else {
      this.orderService.saveOrderToDatabase(this.orderService.orderDto);
    }
  }

  fillOrderDetailObject(orderDetail:OrderDetailDto,tempShippedQuantity:any,newOrderDetail:OrderDetailDto[]){
    if (tempShippedQuantity > 0) {
      newOrderDetail.push(Object.assign({}, orderDetail));
      orderDetail.orderDetailStatus = 'C';
      orderDetail.openQuantity = 0;
      orderDetail.orderQuantity = orderDetail.openQuantity + orderDetail.shippedQuantity;
      const extendedPrice = (((orderDetail.shippedQuantity + orderDetail.openQuantity) * orderDetail.listPrice * (100 - orderDetail.discount)) / 100).toFixed(2);
      orderDetail.extendedPrice = parseFloat(extendedPrice);
    }
    else {
      orderDetail.orderDetailStatus = 'V';
      orderDetail.voidedQuantity = orderDetail.openQuantity;
      orderDetail.openQuantity = 0;
      orderDetail.voidDate = new Date(Date.parse(Date()));
      orderDetail.voidReasonCode = this.voidOrderValue.value;
      orderDetail.extendedPrice = 0;
      orderDetail.taxAmount = 0;
      orderDetail.taxRate = 0;
      orderDetail.orderQuantity = orderDetail.openQuantity + orderDetail.shippedQuantity;
    }
  }

  closeVoidOrderDialog() {
    this.dialog.show = false;
    this.voidReason = '';
    this.voidOrderValue = null;
    this.showVoidModalError = false;
    if (this.orderService.editWasTriggered || this.orderService.returnEditWasTriggered) {
      if (this.orderService.orderDto.orderType === ' ' || this.orderService.orderDto.orderType === 'Q') {
        this.orderService.triggerRevertMethod();
      } else {
        this.orderService.triggerRevertMethodOnReturn();
      }
    }
  }
  closeVoidDepositDialog(): void{
    if(this.voidAuthorizedDialogModal.show===true){
      this.voidAuthorizedDialogModal.show = false;
      this.dialog.show = true;

    }
    if(this.voidDepositDialogModal.show===true){
      this.voidDepositDialogModal.show = false;
      if (this.orderService.orderDto.ccAuthorizationCode === 'A') {
        this.voidAuthorizedDialogModal.show = true;
        setTimeout(() => {
          this.authorizationDialogOk.nativeElement.focus();
        }, 0);
      }
      else
      {
        this.dialog.show = true;
      }
    }
  }
}
