import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReturnInquiryComponent } from '../return-inquiry/return-inquiry.component';
import { BatchReturnsInProcessDto } from '../../dtos/batch-returns-in-process-dto.model';
import { OrderDto } from "../../dtos/order-dto.model";
import { AuthenticationService } from '../../services/authentication.service';
import { BatchReturnService } from '../../services/batch-return.service';
import { ConfigService } from "../../services/config.service";
import { ModeService } from '../../services/mode.service';
import { OrderService } from '../../services/order.service';
import { SwalAlert } from '../../helpers/alert';

@Component({
  selector: 'app-batch-returns',
  templateUrl: './batch-returns.component.html',
  styleUrls: ['./batch-returns.component.css']
})

export class BatchReturnsComponent implements OnInit {
  private readonly _configService: ConfigService;
  private readonly _batchReturnService: BatchReturnService;
  private readonly _authenticationService: AuthenticationService;
  private readonly _router: Router;
  @ViewChild(ReturnInquiryComponent) returnInquiryComponent: ReturnInquiryComponent | undefined;
  normalDialogModal = { show: false, };
  warningDailogDiv = false;
  dailogContentMsg = '';
  totalCount = 0
  totalAmount = 0;
  batchReturn = true;
  returnDtoListWithAuthorization: OrderDto[] = [];
  returnsFound: OrderDto[] = [];
  orderNumber: number | null = null;
  returnNumber: number | null = null;
  isReturnAvailable = false;
  isnavigateFromBatchReturn = true;
  tableHeader = [
    'Return #',
    'Amount', '',
  ];
  returnIsReadySubscription: Subscription | undefined;
  orderIsReadySubscription: Subscription | undefined;
  configIsReadySubscription: Subscription | undefined;
  batchReturnsInProcessDtos: BatchReturnsInProcessDto[] = [];
  enableReturnInquirySection: boolean = false;
  swalAlert = new SwalAlert();

  constructor(
    public orderService: OrderService,
    configService: ConfigService,
    batchReturnService: BatchReturnService,
    authenticationService: AuthenticationService,
    public modeService: ModeService,
    router: Router
  ) {
    this._configService = configService;
    this._batchReturnService = batchReturnService;
    this._authenticationService = authenticationService;
    this._router = router;
  }

  ngOnInit(): void {
    this.batchReturn = true;
    this.isnavigateFromBatchReturn = true;

    this.configIsReadySubscription = this._configService.configIsReady.subscribe(() => {
      this.getAllBatchReturns();
    });

    this._configService.loadConfigurations();
  }

  searchOrder(): void {
    this._configService.resetIdleTimeout();
    if (!this.orderNumber) {
      return;
    }

    this.isReturnAvailable = false;
    this.enableReturnInquirySection = false;
    this.returnIsReadySubscription?.unsubscribe();
    this.orderService.getReturnInformation(this.orderNumber);
    this.returnIsReadySubscription = this.orderService.returnIsReady.subscribe(() => {
      this.returnsFound = [];
      this.returnDtoListWithAuthorization = [...this.orderService.returnDtos];
      this.returnDtoListWithAuthorization.forEach(element => {
          this.returnsFound.push(element);
          element.disableAddReturnToBatchButton = this.orderService.getAddReturnToBatchButtonDisabled(element)
      });
      this.isReturnAvailable = true;
      if (this.returnsFound.length === 0) {
        this._router.navigate([]).then(() => { 
          window.open('/order/' + this.orderNumber, '_blank'); 
        });
      }
    });

  }

  onSelectedReturn(id: number): void {
    this.orderService.getOrderInformation(id);
    this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
    setTimeout(()=>{
      this.returnInquiryComponent?.getOrderData();
    });
    this.orderService.orderDto.disableAddReturnToBatchButton =  this.orderService.getAddReturnToBatchButtonDisabled(this.orderService.orderDto);
    this.enableReturnInquirySection = true;
    });
  }



  searchReturn(): void {
    this._configService.resetIdleTimeout();
    if (!this.returnNumber) {
      return;
    }

    this.isReturnAvailable = false;
    this.enableReturnInquirySection = false;
    this.orderService.getOrderInformation(this.returnNumber);
    this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
      if (this.orderService.orderDto.orderType === ' ') {
        this.dailogContentMsg = 'Order numbers cannot be entered into return # search';
        this.warningDailogDiv = true;
        this.normalDialogModal.show = true;
        this.enableReturnInquirySection = false;
        return;
      }
      if (this.orderService.orderDto.orderType === 'E') {
        this.dailogContentMsg = 'Exceptions cannot be entered in return # search';
        this.warningDailogDiv = true;
        this.normalDialogModal.show = true;
        this.enableReturnInquirySection = false;
        return;
      }
      if (this.orderService.orderDto.orderType === 'Q') {
        this.dailogContentMsg = 'Quotes cannot be entered in return # search';
        this.warningDailogDiv = true;
        this.normalDialogModal.show = true;
        this.enableReturnInquirySection = false;
        return;
      }

      setTimeout(()=>{
        this.returnInquiryComponent?.getOrderData();

      });
      this.orderService.orderDto.disableAddReturnToBatchButton =  this.orderService.getAddReturnToBatchButtonDisabled(this.orderService.orderDto);
      this.enableReturnInquirySection = true;
    });

  }

  clearOrder(): void {
    this.orderNumber = null;
    this.returnsFound = [];
  }

  clearReturn(): void {
    this.returnNumber = null;
  }

  getAllBatchReturns(): void {
    const currentUser = this._configService.getMyMSId();

    this._batchReturnService.getAllBatchReturn(currentUser).subscribe((result: BatchReturnsInProcessDto[]) => {
      this.batchReturnsInProcessDtos = result;
      this.recalculateAmount();
    }, error => {
      console.log(error);
      this.swalAlert.alert('An error occurred while getting batchreturn service details. Please try again.');
    });
  }

  deleteBatchReturns(id: number): void {
    this._batchReturnService.deleteBatchReturn(id).subscribe(() => {
      this.getAllBatchReturns();
    }, error => {
      console.log(error);
      this.swalAlert.alert('batch return service details. Please try again.');
    });
  }

  deleteAllBatchReturns(): void {
    const currentUser = this._configService.getMyMSId();
    this._batchReturnService.deleteAllBatchReturn(currentUser).subscribe(() => {
      this.getAllBatchReturns();
    }, error => {
      console.log(error);
      this.swalAlert.alert('batch return service details. Please try again.');
    });
  }

  processBatchReturns(): void {
    const currentUser = this._configService.getMyMSId();

    this._batchReturnService.processBatchReturns(currentUser)
      .subscribe(() => {
        this.batchReturnsInProcessDtos = [];
        this.totalCount = 0;
        this.totalAmount = 0;
        this.clearReturn();
        this.clearOrder();
        this.enableReturnInquirySection = false;
        this.dailogContentMsg = 'Batch has been processed successfully';
        this.warningDailogDiv = true;
        this.normalDialogModal.show = true;


      }, error => {
        console.log(error);
        this.dailogContentMsg = 'Batch failed to process';
        this.warningDailogDiv = true;
        this.normalDialogModal.show = true;
      });
  }

  recalculateAmount() {
    this.totalCount = this.batchReturnsInProcessDtos.length;
    this.totalAmount = this.batchReturnsInProcessDtos.reduce((sum, current) => sum + current.amount, 0)
  }

  onAddRecordToBatch(returnDto: OrderDto) {
    const checkAlreadyExist = this.batchReturnsInProcessDtos.some(i => i.returnId === returnDto.id);

    if (checkAlreadyExist) {
      this.dailogContentMsg = 'Return already exists in batch.';
      this.warningDailogDiv = true;
      this.normalDialogModal.show = true;
      return;
    }

    if (returnDto.inUseBy) {
      this.swalAlert.alert('Return is in use by another user.');
      return;
    }

    if (returnDto.pendingDate) {
      this.swalAlert.alert('Return is printed and pending.');
      return;
    }

    const batchReturnsInProcessDto: BatchReturnsInProcessDto = {
      id: 0,
      returnId: returnDto.id,
      amount: returnDto.openAmount,
    };

    const currentUser = this._configService.getMyMSId();

    this._batchReturnService.addToBatchReturn(batchReturnsInProcessDto, currentUser).subscribe((data) => {
      this.batchReturnsInProcessDtos.push(data);
      this.batchReturnsInProcessDtos = [...this.batchReturnsInProcessDtos];
      this.recalculateAmount();
    }, error => {
      if (error && error.error.Message === 'An error occurred while updating the entries. See the inner exception for details.') {
        this.swalAlert.alert("Return is already in a batch.");
      } else {
        this.swalAlert.alert("Unable to add record to batch.");
      }
      console.log(error);
    });

  }

  ngOnDestroy(): void {
    this.configIsReadySubscription?.unsubscribe();
    this.orderIsReadySubscription?.unsubscribe();
    this.returnIsReadySubscription?.unsubscribe();
  }
}
