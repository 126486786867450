<div class = "overlayForPopups" *ngIf="dialog.show">
    <div cdkDrag>
<uitk-dialog uitkid='sendemail' [(showModal)]="dialog.show" triggerElementId="sendemailbtn" id="emailcontainer" [closeBtn]=false>
    <uitk-dialog-header *ngIf="!isEmailSent">
        <div cdkDragHandle>
            Send Email
        </div>
    </uitk-dialog-header>
    <uitk-dialog-content *ngIf="!isEmailSent" id="divheader">
        <p id="dialogHeader">{{ dialogueheader }}</p>
        <div class="uitk-l-grid__row">
            <div class="uitk-l-grid__col">
                <form [formGroup]="profileForm">
                    <uitk-form-field>
                        <uitk-fieldset>
                            <uitk-radio-group uitkId="my-basic-radio-group" formControlName="radioGroup"
                                [itemList]="items" [(ngModel)]="myRadioGroupSelecteditem"  (change)= "clearall()">
                            </uitk-radio-group>
                        </uitk-fieldset>
                    </uitk-form-field>
                </form>
            </div>
        </div>
        <p id="sendEmailTo">Send Email To?</p>
        <div class="uitk-l-grid__row" style="padding-left: 18px;">
            <div class="uitk-l-grid__col--4">
                <uitk-form-field>
                    <input uitkCheckbox id="orderBy" type="checkbox" appEnableResource="OrderInquiryAdditionalActionsSendEmailOrderByContactCheckboxButton" [(ngModel)]="orderBycheckbox" (change)= "changeEmail()"/>
                    <label uitkCheckboxLabel id="emailInfoLabel" for="orderBy">
                      Order By Contact
                    </label>
                  </uitk-form-field>
            </div>
            <div class="uitk-l-grid__col--4" style="margin-left: -10px;">
                <uitk-form-field>
                    <input uitkCheckbox id="shipTo" type="checkbox" appEnableResource="OrderInquiryAdditionalActionsSendEmailShiptoContactCheckboxButton" [(ngModel)]="shipTocheckbox" (change)= "changeEmail()"/>
                    <label uitkCheckboxLabel id="emailInfoLabel" for="shipTo">
                      Ship To Contact
                    </label>
                  </uitk-form-field>
            </div>
            <div class="uitk-l-grid__col--4" style="margin-left: -10px;">
                <uitk-form-field>
                    <input uitkCheckbox id="loggedInUser" type="checkbox" [(ngModel)]="LoggedInUsercheckbox" (change)= "changeEmail()"/>
                    <label uitkCheckboxLabel id="emailInfoLabel" >
                        Logged in User
                    </label>
                  </uitk-form-field>
            </div>
            <div class="uitk-l-grid__col--4">
            </div>
        </div>
<div class="uitk-l-grid__row ">
    <div class="uitk-l-grid__col--1">
        </div>
        <div class="uitk-l-grid__col--10">
            <label id="emailmsg">Multiple emails are separated by a semicolon (;)</label>
        </div>
</div>
        <div class="uitk-l-grid__row ">
            <div class="uitk-l-grid__col--1">
            <label uitkLabel id="labelid">Email </label>
            </div>
            <div class="uitk-l-grid__col--10">
              <div  *ngIf="userEmail.errors || !this.email" class="ng-tns-c54-2 uitk-c-notification__container uitk-c-notification__container--error ng-trigger ng-trigger-fadeInOut ng-star-inserted">
                <div class="uitk-c-notification__content-container ng-tns-c54-2">
                  <div class="uitk-c-notification__icon ng-tns-c54-2">
                    <span class="ng-tns-c54-1 uitk-icon uitk-icon-info_filled ng-star-inserted"></span>
                  </div>
                  <div class="uitk-c-notification__content ng-tns-c54-2" id="warningDemoId_content">
                  <div>
                    <span *ngIf="!this.email" class="ng-tns-c54-2"> required</span>
                    <span *ngIf="userEmail.errors" class="ng-tns-c54-2"> Invalid Email</span>
                  </div>
                </div>
              </div>
              </div>

                <uitk-form-field>
                    <input uitkInput id="email" #userEmail="ngModel" class="textbox" type="email" appEnableResource="OrderInquiryAdditionalActionsSendEmailEmailButton" [pattern]="emailPattern" value=""  [(ngModel)]="email"/>
                </uitk-form-field>
            </div>
        </div>
        <div *ngIf="showErrorMsg" class="ng-tns-c54-2 uitk-c-notification__container uitk-c-notification__container--error ng-trigger ng-trigger-fadeInOut ng-star-inserted">
          <div class="uitk-c-notification__content-container ng-tns-c54-2">
            <div class="uitk-c-notification__icon ng-tns-c54-2">
              <span class="ng-tns-c54-1 uitk-icon uitk-icon-info_filled ng-star-inserted"></span><!----><!---->
            </div>
            <div class="uitk-c-notification__content ng-tns-c54-2" id="warningDemoId_content">
            <div>
              <span class="ng-tns-c54-2"> Please make a selection.</span>
            </div>
          </div>
        </div>
        </div>
        <div
            *ngIf="myRadioGroupSelecteditem?.value=='ShipmentConfirmation'||myRadioGroupSelecteditem?.value=='ProductActivation'">
            <div class="uitk-c-table--responsive" id="itemsgrid">
                <table  *ngIf="myRadioGroupSelecteditem?.value=='ShipmentConfirmation'" uitkTable [dataSource]="shippingitemgridData" id="basic-table" class="uitk-c-table"
                    aria-describedby="shipToAdd-data">
                    <thead class="uitk-c-table__head">
                       <ng-container *ngIf="myRadioGroupSelecteditem?.value=='ShipmentConfirmation'" >
                            <tr>
                                <th  scope="col" >
                                <input type="checkbox" name="checkbox" [(ngModel)]="shipmentSelectedAll" id="shipmentselectall"
                                    (change)="selectAll();" />
                                </th>
                                <th *ngFor="let headerName of shiptoheader" scope="col" class="aligntext">
                                    {{ headerName}}
                                </th>
                            </tr>
                     </ng-container>
                    </thead>

                    <tbody id="itemgrid-data" class="uitk-c-table__body">
                        <ng-container *uitkTableRowBody="let itemgrid">
                            <tr class="uitk-c-table__row">
                                <td class="aligntext">
                                    <input type="checkbox" name="checkbox" [checked]="itemgrid.checked"
                                        id="shipmentgridcheckbox" (change)="itemgrid.checked=!itemgrid.checked;shipmentSelectedAll=false" />
                                </td>
                                <td class="aligntext">{{itemgrid.item}}</td>
                                <td class="aligntext">{{itemgrid.description}}</td>

                                <td class="aligntext" *ngIf="myRadioGroupSelecteditem?.value=='ShipmentConfirmation'">
                                    {{itemgrid.shiptoDate | date: 'MM/dd/yyyy'}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <table *ngIf="myRadioGroupSelecteditem?.value=='ProductActivation' && this.contactGridData.length > 0" uitkTable [dataSource]="contactGridData" id="multipleContact-table" class="uitk-c-table"
                aria-describedby="shipToAdd-data">
                <thead class="uitk-c-table__head">
                    <ng-container *ngIf="myRadioGroupSelecteditem?.value=='ProductActivation'" >
                        <tr>
                            <th scope="col">
                            <input type="checkbox" name="checkbox" [(ngModel)]="contactSelectedAll" id="selectall"
                                (change)="contactSelectAll();" />
                            </th>
                            <th *ngFor="let headerName of multipleContactsHeader" scope="col" class="aligntext">
                                {{ headerName}}
                            </th>
                        </tr>
                </ng-container>
                </thead>
                <tbody id="contact-data" class="uitk-c-table__body">
                    <ng-container *uitkTableRowBody="let contact">
                        <tr class="uitk-c-table__row">
                            <td class="aligntext">
                                <input type="checkbox" name="checkbox" [checked]="contact.isSelected"
                                    id="gridcheckbox" (change)="contact.isSelected=!contact.isSelected;contactSelectedAll=false; addSelectedEmail(contact.email,contact.isSelected)" />
                            </td>
                            <td class="aligntext">{{contact.contactId}}</td>
                            <td class="aligntext">{{contact.firstName}} {{contact.lastName}}</td>
                            <td class="aligntext">{{contact.email}}</td>
                        </tr>
                    </ng-container>
                </tbody>
                </table>

            </div>
            <br/>
            <div class="uitk-c-table--responsive" id="itemsgrid2">
            <table *ngIf="myRadioGroupSelecteditem?.value=='ProductActivation'" uitkTable [dataSource]="productitemgridData" id="productactivation-table" class="uitk-c-table"
                aria-describedby="shipToAdd-data">
                <thead class="uitk-c-table__head">
                    <ng-container *ngIf="myRadioGroupSelecteditem?.value=='ProductActivation'" >
                        <tr>
                            <th scope="col">
                            <input type="checkbox" name="checkbox" [(ngModel)]="productSelectedAll" id="selectall"
                                (change)="productselectAll();" />
                            </th>
                            <th *ngFor="let headerName of productheader" scope="col" class="aligntext">
                                {{ headerName}}
                            </th>
                        </tr>
                </ng-container>
                </thead>
                <tbody id="itemgrid-data" class="uitk-c-table__body">
                    <ng-container *uitkTableRowBody="let itemgrid">
                        <tr class="uitk-c-table__row">
                            <td class="aligntext">
                                <input type="checkbox" name="checkbox" [checked]="itemgrid.checked"
                                    id="gridcheckbox" (change)="itemgrid.checked=!itemgrid.checked;productSelectedAll=false" />
                            </td>
                            <td class="aligntext">{{itemgrid.item}}</td>
                            <td class="aligntext">{{itemgrid.description}}</td>
                        </tr>
                    </ng-container>
                </tbody>
                </table>
              </div>
        </div>
    </uitk-dialog-content>

    <uitk-dialog-actions *ngIf="!isEmailSent">
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" appEnableResource="OrderInquiryAdditionalActionsSendEmailSendButton" (click)="saveemail()" appAutoFocus>
            {{ sendButtonText }}
        </button>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" appEnableResource="OrderInquiryAdditionalActionsSendEmailCancelButton" (click)="closeDialog()">
            {{ cancelButtonText }}
        </button>
    </uitk-dialog-actions>
   </uitk-dialog>
<div *ngIf="isEmailSent">
  <uitk-dialog [(showModal)]="sentEmailDialogModal.show" [closeBtn]=false style="width:fit-content;">
    <uitk-dialog-header>
      Success <span class="uitk-icon uitk-icon-checkmark_filled md-icon"></span>
    </uitk-dialog-header>
    <uitk-dialog-content>
      <p style="padding: 10px;">Email has been sent.</p>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button id="okButton" class="uitk-c-button uitk-c-button--default uitk-u-margin--xs" (click)="okDialog()" autofocus>
        Ok
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
        </div>
    </div>
</div>

