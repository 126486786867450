import { IUITKSelectItemProps } from "@uitk/angular";

export class ShipToDto
{
  id = 0;
  contactId = 0;
  firstName = '';
  lastName = '';
  company = '';
  department = '';
  street = '';
  city = '';
  state = '';
  zip = '';
  country = '';
  allowInformationalEmails = false;
  allowPromotionalEmails = false;
  email = '';
  phone = '';
  salesforceId = '';
  defaultSendRenewalNotices = false;
  statusCode = '';
  defaultPaymentMethod: string | null = '';
  title = '';
  middleInitial = '';
  statusCodeDescription = '';
  confirmationEmails: string | null = null;

  // These properties are only used for UI Binding
  stateBinding: IUITKSelectItemProps | null = null;
  orderByshipBinding: IUITKSelectItemProps|null=null;
}
