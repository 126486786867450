import { DataFileDtos } from './datafile-dto.model';
import { FeeAnalyzerDto } from './fee-analyzer-dto.model';
import { SubscriptionDto } from './subscription-dto.model';
import { ChegSetDto } from './cheg-set-dto';
import { CfaWebDto } from './cfa-web-dto.model';
import { ProductDto } from './product-dto.model';
import { CptDto } from './cpt-dto.model';

export class OrderDetailDto {
  id = 0;
  productId = 0;
  warehouseId = 1;
  parentOrderDetailId: number | null = null;
  parentOrderDetailProductCode: string | null = null;
  originalOrderDetailId: number | null = null;
  orderDetailStatus = ' ';
  lastOrderDetailStatus = ' ';
  printVendorCost = 0;
  floorPrice = 0;
  voidDate: Date | null = null;
  voidReasonCode: string | null = null;
  orderId = 0;
  productCode = '';
  shipDate: Date | null = null;
  openQuantity: number = 0;
  shippedQuantity = 0;
  voidedQuantity = 0;
  returnedQuantity = 0;
  pendingQuantity: number | null = null;
  productDescription = '';
  discount: number = 0;
  listPrice = 0;
  taxRate = 0;
  taxAmount = 0;
  requiredDate = new Date(new Date().toDateString());
  enteredDate = new Date();
  accountOwnerCode: string | null = null;
  enteredBy = 'OMA';
  trackingNumber: string | null = null;
  salespersonCode: string | null = null;
  isWebFulfilled = false;
  glCode: string | null = null;
  subscriptionDto: SubscriptionDto | null = null;
  feeAnalyzerDto: FeeAnalyzerDto | null = null;
  cfaWebDtos: CfaWebDto[] = [];
  chegSetDto: ChegSetDto | null = null;
  dataFileDtos = new DataFileDtos();
  cptDto: CptDto | null = null;
  productDto = new ProductDto();
  isElectronic = false;
  isPhysical = false;
  classCode: string | null = null;
  oti: string | null = null;
  referenceNumber: string | null = null;
  configSetExist = false;
  pendingDate: Date | null = null;
  medicaidState: string | null = null;
  isMaxParentQuantity: boolean = false;
  isLicense: boolean = false;
  isMaxQuantityOne: boolean = false;
  batchId = 0;
  modifiedDate = new Date();
  promotionEntryCode: string | null = null;
  promotionOrderDetailId: number | null = null;

  // These fields are calculated fields and only used for binding
  extendedPrice = 0;
  unitPrice = 0;
  orderQuantity = 0;
  configSetSaved = false;
  itemEditable = false;
  itemDisable = false;
  isLineItemChecked = false;
  invoiceNumber = '';
  balance = 0;
  isSelected = false;
  disableQuantity = true;
  originalOrderDetailDto: OrderDetailDto | null = null;
  voidReasonDescription: string | null = null;
  shipAndProcessQuantity = 0;
  salespersonFullName: string | null = null;
  accountOwnerFullName: string | null = null;
  allowPaymentPlan = false;
  priceChangeProductId = 0;
  hasDeleteAccess = false;
  hasGearAccess = false;
  hasNonDepositDeleteIconAccess = false;
  hasDepositDeleteIconAccess = false;
  hasNonDepositGearIconAccess = false;
  hasDepositGearIconAccess = false;
  packingSlipShipQuantity = 0;
  packingSlipBackorderQuantity = 0;
  discountBeforeApplyingPromoOffer = 0;
  configSetButton = '';
  isEligibleAllowed = false;
  eligibleAllowedPercentage = 0;
  allowedPrice = 0;
  discountType = '';
  isSubscriptionRenewedOnDifferentOrder = false;
}
