import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { DataFileDto } from 'src/app/dtos/datafile-dto.model';
import { WebAppDto } from 'src/app/dtos/web-app-dto.model';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})

export class ConfigurationService {
  private readonly _httpClient: HttpClient;
  private readonly _configService: ConfigService;
  webAppAddOnsCache!: WebAppDto[] | null;
  webAppAddOnsCachedObservable!: Observable<WebAppDto[]> | null;
  dataFileAddOnsCache!: DataFileDto[] | null;
  dataFileAddOnsCachedObservable!: Observable<DataFileDto[]> | null;

  constructor(httpClient: HttpClient, configService: ConfigService) {
    this._httpClient = httpClient;
    this._configService = configService;
  }

  getWebAppAddons(productId: number) {
    let observable: Observable<WebAppDto[]>;

    if (this.webAppAddOnsCache) {
      observable = of(this.webAppAddOnsCache);
    }
    else if (this.webAppAddOnsCachedObservable) {
      observable = this.webAppAddOnsCachedObservable;
    }
    else {
      this.webAppAddOnsCachedObservable = this._httpClient.get<WebAppDto[]>(`${this._configService.apiUrl}/Reference/GetDataFileAddons?productId=${productId}`)
        .pipe(
          tap((response: WebAppDto[]) => {
            this.webAppAddOnsCache = response;
          }),
          share(),
          finalize(() => {
            this.webAppAddOnsCache = null;
            this.webAppAddOnsCachedObservable = null;
          })
        );

      observable = this.webAppAddOnsCachedObservable;
    }

    return observable;
  }

  getDataFileAddons(productid: number): Observable<DataFileDto[]> {
    let observable: Observable<DataFileDto[]>;

    if (this.dataFileAddOnsCache) {
      observable = of(this.dataFileAddOnsCache);
    }
    else if (this.dataFileAddOnsCachedObservable) {
      observable = this.dataFileAddOnsCachedObservable;
    }
    else {
      this.dataFileAddOnsCachedObservable = this._httpClient.get<DataFileDto[]>(`${this._configService.apiUrl}/Reference/GetDataFileAddons?productid=${productid}`)
        .pipe(
          tap((response: DataFileDto[]) => {
            this.dataFileAddOnsCache = response;
          }),
          share(),
          finalize(() => {
            this.dataFileAddOnsCache = null;
            this.dataFileAddOnsCachedObservable = null;
          })
        );

      observable = this.dataFileAddOnsCachedObservable;
    }

    return observable;
  }
}
