import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cfdPhoneFormat'
})
export class CfdPhoneFormatPipe implements PipeTransform {

  transform(value: string): string {
    let formattedPhone = "";
      if ((value !== '' && value !== null) && !value.includes('.')) {
         formattedPhone = value.replace('/','.').replace('-','.');
         formattedPhone = formattedPhone.trim();
    }
    return formattedPhone;
  }
}