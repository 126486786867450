import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApprovalDto } from '../../../dtos/approval-dto.model';
import { ApprovalService } from '../../../services/approval.service';
import { OrderService } from '../../../services/order.service';

@Component({
  selector: 'app-approval-history',
  templateUrl: './approval-history.component.html',
  styleUrls: ['./approval-history.component.css']
})

export class ApprovalHistoryComponent implements OnInit, OnDestroy {
  _approvalService: ApprovalService;
  private readonly _orderService: OrderService;
  private readonly _route: ActivatedRoute;
  orderIsReadySubscription: Subscription | undefined;
  orderApprovalHistoryDto: ApprovalDto[] = [];
  isSnapshot = false;
  constructor(approvalService: ApprovalService, orderService: OrderService, public route: ActivatedRoute) {
    this._approvalService = approvalService;
    this._orderService = orderService;
    this._route = route;
  }

  ngOnInit(): void {
    this.isSnapshot = this._route.snapshot.url[1]?.path === 'snapshot';
    if(this.isSnapshot){
      this._approvalService.getOrderApprovalHistoryForSnapshot(this._orderService.orderDto);
    }
    else{
      this._approvalService.getOrderApprovalHistory(this._orderService.orderDto);
    }
  
  }

  ngOnDestroy(): void{
    this.orderIsReadySubscription?.unsubscribe();
  }
}
