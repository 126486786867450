import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OpenCashDto } from '../../../dtos/open-cash-dto.model';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { AccountingService } from '../../../services/accounting.service';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.css', '../config-sets.component.css']
})

export class DepositComponent implements OnInit {
  private readonly _accountingService: AccountingService;
  private readonly _pricingService: PricingService;
  @Input() orderDetailDto: OrderDetailDto = new OrderDetailDto();
  @Output() enableSave = new EventEmitter<boolean>();
  loading = false;
  visibleTextValue = '';
  applyDollarErrorText: string = 'Apply $ should be less than or equal to Balance';
  totalApplyDollarErrortext = 'Total Apply $ should be less than $ Open';
  tableHeader = ['', 'Invoice #', 'Invoice Date', 'Order #', 'Ref #', 'Invoice $', 'Paid $', 'Balance', 'Apply $'];
  openCashDtos: OpenCashDto[] = [];
  remainingDepositAllowed = 0;
  showApplyError: boolean = false;
  showTotalApplyError: boolean = false;
  isEditMode: boolean = false;
  swalAlert = new SwalAlert();

  constructor(
    accountingService: AccountingService,
    public orderService: OrderService,
    pricingService: PricingService) {
      this._accountingService = accountingService;
      this._pricingService = pricingService;
    }

  ngOnInit(): void {
    localStorage.setItem('from', 'depositComponent');
    this.isEditMode = localStorage.getItem('isEditMode') === 'true';
    this.enableSave.next(false);
    this.getOpenCashCreditMemos();
    if(this.openCashDtos.length === 0 ){
      this.enableSave.next(false);
    }
  }

  getOpenCashCreditMemos() {
    this.visibleTextValue = "Fetching Deposits";
    this.loading = true;
    this._accountingService.getOpenCash(this.orderService.orderDto.billToDto.id)
      .subscribe(data => {
        this.openCashDtos = data;
        this.enableSave.next(false);
        if (this.orderDetailDto.oti !== '' && this.orderDetailDto.oti !== null) {
          for (let i = 0; i < this.orderService.orderDto.orderDetailDtos.length; i++) {
            if (this.orderService.orderDto.orderDetailDtos[i].oti !== '' && this.orderService.orderDto.orderDetailDtos[i].oti !== null && 
            this.orderService.orderDto.orderDetailDtos[i].productCode === this.orderDetailDto.productCode && 
            this.orderService.orderDto.orderDetailDtos[i].orderDetailStatus !== 'V') {
              const index = this.openCashDtos.findIndex(o => o.originalTransactionId === this.orderService.orderDto.orderDetailDtos[i].oti);

              if (index > -1) {
                this.openCashDtos[index].isSelected = true;
                this.openCashDtos[index].apply = this.orderService.orderDto.orderDetailDtos[i].unitPrice;
                this.openCashDtos[index].originalApply = this.openCashDtos[index].apply;
                this.enableSave.next(false);
              }
            }
          }
        }
        if (this.isEditMode) {
          this.enableSave.next(true);
        }

        this.calculateRemainingDepositAllowed();
        this.loading = false;
      }, () => {
        this.swalAlert.alert('Error occurred while retrieving Open cash memos');
        this.loading = false;
      });
  }

  async validateApplyDollar(openCash: OpenCashDto) {
    openCash.apply = -Math.abs(openCash.apply);

    if (Math.abs(openCash.apply) > Math.abs(openCash.balance) + Math.abs(openCash.originalApply || 0)) {
      openCash.apply = -Math.abs(openCash.balance) - Math.abs(openCash.originalApply || 0);
      this.showApplyError = false;
      openCash.isSelected = false;
      await this.swalAlert.alert('Apply $ should be less than or equal to Balance');
      return;
    }
    else {
      this.showApplyError = false;
      openCash.isSelected = openCash.apply !== 0;
    }

    this.calculateRemainingDepositAllowed();

    if (this.remainingDepositAllowed < 0) {
      this.showTotalApplyError = true;
    } else {
      this.showTotalApplyError = false;
      const isAnyCheck = this.openCashDtos.some(item => item.isSelected);
      this.enableSave.next(isAnyCheck);
    }

    this.validateEnablingSaveBtn();
  }

  calculateRemainingDepositAllowed(): void {
    const totalApplyDollar = this.openCashDtos.filter(item => item.apply).reduce((sum, item) => sum + Math.abs(item.apply), 0);
    let totalExtendedPrice = this.orderService.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus === ' ' && item.productCode !== '0010')
    .reduce((sum, item) => sum + item.extendedPrice + item.taxAmount, 0);
    totalExtendedPrice = this._pricingService.round(totalExtendedPrice, 2);
    this.remainingDepositAllowed = this._pricingService.round(totalExtendedPrice - totalApplyDollar, 2);
  }

  save(): boolean {
    const totalApplyDollar = this.openCashDtos.filter(item => item.apply).reduce((sum, item) => sum + Math.abs(item.apply), 0);
    let totalExtendedPrice = this.orderService.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus === ' ' && item.productCode !== '0010')
    .reduce((sum, item) => sum + item.extendedPrice + item.taxAmount, 0);
    totalExtendedPrice = this._pricingService.round(totalExtendedPrice, 2);
    if (totalApplyDollar > totalExtendedPrice) {
      this.swalAlert.alert('The total reservation amount cannot exceed the total open amount.');
      return false;
    }

    // If the user had just entered the item into the cart, we will remove it, and add the items selected.
    this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== this.orderDetailDto || item.oti);

    this.saveDepositsConfiguration();

    return true;
  }

  enableSavebtn(): void
  {
    if (this.isEditMode) {
      this.enableSave.next(true);
      localStorage.removeItem('openCashDtos');
      localStorage.setItem('openCashDtos', JSON.stringify(this.openCashDtos));
    } else {
      this.validateEnablingSaveBtn();
    }
  }

  saveDepositsConfiguration() {
    for (const openCashDto of [...this.openCashDtos].reverse()) {
      if (openCashDto.apply < 0) {
        openCashDto.apply = -openCashDto.apply;
      }

      const existingDeposit = this.orderService.orderDto.orderDetailDtos
        .filter(o => o.oti === openCashDto.originalTransactionId && o.productCode === '0010' && o.orderDetailStatus !== 'V' && o.orderDetailStatus !== 'C')[0];

      // Delete or void unselected items
      if (!openCashDto.isSelected && existingDeposit) {
        if (existingDeposit.id <= 0) {
          // Delete the row if it hasn't been saved to the database.
          this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== existingDeposit);
        } else if (existingDeposit.orderDetailStatus === ' ') {
          // Void the row if it has been saved to the database.
          existingDeposit.orderDetailStatus = 'V'
          existingDeposit.voidDate = new Date();
          existingDeposit.voidReasonCode = '14';
          existingDeposit.voidedQuantity = existingDeposit.openQuantity;
          existingDeposit.openQuantity = 0;
          existingDeposit.orderQuantity = 0;
          existingDeposit.taxAmount = 0;
          existingDeposit.taxRate = 0;
        }
      // Update the selected record if it already exists on the order.
      } else if (openCashDto.isSelected && existingDeposit && existingDeposit.orderDetailStatus === ' ') {
        existingDeposit.listPrice = -openCashDto.apply;
        existingDeposit.unitPrice = -openCashDto.apply;
        existingDeposit.extendedPrice = -openCashDto.apply;
        existingDeposit.oti = openCashDto.originalTransactionId;
        existingDeposit.referenceNumber = openCashDto.referenceNumber.trim();
      }
      // Add the selected record if it doesn't exist on the order.
      else if (openCashDto.isSelected && !existingDeposit){
        const newDeposit = this.orderService.createNewOrderDetail(this.orderService.orderDto, '0010');
        newDeposit.openQuantity = 1;
        newDeposit.orderQuantity = 1;
        newDeposit.listPrice = -openCashDto.apply;
        newDeposit.unitPrice = -openCashDto.apply;
        newDeposit.extendedPrice = -openCashDto.apply;
        newDeposit.discount = 0;
        newDeposit.oti = openCashDto.originalTransactionId;
        newDeposit.referenceNumber = openCashDto.referenceNumber.trim();
        this.orderService.orderDto.orderDetailDtos.unshift(newDeposit);
        this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
      }
    }

    const totalExtendedPrice = this.orderService.orderDto.orderDetailDtos.reduce((sum, item) =>  parseFloat(sum.toFixed(2)) + parseFloat(item.extendedPrice.toFixed(2)), 0);
    this.setPaymentTermsOnSave(totalExtendedPrice);
  }

  setPaymentTermsOnSave(totalExtendedPrice:any){
    if (this.orderDetailDto.productCode === "0010" && totalExtendedPrice === 0) {
      this.orderService.orderDto.paymentMethod = 'C';
      this.orderService.orderDto.ccToken = null;
      this.orderService.orderDto.ccLastFour = null;
      this.orderService.orderDto.ccExpirationDate = null;
      this.orderService.orderDto.ccAuthorizationCode = null;
      if(this.orderService.orderDto.paymentTerms==="Credit Card")
      {
        this.orderService.orderDto.paymentTerms = "Net 15 Days";
      }
    }
  }

  validateEnablingSaveBtn(): void {
    const openCashDtos: OpenCashDto[] = this.openCashDtos.filter(e => e.isSelected);
    let selectedCount = 0;
    let valueEnteredCount = 0;
    openCashDtos.forEach(e => {
      if (e['isSelected']) {
        selectedCount ++;
      }
      if (e['isSelected'] && e['apply']) {
        valueEnteredCount++;
      }
    })
    selectedCount === valueEnteredCount ? this.enableSave.next(true) : this.enableSave.next(false);
    localStorage.removeItem('openCashDtos');
    if (selectedCount === valueEnteredCount)
    {
      localStorage.setItem('openCashDtos', JSON.stringify(openCashDtos));
    }
  }
}
