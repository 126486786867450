import { RecentQuoteOrOrderDetailsDto } from "./recent-quote-or-order-details-dto.model";

export class RecentQuotesOrOrdersDto {
    quoteOrOrderNumber = 0;
    type = '';
    orderByContact = '';
    shipToContact = '';
    entryDate = '';
    subTotal = 0;
    status = '';
    poNumber = '';
    recentQuoteOrOrderDetails: RecentQuoteOrOrderDetailsDto[] = [];
}