import { IUITKSelectItemProps } from "@uitk/angular";
import { AdditionalReturnInfoDto } from './additional-return-info-dto-model';
import { BillToDto } from "./bill-to-dto.model";
import { CfdFormFieldsDto } from "./cfd-form-field-selection-dto.model";
import { DataFileAccessDto } from "./data-file-access-dto.model";
import { MultiYearDto } from "./multi-year-dto.model";
import { OfferInfoDto } from "./offer-info-dto";
import { OrderApprovalInputDto } from "./order-approval-input-dto.model";
import { OrderAttachmentDto } from "./order-attachment-dto.model";
import { OrderByDto } from "./order-by-dto.model";
import { OrderDetailDto } from "./order-detail-dto.model";
import { OrderNoteDto } from './order-note-dto.model';
import { OrderOfferDto } from "./order-offer-dto";
import { ShipToDto } from "./ship-to-dto.model";

export class OrderDto {
  id = 0;
  webReferenceNumber: string | null = null;
  originalOrderId: number | null = null;
  orderType = '';
  orderStatus = '';
  orderDate = new Date();
  voidDate: Date | null = null;
  voidNote: string | null = null;
  warehouseId: number = 1;
  isMultiYear = false;
  multiYearCurrentYear: number | null = null;
  paymentPlan = '';
  holdReasonCode: string | null = null;
  exceptionReasonCode: string | null = null;
  quoteReasonCode: string | null = null;
  voidReasonCode: string | null = null;
  accountOwnerCode: string | null = null;
  enteredBy: string | null = null;
  enteredByEmail: string | null = null;
  enteredDate = new Date();
  contactId = 0;
  poNumber: string | null = null;
  isRush = false;
  shipmentMethod: string | null = null;
  paymentMethod: string | null = null;
  paymentTerms: string | null = null;
  promotionCode: string | null = null;
  sourceCode: string | null = null;
  returnCode: string | null = null;
  listCode: string | null = null;
  glCode: string | null = null;
  pendingDate: Date | null = null;
  pendingBy: string | null = null;
  ccAuthorizationCode: string | null = null;
  ccToken: string | null = null;
  ccSecurityCode: string | null = null;
  ccExpirationDate: string | null = null;
  ccLastFour: string | null = null;
  ccAuthOE: string | null = null;
  dataFileAccessDtos: DataFileAccessDto[] = [];
  billToDto: BillToDto = new BillToDto();
  orderByDto: OrderByDto = new OrderByDto();
  shipToDto: ShipToDto = new ShipToDto();
  multiYearDtos: MultiYearDto[] = [];
  orderDetailDtos: OrderDetailDto[] = [];
  additionalReturnInformation: AdditionalReturnInfoDto = new AdditionalReturnInfoDto();
  orderNotes: OrderNoteDto[] | null = null;
  orderAttachments: OrderAttachmentDto[] = [];
  hasReturns: boolean = false;
  changeFutureOrders = false;
  originalOrderDto: OrderDto | null = null;
  inUseBy: string | null = null;
  inUseDate: Date | null = null;
  openAmount = 0;
  shippedAmount = 0;
  modifiedBy = '';
  allowDoNotShip = false;
  allow1000Order = false;
  sendLog = '';
  bypassEvaluateHoldQuote = false;
  allowPORequired = false;

  // These special properties are used for binding to the UI components
  orderTypeBinding: IUITKSelectItemProps = { id: '', label: '', value: '' };
  orderStatusBinding: IUITKSelectItemProps = { id: '', label: '', value: '' };
  orderByContactBinding: IUITKSelectItemProps = { id: '', label: '', value: '' };
  shipToContactBinding: IUITKSelectItemProps = { id: '', label: '', value: '' };
  sourceCodeBinding: IUITKSelectItemProps = { id: '', label: '', value: '' };
  returnCodeBinding: IUITKSelectItemProps = { id: '', label: '', value: '' };
  enteredByBinding: string | null = null;
  enteredByBindingEmail: string | undefined;
  selectedsalesPersonName: string | null = null;
  selectedslaesPersonNameEmail: string | null = null;

  hasActiveSubscriptions = false;
  systemComments = '';
  freeFormComments = '';
  promotionHtml = '';
  amaKey: string | null = '';
  lastShipmentMethod: string | null = null;
  maxTaxRate = 0;
  totalTax = 0;
  hasDock = false;
  hasNoDock = false;
  yearPanelButtonsDisable = false;
  isReturnNote = false;
  arsLabel: string | null = null;
  disableAddReturnToBatchButton = false;
  reviewedBy: string | null = null;
  reviewedDate: Date | null = null;
  isShipAndProcess = false;
  isPartiallyShipped = false;
  leadIdDisplay: string | null = null;
  oppurtunityIdDisplay: string | null = null;
  accountOwnerName: string | null = null;
  leadId: string | null = null;
  opportunityId: string | null = null;
  eligibleAmount = 0;
  offerInfoDto: OfferInfoDto | null = null;
  orderOfferDto: OrderOfferDto | null = null;
  cfdFormFields: CfdFormFieldsDto | null = null;
  totalQuantity = 0;
  saveAndClone = false;
  orderApprovalDetailsDto: OrderApprovalInputDto | null = null;
  finalMultiYearSave = false;
  minimumPromotionQuantity = 0;
  minimumPromotionPrice = 0;
  oppurtunityId: string | null = null;
  hasUserSeenPromoForm = false;
  promotionOfferPopupButton = '';
  orderOfferQualificationsChangedPopupButton = '';
  tierDiscountType = '';
  isOrderEntryContentChanged = false;
  email = '';
  salesPersonEmail = '';
  byPassApproval = false;
  salesOrganization = '';
}
