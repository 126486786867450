export class ReturnDto {
  returnId = 0;
  accountId : number | null = null;
  returnDate = new Date();
  terms : string | null = null;
  last4CC : string | null = null;
  shippedAmount = 0;
  poNumber : string | null = null;
  returnType : string | null = null;
  returnStatus: string | null = null;
  returnCode : string | null = null;
  enteredBy : string | null = null;
}
