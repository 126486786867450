import { AuthorizationLineDto } from './Authorization-line-dto.model';

export class InvoiceDetailsDto {
  invoiceNumber = '';
  invoiceDate: Date = new Date();
  orderNumber = 0;
  accountId = 0;
  referenceNumber = '';
  invoiceAmount = 0;
  paidAmount = 0;
  balance = 0;
  paidDate: Date = new Date();
  originalTransactionId = '';
  purchaseOrderNumber = '';
  authorizationCode = '';
  paymentType = '';
  paymentMethod = '';
  paymentTerms = '';
  trackingNumber = '';
  expirationDate = '';
  cCToken = '';
  cCLastFour = '';
  shiptocontactName = '';
  orderbycontactName = '';
  orderByContactId = 0;
  shipToContactId = 0;
  invoiceDetails: AuthorizationLineDto[] = [];
  isSelected = false;
  status = '';
}
