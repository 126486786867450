import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IUITKColumnState, UITKTableDataSource } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Mode } from '../../enums/mode.enum';
import { ShipmentHistoryDto } from '../../dtos/shipment-history-dto.model';
import { SubscriptionSearchResult } from '../../dtos/subscription-search-result.model';
import { ModeService } from '../../services/mode.service';
import { ShipmentService } from '../../services/shipment.service';

@Component({
  selector: 'app-shipment-history',
  templateUrl: './shipment-history.component.html',
  styleUrls: ['./shipment-history.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
  
export class ShipmentHistoryComponent implements OnInit {
  private readonly _shipmentService: ShipmentService;
  @Input() subscriptionSearchResult: SubscriptionSearchResult = new SubscriptionSearchResult();
  @Input() dialog = { show: false };
  shipmentHistoryIsReadySubscription: Subscription | undefined;
  Mode = Mode;
  dataSource = new UITKTableDataSource<ShipmentHistoryDto>([]);
  tableHeaders = [
    { name: 'Item', id: 'productCode' },
    { name: 'Description', id: 'productDescription' },
    { name: 'Qty', id: 'shippedQuantity' },
    { name: 'Ship Date', id: 'shipDate' },
    { name: 'Tracking #', id: 'trackingNumber' }
  ];
  sortColumn: string = 'shipDate';
  constructor(shipmentService: ShipmentService, public modeService: ModeService) {
    this._shipmentService = shipmentService;
  }

  ngOnInit(): void {
    this.shipmentHistoryIsReadySubscription = this._shipmentService.shipmentHistoryIsReady.subscribe(() => {
      this.dataSource.data = [...this._shipmentService.shipmentHistoryDto];
      this.modeService.mode = Mode.View;
    });

    this.getShipmentHistoryRecords();
  }

  getShipmentHistoryRecords(): void {
   this._shipmentService.getShipmentHistory(this.subscriptionSearchResult.accountId, this.subscriptionSearchResult.subscriptionNumber);
  }

  closeShipmentHistoryDialog(): void {
    this.dialog.show = false;
  }
  ngOnDestroy(): void {
    this.shipmentHistoryIsReadySubscription?.unsubscribe();
  }
  onSortChange(event: IUITKColumnState): void {
    this.sortColumn = event.column;
    this.dataSource.data = this.dataSource.sortData(this.dataSource.data, event.direction, event.column);
  }
  openUpsTracking(trackingNumber: string): void {
    const url = `https://www.ups.com/${trackingNumber}`;
    window.open(url, "_blank");
  }
}
