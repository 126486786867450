export class ARSLabelDto {
    id = 0;
    deliveryMethod = '';
    email = '';
    orderId = 0;
    company = '';
    department = '';
    street = '';
    city = '';
    state = '';
    zip = '';
    country = '';
    arsLabel: string | null= null;
}