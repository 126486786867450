<div id="orderDetailsMain" class="uitk-l-grid p-0">
  <div class="container-fluid">
    <div class="row">
      <div class="col align-self-center">
        <h5 id="inquiryHeader">Order Inquiry:</h5>
      </div>
      <div class="col align-self-center">
        <uitk-tooltip 
          uitkId="orderDate-enteredDate-tooltip"
          [tooltipContent]="orderDateToolTipContent"
          tooltipPlacement="top-left">
          <label style="white-space: nowrap">Order Date: {{ orderService.orderDto.orderDate | date:'MM/dd/yyyy h:mm a' }}</label>
        </uitk-tooltip>
      </div>
      <div class="col align-self-center">
        <div class="float-end">
          <div class="input-group">
            <div *ngFor="let multiYearOrder of orderService.orderDto.multiYearDtos">
              <button 
                [disabled]="modeService.mode !== Mode.View || multiYearOrder.orderId === this.orderService.orderDto.id"
                class="uitk-c-button uitk-c-button--alternative-ghost uitk-u-margin--xxs"
                [ngClass]="{
                  'uitk-c-button--alternative-ghost' : !nextyearbtnVisible, 
                  'uitk-c-button--alternative': nextyearbtnVisible,
                  'btn_disabled_highlight': multiYearOrder.orderId === this.orderService.orderDto.id
                  }"
                [ngbTooltip]="myOrderId" container="body" placement="bottom"
                (click)="gotoOrder(multiYearOrder)"
                >{{ multiYearOrder.yearOfOrder }}</button>
              <ng-template #myOrderId><div style="color: white !important; font-size: 14pt">{{ multiYearOrder.orderId }}</div></ng-template>
            </div>
            <button
              [disabled]="modeService.mode !== Mode.View || addYearButtonDisabled || this.orderService.orderDto.orderStatus === 'V'"
              class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
              (click)="orderService.addYear(false);" appEnableResource="OrderInquiryAddYearButton"
              >Add Year</button>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="orderDetailsChild">
    <div class="container-fluid">
      <div class="row mt-2">
        <div class="col-sm-4">
          <div class="row">
            <label uitkLabel class="col-lg-4">Account ID</label>
            <div class="col-lg-8">
              <a target="_blank" class="link" style="text-decoration: none;" (click)="navigateToARInquiry(orderService.orderDto.billToDto.id)">{{orderService.orderDto.billToDto.id}}</a>
            </div>
          </div>
        </div>
        <div class="col-sm-4 align-self-center">
          <div class="row">
            <label class="col-lg-4" uitkLabel>Acct Type</label>
            <div class="col-lg-8 align-self-center">
              {{ orderService.orderDto.billToDto.accountTypeCode }}
              <span *ngIf="orderService.orderDto.billToDto.accountOwnerName"> - <a target="_blank"
                 [href]="'mailto:'+ orderService.orderDto.billToDto.accountOwneremail"> {{ orderService.orderDto.billToDto.accountOwnerName }}</a></span>
              
              </div>
            
          </div>
        </div>
        <div class="col-sm-4 align-self-center">
          <div class="row">
            <label class="col-lg-4" uitkLabel>Credit Limit</label>
            <div class="col-lg-8 text-nowrap" [ngClass]="{'highlight': orderService.orderDto.billToDto.creditLimit < 0 }" style="font-weight: bold; width: auto">
              {{ orderService.orderDto.billToDto.creditLimitDescription }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-4">
          <div class="row">
            <label class="col-lg-4" uitkLabel>Type</label>
            <div class="col-lg-8">
              <uitk-form-field>
                <uitk-dropdown uitkId="soType" #singleSelect="ngModel" appEnableResource="OrderHeaderType"
                  bbLogTable="OrderHeader" bbLogColumn="OrderType" [bbLogPrimaryId]="orderService.orderDto.id"
                  [items]="orderTypeList" [enableSelectFlag]="true" [(ngModel)]="orderService.orderDto.orderTypeBinding" (onChange)="onChangeOrderType($event)"
                  [disabled]="modeService.mode !== Mode.Edit || disableOrderType || orderService.orderDto.orderType === 'Q' || orderService.orderDto.orderType === 'E'"></uitk-dropdown>
              </uitk-form-field>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="row">
            <label class="col-lg-4" uitkLabel>Order #</label>
            <div class="col-lg-8">
              <uitk-form-field>
                <input uitkInput id="orderId" class="textbox" type="text"
                       [(ngModel)]="orderService.orderDto.id" disabled />
              </uitk-form-field>
            </div>
          </div>
        </div>
        <div class="col-sm-4 align-self-center">
          <div class="row">
            <label class="col-lg-4" uitkLabel>Open Amt</label>
            <div id="dollarsOpen" class="col-lg-8 align-self-center">
              {{ orderService.orderDto.openAmount | currency }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2 mb-2">
        <div class="col-sm-4">
          <div class="row">
            <label class="col-lg-4" uitkLabel>Web Ref #</label>
            <div class="col-lg-8">
              <uitk-form-field>
                <input uitkInput id="webRefNum" class="textbox" type="text" value=""
                       appEnableResource="OrderHeaderWebRef" [(ngModel)]="orderService.orderDto.webReferenceNumber"
                       [disabled]="modeService.mode !== Mode.Edit || disableWebRef" />
              </uitk-form-field>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="row">
            <label class="col-lg-4" uitkLabel>Status</label>
            <div class="col-lg-8 align-self-center">
              <uitk-form-field>
                <uitk-dropdown uitkId="soStatus" [items]="orderStatusList" appEnableResource="OrderHeaderStatus"
                  bbLogTable="OrderHeader" bbLogColumn="OrderStatus" [bbLogPrimaryId]="orderService.orderDto.id"
                  [(ngModel)]="orderService.orderDto.orderStatusBinding" (onChange)="onChangeOrderStatus($event)"
                  [disabled]="modeService.mode !== Mode.Edit || disableOrderStatus || orderService.orderDto.orderStatus === 'H'"></uitk-dropdown>
              </uitk-form-field>
            </div>
          </div>
        </div>
        <div class="col-sm-4 align-self-center">
          <div class="row">
            <label class="col-lg-4 adjustLabel" uitkLabel>Shipped Amt</label>
            <div id="dollarsShipped" class="col-lg-8 align-self-center">
              {{ orderService.orderDto.shippedAmount | currency }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row mt-2">
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-5 adjustLabel" uitkLabel>Source Code</label>
          <div class="col-lg-7">
            <uitk-form-field>
              <uitk-dropdown class="d1" uitkId="sourceCode" appEnableResource="OrderHeaderSourceCode"
                             [items]="sourceCodeList" [disabled]="modeService.mode !== Mode.Edit || disableSourceCode" [enableSelectFlag]="true"
                             [(ngModel)]="sourceCode" (onChange)="onChangeSourceCode($event)"></uitk-dropdown>
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-5" uitkLabel>List Code</label>
          <div class="col-lg-7">
            <uitk-form-field>
              <input maxlength="10" uitkInput id="listCode" class="textbox" type="text" value=""
                     appEnableResource="OrderHeaderListCode" [(ngModel)]="orderService.orderDto.listCode"
                     [disabled]="modeService.mode !== Mode.Edit || disableListCode" />
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-5 adjustLabel" uitkLabel>Promo Code</label>
          <div class="col-lg-7">
            <div class="row">
              <div class="col-sm-3">
                <button type="button" style="border: none; background: none; padding-top: 12px;"
                        [disabled]="!orderService.orderDto.promotionHtml || disablePromoCode" appEnableResource="OrderHeaderPromoDetails"
                        (click)="openPromoCodeDetailsPopup()">
                  <span class="uitk-icon uitk-icon-info_hollow sm-icon" style="font-size: 25px;"
                        [ngStyle]="{'color': (orderService.orderDto.promotionHtml) ? '#002677' : '#B1B2B3'}"></span>
                </button>
              </div>
              <div class="col-sm-9">
                <uitk-form-field>
                  <input uitkInput id="promoCode" class="textbox" (focusout)="onChangePromoCode($event)"
                         appEnableResource="OrderHeaderPromoCode" [disabled]="modeService.mode !== Mode.Edit || disablePromoCode" type="text"
                         value="" [(ngModel)]="orderService.orderDto.promotionCode" />
                </uitk-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row" >
          <label class="col-lg-5" style="margin-top: 2%;" uitkLabel>Entered By</label>
          <div class="col-lg-7 mt-2" bbLogTable="OrderHeader" bbLogColumn="ModifiedBy" [bbLogPrimaryId]="orderService.orderDto.id">
            <a target="_blank" [href]="'mailto:'+ orderService.orderDto.enteredByBindingEmail">{{orderService.orderDto.enteredBy}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr>

  <div class="container-fluid mb-2">
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <label class="col-lg-2" uitkLabel>Product</label>
          <div class="col-lg-10">
            <button *ngIf="ordesInquiryImpersonating.includes(orderService.impersonateTypes)" id="add" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="addLineItem.emit()"
              appEnableResource="OrderInquiryAddButton" (callAddMethod)="modeService.mode === Mode.Edit?addLineItem.emit():''"  [disabled]="modeService.mode !== Mode.Edit || orderService.orderDto.orderStatus === 'C' || orderService.orderDto.orderStatus === 'P' || orderService.orderDto.orderStatus === 'D'">
              <u>A</u>dd
            </button>
            <button  *ngIf="!ordesInquiryImpersonating.includes(orderService.impersonateTypes)" id="add" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="addLineItem.emit()"
              appEnableResource="OrderInquiryAddButton" (callAddMethod)="modeService.mode === Mode.Edit?addLineItem.emit():''" hotkeyCommand="ctrl+a" [disabled]="modeService.mode !== Mode.Edit || orderService.orderDto.orderStatus === 'C' || orderService.orderDto.orderStatus === 'P' || orderService.orderDto.orderStatus === 'D'">
              <u>A</u>dd
            </button>
            <button id="edit" type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
              (click)="edit()" (callEditMethod)="modeService.mode === Mode.View && !isEditDisabled?edit():''"
              hotkeyCommand="ctrl+e"
              [disabled]="modeService.mode !== Mode.View || orderService.orderDto.orderStatus == 'V' ||  orderService.orderDto.orderStatus == 'P' || orderService.orderDto.orderStatus == 'D' || (orderService.orderDto.orderStatus == 'C' && !orderService.orderDto.hasActiveSubscriptions) || orderService.orderDto.pendingDate !== null"
              appEnableResource="OrderInquiryEditButton">

              <u>E</u>dit
            </button>
            <button id="revert" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
                    (click)="revert()" [disabled]="modeService.mode !== Mode.Edit" (callRevertMethod)="modeService.mode === Mode.Edit?revert():''" hotkeyCommand="ctrl+r"
                    appEnableResource="OrderInquiryRevertButton">
              <u>R</u>evert
            </button>
            <button id="itemSearch" target="_blank" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
            (click)="itemSearch()"  [disabled]="modeService.mode !== Mode.Edit" hotkeyCommand="ctrl+f"  (callSearchMethod)="itemSearch()">Item Search</button>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-end">
          <div class="input-group">
            <div *ngIf="!orderService.orderDto.reviewedBy">
              <button id="webReviewed" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
                      (click)="webReviewed()" appEnableResource="OrderInquiryWebReviewedButton" [hidden]="!orderService.orderDto.webReferenceNumber">
                Web Reviewed
              </button>
            </div>
            <div>
              <p class="webReviewed" *ngIf="orderService.orderDto.reviewedBy">{{orderService.orderDto.reviewedBy}}<br>{{ReviewedDateChange | date:'MM/dd/yyyy hh:mm:ss a'}}</p>
            </div>
            <button id="calculateTax" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
                    appEnableResource="OrderHeaderCalculateTaxButton" (click)="calculateTax()"
                    [disabled]="modeService.mode !== Mode.Edit">
              Calculate Tax
            </button>
            <button id="save" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
                    (click)="orderService.additionalContactsSource = 'Order Inquiry'; onClickSave()" (callSaveMethod)="modeService.mode === Mode.Edit?onClickSave():''" hotkeyCommand="ctrl+s"
                    [disabled]="modeService.mode !== Mode.Edit"
                    appEnableResource="OrderInquirySaveButton">
              <u>S</u>ave
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Dialogs -->
<div  *ngIf="promoCodeDialogModal.show" class = "overlayForPopups">
<div cdkDrag>
<div class="divPopUp" *ngIf="promoCodeDialogModal.show">
  <uitk-dialog id="promoCodeDialog" [(showModal)]="promoCodeDialogModal.show">
    <uitk-dialog-content>
      <div [innerHtml]="promoCodeHtmlContent | safeHtml">
      </div>

    </uitk-dialog-content>

  </uitk-dialog>
</div>
</div>
</div>

<div *ngIf="showSendEmailDialog.show">
  <app-send-email [dialog]="showSendEmailDialog"></app-send-email>
</div>

<div *ngIf="holdReasonDialog.show">
  <app-hold-reason [dialog]="holdReasonDialog"></app-hold-reason>
</div>

<div *ngIf="exceptionReasonDialog.show">
  <app-exception-reason [dialog]="exceptionReasonDialog"></app-exception-reason>
</div>

<div *ngIf="quoteReasonDialog.show">
  <app-quote-reason [dialog]="quoteReasonDialog"></app-quote-reason>
</div>

<div *ngIf="orderService.couponItemCheck.show">
  <app-coupon [dialog]="orderService.couponItemCheck"></app-coupon>
</div>

<div *ngIf="orderService.confirmAdditionalContactsDialog.show">
  <app-confirm-additional-contacts [dialog]="orderService.confirmAdditionalContactsDialog"></app-confirm-additional-contacts>
</div>

<div *ngIf="orderService.selectContactsDialog.show">
  <app-select-contacts [dialog]="orderService.selectContactsDialog"></app-select-contacts>
</div>

<div *ngIf="orderService.applyChangesToFutureDialog.show">
  <app-apply-changes-to-future-dialog [dialog]="orderService.applyChangesToFutureDialog"></app-apply-changes-to-future-dialog>
</div>

<div *ngIf="updateInvoicesFromOrderDialog.show">
  <app-update-invoices-from-order-dialog [dialog]="updateInvoicesFromOrderDialog"></app-update-invoices-from-order-dialog>
</div>

<div *ngIf="orderService.shippingDialogModal.show">
  <app-shipping-popup-order-entry [orderDetail]="orderService.configurationOrderDetail"></app-shipping-popup-order-entry>
</div>