<div class="container-fluid px-0">
  <!-- Header of the Ship To section -->
  <div class="row">
    <div class="col d-flex">
      <div class="borderline"></div>
      <div class="flex-grow-1 align-self-center mt-2">
        <h5 *ngIf="!orderService.displayShipToLink || modeService.isWaiting" class="text-dark"><strong>Ship To</strong></h5>
        <h5 *ngIf="orderService.displayShipToLink && !modeService.isWaiting "><a class="link" appEnableResource="OrderInquiryShipToShipToHyperLink" (click)="showSelectedContacts()" target="_blank">Ship To</a></h5>
      </div>
      <div class="uitk-icon uitk-icon-add sm-icon align-self-center mx-2">
        <a (click)="navigateToSFAddContact(orderService.orderDto.billToDto.salesforceId)" appEnableResource="ShipToAddHyperlink" class="link"
           [ngStyle]="{'pointer-events': (disableAddButton) ? 'none' : 'auto'}" target="_blank">Add</a>
      </div>
      <div class ="uitk-icon uitk-icon-refresh sm-icon align-self-center">
        <a (click) ="refreshShipToContactList()" appEnableResource="ShipToRefreshHyperlink" class="link"> Refresh</a>
      </div>
      <div class ="uitk-icon uitk-icon-edit sm-icon align-self-center mx-2">
        <a (click)="navigateToSFNewOrEditContact(orderService.orderDto.shipToDto.salesforceId)" class="link"
          target="_blank" appEnableResource="ShipToEditHyperlink"> Edit</a>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <hr class="m-0 bg-dark">
    </div>
  </div>

  <!-- Contact selection and view icon -->
  <div class="row mt-2 px-1" [hidden]="modeService.mode === Mode.Wait || modeService.mode === Mode.ShipToWait ">
    <div class="col d-flex px-3" bbLogTable="OrderShipToAddress" bbLogColumn="ContactId" [bbLogPrimaryId]="orderService.orderDto.shipToDto.id">
      <uitk-form-field class="align-self-center flex-grow-1">
        <uitk-dropdown uitkId="shipToContact" [items]="contactList" [itemSortOn]="true"
                      (ngModelChange)="setSelectedValue($event)" [(ngModel)]="orderService.orderDto.shipToContactBinding"
                      [disabled]="modeService.mode !== Mode.Edit || isShipToDisabled">
        </uitk-dropdown>
      </uitk-form-field>
      <div class="mx-1"></div>
      <app-contact-search [isShipToContact]="true" [isShipToDisabled]= "isShipToDisabled"></app-contact-search>
      <div class="mx-1"></div>
      <button name="viewBtn" id="viewBtn" appEnableResource="ShipToContactEyeball"
              class="uitk-c-button uitk-c-button--ghost uitk-icon uitk-icon-view px-0 py-1 mt-1" type="button"
              (click)="navigateToSFViewContact(orderService.orderDto.shipToDto.salesforceId)"></button>
    </div>
  </div>

  <!-- Order Ship To Address -->
  <div class="row px-3 mt-2" [hidden]="modeService.mode === Mode.Wait || modeService.mode === Mode.ShipToWait">
    <div class="col">
      <app-changeship-toaddress [shipToDto]="orderService.orderDto.shipToDto" [disableAddButton]="disableAddButton" (addressChanged)="addressChange($event)">
      </app-changeship-toaddress>
      <div bbLogTable="OrderShipToAddress" bbLogColumn="Address" [bbLogPrimaryId]="orderService.orderDto.shipToDto.id">
        {{orderService.orderDto.shipToDto.company}}<br>
        <div *ngIf="orderService.orderDto.shipToDto.department">{{orderService.orderDto.shipToDto.department}}<br></div>
        {{orderService.orderDto.shipToDto.street}}<br>
        {{orderService.orderDto.shipToDto.city}}, {{orderService.orderDto.shipToDto.state}},
        {{orderService.orderDto.shipToDto.zip}}<br>
        <div *ngIf="orderService.orderDto.shipToDto.country !== 'US'">{{orderService.orderDto.shipToDto.country}}</div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col">
      <hr class="m-0">
    </div>
  </div>

  <!-- Email and Phone -->
  <div class="row px-3 mt-2" [hidden]="modeService.mode === Mode.Wait || modeService.mode === Mode.OrderByWait">
    <div class="col">
      <div class="row my-0 py-0">
        <label class="col-lg-3">Email</label>
        <div class="col-lg-9">
          {{orderService.orderDto.shipToDto.email}}
        </div>
      </div>
    </div>
  </div>
  <div class="row px-3" [hidden]="modeService.mode === Mode.Wait || modeService.mode === Mode.OrderByWait">
    <div class="col">
      <div class="row">
        <label class="label col-lg-3">Phone</label>
        <div class="col-lg-9">
          {{orderService.orderDto.shipToDto.phone | phoneFormat}}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row">
        <div class="idInnerSecRight">
          <div *ngIf="!modeService.isWaiting && enableChangeLink" class="idInnerSecRight" style="text-align: right;"><a class="link" (click)="showShipmentConfirmationEmail()" target="_blank">Change</a></div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col">
      <hr class="m-0">
    </div>
  </div>

  <!-- Email Info and Email Promo -->
  <div class="row px-2" [hidden]="modeService.mode === Mode.Wait || modeService.mode === Mode.OrderByWait">
    <div class="col-sm-6">
      <uitk-form-field>
        <input uitkCheckbox id="shiptoemailInfo" type="checkbox" appEnableResource="ShipToEmailInfoCheckbox"
              [(ngModel)]="orderService.orderDto.shipToDto.allowInformationalEmails" [disabled]="modeService.mode !== Mode.Edit" />
        <label uitkCheckboxLabel id="eInfoShipToLabel" for="shiptoemailInfo" style="color: #282A2E">Email Info</label>
      </uitk-form-field>
    </div>
    <div class="col-sm-6">
      <uitk-form-field>
        <input uitkCheckbox id="shiptoemailPromo" type="checkbox" appEnableResource="ShipToEmailPromoCheckbox"
              [(ngModel)]="orderService.orderDto.shipToDto.allowPromotionalEmails" [disabled]="modeService.mode !== Mode.Edit" />
        <label uitkCheckboxLabel id="ePromoShipToLabel" for="shiptoemailPromo" style="color: #282A2E">Email Promo</label>
      </uitk-form-field>
    </div>
  </div>

  <!-- Spinner -->
  <div class="container-fluid" [hidden]="modeService.mode !== Mode.Wait && modeService.mode !== Mode.ShipToWait">
    <div class="row">
      <div class="col">
        <div id ="loadingIndicator">
          <uitk-loading-indicator uitkId ="spinner" [visibleText]="visibleTextValue" spinnerSize="large">
            </uitk-loading-indicator>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Dialogs -->
<div *ngIf="showSelectContactsDialog.show">
  <app-select-contacts [dialog]="showSelectContactsDialog"></app-select-contacts>
</div>

<div *ngIf="showShipmentConfirmationEmailDialog.show">
  <app-shipment-confirmation-email [dialog]="showShipmentConfirmationEmailDialog"></app-shipment-confirmation-email>
</div>
