<div *ngIf="recentQuotesOrOrdersDialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkId="viewRecentQuotesOrOrders" [(showModal)]="recentQuotesOrOrdersDialog.show" [closeBtn]="true"
  (onModalHidden)="focusOrderType()">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Recent Quotes/Orders
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div class="row mt-3">
      <div class="uitk-c-table--responsive container fixTableHead">
        <table uitkTable [dataSource]="recentQuotesOrOrders" id="recent-quotes-or-orders-table" class="uitk-c-table"
          aria-describedby="recent-quotes-or-orders-data">
          <thead class="uitk-c-table__head">
            <tr style="text-align: left">
              <th *ngFor="let tableHeader of tableHeaders" scope="col">
                {{ tableHeader }}
              </th>
            </tr>
          </thead>
          <tbody id="recent-quotes-or-orders-data" class="uitk-c-table__body">
            <tr class="uitk-c-table__row" [hidden]="!modeService.isWaiting">
              <td colspan="8">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col">
                      <div id="loadingIndicator">
                        <uitk-loading-indicator uitkId="spinner" spinnerSize="large">
                        </uitk-loading-indicator>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col d-flex justify-content-center">
                      <h5>
                        {{modeService.waitDescription}}
                      </h5>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="recentQuotesOrOrders.data.length === 0" [hidden]="modeService.isWaiting">
              <td colspan="8">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col" style="text-align: center;">
                      No data found
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <ng-container *uitkTableRowBody="let data; let i = index">
              <tr class="uitk-c-table__row" (click)="getRecentQuoteOrOrderDetails(data, i)"
                [class.active]="i == highlightRow" [hidden]="modeService.isWaiting">
                <td style="cursor: pointer; color: #027db4">
                  <p class="zeroMarginBottom" [innerHTML]="data.quoteOrOrderNumber"></p>
                </td>
                <td style="cursor: pointer;">
                  <p class="zeroMarginBottom" [innerHTML]="data.type"></p>
                </td>
                <td style="cursor: pointer;">
                  <p class="zeroMarginBottom" [innerHTML]="data.orderByContact"></p>
                </td>
                <td style="cursor: pointer;">
                  <p class="zeroMarginBottom" [innerHTML]="data.shipToContact"></p>
                </td>
                <td style="cursor: pointer;">
                  <p class="zeroMarginBottom" [innerHTML]="data.entryDate | date: 'MM/dd/yyyy'"></p>
                </td>
                <td style="cursor: pointer;">
                  <p class="zeroMarginBottom" [innerHTML]="data.subTotal | currency:'USD':'symbol':'1.2-2'"></p>
                </td>
                <td style="cursor: pointer;">
                  <p class="zeroMarginBottom" [innerHTML]="data.status">
                  </p>
                </td>
                <td style="cursor: pointer;">
                  <p class="zeroMarginBottom" [innerHTML]="data.poNumber">
                  </p>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </uitk-dialog-content>
</uitk-dialog>
  </div>
</div>
<div *ngIf="recentQuoteOrOrderDetailsDialog.show">
  <app-recent-quote-or-order-details-dialog [recentQuoteOrOrderDetailsDialog]="recentQuoteOrOrderDetailsDialog"
    [recentQuoteOrOrder]="recentQuoteOrOrder">
  </app-recent-quote-or-order-details-dialog>
</div>
