<div *ngIf="editSourceCodePopup.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="editSourceCodePopup.show" [closeBtn]=false>
  <uitk-dialog-header>
    <div cdkDragHandle>
    <span *ngIf="sourceCodeDetails.id === 0">Add Source Code</span>
    <span *ngIf="sourceCodeDetails.id !== 0">Edit Source Code</span>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content id="sourceCodeEdit">
    <!-- <div>{{sourceCode.value}}</div> -->
    <div class="container-fluid">
      <div class="row d-flex mb-2" id="sourceCode">
        <label class="col-lg-4 display_center" uitkLabel>Source Code</label>
        <div class="col-lg-8">
          <uitk-form-field>
            <input uitkInput id="sourceCode" class="textbox" type="text" value="" [(ngModel)]="source.sourceCode"
              maxlength="10" />
          </uitk-form-field>
        </div>
      </div>
      <div class="row d-flex mb-2">
        <label class="col-lg-4 display_center" uitkLabel>Description</label>
        <div class="col-lg-8">
          <uitk-form-field>
            <input uitkInput id="description" class="textbox" type="text" value="" [(ngModel)]="source.description"
              maxlength="50" />
          </uitk-form-field>
        </div>
      </div>
      <div class="row d-flex mb-2">
        <label class="col-lg-4 display_center" uitkLabel>GL Code</label>
        <div class="col-lg-8">
          <uitk-form-field>
            <input uitkInput id="glCode" class="textbox" type="text" value="" [(ngModel)]="source.glCode"
              maxlength="3" />
          </uitk-form-field>
        </div>
      </div>
      <div class="row d-flex mb-2">
        <label class="col-lg-4 display_center" uitkLabel>Status Code</label>
        <div class="col-lg-8">
          <uitk-form-field>
            <input uitkInput id="status" class="textbox" type="text" value="" [(ngModel)]="source.statusCode"
              maxlength="1" />
          </uitk-form-field>
        </div>
      </div>
      <div class="row d-flex mb-2">
        <label class="col-lg-4 display_center" uitkLabel>IsDefault</label>
        <div class="col-lg-8">
          <uitk-form-field>
            <input uitkInput id="isDefault" class="textbox" type="text" value="TRUE" disabled />
          </uitk-form-field>
        </div>
      </div>
    </div>
    
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveSourceCode()"
      [disabled]="!source.sourceCode || !source.statusCode" #dupWebAppOkBtn>
      Save
    </button>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="cancelSourceCode()">
      Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>
<div *ngIf="deleteSourceCodePopup.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="deleteSourceCodePopup.show" [closeBtn]=false>
  <uitk-dialog-content>
    Are you sure you want to delete this Source Code?
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="deleteSourceCode()"
      #dupWebAppOkBtn>
      Yes
    </button>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="cancelDeleteSourceCode()">
      No
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>