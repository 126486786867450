import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IUITKRadioGroupItem, IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { OrderNoteDto } from '../../dtos/order-note-dto.model';
import { ConfigService } from '../../services/config.service';
import { OrderNotesService } from '../../services/order-notes.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order-entry-error-reason-dialog',
  templateUrl: './order-entry-error-reason-dialog.component.html',
  styleUrls: ['./order-entry-error-reason-dialog.component.css']
})
  
export class OrderEntryErrorReasonDialogComponent implements OnInit {
  profileForm = new UntypedFormGroup({
    radioGroup: new UntypedFormControl(''),
  });
  orderEntryErrorReasons: IUITKRadioGroupItem[] = [
    { label: 'Item', value: 'Item' },
    { label: 'Price', value: 'Price' },
    { label: 'Quantity', value: 'Quantity' },
    { label: 'Address', value: 'Address' },
    { label: 'Other', value: 'Other' },
  ];
  oeeReason!: IUITKRadioGroupItem;
  @Input('oeeReasondialog') dialog = { show: false };
  orderIsReadySubscription:  Subscription | undefined;
  noteTypeList: IUITKSelectItemProps[] = [];
  noteTypeAllList: IUITKSelectItemProps[] = [];
  noteType: IUITKSelectItemProps | null = null;
  selectedNote = new OrderNoteDto();
  myUserCode = '';
  displayFreeFormTextBox = false;
  otherOEEReason = '';

  constructor(private readonly orderNotesService: OrderNotesService, public configService: ConfigService, public orderService: OrderService) { }

  async ngOnInit(): Promise<void> {
    this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(async () => {
      if (!this.orderService.orderDto.orderNotes) {
        this.orderNotesService.orderNotesDto = await this.orderNotesService.getOrderNotes(this.orderService.orderDto.id);
        this.orderService.orderDto.orderNotes = this.orderNotesService.orderNotesDto;
      }
    });
  }

  saveOEEReason(): void {
    const orderNotes = this.orderNotesService.orderNotesDto;
    this.selectedNote = new OrderNoteDto();
    this.selectedNote.id = -1;
    this.selectedNote.createdDate = new Date();
    this.selectedNote.userCode = this.configService.getMyUserCode();
    this.selectedNote.userName = this.configService.getMyFullName();
    this.selectedNote.orderId = this.orderService.orderDto.id;
    this.selectedNote.isEditEnabled=true;
    this.selectedNote.orderType = this.orderService.orderDto.orderType;
    this.noteType = this.configService.getDefaultNoteTypeFromUserCode(this.configService.getMyMSId()) ?? null;
    this.selectedNote.noteType = this.noteType?.id ?? '';
    this.selectedNote.noteTypeText = this.noteType?.label ?? '';
    const reason = this.oeeReason.value === 'Other'? this.otherOEEReason : this.oeeReason.value;
    this.selectedNote.systemNote = 'Order Entry Error Reason- ' + reason;
    const index = orderNotes?.findIndex(o => o.id === this.selectedNote.id) ?? -1;
    if (index > -1) {
      orderNotes[index] = this.selectedNote;
    }
    else {
      orderNotes.push(this.selectedNote);
    }
    if(orderNotes)
    {
      this.orderService.orderDto.orderNotes = [...orderNotes];
    }
    this.dialog.show = false;

  }

  closeDialog(): void {
    this.dialog.show = false;
    this.orderService.triggerRevertSourceCodeOnReturn();
  }

  changeOEEReason(oeeReason: string): void {
    this.displayFreeFormTextBox = oeeReason === 'Other' ? true : false;
  }
}
