export class SaveOrderExceptionDto {
  orderId = 0;
  businessCategoryCode: string | null = null;
  subCategoryCode: string | null = null;
  competitorCode: string | null = null;
  otherCompetitor: string | null = null;
  isBidSituation: boolean | null = null;
  knowCompetitorsOffer: boolean | null = null;
  areTheirIssues: boolean | null = null;
  comments: string | null = null;
  finalMultiYearSave: boolean = false;
}
