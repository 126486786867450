import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, PercentPipe, TitleCasePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import {
  UITKAutocompleteModule, UITKCardModule, UITKCheckboxModule, UITKDatePickerModule, UITKDialogModule, UITKDropdownModule,
  UITKFieldsetModule, UITKFormFieldService, UITKInputModule, UITKInputService, UITKLoadingIndicatorModule, UITKNavigationModule,
  UITKNotificationModule, UITKPageNotificationModule, UITKPaginationModule, UITKPanelModule, UITKProgressBarModule,
  UITKRadioGroupModule, UITKSelectModule, UITKTabbedPanelsModule, UITKTableFeaturesModule, UITKTableModule,
  UITKTextAreaModule, UITKTextareaService, UITKTooltipModule, UITKFileUploadModule
} from '@uitk/angular';
import { HotkeyModule } from 'angular2-hotkeys';
import { LineTruncationLibModule } from 'ngx-line-truncation';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountsReceivableResultsComponent } from './components/accounts-receivable/accounts-receivable-results/accounts-receivable-results.component';
import { SendInvoicePdfComponent } from './components/accounts-receivable/accounts-receivable-results/send-invoice-pdf/send-invoice-pdf.component';
import { AccountsReceivableComponent } from './components/accounts-receivable/accounts-receivable.component';
import { InvoiceEditComponent } from './components/accounts-receivable/invoice-edit/invoice-edit.component';
import { ApprovalConfirmationComponent } from './components/approval-reject-confirmation/approval-confirmation/approval-confirmation.component';
import { ApproveRejectWithCommentsComponent } from './components/approval-reject-confirmation/approve-reject-with-comments/approve-reject-with-comments.component';
import { RejectConfirmationComponent } from './components/approval-reject-confirmation/reject-confirmation/reject-confirmation.component';
import { AddReturnToBatchComponent } from './components/batch-returns/add-return-to-batch/add-return-to-batch.component';
import { BatchReturnsComponent } from './components/batch-returns/batch-returns.component';
import { CashInquirySearchResultsComponent } from './components/cash-inquiry-search-results/cash-inquiry-search-results.component';
import { CashInquiryComponent } from './components/cash-inquiry/cash-inquiry.component';
import { CfaWebComponent } from './components/config-sets/cfa-web/cfa-web.component';
import { ChegsetComponent } from './components/config-sets/chegset/chegset.component';
import { ConfigSetsComponent } from './components/config-sets/config-sets.component';
import { DatafileComponent } from './components/config-sets/datafile/datafile.component';
import { DepositComponent } from './components/config-sets/deposit/deposit.component';
import { FeeAnalyzerComponent } from './components/config-sets/fee-analyzer/fee-analyzer.component';
import { ShippingComponent } from './components/config-sets/shipping/shipping.component';
import { SubscriptionComponent } from './components/config-sets/subscription/subscription.component';
import { WebAppComponent } from './components/config-sets/web-app/web-app.component';
import { AdditionalInformationComponent } from './components/confirm-authorizations/additional-information/additional-information.component';
import { ConfirmAuthorizationsComponent } from './components/confirm-authorizations/confirm-authorizations.component';
import { LargeTooltipComponent } from './components/confirm-authorizations/large-tooltip/large-tooltip.component';
import { SmallTooltipComponent } from './components/confirm-authorizations/small-tooltip/small-tooltip.component';
import { HomeComponent } from './components/home/home.component';
import { ItemSearchComponent } from './components/item-search/item-search.component';
import { OrderEntryMiniComponent } from './components/order-entry-mini/order-entry-mini.component';
import { AccountInformationComponent } from './components/order-entry/account-information/account-information.component';
import { ApprovalHistoryComponent } from './components/order-entry/approval-history/approval-history.component';
import { ApprovalRequirementsComponent } from './components/order-entry/approval-requirements/approval-requirements.component';
import { ContactChangePopupComponent } from './components/order-entry/contact-change-popup/contact-change-popup.component';
import { ItemSearchCatalogComponent } from './components/order-entry/item-search-catalog/item-search-catalog/item-search-catalog.component';
import { OrderByOrderEntryComponent } from './components/order-entry/order-by-order-entry/order-by-order-entry.component';
import { OrderDetailsGridComponent } from './components/order-entry/order-details-grid/order-details-grid.component';
import { OrderEntryFooterComponent } from './components/order-entry/order-entry-footer/order-entry-footer.component';
import { OrderEntryComponent } from './components/order-entry/order-entry.component';
import { OrderHeaderComponent } from './components/order-entry/order-header/order-header.component';
import { SalesPersonLookupPopupComponent } from './components/order-entry/order-header/sales-person-lookup-popup/sales-person-lookup-popup.component';
import { OrderSummarySectionComponent } from './components/order-entry/order-summary-section/order-summary-section.component';
import { ShipToOrderEntryComponent } from './components/order-entry/ship-to-order-entry/ship-to-order-entry.component';
import { ViewMultiYearOrdersComponent } from './components/order-entry/view-multi-year-orders/view-multi-year-orders.component';
import { AdditionalActionsComponent } from './components/order-inquiry/additional-actions/additional-actions.component';
import { AMAKeyComponent } from './components/order-inquiry/additional-actions/amakey/amakey.component';
import { GeneratePackingSlipComponent } from './components/order-inquiry/additional-actions/generate-packing-slip/generate-packing-slip.component';
import { InvoicesComponent } from './components/order-inquiry/additional-actions/invoices/invoices.component';
import { OrderNotesComponent } from './components/order-inquiry/additional-actions/order-notes/order-notes.component';
import { SaveEmailNotesComponent } from './components/order-inquiry/additional-actions/save-email-notes/save-email-notes.component';
import { PackingSlipNotesComponent } from './components/order-inquiry/additional-actions/packing-slip-notes/packing-slip-notes.component';
import { RemoveHoldComponent } from './components/order-inquiry/additional-actions/remove-hold/remove-hold.component';
import { ReturnsActionComponent } from './components/order-inquiry/additional-actions/returns-action/returns-action.component';
import { ShipAndProcessComponent } from './components/order-inquiry/additional-actions/ship-and-process/ship-and-process.component';
import { VoidOrderComponent } from './components/order-inquiry/additional-actions/void-order/void-order.component';
import { OrderFooterComponent } from './components/order-inquiry/order-footer/order-footer.component';
import { OrderGridComponent } from './components/order-inquiry/order-grid/order-grid.component';
import { OrderInquiryComponent } from './components/order-inquiry/order-inquiry.component';
import { OrderStatusComponent } from './components/order-inquiry/order-status/order-status.component';
import { OrderComponent } from './components/order-inquiry/order/order.component';
import { SendEmailComponent } from './components/order-inquiry/send-email/send-email.component';
import { SubscriptionPriceCalculationComponent } from './components/order-inquiry/subscription-price-calculation/subscription-price-calculation.component';
import { OrderSearchResultsComponent } from './components/order-search-results/order-search-results.component';
import { OrderSearchComponent } from './components/order-search/order-search.component';
import { AddNewReturnComponent } from './components/return-entry/add-new-return/add-new-return.component';
import { ArsLabelComponent } from './components/return-entry/add-new-return/ars-label/ars-label.component';
import { ReturnDetailActionComponent } from './components/return-entry/return-detail-action/return-detail-action.component';
import { ReturnDetailComponent } from './components/return-entry/return-detail/return-detail.component';
import { ReturnEntryComponent } from './components/return-entry/return-entry.component';
import { ReturnAdditionalActionsComponent } from './components/return-inquiry/return-additional-actions/return-additional-actions.component';
import { ReturnInquiryComponent } from './components/return-inquiry/return-inquiry.component';
import { ReturnSearchResultsComponent } from './components/return-inquiry/return-search-results/return-search-results.component';
import { ReturnComponent } from './components/return-inquiry/return/return.component';
import { SearchNavigationBarComponent } from './components/search-navigation-bar/search-navigation-bar.component';
import { SearchinvoiceByCCComponent } from './components/searchinvoice-by-cc/searchinvoice-by-cc.component';
import { SearchinvoiceSearchResultsComponent } from './components/searchinvoice-search-results/searchinvoice-search-results.component';
import { ShipmentHistoryComponent } from './components/shipment-history/shipment-history.component';
import { SubscriptionEditComponent } from './components/subscription-edit/subscription-edit.component';
import { SubscriptionHistoryComponent } from './components/subscription-history/subscription-history.component';
import { SubscriptionSearchResultsComponent } from './components/subscription-search-results/subscription-search-results.component';
import { SubscriptionSearchComponent } from './components/subscription-search/subscription-search.component';
import { AddNoteComponent } from './components/worklist/add-note/add-note.component';
import { ViewNoteComponent } from './components/worklist/view-note/view-note.component';
import { WorklistQueueSearchResultsComponent } from './components/worklist/worklist-queue-search-results/worklist-queue-search-results.component';
import { WorkListQueueComponent } from './components/worklist/worklist-queue.component';
import { BillToComponent } from './components/bill-to/bill-to.component';
import { ChangeshipToaddressComponent } from './dialogs/changeship-toaddress/changeship-toaddress.component';
import { ConfirmAdditionalContactsComponent } from './dialogs/confirm-additional-contacts/confirm-additional-contacts.component';
import { ContactSearchComponent } from './dialogs/contact-search/contact-search.component';
import { CouponComponent } from './dialogs/coupon/coupon.component';
import { ExceptionReasonComponent } from './dialogs/exception-reason/exception-reason.component';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { GridFilterComponent } from './components/grid-filter/grid-filter.component';
import { HeaderComponent } from './components/header/header.component';
import { ImpersonationComponent } from './components/header/impersonation/impersonation.component';
import { HoldReasonComponent } from './dialogs/hold-reason/hold-reason.component';
import { InvoiceResultsComponent } from './components/invoice-results/invoice-results.component';
import { ItemLevelOffersComponent } from './dialogs/item-level-offers/item-level-offers.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LogDialogComponent } from './dialogs/log-dialog/log-dialog.component';
import { OrderApprovalComponent } from './dialogs/order-approval/order-approval.component';
import { OrderByComponent } from './components/order-by/order-by.component';
import { OrderEntryErrorReasonDialogComponent } from './dialogs/order-entry-error-reason-dialog/order-entry-error-reason-dialog.component';
import { OrderLevelOffersOthersComponent } from './dialogs/order-level-offers-others/order-level-offers-others.component';
import { OrderLevelOffersTiersComponent } from './dialogs/order-level-offers-tiers/order-level-offers-tiers.component';
import { PostLogin } from './components/post-login/post-login.component';
import { PostLogout } from './components/post-logout/post-logout.component';
import { QuoteReasonComponent } from './dialogs/quote-reason/quote-reason.component';
import { SelectContactsComponent } from './dialogs/select-contacts/select-contacts.component';
import { ShipToComponent } from './components/ship-to/ship-to.component';
import { TokenizeComponent } from './components/tokenize/tokenize.component';
import { VoidItemDialogComponent } from './dialogs/void-item-dialog/void-item-dialog.component';
import { YesNoDialogComponent } from './dialogs/yes-no-dialog/yes-no-dialog.component';
import { AutofocusDirective } from './directives/auto-focus.directive';
import { BBLogDirective } from './directives/bb-log.directive';
import { DiscountFormatDirective } from './directives/discount-format.directive';
import { EnableResourceDirective } from './directives/enable-resource.directive';
import { HotkeyDirective } from './directives/hotkey.directive';
import { IdFormatDirective } from './directives/id-format.directive';
import { PriceFormatDirective } from './directives/price-format.directive';
import { QuantityFormatDirective } from './directives/quantity-format.directive';
import { ShowResourceDirective } from './directives/show-resource.directive';
import { TabTitleDirective } from './directives/tab-title.directive';
import { TextFormatDirective } from './directives/text-format.directive';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ToNumberPipe } from './pipes/to-number.pipe';
import { ConfigService } from './services/config.service';
import { CFDFormFooterSectionComponent } from './components/order-entry/quote-cfd/cfd-form-footer-section/cfd-form-footer-section.component';
import { CfdFormFieldSelectionSectionComponent } from './components/order-entry/quote-cfd/cfd-form-field-selection-section/cfd-form-field-selection-section.component';
import { QuoteCfdComponent } from './components/order-entry/quote-cfd/quote-cfd.component';
import { CfdFormAccountInfoComponent } from './components/order-entry/quote-cfd/cfd-form-account-info/cfd-form-account-info.component';
import { ContactSearchLookupComponent } from './components/order-entry/contact-search-lookup/contact-search-lookup.component';
import { AccountSearchLookupComponent } from './components/order-entry/account-search-lookup/account-search-lookup.component';
import { QuoteCfdFormEmailComponent } from './components/order-entry/quote-cfd/quote-cfd-form-email/quote-cfd-form-email.component';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { OrderOfferQualificationComponent } from './dialogs/order-offer-qualification/order-offer-qualification.component';
import { ShippingPopupOrderEntryComponent } from './components/order-entry/shipping-popup-order-entry/shipping-popup-order-entry.component';
import { CptComponent } from './components/config-sets/cpt/cpt.component';
import { ApplyChangesToFutureDialogComponent } from './dialogs/apply-changes-to-future-dialog/apply-changes-to-future-dialog.component';
import { ConfirmationEmailsComponent } from './components/order-entry/confirmation-emails/confirmation-emails.component';
import { ExpandDirective } from './directives/expand.directive';
import { QuoteCfdPdfComponent } from './components/order-entry/quote-cfd/quote-cfd-pdf/quote-cfd-pdf.component';
import { SaveConfirmationDialogComponent } from './dialogs/save-confirmation-dialog/save-confirmation-dialog.component';
import { CfdPhoneFormatPipe } from './pipes/cfd-phone-format.pipe';
import { RequireEmailDialogComponent } from './dialogs/require-email-dialog/require-email-dialog.component';
import { WebItemAllowOnlyOneDialogComponent } from './dialogs/web-item-allow-only-one-dialog/web-item-allow-only-one-dialog.component';
import { ContinueDuplicateItemDialogComponent } from './dialogs/continue-duplicate-item-dialog/continue-duplicate-item-dialog.component';
import { ScrollToDirective } from './directives/scrollTo.directive';
import { ViewPromotionsComponent } from './components/view-promotions/view-promotions.component';
import { ViewApprovalRequestsComponent } from './components/view-approval-requests/view-approval-requests.component';
import { ItemChangedPopupComponent } from './dialogs/item-changed-popup/item-changed-popup.component';
import { UpdateInvoicesFromOrderDialogComponent } from './dialogs/update-invoices-from-order-dialog/update-invoices-from-order-dialog.component';
import { SubscriptionOrderEntryComponent } from './components/config-sets/subscription-order-entry/subscription-order-entry.component';
import { AccountCommentsComponent } from './components/account-comments/account-comments.component';
import { AccountCommentUpdateComponent } from './components/account-comments/account-comment-update/account-comment-update.component';
import { SourceCodeComponent } from './components/source-code/source-code.component';
import { SourceCodeActionPopupComponent } from './components/source-code/source-code-action-popup/source-code-action-popup.component';
import { RecentQuotesOrOrdersDialogComponent } from './dialogs/recent-quotes-or-orders-dialog/recent-quotes-or-orders-dialog.component';
import { RecentQuoteOrOrderDetailsDialogComponent } from './dialogs/recent-quote-or-order-details-dialog/recent-quote-or-order-details-dialog.component';
import { AdvancedAccountSearchComponent } from './components/advanced-account-search/advanced-account-search.component';
import { OrderAttachmentsComponent } from './components/order-inquiry/additional-actions/order-attachments/order-attachments.component';
import { OrderAttachmentsFilePickerComponent } from './components/order-inquiry/additional-actions/order-attachments/order-attachments-file-picker/order-attachments-file-picker.component';
import { SubscriptionTrialPopUpComponent } from './components/subscription-trial-pop-up/subscription-trial-pop-up.component';
import { AdvancedContactSearchDialogComponent } from './dialogs/advanced-contact-search-dialog/advanced-contact-search-dialog.component';
import { AllowNegativePriceFormatDirective } from './directives/allow-negative-price-format.directive';
import { Ng2CompleterModule } from "ng2-completer";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SelectedTeplateEmailComponent } from './dialogs/selected-template-email/selected-teplate-email.component';
import { SelectTemplateComponent } from './dialogs/select-template/select-template.component';
import { ProFormaInvoiceComponent } from './components/pro-forma-invoice/pro-forma-invoice.component';
import { AddReturnAuthorizationComponent } from './components/return-entry/add-new-return/add-return-authorization/add-return-authorization.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ShipmentConfirmationEmailComponent } from './dialogs/shipment-confirmation-email/shipment-confirmation-email.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    OrderSearchComponent,
    OrderSearchResultsComponent,
    HotkeyDirective,
    LoaderComponent,
    OrderInquiryComponent,
    OrderComponent,
    OrderByComponent,
    ShipToComponent,
    BillToComponent,
    SendEmailComponent,
    ChangeshipToaddressComponent,
    SubscriptionPriceCalculationComponent,
    ConfigSetsComponent,
    DepositComponent,
    SubscriptionSearchComponent,
    SubscriptionEditComponent,
    SubscriptionComponent,
    FeeAnalyzerComponent,
    DatafileComponent,
    ChegsetComponent,
    WebAppComponent,
    ShipmentHistoryComponent,
    SubscriptionHistoryComponent,
    SubscriptionSearchResultsComponent,
    AdditionalActionsComponent,
    BatchReturnsComponent,
    ConfirmAuthorizationsComponent,
    ReturnComponent,
    ReturnInquiryComponent,
    ReturnAdditionalActionsComponent,
    ReturnSearchResultsComponent,
    PostLogin,
    PostLogout,
    AdditionalInformationComponent,
    AccountsReceivableComponent,
    AccountsReceivableResultsComponent,
    CashInquiryComponent,
    ReturnsActionComponent,
    ReturnEntryComponent,
    ReturnDetailComponent,
    ReturnDetailActionComponent,
    ImpersonationComponent,
    InvoiceEditComponent,
    AddNewReturnComponent,
    EnableResourceDirective,
    ScrollToDirective,
    BBLogDirective,
    ShowResourceDirective,
    CashInquiryComponent,
    CashInquirySearchResultsComponent,
    PackingSlipNotesComponent,
    SaveEmailNotesComponent,
    OrderNotesComponent,
    WorkListQueueComponent,
    ToNumberPipe,
    WorklistQueueSearchResultsComponent,
    AddNoteComponent,
    SendInvoicePdfComponent,
    InvoicesComponent,
    InvoiceResultsComponent,
    ViewNoteComponent,
    SendInvoicePdfComponent,
    SafeHtmlPipe,
    VoidOrderComponent,
    CfaWebComponent,
    DiscountFormatDirective,
    AutofocusDirective,
    QuantityFormatDirective,
    PriceFormatDirective,
    AllowNegativePriceFormatDirective,
    IdFormatDirective,
    TextFormatDirective,
    AddReturnToBatchComponent,
    AMAKeyComponent,
    SelectContactsComponent,
    VoidItemDialogComponent,
    TokenizeComponent,
    ShippingComponent,
    ArsLabelComponent,
    SearchinvoiceByCCComponent,
    SearchinvoiceSearchResultsComponent,
    SearchNavigationBarComponent,
    OrderFooterComponent,
    OrderGridComponent,
    OrderStatusComponent,
    ShipAndProcessComponent,
    RemoveHoldComponent,
    HoldReasonComponent,
    QuoteReasonComponent,
    OrderEntryMiniComponent,
    YesNoDialogComponent,
    SmallTooltipComponent,
    LargeTooltipComponent,
    LogDialogComponent,
    TabTitleDirective,
    ContactSearchComponent,
    PhoneFormatPipe,
    ItemSearchComponent,
    OrderEntryErrorReasonDialogComponent,
    GeneratePackingSlipComponent,
    GridFilterComponent,
    FilterBarComponent,
    ExpandDirective,
    ViewPromotionsComponent,
    FilterBarComponent,
    QuoteReasonComponent,
    ExceptionReasonComponent,
    OrderEntryComponent,
    YesNoDialogComponent,
    OrderHeaderComponent,
    AccountInformationComponent,
    ViewMultiYearOrdersComponent,
    ShipToOrderEntryComponent,
    SalesPersonLookupPopupComponent,
    OrderSummarySectionComponent,
    OrderByOrderEntryComponent,
    OrderDetailsGridComponent,
    PhoneFormatPipe,
    OrderEntryFooterComponent,
    OrderLevelOffersOthersComponent,
    ItemLevelOffersComponent,
    OrderLevelOffersTiersComponent,
    ItemSearchCatalogComponent,
    CouponComponent,
    ConfirmAdditionalContactsComponent,
    ApprovalHistoryComponent,
    OrderApprovalComponent,
    ApprovalRequirementsComponent,
    ApprovalConfirmationComponent,
    RejectConfirmationComponent,
    ApproveRejectWithCommentsComponent,
    ContactChangePopupComponent,
    CFDFormFooterSectionComponent,
    CfdFormFieldSelectionSectionComponent,
    QuoteCfdComponent,
    CfdFormAccountInfoComponent,
    ContactSearchLookupComponent,
    AccountSearchLookupComponent,
    QuoteCfdFormEmailComponent,
    HighlightSearchPipe,
    OrderOfferQualificationComponent,
    ShippingPopupOrderEntryComponent,
    CptComponent,
    ApplyChangesToFutureDialogComponent,
    ConfirmationEmailsComponent,
    QuoteCfdPdfComponent,
    SaveConfirmationDialogComponent,
    CfdPhoneFormatPipe,
    RequireEmailDialogComponent,
    WebItemAllowOnlyOneDialogComponent,
    ContinueDuplicateItemDialogComponent,
    ItemChangedPopupComponent,
    ViewPromotionsComponent,
    ViewApprovalRequestsComponent,
    AccountCommentsComponent,
    AccountCommentUpdateComponent,
    SourceCodeComponent,
    SourceCodeActionPopupComponent,
    UpdateInvoicesFromOrderDialogComponent,
    SubscriptionOrderEntryComponent,
    AccountCommentsComponent,
    AccountCommentUpdateComponent,
    SourceCodeComponent,
    SourceCodeActionPopupComponent,
    AdvancedAccountSearchComponent,
    OrderAttachmentsComponent, 
    OrderAttachmentsFilePickerComponent,
    SubscriptionTrialPopUpComponent,
    RecentQuotesOrOrdersDialogComponent,
    RecentQuoteOrOrderDetailsDialogComponent,
    AdvancedContactSearchDialogComponent,
    SelectedTeplateEmailComponent,
    SelectTemplateComponent,
    ProFormaInvoiceComponent,
    AddReturnAuthorizationComponent,
    ReportsComponent,
    ShipmentConfirmationEmailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    UITKInputModule,
    UITKNavigationModule,
    UITKTableModule,
    UITKFieldsetModule,
    UITKSelectModule,
    UITKPanelModule,
    HotkeyModule.forRoot(),
    UITKLoadingIndicatorModule,
    UITKTableModule,
    CommonModule,
    HttpClientModule,
    UITKTableFeaturesModule,
    UITKPaginationModule,
    UITKInputModule,
    UITKDropdownModule,
    UITKDialogModule,
    NgbPopoverModule,
    UITKPageNotificationModule,
    UITKProgressBarModule,
    UITKTextAreaModule,
    UITKDatePickerModule,
    UITKRadioGroupModule,
    UITKCheckboxModule,
    FormsModule,
    UITKTooltipModule,
    UITKNotificationModule,
    UITKTabbedPanelsModule,
    MatMenuModule,
    MatIconModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot(),
    NgbModule,
    UITKDatePickerModule,
    LineTruncationLibModule,
    UITKAutocompleteModule,
    UITKCardModule,
    UITKFileUploadModule,
    Ng2CompleterModule,    
    DragDropModule    
  ],
  providers: [
    SafeHtmlPipe,
    ToNumberPipe,
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    TitleCasePipe,
    Title,
    PercentPipe,
    UITKFormFieldService,
    UITKInputService,
    UITKTextareaService,
    ConfigService,
    { provide: APP_INITIALIZER, useFactory: (config: ConfigService) => () => config.load(), deps: [ConfigService], multi: true }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
