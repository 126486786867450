import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Mode } from "../enums/mode.enum";
import { ModeService } from "../services/mode.service";
import { OrderService } from "../services/order.service";

@Injectable({
    providedIn: 'root',
})

export class OrderEntryEditGuard implements CanActivate {
    orderEntryScreenChangeMessage = 'Any changes will be lost if you leave this page without saving. Do you want to continue?';

    constructor(private readonly router: Router,
        private readonly modeService: ModeService,
        private readonly orderService: OrderService) {
    }

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        if (this.router.url.includes('order-entry') && this.modeService.mode === Mode.Edit && this.orderService.orderDto.isOrderEntryContentChanged) {
            if (!confirm(this.orderEntryScreenChangeMessage)) {
                return false;
            }
        }
        
        return true;
    }
}