<div *ngIf="dialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" [closeBtn]=false style="width: fit-content;">
  <uitk-dialog-header>
    <div cdkDragHandle>
      <h4 class="modal-title pull-left">
        Duplicate
      </h4>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <span>
      The item {{ duplicateOrderDetail.productCode }} has already been entered in the cart. Continue entering the duplicate item?
    </span>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button id="btnDuplicateItemYes" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
      (click)="allowDuplicateItem()">
      Yes
    </button>
    <button id="btnDuplicateItemNo" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
      (click)="stopDuplicateItem()">
      No
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>