<div class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkId="returnAction" [(showModal)]="dialog.show" [closeBtn]=true>
  <uitk-dialog-header>
    <div cdkDragHandle>
      Returns
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>

    <div class="uitk-c-table--responsive">
      <table uitkTable [dataSource]="returnDtos" class="uitk-c-table" id="returns-table">
        <thead class="uitk-c-table__head">
          <tr style="text-align: left">
            <th *ngFor="let headerName of tableHeader" scope="col">
              {{ headerName.name }}
            </th>
          </tr>
        </thead>
        <tbody *ngIf="modeService.isWaiting===true">
          <div class="uitk-l-grid--max-width">
            <div id="loadingIndicator">
              <uitk-loading-indicator uitkId="spinner" [visibleText]="visibleTextValue" spinnerSize="large">
              </uitk-loading-indicator>
            </div>
          </div>
        </tbody>
        <tbody id="row-data" class="uitk-c-table__body" *ngIf="modeService.isWaiting === false">
          <ng-container *uitkTableRowBody="let records">
            <tr class="uitk-c-table__row">
              <td><a id="returnInquiryNavigation" class="link" (click)="navigateToReturnInquiry(records.returnId)">{{records.returnId}}</a></td>
              <td>{{ records.accountId  }}</td>
              <td>{{ records.returnDate | date: 'MM/dd/yyyy' }}</td>
              <td>{{ records.terms }}</td>
              <td>{{ records.last4CC }}</td>
              <td>{{ records.shippedAmount | currency }}</td>
              <td>{{ records.poNumber }}</td>
              <td>{{ records.returnType }}</td>
              <td>{{ records.returnStatus === ' ' ? 'O' : records.returnStatus }}</td>
              <td>{{ records.returnCode }}</td>
              <td>{{ records.enteredBy }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

  </uitk-dialog-content>

  <uitk-dialog-actions>
    <button id="newReturnButton" type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
            appEnableResource="OrderInquiryAdditionalActionsAddReturn"
            (click)="navigateToReturnEntry()" [disabled]="!enableAddNewReturn || orderService.orderDto.pendingDate !== null"
            appTabTitle = "Return Entry">
      Add New Return
    </button>
  </uitk-dialog-actions>


</uitk-dialog>
  </div>
</div>
