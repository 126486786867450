
<div class="container-fluid px-0">
  <!-- Header of the Bill To section -->
  <div class="row">
    <div class="col d-flex">
      <div class="borderline"></div>
      <div class="flex-grow-1 align-self-center mt-2">
        <h5 class="text-dark"><strong>Bill To</strong></h5>
      </div>
      <div class="uitk-icon uitk-icon-refresh sm-icon align-self-center">
        <a (click)="refreshBillTo()" class="link"  appEnableResource="BillToRefreshHyperlink"> Refresh</a>
      </div>
      <div class="uitk-icon uitk-icon-edit sm-icon align-self-center mx-2">
        <a (click)="navigateToSFEditAccount()" appEnableResource="BillToEditHyperlink" class="link" target="_blank"> Edit</a>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <hr class="m-0 bg-dark">
    </div>
  </div>

  <!-- Account ID -->
  <div class="row px-3" [hidden]="modeService.waitDescription === 'Getting bill to information'">
    <div class="col">
      <div class="row my-0 py-0 gx-0">
        <label class="col-lg-4">Account ID</label>
        <div class="col-lg-8">
          {{ orderService.orderDto.billToDto.id }}
        </div>
      </div>
    </div>
  </div>
  <!-- Account Name -->
  <div class="row px-3 mt-2" [hidden]="modeService.waitDescription === 'Getting bill to information'">
    <div class="col">
      <a id="accountName" target="_blank" class="link" appEnableResource="BillToAccountName"
        (click)="navigateToSFViewAccount()">{{orderService.orderDto.billToDto.company}}</a>
    </div>
  </div>

  <!-- Department -->
  <div class="row px-3" [hidden]="modeService.waitDescription === 'Getting bill to information' || !orderService.orderDto.billToDto.department">
    <div class="col">
      {{ orderService.orderDto.billToDto.department }}
    </div>
  </div>

  <!-- Address -->
  <div class="row px-3 my-2" [hidden]="modeService.waitDescription === 'Getting bill to information'">
    <div class="col">
      {{orderService.orderDto.billToDto.street}}<br>
      {{orderService.orderDto.billToDto.city}}, {{orderService.orderDto.billToDto.state}}, {{orderService.orderDto.billToDto.zip}}<br>
      <div *ngIf="orderService.orderDto.billToDto.country !== 'US'">{{orderService.orderDto.billToDto.country}}</div>
    </div>
  </div>

  <!-- Speciality -->
  <div class="row px-3" [hidden]="modeService.waitDescription === 'Getting bill to information'">
    <div class="col">
      <div class="row my-0 py-0">
        <div class="col">
          <div *ngIf="!this.billToService.specialties?.length">Speciality: None</div>
          <uitk-tooltip uitkId="my-basic-input-tooltip" [tooltipContent]="this.billToService.tooltipContent" tooltipPlacement="top-left" *ngIf="this.billToService.specialties?.length > 0">
            <span id="tooltip">Speciality: {{this.billToService.specialties}}</span>
          </uitk-tooltip>
        </div>
      </div>
    </div>
  </div>

  <!-- Spinner -->
  <div class="container-fluid" [hidden]="modeService.waitDescription !== 'Getting bill to information'">
    <div class="row">
      <div class="col">
        <div id="loadingIndicator">
          <uitk-loading-indicator uitkId="spinner" [visibleText]="visibleTextValue" spinnerSize="large">
          </uitk-loading-indicator>
        </div>
      </div>
    </div>
  </div>
</div>


