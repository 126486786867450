import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CampaignDto } from '../dtos/campaign-dto.model';
import { ConfigService } from './config.service';
import { ErrorHandlingService } from './error-handling.service';
import { ModeService } from './mode.service';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {
  swalAlert = new SwalAlert();
  constructor(
    private readonly configService: ConfigService,
    private readonly modeService: ModeService,
    private readonly errorHandlingService: ErrorHandlingService,
    private readonly http: HttpClient
    ) { }

  async getCampaign(promotionCode: string): Promise<CampaignDto | null> {
    return new Promise(resolve => {
      const waitingFor = 'Getting promotion details.'
      this.modeService.addToWaitList(waitingFor);
      this.http.get<CampaignDto>(`${this.configService.discountApiUrl}/Discount/GetCampaign?promotionCode=${promotionCode}`).subscribe({
        next: data => {
          this.modeService.removeFromWaitList(waitingFor);
          resolve(data);
        },
        error: async e => {
          console.log(e);
          this.modeService.removeFromWaitList(waitingFor);
          await this.swalAlert.alert(this.errorHandlingService.getErrorMessage(e));
          resolve(null);
        }
      });
    });
  }
}
