<div *ngIf="dialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkId="ChangeShipToAdd" [(showModal)]="dialog.show" [closeBtn]=false [triggerElementId]="orderService.shippingDialogModalTriggerEle" (onModalHidden)="closeModal()">
    <uitk-dialog-header>
      <div cdkDragHandle>
        Select Contacts
      </div>
    </uitk-dialog-header>
    <uitk-dialog-content scrollToTarget>
      <div class="float-end">
        <span class ="uitk-icon uitk-icon-add sm-icon mx-2">
          <a (click) ="addContactList(orderService.orderDto.billToDto.salesforceId)" class="link">
            Add</a>
        </span>
        <span class ="uitk-icon uitk-icon-refresh sm-icon">
          <a (click) ="refreshContactList()" class="link">
            Refresh</a></span>  
      </div>

      <body style="width: 600px;">
        <uitk-loading-indicator uitkId ="spinner" [hidden]="displayResults" [visibleText]="visibleTextValue" spinnerSize="large">
        </uitk-loading-indicator>
      </body>

      <div class="uitk-c-table--responsive" [hidden] = "!displayResults">
        
        <table uitkTable [dataSource]="dataSource" class="uitk-c-table" id="select-contacts-table">
          <thead class="uitk-c-table__head">
            <tr>
              <th id="chkBox" style="padding-left: 9.5px">
                <input type="checkbox" class="checkBoxInGrid" style="position: relative; height: 20px; width: 20px;" name="selectallCheckBox" [(ngModel)]="selectAllContacts" (ngModelChange)="selectAll()" />
              </th>
              <th *ngFor="let headerName of tableHeader" scope="col" id={{headerName.id}}>
                {{ headerName.name }}
              </th>
            </tr>
          </thead>
          <tbody id="row-data" class="uitk-c-table__body" [hidden]="modeService.mode === Mode.Wait">
            <ng-container *uitkTableRowBody="let data">
              <tr class="uitk-c-table__row">
                <td><input type="checkbox" *ngIf="data.email !==''" value="" class="checkBoxInGrid" style="position: relative; height: 20px; width: 20px;" name="" [(ngModel)]=data.isSelected (ngModelChange)="updateSelectedContactList(data)" /></td>
                <td>
                  <a (click)="navigateToSFNewOrEditContact(data.salesforceId)"
                     class="link"  target="_blank">{{ data.contactId }}</a>
                </td>
                <td>{{ data.firstName }}</td>
                <td>{{ data.lastName }}</td>
                <td>{{ data.email }}</td>
                <td>{{ data.title }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div class="uitk-l-grid--max-width">
          <div id="loadingIndicator" [hidden]="modeService.mode !== Mode.Wait">
            <uitk-loading-indicator uitkId="spinner" [visibleText]="visibleTextValue" spinnerSize="large">
            </uitk-loading-indicator>
          </div>
        </div>
        <div *ngIf="data.length === 0 && modeService.mode !== Mode.Wait">No records found</div>
      </div>
    </uitk-dialog-content>
    <uitk-dialog-actions>

        <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" [disabled] = "modeService.mode === Mode.Wait || !orderService.displayShipToLinkSaveButton" (click)="saveSelectedContacts()" #saveBtn appAutoFocus>
            Save
        </button>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" [disabled]="modeService.mode === Mode.Wait" (click)="closeDialog()">
            Cancel
        </button>
    </uitk-dialog-actions>
   
</uitk-dialog>
  </div>
</div>