<div *ngIf="dialog.show" class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" uitkId="couponItemCheckModal" [closeBtn]=false>
    <uitk-dialog-header>
        <div cdkDragHandle>
            <h4 class="modal-title pull-left">
                Coupon Total
            </h4>
        </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
        <p>The value of the coupon(s) exceeds the Order Total Amount by -${{ this.orderService.couponExcessAmount.toFixed(2) }}</p>
        <br>
        Do you wish to reduce the value of the coupon?
    </uitk-dialog-content>
    <uitk-dialog-actions>
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="couponYesClick()">
        Yes
        </button>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="couponNoClick()">
        No
        </button>
    </uitk-dialog-actions>
</uitk-dialog>
    </div>
</div>