import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { CfaWebDto } from '../../../dtos/cfa-web-dto.model';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { ProductDto } from '../../../dtos/product-dto.model';
import { ConfigService } from '../../../services/config.service';
import { ConfigurationService } from '../../../services/configurations.service';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';
import { GeozipGrid } from '../../../dtos/geozip-grid.model';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-cfa-web',
  templateUrl: './cfa-web.component.html',
  styleUrls: ['./cfa-web.component.css']
})

export class CfaWebComponent implements OnInit, OnDestroy {
  private readonly _configService: ConfigService;
  private readonly _configurationService: ConfigurationService;
  private readonly _pricingService: PricingService;
  @Input() orderDetail: OrderDetailDto = new OrderDetailDto();
  @Output() closeEvent = new EventEmitter<any>();
  @Output() enableSave = new EventEmitter<boolean>();
  @ViewChild('zipCodeInput') zipCodeInput: ElementRef | undefined;
  geoZipDtoIsReadySubscription: Subscription | undefined;
  getSpecialitiesIsReadySubscription: Subscription | undefined;
  geoZipGridData: GeozipGrid[] = [];
  specialityList: IUITKSelectItemProps[] = [
    { id: "AN", label: "All CPT Codes / Multi-Specialty", value: "AN" }
  ];
  organizationInfoList = [
    { id: 'Provider - Hospital', label: 'Hospital - Hospital', value: 'Provider - Hospital' },
    { id: 'Provider - PHO', label: 'Hospital - Physician Hosp. Org. (PHO)', value: 'Provider - PHO' },
    { id: 'Provider - Hospital Consultant', label: 'Hospital - Consultant for Hospital', value: 'Provider - Hospital Consultant' },
    { id: 'Payer - Insurance', label: 'Payer - Insurance Company', value: 'Payer - Insurance' },
    { id: 'Payer - HMO', label: 'Payer - HMO, IPA, PHO, PPO, IDN', value: 'Payer - HMO' },
    { id: 'Payer - Consultant', label: 'Payer - Consultant for Payer/Insurance Company', value: 'Payer - Consultant' },
    { id: 'Provider = Clinic', label: 'Physician - Clinic, Multiple Physician Organization', value: 'Provider = Clinic' },
    { id: 'Provider - Physician', label: 'Physician - Single Physician', value: 'Provider - Physician' },
    { id: 'Provider - Physician Consultant', label: 'Physician - Consultant for Physician', value: 'Provider - Physician Consultant' },
    { id: 'Provider - Billing', label: 'Physician - Billing Company for Physician', value: 'Provider - Billing' },
    { id: 'Provider - MSO', label: 'Physician - Managememnt Services Organization (MSO)', value: 'Provider - MSO' },
    { id: 'Reseller', label: 'Reseller', value: 'Reseller' }
  ];
  selectedOrganization: IUITKSelectItemProps = { id: 'Select', label: 'Select', value: 'Select' };
  selectedSpecialty: IUITKSelectItemProps = { id: "AN", label: "All CPT Codes / Multi-Specialty", value: "AN" };
  additionalGeoZipProduct: ProductDto = new ProductDto();
  hasWrongZipVal = false;
  validZipCode = true;
  companyName = '';
  codeType = 'MED';
  zipCode = '';
  tableHeader = [
    { name: 'Action', id: 'action' },
    { name: 'Speciality', id: 'speciality' },
    { name: 'Zip Code', id: 'zipcode' },
    { name: 'GeoZip', id: 'geozip' },
  ];
  swalAlert = new SwalAlert();

  constructor(
    configService: ConfigService,
    configurationService: ConfigurationService,
    pricingService: PricingService,
    public orderService: OrderService) {
    this._configService = configService;
    this._configurationService = configurationService;
    this._pricingService = pricingService;
  }

  ngOnInit(): void {
    this.enableSave.emit(this.orderDetail.cfaWebDtos.length > 0);

    // Set default company name.
    this.companyName = this.orderDetail.cfaWebDtos[0]?.company || '';

    if (this.companyName === '') {
      if (this.orderService.orderDto.shipToDto.company !== ''  && this.orderService.orderDto.shipToDto.company !== null) {
        this.companyName = this.orderService.orderDto.shipToDto.company;
      }
      else {
        this.companyName = this.orderService.orderDto.billToDto.company;
      }
    }

    // Set default zip code.
    if (this.orderService.orderDto.shipToDto.zip !== '' && this.orderService.orderDto.shipToDto.zip !== null) {
      this.zipCode = `${this.orderService.orderDto.shipToDto.zip.substring(0, 5)}`;
    }
    else {
      this.zipCode = `${this.orderService.orderDto.billToDto.zip.substring(0, 5)}`;
    }

    this._configurationService.getDataFileAddons(this.orderDetail.productId)
      .subscribe(data => {
        this.additionalGeoZipProduct = this._configService.reference?.productDtos.filter(item => item.id === data[0].addonProductId)[0] ?? new ProductDto;
      });

    // Add any existing geozips to the grid.
    for (const cfaWebDto of this.orderDetail.cfaWebDtos) {
      const geozipGridItem: GeozipGrid = {
        id: 0,
        zipCode: cfaWebDto.zip,
        speciality: 'AN',
        geoZip: cfaWebDto.geoZip
      }

      this.geoZipGridData.push(geozipGridItem);
    }
    this.geoZipGridData = [...this.geoZipGridData];

    // After finding the geozip records in the database, add the record to the grid.
    this.geoZipDtoIsReadySubscription = this._configService.geoZipDtoIsReady.subscribe(() => {
      if (this.geoZipGridData.some(item => item.geoZip === this._configService.geoZipDto.geozip)) {
        this.swalAlert.alert(`Geozip of ${this._configService.geoZipDto.geozip} was already added.  Geozips must be unique.`);
        return;
      }

      const geozipGridItem: GeozipGrid = {
        id: 0,
        zipCode: this.zipCode,
        speciality: this.selectedSpecialty.value,
        geoZip: this._configService.geoZipDto.geozip
      }

      this.geoZipGridData.push(geozipGridItem);
      this.geoZipGridData = [...this.geoZipGridData];

      this.enableSave.emit(true);

    }, error => {
      console.log(error);
      this.swalAlert.alert('Error occurred while finding geozip.');
    });
  }

  clickadd(): void {
    this.validZipCode = (this.zipCode.length === 3 || this.zipCode.length === 5) && new RegExp(/^\d+$/).test(this.zipCode);

    if (this.validZipCode) {
      this._configService.getGeoZipDetails(this.codeType, this.zipCode);
    }

    // Put focus back on the zip code.
    this.zipCodeInput?.nativeElement.focus();
  }

  onDeleteClick(rowToDelete: GeozipGrid): void {
    this.geoZipGridData = this.geoZipGridData.filter(item => item !== rowToDelete);
    this.enableSave.emit(this.geoZipGridData.length > 0);
  }

  onChangeOrganizationCode(event: any) {
    if (event.value.value === 'Reseller' || event.value.value.startsWith('Payer')) {
      this.enableSave.emit(false);
    }
    else {
      this.enableSave.emit(this.geoZipGridData.length > 0);
    }
  }

  save(): boolean {
    this.orderDetail.cfaWebDtos = [];

    for(const geoZipGrid of this.geoZipGridData) {
      const cfaWebDto: CfaWebDto = {
        company: this.companyName,
        geoZip: geoZipGrid.geoZip,
        zip: geoZipGrid.zipCode
      };

      this.orderDetail.cfaWebDtos.push(cfaWebDto);
    }

    // If the user entered multiple geozips, it needs to add an item quantity for each geozip after the first one.
    // Each geozip also needs a license for each main item ordered.
    const mainItemQuantity =
      this.orderService.orderDto.orderDetailDtos
      .filter(item => (item.productCode === this.orderDetail.productCode || item.productCode === 'V' + this.orderDetail.productCode) && item.orderDetailStatus !== 'V')
      .reduce((sum, item) => sum + item.orderQuantity, 0);
      ;
    const currentQuantity =
      this.orderService.orderDto.orderDetailDtos
      .filter(item => item.parentOrderDetailId === this.orderDetail.id && !item.productCode.startsWith('V') && item.orderDetailStatus !== 'V')
      .reduce((sum, item) => sum + item.orderQuantity, 0) +
      mainItemQuantity;
    const newQuantity = this.orderDetail.cfaWebDtos.length * mainItemQuantity;
    const quantityToAdd = newQuantity - currentQuantity;

    if (quantityToAdd > 0) {
      // Adjust an existing open item if available.
      const openItem: OrderDetailDto = this.orderService.orderDto.orderDetailDtos
      .filter(item => item.parentOrderDetailId === this.orderDetail.id && item.orderDetailStatus === ' ')[0];
      if (openItem) {
        openItem.openQuantity += quantityToAdd;
      } else {

        const additionalGeoZipItem = this.orderService.createNewOrderDetail(this.orderService.orderDto, this.additionalGeoZipProduct.productCode);

        additionalGeoZipItem.parentOrderDetailId = this.orderDetail.id;
        additionalGeoZipItem.orderDetailStatus = ' ';
        additionalGeoZipItem.openQuantity = quantityToAdd;
        additionalGeoZipItem.requiredDate = this.orderDetail.requiredDate;
        additionalGeoZipItem.enteredBy = this.orderDetail.enteredBy;
        additionalGeoZipItem.salespersonCode = this.orderDetail.salespersonCode;
        additionalGeoZipItem.isElectronic = true;
        additionalGeoZipItem.isPhysical = false;

        // Recalculate list price and discount to ensure we calculate based on tiered pricing and SPL discounts
        additionalGeoZipItem.listPrice = this._pricingService.calculateListPricing(this.orderService.orderDto, additionalGeoZipItem);
        
        if (additionalGeoZipItem.promotionEntryCode !== 'ILP' && additionalGeoZipItem.promotionEntryCode !== 'ILT' &&
          additionalGeoZipItem.promotionEntryCode !== 'OLT' && additionalGeoZipItem.promotionEntryCode !== 'OLP') {
          additionalGeoZipItem.discount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, this.additionalGeoZipProduct.id);
        }        

        const noOfaddOns = this.orderService.orderDto.orderDetailDtos.filter(item => item.parentOrderDetailId === this.orderDetail.id).length;
        const index = this.orderService.orderDto.orderDetailDtos.findIndex(item => item === this.orderDetail);        
        this.orderService.orderDto.orderDetailDtos.splice(index + 1 + noOfaddOns, 0, additionalGeoZipItem);
        this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
      }
    } else if (quantityToAdd < 0) {
      // Reduce the existing open item if available, otherwise do nothing.
        this.reduceOpenItem(quantityToAdd);
    }

    return true;
  }

  reduceOpenItem(quantityToAdd:any){
    const openItem: OrderDetailDto = this.orderService.orderDto.orderDetailDtos
    .filter(item => item.parentOrderDetailId === this.orderDetail.id && item.orderDetailStatus === ' ')[0];
    if (openItem && openItem.openQuantity > -quantityToAdd) {
      openItem.openQuantity += quantityToAdd;
    } else if (openItem && openItem.openQuantity === -quantityToAdd) {
      // If the quantity to remove equals the open quantity of the remaining line item, delete or void the additional line item.
      this.orderService.deleteOrVoidOrderDetail(openItem);
    }
  }

  ngOnDestroy(): void {
    this.getSpecialitiesIsReadySubscription?.unsubscribe();
    this.geoZipDtoIsReadySubscription?.unsubscribe();
  }
}
