import { Component, Input, OnInit } from '@angular/core';
import { OrderDetailsGridComponent } from '../../components/order-entry/order-details-grid/order-details-grid.component';
import { SubscriptionPriceCalculationComponent } from '../../components/order-inquiry/subscription-price-calculation/subscription-price-calculation.component';
import { Dialog } from '../../helpers/dialog';
import { OrderDetailDto } from '../../dtos/order-detail-dto.model';
import { PremiumDto } from '../../dtos/premium-dto';
import { TierDto } from '../../dtos/tier-dto.model';
import { ApprovalService } from '../../services/approval.service';
import { ConfigService } from '../../services/config.service';
import { OrderService } from '../../services/order.service';
import { PricingService } from '../../services/pricing.service';
import { ShippinghandlingService } from '../../services/shippinghandling.service';

@Component({
  selector: 'app-order-level-offers-others',
  templateUrl: './order-level-offers-others.component.html',
  styleUrls: ['./order-level-offers-others.component.css'],
  providers: [OrderDetailsGridComponent, SubscriptionPriceCalculationComponent]
})

export class OrderLevelOffersOthersComponent implements OnInit {
  private readonly _configService: ConfigService;
  private readonly _approvalService: ApprovalService;
  private readonly _orderDetailsGridComponent: OrderDetailsGridComponent;
  private readonly _shippinghandlingService: ShippinghandlingService;
  @Input() orderLevelOffersOthersDialog!: Dialog<string>;
  configSetDialog = new Dialog<string>();
  myDialogHeader = 'Order Level Offers';
  acceptOfferText = 'Accept This Offer';
  doNotAcceptOfferText = 'Do Not Accept This Offer';
  returnToCartText = 'Return to Cart';
  remainingPurchase = 0;
  qualifyingQuantity = 0;
  minimumQualifyingQuantity = 0;
  premiumOrderDetail = new OrderDetailDto();
  orderDetailDtos: OrderDetailDto[] = [];
  tierDiscountType: string = '';
  qualifyingTier: TierDto | undefined;
  selectAllPremium = false;
  orderLevelOfferHeader = ['Item', 'Description', 'Qty', 'List Price', 'Disc', 'New Price'];

  constructor(public orderService: OrderService,
    configService: ConfigService,
    public pricingService: PricingService,
    approvalService: ApprovalService,
    orderDetailsGridComponent: OrderDetailsGridComponent,
    shippinghandlingService: ShippinghandlingService) {
    this._configService = configService;
    this._approvalService = approvalService;
    this._orderDetailsGridComponent = orderDetailsGridComponent;
    this._shippinghandlingService = shippinghandlingService;
  }

  ngOnInit(): void {
    if (this.orderService.orderDto.orderOfferDto?.tiers) {
      this.orderService.orderDto.minimumPromotionQuantity = [...this.orderService.orderDto.orderOfferDto?.tiers].sort((a, b) => 0 - (a.from > b.from ? -1 : 1))[0].from;
    }

    this.orderService.orderDto.orderOfferDto?.premiums.forEach(element => {
      const isPremiumExists = this.orderService.orderDto.orderDetailDtos.some(o => o.productId === element.productId && o.promotionEntryCode === 'OLP');
      element.isSelected = isPremiumExists ? true : false;
    });

    if (!this.orderService.orderDto.orderOfferDto?.premiums.some(p => p.isSelected === false)) {
      this.selectAllPremium = true;
    }

    if (this.orderService.orderDto.orderOfferDto?.discountAndPremium === false) {
      this.tierDiscountType = this.orderService.orderDto.orderDetailDtos.some(o => o.promotionEntryCode === 'OLP') ? 'premiums' : 'orderdiscount';
    }

    if (this.orderService.orderDto.orderOfferDto) {
      this.orderService.orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V').forEach(orderDetail => {
        const productQualifies = this.orderService.orderDto.orderOfferDto?.productIds.some(productId => productId === orderDetail.productId);

        if (productQualifies) {
          orderDetail.promotionEntryCode = 'OLT';
          this.qualifyingQuantity += orderDetail.orderQuantity;
        }
      });

      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.checkOfferCategorySlap();
    }
  }

  async acceptOffer(): Promise<void> {
    if (!this.orderService.orderDto.orderOfferDto) {
      this.orderLevelOffersOthersDialog.close('Yes');
      this.removeEmptyRecordAddAtLast();
      return;
    }

    if (this.orderService.orderDto.orderOfferDto?.discountAndPremium === false) {
      if (this.tierDiscountType === 'orderdiscount') {
        this.orderService.orderDto.tierDiscountType = 'orderdiscount';
        const premiumProductIdsTobeDeleted = this.orderService.orderDto.orderDetailDtos.filter(o => o.promotionEntryCode === 'OLP').map(o => o.id);
        this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(o => !premiumProductIdsTobeDeleted.includes(o.id));
        this.applyDiscountOnOrderDetailDtos();
      }
      else if (this.tierDiscountType === 'premiums') {
        // User selected any one of the premiums listed.
        this.orderService.orderDto.tierDiscountType = 'premiums';

        this.orderService.orderDto.orderDetailDtos.filter(o => o.promotionEntryCode === 'OLT' && o.orderDetailStatus !== 'V').forEach(item => {
          item.discount = this.pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, item.productDto.id);
          this.pricingService.calculateUnitPrice(item);
          this.pricingService.calculateExtendedPrice(item);
        });

        await this.addPremiumsToCart();
      }
    } else {
      // User accepts discount and selects one or more premiums.
      this.orderService.orderDto.tierDiscountType = '';
      this.applyDiscountOnOrderDetailDtos();
      await this.addPremiumsToCart();
    }

    this.orderService.orderDto.hasUserSeenPromoForm = true;
    this.orderService.orderDto.orderOfferQualificationsChangedPopupButton === '';
    this.orderService.orderDto.promotionOfferPopupButton = 'Accept Promo Offer';
    this.removeEmptyRecordAddAtLast();
    this._shippinghandlingService.premiumShippingItem = new PremiumDto();
    this.orderLevelOffersOthersDialog.close('Yes');
  }

  doNotAcceptOffer(): void {
    this.orderService.orderDto.hasUserSeenPromoForm = true;
    this.orderService.orderDto.orderOfferQualificationsChangedPopupButton === '';
    this.orderService.orderDto.promotionOfferPopupButton = 'Decline Promo Offer';

    for (const orderDetail of this.orderService.orderDto.orderDetailDtos) {
      orderDetail.promotionEntryCode = null;
      orderDetail.promotionOrderDetailId = null;
      orderDetail.openQuantity = orderDetail.orderQuantity;
    }
    
    this._shippinghandlingService.premiumShippingItem = new PremiumDto();
    this.orderLevelOffersOthersDialog.close('Yes');
  }

  returnToCart(): void {
    this.orderService.orderDto.hasUserSeenPromoForm = false;
    this.orderService.orderDto.promotionOfferPopupButton = 'Return To Cart';

    for (const orderDetail of this.orderService.orderDto.orderDetailDtos) {
      orderDetail.promotionEntryCode = null;
      orderDetail.promotionOrderDetailId = null;
      orderDetail.openQuantity = orderDetail.orderQuantity;
    }

    this._shippinghandlingService.premiumShippingItem = new PremiumDto();
    this.orderLevelOffersOthersDialog.close('No');

    setTimeout(() => {
      if (document.getElementById(`productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}`)) {
        document.getElementById(`productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}`)?.focus();
      }
    });
  }

  hidePromoPopup(): void {
    if (this.orderService.orderDto.promotionOfferPopupButton === '') {
      setTimeout(() => {
        this.orderLevelOffersOthersDialog.close('No');

        if (document.getElementById(`productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}`)) {
          document.getElementById(`productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}`)?.focus();
        }
      });
    }
  }

  onSelectionChange(premium: PremiumDto): void {//Checkbox & radio button selection changed
    if (this.orderService.orderDto.orderOfferDto?.allPremiums) {
      const selectAll = !this.orderService.orderDto.orderOfferDto?.premiums.some(item => !item.isSelected);
      this.selectAllPremium = selectAll;
    }
    else {
      this.orderService.orderDto.orderOfferDto?.premiums.forEach(element => {
        element.isSelected = false;

        if (element.productId === premium.productId) {
          element.isSelected = true;
        }
      });
    }
  }

  onSelectAllPremiums(): void {
    this.orderService.orderDto.orderOfferDto?.premiums.forEach(element => {
      element.isSelected = this.selectAllPremium;
    });
  }

  applyDiscountOnOrderDetailDtos(): void {
    if (!this.orderService.orderDto.orderOfferDto) {
      return;
    }

    this.orderService.orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V' && o.promotionEntryCode === 'OLT').forEach(element => {
      if (element.productCode !== '') {
        const productQualifies = this.orderService.orderDto.orderOfferDto?.productIds.some(productId => productId === element.productId);

        if (productQualifies) {
          const splDiscount = this.pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, element.productId);
          element.discount = splDiscount > this.orderService.orderDto.orderOfferDto?.percentDiscount! ? splDiscount :
            this.orderService.orderDto.orderOfferDto?.percentDiscount!;
          this.pricingService.calculateUnitPrice(element);
          this.pricingService.calculateExtendedPrice(element);
        }
      }
    });
  }

  async addPremiumsToCart(): Promise<void> {
    if (!this.orderService.orderDto.orderOfferDto) {
      return;
    }
    const selectedPremiums = this.orderService.orderDto.orderOfferDto.premiums.filter(item => item.isSelected);
    this.addAllPremiums();
    for (const premium of selectedPremiums) {
      const product = this._configService.reference?.productDtos.filter(p => p.id === premium.productId)[0];
      const productAlreadyExists = this.orderService.orderDto.orderDetailDtos.some(o => o.productId === premium.productId && o.promotionEntryCode === 'OLP');

      if (!product) {
        return;
      }
      if (productAlreadyExists) {
        continue;
      }
      if (this.orderService.orderDto.orderOfferDto?.allPremiums === false) {
        this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(o => o.promotionEntryCode !== 'OLP');
        if (!this.orderService.orderDto.orderDetailDtos.find(i => i.productId === 0)) {
          this._orderDetailsGridComponent.addNewLineItem();
        }
      }
      const orderDetail = this.orderService.createNewOrderDetail(this.orderService.orderDto, product.productCode);
      orderDetail.listPrice = premium.listPrice;
      orderDetail.discount = premium.discount;
      orderDetail.shippedQuantity = 0;
      orderDetail.voidedQuantity = 0;
      this.pricingService.calculateUnitPrice(orderDetail);
      orderDetail.openQuantity = premium.quantity;
      orderDetail.orderQuantity = orderDetail.openQuantity + orderDetail.shippedQuantity;
      this.pricingService.calculateExtendedPrice(orderDetail);
      orderDetail.itemEditable = false;
      orderDetail.orderDetailStatus = ' ';
      orderDetail.promotionEntryCode = 'OLP';
      //add the record to OrderDetails Grid
      this.orderService.orderDto.orderDetailDtos.push(orderDetail);
      this.orderService.recalculateFields(this.orderService.orderDto);
      await this.openConfigSetAsync(orderDetail, true);
    }
  }

  addAllPremiums(){
    if (this.orderService.orderDto.orderOfferDto?.allPremiums) {
      this.orderService.orderDto.orderOfferDto.premiums.forEach(element => {
        if (!element.isSelected) {
          const productIndex = this.orderService.orderDto.orderDetailDtos.findIndex(o => o.productId === element.productId);
          if (productIndex > -1) {
            this.orderService.orderDto.orderDetailDtos.splice(productIndex, 1);
          }
        }
      });
    }
  }

  checkIsElementSelected(element:any){
    if (!element.isSelected) {
      const productIndex = this.orderService.orderDto.orderDetailDtos.findIndex(o => o.productId === element.productId);

      if (productIndex > -1) {
        this.orderService.orderDto.orderDetailDtos.splice(productIndex, 1);
      }
    }
  }

  setDiscountOnPremiumSelect(selectedPremiums:any){
    if (selectedPremiums.length > 0) {
      if (this.orderService.orderDto.orderOfferDto?.discountAndPremium === false) {
        this.orderService.orderDto.orderDetailDtos.forEach(element => {
          if (element.promotionEntryCode !== 'OLP') {
            element.discount = element.discountBeforeApplyingPromoOffer;
          }
        });
      }
    }
  }

  async openConfigSetAsync(orderDetail: OrderDetailDto, isEdit: boolean = false): Promise<void> {
    if (orderDetail.productDto.configSetDtos.length === 0) {
      return;
    }

    if (isEdit) {
      localStorage.setItem('isEditMode', 'true');
    }

    this.premiumOrderDetail = orderDetail;

    if (this.premiumOrderDetail.classCode !== 'SH' && this.premiumOrderDetail.orderDetailStatus !== 'V') {
      const result = await this.configSetDialog.openAsync();

      if (result === 'Cancel') {
        this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item.id !== this.premiumOrderDetail.id);
        this.orderService.recalculateFields(this.orderService.orderDto);
      }
    }
  }

  removeEmptyRecordAddAtLast(): void {
    //remove empty record from middle and add it at the last
    if (this.orderService.orderDto.orderDetailDtos.find(i => i.productId === 0)) {
      const orderDetailDto = this.orderService.orderDto.orderDetailDtos.filter(i => i.productId === 0)[0];
      const index = this.orderService.orderDto.orderDetailDtos.indexOf(orderDetailDto);

      if (index !== -1) {
        this.orderService.orderDto.orderDetailDtos.splice(index, 1);
        this._orderDetailsGridComponent.addNewLineItem();

        setTimeout(() => {
          if (document.getElementById(`productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}`)) {
            document.getElementById(`productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}`)?.focus();
          }
        });
      }
    }

    this._orderDetailsGridComponent.sumOrderQuantity();
  }

  clearSelectedPremiums(): void {
    this.orderService.orderDto.orderOfferDto?.premiums.forEach(element => {
      element.isSelected = false;
    });
  }

  checkOfferCategorySlap(): void {
    const orderOfferDto = this.orderService.orderDto.orderOfferDto;

    if (!orderOfferDto) {
      return;
    }
    
    this.qualifyingTier = orderOfferDto.tiers.filter(item => this.qualifyingQuantity >= item.from && Math.floor(this.qualifyingQuantity) <= item.to)[0];
    const minimumPromotionQuantity = this.orderService.orderDto.orderOfferDto?.tiers.sort((a, b) => 0 - (a.from > b.from ? -1 : 1))[0].from!;
    this.minimumQualifyingQuantity = minimumPromotionQuantity - this.qualifyingQuantity;
  }
}