<uitk-header>
  <a class="uitk-c-header-skip-to-content-link" href="#main-content">skip to content</a>
  <uitk-header-logo>
    <div class="imgdiv" style="width: max-content;">
      <img class="headerimg" src="assets/images/optum_logo.png" alt="optum logo" />
      <p class="divheader" style="font-size: 1.25em;">|</p>
      <p class="divheader">Tucson</p>
      <p class="divheader" *ngIf="isImpersonated">|</p>
      <p class="divheader" *ngIf="isImpersonated" style="color: #002677;">Impersonating as {{ impersonateType }}</p>
      <p class="divheader" *ngIf="isImpersonated">
        <span class="uitk-icon uitk-icon-remove gridIcons" style="cursor: pointer; padding-top: 3px"
          (click)="showLeaveImpersonationPopUp()"></span>
      </p>
    </div>
  </uitk-header-logo>
  <uitk-navigation-global class="headermenu">
    <uitk-navigation-panel>
      <uitk-navigation-panel-item>
        <a uitkNavRoute routerLink="/" class="fontsize" appEnableResource="OrdersMenu" appTabTitle> Orders </a>
      </uitk-navigation-panel-item>
      <!-- <uitk-navigation-panel-item>
        <a uitkNavRoute routerLink="/order-entry-mini" class="fontsize" appEnableResource="OrderEntryMenu"
          appTabTitle="Order Entry Mini"> Order Entry Mini </a>
      </uitk-navigation-panel-item> -->
      <uitk-navigation-panel-item>
        <a uitkNavRoute routerLink="/order-entry" class="fontsize" appEnableResource="OrderEntryMenu"
          appTabTitle="Order Entry"> Order Entry </a>
      </uitk-navigation-panel-item>
      <uitk-navigation-panel-item>
        <a uitkNavRoute routerLink="/subscriptions" class="fontsize" appEnableResource="SubscriptionsMenu"
          appTabTitle="Subscriptions">
          Subscriptions
        </a>
      </uitk-navigation-panel-item>
      <uitk-navigation-panel-item>
        <a uitkNavRoute routerLink="/worklistqueue" class="fontsize" appEnableResource="WorklistQueueMenu"
          appTabTitle="Worklist Queue"> Worklist Queue </a>
      </uitk-navigation-panel-item>
      <uitk-navigation-panel-item>
        <button uitkNavButton class="fontsize">Returns</button>
        <uitk-navigation-panel>
          <uitk-navigation-panel-item>
            <a uitkNavRoute routerLink="/returns/batchreturns" class="fontsize"
              appEnableResource="ReturnsBatchReturnsMenu" appTabTitle="Batch Returns">
              Batch Returns
            </a>
          </uitk-navigation-panel-item>
          <uitk-navigation-panel-item>
            <a uitkNavRoute routerLink="/returns/confirmAuthorization" class="fontsize"
              appEnableResource="ReturnsConfirmAuthorizationsMenu"
              appTabTitle="Confirm Authorizations">
              Confirm Authorizations
            </a>
          </uitk-navigation-panel-item>
        </uitk-navigation-panel>
      </uitk-navigation-panel-item>
      <uitk-navigation-panel-item *ngIf = "configService.reportsFlag">
        <a uitkNavRoute routerLink="/reports" class="fontsize" 
          appTabTitle="Reports"> Reports </a>
      </uitk-navigation-panel-item>
      <uitk-navigation-panel-item>
        <button uitkNavButton class="fontsize">Accounts Receivable</button>
        <uitk-navigation-panel>
          <uitk-navigation-panel-item>
            <a uitkNavRoute routerLink="accountreceivable/arinquiry" class="fontsize" appEnableResource="ARInquiryMenu"
              appTabTitle="A/R Inquiry">
              A/R Inquiry
            </a>
          </uitk-navigation-panel-item>
          <uitk-navigation-panel-item>
            <a uitkNavRoute routerLink="accountreceivable/cashinquiry" class="fontsize"
              appEnableResource="ARCashInquiryMenu" appTabTitle="Cash Inquiry">
              Cash Inquiry
            </a>
          </uitk-navigation-panel-item>
          <uitk-navigation-panel-item>
            <a uitkNavRoute routerLink="/SearchinvoiceByCC" class="fontsize" appEnableResource="ARSearchInvoiceByCCMenu"
              appTabTitle="Search Invoice by CC" style="margin-bottom: 0.5px;">
              Search Invoice by CC
            </a>
          </uitk-navigation-panel-item>
        </uitk-navigation-panel>
      </uitk-navigation-panel-item>
      <uitk-navigation-panel-item>
        <button uitkNavButton class="fontsize">Maintenance</button>
        <uitk-navigation-panel>
          <uitk-navigation-panel-item>
            <a uitkNavRoute routerLink="/approvalrequests" class="fontsize" appTabTitle="Approval Requests"
              href="javaScript:void(0)">
              Approval Requests
            </a>
          </uitk-navigation-panel-item>
          <uitk-navigation-panel-item>
            <a uitkNavRoute routerLink="/promotions/search" class="fontsize" appTabTitle="View Promotions"
              href="javaScript:void(0)">
              Promotion Details
            </a>
          </uitk-navigation-panel-item>
          <uitk-navigation-panel-item>
            <a uitkNavRoute routerLink="/tokenize" class="fontsize" appEnableResource="TokenizeMenu"
              appTabTitle="Tokenize" href="javaScript:void(0)">
              Tokenize
            </a>
          </uitk-navigation-panel-item>
          <uitk-navigation-panel-item>
            <a  *appShowResource="'accountcomments'" uitkNavRoute routerLink="/accountComments" class="fontsize" appTabTitle="Account Comments" href="javaScript:void(0)">
              Account Comments
            </a>
          </uitk-navigation-panel-item>
          <uitk-navigation-panel-item *appShowResource="'SourceCodeTab'">
            <a uitkNavRoute routerLink="/sourcecode" class="fontsize" appTabTitle="Source Code" href="javaScript:void(0)">
              Source Code</a>
          </uitk-navigation-panel-item>
          
        </uitk-navigation-panel>
      </uitk-navigation-panel-item>
    </uitk-navigation-panel>
  </uitk-navigation-global>
  <uitk-navigation-global class="headerusername">
    <uitk-navigation-panel>
      <uitk-navigation-panel-item *ngIf="isLoggedIn">
        <button class="fontsize" uitkNavButton style="position:relative;bottom:5px">
          
          <a style="position:relative;" icon="uimf-icon-person" hiddenText="person" class="uitk-c-card__icon uitk-icon uitk-icon-person"></a>
          Hi, {{ loggedUserName }}
        </button>
        <uitk-navigation-panel>
          <uitk-navigation-panel-item>
            <span uitkNavRoute class="fontsize">
              {{ loggedUserName }} <br />
              <em> {{ roleName }} </em> <br />
              <em> {{ msId }} </em>
            </span>
          </uitk-navigation-panel-item>
          <uitk-navigation-panel-item>
            <a uitkNavRoute class="fontsize" (click)="openImpersonateModal()"
              [style.display]="displayImpersonateOption ? 'block' : 'none'" appEnableResource="UserImpersonateMenu">
              <span class="uitk-icon uitk-icon-referral md-icon signoutstyle">
                Impersonate
              </span>
            </a>
          </uitk-navigation-panel-item>
          <uitk-navigation-panel-item *ngIf="isLoggedIn">
            <a uitkNavRoute (click)="handleEventEmitter('logout')" class="fontsize">
              <span class="uitk-icon uitk-icon-import sm-icon signoutstyle">
                Sign Out
              </span>
            </a>
          </uitk-navigation-panel-item>
        </uitk-navigation-panel>
      </uitk-navigation-panel-item>
    </uitk-navigation-panel>
  </uitk-navigation-global>
</uitk-header>
<div class="divPopUp" *ngIf="openImpersonatePopup">
  <app-impersonation (closeEvent)="closeEvent()"></app-impersonation>
</div>
<div *ngIf="leavingImpersonateDialogModal.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="leavingImpersonateDialogModal.show" uitkId="leavingImpersonateDialog" [closeBtn]=false
  triggerElementId="leavingImpersonateDialog_openBtn">
  <uitk-dialog-header style="letter-spacing: 0.3px; font-size: 20px; line-height: 48px;">
    <div cdkDragHandle>
      Leaving Impersonation View
      <hr>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div>
      Do you want to leave the impersonation view?
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="clearImpersonation()" #yesBtn>
      Yes
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="hideLeaveImpersonateDialog()">
      No
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>
<app-log-dialog></app-log-dialog>
