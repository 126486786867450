<div class="container-fluid mt-4 px-4">
    <div class="row" style="margin-top: 50px;">
        <div class="col">
            <h2 style="color: #002677;"><b>Reports</b></h2>
        </div>
    </div>
    <div class="row">
        <div class="container-fluid mt-4 px-4">
            <nav style="margin-top: 50px;">
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link active" id="nav-sales-tab" data-bs-toggle="tab"
                        data-bs-target="#nav-sales" type="button" role="tab" aria-controls="nav-sales"
                        aria-selected="true"
                        style="border-left: none; border-top: none;border-right: none; border-width: 3px; font-family: Microsoft Sans Serif; letter-spacing: 0.12px;"
                        [ngStyle]="{'border-color':selectedPanel === 'panel1' ?'#002677':'transparent', 'font-weight':selectedPanel === 'panel1' ?'bold':'normal'}"
                        (click)="selectPanel('panel1')">Sales</button>
                    <button class="nav-link" id="nav-product-tab" data-bs-toggle="tab" data-bs-target="#nav-product"
                        type="button" role="tab" aria-controls="nav-product" aria-selected="false"
                        style="border-left: none; border-top: none;border-right: none; border-width: 3px; font-family: Microsoft Sans Serif; letter-spacing: 0.12px;"
                        [ngStyle]="{'border-color':selectedPanel === 'panel2' ?'#002677':'transparent', 'font-weight':selectedPanel === 'panel2' ?'bold':'normal'}"
                        (click)="selectPanel('panel2')">Product </button>
                    <button class="nav-link" id="nav-revenue-tab" data-bs-toggle="tab"
                        data-bs-target="#nav-revenue" type="button" role="tab" aria-controls="nav-revenue"
                        aria-selected="false"
                        style="border-left: none; border-top: none;border-right: none; border-width: 3px; font-family: Microsoft Sans Serif; letter-spacing: 0.12px;"
                        [ngStyle]="{'border-color':selectedPanel === 'panel3' ?'#002677':'transparent', 'font-weight':selectedPanel === 'panel3' ?'bold':'normal'}"
                        (click)="selectPanel('panel3')">Revenue </button>
                    <button class="nav-link" id="nav-marketing-tab" data-bs-toggle="tab" data-bs-target="#nav-marketing"
                        type="button" role="tab" aria-controls="nav-marketing" aria-selected="false"
                        style="border-left: none; border-top: none;border-right: none; border-width: 3px; font-family: Microsoft Sans Serif; letter-spacing: 0.12px;"
                        [ngStyle]="{'border-color':selectedPanel === 'panel4' ?'#002677':'transparent', 'font-weight':selectedPanel === 'panel4' ?'bold':'normal'}"
                        (click)="selectPanel('panel4')">Marketing</button>
                    
                </div>
            </nav>
            <div class="tab-content">
                <!-------------------------------------------------------------------- Sales -------------------------------------------------------------------->
                <div class="tab-pane fade show active" id="nav-sales" role="tabpanel" aria-labelledby="nav--tab">
                  <div  class="col-12 row mt-3">
                    <div class="card h-200 col-2" style="margin-left: 10px; margin-right: 10px;">
                      <div class="row mt-2 mx-2">
                        <div class="col-12">
                          <h5 class="card-title"><b>Real Time Stats

                          </b></h5>
                        </div>
                      </div>
                      <div class="row mt-3 h-100">
                        <div class="col d-flex justify-content-center align-items-end">
                          <button id="card-btn" type="button"
                            class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs width-150"
                            >Open</button>
                        </div>
                      </div>
                    </div>
                    <div class="card h-200 col-2" style="margin-left: 10px; margin-right: 10px;">
                      <div class="row mt-2 mx-2">
                        <div class="col">
                          <h5 class="card-title"><b>Complete Digital

                          </b></h5>
                        </div>
                      </div>
                      <div class="row mt-3 h-100">
                        <div class="col d-flex justify-content-center align-items-end">
                          <button id="card-btn" type="button"
                            class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs width-150"
                            >Open</button>
                        </div>
                      </div>
                    </div>
                    <div class="card h-200 col-2" style="margin-left: 10px; margin-right: 10px;">
                      <div class="row mt-2 mx-2">
                        <div class="col-12">
                          <h5 class="card-title"><b>Daily Dashboard

                          </b></h5>
                        </div>
                      </div>
                      <div class="row mt-3 h-100">
                        <div class="col d-flex justify-content-center align-items-end">
                          <button id="card-btn" type="button"
                            class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs width-150"
                            >Open</button>
                        </div>
                      </div>
                    </div>
                    <div class="card h-200 col-2" style="margin-left: 10px; margin-right: 10px;">
                      <div class="row mt-2 mx-2">
                        <div class="col-12">
                          <h5 class="card-title"><b>Five Years Sales Product Summary
                          </b></h5>
                        </div>
                      </div>
                      <div class="row mt-3 h-100">
                        <div class="col d-flex justify-content-center align-items-end">
                          <button id="card-btn" type="button"
                            class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs width-150"
                            >Open</button>
                        </div>
                      </div>
                    </div>
                    <div class="card h-200 col-2" style="margin-left: 10px; margin-right: 10px;">
                      <div class="row mt-2 mx-2">
                        <div class="col-12">
                          <h5 class="card-title"><b>Print Dashboard

                          </b></h5>
                        </div>
                      </div>
                      <div class="row mt-3 h-100">
                        <div class="col d-flex justify-content-center align-items-end">
                          <button id="card-btn" type="button"
                            class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs width-150"
                            >Open</button>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
                <!-------------------------------------------------------------------- Product   -------------------------------------------------------------------->
                <div class="tab-pane fade" id="nav-product" role="tabpanel" aria-labelledby="nav-product-tab">
                  <div  class="col-12 row mt-3">
                    <div class="card h-200 col-2" style="margin-left: 10px; margin-right: 10px;">
                      <div class="row mt-2 mx-2">
                        <div class="col-12">
                          <h5 class="card-title"><b>Report 1

                          </b></h5>
                        </div>
                        <div class="col d-flex justify-content-end">
                         
                        </div>
                      </div>
                      <div class="row mt-3 h-100">
                        <div class="col d-flex justify-content-center align-items-end">
                          <button id="card-btn" type="button"
                            class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs width-150"
                            >Open</button>
                        </div>
                      </div>
                    </div>
                  </div>     
                </div>
                <!-------------------------------------------------------------------- Revenue Dashbord -------------------------------------------------------------------->
                <div class="tab-pane fade" id="nav-revenue" role="tabpanel" aria-labelledby="nav-revenue-tab">
                  <div  class="col-12 row mt-3">
                    <div class="card h-200 col-2" style="margin-left: 10px; margin-right: 10px;">
                      <div class="row mt-2 mx-2">
                        <div class="col-12">
                          <h5 class="card-title"><b>Net Shipment

                          </b></h5>
                        </div>
                        <div class="col d-flex justify-content-end">
                         
                        </div>
                      </div>
                      <div class="row mt-3 h-100">
                        <div class="col d-flex justify-content-center align-items-end">
                          <button id="card-btn" type="button"
                            class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs width-150"
                            >Open</button>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
                <!-------------------------------------------------------------------- Marketing -------------------------------------------------------------------->
                <div class="tab-pane show fade" id="nav-marketing" role="tabpanel" aria-labelledby="nav-marketing-tab">
                  <div  class="col-12 row mt-3">
                    <div class="card h-200 col-2" style="margin-left: 10px; margin-right: 10px;">
                      <div class="row mt-2 mx-2">
                        <div class="col-12">
                          <h5 class="card-title"><b>Report 1

                          </b></h5>
                        </div>
                        <div class="col d-flex justify-content-end">
                         
                        </div>
                      </div>
                      <div class="row mt-3 h-100">
                        <div class="col d-flex justify-content-center align-items-end">
                          <button id="card-btn" type="button"
                            class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs width-150"
                            >Open</button>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>    
            </div>
        </div>
    </div>
</div>   