import { IUITKSelectItemProps } from "@uitk/angular";

export class OrderSearchFilterDto {
  orderNumber = '';  
  orderType: IUITKSelectItemProps[] | null = null;
  orderStatus: IUITKSelectItemProps[] | null = null;
  accountName = '';
  accountNumber = '';
  webReferenceNumber = '';
  poNumber = '';
  creditCardLastFour = '';
  streetAddress = '';
  city = '';
  zipCode = '';
  stateNonUS = '';
  stateUS: IUITKSelectItemProps | null = null;
  country: IUITKSelectItemProps | null = null;
  trackingNumber = '';
  enteredBy = '';
  cfd = false;
}