import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Observable, of, Subject } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { AdvancedContactSearchInputDto } from '../dtos/advanced-contact-search-input-dto.model';
import { AdvancedContactSearchResultsDto } from '../dtos/advanced-contact-search-results-dto.model';
import { ContactDto } from '../dtos/contact-dto.model';
import { OrderShipToAddressDto } from '../dtos/order-ship-to-address-dto.model';
import { ConfigService } from './config.service';
import { ErrorHandlingService } from './error-handling.service';
import { ModeService } from './mode.service';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root'
})

export class ContactService {
  gotContacts = new Subject<ContactDto[]>();
  contactDtos: ContactDto[] | null = null;
  orderShipToAddressDetails = new Subject<OrderShipToAddressDto>();
  orderShipToAddressDto: OrderShipToAddressDto | null = null;
  accountId = 0;
  private readonly _httpClient: HttpClient;
  private readonly _modeService: ModeService;
  private readonly _configService: ConfigService;
  private readonly _errorHandlingService: ErrorHandlingService;
  contactsCache!: ContactDto[] | null;
  getContactListForAccount = false;
  triggerGetContactList = new Subject();
  contactsCachedObservable!: Observable<ContactDto[]> | null;
  contactsCachedPromise!: Promise<ContactDto[]> | null;
  contactInformationCache!: ContactDto | null;
  contactInformationCachedObservable!: Observable<ContactDto> | null;
  orderShipToAddressCache!: OrderShipToAddressDto | null;
  orderShipToAddressCachedObservable!: Observable<OrderShipToAddressDto> | null;
  contactSearchKeyword = '';
  orderByContactList: IUITKSelectItemProps[] = [];
  shipToContactList: IUITKSelectItemProps[] = [];
  shipToContacts: string [] = [];
  swalAlert = new SwalAlert();

  constructor(httpClient: HttpClient, modeService: ModeService, configService: ConfigService, errorHandlingService: ErrorHandlingService) {
    this._httpClient = httpClient;
    this._modeService = modeService;
    this._configService = configService;
    this._errorHandlingService = errorHandlingService;
  }

  public clearContacts() {
    this.contactDtos = null;
    this.orderShipToAddressDto = null;
    this.accountId = 0;
  }

  getContacts(accountId: number, waitText: string): void {
    let observable: Observable<ContactDto[]>;

    this.contactsCachedObservable = this._httpClient.get<ContactDto[]>(`${this._configService.apiUrl}/Reference/GetContacts?accountId=${accountId}`)
      .pipe(
        tap((response: ContactDto[]) => {
          this.contactsCache = response;
        }),
        share(),
        finalize(() => {
          this.contactsCache = null;
          this.contactsCachedObservable = null;
        })
      );

    observable = this.contactsCachedObservable;
    // }

    this._modeService.addToWaitList(waitText);
    observable.subscribe((data: ContactDto[]) => {
      this.contactDtos = data;
      this._modeService.removeFromWaitList(waitText);
      this.gotContacts.next(data);
    }, (error: any) => {
      this._modeService.removeFromWaitList(waitText);
      console.log(error);
      this.swalAlert.alert(`An error occurred while getting a list of contacts for the account ${accountId}. Please try again.`);
    });
  }

  async getContactsAsync(accountId: number): Promise<ContactDto[] | null> {
    return new Promise(resolve => {
      const waitText = 'Refreshing contacts on account.';
      this._modeService.addToWaitList(waitText);
      this._httpClient.get<ContactDto[]>(`${this._configService.apiUrl}/Reference/GetContacts?accountId=${accountId}`).subscribe({
        next: data => {
          this._modeService.removeFromWaitList(waitText);
          resolve(data);
        },
        error: async e => {
          this._modeService.removeFromWaitList(waitText);
          await this.swalAlert.alert(`An error occurred while getting a list of contacts for the account ${accountId}. Please try again.`);
          resolve(null);
        }
      })
    });
  }

  convertContactsToUitkProps(contactDtos: ContactDto[]): IUITKSelectItemProps[] {
    const uitkProps: IUITKSelectItemProps[] = [];

    for (const contact of contactDtos) {
      const contactItem = { id: contact.contactId.toString(), label: '', value: contact.contactId.toString() };

      if (contact.firstName === null) {
        contact.firstName = '';
      }

      if (contact.lastName === null) {
        contact.lastName = '';
      }

      if (contact.firstName === '' && contact.lastName === '') {
        contactItem.label = `Unknown - ${contact.contactId}`;
      }
      else {
        contactItem.label = `${contact.firstName} ${contact.lastName} - ${contact.contactId}`;
      }

      uitkProps.push(contactItem);
    }

    return uitkProps;
  }

  convertContactsToArray(contactDtos: ContactDto[]): string[] {
    const availableTags: string[] = [];

    for (const contact of contactDtos) {
      const contactItem = { id: contact.contactId.toString(), label: '', value: contact.contactId.toString() };

      if (contact.firstName === null) {
        contact.firstName = '';
      }

      if (contact.lastName === null) {
        contact.lastName = '';
      }

      if (contact.firstName === '' && contact.lastName === '') {
        contactItem.label = `Unknown - ${contact.contactId}`;
      }
      else {
        contactItem.label = `${contact.firstName} ${contact.lastName} - ${contact.contactId}`;
      }

      availableTags.push(contactItem.label);
    }
    return availableTags;
  }

  // async getContactsAsync(accountId: number): Promise<void> {
  //   let promise: Promise<ContactDto[]>;

  //   if (this.contactsCache) {
  //     promise = of(this.contactsCache).toPromise();
  //   }
  //   else if (this.contactsCachedPromise) {
  //     promise = this.contactsCachedPromise;
  //   }
  //   else {
  //     this.contactsCachedPromise = this._httpClient.get<ContactDto[]>(`${this._configService.apiUrl}/Reference/GetContacts?accountId=${accountId}`)
  //       .pipe(
  //         tap((response: ContactDto[]) => {
  //           this.contactsCache = response;
  //         }),
  //         share(),
  //         finalize(() => {
  //           this.contactsCache = null;
  //           this.contactsCachedPromise = null;
  //         })
  //       ).toPromise();

  //     promise = this.contactsCachedPromise;
  //   }

  //   await promise.then((data: ContactDto[]) => {
  //     this.contactDtos = data;
  //   },
  //     (error: any) => {
  //       this.swalAlert.alert(`An error occurred while getting a list of contacts for the account ${accountId}. Please try again.`);
  //     });
  // }

  getContactInformation(accountId: number): Observable<ContactDto> {
    let observable: Observable<ContactDto>;

    if (this.contactInformationCache) {
      observable = of(this.contactInformationCache);
    }
    else if (this.contactInformationCachedObservable) {
      observable = this.contactInformationCachedObservable;
    }
    else {
      this.contactInformationCachedObservable = this._httpClient.get<ContactDto>(`${this._configService.apiUrl}/Reference/GetContacts?accountId=${accountId}`)
        .pipe(
          tap((response: ContactDto) => {
            this.contactInformationCache = response;
          }),
          share(),
          finalize(() => {
            this.contactInformationCache = null;
            this.contactInformationCachedObservable = null;
          })
        )

      observable = this.contactInformationCachedObservable;
    }

    return observable;
  }

  getOrderShipToAddressDetails(orderId: number): void {
    let observable: Observable<OrderShipToAddressDto>;

    if (this.orderShipToAddressCache) {
      observable = of(this.orderShipToAddressCache);
    }
    else if (this.orderShipToAddressCachedObservable) {
      observable = this.orderShipToAddressCachedObservable;
    }
    else {
      this.orderShipToAddressCachedObservable = this._httpClient.get<OrderShipToAddressDto>(`${this._configService.apiUrl}/Order/GetOrderShipToAddress/${orderId}`)
        .pipe(
          tap((response: OrderShipToAddressDto) => {
            this.orderShipToAddressCache = response;
          }),
          share(),
          finalize(() => {
            this.orderShipToAddressCache = null;
            this.orderShipToAddressCachedObservable = null;
          })
        );

      observable = this.orderShipToAddressCachedObservable;
    }

    observable.subscribe((data: OrderShipToAddressDto) => {
      this.orderShipToAddressDto = data;
      this.orderShipToAddressDetails.next(data);
    }, (error: any) => {
      console.log(error);
      this.swalAlert.alert(`An error occurred while getting the ship to address details for the order ${orderId}. Please try again.`);
    });
  }

  async advancedContactSearch(advancedContactSearchInputDto: AdvancedContactSearchInputDto): Promise<AdvancedContactSearchResultsDto[]> {
    return new Promise((resolve, reject) => {
      const message = 'Loading Search Results…';
      this._modeService.addToWaitList(message);

      this._httpClient.post<AdvancedContactSearchResultsDto[]>(`${this._configService.apiUrl}/order/advancedcontactsearch`, advancedContactSearchInputDto).subscribe({
        next: data => {
          this._modeService.removeFromWaitList(message);
          resolve(data);
        }, error: e => {
          console.log(e);
          this._modeService.removeFromWaitList(message);
          reject(e);
        }
      });
    });
  }
}
