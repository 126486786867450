<div class="row mt-3">
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Order #</label>
    </div>
    <div class="col-7 d-flex">
      <uitk-form-field>
        <input uitkInput id="order-number-textbox" class="textbox mx-3" type="text" disabled
          [(ngModel)]="orderService.orderDto.id === 0 ? '' : orderService.orderDto.id" />
      </uitk-form-field>
    </div>
  </div>
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Created Date</label>
    </div>
    <div class="col-7 d-flex">
      <uitk-form-field>
        <input uitkInput id="created-date-textbox" class="textbox mx-3" type="text"
          [ngModel]="createdDate | date: 'MM/dd/yyyy'" disabled />
      </uitk-form-field>
    </div>
  </div>
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Order Type</label>
    </div>
    <div class="col-7 d-flex" *ngIf = "!(orderService.orderDto.orderType === 'E' && orderService.isSnapshot)">
      <uitk-form-field>
    <uitk-select uitkId="orderType" id="order-type-select" class="orderTypeSelect mx-3" 
                   [itemList]="orderService.orderTypeList" [(ngModel)]="orderService.orderDto.orderTypeBinding"
                   [disabled]="modeService.mode !== Mode.Edit" (onDropdownChange)="onChangeOrderType($event)">
    </uitk-select>
  </uitk-form-field>
    </div>
    <div class="col-7 d-flex" *ngIf = "orderService.orderDto.orderType === 'E' && orderService.isSnapshot">
      <uitk-form-field class="mx-3">
        <uitk-select uitkId="orderType" id="order-type-select" class="orderTypeSelect" 
                   [itemList]="orderService.orderTypeList" [(ngModel)]="orderService.orderTypeList[0]"
                   [disabled]="modeService.mode !== Mode.Edit">
        </uitk-select>
      </uitk-form-field>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Status</label>
    </div>
    <div class="col-7 d-flex">
      <uitk-form-field>
        <input uitkInput id="status-textbox" class="textbox mx-3" type="text"
        [ngModel]="orderService.orderDto.orderStatus==='V'? 'Void':(orderService.orderDto.orderType === 'Q' && orderService.orderDto.orderStatus === ' ' && orderService.orderDto.id > 0 && modeService.mode !== Mode.Edit) ? 'Approved' : orderService.orderDto.orderStatusBinding?.label === null ||
          orderService.orderDto.orderStatusBinding?.label === '' || orderService.orderDto.orderStatusBinding?.label ===
          'Select' ||  modeService.mode === Mode.Edit ? 'WIP' : orderService.orderDto.orderStatusBinding.label"
               disabled />
      </uitk-form-field>
    </div>
  </div>
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Web Reference</label>
    </div>
    <div class="col-7 d-flex">
      <uitk-form-field>
        <input uitkInput id="web-reference-textbox" class="textbox mx-3" type="text" maxlength="15"
          onkeyup="this.value=this.value.replace(/[^a-zA-Z0-9]/g, '')"
          [(ngModel)]="orderService.orderDto.webReferenceNumber" [disabled]="modeService.mode !== Mode.Edit" (keydown.Tab)="onWebReferenceBlur()" />
      </uitk-form-field>
    </div>
  </div>
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Salesperson</label>
    </div>
    <div class="col-7 d-flex">
      <!-- Edit mode  -->
      <a *ngIf="modeService.mode === Mode.Edit" target="_blank" style="width: 100%; text-decoration: none; "
        [href]="'mailto:'+orderService.orderDto.salesPersonEmail" >
        <uitk-form-field>

          <input uitkInput id="sales-person-textbox" class="textbox mx-3 w-100" type="text"
            [(ngModel)]="orderService.orderDto.selectedsalesPersonName" (change)="onsalesPersonNameChange($event)"
            [disabled]="modeService.mode !== Mode.Edit || orderService.salesPersonDisabled"
            (keydown.Tab)="onSalesPersonBlur()" (keydown.enter)="onAccountNameEnter($event)" />
        </uitk-form-field>
      </a>
      <!--View Mode-->
      <a  *ngIf="modeService.mode === Mode.View" target="_blank" style="width: 100%;cursor: pointer;text-decoration: none; "
      [href]="'mailto:'+orderService.orderDto.salesPersonEmail" >
      <uitk-form-field >

        <input uitkInput id="sales-person-textbox" class="textbox mx-3 w-100" type="text"
          [(ngModel)]="orderService.orderDto.selectedsalesPersonName" (change)="onsalesPersonNameChange($event)"
        
          (keydown.Tab)="onSalesPersonBlur()"
          
          (keydown.enter)="onAccountNameEnter($event)" style="  background-color:#9293941f; box-shadow: 0 0 0 1px #D0D0CE;" />
      </uitk-form-field>
    </a>
      <span class="uitk-icon uitk-icon-search salesPersonLookUp"
        [class.disabled]="modeService.mode !== Mode.Edit || orderService.salesPersonDisabled"
        (click)="showSalesPersonLookUp()">
      </span>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label w-100" uitkLabel>Entered By</label>
    </div>
    <div class="col-7 d-flex" >
      <!--Edit mode-->
      <a *ngIf="modeService.mode === Mode.Edit" target="_blank"
        [href]="'mailto:'+orderService.orderDto.enteredByBindingEmail"
        style="width: 100%;text-decoration: none; cursor: pointer;">
        <uitk-form-field>
          <input uitkInput id="entered-by-textbox" class="textbox mx-3 w-100" type="text" 
            [(ngModel)]="this.orderService.orderDto.enteredByBinding" />
        </uitk-form-field>
      </a>
      <!--View mode-->
      <a *ngIf="modeService.mode === Mode.View" target="_blank"
      [href]="'mailto:'+orderService.orderDto.enteredByBindingEmail"
      style="width: 100%;text-decoration: none; cursor: pointer;">
      <uitk-form-field  >
        <input uitkInput id="entered-by-textbox" class="textbox mx-3 w-100" type="text"
          [(ngModel)]="this.orderService.orderDto.enteredByBinding"/>
      </uitk-form-field>
      </a>
    </div>
  </div>
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Lead ID</label>
    </div>
    <div class="col-7 d-flex">
      <uitk-form-field>
        <input uitkInput id="lead-id-textbox" class="textbox mx-3" type="text" disabled
          [(ngModel)]="this.orderService.orderDto.leadId" />
      </uitk-form-field>
    </div>
  </div>
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Opportunity ID</label>
    </div>
    <div class="col-7 d-flex">
      <uitk-form-field>
        <input uitkInput id="opportunity-id-textbox" class="textbox mx-3" type="text" disabled
          [(ngModel)]="this.orderService.orderDto.opportunityId" />
      </uitk-form-field>
    </div>
  </div>
</div>
<div *ngIf="showSalesPersonLookupPopup.show">
  <app-sales-person-lookup-popup [dialog]="showSalesPersonLookupPopup"
    (selectedSalesPerson)="bindSelectedSalesPerson($event)"></app-sales-person-lookup-popup>
</div>