import { Injectable } from '@angular/core';
import { User, UserManager } from 'oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly _authorizationService: AuthorizationService;
  private readonly _userManager: UserManager | undefined;
  private readonly signingKeys: any;
  private readonly currentUserSubject: BehaviorSubject<User | null> | undefined;
  public currentUser: Observable<User | null> | undefined;
  private readonly appSettings: any;

  constructor(authorizationService: AuthorizationService) {
    this._authorizationService = authorizationService;
    const xmlHttpEnvironment = new XMLHttpRequest();
    xmlHttpEnvironment.open('GET', "assets/config/environment.json", false); // false for synchronous request
    xmlHttpEnvironment.send(null);
    const environment = JSON.parse(xmlHttpEnvironment.responseText).environment;
    const xmlHttpSettings = new XMLHttpRequest();
    xmlHttpSettings.open('GET', `assets/config/appsettings.${environment}.json`, false);
    xmlHttpSettings.send(null);
    this.appSettings = JSON.parse(xmlHttpSettings.responseText);

    if (!location.href.includes('bypass')) {
      if (localStorage.getItem('isByPass') === null || localStorage.getItem('isByPass') === 'false') {
        if (!this.signingKeys) {
          this.signingKeys = this.getSigningKeys(this.appSettings.linkidx_url + '/protocol/openid-connect/certs');
        }

        const _settings = {
          authority: this.appSettings.linkidx_url,
          client_id: 'cd-omui',
          redirect_uri: this.appSettings.spaUrl + 'postlogin',
          loadUserInfo: false,
          metadata: {
            issuer: this.appSettings.linkidx_url,
            jwks_uri: this.appSettings.linkidx_url + '/protocol/openid-connect/certs',
            end_session_endpoint: this.appSettings.linkidx_url + '/protocol/openid-connect/logout',
            token_endpoint: this.appSettings.linkidx_url + '/protocol/openid-connect/token',
            token_introspection_endpoint: this.appSettings.linkidx_url + '/protocol/openid-connect/token/introspect',
            userinfo_endpoint: this.appSettings.linkidx_url + '/protocol/openid-connect/userinfo',
            authorization_endpoint: this.appSettings.linkidx_url + '/protocol/openid-connect/auth',
          },
          signingKeys: this.signingKeys,
          scope: 'openid profile',
          response_type: 'code',
          post_logout_redirect_uri: this.appSettings.spaUrl + 'postlogout',
          post_login_route: this.appSettings.spaUrl + 'postlogin',
          automaticSilentRenew: true,
          includeIdTokenInSilentRenew: true,
          silent_redirect_uri: this.appSettings.spaUrl + 'assets/silent-callback.html',
          accessTokenExpiringNotificationTime: 6000
        };

        this._userManager = new UserManager(_settings);
        this.currentUserSubject = new BehaviorSubject<User | null>(JSON.parse(localStorage.getItem('currentUser') as any));
        this.currentUser = this.currentUserSubject.asObservable();
      }
      else {
        this.maintainByPassUrl(true);
      }
    }
    else {
      this.maintainByPassUrl(true);
    }
  }

  public get currentUserValue(): User | null | undefined {
    return this.currentUserSubject?.value;
  }

  async login(): Promise<void> {
    if (!location.href.includes('bypass')) {
      if (localStorage.getItem('isByPass') === null || localStorage.getItem('isByPass') === 'false') {
        this.maintainByPassUrl(false);
        return this._userManager?.signinRedirect();
      }
      else {
        this.maintainByPassUrl(true);
        return;
      }
    }
    else {
      this.maintainByPassUrl(true);
      return;
    }
  }

  async isLoggedIn(): Promise<Boolean | undefined> {
    if (!location.href.includes('bypass')) {
      if (localStorage.getItem('isByPass') === null || localStorage.getItem('isByPass') === 'false') {
        return this._userManager?.getUser().then((user: User | null) => {

          this.maintainByPassUrl(false);
          const userCurrent = !!user && !user.expired;
          return userCurrent;
        });
      }
      else {
        this.maintainByPassUrl(true);
        return true;
      }
    } else {
      this.maintainByPassUrl(true);
      return true;
    }
  }

  async completeLogin() {
    if (!location.href.includes('bypass')) {
      if (localStorage.getItem('isByPass') === null || localStorage.getItem('isByPass') === 'false') {
        return this._userManager?.signinRedirectCallback().then((user: User) => {
          this.maintainByPassUrl(false);
          
          if (user.profile.preferred_username && user.profile.preferred_username.indexOf('_') > -1) {
            user.profile.preferred_username = user.profile.preferred_username.substr(0, user.profile.preferred_username.indexOf('_'))
          }

          localStorage.setItem('currentUser', JSON.stringify(user).replace('oma-',''));
          
          this.currentUserSubject?.next(user);
          this._authorizationService.getAuthorization(this.appSettings.apiUrl);
          return user;
        });
      }
      else {
        this.maintainByPassUrl(true);
        return true;
      }
    } else {
      this.maintainByPassUrl(true);
      return true;
    }
  }

  async logout() {
    return this._userManager?.signoutRedirect();
  }

  async completeLogout() {
    localStorage.clear();
    this.currentUserSubject?.next(null);
    caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
    return this._userManager?.signoutRedirectCallback();
  }

  getSigningKeys(url: string) {
    let xmlHttp = new XMLHttpRequest();
    xmlHttp.open('GET', url, false); // false for synchronous request
    xmlHttp.send(null);
    return xmlHttp.responseText;
  }

  maintainByPassUrl(isBypass: boolean) {
    if (isBypass) {
      localStorage.setItem('isByPass', 'true');
    }
    else {
      localStorage.setItem('isByPass', 'false');
    }
  }
}