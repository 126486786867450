import { Component, Input, OnInit } from '@angular/core';
import { Dialog } from '../../../../helpers/dialog';
import { QuoteCFDEmailDto } from '../../../../dtos/quote-cfd-email-dto.model';
import { CfdPhoneFormatPipe } from '../../../../pipes/cfd-phone-format.pipe';
import { ConfigService } from '../../../../services/config.service';
import { EmailService } from '../../../../services/email.service';
import { GeneratePdfService } from '../../../../services/generate-pdf.service';
import { OrderService } from '../../../../services/order.service';
import { SwalAlert } from '../../../../helpers/alert';

@Component({
  selector: 'app-quote-cfd-form-email',
  templateUrl: './quote-cfd-form-email.component.html',
  styleUrls: ['./quote-cfd-form-email.component.css']
})

export class QuoteCfdFormEmailComponent implements OnInit {
  private readonly _configService: ConfigService;
  private readonly _generatePdfService: GeneratePdfService;
  private readonly _emailService: EmailService;
  private readonly _orderService: OrderService;
  @Input() dialog!: Dialog<string>;
  @Input() enteredByUserRole : string | null = null;
  cfdEmailDto = new QuoteCFDEmailDto();
  emailPattern = /^^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](\s?)(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
  msId = '';
  swalAlert = new SwalAlert();

  constructor(configService: ConfigService, generatePdfService: GeneratePdfService, emailService: EmailService, orderService: OrderService) {
    this._configService = configService;
    this._generatePdfService = generatePdfService;
    this._emailService = emailService;
    this._orderService = orderService;
  }

  ngOnInit(): void {
    const accountTypeCode = this._orderService.orderDto.billToDto.accountTypeCode;
    const filterPipe = new CfdPhoneFormatPipe();
    const formattedPhoneNumber = filterPipe.transform(this._configService.getUserPhoneFromUserCode(this._orderService.orderDto.billToDto.accountOwnerCode));
    const formattedPhoneNumber_PrintRep = this._orderService.orderDto.billToDto.printRepCode? filterPipe.transform(this._configService.getUserPhoneFromUserCode(this._orderService.orderDto.billToDto.printRepCode)):'';

    this.cfdEmailDto.toRecipients = this._orderService.orderDto.orderByDto.email;
    this.cfdEmailDto.subject = "Optum Quote " + this._orderService.orderDto.id;
    let emailbodytext, regards;
    this.setEmailFrom(accountTypeCode);

    if (this._orderService.orderDto.salesOrganization === 'Shutterfly') {
      const emailBodyTextAndRegards = this.setEmailBodyAndRegardsForSalesOrgShutterfly(accountTypeCode, formattedPhoneNumber_PrintRep);
      emailbodytext = emailBodyTextAndRegards.emailbodytext;
      regards = emailBodyTextAndRegards.regards;
    }
    else {
      regards = this._configService.getFullNameFromUserCode(this._orderService.orderDto.billToDto.accountOwnerCode) +
        "\nContent Solutions Client Manager" + "\n" + this._configService.getUserEmailFromUserCode(this._orderService.orderDto.billToDto.accountOwnerCode) + "\n" + (formattedPhoneNumber!== ""? "1.":"") +formattedPhoneNumber;
    }
  
    if (accountTypeCode !== 'O') {
      this.cfdEmailDto.bccRecipients = this._configService.getUserEmailFromUserCode(this._orderService.orderDto.billToDto.accountOwnerCode);
      emailbodytext = "your Content Solutions Client Manager at the number listed below.  Hours of operation are 8 AM - 7 PM ET,";
    }
    this.cfdEmailDto.emailBody = "Thank you for selecting Optum  - your source for simplifying the complex business of health care." +
      " Please review your quote detail attached.\n\nContact us immediately with any corrections or changes to the information by replying to this e-mail or call "
      + emailbodytext + " Monday through Friday.\n\nTo proceed with purchase through email you must attach a copy of the Quote PDF in your response.\n\n\nRegards,\n" + regards;

      this.msId = this._configService.getMyMSId();
  }
  setEmailFrom(accountTypeCode: string) : void {
    if (accountTypeCode === 'C'||accountTypeCode === 'H') {
      this.cfdEmailDto.emailFrom = 'Partners@optum.com';
    }
    else if (accountTypeCode === 'O') {
      this.cfdEmailDto.emailFrom = 'Confirmations@optum.com';
    } 
    else {
      if(this._orderService.orderDto.salesOrganization === 'Shutterfly'){
        this.cfdEmailDto.emailFrom = this._orderService.orderDto.billToDto.printRepCode? this._configService.getUserEmailFromUserCode(this._orderService.orderDto.billToDto.printRepCode): '';
      }
      else{
        this.cfdEmailDto.emailFrom = this._configService.getUserEmailFromUserCode(this._orderService.orderDto.billToDto.accountOwnerCode);
      }
    }
  }
  setEmailBodyAndRegardsForSalesOrgShutterfly(accountTypeCode: string, formattedPhoneNumber_PrintRep: string): {emailbodytext: string, regards: string,} {
    
    let emailbodytext = "";
    let regards = "";
    if (accountTypeCode === 'O') {
      emailbodytext = "Customer Service at 1.800.464.3649, Option 1. Hours of operation are 8 AM - 8 PM ET,";
      regards = (this.enteredByUserRole === "SystemAdministrator" || this.enteredByUserRole === "OrderManagement"? "Melissa Werth": this._configService.getMyFullName()) + 
      "\nCustomer Service Rep\nCustomerAssistance@Optum.com\n1.800.464.3649, option 1";
    }
    else {
      regards = this._orderService.orderDto.billToDto.printRepCode? (this._configService.getFullNameFromUserCode(this._orderService.orderDto.billToDto.printRepCode) +
      "\nContent Solutions Client Manager" + "\n" + this._configService.getUserEmailFromUserCode(this._orderService.orderDto.billToDto.printRepCode) + "\n" + (formattedPhoneNumber_PrintRep!== ""? "1.":"") +formattedPhoneNumber_PrintRep):'';
    }   
    return {emailbodytext, regards};
  }
  cancelDialog() {
    this.dialog.close('cancel');
  }

  async previewQuoteCFDPdf(): Promise<void> {
    await this._generatePdfService.previewQuoteCFDPdf('divQuoteCFDPdf', Math.round(window.devicePixelRatio * 100));
  }

  async sendEmail(): Promise<void> {
    document.getElementById('divQuoteCFDPdf')!.style.display = 'inline-block';
    const quoteCFDPdf = await this._generatePdfService.generateQuoteCFDPdf();
    document.getElementById('divQuoteCFDPdf')!.style.width = this._generatePdfService.getPDFDivWidthBasedOnBrowserZoomLevel(Math.round(window.devicePixelRatio * 100));
    let srcWidth = document.getElementById('divQuoteCFDPdf')?.scrollWidth;
    if (srcWidth == null) {
      srcWidth = 1;
    }
    let pWidth = quoteCFDPdf.internal.pageSize.width;
    let margin = 30;
    let scale = (pWidth - margin * 2) / srcWidth;

    quoteCFDPdf.html(document.getElementById('divQuoteCFDPdf')!, {
      html2canvas: {
        scrollX: 0,
        scrollY: 0,
        scale: window.matchMedia('screen and (max-width: 1200px)').matches ? 1 : scale,
      },
      x: 0,
      y: 0,
      margin: [75, 35, 35, 35],
      autoPaging: 'text',
      callback: (pdf) => {
        document.getElementById('divQuoteCFDPdf')!.style.display = 'none';
        const quoteCFDEmailDto = new QuoteCFDEmailDto;
        quoteCFDEmailDto.emailFrom = this.cfdEmailDto.emailFrom;
        quoteCFDEmailDto.toRecipients = this.cfdEmailDto.toRecipients;
        quoteCFDEmailDto.ccRecipients = this.cfdEmailDto.ccRecipients;
        quoteCFDEmailDto.bccRecipients = this.cfdEmailDto.bccRecipients;
        quoteCFDEmailDto.subject = this.cfdEmailDto.subject;
        quoteCFDEmailDto.emailBody = this.cfdEmailDto.emailBody;
        quoteCFDEmailDto.emailAttachment = pdf.output('datauristring');

        let fileName = this._orderService.saveCfd(pdf, this.msId);
        quoteCFDEmailDto.fileName = fileName;

        this._emailService.sendCfdEmail(quoteCFDEmailDto).subscribe(() => {
          this.swalAlert.alert('Mail sent successfully');
          this.dialog.close('sent');
        }, error => {
          console.log(error);
          this.swalAlert.alert('An error occurred while sending the email. Please try again.');
        });
      }
    });
  }
}
