<div *ngIf="normalDialogModal.show" class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog [(showModal)]="normalDialogModal.show" uitkId="SubsPriceCal" triggerElementId="myDialog-1_openBtn"
    [closeButtonText]="myCloseButtonText">

    <uitk-dialog-header>
        <div cdkDragHandle>
            Additional User Pricing
        </div>
    </uitk-dialog-header>

    <uitk-dialog-content>
        <div class="uitk-l-grid--max-width">

            <div class="uitk-c-table--responsive">
                <table uitkTable id="basic-table" class="uitk-c-table" aria-describedby="patient-data">
                    <thead class="uitk-c-table__head">
                        <tr>
                            <th *ngFor="let headerName of tableHeaderSales" scope="col">
                                {{ headerName }}
                            </th>
                        </tr>
                    </thead>
                    <tbody id="patient-data" class="uitk-c-table__body">

                        <tr class="uitk-c-table__row" >
                            <td style="vertical-align:middle;">{{ currentUser }}</td>
                            <td>
                                <uitk-form-field>
                                    <input uitkInput id="newUserInput" appTextFormat class="textbox" type="text"
                                        (focusout)="onChangeNewUser($event)" [ngModel]="newUser" />
                                </uitk-form-field>
                            </td>
                            <td style="vertical-align:middle;">{{ totalUser }}</td>
                            <td style="vertical-align:middle;">
                                <div *ngIf="!orderDetailDto.productDto.allowCustomPrice" >
                                    {{ orderDetailDto.listPrice | currency:'USD' }}
                                </div>
                                <div *ngIf="orderDetailDto.productDto.allowCustomPrice">
                                    <uitk-form-field>
                                        <input type="text" id="listPrice-{{ orderDetailDto.id }}" uitkInput
                                            [(customModel)]="orderDetailDto.listPrice" name="myBasicListPrice"
                                            appEnableResource="OrderDetailsListPriceTextBox"
                                            (change)="onChangeListPrice(orderDetailDto)" appPriceFormat />
                                    </uitk-form-field>
                                </div>

                            </td>
                            <td>
                                <uitk-form-field>
                                    <input uitkInput id="discountInput" (change)="onChangeDiscount(discountPipeValue)"
                                        [(customModel)]="discountPipeValue" appDiscountFormat class="textbox" [disabled]="orderDetailDto.productDto.allowCustomStandard"
                                        type="text" />
                                </uitk-form-field>
                            </td>
                            <td>
                                <uitk-form-field>
                                    <input uitkInput id="unitPriceInput" appPriceFormat class="textbox" type="text"
                                        (change)="onChangeUnitPrice(unitPricePipeValue)" [disabled]="orderDetailDto.productDto.allowCustomPrice"
                                        [(customModel)]="unitPricePipeValue" />
                                </uitk-form-field>
                            </td>
                            <td style="vertical-align:middle;">
                                {{ orderDetailDto.extendedPrice | currency:'USD' }}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div style="height:2px"></div>

            <div class="">
                <uitk-panels [isAccordion]="true">
                    <uitk-panel uitkId="TestPanel1">
                        <uitk-panel-header-title>
                            <h4>Tier Pricing</h4>
                        </uitk-panel-header-title>
                        <uitk-panel-content>
                            <div class="uitk-l-grid__col--12">
                                <div class="uitk-c-table--responsive" *ngIf="multiUserDtos.length > 0; else noData">
                                    <table uitkTable id="basic-table" class="uitk-c-table"
                                        aria-describedby="patient-data">
                                        <thead class="uitk-c-table__head">
                                            <tr>
                                                <th *ngFor="let headerName of tableHeader" scope="col">
                                                    {{ headerName }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody id="patient-data" class="uitk-c-table__body">

                                            <tr class="uitk-c-table__row" *ngFor="let multi of multiUserDtos">
                                                <td>{{multi.startRange}} - {{multi.endRange}}</td>
                                                <td>{{multi.listPrice | currency:'USD' }}</td>

                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                                <ng-template #noData>
                                    <label>No tiered pricing available for this product</label>
                                </ng-template>


                            </div>
                        </uitk-panel-content>
                    </uitk-panel>


                </uitk-panels>
            </div>
            <hr>
            <div>
                <h5>Pro-Rated Pricing</h5>
            </div>
            <div style="height:2px"></div>
            <div class="uitk-l-grid__row">

                <uitk-form-field>
                    <input id="my-basic-reactive-checkbox" (change)="onProratedChange($event)" type="checkbox"
                        uitkCheckbox [(ngModel)]="isProrated" />
                    <label uitkCheckboxLabel for="my-basic-reactive-checkbox">Apply Pro-Rated Discount</label>
                </uitk-form-field>
                <div style="width:20px"></div>

                <label uitkLabel class="lbelWidth">Days Used </label>
                <div style="width:0px"></div>
                <div class="uitk-l-grid__col" id="ColValue">
                    {{dayUsed}}
                </div>
                <div style="width:0px"></div>
                <label uitkLabel class="lbelWidth">Total Days</label>
                <div style="width:0px"></div>
                <div class="uitk-l-grid__col" id="ColValue">
                    {{totalDays}}
                </div>
            </div>
            <div style="height:12px"></div>
            <div class="uitk-c-table--responsive">
                <table uitkTable id="basic-table" class="uitk-c-table" aria-describedby="patient-data">
                    <thead class="uitk-c-table__head">
                        <tr>
                            <th *ngFor="let headerName of tableHeaderSales" scope="col">
                                {{ headerName }}
                            </th>
                        </tr>
                    </thead>
                    <tbody id="patient-data" class="uitk-c-table__body">

                        <tr class="uitk-c-table__row">
                            <td>{{currentUser}}</td>
                            <td>{{ newUser }}</td>
                            <td>
                                {{totalUser}}
                            </td>
                            <td>
                                {{orderDetailDto.listPrice | currency:'USD'}}</td>
                            <td>
                                {{rateddDscount | number:'1.3-3' }}<span *ngIf="rateddDscount !==0 || rateddDscount !==null">%</span>
                            </td>
                            <td>
                                {{ratedUnitPrice | currency:'USD' }}
                            </td>
                            <td>
                                {{ratedExtendedprice | currency:'USD' }}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div style="height:2px"></div>


            <div class="uitk-l-grid__row">
                <h5>Final Pricing</h5>
            </div>

            <div class="uitk-c-table--responsive">
                <table uitkTable id="basic-table" class="uitk-c-table" aria-describedby="patient-data">
                    <thead class="uitk-c-table__head">
                        <tr>
                            <th *ngFor="let headerName of tableHeaderSales" scope="col">
                                {{ headerName }}
                            </th>
                        </tr>
                    </thead>
                    <tbody id="patient-data" class="uitk-c-table__body">

                        <tr class="uitk-c-table__row">
                            <td>{{currentUser}}</td>
                            <td>{{ newUser }}</td>
                            <td>
                                {{totalUser}}
                            </td>
                            <td>{{orderDetailDto.listPrice | currency:'USD' }}</td>
                            <td>
                                {{ finalDiscount| toNumber | number:'1.3-3' }}<span *ngIf="finalDiscount !==0 || finalDiscount !==null">%</span>
                            </td>
                            <td>
                                {{finalUnitPrice | currency:'USD'}}
                            </td>
                            <td>
                                {{finalExtendedprice | currency:'USD' }}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div style="height: 2px;"></div>
        </div>
    </uitk-dialog-content>
    <uitk-dialog-actions>
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveChanges()">
            {{ myDefaultButtonText }}
        </button>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeDialog()">
            {{ myGhostButtonText }}
        </button>
    </uitk-dialog-actions>
</uitk-dialog>
    </div>
</div>