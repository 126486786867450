<div class="container-fluid bg-lightgrey p-3">
  <div class="row">
    <div class="col">

      <div class="container-fluid bg-white p-4">
        <div class="row gx-5">
          <!-- Top Left Panel -->
          <div class="col-sm-6">
            <!-- Header -->
            <div class="row">
              <div class="col-sm-12">
                <h5 class="headerTextClass">Batch Returns</h5>
              </div>
            </div>

            <!-- Search for return or order -->
            <div class="row mt-4">
              <div class="col-sm-6">
                <div class="row d-flex">
                  <label uitkLabel class="col-xxl-4 align-self-center">Return #</label>
                  <div class="col-xxl-8 d-flex">
                    <uitk-form-field class="flex-grow-1">
                      <input uitkInput class="textbox" id="returnNumber" type="text"
                        [(ngModel)]="returnNumber" (focus)="clearOrder()" (keyup.enter)="searchReturn()"/>
                    </uitk-form-field>
                    <button class="uitk-c-button uitk-c-button--ghost uitk-icon uitk-icon-search px-0 py-1 mx-2 mt-1"
                      (click)="searchReturn()"></button>
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="row d-flex">
                  <label uitkLabel class="col-xxl-4 align-self-center">Order #</label>
                  <div class="col-xxl-8 d-flex">
                    <input uitkInput class="textbox flex-grow-1" id="orderNumber" type="text" [(ngModel)]="orderNumber"
                      (focus)="clearReturn()" (keyup.enter)="searchOrder()" />
                    <button class="uitk-c-button uitk-c-button--ghost uitk-icon uitk-icon-search px-0 py-1 mx-2 mt-1"
                      (click)="searchOrder()"></button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Order search results -->
            <div class="row mt-4" *ngIf="returnsFound.length > 0">
              <div>
                <label uitkLabel class="col">Related Returns</label>
              </div>

              <div *ngFor="let item of returnsFound; let i=index;">
                <div class="row mt-3" *ngIf="i % 3 == 0 && isReturnAvailable">
                  <ng-container *ngFor="let pos of [0, 1, 2]">
                    <div class="col-xxl-2 d-flex" *ngIf="i+pos < returnsFound.length">
                      <span class="uitk-icon uitk-icon-add mx-3"  [ngStyle]="{'pointer-events': (returnsFound[i+pos].disableAddReturnToBatchButton) ? 'none' : 'auto'}"
                        (click)="onAddRecordToBatch(returnsFound[i+pos])"></span>
                      <a target="_blank" class="link align-self-center" style="text-decoration: underline;"
                        (click)="onSelectedReturn(returnsFound[i+pos].id)">{{ returnsFound[i+pos].id }}</a>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <!-- Spinner -->
            <div class="row mt-4" *ngIf="modeService.isWaiting">
              <div class="col">
                <div id="loadingIndicator">
                  <uitk-loading-indicator uitkId="spinner" [visibleText]="'Loading...'" spinnerSize="large">
                  </uitk-loading-indicator>
                </div>
              </div>
            </div>

          </div>

          <!-- Top right panel -->
          <div class="col-sm-6 border-start border-dark">
            <!-- Header -->
            <div class="row">
              <div class="col">
                <h5 class="text-dark"><strong>Selected Items</strong></h5>
              </div>
            </div>

            <div class="row mt-4">
              <!-- Grid of returns to process -->
              <div class="col-sm-7">
                <table uitkTable [dataSource]="batchReturnsInProcessDtos" id="selectedReturnsGrid" class="uitk-c-table"
                  aria-describedby="return-data">
                  <thead class="uitk-c-table__head">
                    <tr style="text-align: left">
                      <th *ngFor="let headerName of tableHeader" scope="col">
                        {{ headerName }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="uitk-c-table__body">
                    <ng-container *uitkTableRowBody="let returnInProcess">
                      <tr class="uitk-c-table__row">

                        <td>
                          <a target="_blank" class="link" style="text-decoration: underline;"
                            (click)="onSelectedReturn(returnInProcess.returnId)">
                            {{ returnInProcess.returnId }}
                          </a>
                        </td>
                        <td>
                          {{ returnInProcess.amount | currency }}
                        </td>
                        <td>
                          <span class="uitk-icon uitk-icon-trash_delete gridIcons"
                            (click)="deleteBatchReturns(returnInProcess.returnId)"></span>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>

              <!-- Total selected and amount -->
              <div class="col-sm-5">
                <div class="row">
                  <label uitkLabel class="col-xxl-6 align-self-center">Total Selected</label>
                  <div class="col-xxl-6">
                    {{ totalCount }}
                  </div>
                </div>
                <div class="row mt-3">
                  <label uitkLabel class="col-xxl-6 align-self-center">Total Amount</label>
                  <div class="col-xxl-6">
                    {{ totalAmount | currency }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Process and cancel buttons -->
            <div class="row mt-4">
              <div class="col-lg-9 d-flex justify-content-end">
                <button id="save" type="button" class="uitk-c-button uitk-c-button--default mx-3"
                  (click)="processBatchReturns()">
                  Process
                </button>
                <button id="close" type="button" class="uitk-c-button uitk-c-button--ghost"
                  (click)="deleteAllBatchReturns()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Return inquiry -->
<div *ngIf="enableReturnInquirySection && (orderService.orderDto.orderType === 'R' || orderService.orderDto.orderType === 'A') && (orderService.orderDto.orderStatus === ' '||orderService.orderDto.orderStatus === 'V'||orderService.orderDto.orderStatus === 'C')">
  <app-return-inquiry [batchReturn]="batchReturn" [returnDtoList]="returnsFound"
    [isnavigateFromBatchReturn]="isnavigateFromBatchReturn">
  </app-return-inquiry>
</div>

<div *ngIf="warningDailogDiv">
  <div *ngIf="normalDialogModal.show" class = "overlayForPopups">
  <div cdkDrag>
  <uitk-dialog [closeBtn]=true uitkId="warningDailog" [(showModal)]="normalDialogModal.show">
    <uitk-dialog-header>
      <div cdkDragHandle>
        Alert
      </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
      <p>{{dailogContentMsg}}</p>
    </uitk-dialog-content>
  </uitk-dialog>
</div>
</div>
</div>

<div style="width: 99%; padding-left: 26%;"
*ngIf="(enableReturnInquirySection)">
  <app-add-return-to-batch (addRecordToBatchEvent)="onAddRecordToBatch(orderService.orderDto)">
  </app-add-return-to-batch>
</div>
