import { Injectable } from '@angular/core';
import { AccountInformationComponent } from 'src/app/components/order-entry/account-information/account-information.component';
import { BillToDto } from '../dtos/bill-to-dto.model';
import { MultiUserPricingDto } from '../dtos/multi-user-pricing.model';
import { OrderDetailDto } from '../dtos/order-detail-dto.model';
import { OrderDto } from '../dtos/order-dto.model';
import { AuthorizationService } from './authorization.service';
import { ConfigService } from './config.service';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root'
})
export class PricingService {
  swalAlert = new SwalAlert();
  constructor(
    private readonly _configService: ConfigService,
    private readonly authorizationService: AuthorizationService) {
  }

  calculateListPricing(orderDto: OrderDto, detail: { orderQuantity: number, unitPrice: number, productCode: string, id: number, listPrice: number }): number {
    let orderQuantity = detail.orderQuantity;

    if (!this._configService.reference) {
      this.swalAlert.alert('Error while calculating list price: References have not yet been loaded.');
      return 0;
    }

    let productDto = this._configService.reference.productDtos.filter(p => p.productCode === detail.productCode)[0];

    if (!productDto) {
      this.swalAlert.alert(`Error while creating new order detail (calculateListPricing): Unable to find a product with product code '${detail.productCode}'`);
      return 0;
    }

    if (productDto.allowCustomStandard || productDto.allowCustomPrice) {
      return detail.listPrice;
    }

    if (productDto.productCode.indexOf('V') === 0) {
      // V Line items use the list price and tiered pricing of their parent.
      productDto = this._configService.reference.productDtos.filter(p => p.productCode === detail.productCode.substring(1))[0];

      // The tiered price of a V Line item is calculated based on the sum of the parent and combined V line items on the order.
      orderQuantity += orderDto.orderDetailDtos
        .filter(item => item.id !== detail.id && (item.productCode === detail.productCode || item.productCode === detail.productCode.substr(1)))
        .reduce((sum, item) => sum + item.orderQuantity, 0);
    }

    let listPrice = productDto.listPrice;

    // Check whether the product has the multi user pricing or not.
    if (productDto.isMultiUser && productDto.multiUserPricingDtos.length > 0) {
      // If They entered a orderQuantity of 7.  Find the record where the orderQuantity >= startRange and orderQuantity <= endRange.  
      //Use the ListPrice of the multiUserDto to update the orderDetail.ListPrice.
      const multiUserPricing: MultiUserPricingDto = productDto.multiUserPricingDtos.filter(m => m.startRange <= orderQuantity && m.endRange >= orderQuantity)[0];

      if (multiUserPricing != null) {
        listPrice = multiUserPricing.listPrice;
      }
    }

    return listPrice;
  }

  calculateAccountDiscount(billToDto: BillToDto, productId: number): number {
    if (!billToDto.accountPricingDtos) {
      return 0;
    }

    const accountPricingDto = billToDto.accountPricingDtos.filter(a => a.productId === productId)[0];

    if (!accountPricingDto) {
      return 0;
    }

    return accountPricingDto.discount;
  }

  calculateDiscount(detail: { listPrice: number, discount: number, unitPrice: number }): void {
    detail.discount = this.round((100 - detail.unitPrice / detail.listPrice * 100), 10);
    this.calculateUnitPrice(detail);
  }

  calculateUnitPrice(detail: { listPrice: number, discount: number, unitPrice: number }): void {
    if (detail.discount === undefined || isNaN(detail.discount)) {
      detail.discount = 0;
    }

    detail.unitPrice = this.round((detail.listPrice * (100 - detail.discount) / 100), 2);
  }

  calculateExtendedPrice(detail: { listPrice: number, discount: number, orderQuantity: number, extendedPrice: number }): void {
    if (detail.discount === undefined || isNaN(detail.discount)) {
      detail.discount = 0;
    }

    detail.orderQuantity = detail.orderQuantity === undefined ? 0 : detail.orderQuantity;
    detail.extendedPrice = isNaN(detail.orderQuantity * this.round((detail.listPrice * (100 - detail.discount) / 100), 2)) ? 0 :
      detail.orderQuantity * this.round((detail.listPrice * (100 - detail.discount) / 100), 2);
  }

  calculateOpenAmount(orderDto: OrderDto): void {
    orderDto.openAmount = 0;

    if (orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V' && o.productCode !== '0010').length > 0) {
      orderDto.openAmount = orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V' && o.productCode !== '0010' && o.productCode !== '0048')
        .map(o => o.openQuantity * this.round(o.listPrice * (100 - o.discount) / 100, 2)).reduce((a, b) => a + b, 0);
    }
  }

  calculateDepositAmount(orderDto: OrderDto): number {
    let totalDepositAmount = 0;

    if (orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V' && o.productCode === '0010').length > 0) {
      totalDepositAmount = orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V' && o.productCode === '0010')
        .map(o => o.openQuantity * this.round((o.listPrice * (100 - o.discount) / 100), 2)).reduce((a, b) => a + b);
    }
    return totalDepositAmount;
  }

  calculateShippedAmount(orderDto: OrderDto): void {
    orderDto.shippedAmount = 0;

    if (orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V' && o.productCode !== '0010').length > 0) {
      orderDto.shippedAmount = orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V' && o.productCode !== '0010' && o.productCode !== '0048')
        .map(o => o.shippedQuantity * this.round(o.listPrice * (100 - o.discount) / 100, 2)).reduce((a, b) => a + b, 0);
    }
  }

  calculateMaxDiscount(discount: number, productId: number): number {
    const productDto = this._configService.getProductDetails(productId);
    if (discount > productDto.maxDiscountPercent && productDto.maxDiscountPercent === 0) {
      this.swalAlert.alert('Discounts are not allowed for this Item code');
      if (!this.authorizationService.hasResource("OrderInquiryByPassMaxDiscountAlert") || !this.authorizationService.hasResource("OrderEntryByPassMaxDiscountAlert")) {
        return 0;
      }
    }
    else if (discount > productDto.maxDiscountPercent) {
      this.swalAlert.alert('Item ' + productDto.productCode + ' has a max discount % of ' + productDto.maxDiscountPercent +
        '. The discount on this item has been reduced to the Max Discount % allowed.');
      if(discount > 100){
        return productDto.maxDiscountPercent;
      }
      if (!this.authorizationService.hasResource("OrderInquiryByPassMaxDiscountAlert") || !this.authorizationService.hasResource("OrderEntryByPassMaxDiscountAlert")) {
        return productDto.maxDiscountPercent;
      }
      if (productDto.productCode === '1553')
      {
        return productDto.maxDiscountPercent;
      }
    }

    return discount ?? 0;
  }

  round(amount: number, digits: number): number {
    amount = Math.round(amount * 10000000000) / 10000000000;
    amount = Math.round(amount * (10 ** digits)) / (10 ** digits);
    return amount;
  }

  getSplDiscount(orderDto:OrderDto, productId:number):any {
    if(orderDto.billToDto.specifiedPriceList){
      const discount = orderDto.billToDto.accountPricingDtos?.filter((priceObj)=>
        priceObj.productId === productId
      )[0]?.discount;
      return discount;
    }else{
      return 0;
    }
  }

  getAllowedPrice(orderDetailDto:OrderDetailDto,quantity:number):number {
    if(orderDetailDto.productDto?.multiUserPricingDtos.length > 0){
      return orderDetailDto.productDto?.multiUserPricingDtos.filter(mup=> 
        mup.startRange <= quantity && mup.endRange >= quantity
      )[0]?.allowedPrice;
    }else{
      return orderDetailDto.productDto?.allowedPrice;
    }
  }
  calculateTp(orderDetailDto:OrderDetailDto){
    if(isFinite((orderDetailDto.unitPrice/(this.getAllowedPrice(orderDetailDto, orderDetailDto.orderQuantity))))){
      return (orderDetailDto.unitPrice/(this.getAllowedPrice(orderDetailDto, orderDetailDto.orderQuantity)));
    }else{
      return 0;
    }
  }
  
}
