<!-- <div>
  <label uitkLabel class="lblConfigSet">{{ orderDetailDto.productCode }}: {{ orderDetailDto.productDescription }}</label>
</div> -->
<div>
  <label *ngIf="orderDetailDto.dataFileDtos.dataFileLicenseDtos.length > 0">
    Multi-User Licensing. Additional licenses that the customer wants to purchase.
  </label>
  <div class="uitk-l-grid__row" *ngIf="orderDetailDto.dataFileDtos.dataFileLicenseDtos.length > 0">
    <div class="uitk-l-grid__col" style="max-width: 1100px;">
      <div id="loadingSpinner" class="uitk-l-grid" *ngIf="loading">
        <uitk-loading-indicator uitkId="spinner" [visibleText]="visibleTextValue" spinnerSize="large">
        </uitk-loading-indicator>
      </div>
      <div *ngIf="!loading" class="uitk-c-table--responsive">
        <table uitkTable [dataSource]="orderDetailDto.dataFileDtos.dataFileLicenseDtos" class="uitk-c-table" aria-describedby="invoiceNo">
          <thead class="uitk-c-table__head">
            <tr style="text-align: left">
              <th *ngFor="let headerName of tableHeader" scope="col">
                {{ headerName }}
              </th>
            </tr>
          </thead>
          <tbody class="uitk-c-table__body">
            <ng-container *uitkTableRowBody="let dataFileDto">
              <tr *ngIf="dataFileDto" class="uitk-c-table__row">
                <td>
                  {{ dataFileDto.productCode }}
                </td>
                <td>
                  <uitk-form-field>
                    <input type="text"
                           uitkInput
                           [disabled]="!dataFileDto.allowCustomPrice"
                           [(customModel)]="dataFileDto.listPrice"
                           appPriceFormat
                           name="myListPriceInput"
                           id="myListPriceInput-{{ dataFileDto.addonProductId }}"
                           (change)="onChangeListPrice(dataFileDto)"
                           style="width: 80px;" />
                  </uitk-form-field>
                </td>
                <td>
                  <uitk-form-field>
                    <input type="text"
                           uitkInput
                           (keyup)="checkValue(dataFileDto)"
                           [(customModel)]="dataFileDto.orderQuantity"
                           appQuantityFormat
                           name="myorderQuantityInput"
                           id="myorderQuantityInput-{{ dataFileDto.addonProductId }}"
                           (change)="onChangeOrderQuantity($event, dataFileDto)"
                           style="width: 80px;" appQuantityFormat/>
                  </uitk-form-field>
                </td>
                <td>
                  <uitk-form-field>
                    <input type="text"
                           uitkInput
                           [(customModel)]="dataFileDto.discount"
                           name="myDiscountInput"
                           id="myDiscountInput-{{ dataFileDto.addonProductId }}"
                           (change)="onChangeDiscount($event, dataFileDto)"
                           style="width: 80px;"
                           [disabled]="dataFileDto.allowCustomStandard" appDiscountFormat/>
                  </uitk-form-field>
                </td>
                <td>
                  <uitk-form-field>
                    <input type="text"
                           uitkInput
                           [(customModel)]="dataFileDto.unitPrice"
                           name="myunitPriceInput"
                           id="myunitPriceInput-{{ dataFileDto.addonProductId }}"
                           (change)="onChangeUnitPrice($event, dataFileDto)"
                           style="width: 80px;"
                           [disabled]="dataFileDto.allowCustomPrice" appPriceFormat/>
                  </uitk-form-field>
                </td>
                <td>
                  {{ dataFileDto.extendedPrice | number:'1.2-2'}}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div id="addOns_DataFile">
    <div>
      <label uitkLabel class="lblConfigSet" style="line-height: 50px;">Add-ons</label>
    </div>
    <div class="uitk-l-grid__row">
      <div class="uitk-l-grid__col" style="max-width: 1100px;">
        <div id="loadingSpinner" class="uitk-l-grid" *ngIf="loading">
          <uitk-loading-indicator uitkId="spinner" [visibleText]="visibleTextValue" spinnerSize="large">
          </uitk-loading-indicator>
        </div>

        <app-void-item-dialog [dialog]="voidItemDialog" (dialogClosed)="onVoidLineItemDialogCancel()" [selectedOrder]="selectedOrderDetail"></app-void-item-dialog>

        <div *ngIf="!loading" class="uitk-c-table--responsive">
          <table uitkTable [dataSource]="orderDetailDto.dataFileDtos.dataFileNonLicenseDtos" class="uitk-c-table" aria-describedby="invoiceNo">
            <thead class="uitk-c-table__head">
              <tr style="text-align: left">
                <th *ngFor="let headerName of addOnsTableHeader" scope="col">
                  {{ headerName }}
                </th>
              </tr>
            </thead>
            <tbody class="uitk-c-table__body">
              <ng-container *uitkTableRowBody="let dataFileDto">
                <tr *ngIf="dataFileDto" class="uitk-c-table__row">
                  <td>
                    <uitk-form-field>
                      <input type="checkbox" class="checkBoxInGrid"
                             uitkCheckbox
                             name="selectRowCheckBox"
                             [(ngModel)]="dataFileDto.isSelected"
                             (change)="onChangeRecordSelction(dataFileDto.isSelected, dataFileDto)" />
                    </uitk-form-field>
                  </td>
                  <td>
                    {{ dataFileDto.productCode }}
                  </td>
                  <td>
                    {{ dataFileDto.description }}
                  </td>
                  <td>
                    <uitk-form-field>
                      <input type="text"
                             uitkInput
                             [disabled]="!dataFileDto.allowCustomPrice"
                             [(customModel)]="dataFileDto.listPrice"
                             appPriceFormat
                             name="myListPriceInput"
                             id="myListPriceInput-{{ dataFileDto.addonProductId }}"
                             (change)="onChangeListPrice(dataFileDto)"
                             style="width: 80px;" />
                    </uitk-form-field>
                  </td>
                  <td>
                    <uitk-form-field>
                      <input type="text"
                             uitkInput
                             [(customModel)]="dataFileDto.orderQuantity"
                             appQuantityFormat
                             name="myorderQuantityInput"
                             id="myorderQuantityInput-{{ dataFileDto.addonProductId }}"
                             (change)="onChangeOrderQuantity($event, dataFileDto,true)"
                             style="width: 80px;" />
                    </uitk-form-field>
                  </td>
                  <td>
                    <uitk-form-field>
                      <input type="text"
                             uitkInput
                             [(customModel)]="dataFileDto.discount"
                             appDiscountFormat
                             name="myDiscountInput"
                             id="myDiscountInput-{{ dataFileDto.addonProductId }}"
                             (change)="onChangeDiscount($event, dataFileDto)"
                             style="width: 80px;"
                             [disabled]="dataFileDto.allowCustomStandard" />
                    </uitk-form-field>
                  </td>
                  <td>
                    <uitk-form-field>
                      <input type="text"
                             uitkInput
                             [(customModel)]="dataFileDto.unitPrice"
                             appPriceFormat
                             name="myunitPriceInput"
                             id="myunitPriceInput-{{ dataFileDto.addonProductId }}"
                             (change)="onChangeUnitPrice($event, dataFileDto)"
                             style="width: 80px;"
                             [disabled]="dataFileDto.allowCustomPrice" />
                    </uitk-form-field>
                  </td>
                  <td>
                    {{ dataFileDto.extendedPrice | currency: 'USD' }}
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</div>
