<div *ngIf="notesDialogModal.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkId="notesAction" [(showModal)]="notesDialogModal.show" [closeBtn]=false>
  <uitk-dialog-header *ngIf="viewNoteHeader">
    <div cdkDragHandle>
      <div class="uitk-c-dialog__close">
        <button type="button" tabindex="0" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" id="topCloseBtn"
          aria-describedby="notesAction_closeBtn" (click)="cancelDialog()" appAutoFocus>
          Close
        </button>
      </div>
      Notes History
    </div>
  </uitk-dialog-header>
  <uitk-dialog-header *ngIf="addNoteHeader">
    Add Note
  </uitk-dialog-header>
  <uitk-dialog-content>
    <button id="addNotesButton" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs row-one"
            (click)="showAddNew()" [(ngModel)]="isNewNote" [hidden]="isNewNote">
      + Add Note
    </button>
    <div *ngIf="modeService.isWaiting===true">
      <div class="uitk-l-grid--max-width">
        <div id="loadingIndicator">
          <uitk-loading-indicator uitkId="spinner" [visibleText]="visibleTextValue" spinnerSize="large">
          </uitk-loading-indicator>
        </div>
      </div>
    </div>
    <div *ngIf="modeService.isWaiting === false">
      <div [hidden]="!isNewNote">
        <div class="row">
          <label uitkLabel class="col-lg-2">Reviewer Name</label>
          <div class="col-lg-4">
            <uitk-form-field>
              <input uitkInput class="textbox" type="text" value="{{userName}}" disabled />
            </uitk-form-field>
          </div>
          <label uitkLabel class="col-lg-2">Follow Up Date</label>
          <div class="col-lg-4">
            <uitk-form-field>
              <uitk-date-picker id="followUpDatePicker" [model]="datePickerConfig" [(ngModel)]="followUpDate" #datePic (change)="formatDate()">
              </uitk-date-picker>
            </uitk-form-field>
            <uitk-notification uitkId="followUpDateValidation" [variant]="'error'" [show]="showFollowUpDateValidation">
              <span>{{ followUpDateValidationMessage }}</span>
            </uitk-notification>
          </div>
        </div>
        <div class="row">
          <label uitkLabel class="col-lg-2">Notes</label>
          <div class="col">
            <form [formGroup]="profileForm">
              <uitk-form-field>
                <textarea uitkTextarea name="textField" [textLimit]="maxTextLimit" formControlName="textarea"
                          uitkId="notes" [(ngModel)]="noteText">
              </textarea>
                <uitk-textarea-character-remaining-message (onCountChange)="onCountChange($event)">
                  {{ remainingCount }} {{ myCharRemaining }}
                </uitk-textarea-character-remaining-message>
              </uitk-form-field>
              <uitk-notification uitkId="followUpDateValidation" [variant]="'error'" [show]="showNotesFieldValidation">
                <span>Notes field should not be empty</span>
              </uitk-notification>
            </form>
          </div>
        </div>
        <div class="actionButtons">
          <uitk-dialog-actions>
            <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveWorklistNote()" autofocus>
              Save
            </button>
            <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="hideDialog()">
              Cancel
            </button>
          </uitk-dialog-actions>
        </div>
      </div>
      <div *ngFor="let notes of notesData" id="notesdiv" [hidden]="isNoteHistoryHidden">
        <div class="row">
          <div class="col-lg-6 text-start">
            <p class="notesdate" id="notedate">{{notes.noteDate | date: 'MM/dd/yyyy HH:mm:ss'}}</p>
            <p class="notesContact" id="contactname">{{notes.noteContact}}</p>
          </div>
          <div class="col-lg-6 text-end" id="editType">
            <span class="uitk-icon uitk-icon-info_hollow sm-icon" *ngIf="notes.followUpDate!=null"
                  style="font-size: 17px;margin-top: 4px;"></span>
            &nbsp;
            <p *ngIf="notes.followUpDate!=null" style="white-space: nowrap; font-weight: 700;">
              Follow Up Date:
              {{notes.followUpDate | date: 'MM/dd/yyyy'}}
            </p>
          </div>
        </div>
        <div class="noteTextSec">
          <div class="textdisable" id="notesText-{{notes.uniqueId }}" [line-truncation]="numOfLines"
               (click)="setdeflines(notes.uniqueId) " [disabled]="notes.isTruncated" [options]="{ellipsis:'(⋯)'}">
            {{notes.notesText}}
          </div>
          <div class="actionSection">
            <a href="javaScript:void(0)" (click)="editNote(notes)" class="uitk-icon uitk-icon-edit sm-icon">Edit</a>
            <a href="javaScript:void(0)" (click)="deleteNote(notes)" class="uitk-icon uitk-icon-trash_delete sm-icon">Delete</a>
          </div>
        </div>
      </div>
    </div>
  </uitk-dialog-content>
</uitk-dialog>
  </div>
</div>