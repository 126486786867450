<div [ngClass]="panelBackground" class="panel">
  <uitk-panel uitkId="orderSearchPanel" [collapsible]="collapsiblePanel" [open]="openPanel"
              (openChange)="setPanelBGHeight()">
    <uitk-panel-header-title>
      <h2>Subscription Search</h2>
    </uitk-panel-header-title>
    <uitk-panel-content>
      <div class="uitk-l-grid__row" (keyup.enter)="search()">
        <div class="uitk-l-grid__col">
          <div class="uitk-l-grid__col--3">
            <label uitkLabel>Account ID</label>
          </div>
          <div class="uitk-l-grid__col--6">
            <uitk-form-field>
              <input uitkInput id="accountNumber" class="textbox" type="number" value="" [(ngModel)]="accountNumber" (ngModelChange)="clearSubNumAndItemNum()"
                      autofocus min="1" oninput="validity.valid || (value = '');" />
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__col uitk-u-flex__align--center">
          <div class="uitk-l-grid__col--3">
            <label uitkLabel>Contact ID</label>
          </div>
          <div class="uitk-l-grid__col--6">
            <uitk-form-field>
              <input uitkInput id="contactId" class="textbox" type="text" value=""
                     [(ngModel)]="contactId" (ngModelChange)="clearSubNumAndItemNum()" />
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__col uitk-u-flex__align--right">
          <div class="uitk-l-grid__col--3">
            <label uitkLabel>Order #</label>
          </div>
          <div class="uitk-l-grid__col--6">
            <uitk-form-field>
              <input uitkInput id="orderId" class="textbox" type="text" value=""
                     [(ngModel)]="orderId" (ngModelChange)="clearSubNumAndItemNum()" />
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="uitk-l-grid__row ">
        <div class="uitk-l-grid__col">
          <div class="uitk-l-grid__col--3">
            <label uitkLabel>Item #</label>
          </div>
          <div class="uitk-l-grid__col--6 ">
            <uitk-form-field>
              <input uitkInput id="itemNumber" class="textbox" type="text" value="" [(ngModel)]="itemNumber" (keydown.enter)="search()"
                     [disabled]="!(accountNumber || contactId)" />
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__col uitk-u-flex__align--center">
          <div class="uitk-l-grid__col--3">
            <label uitkLabel>Sub #</label>
          </div>
          <div class="uitk-l-grid__col--6">
            <uitk-form-field>
              <input uitkInput id="subscriptionNumber" class="textbox" type="text" value="" [(ngModel)]="subscriptionNumber" (keydown.enter)="search()"
                     [disabled]="!(accountNumber || contactId)" />
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__col uitk-u-flex__align--right">
          <div class="uitk-l-grid__col--3"></div>
          <div class="uitk-l-grid__col--6"></div>
        </div>
      </div>
      <div id="searchDiv" class="uitk-l-grid__row ">
        <div class="uitk-u-flex__align--left">
          <button id="searchBtn" (click)="search()" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--lg">
            Search
          </button>
          <a class="link" (click)="clearSearch()">Clear</a>
        </div>
      </div>
    </uitk-panel-content>
  </uitk-panel>
</div>
<div *ngIf="modeService.mode == Mode.SubscriptionWait">
  <body>
    <app-loader> </app-loader>
  </body>
</div>
<app-subscription-search-results [hidden]="!showResults" [collapsedHeader]="collapsedHeader" #subscriptionSearchResultsComponent>
</app-subscription-search-results>
