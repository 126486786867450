import { Directive, HostBinding, Input, OnChanges, ElementRef, Renderer2 } from '@angular/core';
import { AuthorizationService } from '../services/authorization.service';

@Directive({
  selector: '[appEnableResource]'
})
export class EnableResourceDirective implements OnChanges {
  @Input('appEnableResource') resourceName: string = '';
  @Input('disabled') disabled: string | boolean = '';
  @Input('customDisabled') customDisabled: string | boolean = '';
  ngDisabled="ng-reflect-disabled";
  ngIsDisabled="ng-reflect-is-disabled";
  uitkGridIcons='uitk-icon uitk-icon-settings gridIcons';
  uitkRemoveGridIcons='uitk-icon uitk-icon-remove gridIcons';
  uitkTrashGridIcons='uitk-icon uitk-icon-trash_delete gridIcons';
  constructor(
    private readonly authorizationService: AuthorizationService,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) { }

  ngOnChanges(): void {
    //If they have access to resource apply the disabled property binding
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);

    if (currentUser != null) {
      this.handleControls();
    }
  }

  ngAfterViewInit() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);

    if (currentUser != null) {
      this.handleControls();
    }
  }

  handleControls(): void {
    if (this.authorizationService.hasResource(this.resourceName)) {
      if (this.disabled || this.customDisabled) {
        this.disableControl();
      }
      else {
        this.enableControl();
      }
    }
    else {
      this.disableControl();
    }
  }

  disableControl(): void {
    if (this.el.nativeElement.className === 'linkAddress') {
      this.renderer.setAttribute(this.el.nativeElement, "class", "link disabled");
    }

    if (this.el.nativeElement.tagName === "A") {//to disable <anchor> tag elements
      this.el.nativeElement.style.pointerEvents = 'none';
      this.el.nativeElement.style.cursor = 'default';
    }

    if (this.el.nativeElement.localName === 'uitk-dropdown') {
      this.el.nativeElement.firstChild.disabled = true;
      this.renderer.setAttribute(this.el.nativeElement, this.ngDisabled, "true");
      this.renderer.setAttribute(this.el.nativeElement, this.ngIsDisabled, "true");
      this.renderer.setAttribute(this.el.nativeElement, "ng-reflect-get-disabled", "true");
      this.renderer.setAttribute(this.el.nativeElement.firstChild.firstChild, "class", "uitk-c-form__control uitk-c-select uitk-c-dropdown__trigger uitk-c-dropdown--disabled");
      this.renderer.setAttribute(this.el.nativeElement.firstChild, "disabled", "true");
      this.renderer.setAttribute(this.el.nativeElement.firstChild.firstChild, "aria-disabled", "true");
      this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
      this.renderer.setAttribute(this.el.nativeElement.firstChild, 'style', 'pointer-events: none');
    }

    if (this.el.nativeElement.localName === 'select') {
      this.renderer.setAttribute(this.el.nativeElement, "disabled", "true");
      this.renderer.setAttribute(this.el.nativeElement, this.ngDisabled, "true");
      this.renderer.setAttribute(this.el.nativeElement, this.ngIsDisabled, "true");
    }

    if ((this.el.nativeElement.className === 'uitk-icon uitk-icon-settings gridIcons disabled') || (this.el.nativeElement.className === this.uitkGridIcons)) {
      this.renderer.setAttribute(this.el.nativeElement, "class", "uitk-icon uitk-icon-settings gridIcons disabled");
    }

    if (this.el.nativeElement.className === this.uitkRemoveGridIcons) {
      this.renderer.setAttribute(this.el.nativeElement, "class", "uitk-icon uitk-icon-remove gridIcons disabled");
      this.el.nativeElement.style.pointerEvents = 'none';
      this.el.nativeElement.style.cursor = 'default';
    }

    if (this.el.nativeElement.className === this.uitkTrashGridIcons) {
      this.renderer.setAttribute(this.el.nativeElement, "class", "uitk-icon uitk-icon-trash_delete gridIcons disabled");
      this.el.nativeElement.style.pointerEvents = 'none';
      this.el.nativeElement.style.cursor = 'default';
    }

    if (this.el.nativeElement.localName === 'button') {
      this.renderer.setAttribute(this.el.nativeElement, "disabled", "true");//Sets the attribute of the element to Disabled.
    }

    if (this.el.nativeElement.tagName === "UITK-RADIO-GROUP") {
      for (let i = 0; i < this.el.nativeElement.firstChild.firstChild.getElementsByTagName('li').length; i++) {
        this.el.nativeElement.firstChild.firstChild.getElementsByTagName('li')[i].firstChild.disabled = true;
      }
    }

    if (this.el.nativeElement.localName === 'uitk-select') {
      this.renderer.setAttribute(this.el.nativeElement, this.ngDisabled, "true");
      this.renderer.setAttribute(this.el.nativeElement, this.ngIsDisabled, "true");
      this.el.nativeElement.firstChild.disabled = true;
    }

    if (this.el.nativeElement.localName === 'input') {
      this.el.nativeElement.disabled = true;
      this.el.nativeElement.readOnly = true;
    }
    if (this.el.nativeElement.localName === 'textarea') {
      this.el.nativeElement.disabled = true;
    }
  }

  enableControl(): void {
    if (this.el.nativeElement.className === 'linkAddress') {
      this.renderer.setAttribute(this.el.nativeElement, "class", "link");
    }

    if (this.el.nativeElement.localName === 'uitk-dropdown') {
      this.renderer.removeAttribute(this.el.nativeElement, this.ngDisabled);
      this.renderer.removeAttribute(this.el.nativeElement, this.ngIsDisabled);
      this.renderer.removeAttribute(this.el.nativeElement, "ng-reflect-get-disabled");
      this.renderer.setAttribute(this.el.nativeElement.firstChild.firstChild, "class", "uitk-c-form__control uitk-c-select uitk-c-dropdown__trigger");
      this.renderer.removeAttribute(this.el.nativeElement.firstChild, "disabled");
      this.renderer.removeAttribute(this.el.nativeElement.firstChild.firstChild, "aria-disabled");
      this.renderer.setAttribute(this.el.nativeElement.firstChild, 'style', 'pointer-events: all');
    }

    if (this.el.nativeElement.localName === 'select') {
      this.renderer.removeAttribute(this.el.nativeElement, "disabled");
      this.renderer.removeAttribute(this.el.nativeElement, this.ngDisabled);
      this.renderer.removeAttribute(this.el.nativeElement, this.ngIsDisabled);
    }

    if ((this.el.nativeElement.className.indexOf(this.uitkGridIcons)) !== -1) {
      this.renderer.setAttribute(this.el.nativeElement, "class", "uitk-icon uitk-icon-settings gridIcons");
    }

    if ((this.el.nativeElement.className.indexOf(this.uitkRemoveGridIcons)) !== -1) {
      this.renderer.setAttribute(this.el.nativeElement, "class", "uitk-icon uitk-icon-remove gridIcons");
      this.el.nativeElement.style = '';
    }

    if ((this.el.nativeElement.className.indexOf(this.uitkTrashGridIcons)) !== -1) {
      this.renderer.setAttribute(this.el.nativeElement, "class", "uitk-icon uitk-icon-trash_delete gridIcons");
      this.el.nativeElement.style = '';
    }

    if (this.el.nativeElement.localName === 'button') {
      this.renderer.removeAttribute(this.el.nativeElement, "disabled");//Sets the attribute of the element to Disabled.
    }

    if (this.el.nativeElement.tagName === "UITK-RADIO-GROUP") {
      for (let i = 0; i < this.el.nativeElement.firstChild.firstChild.getElementsByTagName('li').length; i++) {
        this.el.nativeElement.firstChild.firstChild.getElementsByTagName('li')[i].firstChild.disabled = false;
      }
    }

    if (this.el.nativeElement.localName === 'uitk-select') {
      this.renderer.removeAttribute(this.el.nativeElement, this.ngDisabled);
      this.renderer.removeAttribute(this.el.nativeElement, this.ngIsDisabled);
      this.el.nativeElement.firstChild.disabled = false;
    }

    if (this.el.nativeElement.localName === 'input') {
      this.el.nativeElement.disabled = false;
      this.el.nativeElement.readOnly = false;
    }
  }
}