import { Component, Input, OnInit } from '@angular/core';
import { UITKTableDataSource } from '@uitk/angular';
import { RecentQuoteOrOrderDetailsDto } from '../../dtos/recent-quote-or-order-details-dto.model';
import { RecentQuotesOrOrdersDto } from '../../dtos/recent-quotes-or-orders-dto.model';
import { PricingService } from '../../services/pricing.service';

@Component({
  selector: 'app-recent-quote-or-order-details-dialog',
  templateUrl: './recent-quote-or-order-details-dialog.component.html',
  styleUrls: ['./recent-quote-or-order-details-dialog.component.css']
})

export class RecentQuoteOrOrderDetailsDialogComponent implements OnInit {
  @Input() recentQuoteOrOrder = new RecentQuotesOrOrdersDto();
  @Input() recentQuoteOrOrderDetailsDialog = { show: false };
  tableHeaders = ['Quote/Order#', 'Item', 'Description', 'Quantity', 'List Price', 'Discount', 'Unit Price', 'Extended Price'];
  recentQuoteOrOrderDetails = new UITKTableDataSource<RecentQuoteOrOrderDetailsDto>([]);

  constructor(private readonly pricingService: PricingService) { }

  ngOnInit(): void {
    this.recentQuoteOrOrderDetails.data = [];
    this.getRecentQuoteOrOrderDetails(this.recentQuoteOrOrder);
  }

  async getRecentQuoteOrOrderDetails(recentQuoteOrOrder: RecentQuotesOrOrdersDto): Promise<void> {
    this.recentQuoteOrOrderDetails.data = recentQuoteOrOrder.recentQuoteOrOrderDetails;

    this.recentQuoteOrOrderDetails.data.forEach(element => {
      this.pricingService.calculateUnitPrice(element);
    });

    this.recentQuoteOrOrderDetails.data = [...this.recentQuoteOrOrderDetails.data];

    setTimeout(() => {
      if (document.getElementById(`viewRecentQuotesOrOrders_closeBtn`)) {
        document.getElementById(`viewRecentQuoteOrOrderDetails_closeBtn`)?.focus();
      }
    });
  }

  async focusParentDialog(): Promise<void>{
    setTimeout(() => {
      if (document.getElementById(`viewRecentQuotesOrOrders_closeBtn`)) {
        document.getElementById(`viewRecentQuotesOrOrders_closeBtn`)?.focus();
      }
    });
  }
}