import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IUITKColumnState, UITKSortDirection, UITKTableComponent, UITKTableDataSource, UITKTableSortDirective } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Mode } from '../../enums/mode.enum';
import { SearchInvoiceByCCSearchResultsDto } from '../../dtos/searchinvoice-by-cc-searchresults.model';
import { ConfigService } from '../../services/config.service';
import { ModeService } from '../../services/mode.service';
import { SearchInvoiceByCCService } from '../../services/search-invoice-bycc.service';

@Component({
  selector: 'app-searchinvoice-search-results',
  templateUrl: './searchinvoice-search-results.component.html',
  styleUrls: ['./searchinvoice-search-results.component.css']
})
  
export class SearchinvoiceSearchResultsComponent implements OnInit {
  private readonly _searchInvoiceByCCService: SearchInvoiceByCCService;
  private readonly _configService: ConfigService;
  private readonly _router: Router;
  Invoices = new UITKTableDataSource<SearchInvoiceByCCSearchResultsDto>([]);
  gotInquirySearchResultsSubscription: Subscription | undefined;
  Mode= Mode;
  tableHeader = [
    {
      name: 'Account ID', id: 'accountId'
    },
    {
      name: 'Invoice #', id: 'invoiceNumber'
    },
    {
      name: 'Order #', id: 'orderNumber'
    },
    {
      name: 'Invoice Amt', id: 'invoiceAmount'
    },
    {
      name: 'Invoice Date', id: 'invoiceDate'
    },
    {
      name: 'Balance', id: 'balance'
    },
    {
      name: 'Auth Code', id: 'authCode'
    }
  ];
  constructor(searchInvoiceByCCService: SearchInvoiceByCCService,
    public modeService: ModeService,
    configService: ConfigService,
    router: Router) {
    this._searchInvoiceByCCService = searchInvoiceByCCService;
    this._configService = configService;
    this._router = router;
  }

  ngOnInit(): void {
    this.filter();
  }
  @ViewChild('sortTable') uitkTableSort!: UITKTableSortDirective;
  @ViewChild('rootTable') uitkTable!: UITKTableComponent<SearchInvoiceByCCSearchResultsDto[]>;

  ngAfterViewInit() {
    this.uitkTableSort.setDirection({ column: 'balance', direction: UITKSortDirection.DESC });
    this.Invoices.sort = this.uitkTableSort;
  }

  filter(): void {
    this.gotInquirySearchResultsSubscription=this._searchInvoiceByCCService.gotSearchResultsEmitter
    .subscribe((data:SearchInvoiceByCCSearchResultsDto[])=>{
      data.forEach(element => {
        element.accountId = element.accountId.replace(/^0+/, '');
      });
      this.Invoices.data=data;
  });
  }
  navigateToSFViewAccount(accountId:string): void {
    this._searchInvoiceByCCService.getSalesforceAccountId(accountId).subscribe(data => {
     const salesforceId= data;
      window.open(`${this._configService.salesforceUrl}/apex/ViewAccount?id=${salesforceId}`);
            });;
  }

  navigateToAccount(accountId: string): void {
    this._router.navigate([]).then(() => {
      window.open('/accountreceivable/arinquiry/' + accountId, '_blank');
    });
  }

  navigateToComet(invoiceNumber:string):void{
    window.open(`${this._configService.cometUrl}/ASP/InvoiceNav.aspx?ReportType=Invoice%20Details&InvoiceID=${invoiceNumber}&
    InvoiceSummary=false&InvoiceDetail=false&FromSalesForce=True&DisplayReportName=All&BreadCrumb=AR|Invoice%20Search|Invoice%20Search(${invoiceNumber})`);
  }

  navigateToARInquiry(accountId:string ,invoiceNumber:string): void {
    this._router.navigate([]).then(() => {
      window.open('/accountreceivable/arinquiry/' + accountId + '/' + invoiceNumber, '_blank');
    });
  }
  
  navigateToOrder(orderNumber:string): void{
    this._router.navigate([]).then(() =>{
      window.open('/order/' + orderNumber, '_blank');
    });
  }

  onSortChange(event: IUITKColumnState): void {
    this.Invoices.data = this.Invoices.sortData(this.Invoices.data, event.direction, event.column);
  }
 
  ngOnDestroy() {
    this.gotInquirySearchResultsSubscription?.unsubscribe();
  }
}
