<div [ngClass]="panelBG">
    <div class="uitk-l-grid__row">
        <div class="uitk-l-grid__col">
            <uitk-panel uitkId="orderSearchPanel" [collapsible]="true" [open]="true"
                (openChange)="setPanelBGHeight()">
                <uitk-panel-header-title>
                    <h2>Order Search</h2>
                </uitk-panel-header-title>
                <uitk-panel-content>
                  <div class="container-fluid" (keyup.enter)="search()">
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="row">
                          <label class="col-lg-4" uitkLabel>Order #</label>
                          <div class="col-lg-8">
                            <uitk-form-field>
                              <input uitkInput id="orderNum" class="textbox" type="text" value="" [(ngModel)]="orderSearchFilterDto.orderNumber" autofocus #orderNumberInput />
                            </uitk-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <label class="col-lg-4" uitkLabel>Order Type</label>
                          <div class="col-lg-8">
                            <uitk-form-field>
                              <uitk-dropdown
                                  uitkId="orderType"
                                  [isMultiSelect]="true"
                                  [items]="orderTypeList"
                                  [itemSortOn]="true"
                                  [fieldsetLabel] ="' '"
                                  name="orderMultiSelect"
                                  [(ngModel)]="orderSearchFilterDto.orderType">
                              </uitk-dropdown>
                            </uitk-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <label class="col-lg-4" uitkLabel>Order Status</label>
                          <div class="col-lg-8">
                            <uitk-form-field>
                              <uitk-dropdown
                                uitkId="orderStatus"
                                [isMultiSelect]="true"
                                [items]="orderStatusList"
                                [itemSortOn]="true"
                                [fieldsetLabel] ="' '"
                                name="orderStatusMultiSelect"
                                [ngModel]="orderSearchFilterDto.orderStatus" (ngModelChange)="onChangeOrderStatus($event)">
                              </uitk-dropdown>
                            </uitk-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="row">
                          <label class="col-lg-4" uitkLabel>Account Name </label>
                          <div class="col-lg-8">
                            <uitk-form-field>
                              <input uitkInput id="accName" class="textbox" type="text" value=""
                                  [(ngModel)]="orderSearchFilterDto.accountName" />
                            </uitk-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <label class="col-lg-4" uitkLabel>Account #</label>
                          <div class="col-lg-8">
                            <uitk-form-field>
                              <input uitkInput id="accNum" class="textbox" type="text" value=""
                                [(ngModel)]="orderSearchFilterDto.accountNumber" />
                            </uitk-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <label class="col-lg-4" uitkLabel>Web Ref #</label>
                          <div class="col-lg-8">
                            <uitk-form-field>
                              <input uitkInput id="webRefNum" class="textbox" type="text" value=""
                                [(ngModel)]="orderSearchFilterDto.webReferenceNumber" />
                            </uitk-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-sm-4">
                        <div class="row">
                          <label class="col-lg-4" uitkLabel>PO #</label>
                          <div class="col-lg-8">
                            <uitk-form-field>
                            <input uitkInput id="poNum" class="textbox" type="text" value=""
                              [(ngModel)]="orderSearchFilterDto.poNumber" />
                            </uitk-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <label class="col-lg-4" uitkLabel>CC Last 4</label>
                          <div class="col-lg-8">
                            <uitk-form-field>
                              <input uitkInput id="creditCardLast4" class="textbox" type="text" value=""
                                [(ngModel)]="orderSearchFilterDto.creditCardLastFour" />
                            </uitk-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <label class="col-lg-4" uitkLabel>Tracking #</label>
                          <div class="col-lg-8">
                            <uitk-form-field>
                              <input uitkInput id="trackingNumber" class="textbox" type="text" value=""
                                [(ngModel)]="orderSearchFilterDto.trackingNumber" />
                            </uitk-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-sm-4">
                          <div class="row">
                            <label class="col-lg-4" uitkLabel>Entered By</label>
                            <div class="col-lg-8">
                              <uitk-form-field>
                              <input uitkInput id="enteredBy" class="textbox" type="text" value=""
                                [(ngModel)]="orderSearchFilterDto.enteredBy" />
                              </uitk-form-field>
                            </div>
                          </div>
                        </div>
                    </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-sm-12">
                        <h4>Ship to Address</h4>
                      </div>
                    </div>
                    <div id="addrDetails" class="row" >
                      <div class="col-sm-4">
                        <div class="row">
                          <label class="col-lg-4" uitkLabel>Street Address </label>
                          <div class="col-lg-8">
                            <uitk-form-field>
                              <input uitkInput id="shipToAddr" class="textbox" type="text" value=""
                                [(ngModel)]="orderSearchFilterDto.streetAddress" />
                            </uitk-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <label class="col-lg-4" uitkLabel>City </label>
                          <div class="col-lg-8">
                            <uitk-form-field>
                              <input uitkInput id="city" class="textbox" type="text" value=""
                                [(ngModel)]="orderSearchFilterDto.city" />
                            </uitk-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <label class="col-lg-4" uitkLabel>State</label>
                          <div class="col-lg-8">
                            <div id="stateDropDown" *ngIf="orderSearchFilterDto.country?.value !== 'US';else stateDropDown">
                              <uitk-form-field>
                                <input uitkInput id="state" class="textbox" type="text" [(ngModel)]="orderSearchFilterDto.stateNonUS" />
                              </uitk-form-field>
                            </div>
                            <ng-template #stateDropDown>
                                <div id="stateDropDown">
                                  <uitk-form-field>
                                    <uitk-select uitkId="state" [itemList]="stateList" [(ngModel)]="orderSearchFilterDto.stateUS" placeholder="Select"></uitk-select>
                                  </uitk-form-field>
                                </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-sm-4">
                        <div class="row">
                          <label class="col-lg-4" uitkLabel>Zip Code</label>
                            <div class="col-lg-8">
                              <uitk-form-field>
                                <input uitkInput id="zipCode" class="textbox" type="text"
                                    [(ngModel)]="orderSearchFilterDto.zipCode" minlength="4" name="zipCodeInput" #zipCodeInput="ngModel"
                                    [pattern]="'[0-9]{4}?[0-9,-]{0,}'" />
                                <uitk-form-field-error
                                  *ngIf="zipCodeInput?.errors?.pattern && zipCodeInput.touched">Invalid Zip Code
                              </uitk-form-field-error>
                            </uitk-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="row">
                          <label class="col-lg-4" uitkLabel>Country</label>
                          <div class="col-lg-8">
                            <uitk-form-field>
                              <uitk-select uitkId="country" [itemList]="countries" [(ngModel)]="orderSearchFilterDto.country" (onDropdownChange)="clearState()">
                                
                              </uitk-select>
                            </uitk-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="row">
                          <div class="col-lg-12">
                            <button id="searchBtn" type="button"
                              class="uitk-c-button uitk-c-button--ghost uitk-u-margin--m" (click)="search()" (callSearchMethod)="search()"
                              hotkeyCommand="ctrl+f">Search</button>
                            <a href="#" (click)="clearSearch()">Clear</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                      </div>
                      <div class="col-sm-4"></div>
                    </div>
                  </div>
                </uitk-panel-content>
            </uitk-panel>
        </div>
    </div>
</div>
<div [hidden]="!modeService.isWaiting">
     <body>
        <app-loader></app-loader>
    </body>
</div>
<app-order-search-results [hidden]="!showResults">
</app-order-search-results>
