<div class="container-fluid px-5">
  <div class="px-3" style="border: 1px solid #797979;">
    <div class="row mt-3">
      <div class="col-12">
        <img class="headerLogo" src="assets/images/optum_logo_pdf.png" alt="optum logo" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 text-center">
        <span class="profromaheader">Pro-Forma-Invoice</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-1">
        <span class="spnHeader">Remit to:</span>
      </div>
      <div class="col-3" id="padding">
        <div>
          <span class="spnValue">Optum</span>
        </div>
      </div>
      <div class="col-6"></div>
      <div class="col-1">
        <span class="spnHeader" style="margin-left: -103%; padding-left: 3%;">Account #:</span>
      </div>
      <div class="col-1">
        <div>
          <span class="spnValue" [innerHTML]="orderService.orderDto.billToDto.id" style="margin-left: -99%"></span>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-1"></div>
      <div class="col-3" id="padding">
        <div>
          <span class="spnValue">PO Box 88050</span>
        </div>
      </div>
      <div class="col-6"></div>
      <div class="col-1">
        <span class="spnHeader" style="margin-left: -103%; padding-left: 3%;">Order #:</span>
      </div>
      <div class="col-1">
        <span class="spnValue" [innerHTML]="orderService.orderDto.id" style="margin-left: -99%"></span>
      </div>
    </div>
    <div class="row">
      <div class="col-1"></div>
      <div class="col-3" id="padding">
        <div>
          <span class="spnValue">Chicago, IL 60680-1050</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-1">
        <span class="spnHeader">Call:</span>
      </div>
      <div class="col-11" id="padding">
        <span class="spnValue"
          [innerHTML]="contentSolutionsClientManagerPhone">{{contentSolutionsClientManagerPhone}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-1">
        <span class="spnHeader">Fax:</span>
      </div>
      <div class="col-11" id="padding">
        <span class="spnValue" [innerHTML]="contentSolutionsClientManagerFax">{{getFax()}}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-1">
        <span class="spnHeader">Bill to:</span>
      </div>
      <div class="col-3" id="padding">
        <div>
          <span class="spnValue" [innerHtml]="orderService.orderDto?.paymentMethod === 'S' ?
            (orderService?.selectedShipToContact?.mailingStreet ? orderService.selectedShipToContact?.mailingCompany
            : orderService?.selectedShipToContact?.street ? orderService.selectedShipToContact?.company
            : orderService.orderDto?.shipToDto?.company)
          : orderService.orderDto?.billToDto?.company">
          </span>
        </div>
        <div>
          <span class="spnValue" [innerHtml]="
            orderService.orderDto?.paymentMethod === 'S' ?
              (orderService?.selectedShipToContact?.mailingStreet ? orderService.selectedShipToContact?.mailingDepartment
              : orderService?.selectedShipToContact?.street ? orderService.selectedShipToContact?.department
              : orderService.orderDto?.shipToDto?.department)
            : orderService.orderDto?.billToDto?.department">
          </span>
        </div>
        <div>
          <span class="spnValue" [innerHTML]="
            orderService.orderDto?.paymentMethod === 'S' ?
              (orderService?.selectedShipToContact?.mailingStreet ? orderService.selectedShipToContact?.mailingStreet
              : orderService?.selectedShipToContact?.street ? orderService.selectedShipToContact?.street
              : orderService.orderDto?.shipToDto?.street)
            : orderService.orderDto?.billToDto?.street">
          </span>
        </div>
        <div>
          <span class="spnValue" [innerHTML]="generateCityStateZip(orderService.orderDto.billToDto.city, orderService.orderDto.billToDto.state,
          orderService.orderDto.billToDto.zip)">
          </span>
        </div>
      </div>
      <div class="col-5"></div>
      <div class="col-1">
        <span class="spnHeader">Ship to:</span>
      </div>
      <div class="col-2">
        <div>
          <span class="spnValue" [innerHTML]="generateFullName(orderService.orderDto.shipToDto.firstName,
          orderService.orderDto.shipToDto.lastName)">
          </span>
        </div>
        <div>
          <span class="spnValue" [innerHTML]="orderService.orderDto.shipToDto.company">
          </span>
        </div>
        <div>
          <span class="spnValue" [innerHTML]="orderService.orderDto.shipToDto.department">
          </span>
        </div>
        <div>
          <span class="spnValue" [innerHTML]="orderService.orderDto.shipToDto.street">
          </span>
        </div>
        <div>
          <span class="spnValue" [innerHTML]="generateCityStateZip(orderService.orderDto.shipToDto.city, orderService.orderDto.shipToDto.state,
          orderService.orderDto.shipToDto.zip)"></span>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12">
          <table class="tableBorder">
            <thead>
              <tr>
                <th class="tableHeader" id="date" scope="col">
                  <span class="tableHeaderSpan">Date</span>
                </th>
                <th class="tableHeader" id="shipVia" scope="col">
                  <span class="tableHeaderSpan">Ship Via</span>
                </th>
                <th class="tableHeader" id="orderbycontact" scope="col">
                  <span class="tableHeaderSpan">Order By Contact</span>
                </th>
                <th class="tableHeader" scope="col">
                  <span class="tableHeaderSpan">Terms</span>
                </th>
              </tr>
            </thead>
            <tbody style="padding-left: 12%;">
              <tr>
                <td class="tableBody" style="text-align: center;">
                  <span class="tableBodySpan" [innerHTML]="today"></span>
                </td>
                <td class="tableBody" style="text-align: center;">
                  <span class="tableBodySpan" [innerHTML]="orderService.orderDto.shipmentMethod"></span>
                </td>
                <td class="tableBody" style="text-align: center;">
                  <span class="tableBodySpan" [innerHTML]="generateFullName(orderService.orderDto.orderByDto.firstName,
                      orderService.orderDto.orderByDto.lastName)"></span>
                </td>
                <td class="tableBody" style="text-align: center;">
                  <span class="tableBodySpan" [innerHTML]="orderService.orderDto.paymentTerms"></span>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <table style="width:100%;">
            <thead>
              <tr >               
                <th   class="tableHeader col-lg-3">
                  <span class="tableHeaderSpan" >Purchase Order Number</span>
                </th>
              
                <th class="tableHeader col-lg-3">
                  <span class="tableHeaderSpan" >Order Date</span>
                </th>
                <th  class="tableHeader col-lg-6">
                  <span class="tableHeaderSpan">Content Solution Client Manager</span>
                </th>
               
              </tr>
            </thead>
            <tbody >
               <tr>
                <td class="tableBody col-lg-3" style="text-align: center;">
                  <span class="tableBodySpan" [innerHTML]="orderService.orderDto.poNumber"></span>
                </td>
                <td class="tableBody col-lg-3" style="text-align: center;">
                  <span class="tableBodySpan"
                    [innerHTML]="orderService.orderDto.orderDate | date:'MM/dd/yyyy'"></span>
                </td>
                <td class="tableBody col-lg-6" style="text-align: center;">
                  <span class="tableBodySpan" [innerHTML]="getClientManagerName()"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <table style="width:100%;">
            <thead>
              <tr>

                <th scope="col" class="tableHeader col-lg-2" style="padding: 0px;" >
                  <span class="tableHeaderSpan">Quantity</span>
                  <tr>
                    <th scope="col" class="col-lg-1" style="border-top: 1px solid #797979; border-right: 1px solid #797979; text-align: left;">
                      <span class="tableHeaderSpan">Required</span>
                    </th>
                    <th scope="col" class="col-lg-1" style="border-top: 1px solid #797979; text-align: left;">
                      <span class="tableHeaderSpan">Shipped</span>
                    </th>
                  </tr>
                </th>

                <th scope="col" class="tableHeader col-lg-1" style=" text-align: left;">
                  <span class="tableHeaderSpan">Item:</span>
                </th>
                <th scope="col" class="tableHeader col-lg-3">
                  <span class="tableHeaderSpan">Description</span>
                </th>

                <th scope="col" class="tableHeader col-lg-2" >
                  <span class="tableHeaderSpan">Unit Price</span>
                </th>
                <th scope="col" class="tableHeader col-lg-2" >
                  <span class="tableHeaderSpan">Discount</span>
                </th>
                <th scope="col" class="tableHeader col-lg-2" >
                  <span class="tableHeaderSpan">Total Price</span>
                </th>
              </tr>
            </thead>
            <tbody >
               <tr *ngFor="let orderDetailDto of orderService.orderDto.orderDetailDtos">
              

                <td class="tableBody col-lg-2">             
                  <tr style="display:flex" >
                    <td class=" col-lg-6" style="text-align: right; border-right: 1px solid #797979;margin-left: 1%;">
                      <span class="tableBodySpan" [innerHTML]="orderDetailDto.openQuantity + orderDetailDto.shippedQuantity"></span>
                    </td>
                    <td class=" col-lg-6" style="text-align: right;">
                      <span class="tableBodySpan" [innerHTML]="orderDetailDto.shippedQuantity"></span>
                    </td>
                  </tr>
                </td>
 

                <td class="tableBody  col-lg-1" style="text-align: left;">
                  <span class="tableBodySpan" [innerHTML]="orderDetailDto.productCode"></span>
                </td>

                <td class="tableBody  col-lg-3" style="text-align: left;">
                  <span class="tableBodySpan"
                    [innerHTML]="orderDetailDto.productDescription"></span>
                </td>

                <td class="tableBody  col-lg-2" style="text-align: right;">
                  <span class="tableBodySpan" [innerHTML]="orderDetailDto.unitPrice | currency:'USD':'symbol':'1.2-2'"></span>
                </td>
                <td class="tableBody  col-lg-2" style="text-align: right;">
                  <span class="tableBodySpan" [innerHTML]="orderDetailDto.discount+ '%'"></span>
                </td>
                <td class="tableBody  col-lg-2" style="text-align: right;">
                  <span class="tableBodySpan" [innerHTML]="orderDetailDto.extendedPrice | currency:'USD':'symbol':'1.2-2'"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="justify-content-left">
        <div class="row mt-3 justify-content-end">
          <div class="col-2 tableBody clearBorderBottom clearBorderRight" style="width:560px; border-right: none;">
            <span class="tableBodySpan">Non-Taxable Subtotal</span>
          </div>
          <div class="col-1 tableBody clearBorderBottom second" id="tablebodyfeild" style="border-left: none;">
            <span class="tableBodySpan" [innerHTML]="getNonTaxableSubTotal() | currency:'USD':'symbol':'1.2-2'"></span>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-2 tableBody clearBorderBottom clearBorderRight" style="width:560px; border-right: none;">
            <span class="tableBodySpan">Taxable Subtotal</span>
          </div>
          <div class="col-1 tableBody clearBorderBottom" id="tablebodyfeild" style="border-left: none;">
            <span class="tableBodySpan" [innerHTML]="getTaxableSubtotal() | currency:'USD':'symbol':'1.2-2'"></span>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-2 tableBody clearBorderBottom clearBorderRight" style="width:560px; border-right: none;">
            <span class="tableBodySpan">Estimated Tax <sup class="superScript">*</sup></span>
          </div>
          <div class="col-1 tableBody clearBorderBottom" id="tablebodyfeild" style="border-left: none;">
            <span class="tableBodySpan" [innerHTML]="getEstimatedTax() | currency:'USD':'symbol':'1.2-2'"></span>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-3 borderBetweenEstimatedTaxAndEstimatedAmountDue" id="border3"></div>
          <div class="col-1 borderBetweenEstimatedTaxAndEstimatedAmountDue" id="border1"></div>
        </div>
        <div class="row mb-3 justify-content-end">
          <div class="col-2 tableBody" style="width:560px; border-right: none;">
            <span class="tableBodySpan">Estimated Amount Due</span>
          </div>
          <div class="col-1 tableBody" id="tablebodyfeild" style="border-left: none;">
            <span class="tableBodySpan" [innerHTML]="getEstimatedAmountDue() |
              currency:'USD':'symbol':'1.2-2'"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="px-3 mt-3 disclaimerSection">
    <div class="row mt-3">*Final tax calculated upon shipment of items.</div>
    <div *ngIf="orderService.orderDto.billToDto.isPORequired">
      <div class="row" style="margin-bottom: 0.6rem;">Your account requires a Purchase Order; to confirm your order
        please complete one of
        the following:</div>
      <div class="disclaimerSectionOLTag">
        <div class="disclaimerSectionLITag" value="1">
          <div class="row wordWrap">1. Email a copy of your PO and your Invoice to ordermanagement@optum.com or Fax to
            801-982-4033</div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">2. Call 1-800-464-3649 to provide a valid Credit Card</div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">3. Pay by EFT/ACH with the information listed below:
            <table class="footer-table">
              <tr>
                <td style="width: 18%;">Optum360LLC</td>
                <td style="width: 18%;">Bank Name:</td>
                <td>Fifth Third Bank</td>
              </tr>
              <tr>
                <td>PO Box 88050</td>
                <td>Account #:</td>
                <td>7025806535</td>
              </tr>
              <tr>
                <td>Chicago, IL 60680-1050</td>
                <td>ACH/Wire Routing Number:</td>
                <td>042000314</td>
              </tr>
              <tr>
                <td></td>
                <td>Swift Code:</td>
                <td>FTBCUS3C</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Deposit Only</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">4. Mail a check to the Remit Address above. Be sure to write the Account # and Quote
            # shown above on your check and attach a copy of the Invoice.</div>
        </div>
      </div>
    </div>
    <div
      *ngIf="!orderService.orderDto.billToDto.isPORequired && this.orderService.orderDto.billToDto.creditLimit !== 10000 && this.orderService.orderDto.billToDto.creditLimit !== 1000">
      <div class="row" style="margin-bottom: 0.6rem;">To confirm your order please complete one of the following:</div>
      <div class="disclaimerSectionOLTag">
        <div class="disclaimerSectionLITag" value="1">
          <div class="row wordWrap">1. Call 1-800-464-3649 to provide a valid Credit Card.</div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">2. Pay by EFT/ACH with the information listed below:
            <table class="footer-table">
              <tr>
                <td style="width: 18%;">Optum360LLC</td>
                <td style="width: 18%;">Bank Name:</td>
                <td>Fifth Third Bank</td>
              </tr>
              <tr>
                <td>PO Box 88050</td>
                <td>Account #:</td>
                <td>7025806535</td>
              </tr>
              <tr>
                <td>Chicago, IL 60680-1050</td>
                <td>ACH/Wire Routing Number:</td>
                <td>042000314</td>
              </tr>
              <tr>
                <td></td>
                <td>Swift Code:</td>
                <td>FTBCUS3C</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Deposit Only</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">3. Mail a check to the Remit Address above. Be sure to write the Account # and Order
            # shown above on your check and attach a copy of the Invoice.</div>
        </div>
      </div>
    </div>
    <div
      *ngIf="!orderService.orderDto.billToDto.isPORequired && !(this.orderService.orderDto.billToDto.creditLimit !== 10000 && this.orderService.orderDto.billToDto.creditLimit !== 1000)">
      <div class="row" style="margin-bottom: 0.6rem;">To confirm your order complete one of the following:</div>
      <div class="disclaimerSectionOLTag">
        <div class="disclaimerSectionLITag" value="1">
          <div class="row wordWrap">1. Call 1-800-464-3649 to provide a valid Credit Card.</div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">2. Pay by EFT/ACH with the information listed below:
            <table class="footer-table">
              <tr>
                <td style="width: 18%;">Optum360LLC</td>
                <td style="width: 18%;">Bank Name:</td>
                <td>Fifth Third Bank</td>
              </tr>
              <tr>
                <td>PO Box 88050</td>
                <td>Account #:</td>
                <td>7025806535</td>
              </tr>
              <tr>
                <td>Chicago, IL 60680-1050</td>
                <td>ACH/Wire Routing Number:</td>
                <td>042000314</td>
              </tr>
              <tr>
                <td></td>
                <td>Swift Code:</td>
                <td>FTBCUS3C</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Deposit Only</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">3. Email a copy of your PO and your Quote to ordermanagement@optum.com or Fax to
            801-982-4033.</div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">4. Mail a check to the Remit Address above. Be sure to write the Account # and Order
            # shown above on your check and attach a copy of the Invoice.</div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">5. Sign Below (in lieu of purchase order, I authorize that this order is correct,
            and will be paid in full upon receipt of goods) and email your Invoice to ordermanagement@optum.com or Fax
            to 801-982-4033
            <br />X_______________________________(Authorized Signature)
          </div>
        </div>
      </div>
    </div>
  </div>

</div>


































