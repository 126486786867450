import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {
  transform(value: string): string {
    let formattedDate = "";
      if ((value !== '' && value !== null) && !value.includes('/')) {
      formattedDate = value.substring(0, 3) + '/' + value.substring(3, 6) + '-'
        + value.substring(6, 10) + ' ' + value.substring(10);
      formattedDate = formattedDate.trim();
    }

    return formattedDate;
  }
}
