import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { InvoiceDetailsDto } from '../../../../dtos/invoice-details-dto.model';
import { InvoicePdfEmailInputDto } from '../../../../dtos/invoice-pdf-email-input-dto.model';
import { AccountingService } from '../../../../services/accounting.service';
import { AccountsReceivableService } from '../../../../services/accounts-receivable.service';
import { ConfigService } from "../../../../services/config.service";
import { ModeService } from '../../../../services/mode.service';
import { OrderService } from '../../../../services/order.service';
import { InvoiceEditComponent } from '../../invoice-edit/invoice-edit.component';

@Component({
  selector: 'app-send-invoice-pdf',
  templateUrl: './send-invoice-pdf.component.html',
  styleUrls: ['./send-invoice-pdf.component.css'],
  providers: [InvoiceEditComponent]
})
export class SendInvoicePdfComponent implements OnInit {
  private readonly _orderService: OrderService;
  private readonly _configService: ConfigService;
  @Input() dialog = { show: false }
  getSelectedOrderSubscription: Subscription | undefined;
  configIsReadySubscription: Subscription | undefined;
  orderIsReadySubscription: Subscription | undefined;
  getSelectedInvoicesSubscription: Subscription | undefined;
  selectedInvoicesList: InvoiceDetailsDto[] = [];
  selectedOrders: number[] = [];
  disable = true;
  disableBillTo = true;
  disableOrderBy = true;
  disableShipTo = true;
  disableloogedInUser=false;
  email = '';
  subject = '';
  body = '';
  emailPattern = /^^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](\s?)(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
  billToContactcheckbox: boolean = false;
  orderByContactcheckbox: boolean = false;
  shipToContactcheckbox: boolean = false;
  LoggedInUsercheckbox:boolean=false;
  loading = false;
  visibleTextValue = '';
  isNotificationVisible = false;
  notificationVariant: any = '';
  emailNotificationMessage = '';

  constructor(
    public accountsReceivableService: AccountsReceivableService,
    orderService: OrderService,
    configService: ConfigService,
    public invoiceEditComponent: InvoiceEditComponent,
    public accountingService: AccountingService,
    public modeService: ModeService) {
    this._orderService = orderService;
    this._configService = configService;
  }

  ngOnInit(): void {
    this.configIsReadySubscription = this._configService.configIsReady.subscribe(() => {
      this.getSelectedInvoicesSubscription = this.accountsReceivableService.getSelectedInvoicesEmitter.
        subscribe((invoices: InvoiceDetailsDto[]) => {
          this.selectedInvoicesList = invoices;
          if (this.selectedInvoicesList[0]) {
            this.subject = this.selectedInvoicesList.length > 1 ? 'Invoices.zip' : `Invoice-${this.selectedInvoicesList[0].invoiceNumber}`
          }
        });
    });

    this._configService.loadConfigurations();

    this.clickSendSelectedToPDF();
  }

  closeSendInvoicePdfDialog() {
    this.dialog.show = false;
  }

  clickSendSelectedToPDF() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
    //currentUser.profile.email
    this._orderService.getOrderInformation(this.accountsReceivableService.selectedOrder);
    this.orderIsReadySubscription = this._orderService.orderIsReady.subscribe(() => {
      this.email = '';
      let loggedInUserToIds=currentUser.profile.email;

      this.accountsReceivableService.getSelectedInvoicesEmitter.
        subscribe((invoices: InvoiceDetailsDto[]) => {
          const distinctOrderByIds = [...new Set(invoices.map(item => item.orderByContactId))];
          const distinctShipToIds = [...new Set(invoices.map(item => item.shipToContactId))];
       

          if (this._orderService.orderDto.billToDto.email !== null && this._orderService.orderDto.billToDto.email !== '') {
            this.billToContactcheckbox = true;
            this.email = this._orderService.orderDto.billToDto.email;
            this.disableBillTo = false;
          }
          else {
            this.disableBillTo = true;
          }
          this.disableloogedInUser=loggedInUserToIds.length > 1|| currentUser.profile.email;
          this.disableOrderBy = distinctOrderByIds.length > 1 || !this._orderService.orderDto.orderByDto.email;

          this.disableShipTo = distinctShipToIds.length > 1 || !this._orderService.orderDto.shipToDto.email;
        });
    });
  }

  changeEmail() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
    let emailIds: string[] = [];
    this.email = '';
    emailIds.push(this.billToContactcheckbox === true ? this._orderService.orderDto.billToDto.email || '' : '');
    emailIds.push(this.orderByContactcheckbox === true ? this._orderService.orderDto.orderByDto.email || '' : '');
    emailIds.push(this.shipToContactcheckbox === true ? this._orderService.orderDto.shipToDto.email || '' : '');
    emailIds.push(this.LoggedInUsercheckbox==true?currentUser.profile.email||'':'');
    emailIds = [...new Set(emailIds)];
    this.email = emailIds.filter(e => e).join(';');
  }

  sendPdf() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
    this.loading = true;
    this.visibleTextValue = 'Sending Email';
    const invoicePdfEmailInputDto = new InvoicePdfEmailInputDto();
    invoicePdfEmailInputDto.emailAddress = this.email;
    invoicePdfEmailInputDto.subject = this.subject;
    invoicePdfEmailInputDto.body = this.body;
    invoicePdfEmailInputDto.LoggedInUserEmail=currentUser.profile.email;

    this.selectedInvoicesList.forEach(item => {
      invoicePdfEmailInputDto.invoiceIds.push(Number(item.invoiceNumber));
    });
    const waitListMessage = "Sending PDF";
    this.modeService.addToWaitList(waitListMessage);
    this.accountingService.emailInvoicePdfs(invoicePdfEmailInputDto)
      .subscribe(() => {
        this.modeService.removeFromWaitList(waitListMessage);
        this.displayNotification(true, 'Mail sent successfully', true);
        this.selectedInvoicesList = [];
        setTimeout(() => {
          this.displayNotification(false, '');
          this.closeSendInvoicePdfDialog();
        }, 2000);
      }, error => {
        this.modeService.removeFromWaitList(waitListMessage);
        console.log(error);

        setTimeout(() => {
          this.displayNotification(true, 'An error occurred while sending an email', false);
        }, 2000);
      });
  }

  displayNotification(isNotificationVisible: boolean, emailNotificationMessage: string, isSuccess: boolean = false) {
    this.isNotificationVisible = isNotificationVisible;
    this.emailNotificationMessage = emailNotificationMessage;

    if (typeof isSuccess !== 'undefined') {
      this.notificationVariant = isSuccess ? 'success' : 'error';
    }
  }
}
