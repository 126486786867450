import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignDto } from 'src/app/dtos/campaign-dto.model';

import { ItemOfferDto } from 'src/app/dtos/item-offer-dto.model';
import { OrderOfferDto } from 'src/app/dtos/order-offer-dto.model';
import { ConfigService } from 'src/app/services/config.service';
import { ModeService } from 'src/app/services/mode.service';
import { PromotionService } from 'src/app/services/promotion.service';

@Component({
  selector: 'app-view-promotions',
  templateUrl: './view-promotions.component.html',
  styleUrls: ['./view-promotions.component.css']
})
export class ViewPromotionsComponent implements OnInit {
  promotionCode = '';
  campaignDto: CampaignDto = new CampaignDto();
  selectedItemOffer: ItemOfferDto | null = null;
  selectedOrderOffer: OrderOfferDto | null = null;

  constructor(
    private readonly promotionService: PromotionService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly modeService: ModeService,
    private readonly configService: ConfigService
  ) { }

  ngOnInit(): void {
    const promotionCode = this.route.snapshot.params.promotionCode;
    if (promotionCode !== 'search') {
      this.promotionCode = promotionCode;
      this.configService.configIsReady.subscribe(() => {
        this.searchPromotion();
      });
    } else {
      this.promotionCode = '';
    }
  }

  clickSearchPromotion() {
    this.router.navigate(['/promotions', this.promotionCode]);
    this.searchPromotion();
  }

  async searchPromotion(): Promise<void> {
    this.campaignDto = new CampaignDto();
    this.selectedItemOffer = null;
    this.selectedOrderOffer = null;

    const result = await this.promotionService.getCampaign(this.promotionCode);
    if (result) {
      this.campaignDto = result;
    }
  }
}
