<div class="uitk-l-grid">
    <div class="uitk-l-grid__row">
        <div class="uitk-l-grid__col--12">
            <div uitkTableCaption>
                <h3 id="table-caption" class="headerTextClass">Source Code</h3>
            </div>
            <div class="row mt-3">
                <div class="col d-flex">
                    <label class="col-lg-1 display_center" uitkLabel>Source Code</label>
                    <uitk-form-field>
                        <input style="width: 200px" [(ngModel)] = "filterValue" uitkInput id="promotion-code-textbox" class="textbox mb-1"
                            type="text" appTextFormat />
                    </uitk-form-field>
                    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs mx-3" 
                    (click)="searchSourceCode(filterValue)">
                        Search
                    </button>
                    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
                        (click)="openEditSourceCodePopup(sourceCode)" *appShowResource="'AddEditSourceCode'">
                        Add New
                    </button>
                </div>
            </div>
            <div uitkTableContainer class="row mt-3" *ngIf = "this.displayList">
                <table uitkTable [dataSource]="sourceCodeList" id="basic-table">
                    <thead>
                        <tr uitkTableSort #sortTable="uitkTableSort">
                            <th scope="col" style="width: 40px;" *appShowResource="'AddEditSourceCode'"></th>
                            <th scope="col" style="width: 40px;" *appShowResource="'AddEditSourceCode'"></th>
                            <th uitkTableSortColumn [column]="headerName.id" (sortChange)="onSortChange($event)"
                                *ngFor="let headerName of tableHeader" scope="col">
                                {{ headerName.name }}
                            </th>
                        </tr>
                    </thead>
                    <tbody id="row-data">
                        <ng-container *uitkTableRowRenderer="let sourceCode">
                            <tr>
                                <td class="align-middle" *appShowResource="'AddEditSourceCode'">
                                    <span class="uitk-icon uitk-icon-edit md-icon" style="cursor: pointer;"
                                        (click)="openEditSourceCodePopup(sourceCode)"></span>
                                </td>
                                <td class="align-middle" *appShowResource="'AddEditSourceCode'">
                                    <span class="uitk-icon uitk-icon-trash_delete md-icon" style="cursor: pointer;"
                                        (click)="openDeleteSourceCodePopup(sourceCode)"></span>
                                </td>
                                <td>{{sourceCode.sourceCode}}</td>
                                <td style = "width: 35%;">{{sourceCode.description}}</td>
                                <td>{{sourceCode.glCode}}</td>
                                <td>{{sourceCode.statusCode}}</td>
                            </tr>
                        </ng-container>
                        <div *ngIf="sourceCodeList.data.length === 0">No records found</div>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="divPopUpContent" *ngIf="deleteSourceCodePopup.show || editSourceCodePopup.show">
    <app-source-code-action-popup [deleteSourceCodePopup]="deleteSourceCodePopup"
        [editSourceCodePopup]="editSourceCodePopup" [sourceCodeDetails]="sourceCode">
    </app-source-code-action-popup>
</div>