import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, share, tap } from 'rxjs/operators';
import { BillToDto } from '../dtos/bill-to-dto.model';
import { Config } from '../dtos/config.model';
import { ContactDto } from '../dtos/contact-dto.model';
import { GeozipDto } from '../dtos/geozip-dto.model';
import { KeyCodeDto } from '../dtos/key-code-dto.model';
import { ProductDto } from '../dtos/product-dto.model';
import { ReferenceDto } from '../dtos/reference-dto.model';
import { SpecialitiesDto } from '../dtos/specialities-dto.model';
import { ZipCodeLookupDto } from '../dtos/zip-code-lookup-dto';
import { AuthenticationService } from './authentication.service';
import { Idle } from '@ng-idle/core';
import { ItemSearchCatalogDto } from '../dtos/item-search-catalog-dto-model';
import { ModeService } from './mode.service';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  configIsReady = new Subject();
  environment = '';
  apiUrl = '';
  taxApiUrl = '';
  accountingApiUrl = '';
  addressStandardizationUrl = '';
  shippingHandlingApiUrl = '';
  salesforceUrl = '';
  cometUrl='';
  discountApiUrl = '';
  idleInterval = 0;
  timeoutInterval = 0;
  ccTokenUrl = '';
  linkidx_url = '';
  spaUrl = '';
  logger_url = '';
  subscriptionkey_header_value = '';
  subscriptionkey_header_logging_value = '';
  orderEntryFeature = true;
  defaultAccountOwnerCode = '';
  timeoutInMinutes = 0;
  orderAttachmentSizeLimit = 0;
  emailFrom = '';
  priceMasterSPAUrl = '';
  quoteCFDPassword = '';
  priceMasterAPIUrl = ' ';
  productMasterApiUrl = '';
  productMasterSPAUrl = '';
  enablePeoplesoftApi = false;
  peoplesoftApiUrl = '';
  reportsFlag = 1;
  reference!: ReferenceDto | null;
  geoZipDto: GeozipDto = new GeozipDto();
  geoZipDtoIsReady = new Subject<GeozipDto>();
  specialtiesDto: SpecialitiesDto[] = [];
  getSpecialitiesDtoIsReady = new Subject<SpecialitiesDto>();
  private readonly _httpClient: HttpClient;
  private readonly _authenticationService: AuthenticationService;
  referenceCache!: ReferenceDto | null;
  referenceCachedObservable!: Observable<ReferenceDto> | null;
  environmentCache!: any | null;
  environmentCachedObservable!: Observable<any> | null;
  configCache!: Config | null;
  configCachedObservable!: Observable<Config> | null;
  geoZipCache!: GeozipDto | null;
  geoZipCachedObservable!: Observable<GeozipDto> | null;
  specialitiesCache!: SpecialitiesDto[] | null;
  specialitiesCachedObservable!: Observable<SpecialitiesDto[]> | null;
  zipCodeLookUpCache!: ZipCodeLookupDto[] | null;
  zipCodeLookUpCachedObservable!: Observable<ZipCodeLookupDto[]> | null;
  keyCodeDto: KeyCodeDto = new KeyCodeDto();
  keyCodeCache!: KeyCodeDto | null;
  keyCodeCachedObservable!: Observable<KeyCodeDto> | null;
  keyCodeUpdateCache!: string | null;
  keyCodeUpdateCachedObservable!: Observable<string> | null;
  profromaInvoicePdfPassword = '';
  private readonly _modeService: ModeService;
  swalAlert = new SwalAlert();

  constructor(
    httpClient: HttpClient,
    authenticationService: AuthenticationService,
    private readonly idle: Idle,
    modeService: ModeService) {
    this._httpClient = httpClient;
    this._modeService = modeService;
    this._authenticationService = authenticationService;
  }

  public getPickList(pickListCode: string): IUITKSelectItemProps[] {
    if (!this.reference) {
      throw new Error("Attempting to get pick list values before refernces are loaded.");
    }

    return this.reference.pickListDtos
      .filter(p => p.pickListCode === pickListCode)
      .map(p => ({ id: p.pickListValue, label: p.description, value: p.pickListValue }));
  }

  resetIdleTimeout(): void {
    this.idle.watch();
  }

  loadConfigurations(): void {
    this.getEnvironmentType().subscribe((envData: any) => {
      this.environment = envData.environment;
      const environments = ['Local', 'Development', 'Test', 'Staging', 'Production'];

      if (!environments.some(e => e === this.environment)) {
        this.swalAlert.alert('Invalid environment file is setup');
        return;
      }

      let observable: Observable<Config>;

      if (this.configCache) {
        observable = of(this.configCache);
      }
      else if (this.configCachedObservable) {
        observable = this.configCachedObservable;
      }
      else {
        this.configCachedObservable = this._httpClient.get<Config>(`assets/config/appsettings.${this.environment}.json`)
          .pipe(
            tap((response: Config) => {
              this.configCache = response;
            }),
            share(),
            finalize(() => {
              this.configCache = null;
              this.configCachedObservable = null;
            })
          );

        observable = this.configCachedObservable;
      }

      observable.subscribe((data: Config) => {
        this.apiUrl = data.apiUrl;
        this.taxApiUrl = data.taxApiUrl;
        this.salesforceUrl = data.salesforceUrl;
        this.cometUrl=data.cometUrl;
        this.accountingApiUrl = data.accountingApiUrl;
        this.shippingHandlingApiUrl = data.shippingHandlingApiUrl;
        this.addressStandardizationUrl = data.addressStandardizationUrl;
        this.discountApiUrl = data.discountApiUrl;
        this.idleInterval = data.idleInterval;
        this.timeoutInterval = data.timeoutInterval;
        this.linkidx_url = data.linkidx_url;
        this.spaUrl = data.spaUrl;
        this.logger_url = data.logger_url;
        this.discountApiUrl = data.discountApiUrl;
        this.subscriptionkey_header_value = data.subscriptionkey_header_value;
        this.subscriptionkey_header_logging_value = data.subscriptionkey_header_logging_value;
        this.orderEntryFeature = data.orderEntryFeature;
        this.defaultAccountOwnerCode = data.defaultAccountOwnerCode;
        this.orderAttachmentSizeLimit = data.orderAttachmentSizeLimit;
        this.timeoutInMinutes = data.timeoutInMinutes;
        this.ccTokenUrl = data.ccTokenUrl;
        this.emailFrom = data.emailFrom;
        this.priceMasterSPAUrl = data.priceMasterSPAUrl;
        this.quoteCFDPassword = data.quoteCFDPassword;
        this.priceMasterAPIUrl = data.priceMasterAPIUrl;
        const currentUser = this._authenticationService.currentUserValue;
        this.productMasterApiUrl = data.productMasterApiUrl;
        this.productMasterSPAUrl = data.productMasterSPAUrl;
        this.profromaInvoicePdfPassword = data.profromaInvoicePdfPassword;
        this.peoplesoftApiUrl = data.peoplesoftApiUrl;
        this.enablePeoplesoftApi = data.enablePeoplesoftApi;
        this.reportsFlag = data.reportsFlag
        
        if (currentUser || localStorage.getItem('isByPass') === 'true' || localStorage.getItem('approvalFromEmail') === 'true' ) {
          this.getReferences().subscribe((referenceData: ReferenceDto) => {
            this.reference = referenceData;
            this.configIsReady.next();
          }, (error: Error) => {
            console.log(error);
            if (error !== null && error.message !== null && error.message.indexOf("Cannot open database") > -1) {
              this.swalAlert.alert('Order Management is unavailable. Please try again in a few minutes. If the issue continues, please alert System Administrators. Thanks.');
            }
            else
            {
              this.swalAlert.alert('An error occured while fetching reference details');
            }
          });
        }
      },
        (error: any) => {
          console.log(error);
          this.swalAlert.alert('Unable to get config data');
        });
    },
      (error: any) => {
        console.log(error);
        this.swalAlert.alert('Unable to get environment details.');
      });
  }

  async load() {
    try {
      return await new Promise((resolve) => {
        this.loadConfigurations();
        resolve(true);
      });
    }
    catch (error) {
      console.log("error in loading environment file.");
    }
  }

  getMyFullName(): string {
    if (localStorage.getItem('isByPass') === 'true') {
      return 'Automated User';
    }
    else {
      const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
      
      if (currentUser != null) {
        return currentUser.profile.name;
      }

      return 'Unknown';
    }
  }

  getFullNameFromUserCode(userCode: string): string {
    return this.getPickList('USERNAME').filter(x => x.id === userCode)[0]?.label;
  }

  getUserCodeFromUserLogin(userLogin: string): string {
    userLogin = userLogin.toLowerCase();
    if (!userLogin.startsWith('ms\\')) {
      userLogin = 'ms\\' + userLogin;
    }
    return this.getPickList('USERCODE').filter(x => x.id.toLowerCase() === userLogin)[0]?.label;
  }

  getUserLoginFromUserCode(userCode: string): string {
    return this.getPickList('USERCODE').filter(x => x.label.toLowerCase() === userCode.toLowerCase())[0]?.id;
  }
  getUserEmailFromUserCode(userCode: string): string {
    return this.getPickList('USEREMAILUSERCODE').filter(x => x.id.toLowerCase() === userCode.toLowerCase())[0]?.label;
  }

  getuserNameUserLogin(userLogin: string | null): string {
    if (userLogin) {
      userLogin = userLogin.toLowerCase();
      if (!userLogin.startsWith('ms\\')) {
        userLogin = 'ms\\' + userLogin;
      }
    return this.getPickList('USERNAMEUSERLOGIN').filter(x => x.id?.toLowerCase() === userLogin?.toLowerCase())[0]?.label;
    }
    else {
      return '';
    }
  }

  getUserEmailFromUserLogin(userLogin: string|null): string {
    if (userLogin) {
      userLogin = userLogin.toLowerCase();

      if (!userLogin.startsWith('ms\\')) {
        userLogin = 'ms\\' + userLogin;
      }

      return this.getPickList('USEREMAIL').filter(x => x.id.toLowerCase() === userLogin)[0]?.label;  
    }
    else {
      return '';
    }
  }

  getUserPhoneFromUserCode(userCode: string): string {
    return this.getPickList('USERPHONE').filter(x => x.id.toLowerCase() === userCode.toLowerCase())[0]?.label;
  }

  getUserSupervisorFromUserCode(userCode: string): string {
    return this.getPickList('USERSUPERVISOR').filter(x => x.id.toLowerCase() === userCode.toLowerCase())[0]?.label;
  }
  
  getDefaultNoteTypeFromUserCode(msId: string): IUITKSelectItemProps {
    const defaultNoteTypeCode = this.getPickList('DNOTETYPE').filter(x => x.id.includes(msId))[0]?.label;
    return this.getPickList("NTYPE").find(item => item.id === defaultNoteTypeCode)!;
  }

  getMyMSId(): string {
    if (localStorage.getItem('isByPass') === 'true') {
      return 'bypass';
    }
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);

    if (currentUser?.profile?.identity_provider === 'uhg-ms-id') {
      return currentUser?.profile?.preferred_username?.replace('oma-', '') ?? 'Unknown';
    } else {
      return currentUser?.profile?.identity_provider_identity?.replace('oma-', '') ?? 'Unknown';
    }
  }

  disablePickListOption(pickList: IUITKSelectItemProps[], value: string): void {
    const optionToDisable = pickList.filter(item => item.value === value)[0];

    if (optionToDisable) {
      optionToDisable.disabled = true;
    }
  }

  getMyUserCode(): string {
    const myMSId = this.getMyMSId();
    return this.getPickList('USERCODE').filter(x => x.id.toUpperCase().trim() === 'MS\\' + myMSId.toUpperCase())[0]?.label ?? "000";
  }

  getMyEmail(): string {
    const myMSId = this.getMyMSId();
    return this.getPickList('USEREMAIL').filter(x => x.id === 'MS\\' + myMSId)[0]?.label ?? "";
  }  

  getGeoZipDetails(codeType: string, zipcode: string): void {
    let observable: Observable<GeozipDto>;

    if (this.geoZipCache) {
      observable = of(this.geoZipCache);
    }
    else if (this.geoZipCachedObservable) {
      observable = this.geoZipCachedObservable;
    }
    else {
      this.geoZipCachedObservable = this._httpClient.get<GeozipDto>(`${this.apiUrl}/Reference/geozips/${codeType}/${zipcode}`)
        .pipe(
          tap((response: GeozipDto) => {
            this.geoZipCache = response;
          }),
          share(),
          finalize(() => {
            this.geoZipCache = null;
            this.geoZipCachedObservable = null;
          })
        );

      observable = this.geoZipCachedObservable;
    }

    observable.subscribe((data: GeozipDto) => {
      this.geoZipDto = data;
      this.geoZipDtoIsReady.next();
    }, (error: any) => {
      console.log(error);
      this.swalAlert.alert('An error occurred while getting geozip details. Please try again.');
    });
  }

  getSpecialitiesDetails(productId: number): void {
    let observable: Observable<SpecialitiesDto[]>;

    if (this.specialitiesCache) {
      observable = of(this.specialitiesCache);
    }
    else if (this.specialitiesCachedObservable) {
      observable = this.specialitiesCachedObservable;
    }
    else {
      this.specialitiesCachedObservable = this._httpClient.get<SpecialitiesDto[]>(`${this.apiUrl}/Reference/specialties/${productId}`)
        .pipe(
          tap((response: SpecialitiesDto[]) => {
            this.specialitiesCache = response;
          }),
          share(),
          finalize(() => {
            this.specialitiesCache = null;
            this.specialitiesCachedObservable = null;
          })
        );

      observable = this.specialitiesCachedObservable;
    }

    observable.subscribe((data: SpecialitiesDto[]) => {
      this.specialtiesDto = data;
      this.getSpecialitiesDtoIsReady.next();
    }, (error: any) => {
      console.log(error);
      this.swalAlert.alert('An error occurred while getting specialities details. Please try again.');
    });
  }

  getLookupZipCode(zipcode: string): Observable<ZipCodeLookupDto[]> {
    let observable: Observable<ZipCodeLookupDto[]>;

    if (this.zipCodeLookUpCache) {
      observable = of(this.zipCodeLookUpCache);
    }
    else if (this.zipCodeLookUpCachedObservable) {
      observable = this.zipCodeLookUpCachedObservable;
    }
    else {
      this.zipCodeLookUpCachedObservable = this._httpClient.get<ZipCodeLookupDto[]>(`${this.apiUrl}/Reference/LookupZipCode?zip=${zipcode}`)
        .pipe(
          tap((response: ZipCodeLookupDto[]) => {
            this.zipCodeLookUpCache = response;
          }),
          share(),
          finalize(() => {
            this.zipCodeLookUpCache = null;
            this.zipCodeLookUpCachedObservable = null;
          })
        );

      observable = this.zipCodeLookUpCachedObservable;
    }

    return observable;
  }

  convertSpecialitiesToUitkProps(specialtiesDto: SpecialitiesDto[]): IUITKSelectItemProps[] {
    const uitkProps: IUITKSelectItemProps[] = [];

    for (const speciality of specialtiesDto) {
      const specialityItem = { id: speciality.pickListValue, label: speciality.description, value: speciality.pickListValue };
      uitkProps.push(specialityItem);
    }

    return uitkProps;
  }

  getProductDetails(productId: number) {
    if (!this.reference) {
      throw new Error("Attempting to get product details before refernces are loaded.");
    }

    return this.reference.productDtos.filter(p => p.id === productId)[0];
  }

  private getEnvironmentType(): Observable<any> {
    let observable: Observable<any>;

    if (this.environmentCache) {
      observable = of(this.environmentCache);
    }
    else if (this.environmentCachedObservable) {
      observable = this.environmentCachedObservable;
    }
    else {
      this.environmentCachedObservable = this._httpClient.get<any>('assets/config/environment.json')
        .pipe(
          tap((response: any) => {
            this.environmentCache = response;
          }),
          share(),
          finalize(() => {
            this.environmentCache = null;
            this.environmentCachedObservable = null;
          })
        );

      observable = this.environmentCachedObservable;
    }

    return observable;
  }

  private getReferences(): Observable<ReferenceDto> {
    let observable: Observable<ReferenceDto>;

    if (this.referenceCache) {
      observable = of(this.referenceCache);
    }
    else if (this.referenceCachedObservable) {
      observable = this.referenceCachedObservable;
    }
    else {
      this.referenceCachedObservable = this._httpClient.get<ReferenceDto>(`${this.apiUrl}/Reference/GetReference`)
        .pipe(
          tap((response: ReferenceDto) => {
            this.referenceCache = response;
          }),
          share(),
          finalize(() => {
            this.referenceCachedObservable = null;
          })
        );

      observable = this.referenceCachedObservable;
    }

    return observable;
  }


  async getIemSearchCatalogProductsAsync(itemSearchCatalogDto: ItemSearchCatalogDto): Promise<ProductDto[] | null> {
    return new Promise(resolve => {
      const message = 'Getting products';
      this._modeService.addToWaitList(message);
      this._httpClient.post<ProductDto[]>(`${this.apiUrl}/reference/GetItemSearchCatalogProducts`, itemSearchCatalogDto).subscribe(data => {
        this._modeService.removeFromWaitList(message);
        resolve(data);
      }, async error => {
        this._modeService.removeFromWaitList(message);
        await this.swalAlert.alert('Error getting Products from Database');
        resolve(null);
      });
    });
  }

  async getProduct(productCode: string): Promise<ProductDto | null> {
    return this._httpClient.get<ProductDto>(`${this.apiUrl}/reference/product/${productCode}`)
    .pipe<ProductDto | null>(
      catchError(err => {
        this.swalAlert.alert('Error getting product from database.');
        return of(null);
      })
    )
    .toPromise();
  }

  async getAccountFilteredContacts(accountId: number, keyWord: string): Promise<ContactDto[]> {
    return this._httpClient.get<ContactDto[]>(`${this.apiUrl}/reference/getaccountfilteredcontacts?accountId=${accountId}&filter=${encodeURIComponent(keyWord)}`).toPromise();
  }

  async getAccountSearchDetails(keyWord: string) {
    return this._httpClient.get<BillToDto[]>(`${this.apiUrl}/reference/getaccountbilltodetails?filter=${encodeURIComponent(keyWord)}`).toPromise();
  }

  getPhoneFromCustomerService(accountTypeCode: string): string {
    return this.reference?.customerServiceDtos.filter(cs => cs.accountTypeCode === accountTypeCode)[0].phone!; 
  }

  getFaxFromCustomerService(accountTypeCode: string): string {
    return this.reference?.customerServiceDtos.filter(cs => cs.accountTypeCode === accountTypeCode)[0].fax!;
  }

  getEmailFromCustomerService(accountTypeCode: string): string {
    return this.reference?.customerServiceDtos.filter(cs => cs.accountTypeCode === accountTypeCode)[0].email!;
  }

  getFaxFromSalesforce(userCode: string): string {
    return this.reference ? userCode !== '' ? this.reference.userFaxDtos.filter(uf => uf.alias === userCode)[0]?.fax : '' : '';
  }

  getNextKeyCode(productType: string, year:string|null): Observable<KeyCodeDto> {
   return this._httpClient.get<KeyCodeDto>(`${this.apiUrl}/Reference/GetNextKeyCode?productType=${productType}&year=${year}`);
  }

  updateKeyCodeTakenDate(Id: number,productType:string): Observable<void> {
    const body = 'Id='+Id+'&productType='+productType+'';
    return this._httpClient.post<void>(this.apiUrl + '/Reference/UpdateKeyCodeTakenDate?Id='+Id+'&productType='+productType+'',body);
  }
}
