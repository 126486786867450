import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Mode } from '../../enums/mode.enum';
import { ContactDto } from '../../dtos/contact-dto.model';
import { ShipToDto } from '../../dtos/ship-to-dto.model';
import { AuthorizationService } from '../../services/authorization.service';
import { ConfigService } from '../../services/config.service';
import { ContactService } from '../../services/contact.service';
import { ModeService } from '../../services/mode.service';
import { OrderService } from '../../services/order.service';
import { Dialog } from '../../helpers/dialog';
import { DataFileAccessDto } from '../../dtos/data-file-access-dto.model';
import { OrderShipToAddressDto } from '../../dtos/order-ship-to-address-dto.model';
import { ShippinghandlingService } from '../../services/shippinghandling.service';

@Component({
  selector: 'app-ship-to',
  templateUrl: './ship-to.component.html',
  styleUrls: ['./ship-to.component.css']
})
  
export class ShipToComponent implements OnInit, OnDestroy {
  @Output() addressChanged = new EventEmitter<boolean>();
  visibleTextValue = "Refreshing Data";
  shipToDto = new ShipToDto();
  orderIsReadySubscription: Subscription | undefined;
  gotContactsSubscription: Subscription | undefined;
  orderShipToAddressDetailsAreReadySubscription: Subscription | undefined;
  activeStatus: string[] = ['A', 'BAD', 'BP'];
  contactList: IUITKSelectItemProps[] = [];
  contacts: ContactDto[] = [];
  isShipToDisabled: boolean = true;
  Mode = Mode;
  @Input() isnavigateFromBatchReturn: boolean = false;
  disableAddButton = false;
  showSelectContactsDialog = new Dialog<string>();
  showShipmentConfirmationEmailDialog = new Dialog<string>();
  displayContactMessage = "Getting ship to contacts";
  enableChangeLink = false;
  constructor(public modeService: ModeService,
    public orderService: OrderService,
    private readonly configService: ConfigService,
    private readonly contactService: ContactService,
    private readonly shippinghandlingService: ShippinghandlingService,
    private readonly authorizationService: AuthorizationService
  ) { }

  addressChange(zipChanged: boolean) {
    this.addressChanged.emit(zipChanged);
  }

  ngOnInit(): void {
    //Below condition is to check for naviagation from Batch Return
    if (this.isnavigateFromBatchReturn === false) {
      this.gotContactsSubscription = this.contactService.gotContacts.subscribe(data => {
        this.contacts = data.filter(c => this.activeStatus.includes(c.statusCode.trim()) || c.contactId === this.orderService.orderDto.shipToDto.contactId);
        this.contactList = this.contactService.convertContactsToUitkProps(this.contacts);
        this.orderService.orderDto.shipToContactBinding = this.contactList.filter(x => x.id === this.orderService.orderDto.shipToDto.contactId.toString())[0];
        this.setContactValue(this.orderService.orderDto.shipToContactBinding);
        this.updateShipToSectionAfterContactDetailsAreReady();
      });

      this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
        this.shipToDto = this.orderService.orderDto.shipToDto;
        this.contactService.getContacts(this.orderService.orderDto.billToDto.id, this.displayContactMessage);
        this.contactService.getOrderShipToAddressDetails(this.orderService.orderDto.id);
        if ((this.orderService.orderDto.orderType === 'R' || this.orderService.orderDto.orderType === 'A') &&
          this.authorizationService.hasResource('ReturnEntryShipToContactDropDown')) {
          this.isShipToDisabled = false;
        }
    
        if ((this.orderService.orderDto.orderType === ' ' || this.orderService.orderDto.orderType === 'E' || this.orderService.orderDto.orderType === 'Q') &&
          this.authorizationService.hasResource('ShipToContactDropDown')) {
          this.isShipToDisabled = false;
        }
        //Commented out below lines to load the data without loader waititng for indefinite time
        // this.modeService.mode = Mode.ShipToWait;
        this.disableAddButtonCheck();
        
        if (this.orderService.orderDto.orderDetailDtos.some(x => x.orderDetailStatus === ' ') && (this.orderService.orderDto.orderType === ' ' || this.orderService.orderDto.orderType === 'E') && (this.authorizationService.hasResource('OrderInquiryEditButton'))) {
          this.enableChangeLink = true
        }
      });

      this.orderShipToAddressDetailsAreReadySubscription = this.contactService.orderShipToAddressDetails.subscribe((data: OrderShipToAddressDto) => {
        this.updateShipToSectionAfterOrderShipToAddressDetailsAreReady(data);
      });

      if (location.href.indexOf('return-entry') > -1)
      {
        this.disableAddButtonCheck();
      }
    }


  }

  async setContactValue(newShipToContact: IUITKSelectItemProps) {
    if (!newShipToContact) {
      return;
    }

    const selectedContact = this.contacts.filter(x => x.contactId.toString() === newShipToContact.id)[0];

    if (!this.orderService.orderDto.shipToDto || !selectedContact) {
      return;
    }

    this.orderService.orderDto.shipToDto.firstName = selectedContact.firstName!;
    this.orderService.orderDto.shipToDto.lastName = selectedContact.lastName!;
    this.orderService.orderDto.shipToDto.contactId = selectedContact.contactId;
    this.orderService.orderDto.shipToDto.allowInformationalEmails = selectedContact.allowInformationalEmails;
    this.orderService.orderDto.shipToDto.allowPromotionalEmails = selectedContact.allowPromotionalEmails;
    this.orderService.orderDto.shipToDto.email = selectedContact.email;
    this.orderService.orderDto.shipToDto.phone = selectedContact.phone;
    this.orderService.orderDto.shipToDto.salesforceId = selectedContact.salesforceId;
    this.orderService.orderDto.shipToDto.defaultPaymentMethod = selectedContact.defaultPaymentMethod;

    if (this.modeService.mode === Mode.Edit) {
      await this.shippinghandlingService.recalculateShippingAndHandlingAsync(this.orderService.orderDto);
      this.orderService.recalculateFields(this.orderService.orderDto);
    }
  }

  async setSelectedValue(newShipToContact: IUITKSelectItemProps) {
    if (!newShipToContact) {
      return;
    }

    const selectedContact = this.contacts.filter(x => x.contactId.toString() === newShipToContact.id)[0];

    if (!this.orderService.orderDto.shipToDto || !selectedContact) {
      return;
    }

    if (this.orderService.orderDto.orderDetailDtos.some(item => item.orderDetailStatus === ' ' && item.productDto.configSetDtos.some(item2 => item2.configSet === 'DATAFILE'))) {
      this.orderService.orderDto.dataFileAccessDtos = this.orderService.orderDto.dataFileAccessDtos.filter(x => x.contactId !== this.orderService.orderDto.shipToDto.contactId);
      let selectedContactId = new DataFileAccessDto();
      selectedContactId.contactId = selectedContact.contactId;
      this.orderService.orderDto.dataFileAccessDtos.push(selectedContactId);
    }

    if (this.orderService.orderDto.shipToDto.contactId !== selectedContact.contactId) {
      this.setselectedContactShipToAddress(selectedContact);
    }

    this.orderService.orderDto.shipToDto.firstName = selectedContact.firstName!;
    this.orderService.orderDto.shipToDto.lastName = selectedContact.lastName!;
    this.orderService.orderDto.shipToDto.contactId = selectedContact.contactId;
    this.orderService.orderDto.shipToDto.allowInformationalEmails = selectedContact.allowInformationalEmails;
    this.orderService.orderDto.shipToDto.allowPromotionalEmails = selectedContact.allowPromotionalEmails;
    this.orderService.orderDto.shipToDto.email = selectedContact.email;
    this.orderService.orderDto.shipToDto.phone = selectedContact.phone;
    this.orderService.orderDto.shipToDto.salesforceId = selectedContact.salesforceId;
    this.orderService.orderDto.shipToDto.defaultPaymentMethod = selectedContact.defaultPaymentMethod;

    if (this.modeService.mode === Mode.Edit) {
      this.addressChange(true);
      this.setPaymentMethodBR();
      await this.shippinghandlingService.recalculateShippingAndHandlingAsync(this.orderService.orderDto);
      this.orderService.recalculateFields(this.orderService.orderDto);
    }
  }
  setselectedContactShipToAddress(selectedContact: ContactDto){
    this.orderService.orderDto.shipToDto.company = selectedContact.street ? selectedContact.company : this.orderService.orderDto.billToDto.abbreviatedCompany?? '';
    this.orderService.orderDto.shipToDto.department = selectedContact.street ? selectedContact.department : this.orderService.orderDto.billToDto.department;
    this.orderService.orderDto.shipToDto.street = selectedContact.street ? selectedContact.street : this.orderService.orderDto.billToDto.street;
    this.orderService.orderDto.shipToDto.city = selectedContact.street ? selectedContact.city : this.orderService.orderDto.billToDto.city;
    this.orderService.orderDto.shipToDto.state = selectedContact.street ? selectedContact.state : this.orderService.orderDto.billToDto.state;
    this.orderService.orderDto.shipToDto.zip = selectedContact.street ? selectedContact.zip : this.orderService.orderDto.billToDto.zip;
    this.orderService.orderDto.shipToDto.country = selectedContact.street ? selectedContact.country : this.orderService.orderDto.billToDto.country;
  
  }
  navigateToSFNewOrEditContact(salesforceContactId: string) {
    window.open(`${this.configService.salesforceUrl}/apex/NewContactPage?id=${salesforceContactId}`);
  }

  navigateToSFAddContact(salesforceAccountId: string) {
    window.open(`${this.configService.salesforceUrl}/apex/NewContactPage?accId=${salesforceAccountId}`);
  }

  navigateToSFViewContact(salesforceContactId: string) {
    window.open(`${this.configService.salesforceUrl}/apex/ViewContact?id=${salesforceContactId}`);
  }

  refreshShipToContactList() {
    this.contactService.clearContacts();
    this.contactService.getContacts(this.orderService.orderDto.billToDto.id, this.displayContactMessage);
    this.contactService.getOrderShipToAddressDetails(this.orderService.orderDto.id);
  }

  getOrderData() {
    this.gotContactsSubscription = this.contactService.gotContacts.subscribe(data => {
      this.contacts = data.filter(c => this.activeStatus.includes(c.statusCode.trim()) || c.contactId === this.orderService.orderDto.shipToDto.contactId);
      this.contactList = this.contactService.convertContactsToUitkProps(this.contacts);
      this.orderService.orderDto.shipToContactBinding = this.contactList.filter(x => x.id === this.orderService.orderDto.shipToDto.contactId.toString())[0];
      this.setSelectedValue(this.orderService.orderDto.shipToContactBinding);
    });

    this.shipToDto = this.orderService.orderDto.shipToDto;
    this.contactService.getContacts(this.orderService.orderDto.billToDto.id, this.displayContactMessage);
  }

  disableAddButtonCheck() {
    if (this.orderService.orderDto.orderType === 'R') {
      if (this.authorizationService.hasResource("ReturnShipToAddButton")) {
        this.disableAddButton = false;
      }
      else {
        this.disableAddButton = true;
      }
    }
    if (this.orderService.orderDto.orderType === ' ') {
      if (this.authorizationService.hasResource("ShipToAddButton")) {
        this.disableAddButton = false;
      }
      else {
        this.disableAddButton = true;
      }
    }
  }

  setPaymentMethodBR() {
    if (!['C', 'D', 'M', 'V', 'X'].includes(this.orderService.orderDto.paymentMethod == null ? '' : this.orderService.orderDto.paymentMethod)) {
      if (this.orderService.orderDto.billToDto.defaultPaymentMethod === 'S') {
        this.setPaymentMethodForBillToDefaultPMeth_S();
        this.checkAndSetPaymentMethod();
      }
      else if (this.orderService.orderDto.billToDto.defaultPaymentMethod === 'I' || (!this.orderService.orderDto.billToDto.defaultPaymentMethod)) {
        if (!this.orderService.orderDto.shipToDto.defaultPaymentMethod || this.orderService.orderDto.shipToDto.defaultPaymentMethod === 'I') {
          this.orderService.orderDto.paymentMethod = 'I';
        }
        else if (this.orderService.orderDto.shipToDto.defaultPaymentMethod === 'S') {
          this.orderService.orderDto.paymentMethod = 'S';
        }
        else if (this.orderService.orderDto.shipToDto.defaultPaymentMethod === 'N') {
          this.orderService.orderDto.paymentMethod = 'N';
        }
      }
    }
  }

  checkAndSetPaymentMethod(){
    if (!this.orderService.orderDto.shipToDto.defaultPaymentMethod || this.orderService.orderDto.shipToDto.defaultPaymentMethod === 'I' ||
      this.orderService.orderDto.shipToDto.defaultPaymentMethod === 'S') {
      this.orderService.orderDto.paymentMethod = 'S';
    }
    else if (this.orderService.orderDto.shipToDto.defaultPaymentMethod === 'N') {
      this.orderService.orderDto.paymentMethod = 'N';
    }
  }
  setPaymentMethodForBillToDefaultPMeth_S() {
    if (!this.orderService.orderDto.shipToDto.defaultPaymentMethod || this.orderService.orderDto.shipToDto.defaultPaymentMethod === 'I' ||
      this.orderService.orderDto.shipToDto.defaultPaymentMethod === 'S') {
      this.orderService.orderDto.paymentMethod = 'S';
    }
    else if (this.orderService.orderDto.shipToDto.defaultPaymentMethod === 'N') {
      this.orderService.orderDto.paymentMethod = 'N';
    }
  }
  updateShipToSectionAfterContactDetailsAreReady(): void {
    this.orderService.orderDto.shipToDto.firstName = this.contactService.contactDtos!.filter(c => c.contactId === this.orderService.orderDto.shipToDto.contactId)[0].firstName!;
    this.orderService.orderDto.shipToDto.lastName = this.contactService.contactDtos!.filter(c => c.contactId === this.orderService.orderDto.shipToDto.contactId)[0].lastName!;
    this.orderService.orderDto.shipToDto.email = this.contactService.contactDtos!.filter(c => c.contactId === this.orderService.orderDto.shipToDto.contactId)[0].email;
    this.orderService.orderDto.shipToDto.phone = this.contactService.contactDtos!.filter(c => c.contactId === this.orderService.orderDto.shipToDto.contactId)[0].phone;
    this.orderService.orderDto.shipToDto.allowInformationalEmails = this.contactService.contactDtos!
      .filter(c => c.contactId === this.orderService.orderDto.shipToDto.contactId)[0].allowInformationalEmails;
    this.orderService.orderDto.shipToDto.allowPromotionalEmails = this.contactService.contactDtos!
      .filter(c => c.contactId === this.orderService.orderDto.shipToDto.contactId)[0].allowPromotionalEmails;
    this.orderService.orderDto.shipToDto.statusCode = this.contactService.contactDtos!.filter(c => c.contactId === this.orderService.orderDto.shipToDto.contactId)[0].statusCode;
    this.orderService.orderDto.shipToDto.defaultPaymentMethod = this.contactService.contactDtos!
      .filter(c => c.contactId === this.orderService.orderDto.shipToDto.contactId)[0].defaultPaymentMethod;
  }

  updateShipToSectionAfterOrderShipToAddressDetailsAreReady(data: OrderShipToAddressDto): void {
    this.orderService.orderDto.shipToDto.company = (data.company !== '' && data.company !== null)? data.company: this.orderService.orderDto.billToDto.abbreviatedCompany ?? '';  
    this.orderService.orderDto.shipToDto.department = data.department;
    this.orderService.orderDto.shipToDto.street = data.street;
    this.orderService.orderDto.shipToDto.city = data.city;
    this.orderService.orderDto.shipToDto.state = data.state;
    this.orderService.orderDto.shipToDto.zip = data.zip;
    this.orderService.orderDto.shipToDto.country = data.country;
  }

  async showSelectedContacts(): Promise<void> {
    this.orderService.additionalContactsSource = 'Ship To Component';

    if (this.modeService.mode === Mode.View) {
      await this.orderService.triggerEditMethod();

      const response = await this.showSelectContactsDialog.openAsync();

      if (response === 'Save') {
        this.orderService.triggerSaveMethod(true);
      } else {
        this.orderService.triggerRevertMethod();
      }  
    } else {
      await this.showSelectContactsDialog.openAsync();
    }
  }

  async showShipmentConfirmationEmail(): Promise<void> {

    if (this.modeService.mode === Mode.View) {
      await this.orderService.triggerEditMethod();

      const response = await this.showShipmentConfirmationEmailDialog.openAsync();
      if (response === 'Save') {
        this.orderService.triggerSaveMethod(true);
      } else {
        this.orderService.triggerRevertMethod();
      }
    } else {
      await this.showShipmentConfirmationEmailDialog.openAsync();
    }
  }
  ngOnDestroy(): void {
    this.orderIsReadySubscription?.unsubscribe();
    this.gotContactsSubscription?.unsubscribe();
    this.orderShipToAddressDetailsAreReadySubscription?.unsubscribe();
  }
}
