export class ApprovalDto {
    id: number = 0;
    approvalLevel: string | null = null;
    approver: string | null = null;
    threshold?: number | null = null;
    approvedOrRejectedStatus: string | null = null;
    approvedOrRejectedBy: string | null = null;
    approvedOrRejectedDate?: Date | null = null;
    comments: string | null = null;
  }
  