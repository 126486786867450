<div *ngIf="recentQuoteOrOrderDetailsDialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkId="viewRecentQuoteOrOrderDetails" [(showModal)]="recentQuoteOrOrderDetailsDialog.show"
  [closeBtn]="true" (onModalHidden)="focusParentDialog()">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Quote/Order Details
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div class="row mt-3">
      <div class="uitk-c-table--responsive container fixTableHead">
        <table uitkTable [dataSource]="recentQuoteOrOrderDetails" id="recent-quote-or-order-details-table"
          class="uitk-c-table" aria-describedby="recent-quote-or-order-details-data">
          <thead class="uitk-c-table__head">
            <tr style="text-align: left">
              <th *ngFor="let tableHeader of tableHeaders" scope="col">
                {{ tableHeader }}
              </th>
            </tr>
          </thead>
          <tbody id="recent-quote-or-order-details-data" class="uitk-c-table__body">
            <tr *ngIf="recentQuoteOrOrderDetails.data.length === 0">
              <td colspan="8">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col" style="text-align: center;">
                      No data found
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <ng-container *uitkTableRowBody="let data">
              <tr class="uitk-c-table__row">
                <td style="color: #027db4">  
                  <a [href]="'/order/' + recentQuoteOrOrder.quoteOrOrderNumber" target="_blank" class="zeroMarginBottom">  
                      {{ recentQuoteOrOrder.quoteOrOrderNumber }}  
                  </a>  
              </td>  
                <td>
                  <p class="zeroMarginBottom" [innerHTML]="data.item"></p>
                </td>
                <td>
                  <p class="zeroMarginBottom" [innerHTML]="data.description"></p>
                </td>
                <td>
                  <p class="zeroMarginBottom" [innerHTML]="data.quantity"></p>
                </td>
                <td>
                  <p class="zeroMarginBottom" [innerHTML]="data.listPrice | currency:'USD':'symbol':'1.2-2'"></p>
                </td>
                <td>
                  <p class="zeroMarginBottom" [innerHTML]="data.discount / 100 | percent:'1.3-3'"></p>
                </td>
                <td>
                  <p class="zeroMarginBottom" [innerHTML]="data.unitPrice | currency:'USD':'symbol':'1.2-2'"></p>
                </td>
                <td>
                  <p class="zeroMarginBottom" [innerHTML]="data.extendedPrice | currency:'USD':'symbol':'1.2-2'"></p>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </uitk-dialog-content>
</uitk-dialog>
  </div>
</div>
