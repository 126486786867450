import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.css']
})

export class ShippingComponent implements OnInit {
  private readonly _pricingService: PricingService;
  private readonly _orderService: OrderService;
  @Input() orderDetail: OrderDetailDto = new OrderDetailDto();
  @Output() enableSave = new EventEmitter<boolean>();
  showErrorMessage = false;
  errorVariant: any = '';
  errorMessage = '';
  licensePricing:
    { productCode: string, listPrice: number, orderQuantity: number, openQuantity: number, discount: number, unitPrice: number, extendedPrice: number, id: number } =
    { productCode: '', listPrice: 0, orderQuantity: 1, openQuantity: 1, discount: 0, unitPrice: 0, extendedPrice: 0, id: 0 }

  constructor(
    pricingService: PricingService,
    orderService: OrderService
  ) {
    this._pricingService = pricingService;
    this._orderService = orderService;
  }

  ngOnInit(): void {
    this.updateLicensePricing(this.orderDetail);
  }

  updateLicensePricing(orderDetail: OrderDetailDto): void {
    this.licensePricing.productCode = orderDetail.productCode;
    this.licensePricing.listPrice = orderDetail.listPrice;
    this.licensePricing.orderQuantity = orderDetail.orderQuantity === 0 ? 1 : orderDetail.orderQuantity;
    this.licensePricing.openQuantity = orderDetail.openQuantity === 0 ? 1 : orderDetail.openQuantity;
    this.licensePricing.discount = orderDetail.discount;
    this.licensePricing.unitPrice = orderDetail.unitPrice;
    this.licensePricing.extendedPrice = orderDetail.extendedPrice;
    this.licensePricing.id = orderDetail.id;
  }

  onChangeShippingCharges(): void {
    this._pricingService.calculateUnitPrice(this.licensePricing);
    this._pricingService.calculateExtendedPrice(this.licensePricing);
  }

  onChangeShippingChargesDiscount(): void {
    this._pricingService.calculateUnitPrice(this.licensePricing);
    this._pricingService.calculateExtendedPrice(this.licensePricing);
  }

  onChangeTotalShippingCharges(): void {
    this.licensePricing.unitPrice = this.licensePricing.extendedPrice;
    this._pricingService.calculateDiscount(this.licensePricing);
    this._pricingService.calculateUnitPrice(this.licensePricing);
    this._pricingService.calculateExtendedPrice(this.licensePricing);
  }

  validateUPSShippingCharges(): boolean {
    if (this.licensePricing.listPrice === 0) {
      this.showErrorMessage = true;
      this.errorVariant = 'error';
      this.errorMessage = 'You must enter Calculated UPS International Shipping Charges to Proceed';
      return false;
    }

    return true;
  }

  save(): boolean {
    if (!this.validateUPSShippingCharges()) {
      return false;
    }

    this.orderDetail.productCode = this.licensePricing.productCode;
    this.orderDetail.listPrice = this.licensePricing.listPrice;
    this.orderDetail.orderQuantity = this.licensePricing.orderQuantity;
    this.orderDetail.openQuantity = this.licensePricing.openQuantity;
    this.orderDetail.discount = this.licensePricing.discount;
    this.orderDetail.unitPrice = this.licensePricing.unitPrice;
    this.orderDetail.extendedPrice = this.licensePricing.extendedPrice;
    this.orderDetail.id = this.licensePricing.id;

    if (this._orderService.saveAfterConfigSet) {
      this._orderService.triggerSaveMethod(false);
    }

    return true;
  }
}
