import { Injectable } from '@angular/core';
import { gridFilterModel } from '../dtos/grid-filter-model';
import { DatePipe } from '@angular/common';
import { UITKTableDataSource } from '@uitk/angular';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor(private readonly datePipe : DatePipe) { }

  showYesNoDialog(): string {
    return '';
  }

  isDate(_date: string){
    const _regExp  = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
    return _regExp.test(_date);
  }

  filterBooleanData(data: Array<any>, filter: gridFilterModel): Array<any> {
    const filteredData = data.filter(item => filter.filterValue === 'checked' ? item[filter.fieldName] : !item[filter.fieldName]);
    return filteredData;
  }

  filterNonBooleanData(data: Array<any>, filter: gridFilterModel): Array<any> {
    const filteredData = data.filter((item: { [x: string]: { toString: () => string; }; }) => {
      if(this.isDate(item[filter.fieldName]?.toString())){
        return  this.datePipe.transform(item[filter.fieldName]?.toString() ?? '', 'MM/dd/yyyy') === filter.filterValue.toLowerCase();
      }
      return item[filter.fieldName]?.toString()?.toLowerCase() === filter.filterValue.toLowerCase();
    }); 
    return filteredData;
  }

  FilterData(gridData : Array<gridFilterModel>, filterData : Array<any>) {
    let filterDataReturn : Array<any> = filterData;
    for(const gridFilter of gridData) {
      if (gridFilter.filterApplied) {
        console.log(gridFilter);
        if (gridFilter.dataType === 'boolean') {
          filterDataReturn = this.filterBooleanData(filterDataReturn, gridFilter);
        } else {
          filterDataReturn = this.filterNonBooleanData(filterDataReturn, gridFilter);
        }  
      }
    }
    return filterDataReturn;
  }
 
  sortData( filteredData : UITKTableDataSource<any>[], gridSort : any){
    let dataSource = new UITKTableDataSource<any>(filteredData);
    let sortedData = dataSource.sortData(dataSource.data, gridSort.sortDirection, gridSort.sortColumn).slice(0, gridSort.currentPageSize);
    return sortedData;
  }
}