import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrderComponent } from '../order/order.component';
import { Mode } from '../../../enums/mode.enum';
import { OrderDto } from '../../../dtos/order-dto.model';
import { AuthorizationService } from '../../../services/authorization.service';
import { ConfigService } from '../../../services/config.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-additional-actions',
  templateUrl: './additional-actions.component.html',
  styleUrls: ['./additional-actions.component.css']
})

export class AdditionalActionsComponent implements OnInit {
  private readonly _router: Router;
  private readonly _authorizationService: AuthorizationService;
  @ViewChild(OrderComponent) orderComponent: OrderComponent | undefined;
  disableAmaKeyButton = false;
  amaKeyButtonsubscription: Subscription | undefined;
  orderIsReadySubscription: Subscription | undefined;
  Mode = Mode;
  showContainer = false;
  showReturnsButton = false;
  showReturnEntryButton = false;
  disableAddNewReturn: boolean = true;
  isButtonPanelCollapsed = true;
  sendEmailDialog = { show: false };
  notesDialog = { show: false };
  packingSlipNotesDialog = { show: false };
  returnsDialog = { show: false };
  amaKeyDialog = { show: false };
  voidOrderDialog = { show: false };
  shipAndProcessDialog = { show: false };
  removeHoldDialog = { show: false };
  generatePackingSlipDialog = { show: false };
  showAttachmentsDialog = { show : false };
  @Input() enteredByUserRole : string | null = null;
  swalAlert = new SwalAlert();

  constructor(
    public modeService: ModeService,
    public orderService: OrderService,
    router: Router,
    authorizationService: AuthorizationService,
    public configService: ConfigService) {
    this._router = router;
    this._authorizationService = authorizationService;
  }

  ngOnInit(): void {
    this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
      this.isButtonPanelCollapsed = !this._authorizationService.hasResource("OrderInquiryAdditionalActionsExpand");
      if (this.orderService.orderDto.hasReturns) {
        this.showReturnsButton = true;
        this.showReturnEntryButton = false;
      } else {
        this.showReturnsButton = false;
        if (this._authorizationService.hasResource("OrderInquiryAdditionalActionsAddNewReturnsButton")) {
          this.showReturnEntryButton = true;
        }
        else
        {
          this.showReturnEntryButton = false;
        }
      }

      for (let i = 0; i < this.orderService.orderDto.orderDetailDtos.length; i++) {
        const lineItem = this.orderService.orderDto.orderDetailDtos[i];
        if (lineItem.orderDetailStatus !== ' ' || lineItem.shippedQuantity > 0) {
          this.disableAddNewReturn = false;
          break;
        }
      }
      this.disableAmaKeyButton = !this.orderService.orderDto.orderDetailDtos.some(item => item.productDto.requiresAmaKey === true);
    });

    this.amaKeyButtonsubscription = this.orderService.getAmaKeyButtonMode().subscribe(isDisabled => this.disableAmaKeyButton = isDisabled);
  }

  navigateReturnEntry(): void {
    this.configService.resetIdleTimeout();
    this._router.navigate(['/return-entry']);
  }

  navigateToInvoices(orderDto: OrderDto): void {
    this.configService.resetIdleTimeout();
    this._router.navigate([]).then(() => {
      window.open('/invoices/' + orderDto.id, '_blank');
    });
  }

  resendOrder(): void {
    this.configService.resetIdleTimeout();
    this.orderService.resendOrder(this.orderService.orderDto.id);
  }

  async revertToQuote() {
    if (this.orderService.orderDto.multiYearDtos.some(x => x.shippedAmount > 0)){
      await this.swalAlert.alert("Cannot convert to Quote as Items within the multiyear order has shipped.");
      return;
    }
    let getOrderDetails : OrderDto  = {...this.orderService.orderDto};
    getOrderDetails.orderType = 'Q'
    getOrderDetails.orderStatus = ' ';
    getOrderDetails.exceptionReasonCode = null;
    getOrderDetails.holdReasonCode = null;
    this.orderService.setLockOrder(this.orderService.orderDto.id, this.configService.getMyMSId(), false).subscribe(
      (data) => {          
        this.orderService.saveOrderToDatabase(getOrderDetails).then(async res => {
          await this.orderService.ConvertOrdersToQuote(this.orderService.orderDto.id, this.configService.getMyMSId());
          this._router.navigateByUrl('/order-entry?orderId=' + this.orderService.orderDto.id);
        })
        
    });
    
  }

  clearInUse(): void {
    this.configService.resetIdleTimeout();
    this.orderService.clearInUse(this.orderService.orderDto.id);
  }

  clearPending(): void {
    this.configService.resetIdleTimeout();
    this.orderService.clearPending(this.orderService.orderDto.id);
  }

  async convertException(): Promise<void> {
    await this.orderService.convertException(this.orderService.orderDto.id);
  }

  async showVoidOrderDialog(): Promise<void> {
    if ((this._authorizationService.hasResource('TucsonAllowPrintProducts') && !this._authorizationService.hasResource('TucsonAllowElectronicProducts'))) {
      if (this.orderService.orderDto.salesOrganization === 'Optum') {
        await this.swalAlert.alert(`You are not authorized to void the order.`);
        this.voidOrderDialog.show = false;
        return;
      }
    }
    else if (!this._authorizationService.hasResource('TucsonAllowPrintProducts') && this._authorizationService.hasResource('TucsonAllowElectronicProducts')) {
      if (this.orderService.orderDto.salesOrganization === 'Shutterfly') {
        await this.swalAlert.alert(`You are not authorized to void the order.`);
        this.voidOrderDialog.show = false;
        return;
      }
    }

    this.voidOrderDialog.show = true;
  }

  ngOnDestroy() {
    this.orderIsReadySubscription?.unsubscribe();
    this.amaKeyButtonsubscription?.unsubscribe();
  }
}
