<div class="container-fluid">
  <uitk-banner-notification [show]="showErrorMessage" uitkId="errorMessage" [variant]="errorVariant">
    <h1 uitkBannerHeading>{{ errorMessage }}</h1>
  </uitk-banner-notification>
  <div class="row mb-4">
    <label class="col-lg-12" uitkLabel>
      Please contact your manager or calculate via UPS.com
    </label>
  </div>
  <div class="row mb-4">
    <div class="col-sm-10">
      <div class="row mb-4">
        <label class="col-lg-9" uitkLabel style="margin-top: 10px;">UPS Shipping Charges</label>
        <div class="col-lg-3">
          <uitk-form-field>
            <input type="text" uitkInput [(customModel)]="licensePricing.listPrice" name="upsShippingCharges"
              id="upsShippingCharges-{{ orderDetail.id }}" (blur)="onChangeShippingCharges()"
              style="width: 100px;" appPriceFormat />
          </uitk-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <label class="col-lg-9" uitkLabel style="margin-top: 10px;">Discount</label>
        <div class="col-lg-3">
          <uitk-form-field>
            <input type="text" uitkInput [(customModel)]="licensePricing.discount" name="upsShippingChargesDiscount"
              id="upsShippingChargesDiscount-{{ orderDetail.id }}"
              (blur)="onChangeShippingChargesDiscount()" style="width: 100px;" appDiscountFormat />
          </uitk-form-field>
        </div>
      </div>
      <div class="row">
        <label class="col-lg-9" uitkLabel style="margin-top: 10px;">Total Shipping Charges</label>
        <div class="col-lg-3">
          <uitk-form-field>
            <input type="text" uitkInput [(customModel)]="licensePricing.extendedPrice" name="upsTotalShippingCharges"
              id="upsTotalShippingCharges-{{ orderDetail.id }}"
              (blur)="onChangeTotalShippingCharges()" style="width: 100px;" appPriceFormat />
          </uitk-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
