import { Component, Input, OnInit } from '@angular/core';
import { OrderDetailDto } from '../../../../dtos/order-detail-dto.model';
import { PackingSlipDetailDto } from '../../../../dtos/packing-slip-detail-dto.model';
import { PackingSlipDto } from '../../../../dtos/packing-slip-dto.model';
import { OrderService } from '../../../../services/order.service';
import { SwalAlert } from '../../../../helpers/alert';

@Component({
  selector: 'app-generate-packing-slip',
  templateUrl: './generate-packing-slip.component.html',
  styleUrls: ['./generate-packing-slip.component.css']
})

export class GeneratePackingSlipComponent implements OnInit {
  private readonly _orderService: OrderService;
  @Input() dialog = { show: false };
  allRowsSelected = true;
  emailAddress = '';
  shipDate = new Date(new Date().toDateString());
  isShipDateManuallySet: boolean = false;
  orderDetailDtos: OrderDetailDto[] = [];
  swalAlert = new SwalAlert();

  constructor(orderService: OrderService) {
    this._orderService = orderService;
  }

  ngOnInit(): void {
    this.orderDetailDtos = this._orderService.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus !== 'V' && item.isPhysical);
    this.orderDetailDtos.sort((item1, item2) => (item1.shipDate?? new Date(2000, 1,1)) < (item2.shipDate?? new Date(2000,1,1)) ? -1 : 1);
    for (const orderDetailDto of this.orderDetailDtos) {
      orderDetailDto.packingSlipShipQuantity = orderDetailDto.shippedQuantity;
      orderDetailDto.packingSlipBackorderQuantity = orderDetailDto.openQuantity;
      orderDetailDto.isSelected = true;
    }
  }

  selectAllRows(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.allRowsSelected = inputElement.checked;

    if (this.allRowsSelected) {
      for (const orderDetailDto of this.orderDetailDtos) {
        orderDetailDto.isSelected = true;
      }
    } else {
      for (const orderDetailDto of this.orderDetailDtos) {
        orderDetailDto.isSelected = false;
      }
    }
    this.changeShipDateOnSelectionChange();
  }

  onRowChecked(): void {
    this.allRowsSelected = !this.orderDetailDtos.filter(item => !item.isSelected);
    this.changeShipDateOnSelectionChange();
  }

  changeShipDateOnSelectionChange() {
    if (this.isShipDateManuallySet === false) {//
      const allShippedDates = []; // to store ShipDates of all the selected line items in a grid
      for (let orderDetailDto of this.orderDetailDtos) {
        if (orderDetailDto.isSelected && orderDetailDto.shipDate != null) {
          allShippedDates.push(orderDetailDto.shipDate);
        }
      }
      this.shipDate = allShippedDates.length === 0 ? new Date(new Date().toDateString()) : allShippedDates.length === 1 ? allShippedDates[0] : this.shipDate;

      this.setShipDate(allShippedDates);
    }
  }

  setShipDate(allShippedDates:any){
    if (allShippedDates.length > 1) {
      let minDate = allShippedDates[0];
      allShippedDates.forEach((lineItemDate:any) => {
        minDate = new Date(lineItemDate).getTime() < new Date(minDate).getTime() ? lineItemDate : minDate;
      });
      this.shipDate = minDate;
    }
  }

  onPackingSlipShipQuantityChange(orderDetailDto: OrderDetailDto): void {
    if (orderDetailDto.packingSlipShipQuantity > orderDetailDto.orderQuantity) {
      orderDetailDto.packingSlipShipQuantity = orderDetailDto.orderQuantity;
    }
  }

  onPackingSlipBackorderQuantityChange(orderDetailDto: OrderDetailDto): void {
    if (orderDetailDto.packingSlipBackorderQuantity > orderDetailDto.orderQuantity) {
      orderDetailDto.packingSlipBackorderQuantity = orderDetailDto.orderQuantity;
    }
  }

  onChangeShipDate(e: any) {
    if (e.srcElement.value === '') {
      this.swalAlert.alert('ShipDate cannot be set to empty');
    }
    else {
      this.formatShipDate(e.srcElement.value);

      for (const item of this.orderDetailDtos) {//resetting the quantities
        item.packingSlipShipQuantity = item.shippedQuantity;
        item.packingSlipBackorderQuantity = item.openQuantity;
      }

      for (let orderDetailDto of this.orderDetailDtos) {
        const shipDate = new Date(this.shipDate);
        shipDate.setUTCHours(23, 59, 59, 9999);
        if (orderDetailDto.shipDate) {
          if (new Date(orderDetailDto.shipDate!).getTime() > shipDate.getTime() && orderDetailDto.packingSlipShipQuantity !== 0) {
            let temp = orderDetailDto.packingSlipShipQuantity;
            orderDetailDto.packingSlipShipQuantity = orderDetailDto.packingSlipBackorderQuantity;
            orderDetailDto.packingSlipBackorderQuantity = temp;//Swapping qunatities if LineItem shipDate is greater
          }
        }
      }
    }
  }

  formatShipDate(inputDate: string) {
    if (inputDate.length === 10) {//Entered MM/dd/yyyy
      this.shipDate = new Date(inputDate);
    }
    else if (inputDate.length === 8 || inputDate.length === 6) {//either MMddyyyy or MMddyy
      const year = inputDate.substr(4);
      const month = inputDate.substr(0, 2);
      const day = inputDate.substr(2, 2);
      const out = month + '/' + day + '/' + year;
      this.shipDate = new Date(out);
      console.log(this.shipDate);
    }
    this.isShipDateManuallySet = true;
  }

  generatePackingSlip(): void {
    const packingSlipDto = new PackingSlipDto();
    packingSlipDto.email = this.emailAddress;
    packingSlipDto.orderId = this._orderService.orderDto.id;
    packingSlipDto.shipDate = new Date(this.shipDate);
    for (const orderDetailDto of this.orderDetailDtos) {
      if (orderDetailDto.isSelected) {
        const packingSlipDetailDto = new PackingSlipDetailDto();
        packingSlipDetailDto.orderDetailId = orderDetailDto.id;
        packingSlipDetailDto.productId = orderDetailDto.productId;
        packingSlipDetailDto.shipQuantity = orderDetailDto.packingSlipShipQuantity;
        packingSlipDetailDto.backorderQuantity = orderDetailDto.packingSlipBackorderQuantity;
        packingSlipDto.packingSlipDetailDtos.push(packingSlipDetailDto);
      }
    }

    if (packingSlipDto.packingSlipDetailDtos.length === 0) {
      this.swalAlert.alert('There are no items selected to generate packing slip.');
    }
    else {
      this._orderService.generatePackingSlip(packingSlipDto).subscribe(() => {
        this.swalAlert.alert('Packing Slip generated successfully.');
      }, () => {
        this.swalAlert.alert("Error attempting to generate packing slip.");
      });
    }

    this.dialog.show = false;
  }
}
