<div *ngIf="orderLevelOffersOthersDialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="orderLevelOffersOthersDialog.show" uitkId="orderLevelOffersOthersDialog"
  triggerElementId="myDialog-1_openBtn" [closeBtn]="false" (onModalHidden)="hidePromoPopup()">
  <uitk-dialog-header>
    <div cdkDragHandle>
    {{ myDialogHeader }}
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div>
      <div class="row">
      <div class="col">
          <div *ngIf="qualifyingTier == undefined"> 
              <strong>Customer does not currently qualify for a discount.</strong> <br />
              <div class="row">
                <label class="align-self-center label col-5" uitkLabel>Qualifying Order Quantity</label>
                <uitk-form-field class="col-6">
                  <input type="text" uitkInput id="minimumQualifyingQuantity" class="textbox" type="text" [(ngModel)]="qualifyingQuantity" disabled/>
                </uitk-form-field>
              </div>
              <div>
                <span style="color: red;">Customer must purchase an additional {{ minimumQualifyingQuantity }} units to
                  qualify for this offer</span>
              </div>
            </div>
      <div
        *ngIf="orderService.orderDto.orderOfferDto?.discountAndPremium === false && qualifyingTier != undefined && orderService.orderDto.orderOfferDto?.premiums!.length > 0"
        style="padding-bottom: 10px">
        <strong>Select either &nbsp;</strong>
        <input type="radio" id="orderDiscount" name="discountType" value="orderdiscount" [(ngModel)]="tierDiscountType"
          checked (click)="clearSelectedPremiums()">
        <label for="orderDiscount" class="radiolabel"> Order Discount </label>
        <strong> OR &nbsp;&nbsp; </strong>
        <input type="radio" id="premium" name="discountType" value="premiums" [(ngModel)]="tierDiscountType">
        <label for="premium" class="radiolabel"> Premium </label>
        <br>
      </div>
      <div *ngIf="qualifyingTier != undefined">
        Customer Qualifies for
        <span> {{orderService.orderDto.orderOfferDto?.percentDiscount!/100 | percent:'1.0-3'}} </span>
        off the entire order
      </div>
      
      </div>
      <div class="col-4">
        <div uitkTableContainer>
          <table uitkTable id="orderLevelOffersTiersDisc-table">
            <thead>
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Disc</th>
              </tr>
            </thead>
            <tbody id="premium-data">
              <ng-container>
                <tr *ngFor="let tier of orderService.orderDto.orderOfferDto?.tiers"
                  [ngClass]="{ 'matched-promo': tier === qualifyingTier }">
                  <td>{{ tier.from }}</td>
                  <td>{{ tier.to }}</td>
                  <td>{{ tier.discount / 100 | percent }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
      <div *ngIf="orderService.orderDto.orderOfferDto?.premiums!.length > 0 && qualifyingTier != undefined">
        <div class="uitk-l-grid">
          <div class="uitk-l-grid__row">
            <div class="uitk-l-grid__col--12">
                <div *ngIf="orderService.orderDto.orderOfferDto?.premiums!.length > 0 && qualifyingTier != undefined">
                    <span *ngIf="orderService.orderDto.orderOfferDto?.discountAndPremium === true" class="redAndBoldText">AND</span>
                    <span *ngIf="orderService.orderDto.orderOfferDto?.discountAndPremium === false" class="redAndBoldText">OR</span>
                    <span *ngIf="orderService.orderDto.orderOfferDto?.allPremiums === true"> Select from the following Premiums:
                    </span>
                    <span *ngIf="orderService.orderDto.orderOfferDto?.allPremiums === false"> Select one of the following Premiums:
                    </span>
                    <div></div>
                  </div>
              <div uitkTableContainer>
                <table uitkTable [dataSource]="orderService.orderDto.orderOfferDto?.premiums!"
                  id="orderLevelOffers-table">
                  <thead>
                    <tr>
                      <th *ngIf="orderService.orderDto.orderOfferDto?.allPremiums === true"
                        style="padding-left: 8px; padding-top: 5px; vertical-align: top">
                        <input type="checkbox" uitkCheckbox id="selectAllPremiums" name="selectAllPremiums"
                          [(ngModel)]="selectAllPremium" (change)="onSelectAllPremiums()" [disabled]="tierDiscountType
                          === 'orderdiscount'" />
                        <label uitkCheckboxLabel for="selectAllPremiums"></label>
                      </th>
                      <th *ngIf="orderService.orderDto.orderOfferDto?.allPremiums === false">
                      </th>
                      <th *ngFor="let headerName of orderLevelOfferHeader" scope="col">
                        {{ headerName }}
                      </th>
                    </tr>
                  </thead>
                  <tbody id="premiums-list">
                    <ng-container *uitkTableRowRenderer="let premium">
                      <tr>
                        <td *ngIf="orderService.orderDto.orderOfferDto?.allPremiums === false">
                          <input id="subGrdRadio" type="radio" name="selectRowRadioGroup" class="uitk-c-radio"
                            (change)="onSelectionChange(premium)" [disabled]="tierDiscountType === 'orderdiscount'"
                            [checked]="premium.isSelected" />
                        </td>
                        <td *ngIf="orderService.orderDto.orderOfferDto?.allPremiums === true">
                          <uitk-form-field>
                            <input type="checkbox" uitkCheckbox id="selectRowCheckBox-{{premium.productId}}"
                              name="selectRowCheckBox-{{premium.productId}}" (change)="onSelectionChange(premium)"
                              [(ngModel)]="premium.isSelected" [disabled]="tierDiscountType === 'orderdiscount'" />
                            <label uitkCheckboxLabel for="selectRowCheckBox-{{premium.productId}}"></label>
                          </uitk-form-field>
                        </td>
                        <td>{{ premium.productCode }}</td>
                        <td>{{ premium.description }}</td>
                        <td>{{ premium.quantity }}</td>
                        <td>{{ premium.listPrice  | currency:'USD'}}</td>
                        <td>{{ premium.discount/100 | percent:'1.0-3'}}</td>
                        <td>
                          {{ premium.quantity * pricingService.round(premium.listPrice * (100 - premium.discount) / 100, 2) | currency:'USD' }}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button [disabled]="qualifyingTier == undefined" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="acceptOffer()" appAutoFocus>
      {{ acceptOfferText }}
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="doNotAcceptOffer()">
      {{ doNotAcceptOfferText }}
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="returnToCart()">
      {{ returnToCartText }}
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
<div class="divPopUpContent" *ngIf="configSetDialog.show">
  <app-config-sets [dialog]="configSetDialog" [orderDetail]="premiumOrderDetail">
  </app-config-sets>
</div>
  </div>
</div>