import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Dialog } from 'src/app/helpers/dialog';
import { Mode } from '../../../enums/mode.enum';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { OrderDto } from '../../../dtos/order-dto.model';
import { OrderNoteDto } from '../../../dtos/order-note-dto.model';
import { ProductDto } from '../../../dtos/product-dto.model';
import { AuthorizationService } from '../../../services/authorization.service';
import { ConfigService } from '../../../services/config.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';
import { ReturnDetailActionComponent } from '../return-detail-action/return-detail-action.component';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-return-detail',
  templateUrl: './return-detail.component.html',
  styleUrls: ['./return-detail.component.css'],
})
  
export class ReturnDetailComponent implements OnInit {
  private readonly _configService: ConfigService;
  private readonly _router: Router;
  private readonly _pricingService: PricingService;
  private readonly _authorizationService: AuthorizationService;
  @ViewChild(ReturnDetailActionComponent) returnDetailActionComponent: ReturnDetailActionComponent | undefined;
  @ViewChild("zeroPricingOkBtn") zeroPricingOkBtn!: ElementRef<HTMLInputElement>;
  @ViewChild("priceChangeOkBtn") priceChangeOkBtn!: ElementRef<HTMLInputElement>;
  @ViewChild("priceChangeInputOkBtn") priceChangeInputOkBtn!: ElementRef<HTMLInputElement>;
  @ViewChild("noItemOkBtn") noItemOkBtn!: ElementRef<HTMLInputElement>;
  Mode = Mode;
  loading = false;
  openDeletePopup = false;
  priceChangeProduct = false;
  disableReturnCode = false;
  deleteItemDialogModal = {
    show: false,
  };
  zeroPricingDialogModal = {
    show: false
  }
  zeroPricingReturnCodeSelected = false;
  showNoItemExists = {
    show: false
  }
  zeroPricingMessage = "You have entered a Zero Pricing source code.";
  priceChangeInputDialogMessage = "Please enter Unit Price Amount # or Discount Amount #";
  priceChangeInputDialogModal = {
    show: false
  }
  priceChangeDialogModal = {
    show: false
  }
  priceChangeDuplicateDialogModal = {
    show: false
  }
  orderEntryErrorReasonDialog = {
    show: false
  }
  priceChangeDuplicateHeaderMessage: string = "";
  priceChangeDuplicateMessage: string = "";
  returnDtos: OrderDto[] = [];
  totalAmount: number = 0;
  retunTableHeader = [
    { name: 'Return #', id: 'id' },
    { name: 'Return Date', id: 'orderDate' },
    { name: 'Return Type', id: 'orderType' },
    { name: 'Return Status', id: 'orderStatus' },
    { name: 'Return Code', id: 'sourceCode' },
    { name: 'Entered By', id: 'enteredBy' },
  ];
  myNotificationWarningContent = "This item is inactive or does not exist. Please enter a new item #";
  priceChangeMessage = "";
  unitPrice = 0;
  discount = 0;
  extPrice = 0;
  quantity = 0;
  selectedLineItemsCount = 0;
  todayDate = Date.now();
  priceChangeReturnCodeSelected = false;
  addNewReturnDialog = new Dialog<string>();
  addReturnAuthorizationDialog = new Dialog<string>();
  @Output() newItemEvent = new EventEmitter<boolean>();
  visibleTextValue = this.modeService.mode === Mode.Wait ? '' : 'Saving Data';
  returnCodeList: IUITKSelectItemProps[] = [];
  orderTypeList: IUITKSelectItemProps[] = [];
  orderStatusList: IUITKSelectItemProps[] = [];
  orderStatusFilterList: IUITKSelectItemProps[] = [];
  returnCode: string = '';
  selectedOrder: OrderDetailDto = new OrderDetailDto();
  nextNoteId = -1;
  isInputDiscountInvalid = false;
  isvalidInput = true;
  showReturnCodeError = false;
  message = '';
  returnCodeValue: string | null = '';
  orderDtoBackup: OrderDto = new OrderDto();
  callRevertSourceCodeSubscription!: Subscription;
  swalAlert = new SwalAlert();
  tableHeader = [
    'Invoice #',
    'Balance',
    'Discount',
    'Unit Price',
    'Ext. Price',
    'Req Date',
    'Stat',
    'Qty Ship',
    'Ship Date',
    'Term',
    'PP Date',
    'Date Voided/Edited',
    'Qty Voided',
    'Void Reason',
    'P Sales ID',
    'S Sales ID',
    'Payment Plan',
    'Sales Tax',
  ];

  constructor(
    configService: ConfigService,
    public modeService: ModeService,
    router: Router,
    public orderService: OrderService,
    pricingService: PricingService,
    authorizationService: AuthorizationService
  ) {
    this._configService = configService;
    this._router = router;
    this._pricingService = pricingService;
    this._authorizationService = authorizationService;
  }

  ngOnInit() {
    this.returnCodeList = this._configService.getPickList('RSOURCE');
    this.orderStatusList = this._configService.getPickList('ORDERSTATUS');
    this.orderStatusFilterList = this.orderStatusList.filter(s => s.value === 'H' || s.value === ' ');
    this.loading = false;
    this.orderService.orderDto.originalOrderDto = JSON.parse(JSON.stringify(this.orderService.orderDto)) as OrderDto;
    this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(x => x.shippedQuantity + x.returnedQuantity > 0 && x.orderDetailStatus !== 'V');
    this.orderService.orderDto.orderDate = new Date(this.todayDate);
    this.returnDtos = this.orderService.returnDtos.filter(o => (o.returnCode === "PC" || o.returnCode === "EPC" || o.returnCode === "PM") && o.orderStatus !== 'V');
    this.orderService.orderDto.orderDetailDtos.forEach(x => {
      x.originalOrderDetailDto = JSON.parse(JSON.stringify(x)) as OrderDetailDto
      x.disableQuantity = true;
      x.orderQuantity = (x.shippedQuantity + x.returnedQuantity) * -1;
      this._pricingService.calculateExtendedPrice(x);
      x.shipDate = null;
      x.openQuantity = (x.shippedQuantity + x.returnedQuantity) * -1;
      x.taxAmount = this._pricingService.round(((x.openQuantity / x.shippedQuantity) * x.taxAmount), 2);
      x.shippedQuantity = 0;
      x.orderDetailStatus = ' ';
    })
    this._pricingService.calculateOpenAmount(this.orderService.orderDto);
    this._pricingService.calculateShippedAmount(this.orderService.orderDto);
    this.orderDtoBackup = JSON.parse(JSON.stringify(this.orderService.orderDto)) as OrderDto;
    this.retrunEntryOrderDto();

    if (!this.orderService.orderDto.orderDetailDtos.some(item => item.invoiceNumber)) {
      this.swalAlert.alert('Invoice has not generated, cannot enter a return.');
      this.navigateToOrderInquiry(this.orderService.orderDto.originalOrderId);
    }

    this.callRevertSourceCodeSubscription = this.orderService.revertReturnCode.subscribe(() => {
      this.cancelReturnCode();
    });
  }

  add() {
    const orderDetail = new OrderDetailDto();
    orderDetail.orderDetailStatus = ' ';
    orderDetail.itemEditable = true;
    orderDetail.disableQuantity = false;
    orderDetail.requiredDate = new Date(new Date().toDateString());
    orderDetail.enteredDate = new Date();
    orderDetail.id = this.orderService.getNextOrderDetailId();
    this.orderService.orderDto.orderDetailDtos.unshift(orderDetail);

    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
  }

  async save(): Promise<void> {
    this._configService.resetIdleTimeout();
    if (this.orderService.orderDto.originalOrderDto && await this.returnQuantityValidateBR() && this.hasReturnCode()) {
      this.orderService.changeSubscriptionQuantityBR('Return Entry', this.orderService.orderDto.originalOrderDto, this.orderService.orderDto);

      let requiresReturnAuthForm:boolean = false;
      for (const orderDetailDto of this.orderService.orderDto.orderDetailDtos) {  
        if (!orderDetailDto.disableQuantity && this.orderService.orderDto.returnCode != "BAD" && this.orderService.orderDto.returnCode != "BANKR" 
              && !this._authorizationService.hasResource("Allow60DayReturnAuthBypass") && this.orderService.orderDto.billToDto.accountTypeCode != 'C' && this.orderService.orderDto.billToDto.accountTypeCode != 'H'
              &&(orderDetailDto.originalOrderDetailDto?.shipDate != null 
              && (new Date(orderDetailDto.originalOrderDetailDto?.shipDate.toString()) < new Date(new Date().setDate(new Date().getDate() - 60)))
              )
          )
        {
          requiresReturnAuthForm = true;
          break;
        }
      }

      this.displaySaveReturnPopups(requiresReturnAuthForm);
    }
  }
  async displaySaveReturnPopups(requiresReturnAuthForm: boolean): Promise<void> {
    let authFormResult = '';
    if (requiresReturnAuthForm) {
      authFormResult = await this.addReturnAuthorizationDialog.openAsync();
    }

    if (authFormResult === '' || authFormResult === 'Save')
    {
      const result = await this.addNewReturnDialog.openAsync();

      if (result === 'Save') {
        this._router.navigate(['/return', this.orderService.orderDto.id]);  
      }
    }
  }
  hasReturnCode(): boolean {
    if (!this.returnCodeValue) {
      this.showReturnCodeError = true;
      this.message = "Return Code is required";
      return false;
    }
    else {
      this.showReturnCodeError = false;
      return true;
    }
  }

  onDeleteClick(record: any) {
    this.openDeletePopup = true;
    this.deleteItemDialogModal.show = true;
    this.selectedOrder = record;
  }

  onDeletePopupClose() {
    this.openDeletePopup = false;
    this.deleteItemDialogModal.show = false;
  }
  onClick_ConfirmDeleteOrder() {
    const index = this.orderService.orderDto.orderDetailDtos.findIndex(i => i.id === this.selectedOrder.id);
    this.orderService.orderDto.orderDetailDtos.splice(index, 1);
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
    this.selectedLineItemsCount = this.selectedLineItemsCount - 1;
    this.calculateReturns();
    this.onDeletePopupClose();
  }

  navigateToOrderInquiry(orderId: number | null) {
    this._router.navigate(['/order/' + orderId]);
  }

  async onChangeProductCode(orderDetail: OrderDetailDto) {
    orderDetail.productCode = orderDetail.productCode.toUpperCase();
    let product: ProductDto | null = this._configService.reference?.productDtos.filter(p => p.productCode === orderDetail.productCode)[0] ?? null;

    if (!product) {
      // Make API call to get the product if it isn't available in the references.
      product = await this._configService.getProduct(orderDetail.productCode);
      if (!product) {
        this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
        return;
      }
      this._configService.reference?.productDtos.push(product);
    }

    if (!product) {
      this.showNoItemExists.show = true;
      this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
      setTimeout(() => {
        this.noItemOkBtn.nativeElement.focus();
      }, 0);
      return;
    }

    // Prevent inactive items from being returned if the user doesn't have permissions.
    const returnInactiveItems = this._authorizationService.hasResource('ReturnInactiveItems');
    if (product.statusCode === 'I' && !returnInactiveItems) {
      await this.swalAlert.alert('Inactive items cannot be returned.');
      this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(o => o !== orderDetail);
      return;
    }

    if (orderDetail.productCode === '0070' || orderDetail.productCode === '0070E') {
      orderDetail.extendedPrice = this.selectedOrder.extendedPrice + this.extPrice;
      orderDetail.listPrice = orderDetail.extendedPrice * -1;
      orderDetail.unitPrice = orderDetail.listPrice;
      if (this.selectedOrder.taxAmount > 0 || (this.selectedOrder.taxAmount * -1) > 0) {
        const orderDetailData = this.getOrderDetailDto('0048');
        const productData = this._configService.reference?.productDtos.filter(p => p.productCode === orderDetailData.productCode)[0];
        let discountPercentage: number = this.calculateDiscount();
        discountPercentage = discountPercentage > 0 ? discountPercentage : (-1 * discountPercentage);
        const listPrice = orderDetail.listPrice * (discountPercentage / 100)
        orderDetailData.listPrice = Number(listPrice.toFixed(2));
        orderDetailData.discount = 0;
        orderDetailData.productId = productData!.id;
        orderDetailData.openQuantity = -1;
        orderDetailData.balance = 0;
        orderDetailData.shippedQuantity = 0;
        orderDetailData.accountOwnerCode = this.orderService.orderDto.billToDto.accountOwnerCode;
        orderDetailData.salespersonCode = '000';
        orderDetailData.classCode = productData!.classCode;
        orderDetailData.glCode = productData!.glCode;
        orderDetailData.isPhysical = productData!.isPhysical;
        orderDetailData.isElectronic = productData!.isElectronic;
        orderDetailData.orderQuantity = -1;
        orderDetailData.extendedPrice = orderDetailData.listPrice * -1;
        orderDetailData.productDescription = productData!.description;
        orderDetailData.isSelected = true;
        orderDetailData.itemEditable = false;
        orderDetailData.taxRate = this.selectedOrder.taxRate;
        this.orderService.orderDto.orderDetailDtos.unshift(orderDetailData);
      }

    } else if (this.zeroPricingReturnCodeSelected === true) {
      orderDetail.listPrice = 0;

    }
    else {
      orderDetail.listPrice = product.listPrice;
    }


    orderDetail.productDescription = product.description;
    orderDetail.discount = 0;
    orderDetail.discount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, product.id);
    orderDetail.isSelected = true;
    this.selectedLineItemsCount = this.selectedLineItemsCount + 1;
    orderDetail.productId = product.id;
    orderDetail.openQuantity = -1;
    orderDetail.balance = 0;
    orderDetail.shippedQuantity = 0;
    orderDetail.accountOwnerCode = this.orderService.orderDto.billToDto.accountOwnerCode;
    orderDetail.salespersonCode = '000';
    orderDetail.classCode = product.classCode;
    orderDetail.glCode = product.glCode;
    orderDetail.isPhysical = product.isPhysical;
    orderDetail.isElectronic = product.isElectronic;
    if (orderDetail.productCode !== '0070' && orderDetail.productCode !== '0070E') {
      this._pricingService.calculateUnitPrice(orderDetail);
      this._pricingService.calculateExtendedPrice(orderDetail);
    }

    orderDetail.orderQuantity = orderDetail.openQuantity + orderDetail.shippedQuantity;
    this.orderService.recalculateFields(this.orderService.orderDto);
    this.calculateReturns();

    if (orderDetail.productCode === '0010') {
      orderDetail.isSelected = false;
    }
    orderDetail.itemEditable = false;
  }
  closeNoItemDialog() {
    this.showNoItemExists.show = false;
  }

  onReturnQuantityChange(orderDetailDto: OrderDetailDto) {
    if (orderDetailDto.orderQuantity > 0) {
      orderDetailDto.orderQuantity *= -1;
      orderDetailDto.openQuantity = orderDetailDto.orderQuantity;
    }

    // If an existing orderDetail was selected
    if (orderDetailDto.originalOrderDetailDto) {
      const originalOrderQuantity = orderDetailDto.originalOrderDetailDto.orderQuantity;
      const originalTaxAmount = orderDetailDto.originalOrderDetailDto.taxAmount;

      // Revert back to shipped quantity if input validation error
      if (orderDetailDto.orderQuantity === 0 ||
        -orderDetailDto.orderQuantity > orderDetailDto.originalOrderDetailDto.shippedQuantity + orderDetailDto.originalOrderDetailDto.returnedQuantity) {
        orderDetailDto.orderQuantity = -orderDetailDto.originalOrderDetailDto.shippedQuantity - orderDetailDto.originalOrderDetailDto.returnedQuantity;
        orderDetailDto.openQuantity = -orderDetailDto.originalOrderDetailDto.shippedQuantity - orderDetailDto.originalOrderDetailDto.returnedQuantity;
      }

      orderDetailDto.taxAmount = this._pricingService.round(((orderDetailDto.orderQuantity / originalOrderQuantity) * originalTaxAmount), 2);
    }
    this.orderService.recalculateFields(this.orderService.orderDto);
    this.calculateReturns();
  }

  async onChangeDiscountOnPriceChangePopUp() {
    await this.verifyValidInputOnPopup();

    if (this.discount > 100) {
      this.discount = 100;
    }

    this.unitPrice = this._pricingService.round((this.selectedOrder.listPrice * (100 - this.discount) / 100), 2);
    this.extPrice = isNaN(this.quantity * this._pricingService.round((this.selectedOrder.listPrice * (100 - this.discount) / 100), 2)) ? 0 :
      this.quantity * this._pricingService.round((this.selectedOrder.listPrice * (100 - this.discount) / 100), 2);
  }

  async verifyValidInputOnPopup() {
    if (this.discount <= this.selectedOrder.discount) {
      this.isvalidInput = false;
      await this.swalAlert.alert('Discount should not be less than or equal to selected order discount');
    }
    else {
      this.isvalidInput = true;
      setTimeout(() => {
        this.priceChangeInputOkBtn.nativeElement.focus();
      }, 0);
    }
  }

  onChangeUnitPrice(orderDetail: OrderDetailDto) {
    if (orderDetail.productCode === '0070' || orderDetail.productCode === '0070E' || orderDetail.productCode === '0048' || orderDetail.productCode === '0060') {
      orderDetail.listPrice = orderDetail.unitPrice;
    }
    orderDetail.discount = this._pricingService.round((100 - orderDetail.unitPrice / orderDetail.listPrice * 100), 10);
    this._pricingService.calculateUnitPrice(orderDetail);
    this._pricingService.calculateExtendedPrice(orderDetail);
    this.orderService.recalculateFields(this.orderService.orderDto);
    this.calculateReturns();
    if (orderDetail.productCode === '0070' || orderDetail.productCode === '0070E') {
      this.updateNotesForPCOrPM(orderDetail);
    }
  }

  onChangeDiscount(orderDetail: OrderDetailDto) {
    this._pricingService.calculateUnitPrice(orderDetail);
    this._pricingService.calculateExtendedPrice(orderDetail);
    this.orderService.recalculateFields(this.orderService.orderDto);
    this.calculateReturns();
    if (orderDetail.productCode === '0070' || orderDetail.productCode === '0070E') {
      this.updateNotesForPCOrPM(orderDetail);
    }
  }
  updateNotesForPCOrPM(orderDetail: OrderDetailDto): void {
    const index = this.orderService.orderDto.orderNotes?.findIndex(o => o.priceChangeProductId === orderDetail.priceChangeProductId) ?? -1;
    if (this.orderService.orderDto.orderNotes && index > -1) {
      this.orderService.orderDto.orderNotes[index].noteText = this.orderService.orderDto.orderNotes[index].noteText.split('$')[0] + "$" + orderDetail.unitPrice.toFixed(2);
    }
  }
  async onChangeUnitPriceOnPriceChangePopUp() {
    this.discount = this._pricingService.round((100 - this.unitPrice / this.selectedOrder.listPrice * 100), 10);
    await this.verifyValidInputOnPopup();

    if (this.discount > 100) {
      this.discount = 100;
    }

    this.unitPrice = this._pricingService.round((this.selectedOrder.listPrice * (100 - this.discount) / 100), 2);
    this.extPrice = isNaN(this.quantity * this._pricingService.round((this.selectedOrder.listPrice * (100 - this.discount) / 100), 2)) ? 0 :
      this.quantity * this._pricingService.round((this.selectedOrder.listPrice * (100 - this.discount) / 100), 2);
  }

  async clickCheckBox(record: OrderDetailDto, event: any) {
    record.disableQuantity = record.isSelected === true ? false : true;
    const childDetails = this.orderService.orderDto.orderDetailDtos.filter(item => item.parentOrderDetailId === record.id);
    const vLineItems = this.orderService.orderDto.orderDetailDtos.filter(m => m.productCode === 'V' + record.productCode);
    for (const addon of childDetails) {     
      this.setAddonObjectOnRecordSelected(addon,record);
    }
    for (const vlineItem of vLineItems) {
      if (!vlineItem.isSelected && record.isSelected) {
        this.selectedLineItemsCount = this.selectedLineItemsCount + 1;
        vlineItem.isSelected = true;
        vlineItem.disableQuantity = false;
      }
    }
    this.selectedLineItemsCount = record.isSelected === true ? this.selectedLineItemsCount + 1 : this.selectedLineItemsCount - 1;
    this.selectedOrder = record;
    if (this.priceChangeReturnCodeSelected === true && this.selectedOrder.discount < 100) {
      this.priceChangeInputDialogModal.show = record.isSelected === true ? true : false;
      this.unitPrice = this.selectedOrder.unitPrice;
      this.discount = this.selectedOrder.discount;
      this.extPrice = this.selectedOrder.extendedPrice * -1;
      this.quantity = this.selectedOrder.orderQuantity * -1;
      this.isvalidInput = false;
    }
    else if (this.priceChangeReturnCodeSelected === true && this.selectedOrder.discount >= 100) {
      record.isSelected = false;
      event.target.checked = false;
      record.disableQuantity = true;
      await this.swalAlert.alert("This item has a 100% discount, no price change is possible.");
    }

    this.checkIfItemPastShipmentDate(record);
    this.calculateReturns();
  }
  checkIfItemPastShipmentDate(record: OrderDetailDto){
    if (record.originalOrderDetailDto?.shipDate != null)
    {
      const pastShipmentDate = new Date(new Date().setDate(new Date().getDate() - 60));
      const originalShipDate = new Date(record.originalOrderDetailDto?.shipDate.toString());
      if (record.isSelected && (originalShipDate < pastShipmentDate) && !this._authorizationService.hasResource("Allow60DayReturnAuthBypass") 
          && this.orderService.orderDto.billToDto.accountTypeCode != 'C' && this.orderService.orderDto.returnCode != 'BAD' 
          && this.orderService.orderDto.returnCode != 'BANKR')
        alert("The item you have selected is more than 60 days past shipment. A return authorization form will be required.");
    }
  }

  setAddonObjectOnRecordSelected(addon:any,record:OrderDetailDto){
    if (!addon.isSelected && record.isSelected) {
      this.selectedLineItemsCount = this.selectedLineItemsCount + 1;
      addon.isSelected = true;
      addon.disableQuantity = false;
    }
  }

  async returnQuantityValidateBR(): Promise<boolean> {
    if (!this.orderService.orderDto.originalOrderDto) {
      await this.swalAlert.alert("Data error getting original order.");
      return false;
    }

    for (const mainLineItem of this.orderService.orderDto.originalOrderDto.orderDetailDtos) {
      const selectedItems = this.orderService.orderDto.orderDetailDtos.filter(o => o.productCode === mainLineItem.productCode);
      let selectedItem = null;
      if(selectedItems){
        selectedItem = selectedItems[0];
      }
      if (selectedItem && selectedItem.hasOwnProperty('isSelected') && selectedItem.isSelected) {
        // Calculate the total main item quantity and the vLine item quantity to ensure the vLine items are returned before the main item.
        const returnedMainItem = this.orderService.orderDto.orderDetailDtos
          .filter(m => m.originalOrderDetailId == mainLineItem.id)[0];
    
        let totalMainQuantity = 0;
        const result = await this.checkIfAllVLineItemReturned(mainLineItem, this.orderService.orderDto.originalOrderDto);
        if(result === false){
          return false;
        }
        else{
          totalMainQuantity = result;
        }
        if(!await this.returnAddOnBr(this.orderService.orderDto.originalOrderDto, mainLineItem, returnedMainItem, totalMainQuantity)){
          return false;
        }
      }
    }

    return true;
  }
  async checkIfAllVLineItemReturned(mainLineItem: OrderDetailDto, originalOrderDto: OrderDto): Promise<any>{
    const returnedMainItem = this.orderService.orderDto.orderDetailDtos
      .filter(m => m.originalOrderDetailId == mainLineItem.id)[0];
    const returnedMainItemQuantity = this.orderService.orderDto.orderDetailDtos
      .filter(m => m.originalOrderDetailId == mainLineItem.id && m.isSelected)
      .reduce((sum, item) => sum + item.orderQuantity, 0);
    const originalVLineItemQuantity = originalOrderDto.orderDetailDtos
      .filter(m => m.productCode == 'V' + mainLineItem.productCode && m.orderDetailStatus !== 'V')
      .reduce((sum, item) => sum + item.orderQuantity + item.returnedQuantity, 0);
    const returnedVLineItemQuantity = this.orderService.orderDto.orderDetailDtos
      .filter(m => m.productCode == 'V' + mainLineItem.productCode && m.isSelected)
      .reduce((sum, item) => sum + item.orderQuantity, 0);
    const originalLicenseLineItemQuantity = originalOrderDto.orderDetailDtos
      .filter(m => m.parentOrderDetailId === mainLineItem.id && m.orderDetailStatus !== 'V' && m.isLicense)
      .reduce((sum, item) => sum + item.orderQuantity + item.returnedQuantity, 0);
    const returnedLicenseLineItemQuantity = this.orderService.orderDto.orderDetailDtos
      .filter(m => m.parentOrderDetailId === returnedMainItem?.id && m.orderDetailStatus !== 'V' && m.isLicense && m.isSelected)
      .reduce((sum, item) => sum + item.orderQuantity, 0);

    if (returnedMainItemQuantity < 0 && originalVLineItemQuantity + returnedVLineItemQuantity + originalLicenseLineItemQuantity + returnedLicenseLineItemQuantity !== 0) {
      await this.swalAlert.alert(`All V line items including license items need to be returned before the main item ${mainLineItem.productCode} is returned.`);
      return false;
    }
    // Ensure the each distinct total addon quantity is not greater than the total main item quantity including vLine items.
    return mainLineItem.orderQuantity + mainLineItem.returnedQuantity + returnedMainItemQuantity + originalVLineItemQuantity +
      returnedVLineItemQuantity + originalLicenseLineItemQuantity + returnedLicenseLineItemQuantity;
  }
  async returnAddOnBr(originalOrderDto: OrderDto, mainLineItem: OrderDetailDto, returnedMainItem: OrderDetailDto, totalMainQuantity: number): Promise<boolean> {
    const distictAddonProductCodes = [...new Set(originalOrderDto.orderDetailDtos
      .filter(item => item.parentOrderDetailId === mainLineItem.id && !item.isLicense).map(item => item.productCode))];
    for (const addonProductCode of distictAddonProductCodes) {
      const originalAddonQuantity = originalOrderDto.orderDetailDtos
        .filter(m => m.productCode == addonProductCode && m.parentOrderDetailId === mainLineItem.id && m.orderDetailStatus !== 'V')
        .reduce((sum, item) => sum + item.orderQuantity + item.returnedQuantity, 0);
      const returnedAddonQuantity = this.orderService.orderDto.orderDetailDtos
        .filter(m => m.productCode == addonProductCode && m.parentOrderDetailId === (returnedMainItem?.id ?? 0) && m.isSelected)
        .reduce((sum, item) => sum + item.orderQuantity, 0);
      const totalAddonQuantity = originalAddonQuantity + returnedAddonQuantity;
      if (!await this.checkAddOnsOnReturn(mainLineItem, totalMainQuantity, totalAddonQuantity, addonProductCode)) {
        return false;
      }  
    }
    return true;
  }
  async checkAddOnsOnReturn(mainLineItem: OrderDetailDto, totalMainQuantity: number, totalAddonQuantity: number, addonProductCode: string): Promise<boolean> {
    const isMaxParentQuantity = this.orderService.orderDto.orderDetailDtos
    .filter(m => m.productCode === addonProductCode && m.originalOrderDetailDto?.parentOrderDetailId === mainLineItem.id)[0]?.isMaxParentQuantity ?? true;
    if (totalMainQuantity === 0 && totalAddonQuantity > 0) {
      await this.swalAlert.alert(`All ${addonProductCode} must be returned when ${mainLineItem.productCode} is fully returned.`);
      return false;
    }
    if (isMaxParentQuantity && totalAddonQuantity > totalMainQuantity) {
      await this.swalAlert.alert(`${mainLineItem.productCode} plus additional licenses has quantity of ${totalMainQuantity}. 
      Adjust quantity of ${addonProductCode} to not exceed the total main item quantity.`);
      return false;
    }
    return true;
  }
  retrunEntryOrderDto() {
    this.orderService.orderDto.originalOrderId = this.orderService.orderDto.id;
    this.orderService.orderDto.id = 0;
    this.orderService.orderDto.orderType = 'R';

    for (const orderDetail of this.orderService.orderDto.orderDetailDtos.filter(o => o.parentOrderDetailId == null)) {
      orderDetail.orderId = 0;
      orderDetail.originalOrderDetailId = orderDetail.id;
      orderDetail.id = this.orderService.getNextOrderDetailId();
    }

    for (const orderDetail of this.orderService.orderDto.orderDetailDtos.filter(o => o.parentOrderDetailId != null)) {
      orderDetail.orderId = 0;
      orderDetail.originalOrderDetailId = orderDetail.id;
      orderDetail.id = this.orderService.getNextOrderDetailId();
      const id = this.orderService.orderDto.orderDetailDtos.filter(o => o.originalOrderDetailId === orderDetail.parentOrderDetailId)[0]?.id ?? null;
      orderDetail.parentOrderDetailId = id;
    }

    this.orderService.orderDto.orderNotes = null;
    this.orderService.orderDto.openAmount = 0;
    this.returnDetailActionComponent?.calculateTaxes(this.orderService.orderDto.orderDetailDtos.filter(o => o.isSelected === true));
  }

  onChangeReturnCode(event: any) {
    if (event.value === "") {
      this.orderService.orderDto.returnCode = event.value;
    }
    else {
      this.orderService.orderDto.returnCode = event.value.value;

    }
    this.orderService.orderDto.returnCode = this.orderService.orderDto.returnCode?.trim() ?? null;
    if (this.orderService.orderDto.returnCode === "NCD" || this.orderService.orderDto.returnCode === "WDS" || this.orderService.orderDto.returnCode === "ROS") {
      this.zeroPricingDialogModal.show = true;

      setTimeout(() => {
        this.zeroPricingOkBtn.nativeElement.focus();
      }, 0);
    }
    this.orderEntryErrorReasonDialog.show = this.orderService.orderDto.returnCode === 'OEE' ? true : false;

    if (this.orderService.orderDto.returnCode === "PC" || this.orderService.orderDto.returnCode === "EPC" || this.orderService.orderDto.returnCode === "PM") {
      this.setPriceChangeMessage();
      this.priceChangeDialogModal.show = true;
      if ((this.returnDtos.length > 0) && (this.orderService.orderDto.returnCode === "PC" || this.orderService.orderDto.returnCode === "EPC" || this.orderService.orderDto.returnCode === "PM")) {
        this.totalAmount = this.orderService.orderDto.shippedAmount + this.orderService.orderDto.openAmount + this.orderService.orderDto.totalTax;
        this.priceChangeDuplicateHeaderMessage = 'Another Price Change/Match exists for the original order'
        this.priceChangeDuplicateMessage = 'Do you want to proceed?';
        this.priceChangeDuplicateDialogModal.show = true;
      }

      setTimeout(() => {
        this.priceChangeOkBtn.nativeElement.focus();
      }, 0);
    }
    this.returnCodeValue = this.orderService.orderDto.returnCode;
    this.hasReturnCode();
    return;
  }

  setPriceChangeMessage(){
    if (this.orderService.orderDto.returnCode === "PC" || this.orderService.orderDto.returnCode === "EPC") {
      this.priceChangeMessage = 'Do you want to create a price change for this order?'
    };
    if (this.orderService.orderDto.returnCode === "PM") {
      this.priceChangeMessage = 'Do you want to create a price match for this order?'
    };
  }
  
  closeZeroPricingItemDialog() {
    this.zeroPricingDialogModal.show = false;
    this.orderService.orderDto.orderDetailDtos.forEach(x => {
      x.listPrice = 0;
      x.discount = 0;
      x.taxAmount = 0;
      this._pricingService.calculateExtendedPrice(x);
      this._pricingService.calculateUnitPrice(x);
    });
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
    this.disableReturnCode = true;
    this.zeroPricingReturnCodeSelected = true;
  }
  closePriceChangeItemDialog() {
    this.priceChangeDuplicateDialogModal.show = false;
    this.priceChangeDialogModal.show = false;
    this.disableReturnCode = true;
    this.priceChangeReturnCodeSelected = true;
  }
  navigateToReturnInquiry(returnId: number) {
    localStorage.removeItem('searchOperator');
    this._router.navigate([]).then(() => { window.open('/return/' + returnId, '_blank'); });
  }
  async closeInputDialog() {
    this.priceChangeInputDialogModal.show = false;
    this.priceChangeDialogModal.show = false;
    this.selectedOrder.disableQuantity = true;
    const orderDetail = this.orderService.orderDto.returnCode === 'PC' ? this.getOrderDetailDto('0070') : this.getOrderDetailDto('0070E');
    orderDetail.invoiceNumber = this.selectedOrder.invoiceNumber;
    orderDetail.priceChangeProductId = this.selectedOrder.id;
    orderDetail.originalOrderDetailId = this.orderService.orderDto.orderDetailDtos
      .filter(m => m.isSelected && m.originalOrderDetailDto && m.originalOrderDetailDto.id > 0)[0].originalOrderDetailDto?.id ?? null;
    this.selectedOrder.isSelected = false;
    this.selectedLineItemsCount = this.selectedLineItemsCount - 1;
    this.orderService.orderDto.orderDetailDtos.unshift(orderDetail);
    this.orderService.orderDto.orderDetailDtos.filter(item => item.parentOrderDetailId === this.selectedOrder.id
      || item.productCode === 'V' + this.selectedOrder.productCode)
      .forEach(item => {
        item.isSelected = false;
      });
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];

    await this.onChangeProductCode(orderDetail);
    this.addOrderNote(orderDetail.unitPrice);
    this.calculateReturns();
  }
  cancelReturnCode() {
    this.priceChangeDialogModal.show = false;
    this.priceChangeDuplicateDialogModal.show = false;
    this.orderService.orderDto.returnCode = "";
    this.orderService.orderDto.returnCodeBinding = { id: '', label: '', value: '' };
  }

  getOrderDetailDto(productCode: string): OrderDetailDto {
    const orderDetail = new OrderDetailDto();
    orderDetail.orderDetailStatus = ' ';
    orderDetail.itemEditable = false;
    orderDetail.disableQuantity = true;
    orderDetail.productCode = productCode;
    orderDetail.requiredDate = new Date();
    orderDetail.enteredDate = new Date();
    orderDetail.id = this.orderService.getNextOrderDetailId();
    return orderDetail;
  }

  calculateDiscount(): number {
    const data = ((this.selectedOrder.taxAmount * 100) / this.selectedOrder.extendedPrice);
    return Number(data.toFixed(2));
  }
  onOrderStatusChange(status: string): void {
    this.orderService.orderDto.orderStatus = status;
  }

  addOrderNote(unitPrice: number): void {
    const systemNote = new OrderNoteDto();
    systemNote.id = this.nextNoteId;
    this.nextNoteId--;
    systemNote.createdDate = new Date();
    systemNote.userCode = this._configService.getMyUserCode();
    systemNote.userName = this._configService.getMyFullName();
    systemNote.orderId = this.orderService.orderDto.id;
    systemNote.noteType = "RT";
    systemNote.noteText = this.orderService.orderDto.returnCode + " done on the line item " + this.selectedOrder.productCode + " for $" + (unitPrice.toFixed(2));
    systemNote.priceChangeProductId = this.selectedOrder.id;
    if (this.orderService.orderDto.orderNotes == null) {
      const orderNotes: OrderNoteDto[] = [];
      orderNotes.push(systemNote)
      this.orderService.orderDto.orderNotes = orderNotes;
    }
    else {
      this.orderService.orderDto.orderNotes.push(systemNote);
    }
  }

  calculateReturns() {
    this.orderService.orderDto.openAmount = 0;
    if (this.orderService.orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V' && o.productCode !== '0010' && o.isSelected === true).length > 0) {
      this.orderService.orderDto.openAmount = this.orderService.orderDto.orderDetailDtos
        .filter(o => o.orderDetailStatus !== 'V' && o.productCode !== '0010' && o.productCode !== '0048' && o.isSelected === true)
        .map(o => o.openQuantity * this._pricingService.round(o.listPrice * (100 - o.discount) / 100, 2)).reduce((a, b) => a + b);
    }
    this.returnDetailActionComponent?.calculateTaxes(this.orderService.orderDto.orderDetailDtos.filter(o => o.isSelected === true));
  }

  closePriceChangeInputDialog(): void {
    this.priceChangeInputDialogModal.show = false;
  }
}
