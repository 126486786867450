<div class="container-fluid px-0 bg-lightgrey">
    <div class="row gx-0">
      <div class="col-12">
        <div class="container-fluid px-4 my-3">
          <div class="row bg-light gx-0 mt-4">
            <div class="col">
              <div class="container-fluid mx-4 my-3">
                <div class="row">
                  <div class="col">
                    <h5>Order Entry:</h5>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col d-flex">
                    <label class="align-self-center" uitkLabel>Account ID</label>
                    <uitk-form-field>
                      <input style="width: 200px" uitkInput id="account-id-textbox" class="textbox mx-3" (keydown.enter)="enterOrder()" type="text" [(ngModel)]="accountId" #accountIdInput pattern="^[0-9]+$"/>
                    </uitk-form-field>
                    <button style="width: 120px" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="enterOrder()">
                      Enter Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  