import { Component, Input } from '@angular/core';
import { AuthorizationDto } from '../../../dtos/authorization-dto.model';

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.css']
})

export class AdditionalInformationComponent {
  
  tableHeader = [
    { name: 'Item #', id: 'producNumber' },
    { name: 'Description', id: 'description' },
    { name: 'Returned Qty', id: 'returnedQuantity' },
    { name: 'List Price', id: 'listPrice' },
    { name: 'Disc %', id: 'discount' },
    { name: 'Unit Price', id: 'unitPrice' },
    { name: 'Ext Price', id: 'extendedPrice' },
    { name: 'Ship Date', id: 'shipDate' }
   
  ];

 @Input() authorizationDto =new AuthorizationDto();   

 

}
