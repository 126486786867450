import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { Mode } from '../enums/mode.enum';
import { CheckRenewalDto } from '../dtos/check-renewal-dto.model';
import { PriorRenewalDto } from '../dtos/prior-renewal-dto.model';
import { SubscriptionDto } from '../dtos/subscription-dto.model';
import { SubscriptionEditDto } from '../dtos/subscription-edit-dto.model';
import { SubscriptionSearchResult } from '../dtos/subscription-search-result.model';
import { SubscriptionSearchResultsDto } from '../dtos/subscription-search-results-dto.model';
import { ConfigService } from './config.service';
import { ModeService } from './mode.service';
import { SwalAlert } from '../helpers/alert';

@Injectable({
    providedIn: 'root'
})

export class SubscriptionService {
    gotSubscriptionSearchResultsEmitter = new Subject<SubscriptionSearchResultsDto>();
    error = new Subject();
    subscriptions: SubscriptionSearchResultsDto | null = null;
    filterObj: any;
    subscriptionHistoryDto: SubscriptionSearchResult[] = [];
    subscriptioneditDto: SubscriptionEditDto = new SubscriptionEditDto();
    subscriptionHistoryIsReady = new Subject();
    subscriptioneditDtoIsReady = new Subject<SubscriptionEditDto>();
    subscriptionIsSavedTemporary: boolean = false;
    private readonly _httpClient: HttpClient;
    private readonly _modeService: ModeService;
    private readonly _configService: ConfigService;
    subscriptionHistoryCache!: SubscriptionSearchResult[] | null;
    subscriptionHistoryCachedObservable!: Observable<SubscriptionSearchResult[]> | null;
    subscriptionDetailsCache!: SubscriptionEditDto | null;
    subscriptionDetailsCachedObservable!: Observable<SubscriptionEditDto> | null;
    subscriptionsForRenewalCache!: SubscriptionDto[] | null;
    subscriptionsForRenewalCachedObservable!: Observable<SubscriptionDto[]> | null;
    swalAlert = new SwalAlert();

    constructor(httpClient: HttpClient, modeService: ModeService, configService: ConfigService) {
        this._httpClient = httpClient;
        this._modeService = modeService;
        this._configService = configService;
    }

    async searchSubscriptions(errorMode: Mode, filtersObj: any, overrideWait: boolean = false): Promise<void> {
        return new Promise(resolve => {
            this._modeService.mode = overrideWait ? Mode.View : Mode.SubscriptionWait;
            this._httpClient.post<SubscriptionSearchResultsDto>(`${this._configService.apiUrl}/Subscription/SearchSubscriptions`, filtersObj)
                .subscribe((data: SubscriptionSearchResultsDto) => {
                this.subscriptions = data;
                this.filterObj = filtersObj;
                this._modeService.mode = Mode.View;
                this.gotSubscriptionSearchResultsEmitter.next(data);
                resolve();
            }, async (error: Error) => {
                console.log(error);
                this._modeService.mode = errorMode;
                if(error !== null && error.message !== null && error.message.indexOf("Cannot open database")> -1){
                    await this.swalAlert.alert('Order Management is unavailable. Please try again in a few minutes. If the issue continues, please alert System Administrators. Thanks.');
                }
                else
                {
                    await this.swalAlert.alert('An error occurred while getting a list of subscriptions.  Please try again.');
                }
                resolve();
            });
        });

    }


    getSubscriptionHistory(accountId: number, subscriptionNumber: number): void {
        this._modeService.mode = Mode.Wait;
        let observable: Observable<SubscriptionSearchResult[]>;

        if (this.subscriptionHistoryCache) {
            observable = of(this.subscriptionHistoryCache);
        }
        else if (this.subscriptionHistoryCachedObservable) {
            observable = this.subscriptionHistoryCachedObservable;
        }
        else {
            this.subscriptionHistoryCachedObservable = this._httpClient
                .get<SubscriptionSearchResult[]>
                (`${this._configService.apiUrl}/Subscription/GetSubscriptionHistory?accountId=${accountId}&subscriptionNumber=${subscriptionNumber}`)
                .pipe(
                    tap((response: SubscriptionSearchResult[]) => {
                        this.subscriptionHistoryCache = response;
                    }),
                    share(),
                    finalize(() => {
                        this.subscriptionHistoryCache = null;
                        this.subscriptionHistoryCachedObservable = null;
                    })
                );

            observable = this.subscriptionHistoryCachedObservable;
        }

        observable.subscribe((data: SubscriptionSearchResult[]) => {
            this.subscriptionHistoryDto = data;

            this.subscriptionHistoryDto.forEach(element => {
                let result = new Date(element.startDate);
                element.expirationDate = new Date(result.setMonth(result.getMonth() + element.term));
            });

            this._modeService.mode = Mode.View;
            this.subscriptionHistoryIsReady.next();
        }, async (error: any) => {
            console.log(error);
            this._modeService.mode = Mode.View;
            await this.swalAlert.alert('An error occurred while getting subscription history details. Please try again.');
        });
    }

    getSubscriptionDetails(subscriptionID: number): void {
        this._modeService.mode = Mode.Wait;
        let observable: Observable<SubscriptionEditDto>;

        if (this.subscriptionDetailsCache) {
            observable = of(this.subscriptionDetailsCache);
        }
        else if (this.subscriptionDetailsCachedObservable) {
            observable = this.subscriptionDetailsCachedObservable;
        }
        else {
            this.subscriptionDetailsCachedObservable = this._httpClient
                .get<SubscriptionEditDto>(`${this._configService.apiUrl}/Subscription/GetSubscription?subscriptionId=${subscriptionID}`)
                .pipe(
                    tap((response: SubscriptionEditDto) => {
                        this.subscriptionDetailsCache = response;
                    }),
                    share(),
                    finalize(() => {
                        this.subscriptionDetailsCache = null;
                        this.subscriptionDetailsCachedObservable = null;
                    })
                );

            observable = this.subscriptionDetailsCachedObservable;
        }

        observable.subscribe((data: SubscriptionEditDto) => {
            this.subscriptioneditDto = data;
            this._modeService.mode = Mode.View;
            this.subscriptioneditDtoIsReady.next();
        }, async (error: any) => {
            console.log(error);
            this._modeService.mode = Mode.View;
            await this.swalAlert.alert('An error occurred while getting subscription details. Please try again.');
        });
    }

    saveSubscriptionDetails(subscription: SubscriptionEditDto): void {
        this._modeService.mode = Mode.Wait;

        this._httpClient.put<SubscriptionEditDto>(`${this._configService.apiUrl}/Subscription/SaveSubscription`, subscription).subscribe(data => {
            this.subscriptioneditDto = data;
        }, (error: any) => {
            console.log(error);
            this._modeService.mode = Mode.Edit;
            this.error.next();
        });
    }

    async verifySubscriptionPriorRenewal(checkRenewalDtos: CheckRenewalDto[]): Promise<PriorRenewalDto[] | null> {
        return new Promise(resolve => {
            const waitFor = 'Duplicate checking previously renewed subscription';
            this._modeService.addToWaitList(waitFor);

            this._httpClient.post<PriorRenewalDto[]>(`${this._configService.apiUrl}/Subscription/VerifySubscriptionPriorRenewal`, checkRenewalDtos).subscribe({
                next: data => {
                    this._modeService.removeFromWaitList(waitFor);
                    resolve(data);
                }, 
                error: async e => {
                    this._modeService.removeFromWaitList(waitFor);
                    console.log(e);
                    if(e !== null && e.message !== null && e.message.indexOf("Cannot open database")> -1){
                        await this.swalAlert.alert('Order Management is unavailable. Please try again in a few minutes. If the issue continues, please alert System Administrators. Thanks.');
                    }
                    else
                    {
                        await this.swalAlert.alert('An error occurred while getting a list of subscriptions.  Please try again.');
                    }
                    resolve(null);
                }
            });
        });
    }


    getSubscriptionsForRenewal(contactId: number, productId: number, lastYearOrderId: number | null): Observable<SubscriptionDto[]> {
        let observable: Observable<SubscriptionDto[]>;

        if (this.subscriptionsForRenewalCache) {
            observable = of(this.subscriptionsForRenewalCache);
        }
        else if (this.subscriptionsForRenewalCachedObservable) {
            observable = this.subscriptionsForRenewalCachedObservable;
        }
        else {
            this.subscriptionsForRenewalCachedObservable = this._httpClient
                .get<SubscriptionDto[]>(`${this._configService.apiUrl}/Subscription/GetSubscriptionsForRenewal?contactId=${contactId}&productId=${productId}&lastYearOrderId=${lastYearOrderId ?? ''}`)
                .pipe(
                    tap((response: SubscriptionDto[]) => {
                        this.subscriptionsForRenewalCache = response;
                    }),
                    share(),
                    finalize(() => {
                        this.subscriptionsForRenewalCache = null;
                        this.subscriptionsForRenewalCachedObservable = null;
                    })
                );

            observable = this.subscriptionsForRenewalCachedObservable;
        }

        return observable;
    }
}
