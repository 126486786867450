import { Component, OnInit,EventEmitter,Output,NgModule } from '@angular/core';
import {UntypedFormGroup,UntypedFormControl} from  '@angular/forms';
import {UITKTextAreaModule} from '@uitk/angular';
import { ConfigService } from 'src/app/services/config.service';
@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.css']
})
export class AddNoteComponent implements OnInit {
  addNoteDialogModal = {
    show: false,
  };
  maxTextLimit=255;
  myCharRemaining = 'characters remaining';
  remainingCount: number=0;
  userName:any='';
  @Output() closeEvent = new EventEmitter<any>();
  constructor(
    private readonly configService: ConfigService
  ) { }
  profileForm = new UntypedFormGroup({
    textarea: new UntypedFormControl(''),
  });
  ngOnInit(): void {
    this.addNoteDialogModal.show= true;
    this.userName = this.configService.getMyFullName();
  }
  closeAddNoteDialog() {
    this.addNoteDialogModal.show = false;
    this.closeEvent.emit();
  }
  onCountChange(count: number) {
      this.remainingCount = count;
  }
}
