import { Component, OnDestroy, OnInit } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { BillToDto } from 'src/app/dtos/bill-to-dto.model';
import { Mode } from '../../../enums/mode.enum';
import { BillToService } from '../../../services/bill-to.service';
import { ConfigService } from '../../../services/config.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';

@Component({
  selector: 'app-order-header',
  templateUrl: './order-header.component.html',
  styleUrls: ['./order-header.component.css']
})

export class OrderHeaderComponent implements OnInit, OnDestroy {
  private readonly _configService: ConfigService;
  private readonly _billToService: BillToService;
  private configIsReadySubscription!: Subscription;
  //orderTypeList: IUITKSelectItemProps[] = [];
  //orderType!: IUITKSelectItemProps;
  createdDate = new Date();
  orderStatus = 'WIP';
  webReferenceRegexPattern = '^\w+$';
  showSalesPersonLookupPopup = { show: false };
  orderHeaderIsReadySubscription: Subscription | undefined;
  billToIsReadySubscription: Subscription | undefined;
  accountFieldsSingle: BillToDto = new BillToDto();
  salespersonemail:string | undefined
  Mode = Mode;
  usersList: string[] = [];
  salesPersonEmail='';
  salesPerson = '';
  salespersonCount: string[] = [];

  constructor(configService: ConfigService,
    public orderService: OrderService,
    billToService: BillToService,
    public modeService: ModeService,
  ) {
    this._configService = configService;
    this._billToService = billToService;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.orderService.orderDto.enteredBy = this._configService.getMyMSId().toUpperCase().replace('MS\\', '');
      if (localStorage.getItem('isByPass') === 'true') {
        this.orderService.orderDto.enteredByBinding = 'Automated User';
      }
      else {
        if (this.orderService.orderDto.enteredByBinding === null || this.orderService.orderDto.enteredByBinding === undefined) {
          this.orderService.orderDto.enteredByBinding = this._configService.getMyFullName().toUpperCase();
          const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
          this.orderService.orderDto.enteredByBindingEmail=currentUser.profile.email;
        }
      }
    }, 500);

    this.configIsReadySubscription = this._configService.configIsReady.subscribe(() => {
      this.orderService.orderTypeList = this._configService.getPickList('ORDERTYPE').filter(o => o.label === 'Order' || o.label === 'Quote');
      this.getOrderType();
      
      this.orderHeaderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
        if (this.orderService.orderDto.id != null && this.orderService.orderDto.id > 0) {
          this.orderStatus = this.orderService.orderDto.orderStatusBinding.label;
          this.orderService.orderDto.enteredByBinding = this._configService.getuserNameUserLogin(this.orderService.orderDto.enteredBy); 
          this.orderService.orderDto.enteredByBindingEmail=this._configService.getUserEmailFromUserLogin(this.orderService.orderDto.enteredBy);
          this.createdDate = this.orderService.orderDto.enteredDate;
          this.orderService.recalculateFields(this.orderService.orderDto);
          this.getOrderType();
        }
      });
    });

    this.billToIsReadySubscription = this._billToService.billToIsReady.subscribe(() => {
      this.orderService.orderDto.accountOwnerCode = this._billToService.billToDto.accountOwnerCode ?? this._configService.defaultAccountOwnerCode;
      this.orderService.recalculateFields(this.orderService.orderDto)
    });
  }

  onChangeOrderType(event: any): void {
    this.orderService.orderTypeChangedFromQuoteToOrder = false;
    if (this.orderService.orderTypeBackUp === 'Q' && event.value === ' ') {
      this.orderService.orderTypeChangedFromQuoteToOrder = true;
      this.orderService.orderDto.enteredBy = this._configService.getMyMSId().toUpperCase().replace('MS\\', '');
    }
    this.orderService.orderDto.orderType = event.value;
  }

  showSalesPersonLookUp(): void {
    if (this.orderService.salesSearchKeyword.trim() == '') {
      this.orderService.searchsalesPersonName = '';
      this.showSalesPersonLookupPopup.show = true;
    }
    else
    {
    this.orderService.searchsalesPersonName = this.orderService.salesSearchKeyword;
     this.onsalesPersonChangeClick(this.orderService.salesSearchKeyword);
     this.orderService.searchsalesPersonName = this.orderService.salesSearchKeyword;
    }
  }

  bindSelectedSalesPerson(selectedSalesPerson: any): void {
    this.orderService.orderDto.accountOwnerName = selectedSalesPerson;
  }

  getOrderType(): void {
    if (this.orderService.orderDto.orderType != null) {
      this.orderService.orderDto.orderTypeBinding = this.orderService.orderTypeList.filter(s => s.value === this.orderService.orderDto.orderType)[0] ?? { id: 'Select', label: 'Select', value: 'Select' };
    }
  }
  onSalesPersonBlur() {
    setTimeout(() => {
      if (document.getElementById(`accountId`)?.attributes.getNamedItem('disabled') !== null && document.getElementById(`contactId`)) {
        document.getElementById(`contactId`)?.focus();
      }
    });
  }
  onWebReferenceBlur() {
    setTimeout(() => {
      if (document.getElementById(`sales-person-textbox`)) {
        document.getElementById(`sales-person-textbox`)?.focus();
      }
    });
  }

  onsalesPersonNameChange(evt:any){
    this.orderService.salesSearchKeyword = evt.target.value;
    this.salespersonemail= this._configService.getUserEmailFromUserCode(this.orderService.orderDto.billToDto.accountOwnerCode);    
  }
  async onAccountNameEnter(evt: any) {
    this.orderService.salesSearchKeyword = evt.target.value;
    this.showSalesPersonLookUp();
  }
  async onsalesPersonChangeClick(evt:any)
  {
    this.usersList = this._configService.getPickList('SALESPERSON').map(u => u.label);
    this.orderService.searchsalesPersonName = this.orderService.salesSearchKeyword;
    const salesPersons = this.usersList.find(u => u == this.orderService.selectedsalesPersonName);
    if (salesPersons !== undefined) {
      this.showSalesPersonLookupPopup.show = false;
    }
    else {
      this.showSalesPersonLookupPopup.show = true;
    }
  }


  ngOnDestroy(): void {
    this.configIsReadySubscription.unsubscribe();
  }
}
