import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { BillToService } from 'src/app/services/bill-to.service';
import { Mode } from '../../../enums/mode.enum';
import { ShippingDto } from '../../../dtos/shipping-dto.model';
import { AuthorizationService } from '../../../services/authorization.service';
import { ConfigService } from '../../../services/config.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';
import { TokenizeService } from '../../../services/tokenize.service';
import { SwalAlert } from '../../../helpers/alert';
import { ApprovalService } from '../../../services/approval.service';
@Component({
  selector: 'app-order-summary-section',
  templateUrl: './order-summary-section.component.html',
  styleUrls: ['./order-summary-section.component.css']
})

export class OrderSummarySectionComponent implements OnInit {
  private readonly _configService: ConfigService;
  private readonly _tokenService: TokenizeService;
  private readonly _authorizationService: AuthorizationService;
  private readonly _billToService: BillToService;
  private readonly _approvalService: ApprovalService;
  @Output() changedShipVia = new EventEmitter<any>();
  @Output() addCheckOrCashRow = new EventEmitter<any>();
  Mode = Mode;
  methodList: IUITKSelectItemProps[] = []
  ccAuth: string = '';
  termsList: IUITKSelectItemProps[] = []
  termsListWithoutCreditCard: IUITKSelectItemProps[] = []
  paymentPlansList: IUITKSelectItemProps[] = []
  // shippingMethodsDeliveryCode: IUITKSelectItemProps[] = []
  packingSlipNotesDialog = { show: false };
  showEmailNotesDialog = { show : false};
  showAttachmentsDialog = { show : false };
  tokenValue = "";
  expirationDate = "";
  expDatePattern = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;
  showExpDatePatternError = false;
  isPastDate: boolean = false;
  shippingMethods: ShippingDto[] = [];
  configIsReadySubscription: Subscription | undefined;
  isCCAuthDisabled = true;
  shippingMethodsDeliveryCode: IUITKSelectItemProps[] = []
  orderPaymentPlanBinding: string = '';
  orderPaymentPlan: string = '';
  orderPaymentMethodBinding: string | null = '';
  creditCardRequired = false;
  amaKeyButtonsubscription: Subscription | undefined;
  billToIsReadySubscription: Subscription | undefined;
  disableAmaKeyButton = true;
  disablePaymentTerms = false;
  modeDisabled = true;
  orderIsReadySubscription: Subscription | undefined;
  swalAlert = new SwalAlert();

  constructor(
    public orderService: OrderService,
    configService: ConfigService,
    tokenService: TokenizeService,
    authorizationService: AuthorizationService,
    public modeService: ModeService,
    billToService: BillToService,
    approvalService: ApprovalService) {
    this._configService = configService;
    this._tokenService = tokenService;
    this._authorizationService = authorizationService;
    this._billToService = billToService;
    this._approvalService = approvalService;
  }

  ngOnInit(): void {
    this.billToIsReadySubscription = this._billToService.billToIsReady.subscribe(() => {
      if (this._billToService.billToDto.defaultPaymentTerms) {
        this.orderService.orderDto.paymentTerms = this._billToService.billToDto.defaultPaymentTerms;
      }
    });
    this.configIsReadySubscription = this._configService.configIsReady.subscribe(() => {
      if (!this._configService.reference) {
        throw new Error('References are not loaded.');
      }

      this.shippingMethods = this._configService.reference.shippingDtos;
      this.methodList = this._configService.getPickList("PMETH");
      this.paymentPlansList = this._configService.getPickList("PAYMNTPLAN");
      this.termsList = this._configService.getPickList("PTERMS").sort((a, b) => {
        return parseInt(a.value.slice(3, 6)) < parseInt(b.value.slice(3, 6)) ? -1 : 1;
      });
      this.termsListWithoutCreditCard = this._configService.getPickList("PTERMS")
        .filter(x => x.value !== 'CC').sort((a, b) => {
          return parseInt(a.value.slice(3, 6)) < parseInt(b.value.slice(3, 6)) ? -1 : 1;
        });
      
      this.setDisablePicklistOptions();      
    });
    
    this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
      this.modeDisabled = (this.orderService.orderDto.paymentMethod === 'V' || this.orderService.orderDto.paymentMethod === 'M'
        || this.orderService.orderDto.paymentMethod === 'D' || this.orderService.orderDto.paymentMethod === 'X') ? false : true;
      this.amaKeyButtonsubscription = this.orderService.getAmaKeyButtonMode().subscribe(isDisabled => this.disableAmaKeyButton = isDisabled);
      this.orderService.paymentMethodBR();
      this.orderService.paymentPlanOrderEntryBR();
      this.disablePaymentTerms = !this._authorizationService.hasResource("OrderFooterTermsDropDown");

      if (this.orderService.orderDto.ccExpirationDate && this.orderService.orderDto.ccExpirationDate !== '') {
        this.expirationDate = this.orderService.orderDto.ccExpirationDate.substring(0, 2) + '/' + this.orderService.orderDto.ccExpirationDate.substring(2, 5);
      }

      if (!this.orderService.orderDto.ccAuthorizationCode) {
        this.ccAuth = 'Not Processed';
      }  
    });
    
    this._configService.loadConfigurations();
  }

  setDisablePicklistOptions(){
    if (!this._authorizationService.hasResource("OrderInquiryPaymentMethodSOrN")) {
      this._configService.disablePickListOption(this.methodList, "S");
      this._configService.disablePickListOption(this.methodList, "N");
    }

    if (!this._authorizationService.hasResource("OrderInquiryPaymentMethodC")) {
      this._configService.disablePickListOption(this.methodList, "C");
    }

    if (!this._authorizationService.hasResource("OrderInquiryPaymentMethodCreditCard")) {
      this._configService.disablePickListOption(this.methodList, "V");
      this._configService.disablePickListOption(this.methodList, "M");
      this._configService.disablePickListOption(this.methodList, "D");
      this._configService.disablePickListOption(this.methodList, "X");
    }

    if (!this._authorizationService.hasResource("OrderInquiryPaymentMethodI")) {
      this._configService.disablePickListOption(this.methodList, "I");
    }
  }

  onShipViaChange(selected: string) {
    if (this.orderService.orderDto.billToDto.id === 0 || this.orderService.orderDto.billToDto.id === null) {      
      this.swalAlert.alert('Account information details should be populated before selecting ship via');
      this.orderService.orderDto.shipmentMethod = '';
      selected = '';

      setTimeout(() => {
        (document.getElementById("accountId") as HTMLElement).focus();
      }, 500);

      return;
    }

    setTimeout(() => this.changedShipVia.emit());
  }

  async onChangePaymentPlanValue() {
    await this.callCashReceiptBR();
    await this.orderService.paymentPlanBR();
  }

  async callCashReceiptBR() {
    const cash = this.orderService.cashReceiptBR('', this.orderService.orderDto.paymentMethod, this.orderPaymentPlanBinding);
    if (cash === true) {
      await this.swalAlert.alert('Cash Receipts are not allowed on a payment plan');
    }
  }
  expDateChange(expirationDate: string) {
    if (expirationDate !== "" && expirationDate !== null && !new RegExp(this.expDatePattern).test(expirationDate)){
      this.showExpDatePatternError = true;
    }else{
      this.showExpDatePatternError = false;
    }     
    if (expirationDate !== "" && expirationDate !== null && expirationDate.length === 4) {
      this.isPastDate = false;
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = parseInt(new Date().getFullYear().toString().substring(2, 4));
      const expYear = parseInt(expirationDate.substring(2, 4));
      const expMonth = parseInt(expirationDate.substring(0, 2));
      if (expYear < currentYear) {
        this.isPastDate = true;
      }
      else if (expYear === currentYear && expMonth < currentMonth) {
        this.isPastDate = true;
      }
      else {
        this.isPastDate = false;
      }
    }
    else{
      this.isPastDate = false;
    }      
  }
  onChangePaymentTerm(): void{
    if (this.orderService.orderDto.billToDto.id === 0 || this.orderService.orderDto.billToDto.id === null) {
      this.swalAlert.alert('Account information details should be populated before selecting payment terms');

      setTimeout(() => {
        (document.getElementById("accountId") as HTMLElement).focus();
      });

      return;
    }

    this.orderService.orderPaymentTerms = this.orderService.orderDto.paymentTerms;
  }

  checkIfExceptionOnCreditCard(){
    if (this.orderService.orderDto.paymentMethod==='X' ||  this.orderService.orderDto.paymentMethod==='D' || this.orderService.orderDto.paymentMethod==='M' || this.orderService.orderDto.paymentMethod==='V')
    { 
      if (this.orderService.orderDto.exceptionReasonCode==='P')
      {
        this.orderService.orderDto.orderType=' ';
        this.orderService.orderDto.orderTypeBinding =this.orderService.orderTypeList.filter(s => s.value === this.orderService.orderDto.orderType)[0] ?? { id: 'Select', label: 'Select', value: 'Select' };
      }
     
      
    }
  }
  async autoConvertToQuote() : Promise<void> {
    if (this.orderService.orderDto.orderDetailDtos.some(item => item.productCode === '0010' && item.orderDetailStatus === ' '  && item.id > 0)) {
      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.orderService.orderDto.totalQuantity = this.orderService.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus !== 'V')
      .map(o => o.orderQuantity).reduce((prev, next) => prev + next, 0);
      await this.swalAlert.alert('A deposit has already been entered on the order.  Use the config set to add or adjust deposits.');
      this.orderService.orderDto.paymentMethod = this.orderService.orderDtoBackup.paymentMethod;
      return;
    }
    this.addCheckOrCashRow.emit();
    if(this.orderService.orderDto.orderType === 'Q'){
      this.orderService.orderDto.orderType=' ';
      this.orderService.orderDto.orderTypeBinding = this.orderService.orderTypeList.filter(x=>x.id===' ')[0];
      this.orderService.orderTypeChangedFromQuoteToOrder = true;
    }
  }
  async onChangePaymentMethod(): Promise<void> {
    if (this.orderService.orderDto.billToDto.id === 0 || this.orderService.orderDto.billToDto.id === null) {
      this.swalAlert.alert('Account information details should be populated before selecting payment method');

      setTimeout(() => {
        (document.getElementById("accountId") as HTMLElement).focus();
      });

      return;
    }
    this.checkIfExceptionOnCreditCard();
    if ((this.orderService.orderDto.paymentPlan === "Monthly" || this.orderService.orderDto.paymentPlan === "Quarterly")
      && (this.orderService.orderDto.paymentMethod === "C" || this.orderService.orderDto.paymentMethod === "N")) {
      await this.orderService.paymentPlanBR();
    }
    if (this.orderService.orderDto.paymentMethod === 'V' || this.orderService.orderDto.paymentMethod === 'M'
      || this.orderService.orderDto.paymentMethod === 'D' || this.orderService.orderDto.paymentMethod === 'X') {
      this.orderService.orderDto.paymentTerms = 'Credit Card';
      this.orderService.orderDto.ccAuthorizationCode = 'N';
      this.ccAuth = "Not Processed";
      this.modeDisabled = false;
    }
    else {

      if (this.orderService.orderDto.paymentTerms === 'Credit Card') {
        this.setCreditCardPaymentTerms();          
      }
      this.orderService.orderDto.ccToken = null;
      this.orderService.orderDto.ccExpirationDate = null;
      this.expirationDate = '';
      this.orderService.orderDto.ccLastFour = null;
      this.orderService.orderDto.ccAuthorizationCode = null;
      this.ccAuth = "";
      this.modeDisabled = true;
    }
    if (this.orderService.orderDto.paymentMethod === 'C') {
      await this.autoConvertToQuote();
    }
  }

  setCreditCardPaymentTerms(){
    if (this.orderService.orderPaymentTerms){
      this.orderService.orderDto.paymentTerms = this.orderService.orderPaymentTerms;
    } else{
      this.orderService.orderDto.paymentTerms = this.orderService.orderDto.billToDto.defaultPaymentTerms;
    }
  }
  
  expirationdate() {
    if (this.expirationDate.includes('/')) {
      this.orderService.orderDto.ccExpirationDate = this.expirationDate.replace("/", "");
    }
    else {
      if (this.expirationDate === '') {
        this.orderService.orderDto.ccExpirationDate = null;
      } else {
        this.orderService.orderDto.ccExpirationDate = this.expirationDate;
        this.expirationDate = this.orderService.orderDto.ccExpirationDate.substring(0, 2) + '/' + this.orderService.orderDto.ccExpirationDate.substring(2, 5);
      }
    }
  }

  async onNgModelChange(encryptedCCString: string): Promise<boolean> {
    if ((encryptedCCString.length === 157 || encryptedCCString.length === 158 || encryptedCCString.length === 177 || encryptedCCString.length === 178)
      && encryptedCCString.endsWith('03')) {
      const equalSign = encryptedCCString.indexOf('=');
      this.orderService.orderDto.ccLastFour = encryptedCCString.substring(equalSign - 4, equalSign);
      const expirationDate = encryptedCCString.substring(equalSign + 1, equalSign + 5);
      const firstSet = encryptedCCString.split("?")[0].split("=");
      const secondSet = firstSet[0].split(";");
      const ccNumber = secondSet[1];

      switch (ccNumber.substring(0, 1)) {
        case "3":
          this.orderService.orderDto.paymentMethod = this.methodList.find(pmeth => pmeth.label === "American Express")?.value!;
          break;
        case "4":
          this.orderService.orderDto.paymentMethod = this.methodList.find(pmeth => pmeth.label === "Visa")?.value!;
          break;
        case "5":
          this.orderService.orderDto.paymentMethod = this.methodList.find(pmeth => pmeth.label === "MasterCard")?.value!;
          break;
        case "6":
          this.orderService.orderDto.paymentMethod = this.methodList.find(pmeth => pmeth.label === "Discover")?.value!;
          break;
        default:
          break;
      }
      await this.onChangePaymentMethod();
      setTimeout(() => {
        this.orderService.orderDto.ccToken = 'Processing, please wait ...';
      });
      setTimeout(() => {
        this._tokenService.getCcToken({ encryptedData: encryptedCCString }).subscribe((data) => {
          if (data.token !== null) {
            this.orderService.orderDto.ccToken = data.token;
            this.orderService.orderDto.ccExpirationDate = expirationDate.substring(2, 4) + expirationDate.substring(0, 2);
            this.expirationDate = expirationDate.substring(2, 4) + '/' + expirationDate.substring(0, 2);
          }
          else {
            this.orderService.orderDto.ccExpirationDate = null;
            this.orderService.orderDto.ccLastFour = "";
            this.expirationDate = '';
            this.creditCardRequired = true;
            this.orderService.orderDto.ccToken = "";
            this.swalAlert.alert('There was an error in fetching the token');
          }
        })
      });
    }

    return true;
  }
  onPaymentMethChange() {
    this.orderService.orderDto.paymentMethod = 'C';
  }
  tokenChange() {
    if (this.orderService.orderDto.ccToken !== ''){
      this.creditCardRequired = false;
    } else{
      this.creditCardRequired = true;
    }      
  }

  onChangeGLString(): void {
    if (this.orderService.orderDto.billToDto.id === 0 || this.orderService.orderDto.billToDto.id === null) {
      this.swalAlert.alert('Account information details should be populated before entering GL String');

      setTimeout(() => {
        (document.getElementById("accountId") as HTMLElement).focus();
      });
      
      return;
    }
  }

  onChangePONumber(): void{
    if (this.orderService.orderDto.billToDto.id === 0 || this.orderService.orderDto.billToDto.id === null) {
      this.swalAlert.alert('Account information details should be populated before entering PO Number');
      this.orderService.orderDto.poNumber = '';

      setTimeout(() => {
        (document.getElementById("accountId") as HTMLElement).focus();
      });

      return;
    }
  }
  onChangeAMEKey(event:any){
    if(this.orderService.orderDto.amaKey){      
      this.orderService.orderDto.amaKey= this.orderService.orderDto?.amaKey?.trim().substring(0, 50).replace(/[^a-zA-Z0-9\-]/g, '');
    }    
  }

  onChangePOBoxKey(value: string){
    this.orderService.orderDto.poNumber = value.trim();
  }

  ngOnDestroy(): void {
    this.configIsReadySubscription?.unsubscribe();
    this.orderIsReadySubscription?.unsubscribe();
  }  
}
