<table class="bg-light orderInquiry" style="width: auto; white-space: nowrap;">
  <!-- Line above grid header row -->
  <tr class="bg-light" style="top: 0px; height: 15px; min-height: 15px; max-height: 15px; position: sticky; z-index: 1;">
    <th scope="col" class="px-0" *ngFor="let column of columns"><hr class="my-0"></th>
  </tr>
  <!-- Header row -->
  <tr class="bg-light" style="top: 15px; height: 30px; min-height: 30px; max-height: 30px; position: sticky; z-index: 1">
    <th scope="col" class="bg-light" *ngFor="let column of columns"
      [ngStyle]="{ 'left.px': column.start, 'min-width.px': column.width, 'max-width.px': column.width, 'position': column.frozen ? 'sticky' : 'relative', 'z-index': column.frozen ? '2' : '1' }"
      [ngClass]="{ 'px-2': !column.frozen }"
    >{{ column.title }}</th>
  </tr>
  <!-- Orange line below grid header row -->
  <tr class="bg-light" style="top: 45px; height: 15px; min-height: 15px; max-height: 15px; position: sticky; z-index: 1">
    <th scope="col" class="px-0" *ngFor="let column of columns"><hr class="color-orange my-0" style="height: 2px; opacity: 1;"></th>
  </tr>
  <tr style="height: 60px; z-index: 2" *ngFor="let orderDetailDto of orderService.orderDto.orderDetailDtos">
    <td style="left: 0px; min-width: 30px; max-width: 30px; position: sticky; background-color: white;">
      <span
        *ngIf="orderDetailDto.classCode != 'SH' || orderService.orderDto.shipmentMethod === 'DONOTSHIP' || orderService.orderDto.shipmentMethod === 'MISC'"
        class="uitk-icon uitk-icon-trash_delete gridIcons"
        [customDisabled]="orderDetailDto.orderDetailStatus === 'C' || orderDetailDto.orderDetailStatus === 'V' || modeService.mode !== Mode.Edit || orderDetailDto.parentOrderDetailId !== null || !orderDetailDto.hasDeleteAccess
          || (orderDetailDto.oti && !orderDetailDto.hasDepositDeleteIconAccess) || (!orderDetailDto.oti && !orderDetailDto.hasNonDepositDeleteIconAccess)
        "
        (click)="onDeleteClick(orderDetailDto)" appEnableResource="OrderInquiryGridDeleteicon"></span>
      <span
        *ngIf="orderDetailDto.classCode === 'SH' && orderService.orderDto.shipmentMethod !== 'DONOTSHIP' && orderService.orderDto.shipmentMethod !== 'MISC'"
        class="uitk-icon uitk-icon-trash_delete gridIcons" [customDisabled]="true"
        appEnableResource="OrderInquiryGridDeleteicon"></span>
    </td>
    <td style="left: 30px; min-width: 50px; max-width: 50px; position: sticky; background-color: white;">
      <div *ngIf="orderDetailDto.configSetExist"
        [ngbTooltip]="orderDetailDto.subscriptionDto || orderDetailDto.feeAnalyzerDto || orderDetailDto.oti ? gearIconContent : ''" container="body" placement="left">
        <span class="uitk-icon uitk-icon-settings gridIcons"
          [customDisabled]="!orderDetailDto.configSetExist ||
          (orderDetailDto.orderDetailStatus === 'C' && orderDetailDto.productDto.fulfillmentTypeCode !== 'WEBAPP'
            && orderDetailDto.productDto.fulfillmentTypeCode !== 'WEBFULFILL' && orderDetailDto.productDto.fulfillmentTypeCode !== 'CFAWEB')
            || (orderDetailDto.subscriptionDto && 'X,C,V,M'.indexOf(orderDetailDto.subscriptionDto.subscriptionStatus) > -1) || !orderDetailDto.hasGearAccess
            || orderDetailDto.orderDetailStatus === 'V' || modeService.mode !== Mode.Edit || orderDetailDto.parentOrderDetailId !== null || this.shippingProductCodes.indexOf(orderDetailDto.productCode) > -1
            || (orderDetailDto.oti && !orderDetailDto.hasDepositGearIconAccess) || (!orderDetailDto.oti && !orderDetailDto.hasNonDepositGearIconAccess)"
          (click)="orderService.clickConfiguration(orderDetailDto, true)" appEnableResource="OrderInquiryGridGearIcon"></span>

        <ng-template #gearIconContent style="width: 300px !important">
          <div class="container-fluid">
            <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0 d-flex">
              <div class="col-6 text-nowrap text-start text-white">Sub #:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.subscriptionDto.subscriptionNumber }}</div>
            </div>
            <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0 d-flex">
              <div class="col-6 text-nowrap text-start text-white">Sub Qty:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.subscriptionDto.subscriptionQuantity }}</div>
            </div>
            <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Start Date:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.subscriptionDto.startDate | date: 'MM/dd/yyyy' }}</div>
            </div>
            <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Term:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.subscriptionDto.term }}</div>
            </div>
            <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Expires:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.subscriptionDto.expireDate | date: 'MM/dd/yyyy' }}</div>
            </div>
            <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Status:</div>
              <div class="col-6 text-nowrap text-start text-white">{{
                orderDetailDto.subscriptionDto.subscriptionStatus === 'F' ? 'Future' :
                orderDetailDto.subscriptionDto.subscriptionStatus === 'S' ? 'Suspended' :
                orderDetailDto.subscriptionDto.subscriptionStatus === 'C' ? 'Cancelled' :
                orderDetailDto.subscriptionDto.subscriptionStatus === 'V' ? 'Void' :
                orderDetailDto.subscriptionDto.subscriptionStatus === 'W' ? 'Waiting' :
                orderDetailDto.subscriptionDto.subscriptionStatus === 'X' ? 'Expired' :
                orderDetailDto.subscriptionDto.subscriptionStatus
              }}</div>
            </div>
            <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Sub Type:</div>
              <div class="col-6 text-nowrap text-start text-white">{{
                orderDetailDto.subscriptionDto.subscriptionType === 'T' ? 'Trial' :
                orderDetailDto.subscriptionDto.subscriptionType === 'R' ? 'Renewal' :
                orderDetailDto.subscriptionDto.subscriptionType === 'N' ? 'New' :
                orderDetailDto.subscriptionDto.subscriptionType
              }}</div>
            </div>
            <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Upd Only:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.subscriptionDto.sendUpdatesOnly ? 'Yes' : 'No' }}</div>
            </div>
            <div *ngIf="orderDetailDto.feeAnalyzerDto" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Company:</div>
              <div class="col-6 text-start text-white">{{ orderDetailDto.feeAnalyzerDto.company }}</div>
            </div>
            <div *ngIf="orderDetailDto.feeAnalyzerDto" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Specialty:</div>
              <div class="col-6 text-start text-white">{{ orderDetailDto.feeAnalyzerDto.description }}</div>
            </div>
            <div *ngIf="orderDetailDto.feeAnalyzerDto" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Zip:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.feeAnalyzerDto.zip }}</div>
            </div>
            <div *ngIf="orderDetailDto.feeAnalyzerDto" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Geo Zip:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.feeAnalyzerDto.geoZip }}</div>
            </div>
            <div *ngIf="orderDetailDto.feeAnalyzerDto" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Completed:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.feeAnalyzerDto.completedDate | date: 'MM/dd/yyyy' }}</div>
            </div>
            <div *ngIf="orderDetailDto.cfaWebDtos.length > 0" class="row px-0">
              <div class="col-12 text-white">
                <hr>
              </div>
            </div>
            <div *ngIf="orderDetailDto.cfaWebDtos.length > 0" class="row px-0">
              <div class="col-6 text-start text-white">Company:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.cfaWebDtos[0].company }}</div>
            </div>
            <div *ngFor="let cfaWebDto of orderDetailDto.cfaWebDtos" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Zip:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ cfaWebDto.zip }} ({{ cfaWebDto.geoZip }})</div>
            </div>
            <div *ngIf="orderDetailDto.chegSetDto" class="row px-0">
              <div class="col-12 text-white">
                <hr>
              </div>
            </div>
            <div *ngIf="orderDetailDto.chegSetDto" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">ChegSet Type:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.chegSetDto.optionType === 1 ? 'Custom' : orderDetailDto.chegSetDto.optionType === 2 ? 'National' : 'Standard' }}</div>
            </div>
            <div *ngIf="orderDetailDto.chegSetDto && orderDetailDto.chegSetDto.state" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">State:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.chegSetDto.state }}</div>
            </div>
            <div *ngIf="orderDetailDto.chegSetDto && orderDetailDto.chegSetDto.carrier" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Carrier:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.chegSetDto.carrier }}</div>
            </div>
            <div *ngIf="orderDetailDto.chegSetDto && orderDetailDto.chegSetDto.locality" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Locality:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.chegSetDto.locality }}</div>
            </div>
            <!-- Deposits -->
            <div *ngIf="orderDetailDto.oti && orderDetailDto.oti[0] === 'C'" class="row px-0">
              <div class="col-6 text-nowrap text-start text-white">Credit Memo:</div>
              <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.oti.substring(1).trim() }}</div>
            </div>
            <div *ngIf="orderDetailDto.oti && orderDetailDto.oti[0] === 'R'" class="row px-0">
              <div class="col-4 text-nowrap text-start text-white">Ref #:</div>
              <div class="col-8 text-nowrap text-start text-white">{{ orderDetailDto.referenceNumber?.trim() }}</div>
            </div>
          </div>
        </ng-template>
      </div>
    </td>
    <td class="productCodeSec" style="left: 80px; min-width: 140px; max-width: 140px; position: sticky; background-color: white;">
      <uitk-form-field>
        <input type="text"
          [ngStyle]="{'width': orderDetailDto.parentOrderDetailId === null && orderDetailDto.productCode.indexOf('V') !== 0 ? '120px' : '105px',
          'margin-left': orderDetailDto.parentOrderDetailId === null && orderDetailDto.productCode.indexOf('V') !== 0 ? '0px' : '15px'}"
          id="productCode-{{ orderDetailDto.id }}" uitkInput [(ngModel)]="orderDetailDto.productCode"
          name="productCode" appEnableResource="OrderDetailsItemTextBox"
          (change)="onChangeProductCode($event,'', orderDetailDto)"
          [disabled]="modeService.mode !== Mode.Edit || !orderDetailDto.itemEditable || orderDetailDto.orderDetailStatus === 'V'"
          [ngStyle]="getdatafile(orderDetailDto)">
      </uitk-form-field>
    </td>
    <td style="left: 220px; min-width: 350px; max-width: 350px; position: sticky; background-color: white;">{{ orderDetailDto.productDescription }}</td>
    <td style="left: 570px; min-width: 100px; max-width: 100px; position: sticky; background-color: white;"
    bbLogTable="OrderDetail" bbLogColumn="OpenQuantity" [bbLogPrimaryId]="orderDetailDto.id">
      <uitk-form-field>
        <input type="text" id="orderQuantity-{{ orderDetailDto.id }}" uitkInput
          (focus)="recordPreviousQuantity(orderDetailDto)" appEnableResource="OrderDetailsOrderQtyTextBox"
          (change)="onChangeOrderQuantity(orderDetailDto)" (keydown.Tab)="onOrderQuantityBlur(orderDetailDto)"
          [(customModel)]="orderDetailDto.orderQuantity" name="myBasicInput" style="width: 80px;"
          [disabled]="modeService.mode !== Mode.Edit || orderDetailDto.parentOrderDetailId !== null || orderDetailDto.classCode == 'SH' || orderDetailDto.productCode === '0010' ||
            (orderDetailDto.orderDetailStatus !== ' ' && (orderDetailDto.orderDetailStatus !== 'C' || (orderDetailDto.productDto.fulfillmentTypeCode !== 'WEBAPP' && orderDetailDto.productDto.fulfillmentTypeCode !== 'CFAWEB') || !orderDetailDto.subscriptionDto || orderDetailDto.subscriptionDto.subscriptionStatus !== 'A'))"
          appQuantityFormat #orderQuantity>
      </uitk-form-field>
    </td>
    <td style="left: 670px; min-width: 120px; max-width:120px; position: sticky; background-color: white;"
    bbLogTable="OrderDetail" bbLogColumn="ListPrice" [bbLogPrimaryId]="orderDetailDto.id">
      <div *ngIf="!orderDetailDto.productDto.allowCustomPrice || modeService.mode !== Mode.Edit || orderDetailDto.orderDetailStatus !== ' ' ">
        {{ orderDetailDto.listPrice | currency:'USD' }}
      </div>
      <div *ngIf="orderDetailDto.productDto.allowCustomPrice && modeService.mode === Mode.Edit && orderDetailDto.orderDetailStatus === ' '">
        <uitk-form-field>
          <input type="text" id="listPrice-{{ orderDetailDto.id }}" uitkInput
            [(customModel)]="orderDetailDto.listPrice" name="myBasicListPrice"
            appEnableResource="OrderDetailsListPriceTextBox" (change)="onChangeListPrice(orderDetailDto)"
            appPriceFormat />
        </uitk-form-field>
      </div>
    </td>
    <td class="px-2" bbLogTable="OrderDetail" bbLogColumn="Discount" [bbLogPrimaryId]="orderDetailDto.id">
      <uitk-form-field>
        <input type="text" id="discount-{{ orderDetailDto.id }}" uitkInput
          [(customModel)]="orderDetailDto.discount" name="myBasicInput" style="width: 90px;"
          appEnableResource="OrderDetailsDiscTexdtBox" (change)="onChangeDiscount(orderDetailDto)"
          [disabled]="modeService.mode !== Mode.Edit || orderDetailDto.orderDetailStatus === 'C'
          || orderDetailDto.itemDisable === true || orderDetailDto.productDto.allowCustomStandard || orderDetailDto.orderDetailStatus === 'V'
          || (orderDetailDto.parentOrderDetailId !== null && orderDetailDto.productCode !== '1577')
          || orderDetailDto.subscriptionDto?.subscriptionType === 'T' || orderDetailDto.productCode === '0010' || orderDetailDto.productDto.classCode === 'XS' " appDiscountFormat
          [ngStyle] = "getdiscount(orderDetailDto)">
      </uitk-form-field>
    </td>
    <td class="px-2" bbLogTable="OrderDetail" bbLogColumn="UnitPrice" [bbLogPrimaryId]="orderDetailDto.id">
      <uitk-form-field>
        <input type="text" id="unitPrice-{{ orderDetailDto.id }}" uitkInput
          [(customModel)]="orderDetailDto.unitPrice" name="myBasicInput" style="width: 90px;"
          appEnableResource="OrderDetailsUnitPriceTextBox" (change)="onChangeUnitPrice(orderDetailDto)"
          [disabled]="modeService.mode !== Mode.Edit || orderDetailDto.orderDetailStatus === 'C'
               || orderDetailDto.itemDisable === true || orderDetailDto.productDto.allowCustomPrice || orderDetailDto.orderDetailStatus === 'V'
               || (orderDetailDto.parentOrderDetailId !== null && orderDetailDto.productCode !== '1577')
               || orderDetailDto.subscriptionDto?.subscriptionType === 'T' || orderDetailDto.productCode === '0010' || orderDetailDto.productDto.classCode === 'XS' "
          appPriceFormat>
      </uitk-form-field>
    </td>
    <td class="px-2">{{ orderDetailDto.extendedPrice | currency:'USD' }}</td>

    <td class="px-2" bbLogTable="OrderDetail" bbLogColumn="RequiredDate" [bbLogPrimaryId]="orderDetailDto.id">
      <uitk-form-field>
        <input type="text" id="requiredDate-{{ orderDetailDto.id }}" uitkInput
          [ngModel]="orderDetailDto.requiredDate | date:'MM/dd/yyyy'"
          appEnableResource="OrderDetailsReqDateTextBox" (change)="onChangeRequiredDate($event, orderDetailDto)"
          name="reqDate" style="width: 120px;" placeholder="MM/DD/YYYY"
          [disabled]="modeService.mode !== Mode.Edit || orderDetailDto.orderDetailStatus === 'C'
               || orderDetailDto.itemDisable === true || orderDetailDto.orderDetailStatus === 'V'
               || orderDetailDto.parentOrderDetailId !== null || orderDetailDto.productCode === '0010' || orderDetailDto.subscriptionDto != null" />
      </uitk-form-field>
    </td>
    <td class="px-2">{{ orderDetailDto.orderDetailStatus === ' ' ? 'O' : orderDetailDto.orderDetailStatus }}
    </td>
    <td class="px-2">{{ orderDetailDto.shippedQuantity + (orderDetailDto.pendingQuantity || 0) }}</td>
    <td class="px-2">{{ orderDetailDto.shipDate | date:'MM/dd/yyyy' }}</td>
    <td class="px-2">{{ orderDetailDto.subscriptionDto?.term }}</td>
    <td class="px-2">{{ orderDetailDto.pendingDate | date:'MM/dd/yyyy  h:mm a' }}</td>
    <td class="px-2">{{ orderDetailDto.orderDetailStatus === 'V' ? (orderDetailDto.voidDate | date:'MM/dd/yyyy') : orderDetailDto.modifiedDate | date:'MM/dd/yyyy' }}</td>
    <td class="px-2">{{ orderDetailDto.voidedQuantity }}</td>
    <td class="px-2">{{ orderDetailDto.voidReasonDescription }}</td>
    <td class="px-2" [ngbTooltip]="accountOwnerFullName" container="body" placement="left">{{ orderDetailDto.accountOwnerCode }}</td>
    <ng-template #accountOwnerFullName><div style="color: white !important">{{ orderDetailDto.accountOwnerFullName }}</div></ng-template>
    <td class="px-2" [ngbTooltip]="salesPersonFullName" container="body" placement="left">{{ orderDetailDto.salespersonCode }}</td>
    <ng-template #salesPersonFullName><div style="color: white !important">{{ orderDetailDto.salespersonFullName }}</div></ng-template>
    <td class="px-2">{{ orderDetailDto.allowPaymentPlan ? 'Yes' : 'No' }}</td>
    <td class="px-2">{{ orderDetailDto.taxAmount | currency:'USD' }}</td>
  </tr>
</table>

<div class = "overlayForPopups" *ngIf="showNoItemExists.show">
  <div cdkDrag>
<uitk-dialog [(showModal)]="showNoItemExists.show" uitkId="noItemDialog" triggerElementId="noItem" [closeBtn]=false>
  <uitk-dialog-header>
    <div cdkDragHandle>
      Error Message
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content *ngIf="!isAddOn && !havePermission">
    {{ myNotificationWarningContent }}
  </uitk-dialog-content>
  <uitk-dialog-content *ngIf="isAddOn && !havePermission">
    {{ addOnWarningContent }}
  </uitk-dialog-content>
  <uitk-dialog-content *ngIf="havePermission">
    {{ addPermissionContent }}
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <div class="text-center">
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="closeNoItemDialog()">Ok</button>
    </div>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>

<div class = "overlayForPopups" *ngIf="dupWebApps.show">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dupWebApps.show" [closeBtn]=false>
  <uitk-dialog-header>
    <div cdkDragHandle>
      Cannot add this Line
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    {{dupNotificationForWebApps}}
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="closeItemDialog()" #dupWebAppOkBtn>
      Ok
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>

<div class = "overlayForPopups" *ngIf="dupProduct.show">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dupProduct.show" [closeBtn]=false>
  <uitk-dialog-header>
    <div cdkDragHandle>
      <h4 class="modal-title pull-left">
        Duplicate
      </h4>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <span>
      This item has already been entered in the cart. Continue entering the duplicate item?
    </span>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="allowDup()" #dupItemOkBtn>
      Yes
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="stopDup()">
      No
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>

<div class = "overlayForPopups" *ngIf="itemComment.show">
  <div cdkDrag>
<uitk-dialog [(showModal)]="itemComment.show" [closeBtn]=false>
  <uitk-dialog-header>
    <div cdkDragHandle>
      <h4 class="modal-title pull-left">
        Order Note
      </h4>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <span>
      {{ itemComment.text }}
    </span>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"  (click)="closeItemDialog()" #orderNoteOkBtn>
      Ok
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>

<div class="divPopUpContent" *ngIf="orderService.configSetDialog.show">
  <app-config-sets [dialog]="orderService.configSetDialog" [orderDetail]="orderService.configurationOrderDetail">
  </app-config-sets>
</div>

<app-void-item-dialog [dialog]="voidItemDialog" [selectedOrder]="selectedOrderDetail"
  (dialogClosed)="onVoidLineItem()"></app-void-item-dialog>

<div class = "overlayForPopups" *ngIf="voidDepositDialogModal.show">
  <div cdkDrag>
  <uitk-dialog [(showModal)]="voidDepositDialogModal.show" uitkId="voidOrderDialog" triggerElementId="voidOrder"
  [closeBtn]=false>
  <uitk-dialog-header>
    <div cdkDragHandle>
      Void/Close {{orderService.orderDto.orderType ==='R' ? ' Return' : ' Order'}} with a deposit
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    There is a deposit on this {{orderService.orderDto.orderType ==='R' ? ' Return' : ' Order'}}. The open deposit amount will be posted as Open Cash on the Account.
  </uitk-dialog-content>
  <uitk-dialog-actions >

    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeVoidDepositDialog()" autofocus>
      OK
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>

<div class = "overlayForPopups" *ngIf="quantityCheckModal.show">
  <div cdkDrag>
<uitk-dialog [(showModal)]="quantityCheckModal.show" uitkId="quantityCheckModal" [closeBtn]=false>
  <uitk-dialog-content>
    <p>Does your customer have a dock that can receive truck shipments?</p>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="onClickYes()" #quantityCheckOkBtn>
      Yes
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="onClickNo()">
      No
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>
<div *ngIf="showItemSearchDialog.show">
  <app-item-search [dialog]="showItemSearchDialog" (productAdded)="addProduct($event)"></app-item-search>
</div>
<app-subscription-price-calculation #subPriceCalculation id="subsPriceCal"></app-subscription-price-calculation>
