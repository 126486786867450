import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { DataFileDtos } from '../../../dtos/datafile-dto.model';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { OrderNoteDto } from '../../../dtos/order-note-dto.model';
import { WebAppDto } from '../../../dtos/web-app-dto.model';
import { ConfigService } from '../../../services/config.service';
import { ConfigurationService } from '../../../services/configurations.service';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-web-app',
  templateUrl: './web-app.component.html',
  styleUrls: ['./web-app.component.css', '../config-sets.component.css']
})

export class WebAppComponent implements OnInit {
  private readonly _configurationService: ConfigurationService;
  private readonly _pricingService: PricingService;
  private readonly _configService: ConfigService;
  @Input() orderDetail: OrderDetailDto = new OrderDetailDto();
  @Output() enableSave = new EventEmitter<boolean>();
  loading = false; //flag for Spinning Wheel
  visibleTextValue = '';
  tableHeader = [' ', 'Item', 'Description', 'List Price', 'Quantity', 'Discount', 'Unit Price', 'Ext Price'];
  webAppDto: WebAppDto[] = [];
  isProductShipped: boolean = this.orderDetail.shipDate != null ? true : false;
  medicaidStatesList: IUITKSelectItemProps[] = [];
  showError = false;
  trialChecked = false;
  voidItemDialog = { show: false };
  selectedOrderDetail: OrderDetailDto = new OrderDetailDto();
  orderDetailDtoBackup :OrderDetailDto[]=[];
  orderNotesDtoBackUp : OrderNoteDto[]=[];
  actualWebAppDtos:WebAppDto[] = [];
  swalAlert = new SwalAlert();

  constructor(configurationService: ConfigurationService,
    pricingService: PricingService,
    configService: ConfigService,
    public orderService: OrderService
  ) {
    this._configurationService = configurationService;
    this._pricingService = pricingService;
    this._configService = configService;
  }

  ngOnInit(){
    this.orderDetailDtoBackup = JSON.parse(JSON.stringify(this.orderService.orderDto.orderDetailDtos)) as OrderDetailDto[];
    this.getWebAppRecords();
    this.medicaidStatesList = this._configService.getPickList('MEDSTATE');
  }

  getWebAppRecords(){
    this.visibleTextValue = 'Loading Records';
    this.loading = true;
    this._configurationService.getWebAppAddons(this.orderDetail.productId)
      .subscribe(data => {
        this.webAppDto = data;
        if (this.webAppDto.length > 0 && this.orderDetail.id !== 0) {
          this.setDefaults();
        }
        this.actualWebAppDtos = JSON.parse(JSON.stringify(data));
        this.setDefaultForSubscriptionTypeT();
        this.updateAddonIfAlreadyInCart();
        this.webAppDto.sort((a,b) => a.productCode > b.productCode ? 1 : -1);
        this.loading = false;
      }, error => {
        this.swalAlert.alert('Error occurred while retrieving Web Application Add-ons');
        this.loading = false;
      });
  }

  private setDefaults() {
    this.webAppDto.forEach(element => {
      element.isSelected = false;
      element.orderQuantity = 1;
      element.discount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, element.addonProductId);
      element.unitPrice = element.listPrice;
      element.extendedPrice = element.unitPrice;
      element.medicaidState = null;
      this._pricingService.calculateUnitPrice(element);
      this._pricingService.calculateExtendedPrice(element);
    })
  }

  setDefaultForSubscriptionTypeT() {
    if (this.orderDetail.subscriptionDto?.subscriptionType === 'T') {
      this.trialChecked = true;
      this.webAppDto.forEach(x => {
        x.discount = 100;
        this.onChangeWebAppDiscount({}, x);
      });
    }
  }

  onChangeRecordSelction(isSelected: boolean, webAppDto: WebAppDto) {
    if (!isSelected) {
      webAppDto.orderQuantity = 1;
      webAppDto.discount =this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, webAppDto.addonProductId);
      webAppDto.voidedQuantity = 0;
      if(this.trialChecked){
        this.isTrialCheckboxChecked(true);
      }
      this._pricingService.calculateUnitPrice(webAppDto);
      this._pricingService.calculateExtendedPrice(webAppDto);
      const unshippedAddon = this.orderService.orderDto.orderDetailDtos
      .filter(x => x.productCode === webAppDto.productCode && x.shipDate === null && x.orderDetailStatus !== "V" && x.orderDetailStatus !== "C" &&
      x.parentOrderDetailId === this.orderDetail.id)[0];
     
      if(unshippedAddon && this.orderService.orderDto.orderStatus !== 'D' && this.orderService.orderDto.orderType !== 'Q')
      {
        this.selectedOrderDetail = unshippedAddon;
        this.voidItemDialog.show = true;
      }
    }
    else
    {
      this.onChangeWebAppQuantity("",webAppDto);
    }
  }

  onVoidLineItemDialogCancel()
  {
    const unshippedAddon = this.orderService.orderDto.orderDetailDtos
    .filter(x => x.productCode === this.selectedOrderDetail.productCode && x.shipDate === null && x.orderDetailStatus !== "V" && x.orderDetailStatus !== "C" &&
    x.parentOrderDetailId === this.orderDetail.id)[0];

    if(unshippedAddon && unshippedAddon.orderDetailStatus === ' ')
    {
      this.webAppDto.filter(x=>x.productCode === this.selectedOrderDetail.productCode)[0].isSelected=true;
    }
  }

  onVoidLineItemCancel()
  {
    this.orderService.orderDto.orderDetailDtos=this.orderDetailDtoBackup;
    this.orderService.orderDto.orderNotes = this.orderNotesDtoBackUp;
  }

  updateAddonIfAlreadyInCart() {
    if (!this.isProductShipped) {
      const existingAddOnsInCart = this.orderService.orderDto.orderDetailDtos
      .filter(x => x.productCode.startsWith("WA") && x.orderDetailStatus === ' ' && x.parentOrderDetailId === this.orderDetail.id);
      existingAddOnsInCart.forEach(cartAddOn => {
        const matchingAddOn = this.webAppDto?.filter(x => x.productCode === cartAddOn.productCode)[0];
        if (matchingAddOn) {
          matchingAddOn.isSelected = true;
          matchingAddOn.orderQuantity = cartAddOn.orderQuantity;
          matchingAddOn.listPrice = cartAddOn.listPrice;
          matchingAddOn.discount = cartAddOn.discount;
          matchingAddOn.extendedPrice = cartAddOn.extendedPrice;
          matchingAddOn.unitPrice = cartAddOn.unitPrice;
          matchingAddOn.medicaidState = cartAddOn.medicaidState;
        }
      });
    }
  }

  onChangeWebAppQuantity(e: any, webAppDto: WebAppDto) {
    if(webAppDto.orderQuantity === 0)
    {
      webAppDto.orderQuantity=1;
    }
    if (webAppDto.isMaxParentQuantity) {
      const addonQuantity = this.orderService.orderDto.orderDetailDtos
        .filter(x => x.parentOrderDetailId === this.orderDetail.id && x.productCode === webAppDto.productCode && x.orderDetailStatus === 'C')
        .reduce((sum, current) => sum + current.orderQuantity + current.returnedQuantity, 0);

        const vlineItemQuantity = this.orderService.orderDto.orderDetailDtos
        .filter(x => x.productCode === "V"+ this.orderDetail.productCode && x.orderDetailStatus !== 'V')
        .reduce((sum, current) => sum + current.orderQuantity + current.returnedQuantity, 0);

      const quantity=this.orderDetail.orderQuantity +this.orderDetail.returnedQuantity+vlineItemQuantity;

      if (webAppDto.orderQuantity + addonQuantity > quantity) {
        this.swalAlert.alert('The addon quantity cannot exceed the order quantity of the main item.');
        webAppDto.orderQuantity = quantity - addonQuantity;
      }
    }

    const calculateVoidQuantity = this.orderService.orderDto.orderDetailDtos
        .filter(x => x.productCode === webAppDto.productCode );


        if(calculateVoidQuantity.length > 0){
          if(calculateVoidQuantity[0].openQuantity > webAppDto.orderQuantity){
            webAppDto.voidedQuantity = calculateVoidQuantity[0].voidedQuantity + (calculateVoidQuantity[0].openQuantity - webAppDto.orderQuantity)
          }
          else {
            webAppDto.voidedQuantity = calculateVoidQuantity[0].voidedQuantity;
          }
        }
    
    webAppDto.listPrice = this._pricingService.calculateListPricing(this.orderService.orderDto, webAppDto) || webAppDto.listPrice;
    this._pricingService.calculateExtendedPrice(webAppDto);
    this._pricingService.calculateUnitPrice(webAppDto);
  }

  onChangeWebAppDiscount(e: any, webAppDto: WebAppDto) {
    this._pricingService.calculateUnitPrice(webAppDto);
    this._pricingService.calculateExtendedPrice(webAppDto);
  }

  onChangeWebAppUnitPrice(e: any, webAppDto: WebAppDto) {
    if (webAppDto.allowCustomStandard){
      webAppDto.listPrice = webAppDto.unitPrice;
    }
    else {
      webAppDto.discount = this._pricingService.round((100 - webAppDto.unitPrice / webAppDto.listPrice * 100), 10);
    }
    this._pricingService.calculateUnitPrice(webAppDto);
    this._pricingService.calculateExtendedPrice(webAppDto);
  }

  isTrialCheckboxChecked(trial: boolean) {
    if (trial) {
      this.trialChecked = true;
      
      this.webAppDto.forEach(x => {
        x.discount = 100;
        this.onChangeWebAppDiscount({}, x);
      });
    }
    else {
      if (trial !== this.trialChecked) {
        this.webAppDto.forEach(element => {
          element.discount = this.actualWebAppDtos.filter(web => web.productCode === element.productCode)[0].discount
          this.onChangeWebAppDiscount({}, element);
        });
      }
      this.trialChecked = false;
    }
  }
 
  checkIsSubscriptionTrial(webApp: WebAppDto) {
    if (this.orderDetail.subscriptionDto?.subscriptionType === 'T') {
      webApp.discount = 100;
      this.onChangeWebAppDiscount({}, webApp);
    }
  }

  save(): boolean {
    if (!this.validateWebAppAddOns()) {
      return false;
    }

    this.showError = false;
    
    this.webAppDto.forEach(webApp => {
      const unshippedAddon = this.orderService.orderDto.orderDetailDtos
      .filter(x => x.productCode === webApp.productCode && x.shipDate === null && x.orderDetailStatus !== "V" && x.orderDetailStatus !== "C" &&
                            x.parentOrderDetailId === this.orderDetail.id)[0];
      if (webApp.hasOwnProperty('isSelected') && webApp.isSelected === true) {
        this.checkIsSubscriptionTrial(webApp);
        if (!unshippedAddon && webApp.orderQuantity > 0) {//Item does not exist in Order Inquiry grid          
          this.pushRecordIntoOrderDetail(webApp);
        }
        else if (unshippedAddon) {//update record
          unshippedAddon.orderQuantity = webApp.orderQuantity;
          unshippedAddon.openQuantity = webApp.orderQuantity;
          unshippedAddon.discount = webApp.discount;
          unshippedAddon.unitPrice = webApp.unitPrice;
          unshippedAddon.extendedPrice = webApp.extendedPrice;
          unshippedAddon.listPrice = webApp.listPrice;
          unshippedAddon.medicaidState = webApp.medicaidState;
          unshippedAddon.voidedQuantity = webApp.voidedQuantity ? webApp.voidedQuantity  : 0;
        }
      }
      else if (webApp.isSelected === false) {//Item is un-checked       
        this.updateUnselectedWebApp(webApp);
      }
    });

    return true;
  }


  private updateUnselectedWebApp(webApp: WebAppDto) {
    const unshippedAddon = this.getUnshippedAddon(webApp.productCode);
    if (unshippedAddon && !this.isProductShipped) {
      if (unshippedAddon.id > 0) {
        this.updateUnshippedAddon(unshippedAddon);
      }
      else {
        this.removeRecordFromOrderDetail(webApp.productCode);
      }
    }
  }
  
  private getUnshippedAddon(productCode: string) {
    return this.orderService.orderDto.orderDetailDtos
      .find(x => x.productCode === productCode && x.shipDate === null && x.orderDetailStatus !== "V" && x.orderDetailStatus !== "C" &&
        x.parentOrderDetailId === this.orderDetail.id);
  }
   
  updateUnshippedAddon(unshippedAddon:any){
    unshippedAddon.lastOrderDetailStatus=unshippedAddon.orderDetailStatus;
    unshippedAddon.orderDetailStatus = 'V';
    if (this.orderService.orderDto.orderStatus === 'D' || this.orderService.orderDto.orderType === 'Q')
    {
      unshippedAddon.voidReasonCode = 'D';
    }
    unshippedAddon.voidedQuantity = (unshippedAddon.voidedQuantity ? unshippedAddon.voidedQuantity  : 0) + unshippedAddon.openQuantity;
    unshippedAddon.openQuantity = 0;
    unshippedAddon.taxAmount = 0;
    unshippedAddon.taxRate = 0;    
  }
  pushRecordIntoOrderDetail(webApp: WebAppDto) {
    const minNegativeOrderId: number = Math.min.apply(Math, this.orderService.orderDto.orderDetailDtos.map(function (o) { 
      return o.id 
    }));
    if (this.orderDetail.id <= 0) {//Unsaved order record
      if (minNegativeOrderId === 0) {
        this.orderDetail.id = -1;
      }
      else if (minNegativeOrderId < 0 && !(this.orderDetail.id < 0)) {
        this.orderDetail.id = minNegativeOrderId - 1;
      }
    }

    const newOrderDetailDto: OrderDetailDto = this.orderService.createNewOrderDetail(this.orderService.orderDto, webApp.productCode);
    newOrderDetailDto.taxAmount = 0;
    newOrderDetailDto.taxRate = 0;
    newOrderDetailDto.dataFileDtos = new DataFileDtos();
    newOrderDetailDto.parentOrderDetailId = this.orderDetail.id;
    newOrderDetailDto.productId = webApp.addonProductId;
    newOrderDetailDto.productCode = webApp.productCode;
    newOrderDetailDto.medicaidState = webApp.medicaidState;
    newOrderDetailDto.productDescription = webApp.description;
    newOrderDetailDto.listPrice = webApp.listPrice;
    newOrderDetailDto.discount = webApp.discount;
    newOrderDetailDto.unitPrice = webApp.unitPrice;
    newOrderDetailDto.extendedPrice = webApp.extendedPrice;
    newOrderDetailDto.shippedQuantity = 0;
    newOrderDetailDto.orderDetailStatus = ' ';
    newOrderDetailDto.openQuantity = parseInt(webApp.orderQuantity.toString());

    newOrderDetailDto.isElectronic = true;
    newOrderDetailDto.enteredBy = 'OMA';
    newOrderDetailDto.configSetExist = false;
    newOrderDetailDto.accountOwnerCode = this.orderService.orderDto.billToDto.accountOwnerCode;
    newOrderDetailDto.requiredDate = this.orderDetail.requiredDate;

    newOrderDetailDto.orderQuantity = newOrderDetailDto.openQuantity;
    const noOfaddOns = this.orderService.orderDto.orderDetailDtos.filter(item => item.parentOrderDetailId === this.orderDetail.id).length;
    const index = this.orderService.orderDto.orderDetailDtos.findIndex(item => item === this.orderDetail);
    this.orderService.orderDto.orderDetailDtos.splice(index + 1 + noOfaddOns, 0, newOrderDetailDto);
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
    return true;
  }

  private removeRecordFromOrderDetail(productCode: string) {
    const index = this.orderService.orderDto.orderDetailDtos.findIndex(x => x.productCode === productCode && x.orderDetailStatus === ' ');
    this.orderService.orderDto.orderDetailDtos.splice(index, 1);
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
    return true;
  }

  validateWebAppAddOns(): boolean {
    this.showError = false;
    this.webAppDto.forEach(webApp => {
      if (webApp.hasOwnProperty('isSelected') && webApp.isSelected === true) {
        if (webApp.productCode === "WA29" && (webApp.medicaidState === 'null' || webApp.medicaidState === null)) {
          this.showError = true;
        }
      }
    });

    if (this.showError) {
      return false;
    }
    else {
      return true;
    }
  }

  onChangeListPrice(webAppDto: WebAppDto) {
    this._pricingService.calculateUnitPrice(webAppDto);
    this._pricingService.calculateExtendedPrice(webAppDto);
  }
}
