<div *ngIf="addNoteDialogModal.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="addNoteDialogModal.show" uitkId="addNoteDialog" [closeBtn]=false
  triggerElementId="addNoteDialog_openBtn">
  <uitk-dialog-header style="letter-spacing: 0.3px; font-size: 20px; line-height: 48px;">
    <div cdkDragHandle>
      Add Note
      <hr>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
            <div class="uitk-l-grid__row" >
                <div class="uitk-l-grid__col"> <p style="font-weight:bold;">Reviewer Name</p> </div>
                <div class="uitk-l-grid__col" id="reviewerCol">
                    
                        <uitk-form-field><input uitkInput id="reviewerName"   type="text" value="{{userName}}"  disabled /></uitk-form-field>
                   
                  </div>
                  <div class="uitk-l-grid__col"><p style="font-weight:bold;">Follow Up Date</p> </div>
                  <div class="uitk-l-grid__col" id="followUpCol">
                      <uitk-form-field >
                        <uitk-date-picker
                        id="folloUpDatePicker"
                    ></uitk-date-picker>
                      
                      </uitk-form-field>
                    </div> 
            </div>
            <div class="uitk-l-grid__row" >
                <div class="uitk-l-grid__col"> <p style="font-weight:bold;">Notes</p> </div>
                <div class="uitk-l-grid__col" id="reviewerCol">
                    <form [formGroup]="profileForm">
                        <uitk-form-field><textarea uitkTextarea name="textField" [textLimit]="maxTextLimit"
                            formControlName="textarea" 
                            uitkId="notes" ></textarea>
                            <uitk-textarea-character-remaining-message (onCountChange)="onCountChange($event)">
                                {{ remainingCount }} {{ myCharRemaining }}
                            </uitk-textarea-character-remaining-message></uitk-form-field>
                    </form>
                   
                  </div>
                </div>

  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" >
      Save
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeAddNoteDialog()">
      Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>
