<div *ngIf="dialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" [closeBtn]="false" uitkId="voidOrderDialog">
    <uitk-dialog-content>
      <h4><span>{{ question }}</span></h4>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="onClickYes()">
        Yes
      </button>
      <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="onClickNo()">
        No
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
  </div>
</div>
  