import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '../../../services/config.service';
import { OrderService } from '../../../services/order.service';

@Component({
  selector: 'app-view-multi-year-orders',
  templateUrl: './view-multi-year-orders.component.html',
  styleUrls: ['./view-multi-year-orders.component.css']
})

export class ViewMultiYearOrdersComponent implements OnInit {
  private readonly _configService: ConfigService;
  private readonly _orderService: OrderService;
  @Input() dialog = { show: false };
  tableHeader = ['Order #', 'Year', 'Status'];
  multiYearOrdersData: any = [];
  isSnapshot = false;

  constructor(configService: ConfigService, orderService: OrderService) {
    this._configService = configService;
    this._orderService = orderService;
  }

  ngOnInit(): void {
    this.multiYearOrdersData = this._orderService.orderDto.multiYearDtos;
    this.isSnapshot = this._orderService.isSnapshot;
  }

  getOrderStatus(orderStatus: string): string {
    return this._configService.getPickList('ORDERSTATUS').filter(x => x.id === orderStatus).length > 0 ?
      this._configService.getPickList('ORDERSTATUS').filter(x => x.id === orderStatus)[0].label : orderStatus;
  }
}
