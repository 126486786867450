<div class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkId="advancedContactSearchDialog" id="advancedContactSearchDialog"
  [(showModal)]="advancedContactSearchDialog.show" [closeBtn]="false">
  <uitk-dialog-content>
    <div class="container-fluid">
      <uitk-page-notification uitkId="error-msg-notification" [(visible)]="errorMessageVisible"
        pageNotificationType="error" triggerElementId="error-msg-trigger" animationType="fadeAnimation">
        <span>{{ errorContentNotification }}</span>
      </uitk-page-notification>
      <form #advancedContactSearchForm="ngForm">
        <uitk-panels [isAccordion]="true" [headingLevel]="headingLevel.h2">
          <uitk-panel uitkId="advancedContactSearchPanel" [open]="isadvancedContactSearchPanelOpen"
            (openChange)="onAdvancedContactSearchPanelOpenChange($event)">
            <uitk-panel-header-title>
              <div cdkDragHandle>
                <div class="row mt-3" uitkPanelTitleText>
                  <div class="col-6 d-flex">
                    <uitk-dialog-header>
                      <h3><b>Contact Search</b></h3>
                    </uitk-dialog-header>
                  </div>
                  <div class="col-6 d-flex justify-content-end">
                    <div class="col-7 d-flex justify-content-end" style="font-size: 18px;">
                      <span class="uitk-icon uitk-icon-add sm-icon" id="spnAddLinkIcon"></span>
                      <span class="link" id="spnAddLinkText">
                        <a href="javascript:void(0)" (click)="navigateToSalesforceNewContactPage()"> Add </a>
                      </span>&nbsp;&nbsp;
                      <button type="button" id="btnClosePopup" name="closePopup"
                        class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
                        (click)="closeAdvancedContactSearchPopup()">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </uitk-panel-header-title>
            <uitk-panel-content>
              <div>
                <div class="row formRow">
                  <div class="col-6 d-flex contentStart">
                    <div class="col-5">
                      <label class="align-self-left" uitkLabel>Contact ID</label>
                    </div>
                    <div class="col-7">
                      <uitk-form-field>
                        <input uitkInput id="txtContactId" name="contactId" class="textbox" type="number" [attr.tabindex]="11"
                          [ngModel]="advancedContactSearchInput.contactId === 0 ? '' : advancedContactSearchInput.contactId"
                          (ngModelChange)="contactIdNgModelChange($event)" />
                      </uitk-form-field>
                    </div>
                  </div>
                  <div class="col-6 d-flex contentEnd">
                    <div class="col-5">
                      <label class="align-self-left" uitkLabel>City</label>
                    </div>
                    <div class="col-7">
                      <uitk-form-field>
                        <input uitkInput id="txtCity" class="textbox" type="text" [attr.tabindex]="15"
                          [(ngModel)]="advancedContactSearchInput.city" minlength="3" name="cityInput"
                          #cityInput="ngModel" pattern="^[A-Z a-z.\-]*$" />
                        <uitk-form-field-error *ngIf="(cityInput?.errors?.minlength && cityInput?.touched)">
                          Minimun 3 characters required
                        </uitk-form-field-error>
                        <uitk-form-field-error *ngIf="cityInput?.errors?.pattern">
                          {{ getCityErrorMessage(cityInput) }}
                        </uitk-form-field-error>
                      </uitk-form-field>
                    </div>
                  </div>
                </div>
                <div class="row formRow">
                  <div class="col-6 d-flex contentStart">
                    <div class="col-5">
                      <label class="align-self-left" uitkLabel>First Name</label>
                    </div>
                    <div class="col-7">
                      <uitk-form-field>
                        <input uitkInput id="txtFirstName" class="textbox" type="text" [attr.tabindex]="12"
                          [(ngModel)]="advancedContactSearchInput.firstName" minlength="3" name="firstNameInput"
                          #firstNameInput="ngModel" pattern="^[A-Z a-z 0-9,.&\/\-\'\(\)\*\@#+\:\[\]\`]*$" />
                      </uitk-form-field>
                      <uitk-form-field-error *ngIf="(firstNameInput?.errors?.minlength && firstNameInput?.touched)">
                        Minimun 3 characters required
                      </uitk-form-field-error>
                      <uitk-form-field-error *ngIf="firstNameInput?.errors?.pattern">
                        {{ getFirstNameErrorMessage(firstNameInput) }}
                      </uitk-form-field-error>
                    </div>
                  </div>
                  <div class="col-6 d-flex contentEnd">
                    <div class="col-5">
                      <label class="align-self-left" uitkLabel>Zip Code</label>
                    </div>
                    <div class="col-7">
                      <uitk-form-field>
                        <input uitkInput id="txtZipCode" class="textbox" type="text" [attr.tabindex]="16"
                          [(ngModel)]="advancedContactSearchInput.zipCode" minlength="3" name="zipCodeInput"
                          #zipCodeInput="ngModel" pattern="^[0-9\-]*$" />
                        <uitk-form-field-error *ngIf="(zipCodeInput?.errors?.minlength && zipCodeInput?.touched)">
                          Minimun 3 characters required
                        </uitk-form-field-error>
                        <uitk-form-field-error *ngIf="zipCodeInput?.errors?.pattern">
                          {{ getZipCodeErrorMessage(zipCodeInput) }}
                        </uitk-form-field-error>
                      </uitk-form-field>
                    </div>
                  </div>
                </div>
                <div class="row formRow">
                  <div class="col-6 d-flex contentStart">
                    <div class="col-5">
                      <label class="align-self-left" uitkLabel>Last Name</label>
                    </div>
                    <div class="col-7">
                      <uitk-form-field>
                        <input uitkInput id="txtLastName" class="textbox" type="text" [attr.tabindex]="13"
                          [(ngModel)]="advancedContactSearchInput.lastName" minlength="3" name="lastNameInput"
                          #lastNameInput="ngModel" pattern="^[A-Z a-z 0-9,.&\/\-\'\(\)\*\@#+\:\[\]\`]*$" />
                        <uitk-form-field-error *ngIf="(lastNameInput?.errors?.minlength && lastNameInput?.touched)">
                          Minimun 3 characters required
                        </uitk-form-field-error>
                        <uitk-form-field-error *ngIf="lastNameInput?.errors?.pattern">
                          {{ getLastNameErrorMessage(lastNameInput) }}
                        </uitk-form-field-error>
                      </uitk-form-field>
                    </div>
                  </div>
                  <div class="col-6 d-flex contentEnd">
                    <div class="col-5">
                      <label class="align-self-left" uitkLabel>Email</label>
                    </div>
                    <div class="col-7">
                      <uitk-form-field>
                        <input uitkInput id="txtEmail" class="textbox" type="text" [attr.tabindex]="17"
                          [(ngModel)]="advancedContactSearchInput.email" minlength="3" name="emailInput"
                          #emailInput="ngModel" pattern="^[A-Z a-z 0-9 @.]*$" />
                        <uitk-form-field-error *ngIf="(emailInput?.errors?.minlength && emailInput?.touched)">
                          Minimun 3 characters required
                        </uitk-form-field-error>
                        <uitk-form-field-error *ngIf="emailInput?.errors?.pattern">
                          {{ getEmailErrorMessage(emailInput) }}
                        </uitk-form-field-error>
                      </uitk-form-field>
                    </div>
                  </div>
                </div>
                <div class="row formRow">
                  <div class="col-6 d-flex contentStart">
                    <div class="col-5">
                      <label class="align-self-left" uitkLabel>Address</label>
                    </div>
                    <div class="col-7">
                      <uitk-form-field>
                        <input uitkInput id="txtAddress" name="address" class="textbox" type="text" [attr.tabindex]="14"
                          [(ngModel)]="advancedContactSearchInput.address" minlength="3" name="addressInput"
                          #addressInput="ngModel" pattern="^[A-Z a-z 0-9 #.,/]*$" />
                        <uitk-form-field-error *ngIf="(addressInput?.errors?.minlength && addressInput?.touched)">
                          Minimun 3 characters required
                        </uitk-form-field-error>
                        <uitk-form-field-error *ngIf="addressInput?.errors?.pattern">
                          {{ getAddressErrorMessage(addressInput) }}
                        </uitk-form-field-error>
                      </uitk-form-field>
                    </div>
                  </div>
                  <div class="col-6 d-flex contentEnd">
                    <div class="col-5">
                      <label class="align-self-left" uitkLabel>Phone</label>
                    </div>
                    <div class="col-7 phoneSection">
                      <uitk-form-field>
                        <input uitkInput id="txtPhone" name="phone" class="textbox" type="text" [attr.tabindex]="18"
                          [(ngModel)]="advancedContactSearchInput.phone" minlength="3" name="phoneInput"
                          #phoneInput="ngModel" pattern="^[0-9\/\-]*$" />
                        <uitk-form-field-error *ngIf="(phoneInput?.errors?.minlength && phoneInput?.touched)">
                          Minimun 3 characters required
                        </uitk-form-field-error>
                        <uitk-form-field-error *ngIf="phoneInput?.errors?.pattern">
                          {{ getPhoneErrorMessage(phoneInput) }}
                        </uitk-form-field-error>
                      </uitk-form-field>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 d-flex justify-content-center">
                    <button type="button" id="btnSearch" name="search" [attr.tabindex]="19"
                      class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
                      (click)="search(advancedContactSearchForm)">Search</button>
                    <a href="javascript:void(0)" class="clearBtn" [attr.tabindex]="20" (click)="clearForm()">Clear</a>
                  </div>
                </div>
              </div>
            </uitk-panel-content>
          </uitk-panel>
        </uitk-panels>
      </form>
      <div>
        <div class="row mt-3">
          <div class="uitk-c-table--responsive container"
            [ngClass]="isadvancedContactSearchPanelOpen ? 'initialHeight' : 'maxHeight'">
            <table uitkTable id="advanced-contact-search-table" class="uitk-c-table"
              aria-describedby="advanced-contact-search-data" [dataSource]="advancedContactSearchData">
              <thead class="uitk-c-table__head">
                <tr class="headerMainColumns">
                  <th scope="col" colspan="4" class="accountContactHeaderMainColumn">
                    Account
                  </th>
                  <th scope="col" colspan="3" class="accountContactHeaderMainColumn">
                    Contact
                  </th>
                  <th scope="col" colspan="2" class="searchHeaderMainColumn">
                    Search
                  </th>
                </tr>
                <tr uitkTableSort #sortAccountSearchTable="uitkTableSort" class="headerSubColumns">
                  <th *ngFor="let tableHeader of tableHeaders" scope="col" uitkTableSortColumn [column]="tableHeader.id"
                    (sortChange)="onSortChange($event)" [style.width]="tableHeader.width">
                    {{ tableHeader.name }}
                  </th>
                </tr>
              </thead>
              <tbody id="advanced-contact-search-data" class="uitk-c-table__body">
                <tr class="uitk-c-table__row" [hidden]="!modeService.isWaiting">
                  <td colspan="8">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col">
                          <div id="loadingIndicator">
                            <uitk-loading-indicator uitkId="spinner" spinnerSize="large">
                            </uitk-loading-indicator>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col d-flex justify-content-center">
                          <h5>
                            {{ modeService.waitDescription }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="advancedContactSearchData.data.length === 0" [hidden]="modeService.isWaiting">
                  <td colspan="9">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col" style="text-align: center;">
                          No results found
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <ng-container *uitkTableRowBody="let row">
                  <tr class="uitk-c-table__row" [hidden]="modeService.isWaiting"
                    (click)="onContactIdClick(row.contactId)">
                    <td>
                      {{ row.accountStatus }}
                    </td>
                    <td>
                      {{ row.accountId }}
                    </td>
                    <td class="textTransform">
                      {{ row.accountName }}
                    </td>
                    <td class="textTransform">
                      {{ row.accountType }}
                    </td>
                    <td>
                      {{ row.contactStatus }}
                    </td>
                    <td class="contactIdLink">
                      <a href="javascript:void(0)">{{ row.contactId }}</a>
                    </td>
                    <td class="textTransform">
                      {{ row.contactName }}
                    </td>
                    <td class="textTransform">
                      {{ row.foundText }}
                    </td>
                    <td class="textTransform">
                      {{ row.location }}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </uitk-dialog-content>
</uitk-dialog>
  </div>
</div>
