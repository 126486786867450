<div class="accountSection">
    <label uitkLabel for="account-search-textbox">Account ID</label>
    <div class="searchField">
        <uitk-form-field>
            <input uitkInput id="account-id-textbox" class="textbox mx-1" type="number" [(ngModel)]="updateObj.accountId"/>
        </uitk-form-field>
    </div>      
</div>
<div class="commentSection">
    <label uitkLabel for="account-comment-area">Comment</label>
    <div class="searchField">
        <uitk-form-field>           
                <textarea uitkTextarea #comments id="text" name="accontComment" uitkId="account-comment-area" [(ngModel)]="updateObj.comments" value="" maxlength="234"></textarea>
                <uitk-textarea-character-remaining-message>
                    {{ 234-updateObj.comments.length }} characters remaining
                </uitk-textarea-character-remaining-message>
   
        </uitk-form-field>
    </div>      
</div>
<div class="buttonSection">
    <button type="button" class="uitk-c-button uitk-c-button--default" (click)="saveAccount()">Save</button>
    <button type="button" class="uitk-c-button uitk-c-button--ghost" (click)="cancelAccount()">Cancel</button>
</div>


