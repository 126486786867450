<div class="container-fluid mb-4">
  <label id="licencingModel">
    Licensing Model
  </label>
  <!-- Content -->
  <div class="row mt-3">
    <div class="col-8 d-flex">
      <div class="col-7 d-flex">
        <label uitkLabel>Licensing Model:</label>
      </div>
      <div class="col-5 d-flex">
        <uitk-form-field class="mx-3">
          <uitk-dropdown style="width: 220px;" uitkId="business-cat" [items]="licensingModelList" [enableSelectFlag]="true"
                         [(ngModel)]="selectedLicensingModel"
                         (onChange)="onChangeLicensingModel($event)">
          </uitk-dropdown>
        </uitk-form-field>
      </div>
    </div>
    <uitk-form-field-error *ngIf="!orderDetailDto.cptDto?.licensingModel && showError">
      Licensing Model is Required.
    </uitk-form-field-error>
  </div>
  <div class="row mt-3" *ngIf="orderDetailDto.cptDto?.licensingModel === 'Provider User Proxy'">
    <div class="col-8 d-flex">
      <div class="col-7 d-flex">
        <label class="align-self-center label col-12" uitkLabel>
          Number of Staffed Beds/Billing Providers:
        </label>
      </div>
      <div class="col-5 d-flex">
        <uitk-form-field class="mx-3">
          <input appIdFormat uitkInput [(customModel)]="orderDetailDto.cptDto!.beds" />
        </uitk-form-field>
      </div>
    </div>
    <uitk-form-field-error *ngIf="orderDetailDto.cptDto!.beds < 1 && showError">
      Number of Staffed Beds/Billing Providers should have a valid value.
    </uitk-form-field-error>
  </div>
  <div class="row mt-3" *ngIf="orderDetailDto.cptDto?.licensingModel === 'Counting Users' || orderDetailDto.cptDto?.licensingModel === 'Provider User Proxy'">
    <div class="col-8 d-flex">
      <div class="col-7 d-flex">
        <label class="align-self-center label col-12" uitkLabel>
          Number of Users:
        </label>
      </div>
      <div class="col-5 d-flex" *ngIf="orderDetailDto.cptDto?.licensingModel === 'Counting Users'">
        <uitk-form-field class="mx-3">
          <input appIdFormat uitkInput [(customModel)]="orderDetailDto.cptDto!.countingUsers" />
        </uitk-form-field>
      </div>
      <div class="col-5 d-flex" *ngIf="orderDetailDto.cptDto?.licensingModel === 'Provider User Proxy'">
        <uitk-form-field class="mx-3">
          <input appIdFormat uitkInput [(customModel)]="orderDetailDto.cptDto!.users" />
        </uitk-form-field>
      </div>
    </div>
    <uitk-form-field-error *ngIf="(orderDetailDto.cptDto!.users < 1 && orderDetailDto.cptDto?.licensingModel === 'Provider User Proxy') && showError">
      Number of Users Should have a valid value.
    </uitk-form-field-error>
    <uitk-form-field-error *ngIf="(orderDetailDto.cptDto!.countingUsers < 1 && orderDetailDto.cptDto?.licensingModel === 'Counting Users') && showError">
      Number of Users Should have a valid value.
    </uitk-form-field-error>
  </div>
  <div class="row mt-3" *ngIf="orderDetailDto.cptDto?.licensingModel === 'Provider User Proxy'">
    <div class="col-8 d-flex">
      <div class="col-7 d-flex">
        <label class="align-self-center label col-12" uitkLabel>
          System Type:
        </label>
      </div>
      <div class="col-5 d-flex">
        <uitk-form-field class="mx-3">
          <uitk-dropdown style="width: 220px;" uitkId="system-type" [items]="systemTypeList"
          (onChange)="onChangeSystemType($event)" [enableSelectFlag]="true" [(ngModel)]="selectedSystemType"></uitk-dropdown>
        </uitk-form-field>
      </div>
    </div>
    <uitk-form-field-error *ngIf="!orderDetailDto.cptDto!.systemType && showError">
      System Type is Required.
    </uitk-form-field-error>
  </div>
  <div class="row mt-3" *ngIf="orderDetailDto.cptDto?.systemType === 'Other'">
  <div class="col-8 d-flex" >
    <div class="col-7 d-flex">
    </div>
    <div class="col-5 d-flex">
      <uitk-form-field class="mx-3">
        <input type="text" maxlength="50" uitkInput [(ngModel)]="orderDetailDto.cptDto!.otherSystemType" />
      </uitk-form-field>
    </div>
  </div>
  <uitk-form-field-error *ngIf="!orderDetailDto.cptDto!.otherSystemType && showError">
    Other is Required.
  </uitk-form-field-error>
</div>
  <div class="row mt-3" *ngIf="orderDetailDto.cptDto?.licensingModel === 'Health Plan'">
    <div class="col-8 d-flex">
      <div class="col-7 d-flex">
        <label class="align-self-center label col-12" uitkLabel>
          Number of Members:
        </label>
      </div>
      <div class="col-5 d-flex">
        <uitk-form-field class="mx-3">
          <input appIdFormat uitkInput [(customModel)]="orderDetailDto.cptDto!.members" />
        </uitk-form-field>
      </div>

    </div>
    <uitk-form-field-error *ngIf="orderDetailDto.cptDto!.members < 1 && showError">
      Number of Members Should have a valid value.
    </uitk-form-field-error>
  </div>
  <div class="row mt-3" *ngIf="orderDetailDto.cptDto?.licensingModel === 'Health Plan'">
    <div class="col-8 d-flex">
      <div class="col-7 d-flex">
        <label class="align-self-center label col-12" uitkLabel>
          Number of Members per 1,000:
        </label>
      </div>
      <div class="col-5 d-flex">
        <uitk-form-field class="mx-3">
          <input type="text" uitkInput [value]="orderDetailDto.cptDto!.members/1000" disabled >
        </uitk-form-field>
      </div>
    </div>
  </div>
  <div class="row mt-3" *ngIf="orderDetailDto.cptDto?.licensingModel === 'Lab Test Model'">
    <div class="col-8 d-flex">
      <div class="col-7 d-flex">
        <label class="align-self-center label col-12" uitkLabel>
          Number of Lab Tests:
        </label>
      </div>
      <div class="col-5 d-flex">
        <uitk-form-field class="mx-3">
          <input appIdFormat uitkInput [(customModel)]="orderDetailDto.cptDto!.labTests" />
        </uitk-form-field>
      </div>
    </div>
    <uitk-form-field-error *ngIf="orderDetailDto.cptDto!.labTests < 1 && showError">
      Number of Lab Tests Should have a valid value.
    </uitk-form-field-error>
  </div>
</div>
