import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { OrderDto } from '../../../dtos/order-dto.model';
import { AuthorizationService } from '../../../services/authorization.service';
import { ConfigService } from '../../../services/config.service';
import { OrderService } from '../../../services/order.service';

@Component({
  selector: 'app-return-detail-action',
  templateUrl: './return-detail-action.component.html',
  styleUrls: ['./return-detail-action.component.css'],
})
  
export class ReturnDetailActionComponent implements OnInit {
  private readonly _configService: ConfigService;
  private readonly _router: Router;
  private readonly _authorizationService: AuthorizationService;
  public isCollapsed = true;
  methodList: IUITKSelectItemProps[] = [];
  termsList: IUITKSelectItemProps[] = [];
  taxRate = 0;
  totalTax = 0;
  notesDialog = { show: false };
  configIsReadySubscription: Subscription | undefined;

  constructor(
    configService: ConfigService,
    router: Router,
    public orderService: OrderService,
    authorizationService: AuthorizationService) {
    this._configService = configService;
    this._router = router;
    this._authorizationService = authorizationService;
  }

  ngOnInit(): void {
    this.configIsReadySubscription = this._configService.configIsReady.subscribe(
      () => {
        this.isCollapsed = !this._authorizationService.hasResource("ReturnEntryAdditionalActionsExpand");
        if (!this._configService.reference) {
          throw new Error('References are not loaded');
        }

        this.methodList = this._configService.getPickList('PMETH');
        this.termsList = this._configService.getPickList('PTERMS');
      }
    );

    this.calculateTaxes(this.orderService.orderDto.orderDetailDtos.filter(o => o.isSelected === true));
    this._configService.loadConfigurations();
  }

  calculateTaxes(returnOrderDetailDto: OrderDetailDto[]) {
    this.taxRate=0;
    this.totalTax=0;
    if(returnOrderDetailDto.length > 0)
    {
      this.taxRate = returnOrderDetailDto
        .map((o) => o.taxRate)
        .reduce((a, b) => Math.max(a, b));

      this.totalTax = returnOrderDetailDto
        .map((o) => o.taxAmount)
        .reduce((a, b) => a + b) +
        returnOrderDetailDto.filter(item => item.productCode === '0048').reduce((sum, item) => sum + item.extendedPrice, 0);
    }
  }

  navigateToInvoices(orderDto : OrderDto) {
    this._router.navigate([]).then(() => {
      window.open('/invoices/' + orderDto.originalOrderId, '_blank');
    });
   }
}
