<div class="container-fluid bg-darkgrey">
  <div class="row">
    <div class="col-3 d-flex">
      <span id="btnBackToSearchResults"
        *ngIf="showBackToSearchResults" [ngClass]="{'disabled' : modeService.mode === Mode.Edit}" style="color: white; margin: auto; border: 1px solid; width: 200px; text-align: center; margin-left: 18px; cursor: pointer;" (click)="backToSearchResults()" appTabTitle>
        &lt; Back to Search Results
      </span>
    </div>
    <div class="col-3 d-flex">
      <a id="previousButton" (click)="PrevClick()"
        [style.visibility]="isPrev ? 'hidden' : 'inherit'"
        [ngClass]="{'disabled' : modeService.mode === Mode.Edit || modeService.isWaiting}" class="align-self-center link text-light h3">&lt;</a>
    </div>
    <div class="col-3 text-light d-flex justify-content-center">
      <p class="uitk-u-margin-top--base"><strong>Search Results</strong> {{currentIndex}} of {{totalRecords}}</p>
    </div>
    <div class="col-3 d-flex justify-content-end">
      <a id="nextButton" (click)="NextClick()"
        [style.visibility]="isNext ? 'hidden' : 'inherit'"
        [ngClass]="{'disabled' : modeService.mode === Mode.Edit || modeService.isWaiting}" class="align-self-center link text-light h3">&gt;</a>
    </div>
  </div>
</div>
