<uitk-dialog [(showModal)]="rejectConfirmationDialog.show"
             uitkId="rejectConfirmationDialog"
             triggerElementId="myDialog-1_openBtn2"
             [closeBtn]="false">
  <uitk-dialog-header>
  </uitk-dialog-header>

  <uitk-dialog-content>
    <div class="d-flex">
      <h3>Rejection complete</h3>
      <span class="uitk-icon uitk-icon-checkmark_bold md-icon" style="padding-left: 0.25rem"></span>
    </div>
  </uitk-dialog-content>

  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="closeDialog()" autofocus>
      Close
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
