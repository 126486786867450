import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UITKTableDataSource } from '@uitk/angular';
import { ScrollToDirective } from '../../directives/scrollTo.directive';
import { Mode } from '../../enums/mode.enum';
import { Dialog } from '../../helpers/dialog';
import { ContactDto } from '../../dtos/contact-dto.model';
import { DataFileAccessDto } from '../../dtos/data-file-access-dto.model';
import { ConfigService } from '../../services/config.service';
import { ContactService } from '../../services/contact.service';
import { ModeService } from '../../services/mode.service';
import { OrderService } from '../../services/order.service';
import { SwalAlert } from '../../helpers/alert';

@Component({
  selector: 'app-select-contacts',
  templateUrl: './select-contacts.component.html',
  styleUrls: ['./select-contacts.component.css']
})
export class SelectContactsComponent implements OnInit {
  @Input() dialog!: Dialog<string>;
  displayResults = false;
  @ViewChild("saveBtn") saveBtn!: ElementRef<HTMLInputElement>;
  @ViewChild(ScrollToDirective) dir:any;
  tableHeader = [
    { name: 'Contact #', id: 'id' },
    { name: 'First Name', id: 'firstName' },
    { name: 'Last Name', id: 'lastName' },
    { name: 'Email', id: 'email' },
    { name: 'Title', id: 'title' }
  ];
  dataSource = new UITKTableDataSource<ContactDto>([]);
  data: ContactDto[] = [];
  contacts: ContactDto[] = [];
  selectedContacts: DataFileAccessDto[] = []
  selectedContactId: DataFileAccessDto = new DataFileAccessDto();
  selectAllContacts: boolean = false;
  checkboxCount = 0;
  visibleTextValue = 'Loading Contacts';
  Mode = Mode;
  activeStatus: string[] = ['A', 'BAD', 'BP'];
  swalAlert = new SwalAlert();

  constructor(public orderService: OrderService, public contactService: ContactService,
    public modeService: ModeService, private readonly configService: ConfigService) { }

  ngOnInit(): void {
    this.displayResults= false;
    this.getContactList();
  }

  ngAfterViewInit(): void {
       setTimeout(() => {
        this.saveBtn.nativeElement.focus();
       }, 2000);
    }
  getContactList() {
    this.selectAllContacts = false;
    this.selectedContacts = [];
    this.checkboxCount = 0;
    if (this.orderService.orderDto.billToDto.id > 0) {
      this.contactService.getContactInformation(this.orderService.orderDto.billToDto.id).subscribe(
        (data: any) => {
          this.contacts = data;
          this.data = [];
          for (const contact of this.contacts) {
            this.checkContactObject(contact);            
            if (contact.isSelected === true) {
              this.selectedContactId = new DataFileAccessDto();
              this.selectedContactId.contactId = contact.contactId;
              this.selectedContacts.push(this.selectedContactId);
            }

            this.data.push(contact);

          }
          this.dataSource.data = this.data.filter(c => this.activeStatus.includes(c.statusCode.trim()) || c.contactId === this.orderService.orderDto.shipToDto.contactId);
          this.selectAllContacts = this.dataSource.data.filter(x => x.email !== '').length === this.selectedContacts.length ? true : false;
          this.displayResults= true;
                    
        }, error => {
          console.log(error);
          this.swalAlert.alert('An error occurred while getting Contact details. Please try again.');
        }

      );
    }
  }

  checkContactObject(contact:ContactDto){
    if (contact.firstName === null){
      contact.firstName = "";
    }
    if (contact.lastName === null){
      contact.lastName = "";
    }
    if (contact.title === null){
      contact.title = "";
    }
    if (contact.email === null){
      contact.email = "";
    }
    else {
      this.checkboxCount = this.checkboxCount + 1;
    }
    contact.isSelected = this.orderService.orderDto.dataFileAccessDtos.find(x => x.contactId === contact.contactId) ? true : false;
  }
  updateSelectedContactList(data: ContactDto) {
    if (data.isSelected === true) {
      this.selectedContactId = new DataFileAccessDto();
      this.selectedContactId.contactId = data.contactId;
      this.selectedContacts.push(this.selectedContactId);
      if (this.checkboxCount === this.selectedContacts.length) {
        this.selectAllContacts = true;
      }
    }
    else {
      this.selectedContacts = this.selectedContacts.filter(x => x.contactId !== data.contactId)
      if (this.selectedContacts.length === 0 || this.checkboxCount !== this.selectedContacts.length) {
        this.selectAllContacts = false;
      }
    }
  }

  selectAll() {
    this.selectedContacts = [];

    for (let i = 0; i < this.dataSource.data.length; i++) {
      this.dataSource.data[i].isSelected = false;
      if (this.dataSource.data[i].email !== '' && this.selectAllContacts === true) {
        this.dataSource.data[i].isSelected = true;
        this.selectedContactId = new DataFileAccessDto();
        this.selectedContactId.contactId = this.dataSource.data[i].contactId;
        this.selectedContacts.push(this.selectedContactId);
      }
    }
  }

  navigateToSFNewOrEditContact(salesforceContactId: string) {
    window.open(`${this.configService.salesforceUrl}/apex/NewContactPage?id=${salesforceContactId}`);
  }

  closeDialog() {
    this.dialog.close('Cancel');
  }

  saveSelectedContacts() {
    this.orderService.orderDto.dataFileAccessDtos = this.selectedContacts;
    this.dialog.close('Save');
  }

  openSelectContacts() {
    setTimeout(() => {
      this.saveBtn.nativeElement.focus();
    }, 0);
  }

  closeModal(){
    this.dir!.target = "#divLeftOrderDetailsGrid";
    this.dir?.scrollToPosition();
  }

  refreshContactList() {
    this.displayResults= false;
    this.getContactList();
  }
  addContactList(salesforceAccountId: string) {
    window.open(`${this.configService.salesforceUrl}/apex/NewContactPage?accId=${salesforceAccountId}`);
  }
}
