<app-header></app-header>
<router-outlet (activate)="onActivate()"></router-outlet>

<!-- Dialogs -->

<div class="divPopUp" *ngIf="openIdlePopup === true">
  <uitk-dialog [(showModal)]="idlePopUpDialogModal.show" [closeBtn]="false">
    <uitk-dialog-header>
      <h4 class="modal-title pull-left">
        You Have Been Idle!
      </h4>
    </uitk-dialog-header>
    <uitk-dialog-content>
      <span>
        {{idleState}}
      </span>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="signOut()">
        Sign Out
      </button>
      <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="stay()">
        Stay
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
</div>
