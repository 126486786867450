import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BatchReturnsComponent } from './components/batch-returns/batch-returns.component';
import { ConfirmAuthorizationsComponent } from './components/confirm-authorizations/confirm-authorizations.component';
import { HomeComponent } from './components/home/home.component';
import { OrderInquiryComponent } from './components/order-inquiry/order-inquiry.component';
import { SubscriptionSearchComponent } from './components/subscription-search/subscription-search.component';
import { ReturnInquiryComponent } from './components/return-inquiry/return-inquiry.component';
import { PostLogin } from './components/post-login/post-login.component';
import { PostLogout } from './components/post-logout/post-logout.component';
import { AuthGuard } from './helpers/auth.guard';
import { CashInquiryComponent } from './components/cash-inquiry/cash-inquiry.component';
import { ReturnEntryComponent } from './components/return-entry/return-entry.component';
import { AccountsReceivableComponent } from './components/accounts-receivable/accounts-receivable.component';
import { WorkListQueueComponent } from './components/worklist/worklist-queue.component';
import { InvoicesComponent } from './components/order-inquiry/additional-actions/invoices/invoices.component';
import { TokenizeComponent } from './components/tokenize/tokenize.component';
import { SearchinvoiceByCCComponent } from './components/searchinvoice-by-cc/searchinvoice-by-cc.component';
import { OrderEntryComponent } from './components/order-entry/order-entry.component';
import { ApprovalConfirmationComponent } from './components/approval-reject-confirmation/approval-confirmation/approval-confirmation.component';
import { RejectConfirmationComponent } from './components/approval-reject-confirmation/reject-confirmation/reject-confirmation.component';
import { ApproveRejectWithCommentsComponent } from './components/approval-reject-confirmation/approve-reject-with-comments/approve-reject-with-comments.component';
import { OrderEntryMiniComponent } from './components/order-entry-mini/order-entry-mini.component';
import { QuoteCfdComponent } from './components/order-entry/quote-cfd/quote-cfd.component';
import { ViewPromotionsComponent } from './components/view-promotions/view-promotions.component';
import { ViewApprovalRequestsComponent } from './components/view-approval-requests/view-approval-requests.component';
import { OrderEntryEditGuard } from './helpers/order-entry-edit.gaurd';
import { AccountCommentsComponent } from './components/account-comments/account-comments.component';
import { SourceCodeComponent } from './components/source-code/source-code.component';
import { ProFormaInvoiceComponent } from './components/pro-forma-invoice/pro-forma-invoice.component';
import { ReportsComponent } from './components/reports/reports.component';

const routes: Routes = [
  { path: 'postlogin', component: PostLogin },
  { path: 'postlogout', component: PostLogout },
  { path: 'bypass', component: HomeComponent },
  { path: '', component: HomeComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'order/:orderId', component: OrderInquiryComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'subscriptions', component: SubscriptionSearchComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'subscriptions/:accountId/:subId/:itemId', component: SubscriptionSearchComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'tokenize', component: TokenizeComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'SearchinvoiceByCC', component: SearchinvoiceByCCComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'worklistqueue', component: WorkListQueueComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'returns/batchreturns', component: BatchReturnsComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'returns/confirmAuthorization', component: ConfirmAuthorizationsComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'return-entry', component: ReturnEntryComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'return/:returnId', component: ReturnInquiryComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'accountreceivable/cashinquiry', component: CashInquiryComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'accountreceivable/arinquiry', component: AccountsReceivableComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'accountreceivable/arinquiry/:accountId', component: AccountsReceivableComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'accountreceivable/arinquiry/:accountId/:invoiceId', component: AccountsReceivableComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'invoices/:orderId', component: InvoicesComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'order-entry-mini', component: OrderEntryMiniComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'promotions/:promotionCode', component: ViewPromotionsComponent, canActivate: [AuthGuard] },
  { path: 'accountreceivable/arinquiry/:id', component: AccountsReceivableComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'invoices/:id', component: InvoicesComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'order-entry', component: OrderEntryComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'order-entry/bypass', component: OrderEntryComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'order-entry/quote-cfd', component: QuoteCfdComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'order-entry/snapshot', component: OrderEntryComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'approve/:id', component: ApprovalConfirmationComponent },
  { path: 'approve-or-reject-withcomments/:id', component: ApproveRejectWithCommentsComponent },
  { path: 'rejectwithcomments/:id', component: ApproveRejectWithCommentsComponent },
  { path: 'reject/:id', component: RejectConfirmationComponent },
  { path: 'quote-cfd-form', component: QuoteCfdComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'promotions/:promotionCode', component: ViewPromotionsComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'approvalrequests', component: ViewApprovalRequestsComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'accountComments', component: AccountCommentsComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'sourcecode', component: SourceCodeComponent, canActivate: [AuthGuard, OrderEntryEditGuard] },
  { path: 'approve-or-reject-withcomments-pc/:id', component: ApproveRejectWithCommentsComponent },
  { path: 'proFormaInvoice', component: ProFormaInvoiceComponent },
  { path: 'order-entry/reship', component: OrderEntryComponent, canActivate: [AuthGuard, OrderEntryEditGuard]},
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
  
export class AppRoutingModule { }
