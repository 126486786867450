<div *ngIf="dialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" [closeBtn]="false" uitkId="voidOrderDialog">
  <uitk-dialog-header>
    <div cdkDragHandle>
      <br>
      <span *ngIf="this.selectedOrder.id <= 0"> Do you want to delete this line item? </span>
      <span *ngIf="this.selectedOrder.id > 0"> Do you want to void this line item? </span>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div *ngIf="orderService.orderDto.multiYearCurrentYear && this.selectedOrder.id > 0" class="uitk-l-grid__row">
      <div class="uitk-l-grid__col--12">
        <div class="demo-l-grid-col-content" style="color:red">Warning! This line item is part of a multi-year deal and future line item will also be voided.</div>
        <br />
      </div>
    </div>
    <div class="uitk-l-grid__row" *ngIf="this.selectedOrder.id > 0">
      <div class="uitk-l-grid__col--2">
        <label uitkLabel style="padding-top: 10px;">Void Reason</label>
      </div>
      <div class="uitk-l-grid__col--10">
        <uitk-form-field>
          <uitk-dropdown 
            uitkId="singleSelect-template"
            [enableSelectFlag]="false"
            [items]="voidOrderList"
            [(ngModel)]="voidOrderValue"
            [required]="true"
            name="requiredSelect"
            #requiredSelect="ngModel"
            (onChange)="voidOnChange()">
          </uitk-dropdown>
          <uitk-form-field-error *ngIf="(requiredSelect?.errors?.required && requiredSelect?.touched) || showVoidModalError">
            required
          </uitk-form-field-error>
        </uitk-form-field>
      </div>
    </div>
    <br>
    <div class="uitk-l-grid__row" *ngIf="this.selectedOrder.id > 0">
      <div class="uitk-l-grid__col--2">

        <label uitkLabel>Void Note</label>

      </div>
      <div class="uitk-l-grid__col--10">

        <div *ngIf="selectedOrder.productCode" class="ng-tns-c54-2 uitk-c-notification__container uitk-c-notification__container--warning ng-trigger ng-trigger-fadeInOut ng-star-inserted">
          <div class="uitk-c-notification__content-container ng-tns-c54-2">
            <div class="uitk-c-notification__icon ng-tns-c54-2">
              <span class="ng-tns-c54-1 uitk-icon uitk-icon-info_filled ng-star-inserted"></span>
            </div>
            <div class="uitk-c-notification__content ng-tns-c54-2" id="warningDemoId_content">
              <span class="ng-tns-c54-2" id="voidNoteInfo"> Voided items {{ selectedOrder.productCode }} will appear in the void note.</span>
            </div>
          </div>
        </div>
        <uitk-form-field>
          <textarea uitkTextarea
                    [(ngModel)]="voidReason"
                    name="textField"
                    uitkId="my-basic-template-textarea"
                    aria-describedby="my-basic-template-textarea-ht"
                    placeholder="Additonal Note"></textarea>
        </uitk-form-field>
      </div>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
     <button *ngIf="this.selectedOrder.id > 0" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="onClick_ConfirmDeleteOrder()" appAutoFocus>
      Save
    </button>
    <button *ngIf="this.selectedOrder.id <= 0" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="onClick_ConfirmDeleteOrder()" autofocus>
      Delete
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="onDeletePopupClose()">
      Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>