export class WorklistNoteDto {
    id = 0;
    createdDate = new Date();
    modifiedDate = new Date();
    orderId = 0;
    noteText = '';
    userCode = '';
    followUpDate = new Date();
    noteDate = new Date();
    notesText = '';
    isTruncated = false;
    uniqueId = 0;
    noteContact: string = '';
}