import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IUITKRadioGroupItem } from '@uitk/angular/forms/radio-group/radio-group.interface';
import { Mode } from '../../../enums/mode.enum';
import { ContactDto } from '../../../dtos/contact-dto.model';
import { EmailInputDto } from "../../../dtos/email-input-dto.model";
import { ItemGridData } from "../../../dtos/item-data.model";
import { OrderDto } from "../../../dtos/order-dto.model";
import { AuthorizationService } from '../../../services/authorization.service';
import { ContactService } from '../../../services/contact.service';
import { EmailService } from '../../../services/email.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';
import { SwalAlert } from '../../../helpers/alert';
import { QuoteCFDEmailDto } from 'src/app/dtos/quote-cfd-email-dto.model';
import { GeneratePdfService } from 'src/app/services/generate-pdf.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent implements OnInit {
  private readonly _emailService: EmailService;
  private readonly _generatePdfService: GeneratePdfService;
  msId=''
  @Input('dialog') dialog = { show: false };
  @Output() newItemEvent = new EventEmitter<boolean>();
  swalAlert = new SwalAlert();
  dialogueheader: string = 'What type of Email would you like to send?';
  sendButtonText: string = 'Send';
  Mode = Mode;
  cancelButtonText: string = 'Cancel';
  orderconfirmationlabel: string = 'Order Confirmation';
  shippingconfirmationlabel: string = 'Shipping Confirmation';
  productactivationlabel: string = 'Product Activation';
  isitemgriddisplayed = false;
  myRadioGroupSelecteditem: any;
  myCheckbox1: string = '';
  selectallcheckbox: boolean = false;
  myRadioGroupSelection: any;
  orderDtoCopy: OrderDto = new OrderDto();
  shippingitemgridData : ItemGridData[] = [];
  productitemgridData : ItemGridData[] = [];
  contactGridData : ContactDto[]  = [];
  email : string ="";
  emailPattern =/^^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](\s?)(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
  isEmailSent = false;
  sentEmailDialogModal = { show: false, };
  orderBycheckbox:boolean=false;
  shipTocheckbox:boolean=false;
  LoggedInUsercheckbox:boolean=false;
  cfdEmailDto = new QuoteCFDEmailDto();

  showErrorMsg: boolean = false;
  items: IUITKRadioGroupItem[] = [
    {
      label: 'Order Confirmation',
      value: 'OrderConfirmation',

    },
    {
      label: 'Shipping Confirmation',
      value: 'ShipmentConfirmation',
      disabled: !this.authorizationService.hasResource("OrderInquiryAdditionalActionsSendEmailShippingConfirmationRadioButton"),
    },
    {
      label: 'Product Activation',
      value: 'ProductActivation',
      disabled: !this.authorizationService.hasResource("OrderInquiryAdditionalActionsSendEmailProductActivationRadioButton"),
    },
    {
      label: 'Pro-Forma Invoice',
      value: 'Pro-FormaInvoice',
      disabled: !this.authorizationService.hasResource("SendEmailProFormaAndInvoice"),

    },
  ];


  shiptoheader = [
    'Item', 'Description', 'Shipped Date'

  ];
  productheader = [
    'Item', 'Description'
  ];
  multipleContactsHeader = [
    'Contact #', 'Name','Email'
  ];
  emailInfo: any;
  shipmentSelectedAll: any;
  productSelectedAll: any;
  contactSelectedAll = false;
  normalDialogModal = { show: false, };
  showNormalDialog() {
    this.isEmailSent=false;
    this.shippingitemgridData = [];
    this.productitemgridData = [];
    this.contactGridData = [];
    this.orderDtoCopy = Object.assign({}, this.orderService.orderDto);
    this.orderDtoCopy.orderDetailDtos.forEach(element => {
      const modalItem: ItemGridData=new ItemGridData();
      modalItem.item=element.productCode;
      modalItem.description=element.productDescription;
      modalItem.shiptoDate=element.shipDate;
      modalItem.id=element.id;
      if(element.isElectronic && element.productDto.emailTypeCode && element.orderDetailStatus === 'C')
      {
        this.productitemgridData.push(modalItem);
      }
      else if(element.isPhysical && element.orderDetailStatus === 'C')
      {
        this.shippingitemgridData.push(modalItem);
      }
    });
    this.items.forEach(element => {
      if(element.value === "ProductActivation" &&  this.productitemgridData.length === 0)
      {
        element.disabled = true;
      }
      else if(element.value === "ShipmentConfirmation" &&  this.shippingitemgridData.filter(m=>m.shiptoDate != null).length === 0)
      {
        element.disabled = true;
      }
    });

    this.contactGridData = this.contactService.contactDtos?.filter(item => item.email)
      .sort((x, y) => (x.firstName + ' ' + x.lastName).toLowerCase() > (y.firstName + ' ' + y.lastName).toLowerCase() ? 1 : -1) ?? [];

    this.normalDialogModal.show = true;
    this.clearall();
    this.myRadioGroupSelecteditem = this.items[0];
  }
  closeDialog() {
    this.dialog.show = false;
  }

  okDialog(){
    this.sentEmailDialogModal.show=false;
    this.dialog.show=false;
  }
  profileForm = new UntypedFormGroup({
    radioGroup: new UntypedFormControl(''),
    checkbox1: new UntypedFormControl(),

  });
  profileForm2 = new UntypedFormGroup({
    checkbox: new UntypedFormControl(),
  });


  constructor(emailService: EmailService,
    public modeService: ModeService,
    public orderService: OrderService,
    generatePdfService: GeneratePdfService,
    public contactService: ContactService,
    private readonly authorizationService: AuthorizationService,
    public configService: ConfigService
    ) {
    this._emailService = emailService;
    this._generatePdfService = generatePdfService;
  }

  ngOnInit(): void {
    this.myRadioGroupSelecteditem = this.items[0];

    this.showNormalDialog();

    this.msId = this.configService.getMyMSId();
  }

  productselectAll() {
    for (let i = 0; i < this.productitemgridData.length; i++) {
      this.productitemgridData[i].checked = this.productSelectedAll;
    }
  }
  contactSelectAll() {
    this.contactGridData.forEach(element => {
      element.isSelected = this.contactSelectedAll;
      this.addSelectedEmail(element.email,this.contactSelectedAll);
    });
  }
  selectAll() {
    for (let i = 0; i < this.shippingitemgridData.length; i++) {
      this.shippingitemgridData[i].checked = this.shipmentSelectedAll;
    }
  }
  addSelectedEmail(email: string, isChecked: boolean):void {
    if(isChecked){
      if(!this.email.includes(email)){
        if(this.email!=="")
        {
          this.email += ";";
        }
        this.email += email;
      }
    }
    else{
      this.email = (this.email.includes(";"))? this.email.replace(";"+email,"") : this.email.replace(email,"");
    }
  }
  removeDuplicatesAndBlank(data: string[]): string[] {
    return data.filter((value, index) => data.indexOf(value) === index && value !== '' && value !== null);

  }
  changeEmail() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
    let emailList = [];
    emailList.push(this.orderDtoCopy.orderByDto.email,this.orderDtoCopy.shipToDto.email,currentUser.profile.email);
    this.setEmail(emailList);
    emailList = this.removeDuplicatesAndBlank(emailList);
    this.email = emailList.join(";");
  }
  setEmail(emailList: string[]){
    if (this.orderBycheckbox && this.shipTocheckbox && this.LoggedInUsercheckbox) {
      return;
    } 
    if(!this.orderBycheckbox){
      emailList.splice(0,1,"");
    }
    if(!this.shipTocheckbox){
      emailList.splice(1,1,"");
    }
    if(!this.LoggedInUsercheckbox){
      emailList.splice(2,1,"");
    }
    return;
  }

  setEmailWhenAllCheckboxesChecked(currentUser: any){
    if (this.orderDtoCopy.orderByDto.email === null && this.orderDtoCopy.shipToDto.email === null && currentUser.profile.email === null) {
      this.email = "";
    }
    else if (this.orderDtoCopy.orderByDto.email === this.orderDtoCopy.shipToDto.email && this.orderDtoCopy.orderByDto.email != currentUser.profile.email) {
      this.email = this.orderDtoCopy.orderByDto.email + ";" + currentUser.profile.email;
    }
    else if (this.orderDtoCopy.shipToDto.email === this.orderDtoCopy.orderByDto.email && this.orderDtoCopy.orderByDto.email != currentUser.profile.email) {
      this.email = this.orderDtoCopy.shipToDto.email + ";" + currentUser.profile.email;
    }
    else if (this.orderDtoCopy.shipToDto.email === null) {
      this.email = this.orderDtoCopy.orderByDto.email;
    }
    else if (this.orderDtoCopy.orderByDto.email === null) {
      this.email = this.orderDtoCopy.shipToDto.email;
    }
  }
  clearall() {
    this.showErrorMsg=false;
    if( this.myRadioGroupSelecteditem === this.items[0])
       {
        this.orderBycheckbox=true;
        this.shipTocheckbox=false;
        this.LoggedInUsercheckbox=false;
       }
       if( this.myRadioGroupSelecteditem === this.items[1]|| this.myRadioGroupSelecteditem === this.items[2])
       {
         this.shipTocheckbox=true;
         this.orderBycheckbox=false;
         this.LoggedInUsercheckbox=false;
    }
    if (this.myRadioGroupSelecteditem === this.items[1] && (this.orderDtoCopy.billToDto.accountTypeCode === 'C'||this.orderDtoCopy.billToDto.accountTypeCode === 'H')) {
      this.shipTocheckbox = false;
      this.orderBycheckbox = true;
      this.LoggedInUsercheckbox=false;
    }
    if (this.myRadioGroupSelecteditem === this.items[1] && (this.orderDtoCopy.billToDto.accountTypeCode === 'C'||this.orderDtoCopy.billToDto.accountTypeCode === 'H')){      this.shipTocheckbox = false;
      this.orderBycheckbox = true;
      this.LoggedInUsercheckbox=false;
    }
    if ((this.myRadioGroupSelecteditem.value === "OrderConfirmation") ||
    (this.myRadioGroupSelecteditem.value === "ShipmentConfirmation" && (this.orderDtoCopy.billToDto.accountTypeCode === 'C'||this.orderDtoCopy.billToDto.accountTypeCode === 'H'))) 
    {
      this.email=this.orderDtoCopy.orderByDto.email;
    }
    else
    {
      this.email=this.orderDtoCopy.shipToDto.email;
    }
    this.productSelectedAll = this.productitemgridData.length===1? true: false;
    this.shipmentSelectedAll = false;
    this.contactSelectedAll = false;
    this.helperfunction();
    
  }
  helperfunction(){
    for (let i = 0; i < this.shippingitemgridData.length; i++) {
      this.shippingitemgridData[i].checked = this.shipmentSelectedAll;
    }

    for (let j = 0; j < this.productitemgridData.length; j++) {
      this.productitemgridData[j].checked = this.productSelectedAll;
    }

    for (let k = 0; k < this.contactGridData.length; k++) {
      this.contactGridData[k].isSelected = this.contactSelectedAll;
    }
  }
  async sendprofromaemail()
  {
    this.orderService.displayProFormaInvoice = true;
    document.getElementById('divQuoteCFDPdf1')!.style.display = 'inline-block';
    const quoteCFDPdf = await this._generatePdfService.generateProFormaInvoicePdf();
    document.getElementById('divQuoteCFDPdf1')!.style.width = this._generatePdfService.getPDFDivWidthBasedOnBrowserZoomLevel(Math.round(window.devicePixelRatio * 100));

    quoteCFDPdf.html(document.getElementById('divQuoteCFDPdf1')!, {
      html2canvas: {
        scrollX: 0,
        scrollY: 0
      },
      x: 0,
      y: 0,
      margin: [60, 30, 30, 30],
      autoPaging: 'text',
      callback: (pdf) => {
        this.orderService.displayProFormaInvoice = false;
        document.getElementById('divQuoteCFDPdf1')!.style.display = 'none';
        const quoteCFDEmailDto = new QuoteCFDEmailDto;
        quoteCFDEmailDto.emailFrom = 'Optum Order Confirmations <confirmations@optum.com>';
        quoteCFDEmailDto.toRecipients = this.email;
        quoteCFDEmailDto.ccRecipients = '';
        quoteCFDEmailDto.bccRecipients = '';
        quoteCFDEmailDto.subject = 'Optum Confirmation of Order:' + ' '+ this.orderService.orderDto.id;
        quoteCFDEmailDto.emailBody = '';
        quoteCFDEmailDto.emailAttachment = pdf.output('datauristring');
        let fileName = this.orderService.saveProFormaPdf(pdf, this.msId);
        quoteCFDEmailDto.fileName = fileName;

        this._emailService.sendCfdEmail(quoteCFDEmailDto).subscribe(() => {
          this.modeService.mode = Mode.View;
          this.sentEmailDialogModal.show = true;
          this.isEmailSent = true;
        }, error => {
          this.modeService.mode = Mode.View;
          console.log(error);
          alert('An error occurred while sending the email.Please try again.');
        });
      }
    });
  }

  saveemail() {
    this.showErrorMsg=false;
    this.isEmailSent=false;
    if(this.email !== '' && this.email !== null && new RegExp(this.emailPattern).test(this.email))
    {
      const emailDto : EmailInputDto=new EmailInputDto();
      let orderDetailIds:number[]=[];
      emailDto.emailAddress=this.email;
      emailDto.emailType = this.myRadioGroupSelecteditem.value;
      emailDto.orderId = this.orderDtoCopy.id;
      if(this.myRadioGroupSelecteditem.value === "ShipmentConfirmation")
      {
       orderDetailIds = this.shippingitemgridData.filter(o=>o.checked === true).map(o=>o.id);
      }
      else if(this.myRadioGroupSelecteditem.value === "ProductActivation")
      {
        orderDetailIds = this.productitemgridData.filter(o=>o.checked === true).map(o=>o.id);
      }
      else
      {
        orderDetailIds = this.orderDtoCopy.orderDetailDtos.filter(o=>o.orderDetailStatus !== 'V').map(o => o.id);
      }

      emailDto.orderDetailIds = orderDetailIds;
      if(this.myRadioGroupSelecteditem.value !== "OrderConfirmation" && orderDetailIds.length === 0)
      {
        this.showErrorMsg=true;
      }
      if(this.myRadioGroupSelecteditem.value === "Pro-FormaInvoice")
      {

         this.sendprofromaemail();
      }
      else
      {
        this._emailService.sendEmail(emailDto).subscribe((msg: string) => {
          this.modeService.mode = Mode.View;
          this.sentEmailDialogModal.show = true;
          this.isEmailSent = true;
        }, error => {
          this.modeService.mode = Mode.View;
          console.log(error);
          this.swalAlert.alert('An error occurred while sending the email.Please try again.');
        });
      }
    }
  }

}
