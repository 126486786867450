import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Mode } from '../../enums/mode.enum';
import { AccountsReceivableService } from '../../services/accounts-receivable.service';
import { ConfigService } from '../../services/config.service';
import { ModeService } from '../../services/mode.service';
import { SendInvoicePdfComponent } from './accounts-receivable-results/send-invoice-pdf/send-invoice-pdf.component';
import { InvoiceEditComponent } from './invoice-edit/invoice-edit.component';

@Component({
  selector: 'app-accounts-receivable',
  templateUrl: './accounts-receivable.component.html',
  styleUrls: ['./accounts-receivable.component.css'],
  providers: [InvoiceEditComponent, SendInvoicePdfComponent]

})

export class AccountsReceivableComponent implements OnInit, OnDestroy {
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _configService: ConfigService;
  collapsiblePanel = true;
  openPanel = true;
  panelBackground = 'uitk-l-grid-open';
  accountId = '';
  invoiceNumber = '';
  orderNumber = '';
  Mode = Mode;
  isSearch = false;
  configIsReadySubscription: Subscription | undefined;
  cmFilter = { id: '1', label: 'All', value: '1' };
  invoiceFilter = { id: '1', label: 'All', value: '1' };
  constructor(
    activatedRoute: ActivatedRoute,
    public accountsReceivableService: AccountsReceivableService,
    public modeService: ModeService,
    configService: ConfigService,
    public invoiceEditComponent: InvoiceEditComponent,
    public sendInvoicePdfComponent:SendInvoicePdfComponent
  ) {
    this._activatedRoute = activatedRoute;
    this._configService = configService;
  }

  ngOnInit(): void {
    if (this._activatedRoute.snapshot.params.invoiceId) {
      this.configIsReadySubscription = this._configService.configIsReady.subscribe(
        () => {
          this.invoiceNumber =this._activatedRoute.snapshot.params.invoiceId;
          this.accountId=this._activatedRoute.snapshot.params.accountId;
          this.search();
        }
      );

      this._activatedRoute.params.subscribe(() => {
        this._configService.loadConfigurations();
      });
    } else if (this._activatedRoute.snapshot.params.accountId) {
      this.configIsReadySubscription = this._configService.configIsReady.subscribe(
        () => {
          this.accountId = this._activatedRoute.snapshot.params.accountId;
          this.search();
        }
      );

      this._activatedRoute.params.subscribe(() => {
        this._configService.loadConfigurations();
      });
    }
  }

  setPanelBGHeight() {
    const panelBackgroundOpen = 'uitk-l-grid-open';
    if (this.panelBackground === panelBackgroundOpen)
    {
      this.panelBackground = 'uitk-l-grid1-close';
    }
    else
    {
      this.panelBackground = panelBackgroundOpen;
    }
  }

  search() {
    this._configService.resetIdleTimeout();
    this.modeService.addToWaitList("Loading Account information");
    this.isSearch = true;
    const filter = {
      accountId: this.accountId !== '' ? parseInt(this.accountId) : 0,
      invoiceNumber: this.invoiceNumber !== '' ? parseInt(this.invoiceNumber) : 0,
      orderNumber: this.orderNumber !== '' ? parseInt(this.orderNumber) :0
    };
    this.accountsReceivableService.getSearchAccountReceivables(filter);
    this.accountsReceivableService.getInvoices(filter);
    this.accountsReceivableService.getPaymentDetails(filter);
    this.invoiceEditComponent.closeDialog();
    this.sendInvoicePdfComponent.closeSendInvoicePdfDialog();
    this.cmFilter = { id: '1', label: 'All', value: '1' };
    this.invoiceFilter = { id: '1', label: 'All', value: '1' };

  }

  reload() {
    this.accountsReceivableService.reInvoiceLoad();
  }

  clear() {
    this.accountId = '';
    this.invoiceNumber = '';
    this.orderNumber = '';
    this.isSearch = false;
  }

  ngOnDestroy() {
    this.configIsReadySubscription?.unsubscribe();
  }
}
