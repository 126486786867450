import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightSearch'
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (!value) {
      return '';
    }
    
    if (!args) {
      return value;
    }

    const regex = new RegExp(args, 'gi');
    const match = value.toString().match(regex);

    if (!match) {
      return value.toString();
    }

    return value.toString().replace(regex, `<span class='highlight'>${match[0]}</span>`);
  }
}
