import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../../../services/config.service';
import { OrderService } from '../../../../services/order.service';

@Component({
  selector: 'app-quote-cfd-pdf',
  templateUrl: './quote-cfd-pdf.component.html',
  styleUrls: ['./quote-cfd-pdf.component.css']
})

export class QuoteCfdPdfComponent implements OnInit, OnDestroy {
  private readonly _configService: ConfigService;
 // disclaimerType = ''
  configIsReadySubscription!: Subscription;
  contentSolutionsClientManagerName = '';
  contentSolutionsClientManagerPhone = '';
  contentSolutionsClientManagerEmail = '';
  contentSolutionsClientManagerFax = '';
  @Input() enteredByUserRole : string | null = null;
  @Input() disclaimerType : string | null = null;
  constructor(public orderService: OrderService, configService: ConfigService) {
    this._configService = configService;
  }

  ngOnInit(): void {
    if (this.orderService.orderDto.billToDto.isPORequired) {
      this.disclaimerType = 'porequired';
    } else if (this.orderService.orderDto.billToDto.creditLimit !== 10000 && this.orderService.orderDto.billToDto.creditLimit !== 1000) {
      this.disclaimerType = 'prepayrequired';
    } else {
      this.disclaimerType = 'standard';
    }
    this._configService.loadConfigurations();
    this.configIsReadySubscription = this._configService.configIsReady.subscribe(() => {
      this.getClientManagerName();   
    });
  }

  generateFullName(firstName: string | null, lastName: string | null): string {
    return [firstName, lastName].filter(Boolean).join(' ');
  }

  generateCityStateZip(city: string | null, state: string | null | undefined, zip: string | null): string {
    if(this.orderService.orderDto?.paymentMethod === 'S'){
      zip = this.orderService?.selectedShipToContact?.mailingStreet ? this.orderService.selectedShipToContact?.mailingZip
      : this.orderService?.selectedShipToContact?.street ? this.orderService.selectedShipToContact?.zip
      : this.orderService.orderDto?.shipToDto?.zip;
      state = this.orderService?.selectedShipToContact?.mailingStreet ? this.orderService?.selectedShipToContact?.mailingStateBinding?.value
      : this.orderService?.selectedShipToContact?.street ? this.orderService?.selectedShipToContact?.stateBinding?.value
      : this.orderService.orderDto?.shipToDto?.stateBinding?.value;
      city = this.getCityDetails();
    }
    
    return [[city, state].filter(Boolean).join(', '), zip].filter(Boolean).join(' ');
  }

  getCityDetails(){
    return this.orderService?.selectedShipToContact?.mailingStreet ? this.orderService.selectedShipToContact?.mailingCity
    : this.orderService?.selectedShipToContact?.street ? this.orderService.selectedShipToContact?.city
    : this.orderService.orderDto?.shipToDto?.city;
  }

  getNonTaxableSubTotal(): number {
    return this.orderService.orderDto.orderDetailDtos.filter(o => o.taxAmount === 0).map(o => o.extendedPrice).reduce((a, b) => a + b, 0);
  }

  getTaxableSubtotal(): number {
    return this.orderService.orderDto.orderDetailDtos.filter(o => o.taxAmount > 0).map(o => o.extendedPrice).reduce((a, b) => a + b, 0);
  }

  getEstimatedTax(): number {
    return this.orderService.orderDto.orderDetailDtos.map(o => o.taxAmount).reduce((a, b) => a + b, 0);
  }

  getEstimatedAmountDue(): number {
    const nonTaxableSubTotal = this.getNonTaxableSubTotal();
    const taxableSubTotal = this.getTaxableSubtotal();
    const estimatedTax = this.getEstimatedTax();
    return nonTaxableSubTotal + taxableSubTotal + estimatedTax;
  }
  getClientManagerName(): string {
    if (this.orderService.orderDto.salesOrganization === 'Shutterfly') {
      if (this.orderService.orderDto.billToDto.accountTypeCode === 'O') {
          this.contentSolutionsClientManagerEmail = this._configService.getEmailFromCustomerService('O');
          this.contentSolutionsClientManagerPhone = this._configService.getPhoneFromCustomerService('O');
          this.contentSolutionsClientManagerFax = this._configService.getFaxFromCustomerService('O');
          return this.enteredByUserRole === "SystemAdministrator" || this.enteredByUserRole === "OrderManagement" ? "Melissa Werth" : this._configService.getMyFullName();
      }
      else {
        return this.orderService.orderDto.billToDto.printRepCode? this.getClientManagerInfo(this.orderService.orderDto.billToDto.printRepCode): '';
      }   
    }
    return this.getClientManagerInfo(this.orderService.orderDto.billToDto.accountOwnerCode);
  }
  getClientManagerInfo(userCode: string): string {
    this.contentSolutionsClientManagerEmail = this._configService.getUserEmailFromUserCode(userCode);
    this.contentSolutionsClientManagerPhone = this._configService.getUserPhoneFromUserCode(userCode);
    this.contentSolutionsClientManagerFax = this._configService.getFaxFromSalesforce(userCode);
    return this._configService.getFullNameFromUserCode(userCode); 
  }
  ngOnDestroy(): void {
    this.configIsReadySubscription.unsubscribe();
  }
}
