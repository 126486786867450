import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddressStandardization } from '../dtos/address-standardization-dto.model';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})

export class StandardizationService {
  private readonly _httpClient: HttpClient;
  private readonly _configService: ConfigService;

  constructor(httpClient: HttpClient, configService: ConfigService) {
    this._httpClient = httpClient;
    this._configService = configService;
  }

  standardizeAddress(addressObj: any): Observable<AddressStandardization> {
    return this._httpClient.post<AddressStandardization>(`${this._configService.addressStandardizationUrl}`, addressObj);
  }
}