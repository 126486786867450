import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { UITKLoadingIndicatorModule } from '@uitk/angular';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
})

 export class LoaderComponent {
    visibleTextValue: string = 'Loading Search Results…';

}
