import { Directive, HostListener, ElementRef, OnInit, Renderer2, Input, SimpleChange } from '@angular/core';

@Directive({
  selector: '[appExpand]'
})
export class ExpandDirective implements OnInit {
  @Input() offset: number = 0;
  @HostListener('window:resize', [])
  onResize() {
    this.setHeight();
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.setHeight();
  }

  ngOnChanges(change: {customModel: SimpleChange}) {
    this.setHeight();
  }

  setHeight(): void {
    const newHeight = window.innerHeight - this.offset;
    this.renderer.setStyle(this.elementRef.nativeElement, "height", newHeight + "px");
    this.renderer.setStyle(this.elementRef.nativeElement, "max-height", newHeight + "px");
  }
}
