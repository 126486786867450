import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IUITKSelectItemProps, UITKTableDataSource } from '@uitk/angular';
import { Subscription } from 'rxjs';
import * as states from '../../../../assets/json/states_USA.json';
import { Mode } from '../../../enums/mode.enum';
import { SourceType } from '../../../enums/source-type.enum';
import { Dialog } from '../../../helpers/dialog';
import { BillToDto } from '../../../dtos/bill-to-dto.model';
import { ContactDto } from '../../../dtos/contact-dto.model';
import { ShipToDto } from '../../../dtos/ship-to-dto.model';
import { BillToService } from '../../../services/bill-to.service';
import { ConfigService } from '../../../services/config.service';
import { ContactService } from '../../../services/contact.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';
import { CompleterItem } from 'ng2-completer';
import { SwalAlert } from '../../../helpers/alert';


@Component({
  selector: 'app-ship-to-order-entry',
  templateUrl: './ship-to-order-entry.component.html',
  styleUrls: ['./ship-to-order-entry.component.css']
})

export class ShipToOrderEntryComponent implements OnInit {
  public readonly _contactService: ContactService;
  private readonly _configService: ConfigService;
  private readonly _billToService: BillToService;
  @Output() addressChanged = new EventEmitter<boolean>();
  phonePattern = /^(\d{3})([\/])(\d{3})([\-])(\d{4})([\ ])(\d{5})$/;
  filteredContactsData = new UITKTableDataSource<ContactDto>([]);
  showPhonePatternError = false;
  phone = "123/123-1234 12345";
  contacts: ContactDto[] | null = null;
  stateList: IUITKSelectItemProps[] = [];
  state!: IUITKSelectItemProps | null;
  callFromRefresh = false;
  contactName: IUITKSelectItemProps[] = [];
  Mode = Mode;
  currentYear = new Date().getFullYear();
  orderIsReadySubscription: Subscription | undefined;
  isDataFound = false;
  contactDialogModal = new Dialog<string>();
  activeStatus: string[] = ['A', 'BAD', 'BP'];
  contact: ContactDto[] = [];
  previousContact: ShipToDto = new ShipToDto();
  contactSearchLookupPopup = { show: false };
  billingAddressUpdated = false;
  loading = false;
  visibleTextValue = '';
  contactSearchKeyword = '';
  isSameContactSelected = false;
  showAdvancedContactSearchDialog = new Dialog<string>();
  enteredContact = "";
  swalAlert = new SwalAlert();

  constructor(public orderService: OrderService,
    public contactService: ContactService,
    public modeService: ModeService,
    configService: ConfigService,
    billToService: BillToService) {
    this._contactService = contactService;
    this._configService = configService;
    this._billToService = billToService;
  }

  ngOnInit(): void {
    this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(async () => {
      if(!this.orderService.isSnapshot){
        const contactList = await this._contactService.getContactsAsync(this.orderService.orderDto.billToDto.id);
        if (contactList) {
          this._contactService.contactDtos = contactList;
          this._contactService.shipToContactList = this._contactService.convertContactsToUitkProps(this._contactService.contactDtos);
          this._contactService.shipToContacts = this._contactService.convertContactsToArray(this._contactService.contactDtos);
          this.orderService.orderDto.shipToContactBinding= this._contactService.shipToContactList.filter(x => x.id === this.orderService.orderDto.shipToDto.contactId.toString())[0];
          this.orderService.selectedShipToContact = this._contactService.contactDtos!.filter(c => c.contactId === parseInt(this.orderService.orderDto.shipToDto.contactId.toString()))[0];
          this.orderService.selectedShipToContact.company=this.orderService.orderDto.shipToDto.company;
          this.orderService.selectedShipToContact.department = this.orderService.orderDto.shipToDto.department;
          this.orderService.selectedShipToContact.street = this.orderService.orderDto.shipToDto.street;
          this.orderService.selectedShipToContact.zip = this.orderService.orderDto.shipToDto.zip;
          this.orderService.selectedShipToContact.city = this.orderService.orderDto.shipToDto.city;
          this.orderService.selectedShipToContact.state = this.orderService.orderDto.shipToDto.state;
          this.orderService.selectedShipToContact.country = this.orderService.orderDto.shipToDto.country;
          this.updateShipToSectionAfterContactDetailsAreReady();
        }
      }        
      this.state = this.stateList.filter(s => s.value === this.orderService.orderDto.shipToDto.state?.trim())[0];
    });
    this.orderService.onSaveAndClone.subscribe(async () => {
      const contactList = await this._contactService.getContactsAsync(this.orderService.orderDto.billToDto.id);
      if (contactList) {
        this._contactService.contactDtos = contactList;
        this._contactService.shipToContactList = this._contactService.convertContactsToUitkProps(this._contactService.contactDtos);
        this._contactService.shipToContacts = this._contactService.convertContactsToArray(this._contactService.contactDtos);
        this.orderService.orderDto.shipToContactBinding= this._contactService.shipToContactList.filter(x => x.id === this.orderService.orderDto.shipToDto.contactId.toString())[0];
        this.orderService.selectedShipToContact = this._contactService.contactDtos!.filter(c => c.contactId === parseInt(this.orderService.orderDto.shipToDto.contactId.toString()))[0];
        this.updateShipToSectionAfterContactDetailsAreReady();
      }
      this.enteredContact = "";
    });
    this.stateList = JSON.parse(JSON.stringify((states as any).default));

    this.stateList.forEach(item => {
      item.disabled = false;
    });
  }
  
  shipToContactIdNgModelChange(contactId: number): void {
    console.log(contactId,"contactid");
    this.orderService.orderDto.shipToDto.contactId = contactId;
    const currentSelectedContact = this._contactService.shipToContactList?.filter(x => x.id.toString() === contactId.toString())[0];
    this.enteredContact = "";
    if (currentSelectedContact)
      this.enteredContact = currentSelectedContact?.label;
  }

  async onContactIdChange(event: any) {
    if(this.orderService.orderDto.shipToDto.contactId && this.orderService.orderDto.orderByDto.contactId && this.orderService.orderDto.shipToDto.contactId !== this.orderService.orderDto.orderByDto.contactId){
      this.orderService.shiptoIsSelected = false;
    }
    if (!this.orderService.orderDto.shipToDto.contactId){
      this.contactSearchKeyword = '';
      this.orderService.shiptoIsSelected = false;
      this.orderService.orderDto.shipToContactBinding = { id: '', label: '', value: '' };
    }
    const oldZipCode = this.orderService.orderDto.shipToDto.zip;
    if (!this.orderService?.orderDto?.billToDto?.id) {
      await this.getContactWhenAccountIdIsBlank(oldZipCode);
    }
    else{
      await this.getContactWhenAccountIdIsNotBlank(oldZipCode);
    }
    this.orderService.selectedShipToContact = this._contactService.contactDtos!.filter(c => c.contactId === parseInt(this.orderService.orderDto.shipToDto.contactId.toString()))[0];
  }
  async getContactWhenAccountIdIsBlank(oldZipCode: string): Promise<void> {
    this.orderService.orderDto.billToDto.id = await this.orderService.getAccountIdAsync(this.orderService.orderDto.shipToDto.contactId);
    if (this.orderService.orderDto.billToDto.id) {
      await this._billToService.getBillToInformationAsync(this.orderService.orderDto.billToDto.id, this.currentYear);
      this.orderService.disableAccountEditViewRefreshLinks = false;
      this._billToService.reloadBillToDto();

      const contactList = await this._contactService.getContactsAsync(this.orderService.orderDto.billToDto.id);
      if (contactList) {
        this._contactService.contactDtos = contactList;
        this._contactService.shipToContactList = this._contactService.convertContactsToUitkProps(this._contactService.contactDtos);
        this._contactService.shipToContacts = this._contactService.convertContactsToArray(this._contactService.contactDtos);
        this.orderService.orderDto.shipToContactBinding= this._contactService.shipToContactList.filter(x => x.id === this.orderService.orderDto.shipToDto.contactId.toString())[0];
        this.orderService.selectedShipToContact = this._contactService.contactDtos!.filter(c => c.contactId === parseInt(this.orderService.orderDto.shipToDto.contactId.toString()))[0];
        this.updateShipToSectionAfterContactDetailsAreReady();
        const zipChanged = this.orderService.orderDto.shipToDto.zip !== oldZipCode;
        this.addressChanged.emit(zipChanged);
      }
    }
  }
  async getContactWhenAccountIdIsNotBlank(oldZipCode: string): Promise<void> {
    if(this.orderService.orderDto.shipToDto.contactId){
      const contactList = await this._contactService.getContactsAsync(this.orderService.orderDto.billToDto.id);
      if (contactList) {
        this._contactService.contactDtos = contactList;
        this._contactService.shipToContactList = this._contactService.convertContactsToUitkProps(this._contactService.contactDtos);
        this._contactService.shipToContacts = this._contactService.convertContactsToArray(this._contactService.contactDtos);
        this.orderService.orderDto.shipToContactBinding= this._contactService.shipToContactList.filter(x => x.id === this.orderService.orderDto.shipToDto.contactId.toString())[0];
        this.orderService.selectedShipToContact = this._contactService.contactDtos!.filter(c => c.contactId === parseInt(this.orderService.orderDto.shipToDto.contactId.toString()))[0];
        this.updateShipToSectionAfterContactDetailsAreReady();
        const zipChanged = this.orderService.orderDto.shipToDto.zip !== oldZipCode;
        this.addressChanged.emit(zipChanged);
      }
    }else{
      const contactList = await this._contactService.getContactsAsync(this.orderService.orderDto.billToDto.id);
      if (contactList) {
        this._contactService.contactDtos = contactList.filter(c => this.activeStatus.includes(c.statusCode.trim()));
        this._contactService.shipToContactList = this._contactService.convertContactsToUitkProps(this._contactService.contactDtos);
        this._contactService.shipToContacts = this._contactService.convertContactsToArray(this._contactService.contactDtos);
      }
      this.orderService.orderDto.shipToDto = new ShipToDto();
      this.orderService.orderDto.shipToContactBinding= { id: '', label: '', value: '' };
      this.state = null;
      this.contactSearchKeyword = '';
    }
  }
  sameAsOrderBy() {
    const oldZipCode = this.orderService.orderDto.shipToDto.zip;
    if (this.orderService.orderDto.orderByDto.contactId && this.orderService.shiptoIsSelected) {
      this.orderService.selectedShipToContact = this._contactService.contactDtos!.filter(c => c.contactId === parseInt(this.orderService.orderDto.orderByDto.contactId.toString()))[0];
      this.orderService.orderDto.shipToContactBinding= this._contactService.shipToContactList.filter(x => x.id === this.orderService.orderDto.orderByDto.contactId.toString())[0];
      this.updateShipToSectionAfterContactDetailsAreReady();
      const zipChanged = this.orderService.orderDto.shipToDto.zip !== oldZipCode;
      this.addressChanged.emit(zipChanged);
      const currentSelectedContact = this._contactService.shipToContactList?.filter(x => x.id.toString() === this.orderService.orderDto.orderByDto.contactId.toString())[0];
      this.enteredContact = "";
      if (currentSelectedContact)
        this.enteredContact = currentSelectedContact?.label;
    }
    else {
      this.orderService.orderDto.shipToDto = new ShipToDto();
      this.orderService.orderDto.shipToContactBinding= { id: '', label: '', value: '' };
      this.state = null;
      this.contactSearchKeyword = '';
      this.enteredContact = "";
    }
  }

  async updateShipToSectionAfterContactDetailsAreReady() {
    if (this.orderService.selectedShipToContact) {
      this.fillShipToDto(this.orderService.selectedShipToContact);
    }
    else {
      if (this.orderService.orderDto.billToDto.id && this.orderService.orderDto.shipToDto.contactId) {
        await this.swalAlert.alert("This Contact is not a part of this Account. Please update the Account ID, or change the Contact")
      }

      this.orderService.orderDto.shipToDto = new ShipToDto();
      this.orderService.orderDto.shipToContactBinding= { id: '', label: '', value: '' };
      this.state = null;
      this.contactSearchKeyword = '';
    }
  }

  isEmptyOrUndefined(object: any) {
    return object === '' || object === null || object === undefined ? true : false;
  }

  navigateToSFNewOrEditContact(salesforceContactId: string) {
    window.open(`${this._configService.salesforceUrl}/apex/NewContactPage?id=${salesforceContactId}`);
  }

  navigateToSFViewContact(salesforceContactId: string) {
    window.open(`${this._configService.salesforceUrl}/apex/ViewContact?id=${salesforceContactId}`);
  }

  navigateToSFAddContact(salesforceAccountId: string) {
    window.open(`${this._configService.salesforceUrl}/apex/NewContactPage?accId=${salesforceAccountId}`);
  }

  async refreshShipToContactList(): Promise<void> {
    this.callFromRefresh = true;
    
    const contactList = await this._contactService.getContactsAsync(this.orderService.orderDto.billToDto.id);
    if (contactList) {
      this._contactService.contactDtos = contactList.filter(c => this.activeStatus.includes(c.statusCode.trim()));
      this._contactService.shipToContactList = this._contactService.convertContactsToUitkProps(this._contactService.contactDtos);
      this._contactService.shipToContacts = this._contactService.convertContactsToArray(this._contactService.contactDtos);
      this.orderService.orderDto.shipToContactBinding= this._contactService.shipToContactList.filter(x => x.id === this.orderService.orderDto.shipToDto.contactId.toString())[0];
      this.orderService.selectedShipToContact = this._contactService.contactDtos!.filter(c => c.contactId === parseInt(this.orderService.orderDto.shipToDto.contactId.toString()))[0];
      this.updateShipToSectionAfterContactDetailsAreReady();
    }
    
    if (this.orderService.orderDto.id > 0){
      this._contactService.getOrderShipToAddressDetails(this.orderService.orderDto.id);
    }     
  }

  addressChange(zipChanged: boolean) {
    this.state = this.stateList.filter(s => s.value === this.orderService.orderDto.shipToDto.state?.trim())[0];
    this.addressChanged.emit(zipChanged);
  }

  async contactChangeBR(statusCode: string): Promise<void> {
    if (statusCode === 'BAD' || statusCode === 'BP' || statusCode === 'DEL' || statusCode === 'MGD' || statusCode === 'MRG') {
      this.orderService.sourceType = SourceType.ShipToContactChange;
      this.orderService.shiptoSectionSourceType = SourceType.ShipToContactChange;
      await this.contactDialogModal.openAsync();
    }
  }

  showContactSearchLookupPopup(): void {
    if (this.contactSearchKeyword.trim() === '') {
      this.contactSearchLookupPopup.show = true;
      this.orderService.selectedContact = '';
    }else{
      this.onShipToContactClick(this.contactSearchKeyword);
    }     
  }

  bindSelectedContact(contactDetails: ContactDto): void {
    const oldZipCode = this.orderService.orderDto.shipToDto.zip;
    this.isSameContactSelected = contactDetails.contactId === this.orderService.orderDto?.shipToDto.contactId;
    this.fillShipToDto(contactDetails);
    const zipChanged = this.orderService.orderDto.shipToDto.zip !== oldZipCode;
    this.addressChanged.emit(zipChanged);
  }

  async fillShipToDto(contact: ContactDto): Promise<void> { 
    if((contact.firstName! + ' '+ contact.lastName!) !== this.contactSearchKeyword){
      this.orderService.orderDto.shipToDto.firstName = '';
      this.orderService.orderDto.shipToDto.lastName = '';
      setTimeout(() => {
        this.orderService.orderDto.shipToDto.firstName = contact.firstName!;
        this.orderService.orderDto.shipToDto.lastName = contact.lastName!;
        this.contactSearchKeyword = this.orderService.orderDto.shipToDto.firstName +' '+ this.orderService.orderDto.shipToDto.lastName;
      }, 500);
    }
    else{
      this.orderService.orderDto.shipToDto.firstName = contact.firstName!;
      this.orderService.orderDto.shipToDto.lastName = contact.lastName!;
    }
    let tempContactsName=this._contactService.shipToContactList;
    this.orderService.orderDto.shipToDto.contactId = contact.contactId;
    this.orderService.orderDto.shipToContactBinding = tempContactsName.filter(x => x.id === this.orderService.orderDto.shipToDto.contactId.toString())[0];
    this.orderService.orderDto.shipToDto.middleInitial = contact.middleInitial;
    this.orderService.orderDto.shipToDto.statusCode = contact.statusCode.trim();
    this.orderService.orderDto.shipToDto.statusCodeDescription = this._configService.getPickList('CTCSTATUS')
      .filter(p => p.id === contact.statusCode.trim()).length > 0 ? this._configService.getPickList('CTCSTATUS')
        .filter(p => p.id === contact.statusCode.trim())[0].label : '';
    this.orderService.orderDto.shipToDto.email = contact.email;
    this.orderService.orderDto.shipToDto.phone = contact.phone;
    this.orderService.orderDto.shipToDto.title = contact.title;
    if (this._contactService.contactDtos) {
      this._contactService.shipToContactList = this._contactService.convertContactsToUitkProps(this._contactService.contactDtos);
      this._contactService.shipToContacts = this._contactService.convertContactsToArray(this._contactService.contactDtos);
    }
    this.orderService.orderDto.shipToContactBinding = this._contactService.shipToContactList.filter(x => x.id === contact.contactId.toString())[0];
    
    this.populateShipToDto(contact);

    if (this.isEmptyOrUndefined(this.orderService.orderDto.shipToDto.zip) || this.isEmptyOrUndefined(this.orderService.orderDto.shipToDto.state)
      && this.isEmptyOrUndefined(this.orderService.orderDto.shipToDto.country)) {
      await this.swalAlert.alert('Invalid Shipping Address.');
    }

    this.orderService.orderDto.shipToDto.defaultPaymentMethod = contact.defaultPaymentMethod;
    this.state = this.stateList.filter(s => s.value === this.orderService.orderDto.shipToDto.state?.trim())[0];
    this.orderService.orderDto.shipToDto.salesforceId = contact.salesforceId;
    this.orderService.orderDto.shipToDto.defaultSendRenewalNotices = contact.sendDefaultRenewalNotices;
    if(!this.isSameContactSelected){
      await this.contactChangeBR(this.orderService.orderDto.shipToDto.statusCode);
      this.isSameContactSelected = false;
    }
    this.previousContact = Object.assign({}, this.orderService.orderDto.shipToDto);
    this.orderService.setPaymentMethodBR();
    this.billingAddressUpdated = false;
    if(this.orderService.orderDto.shipToDto.contactId && this.orderService.orderDto.orderByDto.contactId && this.orderService.orderDto.shipToDto.contactId !== this.orderService.orderDto.orderByDto.contactId){
      this.orderService.shiptoIsSelected = false;
    }
  }
  populateShipToDto(contact: ContactDto) : void {
    if (this.isEmptyOrUndefined(contact.street) && this.isEmptyOrUndefined(contact.city) &&
      this.isEmptyOrUndefined(contact.state) && this.isEmptyOrUndefined(contact.zip)) {
        this.orderService.orderDto.shipToDto.company = (this.orderService.orderDto.billToDto.accountTypeCode === 'C' || this.orderService.orderDto.billToDto.accountTypeCode === 'H') ? '' : this.orderService.orderDto.billToDto.abbreviatedCompany ?? '';        
        this.orderService.orderDto.shipToDto.department = this.orderService.orderDto.billToDto.department;
        this.orderService.orderDto.shipToDto.street = this.orderService.orderDto.billToDto.street;
        this.orderService.orderDto.shipToDto.zip = this.orderService.orderDto.billToDto.zip;
        this.orderService.orderDto.shipToDto.city = this.orderService.orderDto.billToDto.city;
        this.orderService.orderDto.shipToDto.state = this.orderService.orderDto.billToDto.state;
        this.orderService.orderDto.shipToDto.country = this._configService.reference!.countryDtos.filter(x => x.alpha2Code === this.orderService.orderDto.billToDto.country)[0].alpha2Code ?? ' ';
    }
    else {
          this.nullCheckContactCompany(contact);
          this.orderService.orderDto.shipToDto.department = contact.department;
          this.orderService.orderDto.shipToDto.street = contact.street;
          this.orderService.orderDto.shipToDto.zip = contact.zip;
          this.orderService.orderDto.shipToDto.city = contact.city;
          this.orderService.orderDto.shipToDto.state = contact.state;
          this.orderService.orderDto.shipToDto.country = contact.country;
    }
  }
  nullCheckContactCompany(contact:ContactDto){
    if (this.isEmptyOrUndefined(contact.company)) {
      this.orderService.orderDto.shipToDto.company = this.orderService.orderDto.billToDto.accountTypeCode === 'C'?'':this.orderService.orderDto.billToDto.abbreviatedCompany ?? '';
    }
    else {
      this.orderService.orderDto.shipToDto.company = contact.company;
    }
  }

  generateFullName(firstName: string | null, lastName: string | null): string {
    this.contactSearchKeyword = this.orderService.orderDto.shipToDto.firstName +' '+ this.orderService.orderDto.shipToDto.lastName;
    return [firstName, lastName].filter(Boolean).join(', ');
  }
  async onShipToContactChange(evt:any){
    this.contactSearchKeyword = evt.target.value;
  }
  async onShipToContactClick(evt:any){
    this.visibleTextValue = 'Loading Records';
    this.loading = true;
    this.contactSearchKeyword= evt;
    this.isSameContactSelected = false;
    await this._configService.getAccountFilteredContacts(this.orderService.orderDto.billToDto.id, "Name:"+this.contactSearchKeyword)
      .then((data: ContactDto[]) => {
        this.loading = false;
        if(data.length === 1){
          this.bindSelectedContact(data[0]);
        }else if(data.length > 0 && (data[0].firstName + " " + data[0].lastName).trim().toLowerCase() === this.contactSearchKeyword.trim().toLowerCase()){
          this.orderService.selectedContact = this.contactSearchKeyword;
          this.contactSearchLookupPopup.show = true;
        }
      });
  }

  async onShipToContactEnter(evt: any) {
    this.contactSearchKeyword = evt.target.value;
    this.showContactSearchLookupPopup();
  }
  async advanceContactSearch()
  {
    await this.showAdvancedContactSearchDialog.openAsync();
  }
  getContactdetail(newOrderByContact: IUITKSelectItemProps) {
    const selectedContact =  this._contactService.contactDtos?.filter(x => x.contactId.toString() === newOrderByContact.id)[0];
    const orderByDto = this.orderService.orderDto.orderByDto;
    if (!orderByDto || !selectedContact) {
      return;
    }
    this.fillShipToDto(selectedContact);
    this.orderService.selectedShipToContact = this._contactService.contactDtos!.filter(c => c.contactId === parseInt(this.orderService.orderDto.shipToDto.contactId.toString()))[0];
    const oldZipCode = this.orderService.orderDto.shipToDto.zip;
    this.updateShipToSectionAfterContactDetailsAreReady();
    const zipChanged = this.orderService.orderDto.shipToDto.zip !== oldZipCode;
    this.addressChanged.emit(zipChanged);
  }

  getContactArrayDetail(selected: CompleterItem) {
    if (selected == null) {
      this.orderService.orderDto.shipToDto.contactId = 0;
      this.orderService.orderDto.shipToDto.phone = "";
      this.orderService.orderDto.shipToDto.email = "";
      this.orderService.orderDto.shipToDto.title = "";
      this.orderService.orderDto.shipToDto.company = "";
      this.orderService.orderDto.shipToDto.department = "";
      this.orderService.orderDto.shipToDto.street = "";
      this.orderService.orderDto.shipToDto.city = "";
      this.orderService.orderDto.shipToDto.state = "";
      this.orderService.orderDto.shipToDto.country = "";
      this.orderService.orderDto.shipToDto.zip = "";
      return;
    }

    let contactIdFromSelection = selected.title.substring(selected.title.lastIndexOf("-")+2);

    const selectedContact = this._contactService.contactDtos?.filter(x => x.contactId.toString() === contactIdFromSelection)[0];
    const orderByDto = this.orderService.orderDto.orderByDto;
    if (!orderByDto || !selectedContact) {
      return;
    }
    this.fillShipToDto(selectedContact);
    this.orderService.selectedShipToContact = this._contactService.contactDtos!.filter(c => c.contactId === parseInt(this.orderService.orderDto.shipToDto.contactId.toString()))[0];
    const oldZipCode = this.orderService.orderDto.shipToDto.zip;
    this.updateShipToSectionAfterContactDetailsAreReady();
    const zipChanged = this.orderService.orderDto.shipToDto.zip !== oldZipCode;
    this.addressChanged.emit(zipChanged);
  }
}
