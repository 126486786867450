
<div [hidden]="modeService.mode == Mode.InvoiceByCCWait" class="css-t7viak">
    <div class="uitk-l-grid">
      <div class="uitk-l-grid__row">
        <div class="uitk-l-grid__col--12">
          <div class="uitk-c-table__caption">
            <h3 id="table-paginate" class="caption" style="color: #002677;">Invoice By CC</h3>
            <br>
          </div>
            <div class="uitk-c-table--responsive" uitkTableContainer [sticky]="true" style="max-height:800px;">
              <table uitkTable [dataSource]="Invoices" class="uitk-c-table" aria-describedby="order-data"
                     id="searchInvoiceCC-results-table" aria-labelledby="table-paginate" aria-describedby="row-data"
                     #rootTable="uitkTable">
  
                <thead class="uitk-c-table__head">
                  <tr uitkTableSort #sortTable="uitkTableSort">
                    <th *ngFor="let headerName of tableHeader" scope="col" uitkTableSortColumn
                        (sortChange)="onSortChange($event)" [column]="headerName.id">
                      {{ headerName.name }}
                    </th>
                  </tr>
                </thead>
                <tbody id="row-data" class="uitk-c-table__body">
                  <ng-container *uitkTableRowBody="let invoice">
                    <tr class="uitk-c-table__row" >
                      <td><a target="_blank" (click)="navigateToAccount(invoice.accountId)"  class="link" >{{invoice.accountId}}</a></td>
                      <td><a target="_blank" (click)="navigateToARInquiry(invoice.accountId,invoice.invoiceNumber)"  class="link">{{invoice.invoiceNumber}}</a></td>
                      <td><a target="_blank" (click)="navigateToOrder(invoice.orderNumber)" class="link">{{invoice.orderNumber}}</a></td>
                      <td>{{invoice.invoiceAmount| currency}}</td>
                      <td>{{invoice.invoiceDate | date: 'MM/dd/yyyy'}}</td>
                      <td>{{invoice.balance | currency}}</td>
                      <td>{{invoice.authCode}}</td>
                    </tr>
                  </ng-container>
                  <div *ngIf="Invoices.data.length === 0">No records found</div>
                </tbody>
              </table>
            </div>
        </div>
      </div>
    </div>
  </div>
  