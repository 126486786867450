<div *ngIf="dialog.show" class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog uitkId="emailNotes" [(showModal)]="dialog.show" [closeBtn]="false" triggerElementId="EmailNotesButtonOE">
    <uitk-dialog-header>
        <div cdkDragHandle>
            Email Notes
        </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
        <div class="row">
            <label uitkLabel class="col-lg-4"  id='orderNotesLabel'>Email order confirmation notes</label>
            <div class="col-lg-8">
                <uitk-form-field>
                    <textarea uitkTextarea [(ngModel)]="emailOrderConfirmationNotes" [textLimit]="255" id="orderNotes"
                      appEnableResource="OrderInquiryAdditionalActionsPackingSlipNotesComments" appAutoFocus></textarea>
                    <uitk-textarea-character-remaining-message (onCountChange)="onCountChangeOrderNotes($event)">
                        {{ remainingCountOrderNotes }} characters remaining
                    </uitk-textarea-character-remaining-message>
                </uitk-form-field>
            </div>
        </div>
        <div class="row">
            <label uitkLabel class="col-lg-4"  id='shippingNotesLabel'>Shipping confirmation notes</label>
            <div class="col-lg-8">
                <uitk-form-field>
                    <textarea uitkTextarea [(ngModel)]="shippingConfirmationNotes" [textLimit]="255" id="shippingNotes"
                      appEnableResource="OrderInquiryAdditionalActionsPackingSlipNotesComments"></textarea>
                    <uitk-textarea-character-remaining-message (onCountChange)="onCountChangeShippingNotes($event)">
                        {{ remainingCountShippingNotes }} characters remaining
                    </uitk-textarea-character-remaining-message>
                </uitk-form-field>
            </div>
        </div>
    </uitk-dialog-content>

    <uitk-dialog-actions>
        <button id="save" type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
            appEnableResource="OrderInquiryAdditionalActionsPackingSlipNotesSaveButton"
            (click)="saveEmailNotes()" >Save</button>
        <button id="cancel" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
            appEnableResource="OrderInquiryAdditionalActionsPackingSlipNotesCancelButton"
            (click)="cancelDialog()">Cancel</button>

    </uitk-dialog-actions>
</uitk-dialog>
    </div>
</div>
