export enum Mode {
  Initialize,
  View,
  Edit,
  Add,
  Wait,
  Disable,
  OrderByWait,
  ShipToWait,
  BillToWait,
  SubscriptionWait,
  CashInquiryWait,
  OrderNotesWait,
  InvoiceByCCWait
}
