import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderNoteDto } from 'src/app/dtos/order-note-dto.model';
import { Mode } from '../../../../enums/mode.enum';
import { OrderService } from '../../../../services/order.service';
import { ConfigService } from 'src/app/services/config.service';
import { OrderNotesService } from 'src/app/services/order-notes.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-save-email-notes',
  templateUrl: './save-email-notes.component.html',
  styleUrls: ['./save-email-notes.component.css']
})

export class SaveEmailNotesComponent implements OnInit {
  @Input() dialog = { show: false };
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();
  Mode = Mode;
  myCharRemaining = 'characters remaining';
  remainingCountOrderNotes = 255;
  notesType: Array<string> = ["OCE", "SCE"];
  remainingCountShippingNotes = 255;
  shippingConfirmationNotes = '';
  emailOrderConfirmationNotes = '';
  orderIsReadySubscription: Subscription | undefined;

  constructor(public orderService: OrderService, public configService: ConfigService, private readonly orderNotesService: OrderNotesService) { }

  async ngOnInit(): Promise<void> {
    if (!this.orderService.orderDto.orderNotes) {
      this.orderNotesService.orderNotesDto = await this.orderNotesService.getOrderNotes(this.orderService.orderDto.id);
      this.assignToNotes(this.orderNotesService.orderNotesDto)
      this.orderService.orderDto.orderNotes = this.orderNotesService.orderNotesDto;
    } else {
      this.assignToNotes(this.orderService.orderDto.orderNotes)
    }

    this.orderService.triggerEditMethod();
  }

  assignToNotes(orderNotes: Array<OrderNoteDto>) {
    orderNotes.forEach((notes: OrderNoteDto) => {
      if (notes.noteType === "OCE") {
        this.emailOrderConfirmationNotes = notes.noteText;
      } else if (notes.noteType === "SCE") {
        this.shippingConfirmationNotes = notes.noteText;
      }

    });
  }

  cancelDialog() {
    this.dialog.show = false;
    this.cancel.emit()

    // If edit method was triggered automatically when opening the form in view mode, then automatically revert the packing slip note.
    if (this.orderService.editWasTriggered) {
      this.orderService.triggerRevertMethod();
    }
  }

  onCountChangeOrderNotes(count: number) {
    this.remainingCountOrderNotes = count;
  }
  onCountChangeShippingNotes(count: number) {
    this.remainingCountShippingNotes = count;
  }

  saveEmailNotes() {
    this.dialog.show = false;

    if (!this.orderService.orderDto.orderNotes || this.orderService.orderDto.orderNotes.length === 0) {
      this.orderService.orderDto.orderNotes = [];
      this.notesType.forEach(noteType => {
        this.createNewOrderNote(noteType);
      });
    } else {
      const newNotesObjet: Array<OrderNoteDto> = [];
      this.orderService.orderDto.orderNotes.forEach((notes, i) => {
        if (!this.orderService.orderDto.orderNotes) {
          this.orderService.orderDto.orderNotes = [];
        }
        const orderNotes = new OrderNoteDto();
        orderNotes.id = notes.id;
        orderNotes.userCode = notes.userCode;
        orderNotes.userName = notes.userName;
        orderNotes.orderType = notes.orderType;
        orderNotes.noteText = notes.noteType === "OCE" ? this.emailOrderConfirmationNotes : this.shippingConfirmationNotes;
        orderNotes.noteType = notes.noteType;
        orderNotes.createdDate = notes.createdDate;
        orderNotes.orderId = this.orderService.orderDto.id;
        if (!this.orderService.orderDto.orderNotes) {
          this.orderService.orderDto.orderNotes = [];
        }
        newNotesObjet.push(orderNotes);
      });
      this.orderService.orderDto.orderNotes = newNotesObjet;
    }
  }
  createNewOrderNote(noteType:any){
    const orderNotes = new OrderNoteDto();
        orderNotes.id = -1;
        orderNotes.userCode = this.configService.getMyUserCode();
        orderNotes.userName = this.configService.getMyFullName();
        orderNotes.orderType = this.orderService.orderDto.orderType;
        orderNotes.noteText = noteType === "OCE" ? this.emailOrderConfirmationNotes : this.shippingConfirmationNotes;
        orderNotes.noteType = noteType;
        orderNotes.createdDate = new Date();
        orderNotes.orderId = this.orderService.orderDto.id;
        if (!this.orderService.orderDto.orderNotes) {
          this.orderService.orderDto.orderNotes = [];
        }
        this.orderService.orderDto.orderNotes.push(orderNotes);
  }
  
}
