import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IUITKColumnState, UITKSortDirection, UITKTableDataSource, UITKTableSortDirective } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Mode } from '../../enums/mode.enum';
import { AuthorizationDto } from '../../dtos/authorization-dto.model';
import { ConfirmAuthorizationDto } from '../../dtos/confirm-authorization-dto.model';
import { ConfigService } from '../../services/config.service';
import { ConfirmAuthorizationService } from '../../services/confirm-authorization.service';
import { ModeService } from '../../services/mode.service';
import { SwalAlert } from '../../helpers/alert';

@Component({
  selector: 'app-confirm-authorizations',
  templateUrl: './confirm-authorizations.component.html',
  styleUrls: ['./confirm-authorizations.component.css']
})

export class ConfirmAuthorizationsComponent implements OnInit, OnDestroy {
  private readonly _confirmAuthorizationService: ConfirmAuthorizationService;
  private readonly _modeService: ModeService;
  private readonly _currencyPipe: CurrencyPipe;
  private readonly _configService: ConfigService;
  private readonly _router: Router;
  tableHeader = [
    { name: 'Account ID', id: 'accountId' },
    { name: 'Auth #', id: 'id' },
    { name: 'Auth Amt', id: 'amount' },
    { name: 'Auth Date', id: 'authorizationDate' },
    { name: 'Order #', id: 'orderId' },
    { name: 'CM Flag', id: 'cmFlag' },
    { name: 'Account Name', id: 'company' },
    { name: 'Source', id: 'returnCode' },
    { name: 'Acct Type', id: 'accountType' },
    { name: 'Entered By', id: 'enteredBy' },
    { name: 'Notes', id: 'notes' },
  ];
  selectAllAuthorizations = false;
  count = 0;
  totalAmount = 0;
  dataSource = new UITKTableDataSource<AuthorizationDto>([]);
  data: AuthorizationDto[] = [];
  @ViewChild('sortTable') uitkTableSort!: UITKTableSortDirective;
  configIsReadySubscription!: Subscription;
  swalAlert = new SwalAlert();

  constructor(
    confirmAuthorizationService: ConfirmAuthorizationService,
    modeService: ModeService,
    currencyPipe: CurrencyPipe,
    configService: ConfigService,
    router: Router) {
      this._confirmAuthorizationService = confirmAuthorizationService;
      this._modeService = modeService;
      this._currencyPipe = currencyPipe;
      this._configService = configService;
      this._router = router;
  }

  ngOnInit(): void {
    this.configIsReadySubscription = this._configService.configIsReady.subscribe(() => {
      this.getAuthorizations();
    });

    this._configService.loadConfigurations();
  }

  getAuthorizations(): void {
    this._confirmAuthorizationService.getAuthorizations().subscribe(data => {
      this.dataSource.data = data;
      for(const authorizationDto of this.dataSource.data) {
        if(authorizationDto.notes[0].noteText != null)
        {
          authorizationDto.noteSort = authorizationDto.notes[0].noteText.toLowerCase().trim();
        }
      }
      this.totalAmount = data.reduce((a, b) => a + b.amount, 0);
      this.count = data.length;
      this._modeService.mode = Mode.View;
    });
  }

  ngAfterViewInit(): void {
    this.uitkTableSort.setDirection({ column: 'id', direction: UITKSortDirection.ASC });
    this.dataSource.sort = this.uitkTableSort;
  }

  onSortChange(event: IUITKColumnState): void {
    if (event.column === 'notes') {
      event.column = "noteSort"
    }

    this.dataSource.data = this.dataSource.sortData(this.dataSource.data, event.direction, event.column);
  }

  navigateToSFViewAccount(salesforceId: string): void {
    window.open(`${this._configService.salesforceUrl}/apex/ViewAccount?id=${salesforceId}`);
  }

  navigateToARInquiry(accountId: number): void {
    this._router.navigate([]).then(() => { 
      window.open('/accountreceivable/arinquiry/' + accountId, '_blank'); 
    });
  }

  saveAuthorizations(): void {
    this._configService.resetIdleTimeout();
    const confirmAuthorizationDto = new ConfirmAuthorizationDto();
    confirmAuthorizationDto.userId = this._configService.getMyMSId();
    confirmAuthorizationDto.authorizationIds = this.dataSource.data.filter(item => item.isSelected).map(item => item.id);
    this._modeService.mode = Mode.Wait;

    this._confirmAuthorizationService.confirmAuthorizations(confirmAuthorizationDto).subscribe(() => {
      this.getAuthorizations();
      const totalCount = confirmAuthorizationDto.authorizationIds.length;
      const totalAmount = this.dataSource.data.filter(item => item.isSelected).reduce((sum, current) => sum + current.amount, 0);
      this.swalAlert.alert(`${totalCount} authorizations confirmed for ${this._currencyPipe.transform(totalAmount, 'USD')}`);
    }, (error: any) => {
      console.log(error);
      this.swalAlert.alert('Error occurred when confirming authorizations');
    });
  }

  selectAll(): void {
    for (const authorization of this.dataSource.data) {
      authorization.isSelected = this.selectAllAuthorizations;
    }
  }

  ngOnDestroy(): void {
    this.configIsReadySubscription?.unsubscribe();
  }
}
