import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { finalize, share, tap } from "rxjs/operators";
import { ApprovalDto } from "../dtos/approval-dto.model";
import { ApprovalRequestsDto } from "../dtos/approval-requests-dto";
import { OrderApprovalDefaultDto } from "../dtos/order-approval-default-dto.model";
import { OrderApprovalDto } from "../dtos/order-approval-dto.model";
import { OrderApprovalInputDto } from "../dtos/order-approval-input-dto.model";
import { OrderDetailDto } from "../dtos/order-detail-dto.model";
import { OrderDto } from "../dtos/order-dto.model";
import { PriceCatalogApprovalDto } from "../dtos/price-catalog-approval-dto-model";
import { SaveOrderExceptionDto } from "../dtos/save-order-exception-dto.model";
import { BillToService } from "./bill-to.service";
import { ConfigService } from "./config.service";
import { ModeService } from "./mode.service";
import { OrderService } from "./order.service";
import { PricingService } from "./pricing.service";
import { SplApprovalRequestDto } from "../dtos/spl-approval-request-dto-model";
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root'
})

export class ApprovalService {
  private readonly _httpClient: HttpClient;
  private readonly _configService: ConfigService;
  private readonly _billToService: BillToService;
  private readonly _orderService: OrderService;
  private readonly _pricingService: PricingService;
  approvalDto: ApprovalDto = new ApprovalDto();
  orderApprovalDto: OrderApprovalDto = new OrderApprovalDto();
  // splApprovalDto: SplApprovalDto = new SplApprovalDto();
  splApprovalRequestDto: SplApprovalRequestDto = new SplApprovalRequestDto();
  orderApprovalIsReady = new Subject();
  approvalsCache!: ApprovalDto[] | null;
  approvalsCachedObservable!: Observable<ApprovalDto[]> | null;
  orderApprovalInputDto: OrderApprovalInputDto = new OrderApprovalInputDto();
  nonExemptedItemsForApprovals: OrderDetailDto[] = [];
  orderApprovalHistoryCache!: ApprovalDto[] | null;
  orderApprovalHistoryCachedObservable!: Observable<ApprovalDto[]> | null;
  orderApprovalDefaultDtoCache!: OrderApprovalDefaultDto[] | null;
  orderApprovalDefaultDtoCachedObservable!: Observable<OrderApprovalDefaultDto[]> | null;
  orderApprovalHistoryDto: ApprovalDto[] = [];
  approvalsList: ApprovalDto[] = [];
  itemsToApprove: ApprovalRequestsDto[] = [];
  itemsToApprove1: [] = [];
  orderApprovalDefaults: OrderApprovalDefaultDto[] = [];
  updatedThresholds: boolean = false;
  showApprovalHistoryOnQuote = false;
  selectedItemToApprove: ApprovalRequestsDto | null = null;
  priceCatalogApprovalDto: PriceCatalogApprovalDto = new PriceCatalogApprovalDto();
  priceCatalogApprovalIsReady = new Subject();
  swalAlert = new SwalAlert();

  constructor(httpClient: HttpClient, configService: ConfigService, billToService: BillToService, orderService: OrderService, pricingService: PricingService, public modeService: ModeService) {
    this._httpClient = httpClient;
    this._configService = configService;
    this._billToService = billToService;
    this._orderService = orderService;
    this._pricingService = pricingService;
  }

  getApprovals(printRepCode: string, accountOwnerCode: string, accountTypeCode: string, firstItemInTheCartIsElectronic: boolean, orderTypeCode: string): Observable<ApprovalDto[]> {
    let observable: Observable<ApprovalDto[]>;

    if (this.approvalsCache) {
      observable = of(this.approvalsCache);
    }
    else if (this.approvalsCachedObservable) {
      observable = this.approvalsCachedObservable;
    }
    else {
      this.approvalsCachedObservable = this._httpClient.get<ApprovalDto[]>
        (`${this._configService.apiUrl}/approval/getApprovals?printRepCode=${printRepCode}&accountOwnerCode=${accountOwnerCode}&accountTypeCode=${accountTypeCode}&firstItemInTheCartIsElectronic=${firstItemInTheCartIsElectronic}&orderTypeCode=${orderTypeCode}`)
        .pipe(
          tap((response: ApprovalDto[]) => {
            this.approvalsCache = response;
          }),
          share(),
          finalize(() => {
            this.approvalsCache = null;
            this.approvalsCachedObservable = null;
          })
      );

      observable = this.approvalsCachedObservable;
    }

    return observable;
  }
  async getApprovalsAsync(printRepCode: string, accountOwnerCode: string, accountTypeCode: string, firstItemInTheCartIsElectronic: boolean, orderTypeCode: string): Promise<ApprovalDto[] | null> {
    return new Promise(resolve => {
      const message = 'Getting Approvals';
      this.modeService.addToWaitList(message);
      this._httpClient.get<ApprovalDto[]>(`${this._configService.apiUrl}/approval/getApprovals?printRepCode=${printRepCode}&accountOwnerCode=${accountOwnerCode}&accountTypeCode=${accountTypeCode}&firstItemInTheCartIsElectronic=${firstItemInTheCartIsElectronic}&orderTypeCode=${orderTypeCode}`).subscribe(data => {
        this.modeService.removeFromWaitList(message);
        resolve(data);
      }, async error => {
        this.modeService.removeFromWaitList(message);
        await this.swalAlert.alert('Error getting Approvals');
        resolve(null);
      });
    });
  }

  async getDefaultOrderApprovalsAsync(): Promise<OrderApprovalDefaultDto[] | null> {
    return new Promise(resolve => {
      const message = 'Getting Default Order Approvals';
      this.modeService.addToWaitList(message);
      this._httpClient.get<OrderApprovalDefaultDto[]>(`${this._configService.apiUrl}/approval/GetOrderApprovalDefaults`).subscribe(data => {
        this.modeService.removeFromWaitList(message);
        resolve(data);
      }, async error => {
        this.modeService.removeFromWaitList(message);
        await this.swalAlert.alert('Error getting Order Approval Defaults');
        resolve(null);
      });
    });
  }

  saveOrderException(saveOrderExceptionDto: SaveOrderExceptionDto) {
    return this._httpClient.put(`${this._configService.apiUrl}/Approval/SaveOrderException`, saveOrderExceptionDto);
  }

  getOrderApproval(orderApprovalId: number) {
    this._httpClient.get<OrderApprovalDto>(`${this._configService.apiUrl}/Approval/GetOrderApproval/${orderApprovalId}`).subscribe((data: OrderApprovalDto) => {
      this.orderApprovalDto = data;
      this.orderApprovalIsReady.next(this.approvalDto);
    },
      (error: any) => {
        this.swalAlert.alert(`An error occurred while getting OrderApprovalDto for  ${orderApprovalId}. Please try again.`);
      });
  }

  recordApproval() {
    return this._httpClient.put(`${this._configService.apiUrl}/Approval/RecordApproval`, this.approvalDto)
  }

  getOrderApprovalHistory(orderDto: OrderDto): void {
    let observable: Observable<ApprovalDto[]>;

    if (this.orderApprovalHistoryCache) {
      observable = of(this.orderApprovalHistoryCache);
    }
    else if (this.orderApprovalHistoryCachedObservable) {
      observable = this.orderApprovalHistoryCachedObservable;
    }
    else {
      this.orderApprovalHistoryCachedObservable = this._httpClient.get<ApprovalDto[]>(`${this._configService.apiUrl}/approval/getapprovalhistory/${orderDto.id}`)
        .pipe(
          tap((response: ApprovalDto[]) => {
            this.orderApprovalHistoryCache = response;
          }),
          share(),
          finalize(() => {
            this.orderApprovalHistoryCache = null;
            this.orderApprovalHistoryCachedObservable = null;
          })
        );

      observable = this.orderApprovalHistoryCachedObservable;
    }

    observable.subscribe((data: ApprovalDto[]) => {
      this.orderApprovalHistoryDto = data;
      this.refreshApprovalFields(orderDto);
    });
  }
  getOrderApprovalHistoryForSnapshot(orderDto: OrderDto): void {
    let observable: Observable<ApprovalDto[]>;

    if (this.orderApprovalHistoryCache) {
      observable = of(this.orderApprovalHistoryCache);
    }
    else if (this.orderApprovalHistoryCachedObservable) {
      observable = this.orderApprovalHistoryCachedObservable;
    }
    else {
      this.orderApprovalHistoryCachedObservable = this._httpClient.get<ApprovalDto[]>(`${this._configService.apiUrl}/approval/GetApprovalHistoryForSnapshot/${orderDto.id}`)
        .pipe(
          tap((response: ApprovalDto[]) => {
            this.orderApprovalHistoryCache = response;
          }),
          share(),
          finalize(() => {
            this.orderApprovalHistoryCache = null;
            this.orderApprovalHistoryCachedObservable = null;
          })
        );

      observable = this.orderApprovalHistoryCachedObservable;
    }

    observable.subscribe((data: ApprovalDto[]) => {
      this.orderApprovalHistoryDto = data;
      this.refreshApprovalFields(orderDto);
    });
  }



  async refreshApprovalFields(orderDto: OrderDto): Promise<OrderApprovalInputDto> {
    this.nonExemptedItemsForApprovals = this.refreshNonExemptedItemsForApprovals(orderDto);
    this.orderApprovalInputDto.totalExtendedPrice = this.calculateTotalExtendedPrice();
    this.orderApprovalInputDto.itemsBelowFP = this.calculateItemsBelowFP(orderDto);
    this.orderApprovalInputDto.itemsAboveLP = this.calculateItemsAboveLP(orderDto);
    this.orderApprovalInputDto.allowedPercentage = this.calculateAllowedPercentage(orderDto);
    this.orderApprovalInputDto.approvalRequired = await this.isApprovalRequired(orderDto);
    this.orderApprovalInputDto.margin = this.calculateMargin(orderDto);
    orderDto.orderApprovalDetailsDto = this.orderApprovalInputDto;
    if (this._orderService.orderDto.orderDetailDtos.length >= 1 && this._orderService.orderDto.orderDetailDtos[0].productCode !== '') {
      await this.refreshApprovalRequirementsSection();
    }
    return this.orderApprovalInputDto;
  }
  refreshNonExemptedItemsForApprovals(orderDto: OrderDto): OrderDetailDto[] {
    return orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V' && o.productDto.productCode !== '' && o.productDto.productCode !== '0010' && !o.productDto.allowCustomStandard && !o.productDto.allowCustomPrice && !o.productDto.excludeApprovals
      && o.promotionEntryCode !== 'ILT' && o.promotionEntryCode !== 'ILP' && o.promotionEntryCode !== 'OLT' && o.promotionEntryCode !== 'OLP' && o.productId !== 0
      && (o.discount !== this._pricingService.getSplDiscount(orderDto, o.productId) || orderDto.billToDto.accountPricingDtos?.length === 0 )
      && !(o.subscriptionDto && o.subscriptionDto.subscriptionType === 'T') && !(o.parentOrderDetailId && orderDto.orderDetailDtos.filter(or=> or.id === o.parentOrderDetailId)[0].subscriptionDto?.subscriptionType === 'T'));
}

  async refreshApprovalRequirementsSection(): Promise<void> {

    const data = await this.getApprovalsAsync(this._orderService.orderDto.billToDto.printRepCode ?? '', this._orderService.orderDto.billToDto.accountOwnerCode ?? '', this._orderService.orderDto.billToDto.accountTypeCode, this._orderService.orderDto.orderDetailDtos.filter(x => x.orderDetailStatus !== 'V')[0].productDto.salesOrganization === 'Optum', this._orderService.orderDto.orderType ?? '') ?? [];
        data.filter(a => a.approvalLevel === 'Submitter')[0].approver = data
          .filter(a => a.approvalLevel === 'Submitter')[0].approver === ''
          ? this._configService.getFullNameFromUserCode(this._orderService.orderDto.billToDto.accountOwnerCode)
          : data.filter(a => a.approvalLevel === 'Submitter')[0].approver;
        this.approvalsList.length = 0;
        this.approvalsList = data;
        //if(!this.updatedThresholds)
        this.updateApprovalThresholds();
  }

  updateApprovalThresholds(){    
    for(let i=0;i<this.approvalsList.length-1;i++){      
      this.approvalsList[i].threshold = this.approvalsList[i+1]?.threshold ?? 0.00;
    }
    this.updatedThresholds = true;
  }

  calculateTotalExtendedPrice(): number {
    // DE244995 : we have changed the alowed percentage calculation and also total extended price should equal to sub total, 
    this.orderApprovalInputDto.totalExtendedPrice = this._orderService.orderDto.openAmount;
    return this.orderApprovalInputDto.totalExtendedPrice;
  }

  calculateItemsBelowFP(orderDto: OrderDto): string {
    const belowFPOrderDetailDtos = this.nonExemptedItemsForApprovals.filter(o => this._pricingService.round(o.unitPrice,2) < (o.productDto.floorPrice ?? 0)) ;
        return belowFPOrderDetailDtos.map(o => o.productCode).join(', ');
  }

  calculateItemsAboveLP(orderDto: OrderDto): string {//TODO: Adjust logic for tiered pricing to use the first price to calculate the list price
    return this.nonExemptedItemsForApprovals
      .filter(o => o.productId > 0 && (this._pricingService.round((o.unitPrice), 2)) > (o.productDto.listPrice ?? 0))
      .map(o => o.productCode).join(', ');
  }

  calculateAllowedPercentage(orderDto: OrderDto): number {
    orderDto.orderDetailDtos.forEach(o => {
      o.eligibleAllowedPercentage = 1;
      o.allowedPrice = this._pricingService.getAllowedPrice(o, o.orderQuantity);
    })
    this.nonExemptedItemsForApprovals.forEach(o => {        
      o.eligibleAllowedPercentage = this._pricingService.getAllowedPrice(o, o.orderQuantity) !== 0 ? o.unitPrice / this._pricingService.getAllowedPrice(o, o.orderQuantity) : 100;
    });

    const totalAllowedPercentage = this.nonExemptedItemsForApprovals.reduce((prev, next) => prev + next.eligibleAllowedPercentage, 0);
    this.orderApprovalInputDto.allowedPercentage = isFinite(totalAllowedPercentage / this.nonExemptedItemsForApprovals.length) ?
      (totalAllowedPercentage / this.nonExemptedItemsForApprovals.length) * 100 : 100;

    return this.orderApprovalInputDto.allowedPercentage;
  }

  async isApprovalRequired(orderDto: OrderDto): Promise<string> {
    if(this.orderApprovalDefaults === null || this.orderApprovalDefaults.length === 0){
      this.orderApprovalDefaults = await this.getDefaultOrderApprovalsAsync() ?? [];
    }
    const defaultApprovalThreshold = this.orderApprovalDefaults?.find(x => x.approvalOrder === 1)?.threshold ?? 0; //?

    if ((this.orderApprovalInputDto.itemsBelowFP !== '' || this.orderApprovalInputDto.itemsAboveLP !== '' ||
      (this.orderApprovalInputDto.allowedPercentage < defaultApprovalThreshold && this.nonExemptedItemsForApprovals.length > 0)) && this.nonExemptedItemsForApprovals
        .filter(o => o.productId > 0).length > 0) {
//   =======
//     var defaultApprovalThreshold = this.orderApprovalDefaults?.find(x => x.approvalOrder === 1)?.threshold ?? 0;
//     let isApprovalDetailDtos = orderDto.orderDetailDtos.filter(o => o.productDto.productCode !== '' && o.productDto.classCode !== 'SH' && o.productDto.classCode !== 'XS' &&
//       !o.productDto.allowCustomStandard && !o.productDto.allowCustomPrice &&
//       o.productDto.productCode !== '7825' && o.productDto.productCode !== 'CS23' && o.promotionEntryCode !== 'ILT' && o.promotionEntryCode !== 'ILP' &&
//       o.promotionEntryCode !== 'OLT' && o.promotionEntryCode !== 'OLP' && o.productId !== 0 && (o.discount !== this._pricingService.getSplDiscount(orderDto,o.productId) || orderDto.billToDto.accountPricingDtos?.length == 0));
//       isApprovalDetailDtos.forEach(element => {
//         if(element.configSetExist && element.subscriptionDto && element.subscriptionDto.term<=2){
//           isApprovalDetailDtos = isApprovalDetailDtos.filter(o => o.id != element.id);
//         }
//       });
//       let checkForOtherThanExemptItems = isApprovalDetailDtos.length;
//       if (orderDto.billToDto.accountTypeCode !== 'I' && (((orderDto.billToDto.accountTypeCode !== 'C' && this.orderApprovalInputDto.itemsBelowFP !== '') || this.orderApprovalInputDto.itemsAboveLP !== '' ||
//       ((orderDto.billToDto.accountTypeCode !== 'C' && this.orderApprovalInputDto.allowedPercentage < defaultApprovalThreshold) && checkForOtherThanExemptItems > 0)) && isApprovalDetailDtos
//         .filter(o => o.productId > 0).length > 0)) {
// >>>>>>> OEHardLaunch
      this.orderApprovalInputDto.approvalRequired = 'Yes';
    }
    else {
      this.orderApprovalInputDto.approvalRequired = 'No';
    }

    return this.orderApprovalInputDto.approvalRequired;
  }

  calculateMargin(orderDto: OrderDto): number {
    let marginDetailDtos = orderDto.orderDetailDtos.filter(o => o.productId !== 0);
    marginDetailDtos.forEach(element => {
      if (element.subscriptionDto && element.subscriptionDto.subscriptionType === 'T'){
        marginDetailDtos = marginDetailDtos.filter(o => o.id !== element.id);
      }
    });
    return marginDetailDtos.map(o => o.extendedPrice - (o.orderQuantity * o.productDto.floorPrice)).reduce((prev, next) => prev + next, 0);
  }

  async getItemsToApproveAsync(user: string): Promise<void> {
    const waitingFor = 'Getting Items To Approve.';
    this.modeService.addToWaitList(waitingFor);
    await this._httpClient.get<ApprovalRequestsDto[]>(`${this._configService.apiUrl}/Approval/GetItemsToApprove?userName=${user}`).toPromise().then((data: ApprovalRequestsDto[]) => {
      this.itemsToApprove = data;
      this.modeService.removeFromWaitList(waitingFor);
    },
      async (error: any) => {
        this.modeService.removeFromWaitList(waitingFor);
        console.log(error);
        await this.swalAlert.alert(`An error occurred while getting list of Items To Approve. Please try again.`);
      });
  }

  async getSplItemsToApproveAsync(user: string): Promise<void> {
    const waitingFor = 'Getting Items To Approve.';
    this.modeService.addToWaitList(waitingFor);
    await this._httpClient.get<SplApprovalRequestDto[]>(`${this._configService.priceMasterAPIUrl}/Spl/GetSplItemsToApprove?userName=${user}`).toPromise().then((data: SplApprovalRequestDto[]) => {
      this.itemsToApprove = this.itemsToApprove.concat(data);
      this.modeService.removeFromWaitList(waitingFor);
    },
      async (error: any) => {
        this.modeService.removeFromWaitList(waitingFor);
        console.log(error);
        await this.swalAlert.alert(`An error occurred while getting list of Items To Approve. Please try again.`);
      });
  }
  
  async getPriceCatalogItemsToApproveAsync(user: string): Promise<void> {
    const waitingFor = 'Getting Price catalog Items To Approve.';
    this.modeService.addToWaitList(waitingFor);
    await this._httpClient.get<ApprovalRequestsDto[]>(`${this._configService.productMasterApiUrl}/Approval/GetItemsToApprove?userName=${user}`).toPromise().then((data: ApprovalRequestsDto[]) => {
      this.itemsToApprove = this.itemsToApprove.concat(data);
      this.modeService.removeFromWaitList(waitingFor);
    },
      async (error: any) => {
        this.modeService.removeFromWaitList(waitingFor);
        console.log(error);
        await this.swalAlert.alert(`An error occurred while getting list of Items To Approve. Please try again.`);
      });
  }

  getPriceCatalogApproval(priceCatalogApprovalId: number) {
    this._httpClient.get<PriceCatalogApprovalDto>(`${this._configService.productMasterApiUrl}/Approval/GetPriceCatalogApproval?priceCatalogApprovalId=${priceCatalogApprovalId}`).subscribe((data: PriceCatalogApprovalDto) => {
      this.priceCatalogApprovalDto = data;
      this.priceCatalogApprovalIsReady.next(this.approvalDto);
    },
      (error: any) => {
        this.swalAlert.alert(`An error occurred while getting PriceCatalogApprovalDto for ${priceCatalogApprovalId}. Please try again.`);
      });
  }
}
