<div class="container-fluid px-0" id="orderDetailsSection">
  <div class="row mt-3">
    <div class="minWidth12Percent d-flex">
      <span class="orderEntryHeader">
        Order Details
      </span>
    </div>
    <div class="col-6 d-flex">
      <div class="uitk-icon uitk-icon-search sm-icon searchIcon align-self-center mx-2">
        <a class="link" href="javaScript:void(0)" hotkeyCommand="ctrl+f" (click)="itemSearch()" id="itemSearchLink"  (callSearchMethod)="itemSearch()"
           scrollToTarget>Item Catalog</a>
      </div>
    </div>
  </div>
  <div class="row mt-3">

    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Source Code</label>
      </div>
      <div class="col-6 d-flex">
        <uitk-form-field class="col-12">
          <uitk-select class="d1" uitkId="sourceCodeOE" id="source-Code-Select" appEnableResource="OrderHeaderSourceCode"
                       [itemList]="sourceCodeList" [(ngModel)]="sourceCode"
                       (onDropdownChange)="onChangeSourceCode($event)" [disabled]="modeService.mode !== Mode.Edit"  scrollToTarget target="#orderDetailsSection"></uitk-select>
        </uitk-form-field>
      </div>
    </div>

    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>List Code</label>
      </div>
      <div class="col-6 d-flex">
        <uitk-form-field>
          <input uitkInput id="txtListCode" class="textbox" type="text" [(ngModel)]="orderService.orderDto.listCode"
            [disabled]="modeService.mode !== Mode.Edit" (keydown.Tab)="onChangeListCode()"
            />
        </uitk-form-field>
      </div>
    </div>

    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Promo Code</label>
      </div>
      <div class="col-6 d-flex">
        <uitk-form-field>
          <input uitkInput id="txtPromoCode" class="textbox " type="text" [(ngModel)]="orderService.orderDto.promotionCode"
                 (keydown.Tab)="onChangePromoCode()" [disabled]="modeService.mode !== Mode.Edit"/>
        </uitk-form-field>
      </div>
    </div>

  </div>
  <div class="row mt-3">
    <div class="col-4 col-lg-12 d-flex">
      <div class="col-1 d-flex" style="width: 8%">
        <label class="align-self-center label" uitkLabel>Promotion Offer</label>
      </div>
      <div class="col-lg-10 txtBoxPromotionOffer" 
           [innerHtml]="orderService.orderDto.promotionHtml">
           <!-- TODO: We need to restore the safeHtml pipe -->
      </div>
    </div>
  </div>

  <div class="row gx-0 mt-3 divRows">
    <div class="col-12 ml-3">
      <div id="divLeftOrderDetailsGrid" class="divColumns table-responsive" style="width: 40%;">
        <table id="tblLeftOrderDetailsGrid" style="border-left: 0px; width: 100%; white-space: nowrap;">
          <thead>
            <tr style="height: 60px; border-bottom: 2px solid rgb(194, 86, 8);">
              <th scope="col" style="width: 40px;">
                <span class="uitk-icon uitk-icon-trash_delete gridIcons" style="cursor: pointer;"
                      [customDisabled]="filteredOrderDetails.length <= 1 || modeService.mode !== Mode.Edit"
                      appEnableResource="OrderInquiryGridDeleteicon" (click)="deleteAllItems()"></span>
              </th>
              <th scope="col" style="width: 40px;">
                {{ columns[0].title }}
              </th>
              <th scope="col">
                {{ columns[1].title }}
              </th>
              <th scope="col">
                {{ columns[2].title }}
              </th>
              <th scope="col">
                {{ columns[3].title }}
              </th>
              <th scope="col">
                {{ columns[4].title }}
              </th>
            </tr>
          </thead>
          <tfoot style="background-color: #EFEFEE;">
            <tr>
              <td colspan="5" style="font-family: Arial-BoldMT, Arial Bold, Arial, sans-serif; font-weight: 700;
          font-style: normal; font-size: 16px;">
                Total
              </td>
              <td>
                <uitk-form-field>
                  <input type="text" id="tfOrderQuantity" uitkInput [(customModel)]="orderService.orderDto.totalQuantity"
                         name="tfOrderQuantity" style="width: 80px;" appQuantityFormat disabled>
                </uitk-form-field>
              </td>
            </tr>
          </tfoot>
          <tbody id="orderDetailDtos-left-row-data">
            <tr *ngFor="let orderDetailDto of filteredOrderDetails; let i = index"
                style="border-bottom: 1px solid #d0d0ce;">
              <!-- Trash Icon -->
              <td class="align-middle">
                <span class="uitk-icon uitk-icon-trash_delete gridIcons" style="cursor: pointer;"
                      *ngIf="(orderDetailDto.classCode != 'SH' || orderService.orderDto.shipmentMethod === 'DONOTSHIP' || orderService.orderDto.shipmentMethod === 'MISC') && orderDetailDto.productCode !== ''"
                      [customDisabled]="
                        orderDetailDto.orderDetailStatus === 'C' || orderDetailDto.orderDetailStatus === 'V' 
                        || modeService.mode !== Mode.Edit || orderDetailDto.productCode.startsWith('V')
                        || (orderDetailDto.parentOrderDetailId !== null && orderDetailDto.productDto?.isLicense) || ((orderDetailDto.productDto?.inventoryType == 'V' || orderDetailDto.productDto?.inventoryType == 'G') && orderDetailDto.id > 0)"
                      appEnableResource="OrderInquiryGridDeleteicon" (click)="deleteItem(orderDetailDto)"></span>
                <span
                      *ngIf="orderDetailDto.classCode === 'SH' && orderService.orderDto.shipmentMethod !== 'DONOTSHIP' && orderService.orderDto.shipmentMethod !== 'MISC'"
                      class="uitk-icon uitk-icon-trash_delete gridIcons" [customDisabled]="!orderService.isShippingInconsistent"
                      appEnableResource="OrderInquiryGridDeleteicon" (click)="deleteItem(orderDetailDto)"></span>
                <span *ngIf="orderDetailDto.productCode === ''" class="uitk-icon uitk-icon-trash_delete gridIcons"
                      [customDisabled]="true" appEnableResource="OrderInquiryGridDeleteicon"></span>
              </td>
              <!-- Gear Icon -->
              <td class="align-middle">
                <div *ngIf="orderDetailDto.configSetExist && orderDetailDto.productDto?.classCode !== 'SH'"
                     [ngbTooltip]="orderDetailDto.subscriptionDto || orderDetailDto.feeAnalyzerDto || orderDetailDto.oti ? gearIconContent : ''"
                     container="body" placement="right">
                  <span class="uitk-icon uitk-icon-settings gridIcons" style="cursor: pointer;"
                    [customDisabled]="!orderDetailDto.configSetExist ||
                  (orderDetailDto.orderDetailStatus === 'C' && orderDetailDto.productDto?.fulfillmentTypeCode !== 'WEBAPP' &&
                  orderDetailDto.productDto?.fulfillmentTypeCode !== 'WEBFULFILL' && orderDetailDto.productDto?.fulfillmentTypeCode !== 'CFAWEB')
                  || (orderDetailDto.subscriptionDto && 'X,C,V,M'.indexOf(orderDetailDto.subscriptionDto.subscriptionStatus) > -1) ||
                  orderDetailDto.orderDetailStatus === 'V' || orderDetailDto.parentOrderDetailId !== null || this.shippingProductCodes.indexOf(orderDetailDto.productCode) > -1 || modeService.mode !== Mode.Edit" 
                    (click)="clickConfiguration(orderDetailDto, true)"
                    appEnableResource="OrderInquiryGridGearIcon"></span>
                  <ng-template #gearIconContent>
                    <div class="container-fluid">
                      <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0 d-flex">
                        <div class="col-6 text-nowrap text-start text-white">Sub #:</div>
                        <div class="col-6 text-nowrap text-start text-white">
                          {{ orderDetailDto.subscriptionDto.subscriptionNumber }}
                        </div>
                      </div>
                      <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0 d-flex">
                        <div class="col-6 text-nowrap text-start text-white">Sub Qty:</div>
                        <div class="col-6 text-nowrap text-start text-white">
                          {{ orderDetailDto.subscriptionDto.subscriptionQuantity }}
                        </div>
                      </div>
                      <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Start Date:</div>
                        <div class="col-6 text-nowrap text-start text-white">
                          {{ orderDetailDto.subscriptionDto.startDate | date: 'MM/dd/yyyy' }}
                        </div>
                      </div>
                      <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Term:</div>
                        <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.subscriptionDto.term }}</div>
                      </div>
                      <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Expires:</div>
                        <div class="col-6 text-nowrap text-start text-white">
                          {{ orderDetailDto.subscriptionDto.expireDate | date: 'MM/dd/yyyy' }}
                        </div>
                      </div>
                      <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Status:</div>
                        <div class="col-6 text-nowrap text-start text-white">
                          {{
                          orderDetailDto.subscriptionDto.subscriptionStatus === 'F' ? 'Future' :
                          orderDetailDto.subscriptionDto.subscriptionStatus === 'S' ? 'Suspended' :
                          orderDetailDto.subscriptionDto.subscriptionStatus === 'C' ? 'Cancelled' :
                          orderDetailDto.subscriptionDto.subscriptionStatus === 'V' ? 'Void' :
                          orderDetailDto.subscriptionDto.subscriptionStatus === 'W' ? 'Waiting' :
                          orderDetailDto.subscriptionDto.subscriptionStatus === 'X' ? 'Expired' :
                          orderDetailDto.subscriptionDto.subscriptionStatus === 'D' ? 'Draft' :
                          orderDetailDto.subscriptionDto.subscriptionStatus === 'P' ? 'Pending Approval' :
                          orderDetailDto.subscriptionDto.subscriptionStatus === 'Q' ? 'Quote' :
                          orderDetailDto.subscriptionDto.subscriptionStatus
                          }}
                        </div>
                      </div>
                      <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Sub Type:</div>
                        <div class="col-6 text-nowrap text-start text-white">
                          {{
                          orderDetailDto.subscriptionDto.subscriptionType === 'T' ? 'Trial' :
                          orderDetailDto.subscriptionDto.subscriptionType === 'R' ? 'Renewal' :
                          orderDetailDto.subscriptionDto.subscriptionType === 'N' ? 'New' :
                          orderDetailDto.subscriptionDto.subscriptionType
                          }}
                        </div>
                      </div>
                      <div *ngIf="orderDetailDto.subscriptionDto" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Upd Only:</div>
                        <div class="col-6 text-nowrap text-start text-white">
                          {{ orderDetailDto.subscriptionDto.sendUpdatesOnly ? 'Yes' : 'No' }}
                        </div>
                      </div>
                      <div *ngIf="orderDetailDto.feeAnalyzerDto" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Company:</div>
                        <div class="col-6 text-start text-white">{{ orderDetailDto.feeAnalyzerDto.company }}</div>
                      </div>
                      <div *ngIf="orderDetailDto.feeAnalyzerDto" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Specialty:</div>
                        <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.feeAnalyzerDto.specialty }}</div>
                      </div>
                      <div *ngIf="orderDetailDto.feeAnalyzerDto" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Zip:</div>
                        <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.feeAnalyzerDto.zip }}</div>
                      </div>
                      <div *ngIf="orderDetailDto.feeAnalyzerDto" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Geo Zip:</div>
                        <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.feeAnalyzerDto.geoZip }}</div>
                      </div>
                      <div *ngIf="orderDetailDto.feeAnalyzerDto" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Completed:</div>
                        <div class="col-6 text-nowrap text-start text-white">
                          {{ orderDetailDto.feeAnalyzerDto.completedDate | date: 'MM/dd/yyyy' }}
                        </div>
                      </div>
                      <div *ngIf="orderDetailDto.cfaWebDtos.length > 0" class="row px-0">
                        <div class="col-12">
                          <hr>
                        </div>
                      </div>
                      <div *ngIf="orderDetailDto.cfaWebDtos.length > 0" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Company:</div>
                        <div class="col-6 text-start text-white">{{ orderDetailDto.cfaWebDtos[0].company }}</div>
                      </div>
                      <div *ngFor="let cfaWebDto of orderDetailDto.cfaWebDtos" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Zip:</div>
                        <div class="col-6 text-nowrap text-start text-white">{{ cfaWebDto.zip }} ({{ cfaWebDto.geoZip }})</div>
                      </div>
                      <div *ngIf="orderDetailDto.chegSetDto" class="row px-0">
                        <div class="col-12">
                          <hr>
                        </div>
                      </div>
                      <div *ngIf="orderDetailDto.chegSetDto" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">ChegSet Type:</div>
                        <div class="col-6 text-nowrap text-start text-white">
                          {{ orderDetailDto.chegSetDto.optionType === 1 ? 'Custom' :
                          orderDetailDto.chegSetDto.optionType === 2 ? 'National' : 'Standard' }}
                        </div>
                      </div>
                      <div *ngIf="orderDetailDto.chegSetDto && orderDetailDto.chegSetDto.state" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">State:</div>
                        <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.chegSetDto.state }}</div>
                      </div>
                      <div *ngIf="orderDetailDto.chegSetDto && orderDetailDto.chegSetDto.carrier" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Carrier:</div>
                        <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.chegSetDto.carrier }}</div>
                      </div>
                      <div *ngIf="orderDetailDto.chegSetDto && orderDetailDto.chegSetDto.locality" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Locality:</div>
                        <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.chegSetDto.locality }}</div>
                      </div>
                      <!-- Deposits -->
                      <div *ngIf="orderDetailDto.oti && orderDetailDto.oti[0] === 'C'" class="row px-0">
                        <div class="col-6 text-nowrap text-start text-white">Credit Memo:</div>
                        <div class="col-6 text-nowrap text-start text-white">{{ orderDetailDto.oti.substring(1).trim() }}</div>
                      </div>
                      <div *ngIf="orderDetailDto.oti && orderDetailDto.oti[0] === 'R' && orderDetailDto.referenceNumber"
                           class="row px-0">
                        <div class="col-4 text-nowrap text-start">Ref #:</div>
                        <div class="col-8 text-nowrap text-start">{{ orderDetailDto.referenceNumber.trim() }}</div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </td>
              <!-- S.No -->
              <td class="align-middle">
                {{ i + 1 }}
              </td>
              <!-- Item # -->
              <td class="align-middle">
                <uitk-form-field>
                  <!-- this.orderDto.orderDetailDtos.some(item => item.productDto.configSetDtos.some(config => config.configSet === 'DATAFILE') && item.orderDetailStatus !== 'V') -->
                  
                  <input type="text" uitkInput id="productCode-{{ orderDetailDto.id }}"
                         [(ngModel)]="orderDetailDto.productCode" name="productCode"
                         [ngStyle]="{'width': orderDetailDto.parentOrderDetailId === null && orderDetailDto.productCode.indexOf('V') !== 0 ? '120px' : '105px',
          'margin-left': orderDetailDto.parentOrderDetailId === null && orderDetailDto.productCode.indexOf('V') !== 0 ? '0px' : '15px'}"
                         (change)="onChangeProductCode($event,'', orderDetailDto, i + 1)" tabindex="{{ i * 10 + 1 }}"
                         appEnableResource="OrderDetailsItemTextBox" (keydown.Tab)="onProductCodeBlur(orderDetailDto)" 
                         [disabled]="!orderDetailDto.itemEditable || orderDetailDto.orderDetailStatus === 'V' || modeService.mode !== Mode.Edit"
                        [ngStyle]="getdatafile(orderDetailDto)"
                         
                         >
                </uitk-form-field>
              </td>
              <!-- Item Description -->
              <td class="align-middle">
                {{ orderDetailDto.productDescription }}
              </td>
              <!-- Order Qty -->
              <td class="align-middle">
                <uitk-form-field>
                  <input type="text" id="orderQuantity-{{ orderDetailDto.id }}" uitkInput
                         [(customModel)]="orderDetailDto.orderQuantity" name="tdOrderQuantity" style="width: 80px;"
                         (focus)="recordPreviousQuantity(orderDetailDto)" (change)="onChangeOrderQuantity(orderDetailDto)"
                         (keydown.Tab)="onOrderQuantityBlur(orderDetailDto)" (focusout) ="onOrderQuantityBlur(orderDetailDto)" appQuantityFormat
                         tabindex="{{ i * 10 + 2 }}" appEnableResource="OrderDetailsOrderQtyTextBox"
                         [disabled]="orderDetailDto.parentOrderDetailId !== null || orderDetailDto.classCode == 'SH' || orderDetailDto.productCode === '0010' || modeService.mode !== Mode.Edit ||
            (orderDetailDto.orderDetailStatus !== ' ' && (orderDetailDto.orderDetailStatus !== 'C' || orderDetailDto.productDto?.fulfillmentTypeCode !== 'WEBAPP' || !orderDetailDto.subscriptionDto || 
            orderDetailDto.subscriptionDto.subscriptionStatus !== 'A')) || orderDetailDto.promotionEntryCode === 'ILP'">
                </uitk-form-field>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="divColumns table-responsive" id="divRightOrderDetailsGrid" style="width: 55%;">
        <table id="tblRightOrderDetailsGrid" style="border-right: 0px; width: 100%; white-space: nowrap;">
          <thead>
            <tr style="height: 60px; border-bottom: 2px solid rgb(194, 86, 8);">
              <th scope="col">
                {{ columns[5].title }}
              </th>
              <th scope="col">
                {{ columns[6].title }}
              </th>
              <th scope="col">
                {{ columns[7].title }}
              </th>
              <th scope="col">
                {{ columns[8].title }}
              </th>
              <th scope="col">
                {{ columns[9].title }}
              </th>
              <th scope="col">
                {{ columns[10].title }}
              </th>
              <th scope="col">
                {{ columns[11].title }}
              </th>
              <th scope="col">
                {{ columns[12].title }}
              </th>
              <th scope="col">
                {{ columns[13].title }}
              </th>
              <th scope="col">
                {{ columns[14].title }}
              </th>
              <th scope="col">
                {{ columns[15].title }}
              </th>
              <th scope="col">
                {{ columns[16].title }}
              </th>
            </tr>
          </thead>
          <tfoot style="background-color: #EFEFEE;">
            <tr>
              <td colspan="11">
                <uitk-form-field>
                  <input type="text" id="tfHiddenField" uitkInput value="" name="tfHiddenField"
                         style="width: 80px; visibility: hidden;">
                </uitk-form-field>
              </td>
            </tr>
          </tfoot>
          <tbody id="orderDetailDtos-right-row-data">
            <tr *ngFor="let i = index; let orderDetailDto of filteredOrderDetails"
                style="border-bottom: 1px solid #d0d0ce;">
              <!-- List Price -->
              <td class="align-middle">
                <uitk-form-field>
                  <input type="text" id="listPrice-{{ orderDetailDto.id }}" uitkInput
                    [(customModel)]="orderDetailDto.listPrice" name="tdlistPrice" style="width: 150px;" appPriceFormat
                    [disabled]="!orderDetailDto.productDto?.allowCustomPrice || orderDetailDto.orderDetailStatus !== ' ' || modeService.mode !== Mode.Edit"
                    (change)="onChangeListPrice(orderDetailDto)"
                     appEnableResource="OrderDetailsListPriceTextBox" tabindex="{{ i * 10 + 3 }}" />
                </uitk-form-field>
              </td>
              <!-- Discount -->
              <td class="align-middle">
                <uitk-form-field>
                  <input type="text" id="discount-{{ orderDetailDto.id }}" uitkInput
                    [(customModel)]="orderDetailDto.discount" name="tdDiscount" style="width: 150px;"
                    appEnableResource="OrderDetailsDiscTexdtBox" (change)="onChangeDiscount(orderDetailDto)" [disabled]="orderDetailDto.orderDetailStatus === 'C'
                    || (orderDetailDto.productDto?.productCode != '' && orderDetailDto.listPrice == 0 && !orderDetailDto.productDto?.allowCustomStandard && !orderDetailDto.productDto?.allowCustomPrice)
                    || orderDetailDto.itemDisable === true || orderDetailDto.productDto?.allowCustomStandard || orderDetailDto.orderDetailStatus === 'V'
                    || (orderDetailDto.parentOrderDetailId !== null && orderDetailDto.productCode !== '1577')
                    || orderDetailDto.subscriptionDto?.subscriptionType === 'T' || orderDetailDto.productCode === '0010' || modeService.mode !== Mode.Edit 
                    || orderDetailDto.productDto?.classCode === 'XS'"
                    appDiscountFormat tabindex="{{ i * 10 + 4 }}"
                    
                    [ngStyle]="getdiscount(orderDetailDto)"
                         
                         >
                </uitk-form-field>
              </td>
              <!-- Unit Price -->
              <td class="align-middle">
               
                <uitk-form-field>
                 
                  <input type="text" id="unitPrice-{{ orderDetailDto.id }}" uitkInput
                         [(customModel)]="orderDetailDto.unitPrice" name="tdUnitPrice" style="width: 150px;"
                         appEnableResource="OrderDetailsUnitPriceTextBox" [disabled]="orderDetailDto.orderDetailStatus === 'C'
                          || (orderDetailDto.productDto?.productCode != '' && orderDetailDto.listPrice == 0 && !orderDetailDto.productDto?.allowCustomStandard && !orderDetailDto.productDto?.allowCustomPrice)
                          || orderDetailDto.itemDisable === true || orderDetailDto.productDto?.allowCustomPrice || orderDetailDto.orderDetailStatus === 'V'
                          || (orderDetailDto.parentOrderDetailId !== null && orderDetailDto.productCode !== '1577')
                          || orderDetailDto.subscriptionDto?.subscriptionType === 'T' || orderDetailDto.productCode === '0010' || modeService.mode !== Mode.Edit 
                          || orderDetailDto.productDto?.classCode === 'XS'"
                         (change)="onChangeUnitPrice(orderDetailDto,true)" appPriceFormat tabindex="{{ i * 10 + 5 }}" 

                         [ngStyle]="getUnitPriceBackgroundcolor(orderDetailDto)"
                         >
                </uitk-form-field>
              </td>
              <!-- Ext Price -->
              <td class="align-middle">
                {{ orderDetailDto.extendedPrice | currency:'USD' }}
              </td>
              <!-- Req Date -->
              <td class="align-middle">
                <uitk-form-field>
                  <input type="text" id="requiredDate-{{ orderDetailDto.id }}" uitkInput
                         [ngModel]="orderDetailDto.requiredDate | date:'MM/dd/yyyy'" name="tdRequiredDate"
                         style="width: 150px;" placeholder="MM/DD/YYYY" appEnableResource="OrderDetailsReqDateTextBox"
                         (change)="onChangeRequiredDate($event, orderDetailDto, i + 1)" tabindex="{{ i * 10 + 6 }}"
                         [disabled]="orderDetailDto.orderDetailStatus === 'C'
               || orderDetailDto.itemDisable === true || orderDetailDto.orderDetailStatus === 'V'
               || orderDetailDto.parentOrderDetailId !== null || orderDetailDto.productCode === '0010' || orderDetailDto.subscriptionDto != null || modeService.mode !== Mode.Edit || orderDetailDto.productDto?.classCode === 'XS' "
                         required />
                  <uitk-form-field-error *ngIf="showRequiredDateValidation && requiredDateValidationIndex === i + 1">
                    {{ requiredDateValidationMessage }}
                  </uitk-form-field-error>
                </uitk-form-field>
              </td>
              <!-- AP $: orderDetailDto.tpDollar -->
              <td class="align-middle">{{orderDetailDto.allowedPrice | currency:'USD' }}</td>
              <!-- AP %: orderDetailDto.tpPercentage -->
              <td class="align-middle">{{ orderDetailDto.eligibleAllowedPercentage | percent:'2.2-2' }}</td>
              <!-- FP $: orderDetailDto.tpDollar -->
              <td class="align-middle">{{orderDetailDto.productDto?.floorPrice | currency:'USD' }}</td>
              <!-- Tax Amount -->
              <td class="align-middle">
                {{ orderDetailDto.taxAmount | currency:'USD' }}
              </td>
              <!-- Avail Date -->
              <td class="align-middle">{{ orderDetailDto.productDto?.availableText }}</td>
              <!-- Term: orderDetailDto.term -->
              <td class="align-middle">
                {{ orderDetailDto.subscriptionDto === null ? '' : orderDetailDto.subscriptionDto.term }}
              </td>
              <!-- On Hand -->
              <td class="align-middle">{{ orderDetailDto.productDto?.onHand }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6 d-flex">
        <div class="uitk-icon uitk-icon-search sm-icon searchIcon align-self-center mx-2">
          <a class="link" href="javaScript:void(0)" (click)="itemSearch()" id="itemSearchLink"  (callSearchMethod)="itemSearch()"
             scrollToTarget>Item Catalog</a>
        </div>
      </div> 
    </div>
  </div>
</div>
<div class="divPopUpContent" *ngIf="configSetDialog.show">
  <app-config-sets [dialog]="configSetDialog" [orderDetail]="configurationOrderDetail">
  </app-config-sets>
</div>
<div *ngIf="showNoItemExists.show">
  <div class = "overlayForPopups">
    <div cdkDrag>
  <uitk-dialog [(showModal)]="showNoItemExists.show" uitkId="noItemDialog" triggerElementId="noItem" [closeBtn]=false (onModalHidden)="setFocusOnOpenedPopup()">
    <uitk-dialog-header>
      <div cdkDragHandle>
        Error Message
      </div>
    </uitk-dialog-header>
    <uitk-dialog-content *ngIf="!isAddOn && !havePermission">
      {{ myNotificationWarningContent }}
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="isAddOn && !havePermission">
      {{ addOnWarningContent }}
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="havePermission">
      {{ addPermissionContent }}
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <div class="text-center">
        <button id="btnShowNoItemExistsOk" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
                (click)="closeNoItemDialog()" appAutoFocus>
          Ok
        </button>
      </div>
    </uitk-dialog-actions>
  </uitk-dialog>
    </div>
  </div>
</div>
<div *ngIf="dupWebApps.show">
  <div class = "overlayForPopups">
    <div cdkDrag>
  <uitk-dialog [(showModal)]="dupWebApps.show" [closeBtn]=false (onModalHidden)="setFocusOnOpenedPopup()">
    <uitk-dialog-header>
      <div cdkDragHandle>
        Cannot add this Line
      </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
      {{dupNotificationForWebApps}}
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="closeItemDialog()" #dupWebAppOkBtn appAutoFocus>
        Ok
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
    </div>
  </div>
</div>
<div *ngIf="dupProduct.show">
  <div class = "overlayForPopups">
    <div cdkDrag>
  <uitk-dialog [(showModal)]="dupProduct.show" [closeBtn]=false (onModalHidden)="setFocusOnOpenedPopup(true)">
    <uitk-dialog-header>
      <div cdkDragHandle>
        <h4 class="modal-title pull-left">
          Duplicate
        </h4>
      </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
      <span>
        This item has already been entered in the cart. Continue entering the duplicate item?
      </span>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button appAutoFocus class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="allowDup()" #dupItemOkBtn tabindex="0">
        Yes
      </button>
      <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="stopDup()">
        No
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
    </div>
  </div>
</div>
<div *ngIf="itemComment.show">
  <div class = "overlayForPopups">
    <div cdkDrag>
  <uitk-dialog [(showModal)]="itemComment.show" [closeBtn]=false (onModalHidden)="setFocusOnOpenedPopup()">
    <uitk-dialog-header>
      <div cdkDragHandle>
        <h4 class="modal-title pull-left">
          Order Note
        </h4>
      </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
      <span>
        {{ itemComment.text }}
      </span>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button appAutoFocus id="btnItemCommentPopupOk" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
              (click)="closeItemDialog()" #orderNoteOkBtn>
        Ok
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
    </div>
  </div>
</div>
<div *ngIf="quantityCheckModal.show">
  <div class = "overlayForPopups">
    <div cdkDrag>
  <uitk-dialog [(showModal)]="quantityCheckModal.show" uitkId="quantityCheckModal" [closeBtn]=false (onModalHidden)="setFocusOnOpenedPopup()">
    <uitk-dialog-content>
      <p>Does your customer have a dock that can receive truck shipments?</p>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="onClickYes()" #quantityCheckOkBtn appAutoFocus>
        Yes
      </button>
      <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="onClickNo()">
        No
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
    </div>
  </div>
</div>
<app-subscription-price-calculation #subPriceCalculation id="subsPriceCal"></app-subscription-price-calculation>
<div *ngIf="showItemLevelOffers.show">
  <app-item-level-offers [showItemLevelOffers]="showItemLevelOffers" [itemOfferDto]="itemOfferDto" [orderDetail]="selectedOrderDetail" (outOrderDetail)="adjustedLineItemDiscount($event)"></app-item-level-offers>
</div>
<div *ngIf="showItemSearchDialog.show">
  <app-item-search-catalog [dialog]="showItemSearchDialog" (productAdded)="addProductCodeFromDialog($event)"></app-item-search-catalog>
</div>

<div class="divPopUpContent" *ngIf="orderOfferQualificationDialog.show">
  <app-order-offer-qualification [orderOfferQualificationDialog]="orderOfferQualificationDialog"
    [associatedPremiumsList]="associatedPremiumsList" [offerType]="offerType">
  </app-order-offer-qualification>
</div>
<div class="divPopUpContent" *ngIf="itemChangedPopup.show">
  <p>{{itemChangedPopup.show}}</p>
  <app-item-changed-popup [itemChangedPopup]="itemChangedPopup" [productCode]="productCode" [productType]="productType">
  </app-item-changed-popup>
</div>
<div *ngIf="orderService.shippingDialogModal.show">
  <app-shipping-popup-order-entry [orderDetail]="orderService.configurationOrderDetail"></app-shipping-popup-order-entry>
</div>
