import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Mode } from '../../../../enums/mode.enum';
import { OrderDto } from "../../../../dtos/order-dto.model";
import { ModeService } from '../../../../services/mode.service';
import { OrderService } from '../../../../services/order.service';
import { ReturnDto } from '../../../../dtos/return-dto.model';

@Component({
  selector: 'app-returns-action',
  templateUrl: './returns-action.component.html',
  styleUrls: ['./returns-action.component.css'],
})

export class ReturnsActionComponent implements OnInit {
  private readonly _router: Router;
  @Input() dialog = { show: false };
  @Input() fromReturnEntry: boolean = false;
  @Output() newItemEvent = new EventEmitter<boolean>();
  returnIsReadySubscription: Subscription | undefined;
  returnDtos: ReturnDto[] = [];
  returnDtoList: OrderDto[] = [];
  originalOrderID: number = 0;
  enableAddNewReturn = false;
  Mode = Mode;
  isreturnAvailable: boolean = false;
  visibleTextValue = "Loading Returns";
  tableHeader = [
    { name: 'Return #', id: 'returnNumber' },
    { name: 'Account #', id: 'accountNumber' },
    { name: 'Return Date', id: 'returnDate' },
    { name: 'Terms', id: 'terms' },
    { name: 'Last 4 CC', id: 'last4CC' },
    { name: '$ Shipped', id: 'shippedAmount' },
    { name: 'PO #', id: 'poNumber' },
    { name: 'Return Type', id: 'returnType' },
    { name: 'Return Status', id: 'returnStatus' },
    { name: 'Return Code', id: 'ReturnCode' },
    { name: 'Entered By', id: 'enteredBy' },
  ];

  constructor(
    router: Router,
    public orderService: OrderService,
    public modeService: ModeService) {
    this._router = router;
  }

  ngOnInit(): void {
    if (this.fromReturnEntry) {
      this.dialog.show = true;
      this.fromReturnEntry = false;
    }

    this.returnIsReadySubscription = this.orderService.returnIsReady.subscribe(() => {
      this.returnDtoList = [...this.orderService.returnDtos];
      this.returnDtoList.forEach(element => {
        const modalItem = new ReturnDto();

        modalItem.returnId = element.id;
        modalItem.accountId = element.billToDto.id;
        modalItem.returnDate = element.orderDate;
        modalItem.terms = element.paymentTerms;
        modalItem.last4CC = element.ccLastFour;
        modalItem.poNumber = element.poNumber;
        modalItem.returnType = element.orderType;
        modalItem.returnStatus = element.orderStatus;
        modalItem.returnCode = element.returnCode;
        modalItem.enteredBy = element.enteredBy;
        modalItem.shippedAmount = element.shippedAmount;
        this.returnDtos.push(modalItem);
        this.returnDtos = [...this.returnDtos];
      });
    });

    if (this.orderService.orderDto.id !== 0) {
      this.orderService.getReturnInformation(this.orderService.orderDto.id);
    }
    this.enableAddNewReturn = this.orderService.orderDto.orderDetailDtos.some(element => element.shippedQuantity + element.returnedQuantity > 0);
  }

  navigateToReturnEntry() {
    if (!this.fromReturnEntry) {
      this._router.navigate(['/return-entry']);
    }

    this.dialog.show = false;
  }

  navigateToReturnInquiry(returnId: number) {
    this._router.navigate([]).then(() => {
      window.open('/return/' + returnId, '_blank');
    });
  }
}
