import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import JSZip from 'jszip';  
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver'; 
import {
  IUITKPaginationConfig, IUITKPaginationEntriesPerPage, IUITKPaginationEvent, PAGINATION_EVENTS,
  UITKHeadingLevel, UITKPaginationComponent, UITKSortDirection, UITKTableDataSource
} from '@uitk/angular';
import { UITKTableSortDirective } from '@uitk/angular/components/table/features/sort/table-sort.component';
import { IUITKColumnState } from '@uitk/angular/components/table/table.interfaces';
import { Subscription } from 'rxjs';
import { Mode } from '../../enums/mode.enum';
import { OrderSearchInputDto } from '../../dtos/order-search-input-dto.model';
import { OrderSearchResult } from '../../dtos/order-search-result.model';
import { OrderSearchResultsDto } from '../../dtos/order-search-results-dto.model';
import { ConfigService } from '../../services/config.service';
import { OrderService } from '../../services/order.service';
import { Dialog } from 'src/app/helpers/dialog';
import { QuoteCFDEmailDto } from 'src/app/dtos/quote-cfd-email-dto.model';
import { EmailService } from '../../services/email.service';
import { GeneratePdfService } from '../../services/generate-pdf.service';
import { SwalAlert } from '../../helpers/alert';
import { AccountSearchInputDto } from 'src/app/dtos/account-search-input-dto';
import { CfdPhoneFormatPipe } from 'src/app/pipes/cfd-phone-format.pipe';
import { OrderDetailDto } from 'src/app/dtos/order-detail-dto.model';
import { OrderDto } from 'src/app/dtos/order-dto.model';
import { data } from 'jquery';
import { flattenDiagnosticMessageText } from 'typescript';
import { ModeService } from 'src/app/services/mode.service';
import { QuoteCfdPdfComponent } from '../order-entry/quote-cfd/quote-cfd-pdf/quote-cfd-pdf.component';


@Component({
  selector: 'app-order-search-results',
  templateUrl: './order-search-results.component.html',
  styleUrls: ['./order-search-results.component.css']
})
  
export class OrderSearchResultsComponent implements OnInit, OnDestroy {
  private readonly _configService: ConfigService;
  public readonly _orderService: OrderService;
  private readonly _router: Router
  private readonly _generatePdfService: GeneratePdfService;
  private readonly _emailService: EmailService;
  @ViewChild(UITKPaginationComponent, { static: true }) uitkTablePagination!: UITKPaginationComponent;
  @ViewChild('sortTable') uitkTableSort!: UITKTableSortDirective;
  orderSearchResults = new UITKTableDataSource<OrderSearchResult>([]);
  gotOrderSearchResultsSubscription: Subscription | undefined;
  @Input() enteredByUserRole : string | null = null;
  @Input() disclaimerType= '';  
  @Input() orderDto: any; // Replace 'any' with the actual type of orderDto  


  @Input() dialog!: Dialog<string>;  
  msId = '';
  swalAlert = new SwalAlert();
  emailPattern = /^^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](\s?)(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;

  



  tableHeader = [
    { name: 'Account Name', id: 'AccountName' },
    { name: 'Account #', id: 'AccountNumber' },
    { name: 'Ship To Address', id: 'StreetAddress' },
    { name: 'Web Ref #', id: 'WebReferenceNumber' },
    { name: 'PO #', id: 'PONumber' },
    { name: 'Order #', id: 'OrderNumber' },
    { name: 'Type', id: 'OrderType' },
    { name: 'Status', id: 'OrderStatus' },
    { name: 'Total $', id: 'TotalOrderDollars' },
    { name: 'Shipped $', id: 'ShippedDollars' },
    { name: 'Open $', id: 'OpenDollars' },
    { name: 'Order Date', id: 'OrderDate' },
    { name: 'Tracking #', id: 'TrackingNumber' },
    { name: 'Ship To Name', id: 'ShipToContactName' },
    { name: 'Ship To ID', id: 'ShipToContactId' },
    { name: 'Order By Name', id: 'OrderByContactName' },
    { name: 'Order By ID', id: 'OrderByContactId' },
    { name: 'Entered By', id: 'EnteredBy' },
     { name: 'CFD',  id : 'cfd' },
  ];

  currentPageSize: number = 200;
  paginationConfig: IUITKPaginationConfig = {
    id: 'pagination-id',
    description: 'Pagination',
    currentPageNumber: 1,
    displayingPagesTextHeadingLevel: UITKHeadingLevel.h2,
    nextButtonLabel: '>>',
    previousButtonLabel: '<<',
  };

  entriesPerPage: IUITKPaginationEntriesPerPage = {
    pageSizeOptions: [{ value: 200, selected: true }],
  };

  totalRecords = 1;
  quoteCfdFormEmail = new Dialog<string>();
  cfdEmailDto = new QuoteCFDEmailDto();
  selectAll: boolean= false;
  poRequired =  false ;
  prePayRequired= false;
  standard= false;
  


  constructor(
    configService: ConfigService,
    orderService: OrderService,
    router: Router,
    generatepdfService: GeneratePdfService,
    emailService: EmailService, 
    private readonly modeservice: ModeService) {
    this._configService = configService;
    this._orderService = orderService;
    this._router = router;
    this._generatePdfService= generatepdfService;
    this._emailService = emailService;
  }

  ngOnInit() {
    this.filter();
  }

  navigateToSFViewAccount(salesforceId: string) {
    window.open(`${this._configService.salesforceUrl}/apex/ViewAccount?id=${salesforceId}`);
  }

  onPaginationChange(event: any) {
    const pageEvent: IUITKPaginationEvent = event;

    switch (pageEvent.event) {

      case PAGINATION_EVENTS.ENTRIES_PER_PAGE:
        this.paginationConfig.totalPagesCount = Math.ceil(pageEvent.totalRecords / pageEvent.entriesPerPage);
        if (this.paginationConfig.totalPagesCount < this.paginationConfig.currentPageNumber) {
          this.paginationConfig.currentPageNumber = this.paginationConfig.totalPagesCount;
        }
        // get number of records based on entries per page value
        this.currentPageSize = pageEvent.entriesPerPage;
        this._orderService.filterObj.pageNumber = this.paginationConfig.currentPageNumber;
        this._orderService.filterObj.pageSize = pageEvent.entriesPerPage;
        this._orderService.orders = null;
        this._orderService.searchOrders(Mode.View, this._orderService.filterObj, true);
        break;
      case PAGINATION_EVENTS.PREVIOUS_PAGE:
      case PAGINATION_EVENTS.NEXT_PAGE:
      case PAGINATION_EVENTS.GO_TO_PAGE:
        // set current page number
        this.paginationConfig.currentPageNumber = pageEvent.state.currentPageNumber;

        // paginate data based on current page and entries per page value
        this._orderService.filterObj.pageNumber = this.paginationConfig.currentPageNumber;
        this._orderService.filterObj.pageSize = pageEvent.entriesPerPage;
        this._orderService.orders = null;
        this._orderService.searchOrders(Mode.View, this._orderService.filterObj, true);
        break;
    }

    localStorage.setItem('searchOperator', JSON.stringify(this._orderService.filterObj));
  }

  onSortChange(event: IUITKColumnState): void {
    if (event.direction === -1) {
      this._orderService.filterObj.sort = (event.column === 'TrackingNumber' ? 'TrackingNumberSort' : event.column) + " descending";
    }
    else {
      this._orderService.filterObj.sort = event.column === 'TrackingNumber' ? 'TrackingNumberSort' : event.column;
    }
    this._orderService.orders = null;
    this._orderService.searchOrders(Mode.View, this._orderService.filterObj, true);
    localStorage.setItem('searchOperator', JSON.stringify(this._orderService.filterObj));
  }

  filter(): void {
    this.gotOrderSearchResultsSubscription = this._orderService.gotOrderSearchResultsEmitter
      .subscribe((data: OrderSearchResultsDto) => {
        this.orderSearchResults.data = data.orderSearchResults;
        this.totalRecords = data.totalRecords;
        localStorage.setItem('backToSearchResultsCount', data.totalRecords.toString());
        localStorage.setItem('navigateOrigin', 'orderSearch');
        this.setTrackingNumber();
        if (this.orderSearchResults.data.length === 1 && (this.orderSearchResults.data[0].orderType === 'R' || this.orderSearchResults.data[0].orderType === 'A')) {
          this._router.navigate(['/return', this.orderSearchResults.data[0].orderNumber]);
        }
        else if (this.orderSearchResults.data.length === 1 && (this.orderSearchResults.data[0].orderType === 'Q' ||
          this.orderSearchResults.data[0].orderStatus === 'P' || this.orderSearchResults.data[0].orderStatus === 'D' ||
          this.orderSearchResults.data[0].orderStatus === 'M' || this.orderSearchResults.data[0].orderStatus === 'R')) {
          this._router.navigate(['/order-entry'], { queryParams: { orderId: this.orderSearchResults.data[0].orderNumber }});
        }
        else if (this.orderSearchResults.data.length === 1 && (this.orderSearchResults.data[0].orderStatus === 'D' || this.orderSearchResults.data[0].orderStatus === 'P' || this.orderSearchResults.data[0].orderStatus === 'R' || this.orderSearchResults.data[0].orderStatus === 'M' || this.orderSearchResults.data[0].orderType === 'Q')) {
          this._router.navigate(['/order-entry'],{ queryParams: { orderId: this.orderSearchResults.data[0].orderNumber } });
        }
        else if (this.orderSearchResults.data.length === 1) {
          this._router.navigate(['/order', this.orderSearchResults.data[0].orderNumber]);
        }
        if (Object.keys(localStorage).indexOf('searchOperator') > -1) {
          const searchFilters = JSON.parse(localStorage.getItem('searchOperator') as string) as OrderSearchInputDto;
          this.paginationConfig.currentPageNumber = searchFilters.pageNumber;
          this.uitkTablePagination.paginationConfig.currentPageNumber = searchFilters.pageNumber;
          if (searchFilters.sort?.includes('TrackingNumberSort'))
          {
            searchFilters.sort = searchFilters.sort.replace('TrackingNumberSort', 'TrackingNumber');
          }
          const sortColumn = searchFilters.sort;
          this.tableHeader.forEach(header => {
            this.uitkTableSort.setDirection({ column: header.id, direction: UITKSortDirection.UNSORTED });
          });
          this.doSortIsColumnNotNull(sortColumn);
          this.uitkTablePagination.displayingPageText();

        }
      });
  }

  doSortIsColumnNotNull(sortColumn:any){
    if (sortColumn != null) {
      let sortDirection = UITKSortDirection.ASC;
      if (sortColumn.includes("descending")) {
        sortDirection = UITKSortDirection.DESC
      }
      this.uitkTableSort.setDirection({ column: sortColumn.split(' ')[0], direction: sortDirection });
    }
  }

  navigateToOrder(order: OrderSearchResult): void {
    this._router.navigate(['/order', order.orderNumber]);
  }

  navigateToReturn(order: OrderSearchResult): void {
    this._router.navigate(['/return', order.orderNumber]);
  }

  setTrackingNumber() {
    this.orderSearchResults.data.forEach(element => {
      let trackingNumber: string[] | null = null;
      trackingNumber = element.trackingNumber?.split(', ') ?? null;
      element.trackingNumber = trackingNumber?.filter((n, i) => trackingNumber?.indexOf(n) === i).toString().split(",").join("\n") ?? null;
    });
  }

  navigateToARInquiry(accountId: number): void {
    this._router.navigate([]).then(() => {
      window.open('/accountreceivable/arinquiry/' + accountId, '_blank');
    });
  }

  openUpsTracking(trackingNumber: string): void {
    const url = `https://www.ups.com/${trackingNumber}`;
    window.open(url, "_blank");
  }
  async generateCFD() {
    

    if (
      !this.orderSearchResults.data ||
      this.orderSearchResults.data.length === 0
    ) {
      console.error('Order search results data is not available.');
      return;
    }
    const selectedOrders = this.orderSearchResults.data.filter(
      (order) => order.cfd
    );

    if (selectedOrders.length === 0) {
      alert('No orders selected for CFD generation.');
      return;
    }

    const accountIds = selectedOrders.map((order) => order.accountNumber);
    const uniqueAccountIds = [...new Set(accountIds)];
    if (uniqueAccountIds.length > 1) {
      alert('All selected orders must belong to the same account.');
      return;
    }

    // Validate that only approved quotes are selected
    const approvedQuotes = selectedOrders.filter(
      (order) => order.orderStatus === 'A' && order.orderType === 'Q'
    );
    if (approvedQuotes.length !== selectedOrders.length) {
      alert('Please select approved quotes only.');
      return;
    }
    const zip = new JSZip();
    try {
      for (let i = 0; i < selectedOrders.length; i++) {
        const order = selectedOrders[i];

        if (order.orderNumber !== null) {
          const orderDetail = await this._orderService
            .getOrderInfo(order.orderNumber)
            .toPromise();
          this._orderService.orderDto = orderDetail;
          console.log(this._orderService.orderDto.orderDetailDtos);

          // Loop through orderDetailDtos
          for (const orderDetail of this._orderService.orderDto
            .orderDetailDtos) {
            orderDetail.unitPrice =
              Math.round(orderDetail.listPrice * (100 - orderDetail.discount)) /
              100;
            orderDetail.orderQuantity =
              orderDetail.openQuantity + orderDetail.shippedQuantity;
          }
          if (this._orderService.orderDto.billToDto.isPORequired) {
            this.disclaimerType = 'porequired';
          } else if (
            this._orderService.orderDto.billToDto.creditLimit !== 10000 &&
            this._orderService.orderDto.billToDto.creditLimit !== 1000
          ) {
            this.disclaimerType = 'prepayrequired';
          } else {
            this.disclaimerType = 'standard';
          }
          this.populateCFD(order);  
          var a=this._orderService.orderDto.id;
          this._orderService.orderDto =orderDetail;
          var b =this._orderService.orderDto.id;
          

          const pdfBlob = await this.generateOrderPDF(order);
          zip.file(`CFD_${order.orderNumber}.pdf`, pdfBlob);
        }
      }
      if (selectedOrders.length === 1) {
        //  this._orderService.orderDto.billToDto=  selectedOrders[0].billToDto;

        //selectedOrders[0].billToDto = this._orderService.orderDto.billToDto;
        this.quoteCfdFormEmail.openAsync();
        this.populateCFD(selectedOrders[0]);
        if (this._orderService.orderDto.billToDto.isPORequired) {
          this.disclaimerType = 'porequired';
        } else if (
          this._orderService.orderDto.billToDto.creditLimit !== 10000 &&
          this._orderService.orderDto.billToDto.creditLimit !== 1000
        ) {
          this.disclaimerType = 'prepayrequired';
        } else {
          this.disclaimerType = 'standard';
        }

        //this.modeservice.mode= Mode.View;
      } else {
        let count = 0;
        this.quoteCfdFormEmail.openAsync();
        this.populateCFD(selectedOrders[count]);

        const accountNO = selectedOrders[count].accountNumber;
        // this.quoteCfdFormEmail.openAsync();
        // this.populateCFD(selectedOrders[0]);

        // const accountNO= selectedOrders[0].accountNumber;

        for (const order of selectedOrders) {
          try {
            // if (this._orderService.orderDto.billToDto.isPORequired) {
            //   this.disclaimerType = 'porequired';
            // } else if (
            //   this._orderService.orderDto.billToDto.creditLimit !== 10000 &&
            //   this._orderService.orderDto.billToDto.creditLimit !== 1000
            // ) {
            //   this.disclaimerType = 'prepayrequired';
            // } else {
            //   this.disclaimerType = 'standard';
            // }

            // const pdfBlob = await this.generateOrderPDF(order);
            // zip.file(`CFD_${order.orderNumber}.pdf`, pdfBlob);

            count++;
          } catch (error) {
            console.error(
              `Failed to generate PDF for order ${order.orderNumber}:`,
              error
            );
          }
        }

        zip.generateAsync({ type: 'blob' }).then((content) => {
          const filename = `CFD_${accountNO}_Files.zip`;
          saveAs(content, filename);
          // saveAs(content, 'CFD_files.zip');
        });
      }
    } catch (error) {
      console.error('Failed to fetch account details:', error);
      alert('Failed to fetch account details. Please try again.');
    }
  }

  async populateCFD(order: OrderSearchResult) {
    const accountNO = order.accountNumber;
    const orderNo= order.orderNumber;

    this.msId = this._configService.getMyMSId();
    const accountTypeCode =
      this._orderService.orderDto.billToDto.accountTypeCode;
    const filterPipe = new CfdPhoneFormatPipe();
    const formattedPhoneNumber = filterPipe.transform(
      this._configService.getUserPhoneFromUserCode(
        this._orderService.orderDto.billToDto.accountOwnerCode
      )
    );
    const formattedPhoneNumber_PrintRep = this._orderService.orderDto.billToDto
      .printRepCode
      ? filterPipe.transform(
          this._configService.getUserPhoneFromUserCode(
            this._orderService.orderDto.billToDto.printRepCode
          )
        )
      : '';

    this.cfdEmailDto.toRecipients =
      this._orderService.orderDto.orderByDto.email;
    this.cfdEmailDto.subject = 'Optum Quote(s) ';
    let emailbodytext, regards;
    this.setEmailFrom(accountTypeCode);

    if (this._orderService.orderDto.salesOrganization === 'Shutterfly') {
      const emailBodyTextAndRegards =
        this.setEmailBodyAndRegardsForSalesOrgShutterfly(
          accountTypeCode,
          formattedPhoneNumber_PrintRep
        );
      emailbodytext = emailBodyTextAndRegards.emailbodytext;
      regards = emailBodyTextAndRegards.regards;
    } else {
      regards =
        this._configService.getFullNameFromUserCode(
          this._orderService.orderDto.billToDto.accountOwnerCode
        ) +
        '\nContent Solutions Client Manager' +
        '\n' +
        this._configService.getUserEmailFromUserCode(
          this._orderService.orderDto.billToDto.accountOwnerCode
        ) +
        '\n' +
        (formattedPhoneNumber !== '' ? '1.' : '') +
        formattedPhoneNumber;
    }

    if (accountTypeCode !== 'O') {
      this.cfdEmailDto.bccRecipients =
        this._configService.getUserEmailFromUserCode(
          this._orderService.orderDto.billToDto.accountOwnerCode
        );
      emailbodytext =
        'your Content Solutions Client Manager at the number listed below.  Hours of operation are 8 AM - 7 PM ET,';
    }
    this.cfdEmailDto.emailBody =
      'Thank you for selecting Optum  - your source for simplifying the complex business of health care.' +
      ' Please review your quote detail attached.\n\nContact us immediately with any corrections or changes to the information by replying to this e-mail or call ' +
      emailbodytext +
      ' Monday through Friday.\n\nTo proceed with purchase through email you must attach a copy of the Quote PDF in your response.\n\n\nRegards,\n' +
      regards;

    this.msId = this._configService.getMyMSId();
    this.cfdEmailDto.fileName = `CFD_${orderNo}_Files.pdf`;
  }
  setEmailFrom(accountTypeCode: string): void {
    if (accountTypeCode === 'C' || accountTypeCode === 'H') {
      this.cfdEmailDto.emailFrom = 'Partners@optum.com';
    } else if (accountTypeCode === 'O') {
      this.cfdEmailDto.emailFrom = 'Confirmations@optum.com';
    } else {
      if (this._orderService.orderDto.salesOrganization === 'Shutterfly') {
        this.cfdEmailDto.emailFrom = this._orderService.orderDto.billToDto
          .printRepCode
          ? this._configService.getUserEmailFromUserCode(
              this._orderService.orderDto.billToDto.printRepCode
            )
          : '';
      } else {
        this.cfdEmailDto.emailFrom =
          this._configService.getUserEmailFromUserCode(
            this._orderService.orderDto.billToDto.accountOwnerCode
          );
      }
    }
  }
  setEmailBodyAndRegardsForSalesOrgShutterfly(
    accountTypeCode: string,
    formattedPhoneNumber_PrintRep: string
  ): { emailbodytext: string; regards: string } {
    let emailbodytext = '';
    let regards = '';
    if (accountTypeCode === 'O') {
      emailbodytext =
        'Customer Service at 1.800.464.3649, Option 1. Hours of operation are 8 AM - 8 PM ET,';
      regards =
        (this.enteredByUserRole === 'SystemAdministrator' ||
        this.enteredByUserRole === 'OrderManagement'
          ? 'Melissa Werth'
          : this._configService.getMyFullName()) +
        '\nCustomer Service Rep\nCustomerAssistance@Optum.com\n1.800.464.3649, option 1';
    } else {
      regards = this._orderService.orderDto.billToDto.printRepCode
        ? this._configService.getFullNameFromUserCode(
            this._orderService.orderDto.billToDto.printRepCode
          ) +
          '\nContent Solutions Client Manager' +
          '\n' +
          this._configService.getUserEmailFromUserCode(
            this._orderService.orderDto.billToDto.printRepCode
          ) +
          '\n' +
          (formattedPhoneNumber_PrintRep !== '' ? '1.' : '') +
          formattedPhoneNumber_PrintRep
        : '';
    }
    return { emailbodytext, regards };
  }
  cancelDialog() {
    this.quoteCfdFormEmail.close('cancel');
  }
  async sendEmail(): Promise<void> {
    // const divElement = document.getElementById('divQuoteCFDPdf'); 
    // if (!divElement) {
    //   console.error('divQuoteCFDPdf element not found in the DOM.');
    //   return;
    // }
    // console.log(
    //   `Qontent of divElement send email for order :`,
    //   divElement.innerHTML
    // );
    // divElement.style.display = 'inline-block';

    try {
      const selectedOrders = this.orderSearchResults.data.filter( //
        (order) => order.cfd
      );

      if (selectedOrders.length === 1) {

        if (this._orderService.orderDto.billToDto.isPORequired) {
          this.disclaimerType = 'porequired';
        } else if (
          this._orderService.orderDto.billToDto.creditLimit !== 10000 &&
          this._orderService.orderDto.billToDto.creditLimit !== 1000
        ) {
          this.disclaimerType = 'prepayrequired';
        } else {
          this.disclaimerType = 'standard';
        }
        const divElement = document.getElementById('divQuoteCFDPdf'); 
    if (!divElement) {
      console.error('divQuoteCFDPdf element not found in the DOM.');
      return;
    }
    console.log(
      `Qontent of divElement send email for order :`,
      divElement.innerHTML
    );
    divElement.style.display = 'inline-block';
    
        const quoteCFDPdf =
          await this._generatePdfService.generateQuoteCFDPdf();
        divElement.style.width =
          this._generatePdfService.getPDFDivWidthBasedOnBrowserZoomLevel(
            Math.round(window.devicePixelRatio * 100)
          );

        let srcWidth = divElement.scrollWidth || 1;

        const pWidth = quoteCFDPdf.internal.pageSize.width;
        const margin = 30;
        const scale = (pWidth - margin * 2) / srcWidth;

        quoteCFDPdf.html(divElement, {
          html2canvas: {
            scrollX: 0,
            scrollY: 0,
            scale: window.matchMedia('screen and (max-width: 1200px)').matches
              ? 1
              : scale,
          },
          x: 0,
          y: 0,
          margin: [75, 35, 35, 35],
          autoPaging: 'text',
          callback: async (pdf) => {
            divElement.style.display = 'none';

            const quoteCFDEmailDto = new QuoteCFDEmailDto();
            quoteCFDEmailDto.emailFrom = this.cfdEmailDto.emailFrom;
            quoteCFDEmailDto.toRecipients = this.cfdEmailDto.toRecipients;
            quoteCFDEmailDto.ccRecipients = this.cfdEmailDto.ccRecipients;
            quoteCFDEmailDto.bccRecipients = this.cfdEmailDto.bccRecipients;
            quoteCFDEmailDto.subject = this.cfdEmailDto.subject;
            quoteCFDEmailDto.emailBody = this.cfdEmailDto.emailBody;
            quoteCFDEmailDto.emailAttachment = pdf.output('datauristring');
            quoteCFDEmailDto.fileName = this.cfdEmailDto.fileName;

            // Assuming the fileName logic is not essential for sending the email
            // let fileName = this._orderService.saveCfd(pdf, this.msId);
            // quoteCFDEmailDto.fileName = fileName;

            this._emailService.sendCfdEmail(quoteCFDEmailDto).subscribe(
              () => {
                this.swalAlert.alert('Mail sent successfully');
                this.dialog.close('sent');
              },
              (error) => {
                console.log(error);
                this.swalAlert.alert(
                  'An error occurred while sending the email. Please try again.'
                );
              }
            );
          },
        });
      } else {
        const zip = new JSZip();

        //const promises = selectedOrders.map(order => this.generateOrderPDF(order));
        const pdfBlobs: Blob[] = [];
        //await Promise.all(promises);

        //loop through selectedOrders and generate PDFs for each order
        for (let i = 0; i < selectedOrders.length; i++) {
         //for(const order of selectedOrders){
          const orderDetail = await this._orderService
            .getOrderInfo(selectedOrders[i].orderNumber??0)
            .toPromise();
          this._orderService.orderDto = orderDetail;
          for (const orderDetail of this._orderService.orderDto
            .orderDetailDtos) {
            orderDetail.unitPrice =
              Math.round(orderDetail.listPrice * (100 - orderDetail.discount)) /
              100;
            orderDetail.orderQuantity =
              orderDetail.openQuantity + orderDetail.shippedQuantity;
          }
          if (this._orderService.orderDto.billToDto.isPORequired) {
            this.disclaimerType = 'porequired';
          } else if (
            this._orderService.orderDto.billToDto.creditLimit !== 10000 &&
            this._orderService.orderDto.billToDto.creditLimit !== 1000
          ) {
            this.disclaimerType = 'prepayrequired';
          } else {
            this.disclaimerType = 'standard';
          }
          //const pdfBlob = await this.generateOrderPDF(order);

          const divElement = document.getElementById('divQuoteCFDPdf'); 
          if (!divElement) {
            console.error('divQuoteCFDPdf element not found in the DOM.');
            return;
          }
          console.log(
            `Qontent of divElement send email for order :`,
            divElement.innerHTML
          );
          divElement.style.display = 'inline-block';

          pdfBlobs[i] = await this.generateOrderPDF(selectedOrders[i]);
        }

        console.log(`Generated ${pdfBlobs.length} PDFs`);

        pdfBlobs.forEach((blob: Blob, index: number) => {
          console.log(`Adding PDF for order ${selectedOrders[index].orderNumber} to ZIP`);
          console.log('Adding pdf for order number:', this._orderService.orderDto.id);
            const fileName = `CFD_${selectedOrders[index].orderNumber}.pdf`;
          console.log(`Adding ${fileName} to ZIP`);
          zip.file(fileName, blob);
        });

        const content = await zip.generateAsync({ type: 'blob' });

        const reader = new FileReader();
        reader.readAsDataURL(content);
        reader.onloadend = () => {
          const base64data = reader.result as string;
          console.log('ZIP file created and converted to base64');

          const quoteCFDEmailDto = new QuoteCFDEmailDto();
          quoteCFDEmailDto.emailFrom = this.cfdEmailDto.emailFrom;
          quoteCFDEmailDto.toRecipients = this.cfdEmailDto.toRecipients;
          quoteCFDEmailDto.ccRecipients = this.cfdEmailDto.ccRecipients;
          quoteCFDEmailDto.bccRecipients = this.cfdEmailDto.bccRecipients;
          quoteCFDEmailDto.subject = this.cfdEmailDto.subject;
          quoteCFDEmailDto.emailBody = this.cfdEmailDto.emailBody;
          quoteCFDEmailDto.emailAttachment = base64data;
          quoteCFDEmailDto.fileName = 'orders.zip';

          this._emailService.sendCfdEmail(quoteCFDEmailDto).subscribe(
            () => {
              this.swalAlert.alert('Mail sent successfully');
              this.dialog.close('sent');
            },
            (error) => {
              console.log(error);
              this.swalAlert.alert(
                'An error occurred while sending the email. Please try again.'
              );
            }
          );
        };
      }
    } catch (error) {
      console.error('Error generating or sending PDF:', error);
      //divElement.style.display = 'none';
      this.swalAlert.alert(
        'An error occurred while generating the PDF. Please try again.'
      );
    }
  }

  async previewQuoteCFDPdf(): Promise<void> {
    await this._generatePdfService.previewQuoteCFDPdf(
      'divQuoteCFDPdf',
      Math.round(window.devicePixelRatio * 100)
    );
  }
  toggleAll() {
    this.orderSearchResults.data.forEach(
      (order) => (order.cfd = this.selectAll)
    );
  }

  updateSelectAllState() {
    this.selectAll = this.orderSearchResults.data.every((order) => order.cfd);
  }

   async generateOrderPDF(order: OrderSearchResult): Promise<Blob> {
    
    try {
      return new Promise(async (resolve, reject) => {
        await new Promise((resolve) => setTimeout(resolve, 1000));
    const divElement = document.getElementById('divQuoteCFDPdf');
    console.log(
      `QContent of divElement for order ${order.orderNumber}:`,
      divElement
    );

    if (!divElement) {
      throw new Error('divQuoteCFDPdf element not found in the DOM.');
    }

    divElement.style.display = 'inline-block';
    console.log(
      `Qontent of divElement for order ${order.orderNumber}:`,
      divElement.innerHTML
    );
    

    
      console.log(`Generating PDF for order: ${order.orderNumber}`);

      console.log(
        `Content of divElement for order ${order.orderNumber}:`,
        divElement.innerHTML
      );

      //const quoteCFDPdf =  this._generatePdfService.generateQuoteCFDPdf();
      const quoteCFDPdf =  this._generatePdfService.generateQuoteCFDPdf();

      divElement.style.width =
        this._generatePdfService.getPDFDivWidthBasedOnBrowserZoomLevel(
          Math.round(window.devicePixelRatio * 100)
        );

      let srcWidth = divElement.scrollWidth || 1;
      const pWidth = quoteCFDPdf.internal.pageSize.width;
      const margin = 30;
      const scale = (pWidth - margin * 2) / srcWidth;
      console.log(
        `PDF scale: ${scale}, srcWidth: ${srcWidth}, pWidth: ${pWidth}`
      );

      
        quoteCFDPdf.html(divElement, {
          html2canvas: {
            scrollX: 0,
            scrollY: 0,
            scale: window.matchMedia('screen and (max-width: 1200px)').matches
              ? 1
              : scale,
          },
          x: 0,
          y: 0,
          margin: [75, 35, 35, 35],
          autoPaging: 'text',
          callback: (pdf) => {
            divElement.style.display = 'none';
            try {
              const pdfBlob = pdf.output('blob');
              console.log(
                `PDF for order ${order.orderNumber} generated successfully`
              );

              resolve(pdfBlob);
            } catch (error) {
              console.error(
                `Error generating PDF for order ${order.orderNumber}:`,
                error
              );

              reject(error);
            }
          },
        });
      });
    } catch (error) {
      //divElement.style.display = 'none';
      throw error;
    }
  }

    ngOnDestroy() {
    this.gotOrderSearchResultsSubscription?.unsubscribe();
  }
}
