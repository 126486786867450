import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UITKDatePickerComponent } from '@uitk/angular/forms/date-picker/date-picker.component';
import { Subscription } from 'rxjs';
import { WorklistNoteDto } from '../../../dtos/worklist-note-dto.model';
import { ConfigService } from '../../../services/config.service';
import { ModeService } from '../../../services/mode.service';
import { WorklistQueueService } from '../../../services/worklistqueue-service';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-view-note',
  templateUrl: './view-note.component.html',
  styleUrls: ['./view-note.component.css']
})

export class ViewNoteComponent implements OnInit {
  notesDialogModal = {
    show: false,
  };
  worklistNotesIsReadySubscription: Subscription | undefined;
  @Input() worklistNoteDto: WorklistNoteDto = new WorklistNoteDto();
  numOfLines = 5;
  isDisabled = false;
  addNoteHeader = false;
  viewNoteHeader = false;
  notesData: WorklistNoteDto[] = [];
  @Output() newItemEvent = new EventEmitter<boolean>();
  normalDialogModal = { show: false, };
  notesType: any;
  contactsname: any;
  createdDate: string | null = '';
  followupDate: string | null = '';
  followUpDate:string = '';
  isNewNote = false;
  isNoteHistoryHidden = false;
  ishideAddButton = false;
  maxTextLimit = 255;
  myCharRemaining = 'characters remaining';
  visibleTextValue = "Loading Notes";
  remainingCount: number = 0;
  userName: any = '';
  isEdit:boolean = false;
  @Output() closeEvent = new EventEmitter<any>();
  @Input() hasNotes = false;
  @Input() orderNumber = 0;
  profileForm = new UntypedFormGroup({
    textarea: new UntypedFormControl(''),
  });
  noteText = '';
  followUpDateValidationMessage = '';
  showFollowUpDateValidation = false;
  showNotesFieldValidation = false;  
  datePickerConfig: any = {
    minYear: new Date().getFullYear(),
    minMonth: new Date().getMonth() + 1,
    minDay: new Date().getDate()
  };
  worklistNotesData: WorklistNoteDto[] = [];
  dateFormat='MM/dd/yyyy';
  @ViewChild('datePic') datePic!: UITKDatePickerComponent;
  editWorkListNoteObj:WorklistNoteDto=new WorklistNoteDto();
  swalAlert = new SwalAlert();

  constructor(private readonly datePipe: DatePipe,
    private readonly configService: ConfigService,
    private readonly worklistQueueService: WorklistQueueService,
    public modeService: ModeService) { }

  ngOnInit(): void {
    this.configService.resetIdleTimeout();
    this.userName = sessionStorage.getItem('username') || 'bypass';
    this.notesDialogModal.show = true;
    this.createdDate = this.datePipe.transform(new Date(), this.dateFormat);
    this.followupDate = this.datePipe.transform(new Date(),this.dateFormat);
    if (this.hasNotes === false) {
      this.isNewNote = true;
      this.isEdit = false;
      this.isNoteHistoryHidden = true;
      this.addNoteHeader = true;
    }
    else {
      this.isNewNote = false;
      this.isNoteHistoryHidden = false;
      this.viewNoteHeader = true;
      this.getNotes()
    }
  }

  getNotes() {
    this.notesData = [];
    this.modeService.isWaiting = true;
    this.worklistQueueService.getWorklistNote(this.orderNumber).subscribe(data => {
      data.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())
      this.worklistNotesData = data;
      if(data.length == 0 && this.worklistQueueService.selectedWorkListQueueDto){
        this.worklistQueueService.selectedWorkListQueueDto.hasWorkListNotes = false;
      }
      this.worklistNotesData.forEach(element => this.notesData.push({
        noteDate: element.createdDate,
        notesText: element.noteText,
        isTruncated: false,
        uniqueId: element.id,
        orderId: element.orderId,
        userCode:element.userCode,
        noteContact: element.userCode === 'ATMN' ? 'Automated User' : this.configService.getFullNameFromUserCode(element.userCode),
        followUpDate: element.followUpDate === null ? '' : new Date(this.datePipe.transform(element.followUpDate, this.dateFormat)!)
      } as WorklistNoteDto));
      this.modeService.isWaiting = false;
      if(this.worklistNotesData.length == 0){
        this.closeEvent.emit();
      }
    });
  }
  onCountChange(count: number) {
    this.remainingCount = count;
  }

  closeAddNoteDialog() {
    this.configService.resetIdleTimeout();
    this.closeEvent.emit();
  }

  hideDialog() {
    this.isNewNote = false;
    this.isEdit = false;
    if (this.hasNotes === false) {
      this.closeEvent.emit();
    }
    else {
      this.ngOnInit();
    }
  }

  cancelDialog() {
    this.configService.resetIdleTimeout();
    this.isNewNote = false;
    this.isEdit = false;
    this.closeEvent.emit();
  }

  showAddNew() {
    this.followUpDate = '';
    this.noteText = '';
    this.isNewNote = true;
    this.isEdit = false;
    this.isNoteHistoryHidden = false;
  }

  setdeflines(uniqueId: number) {
    this.notesData.forEach(element => {
      if (element.uniqueId === uniqueId) {
        if (element.isTruncated === true)
        {
          element.isTruncated = false;
        }
        else
        {
          element.isTruncated = true;
        }
      }
    });
  }

  displayFollowUpDateValidation(message: string): void {
    this.showFollowUpDateValidation = true;
    this.followUpDateValidationMessage = message;

    setTimeout(() => {
      this.showFollowUpDateValidation = false;
    }, 5000);
  }

  displayNotesFieldValidation(): void {
    this.showNotesFieldValidation = true;

    setTimeout(() => {
      this.showNotesFieldValidation = false;
    }, 5000);
  }

  saveValidation(): boolean {
    let isValid = true;
    let followDate = new DatePipe('en-US').transform(this.followUpDate, 'MM/dd/yyyy')?? '';
    let pickedDate = new DatePipe('en-US').transform(new Date(this.datePickerConfig.minYear, this.datePickerConfig.minMonth - 1, this.datePickerConfig.minDay), 'MM/dd/yyyy')??'';
    if (followDate < pickedDate) {
      this.displayFollowUpDateValidation('Follow up date should be either current or future date');
      isValid = false;
    }

    if (this.followUpDate === 'Invalid Date') {
      this.displayFollowUpDateValidation('Invalid Follow up date');
      isValid = false;
    }

    if (this.noteText === '') {
      this.displayNotesFieldValidation();
      isValid = false;
    }

    return isValid;
  }

  saveWorklistNote(): void {
    if (!this.saveValidation()) {
      return;
    }

    // Update the hasWorkListNotes property so the results grid get updated.
    if (this.worklistQueueService.selectedWorkListQueueDto) {
      this.worklistQueueService.selectedWorkListQueueDto.hasWorkListNotes = true;
    }   
    if(!this.isEdit){
      const worklistNote: WorklistNoteDto = new WorklistNoteDto();
      worklistNote.orderId = this.orderNumber;
      worklistNote.noteText = this.noteText;
      worklistNote.followUpDate = new Date(this.followUpDate);
      worklistNote.userCode = this.userName === 'bypass' ? 'ATMN' : this.configService.getMyUserCode();  
      this.worklistQueueService.saveWorklistNote(worklistNote).subscribe(() => {
        this.hideDialog();
      }, error => {
        console.log(error);
        this.swalAlert.alert('An error occured while saving the worklist note');
      });
    }else{
      this.editWorkListNoteObj.followUpDate = new Date(this.followUpDate);
      this.editWorkListNoteObj.noteText = this.noteText;
      this.editWorkListNoteObj.id = this.editWorkListNoteObj.uniqueId;     
      this.worklistQueueService.updateWorklistNote(this.editWorkListNoteObj).subscribe(() => {
        this.hideDialog();
      }, error => {
        console.log(error);
        this.swalAlert.alert('An error occured while updating the worklist note');
      });
    }
    
  }

  editNote(note:WorklistNoteDto){
    this.followUpDate = new DatePipe('en-US').transform(note.followUpDate, 'MM/dd/yyyy') ?? '';
    this.noteText = note.notesText;
    this.editWorkListNoteObj = note;
    //this.userName = note.userCode === 'ATMN' ? 'Automated User' : this.configService.getUserLoginFromUserCode(note.userCode).split('\\')[1];
   this.userName = note.noteContact;
    this.isNewNote = true;
    this.isEdit = true;
    this.isNoteHistoryHidden = false;
    setTimeout(()=>{
      this.formatDate();
    });
  }

  deleteNote(note:WorklistNoteDto){
    this.worklistQueueService.deleteWorklistNote(note.uniqueId).subscribe(() => {
      this.hideDialog();
    }, error => {
      console.log(error);
      this.swalAlert.alert('An error occured while deleting the worklist note');
    });
  }

  formatDate() {    
    let a = this.datePic.datePickerInput.model;
    a = a.replaceAll('/','');
    if(a !== ''){
     // let value = a.substring(0, 2) + '/' + a.substring(2, 4) + '/'+ a.substring(4, 9);
      let val = a.substring(4, 9) + '-' + a.substring(0, 2) + '-' + a.substring(2, 4);
      let o = {
        dateISO: val,
        disabled: false,
        other: false,
        selected: true,
        today: false
      }
      this.datePic.selectDay(o);
    }

  }
}
