<div *ngIf="dialog.show" class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog uitkId="quoteCdfForm" [(showModal)]="dialog.show" [closeBtn]="false">
    <uitk-dialog-header>
        <div cdkDragHandle>
            <div style="border-bottom: 1px solid; text-align: center; background-color: #f2f2f2;">
                <label style=" font-size: 20px;">CFD Email</label>
            </div>
        </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
        <div class="row mx-3">
            <div class="row mt-2">
                <label class="col-lg-3 align-self-center" uitkLabel>From: </label>
                <div class="col-lg-9">
                    <uitk-form-field>
                        <input uitkInput id="emailfrom" class="textbox" type="text" [(ngModel)]="cfdEmailDto.emailFrom" />
                    </uitk-form-field>
                </div>
            </div>
            <div class="row mt-2">
                <label class="col-lg-3 align-self-center" uitkLabel>TO Recipients: </label>
                <div class="col-lg-9">
                    <uitk-form-field id="toRecipients">
                        <input uitkInput #toRecipients="ngModel" class="textbox" type="email" [pattern]="emailPattern"
                            value="" [(ngModel)]="cfdEmailDto.toRecipients" />
                        <uitk-form-field-error *ngIf="toRecipients?.errors?.pattern && toRecipients?.touched">
                            Invalid Email
                        </uitk-form-field-error>
                    </uitk-form-field>
                </div>
            </div>
            <div class="row mt-2">
                <label class="col-lg-3 align-self-center" uitkLabel>CC Recipients: </label>
                <div class="col-lg-9">
                    <uitk-form-field id="ccRecipients">
                        <input uitkInput #ccRecipients="ngModel" class="textbox" type="email" [pattern]="emailPattern"
                            value="" [(ngModel)]="cfdEmailDto.ccRecipients" />
                        <uitk-form-field-error *ngIf="ccRecipients?.errors?.pattern && ccRecipients?.touched">
                            Invalid Email
                        </uitk-form-field-error>
                    </uitk-form-field>
                </div>
            </div>
            <div class="row mt-2">
                <label class="col-lg-3 align-self-center" uitkLabel>BCC Recipients: </label>
                <div class="col-lg-9">
                    <uitk-form-field id="`bccRecipients">
                        <input uitkInput #bccRecipients="ngModel" class="textbox" type="email" [pattern]="emailPattern"
                            value="" [(ngModel)]="cfdEmailDto.bccRecipients" />
                        <uitk-form-field-error *ngIf="bccRecipients?.errors?.pattern && bccRecipients?.touched">
                            Invalid Email
                        </uitk-form-field-error>
                    </uitk-form-field>
                </div>
            </div>
            <div class="row mt-2">
                <label class="col-lg-3 align-self-center" uitkLabel>Subject: </label>
                <div class="col-lg-9">
                    <uitk-form-field>
                        <input uitkInput id="emailsubject" class="textbox" type="text" value=""
                        [(ngModel)]="cfdEmailDto.subject" />
                    </uitk-form-field>
                </div>
            </div>
            <div class="row mt-2">
                <label class="col-lg-3 align-self-center" uitkLabel>Attachments: </label>
                <div class="col-lg-9">
                    <a class="link" target="_blank" style="color:#316bbe;" (click)="previewQuoteCFDPdf()"><strong>PDF (click to view)</strong></a>
                </div>
            </div>
            <div class="row mt-4">
                <label class="col-lg-3 align-self-center" uitkLabel>Email Body: </label>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <textarea uitkTextarea style="height:300px; padding: 18px;" [(ngModel)]="cfdEmailDto.emailBody"></textarea>
                </div>
            </div>
        </div>
    </uitk-dialog-content>
    <uitk-dialog-actions style="width: fit-content;">
        <div class="mx-4" style="margin-bottom: 20px;">
            <button id="back" type="button"
                class="uitk-c-button uitk-c-button--alternative-ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
                style="margin-right: 470px;" (click)="cancelDialog()">Back</button>

            <button id="sendEmail" type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
                (click)="sendEmail()">Send Email</button>
        </div>
    </uitk-dialog-actions>
</uitk-dialog>
    </div>
</div>