import { Component, OnInit } from '@angular/core';
import { IUITKRadioGroupItem } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { ARSLabelDto } from 'src/app/dtos/ars-label-dto.model';
import { ARSLabelService } from 'src/app/services/ars-label.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-ars-label',
  templateUrl: './ars-label.component.html',
  styleUrls: ['./ars-label.component.css']
})
export class ArsLabelComponent implements OnInit {
  private readonly _orderService: OrderService;
  private readonly _arsLabelService: ARSLabelService;
  configIsReadySubscription: Subscription | undefined;
  arsYesNoItems: IUITKRadioGroupItem[] = [
    {
      label: 'Yes',
      value: true,
      disabled: false,
    },
    {
      label: 'No',
      value: false,
      disabled: false,
    },
  ];

  constructor(orderService: OrderService, public arsLabelService: ARSLabelService) {
    this._orderService = orderService;
    this._arsLabelService = arsLabelService;
  }

  ngOnInit(): void {
    this.resetARSLabelServiceVariables();
    if (this._orderService.orderDto.arsLabel) {
      this._arsLabelService.getARSLabelDetails(this._orderService.orderDto.id).subscribe((data: ARSLabelDto) => {
        this._arsLabelService.arsLabelDto = data;
        this._arsLabelService.arsLabelId =  data.id;
        this.arsLabelService.selectedARSYesNo = this.arsYesNoItems[0];
        this.onChangeOfDeliveryMethodOption(data.deliveryMethod);
        this.setArsEmailAddress(data);
      });
    }
    else {
      this._arsLabelService.selectedARSYesNo = this.arsYesNoItems[1];
      this.onChangeOfYesOrNoOption(false);
      this.defaultEmailAddress();
    }
  }

  onChangeOfYesOrNoOption(option: boolean) {
    if (!this._orderService.orderDto.arsLabel) {
      this.onChangeOfDeliveryMethodOption('Email');
    }
    this.arsLabelService.showARSEmailAddressSection = option ? true : false;

  }

  onChangeOfDeliveryMethodOption(deliveryMethod: string) {
    this.arsLabelService.showARSEmailAddressSection = deliveryMethod === 'Email' ? true : false;
  }

  defaultEmailAddress(): void {
    if (this._orderService.orderDto.shipToDto.email) {
      this._arsLabelService.arsEmailAddress = this._orderService.orderDto.shipToDto.email;
    }
    else if (this._orderService.orderDto.orderByDto.email) {
      this._arsLabelService.arsEmailAddress = this._orderService.orderDto.orderByDto.email;
    }
    else {
      this._arsLabelService.arsEmailAddress = '';
    }
  }

  setArsEmailAddress(arsDetails: ARSLabelDto): void {
    if (arsDetails.deliveryMethod === 'Email')
    {
      this._arsLabelService.arsEmailAddress = arsDetails.email.trim();
    }
    else
    {
      this.defaultEmailAddress();
    }
  }


  resetARSLabelServiceVariables(): void {
    this._arsLabelService.selectedARSYesNo = null;
    this._arsLabelService.showARSEmailAddressSection = true;
    this._arsLabelService.arsLabelId = 0;
    this._arsLabelService.arsEmailAddress = '';
    this._arsLabelService.showARSLabelErrors = false;
    this._arsLabelService.arsLabelErrors = '';
  }
}
