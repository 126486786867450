<button name="contactSearchBtn" id="contactSearchBtn"
[disabled]="modeService.mode !== Mode.Edit || isShipToDisabled"
class="uitk-c-button uitk-c-button--ghost uitk-icon uitk-icon-search px-0 py-1 mt-1" type="button"
(click)="getContacts()"></button>

<div *ngIf="contactSearch.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkId="contactSearch" id = "contactSearch" [(showModal)]="contactSearch.show" [closeBtn] = "true">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Contact Search
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div class="row">
      <div class="col">

        <div class="inner-addon right-addon">
          <em class="uitk-icon uitk-icon-search"></em>
          <input type="text" class="uitk-c-form__control" placeholder="Search" [(ngModel)]="searchtxt"  (input)="onChange($event)" />
        </div>
      </div>
    </div>

    <div class="uitk-c-table--responsive">

      <table uitkTable [dataSource]="dataSource" class="uitk-c-table" id="contactSearch-table">
        <thead class="uitk-c-table__head">
          <tr uitkTableSort #sortTable="uitkTableSort">
            <th uitkTableSortColumn
            [column]="headerName.id"
            (sortChange)="onSortChange($event)"
             *ngFor="let headerName of tableHeader" scope="col" id={{headerName.id}}>
              {{ headerName.name }}
            </th>
          </tr>
        </thead>
        <tbody id="row-data" class="uitk-c-table__body" [hidden]="modeService.mode === Mode.Wait">
          <ng-container *uitkTableRowBody="let data">
            <tr class="uitk-c-table__row">
              <td><a class="link" (click)="contactSelect(data.contactId)">{{ data.contactId }}</a></td>
              <td>{{ data.fullName}}</td>
              <td>
                <span *ngIf="data.company"> {{data.company}},</span>
                <span *ngIf="data.department">{{data.department}},</span><br>
                <span *ngIf="data.street">{{data.street}},</span>
                <span *ngIf="data.city">{{data.city}},<br></span>
                <span *ngIf="data.state">{{data.state}},</span>
                <span *ngIf="data.zip">{{data.zip}}</span>
              </td>
              <td>{{ data.phone | phoneFormat }}</td>
              <td>{{ data.email }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div *ngIf="dataSource.data.length == 0">No records found</div>
      <div class="uitk-l-grid--max-width">
        <div id="loadingIndicator" [hidden]="modeService.mode !== Mode.Wait">
          <uitk-loading-indicator uitkId="spinner" [visibleText]="spinnerText" spinnerSize="large">
          </uitk-loading-indicator>
        </div>
      </div>
    </div>
  </uitk-dialog-content>
</uitk-dialog>
  </div>
</div>
