<div class="container-fluid px-0 bg-lightgrey">

  <!-- Result search navigation bar -->
  <div class="row mx-0 bg-darkgrey">
    <div class="col-12">
      <app-search-navigation-bar></app-search-navigation-bar>
    </div>
  </div>
  <div class="row gx-0">
    <div class="col-3">

      <!-- Left side bar -->
      <div class="container-fluid px-3 mx-0">

        <!-- Order status -->
        <div class="row bg-lightgrey gx-0 mt-2">
          <div class="col-12">
            <app-order-status></app-order-status>
          </div>
        </div>

        <!-- Order By component -->
        <div class="row bg-light gx-0 mt-2">
          <div class="col-12">
            <app-order-by id="order-by"></app-order-by>
          </div>
        </div>

        <!-- Ship To component -->
        <div class="row bg-light gx-0 mt-2">
          <div class="col-12">
            <app-ship-to id="ship-to" (addressChanged)="changeShippingAndHandlingBR('ContactAddress', null); orderHeader?.onChangeShipToAddress($event)"></app-ship-to>
          </div>
        </div>

        <!-- Bill To component -->
        <div class="row bg-light gx-0 mt-2">
          <div class="col-12">
            <app-bill-to id="bill-to"></app-bill-to>
          </div>
        </div>
      </div>
    </div>

    <div class="col-9">
      <div class="container-fluid px-0 bg-light">
        <div class="row gx-0" style="min-height:90vh">
          <div class="col-12" [hidden]="modeService.isWaiting">

            <div class="d-flex flex-column h-100 px-0 mx-0">

              <!-- Order header component -->
              <div class="row px-0 mx-0">
                <div class="col-12 bg-light">
                  <app-order [enableEditMode]="enableEditMode" (addLineItem)="orderGrid?.add()" (addingProductCodeFromSearchDialog)="orderGrid?.openItemSearchDialog()" #orderHeader></app-order>
                </div>
              </div>

              <!-- Order grid component -->
              <div class="row flex-grow-1 px-0 mx-0">
                <div class="col-12" style="position: relative !important; width: 100%; z-index: 1; min-height: 135px;">
                  <div style="overflow-x: auto; overflow-y: auto; width: 98%; position: absolute; height: 100%;">
                    <app-order-grid #orderGrid (changePayMeth)="changePaymentMethode()" (addedShippingItem)="this.changeShippingAndHandlingBR('ShippingItemAdded', null)" (adjustedLineItem)="adjustedLineItem($event)"></app-order-grid>
                  </div>
                </div>
                
              </div>

              <!-- Additional action buttons component -->
              <div class="row px-0 mx-0">
                <div class="col-12">
                  <app-additional-actions></app-additional-actions>
                  
                </div>
              </div>

              <!-- Order footer component -->
              <div class="row px-0 mx-0">
                <div class="col-12">
                  <app-order-footer 
                    (addCheckOrCashRow)="orderGrid?.addCheckOrCashRow()"
                    (changedShipVia)="changeShippingAndHandlingBR('ShipVia', null)">
                  </app-order-footer>
                </div>
              </div>
            </div>
          </div>

          <!-- Spinner -->
          <div class="col-12" style="padding-top: 400px" [hidden]="!modeService.isWaiting">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <div id="loadingIndicator">
                    <uitk-loading-indicator uitkId="spinner" spinnerSize="large">
                    </uitk-loading-indicator>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col d-flex justify-content-center" >
                  <h5>
                    {{modeService.waitDescription}}
                  </h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Dialogs -->

<div *ngIf="showPackingSlipNotesDialog.show">
  <app-packing-slip-notes [dialog]="showPackingSlipNotesDialog" (cancel)="revertShipViaValue()" (save)="savePackingSlipNotes()"></app-packing-slip-notes>
</div>
