import { Component, Input } from '@angular/core';
import { Dialog } from '../../helpers/dialog';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-save-confirmation-dialog',
  templateUrl: './save-confirmation-dialog.component.html',
  styleUrls: ['./save-confirmation-dialog.component.css']
})
export class SaveConfirmationDialogComponent {
  @Input() dialog!: Dialog<void>;

  constructor(public orderService: OrderService) { }

  okDialog() {
    this.dialog.close();
  }
}
