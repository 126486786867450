import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-large-tooltip',
  templateUrl: './large-tooltip.component.html',
  styleUrls: ['./large-tooltip.component.css']
})
export class LargeTooltipComponent {
  @Input('data') data: any;

}
