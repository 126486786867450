import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Mode } from '../../enums/mode.enum';
import { ContactDto } from '../../dtos/contact-dto.model';
import { EmailInputDto } from '../../dtos/email-input-dto.model';
import { SubscriptionEditDto } from '../../dtos/subscription-edit-dto.model';
import { SubscriptionSearchResult } from '../../dtos/subscription-search-result.model';
import { AuthorizationService } from '../../services/authorization.service';
import { ConfigService } from '../../services/config.service';
import { ContactService } from '../../services/contact.service';
import { EmailService } from '../../services/email.service';
import { ModeService } from '../../services/mode.service';
import { SubscriptionService } from '../../services/subscription.service';
import { SwalAlert } from '../../helpers/alert';

@Component({
  selector: 'app-subscription-edit',
  templateUrl: './subscription-edit.component.html',
  styleUrls: ['./subscription-edit.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
  
export class SubscriptionEditComponent implements OnInit {
  private readonly _subscriptionService: SubscriptionService;
  private readonly _emailService: EmailService;
  private readonly _authorizationService: AuthorizationService;
  @ViewChild("okBtn") okBtn!: ElementRef<HTMLInputElement>;
  @Input() subscriptionSearchResult: SubscriptionSearchResult = new SubscriptionSearchResult();
  @Input() dialog = { show: false };
  reactivateDatePickerForm = new UntypedFormGroup({
    datePicker: new UntypedFormControl(''),
  });
  sentEmailDialogModal = { show: false, };
  tableHeader = [
    { name: 'Item', id: 'Item' },
    { name: 'Description', id: 'Description' },
    { name: 'Qty', id: 'Qty' },
    { name: 'Start Date', id: 'StartDate' },
    { name: 'Term', id: 'Term' },
    { name: 'Serial Number', id: 'SerialNumber' },
    { name: 'Re-send Email', id: 'ResendEmail' },
  ];
  contactList: IUITKSelectItemProps[] = [];
  contacts: ContactDto[] = [];
  disableSaveBtn = true;
  contactId: number = 0;
  contactNumber = '';
  showReactivateSubDateError = false;
  reactiveSubUntil: string | null = '';
  startDate: string | null = '';
  accountId: number = this.subscriptionSearchResult.accountId;
  isEmailSent = false;
  isSaveSuccess = false;
  configIsReadySubscription: Subscription | undefined;
  subscriptionDtoIsReadySubscription: Subscription | undefined;
  subscriptionStatusList: IUITKSelectItemProps[] = [];
  subscriptionStatusListWithoutMAndT: IUITKSelectItemProps[] = [];
  Mode = Mode;
  loading = false;
  reactivateDateDisabled = true;
  visibleTextValue = this.modeService.mode === Mode.Wait ? "" : "Processing...";
  subscriptionDto: SubscriptionEditDto = new SubscriptionEditDto();
  subscriptionDtoBackup: SubscriptionEditDto = new SubscriptionEditDto();
  contactIdBackup = 0;
  accountIdBackup = 0;
  subscriptionStatus: IUITKSelectItemProps | null = null;
  selectedContact: IUITKSelectItemProps | null = null;
  isStatusDisabled = false;
  updateOnlyCheck = true;
  sendRenewalNoticeCheck = true;
  sendUpdatesCheck = true;
  emailUpdatesCheck = true;
  currentDate = new Date();
  isActiveSubscription = false;
  datePickerConfig: any = {
    minYear: new Date().getUTCFullYear(),
    minMonth: new Date().getUTCMonth() + 1,
    minDay: new Date().getUTCDate() + 1
  };
  followUpDateValidationMessage = '';
  newSelectedContact = '';
  dateFormat = 'MM/dd/yyyy';
  swalAlert = new SwalAlert();

  constructor(subscriptionService: SubscriptionService,
    public modeService: ModeService,
    public configService: ConfigService,
    public contactService: ContactService,
    emailService: EmailService,
    authorizationService: AuthorizationService) {
    this._subscriptionService = subscriptionService;
    this._emailService = emailService;
    this._authorizationService = authorizationService;
  }

  ngOnInit(): void {
    this.modeService.mode = Mode.Wait;
    this.configIsReadySubscription = this.configService.configIsReady.subscribe(() => {
      if (!this.configService.reference) {
        throw new Error('References are not loaded.');
      }
      this.subscriptionStatusList = this.configService.getPickList("SUBSTATUS");
      this.subscriptionStatusList.forEach(element => {
        if (element.value !== 'A' && element.value !== 'S') {
          element.disabled = true;
        }
      });
      this.subscriptionStatus = this.configService.getPickList('SUBSTATUS').filter(x => x.id === this.subscriptionDto.subscriptionStatus)[0];
      //console.log("status list",this.subscriptionStatusList)

      this.showEditDialogueModel();
    });
    this.configService.loadConfigurations();
    this.modeService.mode = Mode.Edit;
    if (this._authorizationService.hasResource('SubscriptionEditUpdateOnlyCheckbox')) {
      this.updateOnlyCheck = false;
    }
    if (this._authorizationService.hasResource('SubscriptionEditSendRenewalNoticeCheckbox')) {
      this.sendRenewalNoticeCheck = false;
    }
    if (this._authorizationService.hasResource('SubscriptionEditSendUpdateCheckbox')) {
      this.sendUpdatesCheck = false;
    }
    if (this._authorizationService.hasResource('SubscriptionEditEmailUpdateCheckbox')) {
      this.emailUpdatesCheck = false;
    }
  }

  getContactList() {
    this.modeService.mode = Mode.Wait;

    this.contactService.getContactInformation(this.subscriptionSearchResult.accountId).subscribe(
      (data: any) => {
        this.contacts = data;
        this.contactList = [];
        for (const contact of this.contacts) {
          const contactItem = { id: contact.contactId.toString(), label: '', value: contact.contactId.toString() };

          if (contact.firstName == null) {
            contact.firstName = "";
          }
          if (contact.lastName == null) {
            contact.lastName = "";
          }
          if (contact.firstName === "" && contact.lastName === "") {
            contactItem.label = "Unknown";
          }
          else {
            contactItem.label = contact.firstName + " " + contact.lastName + " - " + contact.contactId.toString();
          }

          this.contactList.push(contactItem);

        }
        this.selectedContact = this.contactList.filter(x => x.id === this.contactId.toString())[0];
        this.newSelectedContact = this.contactList.filter(x => x.id === this.contactId.toString())[0].value;
        this.setSelectedValue(this.selectedContact);
        this.modeService.mode = Mode.Edit;

      }, error => {
        this.modeService.mode = Mode.Edit;
        console.log(error);
        this.swalAlert.alert('An error occurred while getting a subscription Contact details. Please try again.');
      }

    );
  }
  setSelectedValue(newSelectedContact: IUITKSelectItemProps) {
    if (!newSelectedContact) {
      return;
    }
    const selectedContact = this.contacts.filter(x => x.contactId.toString() === newSelectedContact.id)[0];
    this.contactId = selectedContact.contactId;
    this.subscriptionDto.contactId = selectedContact.contactId;
    this.subscriptionDto.email = selectedContact.email;
    this.subscriptionDto.allowInformationalEmails = selectedContact.allowInformationalEmails;
    this.subscriptionDto.allowPromotionalEmails = selectedContact.allowPromotionalEmails;
    this.contactNumber = selectedContact.phone;

  }


  setSelectedContact(newSelectedContact: IUITKSelectItemProps) {
    this.newSelectedContact = newSelectedContact?.value;
    if (!newSelectedContact) {
      return;
    }
    this.setSelectedValue(newSelectedContact);
    this.onChangeOfFormData();
  }

  showEditDialogueModel(): void {
    this.isEmailSent = false;
    this.isSaveSuccess = false;
    this.getSubscriptionRecordBasedOnId();
    this.disableSaveBtn = true;
  }

  getSubscriptionRecordBasedOnId() {
    this._subscriptionService.getSubscriptionDetails(this.subscriptionSearchResult.subscriptionId);
    this.modeService.mode = Mode.Wait;
    
    this.subscriptionDtoIsReadySubscription = this._subscriptionService.subscriptioneditDtoIsReady.subscribe(() => {
      this.subscriptionDto = this._subscriptionService.subscriptioneditDto;
      this.subscriptionDtoBackup = JSON.parse(JSON.stringify(this.subscriptionDto)) as SubscriptionEditDto;
      this.contactId = this.subscriptionDto.contactId;
      this.accountId = this.subscriptionSearchResult.accountId;
      this.contactIdBackup = this.contactId;
      this.accountIdBackup = this.accountId;
      this.getContactList();
      this.subscriptionStatus = this.configService.getPickList('SUBSTATUS').filter(x => x.id === this.subscriptionDto.subscriptionStatus)[0];
      this.startDate = new DatePipe('en-US').transform(this.subscriptionDto.startDate, this.dateFormat);
      this.reactiveSubUntil = new DatePipe('en-US').transform(this.subscriptionDto.reactiveDate, this.dateFormat);
      
      if (this.startDate !== null) {
        this.isActiveSubscription = new Date(new Date(new Date(this.startDate).setMonth(new Date(this.startDate).getMonth() + this.subscriptionDto.term)).getTime()) > this.currentDate;
      }
      this.setIsStatusDisabled(this.subscriptionStatus.value);    
      this.reactivateDateDisabled = (this.subscriptionStatus?.value === 'A' && this.reactiveSubUntil !== null &&
        this.reactiveSubUntil !== '') ? false : true;
      
      if (!this._authorizationService.hasResource('SubscriptionEditReactivateUntil')) {
        this.reactivateDateDisabled = true;
      }
      
      this.subscriptionStatusList.filter(x => x.value === 'S')[0].disabled = this.subscriptionStatus.value === 'C' || this.subscriptionStatus.value === 'W' ? true : false;
      
      if (this.subscriptionStatus.value !== 'M' && this.subscriptionStatus.value !== 'T') {
        this.subscriptionStatusListWithoutMAndT = this.subscriptionStatusList.filter(x => x.value !== 'M' && x.value !== 'T');
      }
      
      this.modeService.mode = Mode.Edit;
    }, error => {
      this.swalAlert.alert('Error occurred while getting subscription');
    });
  }
  setIsStatusDisabled(subscriptionStatusValue: string) {
    this.isStatusDisabled = false;
    if (subscriptionStatusValue !== 'W') {
      this.isStatusDisabled = (subscriptionStatusValue !== 'A' && subscriptionStatusValue !== 'S' &&
        subscriptionStatusValue !== 'C') || (!this.isActiveSubscription) ? true : false;
    }
    else {
      if (!this._authorizationService.hasResource("SubscriptionEditStatusFieldWToA") || (!this.isActiveSubscription)) {
        this.isStatusDisabled = true;
      }
    }
  }
  saveSubscription() {
    this.configService.resetIdleTimeout();
    if (!this.saveValidation()) {
      return;
    }
    this.isSaveSuccess = false;
    this.modeService.mode = Mode.Wait;
    this.subscriptionDto.modifiedBy = this.configService.getMyMSId();
    this.subscriptionDto.subscriptionStatus = this.subscriptionStatus?.value ?? '';
    this.subscriptionDto.reactiveDate = this.reactiveSubUntil == null ? this.reactiveSubUntil : new Date(this.reactiveSubUntil);
    this._subscriptionService.saveSubscriptionDetails(this.subscriptionDto);
    this.isSaveSuccess = true;
    this.modeService.mode = Mode.Edit;
    this.subscriptionSearchResult.updateOnly = this.subscriptionDto.sendUpdatesOnly;
    this.subscriptionSearchResult.subscriptionStatus = this.subscriptionDto.subscriptionStatus;
    this.subscriptionSearchResult.contactId = this.contactNumber;
    setTimeout(() => {
      this.okBtn.nativeElement.focus();
    }, 0);
  }

  onChangeDate(): void {
    if (this.reactiveSubUntil !== 'Invalid Date' && this.reactiveSubUntil !== this.subscriptionDtoBackup.reactiveDate) {
      this.disableSaveBtn = false;
    }
  }

  onChangeOfFormData(): void {
    if (this.subscriptionDtoBackup.subscriptionStatus === 'A' && this.subscriptionStatus?.value === 'A') {
      this.reactiveSubUntil = new DatePipe('en-US').transform(this.subscriptionDto.reactiveDate, this.dateFormat);
    }
    if (this.contactId !== this.subscriptionDtoBackup.contactId
      || this.subscriptionStatus?.value !== this.subscriptionDtoBackup.subscriptionStatus
      || this.subscriptionDto.sendUpdatesOnly !== this.subscriptionDtoBackup.sendUpdatesOnly
      || this.subscriptionDto.sendRenewalNotices !== this.subscriptionDtoBackup.sendRenewalNotices
      || this.subscriptionDto.sendUpdates !== this.subscriptionDtoBackup.sendUpdates
      || this.subscriptionDto.emailUpdates !== this.subscriptionDtoBackup.emailUpdates
      || this.reactiveSubUntil !== new DatePipe('en-US').transform(this.subscriptionDtoBackup.reactiveDate, this.dateFormat)) {
      this.disableSaveBtn = false;
      this.reactivateDateDisabled = (this.subscriptionDtoBackup.subscriptionStatus === 'S' &&
        this.subscriptionStatus?.value === 'A') || (((this.subscriptionDtoBackup.subscriptionStatus !== 'C' &&
          this.subscriptionStatus?.value === 'A') || (this.subscriptionDtoBackup.subscriptionStatus === 'A' &&
            this.subscriptionStatus?.value === 'A')) && this.reactiveSubUntil !== null && this.reactiveSubUntil !== '') ? false : true;
      
      if (!this._authorizationService.hasResource('SubscriptionEditReactivateUntil')) {
        this.reactivateDateDisabled = true;
      }
      
      this.setReactivateDateDisabled();
    }
    else {
      this.disableSaveBtn = true;
    }
    if (this.subscriptionDtoBackup.subscriptionStatus === 'A' && this.subscriptionStatus?.value !== 'A') {
      this.reactiveSubUntil = null;
      this.disableSaveBtn = false;
    }
  }

  setReactivateDateDisabled(){
    if ((this.subscriptionDtoBackup.subscriptionStatus === 'W' && this.subscriptionStatus?.value === 'A')) {
      this.reactivateDateDisabled = true;
    }

    if (!this._authorizationService.hasResource('SubscriptionEditReactivateUntil')) {
      this.reactivateDateDisabled = true;
    }
  }
  
  closeDialog(): void {
    this.configService.resetIdleTimeout();
    this.contactId = this.contactIdBackup;
    this.accountId = this.accountIdBackup;
    this.subscriptionDto = this.subscriptionDtoBackup;
    this.modeService.mode = Mode.View;
    this.dialog.show = false;
  }

  okDialog() {
    this.configService.resetIdleTimeout();
    this.sentEmailDialogModal.show = false;
  }

  sendEmail() {
    this.configService.resetIdleTimeout();
    this.isEmailSent = false;
    if (this.subscriptionDto.email !== '' && this.subscriptionDto.email !== null) {
      this.modeService.mode = Mode.Wait;
      const emailDto: EmailInputDto = new EmailInputDto();
      emailDto.emailAddress = this.subscriptionDto.email;
      emailDto.emailType = "ProductActivation";
      emailDto.orderId = this.subscriptionDto.orderId;
      emailDto.orderDetailIds = [this.subscriptionDto.id];
      this._emailService.sendEmail(emailDto).subscribe((msg: string) => {
        console.log("email sent successfully", msg);
        this.isEmailSent = true;
        this.modeService.mode = Mode.Edit;
        this.sentEmailDialogModal.show = true;
        setTimeout(() => {
          this.okBtn.nativeElement.focus();
        }, 0);
      }, (error: any) => {
        this.modeService.mode = Mode.Edit;
        this.isEmailSent = false;
        console.log(error);
        this.swalAlert.alert('An error occurred while sending the email.Please try again.');
      });
    }
  }

  displayFollowUpDateValidation(message: string): void {
    this.showReactivateSubDateError = true;
    this.followUpDateValidationMessage = message;

    setTimeout(() => {
      this.showReactivateSubDateError = false;
    }, 5000);
  }

  saveValidation(): boolean {
    let isValid = true;

    if (this.subscriptionDto.startDate !== null && this.reactivateDateDisabled === false && this.subscriptionStatus?.value === 'A' && this.reactiveSubUntil !== null) {
      if (new Date(new Date(new Date(this.subscriptionDto.startDate).setMonth(new Date(this.subscriptionDto.startDate).getMonth() +
        this.subscriptionDto.term)).getTime() - 24 * 60 * 60 * 1000) < new Date(this.reactiveSubUntil)) {
        if (this._authorizationService.hasResource('SubscriptionEditReactivateUntil')) {
          this.displayFollowUpDateValidation("Reactivate Sub Until Date can't be greater than the expire date of the subscription");
          isValid = false;
            }
      }
    }

    if (this.reactivateDateDisabled === false && (this.reactiveSubUntil === '' || this.reactiveSubUntil === null)) {
      if (this._authorizationService.hasResource('SubscriptionEditReactivateUntil')) {
        this.displayFollowUpDateValidation('Reactivate Sub Until Date should not be empty');
        isValid = false;
          }
    }

    if (this.reactiveSubUntil === 'Invalid Date') {
      if (this._authorizationService.hasResource('SubscriptionEditReactivateUntil')) {
        this.displayFollowUpDateValidation('Invalid Reactivate Sub Until Date');
        isValid = false;
      }
    }

    return isValid;
  }

  ngOnDestroy() {
    this.subscriptionDtoIsReadySubscription?.unsubscribe();
    this.configIsReadySubscription?.unsubscribe();
  }
}
