import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular/forms/select/select-item.interface';
import { Mode } from '../../../../enums/mode.enum';
import { OrderNoteDto } from '../../../../dtos/order-note-dto.model';
import { AuthorizationService } from '../../../../services/authorization.service';
import { ConfigService } from '../../../../services/config.service';
import { ModeService } from '../../../../services/mode.service';
import { OrderNotesService } from '../../../../services/order-notes.service';
import { OrderService } from '../../../../services/order.service';

@Component({
  selector: 'app-order-notes',
  templateUrl: './order-notes.component.html',
  styleUrls: ['./order-notes.component.css']
})

export class OrderNotesComponent implements OnInit {
  @Input() dialog = { show: false };
  @Output() newItemEvent = new EventEmitter<boolean>();
  numOfLines = 5;
  noteTypeList: IUITKSelectItemProps[] = [];
  noteTypeAllList: IUITKSelectItemProps[] = [];
  noteType: IUITKSelectItemProps | null = null;
  Mode = Mode;
  isAddEditNote = false;
  isEditNote = false;
  showNotesError = false;
  myUserCode = '';
  nextNoteId = -1;
  isAuthorized = false;
  isSaveNote = false;
  isCloseOrderSaveNotes = false;
  selectedNote = new OrderNoteDto();

  // TODO: This property should be removed after the button is relocated outside the component.
  hasOnInitRun = false;
  // End of TODO

  constructor(
    private readonly orderNotesService: OrderNotesService,
    public readonly modeService: ModeService,
    private readonly configService: ConfigService,
    public readonly orderService: OrderService,
    private readonly authorizationService: AuthorizationService) {
  }

  async ngOnInit(): Promise<void> {
    this.noteTypeList = this.configService.getPickList("NTYPE");
    this.noteTypeAllList = this.configService.getPickList("NTYPEALL");
    this.myUserCode = this.configService.getMyUserCode();

    this.isAddEditNote = false;
    this.isEditNote = false;

    if (this.orderService.orderDto.orderType === ' ' || this.orderService.orderDto.orderType === 'E' || this.orderService.orderDto.orderType === 'Q') {
      this.orderService.triggerEditMethod();
    } else {
      this.orderService.triggerEditMethodOnReturn();
    }
    
    this.orderService.lockOrderComplete?.subscribe(async () => {
      if(this.dialog.show)
        this.getOrderNotes();
    });

    if (!this.orderService.editWasTriggered) {
      this.getOrderNotes();
    }
  }

  async getOrderNotes(): Promise<void> {
    this.orderNotesService.orderNotesDto = await this.orderNotesService.getOrderNotes(this.orderService.orderDto.id);
    this.orderService.orderDto.orderNotes = this.orderNotesService.orderNotesDto;

    for (const orderNoteDto of this.orderService.orderDto.orderNotes) {
      orderNoteDto.userName = this.configService.getFullNameFromUserCode(orderNoteDto.userCode);
      orderNoteDto.noteTypeText = orderNoteDto.noteType == null ? '' : this.noteTypeAllList.filter(x => x.id === orderNoteDto.noteType)[0].label ?? '';
      if (orderNoteDto.userCode === this.configService.getMyUserCode() || this.authorizationService.hasResource('AdditionalActionsNotesButtonEditIcon')) {
        orderNoteDto.isEditEnabled = true;
      }
    }  
  }

  closeDialog() {
    this.dialog.show = false;
    if ((this.orderService.editWasTriggered || this.orderService.returnEditWasTriggered) && this.isSaveNote === false) {
      this.triggerRevert();
    }
    else if ((this.orderService.editWasTriggered || this.orderService.returnEditWasTriggered) && this.isSaveNote === true) {
     this.triggerSave();
    }
    else if (this.isCloseOrderSaveNotes && this.isSaveNote === true){
      this.orderService.setLockOrder(this.orderService.orderDto.id, this.configService.getMyMSId(), false).subscribe(
        (data) => {          
          this.orderService.triggerSaveMethod(true);
      });
    }
  }

  triggerRevert(){
    if (this.orderService.orderDto.orderType === ' ' || this.orderService.orderDto.orderType === 'E' || this.orderService.orderDto.orderType === 'Q') {
      this.orderService.triggerRevertMethod();
    } else {
      this.orderService.triggerRevertMethodOnReturn();
    }
  }
  triggerSave(){
    if (this.orderService.orderDto.orderType === ' ' || this.orderService.orderDto.orderType === 'E' || this.orderService.orderDto.orderType === 'Q') {
      this.orderService.triggerSaveMethod(false);
    } else {
      this.orderService.triggerSaveMethodOnReturn(false);
    }
  }
  cancelDialog() {
    this.isAddEditNote = false;
    this.isEditNote = false;
  }

  showAddNew() {
    this.isAuthorized = true;
    this.isAddEditNote = true;
    this.showNotesError = false;

    this.selectedNote = new OrderNoteDto();
    this.selectedNote.id = this.nextNoteId;
    this.nextNoteId--;
    this.selectedNote.createdDate = new Date();
    this.selectedNote.userCode = this.configService.getMyUserCode();
    this.selectedNote.userName = this.configService.getMyFullName();
    this.selectedNote.orderId = this.orderService.orderDto.id;
    this.selectedNote.isEditEnabled = true;
    this.selectedNote.orderType = this.orderService.orderDto.orderType;
    this.noteType = this.configService.getDefaultNoteTypeFromUserCode(this.configService.getMyMSId()) ?? null;
  }

  onEdit(note: OrderNoteDto) {
    this.isAuthorized = false;
    this.isAddEditNote = true;
    this.isEditNote = true;
    this.selectedNote = ({
      noteText: note.noteText,
      userCode: note.userCode,
      orderType: note.orderType,
      id: note.id,
      orderId: note.orderId,
      systemNote: note.systemNote,
      createdDate: note.createdDate,
      isTruncated: false,
      userName: this.configService.getFullNameFromUserCode(note.userCode),
      noteType: note.noteType,
      noteTypeText: note.noteType == null ? '' : this.noteTypeAllList.filter(x => x.id === note.noteType)[0].label ?? ''
    } as OrderNoteDto)

    if (this.authorizationService.hasResource('AdditionalActionsNotesSystemNoteText')
      || this.selectedNote.userCode === this.configService.getMyUserCode()
      || this.selectedNote.orderType === 'R' || this.selectedNote.orderType === 'A') {
      this.isAuthorized = true;
    }
  }

  noteTextChange(): void {
    this.showNotesError = !this.selectedNote.noteText;
  }

  saveNote(): void {
    if (!this.orderService.orderDto.orderNotes) {
      return;
    }
    if (!this.selectedNote.noteText) {
      this.showNotesError = true;
      return;
    }
    if(this.orderService.orderDto.orderStatus == 'C'){
      this.isCloseOrderSaveNotes = true;
    }
    if (!this.isEditNote) {
      this.selectedNote.noteType = this.noteType?.id ?? '';
      this.selectedNote.noteTypeText = this.noteType?.label ?? '';
    }

    const index = this.orderService.orderDto.orderNotes?.findIndex(o => o.id === this.selectedNote.id) ?? -1;
    this.selectedNote.isEditEnabled = true;
    if (index > -1) {
      this.orderService.orderDto.orderNotes[index] = this.selectedNote;
    }
    else {
      this.orderService.orderDto.orderNotes.push(this.selectedNote);
    }
    this.orderService.orderDto.orderNotes = [...this.orderService.orderDto.orderNotes];
    this.isSaveNote = true;
    this.isAddEditNote = false;
    this.isEditNote = false;
  }

  setLines(orderNoteDto: OrderNoteDto): void {
    if (!this.orderService.orderDto.orderNotes) {
      return;
    }

    for (const orderDetailDto of this.orderService.orderDto.orderNotes) {
      if (orderDetailDto === orderNoteDto) {
        if (orderDetailDto.isTruncated){
          orderDetailDto.isTruncated = false;
        }
        else {
          orderDetailDto.isTruncated = true;
        }
      }
    }
  }
}
