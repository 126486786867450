<div *ngIf="dialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" uitkId="addNewReturnDialog" [closeBtn]=false triggerElementId="addNewReturnDialog_openBtn">
  <uitk-dialog-header style="letter-spacing: 0.3px; font-size: 20px; line-height: 48px;">
    <div cdkDragHandle>
    Additional Return Information
    <uitk-notification uitkId="ccMethodValidation" [variant]="'error'" [show]="showCCError">
      <span>Credit Method is required</span>
    </uitk-notification>
    <uitk-notification uitkId="ccMethodValidation" [variant]="'error'" [show]="showCCErrorProductReturnSelection">
      <span>Product return selection is required</span>
    </uitk-notification>
    </div>
  </uitk-dialog-header>

  <uitk-dialog-content>
    <div id="addNewReturnMainDiv">
      <div class="uitk-l-grid__row pb-0 mb-0">
        <p style="font-weight:bold;">Are the selected products being returned to the warehouse?</p>
      </div>
      <div class="uitk-l-grid__row mb-3 pt-0 mt-0" id="radiolistDiv">
        <uitk-form-field>
          <uitk-fieldset>
            <uitk-radio-group uitkId="rdlist" id="rdlist" name="rdlist"
              [(ngModel)]="radioReturnSelectedProducts" [itemList]="radioItems" 
              (change)="onChangeReturnTypeRadio(radioReturnSelectedProducts.value)" [disabled]="allElectronic">
            </uitk-radio-group>
          </uitk-fieldset>
        </uitk-form-field>
      </div>

      <div class="uitk-l-grid__row">
        <p style="font-weight: bold">Select the credit method for each invoice.</p>
      </div>
      <br>

      <div class="uitk-c-table--responsive">
        <table uitkTable class="uitk-c-table" aria-describedby="returnOrder-data"
               id="returnOrder-results-table" [dataSource]="invoicesList">
          <thead class="uitk-c-table-expandable-row__thead">
            <tr uitkTableSort>
              <th id="moreHeader" class="freeze">More</th>

              <th *ngFor="let headerName of tableHeader" scope="col">
                {{ headerName.name }}
              </th>
            </tr>
          </thead>
          <tbody id="row-data" class="uitk-c-table__body">
            <ng-container *uitkTableRowRenderer="let data">
              <ng-container *uitkTableRowContext>
                <tr uitkTableExpandableRow id="{{data.invoiceNumber}}" class="freeze" [expandableContent]="expandableContent"
                    expandableA11yText=" details for {{data.invoiceNumber}}">
                  <td>
                    <a href="#" [routerLink]="['/accountreceivable/arinquiry/', orderService.orderDto.billToDto.id, data.invoiceNumber]"
                       style="text-decoration: none;" class="link" target = "_blank">{{ data.invoiceNumber }}</a>
                  </td>
                  <td>{{ data.invoiceAmount | currency }}</td>
                  <td>{{ data.balance | currency }}</td>
                  <td style="padding-top: 0.5%;">
                      <uitk-form-field>
                      <uitk-select id="ccMethodInfo"
                                   [itemList]="data.ccCreditMethodList"
                                   [defaultLabel]="defaultLabel"
                                   [defaultLabelFlag]="defaultLabelFlag"
                                   [(ngModel)]="data.creditMethod"
                                   (change)="validateForm(); updateNotes();">
                      </uitk-select>
                    </uitk-form-field>
                  </td>
                </tr>
                <ng-template #expandableContent>
                  <tr id="expandableContent" uitkExpandableContent [closeBtn]="false">
                    <div class="uitk-c-table--responsive fixedDiv">
                      <table uitkTable class="uitk-c-table" aria-describedby="return-data" id="return-table"
                             #rootTable="uitkTable" [dataSource]="data.invoiceDetails">
                        <thead class="uitk-c-table__head">
                          <tr>
                            <th *ngFor="let headerName of expandableTableHeader" scope="col">
                              {{ headerName.name }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *uitkTableRowRenderer="let invoiceDetailsItem">
                            <ng-container *uitkTableRowContext>
                              <tr>
                                <td>{{ invoiceDetailsItem.item }}</td>
                                <td>{{ invoiceDetailsItem.extendedPrice | currency }}</td>
                                <td>{{ invoiceDetailsItem.invoiceQuantity }}</td>
                              </tr>
                            </ng-container>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </tr>

                </ng-template>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="col-lg-12" *ngIf="arsLabelDialog.show">
        <app-ars-label></app-ars-label>
      </div>
      <!--Notest Section Starts-->
      <div id="noteMain" class="uitk-l-grid">
        <div class="uitk-l-grid__row">
          <label uitkLabel class="lblNotes" style="font:bold;">Notes</label>
        </div>
        <div class="uitk-l-grid__row">
          <p style="font-weight:bold;">Created Date</p>
          <div class="uitk-l-grid__col">
            <div style="padding: 0px 20px 0px 20px;">
              <uitk-form-field>
                <input uitkInput id="created_date" name="myCreateddate" value="{{todayDate | date: 'MM/dd/yyyy'}}" [disabled]="true" />
              </uitk-form-field>
            </div>
          </div>
          <p style="font-weight:bold;">User ID</p>
          <div class="uitk-l-grid__col">
            <uitk-form-field>
              <input uitkInput id="userId" name="myNoteUserID" [(ngModel)]="displayName" [disabled]="true" />
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__row">
          <p style="font-weight:bold;">Type</p>
          <div class="uitk-l-grid__col">
            <div style="padding: 0px 20px 0px 85px; width: fit-content;">
              <uitk-form-field>

                <input uitkInput id="note_type" name="myNoteType" [(ngModel)]="orderService.orderDto.additionalReturnInformation.note.type" [disabled]="true" />
              </uitk-form-field>
            </div>
          </div>
        </div><br>

        <div class="uitk-l-grid__row">
          <label uitkLabel class="lblNote" style="font:bold; ">Note</label>
          <div class="uitk-l-grid__col" id="tooltipMsgDiv">

            <button type="button"
                    style="border: none; background:none; padding-top: 12px;">
              <span class="uitk-icon uitk-icon-info_hollow sm-icon" style="font-size: 25px;color:white;"></span>
            </button>

            <div id="msgDiv">
              <p style="color: white;">Notes for Sales Order {{ orderService.orderDto.id }}</p>
            </div>
          </div>
        </div>

        <div class="uitk-l-grid__row">
          <div style="width:30px"></div>
          <div class="uitk-l-grid__col">
            <uitk-form-field>
              <textarea uitkTextarea name="additional_notes_textField" uitkId="additional_notes" placeholder="Additional Notes"
                        style="width: -webkit-fill-available;height: 90px;margin-left: 100px;" uitkInput
                        [(ngModel)]="orderService.orderDto.additionalReturnInformation.note.additionalNotes" [textLimit]="4000"></textarea>
            </uitk-form-field>
          </div>
        </div>

      </div>
    </div>
  </uitk-dialog-content>

  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="save()"
    [disabled]="!orderService.orderDto.additionalReturnInformation.note.additionalNotes || radioReturnSelectedProducts==null || this.modeService.isWaiting">
      Save
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeAddNewReturnDialog()" [disabled]="this.modeService.isWaiting" tabindex="0">
      Cancel
    </button>

  </uitk-dialog-actions>
</uitk-dialog>
<div class="divPopUp" *ngIf="showReturnsPopUp">
  <app-returns-action [fromReturnEntry]="true"> </app-returns-action>
</div>
  </div>
</div>