<div [ngClass]="panelBackground" class="panel">
    <uitk-panel uitkId="orderSearchPanel" [collapsible]="collapsiblePanel" [open]="openPanel"
                (openChange)="setPanelBGHeight()">
      <uitk-panel-header-title>
        <h2>Search Invoice by CC</h2>
      </uitk-panel-header-title>
      <uitk-panel-content>
        <div class="uitk-l-grid__row" (keyup.enter)="OnSearchClick()">
          <div class="uitk-l-grid__col">
            <div class="uitk-l-grid__col--3">
              <label uitkLabel>CC Type</label>
            </div>
            <div class="uitk-l-grid__col--6">
              <uitk-form-field>
                <uitk-select  uitkId="payMethod"  name="payMethodInput" #poNumberInput="ngModel"  [defaultLabel]="defaultLabel" [defaultLabelFlag]="true" [itemList]="payMethodList" [(ngModel)]="payMethod"
                                  ></uitk-select>
              </uitk-form-field>
            </div>
          </div>
          <div class="uitk-l-grid__col uitk-u-flex__align--center">
            <div class="uitk-l-grid__col--5">
                <uitk-form-field>
              <label uitkLabel>CC Last 4 or Token<label style="color: #002677;">*</label></label>
            </uitk-form-field>
            </div>
            <div class="uitk-l-grid__col--4">
              <uitk-form-field>
                <input id="ccLast4"  uitkInput 
                       name="ccLast4Input" [required]="true" #ccLast4Input="ngModel" [(ngModel)]="ccLast4" (change)="OnChangeCCLast4()"/>
                       <uitk-form-field-error *ngIf="(ccLast4Input?.errors?.required && ccLast4Input.touched) || showccLast4Error">
                       CCLast4 field is required
                      </uitk-form-field-error>
              </uitk-form-field>
            </div>
          </div>
          <div class="uitk-l-grid__col uitk-u-flex__align--right">
            <div class="uitk-l-grid__col--3">
              <label uitkLabel>Declined</label>
            </div>
            <div class="uitk-l-grid__col--6">
              <uitk-form-field>
                <label uitkCheckboxLabel for="declined-checkbox" ></label>
                    <input id="declined-checkbox" type="checkbox" 
                           uitkCheckbox [(ngModel)]="isDeclined"
                           />
              </uitk-form-field>
            </div>
          </div>
        </div>
        <div id="searchDiv" class="uitk-l-grid__row ">
            <div class="uitk-u-flex__align--left">
              <button id="searchBtn" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--lg" (click)="OnSearchClick()" [appTabTitle]="'Search Invoice by CC: '+ccLast4">
                Search
              </button>
              <a class="link" id="clearBtn" (click)="clearInput()" appTabTitle="Search Invoice by CC">Clear</a>
            </div>
          </div>
      </uitk-panel-content>
    </uitk-panel>
  </div>
  <div *ngIf="modeService.mode == Mode.InvoiceByCCWait">
    <body>
      <app-loader> </app-loader>
    </body>
  </div>
  <app-searchinvoice-search-results [hidden]="!showResults"></app-searchinvoice-search-results>
  
