import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})

export class GeneratePdfService {
  constructor(private readonly configService: ConfigService) { }

   generateQuoteCFDPdf(): jsPDF {
    let pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: 'a4'
    });

    pdf = new jsPDF({
      format: [pdf.internal.pageSize.getHeight() * 4.339528, pdf.internal.pageSize.getWidth() * 4.339528],
      encryption: { userPassword: '', userPermissions: ['print'], ownerPassword: this.configService.quoteCFDPassword }
    });

    return pdf;
  }

  async previewQuoteCFDPdf(elementId: string, windowZoomLevel: number): Promise<void> {
    document.getElementById(elementId)!.style.display = 'inline-block';
    const quoteCFDPdf = await this.generateQuoteCFDPdf();
    document.getElementById(elementId)!.style.width = this.getPDFDivWidthBasedOnBrowserZoomLevel(windowZoomLevel);
    let srcWidth = document.getElementById('divQuoteCFDPdf')?.scrollWidth;
    if (srcWidth == null) {
      srcWidth = 1;
    }
    let pWidth = quoteCFDPdf.internal.pageSize.width;
    let margin = 30;
    let scale = (pWidth - margin * 2) / srcWidth;
    quoteCFDPdf.html(document.getElementById(elementId)!, {
      html2canvas: {
        scrollX: 0,
        scrollY: 0,
        scale: window.matchMedia('screen and (max-width: 1200px)').matches ? 1 : scale,
      },
      x: 0,
      y: 0,
      margin: [75, 35, 35, 35],
      autoPaging: 'text',
      callback: (pdf) => {
        document.getElementById(elementId)!.style.display = 'none';

        fetch(pdf.output('datauristring')).then(res => res.blob()).then(blob => {
          window.open('/')!.location.href = URL.createObjectURL(blob) + '#view=fitH';
        });
      }
    });
  }

  getPDFDivWidthBasedOnBrowserZoomLevel(windowZoomLevel: number): string {
    let pdfDivPixel = '';

    switch (windowZoomLevel) {
      case 25:
      case 33:
      case 50:
      case 67:
      case 75:
      case 90:
      case 100:
      case 110:
      case 125:
      case 150:
      case 175:
      case 200:
      case 250:
      case 300:
      case 400:
      case 500:
        pdfDivPixel = '1855px';
        break;
      case 80:
        pdfDivPixel = '1875px';
        break;
    }

    return pdfDivPixel;
  }

  async generateProFormaInvoicePdf(): Promise<jsPDF> {
    let pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: 'a4'
    });

    pdf = new jsPDF({
      format: [pdf.internal.pageSize.getHeight() * 4.339528, pdf.internal.pageSize.getWidth() * 4.339528],
      encryption: { userPassword: '', userPermissions: ['print'], ownerPassword:this.configService.profromaInvoicePdfPassword}
    });

    return pdf;
  }
}
