<div style="height:600px;">
  <div class="container-fluid">

    <!-- <div class="row">
      <div class="col-sm-12">
        <h4>{{ orderDetail.productCode }}: {{ orderDetail.productDescription }}</h4>
      </div>
    </div> -->

    <div *ngIf="!orderDetail.cfaWebDtos[0]">
      <div class="row mt-4">
        <div class="col-sm-12">
          <h5>What type of organization will be using this information?</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <uitk-form-field>
            <uitk-dropdown style="width: 450px" uitkId="organizationInfo" [items]="organizationInfoList" [(ngModel)]="selectedOrganization"
              [enableSelectFlag]="true" (onChange)="onChangeOrganizationCode($event)">
            </uitk-dropdown>
          </uitk-form-field>
        </div>
      </div>
      <div class="row mt-1" *ngIf="selectedOrganization.value === 'Reseller'">
        <div class="col-sm-12">
          <uitk-notification style="width: 450px" uitkId="resellerLabel" [variant]="'error'" [show]="true">
            <span>Reselect to whom you are reselling to.</span>
          </uitk-notification>
        </div>
      </div>
      <div class="row mt-1" *ngIf="selectedOrganization.value.startsWith('Payer')">
        <div class="col-sm-12">
          <uitk-notification style="width: 450px" uitkId="payerLabel" [variant]="'error'" [show]="true">
            <span>FeeAnalyzer products cannot be sold to payers.</span>
          </uitk-notification>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" *ngIf="selectedOrganization.value.startsWith('Provider') || orderDetail.cfaWebDtos[0]">
    <div class="row mt-3">
      <div class="col-sm-12">
        <h5>Account name as it appears on products</h5>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-12">
        <uitk-form-field>
          <input style="width: 450px" [disabled]="orderDetail?.subscriptionDto?.subscriptionStatus === 'X' || orderDetail?.subscriptionDto?.subscriptionStatus === 'V' || orderDetail?.subscriptionDto?.subscriptionStatus === 'C'"
            id="txtCompanyName" uitkInput name="myBasicInput"
            [(ngModel)]="companyName" appTextFormat/>
        </uitk-form-field>
      </div>
    </div>

    <hr>

    <div class="row mt-3">
      <div class="col-sm-12">
        <h5>GeoZips</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <strong>Specialty</strong>
          <div class="col-lg-8">
            <uitk-form-field>
              <uitk-dropdown id="specialityInput" [items]="specialityList" [(ngModel)]="selectedSpecialty" uitkId="singleSelect-template"
                [disabled]="true" [enableSelectFlag]="true"></uitk-dropdown>
            </uitk-form-field>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="row">
          <strong>Zip Code</strong>
          <div class="col-lg-6">
            <uitk-form-field>
              <input uitkInput id="zipCodeInput" [disabled]="orderDetail?.subscriptionDto?.subscriptionStatus === 'X' || orderDetail?.subscriptionDto?.subscriptionStatus === 'V' || orderDetail?.subscriptionDto?.subscriptionStatus === 'C'"
              [(ngModel)]="zipCode" appTextFormat #zipCodeInput/>
            </uitk-form-field>
          </div>
          <div class="col-lg-2">
            <div class="float-end">
              <div class="input-group">
                <div class="uitk-l-grid__row">
                  <button id="add" type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
                    [disabled]="orderDetail?.subscriptionDto?.subscriptionStatus === 'X' || orderDetail?.subscriptionDto?.subscriptionStatus === 'V' || orderDetail?.subscriptionDto?.subscriptionStatus === 'C'"
                    (click)="clickadd()">Add</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1" *ngIf="!validZipCode">
          <div class="col-lg-6">
            <uitk-notification uitkId="resellerLabel" [variant]="'error'" [show]="true">
              <span>Requires 3 or 5 digits.</span>
            </uitk-notification>
          </div>
        </div>
      </div>

    </div>

    <div class="row mt-3">
      <table uitkTable class="uitk-c-table" [dataSource]="geoZipGridData" id="geoZip-table">
        <thead class="uitk-c-table__head">
          <tr style="text-align: left">
            <th *ngFor="let headerName of tableHeader" scope="col">
              {{ headerName.name }}
            </th>
          </tr>
        </thead>
        <tbody id="row-data" class="uitk-c-table__body">
          <ng-container *uitkTableRowBody="let row">
            <tr *ngIf="row" class="uitk-c-table__row">
              <td><span class="uitk-icon uitk-icon-trash_delete gridIcons" style="cursor: pointer;"
                *ngIf="orderDetail?.subscriptionDto?.subscriptionStatus !== 'X' && orderDetail?.subscriptionDto?.subscriptionStatus !== 'V' && orderDetail?.subscriptionDto?.subscriptionStatus !== 'C'"
                (click)="onDeleteClick(row)"></span></td>
              <td>{{row.speciality}}</td>
              <td>{{row.zipCode}}</td>
              <td>{{row.geoZip}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

</div>
