import { Component } from '@angular/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent {
  selectedPanel: string = 'panel1';

  selectPanel(panel: string): void {
    this.selectedPanel = panel;
  }

}
