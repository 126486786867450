<div class="css-t7viak" [hidden] = "!displayResults">
  <div class="uitk-l-grid">
    <div class="uitk-l-grid__row">
      <div class="uitk-l-grid__col--12">
        <div class="uitk-c-table__caption">
            <button id="btn" type="button" (click)="openHoldReasonDialog()" *ngIf="onChangeValue =='On Hold'" [disabled]="selectedOnHold.length===0"
              style="float: left;" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--m">
              Remove Hold
            </button>
            <button id="btn" type="button" (click)="retryCC()" *ngIf="onChangeValue =='Declined CC'" [disabled]="selectedOnHold.length===0"
            style="float: left;" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--m">
            Retry Credit Card
           </button>
           <button id="btn" type="button" (click)="SendDeclinedEmail()" *ngIf="onChangeValue =='Declined CC'" [disabled]="selectedOnHold.length===0"
            style="float: left;" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--m">
            Send Email
           </button>
          <div class="rightSection">
              <span class="uitk-icon uitk-icon-refresh sm-icon">&nbsp;
                <a target="_blank" class="link" style="text-decoration: underline;"
                  (click)="refreshData()">Refresh</a>
              </span>
              <br>
              <strong>Total # of Records:</strong> {{ filteredResultDataSource.data.length }}
              <br>
              <strong>Total Open Amt:</strong> {{totalOpenAmount | currency:'USD':'symbol':'1.2-2'}} 
            </div>       
        </div>
        <app-filter-bar [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data"
         [(gridFilters)]="gridFilters"></app-filter-bar>
        <div class="uitk-c-table--responsive" id="worklistDiv" class="tableFontSize" uitkTableContainer [sticky]="true" style="overflow-x: scroll;" [offset]="collapsedHeader ? 335 : 465" appExpand>
          <table uitkTable [dataSource]="filteredResultDataSource" class="uitk-c-table" aria-describedby="worklist-queue-data"
            id="worklist-queue-results-table" aria-labelledby="table-paginate" aria-describedby="row-data"
            #rootTable="uitkTable">
            <thead class="uitk-c-table__head">
              <tr uitkTableSort #sortTable="uitkTableSort">
                <th>
                  <input type="checkbox" [disabled]="filteredResultDataSource.data.length ===0 || onChangeValue =='All' || onChangeValue =='Email Required'
                  || onChangeValue =='Web Order'" [(ngModel)]="selectAllOnHold"
                    (ngModelChange)="selectAll()" class="checkBoxInGrid"
                    style="position: relative; height: 20px; width: 20px;" name="selectallCheckBox" />
                </th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[1].id" (sortChange)="onSortChange($event)">
                  Notes</th>
                <th></th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[3].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'Type'"  [fieldName]="tableHeader[3].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  Type</th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[4].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'Account ID'" [fieldName]="tableHeader[4].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  Account ID</th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[5].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'Order #'" [fieldName]="tableHeader[5].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  Order #</th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[6].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'Order Date'" [fieldName]="tableHeader[6].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  Order Date</th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[8].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'PO Req'" [dataType]="tableHeader[8].dataType" [fieldName]="tableHeader[8].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  PO Req</th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[9].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'PO #'" [fieldName]="tableHeader[9].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  PO #</th> 
                <th uitkTableSortColumn scope="col" [column]="tableHeader[14].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'Hold Reason'" [fieldName]="tableHeader[14].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  Hold Reason</th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[18].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'MY'" [fieldName]="tableHeader[18].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  MY</th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[7].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'Web Ref'" [fieldName]="tableHeader[7].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  Web Ref</th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[10].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'Open Amt'"[fieldName]="tableHeader[10].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  Open Amt</th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[12].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'Entered By User'" [fieldName]="tableHeader[12].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  Entered By User</th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[13].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'Account Name'" [fieldName]="tableHeader[13].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  Account Name</th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[11].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'Account Owner'" [fieldName]="tableHeader[11].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  Account Owner</th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[15].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'Credit Limit'" [fieldName]="tableHeader[15].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  Credit Limit</th>
                <th uitkTableSortColumn scope="col" [column]="tableHeader[16].id" (sortChange)="onSortChange($event)">
                  <app-grid-filter  [columnName]="'Payment Plan'" [fieldName]="tableHeader[16].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  Payment Plan</th>
                  <th uitkTableSortColumn scope="col" [column]="tableHeader[17].id" (sortChange)="onSortChange($event)">
                    <app-grid-filter  [columnName]="'Last Process Date'" [fieldName]="tableHeader[17].id" [allData]="resultDataSource.data" [(filteredData)]="filteredResultDataSource.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                    Last Process Date</th>
              </tr>
            </thead>
            <tbody id="row-data" class="uitk-c-table__body">
              <ng-container *uitkTableRowBody="let filteredResultDataSource">
                <tr class="uitk-c-table__row" [ngClass]="{ 'background-light-gray': filteredResultDataSource.orderStatus === 'V', 'text-light-red': filteredResultDataSource.orderType === 'Q' }">
                  <td>
                    <input type="checkbox" [(ngModel)]=filteredResultDataSource.isSelected
                      [disabled]="onChangeValue =='All' || filteredResultDataSource.orderType =='Email Required'
                      || filteredResultDataSource.orderType =='Web Order'"
                      (ngModelChange)="selectOnHoldRecord(filteredResultDataSource)" class="checkBoxInGrid"
                      style="position: relative; height: 20px; width: 20px;" name="selectCheckBox" />

                  </td>
                  <td id="notes">
                    <div [ngbTooltip]="filteredResultDataSource.hasWorkListNotes === true && filteredResultDataSource.filterType === 'Declined CC'? noteContent :null" container="body" placement="bottom-left">
                      <span id="noteIcon" 
                            (click)="navigateAddNote(filteredResultDataSource)"
                            [ngClass]="filteredResultDataSource.hasWorkListNotes === true ? 'uitk-icon uitk-icon-notes sm-icon' : 'uitk-icon uitk-icon-add sm-icon'"></span>
                      <ng-template #noteContent style="width: 300px !important; left: 2500px !important">
                        <table id="extraclmsTable" style="color: white !important; width: 280px !important">
                          <tr *ngFor="let notes of filteredResultDataSource.workListNotes">
                            <td> {{ notes }} </td>
                          </tr>
                        </table>
                      </ng-template>
                      </div>
                  </td>
                  <td id="extraClmsIcon">
                    <div [ngbTooltip]="htmlContent" container="body" placement="bottom"><span class="uitk-icon uitk-icon-info_hollow sm-icon"></span>
                      <ng-template #htmlContent style="width: 300px !important">
                        <table id="extraclmsTable" style="color: white !important; width: 280px !important;">
                          <tr>
                            <th>
                              <p>Payment Terms:</p>
                            </th>
                            {{ filteredResultDataSource.paymentTerms }}
                          </tr>

                          <tr>
                            <th>
                              <p>Ship Via :</p>
                            </th>
                            {{ filteredResultDataSource.shipVia }}
                          </tr>
                          <tr>
                            <th>
                              <p>CC Last 4:</p>
                            </th>
                            {{ filteredResultDataSource.ccLastFour }}
                          </tr>

                          <tr>
                            <th>
                              <p>Expiration Date:</p>
                            </th>
                            {{ filteredResultDataSource.expirationDate }}
                          </tr>
                          <tr>
                            <th>
                              <p>Token:</p>
                            </th>
                            {{ filteredResultDataSource.token }}
                          </tr>

                          <tr>
                            <th>
                              <p>Last Process Date:</p>
                            </th>
                            {{ filteredResultDataSource.lastProcessDate | date: 'MM/dd/yyyy hh:mm a'}}
                          </tr>
                          <tr>
                            <th>
                              <p>Web Reference #:</p>
                            </th>
                            {{ filteredResultDataSource.webReferenceNumber }}
                          </tr>
                          <tr>
                            <th>
                              <p>Declined Reason:</p>
                            </th>
                            {{ filteredResultDataSource.ccApprovalCode }}
                          </tr>
                        </table>
                      </ng-template>

                    </div>
                  </td>
                  <td id="filterType">
                    {{ filteredResultDataSource.filterType }}
                  </td>
                  <td id="accountId">
                    <a target="_blank" (click)="navigateToARInquiry(filteredResultDataSource.accountId)"
                      class="link" style="text-decoration: underline;">{{ filteredResultDataSource.accountId }}</a>
                  </td>
                  <td id="orderNumber">
                    <a style="text-decoration: underline;"
                        class="link" (click)="navigateToOrder(filteredResultDataSource.orderNumber)">{{ filteredResultDataSource.orderNumber== 0 ||
                        filteredResultDataSource.orderNumber== null? '' : filteredResultDataSource.orderNumber }}</a>
                  </td>
                  <td id="orderDate">
                    {{ filteredResultDataSource.orderDate | date: 'MM/dd/yyyy' }}
                  </td>
                  <td id="poRequired">
                    <input uitkCheckbox type="checkbox" [checked]="filteredResultDataSource.isPORequired ? true : false" />
                    <label uitkCheckboxLabel id="chk" style="margin-top: auto; width: 0px;"></label>
                  </td>
                  <td id="poNumber">
                    {{ filteredResultDataSource.poNumber }}
                  </td>
                  <td id="holdReason" style="word-break: break-all;overflow-wrap: break-word;">
                    {{ filteredResultDataSource.holdReason }}
                  </td>
                  <td id="multiYear">
                    <input uitkCheckbox type="checkbox" [checked]="filteredResultDataSource.isMultiYear" />
                    <label uitkCheckboxLabel id="chk" style="margin-top: auto; width: 0px;"></label>
                  </td>
                  <td id="webReferenceNumber">
                    {{ filteredResultDataSource.webReferenceNumber }}
                  </td>
                  <td id="openAmount">
                    {{ filteredResultDataSource.openAmount | currency }}
                  </td>
                  <td id="enteredByUser">
                  
                    <a target='' [href]="'mailto:'+ filteredResultDataSource.email">
                    {{ filteredResultDataSource.enteredBy}}
                    </a>
                  </td>
                  <td id="accountName">
                    <a target="_blank" (click)="navigateToSFViewAccount(filteredResultDataSource.accountSalesforceId)"
                      class="link" style="text-decoration: underline;">{{ filteredResultDataSource.accountName }}</a>
                  </td>
                  <td id="accountOwner">
                    <a [href]="'mailto:'+ filteredResultDataSource.accountOwnerEmail">
                      {{ filteredResultDataSource.accountOwner }}

                    </a>
                   
                  </td>
                  <td id="creditLimit">
                    {{ filteredResultDataSource.creditLimitDescription }}
                  </td>
                  <td id="paymentPlan">
                    {{ filteredResultDataSource.paymentPlan }}
                  </td>
                  <td id="lastProcessDate">
                    {{ filteredResultDataSource.lastProcessDate | date: 'MM/dd/yyyy' }}
                  </td>
                </tr>
              </ng-container>
              <div *ngIf="filteredResultDataSource.data.length === 0"><h4>No records found</h4></div>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="openViewNotePopup">
  <app-view-note (closeEvent)="closeEvent()" [hasNotes]="hasNotes" [orderNumber]="orderNumber"></app-view-note>
</div>

<div *ngIf="holdReasonDialogModal.show" class="tttrr">
  <div *ngIf="holdReasonDialogModal.show" class = "overlayForPopups">
    <div cdkDrag>
  <uitk-dialog id="holdReasonDialog" [(showModal)]="holdReasonDialogModal.show" [closeBtn]=false>
    <uitk-dialog-header>
      <div cdkDragHandle>
        <h4 class="modal-title pull-left">
          Remove Hold
        </h4>
      </div>
    </uitk-dialog-header>
    <uitk-dialog-content id="reasonContent">
      <div class="uitk-l-grid">


          <div class="row">
            <label class="col-lg-2" uitkLabel>Reason</label>
            <div class="col-lg-2">
              <uitk-dropdown id="holdReasonDD" [items]="holdReasonPickList" name="select" [(ngModel)]="holdReason">
              </uitk-dropdown>
            </div>
          </div>
          <div class="row"></div><br><div class="row"></div>
          <div class="row">
            <label class="col-lg-2" uitkLabel>Comment</label>
            <div class="col-lg-2">
              <uitk-form-field>
              <textarea uitkTextarea name="notesTextArea" Id="NotesArea" [(ngModel)]="noteText" uitkInput> </textarea>
            </uitk-form-field>
            </div>
          </div><div class="row"></div>

      </div>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" [disabled]="!holdReason?.value || (holdReason?.value === 'EXR' && !noteText)"
        (click)="saveReason()">
        Ok
      </button>
      <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="cancelDialog()">
        Cancel
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
    </div>
  </div>
</div>

<uitk-dialog [(showModal)]="emailDeclinedDialog.show" uitkId="emailConfirmationModal-dialog" [closeBtn]=false
  style="width:fit-content;">
  <uitk-dialog-header>
    {{declinedDialog.header}}
  </uitk-dialog-header>
  <uitk-dialog-content>
    <p style="padding: 10px;">{{declinedDialog.content}}</p>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button id="okButton" class="uitk-c-button uitk-c-button--default uitk-u-margin--xs" (click)="okEmailDeclinedDialog()" appAutoFocus>
      Ok
    </button>
  </uitk-dialog-actions>
</uitk-dialog>

<body>
  <app-loader [hidden]="displayResults"></app-loader>
</body>
