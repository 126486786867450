export class PremiumDto {
  productId = 0;
  productCode = '';
  description = '';
  quantity = 1;
  listPrice: number = 0;
  discount: number = 0;
  statusCode = '';
  // Used for binding to the UI.
  isSelected :boolean= false;
}
