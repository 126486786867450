import { Component, Input, OnInit } from '@angular/core';
import { Dialog } from 'src/app/helpers/dialog';
import { OrderService } from '../../../../services/order.service';
import { ModeService } from 'src/app/services/mode.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-add-return-authorization',
  templateUrl: './add-return-authorization.component.html',
  styleUrls: ['./add-return-authorization.component.css']
})
export class AddReturnAuthorizationComponent implements OnInit {
  @Input('dialog') dialog!: Dialog<string>;

  constructor(public readonly orderService: OrderService,
    public readonly modeService: ModeService,
    public configService: ConfigService,) { }

  showReturnsAuthPopUp = false;
  formDate = Date.now();
  salesRepName = "";

  ngOnInit(): void {
    this.showReturnsAuthPopUp = false;   
    this.salesRepName = this.orderService.orderDto.billToDto.accountOwnerName;
    this.orderService.returnDetails = this.orderService.orderDto.orderDetailDtos.filter(x => x.disableQuantity == false);

    if (this.orderService.returnDetails[0].productDto.salesOrganization != "Optum")
      this.salesRepName = this.configService.getFullNameFromUserCode(this.orderService.orderDto.billToDto.printRepCode);
  }

  closeAddReturnAuthorizationDialog() {
    this.dialog.close('Cancel');
  }

  async save(): Promise<void> {
    let businessJustification: string = (<HTMLInputElement>document.getElementById("businessJustification")).value;
    this.orderService.returnAuthReason = businessJustification;
    this.dialog.close('Save');
  }
}
