import { Component, Input, OnInit } from '@angular/core';
import { OrderDetailDto } from '../../../../dtos/order-detail-dto.model';
import { ShipAndProcessDto } from '../../../../dtos/ship-and-process-dto.model';
import { ShipAndProcessLineDto } from '../../../../dtos/ship-and-process-line-dto.model';
import { ConfigService } from '../../../../services/config.service';
import { OrderService } from '../../../../services/order.service';

@Component({
  selector: 'app-ship-and-process',
  templateUrl: './ship-and-process.component.html',
  styleUrls: ['./ship-and-process.component.css']
})

export class ShipAndProcessComponent implements OnInit {
  private readonly _orderService: OrderService;
  private readonly _configService: ConfigService;
  @Input() dialog = { show: false };
  orderDetailDtos: OrderDetailDto[] = [];
  allRowsSelected = false;
  trackingNumber = '';
  
  constructor(
    orderService: OrderService,
    configService: ConfigService
  ) {
    this._orderService = orderService;
    this._configService = configService;
  }

  ngOnInit(): void {    
    this.orderDetailDtos = this._orderService.orderDto.orderDetailDtos
      .filter(item => item.orderDetailStatus === ' ' && item.productDto.isShipAndProcess && item.subscriptionDto?.subscriptionType !== 'R');

    for (const orderDetailDto of this.orderDetailDtos) {
      orderDetailDto.isSelected = false;
      orderDetailDto.shipAndProcessQuantity = orderDetailDto.openQuantity;
    }
  }

  selectAllRows(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.allRowsSelected = inputElement.checked;
    
    if (this.allRowsSelected) {
      for (const orderDetailDto of this.orderDetailDtos) {
        orderDetailDto.isSelected = true;
      }
    } else {
      for (const orderDetailDto of this.orderDetailDtos) {
        orderDetailDto.isSelected = false;
      }      
    }
  }

  onRowChecked(orderDetailDto: OrderDetailDto): void {
    this.allRowsSelected = !this.orderDetailDtos.filter(item => !item.isSelected);
  }

  onQuantityChange(orderDetailDto: OrderDetailDto): void {
    if (orderDetailDto.openQuantity < 0) {
      orderDetailDto.shipAndProcessQuantity *= -1;
    }

    if (orderDetailDto.openQuantity < 0 && (orderDetailDto.shipAndProcessQuantity < orderDetailDto.openQuantity || orderDetailDto.shipAndProcessQuantity > 0)) {
      orderDetailDto.shipAndProcessQuantity = orderDetailDto.openQuantity;
    }

    if (orderDetailDto.openQuantity >= 0 && (orderDetailDto.shipAndProcessQuantity > orderDetailDto.openQuantity || orderDetailDto.shipAndProcessQuantity < 0)) {
      orderDetailDto.shipAndProcessQuantity = orderDetailDto.openQuantity;
    }
  }

  shipAndProcess(): void {
    const shipAndProcessDto = new ShipAndProcessDto();
    shipAndProcessDto.orderId = this._orderService.orderDto.id;
    shipAndProcessDto.userId = this._configService.getMyMSId();
    shipAndProcessDto.trackingNumber = this.trackingNumber;
    shipAndProcessDto.shipAndProcessLineDtos = 
      this.orderDetailDtos.filter(item => item.isSelected)
      .map(item => ({ orderDetailId: item.id, quantity: item.shipAndProcessQuantity }) as ShipAndProcessLineDto);
    this._orderService.shipAndProcess(shipAndProcessDto);
    this.dialog.show = false;
  }
}
