<div>
  <div class="row mt-3">
    <span class="quoteCFDHeader">Quote CFD</span>
  </div>
  <div class="row">
    <uitk-panel-header-title>
      <h3>Account Information</h3>
    </uitk-panel-header-title>
  </div>
  <div class="row mt-4">
    <div class="col-1">
      <strong>Account#:</strong>
    </div>
    <div class="col-3">
      <p id="accountId" class="clearMarginBottom" [innerHtml]="orderService.orderDto.billToDto.id"></p>
    </div>
    <div class="col-1">
      <strong>Quote #:</strong>
    </div>
    <div class="col-3">
      <p id="quoteNumber" class="clearMarginBottom" [innerHtml]="orderService.orderDto.id"></p>
    </div>
    <div class="col-1">
      <strong>Quote Date:</strong>
    </div>
    <div class="col-3">
      <p id="quoteDate" class="clearMarginBottom" [innerHtml]="orderService.orderDto.enteredDate | date: 'MM/dd/yyyy'">
      </p>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-1">
      <strong>Bill to:</strong>
    </div>
    <div class="col-3">
      <p id="accountName-billTo-Section" class="clearMarginBottom"
        [innerHtml]="orderService.orderDto?.paymentMethod === 'S' ?
        (orderService?.selectedShipToContact?.mailingStreet ? orderService.selectedShipToContact?.mailingCompany
        : orderService?.selectedShipToContact?.street ? orderService.selectedShipToContact?.company
        : orderService.orderDto?.shipToDto?.company)
      : orderService.orderDto?.billToDto?.company">
      </p>
      <p id="department-billTo-Section" class="clearMarginBottom"
        [innerHtml]="orderService.orderDto?.paymentMethod === 'S' ?
        (orderService?.selectedShipToContact?.mailingStreet ? orderService.selectedShipToContact?.mailingDepartment
        : orderService?.selectedShipToContact?.street ? orderService.selectedShipToContact?.department
        : orderService.orderDto?.shipToDto?.department)
      : orderService.orderDto?.billToDto?.department">
      </p>
      <p id="street-billTo-Section" class="clearMarginBottom" [innerHTML]="orderService.orderDto?.paymentMethod === 'S' ?
      (orderService?.selectedShipToContact?.mailingStreet ? orderService.selectedShipToContact?.mailingStreet
      : orderService?.selectedShipToContact?.street ? orderService.selectedShipToContact?.street
      : orderService.orderDto?.shipToDto?.street)
    : orderService.orderDto?.billToDto?.street">
      </p>
      <p id="cityStateZip-billTo-Section" class="clearMarginBottom" [innerHTML]="generateCityStateZip(orderService.orderDto.billToDto.city, orderService.orderDto.billToDto?.state,
        orderService.orderDto.billToDto.zip)">
      </p>
    </div>
    <div class="col-1">
      <strong>Ship to:</strong>
    </div>
    <div class="col-3">
      <p id="contactName-shipTo-Section" class="clearMarginBottom" [innerHTML]="generateFullName(orderService.orderDto.shipToDto.firstName,
        orderService.orderDto.shipToDto.lastName)">
      </p>
      <p id="company-shipTo-Section" class="clearMarginBottom" [innerHTML]="orderService.orderDto.shipToDto.company">
      </p>
      <p id="department-shipTo-Section" class="clearMarginBottom"
        [innerHTML]="orderService.orderDto.shipToDto.department">
      </p>
      <p id="street-shipTo-Section" class="clearMarginBottom" [innerHTML]="orderService.orderDto.shipToDto.street">
      </p>
      <p id="cityStateZip-shipTo-Section" class="clearMarginBottom" [innerHTML]="generateCityStateZip(orderService.orderDto.shipToDto.city, orderService.orderDto.shipToDto.state,
        orderService.orderDto.shipToDto.zip)"></p>
    </div>
    <div class="col-1">
      <strong>Remit to:</strong>
    </div>
    <div class="col-3">
      <p class="clearMarginBottom">Optum</p>
      <p class="clearMarginBottom">PO Box 88050</p>
      <p class="clearMarginBottom">Chicago, IL 60680-1050</p>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-1">
      <strong>Requested By:</strong>
    </div>
    <div class="col-3">
      <p id="requestedBy" class="clearMarginBottom" [innerHTML]="generateFullName(orderService.orderDto.orderByDto.firstName,
        orderService.orderDto.orderByDto.lastName)"></p>
    </div>
    <div class="col-2">
      <strong>Content Solutions</strong>
    </div>
  </div>
  <div class="row">
    <div class="col-1">
      <strong>Phone:</strong>
    </div>
    <div class="col-3">
      <p id="phone" class="clearMarginBottom" [innerHTML]="orderService.orderDto.orderByDto.phone | phoneFormat"></p>
    </div>
    <div class="col-1">
      <strong>Client Manager:</strong>
    </div>
    <div class="col-3">
      <p id="contentSolutionsClientManager" class="clearMarginBottom"
        [innerHTML]="getClientManagerName()"></p>
    </div>
  </div>
  <div class="row">
    <div class="col-1">
      <strong>Fax:</strong>
    </div>
    <div class="col-3">
      <p id="fax" class="clearMarginBottom" [innerHTML]="orderService.orderDto.orderByDto.fax | phoneFormat"></p>
    </div>
  </div>
</div>
