<div *ngIf="logDialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkId="logDialog" id = "logDialog" [(showModal)]="logDialog.show" [closeBtn] = "true">
    <uitk-dialog-header>
      <div cdkDragHandle>
        History of Changes for {{bbLogService.bbLogData.columnName}} of {{bbLogService.bbLogData.tableName}}
      </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
      <div class="uitk-c-table--responsive">
        <table uitkTable [dataSource]="bbLogService.bbLogData.logData" class="uitk-c-table" id="log-table">
          <thead class="uitk-c-table__head">
            <tr>
              <th *ngFor="let headerName of tableHeader" scope="col" id={{headerName.id}}>
                {{ headerName.name }}
              </th>
            </tr>
          </thead>
          <tbody id="row-data" class="uitk-c-table__body" [hidden]="modeService.mode === Mode.Wait">
            <ng-container *uitkTableRowBody="let data">
              <tr class="uitk-c-table__row">
                <td>{{ data.userName }}</td>
                <td>{{ data.changeDate | date:'MM/dd/yyyy hh:mm:ss a' }}</td>
                <td>{{ data.oldValue }}</td>
                <td>{{ data.newValue }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div class="uitk-l-grid--max-width">
          <div id="loadingIndicator" [hidden]="modeService.mode !== Mode.Wait">
            <uitk-loading-indicator uitkId="spinner" [visibleText]="spinnerText" spinnerSize="large">
            </uitk-loading-indicator>
          </div>
        </div>
      </div>
    </uitk-dialog-content>
</uitk-dialog>
  </div>
</div>