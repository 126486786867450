import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ScrollToDirective } from 'src/app/directives/scrollTo.directive';
import { SourceType } from 'src/app/enums/source-type.enum';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';
import { ShippinghandlingService } from '../../../services/shippinghandling.service';

@Component({
  selector: 'app-shipping-popup-order-entry',
  templateUrl: './shipping-popup-order-entry.component.html',
  styleUrls: ['./shipping-popup-order-entry.component.css']
})
export class ShippingPopupOrderEntryComponent implements OnInit {

  private readonly _pricingService: PricingService;
  public readonly _orderService: OrderService;
  private readonly _shippinghandlingService: ShippinghandlingService;
  @Input() orderDetail: OrderDetailDto = new OrderDetailDto();
  @ViewChild(ScrollToDirective) dir:any;
  //@Output() enableSave = new EventEmitter<boolean>();
  showErrorMessage = false;
  errorVariant: any = '';
  errorMessage = '';
  showShippingChargeMessage = false;
  licensePricing:
    { productCode: string, listPrice: number, orderQuantity: number, openQuantity: number, discount: number, unitPrice: number, extendedPrice: number, id: number } =
    { productCode: '', listPrice: 0, orderQuantity: 1, openQuantity: 1, discount: 0, unitPrice: 0, extendedPrice: 0, id: 0 }

  constructor(
    pricingService: PricingService,
    orderService: OrderService,
    shippinghandlingService: ShippinghandlingService
  ) {
    this._pricingService = pricingService;
    this._orderService = orderService;
    this._shippinghandlingService = shippinghandlingService;
  }

  async ngOnInit(): Promise<void> {
    this.showShippingChargeMessage = this._orderService.sourceType === SourceType.OrderInquirySave && this._orderService.previousShippingCharge != this._orderService.currentShippingCharge;
    await this.updateShippingCharge();
    this.updateLicensePricing(this.orderDetail);  
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this._orderService.orderDto.shipmentMethod === 'UPS-INT') {
        if (document.getElementById(`upsShippingCharges-${this.orderDetail.id}`)) {
          document.getElementById(`upsShippingCharges-${this.orderDetail.id}`)?.focus();
        }
      }
      else {
        if (document.getElementById(`upsShippingChargesOkButton`)) {
          document.getElementById(`upsShippingChargesOkButton`)?.focus();
        }
      }
    }, 0);
  }
  updateLicensePricing(orderDetail: OrderDetailDto): void {
    this.licensePricing.productCode = orderDetail.productCode;
    this.licensePricing.listPrice = orderDetail.listPrice;
    this.licensePricing.orderQuantity = orderDetail.orderQuantity === 0 ? 1 : orderDetail.orderQuantity;
    this.licensePricing.openQuantity = orderDetail.openQuantity === 0 ? 1 : orderDetail.openQuantity;
    this.licensePricing.discount = orderDetail.discount;
    this.licensePricing.unitPrice = orderDetail.unitPrice;
    this.licensePricing.extendedPrice = orderDetail.extendedPrice;
    this.licensePricing.id = orderDetail.id;
  }
  async updateShippingCharge(): Promise<void> {
    await this._shippinghandlingService.recalculateShippingAndHandlingAsync(this._orderService.orderDto);
    this._orderService.recalculateFields(this._orderService.orderDto);
  }
  onChangeShippingCharges(): void {
    this._pricingService.calculateUnitPrice(this.licensePricing);
    this._pricingService.calculateExtendedPrice(this.licensePricing);
  }

  onChangeShippingChargesDiscount(): void {
    this._pricingService.calculateUnitPrice(this.licensePricing);
    this._pricingService.calculateExtendedPrice(this.licensePricing);
  }

  onChangeTotalShippingCharges(): void {
    if(this.licensePricing.listPrice === 0){
      this.licensePricing.listPrice = this.licensePricing.unitPrice;
    }
    this._pricingService.calculateDiscount(this.licensePricing);
    this._pricingService.calculateUnitPrice(this.licensePricing);
    this._pricingService.calculateExtendedPrice(this.licensePricing);
  }

  validateUPSShippingCharges(): boolean {
    if (this.licensePricing.listPrice === 0) {
      this.showErrorMessage = true;
      this.errorVariant = 'error';
      this.errorMessage = 'You must enter Calculated UPS International Shipping Charges to Proceed';
      return false;
    }

    return true;
  }
  closeModal(){
    this.dir!.target = "#divLeftOrderDetailsGrid";
    this.dir?.scrollToPosition();
  }
  ok(){
    if (this.validateUPSShippingCharges()) {
      this.orderDetail.productCode = this.licensePricing.productCode;
      this.orderDetail.listPrice = this.licensePricing.listPrice;
      this.orderDetail.orderQuantity = this.licensePricing.orderQuantity;
      this.orderDetail.openQuantity = this.licensePricing.openQuantity;
      this.orderDetail.discount = this.licensePricing.discount;
      this.orderDetail.unitPrice = this.licensePricing.unitPrice;
      this.orderDetail.extendedPrice = this.licensePricing.extendedPrice;
      this.orderDetail.id = this.licensePricing.id;
      this._orderService.shippingDialogModal.close('ok');
    }
  }
}
