<div class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog uitkId="ItemSearchDialog" id="logDialog" [(showModal)]="dialog.show" [closeBtn]="true" triggerElementId="itemSearchLink">
    <uitk-dialog-header>
        <div cdkDragHandle>
            Item Search
        </div>
    </uitk-dialog-header>
    <uitk-dialog-content style="width: 1200px;overflow: auto;">
        <div>
            <uitk-card>
                <div class="row">
                    <div class="col-3">
                        <div class="col">
                            <label uitkLabel>Item #</label>
                        </div>
                        <div class="col">
                            <uitk-form-field>
                                <input appAutoFocus uitkInput id="itemNumber" class="textbox" type="text" value=""
                                    [(ngModel)]="itemNumber" />
                            </uitk-form-field>
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="col">
                            <label uitkLabel>ISBN</label>
                        </div>
                        <div class="col">
                            <uitk-form-field>
                                <input uitkInput id="isbnNumber" class="textbox" type="text" value="" [(ngModel)]="isbn" />
                            </uitk-form-field>
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="col">
                            <label uitkLabel>Edition Yr</label>
                        </div>
                        <div class="col">
                            <uitk-form-field>
                                <uitk-select uitkId="editionYr" name="editionYrInput" [itemList]="editionYrList"
                                    [defaultLabel]="defaultLabel" [(ngModel)]="editionYr" [defaultLabelFlag]="true">
                                </uitk-select>
                            </uitk-form-field>
                        </div>
                    </div>
                    
                    <div class="col-3">
                        <div class="col">
                            <label uitkLabel>Status</label>
                        </div>
                        <div class="col">
                            <uitk-form-field>
                                <uitk-select uitkId="status" name="statusInput" [itemList]="productStatusList"
                                    [defaultLabel]="defaultLabel" [(ngModel)]="productStatus" [defaultLabelFlag]="true">
                                </uitk-select>
                            </uitk-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="col">
                            <label uitkLabel>Item Description</label>
                        </div>
                        <div class="col">
                            <uitk-form-field>
                                <input uitkInput id="itemDescription" class="textbox" type="text" value=""
                                    [(ngModel)]="itemDescription" (keydown.Tab)="itemDescriptionBlur(itemDescription)" />
                            </uitk-form-field>
                        </div>
                    </div>

                    <div class="col">
                        <div class="col">
                            <label uitkLabel>Acronym</label>
                        </div>
                        <div class="col">
                            <uitk-form-field>
                                <uitk-select *ngIf="itemDescription===''" uitkId="acronymYr" name="acronymInput"
                                    [itemList]="acronymList" [defaultLabel]="defaultLabel" [(ngModel)]="acronym"
                                    [defaultLabelFlag]="true"></uitk-select>
                                <uitk-select *ngIf="itemDescription!==''" uitkId="filteredAcronymYr"
                                    name="filteredAcronymInput" [itemList]="filteredAcronymList"
                                    [defaultLabel]="defaultLabel" [(ngModel)]="acronym" [defaultLabelFlag]="true">
                                </uitk-select>
                            </uitk-form-field>
                        </div>
                    </div>
                </div>
                <div id="searchDiv" class="uitk-l-grid__row">
                    <button id="itemSearchBtn" type="button"
                        class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xs" (click)="search()"
                        (callSearchMethod)="search()" hotkeyCommand="ctrl+f" [disabled]="modeService.isWaiting">
                        Search
                    </button>
                    <button id="clearBtn" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xs"
                        (click)="clear()">
                        Clear
                    </button>
                </div>

            </uitk-card>
        </div>

        <div [hidden]="!modeService.isWaiting">
            <app-loader> </app-loader>
        </div>
        <div [hidden]="modeService.isWaiting" uitkTableContainer style="max-height: 62%;" *ngIf="showResultsGrid.show"
            class="uitk-c-table--responsive">
            <table uitkTable [dataSource]="ProductsList" class="uitk-c-table" id="log-table"
            #rootTable="uitkTable">
               
                <thead class="uitk-c-table__head">
                    <tr uitkTableSort #sortTable="uitkTableSort">
                        <th scope="col">
                            Add Item
                        </th>
                        <th uitkTableSortColumn 
                        [column]="headerName.id" 
                        (sortChange)="onSortChange($event)"
                        *ngFor="let headerName of tableHeader" scope="col" [id]="headerName.id"  
                         >
                            {{ headerName.name }}
                        </th>
                    </tr>
                    
                </thead>
                <tbody id="row-data" class="uitk-c-table__body">
                    <ng-container *uitkTableRowBody="let product">
                        <tr class="uitk-c-table__row">
                            <td> <span id="addIcon" (click)="addItem(product.productCode)"
                                    class="uitk-icon uitk-icon-add sm-icon"
                                    [class.disabled]="modeService.mode !== Mode.Edit || product.statusCode === 'I'"></span></td>
                            <td>{{product.productCode}}</td>
                            <td>{{product.description}}</td>
                            <td>{{product.listPrice |currency:'USD'}}</td>
                            <td>{{product.floorPrice |currency:'USD'}}</td>
                            <td>{{product.salesCost |currency:'USD'}}</td>
                            <td>{{product.onHand}}</td>
                            <td>{{product.availableDate | date:'MM/dd/yyyy'}}</td>
                            <td>{{product.isbn}}</td>
                            <td>{{product.editionYear}}</td>
                            <td>{{product.acronym}}</td>
                        </tr>
                    </ng-container>
                    <div *ngIf="ProductsList.length === 0">No records found</div>
                </tbody>
            </table>
        </div>
    </uitk-dialog-content>
</uitk-dialog>
    </div>
</div>