<div *ngIf="dialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" [closeBtn]="false" width="800px" uitkId="quoteReasonDialog">
    <uitk-dialog-content>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <label uitkLabel style="width: 140px">Quote Reason</label>
          </div>
        </div>  
        <div class="row">
          <div class="col">
            <uitk-form-field>
              <uitk-dropdown style="width: 600px; height: 450px" uitkId="singleSelect-template" [enableSelectFlag]="false" [items]="quoteReasons"
                [(ngModel)]="quoteReason" [required]="true" name="requiredSelect">
              </uitk-dropdown>
            </uitk-form-field>
          </div>
        </div>
      </div>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" [disabled]="!quoteReason?.value" (click)="ok()" appAutoFocus>
        Ok
      </button>
      <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="cancel()">
        Cancel
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
  </div>
</div>