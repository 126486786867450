import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Observable, of, Subject } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { Mode } from '../enums/mode.enum';
import { BillToDto } from '../dtos/bill-to-dto.model';
import { ConfigService } from './config.service';
import { ModeService } from './mode.service';
import { OrderService } from './order.service';
import { ShipToDto } from '../dtos/ship-to-dto.model';
import { OrderByDto } from '../dtos/order-by-dto.model';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root'
})

export class BillToService {
  specialties: any = [];
  specialtiesList : IUITKSelectItemProps[] = [];
  tooltipContent: any = [];
  billToIsReady = new Subject();
  billToDto: BillToDto = new BillToDto();
  private readonly _httpClient: HttpClient;
  private readonly _modeService: ModeService;
  private readonly _configService: ConfigService;
  private readonly _orderService: OrderService;
  swalAlert = new SwalAlert();

  constructor(httpClient: HttpClient, modeService: ModeService, configService: ConfigService,orderService:OrderService) {
    this._httpClient = httpClient;
    this._modeService = modeService;
    this._configService = configService;
    this._orderService = orderService;
  }

  async getBillToInformationAsync(accountId: number,editionYear: number): Promise<void> {
    const editText = 'Getting bill to information';
    this._modeService.addToWaitList(editText);
    this.billToDto = new BillToDto();
    await this._httpClient.get<BillToDto>(`${this._configService.apiUrl}/Order/GetBillToDetails?accountId=${accountId}&editionYear=${editionYear}`)
      .toPromise().then((data: BillToDto) => {
      this._modeService.removeFromWaitList(editText);
      this.billToDto = data;
        this._orderService.orderDto.billToDto = this.billToDto;
        this.billToIsReady.next();
        this._orderService.orderDto.accountOwnerCode = this.billToDto.accountOwnerCode ?? this._configService.defaultAccountOwnerCode;
    },
      (error: any) => {
        this._modeService.removeFromWaitList(editText);
        this.swalAlert.alert(`An error occurred while getting BillTo service details. Please try again.`);
        this.clearExistingFields();
      });
  }
  clearExistingFields():void {
    this._orderService.orderDto.billToDto = new BillToDto();
    this._orderService.orderDto.shipToDto = new ShipToDto();
    this._orderService.orderDto.orderByDto = new OrderByDto();
    this._orderService.orderDto.selectedsalesPersonName = '';
    this._orderService.orderDto.orderByContactBinding = { id: '', label: '', value: '' };
    this._orderService.orderDto.shipToContactBinding = { id: '', label: '', value: '' };
    this._orderService.orderDto.orderDetailDtos = this._orderService.orderDto.orderDetailDtos.filter(x => x.orderQuantity == 0);
  }
  reloadBillToDto() {
    const specialtiesLabel: string[] = [];
    this._orderService.orderDto.billToDto = this.billToDto;

    if (this.specialtiesList.filter(x => x.label === this._orderService.orderDto.billToDto.specialties[0]).length > 0) {
      this.specialties = this.specialtiesList.filter(x => x.label === this._orderService.orderDto.billToDto.specialties[0])[0].label;
    }

    this._orderService.orderDto.billToDto.specialties.forEach(element => {
      if (this.specialtiesList.some(a => a.label === element)) {
        specialtiesLabel.push(this.specialtiesList.filter(a => a.label === element)[0].label);
      }
    });

    this.tooltipContent = specialtiesLabel;
  }
}
