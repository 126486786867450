import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SourceCodeDto } from '../dtos/source-code-dto.model';
import { ConfigService } from './config.service';
import { ErrorHandlingService } from './error-handling.service';
import { ModeService } from './mode.service';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  readonly _httpClient: HttpClient;
  readonly _modeService: ModeService;
  readonly _configService: ConfigService;
  readonly _errorHandlingService: ErrorHandlingService;
  swalAlert = new SwalAlert();

  constructor(httpClient: HttpClient, modeService: ModeService, 
   configService: ConfigService, errorHandlingService: ErrorHandlingService) {
    this._httpClient = httpClient;
    this._modeService = modeService;
    this._configService = configService;
    this._errorHandlingService = errorHandlingService;
   }

   async getSourceCodes(filterValue: string): Promise<SourceCodeDto[]> {
    return new Promise(resolve => {
      const waitText = 'Loading source code';
      this._modeService.addToWaitList(waitText);

      this._httpClient.get<SourceCodeDto[]>(`${this._configService.apiUrl}/Maintenance/GetSourceCodes?filterValue=${filterValue}`).subscribe({
        next: (data: SourceCodeDto[]) => {
          this._modeService.removeFromWaitList(waitText);
          resolve(data);
        },
        error: async (error: HttpErrorResponse) => {
          this._modeService.removeFromWaitList(waitText);

          const errorMessage = this._errorHandlingService.getErrorMessage(error);
            await this.swalAlert.alert(errorMessage);
          resolve([]);
        }
      });
    });
  }
  async saveSourceCode(sourceCode: SourceCodeDto): Promise<SourceCodeDto|HttpErrorResponse> {
    return new Promise(resolve => {
      const waitText = 'Saving source code';
      this._modeService.addToWaitList(waitText);
      this._httpClient.post<SourceCodeDto>(`${this._configService.apiUrl}/Maintenance/SaveSourceCode`, sourceCode).subscribe({    
        next: (data: SourceCodeDto) => {
          this._modeService.removeFromWaitList(waitText);
          resolve(data);
        },
        error: async (error: HttpErrorResponse) => {
          this._modeService.removeFromWaitList(waitText);

          const errorMessage = this._errorHandlingService.getErrorMessage(error);
            await this.swalAlert.alert(errorMessage);
          resolve(error);
        }
      });
    });
  }
}
