import { Component} from '@angular/core';
import { Dialog } from 'src/app/helpers/dialog';
import { AccountingService } from 'src/app/services/accounting.service';
import { BillToService } from 'src/app/services/bill-to.service';
import { ConfigService } from 'src/app/services/config.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-account-comments',
  templateUrl: './account-comments.component.html',
  styleUrls: ['./account-comments.component.css']
})
export class AccountCommentsComponent {

  searchList:any = [];
  showUpdateAccountFlag:boolean = false;
  searchListToEdit:any;
  headerText:string='';
  accountIdObj:any = '';
  deleteCommentRow:any;
  showLoaderFlag:boolean =false;
  triggerEle = "searchCommentBtn";
  deleteTriggerEle = 'row-data';
  validationTriggerEle = 'addNewBtn'
  isEdit = false;
  // dialog ={
  //   show:false
  // }
  editDialog = new Dialog<any | null>();
  deleteDialog = {
    show:false
  }
  newObj = {
    'id':'',
    'comment':''
  };
  validationDialog = {
    show:false
  };
  validationMessage:string = '';
  salesForceRedirectFlag:boolean = false;
  constructor(private accountService:AccountingService,private configService: ConfigService,
    private orderService: OrderService,private billToService:BillToService) { }

  searchAccount(){
    this.searchList = [];
    if(this.accountIdObj != ''){
      this.showUpdateAccountFlag = true;
      this.showLoaderFlag = true;
      this.accountService.getAllAccountComments(this.accountIdObj).subscribe(res=>{
        this.searchList = res;
        this.showLoaderFlag = false;
        // if(this.searchList.length == 1){
        //   this.onEditClick(this.searchList[0]);
        // } 
        if(this.searchList.length === 0 ){
          this.validationMessage = 'No Account Comments exist for the Account ID entered, please click the Add New button to create new Account Comments';
          this.validationDialog.show = true;
          setTimeout(() => {
            if (document.getElementById('okBtn')) {
              document.getElementById('okBtn')?.focus();
            }
          });
        } 
      },error=>{
        this.showLoaderFlag = false;       
       this.updateErrorMessage(error);
      });
    }   
  }
  updateErrorMessage(error:any){
    this.validationMessage = error?.error?.Message;
    // if(this.validationMessage && this.validationMessage.includes('The Account ID entered is in Delete status')){
    //   this.salesForceRedirectFlag = true;
    // }else{
    //   this.salesForceRedirectFlag = false;
    // }
    if(error.hasOwnProperty('status') && error.status === 400){
      this.validationMessage = 'The Information which you entered is invalid';
    }
    this.validationDialog.show = true;
    setTimeout(() => {
      if (document.getElementById('okBtn')) {
        document.getElementById('okBtn')?.focus();
      }
    });
  }

  updateFlag(event:any){
    this.showUpdateAccountFlag = event;
  }

  async onEditClick(row:any){   
    this.headerText = 'Edit Account Comment';
    this.triggerEle = this.searchList.length == 1 ? 'searchCommentBtn':'edit-'+row.id;
    this.searchListToEdit = {...row};
    this.isEdit = true;
    const result =  await this.editDialog.openAsync();
    this.updateComments(result);
  }

  updateComments(result:any){
    if (result !== null) {
    const index = this.searchList.findIndex((res:any)=> res.id == result.id);
    if(index >= 0){
      this.searchList[index] = result;
    }else{
      this.searchList.push(result);
    }
   
    this.searchList = [...this.searchList];
    }
    this.searchListToEdit = {
      id:0,
      accountId: this.accountIdObj === ''? '' : parseInt(this.accountIdObj),
      comments:''
    };
  }

  async addNewAccount(){
    this.searchListToEdit = {
      id:0,
      accountId: this.accountIdObj === ''? '' : parseInt(this.accountIdObj),
      comments:''
    };
   
    this.headerText = 'Add Account Comment'; 
    this.isEdit = false;
    const result = await this.editDialog.openAsync(); 
    this.updateComments(result);
  }

  onDeleteClick(row:any){
    this.deleteDialog.show =  true;
    this.deleteCommentRow = row;
    this.deleteTriggerEle = 'delete-'+row.id;
    setTimeout(() => {
      if (document.getElementById(`yesBtn`)) {
        document.getElementById(`yesBtn`)?.focus();
      }
    });
  }

  closeDleteDialog(){
    this.deleteDialog.show =  false;
  }

  async closeValidationDialog(){
    this.validationDialog.show = false;
    if(this.salesForceRedirectFlag && this.accountIdObj){
      await this.billToService.getBillToInformationAsync(this.accountIdObj, new Date().getFullYear());
      window.open(
        `${this.configService.salesforceUrl}/apex/Accountform?id=${this.orderService.orderDto.billToDto.salesforceId}`
      );
    }
    if(this.editDialog.show){     
      this.validationTriggerEle = 'account-id-textbox';
      this.accountIdObj = '';
    }else{
      this.validationTriggerEle ='addNewBtn';
    }
  }

  cancelValidationDialog(){
    this.validationDialog.show = false;
    this.accountIdObj = '';
  }

  deleteComment(){
    this.accountService.deleteAccountComments(this.deleteCommentRow.id).subscribe(res=>{
        if(res){
          this.deleteDialog.show =false;
          const index = this.searchList.findIndex((res:any)=> res.id == this.deleteCommentRow.id);
          if(index >= 0){            
            this.searchList.splice(index, 1);
            this.searchList = [...this.searchList];
          }
          //this.searchAccount();
        }
    });
  }


  validateError(event:any){
   // this.dialog.show = event.closeDialog;
   this.triggerEle = 'account-id-textbox';
    this.accountIdObj = event.accountId;
    this.updateErrorMessage(event.error);
  }

}
