<div class = "overlayForPopups">
<div cdkDrag>
<uitk-dialog uitkId="packingSlip" [(showModal)]="dialog.show" [closeBtn]="false" [triggerElementId]="triggerEleId">
    <uitk-dialog-header>
        <div cdkDragHandle>
            Packing Slip
        </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
        <div class="row">
            <label uitkLabel class="col-lg-3">Comment Type</label>
            <div class="col-lg-9">
                <uitk-form-field>
                    <uitk-dropdown id="commentType" #singleSelect="ngModel" [items]="commentTypeList"
                      [disabled]="orderService.orderDto.orderStatus === 'V'"
                      [enableSelectFlag]="true" name="comment-Type" [(ngModel)]="selectedValue">
                    </uitk-dropdown>
                </uitk-form-field>
            </div>
        </div>

        <div class="row" *ngIf="selectedValue.value=='Account'">
            <div class="uitk-c-table--responsive">
                <table uitkTable [dataSource]="commentsData" id="basic-table" class="uitk-c-table"
                    aria-describedby="comments-data">
                    <thead class="uitk-c-table__head">
                        <tr>
                            <th *ngFor="let headerName of tableHeader" scope="col">
                                {{ headerName}}
                            </th>
                        </tr>
                    </thead>
                    <tbody id="comments-data" class="uitk-c-table__body">
                        <ng-container *uitkTableRowBody="let comment">
                            <tr class="uitk-c-table__row">
                                <td>
                                    <input id="gridRadioBtn" type="radio" name="radioname"
                                        (click)="updateComments(comment)">
                                </td>
                                <td id="comments">{{comment}} </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <tr class="uitk-c-table__row" style="display: table;width: 100%;">
                    <td *ngIf="this.commentsData.length===0">No Account Comments available to display</td>
                </tr>
            </div>
        </div>
        <div class="row">
            <label uitkLabel class="col-lg-3"  id='systemcommentlabel'>System Comment</label>
            <div class="col-lg-9">
                <uitk-form-field>
                    <input id="systemComment" uitkInput appEnableResource="OrderInquiryAdditionalActionsPackingSlipNotesSystemComment"
                    [disabled]="orderService.orderDto.orderStatus === 'V'"
                    [(ngModel)]="systemComment" maxlength="100"/>
                </uitk-form-field>
            </div>
        </div>

        <div class="row">
            <label uitkLabel class="col-lg-3">Comments</label>
            <div class="col-lg-9">
                <uitk-form-field>
                    <textarea uitkTextarea [(ngModel)]="freeFormComments" [textLimit]="255" id="comments"
                    [disabled]="orderService.orderDto.orderStatus === 'V'"
                      appEnableResource="OrderInquiryAdditionalActionsPackingSlipNotesComments" appAutoFocus></textarea>
                    <uitk-textarea-character-remaining-message (onCountChange)="onCountChange($event)">
                        {{ remainingCount }} characters remaining
                    </uitk-textarea-character-remaining-message>
                </uitk-form-field>
            </div>
        </div>
    </uitk-dialog-content>

    <uitk-dialog-actions>
        <button id="save" type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
            appEnableResource="OrderInquiryAdditionalActionsPackingSlipNotesSaveButton"
            [disabled]="orderService.orderDto.orderStatus === 'V' || (orderService.orderDto.shipmentMethod === 'MISC' && !freeFormComments)"
            (click)="savePackingNotes()">Save</button>

        <button id="cancel" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
            appEnableResource="OrderInquiryAdditionalActionsPackingSlipNotesCancelButton"
            (click)="cancelDialog()">Cancel</button>

    </uitk-dialog-actions>
</uitk-dialog>
</div>
</div>