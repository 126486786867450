import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UITKSortDirection, UITKTableDataSource } from '@uitk/angular';
import { UITKTableSortDirective } from '@uitk/angular/components/table/features/sort/table-sort.component';
import { UITKTableComponent } from '@uitk/angular/components/table/table.component';
import { IUITKColumnState } from '@uitk/angular/components/table/table.interfaces';
import { Subscription } from 'rxjs';
import { Mode } from '../../enums/mode.enum';
import { SubscriptionSearchResult } from '../../dtos/subscription-search-result.model';
import { SubscriptionSearchResultsDto } from '../../dtos/subscription-search-results-dto.model';
import { ConfigService } from '../../services/config.service';
import { ModeService } from '../../services/mode.service';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
  selector: 'app-subscription-search-results',
  templateUrl: './subscription-search-results.component.html',
  styleUrls: ['./subscription-search-results.component.css']
})
  
export class SubscriptionSearchResultsComponent implements OnInit {
  private readonly _subscriptionService: SubscriptionService;
  private readonly _configService: ConfigService;
  private readonly _router: Router;
  private readonly _titleService: Title;
  @Input() collapsedHeader = false;
  @Input() gridFilters: { fieldName: string, filterValue: string, filterApplied: boolean, columnName: string;  }[] = [];

  Mode = Mode;
  constructor(public modeService: ModeService,
    subscriptionService: SubscriptionService,
    configService: ConfigService,
    router: Router,
    titleService: Title) {
    this._subscriptionService = subscriptionService;
    this._configService = configService;
    this._router = router;
    this._titleService = titleService;
  }

  ngOnInit() {
    this.filter();
  }

  @ViewChild('sortTable') uitkTableSort!: UITKTableSortDirective;
  @ViewChild('rootTable') uitkTable!: UITKTableComponent<SubscriptionSearchResult[]>;

  selectedSubscription: SubscriptionSearchResult = new SubscriptionSearchResult();
  subscriptionHistoryDialog = { show: false };
  shipmentHistoryDialog = { show: false };
  subscriptionEditDialog = { show: false };

  subscriptions = new UITKTableDataSource<SubscriptionSearchResult>([]);
  filteredSubscriptions = new UITKTableDataSource<SubscriptionSearchResult>([]);
  
  gotSubscriptionSearchResultsSubscription: Subscription | undefined;

  tableHeader = [
    { name: 'Sub #', id: 'SubscriptionNumber', filter: 'subscriptionNumber' },
    { name: 'Item', id: 'ProductCode', filter: 'productCode' },
    { name: 'Description', id: 'ProductDescription', filter: 'productDescription' },
    { name: 'Qty', id: 'Quantity', filter: 'quantity' },
    { name: 'Start Date', id: 'StartDate', filter: 'startDate' },
    { name: 'Term', id: 'Term', filter: 'term' },
    { name: 'Exp Date', id: 'ExpirationDate', filter: 'expirationDate' },
    { name: 'Sub Status', id: 'SubscriptionStatusSort', filter: 'subscriptionStatus' },
    { name: 'Sub Type', id: 'SubscriptionType', filter: 'subscriptionType' },
    { name: 'Order #', id: 'SalesOrderNumber', filter: 'salesOrderNumber' },
    { name: 'Contact ID', id: 'ContactId', filter: 'contactId' },
    { name: 'Upd Only', id: 'UpdateOnly', filter: 'updateOnly' },
    { name: 'Payment Plan', id: 'PaymentPlan', filter: 'paymentPlan' }
  ];

  selectedValue: any;
  totalRecords = 1;
  tabTitle = 'Subscriptions';
  ngAfterViewInit() {
    this.uitkTableSort.setDirection({ column: 'SubscriptionStatusSort', direction: UITKSortDirection.ASC });
    this.uitkTableSort.setDirection({ column: 'ExpirationDate', direction: UITKSortDirection.DESC });
    this.filteredSubscriptions.sort = this.uitkTableSort;
  }

  onSortChange(event: IUITKColumnState): void {
    if (event.direction === -1) {
      this._subscriptionService.filterObj.sort = event.column + " descending";
    }
    else {
      this._subscriptionService.filterObj.sort = event.column;
    }
    this._subscriptionService.subscriptions = null;
    this._subscriptionService.searchSubscriptions(Mode.View, this._subscriptionService.filterObj, true);
  }

  filter(): void {
    this.gotSubscriptionSearchResultsSubscription = this._subscriptionService.gotSubscriptionSearchResultsEmitter
      .subscribe((data: SubscriptionSearchResultsDto) => {
        this.subscriptions.data = [...data.subscriptionSearchResults];
        this.filteredSubscriptions.data = [...data.subscriptionSearchResults];
        this.totalRecords = data.totalRecords;
        this.tabTitle = (this._subscriptionService.filterObj.accountId === 0 && this._subscriptionService.filterObj.contactId === 0 ||
          this.filteredSubscriptions.data.length === 0) ? 'Subscriptions' : 'Subscriptions: ' + this.filteredSubscriptions.data[0]?.accountId;
        this._titleService.setTitle(this.tabTitle);
      });
  }

  clearFilters(): void {
    for(const gridFilter of this.gridFilters) {
      gridFilter.filterValue = '';
      gridFilter.filterApplied = false;
      gridFilter.columnName = '';
    }
    this.gridFilters = [...this.gridFilters];
  }

  navigateToOrder(orderId: number): void {
    this._router.navigate([]).then(() => {
      window.open('/order/' + orderId, '_blank');
    });
  }
  navigateToSFViewAccount(salesforceId: string): void {
    window.open(`${this._configService.salesforceUrl}/apex/ViewContact?id=${salesforceId}`);
  }
  ngOnDestroy() {
    this.gotSubscriptionSearchResultsSubscription?.unsubscribe();
  }
}
