export class ShippingHandlingDto {
  shippingMethod: string | null = null;
  zip = '';
  country = '';
  shippingProducts: ShippingProducts[] = [];
}

export class ShippingProducts
{
  item = '';
  quantity = 0;
  unitWeight = 0;
}
