import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.css']
})
export class YesNoDialogComponent {
  @Input() dialog = { show: false} ;
  @Input() question = '';
  @Output() answer = new EventEmitter<string>();

  onClickYes(): void {
    
  }

  onClickNo(): void {

  }
}
