<div id="returnDetailsMain" class="uitk-l-grid">
  <div class="uitk-l-grid__row">
    <div class="uitk-l-grid__col">
      <h5 id="h5HeaderReturnEntry"><span>Return Entry:</span></h5>
    </div>
    <p>Return Date: {{todayDate | date: 'MM/dd/yyyy'}}</p>
  </div>
  <div id="returnDetailsChild">
    <div class="uitk-l-grid__row" style="margin-bottom:10px">
      <label uitkLabel>Account ID</label>
      <div class="uitk-l-grid__col" style="display:flex;">
        {{orderService.orderDto.billToDto.id}}
      </div>
      <div style="width:30px"></div>
      <label uitkLabel>Acct Type</label>
      <div class="uitk-l-grid__col" style="display:flex;">
        {{ orderService.orderDto.billToDto.accountTypeCode }}<span *ngIf="orderService.orderDto.billToDto.accountOwnerName"> - {{ orderService.orderDto.billToDto.accountOwnerName }}</span>
      </div>
      <div style="width:30px"></div>
      <label uitkLabel>Credit Limit</label>
      <div class="uitk-l-grid__col" style="display:flex;font-weight: bold;"
        [ngClass]="{'highlight': orderService.orderDto.billToDto.creditLimit < 0 }">
        {{ orderService.orderDto.billToDto.creditLimitDescription }}
      </div>
    </div>
    <div class="uitk-l-grid__row" style="margin-bottom:10px">
      <label uitkLabel>Type</label>
      <div class="uitk-l-grid__col" style="display:flex;">
        <uitk-form-field>
          <uitk-dropdown uitkId="soType" #singleSelect="ngModel" [items]="orderTypeList" [disabled]="true"
            [enableSelectFlag]="true" [(ngModel)]="orderService.orderDto.orderTypeBinding"
            appEnableResource="ReturnEntryReturnType"></uitk-dropdown>
        </uitk-form-field>
      </div>
      <div style="width:30px"></div>
      <label uitkLabel>Return #</label>
      <div class="uitk-l-grid__col" style="display:flex;">
        {{orderService.orderDto.id}}
      </div>
      <div style="width:30px"></div>
      <label uitkLabel>Open Amt</label>
      <div class="uitk-l-grid__col" style="display:flex;">
        {{ orderService.orderDto.openAmount | currency }}
      </div>
    </div>
    <div class="uitk-l-grid__row">
      <label uitkLabel>Original SO #</label>
      <div class="uitk-l-grid__col" style="display:flex;">
        <a target="_blank" class="link" style="text-decoration: none;"
          (click)="navigateToOrderInquiry(orderService.orderDto.originalOrderId)">{{
          orderService.orderDto.originalOrderId }}</a>
      </div>
      <div style="width:30px"></div>
      <label uitkLabel>Status</label>
      <div class="uitk-l-grid__col" style="display:flex;">
        <uitk-dropdown uitkId="soStatus" [items]="orderStatusFilterList" [enableSelectFlag]="false"
          [(ngModel)]="orderService.orderDto.orderStatusBinding" (ngModelChange)="onOrderStatusChange($event.value)">
        </uitk-dropdown>
      </div>
      <div style="width:30px"></div>
      <label uitkLabel>Shipped Amt</label>
      <div class="uitk-l-grid__col" style="display:flex;">
        {{ orderService.orderDto.shippedAmount | currency }}
      </div>
    </div>
  </div>
  <div class="uitk-l-grid__row">
    <div class="uitk-l-grid__col--2">
      <div class="demo-l-grid-col-content" style="padding-top: 16px;padding-left: 5px;"><label uitkLabel>Return
          Code</label></div>
    </div>
    <div class="uitk-l-grid__col--6">
      <div class="demo-l-grid-col-content" style="padding-top: 3px;">
        <uitk-form-field>
          <uitk-dropdown style="font-size:0.8rem;width:90%" uitkId="returnSourceCode" [items]="returnCodeList"
            [required]="true" [enableSelectFlag]="true" (onChange)="onChangeReturnCode($event)"
            [disabled]="disableReturnCode" [(ngModel)]="orderService.orderDto.returnCodeBinding"></uitk-dropdown>
          <uitk-form-field-error *ngIf="showReturnCodeError">
            Return Code is required
          </uitk-form-field-error>
        </uitk-form-field>
      </div>
    </div>
    <div class="uitk-l-grid__col--2">
      <div class="demo-l-grid-col-content" style="padding-top: 16px;padding-left: 5px;"><label uitkLabel>Entered By</label></div>
    </div>
    <div class="uitk-l-grid__col--2">
      <div class="demo-l-grid-col-content" style="padding-top: 3px;">
        <uitk-form-field> <input uitkInput id="salesPersonName" [(ngModel)]="orderService.orderDto.enteredBy"
            disabled="disabled" /></uitk-form-field>
      </div>
    </div>
  </div>
  <hr>
  <div class="uitk-l-grid__row">
    <p style="margin-left: 5px;">Product</p>
    <div class="uitk-l-grid__col">
      <button id="add" type="button" class="uitk-c-button uitk-c-button--ghost  uitk-u-margin--xxs" (click)="add()"
        appEnableResource="ReturnEntryAddItemButton">
        Add
      </button>
    </div>
    <div class="uitk-u-flex__align--right">
      <button id="save" type="button"
        class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="save()"
        [disabled]="selectedLineItemsCount < 1" appEnableResource="ReturnEntryContinueButton">
        Save
      </button>
      <button id="close" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
        appEnableResource="ReturnEntryCloseButton"
        (click)="navigateToOrderInquiry(orderService.orderDto.originalOrderId)">Revert</button>
    </div>
  </div>
  <div class="uitk-l-grid__row">
    <div class="uitk-l-grid__col" style="width: 100%;height: 842px;">
      <div id="loadingSpinner" *ngIf="loading" class="uitk-l-grid">
        <uitk-loading-indicator uitkId="spinner" [visibleText]="visibleTextValue" spinnerSize="large">
        </uitk-loading-indicator>
      </div>
      <div *ngIf="!loading" class="uitk-c-table--responsive" style="max-height: 300px">
        <table uitkTable [dataSource]="orderService.orderDto.orderDetailDtos" class="uitk-c-table"
          aria-describedby="return-data">
          <thead class="uitk-c-table__head">
            <tr style="text-align: left">
              <th scope="col" class="freeze"></th>
              <th scope="col" class="freeze"
                [ngStyle]="{'left': orderService.orderDto.orderDetailDtos.length > 0? '80px' : '20px'}">Item </th>
              <th scope="col" class="freeze"
                [ngStyle]="{'left': orderService.orderDto.orderDetailDtos.length > 0? '220px' : '80px'}">Description
              </th>
              <th scope="col" class="freeze"
                [ngStyle]="{'left': orderService.orderDto.orderDetailDtos.length > 0? '370px' : '180px'}">Return Qty
              </th>
              <th scope="col" class="freeze"
                [ngStyle]="{'left': orderService.orderDto.orderDetailDtos.length > 0? '470px' : '250px'}">List Price
              </th>
              <th *ngFor="let headerName of tableHeader" scope="col">
                {{ headerName }}
              </th>
            </tr>
          </thead>
          <tbody class="uitk-c-table__body">
            <ng-container *uitkTableRowBody="let returnDetailDto;">
              <tr *ngIf="returnDetailDto" class="uitk-c-table__row ">
                <td class="freeze" style="background-color: white;">
                  <input uitkCheckbox id="returnOrderDetailId--{{ returnDetailDto.id }}" type="checkbox"
                    [(ngModel)]=returnDetailDto.isSelected (change)="clickCheckBox(returnDetailDto,$event)"
                    [disabled]="!returnDetailDto.invoiceNumber || returnDetailDto.productCode==='0070' || returnDetailDto.productCode==='0070E' || returnDetailDto.productCode === '0048' || returnDetailDto.productCode === '0010'"
                    appEnableResource="ReturnEntryGridCheckbox" />
                  <label uitkCheckboxLabel id="chk"></label>
                  <span class="uitk-icon uitk-icon-trash_delete gridIcons"
                    [class.disabled]="returnDetailDto.originalOrderDetailId!==null"
                    (click)="onDeleteClick(returnDetailDto)" appEnableResource="ReturnEntryGridDeleteicon"></span>
                </td>
                <td class="freeze" style="left:80px;background-color: white;">
                  <uitk-form-field>
                    <input type="text" id="productCode-{{ returnDetailDto.id }}" uitkInput
                      [(ngModel)]="returnDetailDto.productCode" name="myBasicInput"
                      [ngStyle]="{'width': returnDetailDto.parentOrderDetailId === null && returnDetailDto.productCode.indexOf('V') !== 0 ? '120px' : '105px',
                           'margin-left': returnDetailDto.parentOrderDetailId === null && returnDetailDto.productCode.indexOf('V') !== 0 ? '0px' : '15px'}"
                      (change)="onChangeProductCode(returnDetailDto)" [disabled]="!returnDetailDto.itemEditable"
                      appTextFormat>
                  </uitk-form-field>
                </td>
                <td class="freeze" style="left:220px;background-color: white;min-width:150px">{{
                  returnDetailDto.productDescription }}</td>
                <td class="freeze" style="left:370px;background-color: white;">
                  <uitk-form-field>
                    <input type="text" id="returnQuantity-{{ returnDetailDto.id }}" uitkInput
                      (click)="returnDetailDto.orderQuantity = ''" (change)="onReturnQuantityChange(returnDetailDto)"
                      [(customModel)]="returnDetailDto.orderQuantity" name="myBasicInput" style="width: 80px;"
                      appQuantityFormat [disabled]="returnDetailDto.disableQuantity"
                      appEnableResource="ReturnEntryReturnQtyTextBox">
                  </uitk-form-field>
                </td>
                <td class="freeze" style="left:470px;background-color: white;">{{ returnDetailDto.listPrice | currency
                  }}
                </td>
                <td *ngIf="returnDetailDto.invoiceNumber">
                  <a href="#" [routerLink]="['/accountreceivable/arinquiry/', orderService.orderDto.billToDto.id, returnDetailDto.invoiceNumber]"
                    target="_blank" style="text-decoration: none;">{{ returnDetailDto.invoiceNumber }}</a>
                </td>
                <td *ngIf="!returnDetailDto.invoiceNumber">
                  Pending
                </td>
                <td>
                  {{ returnDetailDto.balance | currency }}
                </td>
                <td>
                  <uitk-form-field>
                    <input type="text" id="discount-{{ returnDetailDto.id }}" uitkInput
                      [(customModel)]="returnDetailDto.discount" name="myBasicInput" style="width: 100px;"
                      (change)="onChangeDiscount(returnDetailDto)"
                      [disabled]="returnDetailDto.originalOrderDetailId!==null" appDiscountFormat>
                  </uitk-form-field>
                </td>
                <td>
                  <uitk-form-field>
                    <input type="text" id="unitPrice-{{ returnDetailDto.unitPrice }}" uitkInput
                      [(customModel)]="returnDetailDto.unitPrice" name="myBasicInput" style="width: 80px;"
                      (change)="onChangeUnitPrice(returnDetailDto)"
                      [disabled]="returnDetailDto.originalOrderDetailId!==null" appPriceFormat>
                  </uitk-form-field>
                </td>
                <td>{{ returnDetailDto.extendedPrice | currency }}</td>
                <td>
                  {{returnDetailDto.requiredDate | date:'MM/dd/yyyy'}}
                </td>
                <td>{{ returnDetailDto.orderDetailStatus === ' ' ? 'O' :
                  returnDetailDto.orderDetailStatus
                  }}</td>
                <td>{{ returnDetailDto.shippedQuantity }}</td>
                <td>{{ returnDetailDto.shipDate | date:'MM/dd/yyyy' }}</td>
                <td>
                  <span *ngIf="returnDetailDto.subscriptionDto">
                    {{ returnDetailDto.subscriptionDto.term }}
                  </span>
                </td>
                <td>{{ returnDetailDto.pendingDate | date:'MM/dd/yyyy' }}</td>
                <td>{{ returnDetailDto.orderDetailStatus === 'V' ? (returnDetailDto.voidDate | date:'MM/dd/yyyy') :
                  returnDetailDto.modifiedDate | date:'MM/dd/yyyy' }}</td>
                <td>{{ returnDetailDto.voidedQuantity }}</td>
                <td>{{ returnDetailDto.voidReasonCode }}</td>
                <td class="px-2" [ngbTooltip]="accountOwnerFullName" container="body" placement="left">{{
                  returnDetailDto.accountOwnerCode }}</td>
                <ng-template #accountOwnerFullName><div style="color: white !important">{{ returnDetailDto.accountOwnerFullName }}</div></ng-template>
                <td class="px-2" [ngbTooltip]="salesPersonFullName" container="body" placement="left">{{
                  returnDetailDto.salespersonCode }}</td>
                <ng-template #salesPersonFullName><div style="color: white !important">{{ returnDetailDto.salespersonFullName }}</div></ng-template>
                <td>{{ orderService.orderDto.paymentPlan }}</td>
                <td>{{ returnDetailDto.taxAmount | currency }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <app-return-detail-action></app-return-detail-action>
    </div>
  </div>
</div>
<div class="divPopUp" *ngIf="openDeletePopup === true">
<div *ngIf="deleteItemDialogModal.show" class = "overlayForPopups">
  <div cdkDrag>
  <uitk-dialog [(showModal)]="deleteItemDialogModal.show" [closeBtn]=false>
    <uitk-dialog-content>
      <h4>
        <span> Do you want to delete this line item? </span>
      </h4>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="onClick_ConfirmDeleteOrder()"
        appAutoFocus>
        Delete
      </button>
      <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="onDeletePopupClose()">
        Close
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
  </div>
</div>
</div>

<div *ngIf="zeroPricingDialogModal.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="zeroPricingDialogModal.show" [closeBtn]=false>
  <uitk-dialog-content>
    {{zeroPricingMessage}}
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="closeZeroPricingItemDialog()"
      #zeroPricingOkBtn>
      Ok
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>
<div *ngIf="priceChangeDialogModal.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="priceChangeDialogModal.show" [closeBtn]=false>
  <uitk-dialog-content>
    {{priceChangeMessage}}
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="closePriceChangeItemDialog()"
      #priceChangeOkBtn>
      Ok
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="cancelReturnCode()">
      Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>

<div *ngIf="priceChangeDuplicateDialogModal.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="priceChangeDuplicateDialogModal.show" id= "priceChangeDuplicateDialogModal" [closeBtn]=false>
  <uitk-dialog-header>
    <div cdkDragHandle>
    {{priceChangeDuplicateHeaderMessage}}
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div class="uitk-c-table--responsive">
      <table uitkTable [dataSource]="returnDtos" class="uitk-c-table" id="pcreturns-table">
        <thead class="uitk-c-table__head">
          <tr style="text-align: left">
            <th *ngFor="let headerName of retunTableHeader" scope="col">
              {{ headerName.name }}
            </th>
            <th scope="col">
              <p>SO Total</p>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="modeService.isWaiting===true">
          <div class="uitk-l-grid--max-width">
            <div id="loadingIndicator">
              <uitk-loading-indicator uitkId="spinner" [visibleText]="visibleTextValue" spinnerSize="large">
              </uitk-loading-indicator>
            </div>
          </div>
        </tbody>
        <tbody id="row-data" class="uitk-c-table__body" *ngIf="modeService.isWaiting === false">
          <ng-container *uitkTableRowBody="let records">
            <tr class="uitk-c-table__row">
              <td><a id="returnInquiryNavigation" class="link"
                  (click)="navigateToReturnInquiry(records.id)">{{records.id}}</a></td>
              <td>{{ records.orderDate | date: 'MM/dd/yyyy' }}</td>
              <td>{{ records.orderType }}</td>
              <td>{{ records.orderStatus === ' ' ? 'O' : records.orderStatus }}</td>
              <td>{{ records.returnCode }}</td>
              <td>{{ records.enteredBy }}</td>
              <td>{{ totalAmount | number:'1.2-2'| currency }}
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    {{priceChangeDuplicateMessage}}
    <br />
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="closePriceChangeItemDialog()">
      Ok
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="cancelReturnCode()">
      Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>
<div *ngIf="priceChangeInputDialogModal.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="priceChangeInputDialogModal.show" [closeBtn]=false>
  <uitk-dialog-content>
    {{priceChangeInputDialogMessage}}
    <div class="uitk-l-grid__row" style="margin-bottom:10px;padding-top: 35px;">
      <label uitkLabel>List Price</label>
      <div class="uitk-l-grid__col">
        {{selectedOrder.listPrice | currency:'USD'}}
      </div>
    </div>
    <div class="uitk-l-grid__row">
      <label uitkLabel>Quantity</label>
      <div class="uitk-l-grid__col">
        {{quantity}}
      </div>
    </div>
    <div class="uitk-l-grid__row">
      <label uitkLabel>Discount</label>
      <div class="uitk-l-grid__col">
        <uitk-form-field>
          <input type="text" uitkInput [(customModel)]="discount" name="myBasicInput" style="width: 90px;"
            (change)="onChangeDiscountOnPriceChangePopUp()" appDiscountFormat>
        </uitk-form-field>
      </div>
    </div>
    <div class="uitk-l-grid__row">
      <label uitkLabel>UnitPrice</label>
      <div class="uitk-l-grid__col">
        <uitk-form-field>
          <input type="text" uitkInput [(customModel)]="unitPrice" name="myBasicInput" style="width: 90px;"
            (change)="onChangeUnitPriceOnPriceChangePopUp()" appPriceFormat>
        </uitk-form-field>
      </div>
    </div>
    <div class="uitk-l-grid__row">
      <label uitkLabel>Ext Price</label>
      <div class="uitk-l-grid__col">
        {{extPrice | currency:'USD'}}
      </div>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="closeInputDialog()"
      [disabled]="!isvalidInput" #priceChangeInputOkBtn>
      Ok
    </button>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="closePriceChangeInputDialog()">
      Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>

<div *ngIf="showNoItemExists.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="showNoItemExists.show" uitkId="noItemDialog" triggerElementId="noItem" [closeBtn]=false>
  <uitk-dialog-header>
    <div cdkDragHandle>
    Error Message
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    {{ myNotificationWarningContent }}
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <div class="text-center">
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="closeNoItemDialog()"
        #noItemOkBtn>Ok</button>
    </div>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>
<div class="divPopUp" *ngIf="addNewReturnDialog.show">
  <app-add-new-return [dialog]="addNewReturnDialog"></app-add-new-return>
</div>

<div class="divPopUp" *ngIf="addReturnAuthorizationDialog.show">
  <app-add-return-authorization [dialog]="addReturnAuthorizationDialog"></app-add-return-authorization>
</div>

<app-order-entry-error-reason-dialog [oeeReasondialog]="orderEntryErrorReasonDialog">
</app-order-entry-error-reason-dialog>
