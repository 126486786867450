import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ScrollToDirective } from '../../directives/scrollTo.directive';
import { ApprovalService } from '../../services/approval.service';
import { OrderDetailsGridComponent } from '../../components/order-entry/order-details-grid/order-details-grid.component';
import { SubscriptionPriceCalculationComponent } from '../../components/order-inquiry/subscription-price-calculation/subscription-price-calculation.component';
import { Dialog } from '../../helpers/dialog';
import { ItemOfferDto } from '../../dtos/item-offer-dto';
import { PremiumDto } from '../../dtos/premium-dto';
import { OrderDetailDto } from '../../dtos/order-detail-dto.model';
import { ConfigService } from '../../services/config.service';
import { OrderService } from '../../services/order.service';
import { PricingService } from '../../services/pricing.service';
import { ShippinghandlingService } from '../../services/shippinghandling.service';
import { SwalAlert } from '../../helpers/alert';

@Component({
  selector: 'app-item-level-offers',
  templateUrl: './item-level-offers.component.html',
  styleUrls: ['./item-level-offers.component.css'],
  providers: [OrderDetailsGridComponent, SubscriptionPriceCalculationComponent]
})

export class ItemLevelOffersComponent implements OnInit {
  private readonly _configService: ConfigService;
  private readonly _pricingService: PricingService;
  private readonly _elementRef: ElementRef;
  private readonly _approvalService: ApprovalService;
  private readonly _shippinghandlingService: ShippinghandlingService;
  acceptOfferText = 'Accept This Offer';
  doNotAcceptOfferText = 'Do Not Accept This Offer';
  itemLevelOffersDialog = { show: false };
  @Input() showItemLevelOffers!: Dialog<void>;
  @Input() orderDetail: OrderDetailDto = new OrderDetailDto();
  @Input() itemOfferDto: ItemOfferDto = new ItemOfferDto();
  @Output() outOrderDetail = new EventEmitter<OrderDetailDto>();
  @ViewChild(ScrollToDirective) dir:any;
  configurationOrderDetail: OrderDetailDto = new OrderDetailDto();
  selectAllPremium = false;
  selectedPremiumId = 0;
  selectedPremium: PremiumDto = new PremiumDto();
  configSetDialog = new Dialog<string>();
  premiumOrderDetail = new OrderDetailDto();
  openConfigurationPopup = false;
  discountType: string = 'itemdiscount';
  itemLevelOfferHeader = ['Item', 'Description', 'Qty', 'List Price', 'Disc', 'New Price'];
  allowSingleItemPopup = { show: false };
  duplicateProduct = { show: false };
  webItemAllowOnlyOneDialog = new Dialog<void>();
  continueDuplicateItemDialog = new Dialog<string>();
  duplicateOrderDetail: OrderDetailDto = new OrderDetailDto;
  swalAlert = new SwalAlert();

  constructor(
    public orderService: OrderService,
    configService: ConfigService,
    pricingService: PricingService,
    approvalService: ApprovalService,
    public orderDetailsGridComponent: OrderDetailsGridComponent,
    elementRef: ElementRef,
    shippinghandlingService: ShippinghandlingService) {
    this._configService = configService;
    this._pricingService = pricingService;
    this._elementRef = elementRef;
    this._approvalService = approvalService;
    this._shippinghandlingService = shippinghandlingService;
  }

  ngOnInit(): void {
    this.itemOfferDto.premiums.forEach(element => {
      const isPremiumExists = this.orderService.orderDto.orderDetailDtos.some(o => o.productId === element.productId &&
        o.promotionEntryCode === 'ILP' && o.promotionOrderDetailId === this.orderDetail.id);
      element.isSelected = isPremiumExists ? true : false;
    });
  }
  acceptOfferWithDiscountTypeItemDiscount(){
    this.orderDetail.discountType = 'itemdiscount';
    if(this.itemOfferDto.percentDiscount){
      if (this.itemOfferDto.discountAmount > this.orderDetail?.discount) {
        this.orderDetail.discount = this.itemOfferDto.discountAmount;
        this._pricingService.calculateUnitPrice(this.orderDetail);
        this._pricingService.calculateExtendedPrice(this.orderDetail);
      }
    }
    else{
      let discount = this._pricingService.round((100 - (this.orderDetail.listPrice - this.itemOfferDto.discountAmount) / this.orderDetail.listPrice * 100), 10);
      if (discount > this.orderDetail?.discount) {
      this.orderDetail.unitPrice = this.orderDetail.listPrice-this.itemOfferDto.discountAmount;
      this._pricingService.calculateDiscount(this.orderDetail);
      this._pricingService.calculateExtendedPrice(this.orderDetail);
      }
    }
  }
  async acceptOfferWithDiscountTypePremium() {
    this.clearSelectedPremiums();
    this.orderDetail.discountType = 'premiums';
    for (const element of this.itemOfferDto.premiums) {
      if (element.isSelected) {
        await this.addOrderDetail(element.productId);
      }
    }
  }
  async addSelectedPremiumsIfNotExists() {
    for (const premium of this.itemOfferDto.premiums) {
      const productAlreadyExists = this.orderService.orderDto.orderDetailDtos.some(o => o.productId === premium.productId && premium.isSelected &&
        o.promotionEntryCode === 'ILP' && o.promotionOrderDetailId === this.orderDetail.id);

      if (productAlreadyExists) {
        continue;
      }else if (premium.isSelected){
        await this.addOrderDetail(premium.productId);
      }
    }
  }

  setOfferDiscount(){
    if (this.itemOfferDto.discountAmount > this.orderDetail?.discount) {
      this.orderDetail.discount = this.itemOfferDto.discountAmount;
      this._pricingService.calculateUnitPrice(this.orderDetail);
      this._pricingService.calculateExtendedPrice(this.orderDetail);
    }
  }
  setOfferUnitPrice(){
    let discount = this._pricingService.round((100 - (this.orderDetail.listPrice - this.itemOfferDto.discountAmount) / this.orderDetail.listPrice * 100), 10);
    if (discount > this.orderDetail?.discount) {
      this.orderDetail.unitPrice = this.orderDetail.listPrice - this.itemOfferDto.discountAmount;
      this._pricingService.calculateDiscount(this.orderDetail);
      this._pricingService.calculateExtendedPrice(this.orderDetail);
    }
  }
  async updateCartWhenUserSelectsPremium() {
    if (this.itemOfferDto.premiums.length > 0) {
      this.clearSelectedPremiums();
      await this.addSelectedPremiumsIfNotExists();

      if (this.itemOfferDto.percentDiscount) {
        this.setOfferDiscount();
      }
      else {
        this.setOfferUnitPrice();
      }
    }
  }
  async acceptOffer() {
    this.orderDetail.promotionEntryCode = 'ILT';
    if (this.itemOfferDto.discountAndPremium === false) {
      if (this.discountType === 'itemdiscount') {
        this.acceptOfferWithDiscountTypeItemDiscount();
      }
      else if (this.discountType === 'premium' && this.itemOfferDto.premiums.length > 0) {//user selected any one of the premiums listed
        await this.acceptOfferWithDiscountTypePremium();
      }
    }
    //User accepts discount and selects one or more premiums
    if (this.itemOfferDto.discountAndPremium === true) {
      await this.updateCartWhenUserSelectsPremium();
    }
    if (this.itemOfferDto.percentDiscount && this.itemOfferDto.premiums.length === 0) {
      this.setOfferDiscount();
    }
    if (!this.itemOfferDto.percentDiscount && this.itemOfferDto.premiums.length === 0) {
     this.setOfferUnitPrice();
    }
    this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    this.showItemLevelOffers.close();
    this.removeEmptyRecordAddAtLast();
    this._shippinghandlingService.premiumShippingItem = new PremiumDto();
  }

  clearSelectedPremiums(): void{
    for (const element of this.itemOfferDto.premiums) {
      if (!element.isSelected) {
        const productIndex = this.orderService.orderDto.orderDetailDtos.findIndex(o => o.productId === element.productId &&
          o.promotionEntryCode === 'ILP' && o.promotionOrderDetailId === this.orderDetail.id);

        if (productIndex > -1) {
          this.orderService.orderDto.orderDetailDtos.splice(productIndex, 1);
        }
      }
    }
  }

  doNotAcceptOffer() {
    this.orderDetail.promotionEntryCode = null;
    this.showItemLevelOffers.close();
    this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    this._shippinghandlingService.premiumShippingItem = new PremiumDto();
  }

  onCheckBoxSelectionChange() {
    const selectAll = !this.itemOfferDto.premiums.some(item => !item.isSelected);
    this.selectAllPremium = selectAll;
  }

  onRadioButtonSelectionChange(premium: PremiumDto) {
    this.itemOfferDto.premiums.forEach(item => {
      item.isSelected = false;

      if (item.productId === premium.productId) {
        item.isSelected = true;
      }
    });
  }

  closeDialog(){
    this.dir!.target = "#divLeftOrderDetailsGrid";
    this.dir?.scrollToPosition();

    setTimeout(()=>{
      const eleLbl = '#tblLeftOrderDetailsGrid tbody tr';
      const len = document.querySelectorAll(eleLbl).length;
      if((document.querySelectorAll(eleLbl)[len-1]?.childNodes[3]?.childNodes[0]?.childNodes[0]?.childNodes[1] as HTMLInputElement)){
        (document.querySelectorAll(eleLbl)[len-1]?.childNodes[3]?.childNodes[0]?.childNodes[0]?.childNodes[1] as HTMLInputElement).focus();
      }
    });
  }

  onClickSelectAll() {
    this.itemOfferDto.premiums.forEach(element => {
      element.isSelected = this.selectAllPremium;
    });
  }

  private removeEmptyRecordAddAtLast() {
    //remove empty record from middle and add it at the last
    if (this.orderService.orderDto.orderDetailDtos.find(i => i.productId === 0)) {
      const orderDetailDto = this.orderService.orderDto.orderDetailDtos.filter(i => i.productId === 0)[0];
      const index = this.orderService.orderDto.orderDetailDtos.indexOf(orderDetailDto);
      if (index !== -1) {
        this.orderService.orderDto.orderDetailDtos.splice(index, 1);
        this.orderDetailsGridComponent.addNewLineItem();
      }
    }
  }

  async addOrderDetail(id: number): Promise<void> {
    let duplicateItemPopupResult = '';
    const product = this._configService.reference?.productDtos.filter(p => p.id === id)[0];

    if (!product) {
      this.swalAlert.alert("Item is inactive or does not exist");
      return;
    }

    const newOrderDetail = this.orderService.createNewOrderDetail(this.orderService.orderDto, product.productCode);
    const premium = this.itemOfferDto.premiums.filter(p => p.productId === id)[0];

    if (!premium) {
      return;
    }

    const isItemExists = this.orderService.orderDto.orderDetailDtos
      .filter(o => o.productId === premium.productId && o.orderDetailStatus !== 'V' && o.productDto.inventoryType !== 'S' &&
        o.productDto.classCode !== 'SH' && o.productCode.toUpperCase() === premium.productCode.toUpperCase()).length > 0;
    const shippingAndHandlingItems = this.orderService.orderDto.orderDetailDtos
      .filter(sh => sh.orderDetailStatus === ' ' && sh.productDto.classCode === 'SH');

    if (!await this.checkIfWebAppExists(premium)) {
      return;
    }
    if (shippingAndHandlingItems.length > 0) {
      this.orderService.deleteOrVoidOrderDetail(shippingAndHandlingItems.slice(-1)[0]);
    }

    let splDiscount = this._pricingService.getSplDiscount(this.orderService.orderDto, premium.productId) ?? 0;        
    newOrderDetail.listPrice = premium.listPrice;
    newOrderDetail.discount = premium.discount > splDiscount? premium.discount: splDiscount;
    newOrderDetail.shippedQuantity = 0;
    newOrderDetail.voidedQuantity = 0;
    this._pricingService.calculateUnitPrice(newOrderDetail);
    newOrderDetail.openQuantity = premium.quantity;
    newOrderDetail.orderQuantity = newOrderDetail.openQuantity + newOrderDetail.shippedQuantity;
    this._pricingService.calculateExtendedPrice(newOrderDetail);
    newOrderDetail.itemEditable = false;
    newOrderDetail.orderDetailStatus = ' ';
    newOrderDetail.promotionEntryCode = 'ILP';
    newOrderDetail.promotionOrderDetailId = this.orderDetail.id;
    this.orderService.recalculateFields(this.orderService.orderDto);
    //add the record to OrderDetails Grid
    this.orderService.orderDto.orderDetailDtos.push(newOrderDetail);
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];

    if (isItemExists) {
      this.duplicateOrderDetail = newOrderDetail;
      duplicateItemPopupResult = await this.continueDuplicateItemDialog.openAsync();

      setTimeout(() => {
        if (this._elementRef.nativeElement.querySelector(`button[id=btnDuplicateItemYes]`)) {
          this._elementRef.nativeElement.querySelector(`button[id=btnDuplicateItemYes]`).focus();
        }
      });
    }

    // If the premium has a config set then launch it.
    if (duplicateItemPopupResult !== 'No') {
      await this.openConfigSetAsync(newOrderDetail, true);
    }
  }
  async checkIfWebAppExists(premium: PremiumDto): Promise<boolean> {
    const doesWebAppAlreadyExist = this.orderService.orderDto.orderDetailDtos
      .filter(o => o.productId === premium.productId && o.orderDetailStatus !== 'V' && o.productDto.inventoryType === 'S' &&
        o.productCode.toUpperCase() === premium.productCode.toUpperCase()).length > 0;
    if (doesWebAppAlreadyExist) {
      await this.webItemAllowOnlyOneDialog.openAsync();

      setTimeout(() => {
        if (this._elementRef.nativeElement.querySelector(`button[id=btnAllowSingleItem]`)) {
          this._elementRef.nativeElement.querySelector(`button[id=btnAllowSingleItem]`).focus();
        }
      });

      return false;
    }
    return true
  }
  async openConfigSetAsync(orderDetail: OrderDetailDto, isEdit: boolean = false) {
    if (orderDetail.productDto.configSetDtos.length === 0) {
      return;
    }

    if (isEdit) {
      localStorage.setItem('isEditMode', 'true');
    }

    this.premiumOrderDetail = orderDetail;
    if (this._shippinghandlingService.premiumShippingItem.productId !== 0 && (this.orderService.orderDto.orderStatus !== 'C')) {
      this.orderService.configurationOrderDetail = this.premiumOrderDetail;
      await this.orderService.shippingDialogModal.openAsync();
    }
    else {
      const result = await this.configSetDialog.openAsync();

      if (result === 'Cancel') {
        this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item.id !== this.premiumOrderDetail.id);
        this.orderService.recalculateFields(this.orderService.orderDto);
      }
    }
  }
}

