<uitk-dialog [(showModal)]="dialog.show" uitkId="addReturnAuthorizationDialog" [closeBtn]=false triggerElementId="addReturnAuthorizationDialog_openBtn">
    <uitk-dialog-header style="letter-spacing: 0.3px; font-size: 20px; line-height: 48px;">
        Return Authorization
    </uitk-dialog-header>
    
    <uitk-dialog-content>
        <p>For returns/Authorizations Greater than 60 Days</p>
        <p>To obtain approval on a return greater than 60 days, sales rep must complete this form below. It will be attached to the order. Returns $0 
            to $2500 must be approved by your manager. Returns greater then $2500 must be approved by your manager, VP of Sales and Business Finance Lead.</p>
        <div class="uitk-l-grid__row">
            <label uitkLabel id="startMonthlbl" style="width: 172px;">Date: </label>
            <div class="uitk-l-grid__col">
                <div style="padding: 0px 20px 0px 20px;">
                    <uitk-form-field>
                        <input uitkInput id="formDateate" name="formDate" value="{{formDate | date: 'MM/dd/yyyy'}}" [disabled]="true" />
                    </uitk-form-field>
                </div>
            </div>
        </div>
        <div class="uitk-l-grid__row">
            <label uitkLabel id="salesReplbl" style="width: 172px;">Sales Rep: </label>
            <div class="uitk-l-grid__col">
                <div style="padding: 0px 20px 0px 20px;">
                    <uitk-form-field>
                        <input uitkInput id="salesRep" name="salesRep" [(ngModel)]="this.salesRepName" [disabled]="true" />
                    </uitk-form-field>
                </div>
            </div>
        </div>
        <!-- <div class="uitk-l-grid__row">
            <label uitkLabel id="salesManagerlbl" style="width: 172px;">Manager: </label>
            <div class="uitk-l-grid__col">
                <div style="padding: 0px 20px 0px 20px;">
                    <uitk-form-field>
                        <input uitkInput id="salesManager" name="salesManager" [(ngModel)]="this.orderService.orderDto.billToDto.accountOwnerName" [disabled]="true" />
                    </uitk-form-field>
                </div>
            </div>
        </div> -->
        <div class="uitk-l-grid__row">
            <label uitkLabel id="accountIdlbl" style="width: 172px;">Account ID: </label>
            <div class="uitk-l-grid__col">
                <div style="padding: 0px 20px 0px 20px;">
                    <uitk-form-field>
                        <input uitkInput id="accountId" name="accountId" [(ngModel)]="this.orderService.orderDto.billToDto.id" [disabled]="true" />
                    </uitk-form-field>
                </div>
            </div>
        </div>
        <div class="uitk-l-grid__row">
            <label uitkLabel id="accountNamelbl" style="width: 172px;">Account Name: </label>
            <div class="uitk-l-grid__col">
                <div style="padding: 0px 20px 0px 20px;">
                    <uitk-form-field>
                        <input uitkInput id="accountName" name="accountName" [(ngModel)]="this.orderService.orderDto.billToDto.company" [disabled]="true" />
                    </uitk-form-field>
                </div>
            </div>
        </div>
        <div class="uitk-l-grid__row">
            <label uitkLabel id="salesOrderNumlbl" style="width: 172px;">Sales Order #: </label>
            <div class="uitk-l-grid__col">
                <div style="padding: 0px 20px 0px 20px;">
                    <uitk-form-field>
                        <input uitkInput id="salesOrderNum" name="salesOrderNum" [(ngModel)]="this.orderService.orderDto.originalOrderId" [disabled]="true" />
                    </uitk-form-field>
                </div>
            </div>
        </div>
        <div class="uitk-l-grid__row">
            <label uitkLabel id="itemInfolbl" style="width: 172px;">Item Info: </label>
            <div class="uitk-l-grid__col">
                <div style="padding: 0px 20px 0px 20px;">
                    <table uitkTable class="uitk-c-table" aria-describedby="return-data" id="return-table" #rootTable="uitkTable" [dataSource]="this.orderService.returnDetails">
                        <thead class="uitk-c-table__head">
                            <tr>
                              <th>Item #</th>
                              <th>Quantity</th>
                              <th>Ship Date</th>
                              <th>Invoice #</th>
                              <th>$ Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *uitkTableRowRenderer="let orderDetailDto">
                              <ng-container *uitkTableRowContext>
                                <tr>
                                  <td>{{ orderDetailDto.productCode}}</td>
                                  <td>{{ orderDetailDto.orderQuantity }}</td>
                                  <td>{{ orderDetailDto.originalOrderDetailDto.shipDate | date }}</td>
                                  <td>{{ orderDetailDto.invoiceNumber }}</td>
                                  <td>{{ orderDetailDto.extendedPrice | currency }}</td>
                                </tr>
                              </ng-container>
                            </ng-container>
                          </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="uitk-l-grid__row">
            <label uitkLabel id="exceptionReasonlbl" style="width: 172px;">Exception Reason: </label>
            <div class="uitk-l-grid__col">
                <div style="padding: 0px 20px 0px 20px;">
                    <uitk-form-field>
                        <input uitkInput id="exceptionReason" name="exceptionReason" value="Other" [disabled]="true" />
                    </uitk-form-field>
                </div>
            </div>
        </div>
        <div class="uitk-l-grid__row">
            <label uitkLabel id="businessJustificationlbl" style="width: 172px;">Business Justification: <sup style="color: red;">*</sup></label>    
            <div class="uitk-l-grid__col">
                <div style="padding: 0px 20px 0px 20px;">
                    <uitk-form-field>
                        <textarea uitkInput id="businessJustification" name="businessJustification" aria-multiline="true" rows=6 
                        [(ngModel)]="this.orderService.returnAuthReason" placeholder="Business Justification"></textarea>
                    </uitk-form-field>
                </div>
            </div>
        </div>
        <br/>
        <p>Return / Authorization notes need to include information regarding why the return exception is being granted. As well as where the credit needs to 
            be applied.</p>
    </uitk-dialog-content>

    <uitk-dialog-actions>
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="save()" [disabled]="this.modeService.isWaiting || !this.orderService.returnAuthReason">
          Save
        </button>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeAddReturnAuthorizationDialog()" [disabled]="this.modeService.isWaiting" tabindex="0">
          Cancel
        </button>
    </uitk-dialog-actions>

</uitk-dialog>

<div class="divPopUp" *ngIf="showReturnsAuthPopUp">
    <app-returns-action [fromReturnEntry]="true"> </app-returns-action>
</div>
