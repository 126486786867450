<div *ngIf="openCashDtos.length > 0" class="d-flex justify-content-end">
  <h5 class="lblConfigSet d-flex justify-content-end"><strong>Remaining Deposit Allowed: {{ remainingDepositAllowed | currency }}</strong></h5>
</div>
<div *ngIf="openCashDtos.length === 0">
  <label uitkLabel>This account contains no open cash</label>
</div>
<div class="uitk-l-grid__row">
  <div class="uitk-l-grid__col" style="max-width: 1100px;">
    <div id="loadingSpinner" class="uitk-l-grid" *ngIf="loading">
      <uitk-loading-indicator uitkId="spinner" [visibleText]="visibleTextValue" spinnerSize="large">
      </uitk-loading-indicator>
    </div>
    <div *ngIf="!loading && openCashDtos.length > 0" class="uitk-c-table--responsive">
      <table uitkTable [dataSource]="openCashDtos" class="uitk-c-table" aria-describedby="invoiceNo">
        <thead class="uitk-c-table__head">
          <tr style="text-align: left">
            <th *ngFor="let headerName of tableHeader" scope="col">
              {{ headerName }}
            </th>
          </tr>
        </thead>
        <tbody class="uitk-c-table__body">
          <ng-container *uitkTableRowBody="let openCashDto">
            <tr *ngIf="openCashDto" class="uitk-c-table__row">
              <td>
                <uitk-form-field>
                  <input type="checkbox" class="checkBoxInGrid"
                         uitkCheckbox
                         name="selectRowCheckBox"
                         [(ngModel)]="openCashDto.isSelected" 
                         (change) = "enableSavebtn()" 
                         />
                </uitk-form-field>
              </td>
              <td style="text-align:center">{{ openCashDto.invoiceNumber }}</td>
              <td>{{ openCashDto.invoiceDate | date: 'MM/dd/yyyy' }}</td>
              <td>{{ openCashDto.orderId }}</td>
              <td>{{ openCashDto.referenceNumber }}</td>
              <td>{{ openCashDto.invoiceAmount |currency:'USD':'symbol':'1.2-2'}}</td>
              <td>{{ openCashDto.paidAmount |currency:'USD':'symbol':'1.2-2'}}</td>
              <td>{{ openCashDto.balance |currency:'USD':'symbol':'1.2-2'}}</td>
              <td>
                <uitk-form-field>
                  <input type="text" id="apply-{{ openCashDto.apply }}" 
                  uitkInput 
                  [(customModel)]="openCashDto.apply"
                    name="myApplyBasicInput"
                     style="width: 100px;" 
                     (change)="validateApplyDollar(openCashDto)" appPriceFormat />
                </uitk-form-field>              
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <uitk-form-field-error *ngIf="showApplyError">
        {{ applyDollarErrorText }}
      </uitk-form-field-error>
      <uitk-form-field-error *ngIf="showTotalApplyError">
        {{ totalApplyDollarErrortext }}
      </uitk-form-field-error>
    </div>
  </div>
</div>
