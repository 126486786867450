<div class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog uitkId="confirmationEmailDialog" [(showModal)]="_orderService.confirmationEmailDialog.show" [triggerElementId]="_orderService.shippingDialogModalTriggerEle"
  [closeBtn]="false" (onModalHidden)="afterConfirmationEmailPopupHides()">
    <uitk-dialog-header>
        <div cdkDragHandle>
            Confirmation Emails
        </div>
    </uitk-dialog-header>
    <uitk-dialog-content scrollToTarget>
        <label uitkLabel>Order Confirmation Emails</label>
        <ul uitkCheckboxGroup>
            <li>
                <p>Ordered by</p>
                <input id="orderOrderedByCheckbox" type="checkbox" uitkCheckbox [(ngModel)]="orderOrderedByCheckbox" />

                <label uitkCheckboxLabel for="orderOrderedByCheckbox"></label>
                <uitk-form-field id="orderOrderedByTextbox">
                    <uitk-form-field-hint>{{orderedByContactName}}</uitk-form-field-hint>
                    <input uitkInput class="textbox mx-1" class="textbox" type="email"
                        [(ngModel)]="orderedByContactEmail" disabled />
                </uitk-form-field>

            </li>
            <li>
                <p>Ship to</p>
                <input id="orderShipToCheckbox" type="checkbox" uitkCheckbox [(ngModel)]="orderShipToCheckbox"
                [disabled]="this.orderDto.billToDto.accountTypeCode === 'C' || this.orderDto.billToDto.accountTypeCode === 'H'" />                <label uitkCheckboxLabel for="orderShipToCheckbox" style="margin-top: unset;"></label>
                <uitk-form-field id="orderShipToTextbox">
                    <uitk-form-field-hint>{{shipToContactName}}</uitk-form-field-hint>
                    <input uitkInput class="textbox mx-1" class="textbox" type="email" [(ngModel)]="shipToContactEmail"
                        disabled />
                </uitk-form-field>
            </li>
            <li>
                <p>Logged in User</p>
                <input id="orderloggedInUserCheckbox" type="checkbox" uitkCheckbox [(ngModel)]="orderloggedInUserCheckbox"
                [disabled]="this.orderDto.billToDto.accountTypeCode === 'C' || this.orderDto.billToDto.accountTypeCode === 'H'" />                <label uitkCheckboxLabel for="orderloggedInUserCheckbox" style="margin-top: unset;"></label>
                <uitk-form-field id="orderloggedInUserTextbox">
                    <uitk-form-field-hint>{{loggedInUserName}}</uitk-form-field-hint>
                    <input uitkInput class="textbox mx-1" class="textbox" type="email" [(ngModel)]="loggedInUser"
                        disabled />
                </uitk-form-field>

            </li>

            <li>
                <p>Other</p>
                <input id="orderOtherCheckbox" type="checkbox" uitkCheckbox [(ngModel)]="orderOtherCheckbox"
                [disabled]="this.orderDto.billToDto.accountTypeCode === 'C' || this.orderDto.billToDto.accountTypeCode === 'H'" />
                <label uitkCheckboxLabel for="orderOtherCheckbox"></label>
                <uitk-form-field id="orderOtherTextbox">
                    <input uitkInput #orderOther="ngModel" class="textbox" type="email" [pattern]="emailPattern"
                        value="" [(ngModel)]="orderOtherEmail" (change)="setCheckbox()"
                        [disabled]="this.orderDto.billToDto.accountTypeCode === 'C' || this.orderDto.billToDto.accountTypeCode === 'H'" />                    <uitk-form-field-error *ngIf=" orderOther?.errors?.pattern && orderOther?.touched">
                        Invalid Email
                    </uitk-form-field-error>
                </uitk-form-field>

            </li>
        </ul>
        <label uitkLabel>Shipment Confirmation Emails</label>
        <ul uitkCheckboxGroup>
            <li>
                <p>Ordered by</p>
                <input id="shipmentOrderedByCheckbox" type="checkbox" uitkCheckbox
                    [(ngModel)]="shipmentOrderedByCheckbox" />
                <label uitkCheckboxLabel for="shipmentOrderedByCheckbox"></label>
                <uitk-form-field id="shipmentOrderedByTextbox">
                    <uitk-form-field-hint>{{orderedByContactName}}</uitk-form-field-hint>
                    <input uitkInput class="textbox mx-1" class="textbox" type="email"
                        [(ngModel)]="orderedByContactEmail" disabled />
                </uitk-form-field>

            </li>
            <li>
                <p>Ship to</p>
                <input id="shipmentShipToCheckbox" type="checkbox" uitkCheckbox [(ngModel)]="shipmentShipToCheckbox" />
                <label uitkCheckboxLabel for="shipmentShipToCheckbox" style="margin-top: unset;"></label>
                <uitk-form-field id="shipmentShipToTextbox">
                    <uitk-form-field-hint>{{shipToContactName}}</uitk-form-field-hint>
                    <input uitkInput class="textbox mx-1" class="textbox" type="email" [(ngModel)]="shipToContactEmail"
                        disabled />
                </uitk-form-field>
            </li>
            <li>
                <p>Logged in User</p>
                <input id="shiploggedInUserCheckbox" type="checkbox" uitkCheckbox [(ngModel)]="shiploggedInUserCheckbox"
                [disabled]="this.orderDto.billToDto.accountTypeCode === 'C' || this.orderDto.billToDto.accountTypeCode === 'H'" />                <label uitkCheckboxLabel for="orderloggedInUserCheckbox" style="margin-top: unset;"></label>
                <uitk-form-field id="shiploggedInUserTextbox">
                    <uitk-form-field-hint>{{loggedInUserName}}</uitk-form-field-hint>
                    <input uitkInput class="textbox mx-1" class="textbox" type="email" [(ngModel)]="shiploggedInUser"
                        disabled />
                </uitk-form-field>


            </li>
            <li>
                <p>Other</p>
                <input id="shipmentOtherCheckbox" type="checkbox" uitkCheckbox [(ngModel)]="shipmentOtherCheckbox" />
                <label uitkCheckboxLabel for="shipmentOtherCheckbox"></label>
                <uitk-form-field id="shipmentOtherTextbox">
                    <input uitkInput #shipmentOther="ngModel" class="textbox" type="email" [pattern]="emailPattern"
                        value="" [(ngModel)]="shipmentOtherEmail" (change)="setCheckbox()"/>
                    <uitk-form-field-error *ngIf="shipmentOther?.errors?.pattern && shipmentOther?.touched">
                        Invalid Email
                    </uitk-form-field-error>
                </uitk-form-field>
            </li>
        </ul>
    </uitk-dialog-content>
    <uitk-dialog-actions>
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="sendEmail()" appAutoFocus
            [disabled]="orderOther?.errors?.pattern || shipmentOther?.errors?.pattern">
            OK
        </button>
    </uitk-dialog-actions>
</uitk-dialog>
    </div>
</div>
