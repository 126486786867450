import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UITKTableDataSource } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Mode } from 'src/app/enums/mode.enum';
import { ModeService } from 'src/app/services/mode.service';
import { ConfigService } from '../../../../services/config.service';
import { OrderService } from 'src/app/services/order.service';
import { PickListDto } from 'src/app/dtos/pick-list-dto.model';

@Component({
  selector: 'app-sales-person-lookup-popup',
  templateUrl: './sales-person-lookup-popup.component.html',
  styleUrls: ['./sales-person-lookup-popup.component.css']
})

export class SalesPersonLookupPopupComponent implements OnInit {
  private readonly _configService: ConfigService;
  private readonly configIsReadySubscription!: Subscription;
  @Input() dialog = { show: false };
  @Output() selectedSalesPerson = new EventEmitter<string>();
  tableHeader = ['Name'];
  salesPerson = '';
  salesPersonData = new UITKTableDataSource<any>([]);
  usersList: string[] = [];
  userList: PickListDto[] = [];
  isDataFound = false;
  salesPersonSearchKeyword = '';
  _orderService: OrderService;
  salesPersonsObject:any;
  constructor(configService: ConfigService,public modeService: ModeService,public orderService:OrderService) {
    this._configService = configService;
    this._orderService = orderService;
  }

  ngOnInit(): void {
    this.usersList = this._configService.getPickList('SALESPERSON').map(u => u.label);
    this.salesPersonsObject = this._configService.getPickList('SALESPERSON');
    if(this._orderService.searchsalesPersonName !== null && this._orderService.searchsalesPersonName !== undefined && this._orderService.searchsalesPersonName.trim() !== ''){
      this.salesPersonSearchKeyword = this._orderService.searchsalesPersonName;
      this.showSalesPersonDetails();
    }
    this.orderService.recalculateFields(this.orderService.orderDto);
  }

  closeModal(){
    this.modeService.mode = Mode.Edit;
  }

  showSalesPersonDetails(): void {
    this.isDataFound = false;
    let salesPerson = this.usersList.find(u => u == this.salesPersonSearchKeyword);
    if(salesPerson !== undefined){
      this.orderService.orderDto.selectedsalesPersonName = salesPerson;
      this.dialog.show = false;
    }
    else{
     const salesPersons = this.usersList.filter(u => {
       if (u.toLowerCase().indexOf(this.salesPersonSearchKeyword.toLowerCase()) !== -1 && u.valueOf()) {
        return true;
       }
      else {
       this.isDataFound = true;
       return false;
      }
     });

      this.salesPersonData.data = this.salesPersonSearchKeyword ? [...salesPersons].sort((a, b) => {
        return 0 - (a > b ? -1 : 1);
      }) : [];
    }
    if(!this.salesPersonSearchKeyword) {
      this.salesPersonSearchKeyword='';
    }
  }

  selectSalesPerson(salesPerson: any): void {
    if (salesPerson && salesPerson.target.innerHTML.length > 0)
    {
    this.selectedSalesPerson.emit(salesPerson.target.innerHTML);
    this.dialog.show = false;
    this.orderService.orderDto.selectedsalesPersonName = salesPerson.target.innerHTML;
    let userCode = this.salesPersonsObject.filter((x:any)=> x.label == salesPerson.target.innerHTML)[0].value;
    this.orderService.orderDto.salesPersonEmail=this._configService.getUserEmailFromUserCode(userCode);
    }
  }
  ngOnDestroy(): void {
    //this.configIsReadySubscription.unsubscribe();
  }
}

