import { Component, Input } from '@angular/core';
import { Dialog } from '../../helpers/dialog';

@Component({
  selector: 'app-update-invoices-from-order-dialog',
  templateUrl: './update-invoices-from-order-dialog.component.html',
  styleUrls: ['./update-invoices-from-order-dialog.component.css']
})
export class UpdateInvoicesFromOrderDialogComponent {
  @Input() dialog!: Dialog<string>;

  clickYes(): void {
    this.dialog.close('Yes');
  }

  clickNo(): void {
    this.dialog.close('No');
  }

}
