<div *ngIf="orderService.cfdFormFields">
  <div class="row">
    <div class="col-12 d-flex">
      <strong>What fields do you want to display?</strong>
    </div>
  </div>
  <div class="row">
    <div class=col-2 d-flex>
      <uitk-form-field>
        <input uitkCheckbox id="itemNumber" type="checkbox" [(ngModel)]="orderService.cfdFormFields!.itemNumberCheckbox"  [disabled] = true/>
        <label uitkCheckboxLabel id="itemNumberLabel" for="itemNumber">
          Item #
        </label>
      </uitk-form-field>
    </div>
    <div class=col-2 d-flex>
      <uitk-form-field>
        <input uitkCheckbox id="itemDescription" type="checkbox" [(ngModel)]="orderService.cfdFormFields!.itemDescriptionCheckbox" [disabled] = true/>
        <label uitkCheckboxLabel id="itemDescriptionLabel" for="itemDescription">
          Item Description
        </label>
      </uitk-form-field>
    </div>
    <div class=col-2 d-flex>
      <uitk-form-field>
        <input uitkCheckbox id="listPrice" type="checkbox" [(ngModel)]="orderService.cfdFormFields!.listPriceCheckbox" />
        <label uitkCheckboxLabel id="listPriceLabel" for="listPrice">
          List Price
        </label>
      </uitk-form-field>
    </div>
    <div class=col-2 d-flex>
      <uitk-form-field>
        <input uitkCheckbox id="discountPercentage" type="checkbox" [(ngModel)]="orderService.cfdFormFields!.discountPercentageCheckbox" />
        <label uitkCheckboxLabel id="discountPercentageLabel" for="discountPercentage">
          Discount %
        </label>
      </uitk-form-field>
    </div>
    <div class=col-2 d-flex>
      <uitk-form-field>
        <input uitkCheckbox id="yourPrice" type="checkbox" [(ngModel)]="orderService.cfdFormFields!.yourPriceCheckbox" [disabled] = true />
        <label uitkCheckboxLabel id="yourPriceLabel" for="itemNuyourPricember">
          Your Price
        </label>
      </uitk-form-field>
    </div>
  </div>
  <div class="row">
    <div class=col-2 d-flex>
      <uitk-form-field>
        <input uitkCheckbox id="availDate" type="checkbox" [(ngModel)]="orderService.cfdFormFields!.availDateCheckbox" />
        <label uitkCheckboxLabel id="availDateLabel" for="availDate">
          Avail. Date
        </label>
      </uitk-form-field>
    </div>
    <div class="col-2 d-flex">
      <uitk-form-field>
        <input uitkCheckbox id="quantity" type="checkbox" [(ngModel)]="orderService.cfdFormFields!.quantityCheckbox" [disabled] = true />
        <label uitkCheckboxLabel id="quantityLabel" for="quantity">
          Quantity
        </label>
      </uitk-form-field>
    </div>
    <div class="col-2 d-flex">
      <uitk-form-field>
        <input uitkCheckbox id="total" type="checkbox" [(ngModel)]="orderService.cfdFormFields!.totalCheckbox" [disabled] = true />
        <label uitkCheckboxLabel id="totalLabel" for="total">
          Total
        </label>
      </uitk-form-field>
    </div>
    <div class="col-2 d-flex" *ngIf = "showRenewalDateCheckBox">
      <uitk-form-field>
        <input uitkCheckbox id="renewalDate" type="checkbox" [(ngModel)]="orderService.cfdFormFields!.renewalDateCheckbox"/>
        <label uitkCheckboxLabel id="renewalDateLabel" for="renewalDate">
          Renewal Date
        </label>
      </uitk-form-field>
    </div>
    <div class="col-3 d-flex">
      <uitk-form-field>
        <input uitkCheckbox id="validupto-chkbox" type="checkbox" [(ngModel)]="orderService.cfdFormFields!.validUptoCheckbox" />
        <label uitkCheckboxLabel id="Validlabel" for="validupto-chkbox">
          Valid for
        </label>
      </uitk-form-field>
      <input style="width: 20% !important;margin-left: 0.5rem;margin-right: 0.5rem;" uitkInput max="180" id="validupto" type="number" (change)="onValidUptoChange()" [(ngModel)]="orderService.validUpto" />
      <span style="margin: auto 0px;"> Days</span>
    </div>
  </div>
  </div>
