import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})

export class AuthGuard implements CanActivate {
  private readonly _authenticationService: AuthenticationService;

  constructor(authenticationService: AuthenticationService) {
    this._authenticationService = authenticationService;
  }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserValue;
    localStorage.setItem('returnUrl', state.url);

    if (currentUser || localStorage.getItem('isByPass') === 'true') {
      return true;
    }

    this._authenticationService.login();
    return false;
  }
}
