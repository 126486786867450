import { Component, OnInit, ViewChild } from '@angular/core';
import { IUITKColumnState, UITKSortDirection, UITKTableDataSource, UITKTableSortDirective } from '@uitk/angular';
import { MaintenanceService } from '../../services/maintenance.service';
import { Dialog } from '../../helpers/dialog';
import { SourceCodeDto } from '../../dtos/source-code-dto.model';
import { SwalAlert } from '../../helpers/alert';

@Component({
  selector: 'app-source-code',
  templateUrl: './source-code.component.html',
  styleUrls: ['./source-code.component.css']
})
export class SourceCodeComponent implements OnInit {

  readonly _maintenanceService: MaintenanceService;
  tableHeader = [
    { name: 'Source Code', id: 'sourceCode' },
    { name: 'Description', id: 'description' },
    { name: 'GL Code', id: 'glCode' },
    { name: 'Status Code', id: 'statusCode' },
  ];
  sourceCodeList = new UITKTableDataSource<SourceCodeDto>([]);
  initialData: SourceCodeDto[] = [];
  sortColumn = 'sourceCode';
  sortDirection = UITKSortDirection.ASC;
  @ViewChild('sortTable') uitkTableSort!: UITKTableSortDirective;

  editSourceCodePopup = new Dialog<SourceCodeDto | null>();
  deleteSourceCodePopup = new Dialog<SourceCodeDto | null>();
  sourceCode : SourceCodeDto = new SourceCodeDto();
  swalAlert = new SwalAlert();
  filterValue = '';
  displayList = false;

  constructor(maintenanceService: MaintenanceService) {
    this._maintenanceService = maintenanceService;
  }

  ngOnInit(): void {
    //
  }

  onSortChange(event: IUITKColumnState) {
    this.sortColumn = event.column;
    this.sortDirection = event.direction;
    this.sourceCodeList.data = this.sourceCodeList.sortData(this.sourceCodeList.data, event.direction, event.column);
  }

  async searchSourceCode(filterValue: string){
    this.sourceCodeList.data = await this._maintenanceService.getSourceCodes(filterValue);
    this.displayList = true;
    if(this.sourceCodeList.data.length === 0){
      await this.swalAlert.alert("You have entered an invalid Source Code. Please correct.");
    }
    else{
      this.sourceCodeList.data = this.sourceCodeList.sortData(this.sourceCodeList.data, this.sortDirection, this.sortColumn);
    }
  }

  async openEditSourceCodePopup(sourceCode: SourceCodeDto): Promise<void> {
    this.sourceCode = sourceCode;
    const result = await this.editSourceCodePopup.openAsync();
    this.updateSOurceCodeList(result);
  }

  async openDeleteSourceCodePopup(sourceCode: SourceCodeDto): Promise<void> {
    this.sourceCode = sourceCode;
    const result = await this.deleteSourceCodePopup.openAsync();
    this.updateSOurceCodeList(result);
  }

  updateSOurceCodeList(result: SourceCodeDto | null): void{
    if (result !== null) {
      let index = this.sourceCodeList.data.findIndex(s => s.id === result.id);
      this.sourceCodeList.data[index] = result;
      this.sourceCodeList.data = [...this.sourceCodeList.data];
    }
    this.sourceCode = new SourceCodeDto();
  }
}
