export class SubscriptionDto {
    id = 0;
    contactId = 0;
    productId = 0;
    productCode = '';
    subscriptionNumber = 0;
    subscriptionType = '';
    subscriptionStatus = '';
    subscriptionQuantity = 0;
    startDate = new Date();
    term = 0;
    wapNumber : string | null = null;
    timesRenewed = 0;
    sendUpdatesOnly = false;
    sendRenewalNotices = true;
    sendUpdates = false;
    emailUpdates = false;
    inUseBy : string | null = null;
    trialOrderHeaderId: number | null = null;
    renewalSubscriptionId:  number | null = null;
    subscriptionOrderId = 0;
    // These special properties are used for binding to the UI components
    isSelected = false;
    expireDate = new Date();
}
