<div class = "overlayForPopups" *ngIf="templateUrl.show">
  <div cdkDrag>
<uitk-dialog uitkId="showSelectedTemplateEmail" [(showModal)]="templateUrl.show" [closeBtn]="false">
  <uitk-dialog-header style="width: 117%;">
    <div cdkDragHandle>
      <uitk-banner-notification [show]="isNotificationVisible" uitkId="emailNotification" [variant]="notificationVariant">
        <h1 uitkBannerHeading>{{ emailNotificationMessage }}</h1>
      </uitk-banner-notification>
      <div style="border-bottom: 1px solid; text-align: center; background-color: #f2f2f2;">
        <label style=" font-size: 20px;">{{accountsReceivableService.headerForTemplate}}</label>
      </div>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div class="row mx-3">
      <div class="row mt-2">
        <label class="col-lg-3 align-self-center" uitkLabel style="padding-left:2px">From: </label>
        <div class="col-lg-9">
          <uitk-form-field>
            <input uitkInput id="emailfrom" class="textbox" type="text" [(ngModel)]="loggedInUserEmail" style="margin-left: 2%;
                        width: 107%;" />
          </uitk-form-field>
        </div>
      </div>
    </div>

    <div class="container-fluid" [hidden]="modeService.isWaiting">
      <div class="row">
        <div class="col">
          <uitk-form-field>
            <input id="bill-to-contact-checkbox" type="checkbox" [(ngModel)]="billToContactcheckbox" uitkCheckbox
              (change)="changeEmail()" [disabled]="disableBillTo" />
            <label uitkCheckboxLabel for="bill-to-contact-checkbox">Bill to Contact</label>
          </uitk-form-field>
        </div>
        <div class="col">
          <uitk-form-field>
            <input id="order-by-contact-checkbox" type="checkbox" [(ngModel)]="orderByContactcheckbox" uitkCheckbox
              (change)="changeEmail()" [disabled]="disableOrderBy" />
            <label uitkCheckboxLabel for="order-by-contact-checkbox">Order by Contact</label>
          </uitk-form-field>
        </div>
        <div class="col">
          <uitk-form-field>
            <input id="ship-to-contact-checkbox" type="checkbox" [(ngModel)]="shipToContactcheckbox" uitkCheckbox
              (change)="changeEmail()" [disabled]="disableShipTo" />
            <label uitkCheckboxLabel for="ship-to-contact-checkbox">Ship to Contact</label>
          </uitk-form-field>
        </div>

        <div class="col">
          <uitk-form-field>
            <input id="logged-in-User-checkbox" type="checkbox" [(ngModel)]="LoggedInUsercheckbox" uitkCheckbox
              (change)="changeEmail()" />
            <label uitkCheckboxLabel for="logged-in-User-checkbox">Logged in User</label>
          </uitk-form-field>
        </div>
      </div>
      <!-- options -->
      <div class="row mt-2">

      </div>
      <div class="row mt-2">
        <label class="col-lg-3 align-self-center" uitkLabel>TO Recipients: </label>
        <div class="col-lg-9">
          <uitk-form-field id="toRecipients">
            <input uitkInput class="textbox" type="email" [(ngModel)]="email" #userEmail="ngModel"
              [pattern]="emailPattern" value="" #userEmail1 />
          </uitk-form-field>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-lg-3 align-self-center" uitkLabel>CC Recipients: </label>
        <div class="col-lg-9">
          <uitk-form-field id="ccRecipients">
            <input uitkInput class="textbox" type="email" [(ngModel)]="CCrecipientsloggedInUserEmail" />
          </uitk-form-field>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-lg-3 align-self-center" uitkLabel>Subject: </label>
        <div class="col-lg-9">
          <uitk-form-field>
            <input uitkInput id="emailsubject" class="textbox" type="text" value=""
              [(ngModel)]="accountsReceivableService.EmailSubject" />
          </uitk-form-field>
        </div>
      </div>
      <div class="row mt-2">
        <label class="col-lg-3 align-self-center" uitkLabel>Attachments: </label>
        <div class="col-lg-9">
          <a class="link" target="_blank" style="color:#316bbe;"><strong style="margin-left: -1%;">PDF (click to
              view)</strong></a>
        </div>
      </div>
      <div class="row mt-4">

        <label class="col-lg-3 align-self-center" uitkLabel>Email Body: </label>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div contenteditable="true" style="border-style: solid; border-width: 0.5px; box-shadow: none; border-color: gray; height:22vh; overflow: auto; padding: 10px;" (blur)="onEmailBodyChange($event)" innerHTML="{{accountsReceivableService.EmailBody}}"></div>
      </div>
        
      </div>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions style="width: fit-content;">
    <div class="mx-4" style="margin-bottom: 20px;">
      <button id="back" type="button"
        class="uitk-c-button uitk-c-button--alternative-ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
        style="margin-left:-12px;" (click)="cancelDialog()">Back</button>

      <button id="sendEmail" type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
        style="margin-left: 587px;" [disabled]="modeService.isWaiting" (click)="sendPdf()" appAutoFocus>Send
        Email</button>
    </div>
  </uitk-dialog-actions>


</uitk-dialog>
  </div>
</div>