import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Mode } from '../../enums/mode.enum';
import { OrderSearchInputDto } from '../../dtos/order-search-input-dto.model';
import { OrderSearchResult } from '../../dtos/order-search-result.model';
import { OrderSearchResultsDto } from '../../dtos/order-search-results-dto.model';
import { ConfigService } from '../../services/config.service';
import { ModeService } from '../../services/mode.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-search-navigation-bar',
  templateUrl: './search-navigation-bar.component.html',
  styleUrls: ['./search-navigation-bar.component.css']
})
  
export class SearchNavigationBarComponent implements OnInit {
  index = 0;
  currentIndex = 0;
  totalRecords = 0;
  totalCountResult = 0;
  isNext = false;
  isPrev = false;
  tempOrderSearchResults: OrderSearchResult[] = [];
  usable = false;
  currentOrderIndex = 0;
  showBackToSearchResults = true;
  lastSelectedId = 0;

  configIsReadySubscription: Subscription | undefined;
  Mode = Mode;

  constructor(
    public readonly modeService: ModeService,
    private readonly configService: ConfigService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly orderService: OrderService,
    private readonly router: Router,
    private readonly httpClient: HttpClient,
    private readonly titleService: Title
  ) {
  }

  ngOnInit(): void {
    this.configIsReadySubscription = this.configService.configIsReady.subscribe(() => {

      const navigateOrigin = localStorage.getItem('navigateOrigin') ?? 'orderSearch';

      let selectedId = 0;
      
      if (this.activatedRoute.snapshot.routeConfig?.path?.includes('return')) {
        selectedId = this.activatedRoute.snapshot.params.returnId;
      } else if (this.activatedRoute.snapshot.routeConfig?.path?.includes('order-entry')) {
        selectedId = this.activatedRoute.snapshot.queryParams["orderId"];
  
      } else {
        selectedId = this.activatedRoute.snapshot.params.orderId;
      }
      if (this.lastSelectedId === selectedId) {
        return;
      } else {
        this.lastSelectedId = selectedId;
      }

      if (navigateOrigin === 'worklistQueue') {
        const navigateOrderList = JSON.parse(localStorage.getItem('navigateOrderList') as string) as number[];
        const navigatePosition = +(localStorage.getItem('navigatePosition') as string);
        this.isPrev = navigatePosition === 0;
        this.isNext = navigatePosition === navigateOrderList.length - 1;
        this.currentIndex = navigatePosition + 1;
        this.totalRecords = navigateOrderList.length;


        if(navigateOrderList[navigatePosition] === selectedId)
        {
          this.orderService.getOrderInformation(navigateOrderList[navigatePosition]);
        }
        else
        {
            const index: number = navigateOrderList.findIndex(o => o === +selectedId);
            this.currentIndex = index + 1;
            if(Number(this.currentIndex) === 0)
            {
              this.isPrev = true;
              this.isNext = false;
            }
            else
            {
              this.isPrev = this.currentIndex-1 === 0;
              this.isNext = this.currentIndex-1 === navigateOrderList.length - 1;
            }
            this.orderService.getOrderInformation(selectedId);
        }

      } else {
        this.getCurrentIndex(selectedId);

        if (Object.keys(localStorage).indexOf('searchOrderResults') > -1) {
          this.totalCountResult = JSON.parse(localStorage.getItem('searchOrderResults') as any).totalRecords;
          this.tempOrderSearchResults = JSON.parse(localStorage.getItem('searchOrderResults') as any).orderSearchResults;
        }
  
        if(this.activatedRoute.snapshot.url[1]?.path !== 'snapshot'){
          this.orderService.getOrderInformation(selectedId);
        }
        if (this.activatedRoute.snapshot.routeConfig?.path?.includes('return')) {   
          this.titleService.setTitle("Return: " + selectedId);
        } else {
          const orderId = this.activatedRoute.snapshot.queryParams.orderId ? this.activatedRoute.snapshot.queryParams.orderId :
            this.activatedRoute.snapshot.params.orderId;
          this.titleService.setTitle("Order: " + orderId);
 
        }

        this.currentOrderIndex = this.currentIndex || 0;

        if (Object.keys(localStorage).indexOf('searchOperator') > -1) {
          let searchOperator: any = localStorage.getItem('searchOperator');
          searchOperator = JSON.parse(searchOperator);

          if (this.currentOrderIndex === 0) {
            const index: number = this.tempOrderSearchResults.findIndex(o => o.orderNumber === Number(selectedId));
            this.currentOrderIndex = Number(searchOperator.pageSize) * (Number(searchOperator.pageNumber) - 1) + index + 1;
          }
        }

        this.isPrev = this.currentOrderIndex <= 1;
        this.isNext = this.currentOrderIndex === this.totalCountResult;

        localStorage.setItem('backToSearchResults', 'false');

        if (Object.keys(localStorage).indexOf('searchOperator') > -1) {
          const searchFilters: any = JSON.parse(localStorage.getItem('searchOperator')!);

          if (searchFilters.filters.length === 0) {
            this.showBackToSearchResults = false;
          }
        }
        else {
          this.showBackToSearchResults = false;
        }
      }
    });

    // This reloads in order-inquiry
    this.activatedRoute.params.subscribe(() => {
      this.configService.loadConfigurations();
    });

    // This reloads in order-entry
    this.activatedRoute.queryParams.subscribe(() => {
      this.configService.loadConfigurations();
    });

  }

  getCurrentIndex(val: number) {
    const selectedOrderId = val;
    const searchOrderResultsJson = localStorage.getItem('searchOrderResults');
    if (searchOrderResultsJson) {
      const orderSearchResults: OrderSearchResult[] = JSON.parse(searchOrderResultsJson).orderSearchResults;
      this.totalRecords = JSON.parse(searchOrderResultsJson).totalRecords;
      const searchOperatorString = localStorage.getItem('searchOperator') as string;
      const searchOperator = JSON.parse(searchOperatorString) as OrderSearchInputDto;
      this.index = orderSearchResults.findIndex(o => o.orderNumber === Number(selectedOrderId));

      if (orderSearchResults.length <= searchOperator.pageSize) {
        this.currentIndex = ((searchOperator.pageNumber - 1) * searchOperator.pageSize) + this.index + 1;
      }
      else {
        this.currentIndex = (searchOperator.pageNumber * searchOperator.pageSize) - (orderSearchResults.length - this.index) + 1;
      }
    }
    else{
      this.totalRecords = 1;
      this.currentIndex = 1;
      this.isNext = true;
    }
  }

  NextClick() {
    this.configService.resetIdleTimeout();
    const navigateOrigin = localStorage.getItem('navigateOrigin') ?? 'orderSearch';
    if (navigateOrigin === 'worklistQueue') {
      const navigateOrderList = JSON.parse(localStorage.getItem('navigateOrderList') as string) as number[];
      let navigatePosition = +(localStorage.getItem('navigatePosition') as string);
      navigatePosition++;
      const orderId = navigateOrderList[navigatePosition];
      localStorage.setItem('navigatePosition', (navigatePosition).toString());
      this.currentIndex = navigatePosition + 1;
      this.router.navigate(['/order', orderId]);
    } else {
      this.isPrev = false;
      this.currentOrderIndex = this.currentIndex;
      this.totalCountResult = JSON.parse(localStorage.getItem('searchOrderResults') as any).totalRecords;
      this.tempOrderSearchResults = JSON.parse(localStorage.getItem('searchOrderResults') as any).orderSearchResults;
      let searchOperator: any = localStorage.getItem('searchOperator');
      searchOperator = JSON.parse(searchOperator);
      const pageData: number = Number(searchOperator.pageNumber) * Number(searchOperator.pageSize);
      let isGreater: boolean = false;
      isGreater = this.currentOrderIndex >= pageData ? true : false;
      let isRouting: boolean = true;

      if (isGreater) {
        isRouting = false;
        searchOperator.pageNumber = Number(searchOperator.pageNumber) + 1;
        localStorage.setItem('searchOperator', JSON.stringify(searchOperator));

        this.httpClient.post<OrderSearchResultsDto>(`${this.configService.apiUrl}/Order/SearchOrders`, searchOperator)
          .subscribe(data => {
            localStorage.setItem('searchOrderResults', JSON.stringify(data));
            this.tempOrderSearchResults = JSON.parse(JSON.stringify(data)).orderSearchResults;
            isRouting = true;
            const orderNumber: number = this.tempOrderSearchResults[0].orderNumber || 0;
            const orderType = this.tempOrderSearchResults[0].orderType || ' ';
            const orderStatus = this.tempOrderSearchResults[0].orderStatus || ' ';
            if (orderType === 'Q' || orderStatus === 'P' || orderStatus === 'D' || orderStatus === 'M' || orderStatus === 'R') {
              this.navigateToOrderEntry(orderNumber);
            } 
            else if (orderType === ' ' || orderType === 'O' || orderType === 'E') {
              this.router.navigateByUrl('/order/' + orderNumber)
            } else {
              this.router.navigateByUrl('/return/' + orderNumber)
            }

            this.getCurrentIndex(orderNumber);
          });
      }

      if (isRouting) {
        const orderNumber: number = this.tempOrderSearchResults[this.currentOrderIndex - (Number(searchOperator.pageSize) *
          (Number(searchOperator.pageNumber) - 1))].orderNumber || 0;
        const orderType = this.tempOrderSearchResults[this.currentOrderIndex - (Number(searchOperator.pageSize) * (Number(searchOperator.pageNumber) - 1))].orderType || ' ';
        const orderStatus = this.tempOrderSearchResults[this.currentOrderIndex - (Number(searchOperator.pageSize) * (Number(searchOperator.pageNumber) - 1))].orderStatus || ' ';
        if (orderType === 'Q' || orderStatus === 'P' || orderStatus === 'D' || orderStatus === 'M' || orderStatus === 'R') {
          this.navigateToOrderEntry(orderNumber);
        }
        else if (orderType === ' ' || orderType === 'O' || orderType === 'E') {
          this.router.navigateByUrl('/order/' + orderNumber)
        } else {
          this.router.navigateByUrl('/return/' + orderNumber)
        }

        this.getCurrentIndex(orderNumber);
      }

      if (this.currentOrderIndex === this.totalCountResult) {
        this.isPrev = false;
        this.isNext = true;
      }
    }
  }
  navigateToOrderEntry(orderNumber:any){
    this.router.navigateByUrl('/order-entry?orderId=' + orderNumber);
  }

  PrevClick() {
    this.configService.resetIdleTimeout();
    const navigateOrigin = localStorage.getItem('navigateOrigin') ?? 'orderSearch';
    if (navigateOrigin === 'worklistQueue') {
      const navigateOrderList = JSON.parse(localStorage.getItem('navigateOrderList') as string) as number[];
      let navigatePosition = +(localStorage.getItem('navigatePosition') as string);
      navigatePosition--;
      const orderId = navigateOrderList[navigatePosition];
      localStorage.setItem('navigatePosition', (navigatePosition).toString());
      this.currentIndex = navigatePosition + 1;
      this.router.navigate(['/order', orderId]);
    } else {
      this.isNext = false;
      this.currentOrderIndex = Number(this.currentIndex);
      this.totalCountResult = JSON.parse(localStorage.getItem('searchOrderResults') as any).totalRecords;
      this.tempOrderSearchResults = JSON.parse(localStorage.getItem('searchOrderResults') as any).orderSearchResults;
      let searchOperator: any = localStorage.getItem('searchOperator');
      searchOperator = JSON.parse(searchOperator);
      const pageData: number = Number(searchOperator.pageNumber - 1) * Number(searchOperator.pageSize);
      let isLesser: boolean = false;
      let isRouting: boolean = true;
      isLesser = this.currentOrderIndex <= (pageData + 1) ? true : false;

      if (isLesser) {
        isRouting = false;
        searchOperator.pageNumber = Number(searchOperator.pageNumber) - 1 === 0 ? 1 : Number(searchOperator.pageNumber) - 1;
        localStorage.setItem('searchOperator', JSON.stringify(searchOperator));

        this.httpClient.post<OrderSearchResultsDto>(`${this.configService.apiUrl}/Order/SearchOrders`, searchOperator)
          .subscribe(data => {
            localStorage.setItem('searchOrderResults', JSON.stringify(data));
            this.tempOrderSearchResults = JSON.parse(JSON.stringify(data)).orderSearchResults;
            isRouting = true;
            const orderNumber: number = this.tempOrderSearchResults[this.tempOrderSearchResults.length - 1].orderNumber || 0;
            const orderType = this.tempOrderSearchResults[this.tempOrderSearchResults.length - 1].orderType || ' ';
            const orderStatus = this.tempOrderSearchResults[this.tempOrderSearchResults.length - 1].orderStatus || ' ';
            if (orderType === 'Q' || orderStatus === 'P' || orderStatus === 'D' || orderStatus === 'M' || orderStatus === 'R') {
              this.navigateToOrderEntry(orderNumber);
            }
            else if (orderType === ' ' || orderType === 'O' || orderType === 'E') {
              this.router.navigateByUrl('/order/' + orderNumber)
            } else {
              this.router.navigateByUrl('/return/' + orderNumber)
            }

            this.getCurrentIndex(orderNumber);
          });
      }

      if (isRouting) {
        const orderNumber: number = this.tempOrderSearchResults[(this.currentOrderIndex - 2) - (Number(searchOperator.pageSize) *
          (Number(searchOperator.pageNumber) - 1))].orderNumber || 0;
        const orderType = this.tempOrderSearchResults[(this.currentOrderIndex - 2) - (Number(searchOperator.pageSize) *
          (Number(searchOperator.pageNumber) - 1))].orderType || ' ';
        const orderStatus = this.tempOrderSearchResults[(this.currentOrderIndex - 2) - (Number(searchOperator.pageSize) *
          (Number(searchOperator.pageNumber) - 1))].orderStatus || ' ';

        if (orderType === 'Q' || orderStatus === 'P' || orderStatus === 'D' || orderStatus === 'M' || orderStatus === 'R') {
          this.navigateToOrderEntry(orderNumber);
        }
        else if (orderType === ' ' || orderType === 'O' || orderType === 'E') {
          this.router.navigateByUrl('/order/' + orderNumber)
        } else {
          this.router.navigateByUrl('/return/' + orderNumber)
        }

        this.getCurrentIndex(orderNumber);
      }

      if (this.currentOrderIndex === 2) {
        this.isPrev = true;
        this.isNext = false;
      }
    }
  }

  backToSearchResults() {
    const navigateOrigin = localStorage.getItem('navigateOrigin') ?? 'orderSearch';
    if (navigateOrigin === 'worklistQueue') {
      this.router.navigate(['/worklistqueue']);
    } else {
      localStorage.setItem('backToSearchResults', 'true');

      if (this.configService.getMyFullName() === 'Automated User') {
        this.router.navigate(['/bypass']);
      }

      this.router.navigate(['/']);
    }
  }

  ngOnDestroy() {
    this.configIsReadySubscription?.unsubscribe();
  }
}
