export enum SourceType {
  OrderInquiry,
  OrderEntry,
  OrderInquirySave,
  OrderEntrySave,
  OrderEntrySaveAsDraft,
  OrderEntrySaveAndClone,
  OrderEntryCalculate,
  OrderInquiryCalculateTax,
  OrderEntryShipTozipChanged,
  OrderInquiryShipTozipChanged,
  OrderByContactChange,
  OrderByAccountChange,
  ShipToContactChange,
  OrderEntryMutliyear
}
