import { ConfigSetDto } from './config-set-dto.model';
import { MultiUserPricingDto } from './multi-user-pricing.model';

export class ProductDto {
  id = 0;
  productCode = '';
  taxClassCode = '';
  description = '';
  listPrice = 0;
  isElectronic = false;
  isPhysical = false;
  isShipAndProcess = false;
  statusCode = '';
  isMultiUser = false;
  classCode = '';
  allowTrial = true;
  glCode: string | null = null;
  printVendorCost = 0;
  allowedPrice = 0;
  floorPrice = 0;
  allowCustomPrice = false;
  allowCustomStandard = false;
  hasElectronicUpdates = false;
  emailAlert = false;
  hasMaxQuantityOne = false;
  acronym = '';
  salesOrderNote = '';
  showSalesOrderNote = false;
  defaultSubscriptionTerm = 12;
  subscriptionMultiplier = 0;
  inventoryType = '';
  requiresReview = false;
  requiresProfileForm = false;
  isDownloadable = false;
  isWebFulfilled = false;
  chegSetOptionType: string | null = null;
  multiUserPricingDtos: MultiUserPricingDto[] = [];
  configSetDtos: ConfigSetDto[] = [];
  onHand = 0;
  pendingQuantity = 0;
  allocatedQuantity = 0;
  availableDate: Date | null = null;
  availableText = '';
  reorderPoint = 0;
  isOutOfStock = false;
  outOfStockNote = "";
  maxDiscountPercent = 0.0;
  editionYear: number | null = null;
  isSubscription = false;
  productGroupCode = '';
  allowMultiYear = false;
  allowPaymentPlan = false;
  exemptShipping = true;
  requiresAmaKey = false;
  unitWeight = 0;
  emailTypeCode: string | null = null;
  onlineType: string | null = null;
  fulfillmentTypeCode:string | null = null;
  isbn:string | null = null;
  salesCost = 0;
  isLicense = false;
  excludeApprovals = false;
  salesOrganization = '';
}
