import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Mode } from '../../enums/mode.enum';
import { ProductDto } from '../../dtos/product-dto.model';
import { ConfigService } from '../../services/config.service';
import { ModeService } from '../../services/mode.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-item-search',
  templateUrl: './item-search.component.html',
  styleUrls: ['./item-search.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})

export class ItemSearchComponent implements OnInit {
  private readonly _configService: ConfigService;
  @Input('dialog') dialog = { show: false };
  @Output() productAdded: EventEmitter<number> = new EventEmitter();
  Mode = Mode;
  ProductsList: ProductDto[] = [];
  ItemSearchDialog = { show: false };
  showResultsGrid = { show: false };
  defaultLabel = '-Please Select';
  defaultLabelFlag = false;
  tableHeader = [
    { name: 'Item #', id: 'userName' },
    { name: 'Item Description', id: 'changeDate' },
    { name: 'List Price', id: 'oldValue' },
    { name: 'Floor Price', id: 'newValue' },
    { name: 'Sales Cost', id: 'newValue' },
    { name: 'On Hand Qty', id: 'newValue' },
    { name: 'Available Date', id: 'newValue' },
    { name: 'ISBN', id: 'newValue' },
    { name: 'Edition Yr', id: 'newValue' },
    { name: 'Acronym', id: 'newValue' }
  ];
  acronymList: IUITKSelectItemProps[] = [];
  editionYrList: IUITKSelectItemProps[] = [];
  editionYr: IUITKSelectItemProps | null = null;
  acronym: IUITKSelectItemProps | null = null;
  itemNumber: string = '';
  isbn: String | null = null;
  itemDescription: String = '';
  panelBackgroundOpen = "uitk-l-grid-open";
  panelBackground = this.panelBackgroundOpen;
  constructor(public modeService: ModeService, configService: ConfigService, public orderService: OrderService) {
    this._configService = configService;
  }

  ngOnInit(): void {
    this.acronymList = this._configService.getPickList('ACRODESC');
    this.editionYrList = this._configService.getPickList('EDITIONYR');
    this.showResultsGrid.show = false;
    this.ItemSearchDialog.show = true;
  }
  setPanelBGHeight() {
    if(this.panelBackground === "uitk-l-grid-open")
    {
          this.panelBackground="uitk-l-grid1-close";
    }
    else
    {
          this.panelBackground=this.panelBackgroundOpen;
    }
  }
  closeDialog() {
    this._configService.resetIdleTimeout();
    this.showResultsGrid.show = false;
    this.ItemSearchDialog.show = false;
  }
  clear() {
    this.itemNumber = '';
    this.isbn = null;
    this.acronym = null;
    this.itemDescription = '';
    this.editionYr = null;
    this.ProductsList = [];
  }

  addItem(productCode: any) {
    this._configService.resetIdleTimeout();
    this.productAdded.emit(productCode);
    console.log("selected product code", this.productAdded);
    this.dialog.show = false;
  }
  search() {
    this._configService.resetIdleTimeout();
    this.showResultsGrid.show = false;
    this.modeService.mode = Mode.Wait;
    this.ProductsList = this._configService.reference!.productDtos
      .filter(p => (this.itemNumber === '' || p.productCode.toString().toLowerCase().includes(this.itemNumber.toString().toLowerCase())) &&
        (this.isbn === null || p.isbn?.trim().toString().startsWith(this.isbn?.trim().toString())) &&
        (this.editionYr === null || p.editionYear === +this.editionYr?.label) &&
        (this.itemDescription === '' || p.description.toString().toLowerCase().includes(this.itemDescription.toString().toLowerCase())) &&
        (this.acronym === null || p.acronym === this.acronym.value.toString())
      );
      this.ProductsList
        .sort((a, b) => a.productCode < b.productCode ? -1 : 1);
    console.log("Prodct items list", this.ProductsList);
    this.modeService.mode = Mode.View;
    this.showResultsGrid.show = true;
  }
}
