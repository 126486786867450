import { Component, Input } from '@angular/core';
import { ApprovalService } from '../../services/approval.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})
  
export class CouponComponent {
  @Input() dialog = { show: false};

  constructor(public orderService: OrderService,
    private readonly approvalService: ApprovalService) { }

  couponNoClick() {
    this.dialog.show = false;
    this.orderService.addNewLineItem();
    setTimeout(() => {
      if ((document.getElementById(`productCode--${Math.abs(this.orderService.orderDto.orderDetailDtos
      [this.orderService.orderDto.orderDetailDtos.length - 1].id)}`) as HTMLElement)) {
        (document.getElementById(`productCode--${Math.abs(this.orderService.orderDto.orderDetailDtos
        [this.orderService.orderDto.orderDetailDtos.length - 1].id)}`) as HTMLElement).focus();
      }
    }, 500);
  }

  couponYesClick() {
    const couponItems = this.orderService.orderDto.orderDetailDtos.filter(item => item.productDto.classCode === 'XS');
    if (couponItems.length > 0) {
      for (const element of couponItems) {
        if (element.orderDetailStatus === 'O' || element.orderDetailStatus === ' ') {
          const updateItemIndex = this.orderService.orderDto.orderDetailDtos.map(item => item.id).indexOf(element.id);
          if (element.listPrice >= -Math.abs(this.orderService.couponExcessAmount).toFixed(2)) {
            this.orderService.couponExcessAmount = -Math.abs(this.orderService.couponExcessAmount - element.listPrice);
            this.orderService.orderDto.orderDetailDtos.splice(updateItemIndex, 1);
            this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos]
          } else {
            element.listPrice = -Math.abs(element.listPrice - this.orderService.couponExcessAmount);
            element.unitPrice = -Math.abs(element.unitPrice - this.orderService.couponExcessAmount);
            break;
          }
        }
      };
      this.orderService.recalculateFields(this.orderService.orderDto);
      this.approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.orderService.addNewLineItem();
      this.dialog.show = false;

      setTimeout(() => {
        if ((document.getElementById(`productCode--${Math.abs(this.orderService.orderDto.orderDetailDtos
        [this.orderService.orderDto.orderDetailDtos.length - 1].id)}`) as HTMLElement)) {
          (document.getElementById(`productCode--${Math.abs(this.orderService.orderDto.orderDetailDtos
          [this.orderService.orderDto.orderDetailDtos.length - 1].id)}`) as HTMLElement).focus();
        }
      }, 500);
    }
  }
}
