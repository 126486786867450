<div id="divAR" class="css-t7viak">
  <div class="uitk-l-grid">
    <div class="uitk-l-grid__row">
      <div class="uitk-l-grid__col--12">
        <div [hidden]="isDataFound">
          <h5 id="table-paginate" class="caption">Accounts Receivable</h5>
          <br>
          <h4>No Data Found</h4>
        </div>
        <div [hidden]="!isDataFound" class="uitk-c-table__caption">
          <h5 id="table-paginate" class="caption">Accounts Receivable</h5>
          <h5>Account #: <a target="_blank" (click)="navigateToSFViewAccount(accountInfo.salesforceId)" class="link">{{accountInfo.accountId}}</a></h5>

          <div class="uitk-l-grid__row" style="border: 1px solid #D0D0CE;">
            <div class="uitk-l-grid__col">
              <div class="uitk-l-grid__col--3">
                <div class="row">
                  <p class="col-lg-4" style="font-weight: bold;"> {{accountInfo.accountName}}</p>
                </div>
                <div class="row">
                  <p>
                    <span class="col-lg-4">{{accountInfo.department}} {{accountInfo.street}}</span>
                  </p>
                </div>
                <div class="row">
                  <p>
                    <span class="col-lg-4">{{accountInfo.city}}, {{accountInfo.state}} {{accountInfo.zip}}</span>
                  </p>
                </div>
              </div>
              <div class="uitk-l-grid__col--3">
                <div class="row">
                  <label class="col-lg-3">Account Type:</label>
                  <p class="col-lg-9">{{accountFieldsSingle?.accountTypeCode}}</p>
                </div>
                <div class="row">
                 <label class="col-lg-3">Account Manager:</label>
                 <p class="col-lg-9"><a target="_blank" [href]="'mailto:'+accountManagerEmail"> {{accountManager}}</a></p>
                  </div>
                <div class="row">
                  <label class="col-lg-3">Account Status:</label>
                  <p class="col-lg-9">{{accountFieldsSingle?.statusCode}}</p>
                </div>
              </div>
              <div class="uitk-l-grid__col--3">
                <div class="row">
                  <label class="col-lg-6">Credit Limit:</label>
                  <p class="col-lg-6"><span [ngClass]="accountFieldsSingle ? accountFieldsSingle.creditLimit  < 0?'creditLimitNegative':'col-lg-9': 'col-lg-9'" style="font-weight: bold;"> {{accountFieldsSingle? accountFieldsSingle.creditLimit < 0 ? accountFieldsSingle.creditLimitDescription : creditDescription: creditDescription}}</span></p>
                </div>
                <div class="row">
                  <label class="col-lg-6">Bill to Contact Name:</label>
                  <p class="col-lg-6">{{accountFieldsSingle?.contactFirstName == null ? " " : accountFieldsSingle?.contactFirstName}} {{accountFieldsSingle?.contactLastName == null ? " " : accountFieldsSingle?.contactLastName}}</p>
                </div>
                <div class="row">
                  <label class="col-lg-6">Credit Memo Flag:</label>
                  <p class="col-lg-6">{{accountFieldsSingle?.creditMemoFlag == true ? "Yes" : "No"}}</p>
                </div>
              </div>
              <div class="uitk-l-grid__col--3">
                <div class="row">
                  <label class="col-lg-3">Gross A/R Balance:</label>
                  <p class="col-lg-9">{{accountBalanceDto.grossARBalance | currency:'USD':'symbol':'1.2-2' }}</p>
                </div>
                <div class="row" style="height: 32px;">
                  <label class="col-lg-3">Open Credit:</label>
                  <p class="col-lg-9">{{accountBalanceDto.openCreditBalance | currency:'USD':'symbol':'1.2-2'}}</p>
                </div>
                <div class="row">
                  <label class="col-lg-3">Open Cash:</label>
                  <p class="col-lg-9">{{accountBalanceDto.openCashBalance | currency:'USD':'symbol':'1.2-2'}}</p>
                </div>
                <div class="row">
                  <label class="col-lg-3">Net Balance:</label>
                  <p class="col-lg-9">{{accountBalanceDto.netBalance | currency:'USD':'symbol':'1.2-2'}}</p>
                  <button class="col-lg-6 uitk-c-button uitk-c-button--ghost uitk-u-margin--lg" style="margin-left: 10px;" (click)="calculateAccountBalance()">Re-calculate</button>
                </div>
              </div>
            </div>
          </div>
          <br>
          <uitk-tabbed-panels [isTabbedPanelsMedium]="true" style="border: none;" [selectedIndex]="selectedTab">
            <uitk-tabbed-panel tabTitle="Open">
              <h4>Open Receivables</h4>
              <app-filter-bar [allData]="openReceivablesDataSource.data" [(filteredData)]="filteredOpenReceivablesDataSource.data" [(gridFilters)]="openReceivablesGridFilters"></app-filter-bar>
              <div class="uitk-c-table--responsive" uitkTableContainer [sticky]="true" style="min-height: 300px; max-height:800px; overflow: auto;" >
                <table uitkTable [dataSource]="filteredOpenReceivablesDataSource" class="uitk-c-table"
                       aria-describedby="order-data">
                  <thead class="uitk-c-table__head">
                    <tr uitkTableSort #sortTable="uitkTableSort" style="text-align: left">
                      <th *ngFor="let headerName of openReceivablesHeader" (sortChange)="onSortChange($event)"
                          [column]="headerName.id" uitkTableSortColumn scope="col">
                        <app-grid-filter [columnName]="headerName.name" [fieldName]="headerName.id" [allData]="openReceivablesDataSource.data" [(filteredData)]="filteredOpenReceivablesDataSource.data" [(gridFilters)]="openReceivablesGridFilters"></app-grid-filter>
                        {{ headerName.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="uitk-c-table__body" id='scrollTop'>
                    <ng-container *uitkTableRowBody="let data">
                      <tr *ngIf="data" class="uitk-c-table__row" 
                        [ngStyle]="{ 'background-color': 
                          data.invoiceNumber === '_RECEIPT' && data.balance < 0 ? 'lightgreen' : 
                          data.type == 'Credit Memo' ? 'skyblue' : 
                          'white'}">
                        <td [ngClass]="{ 'color-red': data.invoiceNumber === '_RECEIPT' && data.balance >= 0 }">{{ data.invoiceNumber }}</td>
                        <td [ngClass]="{ 'color-red': data.invoiceNumber === '_RECEIPT' && data.balance >= 0 }">{{ data.invoiceDate |date: 'MM/dd/yyyy'  }}</td>
                        <td [ngClass]="{ 'color-red': data.invoiceNumber === '_RECEIPT' && data.balance >= 0 }">{{ data.referenceNumber }}</td>
                        <td [ngClass]="{ 'color-red': data.invoiceNumber === '_RECEIPT' && data.balance >= 0 }">
                          <a href="#" target="_blank"
                             [routerLink]="['/order', data.orderNumber]">{{ data.orderNumber }}</a>
                        </td>
                        <td [ngClass]="{ 'color-red': data.invoiceNumber === '_RECEIPT' && data.balance >= 0 }">{{ data.invoiceAmount |currency:'USD':'symbol':'1.2-2'}}</td>
                        <td [ngClass]="{ 'color-red': data.invoiceNumber === '_RECEIPT' && data.balance >= 0 }">{{ data.paidAmount |currency:'USD':'symbol':'1.2-2'}}</td>
                        <td [ngClass]="{ 'color-red': data.invoiceNumber === '_RECEIPT' && data.balance >= 0 }">{{ data.balance |currency:'USD':'symbol':'1.2-2'}}</td>
                        <td [ngClass]="{ 'color-red': data.invoiceNumber === '_RECEIPT' && data.balance >= 0 }">{{ data.paidDate == '1900-01-01T00:00:00'? '' : data.paidDate |date: 'MM/dd/yyyy'}}</td>
                        <td [ngClass]="{ 'color-red': data.invoiceNumber === '_RECEIPT' && data.balance >= 0 }">{{ data.type }}</td>
                        <td [ngClass]="{ 'color-red': data.invoiceNumber === '_RECEIPT' && data.balance >= 0 }">{{ data.poNumber }}</td>
                      </tr>
                    </ng-container>
                    <div *ngIf="openReceivablesDataSource.data.length === 0">No records found</div>
                  </tbody>
                </table>
              </div>             
              <div class="scroll-to-top">
                <button class="uitk-icon uitk-icon-arrow_up lg-icon" (click)="scrollToTop($event)">
                  <i class="fa fa-chevron-up"></i>
                </button>
              </div>
            </uitk-tabbed-panel>
            <uitk-tabbed-panel tabTitle="Payment">
              <h4>Payment</h4><br>Total Records : {{totalRecords}}
              <app-filter-bar [allData]="paymentDetailsDataSource.data" [(filteredData)]="filteredPaymentDetailsDataSource.data" [(gridFilters)]="paymentGridFilters"></app-filter-bar>
              
              <uitk-pagination [paginationConfig]="paginationConfig" [entriesPerPage]="entriesPerPage"
                    [enableGoToPage]="true" [totalRecordsCount]="totalRecords"
                    (onPaginationChange)="onPaginationChange($event)">
              
                <div class="uitk-c-table--responsive" uitkTableContainer [sticky]="true" style="min-height: 400px; max-height:800px;" >
                  <table uitkTable [dataSource]="filteredPaymentDetailsDataSource" class="uitk-c-table"
                        aria-describedby="order-data">
                    <thead class="uitk-c-table__head">
                      <tr uitkTableSort #sortPaymentTable="uitkTableSort" style="text-align: left">
                        <th *ngFor="let headerName of paymentsHeader" (sortChange)="onPaymentSortChange($event)"
                            [column]="headerName.id" uitkTableSortColumn scope="col">
                          <app-grid-filter [columnName]="headerName.name" [fieldName]="headerName.id" [allData]="paymentDetailsDataSource.data" [(filteredData)]="filteredPaymentDetailsDataSource.data" [(gridFilters)]="paymentGridFilters"></app-grid-filter>
                          {{ headerName.name }}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="uitk-c-table__body">
                      <ng-container *uitkTableRowBody="let data">
                        <tr *ngIf="data" class="uitk-c-table__row" [ngStyle]="{ 'background-color': data.paymentMethod === 'Reservation' ? 'paleturquoise' : 'white'}">
                          <td>{{ data.invoiceNumber }}</td>
                          <td>{{ data.invoiceDate |date: 'MM/dd/yyyy' }}</td>
                          <td>{{ data.paymentMethod }}</td>
                          <td>{{ data.referenceNumber }}</td>
                          <td>
                            <a href="#" target="_blank" [routerLink]="['/order', data.orderId]">{{ data.orderId== '0' || data.orderId== null? '' : data.orderId }}</a>
                          </td>
                          <td>{{ data.poNumber }}</td>
                          <td>{{ data.paidDate == '1900-01-01T00:00:00'? '' : data.paidDate |date: 'MM/dd/yyyy'}}</td>
                          <td>{{ data.paidAmount |currency:'USD':'symbol':'1.2-2' }}</td>
                          <td>{{ data.paymentType }}</td>
                        </tr>
                      </ng-container>
                      <div *ngIf="paymentDetailsDataSource.data.length === 0">No records found</div>
                    </tbody>
                  </table>
                </div>
              </uitk-pagination>
              <div class="scroll-to-top">
                <button class="uitk-icon uitk-icon-arrow_up lg-icon" (click)="scrollToTop($event)">
                  <i class="fa fa-chevron-up"></i>
                </button>
              </div>
            </uitk-tabbed-panel>
            <uitk-tabbed-panel tabTitle="Invoices">
              <uitk-banner-notification uitkId="demoId" variant="warning" [show]="true">
                <h1 uitkBannerHeading>
                  The grid shows data for the past 3 years. For historical data please view Invoices
                  in COMET
                </h1>
              </uitk-banner-notification>
              <br>
              <h4>Invoices</h4>
              <div class="uitk-l-grid__row">
                <label uitkLabel class="labelsAR">View Invoices</label>
                <div class="uitk-l-grid__col">
                  <uitk-select uitkId="country" appEnableResource="ARInquiryInvoiceMemoDropDown" class="selectAR"
                               [itemList]="list"
                               [defaultLabel]="defaultLabel"
                               [defaultLabelFlag]="defaultLabelFlag"
                               [(ngModel)]="invoiceFilter"
                               (onDropdownChange)="onInvFilterChange()"></uitk-select>
                </div>
                <label uitkLabel class="labelsAR">View CM</label>
                <div class="uitk-l-grid__col">
                  <uitk-select uitkId="country" appEnableResource="ARInquiryCreditMemoDropDown" class="selectAR"
                               [itemList]="list"
                               [defaultLabel]="defaultLabel"
                               [defaultLabelFlag]="defaultLabelFlag"
                               [(ngModel)]="cmFilter"
                               (onDropdownChange)="onInvFilterChange()"></uitk-select>
                </div>
                <div class="uitk-l-grid__col">
                </div>
              </div>
              <div>
                <button id="sendBtn" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--m"
                  *appShowResource="'ARInquirySendSelectedToPdfButton'" (click)="showSelectedTeaplate()" [disabled]="(this.invoiceService.hasCCMemo && !sendCCPdfAccess) || this.accountsReceivableService.selectedOrder === 0">
                  Send Select to PDF</button>
                <app-invoice-edit [accountId]="accountInfo.accountId" (invoiceEdited)="reload()"></app-invoice-edit>
              </div>
              <app-invoice-results [invoiceNumber]="invoiceNumber"></app-invoice-results>
              <div class="scroll-to-top">
                <button class="uitk-icon uitk-icon-arrow_up lg-icon" (click)="scrollToTop($event)">
                  <i class="fa fa-chevron-up"></i>
                </button>
              </div>
            </uitk-tabbed-panel>
          </uitk-tabbed-panels>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Dialogs -->
<div *ngIf="showSelectedTemplate.show || showTemplateEmail.show">
  <app-select-template [showTemplateEmailDialog]="showSelectedTemplate" ></app-select-template>
  
  <app-selected-template-email [templateUrl]="showTemplateEmail"></app-selected-template-email>
</div>
                                                                                                                                                      
