import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IUITKRadioGroupItem } from '@uitk/angular';
import { Subject } from 'rxjs';
import { MultiUserPricingDto } from '../../../dtos/multi-user-pricing.model';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { ProductDto } from '../../../dtos/product-dto.model';
import { ConfigService } from '../../../services/config.service';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-subscription-price-calculation',
  templateUrl: './subscription-price-calculation.component.html',
  styleUrls: ['./subscription-price-calculation.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class SubscriptionPriceCalculationComponent implements OnInit {
  private readonly _configService: ConfigService;
  private readonly _orderService: OrderService;
  private readonly _pricingService: PricingService;
  myDialogHeader: string = 'Add Order';
  myDialogContent: string = 'Content Data will come here.';
  myDefaultButtonText: string = 'Save';
  myGhostButtonText: string = 'Cancel';
  myCloseButtonText: string = 'Close';
  productDtos: ProductDto[] | undefined = [];
  multiUserDtos: MultiUserPricingDto[] = [];
  productId: number = 0;
  currentUser: number = 0;
  newUser: number = 0;
  totalUser: number = 0;
  discount: number = 0
  discountPipeValue: number = 0;
  listPrice: number = 0;
  extPrice: number = 0;
  usnitPrice: number = 0;
  unitPricePipeValue: number = 0;
  orderDetailDto: OrderDetailDto = new OrderDetailDto();
  totalDays: number = 0;
  dayUsed: number | undefined = 0;
  rateddDscount: number = 0
  ratedUnitPrice: number = 0;
  ratedExtendedprice: number = 0;
  finalDiscount: number = 0;
  finalUnitPrice: number = 0;
  finalExtendedprice: number = 0
  isProrated: boolean = false;
  unsavedOrderDto: OrderDetailDto[] = [];
  popupClosed = new Subject();
  tableHeader = ['User Range', 'Price Per User'];
  tableHeaderSales = ['Current Users', 'New Users', 'Total Users', 'List Price', 'Discount', 'Unit Price', 'Ext.Price'];
  swalAlert = new SwalAlert();

  constructor(
    configService: ConfigService,
    orderService: OrderService,
    pricingService: PricingService) {
    this._configService = configService;
    this._orderService = orderService;
    this._pricingService = pricingService;
  }

  ngOnInit(): void {
    this._configService.configIsReady.subscribe(m => {
      this.productDtos = this._configService.reference?.productDtos;
      this.recalculate();
    });
    this._configService.loadConfigurations();
  }
  normalDialogModal = {
    show: false,
  };

  showNormalDialog(productId: number, unsavedOrderDto: OrderDetailDto[]) {
    this.isProrated = false;
    this.unsavedOrderDto = [...unsavedOrderDto];
    this.productId = productId;
    this.orderDetailDto = JSON.parse(JSON.stringify(this._orderService.orderDto.orderDetailDtos.find(m => m.productId === this.productId) as OrderDetailDto));
    this.multiUserDtos = this.productDtos?.find(m => m.id === productId)?.multiUserPricingDtos ?? [];
    this.currentUser =  this._orderService.orderDto.orderDetailDtos.filter(item =>
        (item.productCode === 'V' + this.orderDetailDto.productCode) && item.orderDetailStatus === 'C')
      .reduce((sum, current) => sum + current.shippedQuantity, 0) + this.orderDetailDto.shippedQuantity+this.orderDetailDto.returnedQuantity;
    this.totalUser = this.orderDetailDto.openQuantity + this.currentUser;
    this.newUser = this.orderDetailDto.openQuantity;

    this.discount = this.orderDetailDto.discount as number;
    this.discountPipeValue = this.discount;
    this.recalculate();
    this.normalDialogModal.show = true;
  }
  onChangeListPrice(orderDetailDto: OrderDetailDto) {
    if (orderDetailDto.discount === undefined) {
      orderDetailDto.discount = 0;
    }

    orderDetailDto.unitPrice = this._pricingService.round((orderDetailDto.listPrice * (100 - orderDetailDto.discount) / 100), 2);
    if (orderDetailDto.discount === undefined) {
      orderDetailDto.discount = 0;
    }
    orderDetailDto.orderQuantity = orderDetailDto.orderQuantity === undefined ? 0 : orderDetailDto.orderQuantity;
    orderDetailDto.extendedPrice = isNaN(this.newUser * this._pricingService.round((orderDetailDto.listPrice * (100 - orderDetailDto.discount) / 100), 2)) ? 0 :
      this.newUser * this._pricingService.round((orderDetailDto.listPrice * (100 - orderDetailDto.discount) / 100), 2);
    this.unitPricePipeValue = this.orderDetailDto.unitPrice;
    this.discountPipeValue = this.orderDetailDto.discount;
    this.finalDiscount = this.orderDetailDto.discount || 0;
    this.finalUnitPrice = this.orderDetailDto.unitPrice;
    this.finalExtendedprice = this.newUser * this.finalUnitPrice;

    this.getRatedPrice();
  }

  getListPrice() {
    this.listPrice = this.orderDetailDto.listPrice;
    if (this.multiUserDtos.length > 0) {
      const multiUserPricing: MultiUserPricingDto = this.multiUserDtos.filter(m => m.startRange <= this.totalUser && m.endRange >= this.totalUser)[0];
      if (multiUserPricing) {
        this.orderDetailDto.listPrice = multiUserPricing.listPrice;
      }
    }
  }

  getRatedPrice() {
    this.calculateRatedDiscount();
    this.ratedUnitPrice = this.fnUnitPrice(this.orderDetailDto.listPrice, this.rateddDscount);
    this.ratedExtendedprice = +this.newUser * this.ratedUnitPrice;
  }

  getDebugNoDay(startDate: any) {
    const startDt = new Date(startDate);
    const currentDate = new Date();
    let dayCount = 0;
    while (currentDate > startDt) {
      dayCount++;
      startDt.setDate(startDt.getDate() + 1);
    }
    return dayCount;
  }

  onChangeNewUser
    (event: any) {
    const newUser = +event.target.value;
    this.totalUser = newUser + this.currentUser;
    this.newUser = newUser;
    this.recalculate();
    this.totalUser = newUser + this.currentUser;
    this.newUser = newUser;
    this.ratedUnitPrice = this.fnUnitPrice(this.orderDetailDto.listPrice, this.rateddDscount);
    this.ratedExtendedprice = this.newUser * this.ratedUnitPrice;
  }

  recalculate() {
    this.getListPrice();
    this.getRatedPrice();
    this.getUnitPrice();
    this.getExtendedPrice();
    this.getDiscount();
  }

  onChangeDiscount(discount: number) {
    this.discountPipeValue = discount;
    this.orderDetailDto.discount = discount;
    this.finalDiscount = this.orderDetailDto.discount || 0;
    this.getUnitPrice();
    this.getExtendedPrice();
    this.changeFinalPricing();
  }

  changeFinalPricing() {
    if (this.isProrated) {
      const discount = ((100.000 - (+this.rateddDscount)) * +this.orderDetailDto.discount / 100.000) + (+this.rateddDscount);
      this.finalDiscount = discount || 0;
      this.finalDiscount = +(Number.parseFloat(this.finalDiscount + '').toFixed(3));
      this.discountPipeValue = this.orderDetailDto.discount;
      this.finalUnitPrice = this._pricingService.round(this.orderDetailDto.listPrice * (100 - this.finalDiscount) / 100, 2);
      this.finalExtendedprice = this.finalUnitPrice * this.newUser;
    }
  }

  onChangeUnitPrice(unitPrice: number) {

    this.finalUnitPrice = unitPrice;
    this.orderDetailDto.unitPrice = unitPrice;
    if (this.orderDetailDto.productDto.allowCustomStandard) {
      this.orderDetailDto.listPrice = this.orderDetailDto.unitPrice;
    }

    this.getDiscount();
    this.getExtendedPrice();
    this.changeFinalPricing();
  }

  getDiscount() {
    const discount = this._pricingService.round((100 - this.orderDetailDto.unitPrice / this.orderDetailDto.listPrice * 100), 10);
    this.discount = discount;
    this.discountPipeValue = this.orderDetailDto.discount = this.discount;
    if (this.isProrated) {
      this.finalPriceDiscount();
    } else {
      this.finalDiscount = this.discount || 0;
    }
  }

  getUnitPrice() {
    this.orderDetailDto.unitPrice = this._pricingService.round((this.orderDetailDto.listPrice * (100 - this.orderDetailDto.discount) / 100), 2);
    if (this.isProrated) {
      this.finalUnitPrice = this.fnUnitPrice(this.orderDetailDto.listPrice, this.finalDiscount);
    } else {
      this.finalUnitPrice = this.orderDetailDto.unitPrice;
    }
    this.unitPricePipeValue = this.orderDetailDto.unitPrice;
  }
  getExtendedPrice() {
    this.orderDetailDto.extendedPrice = this.newUser * +this.orderDetailDto.unitPrice;
    if (this.isProrated) {
      this.finalExtendedprice = this.newUser * this.finalUnitPrice;
    } else {
      this.finalExtendedprice = this.orderDetailDto.extendedPrice;
    }
  }

  calculateRatedDiscount() {
    const days = this.orderDetailDto?.subscriptionDto?.term;
    if (days) {
      this.dayUsed = this.getDebugNoDay(this.orderDetailDto?.subscriptionDto?.startDate);
      this.totalDays = days * 30;
      this.rateddDscount = (this.dayUsed / this.totalDays) * 100;
    }
  }

  fnUnitPrice(listPrice: number, discount: any) {
    return this._pricingService.round(listPrice * (100 - discount) / 100, 2);
  }

  onProratedChange(event: any) {
    if (event.target.checked) {
      this.changeFinalPricing();
    } else {
      this.discountPipeValue = this.discount;
      this.calculateRatedDiscount();
      this.finalDiscount = this.orderDetailDto.discount || 0;
      this.finalUnitPrice = this.orderDetailDto.unitPrice;
      this.finalExtendedprice = this.newUser * this.finalUnitPrice;
      this.discountPipeValue = this.orderDetailDto.discount;
    }
  }

  finalPriceDiscount() {
    this.finalDiscount = ((1 - (100 - this.rateddDscount) / 100 * (100 - this.discount) / 100) * 100) || 0;
  }

  saveChanges() {
    if (!this._configService.reference) {
      this.swalAlert.alert('Error attempting to use references before they were loaded.');
      return;
    }

    const item = this._orderService.orderDto.orderDetailDtos.find(m => m.productId === this.productId) as OrderDetailDto;
    const vLineItemProduct = this._configService.reference.productDtos.filter(i => i.productCode === 'V' + item.productCode)[0];
    const productExistsIndex = this._orderService.orderDto.orderDetailDtos.findIndex(c => c.productCode.includes(vLineItemProduct.productCode) && c.orderDetailStatus === ' ');
    const orderDetail = this._orderService.createNewOrderDetail(this._orderService.orderDto, 'V' + item.productCode);
    orderDetail.itemEditable = true;
    orderDetail.orderQuantity = this.totalUser;
    orderDetail.unitPrice = this.finalUnitPrice;
    orderDetail.discount = +this.finalDiscount;
    orderDetail.listPrice = this.orderDetailDto.listPrice;
    orderDetail.openQuantity = this.totalUser - this.currentUser;
    orderDetail.orderDetailStatus = ' ';
    orderDetail.parentOrderDetailId = item.id;

    if (productExistsIndex === -1) {
      const mainIndex = this._orderService.orderDto.orderDetailDtos.findIndex(c => c.productId === this.productId);
      this._orderService.orderDto.orderDetailDtos.splice(mainIndex+1,0,orderDetail);
    }
    else {
      orderDetail.id = this._orderService.orderDto.orderDetailDtos[productExistsIndex].id;
      this._orderService.orderDto.orderDetailDtos.splice(productExistsIndex, 1, orderDetail);
    }

    this._orderService.orderDto.orderDetailDtos = [...this._orderService.orderDto.orderDetailDtos];
    this._orderService.recalculateFields(this._orderService.orderDto);
    this.normalDialogModal.show = false;
  }

  closeDialog() {
    this._orderService.recalculateFields(this._orderService.orderDto);
    this.popupClosed.next();
    this.normalDialogModal.show = false;
  }

  myOptionLabel1: string = 'Amount';
  myOptionLabel2: string = 'Percent';

  items: IUITKRadioGroupItem[] = [
    {
      label: 'Amount',
      value: '1',
    },
    {
      label: 'Percent',
      value: '2',

    },
  ];

  myRadioGroupSelection: any;


  ngDoCheck(): void {
    this.items[0].label = this.myOptionLabel1;
    this.items[1].label = this.myOptionLabel2;
  }
}
