import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IUITKColumnState, UITKSortDirection, UITKTableDataSource, UITKTableSortDirective } from '@uitk/angular';
import { ContactDto } from '../../../dtos/contact-dto.model';
import { ConfigService } from '../../../services/config.service';
import { OrderService } from '../../../services/order.service';

@Component({
  selector: 'app-contact-search-lookup',
  templateUrl: './contact-search-lookup.component.html',
  styleUrls: ['./contact-search-lookup.component.css']
})
  
export class ContactSearchLookupComponent implements OnInit,AfterViewInit {
  private readonly _configService: ConfigService;
  private readonly _orderService: OrderService;
  loading: boolean = false;
  contactSearchKeyword: string = '';
  @Input() contactSearchLookupDialog = { show: false };
  @Input() triggerEleId:string = "";
  @Output() selectedContact = new EventEmitter<ContactDto>();
  @ViewChild('sortContactSearchTable') uitkTableSortContactSearch!: UITKTableSortDirective;
  tableHeader = ['Contact ID', 'Name', 'Address', 'Email', 'Phone', 'Status'];
  filteredContactsData = new UITKTableDataSource<ContactDto>([]);
  isDataFound = false;

  constructor(configService: ConfigService, orderService: OrderService) {
    this._configService = configService;
    this._orderService = orderService;
  }

  ngOnInit(): void {
    
    if(this._orderService.selectedContact !== null && this._orderService.selectedContact !== undefined && this._orderService.selectedContact.trim() !== ''){
      this.contactSearchKeyword = this._orderService.selectedContact;
      this.showContactDetailsBasedOnKeyword();
    }
  }
  ngAfterViewInit(): void {
    (document.getElementById('viewFilteredContactsData_closeBtn') as HTMLElement).style.display = 'none';
    setTimeout(()=>{
      (document.getElementById('viewFilteredContactsData_closeBtn') as HTMLElement).style.display = 'block';
    });
  }

  async showContactDetailsBasedOnKeyword(): Promise<void> {
    this.filteredContactsData.data = [];
    this.isDataFound = false;
    this.loading = true;
    const contactSearchKeyword = (this.contactSearchKeyword.indexOf('/') > -1 && this.contactSearchKeyword.indexOf('-') > -1 && this.contactSearchKeyword.indexOf('-') === this.contactSearchKeyword.lastIndexOf('-')) ? this.contactSearchKeyword.replace("-",'').replace("/",'') :  this.contactSearchKeyword;
    await this._configService.getAccountFilteredContacts(this._orderService.orderDto.billToDto.id, contactSearchKeyword.trim())
      .then((data: ContactDto[]) => {
        this.loading = false;
        if(!isNaN(Number(this.contactSearchKeyword)) && this.contactSearchKeyword.length >= 10){
          this.filteredContactsData.data = [];
        }else{
          this.filteredContactsData.data = data;
        }
        this.isDataFound = true;
        this.tableHeader.forEach(header => {
          this.uitkTableSortContactSearch.setDirection({ column: header, direction: UITKSortDirection.UNSORTED });
        });

        this.uitkTableSortContactSearch.setDirection({ column: 'Name', direction: UITKSortDirection.ASC });
        this.filteredContactsData.data.sort((a, b) => 0 - (this.generateFullName(a) > this.generateFullName(b) ? -1 : 1));
        this.filteredContactsData.data = [...this.filteredContactsData.data];
      });
  }

  selectContact(contactDetails: ContactDto): void {
    this.selectedContact.emit(contactDetails);
    this.contactSearchLookupDialog.show = false;
  }

  onSortChange(event: IUITKColumnState): void {
    this.filteredContactsData.data = this.filteredContactsData.sortData(this.filteredContactsData.data, event.direction, event.column.toLowerCase());
    
    switch (event.column.toLowerCase().replace(/\s/g, '')) {
      case 'contactid':
        switch (event.direction) {
          case 1:
            this.filteredContactsData.data.sort((a, b) => 0 - (a.contactId > b.contactId ? -1 : 1));
            break;
          case -1:
            this.filteredContactsData.data.sort((a, b) => 0 - (a.contactId > b.contactId ? 1 : -1));
            break;
        }

        break;
      case 'name':
        this.sortByName(event.direction);        
        break;
      case 'address':
        this.sortByAddress(event.direction);
        break;
      case 'email':
        this.sortByEmail(event.direction);
        break;
      case 'phone':
        this.sortByPhone(event.direction);
        break;
      case 'status':
        this.sortByStatus(event.direction);
        break;
    }

    this.filteredContactsData.data = [...this.filteredContactsData.data];
  }

  sortByStatus(direction:number){
    switch (direction) {
      case 1:
        this.filteredContactsData.data.sort((a, b) => 0 - (a.statusCode > b.statusCode ? -1 : 1));
        break;
      case -1:
        this.filteredContactsData.data.sort((a, b) => 0 - (a.statusCode > b.statusCode ? 1 : -1));
        break;
    }
  }

  sortByName(direction:number){
    switch (direction) {
      case 1:
        this.filteredContactsData.data.sort((a, b) => 0 - (this.generateFullName(a) > this.generateFullName(b) ? -1 : 1));
        break;
      case -1:
        this.filteredContactsData.data.sort((a, b) => 0 - (this.generateFullName(a) > this.generateFullName(b) ? 1 : -1));
        break;
    }
  }

  sortByAddress(direction:number){
    switch (direction) {
      case 1:
        this.filteredContactsData.data.sort((a, b) => 0 - (this.generateAddress(a) > this.generateAddress(b) ? -1 : 1));
        break;
      case -1:
        this.filteredContactsData.data.sort((a, b) => 0 - (this.generateAddress(a) > this.generateAddress(b) ? 1 : -1));
        break;
    }
  }

  sortByPhone(direction:number){
    switch (direction) {
      case 1:
        this.filteredContactsData.data.sort((a, b) => 0 - (a.phone > b.phone ? -1 : 1));
        break;
      case -1:
        this.filteredContactsData.data.sort((a, b) => 0 - (a.phone > b.phone ? 1 : -1));
        break;
    }
  }

  sortByEmail(direction:number){
    switch (direction) {
      case 1:
        this.filteredContactsData.data.sort((a, b) => 0 - (a.email > b.email ? -1 : 1));
        break;
      case -1:
        this.filteredContactsData.data.sort((a, b) => 0 - (a.email > b.email ? 1 : -1));
        break;
    }
  }

  generateFullName(contact: ContactDto): string {
    return [contact.firstName, contact.lastName].filter(Boolean).join(' ');
  }

  generateCityStateZip(contact: ContactDto): string{
    return [contact.city, contact.state, contact.zip].filter(Boolean).join(', ');
  }
  generateStatus(contact: ContactDto): string{
    const statusCode = contact.statusCode.trim();
    if(statusCode === 'DEL'){
      return 'Delete';
    }
    else if(statusCode === 'A'){
      return 'Active';
    }
    else if(statusCode === 'BAD'){
      return 'Bad Address';
    }
    else if(statusCode === 'BP'){
      return 'Bad Phone';
    }
    return '';
  }
  generateAddress(contact: ContactDto): string{
    return [contact.company, contact.department, contact.street, contact.city, contact.state, contact.zip, contact.country].filter(Boolean).join(', ');
  }

  onChangeContactKey(value: string){
    this.contactSearchKeyword = value.trim();
  }
}
