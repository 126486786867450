
<div [hidden]="modeService.mode == Mode.SubscriptionWait" class="css-t7viak">
  <div class="uitk-l-grid">
    <div class="uitk-l-grid__row">
      <div class="uitk-l-grid__col--12">
        <div class="uitk-c-table__caption">
          <h3 id="table-paginate" class="caption">Search Results</h3>
          Total Records : {{totalRecords}}
          <br>
        </div>
        <app-filter-bar [allData]="subscriptions.data" [(filteredData)]="filteredSubscriptions.data" [(gridFilters)]="gridFilters"></app-filter-bar>
        <div class="uitk-c-table--responsive" uitkTableContainer [sticky]="true" [offset]="collapsedHeader ? 340 : 580" appExpand>
          <table uitkTable [dataSource]="filteredSubscriptions" class="uitk-c-table" aria-describedby="order-data"
                 id="subscription-results-table" aria-describedby="row-data"
                 #rootTable="uitkTable">

            <thead class="uitk-c-table__head">
              <tr uitkTableSort #sortTable="uitkTableSort">
                <th></th>
                <th *ngFor="let headerName of tableHeader" scope="col" uitkTableSortColumn
                    (sortChange)="onSortChange($event)" [column]="headerName.id">
                  <app-grid-filter [fieldName]="headerName.filter" [columnName]="headerName.name" [allData]="subscriptions.data" [(filteredData)]="filteredSubscriptions.data" [(gridFilters)]="gridFilters"></app-grid-filter>
                  {{ headerName.name }}
                </th>
              </tr>
            </thead>
            <tbody id="row-data" class="uitk-c-table__body">
              <ng-container *uitkTableRowBody="let subscription">
                <tr class="uitk-c-table__row" [ngStyle]="{ 'background-color' : subscription.subscriptionStatus === 'F' || subscription.hasRenewal ? 'lavender' : 'white' }">
                  <td>
                    <button id="editBtn" [ngStyle]="{ 'background-color' : subscription.subscriptionStatus === 'F' || subscription.hasRenewal ? 'lavender' : 'white' }" (click)="selectedSubscription = subscription; subscriptionEditDialog.show = true" title="Edit subscription" class="uitk-icon uitk-icon-edit sm-icon orange" style="cursor: pointer; background-color:white; border: none;" type="button"></button>
                    <button id="subscriptionHistoryId" [ngStyle]="{ 'background-color' : subscription.subscriptionStatus === 'F' || subscription.hasRenewal ? 'lavender' : 'white' }" (click)="selectedSubscription = subscription; subscriptionHistoryDialog.show = true" title="Subscription history" class="uitk-icon uitk-icon-history sm-icon" style="cursor: pointer; background-color:white; border: none;" type="button"></button>
                    <button id="shipmentHistoryId" [ngStyle]="{ 'background-color' : subscription.subscriptionStatus === 'F' || subscription.hasRenewal ? 'lavender' : 'white' }" (click)="selectedSubscription = subscription; shipmentHistoryDialog.show = true" title="Shipment history" class="uitk-icon uitk-icon-router sm-icon" style="cursor: pointer; background-color:white; border: none;" type="button"></button>
                  </td>
                  <td id="subscriptionNumber">{{ subscription.subscriptionNumber }}</td>
                  <td>{{ subscription.productCode }}</td>
                  <td>{{ subscription.productDescription }}</td>
                  <td>{{ subscription.quantity }}</td>
                  <td>{{ subscription.startDate | date: 'MM/dd/yyyy' }}</td>
                  <td>{{ subscription.term }}</td>
                  <td>{{ subscription.expirationDate | date: 'MM/dd/yyyy' }}</td>
                  <td>{{ subscription.subscriptionStatus }}</td>
                  <td>{{ subscription.subscriptionType }}</td>
                  <td><a target="_blank" class="link" style="text-decoration: none;" (click)="navigateToOrder(subscription.salesOrderNumber)">{{ subscription.salesOrderNumber }}</a></td>
                  <td><a target="_blank" class="link" style="text-decoration: none;" (click)="navigateToSFViewAccount(subscription.salesforceId)">{{ subscription.contactId  }}</a></td>
                  <td style="text-align: center;">
                    <input type="checkbox" id="resultsUpdateOnly" disabled [checked]="subscription.updateOnly">
                  </td>
                  <td>{{ subscription.paymentPlan }}</td>
                </tr>
              </ng-container>
              <div *ngIf="subscriptions.data.length === 0">No records found</div>
            </tbody>
          </table>          
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Dialogs -->
<div *ngIf="subscriptionEditDialog.show">
  <app-subscription-edit [dialog]="subscriptionEditDialog" [subscriptionSearchResult]="selectedSubscription"></app-subscription-edit>
</div>
<div *ngIf="subscriptionHistoryDialog.show">
  <app-subscription-history [dialog]="subscriptionHistoryDialog" [subscriptionSearchResult]="selectedSubscription"></app-subscription-history>
</div>
<div *ngIf="shipmentHistoryDialog.show">
  <app-shipment-history [dialog]="shipmentHistoryDialog" [subscriptionSearchResult]="selectedSubscription"></app-shipment-history>
</div>
