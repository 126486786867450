<div *ngIf="dialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" uitkId="openAndFutureInvoicesPopupOrderLevel"
             id="openAndFutureInvoicesPopupOrderLevel" triggerElementId="save" [closeBtn]=false>
  <uitk-dialog-content>
    <div>
      Do you want to update all open and future invoices for this order?
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="clickYes()" #yesBtn>
      Yes
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
            (click)="clickNo()">
      No
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>