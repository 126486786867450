import { Component, Input, OnInit } from '@angular/core';
import { Mode } from '../../../../enums/mode.enum';
import { OrderService } from '../../../../services/order.service';

@Component({
  selector: 'app-amakey',
  templateUrl: './amakey.component.html',
  styleUrls: ['./amakey.component.css'],
})

export class AMAKeyComponent implements OnInit {
  @Input() dialog = { show: false };
  amaKey: string | null = '';
  Mode = Mode;

  constructor(
    public orderService: OrderService
  ) {}

  ngOnInit(): void {
    if (this.orderService.orderDto.amaKey === null) {
      this.orderService.orderDto.amaKey = '';
    }

    this.amaKey = this.orderService.orderDto.amaKey;
    this.orderService.triggerEditMethod();
  }

  closeDialog() {
    this.dialog.show = false;
    this.amaKey = this.orderService.orderDto.amaKey;

    if (this.orderService.editWasTriggered) {
      this.orderService.triggerSaveMethod(false);
    }
  }

  saveAmaKey() {
    this.dialog.show = false;
    if(this.amaKey?.trim() ==='')
    {
      this.orderService.orderDto.amaKey = null;
    }
    else
    {
    this.orderService.orderDto.amaKey = this.amaKey;
    }

    if (this.orderService.editWasTriggered) {
      this.orderService.triggerSaveMethod(false);
    }
  }

  copyAmaKey() {
    const listener = (e: ClipboardEvent) => {
      e.clipboardData?.setData('text/plain', this.amaKey!);
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }
}
