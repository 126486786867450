import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReturnComponent } from './return/return.component';
import { BillToComponent } from '../bill-to/bill-to.component';
import { OrderByComponent } from '../order-by/order-by.component';
import { ShipToComponent } from '../ship-to/ship-to.component';
import { Mode } from '../../enums/mode.enum';
import { OrderDto } from '../../dtos/order-dto.model';
import { OrderSearchResult } from '../../dtos/order-search-result.model';
import { ConfigService } from "../../services/config.service";
import { ModeService } from '../../services/mode.service';
import { OrderService } from '../../services/order.service';
import { ReturnSearchResultsComponent } from './return-search-results/return-search-results.component';

@Component({
  selector: 'app-return-inquiry',
  templateUrl: './return-inquiry.component.html',
  styleUrls: ['./return-inquiry.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ReturnInquiryComponent implements OnInit, OnDestroy {
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _configService: ConfigService;
  private readonly _titleService: Title;
  configIsReadySubscription: Subscription | undefined;
  @Input() batchReturn: any;
  @Input() returnDtoList: OrderDto[] = [];
  @Input() isnavigateFromBatchReturn: boolean = false;
  @ViewChild(ReturnComponent) returnComponent: ReturnComponent | undefined;
  @ViewChild(BillToComponent) billToComponent: BillToComponent | undefined;
  @ViewChild(OrderByComponent) orderByComponent: OrderByComponent | undefined;
  @ViewChild(ShipToComponent) shipToComponent: ShipToComponent | undefined;
  @ViewChild(ReturnSearchResultsComponent) SearchResults: ReturnSearchResultsComponent | undefined;
  hideSearchBar = true;
  @Output() getResponse = new EventEmitter;
  showBackToSearchResults = true;
  gotOrderSearchResultsSubscription: Subscription | undefined; totalRecords = 0;
  totalCountResult = 0;
  isNext: boolean = false;
  isPrev: boolean = false;
  usable: boolean = false;
  currentOrderIndex: any;
  tempOrderSearchResults: OrderSearchResult[] = [];
  Mode = Mode;

  constructor(activatedRoute: ActivatedRoute,
    public orderService: OrderService,
    configService: ConfigService,
    public modeService: ModeService,
    titleService: Title) {
    this._activatedRoute = activatedRoute;
    this._configService = configService;
    this._titleService = titleService;
  }

  ngOnInit(): void {
    if (this.batchReturn === true) {
      this.hideSearchBar = false;
    }
    else{
      this.configIsReadySubscription = this._configService.configIsReady.subscribe(() => {
        if (Object.keys(localStorage).indexOf('searchOrderResults') > -1) {
          this.totalCountResult = JSON.parse(localStorage.getItem('searchOrderResults') as any).totalRecords;
          this.tempOrderSearchResults = JSON.parse(localStorage.getItem('searchOrderResults') as any).orderSearchResults;
        }
        const selectedOrderId = this._activatedRoute.snapshot.params.returnId;
       this.orderService.getOrderInformation(selectedOrderId);
       this._titleService.setTitle("Return: "+this._activatedRoute.snapshot.params.returnId);
       this.currentOrderIndex = this.SearchResults?.currentindex || 0;
       if (Object.keys(localStorage).indexOf('searchOperator') > -1) {
        let searchOperator: any = localStorage.getItem('searchOperator');
        searchOperator = JSON.parse(searchOperator);

        if (Number(this.currentOrderIndex) === 0) {
          const index: number = this.tempOrderSearchResults.findIndex(o => o.orderNumber === Number(selectedOrderId));
          this.currentOrderIndex = Number(searchOperator.pageSize) * (Number(searchOperator.pageNumber) - 1) + index + 1;
        }
      }
      if (Number(this.totalCountResult) === 1) {
        this.isPrev = true;
        this.isNext = true;
      }
      else {
        this.isPrev = false;
        this.isNext = false;
      }

      if (this.currentOrderIndex <= 1) {
        this.isPrev = true;
      }
      else {
        this.isPrev = false;
      }

      if (this.currentOrderIndex === this.totalCountResult) {
        this.isNext = true;
      }
      else {
        this.isNext = false;
      }

        localStorage.setItem('backToSearchResults', 'false');

        if (Object.keys(localStorage).indexOf('searchOperator') > -1) {
          const searchFilters: any = JSON.parse(localStorage.getItem('searchOperator')!);

          if (searchFilters.filters.length === 0) {
            this.showBackToSearchResults = false;
          }
        }
        else {
          this.showBackToSearchResults = false;
        }
      });

    this._activatedRoute.params.subscribe(params => {
    this._configService.loadConfigurations();
  });
    }

  }

  getOrderData() {
    this.orderByComponent?.getOrderData();
    this.shipToComponent?.getOrderData();
  }

  ngOnDestroy() {
    this.configIsReadySubscription?.unsubscribe();
  }
}
