<div [ngClass]="panelBackground" class="panel">
  <uitk-panel uitkId="orderSearchPanel" [collapsible]="collapsiblePanel" [open]="openPanel"
              (openChange)="setPanelBGHeight()">
    <uitk-panel-header-title>
      <h2>Accounts Receivable Search</h2>
    </uitk-panel-header-title>
    <uitk-panel-content>
      <div class="uitk-l-grid__row" (keyup.enter)="search()">
        <div class="uitk-l-grid__col">
          <div class="uitk-l-grid__col--3">
            <label uitkLabel>Account #</label>
          </div>
          <div class="uitk-l-grid__col--6">
            <uitk-form-field>
              <input uitkInput id="accountNumber" class="textbox" type="text" [(ngModel)]="accountId"
                     autofocus  oninput="validity.valid || (value = '');" />
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__col uitk-u-flex__align--center">
          <div class="uitk-l-grid__col--3">
            <label uitkLabel>Invoice #</label>
          </div>
          <div class="uitk-l-grid__col--6">
            <uitk-form-field>
              <input uitkInput id="invoice" class="textbox" type="text" [(ngModel)]="invoiceNumber"
                      oninput="validity.valid || (value = '');" />
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__col uitk-u-flex__align--right">
          <div class="uitk-l-grid__col--3">
            <label uitkLabel>Order #</label>
          </div>
          <div class="uitk-l-grid__col--6">
            <uitk-form-field>
              <input uitkInput id="orderNumber" class="textbox" type="text" [(ngModel)]="orderNumber"
                     oninput="validity.valid || (value = '');" />
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div id="searchDiv" class="uitk-l-grid__row ">
        <div class="uitk-u-flex__align--left">
          <button (click)="search()" id="searchBtn" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--lg">
            Search
          </button>
          <a (click)="clear()" class="link" appTabTitle="A/R Inquiry">Clear</a>
        </div>
      </div>
    </uitk-panel-content>
  </uitk-panel>
</div>
 <div class="col-12" style="padding-top: 400px" [hidden]="!modeService.isWaiting">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div id="loadingIndicator">
          <uitk-loading-indicator uitkId="spinner" spinnerSize="large">
          </uitk-loading-indicator>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col d-flex justify-content-center" >
        <h5>
          {{modeService.waitDescription}}
        </h5>
      </div>
    </div>
  </div>
</div>
<app-accounts-receivable-results [hidden]="modeService.isWaiting || !isSearch" [invoiceNumber]="invoiceNumber" [invoiceFilter]="invoiceFilter" [cmFilter]="cmFilter"  (invoiceEdited)="reload()"></app-accounts-receivable-results>
