<div *ngIf = "dialog.show" class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog uitkId="orderAttachmentsDialog" [(showModal)]="dialog.show" 
    [closeBtn]="false" triggerElementId="OrderAttachmentsOE"
    [width]="'1000px'"
    id="orderAttachmentsDialog">
    <uitk-dialog-header>
        <div cdkDragHandle>
            Order Attachments
        </div>
    </uitk-dialog-header>
    <uitk-dialog-content scrollToTarget>        
        <div class="container-fluid">
            <div class="row">
                <table uitktablecontainer uitkTable [dataSource]="orderAttachmentsList" id="basic-table">
                    <thead>
                        <tr uitkTableSort #sortTable="uitkTableSort">
                            <th scope="col" style="width: 40px;"></th>                                    
                            <th uitkTableSortColumn [column]="headerName.id" (sortChange)="onSortChange($event)"
                                *ngFor="let headerName of tableHeader" scope="col"
                                [ngClass]="(headerName.name == 'File Size' || headerName.name== 'Created Date') ? 'right-align' : 'left-align'"
                            >
                                {{ headerName.name }}
                            </th>
                        </tr>
                    </thead>
                    <tbody id="row-data">
                        <ng-container *uitkTableRowRenderer="let orderAttachment">
                            <tr>
                                <td>
                                    <span class="uitk-icon uitk-icon-trash_delete gridIcons" [customDisabled]="disableDelete(orderAttachment)"
                                    appEnableResource="OrderInquiryOrderAttachmentsDelete" (click)="openDeleteSourceCodePopup(orderAttachment)"></span>
                                </td>

                                <td *ngIf="orderAttachment.id !== 0"><a class="link" href="javascript:void(0)" 
                                    (click)="downloadOrderAttachmentFile(orderAttachment.fileName, orderAttachment.orderId)">{{orderAttachment.fileName}}</a></td>

                                <td style="text-align: right;">{{ this.formatBytes(orderAttachment.fileSize) }}</td>
                                <td style="text-align: right;">{{orderAttachment.createdDate | date }}</td>                                        
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </uitk-dialog-content>

    <uitk-dialog-actions>
        <button id="add" type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
            appEnableResource="OrderInquiryOrderAttachmentsDelete"
            (click)="openFilePickerDialog()" appAutoFocus>Add</button>         
        <button id="cancel" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
            appEnableResource="OrderInquiryOrderAttachmentsDelete"
            (click)="cancelDialog()">Close</button>

    </uitk-dialog-actions>
</uitk-dialog>
    </div>
</div>
<div class="divPopUpContent" >
    <app-order-attachments-file-picker *ngIf="filePickerPopup.show" 
    [filePickerPopup]="filePickerPopup" [filesList]="orderAttachmentsList.data" [orderId]="orderService.orderDto.id" [enteredBy]="currentUser" (newOrderAttachmentAdded)="newAttachmentAdded($event)">
    </app-order-attachments-file-picker>
</div>

<div *ngIf = "deleteOrderAttachmentPopup.show" class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog [(showModal)]="deleteOrderAttachmentPopup.show" [closeBtn]=false>
    <uitk-dialog-content scrollToTarget>
      Are you sure you want to delete this Order Attachment?
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button id="yesbtn" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="deleteOrderAttachment()" appAutoFocus>
        Yes
      </button>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="cancelDeleteOrderAttachment()">
        No
      </button>
    </uitk-dialog-actions>
</uitk-dialog>
    </div>
</div>
<div *ngIf = "cancelOrderAttachmentPopup.show" class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog [(showModal)]="cancelOrderAttachmentPopup.show" [closeBtn]=false>
    <uitk-dialog-content scrollToTarget>
      Are you sure you want to discard unsaved Order Attachments?
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button id="discardyesbtn" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="discardOrderAttachments()" appAutoFocus>
        Yes
      </button>
      <button id="discardnobtn" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="donotDiscardOrderAttachments()">
        No
      </button>
    </uitk-dialog-actions>
</uitk-dialog>
    </div>
</div>
