import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ScrollToDirective } from '../../directives/scrollTo.directive';
import { Dialog } from '../../helpers/dialog';
import { ConfigService } from '../../services/config.service';
import { ContactService } from '../../services/contact.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-require-email-dialog',
  templateUrl: './require-email-dialog.component.html',
  styleUrls: ['./require-email-dialog.component.css']
})
export class RequireEmailDialogComponent {
  @Input() dialog!: Dialog<string>;
  @Input() productType!: string;
  @Input() electronicProductCodesOnly!: string;
  @Input() eAlertProductCodesOnly!: string;
  @Input() targetEleToScroll: string="#divLeftOrderDetailsGrid";
  @Input() triggerEleToFocus!: string;

  @ViewChild(ScrollToDirective) dirs:any;
  constructor(
    public readonly orderService: OrderService,
    private readonly contactService: ContactService,
    private readonly configService: ConfigService
    ) { }

  async refreshContactDetailsAsync(): Promise<void> {
    await this.contactService.getContactsAsync(this.orderService.orderDto.billToDto.id);
    
    if (this.orderService.orderDto.orderByDto.id === this.orderService.orderDto.shipToDto.id) {
      this.orderService.orderDto.orderByDto.email = this.orderService.orderDto.shipToDto.email =
        this.contactService.contactDtos?.filter(c => c.contactId === this.orderService.orderDto.shipToDto.contactId)[0].email!;
    }
    else {
      this.orderService.orderDto.shipToDto.email =
        this.contactService.contactDtos?.filter(c => c.contactId === this.orderService.orderDto.shipToDto.contactId)[0].email!;  
    }
  }

  navigateToSalesForceEditContact(salesforceContactId: string): void {
    window.open(`${this.configService.salesforceUrl}/apex/NewContactPage?id=${salesforceContactId}`);
  }

  onModalClosed(event:any){   
    this.dirs.target = "#divLeftOrderDetailsGrid";
    this.dirs.scrollToPosition();
  }
  okButtonClick(): void {
    if (!this.orderService.orderDto.shipToDto.email) {
      switch (this.productType) {
        case 'Electronic':
          this.dialog.close('OkWithoutEmailForElectronic');
          break;
        case 'E-Alert':
        case 'Both Electronic and E-Alert':
          this.dialog.close('OkWithoutEmail');
          break;
      }
    }
    else {
      this.dialog.close('OkWithEmail');
    }
  }

  cancelButtonClick(): void {
    this.dialog.close('Cancel');   
  }
}
