import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IUITKColumnState, IUITKSelectItemProps, UITKSortDirection, UITKTableDataSource, UITKTableSortDirective } from '@uitk/angular';
import { Mode } from '../../enums/mode.enum';
import { ContactDto } from '../../dtos/contact-dto.model';
import { ContactService } from '../../services/contact.service';
import { ModeService } from '../../services/mode.service';
import { OrderService } from '../../services/order.service';
import { SwalAlert } from '../../helpers/alert';

@Component({
  selector: 'app-contact-search',
  templateUrl: './contact-search.component.html',
  styleUrls: ['./contact-search.component.css']
})
export class ContactSearchComponent {
  @Input() isShipToDisabled = true;
  @Input() isShipToContact = true;
  Mode = Mode;
  spinnerText = "Loading Data";
  dataSource = new UITKTableDataSource<ContactDto>([]);
  data: ContactDto[] = [];
  contacts: ContactDto[] = [];
  contactList: IUITKSelectItemProps[] = [];
  searchtxt='';
  @ViewChild('sortTable') uitkTableSort!: UITKTableSortDirective;

  tableHeader = [
    { name: 'Contact ID', id: 'contactId' },
    { name: 'Name', id: 'fullName' },
    { name: 'Address', id: 'street' },
    { name: 'Phone', id: 'phone' },
    { name: 'Email', id: 'email' }

  ];
  contactSearch = { show: false };
  swalAlert = new SwalAlert();
  constructor(public orderService: OrderService, public contactService: ContactService, public modeService: ModeService) { }

  contactSelect(contactId:any)
  {
    this.contactList = this.contactService.convertContactsToUitkProps(this.contacts);
    if(this.isShipToContact)
    {
     this.orderService.orderDto.shipToContactBinding = this.contactList.filter(x => x.id === contactId.toString())[0];
    }
    else
    {
      this.orderService.orderDto.orderByContactBinding = this.contactList.filter(x => x.id === contactId.toString())[0];
    }
    this.contactSearch.show=false;
  }
  getContacts()
  {
    this.orderService.triggerEditMethod();
    this.searchtxt='';
    this.getContactList();
    this.contactSearch.show = true;
  }
  getContactList() {
    if (this.orderService.orderDto.billToDto.id > 0) {
      this.contactService.getContactInformation(this.orderService.orderDto.billToDto.id).subscribe(
        (data: any) => {

          this.data = [];
          for (const contact of data) {
           this.isFirstAndLastNameNull(contact);
           this.isEmailAndCompanyNull(contact);
            
            if (contact.department === null)
            {
              contact.department = "";
            }
            this.isAddressObjectNull(contact);
            if (contact.zip === null){
              contact.zip = "";
            }
            this.checkIsPhoneDataIsNull(contact);
             contact.fullName = contact.firstName+" "+contact.lastName;
            this.data.push(contact);

          }
          this.contacts =  this.data;
          this.dataSource.data = this.data;
          //Below line will reset to default sort
          document.getElementById("fullName")?.click();
          this.uitkTableSort.setDirection({ column: 'fullName', direction: UITKSortDirection.ASC });


        }, error => {
          console.log(error);
          this.swalAlert.alert('An error occurred while getting Contact details. Please try again.');
        }

      );
    }
  }

  checkIsPhoneDataIsNull(contact:ContactDto){
    if (contact.phone === null)
    {
      contact.phone = "";
    }
  }

  isFirstAndLastNameNull(contact:ContactDto){
    if (contact.firstName === null)
    {
      contact.firstName = "";
    }
    if (contact.lastName === null)
    {
      contact.lastName = "";
    }
  }

  isEmailAndCompanyNull(contact:ContactDto){
    if (contact.email === null)
    {
      contact.email = "";
    }
    if (contact.company === null)
    {
      contact.company = "";
    }
  }

  isAddressObjectNull(contact:ContactDto){
    if(contact.street === null){
      contact.street = "";
    }    
    if(contact.city === null){
      contact.city = "";
    }
    if(contact.state === null){
      contact.state = "";
    }
  }
  onChange(event:any)
  {
    if(this.contacts !=null)
    {
      const filter = this.contacts.filter(item => (item.fullName + " " + item.email + " " + item.department + " " +
        item.street + " " + item.company + " " + item.city + " " + item.state + " " + item.zip + " " + item.phone + " " + item.contactId)
        .toUpperCase().trim().includes(event.srcElement.value.toUpperCase().trim()));
     this.dataSource.data = [...filter];
    }
  }
  ngAfterViewInit() {
   // sets initial sort direction
    this.uitkTableSort.setDirection({ column: 'fullName', direction: UITKSortDirection.ASC });
    this.dataSource.sort = this.uitkTableSort;
}

onSortChange(event: IUITKColumnState): void {
  this.dataSource.data = this.dataSource.sortData(this.dataSource.data, event.direction, event.column);
}

}
