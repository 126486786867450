import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, async } from 'rxjs';
import { Mode } from '../enums/mode.enum';
import { ConfigService } from './config.service';
import { ModeService } from './mode.service';
import { SearchInvoiceByCCSearchResultsDto } from '../dtos/searchinvoice-by-cc-searchresults.model';
import { SwalAlert } from '../helpers/alert';

@Injectable({
    providedIn: 'root'
})


export class SearchInvoiceByCCService
{
    gotSearchResultsEmitter = new Subject<SearchInvoiceByCCSearchResultsDto[]>();
    error = new Subject();
    Invoices: SearchInvoiceByCCSearchResultsDto[] | null = null;
    filterObj: any;
    private readonly _httpClient!: HttpClient;
    private readonly _modeService!: ModeService;
    private readonly _configService!: ConfigService;
    salesForceID:string='';
    swalAlert = new SwalAlert();

    constructor(httpClient: HttpClient, modeService: ModeService, configService: ConfigService) {
        this._httpClient = httpClient;
        this._modeService = modeService;
        this._configService = configService;
    }

    searchInvoices(errorMode: Mode, filtersObj: any, overrideWait: boolean = false): void {
        this._modeService.mode = overrideWait ? Mode.View : Mode.InvoiceByCCWait;

        this._httpClient.post<SearchInvoiceByCCSearchResultsDto[]>(`${this._configService.accountingApiUrl}/SearchInvoiceByCC`, filtersObj)
            .subscribe((data: SearchInvoiceByCCSearchResultsDto[]) => {
            this.Invoices = data;
            console.log("in service",data);
            this.filterObj = filtersObj;
            this._modeService.mode = Mode.View;
            this.gotSearchResultsEmitter.next(data);
        }, (error: any) => {
            console.log(error);
            this._modeService.mode = errorMode;
            this.swalAlert.alert('An error occurred while getting a invoices list.  Please try again.');
        });
    }
    getSalesforceAccountId(accountId: string):Observable<string>{
      return  this._httpClient.get<string>(`${this._configService.apiUrl}/Order/GetSalesforceAccountId/`+accountId);
      }
}