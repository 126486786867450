<div class="container-fluid px-0">
  <!-- Header of the Order By section -->
  <div class="row">
    <div class="col d-flex">
      <div class="borderline"></div>
      <div class="flex-grow-1 align-self-center mt-2">
        <h5 class="text-dark"><strong>Order By</strong></h5>
      </div>
      <div class="uitk-icon uitk-icon-refresh sm-icon align-self-center">
        <a (click) ="refreshOrderByContactList()" class="link" appEnableResource="OrderByRefreshHyperlink"> Refresh</a>
      </div>
      <div class ="uitk-icon uitk-icon-edit sm-icon align-self-center mx-2">
        <a (click)="navigateToSFNewOrEditContact(orderService.orderDto.orderByDto.salesforceId)" class="link"
         target="_blank" appEnableResource="OrderByEditHyperlink"> Edit</a>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <hr class="m-0 bg-dark">
    </div>
  </div>

  <!-- Contact selection and view icon   -->
  <div class="row mt-2 px-1" [hidden]="modeService.waitDescription === 'Getting order by contacts'">
    <div class="col d-flex px-3" bbLogTable="OrderHeader" bbLogColumn="ContactId" [bbLogPrimaryId]="orderService.orderDto.id">
      <uitk-form-field class="align-self-center flex-grow-1">
        <uitk-dropdown uitkId="orderByContact" [items]="contactList" [itemSortOn]="true"
                      (ngModelChange)="setSelectedValue($event)" [(ngModel)]="orderService.orderDto.orderByContactBinding"
                      [disabled]="modeService.mode !== Mode.Edit || isOrderByDisabled" >
        </uitk-dropdown>
      </uitk-form-field>
      <div class="mx-1"></div>
      <app-contact-search [isShipToContact]="false" [isShipToDisabled]= "isOrderByDisabled"></app-contact-search>
      <div class="mx-1"></div>
      <button name="viewBtn" id="viewBtn"
              class="uitk-c-button uitk-c-button--ghost uitk-icon uitk-icon-view px-0 py-1 mt-1" type="button"
              (click)="navigateToSFViewContact(orderService.orderDto.orderByDto.salesforceId)" appEnableResource="OrderByContactEyeball"></button>
    </div>
  </div>

  <!-- Email and Phone -->
  <div class="row px-3 mt-2" [hidden]="modeService.waitDescription === 'Getting order by contacts'">
    <div class="col">
      <div class="row my-0 py-0">
        <label class="col-lg-3">Email</label>
        <div class="col-lg-9">
          {{orderService.orderDto.orderByDto.email}}
        </div>
      </div>
    </div>
  </div>
  <div class="row px-3" [hidden]="modeService.waitDescription === 'Getting order by contacts'">
    <div class="col">
      <div class="row">
        <label class="label col-lg-3">Phone</label>
        <div class="col-lg-9">
          {{orderService.orderDto.orderByDto.phone | phoneFormat}}
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col">
      <hr class="m-0">
    </div>
  </div>

  <!-- Email Info and Email Promo -->
  <div class="row px-2" [hidden]="modeService.waitDescription === 'Getting order by contacts'">
    <div class="col-sm-6">
      <uitk-form-field>
        <input uitkCheckbox id="orderbyemailInfo" type="checkbox" appEnableResource="OrderByEmailInfoCheckbox"
              [(ngModel)]="orderService.orderDto.orderByDto.allowInformationalEmails" [disabled]="modeService.mode !== Mode.Edit" />
        <label uitkCheckboxLabel id="eInfoOrderByLabel" for="orderbyemailInfo" style="color: #282A2E">Email Info</label>
      </uitk-form-field>
    </div>
    <div class="col-sm-6">
      <uitk-form-field>
        <input uitkCheckbox id="orderbyemailPromo" type="checkbox" appEnableResource="OrderByEmailPromoCheckbox"
              [(ngModel)]="orderService.orderDto.orderByDto.allowPromotionalEmails" [disabled]="modeService.mode !== Mode.Edit" />
        <label uitkCheckboxLabel id="ePromoOrderByLabel" for="orderbyemailPromo" style="color: #282A2E">Email Promo</label>
      </uitk-form-field>
    </div>
  </div>

  <!-- Spinner -->
  <div class="container-fluid" [hidden]="modeService.waitDescription !== 'Getting order by contacts'">
    <div class="row">
      <div class="col">
        <div id ="loadingIndicator">
          <uitk-loading-indicator uitkId ="spinner" [visibleText]="visibleTextValue" spinnerSize="large">
            </uitk-loading-indicator>
        </div>
      </div>
    </div>
  </div>
</div>
