<div class="container-fluid px-0" style="overflow-x: hidden;">
  <!-- Result search navigation bar -->
  <div class="row mx-0 bg-darkgrey" *ngIf="orderId && !isReship">
    <div class="col-12">
      <app-search-navigation-bar></app-search-navigation-bar>
    </div>
  </div>
  <div class="row gx-0">
    <div class="col-12">
      <div class="container-fluid px-4 my-3">
        <div class="row gx-0 mt-4">
          <div class="col">
            <div class="container-fluid mx-4 my-3">
              <div class="row">
                <div class="col-6">
                  <span class="headerTextClass">Order Entry:</span>
                </div>
                <div class="col-6">
                  <button id="viewMYOrdersBtn" type="button"
                    class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs float-end"
                    (click)="showMultiYearOrders()">
                    View MY Orders
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <app-order-header></app-order-header>
                </div>
              </div>
              <hr class="hr-width">
              <div class="row">
                <div class="col">
                  <app-account-information  (getContactList)="getContactListForAccount()"></app-account-information>
                </div>
              </div>
              <hr class="hr-width">
              <div class="row">
                <div class="col">
                  <app-order-by-order-entry [event]="getAllContactList"></app-order-by-order-entry>
                </div>
              </div>
              <hr class="hr-width">
              <div class="row">
                <div class="col">
                  <app-ship-to-order-entry (addressChanged)="changeShippingAndHandlingBR('ContactAddress', null); onChangeShipToAddress($event)" ></app-ship-to-order-entry>
                </div>
              </div>
              <hr class="hr-width">
              <div class="row" *ngIf="(this.approvalService.showApprovalHistoryOnQuote && orderService.orderDto.orderStatus !== 'W')  || orderService.orderDto.orderStatus === 'P' || orderService.orderDto.orderStatus === 'R' || orderService.orderDto.orderStatus === 'M' || this.orderService.isSnapshot">
                <div class="col">
                  <app-approval-history></app-approval-history>
                </div>
              </div>
              <div class="row"
                *ngIf="(orderService.orderDto.orderType === 'Q' && !this.approvalService.showApprovalHistoryOnQuote) || (orderService.orderDto.id > 0 && orderService.orderTypeChangedFromQuoteToOrder && !this.approvalService.showApprovalHistoryOnQuote) || orderService.orderDto.orderStatus === 'D' || orderService.orderDto.orderStatus === 'W'  || orderService.orderDto.orderStatus === ''">
                <div class="col" *ngIf = "!this.orderService.isSnapshot">
                  <app-approval-requirements></app-approval-requirements>
                </div>
              </div>
              <hr class="hr-width">
              <div class="row">
                <div class="col">
                  <app-order-details-grid #orderGrid (changePayMeth)="changePaymentMethode()" (addedShippingItem)="this.changeShippingAndHandlingBR('ShippingItemAdded', null)" (adjustedLineItem)="adjustedLineItem($event)" (changePromoCode)="changePromoCode($event)"  (addedPremiumShippingItem)="this.changeShippingAndHandlingBR('PremiumShippingItemAdded', null)"></app-order-details-grid>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <app-order-summary-section (addCheckOrCashRow)="onAddCheckOrCashRow()" (changedShipVia)="changeShippingAndHandlingBR('ShipVia', null)">
                  </app-order-summary-section>
                </div>
              </div>
              <br />
              <div class="row mt-5 pblank-5" *ngIf = "!this.orderService.isSnapshot">
                <div class="col">
                  <app-order-entry-footer [newMyOrder]="newMYOrder"></app-order-entry-footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div *ngIf="showPackingSlipNotesDialog.show">
  <app-packing-slip-notes [dialog]="showPackingSlipNotesDialog" (cancel)="revertShipViaValue()" (save)="savePackingSlipNotes()" triggerEleId="shipViaDD"></app-packing-slip-notes>
</div>
<div *ngIf="showViewMultiYearOrdersPopup.show">
  <app-view-multi-year-orders [dialog]="showViewMultiYearOrdersPopup"></app-view-multi-year-orders>
</div>
<div *ngIf="recentQuotesOrOrdersDialog.show">
  <app-recent-quotes-or-orders-dialog [recentQuotesOrOrdersDialog]="recentQuotesOrOrdersDialog">
  </app-recent-quotes-or-orders-dialog>
</div>
<div class="divPopUpContent" *ngIf="configSetDialog.show">
  <app-config-sets [dialog]="configSetDialog" [orderDetail]="reshipOrderDetail">
  </app-config-sets>
</div>