import { formatDate } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Observable, of, Subject } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import * as states from 'src/assets/json/states_USA.json';
import { Mode } from '../enums/mode.enum';
import { SourceType } from '../enums/source-type.enum';
import { Dialog } from '../helpers/dialog';
import { AddYearDto } from '../dtos/add-year-dto';
import { DataFileAccessDto } from '../dtos/data-file-access-dto.model';
import { DepositDto } from '../dtos/deposits-dto.model';
import { InvoiceLineItemDto } from '../dtos/invoice-line-item-dto.model';
import { ItemOfferDto } from '../dtos/item-offer-dto';
import { ItemOfferInputDto } from '../dtos/item-offer-input-dto.model';
import { MultiYearDto } from '../dtos/multi-year-dto.model';
import { OfferDto } from '../dtos/offer-dto.model';
import { OfferInfoDto } from '../dtos/offer-info-dto';
import { OrderDetailDto } from '../dtos/order-detail-dto.model';
import { OrderDto } from '../dtos/order-dto.model';
import { OrderNumberDto } from '../dtos/order-number-dto.model';
import { OrderOfferDto } from '../dtos/order-offer-dto';
import { OrderOfferInputDto } from '../dtos/order-offer-input-dto.model';
import { OrderSearchInputDto } from '../dtos/order-search-input-dto.model';
import { OrderSearchResultsDto } from '../dtos/order-search-results-dto.model';
import { PackingSlipDto } from '../dtos/packing-slip-dto.model';
import { ProductDto } from '../dtos/product-dto.model';
import { ReservationDto } from '../dtos/reservation-dto.model';
import { ShipAndProcessDto } from '../dtos/ship-and-process-dto.model';
import { AccountingService } from './accounting.service';
import { AuthorizationService } from './authorization.service';
import { ConfigService } from './config.service';
import { ModeService } from './mode.service';
import { PricingService } from './pricing.service';
import { ShippinghandlingService } from './shippinghandling.service';
import { TaxService } from './tax.service';
import { SubscriptionDto } from '../dtos/subscription-dto.model';
import { OpportunityDto } from '../dtos/opportunity-dto.model';
import { ContactDto } from '../dtos/contact-dto.model';
import { CfdFormFieldsDto } from '../dtos/cfd-form-field-selection-dto.model';
import { OrderAttachmentDto } from '../dtos/order-attachment-dto.model';
import { promise } from 'protractor';
import { AccountSearchFinalDto } from '../dtos/account-search-input-dto';
import jsPDF from 'jspdf';
import { CfdDto } from '../dtos/cfd-dto.model';
import { RecentQuotesOrOrdersDto } from '../dtos/recent-quotes-or-orders-dto.model';
import { ErrorHandlingService } from './error-handling.service';
import { SwalAlert } from '../helpers/alert';
import { AdditionalReturnInfoDto } from '../dtos/additional-return-info-dto-model';
import { resolve } from 'dns';
import { BlockLike } from 'typescript';
import { FileDto } from '../dtos/file-dto-model';

@Injectable({
  providedIn: 'root'
})

export class OrderService {
  //private shippinghandlingService!: ShippinghandlingService;
  //private taxService!: TaxService;
  gotOrderSearchResultsEmitter = new Subject<OrderSearchResultsDto>();
  getOrderOfferResultsEmitter = new Subject<OrderOfferDto>();
  onSaveAndClone = new Subject();
  orderIsReady = new Subject();
  returnIsReady = new Subject();
  onTriggerSave = new Subject();
  onTriggerEdit = new Subject();
  onTriggerRevert = new Subject();
  editWasTriggered = false;
  onReturnTriggerSave = new Subject();
  onReturnTriggerEdit = new Subject();
  onReturnTriggerRevert = new Subject();
  returnEditWasTriggered = false;
  offerDto: OfferDto = new OfferDto();
  orderDto: OrderDto = new OrderDto();
  orders: OrderSearchResultsDto | null = null;
  filterObj: OrderSearchInputDto = new OrderSearchInputDto();
  reservationDtos: ReservationDto[] = [];
  invoiceLineItems: InvoiceLineItemDto[] = [];
  returnDtos: OrderDto[] = [];
  nextOrderDetailId = -1;
  displayShipToLink = false;
  displayShipToLinkSaveButton = false;
  flagforitemdialog= true;
  validateShipping = false;
  isShippingError = false;
  orderStatus = 'Loading ...';
  enableShipToOnClosedOrder = false;
  saveAfterConfigSet = false;
  arsLabelInDb: string | null = '';
  paymentMethodInDb: string | null = '';
  skipFutureOrderSave = false;
  orderDtoBackup: OrderDto = new OrderDto();
  configurationOrderDetail: OrderDetailDto = new OrderDetailDto();
  couponExcessAmount = 0;
  couponItemCheck = { show: false };
  additionalContactsSource = '';
  orderPaymentTerms: string | null = '';
  shippingDialogModalTriggerEle = "";
  shippingDialogModal = new Dialog<string>();
  confirmationEmailDialog = new Dialog<string>();
  confirmAdditionalContactsDialog = new Dialog<string>();
  configSetDialog = new Dialog<string>();
  selectContactsDialog = new Dialog<string>();
  applyChangesToFutureDialog = new Dialog<string>();
  orderDetailDtosBackup: OrderDetailDto[] = [];

  orderCache!: OrderDto | null;
  orderCachedObservable!: Observable<OrderDto> | null;
  orderCachedPromise!: Promise<OrderDto> | null;
  returnsCache!: OrderDto[] | null;
  returnsCachedObservable!: Observable<OrderDto[]> | null;
  unlockOrderCache!: string | null;
  unlockOrderCachedObservable!: Observable<string> | null;
  addYearOrderCache!: AddYearDto | null;
  addYearOrderCachedObservable!: Observable<AddYearDto> | null;
  accountCommentsCache!: string[] | null;
  accountCommentsCachedObservable!: Observable<string[]> | null;
  setLockOrderCache!: OrderDto | null;
  setLockOrderCachedObservable!: Observable<OrderDto> | null;
  offerCache!: OfferDto | null;
  offerCachedObservable!: Observable<OfferDto> | null;
  setWebReviewedCache!: string | null;
  setWebReviewedrCachedObservable!: Observable<string> | null;
  lockOrderComplete = new Subject<void>();
  offerInfoDtoCache!: OfferInfoDto | null;
  offerInfoDtoCachedObservable!: Observable<OfferInfoDto> | null;
  disableAmaKeyButton = new Subject<boolean>();
  stateList: IUITKSelectItemProps[] = [];
  isPaymentPlanDisabled: boolean = false;
  sourceType: SourceType = SourceType.OrderInquiryCalculateTax;
  disableMultiYearBtn: boolean = true;
  selectedSubscriptionOrderId= 0;
  OrderIdDataFile:number[] =[];
  revertReturnCode = new Subject();
  saveMessage = 'Saving Order';
  orderEntryFunctionType = '';
  isSnapshot = false;
  selectedShipToContact: ContactDto | null = null;
  cfdFormFields : CfdFormFieldsDto = new CfdFormFieldsDto();
  accountIdChanged=false;
  selectedContact:string = '';
  selectedAccount:string = '';
  shiptoSectionSourceType = 0;
  shiptoIsSelected = false;
  isShippingInconsistent = false;
  orderTypeChangedFromQuoteToOrder = false;
  orderTypeBackUp = '';
  openAmountBackUp =0;
  getOrderSnapshotAsyncResult!: Promise<HttpErrorResponse | null>;
  resolveEdit: any;
  selectedsalesPersonName:string | null = null;
  searchsalesPersonName:string | null = null;
  salesPersonDisabled = true;
  salesSearchKeyword = '';
  orderEntryFlag=true;
  salesPerson: any;
  disableAccountEditViewRefreshLinks = true;
  isClosedOrderSaveNotes = false;
  orderTypeList: IUITKSelectItemProps[] = [];
  isOrderInRejectedStatus = false;
  groupAccount='';
  previousShippingCharge = 0;
  currentShippingCharge = 0;
  impersonateTypes: string = '';
  validUpto = 30;
  swalAlert = new SwalAlert();
  phoneNumber= '';
  displayProFormaInvoice = false;
  returnAuthReason='';
  returnDetails: OrderDetailDto[] = [];
  noDock = false;
constructor(
    private readonly _httpClient: HttpClient,
    private readonly _modeService: ModeService,
    private readonly _configService: ConfigService,
    private readonly _pricingService: PricingService,
    private readonly _accountingService: AccountingService,
    private readonly _authorizationService: AuthorizationService,
    private readonly pricingService: PricingService,
    private readonly router: Router,
    private readonly shippinghandlingService: ShippinghandlingService,
    private readonly taxService: TaxService,
    private readonly http: HttpClient,
    private readonly errorHandlingService: ErrorHandlingService
  ) {
  }
  public getAmaKeyButtonMode(): Observable<boolean> {
    return this.disableAmaKeyButton.asObservable();
  }

  public updateAmaKeyButtonMode(): void {
    if(this.orderDto.orderDetailDtos.some(item => item.productDto.requiresAmaKey === true))
    {
      this.disableAmaKeyButton.next(false);
    }
    else
    {
      this.disableAmaKeyButton.next(true);
    }
  }

  async triggerEditMethod(): Promise<void> {
    if (!(this._modeService.mode === Mode.View && this.orderDto.orderStatus !== 'C' && this.orderDto.orderStatus !== 'V') &&
      !(this.orderDto.orderStatus === 'C' && ((this.orderDto.hasActiveSubscriptions || this.orderDto.dataFileAccessDtos.length > 0) || this.orderDto.pendingDate))) {
      return;
    }

    return new Promise(resolve => {
      this.resolveEdit = resolve;
      this.onTriggerEdit.next();
      this.editWasTriggered = true;
    });
  }

  triggerSaveMethod(forceSave: boolean): void {
    if (this._modeService.mode === Mode.Edit || forceSave) {
      this.onTriggerSave.next();
      this.editWasTriggered = false;
    }
  }

  triggerRevertMethod(): void {
    if (this._modeService.mode === Mode.Edit) {
      this.onTriggerRevert.next();
      this.editWasTriggered = false;
    }
  }

  triggerEditMethodOnReturn(): void {
    if (this._modeService.mode === Mode.View && this.orderDto.orderStatus !== 'V') {
      this.onReturnTriggerEdit.next();
      this.returnEditWasTriggered = true;
    }
  }

  triggerSaveMethodOnReturn(forceSave: boolean): void {
    if (this._modeService.mode === Mode.Edit || forceSave) {
      this.onReturnTriggerSave.next();
      this.returnEditWasTriggered = false;
    }
  }

  triggerRevertMethodOnReturn(): void {
    if (this._modeService.mode === Mode.Edit) {
      this.onReturnTriggerRevert.next();
      this.returnEditWasTriggered = false;
    }
  }

  triggerRevertSourceCodeOnReturn(): void {
    this.revertReturnCode.next();
  }

  getNextOrderDetailId(): number {
    if (this.orderDto.saveAndClone === true) {
      this.nextOrderDetailId = -1;
      this.orderDto.saveAndClone = false;
    }
    const nextId = this.nextOrderDetailId;
    this.nextOrderDetailId--;
    return nextId;
  }

  searchOrders(errorMode: Mode, filtersObj: any, overrideWait: boolean = false): void {
    if (this.orders) {
      this._modeService.mode = Mode.View;
      this.gotOrderSearchResultsEmitter.next(this.orders);
      return;
    }

    this._modeService.mode = overrideWait ? Mode.View : Mode.Wait;

    this._httpClient.post<OrderSearchResultsDto>(`${this._configService.apiUrl}/Order/SearchOrders`, filtersObj)
      .subscribe((data: OrderSearchResultsDto) => {
        this.orders = data;
        this.filterObj = filtersObj;
        this._modeService.mode = Mode.View;
        localStorage.setItem('searchOperator', JSON.stringify(filtersObj));
        this.gotOrderSearchResultsEmitter.next(data);
        localStorage.setItem('searchOrderResults', JSON.stringify(data));
      }, (error: any) => {
        console.log(error);
        this._modeService.mode = errorMode;
        this.swalAlert.alert('An error occurred while getting a list of orders.  Please try again.');
      });
  }

  getOrderInformation(orderId: number): void {
    if (!this._configService.reference) {
      throw new Error('Initial config was not yet complete.  The configService must be ready before getting order information.');
    }

    this._modeService.mode = Mode.Wait;
    let observable: Observable<OrderDto>;

    if (this.orderCache) {
      observable = of(this.orderCache);
    }
    else if (this.orderCachedObservable) {
      observable = this.orderCachedObservable;
    }
    else {
      this.orderCachedObservable = this._httpClient.get<OrderDto>(`${this._configService.apiUrl}/Order/GetOrder/${Number(orderId)}`)
        .pipe(
          tap((response: OrderDto) => {
            this.orderCache = response;
          }),
          share(),
          finalize(() => {
            this.orderCache = null;
            this.orderCachedObservable = null;
          })
        );

      observable = this.orderCachedObservable;
    }
   
    const recordType = this.router?.url?.toUpperCase()?.includes('RETURN') ? 'Return' : 'Order';
    const loadingMessage = `Loading ${recordType}`;
    this._modeService.addToWaitList(loadingMessage);
    observable.subscribe((data: OrderDto) => {
      this._modeService.removeFromWaitList(loadingMessage);
      this.orderDto = data;
      this.setOrderEntryFlag();
      // If the order has items that aren't in the product DTOs, those items need to be merged into the product DTOs.
      for (const orderDetailDto of this.orderDto.orderDetailDtos) {
        if (!this._configService.reference?.productDtos.some(item => item.id === orderDetailDto.productDto.id)) {
          this._configService.reference?.productDtos.push(orderDetailDto.productDto);
        }
        
      }

      this.reorderOrderDetailIems(this.orderDto.orderDetailDtos);

      this.recalculateFields(this.orderDto);
      this.previousShippingCharge = this.currentShippingCharge = this.orderDto.orderDetailDtos.filter(item => item.productDto.classCode === 'SH' && item.orderDetailStatus !== 'V')[0]?.listPrice ?? 0;
      
      this._modeService.mode = Mode.View;
      this.orderIsReady.next(this.orderDto);
    }, (error: Error) => {
      this._modeService.removeFromWaitList(loadingMessage);
      console.log(error);
      this._modeService.mode = Mode.View;
     
      if (error.message && error.message.indexOf('Order cannot be found') > -1) {
        this.swalAlert.alert('You have entered an invalid return Id');
      } else {
        this.swalAlert.alert('An error occurred while getting the order details.  Please try again.');
      }

    });
  }

  setOrderEntryFlag() {
    if (this._authorizationService.hasResource('OrderEntryFooterVoid')) {
      this.orderEntryFlag = false;
    }
    else {
      if (this.orderDto.orderStatus === 'D' || this.orderDto.orderStatus === 'A' || this.orderDto.orderStatus === ' ') {
        this.orderEntryFlag = false;
      }
    }
  }

  async getOrderInformationAsync(orderId: number): Promise<void> {
    let promise!: Promise<OrderDto>;

    if (this.orderCache) {
      promise = of(this.orderCache).toPromise();
    }
    else if (this.orderCachedPromise) {
      promise = this.orderCachedPromise;
    }
    else {
      this.orderCachedPromise = this._httpClient.get<OrderDto>(`${this._configService.apiUrl}/Order/GetOrder/${Number(orderId)}`)
        .pipe(
          tap((response: OrderDto) => {
            this.orderCache = response;
            console.log(this.orderCache.billToDto.groupAccountName,"Name");
          }),
          share(),
          finalize(() => {
            this.orderCache = null;
            this.orderCachedPromise = null;
          })
        ).toPromise();

      promise = this.orderCachedPromise;
    }
    
    await promise.then((data: OrderDto) => {
      this.orderDto = data;
      // If the order has items that aren't in the product DTOs, those items need to be merged into the product DTOs.
      for (const orderDetailDto of this.orderDto.orderDetailDtos) {
        if (!this._configService.reference?.productDtos.some(item => item.id === orderDetailDto.productDto.id)) {
          this._configService.reference?.productDtos.push(orderDetailDto.productDto);
          
        }
        this.reorderOrderDetailIems(this.orderDto.orderDetailDtos);
      }
      if(this.orderDto.billToDto.groupAccountId==null){
        this.groupAccount='';
      }
      else{
        this.groupAccount=this.orderDto.billToDto.groupAccountId+"-"+this.orderDto.billToDto.groupAccountName;
      }
    
      this.recalculateFields(this.orderDto);
     
      this.orderDetailDtosBackup = JSON.parse(JSON.stringify(this.orderDto.orderDetailDtos)) as OrderDetailDto[];
      this.orderTypeBackUp = this.orderDto.orderType;
      this.openAmountBackUp = this.orderDto.openAmount;
      this.orderIsReady.next(this.orderDto);
    },
      async (error: any) => {
        if (error.message && error.message.indexOf('Order cannot be found') > -1) {
          await this.swalAlert.alert('You have entered an invalid return Id');
        } else {
          await this.swalAlert.alert('An error occurred while getting the order details.  Please try again.');
        }
      });
  }

  async getOrderSnapshotAsync(orderId: number): Promise<HttpErrorResponse | null> {
    if(this.getOrderSnapshotAsyncResult)
    {
      return this.getOrderSnapshotAsyncResult;
    }
    this.getOrderSnapshotAsyncResult =  new Promise(resolve => {
    this._httpClient.get<OrderDto>(`${this._configService.apiUrl}/Order/GetOrderSnapshot/${Number(orderId)}`).toPromise().then((data: OrderDto) => {
      this.orderDto = data;
      this.isSnapshot = true;
      // If the order has items that aren't in the product DTOs, those items need to be merged into the product DTOs.
      for (const orderDetailDto of this.orderDto.orderDetailDtos) {
        if (!this._configService.reference?.productDtos.some(item => item.id === orderDetailDto.productDto.id)) {
          this._configService.reference?.productDtos.push(orderDetailDto.productDto);
        }    
      }
      this.reorderOrderDetailIems(this.orderDto.orderDetailDtos);
      this.recalculateFields(this.orderDto);
      this.orderIsReady.next(this.orderDto);
      resolve(null);
    },
      async (error: HttpErrorResponse) => {
        if (error.error.Message && error.error.Message.indexOf('Order cannot be found') > -1) {
          await this.swalAlert.alert('You have entered an invalid snapshot order Id');
        } else {
          await this.swalAlert.alert('An error occurred while getting the order snapshot details. Please try again.');
        }
        resolve(error);
      });
    });
  return this.getOrderSnapshotAsyncResult;
  }

  reorderOrderDetailIems(orderDetailDtos: OrderDetailDto[]): void {
    let ecmecItem = orderDetailDtos.find(item => item.productCode === 'ECMEC');
    let ecmecParentProduct = orderDetailDtos.find(item => item.productCode === '3452' && item.orderDetailStatus !='V');
    if(ecmecItem && ecmecParentProduct){
      ecmecItem.parentOrderDetailId = ecmecParentProduct.id;
      let ecmecItemIndex = orderDetailDtos.indexOf(ecmecItem);
      orderDetailDtos.splice(ecmecItemIndex, 1);
      let ecmecParentProductIndex = orderDetailDtos.indexOf(ecmecParentProduct);
      let childItems = orderDetailDtos.filter(item => item.parentOrderDetailId === ecmecParentProduct?.id).length;
      orderDetailDtos.splice(ecmecParentProductIndex + childItems + 1, 0, ecmecItem);
    }

    let shipingItem = orderDetailDtos.find(item => item.classCode === 'SH');
    if(shipingItem){
      let index = this.orderDto.orderDetailDtos.indexOf(shipingItem);
      this.orderDto.orderDetailDtos.splice(index, 1);
      this.orderDto.orderDetailDtos.push(shipingItem);
    }
  }
  getReturnInformation(orderId: number): void {
    let observable: Observable<OrderDto[]>;

    if (this.returnsCache) {
      observable = of(this.returnsCache);
    }
    else if (this.returnsCachedObservable) {
      observable = this.returnsCachedObservable;
    }
    else {
      this.returnsCachedObservable = this._httpClient.get<OrderDto[]>(`${this._configService.apiUrl}/Order/GetReturns/${orderId}`)
        .pipe(
          tap((response: OrderDto[]) => {
            this.returnsCache = response;
          }),
          share(),
          finalize(() => {
            this.returnsCache = null;
            this.returnsCachedObservable = null;
          })
        );

      observable = this.returnsCachedObservable;
    }

    const returnsMessage='Searching for Returns';
    this._modeService.addToWaitList(returnsMessage);
    observable.subscribe((data: OrderDto[]) => {
      this._modeService.removeFromWaitList(returnsMessage);
      this.returnDtos = data;
      this.returnIsReady.next(this.returnDtos);
    }, async (error: Error) => {
      this._modeService.removeFromWaitList(returnsMessage);
      console.log(error);

      if (error.message && error.message.indexOf('Invalid OrderID') > -1) {
        this.returnDtos = [];
        await this.swalAlert.alert('You have entered an invalid order Id');
      } else {
        await this.swalAlert.alert('Error while retrieving related returns.');
      }
    });
  }

  unlockOrder(orderId: number): Observable<string> {
    this._modeService.mode = Mode.Wait;
    let observable: Observable<string>;

    if (this.unlockOrderCache) {
      observable = of(this.unlockOrderCache);
    }
    else if (this.unlockOrderCachedObservable) {
      observable = this.unlockOrderCachedObservable;
    }
    else {
      this.unlockOrderCachedObservable = this._httpClient.get<string>(`${this._configService.apiUrl}/Order/UnlockOrder/${orderId}`)
        .pipe(
          tap((response: string) => {
            this.unlockOrderCache = response;
          }),
          share(),
          finalize(() => {
            this.unlockOrderCache = null;
            this.unlockOrderCachedObservable = null;
          })
        );

      observable = this.unlockOrderCachedObservable;
    }

    return observable;
  }

  setWebReviewed(orderId: number, msId: string): Observable<string> {

    let observable: Observable<string>;
    if (this.setWebReviewedCache) {
      observable = of(this.setWebReviewedCache);
    }
    else if (this.setWebReviewedrCachedObservable) {
      observable = this.setWebReviewedrCachedObservable;
    }
    else {
      this.setWebReviewedrCachedObservable = this._httpClient.get<string>(`${this._configService.apiUrl}/Order/SetWebReviewed?orderId=${orderId}&userName=${msId}`)
        .pipe(
          tap((response: string) => {
            this.setWebReviewedCache = response;
          }),
          share(),
          finalize(() => {
            this.setWebReviewedCache = null;
            this.setWebReviewedrCachedObservable = null;
          })
        );
      observable = this.setWebReviewedrCachedObservable;
    }
    return observable;
  }


  addYearOrder(orderId: number, msId: string, fromOE: boolean = false): Observable<AddYearDto> {
    let observable: Observable<AddYearDto>;

    if (this.addYearOrderCache) {
      observable = of(this.addYearOrderCache);
    }
    else if (this.addYearOrderCachedObservable) {
      observable = this.addYearOrderCachedObservable;
    }
    else {
      this.addYearOrderCachedObservable = this._httpClient.get<AddYearDto>(`${this._configService.apiUrl}/Order/AddYearOrder/${orderId}/${msId}/${fromOE}`)
        .pipe(
          tap((response: AddYearDto) => {
            this.addYearOrderCache = response;
          }),
          share(),
          finalize(() => {
            this.addYearOrderCache = null;
            this.addYearOrderCachedObservable = null;
          })
        );

      observable = this.addYearOrderCachedObservable;
    }

    return observable;
  }

  voidNewSavedMYOrder() {
    this.orderDto.orderDetailDtos =  this.orderDto.orderDetailDtos.filter(x => x.productId !== 0);
    for (const orderDetail of this.orderDto.orderDetailDtos) {
      orderDetail.orderDetailStatus = 'V';
      orderDetail.voidedQuantity = orderDetail.openQuantity;
      orderDetail.openQuantity = 0;
      orderDetail.voidDate = new Date(Date.parse(Date()));
      orderDetail.voidReasonCode = '12';
      orderDetail.extendedPrice = 0;
      orderDetail.taxAmount = 0;
      orderDetail.taxRate = 0;
      orderDetail.orderQuantity = 0;
      if (orderDetail.subscriptionDto !== null) {
        orderDetail.subscriptionDto.subscriptionStatus = 'V';
      }
    }

    this.orderDto.openAmount = 0;
    this.orderDto.orderStatus = 'V';
    this.orderDto.voidReasonCode = '12';
    this.orderDto.voidDate = new Date(Date.parse(Date()));
    this.saveOrderToDatabase(this.orderDto);
  }

  async saveOrderAsync(): Promise<HttpErrorResponse | string> {
    // Recalculate order pricing and bound fields.
    this.recalculateFields(this.orderDto);
    if(await this.checkValidateError() === 'Validation Error'){
      return 'Validation Error';
    }
    
    await this.differentfunctionHandling();
   
    // Apply deposits
    const depositDtos = this.getReservationData();

    let depositResultDtos: DepositDto[] | null = null;
    if (depositDtos.length > 0) {
      depositResultDtos = await this._accountingService.applyDeposits(depositDtos);

      if (depositResultDtos === null) {
        return 'Validation Error';
      }
    }
    await this.checkSourceType();
 
    const error = await this.saveOrderToDatabase(this.orderDto);
   
    if (!error) {
      this._modeService.mode = Mode.View;
    } else {
      // If the order save failed, we must rollback deposits.
      await this.rollbackDepositisIfNeeded(depositResultDtos);
    }

    return error ?? 'Success';
  }
  async checkValidateError():Promise<string> { 
    if (this.sourceType !== SourceType.OrderEntrySaveAsDraft) {
      if (!await this.validateOrder()) {
        return 'Validation Error';
      }
    }else{
      return this.validateDraftOrder();
    }
    return '';
  }
  async differentfunctionHandling() {
    this.fixOrderData();
    // If the user added data file products to their cart, show the additional contacts dialog.
    await this.handleAdditionalContacts();
    // Recalculate shipping and handling.
    await this.recalculateShippingAndHandling();
    // Below logic for showing the ups charges screen for OE
    await this.showUpsChargesScreen(); 
    // Validations are complete
    await this.validateCashReceipts();
    // Before saving the orderDto, update properties if needed.
    this.performValidations();
    await this.handleMultiYearOrders();
    await this.calculateTaxes();
  }
  async rollbackDepositisIfNeeded(depositResultDtos: DepositDto[]|null) {
    if (depositResultDtos && depositResultDtos.length > 0) {

      depositResultDtos.forEach(e => {
        if (e.action === 'Unreservation') {
          e.action = 'Reservation';
        }
        else if (e.action === 'Reservation') {
          e.action = 'Unreservation';
        }
      })
      await this._accountingService.applyDeposits(depositResultDtos);
    }
  }
  
  async checkSourceType() {
    if(this.sourceType === SourceType.OrderEntrySave || this.sourceType === SourceType.OrderEntrySaveAndClone || this.sourceType === SourceType.OrderEntryMutliyear)
    {
      await this.confirmationEmailDialog.openAsync();
    }
    this.UpdateOrderDetailOnChangeSalesPerson();
    // Save the order to the database
    const orderDetailDto = this.orderDto.orderDetailDtos.filter(i => i.productId === 0)[0];
    const index = this.orderDto.orderDetailDtos.indexOf(orderDetailDto);

    this.orderDto.orderDetailDtos.forEach(x => {
      if(x.accountOwnerCode === ''){
        x.accountOwnerCode = this.orderDto.accountOwnerCode ?? this.orderDto.billToDto.accountOwnerCode;
      }
    })

    if (index !== -1) {
      this.orderDto.orderDetailDtos.splice(index, 1);
    }

  }
  async calculateTaxes() {
    let isSuccess = await this.taxService.calculateTax(this.sourceType,this.orderDto);
    if (!isSuccess) {
      await this.swalAlert.alert('Tax will be calculated during invoicing.');
      isSuccess = true;
    }

    this.recalculateFields(this.orderDto);

    if (!isSuccess) {
      return 'Validation Error';
    }
    return '';
  }
  async handleMultiYearOrders() {
    this.changeSubscriptionQuantityBR('Order Inquiry / Order Entry', this.orderDto, null);

    this.orderDto.changeFutureOrders = false;

    if (this.orderDto.multiYearDtos?.length > 0
      && this.orderDto.multiYearDtos[this.orderDto.multiYearDtos.length - 1].yearOfOrder !== this.orderDto.multiYearCurrentYear) {

      if (this.sourceType !== SourceType.OrderEntrySave && this.sourceType !== SourceType.OrderEntrySaveAsDraft && this.sourceType !== SourceType.OrderEntryMutliyear) {
        const result = await this.applyChangesToFutureDialog.openAsync();
        this.orderDto.changeFutureOrders = result === 'Yes';

        if (result === 'Yes') {
          await this.reviewAndUpdateRequiredDate();
        }
      }
    }
  }
  performValidations() {
   // Update system comments
   this.updateSystemComments();

   // Subscription record should be updated with the new Ship to Contact.
   this.orderDto.orderDetailDtos.filter(o => o.orderDetailStatus === ' ').forEach(
     item => {
       if (item.subscriptionDto) {
         item.subscriptionDto.contactId = this.orderDto.shipToDto.contactId;
       }
     }
   )

  }
  async validateCashReceipts() {
    const shippingOrderDetail = this.orderDto.orderDetailDtos.filter(item => item.productDto.classCode === 'SH' && item.orderDetailStatus !== 'V')[0];
    this.previousShippingCharge = shippingOrderDetail ? this.currentShippingCharge: 0;
    let productCode = this.orderDto.orderDetailDtos.some(element => element.productCode === '0010' && element.id < 0) === true ? '0010' : '';
    if (this.cashReceiptBR(productCode, this.orderDto.paymentMethod, this.orderDto.paymentPlan) === true) {
      await this.swalAlert.alert('Cash Receipts are not allowed on a payment plan');
      return 'Validation Error';
    }
  return '';
  }
  async showUpsChargesScreen() {
    const shippingOrderDetail = this.orderDto.orderDetailDtos.filter(item => item.productDto.classCode === 'SH' && item.orderDetailStatus !== 'V')[0];
    if ((shippingOrderDetail) && ( this.sourceType === SourceType.OrderInquirySave || this.sourceType === SourceType.OrderEntrySave || this.sourceType === SourceType.OrderEntrySaveAndClone ||
      this.sourceType === SourceType.OrderEntryMutliyear || this.sourceType === SourceType.OrderEntrySaveAsDraft) && (this.orderDto.billToDto.accountTypeCode !== 'C')&& (this.orderDto.orderStatus !== 'C')) {      this.configurationOrderDetail = shippingOrderDetail;
      this.currentShippingCharge = shippingOrderDetail.listPrice; 
      await this.shippingDialogModal.openAsync();

    }
  }

async recalculateShippingAndHandling(): Promise<string>{
  const shippingOrderDetail = this.orderDto.orderDetailDtos.filter(item => item.productDto.classCode === 'SH' && item.orderDetailStatus !== 'V')[0];
    if (shippingOrderDetail) {
      await this.shippinghandlingService.recalculateShippingAndHandlingAsync(this.orderDto);
      this.recalculateFields(this.orderDto);
    }

    if (this.shippinghandlingService.isError) {
      await this.swalAlert.alert("Shipping and Handling Service is down. Please try again after some time.");
      return 'Validation Error';
    }
    return '';
  }
  async handleAdditionalContacts() {
    if (this.additionalContactsSource !== 'Ship To Component'
      && this.orderDto.orderDetailDtos.some(item => item.productDto.configSetDtos.some(config => config.configSet === 'DATAFILE') && item.orderDetailStatus !== 'V')
      && this.orderDto.dataFileAccessDtos.length > 0) {
      const response = await this.confirmAdditionalContactsDialog.openAsync();
      if (response === 'Yes') {
        await this.selectContactsDialog.openAsync();
      }
    }
  }
  validateDraftOrder(): string {
    const xsItems = this.orderDto.orderDetailDtos.filter(m => m.classCode === 'XS');
   if (xsItems.length > 0) {
        const cartCouponTotal = this.orderDto.orderDetailDtos.filter(m => m.classCode === 'XS' && (m.orderDetailStatus === 'O' || m.orderDetailStatus === ' '))
          .reduce((sum, item) => sum + item.extendedPrice, 0);
        const orderTotal = this.orderDto.orderDetailDtos.filter(m => m.extendedPrice > 0 && (m.orderDetailStatus === 'O' || m.orderDetailStatus === ' ') && m.classCode !== 'XS')
          .reduce((sum, item) => sum + item.extendedPrice, 0);
        this.couponExcessAmount = orderTotal + cartCouponTotal;
        const itemsExtendedTotal = orderTotal.toFixed(2);
        const couponsTotal = cartCouponTotal.toFixed(2);
        if ((parseFloat(couponsTotal) * -1) > parseFloat(itemsExtendedTotal)) {
          this.couponItemCheck.show = true;
          return 'Validation Error';
        }
      }
      return '';
    }

  async saveOrderToDatabase(order: OrderDto): Promise<HttpErrorResponse | null> {
    return new Promise(resolve => {
      order.billToDto.accountPricingDtos = null;
      order.offerInfoDto = null;
      order.orderOfferDto = null;
      order.modifiedBy = this._configService.getMyMSId();
      order.allow1000Order = this._authorizationService.hasResource('Allow1000DollarOrder');
      order.allowDoNotShip = this._authorizationService.hasResource('AllowDoNotShip');
      order.allowPORequired = this._authorizationService.hasResource('OrderEntryByPassPORequiredAlert');
      order.originalOrderDto = null;

      for (const orderDetailDto of order.orderDetailDtos) {
        orderDetailDto.originalOrderDetailDto = null;
      }
      this.reorderOrderDetailIems(order.orderDetailDtos);
      this._modeService.addToWaitList('Saving Order');

      this._httpClient.put<OrderDto>(`${this._configService.apiUrl}/Order/SaveOrder`, order).subscribe({
        next: async (data: OrderDto) => {
           // Display alerts if needed
          this._modeService.removeFromWaitList('Saving Order');

          await this.displayAlerts(order,data);
          // Update other properties
          this.updateOtherProperties(data);

          resolve(null);
        },
        error: async (error: HttpErrorResponse) => {
          await this.handleSaveError(error);
        }
      });
    });
  }
 
  public async handleSaveError(error: HttpErrorResponse): Promise<void> {
    console.log(error);
    this._modeService.removeFromWaitList('Saving Order');
    if (error && error.message) {
      if (error.message.includes('Order lock was cleared')) {
        await this.swalAlert.alert(error.message);
      } else if (error.message.includes('Http failure response')) {
        if (error.error.Message.includes('Order saved successfully but unable to send an email.')) {
          await this.swalAlert.alert(error.error.Message);
        } else {
          await this.swalAlert.alert('Unable to save order because ' + error.error.Message[0].toLowerCase() + error.error.Message.slice(1));
        }
      } else {
        await this.swalAlert.alert('Unable to save order. Please try again.');
      }
    } else {
      await this.swalAlert.alert('Unable to save order. Please try again.');
    }
  
    // Restore original ARS label
    this.orderDto.arsLabel = this.arsLabelInDb;
  }
  public updateOtherProperties(data: OrderDto): void {
    let contactBinding = this.orderDto.orderByContactBinding;
    let shipToContactBinding = this.orderDto.shipToContactBinding;
    this.orderDto = data;
    this.orderDto.orderByContactBinding = contactBinding;
    this.orderDto.shipToContactBinding = shipToContactBinding;

    this.orderDto.inUseBy = null;
    this.orderDto.inUseDate = null;
    this.recalculateFields(this.orderDto);
    this.orderDto.disableAddReturnToBatchButton = this.getAddReturnToBatchButtonDisabled(this.orderDto);
    this.arsLabelInDb = this.orderDto.arsLabel;
  }
  public async displayAlerts(order: OrderDto, data: OrderDto): Promise<void> {
    if (this._configService.orderEntryFeature && order.exceptionReasonCode == null && data.exceptionReasonCode != null) {
      const exceptionReasons = this._configService.getPickList("EREASON");
      const firstExceptionReason = exceptionReasons.filter(item => item.id === data.exceptionReasonCode)[0];
      await this.swalAlert.alert(`Converting order to exception. ${firstExceptionReason.label} `);
    } else if (order.holdReasonCode == null && data.holdReasonCode != null) {
      const holdReasons = this._configService.getPickList("HREASON");
      const firstHoldReason = holdReasons.filter(item => item.id === data.holdReasonCode)[0];
      await this.swalAlert.alert(`Putting order on hold. ${firstHoldReason.label} `);
    }
  }

  async saveReturnAuthorization(order: OrderDto, returnDetails: OrderDetailDto[], returnAuthReason: string): Promise<HttpErrorResponse | null> {
    return new Promise(resolve => {

      let returnOrder = order;
      returnOrder.additionalReturnInformation = new AdditionalReturnInfoDto();
      returnOrder.additionalReturnInformation.note.additionalNotes = returnAuthReason;
      returnOrder.orderDetailDtos = returnDetails;
      
      returnOrder.orderDetailDtos.forEach( (element) => {
        element.originalOrderDetailId = parseInt(element.invoiceNumber);
      });

      this._modeService.addToWaitList('Saving Return Authorization');

      this._httpClient.put<OrderAttachmentDto>(`${this._configService.apiUrl}/Order/SaveReturnAuthorization`, returnOrder).subscribe({
        next: (data: OrderAttachmentDto) => {
          this._modeService.removeFromWaitList('Saving Return Authorization');

          resolve(null);
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);

        }
      });
    });
  }

  createNewOrderDetail(orderDto: OrderDto, productCode: string): OrderDetailDto {
    if (!this._configService.reference) {
      this.swalAlert.alert('Error while creating new order detail: References have not yet been loaded.');
      return new OrderDetailDto();
    }

    const productDto = this._configService.reference.productDtos.filter(item => item.productCode === productCode)[0];

    if (!productDto) {
      this.swalAlert.alert(`Error while creating new order detail (createNewOrderDetail): Unable to find a product with product code '${productCode}'`);
      return new OrderDetailDto();
    }

    // (orderDto: OrderDto, detail: { orderQuantity: number, listPrice: number, unitPrice: number, productCode: string, id: number })
    const listPrice = this._pricingService.calculateListPricing(orderDto, { orderQuantity: 1, unitPrice: 0, productCode: productDto.productCode, id: 0, listPrice: 0 });
    const orderDetailDto = new OrderDetailDto();
    orderDetailDto.id = this.getNextOrderDetailId();
    orderDetailDto.orderId = orderDto.id;
    orderDetailDto.productId = productDto.id;
    orderDetailDto.warehouseId = 1;
    orderDetailDto.productCode = productCode;
    orderDetailDto.printVendorCost = productDto.printVendorCost;
    orderDetailDto.listPrice = listPrice;
    orderDetailDto.floorPrice = productDto.floorPrice;
    orderDetailDto.extendedPrice = listPrice;
    orderDetailDto.accountOwnerCode = orderDto.billToDto.accountOwnerCode;
    if (this.sourceType === SourceType.OrderEntry || this.sourceType === SourceType.OrderEntrySave || this.sourceType === SourceType.OrderEntrySaveAsDraft || this.sourceType === SourceType.OrderEntrySaveAndClone || this.sourceType === SourceType.OrderEntryCalculate)
      orderDetailDto.salespersonCode = this._configService.getPickList('SALESPERSON').filter(a => a.label === this.orderDto.selectedsalesPersonName)[0]?.id;
    else
      orderDetailDto.salespersonCode = this._configService.getMyUserCode();
    orderDetailDto.isWebFulfilled = productDto.isWebFulfilled;
    orderDetailDto.classCode = productDto.classCode;
    orderDetailDto.glCode = productDto.glCode;
    orderDetailDto.productDto = productDto;
    orderDetailDto.productDescription = productDto.description;
    orderDetailDto.enteredDate = new Date();

    // Reopen the order if it's closed.
    if (orderDto.orderStatus === 'C') {
      orderDto.orderStatus = ' ';
    }
    return orderDetailDto;
  }

  updateECMECOrderDetail(orderDetailDto: OrderDetailDto): void {
    //Set ParentOrderDetailId for ECMEC as 3452 Item's Id if 3452 exists
    let ECMEC_parentOrderDetailId = this.orderDto.orderDetailDtos.filter(item => item.productCode === "3452")[0]?.id ?? 0;
    if (orderDetailDto.productCode === "ECMEC" && ECMEC_parentOrderDetailId != 0) {
      orderDetailDto.parentOrderDetailId = ECMEC_parentOrderDetailId;
    }
  }
  async reproductOrderDetail(orderDetailDto: OrderDetailDto, productCode: string): Promise<void> {
    if (!this._configService.reference) {
      await this.swalAlert.alert('Error while creating new order detail: References have not yet been loaded.');
      return;
    }

    const productDto = this._configService.reference.productDtos.filter(item => item.productCode === productCode)[0];

    if (!productDto) {
      await this.swalAlert.alert(`Error while creating new order detail (createNewOrderDetail): Unable to find a product with product code '${productCode}'`);
      return;
    }

    // Remove duplicate open shipping and handling charges if they exist.
    const secondShippingCharge = this.orderDto.orderDetailDtos.filter(item => item !== orderDetailDto && item.orderDetailStatus === ' ' && item.productDto.classCode === 'SH')[0];
    if (orderDetailDto && orderDetailDto.productDto && orderDetailDto.productDto.classCode === 'SH' && secondShippingCharge) {
      this.deleteOrVoidOrderDetail(secondShippingCharge);
    }

    const listPrice = this._pricingService.calculateListPricing(this.orderDto, { orderQuantity: 1, unitPrice: 0, productCode: productDto.productCode, id: 0, listPrice: 0 });

    if(orderDetailDto){
      orderDetailDto.productId = productDto.id;
      orderDetailDto.productCode = productCode;
      orderDetailDto.printVendorCost = productDto.printVendorCost;
      orderDetailDto.listPrice = listPrice;
      orderDetailDto.floorPrice = productDto.floorPrice;
      orderDetailDto.isWebFulfilled = productDto.isWebFulfilled;
      orderDetailDto.classCode = productDto.classCode;
      orderDetailDto.glCode = productDto.glCode;
      orderDetailDto.productDto = productDto;
      orderDetailDto.productDescription = productDto.description;
    }  
  }

  deleteOrVoidOrderDetail(orderDetail: OrderDetailDto) {
    if (orderDetail.orderDetailStatus === 'C') {
      return;
    }

    if (orderDetail.id > 0 ) {
      orderDetail.orderDetailStatus = 'V';
      if (this.orderDto.orderStatus !== 'D' && this.orderDto.orderType !== 'Q'){
        orderDetail.voidReasonCode = orderDetail.isSubscriptionRenewedOnDifferentOrder ? 'D' : '14';
      } else {
        orderDetail.voidReasonCode = 'D';
      }
      orderDetail.voidDate = new Date();
      orderDetail.voidedQuantity = orderDetail.openQuantity;
      orderDetail.openQuantity = 0;
      orderDetail.taxAmount = 0;
      orderDetail.taxRate = 0;
    } else {
      this.orderDto.orderDetailDtos = this.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
    }
    const childOrderDetailDtos = this.orderDto.orderDetailDtos.filter(item => item.parentOrderDetailId === orderDetail.id);
    for (const childOrderDetailDto of childOrderDetailDtos) {
      this.deleteOrVoidOrderDetail(childOrderDetailDto);
    }
    console.log('sourceType' + this.sourceType);
    if (this.sourceType === SourceType.OrderEntry)
    {
      let lastOrderDetailProductCode = this.orderDto.orderDetailDtos[this.orderDto.orderDetailDtos.length - 1].productCode;
      if(lastOrderDetailProductCode !== '0' && lastOrderDetailProductCode !== '')
        this.addNewLineItem();
    }      
  }

  addNewLineItem(): void {
    console.log('addNewLineItem');
    const orderDetail = new OrderDetailDto();
    orderDetail.id = this.getNextOrderDetailId();
    orderDetail.productCode = '';
    orderDetail.orderDetailStatus = ' ';
    orderDetail.itemEditable = true;
    orderDetail.requiredDate = new Date();
    orderDetail.enteredDate = new Date();
    orderDetail.productDto = new ProductDto();
    this.orderDto.orderDetailDtos.push(orderDetail);
    this.orderDto.orderDetailDtos = [...this.orderDto.orderDetailDtos];
    this.orderDto.totalQuantity = this.orderDto.orderDetailDtos.map(o => o.orderQuantity).reduce((prev, next) => prev + next, 0);
  }

  getEligibleAmount(): void {

    this.orderDto.eligibleAmount = this.orderDto.orderDetailDtos
      .filter(item => item.productDto.allowPaymentPlan && this.orderDto.billToDto.allowPaymentPlans)
      .reduce((sum, item) => sum + item.extendedPrice, 0);
  }
  isChangedTier:boolean = false;
  recalculateFields(orderDto: OrderDto) {
    // Change blank fields to null to allow save.
    
    this.stateList = (states as any).default;

    //This if condition is for the code coverage to fill the stateList object
    if (!this.stateList) {
      this.stateList = states;
    }

    this.stateList.forEach(item => {
      item.disabled = false;
    });
    if (orderDto.listCode?.trim() === '') {
      orderDto.listCode = null;
    }
    //Set defaultPaymentMethod
    this.paymentMethodBR();
    // Determine payment plan enabled or not
    this.paymentPlanOrderEntryBR();

    // Reorder multi-year deal orders
    orderDto.multiYearDtos.sort((a, b) => a.yearOfOrder - b.yearOfOrder);

    // Recalculate order status
    if (orderDto.orderDetailDtos.length > 0) {
      if ((!orderDto.orderDetailDtos.some(item => item.orderDetailStatus !== 'V')) && (orderDto.orderStatus !== 'V') && orderDto.orderStatus !== 'C') {
        orderDto.orderStatus = 'V';
        orderDto.voidReasonCode = '14';
        orderDto.holdReasonCode = null;
        orderDto.openAmount = 0;
      }
      if ((!orderDto.orderDetailDtos.some(item => item.orderDetailStatus === ' ')) && (orderDto.orderStatus !== 'V') && orderDto.orderStatus !== 'C') {
        orderDto.orderStatus = 'C';
        if (orderDto.orderType === 'E') {
          orderDto.orderType = ' ';
          orderDto.exceptionReasonCode = null;
        }
        orderDto.holdReasonCode = null;
        orderDto.openAmount = 0;
      }
    }

    // This order has no line items and is void, it came from order entry and should show as opened.
    if (!orderDto.orderDetailDtos[0] && orderDto.orderStatus === 'V') {
      orderDto.orderStatus = ' ';
      orderDto.voidReasonCode = null;
      orderDto.holdReasonCode = null;
    }

    // Calculate the account owner name
    orderDto.billToDto.accountOwnerName = this._configService.getFullNameFromUserCode(orderDto.billToDto.accountOwnerCode);
    orderDto.billToDto.accountOwneremail=this._configService.getUserEmailFromUserCode(orderDto.billToDto.accountOwnerCode);
    orderDto.salesPersonEmail=this._configService.getUserEmailFromUserCode(orderDto.billToDto.accountOwnerCode);
    orderDto.enteredByBindingEmail=this._configService.getUserEmailFromUserLogin(orderDto.enteredBy);
    // Recalculate fields
    let lstCreditLmtInformation = this._configService.getPickList('CLIMIT').filter(x => x.id === orderDto.billToDto.creditLimit.toString());
    if (lstCreditLmtInformation != null && lstCreditLmtInformation.length > 0)
      orderDto.billToDto.creditLimitDescription = lstCreditLmtInformation[0].label;

    orderDto.orderTypeBinding = this._configService.getPickList('ORDERTYPE').filter(x => x.id === orderDto.orderType)[0];
    orderDto.orderStatusBinding = this._configService.getPickList('ORDERSTATUS').filter(x => x.id === orderDto.orderStatus).length > 0 ?
      this._configService.getPickList('ORDERSTATUS').filter(x => x.id === orderDto.orderStatus)[0] : { id: 'Select', label: 'Select', value: 'Select' };
     orderDto.enteredByBinding = this._configService.getuserNameUserLogin(orderDto.enteredBy);
      this.getEligibleAmount();
    if ((orderDto.orderDetailDtos.some(item => item.productDto.isDownloadable)) && (orderDto.orderStatus !== 'V')
      && orderDto.pendingDate === null && orderDto.inUseBy === null
    ) {
      this.displayShipToLink = true;
      let selectedContactId = new DataFileAccessDto();
      selectedContactId.contactId = orderDto.shipToDto.contactId
      if (!orderDto.dataFileAccessDtos?.some(item => item.contactId == selectedContactId.contactId))
        orderDto.dataFileAccessDtos?.push(selectedContactId);
    }
    else {
      this.displayShipToLink = false;
    }

    if ((orderDto.orderDetailDtos.some(item => item.productDto.isDownloadable &&
      item.subscriptionDto?.subscriptionStatus !== 'X' &&
      item.subscriptionDto?.subscriptionStatus !== 'C'))) {
      this.displayShipToLinkSaveButton = true;
    }
    else {
      this.displayShipToLinkSaveButton = false;
    }

    for (let i = 0; i < orderDto.orderDetailDtos.length; i++) {
      const orderDetail = orderDto.orderDetailDtos[i];

      // Get fullname of SalespersonCode and AccountOwnerCode
      orderDetail.salespersonFullName = this._configService.getPickList('USERNAME').filter(x => x.id === orderDetail.salespersonCode)[0]?.label;
      orderDetail.accountOwnerFullName = this._configService.getPickList('USERNAME').filter(x => x.id === orderDetail.accountOwnerCode)[0]?.label;

      if (orderDetail.listPrice === 0 && orderDetail.discount === 0) {
        orderDetail.unitPrice = 0;
      }
      else {
        // Recalculate pricing fields if needed
        orderDetail.unitPrice = orderDetail.unitPrice ? orderDetail.unitPrice : isFinite(this._pricingService.round((orderDetail.listPrice * (100 - orderDetail.discount) / 100), 2)) ?
          this._pricingService.round((orderDetail.listPrice * (100 - orderDetail.discount) / 100), 2) : 0;

        if (this.isChangedTier) {
          const discountedPrice = this._pricingService.round((orderDetail.listPrice * (100 - orderDetail.discount) / 100), 2);
          orderDetail.unitPrice = isFinite(discountedPrice) ? discountedPrice : 0;
        }
      }
      
      orderDetail.orderQuantity = orderDetail.openQuantity + orderDetail.shippedQuantity;
      orderDetail.extendedPrice = orderDetail.unitPrice * (orderDetail.orderQuantity);
      orderDetail.voidReasonDescription = this._configService.getPickList('VOIDREASON').filter(x => x.id === orderDetail.voidReasonCode)[0]?.label;
      const configSetDtos = this._configService.reference?.productDtos.filter(p => p.productCode.trim() === orderDetail.productCode.trim())[0]?.configSetDtos;
      if (orderDetail.parentOrderDetailId) {
        orderDetail.allowPaymentPlan = orderDto.orderDetailDtos.filter(item => item.id === orderDetail.parentOrderDetailId)[0]?.allowPaymentPlan ?? false;
      } else {
        orderDetail.allowPaymentPlan = orderDetail.productDto.allowPaymentPlan;
      }

      orderDetail.hasNonDepositDeleteIconAccess = this._authorizationService.hasResource('OrderInquiryGridNonDepositDeleteIcon');
      orderDetail.hasDepositDeleteIconAccess = this._authorizationService.hasResource('OrderInquiryGridDepositDeleteIcon');
      orderDetail.hasNonDepositGearIconAccess = this._authorizationService.hasResource('OrderInquiryGridNonDepositGearIcon');
      orderDetail.hasDepositGearIconAccess = this._authorizationService.hasResource('OrderInquiryGridDepositGearIcon');

      if (orderDetail.subscriptionDto) {
        orderDetail.subscriptionDto.startDate = new Date(orderDetail.subscriptionDto.startDate);
        const startDate = new Date(orderDetail.subscriptionDto.startDate);
        const expireDate = new Date(startDate.getFullYear(), startDate.getMonth() + orderDetail.subscriptionDto.term, 1);
        expireDate.setDate(expireDate.getDate() - 1);
        orderDetail.subscriptionDto.expireDate = expireDate;
      }

      if (configSetDtos) {
        if (configSetDtos?.findIndex(c => c.configSet === 'WEBAPP') !== -1 && orderDetail.orderDetailStatus === 'C') {
          orderDetail.itemDisable = true;
        }

        if (configSetDtos?.length !== 0) {
          orderDetail.configSetExist = true;
        }
      }

      if (orderDetail.productCode === '0010') {
        orderDetail.hasDeleteAccess = this._authorizationService.hasResource('OrderInquiryGridDepositDeleteIcon');
        orderDetail.hasGearAccess = this._authorizationService.hasResource('OrderInquiryGridDepositGearIcon');
      } else {
        orderDetail.hasDeleteAccess = this._authorizationService.hasResource('OrderInquiryGridNonDepositDeleteIcon');
        orderDetail.hasGearAccess = this._authorizationService.hasResource('OrderInquiryGridNonDepositGearIcon');
      }
    }

    this._pricingService.calculateOpenAmount(orderDto);
    this._pricingService.calculateShippedAmount(orderDto);

    this._accountingService.getInvoiceLineItems(orderDto.id).subscribe((data: InvoiceLineItemDto[]) => {
      this.invoiceLineItems = data;

      for (let i = 0; i < orderDto.orderDetailDtos.length; i++) {
        const orderDetail = orderDto.orderDetailDtos[i];
        const invoiceLineItemDetails: InvoiceLineItemDto[] = this.invoiceLineItems.filter((il) => il.orderDetailId === orderDetail.id);

        if (invoiceLineItemDetails.length > 0) {
          orderDetail.invoiceNumber = invoiceLineItemDetails[0].invoiceNumber;
          orderDetail.balance = invoiceLineItemDetails[0].balance;
        }
      }
    });

    orderDto.totalQuantity = this.orderDto.orderDetailDtos.map(o => o.orderQuantity).reduce((prev, next) => prev + next, 0);
    orderDto.maxTaxRate = this.orderDto.orderDetailDtos.map(o => o.taxRate).reduce((a, b) => Math.max(a, b), 0);
    orderDto.totalTax = this.orderDto.orderDetailDtos.map(o => o.taxAmount).reduce((a, b) => a + b, 0) +
      this.orderDto.orderDetailDtos.filter(item => item.productCode === '0048').reduce((sum, item) => sum + item.extendedPrice, 0);

    // Calculate active subscriptions
    orderDto.hasActiveSubscriptions = orderDto.orderDetailDtos.some(item => !item.isPhysical && item.subscriptionDto &&
      'A,F,W,S'.indexOf(item.subscriptionDto.subscriptionStatus) > -1);

    // Calculate partially shipped
    orderDto.isPartiallyShipped = orderDto.orderDetailDtos.some(item => item.shippedQuantity !== 0);

    // Calculate order status
    if (orderDto.inUseBy && orderDto.inUseBy.toLowerCase() !== this._configService.getMyMSId().toLowerCase()) {
      let fullName = orderDto.inUseBy;
      const userCode = this._configService.getUserCodeFromUserLogin(orderDto.inUseBy);
      if (userCode) {
        fullName = this._configService.getFullNameFromUserCode(userCode);
        if (!fullName) {
          fullName = orderDto.inUseBy
        }
      }

      this.orderStatus = 'In Use By ' + fullName;
    } else if (orderDto.orderStatus === 'V') {
      this.orderStatus = 'Voided';
    } else if (orderDto.pendingDate !== null) {
      const batchId = orderDto.orderDetailDtos.filter(item => item.batchId !== null)[0]?.batchId;
      this.orderStatus = 'Pending Shipment - ' + formatDate(orderDto.pendingDate.toString(), 'MM/dd/yyyy', 'en-US') + ' - ' + batchId;
    } else if (orderDto.orderStatus === 'C') {
      this.orderStatus = 'Shipped in Full';
    }
    else if (orderDto.orderType === 'Q') {
      const quoteReasons = this._configService.getPickList("QREASON");
      const quoteReason = quoteReasons.filter(item => item.id === orderDto.quoteReasonCode)[0]?.label;
      if (quoteReason) {
        this.orderStatus = 'Quote - ' + quoteReason;
      }
      else {
        this.orderStatus = 'Quote'
      }
    }
    else if (orderDto.orderType === 'E') {
      const exceptionReasons = this._configService.getPickList("EREASON");
      const exceptionReason = exceptionReasons.filter(item => item.id === orderDto.exceptionReasonCode)[0]?.label;
      if (exceptionReason) {
        this.orderStatus = 'Exception - ' + exceptionReason;
      }
      else {
        this.orderStatus = 'Exception'
      }
    }

    else if (orderDto.orderStatus === 'H') {
      const holdReasons = this._configService.getPickList("HREASON");
      this.orderStatus = 'On Hold - ' + (holdReasons.filter(item => item.id === orderDto.holdReasonCode)[0]?.label ?? 'Unknown')
    } else if (orderDto.orderStatus === ' ' && orderDto.orderType === ' ' && orderDto.orderDetailDtos.some(item => item.orderDetailStatus === 'C')) {
      this.orderStatus = 'Partially Shipped';
    } else {
      this.orderStatus = ' ';
    }
    this.orderDto.isShipAndProcess = this.orderDto.orderDetailDtos.some(item => item.productDto.isShipAndProcess && item.orderDetailStatus === ' ');
    const orderDetailWithSalesPersonCode = this.orderDto.orderDetailDtos
    if(!this.orderDto.selectedsalesPersonName && orderDetailWithSalesPersonCode && orderDetailWithSalesPersonCode[0]?.salespersonCode)
    {
      this.orderDto.selectedsalesPersonName = this._configService.getFullNameFromUserCode(orderDetailWithSalesPersonCode[0].salespersonCode);
      this.orderDto.selectedslaesPersonNameEmail=this._configService.getUserEmailFromUserCode(orderDetailWithSalesPersonCode[0].salespersonCode);
    }


    if(!this.orderDto.accountOwnerName && !this.orderDto.selectedsalesPersonName && this.orderDto.accountOwnerCode !== '' && this.orderDto.accountOwnerCode !== undefined && this.orderDto.accountOwnerCode !== null ) 
    {
      this.orderDto.accountOwnerName = this._configService.getFullNameFromUserCode(this.orderDto.accountOwnerCode);
      this.orderDto.selectedsalesPersonName = this.orderDto.accountOwnerName;
      this.orderDto.selectedslaesPersonNameEmail=this.orderDto.email;
    }

    let selectedStateArray = this.stateList.filter(s => s.value === this.orderDto.billToDto.state?.trim());

    if (selectedStateArray != null && selectedStateArray.length > 0) {
      this.orderDto.billToDto.selectedState = selectedStateArray[0];
    }
    
    this.orderDto.shipToDto.stateBinding = this.stateList.filter(s => s.value === this.orderDto.shipToDto.state?.trim())[0] ?? null;
    if (this.selectedShipToContact) {
      this.selectedShipToContact.mailingStateBinding = this.stateList.filter(s => s.value === this.selectedShipToContact?.mailingState?.trim())[0] ?? null;
    }
    if (this.selectedShipToContact) {
      this.selectedShipToContact.stateBinding = this.stateList.filter(s => s.value === this.selectedShipToContact?.state?.trim())[0] ?? null;
    }

    this.orderDto.billToDto.specifiedPriceList= this.orderDto.billToDto.accountPricingDtos!=null&&this.orderDto.billToDto.accountPricingDtos.length>0?true:false

    //To enable Ship To link
    const shippedWithInLastYear = (item : OrderDetailDto) => item.shipDate !==null &&  new Date( new Date(item.shipDate).getFullYear() + 1, new Date(item.shipDate).getMonth(), new Date(item.shipDate).getDate()) >= new Date(new Date().setHours(0,0,0,0));
    const enableShipTo =  orderDto.orderDetailDtos.some(item=>item.productDto.isDownloadable && item.parentOrderDetailId ===null  && item.orderDetailStatus !== 'V' && (((item.shipDate ===null || shippedWithInLastYear(item)) && item.subscriptionDto===null )  || ['A','F','W','S'].includes(item.subscriptionDto?.subscriptionStatus??'') ))
    if (orderDto.inUseBy === null && enableShipTo) {
      this.displayShipToLink = true;
      let selectedContactId = new DataFileAccessDto();
      selectedContactId.contactId = orderDto.shipToDto.contactId
      if (!orderDto.dataFileAccessDtos?.some(item => item.contactId == selectedContactId.contactId)) {
        orderDto.dataFileAccessDtos?.push(selectedContactId);
      }

    }
    else if ( enableShipTo) {
      this.displayShipToLink = false;
      let selectedContactId = new DataFileAccessDto();
      selectedContactId.contactId = orderDto.shipToDto.contactId
      if (!orderDto.dataFileAccessDtos.some(item => item.contactId == selectedContactId.contactId)) {
        orderDto.dataFileAccessDtos.push(selectedContactId);
      }

    }
    else {
      this.displayShipToLink = false;
    }

  }

  getAccountComments(accountId: number): Observable<string[]> {
    let observable: Observable<string[]>;

    if (this.accountCommentsCache) {
      observable = of(this.accountCommentsCache);
    }
    else if (this.accountCommentsCachedObservable) {
      observable = this.accountCommentsCachedObservable;
    }
    else {
      this.accountCommentsCachedObservable = this._httpClient.get<string[]>(`${this._configService.apiUrl}/Order/GetAccountComments/${accountId}`)
        .pipe(
          tap((response: string[]) => {
            this.accountCommentsCache = response;
          }),
          share(),
          finalize(() => {
            this.accountCommentsCache = null;
            this.accountCommentsCachedObservable = null;
          })
        );

      observable = this.accountCommentsCachedObservable;
    }

    return observable;
  } 

  saveOrderAttachments(formData: FormData, headers: HttpHeaders): Promise<HttpErrorResponse | null> {
    this._modeService.addToWaitList('Saving Order Attachments');
    return new Promise(resolve => {
      this._httpClient.put<OrderAttachmentDto[]>(`${this._configService.apiUrl}/Order/SaveOrderAttachments`, formData, { headers }).subscribe({
        next: (data: OrderAttachmentDto[]) => {

          this._modeService.removeFromWaitList('Saving Order Attachments');
          this.orderDto.orderAttachments = data;
          resolve(null);
        },
        error: async (error: HttpErrorResponse) => {
          console.log(error);
          this._modeService.removeFromWaitList('Saving Order Attachments');
          await this.swalAlert.alert('Unable to save order attachments.  Please try again.');         
          resolve(error);
        }
      });
    });
  }

  getOrderAttachments(orderId: number): Promise<OrderAttachmentDto[]> {
    this._modeService.addToWaitList('Getting Order Attachments');
    return new Promise(resolve => {
      this._httpClient.get<OrderAttachmentDto[]>(`${this._configService.apiUrl}/Order/GetOrderAttachments/${orderId}`).subscribe({
        next: (data: OrderAttachmentDto[]) => {
          this.orderDto.orderAttachments = data;
          this._modeService.removeFromWaitList('Getting Order Attachments');
          resolve(data);
        },
        error: async (error: HttpErrorResponse) => {
          this._modeService.removeFromWaitList('Getting Order Attachments');
          await this.swalAlert.alert('Unable to get order attachments.  Please try again.');  
          resolve([]);
        }
      });
    });
  }

  deleteOrderAttachment(fileDto: FileDto, orderId: number): Observable<any> {
    return this._httpClient.post(`${this._configService.apiUrl}/Order/DeleteOrderAttachment?orderId=${orderId}`,fileDto);
  }

  saveCfd(pdf: jsPDF, msId: string): string {

    let accountId = this.orderDto.billToDto.id.toString();
    let quoteNumber = this.orderDto.id;
    let timeStamp = this.dateToString(new Date());  
    let fileName = `CFD_${accountId}_${quoteNumber}_${timeStamp}.pdf`;

    let blob = pdf.output("blob");
    const file = new File([blob], fileName, { type: 'application/pdf'});

    const formData = new FormData();   
    formData.append('quoteCfdDto.fileName', fileName);
    formData.append('quoteCfdDto.file', file, fileName);        
    formData.append('quoteCfdDto.accountId', this.orderDto.billToDto.id.toString());
    formData.append('quoteCfdDto.createdBy', msId); 
    const headers = new HttpHeaders();       

    this.saveQuoteCfd(formData, headers);
    return fileName;
  }

  saveProFormaPdf(pdf: jsPDF, msId: string): string {
    let orderId = this.orderDto.id;
    let fileName = `ProFormaInvoice-${orderId}.pdf`;

    let blob = pdf.output("blob");
    const file = new File([blob], fileName, { type: 'application/pdf'});

    const formData = new FormData();   
    formData.append('quoteCfdDto.fileName', fileName);
    formData.append('quoteCfdDto.file', file, fileName);        
    formData.append('quoteCfdDto.accountId', this.orderDto.billToDto.id.toString());
    formData.append('quoteCfdDto.createdBy', msId);      
    return fileName;
  }

  dateToString(date_Object: Date) {    
    let date_String = date_Object.getFullYear().toString()+(date_Object.getMonth() + 1)+date_Object.getDate()+date_Object.getHours()+date_Object.getMinutes()+date_Object.getSeconds();
    return date_String;
  }


  saveQuoteCfd(formData: FormData, headers: HttpHeaders): Promise<HttpErrorResponse | null> {
    this._modeService.addToWaitList('Saving Quote Cfd');
    return new Promise(resolve => {
      this._httpClient.put<CfdDto[]>(`${this._configService.apiUrl}/Order/SaveGeneratedQuoteCfd`, formData, { headers }).subscribe({
        next: () => {
          this._modeService.removeFromWaitList('Saving Quote Cfd');               
          resolve(null);
        },
        error: async (error: HttpErrorResponse) => {
          console.log(error);
          this._modeService.removeFromWaitList('Saving Quote Cfd');
          await this.swalAlert.alert('Unable to save file.  Please try again.');         
          resolve(error);
        }
      });
    });
  }

  downloadFileFromAzure(orderAttachment: OrderAttachmentDto): Promise<Blob> {   
    this._modeService.addToWaitList('Getting File...');
    return new Promise(resolve => {
      this._httpClient.post(`${this._configService.apiUrl}/Order/GetFileFromAzure`, orderAttachment, {
        responseType: 'blob'
      }).subscribe({
        next: (data: Blob) => {
          this._modeService.removeFromWaitList('Getting File...');
          resolve(data);
        },
        error: (error: HttpErrorResponse) => {
          this._modeService.removeFromWaitList('Getting File...');
          this.swalAlert.alert('Unable to get order attachments.  Please try again.');  
          resolve(new Blob());
        }
      });
    });
  }
  
  downloadOrderAttachmentFile(fileDto: FileDto, orderId: number): Promise<Blob> {   
    this._modeService.addToWaitList('Getting File...');
    return new Promise(resolve => {
      this._httpClient.post(`${this._configService.apiUrl}/Order/GetOrderAttachmentFile?orderId=${orderId}`, fileDto, {
        responseType: 'blob'
      }).subscribe({
        next: (data: Blob) => {
          this._modeService.removeFromWaitList('Getting File...');
          resolve(data);
        },
        error: (error: HttpErrorResponse) => {
          this._modeService.removeFromWaitList('Getting File...');
          this.swalAlert.alert('Unable to get order attachments.  Please try again.');  
          resolve(new Blob());
        }
      });
    });
  }
  setLockOrder(orderId: number, msId: string, bypassPending: boolean = false): Observable<OrderDto> {
    let observable: Observable<OrderDto>;

    if (this.setLockOrderCache) {
      observable = of(this.setLockOrderCache);
    }
    else if (this.setLockOrderCachedObservable) {
      observable = this.setLockOrderCachedObservable;
    }
    else {
      this.setLockOrderCachedObservable = this._httpClient
        .get<OrderDto>(`${this._configService.apiUrl}/Order/LockOrder?orderId=${orderId}&userName=${msId}&bypassPending=${bypassPending}`)
        .pipe(
          tap((response: OrderDto) => {
            this.setLockOrderCache = response;
            this.lockOrderComplete.next();
          }),
          share(),
          finalize(() => {
            this.setLockOrderCache = null;
            this.setLockOrderCachedObservable = null;
          })
        );

      observable = this.setLockOrderCachedObservable;
    }

    return observable;
  }

  getOfferStatus(promocode: string | null): Observable<OfferDto> {
    let observable: Observable<OfferDto>;

    if (this.offerCache) {
      observable = of(this.offerCache);
    }
    else if (this.offerCachedObservable) {
      observable = this.offerCachedObservable;
    }
    else {
      this.offerCachedObservable = this._httpClient.get<OfferDto>(`${this._configService.apiUrl}/Order/GetOfferStatus/?promotionCode=${promocode}`)
        .pipe(
          tap((response: OfferDto) => {
            this.offerCache = response;
          }),
          share(),
          finalize(() => {
            this.offerCache = null;
            this.offerCachedObservable = null;
          })
        )

      observable = this.offerCachedObservable;
    }

    return observable;
  }

  cashReceiptBR(productCode: string, paymentMethod: string | null, paymentPlan: string): boolean {
    return ((productCode === '0010' || paymentMethod === 'C') && (paymentPlan === 'Monthly' || paymentPlan === 'Quarterly')) ? true : false;
  }

  changeSubscriptionQuantityBR(source: string, salesOrder: OrderDto, returnOrder: OrderDto | null) {
    // When changing the order quantity, the subscription quantity must also change.  If the subscription quantity has a multiplier, we must take it into account.

    for (const orderDetailDto of salesOrder.orderDetailDtos) {
      if (orderDetailDto.orderDetailStatus === 'V' || !orderDetailDto.subscriptionDto) {
        continue;
      }

      const vProductCode = 'V' + orderDetailDto.productCode;
      let newQuantity = 0;

      // Order Inquiry
      if (source === 'Order Inquiry / Order Entry') {
        newQuantity = this.orderDto.orderDetailDtos
          .filter(item => (item.id === orderDetailDto.id || item.productCode === vProductCode) && item.orderDetailStatus !== 'V')
          .reduce((sum, item) => sum + item.openQuantity + item.shippedQuantity + item.returnedQuantity, 0);
      }
      else {
        newQuantity = this.changeSubscriptionQuantityBRForReturn(source, salesOrder, returnOrder, orderDetailDto, vProductCode);
      }

      const multiplier = orderDetailDto.productDto.subscriptionMultiplier === 0 ? 1 : orderDetailDto.productDto.subscriptionMultiplier;
      orderDetailDto.subscriptionDto.subscriptionQuantity = newQuantity * multiplier;
    }
  }

  changeSubscriptionQuantityBRForReturn(source: string, salesOrder: OrderDto, returnOrder: OrderDto | null, orderDetailDto: OrderDetailDto, vProductCode: string): number {
    const quantityFromOrder = salesOrder.orderDetailDtos
      .filter(item => (item.id === orderDetailDto.id || item.productCode === vProductCode) && item.orderDetailStatus !== 'V')
      .reduce((sum, item) => sum + item.openQuantity + item.shippedQuantity + item.returnedQuantity, 0);
    // Return Entry
    if (source === 'Return Entry' && returnOrder !== null) {
      const quantityAddedOnReturn = returnOrder.orderDetailDtos
        .filter(item => (item?.originalOrderDetailDto?.id === orderDetailDto.id || item.productCode === vProductCode) && item.orderDetailStatus !== 'V' && item.isSelected)
        .reduce((sum, item) => sum + item.openQuantity, 0);
      return (quantityFromOrder + quantityAddedOnReturn);
    }
    // Return Inquiry
    else if (source === 'Return Inquiry' && returnOrder !== null) {
      const quantityAddedOnReturn = returnOrder.orderDetailDtos
        .filter(item => (item?.originalOrderDetailDto?.id === orderDetailDto.id || item.productCode === vProductCode) && item.orderDetailStatus !== 'V')
        .reduce((sum, item) => sum + item.openQuantity, 0);
      return (quantityFromOrder + quantityAddedOnReturn);
    }
    return 0;
  }

  async accountContactValidationBR(): Promise<boolean> {
  const billToStatus = this.orderDto.billToDto.statusCode?.trim();
  const orderByStatus = this.orderDto.orderByDto.statusCode?.trim();
  const shipToStatus = this.orderDto.shipToDto.statusCode?.trim();

  const isAccountActive = ['A', 'BAD', 'BP', 'NR'].includes(billToStatus);
  const isOrderByActive = ['A', 'BAD', 'BP', 'NR'].includes(orderByStatus);
  const isShipToActive = ['A', 'BAD', 'BP', 'NR'].includes(shipToStatus);

  if (!isAccountActive && !isOrderByActive && !isShipToActive) {
    await this.swalAlert.alert('The account, orderby contact, and ship to contact are not active. All three statuses must be active.');
    return true;
  } else if (!isAccountActive && isOrderByActive && isShipToActive) {
    await this.swalAlert.alert('The account is not active. The account status must be active.');
    return true;
  } else if (!isAccountActive && !isOrderByActive && isShipToActive) {
    await this.swalAlert.alert('The account & order by contact are not active. Both statuses must be active.');
    return true;
  } else if (!isAccountActive && isOrderByActive && !isShipToActive) {
    await this.swalAlert.alert('The account & ship to contact are not active. Both statuses must be active.');
    return true;
  } else if (isOrderByActive && !isShipToActive) {
    await this.swalAlert.alert('The ship to contact is not active. The ship to contact status must be active.');
  } else if (!isOrderByActive && isShipToActive) {
    await this.swalAlert.alert('The order by contact is not active. The order by contact status must be active.');
  } else if (!isOrderByActive && !isShipToActive) {
    await this.swalAlert.alert('The order by and ship to contact are not active. Both statuses must be active.');
  }

  return false;

  }

  async addOrder(accountId: number): Promise<number | null> {
    return new Promise(resolve => {
      this._modeService.addToWaitList(this.saveMessage);
      this._httpClient.get<OrderNumberDto>(`${this._configService.apiUrl}/Order/AddOrder/${accountId}`).subscribe(orderNumberDto => {
        this._modeService.removeFromWaitList(this.saveMessage);
        resolve(orderNumberDto.orderNumber);
      }, async error => {
        this._modeService.removeFromWaitList(this.saveMessage);
        await this.swalAlert.alert('Error adding new order.');
        resolve(null);
      });
    });
  }

  resendOrder(orderId: number): void {
    this._httpClient.get<string>(`${this._configService.apiUrl}/Order/ResendOrder/${orderId}`).subscribe(() => {
      this.swalAlert.alert("Order marked to be resent successfully.");
    }, error => {
      this.swalAlert.alert("Error attempting to resend order.");
    });
  }

  clearInUse(orderId: number): void {
    this._httpClient.get<string>(`${this._configService.apiUrl}/Order/UnlockOrder/${orderId}`).subscribe(() => {
      this.swalAlert.alert("In use flag cleared successfully.");
      this.orderDto.inUseBy = null;
      this.orderDto.inUseDate = null;
      this.recalculateFields(this.orderDto);
    }, error => {
      this.swalAlert.alert("Error attempting to clear in use flag on order.");
    });
  }

  clearPending(orderId: number): void {
    this._httpClient.get<string>(`${this._configService.apiUrl}/Order/ClearPending/${orderId}`).subscribe(() => {
      this.swalAlert.alert("Pending flag cleared successfully.");
      this.orderDto.pendingBy = null;
      this.orderDto.pendingDate = null;

      for (const orderDetailDto of this.orderDto.orderDetailDtos) {
        orderDetailDto.pendingDate = null;
        orderDetailDto.pendingQuantity = null;
      }

      this.recalculateFields(this.orderDto);
    }, error => {
      this.swalAlert.alert("Error attempting to clear in pending flag on order.");
    });
  }

  shipAndProcess(shipAndProcessDto: ShipAndProcessDto): void {
    this._httpClient.post<ShipAndProcessDto>(`${this._configService.apiUrl}/Order/ShipAndProcess`, shipAndProcessDto).subscribe(() => {
      this.orderDto.pendingBy = this._configService.getMyMSId();
      this.orderDto.pendingDate = new Date();

      for (const orderDetailDto of this.orderDto.orderDetailDtos.filter(item => item.isSelected)) {
        orderDetailDto.pendingDate = new Date();
        orderDetailDto.pendingQuantity = shipAndProcessDto.shipAndProcessLineDtos.filter(item => item.orderDetailId === orderDetailDto.id)[0].quantity;
        orderDetailDto.isSelected = false;
      }

      this.recalculateFields(this.orderDto);

      this.swalAlert.alert("Items successfully shipped and processed.");
    }, error => {
      this.swalAlert.alert("Error attempting ship and process items.");
    });
  }

  async convertQuote(orderId: number): Promise<void> {
    if (this.orderDto.multiYearCurrentYear) {
      const minimumQuoteYear = Math.min(...this.orderDto.multiYearDtos.filter(item => item.orderType === 'Q').map(item => item.yearOfOrder));
      const firstQuoteIdInDeal = this.orderDto.multiYearDtos.filter(item => item.yearOfOrder === minimumQuoteYear)[0]?.orderId;

      if (firstQuoteIdInDeal !== this.orderDto.id) {
        await this.swalAlert.alert('The first quote in a multi-year deal must be converted first.  Press OK to be redirected to the first quote in the multi-year deal.');
        window.open(`order/${firstQuoteIdInDeal}`);
        return;
      }
    }

    if (!this.orderDto.multiYearCurrentYear || confirm('Are you sure you wish to convert this and the future quotes as well?')) {
      const msId = this._configService.getMyMSId();
      this._httpClient.get<string>(`${this._configService.apiUrl}/Order/ConvertQuote?orderId=${orderId}&userName=${msId}`).subscribe(async () => {
        this.orderDto.orderType = ' ';
        this.orderDto.quoteReasonCode = null;

        this.recalculateFields(this.orderDto);
        await this.swalAlert.alert("Quote successfully converted.");
      }, error => {
        this.swalAlert.alert("Error converting quote.");
      });
    }
  }

  getKeyCodeFromService() {
    if (!this.orderDto.amaKey) {
      for (const orderDetail of this.orderDto.orderDetailDtos) {
        const itemDetail = this._configService.reference?.productDtos.filter(p => p.productCode === orderDetail.productCode && p.requiresAmaKey === true)[0];
        let ameKeyProductCode = '';
        if (itemDetail?.requiresAmaKey) {
          if (itemDetail.productCode.toUpperCase().includes('PRO')) {
            ameKeyProductCode = 'PRO';
          }
          else if (itemDetail.productCode.toUpperCase().includes('PF')) {
            ameKeyProductCode = 'PF';
          }
          this._configService.getNextKeyCode(ameKeyProductCode, String(itemDetail.editionYear)).subscribe(result => {
            this.orderDto.amaKey = result.keyCode;
            this._configService.keyCodeDto.keyCode = result.keyCode;
            this._configService.keyCodeDto.id = result.id;
          });
        }
      }
    }
  }

  async convertException(orderId: number): Promise<void> {
    if (this.orderDto.multiYearCurrentYear) {
      const minimumExceptionYear = Math.min(...this.orderDto.multiYearDtos.filter(item => item.orderType === 'E').map(item => item.yearOfOrder));
      const firstExceptionIdInDeal = this.orderDto.multiYearDtos.filter(item => item.yearOfOrder === minimumExceptionYear)[0]?.orderId;

      if (firstExceptionIdInDeal !== this.orderDto.id) {
        await this.swalAlert.alert('The first exception in a multi-year deal must be converted first.  Press OK to be redirected to the first exception in the multi-year deal.');
        window.open(`order/${firstExceptionIdInDeal}`);
        return;
      }
    }

    if (!this.orderDto.multiYearCurrentYear || confirm('Are you sure you wish to convert this and the future exception as well?')) {
      const msId = this._configService.getMyMSId();
      this._httpClient.get<string>(`${this._configService.apiUrl}/Order/ConvertException?orderId=${orderId}&userName=${msId}`).subscribe(() => {
        this.orderDto.orderType = ' ';
        this.orderDto.exceptionReasonCode = null;

        this.recalculateFields(this.orderDto);
        this.swalAlert.alert("Exception successfully converted.");
      }, error => {
        this.swalAlert.alert("Error converting exception.");
      });
    }
  }

  getAddReturnToBatchButtonDisabled(orderDto: OrderDto): boolean {
    if (orderDto.orderType === 'A') {

      return true;
    }
    if (orderDto.orderStatus === 'V') {
      return true;

    }
    if (orderDto.orderStatus === 'C') {
      return true;
    }
    if (orderDto.orderStatus === 'H') {
      return true;
    }
    if (orderDto.pendingDate !== null) {
      return true;
    }
    if (!orderDto.orderDetailDtos.some(item => item.orderDetailStatus === ' ')) {
      return true;
    }
    return false;
  }

  getAccountIdAsync(contactId: number): Promise<number> {
    return this._httpClient.get<number>(`${this._configService.apiUrl}/Order/getAccountId?contactId=${contactId}`).toPromise();
  }

  async getOfferInfoAsync(promoCode: string): Promise<OfferInfoDto> {
    let observable: Observable<OfferInfoDto>;

    if (this.offerInfoDtoCache) {
      observable = of(this.offerInfoDtoCache);
    }
    else if (this.offerInfoDtoCachedObservable) {
      observable = this.offerInfoDtoCachedObservable;
    }
    else {
      this.offerInfoDtoCachedObservable = this._httpClient.get<OfferInfoDto>(`${this._configService.apiUrl}/Discount/GetOfferInfo?promotionCode=${promoCode}`)
        .pipe(
          tap((response: OfferInfoDto) => {
            this.offerInfoDtoCache = response;
          }),
          share(),
          finalize(() => {
            this.offerInfoDtoCache = null;
            this.offerInfoDtoCachedObservable = null;
          })
        );

      observable = this.offerInfoDtoCachedObservable;
    }
    
    return new Promise(resolve => {
      observable.subscribe({
        next: (data: OfferInfoDto) => {
          resolve(data);
        },
        error: async (e) => {
          console.log(e);
          await this.swalAlert.alert("Error while getting offer info.");
        }
      });
    });
  }

  async buildOrderOfferAsync(orderOfferInputDto: OrderOfferInputDto): Promise<OrderOfferDto> {
    return new Promise(resolve => {
      this._httpClient.post<OrderOfferDto>(`${this._configService.apiUrl}/Discount/BuildOrderOffer`, orderOfferInputDto).subscribe({
        next: (data: OrderOfferDto) => {
          resolve(data);
        },
        error: async (e) => {
          console.log(e);
          await this.swalAlert.alert("Error while getting order level offer.");
        }
      });
    });
  }

  async buildItemOfferAsync(itemOfferInputDto: ItemOfferInputDto): Promise<ItemOfferDto> {
    return new Promise(resolve => {
      this._httpClient.post<ItemOfferDto>(`${this._configService.apiUrl}/Discount/BuildItemOffer`, itemOfferInputDto).subscribe({
        next: (data: ItemOfferDto) => {
          resolve(data);
        },
        error: async (e) => {
          console.log(e);
          await this.swalAlert.alert("Error while getting item level offer.");
        }
      });
    });
  }

  async validateOrder(): Promise<boolean> {
    const promoCodeAndSourceIsRequiredBR=await this.promoCodeAndSourceIsRequiredBR();
    if (!promoCodeAndSourceIsRequiredBR) {
      return false;
    }
    const paymentMethod = await this.paymentPlanBR();
    if(!paymentMethod){
      return false;
    }
    
    await this.validateShippingAndHandlingBR();
    if (!this.validateShipping) {
      return false;
    }
    this.cartCouponTotal();
    
    if (!this.orderDto.shipToDto.state ||
      !this.orderDto.shipToDto.city ||
      !this.orderDto.shipToDto.zip) {
      await this.swalAlert.alert("The ship to address must have state, city and zip");
      return false;
    }

    // Validate Token
    await this.ValidateToken();
   

    // Validate AMA Key
   await this.ValidateAMAKey();
  

    // Validate if the user requires an email address.
    const emailValidation = await this.validateEmailBR();
    if (!emailValidation) {
      return false;
    }

    return true;
  }
  cartCouponTotal() {
    if (this.orderDto.orderDetailDtos.filter(m => m.classCode === 'XS').length > 0) {
      const cartCouponTotal = this.orderDto.orderDetailDtos.filter(m => m.classCode === 'XS' && (m.orderDetailStatus === 'O' || m.orderDetailStatus === ' '))
        .reduce((sum, item) => sum + item.extendedPrice, 0);
      const orderTotal = this.orderDto.orderDetailDtos.filter(m => m.extendedPrice > 0 && (m.orderDetailStatus === 'O' || m.orderDetailStatus === ' ') && m.classCode !== 'XS')
        .reduce((sum, item) => sum + item.extendedPrice, 0);
      this.couponExcessAmount = orderTotal + cartCouponTotal;
      const itemsExtendedTotal = orderTotal.toFixed(2);
      const couponsTotal = cartCouponTotal.toFixed(2);
      if ((parseFloat(couponsTotal) * -1) > parseFloat(itemsExtendedTotal)) {
        this.couponItemCheck.show = true;
        return false;
      }
    }
    return true;

  }
  async ValidateAMAKey() {
    const requiresAmakey=this.orderDto.orderDetailDtos.some(item => item.productDto.requiresAmaKey);
    if (requiresAmakey && (this.orderDto.amaKey === null || this.orderDto.amaKey === '')) {
      await this.swalAlert.alert('AMA Key is required.');
      return false;
    }
    return;
  }
  async ValidateToken() {
    const tokenCheck = this.orderDto.ccToken;
    if ('VMDX'.includes(this.orderDto.paymentMethod ?? 'NULL')) {
      if (!tokenCheck || (tokenCheck && tokenCheck.replace(/[^A-Za-z0-9\-]/g, '').length !== 6)) 
      {
        if(this.orderDto.ccToken && this.orderDto.ccToken.length > 0){
          this.orderDto.ccToken = this.orderDto.ccToken.replace(/[^A-Za-z0-9\-]/g, '');
        }
        await this.swalAlert.alert("Token must be 6 digits");
        return false;
      }
    }
    return;
  }

  async validateEmailBR() {
    if(this.orderDto.orderStatus === "D"){
      return true;
    }

    if(this.router.url.includes('order-entry')) {
      return true;
    }

    const electronicLineItems = this.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus === ' ' &&
      (item.productDto.isElectronic || item.productDto.hasElectronicUpdates) && item.productDto.emailAlert === false);
    const emailAlerts = this.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus === ' ' && item.productDto.isElectronic &&
      item.productDto.hasElectronicUpdates && item.productDto.emailAlert)

    if (electronicLineItems !== undefined && electronicLineItems.length > 0 && (!this.orderDto.shipToDto.email)) {
      const uniqueProductCodes = [...new Set(electronicLineItems.map(item => item.productCode))];
      await this.swalAlert.alert('This order contains products that require a ship-to contact email and will not ship until an email is entered: ' + uniqueProductCodes.join(', '));
      return false;
    }

    if (emailAlerts !== undefined && emailAlerts.length > 0 && (!this.orderDto.shipToDto.email)) {
      const emailAlertsProducts = [...new Set(emailAlerts.map(item => item.productCode))];

      if (!confirm('Product E-Alerts will not be received for items: ' + emailAlertsProducts.join(", ") +
        ' if no email address is entered. Are you sure you want to leave the Ship-To email Blank')) {
        return false;
      }
    }

    return true;
  }

  updateSystemComments() {
    if (this.noDock) {
      this.orderDto.systemComments = '';
      this.noDock = false;
    } else {
      if (this.orderDto.hasDock) {
        this.orderDto.systemComments = 'Customer has a dock and can receive truck shipments';
      } else if (this.orderDto.hasNoDock) {
        this.orderDto.systemComments = 'No dock available, truck with lift gate and hand truck is required';
      }
    }
    const isPoBox = this.orderDto.shipToDto.street.replace('.', '').replace(' ', '').toUpperCase().includes('POBOX');
    if (this.orderDto.systemComments) {
      // Apply system comments.
      this.ApplyComments(isPoBox);
      // Remove system comments that no longer apply.
      this.RemoveComments(isPoBox);
    }
  }
  RemoveComments(isPoBox: boolean) {
    if (this.orderDto.shipmentMethod && this.orderDto.shipmentMethod.toUpperCase() !== 'DONOTSHIP' && this.orderDto.systemComments.includes('Do not ship')) {
      this.orderDto.systemComments = this.orderDto.systemComments.replace(', Do not ship', '').replace('Do not ship', '');
    }
    if (this.orderDto.shipmentMethod !== 'UPS-INT' && this.orderDto.systemComments.includes('UPS International shipping')) {
      this.orderDto.systemComments = this.orderDto.systemComments.replace(', UPS International shipping', '').replace('UPS International shipping', '');
    }
    if (!isPoBox && this.orderDto.systemComments.includes('Must ship USPS')) {
      this.orderDto.systemComments = this.orderDto.systemComments.replace(', Must ship USPS', '').replace('Must ship USPS', '');
    }
  }
  ApplyComments(isPoBox:boolean) {
    
    if (this.orderDto.shipmentMethod && this.orderDto.shipmentMethod.toUpperCase() === 'DONOTSHIP' && !this.orderDto.systemComments.includes('Do not ship')) {
      this.orderDto.systemComments = this.orderDto.systemComments ? `${this.orderDto.systemComments}, Do not ship` : 'Do not ship';
    }
    if (this.orderDto.shipmentMethod === 'UPS-INT' && !this.orderDto.systemComments.includes('UPS International shipping')) {
      this.orderDto.systemComments = this.orderDto.systemComments ? `${this.orderDto.systemComments}, UPS International shipping` : 'UPS International shipping';
    }
    if (isPoBox && !this.orderDto.systemComments.includes('Must ship USPS')) {
      this.orderDto.systemComments = this.orderDto.systemComments ? `${this.orderDto.systemComments}, Must ship USPS` : 'Must ship USPS';
    }
  }

  getReservationData(): DepositDto[] {
    const depositDtos: DepositDto[] = [];

    const oldOtis = this.orderDtoBackup.orderDetailDtos.filter(item => item.orderDetailStatus === ' ' && item.oti).map(item => item.oti ?? '');
    const newOtis = this.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus === ' ' && item.oti).map(item => item.oti ?? '');

    const distinctOtis: string[] = [...new Set(oldOtis.concat(newOtis))];

    for (const oti of distinctOtis) {
      const oldOrderDetailDto = this.orderDtoBackup.orderDetailDtos.filter(item => item.orderDetailStatus === ' ' && item.oti === oti)[0];
      const newOrderDetailDto = this.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus === ' ' && item.oti === oti)[0];

      if (oldOrderDetailDto && !newOrderDetailDto) {
        const unreservationDto = new DepositDto();
        unreservationDto.accountId = this.orderDto.billToDto.id;
        unreservationDto.orderId = this.orderDto.id;
        unreservationDto.userCode = 'AIMP';
        unreservationDto.reservedAmount = Math.abs(oldOrderDetailDto.listPrice);
        unreservationDto.oti = oti;
        unreservationDto.action = 'Unreservation';
        depositDtos.push(unreservationDto);

      } else if (oldOrderDetailDto && newOrderDetailDto && oldOrderDetailDto.listPrice !== newOrderDetailDto.listPrice) {
        const unreservationDto = new DepositDto();
        unreservationDto.accountId = this.orderDto.billToDto.id;
        unreservationDto.orderId = this.orderDto.id;
        unreservationDto.userCode = 'AIMP';
        unreservationDto.reservedAmount = Math.abs(oldOrderDetailDto.listPrice);
        unreservationDto.oti = oti;
        unreservationDto.action = 'Unreservation';
        depositDtos.push(unreservationDto);

        const reservationDto = new DepositDto();
        reservationDto.accountId = this.orderDto.billToDto.id;
        reservationDto.orderId = this.orderDto.id;
        reservationDto.userCode = 'AIMP';
        reservationDto.reservedAmount = Math.abs(newOrderDetailDto.listPrice);
        reservationDto.oti = oti;
        reservationDto.action = 'Reservation';
        depositDtos.push(reservationDto);

      } else if (!oldOrderDetailDto && newOrderDetailDto) {
        const reservationDto = new DepositDto();
        reservationDto.accountId = this.orderDto.billToDto.id;
        reservationDto.orderId = this.orderDto.id;
        reservationDto.userCode = 'AIMP';
        reservationDto.reservedAmount = Math.abs(newOrderDetailDto.listPrice);
        reservationDto.oti = oti;
        reservationDto.action = 'Reservation';
        depositDtos.push(reservationDto);
      }
    }

    return depositDtos;
  }

  fixOrderData(): void {
    if ('VMDX'.includes(this.orderDto.paymentMethod ?? 'NULL')) {
      if (!this.orderDto.ccLastFour) {
        this.orderDto.ccLastFour = '0000'
      }

      if (!this.orderDto.ccExpirationDate) {
        this.orderDto.ccExpirationDate = '0199'
      }
    } else {
      this.orderDto.ccLastFour = null;
      this.orderDto.ccExpirationDate = null;
    }

    if (this.orderDto.poNumber === '') {
      this.orderDto.poNumber = null
    }
  }

  async promoCodeAndSourceIsRequiredBR() {
    if (!this.orderDto.promotionCode && this.orderDto.sourceCode) {
      this.orderDto.promotionCode = this.orderDto.sourceCode;
    }

    if (!this.orderDto.sourceCode) {
      await this.swalAlert.alert("A Valid Source Code is Required.");
      return false;
    }
    return true;
  }



  async validateShippingAndHandlingBRForCalculateButton() {
    await this.validateShippingAndHandlingBR();
    this.shippingDialogModalTriggerEle = "calculateBtn";

    if (!this.validateShipping) {
      return;
    }
    await this.checkShippingAndHandling();
   
    if (this.shippinghandlingService.isError) {
      await this.swalAlert.alert("Shipping and Handling Service is down. Please try again after some time.");
      return;
    }

    //below logic for showing the ups charges screen for OE

   await this.showingUpsChargesScreen();
    
  }
  async showingUpsChargesScreen() {
    const shippingOrderDetail = this.orderDto.orderDetailDtos.filter(item => item.productDto.classCode === 'SH' && item.orderDetailStatus !== 'V')[0];
    if ((shippingOrderDetail) && (this.orderDto.billToDto.accountTypeCode !== 'C') &&  (this.orderDto.shipmentMethod !== 'DONOTSHIP' && this.orderDto.shipmentMethod !== 'MISC') && (this.orderDto.orderStatus !== 'C')) {      this.configurationOrderDetail = shippingOrderDetail;
      await this.shippingDialogModal.openAsync();
    }
  }
  async checkShippingAndHandling() {
    const shippingOrderDetail = this.orderDto.orderDetailDtos.filter(item => item.productDto.classCode === 'SH' && item.orderDetailStatus !== 'V')[0];
    if (shippingOrderDetail) {
      await this.shippinghandlingService.recalculateShippingAndHandlingAsync(this.orderDto);
      this.recalculateFields(this.orderDto);
    }

  }

  async validateShippingAndHandlingBR(): Promise<boolean> {
    const isAlaskaHawaii = this.orderDto.shipToDto.country === 'US' && (this.orderDto.shipToDto.state?.trim() === 'AK' || this.orderDto.shipToDto.state?.trim() === 'HI');
    const domesticStates = 'AL,AK,AZ,AR,CA,CO,CT,DE,DC,FL,GA,HI,ID,IL,IN,IA,KS,KY,LA,ME,MD,MA,MI,MN,MS,MO,MT,'+
    'NE,NV,NH,NJ,NM,NY,NC,ND,OH,OK,OR,PA,RI,SC,SD,TN,TX,UT,VT,VA,WA,WV,WI,WY';
    const isApoFpo = (this.orderDto.shipToDto.city?.toUpperCase()?.substring(0, 3) === 'APO' || this.orderDto.shipToDto.city?.toUpperCase()?.substring(0, 3) === 'FPO');
    const isInternational = (this.orderDto.shipToDto.country !== 'US' || domesticStates.indexOf(this.orderDto.shipToDto.state?.trim()) === -1) && !isApoFpo;
    const isPoBox = this.orderDto.shipToDto.street.replace('.', '').replace(' ', '').toUpperCase().includes('POBOX');
    const isUsps = (isApoFpo || isPoBox) && !isInternational;
    const hasOpenPhysicalItems = this.orderDto.orderDetailDtos.some(item => item.productDto.isPhysical && item.orderDetailStatus === ' ');
    const shippingOrderDetail = this.orderDto.orderDetailDtos.filter(item => item.productDto.classCode === 'SH' && item.orderDetailStatus !== 'V')[0];
    const doNotShipOrMisc = this.orderDto.shipmentMethod === 'DONOTSHIP' || this.orderDto.shipmentMethod === 'MISC';
    this.saveAfterConfigSet = false;

    if (!shippingOrderDetail && hasOpenPhysicalItems && this.orderDto.shipmentMethod !== 'DONOTSHIP' && this.orderDto.shipmentMethod !== 'MISC') {
     let productCode = '';
     this.dertermineProductcode(productCode, isUsps,isInternational,isAlaskaHawaii);
    }
    this.validateShipping = true;
   await this.performShippingValidation(shippingOrderDetail,isInternational,isPoBox,isUsps,isAlaskaHawaii,doNotShipOrMisc,hasOpenPhysicalItems,isApoFpo);
    return this.validateShipping;
  }
  async performShippingValidation(shippingOrderDetail: OrderDetailDto, isInternational: boolean, isPoBox: boolean, isUsps: boolean, isAlaskaHawaii: boolean, doNotShipOrMisc: boolean, hasOpenPhysicalItems: boolean,isApoFpo: boolean) {
    if (!shippingOrderDetail && isInternational && this.orderDto.shipmentMethod !== 'UPS-INT' && hasOpenPhysicalItems) {
      await this.swalAlert.alert('This address is international and must be shipped using UPS-INT.');
      this.validateShipping = false;
    }
    else if (!shippingOrderDetail || shippingOrderDetail.orderDetailStatus === 'C') {
      // If there is no open shipping order detail, then skip validations
      this.validateShipping = true;
    } else if (isPoBox && this.orderDto.shipmentMethod !== 'USPS' && !doNotShipOrMisc) {
      await this.swalAlert.alert('This address contains a PO Box and must be shipped using USPS.');
      this.validateShipping = false;
    } else if (this.orderDto.billToDto.country !== 'US' && this.orderDto.billToDto.country?.toUpperCase() !== 'UNITED STATES' && isPoBox) {
      await this.swalAlert.alert('An international address cannot have a PO Box.');
      this.validateShipping = false;
    } else {
      await this.peformshipping(shippingOrderDetail,isInternational,isPoBox,isUsps,isAlaskaHawaii,doNotShipOrMisc,hasOpenPhysicalItems,isApoFpo)
    }
  }
   
  async peformshipping(shippingOrderDetail: OrderDetailDto, isInternational: boolean, isPoBox: boolean, isUsps: boolean, isAlaskaHawaii: boolean, doNotShipOrMisc: boolean, hasOpenPhysicalItems: boolean,isApoFpo: boolean){
    if (isPoBox && isInternational) {
      // We already put the order on hold and gave the user a warning.
      this.validateShipping = true;
    } else if (!isUsps && ((isAlaskaHawaii && shippingOrderDetail.productCode !== '0045') || (!isAlaskaHawaii && shippingOrderDetail.productCode === '0045'))) {
      await this.swalAlert.alert(`Existing item ${shippingOrderDetail.productCode} is inconsistent with the address.  Please change current shipping item in the cart or Ship Via`);
      this.validateShipping = false;
      this.isShippingInconsistent = true;
    } else if (!isUsps && isAlaskaHawaii && this.orderDto.shipmentMethod !== 'UPS-AK/HI' && !doNotShipOrMisc) {
      await this.swalAlert.alert('Shipments to Alaska or Hawaii must use the UPS Alaska/Hawaii Two Day Air shipping method.');
      this.validateShipping = false;
    } else {
      await this.performshippingValidationContinuation(shippingOrderDetail,isInternational,isPoBox,isUsps,isAlaskaHawaii,doNotShipOrMisc,hasOpenPhysicalItems,isApoFpo);
    }
  }

  async performshippingValidationContinuation(shippingOrderDetail: OrderDetailDto, isInternational: boolean, isPoBox: boolean, isUsps: boolean, isAlaskaHawaii: boolean, doNotShipOrMisc: boolean, hasOpenPhysicalItems: boolean,isApoFpo: boolean) {
    if (isUsps && shippingOrderDetail.productCode !== '0053') {
      await this.swalAlert.alert(`Existing item ${shippingOrderDetail.productCode} is inconsistent with the address.  Please change current shipping item in the cart or Ship Via`);
      this.validateShipping = false;
      this.isShippingInconsistent = true;
    } else if (!isPoBox && isApoFpo && this.orderDto.shipmentMethod !== 'USPS' && !doNotShipOrMisc) {
      await this.swalAlert.alert('APO/FPO shipping address must be shipped using USPS.');
      this.validateShipping = false;
    } else {
      await this.performshippingValidationcontinue(shippingOrderDetail,isInternational,isPoBox,isUsps,isAlaskaHawaii,doNotShipOrMisc,hasOpenPhysicalItems,isApoFpo);
    }
  }

  async performshippingValidationcontinue(shippingOrderDetail: OrderDetailDto, isInternational: boolean, isPoBox: boolean, isUsps: boolean, isAlaskaHawaii: boolean, doNotShipOrMisc: boolean, hasOpenPhysicalItems: boolean,isApoFpo: boolean) {
    if ((isInternational && shippingOrderDetail.productCode !== '0052') || (!isInternational && shippingOrderDetail.productCode === '0052')) {
      await this.swalAlert.alert(`Existing item ${shippingOrderDetail.productCode} is inconsistent with the address.  Please change current shipping item in the cart or Ship Via`);
      this.validateShipping = false;
      this.isShippingInconsistent = true;
    } else if (isInternational && this.orderDto.shipmentMethod !== 'UPS-INT' && !doNotShipOrMisc) {
      await this.swalAlert.alert('This address is international and must be shipped using UPS-INT.');
      this.validateShipping = false;
    } else { await this.checkperformshippingValidationcontinue(shippingOrderDetail,isInternational,isPoBox,isUsps,isAlaskaHawaii,doNotShipOrMisc,hasOpenPhysicalItems,isApoFpo)
      
    };
  }
  async checkperformshippingValidationcontinue(shippingOrderDetail: OrderDetailDto, isInternational: boolean, isPoBox: boolean, isUsps: boolean, isAlaskaHawaii: boolean, doNotShipOrMisc: boolean, hasOpenPhysicalItems: boolean, isApoFpo: boolean) {
    if (isInternational && shippingOrderDetail.listPrice === 0 && this.sourceType === SourceType.OrderInquirySave) {
      await this.swalAlert.alert('Pricing on international shipping change must be manually calculated.');
      this.validateShipping = false;
    }
    else if ((this.orderDto.shipmentMethod === 'UPS-AK/HI' && shippingOrderDetail.productCode !== '0045')
      || (this.orderDto.shipmentMethod === 'USPS' && shippingOrderDetail.productCode !== '0053')
      || (this.orderDto.shipmentMethod === 'UPS' && shippingOrderDetail.productCode !== '0053')
      || (this.orderDto.shipmentMethod === 'UPS-INT' && shippingOrderDetail.productCode !== '0052')
      || (this.orderDto.shipmentMethod === 'UPS-ONE' && shippingOrderDetail.productCode !== '0050')
      || (this.orderDto.shipmentMethod === 'UPS-TWO' && shippingOrderDetail.productCode !== '0051')) {
      await this.swalAlert.alert(`Existing item ${shippingOrderDetail.productCode} is inconsistent with Ship Via.  Please change current shipping item in the cart or Ship Via`);
      this.validateShipping = false;
    }
  }

  
  dertermineProductcode(productCode: string, isUsps: boolean,isInternational:boolean,isAlaskaHawaii:boolean) { 
    productCode = this.checkProductcode(isUsps,productCode,isInternational,isAlaskaHawaii);
    
    this.setShipmentMethod(productCode, isUsps);
    if(this.orderDto.shipmentMethod === 'UPS-ONE')
    {
      productCode = '0050';
    }
    else if(this.orderDto.shipmentMethod === 'UPS-TWO')
    {
      productCode = '0051';
    }
    this.setRushCheckBox(false);

    if (this.orderDto.billToDto.accountTypeCode !== 'C') {
      const newOrderDetail = this.createOrderDetail(productCode);
       this.UpdateOrderDetails(newOrderDetail); 
    }
    return;
  }
  UpdateOrderDetails(newOrderDetail: OrderDetailDto) {
    let lastOrderDetailProductCode = this.orderDto.orderDetailDtos[this.orderDto.orderDetailDtos.length - 1].productCode;
      if(lastOrderDetailProductCode == '' || lastOrderDetailProductCode == '0')
        this.orderDto.orderDetailDtos.splice(this.orderDto.orderDetailDtos.length - 1, 0, newOrderDetail);
      else
        this.orderDto.orderDetailDtos.push(newOrderDetail);

      if (this.sourceType === SourceType.OrderInquirySave && this.orderDto.shipmentMethod === 'UPS-INT') {
          this.saveAfterConfigSet = true;
          this.clickConfiguration(newOrderDetail);
          this.validateShipping = false;
          return false;
        }
      return;  
  }
  createOrderDetail(productCode: string) {
    const newOrderDetail=this.createNewOrderDetail(this.orderDto, productCode);
    newOrderDetail.orderQuantity = 1;
    newOrderDetail.openQuantity = 1;
    newOrderDetail.orderDetailStatus = ' ';
    newOrderDetail.itemEditable = false;
    
    if (newOrderDetail.promotionEntryCode !== 'ILP' && newOrderDetail.promotionEntryCode !== 'ILT' &&
      newOrderDetail.promotionEntryCode !== 'OLT' && newOrderDetail.promotionEntryCode !== 'OLP') {
      newOrderDetail.discount = this._pricingService.calculateAccountDiscount(this.orderDto.billToDto, newOrderDetail.productDto.id);
    }  
    return newOrderDetail;
  }
  checkProductcode(isUsps: boolean, productCode: string,isInternational:boolean,isAlaskaHawaii:boolean): string {
    if (isUsps) {
     return productCode = '0053';
    } else if (isInternational) {
     return productCode = '0052';
    } else if (isAlaskaHawaii) {
     return productCode = '0045';
    } else {
     return  productCode = '0053';
    }
  }

  setShipmentMethod(productCode: string, isUsps: boolean): void {
    if (this.orderDto.shipmentMethod === 'DONOTSHIP' || this.orderDto.shipmentMethod === 'MISC') {
      return;
    }

    if(this.orderDto.shipmentMethod !== 'UPS-ONE' && this.orderDto.shipmentMethod !== 'UPS-TWO')
    {
    if (!productCode) {
      this.orderDto.shipmentMethod = 'UPS';
    } else if (productCode === '0045') {
      this.orderDto.shipmentMethod = 'UPS-AK/HI';
    } else if (productCode === '0050') {
      this.orderDto.shipmentMethod = 'UPS-ONE';
    } else if (productCode === '0051') {
      this.orderDto.shipmentMethod = 'UPS-TWO';
    } else if (productCode === '0053' && isUsps) {
      this.orderDto.shipmentMethod = 'USPS';
    } else if (productCode === '0052') {
      this.orderDto.shipmentMethod = 'UPS-INT';
    } else {
      this.orderDto.shipmentMethod = 'UPS';
    }
  }
    this.orderDto.lastShipmentMethod = this.orderDto.shipmentMethod;
  }

  setRushCheckBox(shipViaChanged: boolean) {
    if (this.orderDto.shipmentMethod === 'UPS-AK/HI'
      || this.orderDto.shipmentMethod === 'UPS-ONE'
      || this.orderDto.shipmentMethod === 'UPS-TWO'
      || this.orderDto.shipmentMethod === 'UPS-INT') {
      this.orderDto.isRush = true;
    } else if (shipViaChanged) {
      this.orderDto.isRush = false;
    }
  }

  async clickConfiguration(record: OrderDetailDto, isEdit: boolean = false): Promise<void> {
    const productDto = this._configService.reference?.productDtos?.filter(p => p.id === record.productId)[0];
    if (!await this.checkIfAuthorizedToEdit(record, productDto)) {
      return;
    }
    
    if (record.productDto.configSetDtos.length === 0) {
      return;
    }

    this.configurationOrderDetail = record;

    if (isEdit) {
      localStorage.setItem('isEditMode', 'true');
    }

    const result = await this.configSetDialog.openAsync();

    if (result === 'Save') {
      const fromPage = localStorage.getItem('from');
      if (fromPage && fromPage === 'depositComponent') {
        this.onSaveDeposit();
      }
    } else if (result === 'Cancel') {
      const fromPage = localStorage.getItem('from');
      if (fromPage && fromPage === 'depositComponent') {
        localStorage.removeItem('isEditMode');
        localStorage.removeItem('openCashDtos');
      }
    }
  }

  async checkIfAuthorizedToEdit(record: OrderDetailDto, productDto: ProductDto | undefined): Promise<boolean> {
    if ((this._authorizationService.hasResource('TucsonAllowPrintProducts') && !this._authorizationService.hasResource('TucsonAllowElectronicProducts'))) {
      if (productDto?.salesOrganization === 'Optum') {
        await this.swalAlert.alert(`You are not authorized to modify the item ${record.productCode}.`);
        return false;
      }
    }
    else if (!this._authorizationService.hasResource('TucsonAllowPrintProducts') && this._authorizationService.hasResource('TucsonAllowElectronicProducts')) {
      if (productDto?.salesOrganization === 'Shutterfly') {
        await this.swalAlert.alert(`You are not authorized to modify the item ${record.productCode}.`);
        return false;
      }
    }
    return true;
  }
  async onSaveDeposit(){
    const openCashDtos = localStorage.getItem('openCashDtos');
    let selectedOpenCashDtos = [];
    if (openCashDtos) {
      selectedOpenCashDtos = JSON.parse(openCashDtos);
    }
    selectedOpenCashDtos = selectedOpenCashDtos.filter((e: any) => e['isSelected'] === true);
    if (!selectedOpenCashDtos.length) {
      this.orderDto.paymentMethod = 'I';
    }
  }
  generatePackingSlip(packingSlipDto: PackingSlipDto): Observable<string> {
    return this._httpClient.post<string>(`${this._configService.apiUrl}/Order/GeneratePackingSlip`, packingSlipDto);
  }

  paymentMethodBR() {
    if (!this.orderDto.billToDto.id) {
      this.orderDto.paymentMethod = 'I';
    }
    else if (!this.orderDto.paymentMethod && this.orderDto.paymentMethod !== undefined) {
      this.orderDto.paymentMethod = this.orderDto.billToDto.defaultPaymentMethod;
    }
    else if(this.orderDto.paymentMethod === undefined)
    {
      this.orderDto.paymentMethod = 'I';
    }
  }
  paymentPlanOrderEntryBR() {
    if (!this.orderDto.billToDto.id) {
      this.orderDto.paymentPlan = 'OneTime';
      this.isPaymentPlanDisabled = true;
      return;
    }
    if (this.orderDto.billToDto.accountTypeCode === 'C' || this.orderDto.billToDto.accountTypeCode==='H' || !this.orderDto.orderDetailDtos.some(item => item.productDto.allowPaymentPlan) || !this.orderDto.billToDto.allowPaymentPlans ) {
      this.orderDto.paymentPlan = 'OneTime';
      this.isPaymentPlanDisabled = true;
    }
    else {
      this.isPaymentPlanDisabled = false;
    }
  }

  async paymentPlanBR(): Promise<boolean> {
    if ((this.orderDto.paymentPlan !== "Monthly" && this.orderDto.paymentPlan !== "Quarterly")) {
      return true;
    }
    if(!await this.isAccountTypeC()){
      return false;
    }
    
    this.getEligibleAmount();
    await this.isPaymentMethod();
   
    return true;
  }
  async isAccountTypeC(): Promise<boolean> {
    if (this.orderDto.billToDto.accountTypeCode === 'C' || this.orderDto.billToDto.accountTypeCode === 'H') {
            await this.swalAlert.alert("Payments plans are not allowed for Account Type: C or Account Type: H");
      return false;
    }
    if (this.orderDto.paymentMethod === "C") {
      await this.swalAlert.alert("Payments plans are not allowed on orders paid using cash or check.");
      return false;
    }
    return true;
  }
  async isPaymentMethod(): Promise<boolean>{
    let itemsNotShipped = this.orderDto.orderDetailDtos
      .filter(item => item.allowPaymentPlan && item.orderDetailStatus !== 'V').every(item => item.shipDate === null);
    let couponItems = this.orderDto.orderDetailDtos
      .filter(item => item.productDto.classCode === 'XS' && item.orderDetailStatus === ' ');
    let paymentplanHold = !this.orderDto.billToDto.allowPaymentPlans;

    if (this.orderDto.eligibleAmount === 0 && this.orderDto.orderStatus !== 'V') {
      await this.swalAlert.alert("All payment plan orders must be greater than 0. Please take this order off of the payment plan.");
      return false;
    }
    if (paymentplanHold && ('M,D,X,V'.indexOf(this.orderDto.paymentMethod ?? '') === -1)) {
      await this.swalAlert.alert("This customer is on Payment Plan Hold which requires payment with a credit card. Please change the Payment method and " +
        "collect a valid credit card from the customer.If a credit card is not available, please take this order off of the Payment Plan.");
      return false;
    }
    if ((this.orderDto.paymentMethod === "N")) {
      if (!await this.paymentPlanBRForMethodN(itemsNotShipped)) {
        return false;
      }
    }
    //The below popup should come before Credit card as per the defect DE248661
    if (!await this.paymentPlanBRForCouponsAndSubscriptions(itemsNotShipped, couponItems)) {
      return false;
    }

    return true;
  }

  async paymentPlanBRForMethodN(itemsNotShipped: boolean): Promise<boolean> {
    if (itemsNotShipped) {
      await this.swalAlert.alert("Payment Method cannot be No Invoice when an unshipped Payment Plan is Monthly or Quarterly. You must change the Payment Plan to 'One-Time Payment'.");
    }
    else {
      await this.swalAlert.alert("Items have shipped, you can not change the Payment Method to No Invoice.")
    }
    return false;
  }

  async paymentPlanBRForCouponsAndSubscriptions(itemsNotShipped: boolean, couponItems: OrderDetailDto[]): Promise<boolean> {
    if (this.orderDto.orderDetailDtos.some(item => item.classCode === 'XS' && this.orderDto.id <= 0 || this.orderDto.id === null)) {
      await this.swalAlert.alert("Coupons are not allowed on an order that is setup with a Payment Plan. The coupon items has been removed from the order");
      this.orderDto.orderDetailDtos = this.orderDto.orderDetailDtos.filter(item => item.classCode !== 'XS');
      this.orderDto.totalQuantity = this.orderDto.orderDetailDtos.map(o => o.orderQuantity).reduce((prev, next) => prev + next, 0);
      return false;
    }

    if (('M,D,X,V'.indexOf(this.orderDto.paymentMethod ?? '') === -1) && (this.orderDto.eligibleAmount > 0 && this.orderDto.eligibleAmount < 2000) ) {
      await this.swalAlert.alert("All payment plan orders that are less than $2000 require payment with a credit card. Please change the payment method and collect a valid " +
        "credit card from the customer.If a credit card is not available, please take this order off the payment plan.");
      return false;
    }

    if (this.orderDto.orderDetailDtos.some(item => item.classCode === 'XS' && this.orderDto.id > 0)) {
      await this.swalAlert.alert("Coupons are not allowed on an order that is setup with a Payment Plan.");
      return false;
    }
    if (couponItems.length > 0 && itemsNotShipped) {
      await this.swalAlert.alert("Payment Plans are not allowed with coupons");
      return false;
    }

    if (this.orderDto.eligibleAmount > 0 && this.orderDto.orderDetailDtos.some(item => item.subscriptionDto && item.subscriptionDto.term < 12)) {
      await this.swalAlert.alert("Payment plans are not allowed on subscriptions with terms less than 12 months");
      return false;
    }

    return true;
  }

  async reviewAndUpdateRequiredDate() {
    
    let nonSubscriptionNonEditionProductDtos = this._configService.reference?.productDtos
      .filter(p => p.editionYear === null &&
        p.statusCode === 'A' &&
        p.isSubscription === false &&
        p.productGroupCode.toLowerCase() !== 'shiptax' &&
        p.productCode !== '0010' &&
        !p.productCode.startsWith('DV'));
    let nonSubscriptionEditionProductDtos = this._configService.reference?.productDtos
      .filter(p => p.editionYear !== null &&
        p.statusCode === 'A' &&
        p.isSubscription === false &&
        p.productGroupCode.toLowerCase() !== 'shiptax' &&
        p.productCode !== '0010' &&
        !p.productCode.startsWith('DV'));
    let nonSubscriptionNonEditionProducts: string[] = [];
    let nonSubscriptionEditionProducts: string[] = [];
    let nonSubscriptionNonEditionProductsMessage = '';
    let nonSubscriptionEditionProductsMessage = '';

    this.orderDto.orderDetailDtos.forEach(item => {
      if (nonSubscriptionNonEditionProductDtos?.findIndex(n => n.productCode === item.productCode)! > -1) {
        nonSubscriptionNonEditionProducts.push(item.productCode);
      }
      this.getNonSubscriptionEditionProducts(item, nonSubscriptionEditionProductDtos, nonSubscriptionEditionProducts);
    });

    if (nonSubscriptionNonEditionProducts.length > 0 && this.flagforitemdialog) {
      nonSubscriptionNonEditionProductsMessage = 'Review and update the required date as needed for ' + nonSubscriptionNonEditionProducts.join(',') + ' products';
    }

    if (nonSubscriptionEditionProducts.length > 0) {
      nonSubscriptionEditionProductsMessage = 'Contact Order Management to have the next edition year product created for ' +
        nonSubscriptionEditionProducts.join(',') + ' products';
    }

    if (nonSubscriptionNonEditionProductsMessage !== '' && nonSubscriptionEditionProductsMessage === '') {
      await this.swalAlert.alert(nonSubscriptionNonEditionProductsMessage);
    }
    else if (nonSubscriptionNonEditionProductsMessage === '' && nonSubscriptionEditionProductsMessage !== '') {
      await this.swalAlert.alert(nonSubscriptionEditionProductsMessage);
    }
    else if (nonSubscriptionNonEditionProductsMessage !== '' && nonSubscriptionEditionProductsMessage !== '') {
      await this.swalAlert.alert(nonSubscriptionNonEditionProductsMessage + '\n' + nonSubscriptionEditionProductsMessage);
    }
  }

  async getNonSubscriptionEditionProducts(orderDetail: OrderDetailDto, nonSubscriptionEditionProductDtos: ProductDto[] | undefined, nonSubscriptionEditionProducts: string[]) {

    if (nonSubscriptionEditionProductDtos?.findIndex(n => n.productCode === orderDetail.productCode)! > -1) {
      let yearOfOrder: number | null = this.orderDto.multiYearCurrentYear;

      if (yearOfOrder) {
        let multiYearOrders: MultiYearDto[] = this.orderDto.multiYearDtos.filter(m => m.yearOfOrder > yearOfOrder!);

        if (multiYearOrders.length > 0) {
          let futureNonSubscriptionEditionProducts: ProductDto[] | undefined = nonSubscriptionEditionProductDtos?.filter(p => p.editionYear! > orderDetail.productDto.editionYear!
            && p.acronym === orderDetail.productDto.acronym?.trim());

          if (futureNonSubscriptionEditionProducts!.length === 0) {
            nonSubscriptionEditionProducts.push(orderDetail.productCode);
          }
        }
      }
    }
  }

  openurl() {
   return true;
  }

  addYear(fromOE: boolean) {

    this._modeService.addToWaitList('Adding Year');
    const msId = this._configService.getMyMSId();
    
    this.addYearOrder(this.orderDto.id, msId, fromOE)
      .subscribe((addYear: AddYearDto) => {
        this._modeService.removeFromWaitList('Adding Year');
        if (addYear.missingProductCodes.length > 0) {
          this.swalAlert.alert('Order Management has been contacted to have the Next Edition Year Product created for products ' + addYear.missingProductCodes.join(','));
        }

        if (this.orderDto?.multiYearDtos?.length === 0) {
          this.orderDto?.multiYearDtos?.push(
            {
              orderId: this.orderDto.id,
              yearOfOrder: new Date().getFullYear(),
              multiYearId: this.orderDto.id,
              orderDate: this.orderDto.orderDate,
              orderType: this.orderDto.orderType,
              orderStatus: this.orderDto.orderStatus,
              shippedAmount: this.orderDto.shippedAmount
            });

          this.orderDto?.multiYearDtos?.push(
            {
              orderId: addYear.id,
              yearOfOrder: new Date().getFullYear() + 1,
              multiYearId: this.orderDto.id,
              orderDate: new Date(),
              orderType: ' ',
              orderStatus: this.orderDto.orderStatus,
              shippedAmount: this.orderDto.shippedAmount
            });
        } else {
          this.orderDto?.multiYearDtos?.push(
            {
              orderId: addYear.id,
              yearOfOrder: (this.orderDto?.multiYearDtos[this.orderDto?.multiYearDtos?.length - 1].yearOfOrder + 1),
              multiYearId: this.orderDto.multiYearDtos[0].multiYearId,
              orderDate: new Date(),
              orderType: ' ',
              orderStatus: this.orderDto.orderStatus,
              shippedAmount: this.orderDto.shippedAmount
            });
        }
        if (fromOE) {
          window.open(`order-entry?orderId=${addYear.id}&EditMode=true&NewMYOrder=true`);
        }
        else {
          window.open(`order/${addYear.id}?EditMode=true`);
        }
      }, error => {
        console.log(error);
        this._modeService.removeFromWaitList('Adding Year');
        this.swalAlert.alert('Error adding order to MY deal.');
      });
  }

   getDataAccessContactsAsync(orderId: number): Observable<DataFileAccessDto[]> {
    return this._httpClient.get<DataFileAccessDto[]>(`${this._configService.apiUrl}/Order/GetDataFileAccessContacts?orderId=${orderId}`);
  }
  
  getOpportunitiesProducts(OpportunityId: string): Observable<OpportunityDto> {
    return this._httpClient.get<OpportunityDto>(`${this._configService.apiUrl}/Order/GetOpportunityProducts/${String(OpportunityId)}`);
  }

  setPaymentMethodBR() {
    const defaultPaymentMapping = [ 
      { account: 'I', contact: 'I', order: 'I' },
      { account: 'S', contact: 'I', order: 'S' },
      { account: 'N', contact: 'I', order: 'N' },
      { account: 'I', contact: 'S', order: 'S' },
      { account: 'S', contact: 'S', order: 'S' },
      { account: 'N', contact: 'S', order: 'N' },
      { account: 'I', contact: 'N', order: 'N' },
      { account: 'S', contact: 'N', order: 'N' },
      { account: 'N', contact: 'N', order: 'N' }
    ];

    if (!this.orderDto.paymentMethod || !'V,M,X,D,C'.includes(this.orderDto.paymentMethod)) {
      this.orderDto.paymentMethod = defaultPaymentMapping.filter(item => 
        item.account === (this.orderDto.billToDto.defaultPaymentMethod ?? 'I')
        && item.contact === (this.orderDto.shipToDto.defaultPaymentMethod ?? 'I'))[0]?.order;
    }
  }
  async DownloadAttachment(fileName: string) {
    const filename = 'TestFileRRRR'; // Replace with your filename
    this.http.get(this._configService.apiUrl + '/order/AttachmentsDownload/' + fileName, {
      responseType: 'blob',
    }).subscribe((blob: Blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename+'.png';
      link.click();
    }); 
  }

  async UpdateOrderDetailOnChangeSalesPerson(): Promise<void>{
    this.salesPerson = this._configService.getPickList('SALESPERSON').filter(a => a.label == this.orderDto.selectedsalesPersonName).find(a =>a.id);
    this.orderDto.orderDetailDtos.forEach(a => {
      a.salespersonCode = this.salesPerson.id.toString();
      a.salespersonFullName = this._configService.getFullNameFromUserCode(this.salesPerson.id);
    })
  }
  
  getAccountsList(accountSearchInput: AccountSearchFinalDto): Observable<string> {
    return this._httpClient.post<string>(`${this._configService.apiUrl}/Order/SearchAccount`, accountSearchInput);
  }

  async getRecentQuotesOrOrders(accountId: number): Promise<RecentQuotesOrOrdersDto[]> {
    return new Promise(resolve => {
      const message = 'Getting Recent Quotes/Orders';
      this._modeService.addToWaitList(message);
      this._httpClient.get<RecentQuotesOrOrdersDto[]>(`${this._configService.apiUrl}/order/getrecentquotesororders/${accountId}`).subscribe({
        next: data => {
          this._modeService.removeFromWaitList(message);
          resolve(data);
        }, error: async e => {
          console.log(e);
          this._modeService.removeFromWaitList(message);
          await this.swalAlert.alert(this.errorHandlingService.getErrorMessage(e));
          resolve([]);
        }
      });
    });
  }

  async ConvertOrdersToQuote(orderId: number, userName: string): Promise<void> {
    return new Promise(resolve => {
      const message = 'Converting Order to Quote';
      this._modeService.addToWaitList(message);
      this._httpClient.get(`${this._configService.apiUrl}/Order/ConvertOrderToQuote?orderId=${orderId}&userName=${userName}`).subscribe({
        next: data => {
          this._modeService.removeFromWaitList(message);
          resolve();
        }, error: async e => {
          console.log(e);
          this._modeService.removeFromWaitList(message);
          await this.swalAlert.alert(this.errorHandlingService.getErrorMessage(e));
          resolve();
        }
      });
    });
  }
//   getAccountDetails(accountId: number): Observable<AccountDto> {  
//     const url = `${this._configService.apiUrl}/Account/GetAccountDetails/${accountId}`;  
//     return this._httpClient.get<AccountDto>(url);  
//   }  
//   etAccountDetails(accountNumber: string): Observable<AccountDto> {  
//     // Implement the logic to fetch account details from the backend  
//     return this.httpClient.get<AccountDto>(`/api/account/${accountNumber}`);  
// }  
  getOrderInfo(orderId: number): Observable<OrderDto> {
    return this._httpClient.get<OrderDto>(`${this._configService.apiUrl}/Order/GetOrder/${Number(orderId)}`);
  }
}
