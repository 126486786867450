<br>
<div class="order-summary-section container-fluid" id="orderSummarySection">
  <div class="bg-white">
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <label class="col-lg-2 align-self-center" id="poNumberOELabel" uitkLabel><span [ngClass]="{'hilightPoFiled': orderService.orderDto.billToDto.isPORequired}">PO # </span></label>
          <div class="col-lg-10 no-lp">
            <uitk-form-field>
              <input id="poNumberOE" uitkInput maxlength="75" [(ngModel)]="orderService.orderDto.poNumber"
                [disabled]="modeService.mode !== Mode.Edit" onkeyup="this.value=this.value.substring(0,
                            75).replace(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
                            '')" (ngModelChange)="onChangePOBoxKey($event)" appTextFormat (keydown.Tab)="onChangePONumber()" />
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-4 align-self-center" id="ppEligibleAmtOELabel" uitkLabel>PP Eligible
            Amt</label>
          <div class="col-lg-8">
            <uitk-form-field>
              <input id="ppEligibleAmtOE" type="text" [(customModel)]="orderService.orderDto.eligibleAmount" uitkInput
                disabled appPriceFormat />
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3 border-start border-grey">
        <div class="row">
          <label class="col-lg-4 align-self-center" id="subTotalOELabel" uitkLabel>Sub Total</label>
          <div class="col-lg-8">
            <input id="subTotalOE" uitkInput class="textbox" type="text"
              [(customModel)]="orderService.orderDto.openAmount" disabled appPriceFormat />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <div class="row mt-2">
          <label class="col-lg-4 align-self-center" id="shipViaDDOELabel" uitkLabel>Ship Via</label>
          <div class="col-lg-5 no-lp no-rp">
            <select #mySelect id="shipViaDD" [(ngModel)]="orderService.orderDto.shipmentMethod"
              [disabled]="modeService.mode !== Mode.Edit" (change)="onShipViaChange(mySelect.value)" class="form-select"
              aria-label="select">
              <option *ngFor="let list of shippingMethods" value={{list.deliveryCode}}>
                {{list.deliveryCode}}
              </option>
            </select>
          </div>
          <div id="rushCheckbox" class="col-lg-3 no-lp no-rp">
            <uitk-form-field>
              <input uitkCheckbox id="rushCheckboxOE" type="checkbox" [(ngModel)]="orderService.orderDto.isRush"
                [disabled]="orderService.orderDto.shipmentMethod === 'UPS-AK/HI' || orderService.orderDto.shipmentMethod === 'UPS-INT'
                                        || orderService.orderDto.shipmentMethod==='UPS-ONE' || orderService.orderDto.shipmentMethod==='UPS-TWO' || modeService.mode !== Mode.Edit" />
              <label uitkCheckboxLabel >Rush</label>
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row mt-2">
          <label class="col-lg-3 align-self-center" id="glStringOELabel" uitkLabel>GL String </label>
          <div class="col-lg-9 no-lp">
            <uitk-form-field>
              <input id="glStringOE" uitkInput class="textbox" type="text"  tabindex="1"
                onkeyup="this.value=this.value.replace(/[^a-zA-Z0-9 ]/g, '')" maxlength="30"
                [disabled]="modeService.mode !== Mode.Edit" scrollToTarget target="#emailNotesSection" 
                (keydown.Tab)="onChangeGLString()" />
            </uitk-form-field>
          </div>
          
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row mt-2">
          <label class="col-lg-4 align-self-center" uitkLabel>Payment Plans</label>
          <div class="col-lg-8">
            <select id="paymentPlanDDOE" [(ngModel)]="orderService.orderDto.paymentPlan" class="form-select"
              aria-label="select" (change)="onChangePaymentPlanValue()"
              [disabled]="orderService.isPaymentPlanDisabled || modeService.mode !== Mode.Edit">
              <option value="" selected>Select</option>
              <option *ngFor="let list of paymentPlansList" [ngValue]="list.value" [disabled]="list.disabled">
                {{list.label}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-3 border-start border-grey">
        <div class="row mt-2">
          <label class="col-lg-4 align-self-center" id="taxRateOELabel" uitkLabel>Tax Rate</label>
          <div class="col-lg-8">
            <input id="taxRateOE" uitkInput class="textbox" type="text" [(customModel)]=orderService.orderDto.maxTaxRate
              disabled appDiscountFormat />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <div class="row mt-2">
          <label class="col-lg-4 align-self-center" id="paymentMethodOELabel" uitkLabel >Method</label>
          <div class="col-lg-8 no-lp">
            <select id="methodDD" [(ngModel)]="orderService.orderDto.paymentMethod"  
              (change)="callCashReceiptBR();onChangePaymentMethod();" class="form-select" aria-label="select"
              appEnableResource="OrderInquiryFooterMethodField"
              [disabled]="modeService.mode !== Mode.Edit">
              <option value="" selected>Select</option>
              <option *ngFor="let list of methodList" [ngValue]="list.value" [disabled]="list.disabled">
                {{list.label}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row mt-2">
          <label class="col-lg-3 align-self-center" id="paymentTermsOELabel" uitkLabel>Terms</label>
          <div class="col-lg-9">
            <select *ngIf="orderService.orderDto.paymentTerms==='Credit Card'" id="termsDD"
              appEnableResource="OrderEntrySummaryTermsDropDown" [(ngModel)]="orderService.orderDto.paymentTerms"
              class="form-select" aria-label="select" [disabled]="true">
              <option value="null" selected>Select</option>
              <option *ngFor="let list of termsList" [ngValue]="list.label">{{list.label}}</option>
            </select>
            <select *ngIf="orderService.orderDto.paymentTerms!=='Credit Card'" id="termsDD"
              appEnableResource="OrderEntrySummaryTermsDropDown" [(ngModel)]="orderService.orderDto.paymentTerms" (change)="onChangePaymentTerm()"
              class="form-select" aria-label="select"
              [disabled]="modeService.mode !== Mode.Edit || disablePaymentTerms">
              <option value="null" selected>Select</option>
              <option *ngFor="let list of termsListWithoutCreditCard" [ngValue]="list.label">
                {{list.label}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row mt-2">
          <label class="col-lg-4 align-self-center" id="ccAuthOELabel" uitkLabel>CC Auth</label>
          <div class="col-lg-8">
            <uitk-form-field>
              <input id="ccAuthOE" uitkInput [(ngModel)]="ccAuth" disabled />
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3 border-start border-grey">
        <div class="row mt-2">
          <label class="col-lg-4 align-self-center" id="totalTaxOELabel" uitkLabel>Total Tax</label>
          <div class="col-lg-8">
            <uitk-form-field><input id="totaltaxOE" [(customModel)]="orderService.orderDto.totalTax" uitkInput disabled
                appPriceFormat />
            </uitk-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <div class="row mt-2">
          <label class="col-lg-4 align-self-center" id="ccTokenOELabel" uitkLabel>CC/Token</label>
          <div class="col-lg-8 no-lp">
            <uitk-form-field>
              <input id="tokenOEInput" uitkInput class="textbox" type="text" [(ngModel)]="orderService.orderDto.ccToken"
                (ngModelChange)="onNgModelChange($event)" name="tokenInp" #tokenInp="ngModel"
                [disabled]="orderService.orderDto.ccToken?.startsWith('Processing') || modeDisabled || modeService.mode !== Mode.Edit"
                (input)="tokenChange()" />
              <uitk-form-field-error
                *ngIf="!modeDisabled && orderService.orderDto.ccToken!=='' && orderService.orderDto.ccToken?.length!==6 && tokenInp.touched">
                Token must be 6 digits
              </uitk-form-field-error>
              <uitk-form-field-error *ngIf="tokenInp?.errors?.required && tokenInp.touched || creditCardRequired">
                Token is required
              </uitk-form-field-error>
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row mt-2">
          <label class="col-lg-3 align-self-center" id="expOELabel" uitkLabel>Exp</label>
          <div class="col-lg-9">
            <uitk-form-field>
              <input id="expOEInput" uitkInput maxlength="4" [(ngModel)]="expirationDate" name="expDateInp"
                (change)="expirationdate()" #expDateInp="ngModel" [pattern]=expDatePattern
                value="{{expirationDate}}"
                (input)="expDateChange(expirationDate)" [disabled]="modeDisabled || modeService.mode !== Mode.Edit" />
              <uitk-form-field-error *ngIf="expirationDate!=='' && (isPastDate)">
                Date is expired
              </uitk-form-field-error>
              <uitk-form-field-error
                *ngIf="(expirationDate!=='' && expDateInp?.errors?.pattern) || showExpDatePatternError">
                Should be valid MMYY format
              </uitk-form-field-error>
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row mt-2">
          <label class="col-lg-4 align-self-center" id="last4ofCCOELabel" uitkLabel>Last 4 of CC</label>
          <div class="col-lg-8">
            <uitk-form-field>
              <input id="last4ofCCOEInput" uitkInput class="textbox" type="text" maxlength="4"
                onkeyup="this.value=this.value.replace(/[^0-9]/g, '')" [(ngModel)]="orderService.orderDto.ccLastFour"
                [disabled]="modeDisabled || modeService.mode !== Mode.Edit" />
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3 border-start border-grey">
        <div class="row mt-2">
          <label class="col-lg-4 align-self-center" id="soTotalOELabel" uitkLabel>SO Total</label>
          <div class="col-lg-8">
            <uitk-form-field><input id="soTotalOE"
                [(customModel)]="orderService.orderDto.openAmount + orderService.orderDto.totalTax" uitkInput disabled
                appPriceFormat /></uitk-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-9 mt-2">
        <div class="row">
          <div class="col-lg-4" *ngIf = "!this.orderService.isSnapshot">
            <button id="packingSlipButtonOE" type="button"
              class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1 uitk-u-margin--xxs"
              (click)="packingSlipNotesDialog.show = true" [disabled]="modeService.mode !== Mode.Edit">
              Packing Slip Notes</button>
              <button id="EmailNotesButtonOE" type="button"
              class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1 uitk-u-margin--xxs"
              (click)="showEmailNotesDialog.show = true" [disabled]="modeService.mode !== Mode.Edit">
              Email Notes</button>
              <!-- future userstory -->
              <!-- <button id="OrderAttachmentsOE" type="button"
              class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1 uitk-u-margin--xxs"
              (click)="showAttachmentsDialog.show = true" [disabled]="modeService.mode !== Mode.Edit">
              Attachments</button>   -->
          </div>
          
            
          <label class="col-lg-1 align-self-center" id="amaKeyOELabel" uitkLabel style="    margin-top: -1%;
          ">AMA Key</label>
          <div class="col-lg-7 no-lp">
            <uitk-form-field>
              <input id="amaKeyOEInput" uitkInput class="textbox" type="text" [(ngModel)]="orderService.orderDto.amaKey"
                (ngModelChange)="onChangeAMEKey($event)" name="amakey" #amakey="ngModel"
                [disabled]="disableAmaKeyButton || modeService.mode !== Mode.Edit"
                (input)="onChangeAMEKey($event)"/>                
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3 border-start border-grey"></div>
    </div>
  </div>
</div>


<div *ngIf="packingSlipNotesDialog.show">
  <app-packing-slip-notes [dialog]="packingSlipNotesDialog" triggerEleId="packingSlipButtonOE"></app-packing-slip-notes>
</div>
<div *ngIf="showEmailNotesDialog.show" id="emailNotesSection">
  <app-save-email-notes [dialog]="showEmailNotesDialog"></app-save-email-notes>
</div>
<div *ngIf="showAttachmentsDialog.show" id="attachmentsSection">
  <app-order-attachments [dialog]="showAttachmentsDialog"></app-order-attachments>
</div>

