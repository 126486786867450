<div class="css-t7viak" >
  <div class="uitk-l-grid">
    <div class="uitk-l-grid__row">
      <div class="uitk-l-grid__col--12">
        <div class="uitk-c-table__caption">
          <h4 id="table-paginate" class="headerTextClass">Confirm Authorization </h4>
        </div>
        <div class="uitk-u-flex__align--left">
          <button (click)="saveAuthorizations()" id="saveBtn" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--lg">
            Save
          </button>
          <div id="saveDiv" class="float-end uitk-u-margin--lg">
            <div class="uitk-u-flex__align--right">
              Count: {{ count }}<br>
              Amount: {{ totalAmount | currency }}
            </div>
          </div>  
        </div>
        <div class="uitk-c-table--responsive" uitkTableContainer [sticky]="true" style="max-height:800px;">
          <table uitkTable [dataSource]="dataSource" class="uitk-c-table" aria-describedby="order-data"
            id="order-results-table" aria-labelledby="table-paginate" aria-describedby="row-data">
            <thead class="uitk-c-table-expandable-row__thead">
              <tr uitkTableSort #sortTable="uitkTableSort">
                <th id="moreHeader" style="position: sticky; left: 0px; background-color: #ffffff; padding-inline: 4px; border-right: none;" class="freeze">More</th>
                <th id="chkBox" style="position: sticky; left: 0px; background-color: #ffffff; border-right: 1px solid #D0D0CE" class="freeze" scope="col">
                  <input type="checkbox" class="checkBoxInGrid" style="position: relative; height: 20px; width: 20px; z-index: 1700 !important" name="selectallCheckBox" [(ngModel)]="selectAllAuthorizations" (ngModelChange)="selectAll()" />
                </th>
                <th *ngFor="let headerName of tableHeader" scope="col" uitkTableSortColumn (sortChange)="onSortChange($event)" [column]="headerName.id">
                  {{ headerName.name }}
                </th>
              </tr>
            </thead>
            <tbody id="row-data" class="uitk-c-table__body">
              <ng-container *uitkTableRowRenderer="let data">
                <ng-container *uitkTableRowContext>
                  <tr uitkTableExpandableRow [expandableContent]="expandableContent"
                    expandableA11yText=" details for {{data.authId}}">
                    <td>
                      <input type="checkbox" class="checkBoxInGrid" style="position:initial" name="selectRowCheckBox" [(ngModel)]="data.isSelected" />
                    </td>
                    <td><a target="_blank" (click)="navigateToARInquiry(data.accountId)"  class="link">{{data.accountId}}</a></td>
                    <td><a href="#" target="_blank" [routerLink]="['/return', data.id]"  class="link">{{data.id}}</a></td>
                    <td>{{ data.amount | currency:'USD':'symbol':'1.2-2'}}</td>
                    <td>{{ data.authorizationDate |date: 'MM/dd/yyyy' }}</td>
                    <td><a href="#" target="_blank" [routerLink]="['/order', data.orderId]"  class="link">{{data.orderId}}</a></td>
                    <td>{{ data.cmFlag }}</td>
                    <td><a target="_blank" (click)="navigateToSFViewAccount(data.salesforceId)"  class="link">{{data.company}}</a></td>
                    <td>{{ data.returnCode }}</td>
                    <td>{{ data.accountType }}</td>
                    <td><a target="_blank" [href]="'mailto:'+ data.enteredByemail">{{ data.enteredBy}}</a>
                    
                    </td>
                    
                    <td class="px-2" [ngbTooltip]="notesFull" container="body" placement="left auto"><div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 150px;word-break: break-all"
                      >{{ data.notes[0].noteText }}</div></td>
                    
                    <ng-template #notesFull><pre *ngFor="let note of data.notes; let i=index" style="white-space:pre-wrap; width: 1400px"><hr *ngIf="i > 0"><div *ngIf="note.systemNote" style="color: white !important"><strong>System Note:</strong> {{ note.systemNote }}<br><br></div><div *ngIf="note.noteText" style="color: white !important;"><strong>Note:</strong> {{ note.noteText }}</div></pre></ng-template>                    
                  </tr>
                  <ng-template #expandableContent>
                    <tr id="expandableContent" uitkExpandableContent [closeBtn]="false">
                      <app-additional-information [authorizationDto]="data"></app-additional-information>
                    </tr>
                  </ng-template>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>

        <div class="uitk-u-flex__align--left">
          <button (click)="saveAuthorizations()" id="saveBtn" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--lg">
            Save
          </button>
        </div>      
      </div>
    </div>
  </div>
</div>