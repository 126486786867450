<div *ngIf = "filePickerPopup.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="filePickerPopup.show" [closeBtn]=false>
  <uitk-dialog-header>    
    <div cdkDragHandle>
      <span>Add Order Attachment</span>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content id="orderAttachmentAdd">        
    <div class="uitk-l-grid">
      <div class="uitk-l-grid__row">
        <div class="uitk-l-grid__col">
          <form [formGroup]="fileUploadForm">
            <uitk-form-field>
              <uitk-file-upload
                [id]="'multiple-with-button-upload'"
                formControlName="fileUpload"
                [url]="url"
                [fileMaxSize]="fileSizeLimit"
                [isUploadOnSelect]="false"
                [hideUploadButton]="false"
                [checkFileDuplication]="true"
                (onCancel)="handleOnCancel($event)"
                (onRemove)="handleOnRemove($event)"
                (onSelectFiles)="handleOnSelectFiles($event)"
                (onUpload)="handleOnUpload($event)"
                (onError)="handleOnError($event)"
                (onValidation)="handleOnValidation($event)">
              </uitk-file-upload>
              <uitk-form-field-error
                *ngIf="
                    fileUploadForm?.controls?.fileUpload?.errors?.fileLimitExceeded &&
                    !fileUploadForm?.controls?.fileUpload?.errors?.invalidFiles
                    ">
                  <span>
                    Too many files selected. Remove files to upload
                  </span>
              </uitk-form-field-error>
              <uitk-form-field-error
                *ngIf="
                    fileUploadForm?.controls?.fileUpload?.errors?.noFilesSelected &&
                    fileUploadForm?.controls?.fileUpload?.touched
                    ">
                    No file selected
              </uitk-form-field-error>            
            </uitk-form-field>
          </form>            
        </div>
      </div>                          
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="close()" appAutoFocus>
          Close
      </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>
<div *ngIf = "overrideOrderAttachmentPopup.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="overrideOrderAttachmentPopup.show" [closeBtn]=false>
  <uitk-dialog-content scrollToTarget>
    Are you sure you want to override this Order Attachment?
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button id="yesbtnoverride" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="overrideOrderAttachment()" appAutoFocus>
      Yes
    </button>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="cancelOverrideDeleteOrderAttachment()">
      No
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>