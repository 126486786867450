<div [hidden]="modeService.mode == Mode.CashInquiryWait" class="css-t7viak">
    <div class="uitk-l-grid">
      <div class="uitk-l-grid__row">
        <div class="uitk-l-grid__col--12">
          <div class="uitk-c-table__caption">
            <h3 id="table-paginate" class="caption" style="color: #002677;">Search Results</h3>
            Total Records : {{TotalRecords}}
          <br>
          </div>
          <uitk-pagination [paginationConfig]="paginationConfig" [entriesPerPage]="entriesPerPage"
                         [enableGoToPage]="true" [totalRecordsCount]="TotalRecords"
                         (onPaginationChange)="onPaginationChange($event)">
            <div class="uitk-c-table--responsive" uitkTableContainer [sticky]="true" [offset]="collapedHeader ? 430 : 680" appExpand>
              <table uitkTable [dataSource]="cashInquiries" class="uitk-c-table" aria-describedby="cashInquiry-data"
                     id="cashInquiry-results-table" aria-labelledby="table-paginate" aria-describedby="row-data"
                     #rootTable="uitkTable">
  
                <thead class="uitk-c-table__head">
                  <tr uitkTableSort #sortTable="uitkTableSort">
                    <th uitkTableSortColumn
                                        [column]="headerName.id"
                                        (sortChange)="onSortChange($event)" *ngFor="let headerName of tableHeader" scope="col">
                      {{ headerName.name }}
                    </th>
                  </tr>
                </thead>
                <tbody id="row-data" class="uitk-c-table__body">
                  <ng-container *uitkTableRowBody="let cashInquiry">
                    <tr class="uitk-c-table__row">
                      <td>{{cashInquiry.referenceNumber}}</td>
                      <td>{{cashInquiry.paidAmount |currency}}</td>
                      <td><a target="_blank" class="link" style="text-decoration: none;" (click)="navigateToARInquiry(cashInquiry.accountId)">{{cashInquiry.accountId}}</a></td>
                      <td>{{cashInquiry.paidDate | date: 'MM/dd/yyyy'}}</td>
                      <td>{{cashInquiry.accountName}}</td>
                      <td *ngIf="cashInquiry.invoiceNumber !== '_RECEIPT'"><a target="_blank" class="link" style="text-decoration: none;" (click)="navigateToARInquiryInvoice(cashInquiry.accountId, cashInquiry.invoiceNumber)">{{cashInquiry.invoiceNumber}}</a></td>
                      <td *ngIf="cashInquiry.invoiceNumber === '_RECEIPT'">{{cashInquiry.invoiceNumber}}</td>
                      <td>{{cashInquiry.invoiceDate | date: 'MM/dd/yyyy'}}</td>
                      <td><a target="_blank" [routerLink]="['/order', cashInquiry.orderId]" class="link">{{cashInquiry.orderId}}</a></td>
                      <td>{{cashInquiry.poNumber}}</td>
                      <td>{{cashInquiry.sessionId}}</td>
                      <td>{{cashInquiry.userId}}</td>
                    </tr>
                  </ng-container>
                  <div *ngIf="cashInquiries.data.length === 0">No records found</div>
                </tbody>
              </table>
            </div>
        </uitk-pagination>
        </div>
      </div>
    </div>
  </div>
