<div class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog id="holdReasonDialog" [(showModal)]="dialog.show" [closeBtn]=false>
  <uitk-dialog-header>
    <div cdkDragHandle>
      Remove Hold
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content class="w-100 pb-3" id="reasonContent">
    <div class="container-fluid">
      <div class="row">
        <label class="col-lg-2" uitkLabel>Reason</label>
        <div class="col-lg-10">
          <uitk-dropdown id="holdReasonDD" [items]="holdReasonPickList" name="select" [(ngModel)]="holdReason">
          </uitk-dropdown>
        </div>
      </div>
      <div class="row mt-4">
        <label class="col-lg-2" uitkLabel>Comment</label>
        <div class="col-lg-10">
          <uitk-form-field>
            <textarea uitkTextarea name="notesTextArea" id="comment-text-area" [(ngModel)]="noteText" uitkInput> </textarea>
          </uitk-form-field>
        </div>
      </div>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" [disabled]="!holdReason?.value || (holdReason?.value === 'EXR' && !noteText)"
      (click)="removeHold()">
      Ok
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="dialog.show = false">
      Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>