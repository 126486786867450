<div class="container-fluid bg-light px-0 pt-2">
  <div class="row" style="min-height: 25px; z-index: 1;">
    <div class="col-12">
      <button type="button" class="btn btn-primary border-0" (click)="collapse.toggle();" onclick="this.blur();"
              style="background-color: #002677; color: white !important; font-weight: bold; border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;">
        Additional Actions
      </button>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <div [(ngbCollapse)]="isButtonPanelCollapsed" #collapse="ngbCollapse">
        <div class="card card-body p-0">
          <div class="container-fluid px-0 m-0">
            <div class="row" style="min-height: 50px;">
              <div class="col-12 d-flex">
                <button id="NotesButton" type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                        (click)="notesDialog.show = true" *appShowResource="'OrderInquiryAdditionalActionsNotesButton'">
                  Notes
                </button>

                <button id="packingSlipButton" type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                        (click)="packingSlipNotesDialog.show = true"
                        *appShowResource="'OrderInquiryAdditionalActionsPackingSlipNotesButton'">
                  Packing Slip Notes
                </button>

                <button id="orderAttachmentsButton" type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                (click)="showAttachmentsDialog.show = true">
                  Order Attachments
                </button>

                <button type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                        (click)="sendEmailDialog.show = true" *appShowResource="'OrderInquiryAdditionalActionsSendEmailButton'">
                  Send
                  Email
                </button>

                <button id="InvoicesButton" type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                        (click)="navigateToInvoices(orderService.orderDto)"
                        *appShowResource="'OrderInquiryAdditionalActionsInvoiceButton'"
                        [disabled]="modeService.mode !== Mode.View">
                  Invoices
                </button>

                <button id="returnBtn" type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                        (click)="returnsDialog.show = true" [hidden]="!showReturnsButton"
                        *appShowResource="'OrderInquiryAdditionalActionsReturnsButton'"
                        [disabled]="modeService.mode !== Mode.View">
                  Returns
                </button>

                <button id="addNewReturn" type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                        (click)="navigateReturnEntry()" [hidden]="!showReturnEntryButton" *appShowResource="'OrderInquiryAdditionalActionsReturnsButton'"
                        [disabled]="modeService.mode !== Mode.View || disableAddNewReturn || orderService.orderDto.pendingDate !== null" appTabTitle="Return Entry">
                  Add New Return
                </button>

                <button id="amaKeyButton" type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                        (click)="amaKeyDialog.show = true" [disabled]="disableAmaKeyButton">
                  AMA Key
                </button>

                <button id="VoidOrderButton" type="button"
                        class="uitk-c-button uitk-c-button--alternative-ghost m-2 flex-grow-1"
                        (click)="showVoidOrderDialog()"
                        *appShowResource="'OrderInquiryAdditionalActionsVoidOrderButton'"
                        [disabled]="orderService.orderDto.orderStatus === 'C' || orderService.orderDto.orderStatus === 'V' || orderService.orderDto.pendingDate !== null">
                  Void
                  Order
                </button>
              </div>
            </div>
            <div class="row" style="min-height: 50px;">
              <div class="col-12 d-flex">
                <button id="GeneratePackingSlipButton" type="button"
                  class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1" [disabled]="modeService.mode !== Mode.View"
                  *appShowResource="'OrderInquiryAdditionalActionsGeneratePackingSlipButton'" (click)="generatePackingSlipDialog.show = true">
                  Generate Packing Slip
                </button>
                <button id="ResendOrderButton" type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                        [disabled]="modeService.mode !== Mode.View || orderService.orderDto.pendingDate" (click)="resendOrder()"
                        *appShowResource="'OrderInquiryAdditionalActionsResendOrderButton'"
                        [ngbTooltip]="sendOrderLog" container="body" placement="bottom">
                  Resend Order
                </button>               
                <ng-template #sendOrderLog><div style="color: white !important; font-size: 14pt">{{ orderService.orderDto.sendLog ? orderService.orderDto.sendLog : 'Order has not been sent.' }}</div></ng-template>
                <button id="ClearInUseButton" type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                        [disabled]="modeService.mode !== Mode.View || !orderService.orderDto.inUseBy" (click)="clearInUse()"
                        *appShowResource="'OrderInquiryAdditionalActionsClearInUseButton'">
                  Clear In Use
                </button>
                <button id="ClearPPButton" type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                        [disabled]="modeService.mode !== Mode.View || !orderService.orderDto.pendingDate" (click)="clearPending()"
                        *appShowResource="'OrderInquiryAdditionalActionsClearP&PButton'">
                  Clear P&P
                </button>
                <button id="ShipandProcessButton" type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                  [disabled]="modeService.mode !== Mode.View || !orderService.orderDto.isShipAndProcess || 
                    orderService.orderDto.orderStatus === 'H' || orderService.orderDto.orderType === 'Q' || orderService.orderDto.orderStatus === 'D' || orderService.orderDto.orderStatus === 'P' || orderService.orderDto.inUseBy || orderService.orderDto.orderType === 'E' ||
                    orderService.orderDto.pendingDate" (click)="shipAndProcessDialog.show = true"
                  *appShowResource="'OrderInquiryAdditionalActionsShip&ProcessButton'">
                  Ship & Process
                </button>
                <button id="convert-exception-button" type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                  [disabled]="modeService.mode !== Mode.View || orderService.orderDto.orderType !== 'E' || orderService.orderDto.orderStatus === 'V' || orderService.orderDto.pendingDate" (click)="convertException()"
                  *appShowResource="'OrderInquiryAdditionalActionsConvertExceptionButton'" [hidden]="!configService.orderEntryFeature">
                  Convert Exception
                </button>
                <button id="remove-hold-button" type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                        [disabled]="modeService.mode !== Mode.View || orderService.orderDto.orderStatus !== 'H' || orderService.orderDto.pendingDate" (click)="removeHoldDialog.show = true"
                        *appShowResource="'OrderInquiryAdditionalActionsRemoveHoldButton'">
                  Remove Hold 
                </button>

                
              </div>
              

            </div>
            <div class="row" style="min-height: 50px;" 
              *ngIf="(orderService.orderDto.orderStatus === 'Q' && orderService.orderDto.orderType === '') 
              || ((orderService.orderDto.orderStatus === ' ' || orderService.orderDto.orderStatus === 'H' )
              && (orderService.orderDto.orderType === ' ' || orderService.orderDto.orderType === 'E' ))">
                <div class="col-2 d-flex">
                  <button id="revert-to-quote-button" type="button" class="uitk-c-button uitk-c-button--ghost m-2 flex-grow-1"
                  [disabled]="modeService.mode !== Mode.View || orderService.orderDto.shippedAmount !== 0 
                  || orderService.orderDto.pendingDate !== null"
 
                           (click)="revertToQuote()">
                          Revert to Quote
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Dialogs -->

<div *ngIf="removeHoldDialog.show">
  <app-remove-hold [dialog]="removeHoldDialog"></app-remove-hold>
</div>

<div *ngIf="notesDialog.show">
  <app-order-notes [dialog]="notesDialog"></app-order-notes>
</div>

<div *ngIf="packingSlipNotesDialog.show">
  <app-packing-slip-notes [dialog]="packingSlipNotesDialog"></app-packing-slip-notes>
</div>

<div *ngIf="showAttachmentsDialog.show">
  <app-order-attachments [dialog]="showAttachmentsDialog"></app-order-attachments>
</div>

<div *ngIf="sendEmailDialog.show">
  <app-send-email [dialog]="sendEmailDialog"></app-send-email>
</div>

<div *ngIf="returnsDialog.show">
  <app-returns-action [dialog]="returnsDialog" [fromReturnEntry]="false"></app-returns-action>
</div>

<div *ngIf="amaKeyDialog.show">
  <app-amakey [dialog]="amaKeyDialog"></app-amakey>
</div>

<div *ngIf="voidOrderDialog.show">
  <app-void-order [dialog]="voidOrderDialog"></app-void-order>
</div>

<div *ngIf="shipAndProcessDialog.show">
  <app-ship-and-process [dialog]="shipAndProcessDialog"></app-ship-and-process>
</div>

<div *ngIf="generatePackingSlipDialog.show">
  <app-generate-packing-slip [dialog]="generatePackingSlipDialog"></app-generate-packing-slip>
</div>

<div id="divQuoteCFDPdf1" style="display: none;">
  <app-pro-forma-invoice></app-pro-forma-invoice>
</div>
