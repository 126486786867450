import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IUITKRadioGroupItem } from '@uitk/angular';
import { IUITKSelectItemProps } from '@uitk/angular/forms/select/select-item.interface';
import { Mode } from 'src/app/enums/mode.enum';
import { Dialog } from 'src/app/helpers/dialog';
import { ModeService } from 'src/app/services/mode.service';
import { AdditionalReturnInfoDto, InvoiceCreditMethodDto, InvoiceCreditMethod_DetailsDto } from '../../../dtos/additional-return-info-dto-model';
import { ARSLabelDto } from '../../../dtos/ars-label-dto.model';
import { InvoicesListDto } from '../../../dtos/invoices-list-dto.model';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { AccountingService } from '../../../services/accounting.service';
import { ARSLabelService } from '../../../services/ars-label.service';
import { ConfigService } from '../../../services/config.service';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';
import { SwalAlert } from '../../../helpers/alert';
import { OrderApprovalInputDto } from 'src/app/dtos/order-approval-input-dto.model';
import { SaveOrderExceptionDto } from 'src/app/dtos/save-order-exception-dto.model';
import { ApprovalService } from '../../../services/approval.service';
import { OrderDto } from '../../../dtos/order-dto.model';

@Component({
  selector: 'app-add-new-return',
  templateUrl: './add-new-return.component.html',
  styleUrls: ['./add-new-return.component.css'],
  encapsulation: ViewEncapsulation.None
})
  
export class AddNewReturnComponent implements OnInit {
  @Input('dialog') dialog!: Dialog<string>;
  Mode = Mode;

  constructor(
    private readonly accountingService: AccountingService,
    public readonly orderService: OrderService,
    private readonly pricingService: PricingService,
    private readonly arsLabelService: ARSLabelService,
    private readonly configService: ConfigService,
    public readonly modeService: ModeService,
    private readonly approvalService: ApprovalService
  ) {
  }

  defaultLabel = 'Please Select';
  applyCreditToInvoice='Apply credit to invoice';
  applyCreditToNewOrder='Apply credit to new order';
  applyToAnotherInvoice='Apply to another invoice';
  leaveAsCreditOnAccount='Leave as credit on account';
  noCreditDue='No credit due';
  refundCheck='Refund Check';
  defaultLabelFlag = true;
  todayDate = Date.now();
  creditMethodZeroBalanceList: IUITKSelectItemProps[] = [];
  creditMethodList: IUITKSelectItemProps[] = [
    { id: '1', label: this.applyCreditToInvoice, value: this.applyCreditToInvoice},
    { id: '2', label: this.applyCreditToNewOrder, value: this.applyCreditToNewOrder },
    { id: '3', label: this.applyToAnotherInvoice, value: this.applyToAnotherInvoice },
    { id: '4', label: this.leaveAsCreditOnAccount, value: this.leaveAsCreditOnAccount },
    { id: '5', label: this.noCreditDue, value: this.noCreditDue },
    { id: '6', label: this.refundCheck, value: this.refundCheck },
  ];
  ccCreditMethodList: IUITKSelectItemProps[] = [
    { id: '1', label: this.applyCreditToInvoice, value: this.applyCreditToInvoice },
    { id: '2', label: this.applyCreditToNewOrder, value: this.applyCreditToNewOrder },
    { id: '3', label: this.applyToAnotherInvoice, value: this.applyToAnotherInvoice },
    { id: '4', label: this.leaveAsCreditOnAccount, value: this.leaveAsCreditOnAccount },
    { id: '5', label: this.noCreditDue, value: this.noCreditDue },
    { id: '7', label: 'Refund Credit card', value: 'Refund credit card' }
  ];
  expandableTableHeader = [
    { name: 'Item', id: 'itemNumber' },
    { name: 'Return Amt', id: 'return' },
    { name: 'Quantity', id: 'quantity' },
  ];
  tableHeader = [
    { name: 'Invoice #', id: 'invoiceId' },
    { name: 'Invoice Amt', id: 'invoiceAmount' },
    { name: 'Balance', id: 'totalBalance' },
    { name: 'Credit Method', id: 'ccTypes' },
  ];
  radioItems: IUITKRadioGroupItem[] = [
    { label: "Yes", value: "Yes", },
    { label: "No", value: "No", },
  ];

  invoicesList: InvoicesListDto[] = [];
  showReturnsPopUp = false;
  radioReturnSelectedProducts: any;
  showCreditcard = false;
  showCCError = false;
  showCCErrorProductReturnSelection = false;
  arsLabelDialog = { show: true };
  emailPattern = /^^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](\s?)(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
  displayName = '';
  swalAlert = new SwalAlert();
  allElectronic = false;
  saveOrderExceptionDto = new SaveOrderExceptionDto();

  ngOnInit(): void {
    this.showReturnsPopUp = false;  
    if (this.orderService.orderDto.additionalReturnInformation === null || this.orderService.orderDto.additionalReturnInformation === undefined) {
      this.orderService.orderDto.additionalReturnInformation = new AdditionalReturnInfoDto();
    } 
    this.allElectronic = this.orderService.orderDto.orderDetailDtos.filter(x=>x.isSelected).every(a => a.productDto.isElectronic);
    if(this.allElectronic){
      this.radioReturnSelectedProducts = this.radioItems[1];
      this.orderService.orderDto.additionalReturnInformation.note.type = "A";
    }
    else{
      this.radioReturnSelectedProducts = (this.orderService.orderDto.returnCode === 'PC' || this.orderService.orderDto.returnCode === 'EPC')? this.radioItems[1] : this.radioItems[0];
      this.orderService.orderDto.additionalReturnInformation.note.type = "R";
    }
    const currentUser = this.configService.getMyMSId();
    this.orderService.orderDto.additionalReturnInformation.note.userId = currentUser;
    this.displayName=this.orderService.orderDto.enteredBy ?? "";
    this.getInvoiceDetails();
    if(this.orderService.orderDto.paymentMethod !== null)
    {
      if(['D','M','V','X'].includes(this.orderService.orderDto.paymentMethod))
      {
          this.showCreditcard = true;
      }
      else
      {
        this.showCreditcard = false;
      }
    }
    else
    {
      this.showCreditcard= false;
    }
  }

  async getInvoiceDetails(): Promise<void> {
    const invoices = await this.accountingService.getInvoices(this.orderService.orderDto.originalOrderId==null?this.orderService.orderDto.id:this.orderService.orderDto.originalOrderId)
    if (!invoices) {
      return;
    }
    let invoicesListTemp = [...invoices];
    for (let invoice of invoicesListTemp) {
      let lineItem = this.orderService.orderDto.orderDetailDtos.find(item => item.invoiceNumber === invoice.invoiceNumber && item.isSelected === true);
      if (lineItem) {
        if(invoice.balance === 0 && !this.showCreditcard)
        {
          invoice.ccCreditMethodList=JSON.parse(JSON.stringify(this.creditMethodList)) as IUITKSelectItemProps[];
          invoice.ccCreditMethodList[0].disabled=true;
        }
        else if(invoice.balance === 0 && this.showCreditcard)
        {
          invoice.ccCreditMethodList=JSON.parse(JSON.stringify(this.ccCreditMethodList)) as IUITKSelectItemProps[];
          invoice.ccCreditMethodList[0].disabled=true;
        }
        else if(this.showCreditcard)
        {
          invoice.ccCreditMethodList=JSON.parse(JSON.stringify(this.ccCreditMethodList)) as IUITKSelectItemProps[];
        }
        else
        {
          invoice.ccCreditMethodList=JSON.parse(JSON.stringify(this.creditMethodList)) as IUITKSelectItemProps[];
        }
        this.invoicesList.push(invoice);
        this.invoicesList = [...this.invoicesList];
      }
    }
  }

  getLineItem(invoice:any){
    return this.orderService.orderDto.orderDetailDtos.find(item => item.invoiceNumber === invoice.invoiceNumber && item.isSelected === true);
  }
  closeAddNewReturnDialog() {
    this.dialog.close('Cancel');
  }

  validateForm(): void {
    if(this.invoicesList.some(element => !element.creditMethod?.value))
    {
      this.showCCError = true;
    }
    else
    {
      this.showCCError = false;
    }

    if(!this.radioReturnSelectedProducts)
    {
      this.showCCErrorProductReturnSelection = true;
    }
    else
    {
      this.showCCErrorProductReturnSelection = false;
    }
  }

  updateNotes() {
    this.orderService.orderDto.additionalReturnInformation.note.additionalNotes = "";
    this.invoicesList.forEach(invoice => {
      if (invoice.creditMethod) {
        if(invoice.creditMethod.label === 'No credit due'){
          this.orderService.orderDto.additionalReturnInformation.note.additionalNotes += `${invoice.creditMethod.label} (Original invoice ${invoice.invoiceNumber}). `;
        }
        else{
          this.orderService.orderDto.additionalReturnInformation.note.additionalNotes += `${invoice.creditMethod.label} (Original invoice ${invoice.invoiceNumber} for $${invoice.invoiceAmount .toFixed(2)}). `;
        }
      }
    });
  }
 
  async save(): Promise<void> {
    this.validateForm();

    if (this.showCCError) {
      return;
    }

    if (this.arsLabelDialog.show) {
      if (!this.validateARSLabelDetails()) {
        return;
      }
    }

    this.updateOrderDto();
    let orderDto = this.orderService.orderDto;
    orderDto.orderType = orderDto.additionalReturnInformation.isSelectedProducts ? 'R' : 'A';
    orderDto.orderStatus = ' ';
    orderDto.holdReasonCode = null;
    orderDto.warehouseId = 1;
    orderDto.voidDate = null;
    orderDto.voidReasonCode = null;
    let openAmount: number = 0;
    const orderDetailDtos: OrderDetailDto[] = [];

    this.updateOrderDetail(orderDto, openAmount, orderDetailDtos);

    orderDto.orderDetailDtos = [...orderDetailDtos];
    orderDto.openAmount = this.pricingService.round(openAmount, 2);
    this.orderService.orderDto.arsLabel = this.arsLabelService.selectedARSYesNo!.label === 'Yes' ? 'Pending' : null;
    
    this.orderService.orderDto.arsLabel = this.arsLabelService.selectedARSYesNo!.label === 'Yes' ? 'ARS Email'  : null;
    
    // create approval
    orderDto.orderApprovalDetailsDto = new OrderApprovalInputDto;
    orderDto.orderApprovalDetailsDto.approvalRequired = "Yes";
    
    if (this.orderService.returnAuthReason != "")
     this.orderService.orderDto.orderStatus = 'P'; // set status to pending for auth required return
    
    await this.orderService.saveOrderToDatabase(orderDto);
    this.saveARSLabelDetails();

    if (this.orderService.returnAuthReason != "")
    {
      await this.orderService.saveReturnAuthorization(this.orderService.orderDto, this.orderService.returnDetails, this.orderService.returnAuthReason); 
      
      this.saveOrderExceptionDto.orderId = this.orderService.orderDto.id;
      this.saveOrderExceptionDto.comments = this.orderService.returnAuthReason;
      if (this.saveOrderExceptionDto.orderId !== 0) {
        this.approvalService.saveOrderException(this.saveOrderExceptionDto).subscribe(() => {
        }, error => {
          console.log(error);
          alert('An error occurred while saving Approval information. Please try again.');
        });
      }
    }

    this.dialog.close('Save');
  }

  private updateOrderDto() {
    this.invoicesList.forEach(invoiceItem => {
      let objInvoiceCreditMethod = new InvoiceCreditMethodDto();
      objInvoiceCreditMethod.invoiceNumber = parseInt(invoiceItem.invoiceNumber);
      objInvoiceCreditMethod.totalBalance = invoiceItem.balance;
      objInvoiceCreditMethod.invoiceAmount = invoiceItem.invoiceAmount;
      objInvoiceCreditMethod.creditMethod = invoiceItem.creditMethod?.value ?? '';
      invoiceItem.invoiceDetails.forEach(invoiceDetailItem => {
        let objDetail = new InvoiceCreditMethod_DetailsDto();
        objDetail.balance = invoiceDetailItem.invoiceQuantity;
        objDetail.item = invoiceDetailItem.item;
        objDetail.return = invoiceDetailItem.extendedPrice;
        objInvoiceCreditMethod.invoiceCreditMethodDetails.push(objDetail);
      });
      this.orderService.orderDto.additionalReturnInformation.invoiceCreditMethods.push(objInvoiceCreditMethod);
    });
    this.orderService.orderDto.additionalReturnInformation.isSelectedProducts = this.radioReturnSelectedProducts.value === "Yes" ? true : false;
  }

  updateOrderDetail(orderDto:  OrderDto, openAmount: number, orderDetailDtos: OrderDetailDto[]) : void {
    orderDto.orderDetailDtos.forEach(order => {
      if (order.isSelected) {
        openAmount = openAmount + order.extendedPrice;
        orderDetailDtos.push(order);
      }
      else {
        if (order.parentOrderDetailId === null) {
          const count = orderDto.orderDetailDtos.filter(od => od.parentOrderDetailId === order.id && od.isSelected).length;

          if (count > 0) {
            order.openQuantity = 0;
            order.orderQuantity = 0;
            order.extendedPrice = 0;
            order.taxAmount = 0;
            order.taxRate = 0;
            orderDetailDtos.push(order);
          }
        }
      }
    });
  }
  onChangeReturnTypeRadio(selectedRadio: any) {
    if (selectedRadio === "No") {
      this.arsLabelDialog.show = false
    }
    else {
      this.arsLabelDialog.show = true;
    }

    this.orderService.orderDto.additionalReturnInformation.note.type = selectedRadio === "Yes" ? "R" : "A";
  }

  validateARSLabelDetails(): boolean {
    let isValidated: boolean = true;

    if (this.arsLabelService.selectedARSYesNo!.label === 'No') {
      isValidated = true;
    }
    else {      
        isValidated = this.checkEmailAddress(isValidated);    
    }

    return isValidated;
  }

  checkEmailAddress(isValidated:any){
    if (this.arsLabelService.arsEmailAddress === '') {
      isValidated = false;
      this.arsLabelService.showARSLabelErrors = true;
      this.arsLabelService.arsLabelErrors = 'Please enter email address';

      setTimeout(() => {
        this.arsLabelService.showARSLabelErrors = false;
      }, 3000);
    }
    if (this.arsLabelService.arsEmailAddress !== '' && !new RegExp(this.emailPattern).test(this.arsLabelService.arsEmailAddress)) {
      isValidated = false;
      this.arsLabelService.showARSLabelErrors = true;
      this.arsLabelService.arsLabelErrors = 'Please enter valid email address';

      setTimeout(() => {
        this.arsLabelService.showARSLabelErrors = false;
      }, 3000);
    }
    return isValidated
  }

  saveARSLabelDetails(): void {
    if (this.validateARSLabelDetails()) {

      const arsLabelDto: ARSLabelDto = new ARSLabelDto();
      arsLabelDto.orderId = this.orderService.orderDto.id;
      arsLabelDto.id = this.arsLabelService.arsLabelId;
      this.orderService.orderDto.arsLabel = null;

      if (this.arsLabelService.selectedARSYesNo!.label === 'Yes') {
          arsLabelDto.email = this.arsLabelService.arsEmailAddress;
          arsLabelDto.deliveryMethod = 'Email';

        this.arsLabelService.saveARSLabelDetails(arsLabelDto).subscribe(() => {
          this.swalAlert.alert('ARS Label details saved successfully');
        }, error => {
          console.log(error);
          this.swalAlert.alert('An error occurred while saving ars label details');
        });
      }
    }
  }
}
