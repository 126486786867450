import { Component, Input, OnInit } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { ConfigService } from '../../services/config.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-hold-reason',
  templateUrl: './hold-reason.component.html',
  styleUrls: ['./hold-reason.component.css']
})
  
export class HoldReasonComponent implements OnInit {
  @Input() dialog = { show: false };
  holdReasons: IUITKSelectItemProps[] = [];
  holdReason: IUITKSelectItemProps | null = null;

  constructor(
    private readonly configService: ConfigService,
    private readonly orderService: OrderService
  ) { }

  ngOnInit(): void {
    this.holdReasons = this.configService.getPickList('HREASON');
  }

  ok() {
    this.orderService.orderDto.orderStatus = 'H';
    this.orderService.orderDto.holdReasonCode = this.holdReason?.value ?? null;
    this.orderService.recalculateFields(this.orderService.orderDto);
    this.dialog.show = false;
  }

  cancel() {
    this.orderService.orderDto.orderStatus = ' ';
    this.orderService.recalculateFields(this.orderService.orderDto);
    this.dialog.show = false;
  }
}
