import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountCommentsDto } from 'src/app/dtos/account-comment-dto-model';
import { AccountingService } from 'src/app/services/accounting.service';
import { ConfigService } from 'src/app/services/config.service';
import { ModeService } from 'src/app/services/mode.service';
import { Mode } from '../../../../enums/mode.enum';
import { OrderService } from '../../../../services/order.service';

@Component({
  selector: 'app-packing-slip-notes',
  templateUrl: './packing-slip-notes.component.html',
  styleUrls: ['./packing-slip-notes.component.css']
})

export class PackingSlipNotesComponent implements OnInit {
  private readonly _configService: ConfigService;
  @Input() dialog = { show: false };
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();
  @Input() triggerEleId="";
  Mode = Mode;
  selectedValue: any = { label: 'Freeform', value: 'Freeform' };
  commentTypeList = [
    { label: 'Account', value: 'Account' },
    { label: 'Freeform', value: 'Freeform' },
  ];
  tableHeader = [
    'Select', 'Comments'
  ];
  commentsData: string[] = [];
  freeFormComments = '';

  myCharRemaining = 'characters remaining';
  remainingCount = 255;
  systemComment = '';

  constructor(private accountService:AccountingService,
    public orderService: OrderService,
    configService: ConfigService,
    public modeService: ModeService) {
      this._configService = configService;
  }

  ngOnInit(): void {
    this.systemComment = this.orderService.orderDto.systemComments;
    this.freeFormComments = this.orderService.orderDto.freeFormComments;
    this.accountService.getAllAccountComments(this.orderService.orderDto.billToDto.id).subscribe((comments: AccountCommentsDto[]) =>{
      let accountComments = comments.map(x => x.comments);
      this.commentsData = [...accountComments];
    }, error =>{
      this.commentsData = [];
    });

    if (this.systemComment === '') {
      this.systemComment = '';
    } else {
      this.systemComment = this.orderService.orderDto.systemComments;
    }
    if (this.freeFormComments === '') {
      this.freeFormComments = '';
    } else {
      this.freeFormComments = this.orderService.orderDto.freeFormComments;
    }

    this.orderService.triggerEditMethod();
  }

  cancelDialog() {
    this.dialog.show = false;
    this.cancel.emit()

    // If edit method was triggered automatically when opening the form in view mode, then automatically revert the packing slip note.
    if (this.orderService.editWasTriggered) {
      this.orderService.triggerRevertMethod();
    }
  }

  onCountChange(count: number) {
    this.remainingCount = count;
  }

  updateComments(accountComments: string) {
    this.freeFormComments = accountComments;
  }

  savePackingNotes() {
    this.dialog.show = false;
    if(this.orderService.orderDto.orderStatus == 'C'){
      this.orderService.isClosedOrderSaveNotes = true;
    }
    this.orderService.orderDto.systemComments = this.systemComment;
    this.orderService.orderDto.freeFormComments = this.freeFormComments;

    if(this.orderService.isClosedOrderSaveNotes){
      this.modeService.addToWaitList('saving packing slip notes');
      this.orderService.setLockOrder(this.orderService.orderDto.id, this._configService.getMyMSId(), false).subscribe(
        (data) => {
          this.modeService.removeFromWaitList('saving packing slip notes');
          this.orderService.triggerSaveMethod(true);          
      },(error) =>{
          this.modeService.removeFromWaitList('saving packing slip notes');
      }); 
    }
    else if(this.orderService.editWasTriggered){
      this.orderService.triggerSaveMethod(false);
    }
    this.save.emit();
  }
}
