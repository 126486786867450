import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataFileAccessDto } from 'src/app/dtos/data-file-access-dto.model';
import { DataFileDto, DataFileDtos } from '../../../dtos/datafile-dto.model';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { OrderNoteDto } from '../../../dtos/order-note-dto.model';
import { ConfigurationService } from '../../../services/configurations.service';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-datafile',
  templateUrl: './datafile.component.html',
  styleUrls: ['./datafile.component.css']
})

export class DatafileComponent implements OnInit {
  private readonly _configurationService: ConfigurationService;
  private readonly _pricingService: PricingService;
  @Input() orderDetailDto: OrderDetailDto = new OrderDetailDto();
  @Output() enableSave = new EventEmitter<boolean>();
  loading = false;//flag for Spinning Wheel
  visibleTextValue = '';
  tableHeader = ['Item', 'List Price', 'Quantity', 'Discount', 'Unit Price', 'Ext Price'];
  addOnsTableHeader = [' ', 'Item', 'Description', 'List Price', 'Quantity', 'Discount', 'Unit Price', 'Ext Price'];
  isProductShipped: boolean = this.orderDetailDto.shipDate != null ? true : false;
  voidItemDialog = { show: false };
  selectedOrderDetail: OrderDetailDto = new OrderDetailDto();
  orderDetailDtoBackup :OrderDetailDto[]=[];
  orderNotesDtoBackUp : OrderNoteDto[]=[];
  swalAlert = new SwalAlert();

  constructor(
    configurationService: ConfigurationService,
    pricingService: PricingService,
    public orderService: OrderService) {
      this._configurationService = configurationService;
      this._pricingService = pricingService;
  }

  ngOnInit(): void {
    this.orderDetailDtoBackup = JSON.parse(JSON.stringify(this.orderService.orderDto.orderDetailDtos)) as OrderDetailDto[];
    this.orderNotesDtoBackUp = JSON.parse(JSON.stringify(this.orderService.orderDto.orderNotes)) as OrderNoteDto[];
    this.getDataFileRecords();
  }

  getDataFileRecords() {
    this.visibleTextValue = 'Loading Records';
    this.loading = true;
    this.orderDetailDto.dataFileDtos = new DataFileDtos();
    this._configurationService.getDataFileAddons(this.orderDetailDto.productId)
      .subscribe(data => {
        this.orderDetailDto.dataFileDtos.dataFileLicenseDtos = data.filter(x => x.isLicense === true);
        this.orderDetailDto.dataFileDtos.dataFileNonLicenseDtos = data.filter(x => x.isLicense === false);
        this.setDefaults();
        this.updateAddonIfAlreadyInCart();
      });

    this.loading = false;
  }

  private setDefaults() {
    this.orderDetailDto.dataFileDtos.dataFileLicenseDtos.forEach(element => {
      element.orderQuantity = 0;
      element.discount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, element.addonProductId);
      element.unitPrice = element.listPrice;
      element.extendedPrice =0;
      this._pricingService.calculateUnitPrice(element);
      this._pricingService.calculateExtendedPrice(element);
    });
    this.orderDetailDto.dataFileDtos.dataFileNonLicenseDtos.forEach(element => {
      element.orderQuantity = 1;
      element.discount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, element.addonProductId);
      element.extendedPrice = element.unitPrice = element.listPrice;
      this._pricingService.calculateUnitPrice(element);
      this._pricingService.calculateExtendedPrice(element);
    });
  }
  updateAddonIfAlreadyInCart() {
    if (!this.isProductShipped) {
      const existingAddOnsInCart = this.orderService.orderDto.orderDetailDtos.filter(x => (x.productCode.startsWith("A0") || x.productCode.startsWith("A4")) && (x.orderDetailStatus === 'O' || x.orderDetailStatus === ' ') && x.parentOrderDetailId === this.orderDetailDto.id);
      existingAddOnsInCart.forEach(cartAddOn => {
        const matchingAddOn = this.orderDetailDto.dataFileDtos?.dataFileNonLicenseDtos.filter(x => x.productCode === cartAddOn.productCode)[0];
        matchingAddOn.isSelected = true;
        matchingAddOn.orderQuantity = cartAddOn.orderQuantity;
        matchingAddOn.discount = cartAddOn.discount;
        matchingAddOn.extendedPrice = cartAddOn.extendedPrice;
        matchingAddOn.unitPrice = cartAddOn.unitPrice;
        matchingAddOn.listPrice = cartAddOn.listPrice;
      });

      const existingLicenseAddOnsInCart = this.orderService.orderDto.orderDetailDtos.filter(x => (!x.productCode.startsWith("A0") && !x.productCode.startsWith("A4")) && (x.orderDetailStatus === 'O' || x.orderDetailStatus === ' ') && x.parentOrderDetailId === this.orderDetailDto.id);
      existingLicenseAddOnsInCart.forEach(cartAddOn => {
        const matchingLicenseAddOn = this.orderDetailDto.dataFileDtos?.dataFileLicenseDtos.filter(x => x.productCode === cartAddOn.productCode)[0];
        matchingLicenseAddOn.orderQuantity = cartAddOn.orderQuantity;
        matchingLicenseAddOn.discount = cartAddOn.discount;
        matchingLicenseAddOn.extendedPrice = cartAddOn.extendedPrice;
        matchingLicenseAddOn.unitPrice = cartAddOn.unitPrice;
        matchingLicenseAddOn.listPrice=cartAddOn.listPrice;
      });


    }
  }

  save(): boolean {
    this.saveLicenseRecords(this.orderDetailDto.dataFileDtos.dataFileLicenseDtos);
    this.saveNonLicenseRecords(this.orderDetailDto.dataFileDtos.dataFileNonLicenseDtos);
    this.getDataFileContacts();
    return true;
  }

  getDataFileContacts() {
    if (this.orderService.selectedSubscriptionOrderId > 0) {
      this.orderService.getDataAccessContactsAsync(this.orderService.selectedSubscriptionOrderId).subscribe(
        (data: DataFileAccessDto[]) => {
           this.orderService.orderDto.dataFileAccessDtos = data;
        }
      );
    }
  }


  private saveLicenseRecords(dataFile: DataFileDto[]): void {
    dataFile.forEach(element => {
      const recordCheck = this.orderService.orderDto.orderDetailDtos
      .filter(x => x.productCode === element.productCode && x.shipDate === null && x.orderDetailStatus !== "V" && x.orderDetailStatus !== "C")[0];
      this.checkAndPushRecord(recordCheck,element);
      if (recordCheck && element.orderQuantity <= 0) {
        if(recordCheck.id < 0)
        {
          this.removeRecordFromOrderDetail(element.productCode);
        }
        else  if(recordCheck.id > 0)
        {
          recordCheck.lastOrderDetailStatus= recordCheck.orderDetailStatus;
          recordCheck.orderDetailStatus = 'V';
          if (this.orderService.orderDto.orderStatus === 'D' || this.orderService.orderDto.orderType === 'Q')
          {
            recordCheck.voidReasonCode = 'D';
          }
          recordCheck.voidedQuantity = (recordCheck.voidedQuantity ? recordCheck.voidedQuantity  : 0) + recordCheck.openQuantity;
          recordCheck.openQuantity = 0;
          recordCheck.taxAmount = 0;
          recordCheck.taxRate = 0;
        }
      }
      if (recordCheck && element.orderQuantity > 0) {
          recordCheck.openQuantity = parseInt(element.orderQuantity.toString())
          recordCheck.discount = element.discount;
          recordCheck.unitPrice = element.unitPrice;
          recordCheck.extendedPrice = element.extendedPrice;
          recordCheck.listPrice = element.listPrice;
      }
    });
  }

  private checkAndPushRecord(recordCheck:any,element:DataFileDto){
    if (!recordCheck && element.orderQuantity > 0) {
      this.pushRecord(element);
    }
  }

  private saveNonLicenseRecords(dataFile: DataFileDto[]): void {
    dataFile.forEach(element => {
      const recordCheck = this.orderService.orderDto.orderDetailDtos
      .filter(x => x.productCode === element.productCode && x.shipDate === null && x.orderDetailStatus !== "V" && x.orderDetailStatus !== "C")[0];
      if (element.isSelected ) {
        if (!recordCheck && element.orderQuantity > 0) {//Item does not exist in Order Inquiry grid
          this.pushRecord(element);
        }
        else if (recordCheck) {//update record
          recordCheck.openQuantity = parseInt(element.orderQuantity.toString())
          recordCheck.discount = element.discount;
          recordCheck.unitPrice = element.unitPrice;
          recordCheck.extendedPrice = element.extendedPrice;
          recordCheck.listPrice = element.listPrice;
          recordCheck.voidedQuantity = element.voidedQuantity ? element.voidedQuantity  : 0;
        }

      }
      else if (element.isSelected === false && recordCheck && recordCheck.id > 0) {
       this.updateRecordWhenElementNotSelected(recordCheck);
      }
      else if (element.isSelected === false && recordCheck && recordCheck.id < 0) {
        this.removeRecordFromOrderDetail(element.productCode);
      }
    });
  }

  private updateRecordWhenElementNotSelected(recordCheck:OrderDetailDto){
    recordCheck.lastOrderDetailStatus= recordCheck.orderDetailStatus;
    recordCheck.orderDetailStatus = 'V';
    if (this.orderService.orderDto.orderStatus === 'D' || this.orderService.orderDto.orderType === 'Q')
    {
      recordCheck.voidReasonCode = 'D';
    }
    recordCheck.voidedQuantity = (recordCheck.voidedQuantity ? recordCheck.voidedQuantity  : 0) + recordCheck.openQuantity;
    recordCheck.openQuantity = 0;
    recordCheck.taxAmount = 0;
    recordCheck.taxRate = 0;
  }

  private pushRecord(element: DataFileDto) {
    const newOrderDetailDto: OrderDetailDto = this.orderService.createNewOrderDetail(this.orderService.orderDto, element.productCode);
    newOrderDetailDto.openQuantity = parseInt(element.orderQuantity.toString());
    newOrderDetailDto.discount = element.discount;
    newOrderDetailDto.unitPrice = element.unitPrice;
    newOrderDetailDto.extendedPrice = element.extendedPrice;
    newOrderDetailDto.listPrice = element.listPrice;
    newOrderDetailDto.parentOrderDetailId = this.orderDetailDto.id;
    newOrderDetailDto.requiredDate = this.orderDetailDto.requiredDate;
    newOrderDetailDto.orderDetailStatus = ' ';
    newOrderDetailDto.enteredDate = this.orderDetailDto.enteredDate;
    const noOfaddOns = this.orderService.orderDto.orderDetailDtos.filter(item => item.parentOrderDetailId === this.orderDetailDto.id).length;
    const index = this.orderService.orderDto.orderDetailDtos.findIndex(item => item === this.orderDetailDto);
    this.orderService.orderDto.orderDetailDtos.splice(index + 1 + noOfaddOns, 0, newOrderDetailDto);
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
  }

  private removeRecordFromOrderDetail(productCode: string) {
    const index = this.orderService.orderDto.orderDetailDtos.findIndex(x => x.productCode === productCode);
    this.orderService.orderDto.orderDetailDtos.splice(index, 1);
    this.orderService.orderDto.orderDetailDtos=[...this.orderService.orderDto.orderDetailDtos];
  }


  onChangeRecordSelction(isSelected: boolean, dataFileDto: DataFileDto) {
    if (!isSelected) {
      dataFileDto.orderQuantity = 1;
      dataFileDto.discount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, dataFileDto.addonProductId);
      dataFileDto.extendedPrice = dataFileDto.unitPrice = dataFileDto.listPrice;
      this._pricingService.calculateUnitPrice(dataFileDto);
      this._pricingService.calculateExtendedPrice(dataFileDto);
      const unshippedDataFileAddon = this.orderService.orderDto.orderDetailDtos
      .filter(x => x.productCode === dataFileDto.productCode && x.shipDate === null && x.orderDetailStatus !== "V" && x.orderDetailStatus !== "C" &&
                            x.parentOrderDetailId === this.orderDetailDto.id)[0];
        if(unshippedDataFileAddon && this.orderService.orderDto.orderStatus !== 'D' && this.orderService.orderDto.orderType !== 'Q')
        {
          this.selectedOrderDetail = unshippedDataFileAddon;
          this.voidItemDialog.show = true;
        }
    }
  }

  onVoidLineItemDialogCancel()
  {
    const unshippedAddon = this.orderService.orderDto.orderDetailDtos
    .filter(x => x.productCode === this.selectedOrderDetail.productCode && x.shipDate === null && x.orderDetailStatus !== "V" && x.orderDetailStatus !== "C" &&
    x.parentOrderDetailId === this.orderDetailDto.id)[0];
    if(unshippedAddon && unshippedAddon.orderDetailStatus === ' ')
    {
      const datafile =this.orderDetailDto.dataFileDtos.dataFileLicenseDtos.filter(x=>x.productCode === this.selectedOrderDetail.productCode)[0];
      if(datafile)
      {
        datafile.orderQuantity=this.selectedOrderDetail.orderQuantity;
      }
      else
      {
        this.orderDetailDto.dataFileDtos.dataFileNonLicenseDtos.filter(x=>x.productCode === this.selectedOrderDetail.productCode)[0].isSelected=true;
      }
    }
  }

  onVoidLineItemCancel()
  {
    this.orderService.orderDto.orderDetailDtos=this.orderDetailDtoBackup;
    this.orderService.orderDto.orderNotes = this.orderNotesDtoBackUp;
  }

  formatOrderQuantity(dataFileDto: DataFileDto) {
    const regex = new RegExp("^[0-9]\\d*$");
    if (!dataFileDto.orderQuantity.toString()?.match(regex)) {
      dataFileDto.orderQuantity = Math.round(dataFileDto.orderQuantity);
    }
  }

  checkAddonQuantity(dataFileDto: DataFileDto) {
    const totalMainItems = this.orderDetailDto.dataFileDtos.dataFileLicenseDtos.reduce((sum, item) => sum + item.orderQuantity, 0) + 1;
    if (dataFileDto.orderQuantity > totalMainItems) {
      this.swalAlert.alert("The addon quantity cannot exceed the order quantity of the main item.");
      dataFileDto.orderQuantity = Math.round(totalMainItems);
    }
  }

  onChangeOrderQuantity(e: any, dataFileDto: DataFileDto,isAddon=false) {
    this.formatOrderQuantity(dataFileDto);

    if(dataFileDto.orderQuantity === 0 && isAddon)
    {
      dataFileDto.orderQuantity =1;
    }

    // The user changed the license quantity.
    if (!isAddon) {      
     this.updateLicenseQuantity(dataFileDto);   
    }

    // The user changed an addon quantity.
    if(dataFileDto.isMaxParentQuantity && isAddon)
    {     
      this.checkAddonQuantity(dataFileDto);
    }
    const calculateVoidQuantity = this.orderService.orderDto.orderDetailDtos
    .filter(x => x.productCode === dataFileDto.productCode );


    if(calculateVoidQuantity.length > 0){
      if(calculateVoidQuantity[0].openQuantity > dataFileDto.orderQuantity){
        dataFileDto.voidedQuantity = calculateVoidQuantity[0].voidedQuantity + (calculateVoidQuantity[0].openQuantity - dataFileDto.orderQuantity)
      }
      else {
        dataFileDto.voidedQuantity = calculateVoidQuantity[0].voidedQuantity;
      }
    }

    dataFileDto.listPrice = this._pricingService.calculateListPricing(this.orderService.orderDto, dataFileDto) || dataFileDto.listPrice;
    this._pricingService.calculateUnitPrice(dataFileDto);
    this._pricingService.calculateExtendedPrice(dataFileDto);

    if ( dataFileDto.orderQuantity <= 0  && !isAddon ) {
      const unshippedDataFileAddon = this.orderService.orderDto.orderDetailDtos
      .filter(x => x.productCode === dataFileDto.productCode && x.shipDate === null && x.orderDetailStatus !== "V" && x.orderDetailStatus !== "C" &&
      x.parentOrderDetailId === this.orderDetailDto.id)[0];
      this.selectedOrderDetail = unshippedDataFileAddon;
      this.voidItemDialog.show = true;
    }
  }

  updateLicenseQuantity(dataFileDto:DataFileDto){
    const totalMainItems = dataFileDto.orderQuantity + 1;
    for (const addon of this.orderDetailDto.dataFileDtos.dataFileNonLicenseDtos) {
      if(addon.orderQuantity > totalMainItems && addon.isSelected && addon.isMaxParentQuantity) {
        addon.orderQuantity = Math.round(totalMainItems);
      }
    }  
  }

  onChangeDiscount(e: any, dataFileDto: DataFileDto) {
    dataFileDto.discount= this._pricingService.calculateMaxDiscount(dataFileDto.discount,dataFileDto.addonProductId)
    this._pricingService.calculateUnitPrice(dataFileDto);
    this._pricingService.calculateExtendedPrice(dataFileDto);
  }

  onChangeUnitPrice(e: any, dataFileDto: DataFileDto) {
    if (dataFileDto.allowCustomStandard) {
      dataFileDto.listPrice = dataFileDto.unitPrice;
    }
    else {
      dataFileDto.discount = this._pricingService.round((100 - dataFileDto.unitPrice / dataFileDto.listPrice * 100), 10);
    }
    this._pricingService.calculateUnitPrice(dataFileDto);
    this._pricingService.calculateExtendedPrice(dataFileDto);
  }

  checkValue(dataFileDto: DataFileDto): void {
    if (typeof(dataFileDto.orderQuantity) !== 'number') {
      const stringValue: string = dataFileDto.orderQuantity;
      dataFileDto.orderQuantity = +(stringValue.replace(/\D/g,''));
    }
  }

  onChangeListPrice(dataFileDto: DataFileDto): void {
    this._pricingService.calculateUnitPrice(dataFileDto);
    this._pricingService.calculateExtendedPrice(dataFileDto);
  }
}