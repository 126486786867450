<div id="subConfigSet">
    <label>
        Subscription Type:
    </label>
    <form [formGroup]="profileForm">
        <uitk-form-field>
            <uitk-fieldset>
                <uitk-radio-group uitkId="my-basic-radio-group" formControlName="radioGroup" [itemList]="items" [(ngModel)]="formatType" (change)="onChangeFormatType()"> 
                </uitk-radio-group>
            </uitk-fieldset>
        </uitk-form-field>
    </form>
    <label id="newSub" *ngIf="hasSubscriptionsToRenew">
      Renew Existing Subscription
    </label>
    <div class="message--error" *ngIf="showError && this.orderDetail.shipDate === null">
      Subscription expired more than 6 months ago. It can no longer be renewed.
    </div>
    <br />
    <div class="uitk-c-table--responsive">
      <table uitkTable [dataSource]="renewalSubscriptions" class="uitk-c-table" id="subscriptionsTable">
        <thead class="uitk-c-table__head">
          <tr>
            <th scope="col"></th>
            <th *ngFor="let header of tableHeaders" scope="col" id="{{header.id}}">
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <tbody id="row-data" class="uitk-c-table__body">
          <ng-container *uitkTableRowBody="let record">
            <tr class="uitk-c-table__row">
              <td>
                <input id="subGrdRadio" type="radio" class="uitk-c-radio" name="selectRowRadioGroup"
                       (change)="onSelectionChange(record.id)" [checked]="record.isSelected" [disabled]="record.renewalSubscriptionId"
                       (click)="selectedSubscription(record)" />
              </td>
              <td>{{ record.subscriptionNumber }}</td>
              <td>{{ record.startDate | date: 'MM/dd/yyyy' }}</td>
              <td>{{ record.term }}</td>
              <td>{{ record.expireDate | date: 'MM/dd/yyyy' }}</td>
              <td>{{ record.subscriptionStatus }}</td>
              <td>{{ record.subscriptionQuantity | number : '1.2-2' }}</td>
              <td>{{ record.subscriptionType }}</td>
              <td><a style="color: #3271e1; cursor: pointer;" (click)="navigateToSubscriptionSearch(record.subscriptionOrderId)">{{ record.subscriptionOrderId }}</a></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <br />
    </div>
    <uitk-form-field-error *ngIf="(this.orderDetail.shipDate === null && !isSubscriptionAllowbackdate && isSubscriptionTypeNT && !isRenewal && ((startMonth > 12 || startMonth < 1 || (startMonth < currentMonth && startYear == currentYear)) || (startYear < currentYear))) ">
      New Subscriptions cannot begin in the past
    </uitk-form-field-error>
    <uitk-form-field-error *ngIf="this.orderDetail.shipDate === null && !isSubscriptionTypeNT && isRenewal && isInvalidStartDate ||(this.orderDetail.shipDate === null && !isSubscriptionTypeNT && isInvalidStartDate) ">
      Start Date cannot be older that 6 months
    </uitk-form-field-error>
    <uitk-form-field-error *ngIf="this.orderDetail.shipDate === null && startYear > currentYear+ 10">
      Start Year should be within next 10 year
    </uitk-form-field-error>
    <uitk-form-field-error *ngIf="this.orderDetail.shipDate === null && formatType?.label === 'Trial' && (termPeriod > 2 || termPeriod < 1)">
      Trial term period should be 1 or 2 months
    </uitk-form-field-error>
    <uitk-form-field-error *ngIf="termPeriod > 18 && !hasSubscriptionTermNoLimit">
      Term period should not be more than 18 months
    </uitk-form-field-error>
    <uitk-form-field-error *ngIf="termPeriod < 1">
      Term period should not be less than 1 month
    </uitk-form-field-error>
    <uitk-form-field-error *ngIf="startMonth > 12">
      Start month should not be more than 12 months
    </uitk-form-field-error>
    <div class="uitk-l-grid__row">
      <label uitkLabel class="padding" id="startMonthlbl">Start Month&nbsp;&nbsp;</label>
      <div class="uitk-l-grid__col">
        <uitk-form-field>
          <input uitkInput id="startMonth" class="textbox term" [(customModel)]="startMonth" (change)="validateStartDate()"
           [disabled]="!isSubscriptionTypeNT"
           appIdFormat #startMonthInput />
        </uitk-form-field>
      </div>

      <label uitkLabel class="padding" id="startYearlbl">Start Year&nbsp;&nbsp; </label>
      <div class="uitk-l-grid__col ">
        <uitk-form-field>
          <input uitkInput id="startYear" class="textbox term" [(customModel)]="startYear" (change)="validateStartDate()"
          appIdFormat [disabled]="!isSubscriptionTypeNT" />
        </uitk-form-field>
      </div>

      <label uitkLabel class="padding" id="termPeriodlbl">Term Period&nbsp;&nbsp; </label>
      <div class="uitk-l-grid__col">
        <uitk-form-field>
          <input uitkInput id="termPeriod" class="textbox term" [(customModel)]="termPeriod" (change)="validateStartDate()"
          appIdFormat  #termPeriodInput (change)="termPeriodChanged()"/>
        </uitk-form-field>
      </div>
    </div>

    <hr>
    <div class="uitk-l-grid__row">
      <div class="uitk-l-grid__col">
        <uitk-form-field>
          <input uitkCheckbox id="renewalNotices" type="checkbox" [(ngModel)]="sendRenewalNotices" />
          <label uitkCheckboxLabel id="renewalNoticesLabel" for="renewalNotices">Send Renewal Notices</label>
        </uitk-form-field>

      </div>
      <div class="uitk-l-grid__col">
        <uitk-form-field>
          <input uitkCheckbox id="updatesOnly" type="checkbox" [(ngModel)]="updatesOnly"
                 [disabled]="this.orderDetail.shipDate != null || (this.orderDetail.subscriptionDto?.subscriptionType=== 'R')" />
          <label uitkCheckboxLabel id="updatesOnlyLabel" for="updatesOnly">Send Updates Only</label>
        </uitk-form-field>

      </div>
      <div class="uitk-l-grid__col">
        <uitk-form-field>
          <input uitkCheckbox id="emailUpdates" type="checkbox" [(ngModel)]="emailUpdates" />
          <label uitkCheckboxLabel id="emailUpdatesLabel" for="emailUpdates">Email Updates</label>
        </uitk-form-field>

      </div>
    </div>
    <hr>
  </div>

  <div *ngIf="showSubscriptionDialog.show">
    <app-subscription-trial-pop-up [SubscriptionDialog] = "showSubscriptionDialog"></app-subscription-trial-pop-up>
  </div>