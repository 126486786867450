export class SubscriptionSearchResult {
  subscriptionId = 0;
  accountId = 0;
  subscriptionNumber = 0;
  productCode: string | null = null;
  productDescription: string | null = null;
  quantity: number | null = 0;
  startDate = new Date();
  term = 0;
  expirationDate: Date | null = null;
  subscriptionStatus: string | null = null;
  subscriptionType: string | null = null;
  salesOrderNumber: number | null = 0;
  contactId: string | null = null;
  updateOnly: boolean | null = false;
  paymentPlan: string | null = null;
  subscriptionStatusSort = 0;
  hasRenewal = false;
  salesforceId:string | null = null;
}
