import { Component, OnInit } from '@angular/core';
import { CfdFormFieldsDto } from '../../../../dtos/cfd-form-field-selection-dto.model';
import { OrderService } from '../../../../services/order.service';

@Component({
  selector: 'app-cfd-form-field-selection-section',
  templateUrl: './cfd-form-field-selection-section.component.html',
  styleUrls: ['./cfd-form-field-selection-section.component.css']
})

export class CfdFormFieldSelectionSectionComponent implements OnInit {
  cfdFormFieldsDto: CfdFormFieldsDto = new CfdFormFieldsDto();
  showRenewalDateCheckBox = false;

  constructor(public orderService: OrderService) {
    this.orderService.cfdFormFields = this.cfdFormFieldsDto;
    this.showRenewalDateCheckBox = this.orderService.orderDto.orderDetailDtos.some( x => x.subscriptionDto != null);
  }
  ngOnInit(): void {
    this.orderService.validUpto = 30;
  }

  onValidUptoChange(){
    if(this.orderService.validUpto > 180){
      this.orderService.validUpto = 180;
    }
  }
}
