<div style="height:600px;">
  <!-- <h4>{{ orderDetail.productCode }}: {{ orderDetail.productDescription }}</h4> -->
  <div class="uitk-l-grid accountName" *ngIf="!organizationAdded">
    <div class="uitk-l-grid__row">
      <div class="uitk-l-grid__col">
        <label uitkLabel>{{ orgInfoText }}</label>
        <uitk-form-field>
          <uitk-dropdown style="width: 450px" [disabled]="orderDetail.shipDate!=null" (onChange)="onChangeOrganizationCode($event)"
            uitkId="organizationInfo" [items]="organizationInfoList" [(ngModel)]="organizationInfo" [enableSelectFlag]="true">
          </uitk-dropdown>
        </uitk-form-field>
        <div *ngIf="resellerSelected" id="orgInfoTextError" class="ng-tns-c54-2 uitk-c-notification__container uitk-c-notification__container--error ng-trigger ng-trigger-fadeInOut ng-star-inserted">
          <div class="uitk-c-notification__content-container ng-tns-c54-2">
            <div class="uitk-c-notification__icon ng-tns-c54-2">
              <span class="ng-tns-c54-1 uitk-icon uitk-icon-info_filled ng-star-inserted"></span>
              <!---->
              <!---->
            </div>
            <div class="uitk-c-notification__content ng-tns-c54-2" id="warningDemoId_content">
              <div>
                <span class="ng-tns-c54-2"> Reselect to whom you are reselling to.</span>
              </div>
            </div>
          </div>
          <!---->
        </div>
        <div *ngIf="payerSelected" id="orgInfoTextError" class="ng-tns-c54-2 uitk-c-notification__container uitk-c-notification__container--error ng-trigger ng-trigger-fadeInOut ng-star-inserted">
          <div class="uitk-c-notification__content-container ng-tns-c54-2">
            <div class="uitk-c-notification__icon ng-tns-c54-2">
              <span class="ng-tns-c54-1 uitk-icon uitk-icon-info_filled ng-star-inserted"></span>
              <!---->
              <!---->
            </div>
            <div class="uitk-c-notification__content ng-tns-c54-2" id="warningDemoId_content">
              <div>
                <span class="ng-tns-c54-2"> FeeAnalyzer products cannot be sold to payers.</span>
              </div>
            </div>
          </div>
          <!---->
        </div>
      </div>
    </div>
  </div>

  <div class="uitk-l-grid accountName" *ngIf="hasnonReseller || organizationAdded">
    <div class="uitk-l-grid__row ">
      <div class="uitk-l-grid__col">
        <label uitkLabel>{{ companyNameText }}</label>
        <uitk-form-field>
          <input style="width: 450px" [disabled]="orderDetail.shipDate!=null" id="txtCompanyName" uitkInput name="myBasicInput" [(ngModel)]="companyName"
          />
        </uitk-form-field>
      </div>
    </div>
  </div>

  <div class="uitk-l-grid geoZip" *ngIf="hasnonReseller || organizationAdded">
    <div class="uitk-l-grid__row">
      <div class="uitk-l-grid__col">
        <br/>
        <hr>
        <label uitkLabel style="font-size: 15px;"> {{ geoZips }}</label>
      </div>
    </div>
    <br/>

    <div class="uitk-l-grid__row" id="zipCodeRow">
      <label uitkLabel id="geoZipsCol">{{ speciality }}</label>
      <div class="uitk-l-grid__col--6">

        <div *ngIf="hasWrongSpeciality" id="gioZipError" class="ng-tns-c54-2 uitk-c-notification__container uitk-c-notification__container--error ng-trigger ng-trigger-fadeInOut ng-star-inserted">
          <div class="uitk-c-notification__content-container ng-tns-c54-2">
            <div class="uitk-c-notification__icon ng-tns-c54-2">
              <span class="ng-tns-c54-1 uitk-icon uitk-icon-info_filled ng-star-inserted"></span>
              <!---->
              <!---->
            </div>
            <div class="uitk-c-notification__content ng-tns-c54-2" id="warningDemoId_content">
              <div>
                <span class="ng-tns-c54-2"> Please select a speciality</span>
              </div>
            </div>
          </div>
          <!---->
        </div>
        <uitk-form-field>
          <uitk-dropdown id="speciality" [items]="specialityDescriptionList" #singleSelect="ngModel" uitkId="singleSelect-template"
            [disabled]="orderDetail.shipDate!=null" [enableSelectFlag]="true" (onChange)="onChangeSpecialityVal($event)" [(ngModel)]="selectedSpecialityInDropDown">
          </uitk-dropdown>
        </uitk-form-field>
      </div>
      <div style="width:30px"></div>
      <label uitkLabel id="geoZipsCol"> {{ zip }}</label>
      <div class="uitk-l-grid__col--2">


        <div *ngIf="hasWrongZipVal" id="gioZipError" class="ng-tns-c54-2 uitk-c-notification__container uitk-c-notification__container--error ng-trigger ng-trigger-fadeInOut ng-star-inserted">
          <div class="uitk-c-notification__content-container ng-tns-c54-2">
            <div class="uitk-c-notification__icon ng-tns-c54-2">
              <span class="ng-tns-c54-1 uitk-icon uitk-icon-info_filled ng-star-inserted"></span>
              <!---->
              <!---->
            </div>
            <div class="uitk-c-notification__content ng-tns-c54-2" id="warningDemoId_content">
              <div>
                <span class="ng-tns-c54-2"> required 3 digit or 5 digit zipcode</span>
              </div>
            </div>
          </div>
          <!---->
        </div>
        <uitk-form-field>
          <input uitkInput id="zipCode" [disabled]="orderDetail.shipDate!=null" name="myBasicInput" [(ngModel)]="zipCode" />
        </uitk-form-field>
      </div>
    </div>

    <div class="uitk-l-grid__row">
      <button id="add" type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" [disabled]="zipCodeAdded || orderDetail.shipDate!=null"
        (click)="clickadd()">
        Add
      </button>
    </div>
  </div>

  <div class="uitk-l-grid__row" *ngIf="hasnonReseller || organizationAdded">

    <div class="uitk-l-grid__col" style="max-width: 1100px;">

      <div class="uitk-c-table--responsive">
        <table uitkTable class="uitk-c-table" [dataSource]="geoZipGridData" id="geoZip-table">
          <thead class="uitk-c-table__head">
            <tr style="text-align: left">
              <th *ngFor="let headerName of tableHeader" scope="col">
                {{ headerName.name }}
              </th>
            </tr>
          </thead>
          <tbody id="row-data" class="uitk-c-table__body">
            <ng-container *uitkTableRowBody="let record">
              <tr *ngIf="record" class="uitk-c-table__row">
                <td> <span class="uitk-icon uitk-icon-trash_delete gridIcons" *ngIf="orderDetail.shipDate===null" (click)="onDeleteClick(record)"></span></td>
                <td>{{record.speciality}}</td>
                <td>{{record.zipCode}}</td>
                <td>{{record.geoZip}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>