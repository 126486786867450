<div class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="contactDialogModal.show"
             uitkId="contactDialog"
             [closeBtn]=false (onModalHidden)="closeModal()">
  <uitk-dialog-content *ngIf="subscriptionRenewalProductExists === true && orderService.sourceType === SourceType.ShipToContactChange; else statusCodeCheck">
    <p>{{ subscriptionRenewalPopupText }}</p>
  </uitk-dialog-content>
  <ng-template #statusCodeCheck>
    <uitk-dialog-content *ngIf="(statusCode === 'MGD' || statusCode === 'MRG') && orderService.sourceType !== SourceType.OrderByAccountChange">
      <p>{{ mrgAndMgdStatusPopupText }}</p>
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="statusCode === 'DEL' && this.useDelAccountResource && orderService.sourceType !== SourceType.OrderByAccountChange">
      <p>{{ deleteStatusPopupTextForSAAndOMRole }}</p>
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="statusCode === 'DEL' && !this.useDelAccountResource && orderService.sourceType !== SourceType.OrderByAccountChange">
      <p>{{ deleteStatusPopupTextForNonSAAndOMRole }}</p>
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="statusCode === 'BP' && this.useBPAccountResource && orderService.sourceType !== SourceType.OrderByAccountChange">
      <p>{{ badPhoneStatusPopupTextForSAAndOMRole }}</p>
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="statusCode === 'BP' && !this.useBPAccountResource && orderService.sourceType !== SourceType.OrderByAccountChange">
      <p>{{ badPhoneStatusPopupTextForNonSAAndOMRole }}</p>
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="statusCode === 'BAD' && this.useBadAccountResource && orderService.sourceType !== SourceType.OrderByAccountChange">
      <p>{{ badAddressStatusPopupTextForSAAndOMRole }}</p>
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="statusCode === 'BAD' && !this.useBadAccountResource && orderService.sourceType !== SourceType.OrderByAccountChange">
      <p>{{ badAddressStatusPopupTextForNonSAAndOMRole }}</p>
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="(this.orderService.orderDto.billToDto.statusCode === 'MGD' || this.orderService.orderDto.billToDto.statusCode === 'MRG') && orderService.sourceType === SourceType.OrderByAccountChange">
      <p>{{ mrgAndMgdStatusPopupText1 }}</p>
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="this.orderService.orderDto.billToDto.statusCode === 'DEL' && this.useDelAccountResource && orderService.sourceType === SourceType.OrderByAccountChange">
      <p>{{ deleteStatusPopupTextForSAAndOMRole1 }}</p>
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="this.orderService.orderDto.billToDto.statusCode === 'DEL' && !this.useDelAccountResource && orderService.sourceType === SourceType.OrderByAccountChange">
      <p>{{ deleteStatusPopupTextForNonSAAndOMRole1 }}</p>
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="this.orderService.orderDto.billToDto.statusCode === 'BP' && this.useBPAccountResource && orderService.sourceType === SourceType.OrderByAccountChange">
      <p>{{ badPhoneStatusPopupTextForSAAndOMRole1 }}</p>
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="this.orderService.orderDto.billToDto.statusCode === 'BP' && !this.useBPAccountResource && orderService.sourceType === SourceType.OrderByAccountChange">
      <p>{{ badPhoneStatusPopupTextForNonSAAndOMRole1 }}</p>
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="this.orderService.orderDto.billToDto.statusCode === 'BAD' && this.useBadAccountResource && orderService.sourceType === SourceType.OrderByAccountChange">
      <p>{{ badAddressStatusPopupTextForSAAndOMRole1 }}</p>
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="this.orderService.orderDto.billToDto.statusCode === 'BAD' && !this.useBadAccountResource && orderService.sourceType === SourceType.OrderByAccountChange">
      <p>{{ badAddressStatusPopupTextForNonSAAndOMRole1 }}</p>
    </uitk-dialog-content>
    </ng-template>
    <uitk-dialog-actions>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="ok()" appAutoFocus>
        {{ myDefaultButtonText }}
      </button>
      <button *ngIf="statusCode !== 'MGD' && statusCode !== 'MRG'" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="cancel()">
        {{ myGhostButtonText }}
      </button>
    </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>
