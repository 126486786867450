export class AdvancedContactSearchInputDto {
    contactId = 0;
    firstName = '';
    lastName = '';
    address = '';
    city = '';
    zipCode = '';
    email = '';
    phone = '';
    accountId = '';
}