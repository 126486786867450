<div class="container-fluid" style="padding-left: 1px;">
  <uitk-notification uitkId="arsLabelValidations" [variant]="'error'" [show]="arsLabelService.showARSLabelErrors">
    <span>{{ arsLabelService.arsLabelErrors }}</span>
  </uitk-notification>
  <div class="row mt-4">
    <label uitkLabel class="col-lg-12">Would you like to send an ARS label?</label>
  </div>
  <div class="row mt-1">
    <uitk-form-field>
      <uitk-fieldset>
        <uitk-radio-group uitkId="rdGrpARSYesNo" id="rdGrpARSYesNo" name="rdGrpARSYesNo"
          [(ngModel)]="arsLabelService.selectedARSYesNo" [itemList]="arsYesNoItems"
          (ngModelChange)="onChangeOfYesOrNoOption(arsLabelService.selectedARSYesNo?.value)">
        </uitk-radio-group>
      </uitk-fieldset>
    </uitk-form-field>
  </div>
  <div *ngIf="arsLabelService.showARSEmailAddressSection">
    <div class="row mt-1 mb-1">
      <label uitkLabel class="col-lg-6">ARS Email Address<span style="color: #002677;">*</span></label>
      <div class="col-lg-6">
        <uitk-form-field>
          <input uitkInput id="arsEmailAddress" name="arsEmailAddress" class="textbox" type="text" value=""
            [(ngModel)]="arsLabelService.arsEmailAddress" />
        </uitk-form-field>
      </div>
    </div>
  </div>
</div>
