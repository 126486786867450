<div class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkId="requiredContactEmailAddressDialog" [(showModal)]="dialog.show"
  [closeBtn]="false" [triggerElementId]="triggerEleToFocus" (onModalHidden)="onModalClosed($event)">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Contact Email
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div class="row mt-3">
      <span>Please verify or request Ship To Contact's email address:</span>
      <div class="col-12 d-flex">
        <span *ngIf="productType === 'Electronic'">
          These items require that the Ship To Contact has a valid email: {{ electronicProductCodesOnly }}
        </span>
        <span *ngIf="productType === 'E-Alert'">
          These items require that the Ship To Contact has a valid email: {{ eAlertProductCodesOnly }}
        </span>
        <span *ngIf="productType === 'Both Electronic and E-Alert'">
          These items require that the Ship To Contact has a valid email: {{ electronicProductCodesOnly + ', '+ eAlertProductCodesOnly }}
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-3 d-flex">
        <label class="label" uitkLabel>Contact Id:</label>
      </div>
      <div class="col-3 d-flex">
        <a target="_blank" (click)="navigateToSalesForceEditContact(orderService.orderDto.shipToDto.salesforceId)"
          class="link">{{ orderService.orderDto.shipToDto.contactId }}</a>
      </div>
      <div class="col-6 d-flex">
        <span class="uitk-icon uitk-icon-refresh sm-icon" style="margin-top: 5px;">
          <a class="link" (click)="refreshContactDetailsAsync()"></a>
        </span>
        &nbsp;
        <span>
          <a class="link" (click)="refreshContactDetailsAsync()">Refresh</a>
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-3 d-flex">
        <label class="label" uitkLabel>Email Address:</label>
      </div>
      <div class="col-9 d-flex">
        {{ orderService.orderDto.shipToDto.email }}
      </div>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button id="btnOk" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="okButtonClick()" appAutoFocus>
      Ok
    </button>
    <button id="btnCancel" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="cancelButtonClick()" scrollToTarget [target]="targetEleToScroll">
      Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>