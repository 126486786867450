

import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { OrderDetailDto } from 'src/app/dtos/order-detail-dto.model';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SubscriptionDto } from 'src/app/dtos/subscription-dto.model';
import { ProductDto } from 'src/app/dtos/product-dto.model';
import { OrderService } from 'src/app/services/order.service';
import { ConfigService } from 'src/app/services/config.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { PricingService } from 'src/app/services/pricing.service';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class SubscriptionComponent implements OnInit {
  private readonly _subscriptionService: SubscriptionService;
  private readonly _configService: ConfigService;
  private readonly _authorizationService: AuthorizationService;
  private readonly _pricingService: PricingService;
  @ViewChild('startMonthInput') startMonthInput: ElementRef | undefined;
  @ViewChild('termPeriodInput') termPeriodInput: ElementRef | undefined;
  @Input() orderDetail: OrderDetailDto = new OrderDetailDto();
  @Output() enableSave = new EventEmitter<boolean>();
  @Input() productDto: ProductDto = new ProductDto();
  @Input() tempSubscriptionDto: SubscriptionDto = new SubscriptionDto();
  @Output() isTrialChecked: EventEmitter<boolean> = new EventEmitter();
  showError = false
  subscriptionError = false;
  renewalSubscriptions: SubscriptionDto[] = [];
  termPeriod = 12;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  startMonth = new Date().getMonth() + 1;
  startYear = new Date().getFullYear();
  subscriptionNumber = 0;
  subscriptionOrderId = 0;
  isNewSubscription = this.orderDetail.subscriptionDto?.renewalSubscriptionId === null || this.orderDetail.subscriptionDto?.renewalSubscriptionId === undefined;
  enableNewSubscription = this.orderDetail.subscriptionDto?.renewalSubscriptionId === null || this.orderDetail.subscriptionDto?.renewalSubscriptionId === undefined;
  isTrialSubscription = false;
  tempSubscriptionType:string | undefined ='';
  sendRenewalNotices = true;
  emailUpdates = true;
  updatesOnly = false;
  allowTrial = this.orderDetail.productDto.allowTrial;
  trial = false;
  isSync = true;
  isSubscriptionSavedToDb = false;
  hasSubscriptionsToRenew = false;
  hasSubscriptionTermNoLimit = false;
  expireDate: Date | number = new Date();
  startDate: Date | number | string = new Date();
  renewalSubscriptionId: number | null = null;
  temptermPeriod = 0;
  isInvalidStartDate = false;
  disableStartYear = false;
  subscriptionRBChecked = false;
  isSubscriptionAllowbackdate = false;
  tableHeaders = [
    { name: 'Sub #', id: 'subscriptionNumber' },
    { name: 'Start Date', id: 'startDate' },
    { name: 'Term', id: 'term' },
    { name: 'Expire Date', id: 'expireDate' },
    { name: 'Sub Status', id: 'subStatus' },
    { name: 'Qty', id: 'quantity' },
    { name: 'Sub Type', id: 'subscriptionType' },
    { name: 'Order #', id: 'orderNumber' }
  ];

  constructor(
    subscriptionService: SubscriptionService,
    public orderService: OrderService,
    configService: ConfigService,
    authorizationService: AuthorizationService, private readonly pricingService: PricingService) {
    this._subscriptionService = subscriptionService;
    this._configService = configService;
    this._authorizationService = authorizationService;
    this._pricingService = pricingService;
  }

  ngOnInit(): void {
    this.getSubscriptionsForRenewal();
    this.defaultSubscriptionValues();
    if (this._authorizationService.hasResource('SubscriptionTermNoLimit')) {
        this.hasSubscriptionTermNoLimit=true;
    }

    if(this._authorizationService.hasResource('SubscriptionsAllowBackDateEntry')){
      this.isSubscriptionAllowbackdate = true;
    }
  }

  navigateToSubscriptionSearch(orderId: number): void {
    localStorage.setItem('subscriptionOrderId', orderId.toString());
      window.open(`${this._configService.spaUrl}subscriptions`);
  }
  
  defaultSubscriptionValues() {
    this.subscriptionNumber = 0;
    this.renewalSubscriptions.forEach(renewalSub => {
      renewalSub.isSelected = renewalSub.id === this.orderDetail.subscriptionDto?.renewalSubscriptionId ? true : false;
    });
    this.setSubscriptionSaveDBFlag();
    this.isNewSubscription = this.orderDetail.subscriptionDto?.subscriptionType==='N' || !this.isSubscriptionSavedToDb;
    this.isTrialSubscription = this.orderDetail.subscriptionDto?.subscriptionType==='T';
    this.tempSubscriptionType = this.orderDetail.subscriptionDto?.subscriptionType;
    this.allowTrial = this.orderDetail.productDto.allowTrial;
    if (this.orderDetail.subscriptionDto)
    {
      this.trial = this.orderDetail.subscriptionDto.term < 3 && this.orderDetail.subscriptionDto.term > 0;
    }
    else
    {
      this.trial = false;
    }
    if (this.orderDetail.subscriptionDto === null) {
      this.startMonth = new Date().getMonth() + 1;
      this.startYear = new Date().getFullYear();
      this.termPeriod = this.orderDetail.productDto.defaultSubscriptionTerm;
      this.sendRenewalNotices = (this.orderService.orderDto.billToDto.defaultSendRenewalNotices === true
        && this.orderService.orderDto.shipToDto.defaultSendRenewalNotices === true) ? true : false;
      this.updatesOnly = false;
      this.emailUpdates = this.orderService.orderDto.shipToDto.allowInformationalEmails&&this.orderService.orderDto.billToDto.defaultSendUpdates;
      this.isSync = false;
    }
    else {
      this.startMonth = new Date(this.orderDetail.subscriptionDto?.startDate).getMonth() + 1;
      this.startYear = new Date(this.orderDetail.subscriptionDto?.startDate).getFullYear();
      this.termPeriod = this.orderDetail.subscriptionDto.term;
      this.temptermPeriod = this.orderDetail.subscriptionDto.term;
      this.sendRenewalNotices = this.orderDetail.subscriptionDto.sendRenewalNotices;
      this.updatesOnly = this.orderDetail.subscriptionDto.subscriptionType=== 'R'? true :this.orderDetail.subscriptionDto.sendUpdatesOnly;
      this.emailUpdates = this.orderDetail.subscriptionDto.emailUpdates;

    }
    this.validateStartDate();
  }

  setSubscriptionSaveDBFlag(){
    if (this.orderDetail.orderId > 0)
    {
      this.isSubscriptionSavedToDb = true;
    }
    else
    {
      this.isSubscriptionSavedToDb = false;
    }
  }

  save(): boolean {

    if (!this.validateRenewalOnSave()) {
      return false;
    }

    this._subscriptionService.subscriptionIsSavedTemporary = true;
    this.startDate = (this.startMonth) + "/01/" + (this.startYear);
    this.tempSubscriptionDto.id = this.orderDetail.id;
    this.tempSubscriptionDto.productId = this.orderDetail.productId;
    this.tempSubscriptionDto.productCode = this.orderDetail.productCode;
    this.tempSubscriptionDto.startDate = new Date(this.startYear, this.startMonth - 1, 1);
    this.tempSubscriptionDto.term = Number(this.termPeriod);

    this.tempSubscriptionDto.sendUpdatesOnly = this.updatesOnly;
    this.tempSubscriptionDto.sendRenewalNotices = this.sendRenewalNotices;
    this.tempSubscriptionDto.sendUpdates = true;
    this.tempSubscriptionDto.emailUpdates = this.emailUpdates;
    this.tempSubscriptionDto.renewalSubscriptionId = this.renewalSubscriptionId;

    const wapNumber =  this._configService.getPickList('WAP').filter(item => item.value === this.orderDetail.productDto.onlineType)[0]?.label;
    if (wapNumber) {
      this.tempSubscriptionDto.wapNumber = wapNumber;
    }
    this.renewalSubscriptions.forEach(renewal => {
      if(renewal.isSelected)
      {
       this.orderService.selectedSubscriptionOrderId= renewal.subscriptionOrderId;
       this.subscriptionRBChecked = true;
      }
     });

    if (this.orderDetail.subscriptionDto === null) {
      this.tempSubscriptionDto.contactId = this.orderService.orderDto.shipToDto.contactId;
      this.tempSubscriptionDto.subscriptionQuantity = this.orderDetail.orderQuantity * (this.productDto.subscriptionMultiplier || 1);
      this.tempSubscriptionDto.timesRenewed = 0;
      this.tempSubscriptionDto.subscriptionStatus = 'F';
      this.tempSubscriptionDto.subscriptionType = this.enableNewSubscription || !this.subscriptionRBChecked ? 'N' : 'R';
      this.tempSubscriptionDto.subscriptionNumber = this.enableNewSubscription ? 0 : this.subscriptionNumber;

      this.tempSubscriptionDto.trialOrderHeaderId = null;
    } else {
      this.tempSubscriptionDto.contactId = this.orderDetail.subscriptionDto.contactId;
      this.tempSubscriptionDto.subscriptionNumber = this.orderDetail.subscriptionDto.subscriptionNumber;
      this.tempSubscriptionDto.subscriptionQuantity = this.orderDetail.subscriptionDto.subscriptionQuantity;
      this.tempSubscriptionDto.timesRenewed = this.orderDetail.subscriptionDto.timesRenewed;
      this.tempSubscriptionDto.subscriptionStatus = this.orderDetail.subscriptionDto.subscriptionStatus;
      this.tempSubscriptionDto.subscriptionType = this.orderDetail.subscriptionDto.subscriptionType;
      this.tempSubscriptionDto.trialOrderHeaderId = this.orderDetail.subscriptionDto.trialOrderHeaderId;
    }
    

    this.subscriptionDiscountBR();

    this.orderDetail.subscriptionDto =this.tempSubscriptionDto;
    this.orderDetail.requiredDate = this.tempSubscriptionDto.startDate;
    this.orderService.orderDto.orderDetailDtos.filter(o => o.parentOrderDetailId === this.orderDetail.id && o.orderDetailStatus === ' ')
    .forEach(item => {
      item.requiredDate = this.orderDetail.requiredDate;
    });
    return true;
  }

  setNewSubscription(value: boolean) {
    this.showError = false;
    this.enableNewSubscription = value;
  }

  trialCheckbox() {
    if (this.trial)
    {
      this.termPeriod = 2;
    }
    else
    {
      if (this.orderDetail.promotionEntryCode !== 'ILP' && this.orderDetail.promotionEntryCode !== 'ILT' &&
        this.orderDetail.promotionEntryCode !== 'OLT' && this.orderDetail.promotionEntryCode !== 'OLP') {
        this.orderDetail.discount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, this.orderDetail.productDto.id);
      }
      
      this.orderDetail.subscriptionDto = null;
      this.termPeriod =this.orderDetail.productDto.defaultSubscriptionTerm;
    }
    this.isTrialChecked.emit(this.trial);
  }

  syncSubscription() {
    if (this.isSync === true && this.renewalSubscriptionId) {
      const result = new Date(this.expireDate);
      this.startDate = result.setMonth(result.getMonth() + 1);
      this.startMonth = new Date(this.startDate).getMonth() + 1;
      this.startYear = new Date(this.startDate).getFullYear();
    }
    else {
      if (this.orderDetail.subscriptionDto) {
        this.startMonth = new Date(this.orderDetail.subscriptionDto.startDate).getMonth() + 1;
        this.startYear = new Date(this.orderDetail.subscriptionDto.startDate).getFullYear();
      }
    }
  }

  selectedSubscription(record: SubscriptionDto) {
    let result = new Date(record.startDate);
    this.expireDate = result.setMonth(result.getMonth() + (record.term - 1));
    this.renewalSubscriptionId = record.id;
    this.subscriptionOrderId = record.subscriptionOrderId;
    result = new Date(this.expireDate);
    this.startDate = result.setMonth(result.getMonth() + 1);
    this.startMonth = new Date(this.startDate).getMonth() + 1;
    this.startYear = new Date(this.startDate).getFullYear();
    this.subscriptionNumber = record.subscriptionNumber;

    if(this.orderDetail.subscriptionDto?.subscriptionType === 'T')
    {
      this.trial = false;
      this.orderDetail.subscriptionDto=null;
      this.termPeriod =this.orderDetail.productDto.defaultSubscriptionTerm;
    }
    this.validateStartDate();
  }

  getSubscriptionsForRenewal() {

    const orderId = this.orderService.orderDto.id;
    const contactId = this.orderService.orderDto.shipToDto.contactId;
    const productId = this.orderDetail.productId;
    const orderIndex = this.orderService.orderDto.multiYearDtos.findIndex(item => item.orderId === orderId);

    const lastYearOrderId = orderIndex <= 0 ? null : this.orderService.orderDto.multiYearDtos[orderIndex - 1].orderId;

    this._subscriptionService.getSubscriptionsForRenewal(contactId, productId, lastYearOrderId).subscribe(data => {

      this.renewalSubscriptions = data;
      if (this.renewalSubscriptions.length > 0) {
        this.hasSubscriptionsToRenew = true;
      }
      else {
        this.hasSubscriptionsToRenew = false;
      }
      if (!this.isSubscriptionSavedToDb && this.hasSubscriptionsToRenew)
      {
        this.enableNewSubscription = false;
      }
      else
      {
        this.enableNewSubscription = true;
      }

      this.renewalSubscriptions.forEach(element => {
        element.isSelected = element.id === this.orderDetail.subscriptionDto?.renewalSubscriptionId ? true : false;
        element["expireDate"] = new Date(new Date(new Date(element.startDate).setMonth(new Date(element.startDate).getMonth() + element.term)).getTime() - 24 * 60 * 60 * 1000);
      });
    }, () => {
      console.log('Error occurred while retrieving renewal Subscriptions Data');
    });
  }

  checkIsValidDate(){
    return ((this.startMonth > 12 || this.startMonth < 1 ||
      (this.startMonth < this.currentMonth && this.startYear === this.currentYear)) ||
      (this.startYear > this.currentYear + 10) || (this.startYear < this.currentYear) ||
      (this.trial === true && (this.termPeriod > 2 || this.termPeriod < 1)) || this.termPeriod < 1);
  }

  validateRenewalOnSave(): boolean {
    this.showError = false;
    this.subscriptionError = false;
    const isNotValidDate = this.checkIsValidDate();

    if(this.termPeriod > 18 && !this.hasSubscriptionTermNoLimit)
    {
      this.termPeriodInput?.nativeElement.focus();
      this.subscriptionError = true;
    }    
    else if ((!this.isSubscriptionAllowbackdate && this.isNewSubscription && this.enableNewSubscription && isNotValidDate ) || !this.isNewSubscription && this.isInvalidStartDate) {
      this.startMonthInput?.nativeElement.focus();
      this.subscriptionError = true;
    }

    this.renewalSubscriptions.forEach(renewalElement => {
      if (new Date(new Date(renewalElement.expireDate).setMonth(new Date(renewalElement.expireDate).getMonth() + 7)) < new Date(new Date(new Date().setDate(1)).toDateString()) &&
      renewalElement.isSelected) {
        this.showError = true;
      }
    });
    if (this.orderDetail.shipDate === null && (this.showError || this.subscriptionError)) {
      return false;
    }
    else {
      return true;
    }
  }

  onSelectionChange(id: number) {
    this.showError = false;
    this.renewalSubscriptions.forEach(renewalSubElement => {
      renewalSubElement.isSelected = false;
      if (renewalSubElement.id === id) {
        renewalSubElement.isSelected = true;
      }
    });
    this.updatesOnly =  true;
  }

  subscriptionDiscountBR(): void {
    if (this.trial) {
      this.tempSubscriptionDto.subscriptionType = 'T';
      this.orderDetail.discount = 100;
      this.orderDetail.unitPrice = 0;
      this.orderDetail.extendedPrice = 0;
    }
  }
  
  validateStartDate(): void {
    const updatedDate = new Date(new Date(new Date(new Date().setFullYear(this.startYear)).setMonth(this.startMonth + 6)).setDate(1)).toDateString();

    if (new Date(updatedDate) < new Date(new Date(new Date().setDate(1)).toDateString())) {
      this.isInvalidStartDate = true;
    }
    else {
      this.isInvalidStartDate = false;
    }

    if (this.termPeriod > 2) {
      this.trial = false;
      
      if ((this.orderDetail.promotionEntryCode !== 'ILP' && this.orderDetail.promotionEntryCode !== 'ILT' &&
        this.orderDetail.promotionEntryCode !== 'OLT' && this.orderDetail.promotionEntryCode === 'OLP') &&
        (this.tempSubscriptionType === 'T' && this.orderDetail.orderDetailStatus === ' ')) {
        this.orderDetail.discount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, this.orderDetail.productDto.id);
        this._pricingService.calculateUnitPrice(this.orderDetail);
        this._pricingService.calculateExtendedPrice(this.orderDetail);
        this.orderService.recalculateFields(this.orderService.orderDto);
      }

      if(this.tempSubscriptionType === 'T' && this.orderDetail.orderDetailStatus === ' ')
      {
        this.orderDetail.subscriptionDto=null;
      }
    }
  }
}
