<div *ngIf="dialog.show" class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog id="promoCodeDialog" [(showModal)]="dialog.show" [closeBtn]=false [triggerElementId]="orderService.shippingDialogModalTriggerEle" (onModalHidden)="closeModal()">
    <uitk-dialog-content scrollToTarget>
        Do you want to select more Ship-To Contacts to send the email notification for Web Fulfillment?
    </uitk-dialog-content>
    <uitk-dialog-actions>
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="openSelectContacts()">
            Yes
        </button>
        <button appAutoFocus class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
            (click)="closeAdditionalContactsPopup()">
            No
        </button>
    </uitk-dialog-actions>
</uitk-dialog>
    </div>
</div>
  