<div class="footer-container" [ngClass]="showContainer ? 'footer-container' : 'footer-container-collapse'">
  <div [ngClass]="showContainer ? 'top-toggle' : 'top-toggle-collapse'">
    <button type="button"
      class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs"
      style="background-color: #002677; color: white !important; font-weight: bold; border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
      (click)="showContainer=!showContainer">
      Additional Actions
    </button>
    <div class="row mt-2">
      <div class="col-12">
        <div *ngIf="showContainer" id="addtionalActionsContainer">
          <div class="container-fluid px-0 m-0">
            <div class="row" style="min-height: 50px;">
              <div class="col-12 d-flex">
                <button id="NotesButton" type="button" class="uitk-c-button uitk-c-button--ghost my-2 mx-1 flex-grow-1"
                  (click)="notesDialog.show = true">
                  Notes
                </button>
                <button id="orderAttachmentsButton" type="button"
                  class="uitk-c-button uitk-c-button--ghost my-2 mx-1 flex-grow-1"
                  (click)="showAttachmentsDialog.show = true">
                  Order Attachments
                </button>
                <button id="btn" type="button" class="uitk-c-button uitk-c-button--ghost my-2 mx-1 flex-grow-1"
                  (click)="navigateToInvoices(orderService.orderDto)"
                  appEnableResource="ReturnInquiryAdditionalActionsInvoiceButton">
                  Invoice
                </button>
                <button [hidden]="orderService.orderDto.arsLabel" id="btnCreateARSLabel" type="button"
                  class="uitk-c-button uitk-c-button--ghost my-2 mx-1 flex-grow-1" (click)="openARSLabelDialog()"
                  *appShowResource="'ReturnInquiryAdditionalActionsArsLabelButton'"
                  [disabled]="orderService.orderDto.orderStatus === 'V'">
                  Create ARS Label
                </button>
                <button [hidden]="orderService.orderDto.orderStatus !== ' ' || !orderService.orderDto.arsLabel"
                  id="btnResendARSLabel" type="button" class="uitk-c-button uitk-c-button--ghost my-2 mx-1 flex-grow-1"
                  (click)="openARSLabelDialog()" *appShowResource="'ReturnInquiryAdditionalActionsArsLabelButton'"
                  [disabled]="orderService.orderDto.orderStatus === 'V'">
                  Resend ARS Label
                </button>
                <button id="btnResendReturnLabel" type="button"
                  class="uitk-c-button uitk-c-button--ghost my-2 mx-1 flex-grow-1" (click)="resendReturn()"
                  *appShowResource="'ReturnInquiryAdditionalActionsResendReturnButton'"
                  [disabled]="modeService.mode !== Mode.View || orderService.orderDto.pendingDate">
                  Resend Return
                </button>
                <button id="btnCreateARSLabel" type="button"
                  class="uitk-c-button uitk-c-button--ghost my-2 mx-1 flex-grow-1" (click)="clearInUse()"
                  *appShowResource="'ReturnInquiryAdditionalActionsClearInUseButton'"
                  [disabled]="modeService.mode !== Mode.View || !orderService.orderDto.inUseBy">
                  Clear In Use
                </button>
                <button id="btnCreateARSLabel" type="button"
                  class="uitk-c-button uitk-c-button--ghost my-2 mx-1 flex-grow-1" (click)="clearPending()"
                  *appShowResource="'ReturnInquiryAdditionalActionsClearP&PButton'"
                  [disabled]="modeService.mode !== Mode.View || !orderService.orderDto.pendingDate">
                  Clear P&P
                </button>
                <button id="btnCreateARSLabel" type="button"
                  class="uitk-c-button uitk-c-button--ghost my-2 mx-1 flex-grow-1"
                  (click)="shipAndProcessDialog.show = true" *appShowResource="'ReturnInquiryAdditionalActionsShip'"
                  [disabled]="modeService.mode !== Mode.View || orderService.orderDto.pendingDate">
                  Ship & Process
                </button>
                <button id="VoidOrderButton"
                  [disabled]="(orderService.orderDto.orderStatus === 'C' || orderService.orderDto.orderStatus === 'V' || orderService.orderDto.pendingDate !== null)"
                  type="button" (click)="voidOrderDialog.show = true"
                  class="uitk-c-button uitk-c-button--alternative-ghost row-one voidOrderBtn my-2 mx-1 flex-grow-1"
                  *appShowResource="'ReturnInquiryAdditionalActionsVoidOrderButton'">
                  Void Return
                </button>
                <button id="btnCreateARSLabel" type="button"
                  class="uitk-c-button uitk-c-button--ghost my-2 mx-1 flex-grow-1"
                  [hidden]="!(orderService.orderDto.orderType === 'R' && (orderService.orderDto.orderStatus === ' ' || orderService.orderDto.orderStatus ==='C'))"
                  (click)="createReship()" *appShowResource="'ReturnInquiryAdditionalActionsReshipButton'">
                  Create Reship
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="additionalFotter" class="container-fluid footer">
    <div class="row">
      <div class="col-9">
        <div class="row">
          <div class="col-4 my-1">
            <div class="row">
              <label class="col-5 align-self-center" uitkLabel style="display: flex;">PO#</label>
              <div class="col-7 ps-0">
                <uitk-form-field>
                  <input uitkInput id="returnActionsPONumber" class="textbox" type="text"
                    [(ngModel)]="orderService.orderDto.poNumber" [disabled]="modeService.mode !== Mode.Edit" />
                </uitk-form-field>
              </div>
            </div>
          </div>
          <div class="col-4 my-1">
            <div class="row">
              <label class="col-5 align-self-center" uitkLabel style="display: flex;">Payment Plans</label>
              <div class="col-7 ps-0">
                <select style="background-color: #EFEFEE;color: #B1B2B3;cursor: default;box-shadow: 0 0 0 1px #d0d0ce;"
                  id="returnActionsTerms" [(ngModel)]="orderService.orderDto.paymentPlan" class="form-select"
                  aria-label="select" disabled>
                  <option value="null" selected>Select</option>
                  <option *ngFor="let list of paymentPlansList" [ngValue]="list.value">{{list.label}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-4 my-1">
          </div>
        </div>
        <div class="row">
          <div class="col-4 my-1">
            <div class="row">
              <label class="col-5 align-self-center" uitkLabel style="display: flex;">Method</label>
              <div class="col-7 ps-0">
                <select style="background-color: #EFEFEE;color: #B1B2B3;cursor: default;box-shadow: 0 0 0 1px #d0d0ce;"
                  id="returnActionsMethod" [(ngModel)]="orderService.orderDto.paymentMethod" class="form-select"
                  aria-label="select" disabled>
                  <option value="null" selected>Select</option>
                  <option *ngFor="let list of methodList" [ngValue]="list.value">{{list.label}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-4 my-1">
            <div class="row">
              <label class="col-5 align-self-center" uitkLabel style="display: flex;">Terms</label>
              <div class="col-7 ps-0">
                <select style="background-color: #EFEFEE;color: #B1B2B3;cursor: default;box-shadow: 0 0 0 1px #d0d0ce;"
                  id="returnActionsTerms" [(ngModel)]="orderService.orderDto.paymentTerms" class="form-select"
                  aria-label="select" disabled>
                  <option value="null" selected>Select</option>
                  <option *ngFor="let list of termsList" [ngValue]="list.label">{{list.label}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-4 my-1">
          </div>
        </div>
        <div class="row">
          <div class="col-4 my-1">
            <div class="row">
              <label class="col-5 align-self-center" uitkLabel style="display: flex;">Token</label>
              <div class="col-7 ps-0">
                <uitk-form-field>
                  <input uitkInput id="returnActionsCCToken" class="textbox" type="text"
                    [(ngModel)]="orderService.orderDto.ccToken" [disabled]="modeService.mode !== Mode.Edit" />
                </uitk-form-field>
              </div>
            </div>
          </div>
          <div class="col-4 my-1">
            <div class="row">
              <label class="col-5 align-self-center" uitkLabel style="display: flex;">Exp</label>
              <div class="col-7 ps-0">
                <uitk-form-field>
                  <input uitkInput id="returnActionsExp" class="textbox" type="text"
                    [(ngModel)]="orderService.orderDto.ccExpirationDate" disabled />
                </uitk-form-field>
              </div>
            </div>
          </div>
          <div class="col-4 my-1">
            <div class="row">
              <label class="col-5 align-self-center" uitkLabel style="display: flex;">CC Last 4</label>
              <div class="col-7 ps-0">
                <uitk-form-field>
                  <input uitkInput id="returnActionsCCLastFour" class="textbox" type="text"
                    [(ngModel)]="orderService.orderDto.ccLastFour" disabled />
                </uitk-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4 my-1">
            <div class="row">
              <label class="col-5 align-self-center" uitkLabel style="display: flex;">ARS Label</label>
              <div class="col-7 ps-0">
                <uitk-form-field>
                  <input uitkInput id="returnActionsArsLabel" class="textbox" type="text" [(ngModel)]="arsLabel"
                    (change)="onChangeArsLabel()"
                    [disabled]="modeService.mode !== Mode.Edit || !orderService.arsLabelInDb ||orderService.orderDto.arsLabel?.toUpperCase() === 'PENDING' " />
                </uitk-form-field>
              </div>
            </div>
          </div>
          <div class="col-4 my-1">
          </div>
          <div class="col-4 my-1">
          </div>
        </div>

      </div>
      <div class="col-3">
        <div class="row" style="border-left: 4px solid gray;">
          <div class="uitk-l-grid__row">
            <label uitkLabel style="padding-left: 13px;width: 100px;">Tax Rate</label>
            <uitk-form-field>
              <input uitkInput id="returnActionsTaxRate" class="textbox" type="text" [ngModel]="iTaxRate"
                     style="width: 100px;" disabled />
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__row" style="padding-top: 7px;">
            <label uitkLabel style="padding-left: 13px;width: 100px;">Total Tax</label>
            <uitk-form-field>
              <input uitkInput id="returnActionsTotalTax" class="textbox" type="text"
                     [ngModel]="iTotalTax| number:'1.2-2'" style="width: 100px;" disabled />
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__row" style="padding-top: 5px;">
            <label uitkLabel style="padding-left: 13px;width: 100px;">SO Total</label>
            <uitk-form-field>
              <input uitkInput id="returnActionsSOTotal" class="textbox" type="text"
                [ngModel]="orderService.orderDto.shippedAmount + orderService.orderDto.openAmount + iTotalTax | number:'1.2-2'"
                style="width: 100px;" disabled />
            </uitk-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notesDialog.show">
  <app-order-notes [dialog]="notesDialog"></app-order-notes>
</div>

<div *ngIf="voidOrderDialog.show">
  <app-void-order [dialog]="voidOrderDialog"></app-void-order>
</div>

<div *ngIf="showAttachmentsDialog.show">
  <app-order-attachments [dialog]="showAttachmentsDialog"></app-order-attachments>
</div>

<div class="col-lg-12" *ngIf="arsLabelDialog.show">
  <div *ngIf="arsLabelDialog.show" class = "overlayForPopups">
    <div cdkDrag>
  <uitk-dialog uitkId="arsLabelDialog" [(showModal)]="arsLabelDialog.show" [closeBtn]="false">
    <uitk-dialog-header>
      <div cdkDragHandle>
        ARS Label
      </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
      <app-ars-label></app-ars-label>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveARSLabel()" [disabled]="modeService.mode === Mode.Wait" #saveArsLabelBtn>
        Save
      </button>
      <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeARSLabelDialog()" [disabled]="modeService.mode === Mode.Wait">
        Cancel
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
    </div>
  </div>
</div>

<div *ngIf="shipAndProcessDialog.show">
  <app-ship-and-process [dialog]="shipAndProcessDialog"></app-ship-and-process>
</div>
