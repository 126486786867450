<div class="container-fluid px-5" *ngIf="orderService.cfdFormFields">
  <div class="px-3" style="border: 1px solid #797979;">
    <div class="row mt-3">
      <div class="col-12">
        <img class="headerLogo" src="assets/images/optum_logo_pdf.png" alt="optum logo" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 text-center">
        <span class="quoteHeader">Quote</span>
      </div>
      <div class="col-12 text-center">
        <span class="spnValue">Prices valid for {{orderService.cfdFormFields!.validUptoCheckbox ? orderService.validUpto : 30 }} days from quote date</span>
      </div>
      <div class="col-12 text-center">
        <span class="spnValue">Item availability subject to change</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 text-center">
        <span class="thisIsNotAnInvoice">This is not an invoice</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-2">
        <span class="spnHeader">Remit to:</span>
      </div>
      <div class="col-3">
        <div>
          <span class="spnValue">Optum</span>
        </div>
      </div>
      <div class="col-2"></div>
      <div class="col-2">
        <span class="spnHeader">Account #:</span>
      </div>
      <div class="col-3">
        <div>
          <span class="spnValue" [innerHTML]="orderService.orderDto.billToDto.id"></span>
          
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-3">
        <div>
          <span class="spnValue">PO Box 88050</span>
        </div>
      </div>
      <div class="col-2"></div>
      <div class="col-2">
        <span class="spnHeader">Quote #:</span>
      </div>
      <div class="col-3">
        <span class="spnValue" [innerHTML]="orderService.orderDto.id"></span>
      </div>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-2">
        <div>
          <span class="spnValue">Chicago, IL 60680-1050</span>
        </div>
      </div>
    </div>
    <div class="row" >
      <div class="col-2">
        <span class="spnHeader">Content Solutions</span>
      </div>
      <div class="col-10">
        <span class="spnValue" [innerHTML]=""></span>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <span class="spnHeader">Client Manager:</span>
      </div>
      <div class="col-10" > 
        <span class="spnValue"  [innerHTML]="getClientManagerName()"></span>
        
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <span class="spnHeader">Email:</span>
      </div>
      <div class="col-10">
        <span class="spnValue" [innerHTML]="contentSolutionsClientManagerEmail"></span>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <span class="spnHeader">Call:</span>
      </div>
      <div class="col-10">
        <span class="spnValue" [innerHTML]="contentSolutionsClientManagerPhone"></span>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <span class="spnHeader">Fax:</span>
      </div>
      <div class="col-10">
        <span class="spnValue" [innerHTML]="contentSolutionsClientManagerFax"></span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-2">
        <span class="spnHeader">Bill to:</span>
      </div>
      <div class="col-4 mt-3">
        <div>
          <span class="spnValue" [innerHtml]="orderService.orderDto?.paymentMethod === 'S' ?
          (orderService?.selectedShipToContact?.mailingStreet ? orderService.selectedShipToContact?.mailingCompany
          : orderService?.selectedShipToContact?.street ? orderService.selectedShipToContact?.company
          : orderService.orderDto?.shipToDto?.company)
        : orderService.orderDto?.billToDto?.company">
          </span>
        </div>
        <div>
          <span class="spnValue" [innerHtml]="
          orderService.orderDto?.paymentMethod === 'S' ?
            (orderService?.selectedShipToContact?.mailingStreet ? orderService.selectedShipToContact?.mailingDepartment
            : orderService?.selectedShipToContact?.street ? orderService.selectedShipToContact?.department
            : orderService.orderDto?.shipToDto?.department)
          : orderService.orderDto?.billToDto?.department">
          </span>
        </div>
        <div>
          <span class="spnValue" [innerHTML]="
          orderService.orderDto?.paymentMethod === 'S' ?
            (orderService?.selectedShipToContact?.mailingStreet ? orderService.selectedShipToContact?.mailingStreet
            : orderService?.selectedShipToContact?.street ? orderService.selectedShipToContact?.street
            : orderService.orderDto?.shipToDto?.street)
          : orderService.orderDto?.billToDto?.street">
          </span>
        </div>
        <div>
          <span class="spnValue" [innerHTML]="generateCityStateZip(orderService.orderDto.billToDto.city, orderService.orderDto.billToDto?.state,
        orderService.orderDto.billToDto.zip)">
          </span>
        </div>
      </div>
      <div class="col-1"></div>
      <div class="col-2">
        <span class="spnHeader">Ship to:</span>
      </div>
      <div class="col-3">
        <div>
          <span class="spnValue" [innerHTML]="generateFullName(orderService.orderDto.shipToDto.firstName,
        orderService.orderDto.shipToDto.lastName)">
          </span>
        </div>
        <div>
          <span class="spnValue" [innerHTML]="orderService.orderDto.shipToDto.company">
          </span>
        </div>
        <div>
          <span class="spnValue" [innerHTML]="orderService.orderDto.shipToDto.department">
          </span>
        </div>
        <div>
          <span class="spnValue" [innerHTML]="orderService.orderDto.shipToDto.street">
          </span>
        </div>
        <div>
          <span class="spnValue" [innerHTML]="generateCityStateZip(orderService.orderDto.shipToDto.city, orderService.orderDto.shipToDto.state,
        orderService.orderDto.shipToDto.zip)"></span>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-2">
        <span class="spnHeader">Quote Date:</span>
      </div>
      <div class="col-3">
        <div>
          <span class="spnValue" [innerHtml]="orderService.orderDto.enteredDate | date: 'MM/dd/yyyy'"></span>
        </div>
      </div>
      <div class="col-2"></div>
      <div class="col-2">
        <span class="spnHeader">Requested By:</span>
      </div>
      <div class="col-3">
        <span class="spnValue" [innerHtml]="generateFullName(orderService.orderDto.orderByDto.firstName,
            orderService.orderDto.orderByDto.lastName)"></span>
      </div>
    </div>

    <div style="margin-left: -1%;">
      <div class="uitk-l-grid" style="padding-left: 0%;">
        <div class="uitk-l-grid__row">
          <div class="uitk-l-grid__col--12">
            <div uitkTableContainer>
              <table uitkTable [dataSource]="orderService.orderDto.orderDetailDtos" id="basic-table">
                <thead>
                  <tr>
                    <th class="tableHeader" scope="col" *ngIf="orderService.cfdFormFields!.itemNumberCheckbox">
                      <span class="tableHeaderSpan">Item #</span>
                    </th>
                    <th class="tableHeader" scope="col" *ngIf="orderService.cfdFormFields!.itemDescriptionCheckbox">
                      <span class="tableHeaderSpan">Item Description</span>
                    </th>
                    <th class="tableHeader" scope="col" *ngIf="orderService.cfdFormFields!.renewalDateCheckbox">
                      <span class="tableHeaderSpan">Renewal Date</span>
                    </th>
                    <th class="tableHeader" scope="col" *ngIf="orderService.cfdFormFields!.availDateCheckbox">
                      <span class="tableHeaderSpan">Avail Date</span>
                    </th>
                    <th class="tableHeader" scope="col" *ngIf="orderService.cfdFormFields!.listPriceCheckbox">
                      <span class="tableHeaderSpan">List Price</span>
                    </th>
                    <th class="tableHeader" scope="col"
                      *ngIf="orderService.cfdFormFields!.discountPercentageCheckbox">
                      <span class="tableHeaderSpan">Discount</span>
                    </th>
                    <th class="tableHeader" scope="col" *ngIf="orderService.cfdFormFields!.yourPriceCheckbox">
                      <span class="tableHeaderSpan">Your Price</span>
                    </th>
                    <th class="tableHeader" scope="col" *ngIf="orderService.cfdFormFields!.quantityCheckbox">
                      <span class="tableHeaderSpan">Qty</span>
                    </th>
                    <th class="tableHeader" id="total" scope="col" *ngIf="orderService.cfdFormFields!.totalCheckbox">
                      <span class="tableHeaderSpan" >Total</span>
                    </th>
                  </tr>
                </thead>
                <tbody id="orderDetail-data">
                  <ng-container *uitkTableRowRenderer="let orderDetailDto">
                    <tr>
                      <td class="tableBody" *ngIf="orderService.cfdFormFields!.itemNumberCheckbox">
                        <span class="tableBodySpan" [innerHTML]="orderDetailDto.productCode"></span>
                      </td>
                      <td class="tableBody" *ngIf="orderService.cfdFormFields!.itemDescriptionCheckbox">
                        <span class="tableBodySpan" [innerHTML]="orderDetailDto.productDescription"></span>
                      </td>
                      <td class="tableBody" *ngIf="orderService.cfdFormFields!.renewalDateCheckbox">
                        <span class="tableBodySpan" [innerHTML]="orderDetailDto.subscriptionDto?.startDate | date: 'MM/dd/yyyy'"></span>
                      </td>
                      <td class="tableBody" *ngIf="orderService.cfdFormFields!.availDateCheckbox">
                        <span class="tableBodySpan" [innerHTML]="orderDetailDto.productDto.availableText"></span>
                      </td>
                      <td class="tableBody" *ngIf="orderService.cfdFormFields!.listPriceCheckbox">
                        <span class="tableBodySpan" *ngIf="orderDetailDto.unitPrice > orderDetailDto.listPrice"
                          [innerHTML]="orderDetailDto.unitPrice | currency:'USD':'symbol':'1.2-2'">
                        </span>
                        <span class="tableBodySpan" *ngIf="orderDetailDto.unitPrice <= orderDetailDto.listPrice"
                        [innerHTML]="orderDetailDto.listPrice | currency:'USD':'symbol':'1.2-2'">
                      </span>
                    
                      </td>
                      <td class="tableBody" *ngIf="orderService.cfdFormFields!.discountPercentageCheckbox">
                        <span class="tableBodySpan" [innerHTML]="(orderDetailDto.unitPrice > orderDetailDto.listPrice ? 0.00 : orderDetailDto.discount / 100) | percent:'1.2-2'"></span>
                      </td>
                      <td class="tableBody" *ngIf="orderService.cfdFormFields!.yourPriceCheckbox">
                        <span class="tableBodySpan"
                          [innerHTML]="orderDetailDto.unitPrice | currency:'USD':'symbol':'1.2-2'"></span>
                      </td>
                      <td class="tableBody" *ngIf="orderService.cfdFormFields!.quantityCheckbox">
                        <span class="tableBodySpan" [innerHTML]="orderDetailDto.orderQuantity"></span>
                      </td>
                      <td class="tableBodyEnd" *ngIf="orderService.cfdFormFields!.totalCheckbox">
                        <span class="tableBodySpan"
                          [innerHTML]="orderDetailDto.extendedPrice | currency:'USD':'symbol':'1.2-2'"></span>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uitk-l-grid">
      <div class="uitk-l-grid__row">
        <div class="uitk-l-grid__col--8">
        </div>
        <div class="uitk-l-grid__col--4">
          <table uitkTable>
            <tbody id="patient-data">
              <tr>
                <td class="tableBody">
                  <span class="tableBodySpan">Non-Taxable Subtotal</span>
                </td>
                <td class="tableBodyEnd" style="width: 37%;">
                  <span class="tableBodySpan" [innerHTML]="getNonTaxableSubTotal() | currency:'USD':'symbol':'1.2-2'"></span>
                </td>
              </tr>
              <tr>
                <td class="tableBody">
                  <span class="tableBodySpan">Taxable Subtotal</span>
                </td>
                <td class="tableBodyEnd" style="width: 37%;">
                  <span class="tableBodySpan" [innerHTML]="getTaxableSubtotal() | currency:'USD':'symbol':'1.2-2'"></span>
                </td>
              </tr>
              <tr>
                <td class="tableBody">
                  <span class="tableBodySpan">Estimated Tax</span>
                </td>
                <td class="tableBodyEnd" style="width: 37%;">
                  <span class="tableBodySpan" [innerHTML]="getEstimatedTax() | currency:'USD':'symbol':'1.2-2'"></span>
                </td>
              </tr>
              <tr>
                <td class="tableBody" style="border-top: 4px solid #797979;">
                  <span class="tableBodySpan">Estimated Amount Due</span>
                </td>
                <td class="tableBodyEnd" style="width: 37%; border-top: 4px solid #797979;">
                  <span class="tableBodySpan" [innerHTML]="getEstimatedAmountDue() | currency:'USD':'symbol':'1.2-2'"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>
  <div class="px-3 mt-3 disclaimerSection">
    <div *ngIf="disclaimerType === 'porequired'">
      <div class="row" style="margin-bottom: 0.6rem;">Your account requires a Purchase Order; to confirm your order please complete one of
        the following:</div>
      <div class="disclaimerSectionOLTag">
        <div class="disclaimerSectionLITag" value="1">
          <div class="row wordWrap">1. Email a copy of your PO and your Quote to ordermanagement@optum.com or Fax to 801-982-4033</div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">2. Call 1-800-464-3649 to provide a valid Credit Card</div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">3. Pay by EFT/ACH with the information listed below:
            <table class="footer-table">
              <tr>
                <td style="width: 18%;">Optum360LLC</td>
                <td style="width: 18%;">Bank Name:</td>
                <td>Fifth Third Bank</td>
              </tr>
              <tr>
                <td >PO Box 88050</td>
                <td>Account #:</td>
                <td>7025806535</td>
              </tr>
              <tr>
                <td>Chicago, IL 60680-1050</td>
                <td>ACH/Wire Routing Number:</td>
                <td>042000314</td>
              </tr>
              <tr>
                <td></td>
                <td>Swift Code:</td>
                <td>FTBCUS3C</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Deposit Only</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">4. Mail a check to the Remit Address above. Be sure to write the Account # and Quote # shown above on your check and attach a copy of the Quote.</div>
        </div>
      </div>
      <!-- <div class="row mt-3">To proceed with purchase through email you must attach a copy of this Quote in your
        response.</div> -->
      <div class="row mt-3">*Final tax calculated upon shipment of items.</div>
      <div class="row mt-3 wordWrap">At Optum, we are committed to providing exceptional customer service and ensuring your
        satisfaction with our products. We understand that sometimes returns may be necessary, and we want to make the process
        as smooth as possible for you. Please review our return policy below for information on how to proceed with returns
        and refunds. Our dedicated customer service team is here to assist you every step of the way. Thank you for choosing
        Optum.</div>
        <div class="row mt-3"><span style="color: #002677; font-weight: bold;">Return Policy: </span></div>
        <div class="mt-3"><span style="color: #002677; font-weight: bold;">Books: </span> Books purchased from us have a 60-day return policy. If you are unsatisfied with
          the book, please return the book within 60 days of shipment (as reflected on the packing slip). Damaged merchandise
          will not be credited. Special and custom orders are non-returnable and non-refundable.</div>
        <div class="row mt-3"><span style="color: #002677; font-weight: bold;">Return Address: </span></div>
        <div class="row mt-3">Optum</div>
        <div class="row">ATTN: Returns Processing</div>
        <div class="row">4810 Williamsburg Warehouse #2</div>
        <div class="row">Hurlock, MD 21643</div>
        <div class="mt-3"><span style="color: #002677; font-weight: bold;">Refunds:</span> Refunds will be processed within 30 business days of receipt of the returned
          item(s). Please include the original packing slip or Return Authorization Number with your return.</div>
        <div class="mt-3"><span style="color: #002677; font-weight: bold;">Digital Solutions:</span>  Digital solutions, such as, but not limited to, eBooks, Data Files,
          SaaS and Web-Based Applications, are not eligible for return. Digital solutions are subject to the terms and
          conditions of the associated agreement.</div>
    </div>
    <div *ngIf="disclaimerType === 'prepayrequired'">
      <div class="row" style="margin-bottom: 0.6rem;">To confirm your order please complete one of the following:</div>
      <div class="disclaimerSectionOLTag">
        <div class="disclaimerSectionLITag" value="1">
          <div class="row wordWrap">1. Call 1-800-464-3649 to provide a valid Credit Card.</div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">2. Pay by EFT/ACH with the information listed below:
            <table class="footer-table">
              <tr>
                <td style="width: 18%;">Optum360LLC</td>
                <td style="width: 18%;">Bank Name:</td>
                <td>Fifth Third Bank</td>
              </tr>
              <tr>
                <td >PO Box 88050</td>
                <td>Account #:</td>
                <td>7025806535</td>
              </tr>
              <tr>
                <td>Chicago, IL 60680-1050</td>
                <td>ACH/Wire Routing Number:</td>
                <td>042000314</td>
              </tr>
              <tr>
                <td></td>
                <td>Swift Code:</td>
                <td>FTBCUS3C</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Deposit Only</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">3. Mail a check to the Remit Address above. Be sure to write the Account # and Quote # shown above on your check and attach a copy of the Quote.</div>
        </div>
      </div>
      <!-- <div class="row mt-3 wordWrap">To proceed with purchase through email you must attach a copy of this Quote in your
        response.</div> -->
      <div class="row mt-3 wordWrap">*Final tax calculated upon shipment of items.</div>
      <div class="row mt-3 wordWrap">At Optum, we are committed to providing exceptional customer service and ensuring your
        satisfaction with our products. We understand that sometimes returns may be necessary, and we want to make the process
        as smooth as possible for you. Please review our return policy below for information on how to proceed with returns
        and refunds. Our dedicated customer service team is here to assist you every step of the way. Thank you for choosing
        Optum.</div>
        <div class="row mt-3"><span style="color: #002677; font-weight: bold;">Return Policy: </span></div>
        <div class="mt-3"><span style="color: #002677; font-weight: bold;">Books: </span> Books purchased from us have a 60-day return policy. If you are unsatisfied with
          the book, please return the book within 60 days of shipment (as reflected on the packing slip). Damaged merchandise
          will not be credited. Special and custom orders are non-returnable and non-refundable.</div>
        <div class="row mt-3"><span style="color: #002677; font-weight: bold;">Return Address: </span></div>
        <div class="row mt-3">Optum</div>
        <div class="row">ATTN: Returns Processing</div>
        <div class="row">4810 Williamsburg Warehouse #2</div>
        <div class="row">Hurlock, MD 21643</div>
        <div class="mt-3"><span style="color: #002677; font-weight: bold;">Refunds:</span> Refunds will be processed within 30 business days of receipt of the returned
          item(s). Please include the original packing slip or Return Authorization Number with your return.</div>
        <div class="mt-3"><span style="color: #002677; font-weight: bold;">Digital Solutions:</span>  Digital solutions, such as, but not limited to, eBooks, Data Files,
          SaaS and Web-Based Applications, are not eligible for return. Digital solutions are subject to the terms and
          conditions of the associated agreement.</div>
    </div>
    <div *ngIf="disclaimerType === 'standard'">
      <div class="row" style="margin-bottom: 0.6rem;">To confirm your order complete one of the following:</div>
      <div class="disclaimerSectionOLTag">
        <div class="disclaimerSectionLITag" value="1">
          <div class="row wordWrap">1. Call 1-800-464-3649 to provide a valid Credit Card.</div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">2. Pay by EFT/ACH with the information listed below:
            <table class="footer-table">
              <tr>
                <td style="width: 18%;">Optum360LLC</td>
                <td style="width: 18%;">Bank Name:</td>
                <td>Fifth Third Bank</td>
              </tr>
              <tr>
                <td >PO Box 88050</td>
                <td>Account #:</td>
                <td>7025806535</td>
              </tr>
              <tr>
                <td>Chicago, IL 60680-1050</td>
                <td>ACH/Wire Routing Number:</td>
                <td>042000314</td>
              </tr>
              <tr>
                <td></td>
                <td>Swift Code:</td>
                <td>FTBCUS3C</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Deposit Only</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">3. Email a copy of your PO and your Quote to ordermanagement@optum.com or Fax to 801-982-4033.</div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">4. Mail a check to the Remit Address above. Be sure to write the Account # and Quote # shown above on your check and attach a copy of the Quote.</div>
        </div>
        <div class="disclaimerSectionLITag">
          <div class="row wordWrap">5. Sign Below (in lieu of purchase order, I authorize that this order is correct, and will be paid in full upon receipt of goods) and email your Quote to ordermanagement@optum.com or Fax to 801-982-4033
            <br />X_______________________________(Authorized Signature)</div>
        </div>
      </div>
      <!-- <div class="row mt-3 wordWrap">To proceed with purchase through email you must attach a copy of this Quote in your
        response.</div> -->
      <div class="row mt-3 wordWrap">*Final tax calculated upon shipment of items.</div>
      <div class="row mt-3 wordWrap">At Optum, we are committed to providing exceptional customer service and ensuring your
        satisfaction with our products. We understand that sometimes returns may be necessary, and we want to make the process
        as smooth as possible for you. Please review our return policy below for information on how to proceed with returns
        and refunds. Our dedicated customer service team is here to assist you every step of the way. Thank you for choosing
        Optum.</div>
        <div class="row mt-3"><span style="color: #002677; font-weight: bold;">Return Policy: </span></div>
        <div class="mt-3"><span style="color: #002677; font-weight: bold;">Books: </span> Books purchased from us have a 60-day return policy. If you are unsatisfied with
          the book, please return the book within 60 days of shipment (as reflected on the packing slip). Damaged merchandise
          will not be credited. Special and custom orders are non-returnable and non-refundable.</div>
        <div class="row mt-3"><span style="color: #002677; font-weight: bold;">Return Address: </span></div>
        <div class="row mt-3">Optum</div>
        <div class="row">ATTN: Returns Processing</div>
        <div class="row">4810 Williamsburg Warehouse #2</div>
        <div class="row">Hurlock, MD 21643</div>
        <div class="mt-3"><span style="color: #002677; font-weight: bold;">Refunds:</span> Refunds will be processed within 30 business days of receipt of the returned
          item(s). Please include the original packing slip or Return Authorization Number with your return.</div>
        <div class="mt-3"><span style="color: #002677; font-weight: bold;">Digital Solutions:</span>  Digital solutions, such as, but not limited to, eBooks, Data Files,
          SaaS and Web-Based Applications, are not eligible for return. Digital solutions are subject to the terms and
          conditions of the associated agreement.</div>
    </div>
  </div>
</div>
