<div class="uitk-l-grid__row mt-1 mb-1">
  <span class="uitk-icon uitk-icon-edit sm-icon mt-1"></span>

  <a class="link" id="editShipAddress" href="javaScript:void(0)"
    (click)="showNormalDialog()"  [ngStyle]="{'pointer-events': (disableAddButton || orderService.orderDto.orderStatus === 'V' || orderService.orderDto.orderStatus === 'C' || ( (orderService.orderDto.inUseBy || orderService.orderDto.pendingDate) && modeService.mode !== Mode.Edit )) ? 'none' : 'auto'}"> Edit Address</a>
</div>

<div *ngIf="normalDialogModal.show" scrollToTarget>
  <div *ngIf="normalDialogModal.show" class = "overlayForPopups">
    <div cdkDrag>
    <uitk-dialog uitkId="ChangeShipToAdd" [(showModal)]="normalDialogModal.show" triggerElementId="editShipAddress" (onModalHidden)="closeModal('#sameAsOrderByLabel')">
      <uitk-dialog-header>
        <div cdkDragHandle>
          <br>
        <h3>Change Ship to Address</h3>
        </div>
      </uitk-dialog-header>
      <uitk-dialog-content *ngIf="IsChangeShiptoAdd">
        
        <h4>Current Ship to Address</h4>

        <p id="addressDet">{{shipToDto.company}}</p>

        <p id="addressDet"> {{shipToDto.department}}</p>

        <p id="addressDet"> {{shipToDto.street}}</p>

        <p id="addressDet"> {{shipToDto.city}}, {{shipToDto.state}}, {{shipToDto.zip}}</p>

        <p id="addressDet"> {{shipToDto.country}}</p>
        <br>
        <div class="mb-0" id="companyNameMessage"><p> *Limit to 35 characters due to UPS restrictions</p></div>
        <div class="uitk-l-grid__row">
          <div class="uitk-l-grid__col--3 ps-0 pt-0">
            <label uitkLabel id="label">Abbreviated Company Name</label>
          </div>
          <div class="uitk-l-grid__col--3 pt-0">
            <uitk-form-field>
              <input uitkInput id="companyName" class="textbox" type="text" value="" maxlength="35" appEnableResource="ShipToEditAddressCompanyNameTextBox" [(ngModel)]="companyName"
                      />
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__col--3  pt-0">
            <label uitkLabel id="label">&nbsp;&nbsp;Department Name</label>
          </div>
          <div class="uitk-l-grid__col--3  pt-0">
            <uitk-form-field>
              <input uitkInput id="departmentName" class="textbox" type="text" value="" appEnableResource="ShipToEditAddressDepartmentNameTextBox"
                    [(ngModel)]="departmentName" />
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__row">
          <div class="uitk-l-grid__col--3 ps-0">
            <label uitkLabel id="label">Address Line 1 </label>
          </div>
          <div class="uitk-l-grid__col--3">
            <uitk-form-field>
              <input uitkInput id="addLine1" class="textbox" type="text" value="" appEnableResource="ShipToEditAddressAddressLine1TextBox" [(ngModel)]="addLine1" name="streetInput"
              required
              #streetInput="ngModel" appAutoFocus/>
              <uitk-form-field-error *ngIf="streetInput?.errors?.required">
              required
            </uitk-form-field-error>
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__col--3">
            <label uitkLabel id="label">&nbsp;&nbsp;Zip Code</label>
          </div>
          <div class="uitk-l-grid__col--3">
            <uitk-form-field>
              <input uitkInput required  id="zipCode" class="textbox" type="text" value="" appEnableResource="ShipToEditAddressZipCodeTextBox" (change)="onChangezipCode($event)" [(ngModel)]="zipCode"
              name="zipcodeInput"
              #zipcodeInput="ngModel"  />
              <uitk-form-field-error *ngIf="zipcodeInput?.errors?.required">
                required
            </uitk-form-field-error>
            </uitk-form-field>
          </div>
        </div>
        <br>
        <uitk-form-field-error *ngIf="showZipCodeTableError">
          Required
        </uitk-form-field-error>
        <div [hidden]="!showZipCodeTable" class="uitk-c-table--responsive">
          <table uitkTable [dataSource]="shipToAddressData" id="basic-table" class="uitk-c-table"
                aria-describedby="shipToAdd-data">

            <thead class="uitk-c-table__head">
              <tr>
                <th *ngFor="let headerName of tableHeader" scope="col">
                  {{ headerName}}
                </th>
              </tr>
            </thead>
            <tbody id="shipToAdd-data" class="uitk-c-table__body">
              <ng-container *uitkTableRowBody="let shipToAdd">
                <tr class="uitk-c-table__row">
                  <td>
                    <input id="gridcheckbox" type="radio" name="radioname" addEnableResource="ShipToEditAddressSelectCityRadioButton" (change)="onSelectionChange(shipToAdd.subCity)" [checked]="shipToAdd.checked">
                  </td>
                  <td>{{shipToAdd.subCity}}</td>
                  <td>{{shipToAdd.state}}</td>
                  <td>{{shipToAdd.country}}</td>
                </tr>
              </ng-container>
              <div *ngIf="shipToAddressData.length === 0">No records found</div>
            </tbody>
          </table>
        </div>
      </uitk-dialog-content>
      <uitk-dialog-content *ngIf="!IsChangeShiptoAdd">
        <h3>Select Ship to Address</h3>
        <div  id="StandardizedAdd" class="uitk-l-grid">
          <div class="uitk-l-grid__row">
            <form [formGroup]="profileForm">
              <uitk-form-field>
                  <uitk-fieldset>
            <uitk-radio-group uitkId="my-basic-radio-group" formControlName="radioGroup"
            [itemList]="items" [(ngModel)]="myRadioGroupSelecteditem" >
        </uitk-radio-group>
      </uitk-fieldset>
    </uitk-form-field>
  </form>
          </div>
        <div class="uitk-l-grid__row">

          <div class="uitk-l-grid__col">
              <div class="selectAdd">
              {{companyName}}<br>
              {{addressEntered.department}}<br>
              {{addressEntered.street}}<br>
              {{addressEntered.city}}, {{addressEntered.state.trim()}}, {{addressEntered.zip}}<br>
              {{addressEntered.country}}
              </div>
          </div>
          <div class="uitk-l-grid__col">
              <div *ngIf="addressStandardized.zip" class="selectAdd">
                {{companyName}}<br>
                {{addressStandardized.department}}<br>
                {{addressStandardized.street}}<br>
                {{addressStandardized.city}}, {{addressStandardized.state.trim()}}, {{addressStandardized.zip}}<br>
                {{addressStandardized.country}}
              </div>
              <div *ngIf="!addressStandardized.zip" class="selectAdd">
                Unable to Standardize address<br><br><br><br><br>
              </div>
          </div>
        </div>
        </div>
      </uitk-dialog-content>
      <uitk-dialog-actions>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" *ngIf="isNewBtnEnabled" (click)="newZipCode()" > New  </button>
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" *ngIf="IsChangeShiptoAdd"
        [disabled]="!addLine1 || !zipCode" appEnableResource="ShipToEditAddressNextButton" (click)="next()" > Next  </button>
        <button class="uitk-c-button uitk-c-button--alternative uitk-u-margin-right--base uitk-u-margin-bottom--xs" *ngIf="!IsChangeShiptoAdd" appEnableResource="ShipToEditAddressPreviousButton" (click)="previous()"> Previous  </button>
          <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveShipToAddress()" *ngIf="!IsChangeShiptoAdd" appEnableResource="ShipToEditAddressSaveButton">Save</button>
          <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" appEnableResource="ShipToEditAddressCancelButton" (click)="cancelDialog()">Cancel</button>
      </uitk-dialog-actions>
  </uitk-dialog>
    </div>
  </div>
</div>

<div *ngIf="warningDialogModal.show">
  <div *ngIf="warningDialogModal.show" class = "overlayForPopups">
    <div cdkDrag>
    <uitk-dialog
    [(showModal)]="warningDialogModal.show"
    uitkId="warningDialog"
    [closeBtn]=false
    triggerElementId="editShipAddress"   
    (onModalHidden)="closeModal('#sameAsOrderByLabel')"
  >
    <uitk-dialog-header>
      <div cdkDragHandle>
        Alert
      </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
        <p>These changes are temporary for this order only. Permanent address changes must be made in Salesforce.</p>
    </uitk-dialog-content>
    <uitk-dialog-actions>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeWarningDialog()" appAutoFocus>
            Ok
        </button>
    </uitk-dialog-actions>
  </uitk-dialog>
    </div>
  </div>
</div>
