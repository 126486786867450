<div class="overlayForPopups">
  <div cdkDrag>
    <uitk-dialog uitkId="dialog"  [(showModal)]="dialog.show" [triggerElementId]="_orderService.shippingDialogModalTriggerEle"
                 [closeBtn]="false" (onModalHidden)="closeModal()">
      <uitk-dialog-header>
        <div cdkDragHandle>
          Shipment Confirmation Emails
        </div>
      </uitk-dialog-header>
      <uitk-dialog-content scrollToTarget>
        <!--<label uitkLabel>Shipment Confirmation Emails</label>-->
        <ul uitkCheckboxGroup>
          <li>
            <p>Ordered by</p>
            <input id="shipmentOrderedByCheckbox" type="checkbox" uitkCheckbox
                   [(ngModel)]="shipmentOrderedByCheckbox" />
            <label uitkCheckboxLabel for="shipmentOrderedByCheckbox"></label>
            <uitk-form-field id="shipmentOrderedByTextbox">
              <uitk-form-field-hint>{{orderedByContactName}}</uitk-form-field-hint>
              <input uitkInput class="textbox mx-1" class="textbox" type="email"
                     [(ngModel)]="orderedByContactEmail" disabled />
            </uitk-form-field>

          </li>
          <li>
            <p>Ship to</p>
            <input id="shipmentShipToCheckbox" type="checkbox" uitkCheckbox [(ngModel)]="shipmentShipToCheckbox" />
            <label uitkCheckboxLabel for="shipmentShipToCheckbox" style="margin-top: unset;"></label>
            <uitk-form-field id="shipmentShipToTextbox">
              <uitk-form-field-hint>{{shipToContactName}}</uitk-form-field-hint>
              <input uitkInput class="textbox mx-1" class="textbox" type="email" [(ngModel)]="shipToContactEmail"
                     disabled />
            </uitk-form-field>
          </li>
          <li>
            <p>Logged in User</p>
            <input id="shiploggedInUserCheckbox" type="checkbox" uitkCheckbox [(ngModel)]="shiploggedInUserCheckbox"
                   [disabled]="this.orderDto.billToDto.accountTypeCode === 'C' || this.orderDto.billToDto.accountTypeCode === 'H'" />                <label uitkCheckboxLabel for="orderloggedInUserCheckbox" style="margin-top: unset;"></label>
            <uitk-form-field id="shiploggedInUserTextbox">
              <uitk-form-field-hint>{{loggedInUserName}}</uitk-form-field-hint>
              <input uitkInput class="textbox mx-1" class="textbox" type="email" [(ngModel)]="shiploggedInUser"
                     disabled />
            </uitk-form-field>


          </li>
          <li>
            <p>Other</p>
            <input id="shipmentOtherCheckbox" type="checkbox" uitkCheckbox [(ngModel)]="shipmentOtherCheckbox" />
            <label uitkCheckboxLabel for="shipmentOtherCheckbox"></label>
            <uitk-form-field id="shipmentOtherTextbox">
              <input uitkInput #shipmentOther="ngModel" class="textbox" type="email" [pattern]="emailPattern"
                     value="" [(ngModel)]="shipmentOtherEmail" (change)="setCheckbox()" />
              <uitk-form-field-error *ngIf="shipmentOther?.errors?.pattern && shipmentOther?.touched">
                Invalid Email
              </uitk-form-field-error>
            </uitk-form-field>
          </li>
        </ul>
      </uitk-dialog-content>
      <uitk-dialog-actions>
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveEmail()" appAutoFocus
                [disabled]="shipmentOther?.errors?.pattern">
          OK
        </button>
      </uitk-dialog-actions>
    </uitk-dialog>
  </div>
</div>
