import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular/forms/select/select-item.interface';
import { ConfigService } from '../../services/config.service';
import { TokenizeService } from '../../services/tokenize.service';

@Component({
  selector: 'app-tokenize',
  templateUrl: './tokenize.component.html',
  styleUrls: ['./tokenize.component.css']
})
export class TokenizeComponent {
  collapsiblePanel = true;
  openPanel = true;
  panelBackgroundOpen = "uitk-l-grid-open";
  panelBackground = this.panelBackgroundOpen;
  defaultLabel = "- Please Select";
  payMethodList: IUITKSelectItemProps[] = [];
  ccPayMethodList: IUITKSelectItemProps[] = [];
  payMethod: IUITKSelectItemProps | undefined = { id: 'Select', label: 'Select', value: 'Select' };
  ccNumber: string = '';
  token: string = '';
  expDate: string = '';
  fourDigits: string = '';
  showTokenError = false;
  @ViewChild('ccInput') ccInput: ElementRef | undefined;
  constructor(public configService: ConfigService, public tokenService: TokenizeService) {
  }

  setPanelBGHeight() {
    if (this.panelBackground === this.panelBackgroundOpen)
    {
      this.panelBackground = "uitk-l-grid1-close";
    }
    else
    {
      this.panelBackground = this.panelBackgroundOpen;
    }
  }
  getDropDownValues() {
    this.ccPayMethodList = this.configService.getPickList("PMETH").filter(pl => ['D', 'X', 'M', 'V'].indexOf(pl.value) >= 0);
  }

  onCCChange(encryptedCCStr: any): void {
    this.getDropDownValues();
    this.payMethodList = this.ccPayMethodList;
    if ((encryptedCCStr.length === 157 || encryptedCCStr.length === 158 || encryptedCCStr.length === 177 || encryptedCCStr.length === 178) && encryptedCCStr.endsWith('03')) {
      this.showTokenError = false;
      const firstSet = this.ccNumber.split("?")[0].split("=");
      this.expDate = firstSet[1].substr(2, 2) + firstSet[1].substr(0, 2);
      const secondSet = firstSet[0].split(";");
      this.ccNumber = secondSet[1];

      switch (this.ccNumber.substr(0, 1)) {
        case "3":
          this.payMethod = this.payMethodList.find(pmeth => pmeth.label === "American Express");
          break;
        case "4":
          this.payMethod = this.payMethodList.find(pmeth => pmeth.label === "Visa");
          break;
        case "5":
          this.payMethod = this.payMethodList.find(pmeth => pmeth.label === "MasterCard");
          break;
        case "6":
          this.payMethod = this.payMethodList.find(pmeth => pmeth.label === "Discover");
          break;
        default:
          break;
      }
      setTimeout(() => {
        this.token = 'Processing, please wait ...';
      });
      setTimeout(() => {
        this.tokenService.getCcToken({ encryptedData: encryptedCCStr }).subscribe((data) => {
          this.token = data.token;
        })
      }, 2000);
    }
    else {
      this.showTokenError = true;
    }

  }

  clear() {
    this.payMethod = { id: 'Select', label: 'Select', value: 'Select' };
    this.expDate = '';
    this.token = '';
    this.ccNumber = '';
    this.ccInput?.nativeElement.focus();
    this.showTokenError = false;
  }
}
