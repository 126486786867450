export class CfdFormFieldsDto {
  itemNumberCheckbox = true;
  itemDescriptionCheckbox = true;
  renewalDateCheckbox = false;
  listPriceCheckbox = false;
  discountPercentageCheckbox = false;
  yourPriceCheckbox = true;
  availDateCheckbox = false;
  quantityCheckbox = true;
  totalCheckbox = true;
  validUptoCheckbox = true;
  }
  