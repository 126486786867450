import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UITKHeadingLevel, UITKTableDataSource } from '@uitk/angular';
import { OrderByOrderEntryComponent } from '../../components/order-entry/order-by-order-entry/order-by-order-entry.component';
import { ShipToOrderEntryComponent } from '../../components/order-entry/ship-to-order-entry/ship-to-order-entry.component';
import { AdvancedContactSearchInputDto } from '../../dtos/advanced-contact-search-input-dto.model';
import { AdvancedContactSearchResultsDto } from '../../dtos/advanced-contact-search-results-dto.model';
import { Dialog } from '../../helpers/dialog';
import { ConfigService } from '../../services/config.service';
import { ContactService } from '../../services/contact.service';
import { ModeService } from '../../services/mode.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-advanced-contact-search-dialog',
  templateUrl: './advanced-contact-search-dialog.component.html',
  styleUrls: ['./advanced-contact-search-dialog.component.css']
})

export class AdvancedContactSearchDialogComponent {
  @Input() advancedContactSearchDialog!: Dialog<string>;
  @Input() component!: string;
  tableHeaders = [
    { name: 'Status', id: 'accountStatus', width: '7%' },
    { name: 'Id', id: 'accountId', width: '6%' },
    { name: 'Name', id: 'accountName', width: '17%' },
    { name: 'Type', id: 'accountType', width: '6%' },
    { name: 'Status', id: 'contactStatus', width: '8%' },
    { name: 'ID', id: 'contactId', width: '8%' },
    { name: 'Name', id: 'contactName', width: '14%' },
    { name: 'Found Text', id: 'foundText', width: '20%' },
    { name: 'Location', id: 'location', width: '16%' },
  ];
  advancedContactSearchInput: AdvancedContactSearchInputDto = new AdvancedContactSearchInputDto();
  advancedContactSearchData = new UITKTableDataSource<AdvancedContactSearchResultsDto>([]);
  errorMessageVisible = false;
  errorContentNotification = 'Filter information must be added to complete search.';
  headingLevel = UITKHeadingLevel;
  isadvancedContactSearchPanelOpen = true;

  constructor(private readonly contactService: ContactService,
    public readonly modeService: ModeService,
    private readonly configService: ConfigService,
    private readonly orderService: OrderService,
    private readonly orderByOrderEntryComponent: OrderByOrderEntryComponent,
    private readonly shipToOrderEntryComponent: ShipToOrderEntryComponent) { }

  contactIdNgModelChange(contactId: number): void {
    this.advancedContactSearchInput.contactId = contactId;
  }

  searchInputValidation(): boolean{
    if (this.advancedContactSearchInput.city && (!this.advancedContactSearchInput.contactId || this.advancedContactSearchInput.contactId === 0)) {
      if (!(this.advancedContactSearchInput.firstName || this.advancedContactSearchInput.lastName || this.advancedContactSearchInput.address ||
        this.advancedContactSearchInput.zipCode)) {
        this.errorContentNotification = 'Please Enter First Name or Last Name or Address or Zipcode to search.';
        this.errorMessageVisible = true;
        return false;
      }
    }
    return true;
  }

  async search(advancedContactSearchForm: NgForm): Promise<void> {
    this.advancedContactSearchData.data = [];

    if ((this.advancedContactSearchInput.contactId || this.advancedContactSearchInput.firstName || this.advancedContactSearchInput.lastName ||
      this.advancedContactSearchInput.email || this.advancedContactSearchInput.phone || this.advancedContactSearchInput.address || this.advancedContactSearchInput.city ||
      this.advancedContactSearchInput.zipCode) && advancedContactSearchForm.valid) {
      if(!this.searchInputValidation()){
        return;
      }
      if (this.orderService.orderDto.billToDto.id && this.orderService.orderDto.billToDto.id > 0) {
        this.advancedContactSearchInput.accountId = this.orderService.orderDto.billToDto.id.toString();
      }

      const result = await this.contactService.advancedContactSearch(this.advancedContactSearchInput).catch(error => {
        if (error.error.Message && error.error.Message.indexOf('Results exceeding 500') > -1) {
          this.errorContentNotification = 'More than 500 results found, please add more info in the filters.';
          this.errorMessageVisible = true;
        }
      });

      if (result) {
        this.isadvancedContactSearchPanelOpen = false;
        this.advancedContactSearchData.data = result;
      }
    }
    else {
      this.errorContentNotification = 'Filter information must be added to complete search.';
      this.errorMessageVisible = true;
    }
  }

  onAdvancedContactSearchPanelOpenChange(event: any): void {
    this.isadvancedContactSearchPanelOpen = !this.isadvancedContactSearchPanelOpen;
  }

  navigateToSalesforceNewContactPage(): void {
    window.open(`${this.configService.salesforceUrl}/apex/NewContactPage?accId=${this.orderService.orderDto.billToDto.salesforceId}`);
  }

  closeAdvancedContactSearchPopup(): void {
    this.advancedContactSearchDialog.close('close');
  }

  getCityErrorMessage(cityInput: any): string {
    if (cityInput.errors.pattern) {
      return 'Character ' + cityInput.value.match(/[^A-Z a-z 0-9.\-]/g) + ' is not allowed';
    }

    return '';
  }

  getFirstNameErrorMessage(firstNameInput: any): string {
    if (firstNameInput.errors.pattern) {
      return 'Character ' + firstNameInput.value.match(/[^a-zA-Z0-9,.&\/\-\'\(\)\*\@#+\:\[\]\`]/g) + ' is not allowed';
    }

    return '';
  }

  getZipCodeErrorMessage(zipCodeInput: any): string {
    if (zipCodeInput.errors.pattern) {
      return 'Character ' + zipCodeInput.value.match(/[^0-9\-]/g) + ' is not allowed';
    }

    return '';
  }

  getLastNameErrorMessage(lastNameInput: any): string {
    if (lastNameInput.errors.pattern) {
      return 'Character ' + lastNameInput.value.match(/[^a-zA-Z0-9,.&\/\-\'\(\)\*\@#+\:\[\]\`]/g) + ' is not allowed';
    }

    return '';
  }

  getEmailErrorMessage(emailInput: any): string {
    if (emailInput.errors.pattern) {
      return 'Character ' + emailInput.value.match(/[^A-Z a-z 0-9 #.,]/g) + ' is not allowed';
    }

    return '';
  }

  getAddressErrorMessage(addressInput: any): string {
    if (addressInput.errors.pattern) {
      return 'Character ' + addressInput.value.match(/[^A-Z a-z 0-9 #.,]/g) + ' is not allowed';
    }

    return '';
  }

  getPhoneErrorMessage(phoneInput: any): string {
    if (phoneInput.errors.pattern) {
      return 'Character ' + phoneInput.value.match(/[^0-9\-\/]/g) + 'is not allowed';
    }

    return '';
  }

  clearForm(): void {
    this.advancedContactSearchInput = new AdvancedContactSearchInputDto();
    this.advancedContactSearchData.data = [];
  }

  onContactIdClick(contactId: number): void {
    switch (this.component) {
      case 'order-by':
        this.orderService.orderDto.orderByDto.contactId = contactId;
        this.closeAdvancedContactSearchPopup();
        this.orderByOrderEntryComponent.onContactIdChange(contactId);

        setTimeout(() => {
          (document.getElementById("contactId") as HTMLElement).focus();
          (document.getElementById('contactId') as HTMLElement).blur();
        });

        break;
      case 'ship-to':
        this.orderService.orderDto.shipToDto.contactId = contactId;
        this.closeAdvancedContactSearchPopup();
        this.shipToOrderEntryComponent.onContactIdChange(contactId);

        setTimeout(() => {
          (document.getElementById("contactId-shipTo-textbox") as HTMLElement).focus();
          (document.getElementById('contactId-shipTo-textbox') as HTMLElement).blur();
        });

        break;
    }
  }

  onSortChange(event: any): void {
    this.advancedContactSearchData.data.sort((a: any, b: any) => 0 - (a[event.column] > b[event.column] ? event.direction * -1 : event.direction * 1));
    this.advancedContactSearchData.data = [...this.advancedContactSearchData.data];
  }
}
