
<div [ngClass]="panelBackground" class="panel">
    <uitk-panel uitkId="orderSearchPanel" [collapsible]="collapsiblePanel" [open]="openPanel"
                (openChange)="setPanelBGHeight()">
      <uitk-panel-header-title>
        <h2>Cash Inquiry Search</h2>
      </uitk-panel-header-title>
      <uitk-panel-content>
        <div class="uitk-l-grid__row" (keyup.enter)="search()">
          <div class="uitk-l-grid__col">
            <div class="uitk-l-grid__col--3">
              <label uitkLabel>Invoice #</label>
            </div>
            <div class="uitk-l-grid__col--6">
              <uitk-form-field>
                <input uitkInput id="invoiceNumber" class="textbox" type="text" value="" [(ngModel)]="invoiceNumber"
                        autofocus />
              </uitk-form-field>
            </div>
          </div>
          <div class="uitk-l-grid__col uitk-u-flex__align--center">
            <div class="uitk-l-grid__col--3">
              <label uitkLabel>Reference #</label>
            </div>
            <div class="uitk-l-grid__col--6">
              <uitk-form-field>
                <input uitkInput id="referenceNumber" class="textbox" type="text" value="" [(ngModel)]="referenceNumber"
                         />
              </uitk-form-field>
            </div>
          </div>
          <div class="uitk-l-grid__col uitk-u-flex__align--right">
            <div class="uitk-l-grid__col--3">
              <label uitkLabel>Paid Amt</label>
            </div>
            <div class="uitk-l-grid__col--6">
              <uitk-form-field>
                <input uitkInput id="paidAmt" class="textbox" type="text" value=""  [(customModel)]="paidAmt"  allowNegativePriceFormat  />
              </uitk-form-field>
            </div>
          </div>
        </div>
        <div class="uitk-l-grid__row ">
          <div class="uitk-l-grid__col">
            <div class="uitk-l-grid__col--3">
              <label uitkLabel>Paid Date</label>
            </div>
            <div class="uitk-l-grid__col--6 ">
                <form [formGroup]="requiredDatePickerForm">
              <uitk-form-field>
                      <uitk-date-picker
                                id="my-required-date-picker" #datePic  
                                formControlName="datePicker" (blur)="formatDate()" (change) = "formatDate()"
                            ></uitk-date-picker>
              </uitk-form-field>
            </form>
            </div>
          </div>
          <div class="uitk-l-grid__col"></div>
          <div class="uitk-l-grid__col"></div>
        </div>
        <div id="searchDiv" class="uitk-l-grid__row ">
          <div class="uitk-u-flex__align--left">
            <button id="searchBtn" (click)="search()"  type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--lg">
              Search
            </button>
            <a class="link" (click)="clearSearch()">Clear</a>
          </div>
        </div>
      </uitk-panel-content>
    </uitk-panel>
  </div>
  <div *ngIf="modeService.mode == Mode.CashInquiryWait">
    <body>
      <app-loader> </app-loader>
    </body>
  </div>
  <app-cash-inquiry-search-results [hidden]="!showResults" [collapedHeader]="collapsedHeader"></app-cash-inquiry-search-results>
 
  
  