import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ScrollToDirective } from 'src/app/directives/scrollTo.directive';
import { Dialog } from 'src/app/helpers/dialog';
import { ApprovalService } from 'src/app/services/approval.service';
import { ChegsetComponent } from './chegset/chegset.component';
import { OrderDetailDto } from '../../dtos/order-detail-dto.model';
import { OrderService } from '../../services/order.service';
import { CfaWebComponent } from './cfa-web/cfa-web.component';
import { CptComponent } from './cpt/cpt.component';
import { DatafileComponent } from './datafile/datafile.component';
import { DepositComponent } from './deposit/deposit.component';
import { FeeAnalyzerComponent } from './fee-analyzer/fee-analyzer.component';
import { ShippingComponent } from './shipping/shipping.component';
import { SubscriptionOrderEntryComponent } from './subscription-order-entry/subscription-order-entry.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { WebAppComponent } from './web-app/web-app.component';

@Component({
  selector: 'app-config-sets',
  templateUrl: './config-sets.component.html',
  styleUrls: ['./config-sets.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ConfigSetsComponent implements OnInit {
  hasSubscriptionConfigSet = false;
  hasFeeAnalyzerConfigSet = false;
  hasDataFileConfigSet = false;
  hasChegSetConfigSet = false;
  hasWebAppConfigSet = false;
  hasCfaWebConfigSet = false;
  hasDepositConfigSet = false;
  hasShippingConfigSet = false;
  hasCptConfigSet = false;

  enableSaveSubscriptionConfigSet = true;
  enableSaveFeeAnalyzerConfigSet = true;
  enableSaveDataFileConfigSet = true;
  enableSaveChegSetConfigSet = true;
  enableSaveWebAppConfigSet = true;
  enableSaveCfaWebConfigSet = true;
  enableSaveDepositConfigSet = true;
  enableShippingConfigSet = true;
  enableCptConfigSet = true;
  triggerEle ='';

  private readonly _approvalService: ApprovalService;

  @Input() dialog!: Dialog<string>;
  @Input() orderDetail: OrderDetailDto = new OrderDetailDto();
  @Output() closeEvent = new EventEmitter<any>();
  @Output() saveConfigurationsEvent = new EventEmitter<any>();

  @ViewChild(DepositComponent) depositComponent: DepositComponent | undefined;
  @ViewChild(SubscriptionComponent) subscriptionComponent: SubscriptionComponent | undefined;
  @ViewChild(SubscriptionOrderEntryComponent) subscriptionOrderEntryComponent: SubscriptionOrderEntryComponent | undefined;
  @ViewChild(DatafileComponent) datafileComponent: DatafileComponent | undefined;
  @ViewChild(WebAppComponent) webAppComponent: WebAppComponent | undefined;
  @ViewChild(ChegsetComponent) chegSetComponent: ChegsetComponent | undefined;
  @ViewChild(FeeAnalyzerComponent) feeAnalyzerComponent: FeeAnalyzerComponent | undefined;
  @ViewChild(CfaWebComponent) cfaWebComponent: CfaWebComponent | undefined;
  @ViewChild(ShippingComponent) shippingComponent: ShippingComponent | undefined;
  @ViewChild(CptComponent) cptComponent: CptComponent | undefined;
  @ViewChild(ScrollToDirective) dir:any;

  constructor(public orderService: OrderService, public approvalService: ApprovalService) { this._approvalService = approvalService }

  ngOnInit(): void {
    if (this.orderDetail.productDto.configSetDtos.find(i => i.configSet.includes('CFA') && !i.configSet.includes('CFAWEB'))) {
      this.hasFeeAnalyzerConfigSet = true;
    }
    if (this.orderDetail.productDto.configSetDtos.find(i => i.configSet.includes('CFAWEB'))) {
      this.hasCfaWebConfigSet = true;
    }
    if (this.orderDetail.productDto.configSetDtos.find(i => i.configSet.includes('DATAFILE'))) {
      this.hasDataFileConfigSet = true;
    }
    if (this.orderDetail.productDto.configSetDtos.find(i => i.configSet.includes('WEBAPP'))) {
      this.hasWebAppConfigSet = true;
    }
    if (this.orderDetail.productDto.configSetDtos.find(i => i.configSet.includes('SBENTR'))) {
      this.hasSubscriptionConfigSet = true;
    }
    if (this.orderDetail.productDto.configSetDtos.find(i => i.configSet.includes('CHEGSETS'))) {
      this.hasChegSetConfigSet = true;
    }
    if (this.orderDetail.productDto.configSetDtos.find(i => i.configSet.includes('DEPOSIT'))) {
      this.hasDepositConfigSet = true;
    }
    if (this.orderDetail.productDto.configSetDtos.find(i => i.configSet.includes('SHIPPING'))) {
      this.hasShippingConfigSet = true;
    }
    if (this.orderDetail.productDto.configSetDtos.find(i => i.configSet.includes('CPT'))) {
      this.hasCptConfigSet = true;
    }

  }

  closeConfigurationDialog() {
    this.orderService.recalculateFields(this.orderService.orderDto);
    if (this.orderDetail.configSetButton !== 'save') {
      // Remove the orderDetail if this it was just added, and hasn't made it through the config set earlier.
      if (this.orderDetail.id <= 0 && !this.orderDetail.configSetSaved) {
        this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== this.orderDetail);
      }
      //  Checking undefined too as configSetSaved is not set when in inquiry screen
      if (this.orderDetail.configSetSaved || this.orderDetail.configSetSaved === undefined) {
        this.webAppComponent?.onVoidLineItemCancel();
        this.datafileComponent?.onVoidLineItemCancel();
        this.chegSetComponent?.onVoidLineItemCancel();
      }
      setTimeout(()=>{
        const ele = '#tblLeftOrderDetailsGrid tbody tr';
        const len = document.querySelectorAll(ele).length;
        if((document.querySelectorAll(ele)[len-1]?.childNodes[3]?.childNodes[0]?.childNodes[0]?.childNodes[1] as HTMLInputElement)){
          (document.querySelectorAll(ele)[len-1]?.childNodes[3]?.childNodes[0]?.childNodes[0]?.childNodes[1] as HTMLInputElement).focus();
        }        
      });
    }
    if (this.orderService.orderDto.paymentMethod === 'C' && !this.orderService.orderDto.orderDetailDtos.some(item => item.productCode === '0010' || item.productCode === '0052')) {
      this.orderService.orderDto.paymentMethod = this.orderService.paymentMethodInDb;
    }
    
    this.orderDetail.configSetButton = '';
    // TODO: This was removed from the main branch.
    // this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    this.closeEvent.emit();
    this.dir!.target = "#divLeftOrderDetailsGrid";
    this.dir?.scrollToPosition();
    this.dialog.close("Cancel"); 
  }
  isTrialChecked(trial: boolean) {
    this.webAppComponent?.isTrialCheckboxChecked(trial);
  }

  save() {
    let success = true;
    this.orderDetail.configSetButton = 'save';
    if (success && this.hasSubscriptionConfigSet) {
      if(this.orderService.orderDto.orderStatus === 'R'  || this.orderService.orderDto.orderStatus === 'D' || (this.orderService.orderDto.orderStatus === '' && this.orderService.orderDto.id <= 0) || (this.orderService.orderDto.orderStatus === ' ' && this.orderService.orderDto.orderType === 'Q') || this.orderService.orderDto.orderStatus === 'P' || this.orderService.orderDto.orderStatus === 'M'){
        success = this.subscriptionOrderEntryComponent?.save() ?? true;
        }
        else{
          success = this.subscriptionComponent?.save() ?? true;
        }
    }
    if (success && this.hasFeeAnalyzerConfigSet) {
      success = this.feeAnalyzerComponent?.save() ?? true;
    }
    if (success && this.hasChegSetConfigSet) {
      success = this.chegSetComponent?.save() ?? true;
    }
    if (success && this.hasWebAppConfigSet) {
      success = this.webAppComponent?.save() ?? true;
    }
    if (success && this.hasCfaWebConfigSet) {
      success = this.cfaWebComponent?.save() ?? true;
    }    
    this.saveContinue(success);
  }
  
  saveContinue(success:boolean){
    if (success && this.hasDepositConfigSet) {
      success = this.depositComponent?.save() ?? true;
    }
    if (success && this.hasDataFileConfigSet) {
      success = this.datafileComponent?.save() ?? true;
    }
    if (success && this.hasShippingConfigSet) {
      success = this.shippingComponent?.save() ?? true;
    }

    if (success && this.hasCptConfigSet) {
      success = this.cptComponent?.save() ?? true;
    }

    if (success) {
      this.orderDetail.configSetSaved = true;
      this.orderService.recalculateFields(this.orderService.orderDto);
      this.saveConfigurationsEvent.emit();
      localStorage.removeItem('from');
      localStorage.removeItem('isEditMode');
      localStorage.removeItem('openCashDtos');

      this.dialog.close("Save");
    }
  }
}
