import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { AccountResultDto } from '../dtos/account-result-dto.model';
import { AccountsReceivableResultsDto } from '../dtos/accounts-receivable-results-dto.model';
import { OpenReceivableDto } from '../dtos/open-receivable-dto.model';
import { InvoiceDetailsDto } from '../dtos/invoice-details-dto.model';
import { OrderSearchResultsDto } from '../dtos/order-search-results-dto.model';
import { PaymentDetailDto } from '../dtos/payment-detail-dto.model';
import { ConfigService } from './config.service';
import { ModeService } from './mode.service';
import { AccountBalanceDto } from '../dtos/account-balance-dto-model';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root'
})
export class AccountsReceivableService {
  accountsReceivableResults: AccountsReceivableResultsDto = new AccountsReceivableResultsDto();
  getAccountsReceivableResultsEmitter = new ReplaySubject<AccountsReceivableResultsDto>(1);
  getInvoicesEmitter = new Subject<InvoiceDetailsDto[]>();
  getSelectedInvoicesEmitter = new ReplaySubject<InvoiceDetailsDto[]>(1);
  getReloadEmitter = new Subject<boolean>();
  selectedInvoices: InvoiceDetailsDto[] = [];
  selectedOrder = 0;
  selecedTemplatedValue:string | undefined;
  accountIdForTemplate=0;
  headerForTemplate:string|undefined;
  EmailBody='';
  EmailSubject:any;
  orderNumber = 0;
  invoiceResults: InvoiceDetailsDto[] | undefined;
  getPaymentsEmitter = new Subject<PaymentDetailDto[]>();
  paymentResults: PaymentDetailDto[] | undefined;
  accountBalanceDto: AccountBalanceDto = new AccountBalanceDto();
  invoiceFilter: any;
  private readonly _httpClient: HttpClient;
  private readonly _configService: ConfigService;
  private readonly _modeService: ModeService;
  invoiceDetailsCache!: InvoiceDetailsDto[] | null;
  invoiceDetailsCachedObservable!: Observable<InvoiceDetailsDto[]> | null;
  paymentDetailsCache!: PaymentDetailDto[] | null;
  paymentDetailsCachedObservable!: Observable<PaymentDetailDto[]> | null;
  swalAlert = new SwalAlert();

  constructor(httpClient: HttpClient, configService: ConfigService, modeService: ModeService) {
    this._httpClient = httpClient;
    this._configService = configService;
    this._modeService = modeService;
  }

  getSearchAccountReceivables(filtersObj: any): void {
    const loadingInformationMessage="Loading Account information";
    if (filtersObj["accountId"] === 0 && filtersObj["invoiceNumber"] === 0 && filtersObj["orderNumber"] !== 0) {
      const filter = [{ searchField: 'OrderNumber', searchOperator: 'equals', searchValue: filtersObj["orderNumber"] + "" }];
      const searchOperator = { filters: filter, pageNumber: 1, pageSize: 50, sort: "OrderDate descending" };
       this._httpClient.post<OrderSearchResultsDto>(`${this._configService.apiUrl}/Order/SearchOrders`, searchOperator)
        .subscribe((data: OrderSearchResultsDto) => {
          if (data.totalRecords === 0) {
            this._modeService.removeFromWaitList(loadingInformationMessage);
            this.getAccountsReceivableResultsEmitter.next(new AccountsReceivableResultsDto());
          }
          else {
            this._modeService.removeFromWaitList(loadingInformationMessage);
            filtersObj["accountId"] = data.orderSearchResults[0].accountNumber;
            this.getAccountReceivables(filtersObj);
          }
        }, (error: any) => {
          this._modeService.removeFromWaitList(loadingInformationMessage);
          console.log(error);
          this.swalAlert.alert('An error occurred while getting a list of orders.  Please try again.');
        })
    }
    else if (filtersObj["accountId"] === 0 && filtersObj["invoiceNumber"] === 0 && filtersObj["orderNumber"] === 0) {
      this._modeService.removeFromWaitList(loadingInformationMessage);
      this.getAccountsReceivableResultsEmitter.next(new AccountsReceivableResultsDto());
    }
    else {
      this._modeService.removeFromWaitList(loadingInformationMessage);
      this.getAccountReceivables(filtersObj);
    }
  }

  getAccountReceivables(filtersObj: any): void {
    const loadingInformationMessage="Loading AccountReceivables"
    this._modeService.addToWaitList(loadingInformationMessage);
    this._httpClient.post<AccountResultDto>(`${this._configService.accountingApiUrl}/getAccountReceivables`, filtersObj)
      .subscribe((data: AccountResultDto) => {
        if (data === null) {
          this._modeService.removeFromWaitList(loadingInformationMessage);
          this.getAccountsReceivableResultsEmitter.next(new AccountsReceivableResultsDto());
          return;
        }

        this.accountsReceivableResults.accountResultDto = data;

       // this._httpClient.get<OpenReceivableDto[]>(`${this._configService.accountingApiUrl}/openReceivables/${data.accountId}`)
           let url = this._configService.enablePeoplesoftApi? `${this._configService.peoplesoftApiUrl}/Accounting/GetOpenReceivables?accountId=${data.accountId}`   
          : `${this._configService.accountingApiUrl}/openReceivables/${data.accountId}`;    
            this._httpClient.get<OpenReceivableDto[]>(url)
            .subscribe(result => {
            this._modeService.removeFromWaitList(loadingInformationMessage);
            this.accountsReceivableResults.accountsReceivableResults = result;
            this.getAccountsReceivableResultsEmitter.next(this.accountsReceivableResults);
          });

        filtersObj["accountId"] = data.accountId;
        this.getInvoices(filtersObj);
        this.getPaymentDetails(filtersObj);
      }, (error: any) => {
        this._modeService.removeFromWaitList(loadingInformationMessage);
        console.log(error);
        this.swalAlert.alert('An error occurred while getting a list of Account Receivables.  Please try again.');
      });
  }

  reInvoiceLoad() {
    this.getInvoices(this.invoiceFilter);
  }

  // TODO: We need to refactor this code.
  getInvoices(filtersObj: any): void {
   const loadingInvoicesMsg="Loading Invoices";
    this._modeService.addToWaitList(loadingInvoicesMsg);
    this.invoiceFilter = filtersObj;
    let url = this._configService.enablePeoplesoftApi ? `${this._configService.peoplesoftApiUrl}/Accounting/GetInvoiceDetails?accountId=${filtersObj["accountId"]}` : `${this._configService.accountingApiUrl}/getinvoiceDetails/${filtersObj["accountId"]}`; 
    this.invoiceDetailsCachedObservable = this._httpClient.get<InvoiceDetailsDto[]>(url)
    .pipe(
      tap((response: InvoiceDetailsDto[]) => {
        this.invoiceDetailsCache = response;
      }),
      share(),
      finalize(() => {
        this.invoiceDetailsCache = null;
        this.invoiceDetailsCachedObservable = null;
      })
    );

   const observable = this.invoiceDetailsCachedObservable;
    observable.subscribe((data: InvoiceDetailsDto[]) => {
      this.invoiceResults = data;
      this.getInvoicesEmitter.next(this.invoiceResults);
      this._modeService.removeFromWaitList(loadingInvoicesMsg);
    }, (error: any) => {
      this._modeService.removeFromWaitList(loadingInvoicesMsg);
      console.log(error);
      this.swalAlert.alert('An error occurred while getting the invoice details');
    });
  }

  getPaymentDetails(filtersObj: any): void {
    const loadingPaymentDetails="Loading PaymentDetails";
    this._modeService.addToWaitList(loadingPaymentDetails);
    let observable: Observable<PaymentDetailDto[]>;

    if (this.paymentDetailsCache) {
      observable = of(this.paymentDetailsCache);
    }
    else if (this.paymentDetailsCachedObservable) {
      observable = this.paymentDetailsCachedObservable;
    }
    else {
      let paymentUrl = this._configService.enablePeoplesoftApi ? `${this._configService.peoplesoftApiUrl}/Accounting/GetPayments?accountId=${filtersObj["accountId"]}` : `${this._configService.accountingApiUrl}/paymentDetails/${filtersObj["accountId"]}`;
      this.paymentDetailsCachedObservable = this._httpClient.get<PaymentDetailDto[]>(paymentUrl)
        .pipe(
          tap((response: PaymentDetailDto[]) => {
            this.paymentDetailsCache = response;
          }),
          share(),
          finalize(() => {
            this.paymentDetailsCache = null;
            this.paymentDetailsCachedObservable = null;
          })
        );

      observable = this.paymentDetailsCachedObservable;
    }

    observable.subscribe((data: PaymentDetailDto[]) => {
      this.paymentResults = data;
      this.getPaymentsEmitter.next(this.paymentResults);
      this._modeService.removeFromWaitList(loadingPaymentDetails);
    }, (error: any) => {
      this._modeService.removeFromWaitList(loadingPaymentDetails);
      console.log(error);
      this.swalAlert.alert('An error occurred while getting the payment details');
    });
  }

  updateSelectedInvoicesList(invoiceList: InvoiceDetailsDto[], reload: boolean = false) {
    this.selectedInvoices = invoiceList ;
    this.getSelectedInvoicesEmitter.next(this.selectedInvoices);

    if (reload) {
      this.getReloadEmitter.next(reload);
    }
  }

  updateSelectedOrder(orderNumber: number) {
    this.selectedOrder = orderNumber;
  }

  filterInvoices(invoice: string, cm: string) {
    let result: InvoiceDetailsDto[] = [];
    const imAll = invoice === '1';
    const cmAll = cm === '1';
    const imOpen = invoice === '2';
    const cmOpen = cm === '2';
    const cmExclude = cm === '3';

    if (imOpen && cmOpen) {
      result = this.invoiceResults?.filter(inv => inv.balance !== 0 && inv.status !== 'V')!;
    }
    else if (imOpen && cmAll) {
      result = this.invoiceResults?.filter(inv => (inv.invoiceAmount > 0 && inv.balance !== 0 && inv.status !== 'V') || (inv.invoiceAmount < 0))!;
    }
    else if (imAll && cmOpen) {
      result = this.invoiceResults?.filter(inv => (inv.invoiceAmount < 0 && inv.balance !== 0) || (inv.invoiceAmount > 0))!;
    }
    else if (imOpen && cmExclude) {
      result = this.invoiceResults?.filter(inv => (inv.invoiceAmount > 0 && inv.balance !== 0 && inv.status !== 'V'))!;
    }
    else{
      result = this.proceedWithInvoiceFilter(invoice, cm);
    }
    this.getInvoicesEmitter.next(result);
  }

  proceedWithInvoiceFilter(invoice: string, cm: string): InvoiceDetailsDto[]{
    const imAll = invoice === '1';
    const cmAll = cm === '1';
    const cmOpen = cm === '2';
    const imExclude = invoice === '3';
    const cmExclude = cm === '3';
    if (imExclude && cmOpen) {
      return this.invoiceResults?.filter(inv => (inv.invoiceAmount < 0 && inv.balance !== 0))!;
    }
    else if (imAll && cmExclude) {
      return this.invoiceResults?.filter(inv => (inv.invoiceAmount > 0))!;
    }
    else if (imExclude && cmAll) {
      return this.invoiceResults?.filter(inv => (inv.invoiceAmount < 0))!;
    }
    else if (imExclude && cmExclude)
    {
      return [];
    }
    else {
      return this.invoiceResults!;
    }
  }

  async getAccountBalanceDetails(accountId: number): Promise<AccountBalanceDto | null> {
    return new Promise(resolve => {
      const message = 'Loading Account Balance';
      this._modeService.addToWaitList(message);
      this._httpClient.get<AccountBalanceDto>(`${this._configService.accountingApiUrl}/getAccountBalance?accountId=${accountId}`).subscribe(data => {
        this._modeService.removeFromWaitList(message);
        resolve(data);
      }, error => {
        this._modeService.removeFromWaitList(message);
        this.swalAlert.alert('Error getting Order Approval Defaults');
        resolve(null);
      });
    });
  }
}
