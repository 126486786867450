import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  getErrorMessage(error: HttpErrorResponse): string {
    if (error?.message) {
      return error?.message;
    } else if ((error?.error as string).startsWith('System.ArgumentException: ')) {
      let errorMessage = (error?.error as string).replace('System.ArgumentException: ', '');
      errorMessage = errorMessage.substring(0, errorMessage.indexOf('\r\n'));
      return errorMessage;
    } else {
      return error.message;
    }
  }
}
