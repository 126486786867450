import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { ModeService } from '../../services/mode.service';
import { Mode } from '../../enums/mode.enum';
import { OrderService } from '../../services/order.service';
import { BillToService } from '../../services/bill-to.service';
import { Subscription } from 'rxjs';
import { IUITKSelectItemProps } from '@uitk/angular';
import { promise } from 'protractor';

@Component({
  selector: 'app-bill-to',
  templateUrl: './bill-to.component.html',
  styleUrls: ['./bill-to.component.css'],
})
  
export class BillToComponent implements OnInit {
  specialityLabel: string = 'Speciality:';
  visibleTextValue = 'Refreshing Data';
  Mode = Mode;
  editionYear = 0;
  configIsReadySubscription: Subscription | undefined;
  @Input() isnavigateFromBatchReturn:boolean =false;

  constructor(
    public readonly orderService: OrderService,
    public readonly billToService: BillToService,
    private readonly configService: ConfigService,
    public readonly modeService: ModeService
  ) {}

  ngOnInit(): void {
    this.configIsReadySubscription = this.configService.configIsReady.subscribe(() => {
      if (!this.configService.reference) {
        throw new Error('References are not loaded.');
      }
      this.billToService.specialtiesList = this.configService.getPickList("SPECIALTY");
     });
        //Below condition is to check for naviagation from Batch Return
    if(this.isnavigateFromBatchReturn === false)
    {
      this.orderService.orderIsReady.subscribe(() => {
        if (this.billToService.specialtiesList.filter(x => x.id === this.orderService.orderDto.billToDto.specialties[0]).length > 0) {
          this.billToService.specialties = this.billToService.specialtiesList.filter(x => x.id === this.orderService.orderDto.billToDto.specialties[0])[0].label;
        }

        const specialtiesLabel : string[] = [];
        this.orderService.orderDto.billToDto.specialties.forEach(element => {
          if(this.billToService.specialtiesList.some(a => a.id === element))
          {
            specialtiesLabel.push(this.billToService.specialtiesList.filter( a => a.id === element)[0].label);
          }
        });
        this.billToService.tooltipContent = specialtiesLabel;
      });
    }
  }

  


  navigateToSFViewAccount() {
    window.open(
      `${this.configService.salesforceUrl}/apex/ViewAccount?id=${this.orderService.orderDto.billToDto.salesforceId}`
    );
  }

  navigateToSFEditAccount() {
    window.open(
      `${this.configService.salesforceUrl}/apex/Accountform?id=${this.orderService.orderDto.billToDto.salesforceId}`
    );
  }

  async refreshBillTo(): Promise<void> {
    const accountId = this.orderService.orderDto.billToDto.id;
    const editionYear = new Date().getFullYear();
    await this.billToService.getBillToInformationAsync(accountId, editionYear);
    this.billToService.reloadBillToDto();
  }
}
