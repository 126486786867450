<div class="uitk-l-grid">
  <div class="uitk-l-grid__row uitk-l-grid__row--no-gutter">
      <div class="uitk-l-grid__row uitk-l-grid__row--no-gutter" style="width: 100%;">
        <div class="uitk-l-grid__col--3">
          <div id="leftGrid" class="uitk-l-grid">
            <div class="uitk-l-grid__row">
              <div class="uitk-l-grid__col">
                <div class="demo-l-grid-col-content">
                  <div class="css-t7viak left-justify fill-width">
                    <app-order-by id="order-by" ></app-order-by>
                  </div>
                </div>
              </div>
            </div>
            <div class="uitk-l-grid__row">
              <div class="uitk-l-grid__col">
                <div class="demo-l-grid-col-content">
                  <div class="css-t7viak left-justify fill-width uitk-u-margin-top--m">
                    <app-ship-to id="ship-to" ></app-ship-to>
                  </div>
                </div>
              </div>
            </div>
            <div class="uitk-l-grid__row">
              <div class="uitk-l-grid__col">
                <div class="demo-l-grid-col-content">
                  <div class="css-t7viak left-justify fill-width uitk-u-margin-top--m">
                    <app-bill-to id="bill-to" ></app-bill-to>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uitk-l-grid__col--9">
          <div class="left-justify" style="background-color: white;">
            <app-return-detail id="returnDetail"></app-return-detail>
          </div>
        </div>
      </div>

  </div>
  <div class="divPopUp" *ngIf="hideReturnsPopUp">
    <app-returns-action> </app-returns-action>
  </div>
