import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Mode } from '../enums/mode.enum';
import { EmailInputDto } from '../dtos/email-input-dto.model';
import { QuoteCFDEmailDto } from '../dtos/quote-cfd-email-dto.model';
import { ConfigService } from './config.service';
import { ModeService } from './mode.service';
import { DeclinedCCDto } from '../dtos/retry-cc-dto';

@Injectable({
  providedIn: 'root'
})

export class EmailService {
  private readonly _httpClient: HttpClient;
  private readonly _modeService: ModeService;
  private readonly _configService: ConfigService;
  orderByConfirmationEmails:string |null = null;

  constructor(httpClient: HttpClient, modeService: ModeService, configService: ConfigService) {
    this._httpClient = httpClient;
    this._modeService = modeService;
    this._configService = configService;
  }

  sendEmail(emailInputDto: EmailInputDto): Observable<string> {
    this._modeService.mode = Mode.Wait;
    return this._httpClient.post<string>(`${this._configService.apiUrl}/Order/SendEmail`, emailInputDto);
  }

  sendCfdEmail(quoteCfdEmailDto: QuoteCFDEmailDto): Observable<string> {
    this._modeService.mode = Mode.Wait;
    return this._httpClient.post<string>(`${this._configService.apiUrl}/order/sendcfdemail`, quoteCfdEmailDto);
  }

  sendDeclinedCCEmail(declinedCCDto: DeclinedCCDto){
    this._modeService.mode = Mode.Wait;
    return this._httpClient.post(`${this._configService.apiUrl}/worklist/SendDeclinedCCEmail`, declinedCCDto);
  }
}
