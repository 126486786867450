export class SubscriptionSearchDto {
  accountId = 0;
  contactId = 0;
  orderId = 0;
  productCode = '';
  subscriptionNumber = 0;
  sort = 'default';
  pageNumber = 1;
  pageSize = 10000;
}
