import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm } from '@angular/forms';
import { IUITKColumnState, IUITKSelectItemProps, UITKHeadingLevel, UITKSortDirection, UITKTableDataSource } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Dialog } from 'src/app/helpers/dialog';
import { AccountSearchFinalDto, AccountSearchInputDto } from 'src/app/dtos/account-search-input-dto';
import { AccountSearchResultDto } from 'src/app/dtos/account-search-result-dto';
import { ApprovalService } from 'src/app/services/approval.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { BillToService } from 'src/app/services/bill-to.service';
import { ConfigService } from 'src/app/services/config.service';
import { ContactService } from 'src/app/services/contact.service';
import { ModeService } from 'src/app/services/mode.service';
import { OrderService } from 'src/app/services/order.service';
import * as states from 'src/assets/json/states_USA.json';
import { AccountInformationComponent } from '../order-entry/account-information/account-information.component';
@Component({
  selector: 'app-advanced-account-search',
  templateUrl: './advanced-account-search.component.html',
  styleUrls: ['./advanced-account-search.component.css']
})
export class AdvancedAccountSearchComponent implements OnInit,OnDestroy {
  //@Input('dialog') dialog = { show: false };
  @Input() AccountSearch!: Dialog<string>;  
  private readonly _configService: ConfigService;
  private readonly _titleCasePipe: TitleCasePipe;
  private readonly _orderService: OrderService;
  private readonly _moderService:ModeService;
  private readonly _authService:AuthorizationService;
  private readonly _approvalService:ApprovalService;
  private readonly _billToService:BillToService;
  private readonly _contactSerivce:ContactService; 
  headingLevel = UITKHeadingLevel; 
  isPanelOpen:boolean = true;
  tableHeader = [
    { name:  'Status', id: 'accountStatus',width:'7%' },
    { name:  'ID', id: 'accountId',width:'7%' },
    { name:  'Name', id: 'accountName',width:'16%' },
    { name:  'Type', id: 'accountType',width:'6%' },
    { name:  'Status', id: 'contactStatus',width:'8%' },
    { name:  'ID', id: 'contactId',width:'8%' },
    { name:  'Name', id: 'contactName',width:'14%' },
    { name:  'Found Text', id: 'foundText',width:'20%' },
    { name:  'Location', id: 'location',width:'16%' },
 ];
  country:any;
  stateInfo:any; 
  sortColumn = 'accountId';
  visible = false;
  showLoaderFlag = false;  
  myNotificationErrorContent:string = "Please Enter any of the fileds to search";
  sortDirection = UITKSortDirection.ASC;
  stateList: IUITKSelectItemProps[] = [];
  countries: IUITKSelectItemProps[] = [];
  configIsReadySubscription: Subscription | undefined;
  stateNonUS:any;
  stateUS:any;
  accountInfoComponentObj:any;
  accountSearchObj:AccountSearchInputDto = new AccountSearchInputDto();
  accountSearchFinalObj:AccountSearchFinalDto = new AccountSearchFinalDto();
  accountForm = new UntypedFormGroup({
    accountId:new UntypedFormControl(),
    accountName:new UntypedFormControl(),
    street:new UntypedFormControl(),
    city: new UntypedFormControl(),
    state:new UntypedFormControl(),
    country:new UntypedFormControl(),
    zipCode:new UntypedFormControl(),
    phone:new UntypedFormControl()
  });
  searchList = new UITKTableDataSource<AccountSearchResultDto>([]);
  constructor(configService: ConfigService,titleCasePipe: TitleCasePipe,orderService:OrderService,
    modeService:ModeService,authService:AuthorizationService,approvalService:ApprovalService,billToService:BillToService,
    contactService:ContactService) { 
    this._configService = configService;
    this._titleCasePipe = titleCasePipe;
    this._orderService = orderService;
    this._moderService = modeService;
    this._authService = authService;
    this._approvalService = approvalService;
    this._billToService = billToService;
    this._contactSerivce = contactService;
  }
  
  ngOnInit(): void {  
    this.countries = [...this._configService.reference!.countryDtos]
      .sort((a, b) => a.countryName < b.countryName ? -1 : 1)
      .map(c => ({ id: c.alpha2Code, label: this._titleCasePipe.transform(c.countryName), value: c.alpha2Code }));
    this.accountSearchObj.country = this.countries[231];
    this.stateList = JSON.parse(JSON.stringify((states as any).default));
    this.stateList.forEach(element => {
      element.disabled = false;
    });
    this.accountInfoComponentObj = new AccountInformationComponent(this._orderService,this._moderService,this._configService,this._billToService,this._authService,this._contactSerivce,this._approvalService);
  }  
  clearForm(){
    this.accountSearchObj = new AccountSearchInputDto();
    this.searchList.data = [];
  }
  closepopup(){
    this.AccountSearch.close('close');
  }  
  ngOnDestroy() {
    this.configIsReadySubscription?.unsubscribe();
  }
  validateCityStateSearch(): boolean{
    if(!(this.accountSearchObj.accountName || this.accountSearchObj.streetAddress || this.accountSearchObj.zipCode)){
      this.myNotificationErrorContent = "Please Enter Account Name or Street or zip code to search";
      this.visible = true;
      return false;
    }           
    return true;      
  }
  validateCountrySearch(): boolean{
    if(!(this.accountSearchObj.accountName || this.accountSearchObj.streetAddress || this.accountSearchObj.phone)){
      this.myNotificationErrorContent = "Please Enter Account Name or Street or Phone to search";
      this.visible = true;
      return false;
    }           
    return true;    
  }
  validateSearchForm(): boolean{
    if(!(this.accountSearchObj.accountId || this.accountSearchObj.accountName || this.accountSearchObj.zipCode || this.accountSearchObj.phone)){
      if(this.accountSearchObj.city || this.accountSearchObj.state){
        if(!this.validateCityStateSearch()){
          return false;
        }
      }
      if(this.accountSearchObj.country?.value){
       if(!this.validateCountrySearch()){
          return false;
       }
      }
     }   
     return true;
  }
  setSearchAddressInput(): void {
    this.accountSearchFinalObj.streetAddress = this.accountSearchObj.streetAddress ? this.accountSearchObj.streetAddress : null;
    this.accountSearchFinalObj.city = this.accountSearchObj.city ? this.accountSearchObj.city : null;
    this.accountSearchFinalObj.country = this.accountSearchObj.country.value ? this.accountSearchObj.country.value : null;
    this.accountSearchFinalObj.zipCode = this.accountSearchObj.zipCode ? this.accountSearchObj.zipCode : null;  
    this.accountSearchFinalObj.phone = this.accountSearchObj.phone ? this.accountSearchObj.phone?.replace(/[\/-]/g, '') ?? null:null;    
  }

  getAccountsList(): void {
    this._orderService.getAccountsList(this.accountSearchFinalObj).subscribe((res:any)=>{
      this.isPanelOpen = false;              
      this.searchList.data = res;
      this.showLoaderFlag = false;
      if(this.searchList.data.length === 1){  
       this.onAccountIdClick(this.searchList.data[0].accountId);
      }
  },
  error=>{
      this.showLoaderFlag = false;
      if(error.error.Message && error.error.Message.indexOf("Results exceeding 500") > -1){
       this.myNotificationErrorContent ="More than 500 results found, please add more info in the filters.";
       this.visible = true;
      }               
  });
  }
  search(form:NgForm){    
    this.accountSearchObj.state = this.accountSearchObj.country?.value === 'US' ?
    (this.accountSearchObj.stateUS?.value ?? null) : this.accountSearchObj.stateNonUS??null;
    this.accountSearchObj.country.value = this.accountSearchObj.country.value === ""? null:this.accountSearchObj.country.value;
   
    if((this.accountSearchObj.accountId  || this.accountSearchObj.accountName || this.accountSearchObj.phone || this.accountSearchObj.streetAddress
        || this.accountSearchObj.zipCode || this.accountSearchObj.country.value || this.accountSearchObj.city || this.accountSearchObj.state) && form.valid){
         this.validation();
        }
        else{
          this.myNotificationErrorContent ="Filter information must be added to complete search";
          this.visible = true;
        }       
  }
  validation() {
    if(!this.validateSearchForm()){
      return;
    }
    this.showLoaderFlag = true;
    this.searchList.data = [];
    this.accountSearchFinalObj.state = this.accountSearchObj.country?.value === 'US' ?
    (this.accountSearchObj.stateUS?.value ?? null) : this.accountSearchObj.stateNonUS??null;      
    this.accountSearchFinalObj.accountId = this.accountSearchObj.accountId ?? 0;
    this.accountSearchFinalObj.accountName = this.accountSearchObj.accountName ?this.accountSearchObj.accountName: null;
    this.setSearchAddressInput();
    this.getAccountsList();
  }

  onOpenChange(event:any){
    this.isPanelOpen = !this.isPanelOpen;
  }
 
  onSortChange(event: IUITKColumnState) {
      this.sortColumn = event.column;
      this.sortDirection = event.direction;
      this.searchList.data.forEach((dataVal:any)=>{
        dataVal[event.column] =  dataVal[event.column];
        if(dataVal[event.column] == null){
          dataVal[event.column] = "";
        }
      })
      this.searchList.data = this.searchList.sortData(this.searchList.data, event.direction, event.column);
  }
  onAccountIdClick(accountId:any){   
    this._orderService.orderDto.billToDto.id = accountId;    
    this.AccountSearch.close('close');
    this.accountInfoComponentObj?.onAccountIdChange(accountId);
    setTimeout(()=>{
      (document.getElementById("accountId") as HTMLElement).focus();
      (document.getElementById('accountId') as HTMLElement).blur();
     
    });
  }
  getAccountNameErrorMessage(formInput:any){  
    if(formInput.errors.pattern){    
      return 'Character '+formInput.value.match(/[^a-zA-Z0-9,.&\/\-\'\(\)\*\@#+\:\[\]\`]/g) +' is not allowed';
    }
    return '';
  }
  getStreetErrorMessage(formInput:any){
    if(formInput.errors.pattern){    
      return 'Character '+formInput.value.match(/[^A-Z a-z 0-9 #.,]/g) +' is not allowed';
    }
    return '';
  }
  getCityErrorMessage(formInput:any){
    if(formInput.errors.pattern){    
      return 'Character '+formInput.value.match(/[^A-Z a-z 0-9.\-]/g) +' is not allowed';
    }
    return '';
  }
  getPhoneErrorMessage(formInput:any){
    if(formInput.errors.pattern){    
      return 'Character '+formInput.value.match(/[^0-9\-\/]/g) +'is not allowed';
    }
    return '';
  }
  getZipErrorMessage(formInput:any){
    if(formInput.errors.pattern){    
      return 'Character '+formInput.value.match(/[^0-9\-]/g) +' is not allowed';
    }
    return '';
  }
  navigateToSFAddAccount() {
    window.open(
      `${this._configService.salesforceUrl}/apex/Accountform?retURL=%2F001%2Fo&save_new=1&sfdc.override=1`
    );
  }
}
