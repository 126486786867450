import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { ScrollToDirective } from '../../directives/scrollTo.directive';
import { OrderDto } from '../../dtos/order-dto.model';
import { Mode } from '../../enums/mode.enum';
import { Dialog } from '../../helpers/dialog';
import { EmailService } from '../../services/email.service';
import { ModeService } from '../../services/mode.service';

@Component({
  selector: 'app-shipment-confirmation-email',
  templateUrl: './shipment-confirmation-email.component.html',
  styleUrls: ['./shipment-confirmation-email.component.css']
})
export class ShipmentConfirmationEmailComponent {
  private readonly _emailService: EmailService;
  public readonly _orderService: OrderService;
  private readonly _modeService: ModeService;
  private readonly _elementRef: ElementRef;
  @Input() dialog = new Dialog<string>();
  orderDto: OrderDto = new OrderDto();
  orderedByContactName = '';
  shipToContactName = '';
  orderedByContactEmail = '';
  shipToContactEmail = '';
  orderOtherEmail = '';
  shipmentOtherEmail = '';
  loggedInUser = '';
  shiploggedInUser = '';
  loggedInUserName = '';
  shipmentOrderedByCheckbox = false;
  shipmentShipToCheckbox = false;
  shipmentOtherCheckbox = false;
  shiploggedInUserCheckbox = false
  shipmentConfirmationEmails = '';
  confirmationEmailsList: string[] = [];

  emailPattern = /^^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](\s?)(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
  @ViewChild(ScrollToDirective) dir: any;
  constructor(emailService: EmailService,
    orderService: OrderService,
    modeService: ModeService,
    elementRef: ElementRef) {
    this._emailService = emailService;
    this._orderService = orderService;
    this._modeService = modeService;
    this._elementRef = elementRef;
  }

  ngOnInit(): void {
    this.shipmentOrderedByCheckbox = false;
    this.shipmentShipToCheckbox = false;
    this.shiploggedInUserCheckbox = false;
    this.shipmentOtherCheckbox = false;
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
    this.orderDto = JSON.parse(JSON.stringify(this._orderService.orderDto)) as OrderDto;
    this.orderedByContactEmail = this.orderDto.orderByDto.email;
    this.loggedInUserName = currentUser.profile.name;
    this.shipToContactEmail = this.orderDto.shipToDto.email;
    this.confirmationEmailsList = this.orderDto.shipToDto.confirmationEmails?.split(';') ?? [];
    this.loggedInUser = currentUser.profile.email;
    this.shiploggedInUser = currentUser.profile.email;
    if (this.confirmationEmailsList.indexOf(this.orderedByContactEmail) > -1) {
      this.shipmentOrderedByCheckbox = true;
    }
    if (this.confirmationEmailsList.indexOf(this.shipToContactEmail) > -1) {
      this.shipmentShipToCheckbox = true;
    }
    if (this.confirmationEmailsList.indexOf(this.loggedInUser) > -1) {
      this.shiploggedInUserCheckbox = true;
    }
    this.shipmentOtherEmail = this.confirmationEmailsList.filter(email => email !== this.orderedByContactEmail && email !== this.shipToContactEmail && email !== this.loggedInUser).join(';');
    if (this.shipmentOtherEmail != null && this.shipmentOtherEmail.length > 0) {
      this.shipmentOtherCheckbox = true;
    }

    this.orderedByContactName = (this.orderDto.orderByDto.firstName ?? '') + ' ' + this.orderDto.orderByDto.lastName ?? '';
    this.shipToContactName = (this.orderDto.shipToDto.firstName ?? '') + ' ' + this.orderDto.shipToDto.lastName ?? '';
  }

  saveEmail(): void {
    this.updateShipmentConfirmationEmailList();
    if (this.shipmentConfirmationEmails !== '' && this.shipmentConfirmationEmails !== null) {
      this._orderService.orderDto.shipToDto.confirmationEmails = this.shipmentConfirmationEmails;
    }
    else {
      this._orderService.orderDto.shipToDto.confirmationEmails = null;
    }
    this.dialog.close("Save");
  }

  updateShipmentConfirmationEmailList() {
    const shipmentConfirmationEmailsList = [];
    if (this.shipmentOrderedByCheckbox && this.orderedByContactEmail?.length > 0)
      shipmentConfirmationEmailsList.push(this.orderedByContactEmail);
    if (this.shipmentShipToCheckbox && this.shipToContactEmail?.length > 0 && shipmentConfirmationEmailsList.find(email => email === this.shipToContactEmail) === undefined)
      shipmentConfirmationEmailsList.push(this.shipToContactEmail);
    if (this.shiploggedInUserCheckbox && this.loggedInUser?.length > 0)
      shipmentConfirmationEmailsList.push(this.loggedInUser);
    if (this.shipmentOtherCheckbox && this.shipmentOtherEmail?.length > 0 && shipmentConfirmationEmailsList.find(email => email === this.shipmentOtherEmail) === undefined)
      shipmentConfirmationEmailsList.push(this.shipmentOtherEmail);
    this.shipmentConfirmationEmails = shipmentConfirmationEmailsList.join(';');
  }

  setCheckbox() {
    this.shipmentOtherCheckbox = this.shipmentOtherEmail !== '';
  }
  closeModal() {
    this.dir!.target = "#divLeftOrderDetailsGrid";
    this.dir?.scrollToPosition();
    this.dialog.close("Cancel");
  }
}
