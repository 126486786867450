<div *ngIf="dialog.show" class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" uitkId="shipmentHistoryDialog" [closeBtn]='true'>
    <uitk-dialog-header>
        <div cdkDragHandle>
        Shipment History
        </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
        <div class="uitk-c-table--responsive">
            <table uitkTable [dataSource]="dataSource" class="uitk-c-table" id="shipment-history-table">
                <thead class="uitk-c-table__head">
                    <tr uitkTableSort #sortTable="uitkTableSort">
                        <th uitkTableSortColumn [column]="header.id" (sortChange)="onSortChange($event)" *ngFor="let header of tableHeaders" scope="col" >
                            {{ header.name }}
                        </th>
                    </tr>
                </thead>
                <tbody id="row-data" class="uitk-c-table__body">
                    <ng-container *uitkTableRowBody="let record">
                        <tr class="uitk-c-table__row">
                            <td>{{ record.productCode }}</td>
                            <td>{{ record.productDescription }}</td>
                            <td>{{ record.shippedQuantity }}</td>
                            <td>{{ record.shipDate | date: 'MM/dd/yyyy' }}</td>
                            <td *ngIf="record.trackingNumber?!record.trackingNumber.startsWith('1Z'):' '"> {{ record.trackingNumber }}</td>  
                            <td *ngIf="record.trackingNumber?record.trackingNumber.startsWith('1Z'):' '"> <a class="link" (click)="openUpsTracking(record.trackingNumber)">{{ record.trackingNumber }}</a></td>
                            </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </uitk-dialog-content>
</uitk-dialog>
    </div>
</div>