import {
  Directive,
  ElementRef,
  Input
} from "@angular/core";
import { BBLogService } from '../services/bb-log.service';
import { BBLogDto } from "../dtos/bb-log-dto.model";


@Directive({
  selector: "[bbLogTable]"
})
export class BBLogDirective {
  @Input() bbLogPrimaryId = 0;
  @Input() bbLogTable = "";
  @Input() bbLogColumn = "";
  bbLogInput : BBLogDto = new BBLogDto() ;

  constructor(
    public bbLogService:BBLogService,
    public elementRef: ElementRef<HTMLElement>
  ) {}

  private readonly getBBLogData = (event$: MouseEvent) => {
    event$.preventDefault();
    this.getData();
  };

  public ngOnInit() {
    this.elementRef.nativeElement.addEventListener(
      "contextmenu",
      this.getBBLogData
    );
  }

  public ngOnDestroy() {
    this.elementRef.nativeElement.removeEventListener(
      "contextmenu",
      this.getBBLogData
    );
  }

  public getData() {
     this.bbLogInput.columnName = this.bbLogColumn;
     this.bbLogInput.tableName =  this.bbLogTable;
     this.bbLogInput.primaryId = this.bbLogPrimaryId;
     this.bbLogService.getLogData(this.bbLogInput);

  }
}
