import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationService } from '../services/authorization.service';

@Directive({
  selector: '[appShowResource]'
})
export class ShowResourceDirective {
  @Input() set appShowResource(resourceName: string) {
    if (this.authorizationService.hasResource(resourceName)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }

  constructor(
    private readonly authorizationService: AuthorizationService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>
  ) { }
}
