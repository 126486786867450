<div class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="_orderService.shippingDialogModal.show"
             uitkId="shippingDialog" [closeBtn]=false
             [triggerElementId]="_orderService.shippingDialogModalTriggerEle"
             id="configSetsDialog"
             (onModalHidden)="closeModal()">
  <uitk-dialog-header *ngIf="_orderService.orderDto.shipmentMethod !== 'UPS-INT'">
    <div cdkDragHandle>
      Apply Domestic Shipping Charges
    </div>
  </uitk-dialog-header>
  <uitk-dialog-header *ngIf="_orderService.orderDto.shipmentMethod === 'UPS-INT'">
    <div cdkDragHandle>
      Apply International Shipping Charges
    </div>
  </uitk-dialog-header>

  <uitk-dialog-content scrollToTarget>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <uitk-banner-notification [show]="showErrorMessage" uitkId="errorMessage" [variant]="errorVariant">
            <h1 uitkBannerHeading>{{ errorMessage }}</h1>
          </uitk-banner-notification>
          </div>
        </div>
      <div class="row">
            <div class="col-lg-12">
              <div class="row mt-4" id="shippingChargeMessage" *ngIf = "showShippingChargeMessage">      
                <b class="shippingChargeMessage">Shipping charges on order changed from ${{_orderService.previousShippingCharge}} to ${{_orderService.currentShippingCharge}}</b>
              </div>
              <div class="row mt-4">
                <label class="col-lg-10" *ngIf="_orderService.orderDto.shipmentMethod !== 'UPS-INT'" uitkLabel style="margin-top: 10px;">System Calculated Shipping Charges</label>
                <label class="col-lg-10" *ngIf="_orderService.orderDto.shipmentMethod === 'UPS-INT'" uitkLabel style="margin-top: 10px;">Enter Calculated UPS International Shipping Charges</label>
                <div class="col-lg-2">
                  <uitk-form-field>
                    <input type="text" uitkInput [(customModel)]="licensePricing.listPrice" name="upsShippingCharges"
                           id="upsShippingCharges-{{ orderDetail.id }}" [disabled]=true (blur)="onChangeShippingCharges()"
                           style="width: 100px;" appPriceFormat />
                  </uitk-form-field>
                </div>
              </div>
              <div class="row mb-4" *ngIf="_orderService.orderDto.shipmentMethod === 'UPS-INT'">
                <label class="col-lg-12" uitkLabel style="color:red">
                  **Please contact your manager OR calculate via UPS.com
                </label>
              </div>
              <div class="row mb-4 mt-4">
                <label class="col-lg-10" uitkLabel style="margin-top: 10px;">Apply Discount of</label>
                <div class="col-lg-2">
                  <uitk-form-field>
                    <input type="text" uitkInput [(customModel)]="licensePricing.discount" name="upsShippingChargesDiscount"
                           id="upsShippingChargesDiscount-{{ orderDetail.id }}"
                           (blur)="onChangeShippingChargesDiscount()" style="width: 100px;" appDiscountFormat />
                  </uitk-form-field>
                </div>
              </div>
              <div class="row">
                <label class="col-lg-10" uitkLabel style="margin-top: 10px;">Total Shipping Charges</label>
                <div class="col-lg-2">
                  <uitk-form-field>
                    <input type="text" uitkInput [(customModel)]="licensePricing.unitPrice" name="upsTotalShippingCharges"
                           id="upsTotalShippingCharges-{{ orderDetail.id }}"
                           (blur)="onChangeTotalShippingCharges()" style="width: 100px;" appPriceFormat />
                  </uitk-form-field>
                </div>
              </div>
              <div class="row">
                <br /><br />
                <hr class="col-lg-12 color-orange my-0" style="height: 2px; opacity: 1;">
              </div>
            </div>
          </div>
        </div>
</uitk-dialog-content>

  <uitk-dialog-actions>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" id="upsShippingChargesOkButton" style="position:relative" (click)="ok()" scrollToTarget target="#divLeftOrderDetailsGrid">
            Ok
          </button>
        </div>
      </div>
    </div>
</uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>