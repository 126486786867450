<div *ngIf="dialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" uitkId="editSubscriptionDialog" triggerElementId="editSubscription"
             [closeBtn]=false>
  <uitk-dialog-header>
    <div cdkDragHandle>
    Subscription Edit <uitk-notification uitkId="followUpDateValidation" [variant]="'error'"
                                         [show]="showReactivateSubDateError">
      <span>{{ followUpDateValidationMessage }}</span>
    </uitk-notification>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content *ngIf=" !isSaveSuccess">

    <div id="loadingSpinner" class="uitk-l-grid" [hidden]="modeService.mode !== Mode.Wait">
      <uitk-loading-indicator uitkId="spinner" [visibleText]="visibleTextValue" spinnerSize="small">
      </uitk-loading-indicator>
    </div>

    <div [hidden]="modeService.mode === Mode.Wait">
      <div class="container-fluid">
        <div class="row d-flex">
          <div class="col align-self-center">
            <label uitkLabel class="grey-text">Account ID</label>
            <div class="float-end">
              <uitk-form-field>
                <input uitkInput id="accNum" class="textbox text-end" type="text" style="width: 200px" disabled
                       [(ngModel)]="subscriptionDto.accountId">
              </uitk-form-field>
            </div>
          </div>
          <div class="col align-self-center">
            <label uitkLabel class="grey-text">Order #</label>
            <div class="float-end">
              <uitk-form-field>
                <input uitkInput id="soNum" class="textbox" type="text" style="width: 200px" disabled
                       [(ngModel)]="subscriptionDto.orderId" />
              </uitk-form-field>
            </div>
          </div>
        </div>
        <div class="row d-flex">
          <div class="col align-self-center">
            <label uitkLabel class="grey-text">Status</label>
            <div class="float-end" bbLogTable="Subscription" bbLogColumn="SubscriptionStatus" [bbLogPrimaryId]="subscriptionDto.id" style="width: 200px">
              <uitk-select *ngIf="subscriptionDto.subscriptionStatus!=='M' && subscriptionDto.subscriptionStatus!=='T'"
                           uitkId="status" [itemList]="subscriptionStatusListWithoutMAndT" [disabled]="isStatusDisabled"
                           [(ngModel)]="subscriptionStatus" appEnableResource="SubscriptionEditStatusField"
                           (change)="onChangeOfFormData()"></uitk-select>
              <uitk-select *ngIf="subscriptionDto.subscriptionStatus==='M' || subscriptionDto.subscriptionStatus==='T'"
                           uitkId="statusMAndT" [itemList]="subscriptionStatusList" [disabled]="true"
                           [(ngModel)]="subscriptionStatus" appEnableResource="SubscriptionEditStatusField"></uitk-select>
            </div>
          </div>
          <div class="col align-self-center">
            <label uitkLabel class="grey-text">Reactivate Until</label>
            <div class="float-end" bbLogTable="Subscription" bbLogColumn="ReactivateDate"
                 [bbLogPrimaryId]="subscriptionDto.id">
              <uitk-form-field>
                <uitk-date-picker style="min-height: 2rem !important; width: 200px"
                                  [disabled]="isStatusDisabled || reactivateDateDisabled" id="reactiveDatePicker"
                                  [model]="datePickerConfig" [(ngModel)]="reactiveSubUntil"
                                  (ngModelChange)="onChangeDate()" appEnableResource="SubscriptionEditReactivateUntil">
                </uitk-date-picker>
              </uitk-form-field>
            </div>
          </div>
        </div>
        <div class="row d-flex">
          <div class="col align-self-center">
            <label uitkLabel class="grey-text">Sub Type</label>
            <div class="float-end">
              <uitk-form-field>
                <input uitkInput id="subType" class="textbox" type="text" disabled
                       [(ngModel)]="subscriptionDto.subscriptionType" style="width: 200px" />
              </uitk-form-field>
            </div>
          </div>
          <div class="col align-self-center">
            <label uitkLabel class="grey-text">Sub #</label>
            <div class="float-end">
              <uitk-form-field>
                <input uitkInput id="subscription" class="textbox" type="text" disabled
                       [(ngModel)]="subscriptionDto.subscriptionNumber" style="width: 200px" />
              </uitk-form-field>
            </div>
          </div>
        </div>
        <div class="row d-flex">
          <div class="col align-self-center">
            <label uitkLabel class="grey-text">Contact</label>
            <div class="float-end" style="width: 200px">
              <uitk-select uitkId="contact" [itemList]="contactList" (ngModelChange)="setSelectedContact($event)"
                           [(ngModel)]="selectedContact" appEnableResource="subscriptioneditcontactfield">
              </uitk-select>
            </div>
          </div>
          <div class="col align-self-center">
            <label uitkLabel class="grey-text">Contact ID</label>
            <div class="float-end" bbLogTable="Subscription" bbLogColumn="ContactId"
                 [bbLogPrimaryId]="subscriptionDto.id">
              <uitk-form-field>
                <input uitkInput id="contactNumber" class="textbox" style="width: 200px" type="text" disabled
                       [(ngModel)]="subscriptionDto.contactId" />
              </uitk-form-field>
            </div>
          </div>
        </div>
        <div class="row d-flex">
          <div class="col-6 align-self-center">
            <label uitkLabel class="grey-text">Email</label>
            <div class="float-end">
              <uitk-form-field>
                <input uitkInput id="email" class="textbox" type="text" style="width: 200px" disabled [(ngModel)]="subscriptionDto.email" />
              </uitk-form-field>
            </div>
          </div>
          <div class="col-3 align-self-center">
            <uitk-form-field>
              <input uitkCheckbox id="emailInfo" type="checkbox" disabled
                     [(ngModel)]="subscriptionDto.allowInformationalEmails" />
              <label uitkCheckboxLabel id="emailInfoLabel" for="emailInfo">Email Info</label>
            </uitk-form-field>
          </div>
          <div class="col-3 align-self-center">
            <div>
              <uitk-form-field>
                <input uitkCheckbox id="emailPromo" type="checkbox" disabled
                       [(ngModel)]="subscriptionDto.allowPromotionalEmails" />
                <label uitkCheckboxLabel id="emailPromoLabel" for="emailPromo">Email Promo</label>
              </uitk-form-field>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="uitk-l-grid__row" style="width: 100%;">
        <div class="uitk-l-grid__col" style="width: 25%;">
          <uitk-form-field>
            <input uitkCheckbox id="updateOnly" type="checkbox" [(ngModel)]="subscriptionDto.sendUpdatesOnly"
                   (change)="onChangeOfFormData()"
                   [disabled]="updateOnlyCheck">
            <label uitkCheckboxLabel id="updateOnlyLabel" for="updateOnly">Updates Only</label>
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col" style="width: 25%;">
          <uitk-form-field>
            <input uitkCheckbox id="sendRenewalNotice" type="checkbox" [(ngModel)]="subscriptionDto.sendRenewalNotices"
                   (change)="onChangeOfFormData()"
                   [disabled]="(subscriptionStatus?.id!=='A' && subscriptionStatus?.id!=='F' && subscriptionStatus?.id!=='S' && subscriptionStatus?.id!=='W') || sendRenewalNoticeCheck" />
            <label uitkCheckboxLabel id="sendRenewalNoticeLabel" for="sendRenewalNotice">Send Renewal Notice</label>
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col" style="width: 25%;">
          <uitk-form-field>
            <input uitkCheckbox id="sendUpdate" type="checkbox" [(ngModel)]="subscriptionDto.sendUpdates"
                   (change)="onChangeOfFormData()"
                   [disabled]="(subscriptionStatus?.id!=='A' && subscriptionStatus?.id!=='F' && subscriptionStatus?.id!=='S' && subscriptionStatus?.id!=='W') || sendUpdatesCheck" />
            <label uitkCheckboxLabel id="sendUpdatesLabel" for="sendUpdate">Send Updates</label>
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col" style="width: 25%;">
          <uitk-form-field>
            <input uitkCheckbox id="emailUpdates" type="checkbox" [(ngModel)]="subscriptionDto.emailUpdates"
                   (change)="onChangeOfFormData()"
                   [disabled]="(subscriptionStatus?.id!=='A' && subscriptionStatus?.id!=='F' && subscriptionStatus?.id!=='S' && subscriptionStatus?.id!=='W') || emailUpdatesCheck" />
            <label uitkCheckboxLabel id="eUpdatesLabel" for="emailUpdates">Email Updates</label>
          </uitk-form-field>
        </div>
      </div>
      <div id=editSubscriptionTable class="uitk-c-table--responsive">
        <table uitkTable class="uitk-c-table" id="subscriptionedit-results-table" aria-labelledby="table-paginate"
               aria-describedby="row-data" #rootTable="uitkTable">

          <thead class="uitk-c-table__head">
            <tr uitkTableSort #sortTable="uitkTableSort">
              <th *ngFor="let headerName of tableHeader" scope="col">
                {{ headerName.name }}
              </th>
            </tr>
          </thead>
          <tbody id="row-data" class="uitk-c-table__body">
            <tr class="uitk-c-table__row">
              <td class="uitk-l-grid__col">
                <uitk-form-field>
                  <input uitkInput id="item" class="textbox" type="text"
                         [(ngModel)]="subscriptionDto.productCode" disabled>
                </uitk-form-field>
              </td>
              <td class="uitk-l-grid__col">
                <uitk-form-field>
                  <input uitkInput id="description" class="textbox" type="text"
                         [(ngModel)]="subscriptionDto.description" disabled>
                </uitk-form-field>
              </td>
              <td class="uitk-l-grid__col">
                <uitk-form-field>
                  <input uitkInput id="quantity" class="textbox" type="number"
                         [(ngModel)]="subscriptionDto.subscriptionQuantity" disabled>
                </uitk-form-field>
              </td>
              <td class="uitk-l-grid__col">
                <uitk-form-field>
                  <input uitkInput id="startDate" class="textbox" type="text"
                         [(ngModel)]="this.startDate" disabled>
                </uitk-form-field>
              </td>
              <td class="uitk-l-grid__col">
                <uitk-form-field>
                  <input uitkInput id="term" class="textbox" type="number"
                         [(ngModel)]="subscriptionDto.term" disabled>
                </uitk-form-field>
              </td>
              <td class="uitk-l-grid__col">
                <uitk-form-field>
                  <input uitkInput id="serialNumber" class="textbox" type="text"
                         [(ngModel)]="subscriptionDto.wapNumber" disabled>
                </uitk-form-field>
              </td>
              <td class="uitk-l-grid__col">
                <button id="reSendBtn" type="button"
                        class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="sendEmail()"
                        [disabled]="subscriptionDto.email ==='' || subscriptionDto.email ===null || (subscriptionStatus?.value !== 'A' && subscriptionStatus?.value !== 'W')"
                        appEnableResource="SubscriptionEditItemGridResendEmailButton">
                  Re-send
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions *ngIf="!isSaveSuccess">
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveSubscription()"
            [disabled]="disableSaveBtn || !subscriptionStatus ||!newSelectedContact"
            appEnableResource="SubscriptionEditSaveButton">
      Save
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeDialog()"
            (autofocus)="(disableSaveBtn)">
      Cancel
    </button>

  </uitk-dialog-actions>
  <uitk-dialog-content *ngIf="isSaveSuccess">
    Subscription Saved Succesfully.
  </uitk-dialog-content>
  <uitk-dialog-actions *ngIf="isSaveSuccess">
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeDialog()" #okBtn>
      Ok
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>

<div *ngIf="sentEmailDialogModal.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="sentEmailDialogModal.show" [closeBtn]=false Id="emailDialog">
  >
  <uitk-dialog-content *ngIf="isEmailSent">
    Email has been sent.
  </uitk-dialog-content>
  <uitk-dialog-actions *ngIf="isEmailSent">
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="okDialog()" #okBtn>
      Ok
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>
