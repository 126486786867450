<div class="uitk-l-grid p-0">
  <div class="uitk-l-grid__row">
    <div class="uitk-l-grid__col p-0">
      <app-search-navigation-bar *ngIf="hideSearchBar"></app-search-navigation-bar>
      <div class="uitk-l-grid__row uitk-l-grid__row--no-gutter" style="width: 100%;">
        <div class="uitk-l-grid__col--3">
          <div id="leftGrid" class="uitk-l-grid">
            <div class="uitk-l-grid__row">
              <div class="css-t7viak left-justify fill-width">
                <div class="rounded-pill bg-orange text-center text-white py-2" style="min-height: 40px;"><strong>{{ orderService.orderStatus }}</strong></div>
              </div>
            </div>
            <div class="uitk-l-grid__row">
              <div class="css-t7viak left-justify fill-width">
                <app-order-by id="return-order-by" [isnavigateFromBatchReturn]="isnavigateFromBatchReturn">
                </app-order-by>
              </div>
            </div>
            <div class="uitk-l-grid__row">
              <div class="css-t7viak left-justify fill-width uitk-u-margin-top--m">
                <app-ship-to id="return-ship-to" [isnavigateFromBatchReturn]="isnavigateFromBatchReturn">
                </app-ship-to>
              </div>
            </div>
            <div class="uitk-l-grid__row">
              <div class="css-t7viak left-justify fill-width uitk-u-margin-top--m">
                <app-bill-to id="return-bill-to" [isnavigateFromBatchReturn]="isnavigateFromBatchReturn">
                </app-bill-to>
              </div>
            </div>
          </div>
        </div>
        <div class="uitk-l-grid__col--9" [hidden]="modeService.isWaiting">
          <div class="left-justify" style="background-color: white;">
            <app-return [batchReturn]="batchReturn" [returnDtoList]="returnDtoList"
              [isnavigateFromBatchReturn]="isnavigateFromBatchReturn"></app-return>
          </div>
        </div>
        <div class="uitk-l-grid__col--9" style="padding-top: 300px" [hidden]="!modeService.isWaiting">
          <div id="loadingIndicator">
            <uitk-loading-indicator uitkId="spinner" spinnerSize="large">
            </uitk-loading-indicator>
          </div>
          <div class="row mt-3">
            <div class="col d-flex justify-content-center" >
              <h5>{{ modeService.waitDescription }}</h5>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</div>


