<div class="uitk-l-grid">
    <div class="uitk-l-grid__row">
      <div class="uitk-l-grid__col--12">      
          <h3 id="table-caption" class="headerTextClass">Account Comments</h3>
      </div>
      <div class="uitk-l-grid__col--12">  
          <div class="accountSection">
            <label uitkLabel style="margin-top: 10px;" for="account-search-textbox">Account ID</label>
            <div class="searchField">
                <uitk-form-field>
                     <input appAutoFocus uitkInput id="account-search-textbox" class="textbox mx-1" type="number" [(ngModel)]="accountIdObj" />
                </uitk-form-field>
            </div>
            <button type="button" class="uitk-c-button uitk-c-button--ghost" id="searchCommentBtn" (click)="searchAccount()">Search</button>
            <button type="button" class="uitk-c-button uitk-c-button--default" id="addNewBtn" (click)="addNewAccount()">Add New</button>
          </div>         
      </div>
      <div *ngIf="searchList.length > 0 && showUpdateAccountFlag" class="col-lg-12">
        <table uitkTable class="uitk-c-table" [dataSource]="searchList" id="searchList-table">
          <thead class="uitk-c-table__head">
            <tr style="text-align: left">
              <th scope="col" class="actionClass"></th>
              <th scope="col" class="actionClass"></th>
              <th scope="col">
                Comments
              </th>
            </tr>
          </thead>
          <tbody id="row-data" class="uitk-c-table__body">
            <ng-container *uitkTableRowBody="let row">
              <tr *ngIf="row" class="uitk-c-table__row">
                <td class="actionClass"><a href="javascript:void(0)" class="uitk-icon uitk-icon-trash_delete gridIcons" [id]="'delete-'+row.id"         
                  (click)="onDeleteClick(row)"></a></td>
                <td class="actionClass"><a href="javascript:void(0)" class="uitk-icon uitk-icon-edit gridIcons" [id]="'edit-'+row.id"                     
                  (click)="onEditClick(row)"></a></td>
                <td class="commentSection">{{row.comments}}</td>               
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>      
    </div>
  </div>
  <div *ngIf="editDialog.show">
    <div class = "overlayForPopups">
      <div cdkDrag>
        <uitk-dialog [(showModal)]="editDialog.show" uitkId="accountCommentDialog" [closeBtn]=false
      [triggerElementId]="triggerEle">
        <uitk-dialog-header style="font-size: 25px; line-height: 36px;">   
          <div cdkDragHandle>
            {{headerText}}
          </div>
        </uitk-dialog-header>
        <uitk-dialog-content>
          <app-account-comment-update [editDialog]="editDialog" [searchList]="searchListToEdit" [isEdit]="isEdit" (errorvalidation)="validateError($event)"></app-account-comment-update>
        </uitk-dialog-content>    
      </uitk-dialog>
      </div>
    </div>
  </div>
  <div class = "overlayForPopups" *ngIf="deleteDialog.show">
    <div cdkDrag>
  <uitk-dialog [(showModal)]="deleteDialog.show" uitkId="accountCommentDialog" [closeBtn]=false
  [triggerElementId]="deleteTriggerEle">  
    <uitk-dialog-content>
      Are you sure you would like to delete this Account Comment?
    </uitk-dialog-content>
    <uitk-dialog-actions class="actionBtns">
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" id="yesBtn" (click)="deleteComment()" appAutoFocus>
        Yes
      </button>
      <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" id="noBtn" (click)="closeDleteDialog()">
        No
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
    </div>
  </div>
  <div class = "overlayForPopups" *ngIf="validationDialog.show">
    <div cdkDrag>
  <uitk-dialog [(showModal)]="validationDialog.show" uitkId="validationDialog" [closeBtn]=false
  [triggerElementId]="validationTriggerEle">  
    <uitk-dialog-content>
      {{validationMessage}}
    </uitk-dialog-content>
    <uitk-dialog-actions class="actionBtns">
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" id="okBtn" (click)="closeValidationDialog()" appAutoFocus>
        Ok
      </button>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" id="cancelBtn" (click)="cancelValidationDialog()" *ngIf="salesForceRedirectFlag">Cancel</button>
      
    </uitk-dialog-actions>
  </uitk-dialog>
    </div>
  </div>
  <app-loader *ngIf="showLoaderFlag"> </app-loader>