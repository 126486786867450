<div *ngIf="dialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkId="ItemSearchDialog" id="logDialog" [(showModal)]="dialog.show" [closeBtn]="true">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Item Search
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content style="width: 1169px;">
    <div>
        <uitk-panel uitkId="orderSearchPanel" [collapsible]="true" [open]="true"
        (openChange)="setPanelBGHeight()">
        <uitk-panel-content>
        <div class="uitk-l-grid__row">
          <div class="uitk-l-grid__col">
            <label uitkLabel>Item #</label>
          </div>
          <div class="uitk-l-grid__col">
            <uitk-form-field>
              <input appAutoFocus uitkInput id="itemNumber" class="textbox" type="text" value=""
                [(ngModel)]="itemNumber" (keydown.enter)="search()" />
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__col">
            <label uitkLabel>ISBN</label>
          </div>
          <div class="uitk-l-grid__col">
            <uitk-form-field>
              <input uitkInput id="isbnNumber" class="textbox" type="text" value="" [(ngModel)]="isbn"
                (keydown.enter)="search()" />
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__col">
            <label uitkLabel>Edition Yr</label>
          </div>
          <div class="uitk-l-grid__col">
            <uitk-form-field>
              <uitk-select uitkId="editionYr" name="editionYrInput" [itemList]="editionYrList"
                [defaultLabel]="defaultLabel" [(ngModel)]="editionYr" [defaultLabelFlag]="true"
                (keydown.enter)="search()" (change)="search()"></uitk-select>
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__row">
          <div class="uitk-l-grid__col--2">
            <label uitkLabel>Item Description</label>
          </div>
          <div class="uitk-l-grid__col--2">
            <uitk-form-field>
              <input uitkInput id="itemDescription" class="textbox" type="text" value="" [(ngModel)]="itemDescription"
                (keydown.enter)="search()" />
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__col--2">
            <label uitkLabel>Acronym</label>
          </div>
          <div class="uitk-l-grid__col--6">
            <uitk-form-field>
              <uitk-select uitkId="acronymYr" name="acronymInput" [itemList]="acronymList" [defaultLabel]="defaultLabel"
                [(ngModel)]="acronym" [defaultLabelFlag]="true" (keydown.enter)="search()" (change)="search()"></uitk-select>

            </uitk-form-field>
          </div>
        </div>
        <div id="searchDiv" class="uitk-l-grid__row ">
          <button id="itemSearchBtn" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xs" style="margin: 10px"
            (click)="search()" (callSearchMethod)="search()" hotkeyCommand="ctrl+f">
            Search
          </button>
          <button id="clearBtn" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xs"
            (click)="clear()">
            Clear
          </button>
        </div>
      </uitk-panel-content>
    </uitk-panel>
    </div>
    <div *ngIf="modeService.mode == Mode.Wait">
      <body>
        <app-loader> </app-loader>
      </body>
    </div>
    <div uitkTableContainer [sticky]="true" style="height: 400px;" *ngIf="showResultsGrid.show"
      class="uitk-c-table--responsive">
      <table uitkTable [dataSource]="ProductsList" class="uitk-c-table" id="log-table">
        <thead class="uitk-c-table__head">
          <tr>
            <th scope="col">
              Add Item
            </th>
            <th *ngFor="let headerName of tableHeader" scope="col" id={{headerName.id}}>
              {{ headerName.name }}
            </th>
          </tr>
        </thead>
        <tbody id="row-data" class="uitk-c-table__body">
          <ng-container *uitkTableRowBody="let product">
            <tr class="uitk-c-table__row">
              <td> <span id="addIcon" (click)="addItem(product.productCode)"
                  class="uitk-icon uitk-icon-add sm-icon"></span></td>
              <td>{{product.productCode}}</td>
              <td>{{product.description}}</td>
              <td>{{product.listPrice |currency:'USD'}}</td>
              <td>{{product.floorPrice |currency:'USD'}}</td>
              <td>{{product.salesCost |currency:'USD'}}</td>
              <td>{{product.onHand}}</td>
              <td>{{product.availableText}}</td>
              <td>{{product.isbn}}</td>
              <td>{{product.editionYear}}</td>
              <td>{{product.acronym}}</td>
            </tr>
          </ng-container>
          <div *ngIf="ProductsList.length === 0">No records found</div>
        </tbody>
      </table>
    </div>
  </uitk-dialog-content>
</uitk-dialog>
  </div>
</div>