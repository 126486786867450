import { IUITKSelectItemProps } from "@uitk/angular";
import { ShipToDto } from "./ship-to-dto.model";
export class SubscriptionEditDto {
        id =0;
        contactId =0;
        productId =0
        productCode ='';
        subscriptionNumber =0
        subscriptionType ='';
        subscriptionStatus ='';
        subscriptionQuantity =0;
        startDate: Date | null = null;
        term =0;
        wapNumber= '';
        sendUpdatesOnly =false;
        sendRenewalNotices =false;
        emailUpdates =false;
        sendUpdates =false;
        orderId =0;
        reactiveDate: Date| null = null;
        accountId =0;
        description ='';
        firstName='';
        lastName ='';
        email ='';
        allowInformationalEmails =false;
        allowPromotionalEmails =false;
        phone ='';
        modifiedBy = '';
   }

