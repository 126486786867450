import { Component, Input, OnInit } from '@angular/core';
import { Dialog } from '../../helpers/dialog';

@Component({
  selector: 'app-item-changed-popup',
  templateUrl: './item-changed-popup.component.html',
  styleUrls: ['./item-changed-popup.component.css']
})
export class ItemChangedPopupComponent implements OnInit {
  @Input() itemChangedPopup!: Dialog<string>;
  @Input() productCode!: string;
  @Input() productType!: string;

  ngOnInit(): void {
    console.log("..........",this.productType);
  }

  deletePremiums(): void {
    this.itemChangedPopup.close("Delete");
  }
}
