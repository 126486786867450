import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IUITKRadioGroupItem } from '@uitk/angular';
import { Dialog } from 'src/app/helpers/dialog';
import { OrderDetailDto } from 'src/app/dtos/order-detail-dto.model';
import { ProductDto } from 'src/app/dtos/product-dto.model';
import { SubscriptionDto } from 'src/app/dtos/subscription-dto.model';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ConfigService } from 'src/app/services/config.service';
import { OrderService } from 'src/app/services/order.service';
import { PricingService } from 'src/app/services/pricing.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-subscription-order-entry',
  templateUrl: './subscription-order-entry.component.html',
  styleUrls: ['./subscription-order-entry.component.css']
})
export class SubscriptionOrderEntryComponent implements OnInit {

  private readonly _subscriptionService: SubscriptionService;
  private readonly _configService: ConfigService;
  private readonly _authorizationService: AuthorizationService;
  private readonly _pricingService: PricingService;
  @ViewChild('startMonthInput') startMonthInput: ElementRef | undefined;
  @ViewChild('termPeriodInput') termPeriodInput: ElementRef | undefined;
  @Input() orderDetail: OrderDetailDto = new OrderDetailDto();
  @Output() enableSave = new EventEmitter<boolean>();
  @Input() productDto: ProductDto = new ProductDto();
  @Input() tempSubscriptionDto: SubscriptionDto = new SubscriptionDto();
  @Output() isTrialChecked: EventEmitter<boolean> = new EventEmitter();
  showError = false
  subscriptionError = false;
  renewalSubscriptions: SubscriptionDto[] = [];
  termPeriod = 12;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  startMonth = new Date().getMonth() + 1;
  startYear = new Date().getFullYear();
  subscriptionNumber = 0;
  subscriptionOrderId = 0;
  isRenewal = false;
  sendRenewalNotices = true;
  emailUpdates = true;
  updatesOnly = false;
  trial = false;
  isSync = true;
  isSubscriptionSavedToDb = false;
  hasSubscriptionsToRenew = false;
  hasSubscriptionTermNoLimit = false;
  expireDate: Date | number = new Date();
  startDate: Date | number | string = new Date();
  renewalSubscriptionId: number | null = null;
  temptermPeriod = 0;
  isInvalidStartDate = false;
  disableStartYear = false;
  subscriptionRBChecked = false;
  subscriptionType = '';
  subscriptionUserChangedTermPeriod = 0;
  userSelectedRadioBtn = 1; 
  tableHeaders = [
    { name: 'Sub #', id: 'subscriptionNumber' },
    { name: 'Start Date', id: 'startDate' },
    { name: 'Term', id: 'term' },
    { name: 'Expire Date', id: 'expireDate' },
    { name: 'Sub Status', id: 'subStatus' },
    { name: 'Qty', id: 'quantity' },
    { name: 'Sub Type', id: 'subscriptionType' },
    { name: 'Order #', id: 'orderNumber' }
  ];
  profileForm = new UntypedFormGroup({
    radioGroup: new UntypedFormControl(''),
  });
  items: IUITKRadioGroupItem[] = [
    {
      label: 'New',
      value: 'new',
    },
    {
      label: 'Trial',
      value: 'trial',
    },
  ];
  formatType: any;
  isSubscriptionTypeNT = true;
  isSubscriptionAllowbackdate: boolean = false;
  showSubscriptionDialog = new Dialog<string>();
  constructor(
    subscriptionService: SubscriptionService,
    public orderService: OrderService,
    configService: ConfigService,
    authorizationService: AuthorizationService,
    pricingService: PricingService) {
    this._subscriptionService = subscriptionService;
    this._configService = configService;
    this._authorizationService = authorizationService;
    this._pricingService = pricingService;
  }

  ngOnInit(): void {
    if (this.orderDetail.productDto.allowTrial === false) {
      this.items[1].disabled = true;
    }
    this.getSubscriptionsForRenewal();
    if (this.orderDetail.subscriptionDto === null)
      this.formatType = this.items[0];
    else
      this.resetRadioButton();

    this.defaultSubscriptionValues();
    this.toCheckSavedSubscription();
    if (this._authorizationService.hasResource('SubscriptionTermNoLimit')) {
      this.hasSubscriptionTermNoLimit = true;
    }

    if(this._authorizationService.hasResource('SubscriptionsAllowBackDateEntry')){
      this.isSubscriptionAllowbackdate = true;
    }
    console.log("subscriptionValues", this.orderDetail.subscriptionDto);
  }

  async onChangeFormatType(): Promise<void> {

    this.isSubscriptionTypeNT = this.formatType ? true : false;
    this.isRenewal = !this.isSubscriptionTypeNT;
    if (this.formatType.label === 'Trial'){
      this.termPeriod = 2;
      this.userSelectedRadioBtn = 3;
    }
    if (this.orderDetail.subscriptionDto) {
      this.orderDetail.subscriptionDto.subscriptionType = this.formatType.label === 'New' ? 'N' : 'T';
    }
    if(this.formatType.label === 'New')
    {
      this.userSelectedRadioBtn = 2;
    }
    this.defaultSubscriptionValues();
  }

  setSubscriptionCheckboxValue(): void {
    this.sendRenewalNotices = (this.orderService.orderDto.billToDto.defaultSendRenewalNotices === true
      && this.orderService.orderDto.shipToDto.defaultSendRenewalNotices === true) ? true : false;
    this.updatesOnly = false;
    this.emailUpdates = this.orderService.orderDto.shipToDto.allowInformationalEmails && this.orderService.orderDto.billToDto.defaultSendUpdates;
  }
  defaultSubscriptionValues() {
    this.isSubscriptionSavedToDb = this.orderDetail.orderId > 0;
    this.startMonth = new Date().getMonth() + 1;
    this.startYear = new Date().getFullYear();
    if (this.formatType) {
      if (this.formatType.label === 'Trial' && (this.subscriptionUserChangedTermPeriod === 0 || this.subscriptionUserChangedTermPeriod !==0 )) {
        this.termPeriod = 2;
      }
      else if(this.subscriptionUserChangedTermPeriod > 0 && this.formatType.label !== 'Trial' && this.userSelectedRadioBtn !== 2)
      {
          this.termPeriod = this.subscriptionUserChangedTermPeriod;
      }
      else if(this.formatType.label === 'New' && this.userSelectedRadioBtn === 2)
      {
        this.termPeriod = this.orderDetail.productDto.defaultSubscriptionTerm;
      }
      else if (this.formatType.label === 'New' && this.subscriptionUserChangedTermPeriod !== 0) {
        this.termPeriod = this.subscriptionUserChangedTermPeriod;
      }
      else {
        this.termPeriod = this.orderDetail.productDto.defaultSubscriptionTerm;
      }
      if (this.formatType.label === 'Trial' || this.formatType.label === 'New') {
        this.renewalSubscriptions.forEach(element => {
          element.isSelected = false;
        });
      }
    }
    this.setSubscriptionCheckboxValue();
    this.validateStartDate();
  }

  navigateToSubscriptionSearch(orderId: number): void {
    localStorage.setItem('subscriptionOrderId', orderId.toString());
      window.open(`${this._configService.spaUrl}subscriptions`);
  }

  defaultSubscriptionValue() {
    this.isSubscriptionSavedToDb = this.orderDetail.orderId > 0;
      this.startMonth = new Date().getMonth() + 1;
      this.startYear = new Date().getFullYear();
      if(this.formatType){
        this.termPeriod = this.formatType.label === 'Trial'?2:this.orderDetail.productDto.defaultSubscriptionTerm;
        if(this.formatType.label === 'Trial' || this.formatType.label === 'New'){
          this.renewalSubscriptions.forEach(element => {
            element.isSelected = false;
           });
        }
      }
      else{
        this.termPeriod = this.orderDetail.productDto.defaultSubscriptionTerm;
      }
      this.sendRenewalNotices = (this.orderService.orderDto.billToDto.defaultSendRenewalNotices === true
        && this.orderService.orderDto.shipToDto.defaultSendRenewalNotices === true) ? true : false;
      this.updatesOnly = false;
      this.emailUpdates = this.orderService.orderDto.shipToDto.allowInformationalEmails&&this.orderService.orderDto.billToDto.defaultSendUpdates;
  }
  toCheckSavedSubscription(){
    if (this.orderDetail.subscriptionDto === null) {
      this.startMonth = new Date().getMonth() + 1;
      this.startYear = new Date().getFullYear();

      this.termPeriod = this.orderDetail.productDto.defaultSubscriptionTerm;
      this.sendRenewalNotices = (this.orderService.orderDto.billToDto.defaultSendRenewalNotices === true
        && this.orderService.orderDto.shipToDto.defaultSendRenewalNotices === true) ? true : false;
      this.updatesOnly = false;
      this.emailUpdates = this.orderService.orderDto.shipToDto.allowInformationalEmails && this.orderService.orderDto.billToDto.defaultSendUpdates;
      this.isSync = false;
    }
    else {
      this.isSubscriptionTypeNT = this.formatType ? true : false;
      this.startMonth = new Date(this.orderDetail.subscriptionDto?.startDate).getMonth() + 1;
      this.startYear = new Date(this.orderDetail.subscriptionDto?.startDate).getFullYear();
      this.termPeriod = this.formatType?.label === 'Trial' ? 2 : this.orderDetail.subscriptionDto.term;
      this.temptermPeriod = this.orderDetail.subscriptionDto.term;
      this.sendRenewalNotices = this.orderDetail.subscriptionDto.sendRenewalNotices;
      this.updatesOnly = this.orderDetail.subscriptionDto.subscriptionType === 'R' ? true : this.orderDetail.subscriptionDto.sendUpdatesOnly;
      this.emailUpdates = this.orderDetail.subscriptionDto.emailUpdates;

    }
  }
  resetRadioButton() {
    if (this.orderDetail.subscriptionDto?.subscriptionType !== 'R') {
      this.isRenewal = false;
      if (this.orderDetail.subscriptionDto?.subscriptionType === 'T' && this.orderDetail.subscriptionDto.term < 3 && this.orderDetail.subscriptionDto.term > 0)
        this.formatType = this.items[1];
      else
        this.formatType = this.items[0];
    }
    this.isRenewal = true;
  }
  saveSubscriptionType(): void{
    if (this.formatType?.label === 'Trial') {
      this.tempSubscriptionDto.subscriptionType = 'T';
    }
    else {
      this.tempSubscriptionDto.subscriptionType = this.formatType || !this.subscriptionRBChecked ? 'N' : 'R';
    }
  }
  save(): boolean {

    if (!this.validateRenewalOnSave()) {
      return false;
    }

    this._subscriptionService.subscriptionIsSavedTemporary = true;
    this.startDate = (this.startMonth) + "/01/" + (this.startYear);
    this.tempSubscriptionDto.id = this.orderDetail.id;
    this.tempSubscriptionDto.productId = this.orderDetail.productId;
    this.tempSubscriptionDto.productCode = this.orderDetail.productCode;
    this.tempSubscriptionDto.startDate = new Date(this.startYear, this.startMonth - 1, 1);
    this.tempSubscriptionDto.term = Number(this.termPeriod);

    this.tempSubscriptionDto.sendUpdatesOnly = this.updatesOnly;
    this.tempSubscriptionDto.sendRenewalNotices = this.sendRenewalNotices;
    this.tempSubscriptionDto.sendUpdates = true;
    this.tempSubscriptionDto.emailUpdates = this.emailUpdates;

    const wapNumber = this._configService.getPickList('WAP').filter(item => item.value === this.orderDetail.productDto.onlineType)[0]?.label;
    if (wapNumber) {
      this.tempSubscriptionDto.wapNumber = wapNumber;
    }
    this.renewalSubscriptions.forEach(element => {
      if (element.isSelected) {
        this.orderService.selectedSubscriptionOrderId = element.subscriptionOrderId;
        this.subscriptionRBChecked = true;
      }
    });
    if (this.orderDetail.subscriptionDto === null) {
      this.tempSubscriptionDto.contactId = this.orderService.orderDto.shipToDto.contactId;
      this.tempSubscriptionDto.subscriptionQuantity = this.orderDetail.orderQuantity * (this.productDto.subscriptionMultiplier || 1);
      this.tempSubscriptionDto.timesRenewed = 0;
      this.tempSubscriptionDto.subscriptionStatus = 'F';
      this.saveSubscriptionType();
      this.tempSubscriptionDto.subscriptionNumber = this.formatType ? 0 : this.subscriptionNumber;

      this.tempSubscriptionDto.trialOrderHeaderId = null;
    } else {
      this.tempSubscriptionDto.contactId = this.orderDetail.subscriptionDto.contactId;
      if (this.orderDetail.subscriptionDto.subscriptionType === 'R') {
        this.tempSubscriptionDto.subscriptionNumber = this.subscriptionNumber;
      } else {
        this.tempSubscriptionDto.subscriptionNumber = 0;
      }
      this.tempSubscriptionDto.subscriptionQuantity = this.orderDetail.subscriptionDto.subscriptionQuantity;
      this.tempSubscriptionDto.timesRenewed = this.orderDetail.subscriptionDto.timesRenewed;
      this.tempSubscriptionDto.subscriptionStatus = this.orderDetail.subscriptionDto.subscriptionStatus;
      this.tempSubscriptionDto.subscriptionType = this.orderDetail.subscriptionDto.subscriptionType;
      this.tempSubscriptionDto.trialOrderHeaderId = this.orderDetail.subscriptionDto.trialOrderHeaderId;
      this.tempSubscriptionDto.term = this.termPeriod;
    }


    this.subscriptionDiscountBR();

    this.orderDetail.subscriptionDto = this.tempSubscriptionDto;
    this.orderDetail.requiredDate = this.tempSubscriptionDto.startDate;
    this.orderService.orderDto.orderDetailDtos.filter(o => o.parentOrderDetailId === this.orderDetail.id && o.orderDetailStatus === ' ')
      .forEach(item => {
        item.requiredDate = this.orderDetail.requiredDate;
      });
    return true;
  }

  selectedSubscription(record: SubscriptionDto) {
    if (this.orderDetail.subscriptionDto) {
      this.orderDetail.subscriptionDto.subscriptionType = 'R';
    }
    this.formatType = '';
    this.isRenewal = true;
    this.termPeriod = this.orderDetail.productDto.defaultSubscriptionTerm;
    this.isSubscriptionTypeNT = false;
    let result = new Date(record.startDate);
    this.expireDate = result.setMonth(result.getMonth() + (record.term - 1));
    this.subscriptionOrderId = record.subscriptionOrderId;
    result = new Date(this.expireDate);
    this.startDate = result.setMonth(result.getMonth() + 1);
    this.startMonth = new Date(this.startDate).getMonth() + 1;
    this.startYear = new Date(this.startDate).getFullYear();
    this.subscriptionNumber = record.subscriptionNumber;

    this.validateStartDate();
    console.log("start m/y", this.startMonth, this.startYear);
  }

  getSubscriptionsForRenewal() {
    const orderIndex = this.orderService.orderDto.multiYearDtos.findIndex(item => item.orderId === this.orderService.orderDto.id);
    const lastYearOrderId = orderIndex <= 0 ? null : this.orderService.orderDto.multiYearDtos[orderIndex - 1].orderId;

    this._subscriptionService.getSubscriptionsForRenewal(this.orderService.orderDto.shipToDto.contactId, this.orderDetail.productId, lastYearOrderId).subscribe(data => {

      this.renewalSubscriptions = data;
      if (this.renewalSubscriptions.length > 0) {
        this.hasSubscriptionsToRenew = true;
      }
      else {
        this.hasSubscriptionsToRenew = false;
      }
      this.renewalSubscriptions.forEach(element => {
        element.isSelected = element.subscriptionNumber === this.orderDetail.subscriptionDto?.subscriptionNumber ? true : false;
        element["expireDate"] = new Date(new Date(new Date(element.startDate).setMonth(new Date(element.startDate).getMonth() + element.term)).getTime() - 24 * 60 * 60 * 1000);
        if (element.isSelected)
          this.selectedSubscription(element);
      });
    }, () => {
      console.log('Error occurred while retrieving renewal Subscriptions Data');
    });
  }
  validateRenewalDate(): void {
    this.renewalSubscriptions.forEach(element => {
      if (new Date(new Date(element.expireDate).setMonth(new Date(element.expireDate).getMonth() + 7)) < new Date(new Date(new Date().setDate(1)).toDateString()) &&
        element.isSelected) {
          this.showError = true;
      }
    });
  }
  validateRenewalOnSave(): boolean {
    this.showError = false;
    this.subscriptionError = false;
    let isNotValidDate = ((this.startMonth > 12 || this.startMonth < 1 ||
      (this.startMonth < this.currentMonth && this.startYear === this.currentYear)) ||
      (this.startYear > this.currentYear + 10) || (this.startYear < this.currentYear) ||
      (this.formatType?.label === 'Trial' && (this.termPeriod > 2 || this.termPeriod < 1)) || this.termPeriod < 1);

    if (this.termPeriod > 18 && !this.hasSubscriptionTermNoLimit) {
      this.termPeriodInput?.nativeElement.focus();
      this.subscriptionError = true;
    }
    else if ((!this.isSubscriptionAllowbackdate && this.isSubscriptionTypeNT && isNotValidDate) || (!this.isSubscriptionTypeNT && this.isInvalidStartDate)) {
      this.startMonthInput?.nativeElement.focus();
      this.subscriptionError = true;
    }
    this.validateRenewalDate();
    if (this.orderDetail.shipDate === null && (this.showError || this.subscriptionError)) {
      return false;
    }
    else {
      return true;
    }
  }

  termPeriodChanged() {
    if (this.termPeriod < 3) {
      this.trial = true;
    }
    else {
      this.trial = false;
      this.orderDetail.subscriptionDto = null;
    }
    this.isTrialChecked.emit(this.trial);
  }

  onSelectionChange(id: number) {
    this.showError = false;
    this.renewalSubscriptions.forEach(element => {
      element.isSelected = false;
      if (element.id === id) {
        element.isSelected = true;
      }
    });
    this.updatesOnly = true;
  }

  subscriptionDiscountBR(): void {
    if (this.formatType?.label === 'Trial') {
      this.orderDetail.discount = 100;
      this.orderDetail.unitPrice = 0;
      this.orderDetail.extendedPrice = 0;
    }
  }
  validateStartDate(): void {
    let updatedDate = new Date(new Date(new Date(new Date().setFullYear(this.startYear)).setMonth(this.startMonth + 6)).setDate(1)).toDateString();
    let allowBackDate = this.isSubscriptionAllowbackdate && !this.isRenewal && this.isSubscriptionTypeNT;
    if (new Date(updatedDate) < new Date(new Date(new Date().setDate(1)).toDateString())) {
      this.isInvalidStartDate = true;
    }
    else {
      this.isInvalidStartDate = false;
    }
    if(allowBackDate)
      this.isInvalidStartDate = false;

    if (this.termPeriod > 2) {
      if (this.formatType?.label === 'Trial') {
        this.subscriptionUserChangedTermPeriod = this.termPeriod;
        this.termPeriod = this.subscriptionUserChangedTermPeriod;
        this.formatType = this.items[0];
        this.showSubscriptionDialog.openAsync();
        if (this.orderDetail.subscriptionDto)
          this.orderDetail.subscriptionDto.subscriptionType = 'N';
        this.defaultSubscriptionValue();
        this.defaultSubscriptionValues();
      }
    }
  }
}
