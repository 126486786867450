<div class="row mt-3" id="orderBySection">
  <div class="uitk-c-panel__header" style="display: -webkit-inline-box;">
    <div class="col-1 d-flex">
      <span
        style="font-size: 24px; font-family: Arial-BoldMT, Arial Bold, Arial, sans-serif; font-weight: 700;font-style: normal">
        Order By
      </span>
    </div>
    <div class="col-11 d-flex">
      <div class="uitk-icon uitk-icon-add sm-icon align-self-center mx-2">
        <a class="link" target="_blank" (click)="navigateToSFAddContact(orderService.orderDto.billToDto.salesforceId)"
          [ngClass]="{'disabled': !orderService.orderDto.billToDto.id || modeService.mode !== Mode.Edit}" tabindex="-1">Add</a>
      </div>
      <div class="uitk-icon uitk-icon-refresh sm-icon align-self-center mx-3">
        <a class="link" target="_blank" (click)="refreshOrderByContactList()"
          [ngClass]="{'disabled': disableEditViewRefreshLinks || modeService.mode !== Mode.Edit}">
          Refresh
        </a>
      </div>
      <div class="uitk-icon uitk-icon-edit sm-icon align-self-center mx-3">
        <a class="link" target="_blank"
          (click)="navigateToSFNewOrEditContact(orderService.orderDto.orderByDto.salesforceId)"
          [ngClass]="{'disabled': disableEditViewRefreshLinks || modeService.mode !== Mode.Edit}">
          Edit
        </a>
      </div>
      <div class="uitk-icon uitk-icon-search sm-icon searchIcon align-self-center">
        <a class="link" target="_blank"
        (click)="advanceContactSearch()">
          Contact Search 
        </a>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Contact ID</label>
    </div>
  <div class="col-7 d-flex contactIdMainSec">
      <div class="idSection">
        <div class="idInnerSec">
          <uitk-form-field style="padding-left: 0px;">
          <input uitkInput id="contactId"
            [ngModel]="orderService.orderDto.orderByDto.contactId === 0 ? '' : orderService.orderDto.orderByDto.contactId"
            (ngModelChange)="orderByContactIdNgModelChange($event)"
            class="textbox"
            type="text" (focusout)="onContactIdChange($event)" onkeyup="this.value=this.value.replace(/[^0-9]/g, '')"
            [disabled]="modeService.mode !== Mode.Edit" scrollToTarget target="#orderBySection" />
            <uitk-form-field-error *ngIf="invalidContactIdCheck === true">
              No Results Found
            </uitk-form-field-error>
        </uitk-form-field>
      </div>
      <div class="idInnerSec">
        <button name="orderBy-viewBtn" id="viewBtn"
          class="uitk-c-button uitk-c-button--ghost uitk-icon uitk-icon-view px-0 py-1 mt-1" type="button"
          (click)="navigateToSFViewContact(orderService.orderDto.orderByDto.salesforceId)"
          [disabled]="disableEditViewRefreshLinks || modeService.mode !== Mode.Edit"></button>
      </div>
    </div> 
    </div>
  </div>
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Contact Name</label>
    </div>
    <div class="col-7 d-flex">
      <uitk-form-field class="mx-3" id="drpContactName" class="w-100" *ngIf="_contactService.orderByContactList.length<3000">
        <uitk-select id="contactName" class="mx-3 w-100" [itemList]="_contactService.orderByContactList" [disabled]="orderService.orderDto.billToDto.id==0 || modeService.mode !== Mode.Edit"
        (ngModelChange)="getContactdetail($event)" [(ngModel)]="orderService.orderDto.orderByContactBinding">
        </uitk-select>
      </uitk-form-field>
      <ng2-completer id="autoCompleteContactName" [datasource]="this._contactService.shipToContacts" [minSearchLength]="2" [inputClass]="'completer-dropdown'" 
        class="mx-3 w-100 ng-completer" (selected)="getContactArrayDetail($event)" [initialValue]="enteredContact" 
        *ngIf="_contactService.orderByContactList.length>3000" [(ngModel)]="enteredContact" >
      </ng2-completer>
    </div>
  </div>
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Status</label>
    </div>
    <div class="col-7 d-flex">
      <uitk-form-field>
        <input uitkInput id="status" class="textbox mx-3" type="text" disabled
          [(ngModel)]="orderService.orderDto.orderByDto.statusCodeDescription" />
      </uitk-form-field>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Title Description</label>
    </div>
    <div class="col-7 d-flex">
      <uitk-form-field>
        <input uitkInput id="titleDesc" class="textbox mx-3" type="text" disabled
          [(ngModel)]="orderService.orderDto.orderByDto.title" />
      </uitk-form-field>
    </div>
  </div>
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Phone</label>
    </div>
    <div class="col-7 d-flex">
      <uitk-form-field>
        <input uitkInput id="phone" [ngModel]="orderService.orderDto.orderByDto.phone | phoneFormat"
          class="textbox mx-3" type="text" name="phoneInp" #phoneInp="ngModel" disabled />
      </uitk-form-field>
    </div>
  </div>
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Email</label>
    </div>
    <div class="col-7 d-flex">
      <uitk-form-field>
        <input uitkInput id="email" class="textbox mx-3" type="text" disabled
          [(ngModel)]="orderService.orderDto.orderByDto.email" />
      </uitk-form-field>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-4 d-flex">
    <div class="col-3 d-flex">
      <label class="align-self-center label" uitkLabel>Fax</label>
    </div>
    <div class="col-7 d-flex">
      <uitk-form-field>
        <input uitkInput id="fax" class="textbox mx-3" type="text" disabled
          [ngModel]="orderService.orderDto.orderByDto.fax | phoneFormat" />
      </uitk-form-field>
    </div>
  </div>
  <div class="col-8 d-flex">
  </div>
</div>
<div *ngIf="contactDialogModal.show">
  <app-contact-change-popup [contactDialogModal]="contactDialogModal"></app-contact-change-popup>
</div>
<div *ngIf="contactSearchLookupPopup.show">
  <app-contact-search-lookup [contactSearchLookupDialog]="contactSearchLookupPopup" triggerEleId="contactName"
    (selectedContact)="bindSelectedContact($event)">
  </app-contact-search-lookup>
</div>
<div *ngIf="showAdvancedContactSearchDialog.show">
  <app-advanced-contact-search-dialog [advancedContactSearchDialog]="showAdvancedContactSearchDialog"
    [component]="'order-by'">
  </app-advanced-contact-search-dialog>
</div>