<div class="container-fluid">
  <!-- Header -->
  <!-- <div class="row">
    <div class="col-12 mb-3">
      <h4>{{ orderDetail.productCode }}: {{ orderDetail.productDescription }}</h4>
    </div>
  </div> -->
  <app-void-item-dialog [dialog]="voidItemDialog" (dialogClosed)="onVoidLineItemDialogCancel()" [selectedOrder]="selectedOrderDetail"></app-void-item-dialog>

  <!-- State, Carrier, Locality selection-->
  <div class="row mb-4" *ngIf="this.mainProductDto.chegSetOptionType === 'S' || this.mainProductDto.chegSetOptionType === 'C' || this.mainProductDto.chegSetOptionType === 'L'">
    <div class="col-4">
      <div class="row">
        <label class="col-lg-3" uitkLabel>State</label>
        <div class="col-lg-9">
          <uitk-form-field>
            <uitk-dropdown
              (ngModelChange)="onChangeState($event.value)" [enableSelectFlag]="true"
              Id="stateinfo" [items]="stateList" [(ngModel)]="selectedState">
            </uitk-dropdown>
          </uitk-form-field>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="row">
        <label class="col-lg-3" uitkLabel>Carrier</label>
        <div class="col-lg-9">
          <uitk-form-field>
            <uitk-dropdown [disabled]="(this.mainProductDto.chegSetOptionType !== 'C' && this.mainProductDto.chegSetOptionType !== 'L') || selectedState.value === 'Select'"
              (ngModelChange)="onChangeCarrier($event.value)"
              Id="carrierinfo" [items]="carrierList" [enableSelectFlag]="true"
              [(ngModel)]="selectedCarrier">
            </uitk-dropdown>
          </uitk-form-field>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="row">
        <label class="col-lg-3" uitkLabel>Locality</label>
        <div class="col-lg-9">
          <uitk-form-field>
            <uitk-dropdown [disabled]="this.mainProductDto.chegSetOptionType !== 'L' || selectedCarrier.value === 'Select'"
              (ngModelChange)="onChangeLocality($event.value)"
              Id="localityinfo" [items]="localityList" [enableSelectFlag]="true" [(ngModel)]="selectedLocality"></uitk-dropdown>
          </uitk-form-field>
        </div>
      </div>
    </div>
  </div>

  <!-- Additional Licenses -->
  <h5>Additional licenses that the customer wants to purchase?</h5>
  <div class="row mb-3">
    <div class="col-12">
      <table uitkTable class="uitk-c-table">
        <thead class="uitk-c-table__head">
          <tr style="text-align: left">
            <th scope="col">
              Item
            </th>
            <th scope="col">
              List Price
            </th>
            <th scope="col">
              Quantity
            </th>
            <th scope="col">
              Discount
            </th>
            <th scope="col">
              Unit Price
            </th>
            <th scope="col">
              Ext Price
            </th>
          </tr>
        </thead>
        <tbody class="uitk-c-table__body">
          <ng-container>
            <tr class="uitk-c-table__row">
              <td>{{ licenseProduct.productCode }}</td>
              <td>${{ licensePricing.listPrice }}</td>
              <td>
                <uitk-form-field><input appQuantityFormat uitkInput id="quantity-{{ orderDetail.id }}"
                    (change)="onChangeOrderQuantity()" class="textbox textbx" type="text"
                    [(customModel)]="licensePricing.orderQuantity" /></uitk-form-field>
              </td>
              <td>
                <uitk-form-field><input appDiscountFormat uitkInput id="discount-{{ orderDetail.id }}"
                    (blur)="onChangeChegSetDiscount()" class="textbox textbx" type="text"
                    [(customModel)]="licensePricing.discount" /></uitk-form-field>
              </td>
              <td>
                <uitk-form-field><input appPriceFormat uitkInput id="unitPrice-{{ orderDetail.id }}"
                    (blur)="onChangeChegSetUnitPrice()" class="textbox textbx" type="text"
                    [(customModel)]="licensePricing.unitPrice" /></uitk-form-field>
              </td>
              <td>${{ licensePricing.extendedPrice | number:'1.2-2' }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Comments -->
  <div class="row mb-2">
    <div class="col-sm-12">
      <div class="row">
        <label class="col-lg-2 align-self-start" uitkLabel>Comments</label>
        <div class="col-lg-10">
          <uitk-form-field>
            <textarea uitkTextarea name="textField" uitkId="comment" uitkInput
              [(ngModel)]="comments" style="height: 150px; "></textarea>
          </uitk-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
