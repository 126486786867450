export class RetryCCDto {
    orderIds: number[] = [];
  userName = '';
  emailType = '';
  userCode = '';
}

export class DeclinedCCDto extends RetryCCDto{

}
