import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModeService } from 'src/app/services/mode.service';
import { Mode } from 'src/app/enums/mode.enum';
import { CashInquirySearchDto } from 'src/app/dtos/cash-inquiry-search-dto.model';
import { CashinquiryService } from 'src/app/services/cashinquiry.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ConfigService } from 'src/app/services/config.service';
import { DatePipe } from '@angular/common';
import { UITKDatePickerComponent } from '@uitk/angular';

@Component({
  selector: 'app-cash-inquiry',
  templateUrl: './cash-inquiry.component.html',
  styleUrls: ['./cash-inquiry.component.css']
})

export class CashInquiryComponent {
  @ViewChild('datePic') datePic!: UITKDatePickerComponent;
  collapsiblePanel = true;
  openPanel = true;
  collapsedHeader = false;
  Mode= Mode;
  panelBackground = "uitk-l-grid-open";
  invoiceNumber='';
  referenceNumber='';
  paidAmt=0;
  paidDate='';
  showResults=false;
  paidAmtConvert='';
  requiredDatePickerForm = new UntypedFormGroup({
    datePicker: new UntypedFormControl(''),
  });
  date='';
  constructor(
    public modeService: ModeService,
    private readonly cashInquiryService: CashinquiryService,
    private readonly configService: ConfigService ) { }

  setPanelBGHeight() {
    const panelBackgroundOpen = "uitk-l-grid-open";
    this.collapsedHeader = !this.collapsedHeader;
    if (this.panelBackground === panelBackgroundOpen) {
      this.panelBackground = "uitk-l-grid1-close";
    }
    else {
      this.panelBackground = panelBackgroundOpen;
    }
  }
  clearSearch(){
    this.configService.resetIdleTimeout();
    this.invoiceNumber='';
    this.referenceNumber='';
    this.paidAmt=0;
    this.paidDate='';
    this.requiredDatePickerForm?.controls?.datePicker.reset();
  }
  search() {
    this.configService.resetIdleTimeout();
    this.showResults=false;
    this.modeService.mode = Mode.CashInquiryWait;
    const searchOperator=new CashInquirySearchDto();
    searchOperator.invoiceNumber=this.invoiceNumber;
    searchOperator.referenceNumber=this.referenceNumber;
    searchOperator.paidAmount= this.paidAmt;
    searchOperator.paidDate=this.requiredDatePickerForm?.controls?.datePicker.value?this.requiredDatePickerForm?.controls?.datePicker.value:'';
    this.cashInquiryService.cashInquiries = null;
    this.cashInquiryService.searchCashInquirys(Mode.View,searchOperator);
    this.showResults=true;
  }
  formatDate() {
    let a = this.datePic.datePickerInput.model;
    if(a !== '' && a.match(/\D/) == null){
      let val = a.substring(4, 9) + '-' + a.substring(0, 2) + '-' + a.substring(2, 4);
      let o = {
        dateISO: val,
        disabled: false,
        other: false,
        selected: true,
        today: false
      }
      this.datePic.selectDay(o);
    }
  }
}
