import { Component, Input, OnInit } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { OrderNoteDto } from '../../../../dtos/order-note-dto.model';
import { RemoveHoldDto } from '../../../../dtos/remove-hold-dto';
import { ConfigService } from '../../../../services/config.service';
import { OrderService } from '../../../../services/order.service';
import { WorklistQueueService } from '../../../../services/worklistqueue-service';
import { SwalAlert } from '../../../../helpers/alert';

@Component({
  selector: 'app-remove-hold',
  templateUrl: './remove-hold.component.html',
  styleUrls: ['./remove-hold.component.css']
})

export class RemoveHoldComponent implements OnInit {
  private readonly _worklistQueueService: WorklistQueueService;
  private readonly _orderService: OrderService;
  private readonly _configService: ConfigService;
  @Input() dialog = { show: false };
  holdReason: IUITKSelectItemProps | undefined;
  holdReasonPickList: IUITKSelectItemProps[] = [];
  noteText = '';
  swalAlert = new SwalAlert();

  constructor(
    worklistQueueService: WorklistQueueService,
    orderService: OrderService,
    configService: ConfigService
  ) {
    this._worklistQueueService = worklistQueueService;
    this._orderService = orderService;
    this._configService = configService;
  }

  ngOnInit(): void {    
    this.holdReasonPickList = this._configService.getPickList("RELHREASON");
  }

  removeHold(): void {
    const currentUser = this._configService.getMyMSId();
    const orderNoteDto = new OrderNoteDto();
    orderNoteDto.noteText = this.holdReason?.label + (this.noteText?('- ' + this.noteText):"");
    orderNoteDto.createdDate = new Date();
    orderNoteDto.userCode =  this._configService.getMyUserCode();
    orderNoteDto.userName = this._configService.getMyFullName();

    const removeHoldDto = new RemoveHoldDto();
    removeHoldDto.orderNoteDto = orderNoteDto;
    removeHoldDto.orderIds.push(this._orderService.orderDto.id);

    this._worklistQueueService.saveRemoveHoldReason(removeHoldDto, currentUser).subscribe(() => {
      this.swalAlert.alert('Hold removed successfully.');
      this._orderService.orderDto.orderStatus = ' ';
      this._orderService.orderDto.holdReasonCode = null;
      this._orderService.recalculateFields(this._orderService.orderDto);
    }, () => {
      this.swalAlert.alert('Hold failed to remove.');
    });

    this.dialog.show = false;
  }
}
