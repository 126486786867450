<div>
  <label uitkLabel class="lblConfigSet" style="line-height: 50px;">Add-ons</label>
</div>
<div class="uitk-l-grid__row">
  <div class="uitk-l-grid__col" style="max-width: 1100px;">
    <div id="loadingSpinner" class="uitk-l-grid" *ngIf="loading">
      <uitk-loading-indicator uitkId="spinner" [visibleText]="visibleTextValue" spinnerSize="large">
      </uitk-loading-indicator>
    </div>

    <app-void-item-dialog [dialog]="voidItemDialog" (dialogClosed)="onVoidLineItemDialogCancel()" [selectedOrder]="selectedOrderDetail"></app-void-item-dialog>

    <div *ngIf="!loading" class="uitk-c-table--responsive">
      <table uitkTable [dataSource]="webAppDto" class="uitk-c-table" aria-describedby="invoiceNo">
        <thead class="uitk-c-table__head">
          <tr style="text-align: left">
            <th *ngFor="let headerName of tableHeader" scope="col">
              {{ headerName }}
            </th>
          </tr>
        </thead>
        <tbody class="uitk-c-table__body">
          <ng-container *uitkTableRowBody="let webAppDto">
            <tr *ngIf="webAppDto" class="uitk-c-table__row">
              <td style="vertical-align:middle;"
                [ngStyle]="{'border-bottom':webAppDto.productCode === 'WA29' &&  webAppDto.isSelected? 'none' : '1px solid #D0D0CE' }">
                <uitk-form-field>
                  <input type="checkbox" class="checkBoxInGrid" uitkCheckbox name="selectRowCheckBox"
                    [(ngModel)]="webAppDto.isSelected"
                    (change)="onChangeRecordSelction(webAppDto.isSelected, webAppDto)" />
                </uitk-form-field>
              </td>
              <td style="vertical-align:middle;"
                [ngStyle]="{'border-bottom':webAppDto.productCode === 'WA29' &&  webAppDto.isSelected? 'none' : '1px solid #D0D0CE' }">
                {{ webAppDto.productCode }}
              </td>
              <td style="vertical-align:middle;"
                [ngStyle]="{'border-bottom':webAppDto.productCode === 'WA29' &&  webAppDto.isSelected? 'none' : '1px solid #D0D0CE' }">
                {{ webAppDto.description }}
              </td>
              <td
                [ngStyle]="{'border-bottom':webAppDto.productCode === 'WA29' &&  webAppDto.isSelected? 'none' : '1px solid #D0D0CE' }">
                <uitk-form-field>
                  <input type="text" [disabled]="!webAppDto.allowCustomPrice" uitkInput [(customModel)]="webAppDto.listPrice" appPriceFormat
                    name="myWebAppListPriceInput" id="myWebAppListPriceInput-{{ webAppDto.addonProductId }}"
                    (change)="onChangeListPrice(webAppDto)" style="width: 80px;" />
                </uitk-form-field>
              </td>
              <td
                [ngStyle]="{'border-bottom':webAppDto.productCode === 'WA29' &&  webAppDto.isSelected? 'none' : '1px solid #D0D0CE' }">
                <uitk-form-field>
                  <input type="text" uitkInput [(customModel)]="webAppDto.orderQuantity" appQuantityFormat
                    name="myWebAppQuantityInput" id="myWebAppQuantityInput-{{ webAppDto.addonProductId }}"
                    (change)="onChangeWebAppQuantity($event, webAppDto)" style="width: 80px;" />
                </uitk-form-field>
              </td>
              <td
                [ngStyle]="{'border-bottom':webAppDto.productCode === 'WA29' &&  webAppDto.isSelected? 'none' : '1px solid #D0D0CE' }">
                <uitk-form-field>
                  <input [disabled]="webAppDto.allowCustomStandard || trialChecked" type="text" uitkInput [(customModel)]="webAppDto.discount" appDiscountFormat
                    name="myWebAppDiscountInput" id="myWebAppDiscountInput-{{ webAppDto.addonProductId }}"
                    (change)="onChangeWebAppDiscount($event, webAppDto)" style="width: 80px;"/>
                </uitk-form-field>
              </td>
              <td
                [ngStyle]="{'border-bottom':webAppDto.productCode === 'WA29' &&  webAppDto.isSelected? 'none' : '1px solid #D0D0CE' }">
                <uitk-form-field>
                  <input [disabled]="webAppDto.allowCustomPrice || trialChecked" type="text" uitkInput appPriceFormat [(customModel)]="webAppDto.unitPrice"
                    name="myWebAppUnitPriceInput" id="myWebAppUnitPriceInput-{{ webAppDto.addonProductId }}"
                    (change)="onChangeWebAppUnitPrice($event, webAppDto)" style="width: 80px;"/>
                </uitk-form-field>
              </td>
              <td style="vertical-align:middle;"
                [ngStyle]="{'border-bottom':webAppDto.productCode === 'WA29' &&  webAppDto.isSelected? 'none' : '1px solid #D0D0CE' }">
                {{ webAppDto.extendedPrice === undefined ? 0 : webAppDto.extendedPrice | currency:'USD' }}
              </td>
            </tr>
            <tr *ngIf="webAppDto.productCode=='WA29' && webAppDto.isSelected">
              <td></td>
              <td>Medicaid State</td>
              <td>
                <div class="message--error" *ngIf="showError">
                  Required
                </div>
                <select id="medicaidState" [(ngModel)]="webAppDto.medicaidState" class="form-select"
                  aria-label="select">
                  <option value='null' selected>Select</option>
                  <option *ngFor="let list of medicaidStatesList" value={{list.value}}>{{list.label}}</option>
                </select>
              </td>
              <td colspan="5"></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
