import { Component, EventEmitter, Output } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-add-return-to-batch',
  templateUrl: './add-return-to-batch.component.html',
  styleUrls: ['./add-return-to-batch.component.css']
})

export class AddReturnToBatchComponent {
  private readonly _configService: ConfigService;
  @Output() addRecordToBatchEvent = new EventEmitter<any>();

  constructor(
    public orderService: OrderService,
    configService: ConfigService
  ) {
    this._configService = configService;
  }

  onAddRecordToBatch() {
    this._configService.resetIdleTimeout();
    this.addRecordToBatchEvent.emit();
  }
}
