import { IUITKSelectItemProps } from "@uitk/angular";

export class CptDto {
  licensingModel = '';
  users = 0;
  countingUsers = 0;
  beds = 0;
  systemType : string | null = null;
  members = 0;
  labTests = 0;
  otherSystemType : string | null = null;
}
