<div *ngIf="dialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" uitkId="saveConfirmationModal-dialog" [closeBtn]=false
  style="width:fit-content;">
  <uitk-dialog-header>
    <div cdkDragHandle>
    Success <span class="uitk-icon uitk-icon-checkmark_filled md-icon"></span>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <p style="padding: 10px;">Save is successful for {{orderService.orderDto.id}}</p>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button id="okButton" class="uitk-c-button uitk-c-button--default uitk-u-margin--xs" (click)="okDialog()" appAutoFocus>
      Ok
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>