import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Mode } from '../../../enums/mode.enum';
import { ARSLabelDto } from '../../../dtos/ars-label-dto.model';
import { OrderDto } from '../../../dtos/order-dto.model';
import { ARSLabelService } from '../../../services/ars-label.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { ConfigService } from '../../../services/config.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-return-additional-actions',
  templateUrl: './return-additional-actions.component.html',
  styleUrls: ['./return-additional-actions.component.css']
})
  
export class ReturnAdditionalActionsComponent implements OnInit, OnDestroy {
  private readonly _configService: ConfigService;
  private readonly _orderService: OrderService;
  private readonly _arsLabelService: ARSLabelService;
  private readonly _router: Router;
  private readonly _authorizationService: AuthorizationService;
  @ViewChild("saveArsLabelBtn") saveArsLabelBtn!: ElementRef<HTMLInputElement>;
  @Input() iTaxRate: any = 0;
  @Input() iTotalTax: any = 0;
  hideAdditionalActions = true;
  displayAddReturnBatch = false;
  public isCollapsed = true;
  public showContainer = false;
  methodList: IUITKSelectItemProps[] = [];
  termsList: IUITKSelectItemProps[] = [];
  paymentPlansList: IUITKSelectItemProps[] = [];
  orderIsReadySubscription: Subscription | undefined;
  configIsReadySubscription: Subscription | undefined;
  arsLabelIsReadySubscription: Subscription | undefined;
  taxRate = 0;
  totalTax = 0;
  @Input() arsLabel: string| null = '';
  arsLabelDto!: ARSLabelDto;
  checkOrderDetails: any;
  Mode = Mode;
  notesDialog = { show: false };
  voidOrderDialog = { show: false };
  arsLabelDialog = { show: false };
  shipAndProcessDialog = { show: false };
  swalAlert = new SwalAlert();
  emailPattern = /^^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](\s?)(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
  showAttachmentsDialog = { show : false };
  
  constructor(configService: ConfigService, public orderService: OrderService, public modeService: ModeService,
    router: Router, arsLabelService: ARSLabelService, authorizationService: AuthorizationService) {
    this._configService = configService;
    this._orderService = orderService;
    this._arsLabelService = arsLabelService;
    this._router = router;
    this._authorizationService = authorizationService;
  }

  ngOnInit(): void {
    this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
      this.calculateTaxes(this.orderService.orderDto);
      this.orderService.recalculateFields(this.orderService.orderDto);
    });

    this.configIsReadySubscription = this._configService.configIsReady.subscribe(
      () => {
        this.showContainer = this._authorizationService.hasResource("ReturnInquiryAdditionalActionsExpand");
        if (!this._configService.reference) {
          throw new Error('References are not loaded');
        }

        this.methodList = this._configService.getPickList('PMETH');
        this.termsList = this._configService.getPickList('PTERMS');
        this.paymentPlansList = this._configService.getPickList("PAYMNTPLAN");
        this.orderService.getOrderInformation(this.orderService.orderDto.id);
      }
    );

    this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
      this.arsLabel = this.orderService.orderDto.arsLabel;
      if(this.arsLabel)
      {
        this._arsLabelService.getARSLabelDetails(this._orderService.orderDto.id).subscribe((data: ARSLabelDto) => {
          this._arsLabelService.arsLabelDto = data;
          this._arsLabelService.arsLabelId =  data.id;
        });
    }
      this.arsLabel = this.orderService.orderDto.arsLabel;
      this.orderService.arsLabelInDb = this.orderService.orderDto.arsLabel;
    });

    this._configService.loadConfigurations();

  }

  calculateTaxes(returnOrderDto: OrderDto) {
    this.taxRate = returnOrderDto.orderDetailDtos.map((o) => o.taxRate).reduce((a, b) => Math.max(a, b));
    this.iTaxRate = this.iTaxRate === 0 ? this.taxRate : this.iTaxRate;
    this.totalTax = returnOrderDto.orderDetailDtos.map((o) => o.taxAmount).reduce((a, b) => a + b) +
    returnOrderDto.orderDetailDtos.filter(item => item.productCode === '0048').reduce((sum, item) => sum + item.extendedPrice, 0);
    this.iTotalTax = this.iTotalTax === 0 ? this.totalTax : this.iTotalTax;
  }

  navigateToInvoices(orderDto: OrderDto) {
    this._router.navigate([]).then(() => {
      window.open('/invoices/' + orderDto.id, '_blank');
    });
  }

  openARSLabelDialog(): void{
    if (this.orderService.orderDto.inUseBy || this.orderService.orderDto.inUseDate || this.orderService.orderDto.pendingDate) {
      this.swalAlert.alert('Return is already in use or pending shipment and it is locked.');
      return;
    }

    this.arsLabelDialog.show = true;
    this.orderService.triggerEditMethodOnReturn();
    setTimeout(() => {
      this.saveArsLabelBtn.nativeElement.focus();
    }, 2000);
  }
  saveARSLabel(): void {
    this.saveARSLabelDetails();
  }

  closeARSLabelDialog(): void {
    this.arsLabelDialog.show = false;
    this._arsLabelService.isArsSaveCalled = false;
    if(this.orderService.returnEditWasTriggered)
    {
      this.orderService.triggerRevertMethodOnReturn();
    }
  }

  validateARSLabelDetails(): boolean {
    let isValidated: boolean = true;

    if (this._arsLabelService.selectedARSYesNo!.label === 'No') {
      isValidated = true;
    }
    else {
        isValidated = this.validateEmailAddress(isValidated);
    }

    return isValidated;
  }

  validateEmailAddress(isValidated:any){
    if (this._arsLabelService.arsEmailAddress === '') {
      isValidated = false;
      this._arsLabelService.showARSLabelErrors = true;
      this._arsLabelService.arsLabelErrors = 'Please enter email address';

      setTimeout(() => {
        this._arsLabelService.showARSLabelErrors = false;
      }, 3000);
    }

    if (this._arsLabelService.arsEmailAddress !== '' && !new RegExp(this.emailPattern).test(this._arsLabelService.arsEmailAddress)) {
      isValidated = false;
      this._arsLabelService.showARSLabelErrors = true;
      this._arsLabelService.arsLabelErrors = 'Please enter valid email address';

      setTimeout(() => {
        this._arsLabelService.showARSLabelErrors = false;
      }, 3000);
    }
    return isValidated;
  }

  saveARSLabelDetails(): void {
    debugger;
    if (this.validateARSLabelDetails()) {
      const arsLabelDto: ARSLabelDto = new ARSLabelDto();
      arsLabelDto.orderId = this._orderService.orderDto.id;
      arsLabelDto.id = this._arsLabelService.arsLabelId;

      if (this._arsLabelService.selectedARSYesNo!.label === 'Yes') {
          arsLabelDto.email = this._arsLabelService.arsEmailAddress;
          arsLabelDto.deliveryMethod = 'Email';
        arsLabelDto.arsLabel = this._orderService.orderDto.arsLabel = this._arsLabelService.selectedARSYesNo!.label === 'Yes' ? 'ARS ' + arsLabelDto.deliveryMethod : null;
        this._arsLabelService.arsLabelDto = arsLabelDto;
        this._arsLabelService.isArsSaveCalled = true;
        this.orderService.triggerSaveMethodOnReturn(false);
        this.closeARSLabelDialog();
        this.arsLabel = arsLabelDto.arsLabel;
      }
      else {
        this.closeARSLabelDialog();
      }
    }
  }

  resetARSLabelServiceVariables(): void {
    this._arsLabelService.selectedARSYesNo = null;
    this._arsLabelService.showARSEmailAddressSection = true;
    this._arsLabelService.arsLabelId = 0;
    this._arsLabelService.arsEmailAddress = '';
    this._arsLabelService.showARSLabelErrors = false;
    this._arsLabelService.arsLabelErrors = '';
  }

  resendReturn() {
    this.orderService.resendOrder(this.orderService.orderDto.id);
  }

  clearInUse() {
    this.orderService.clearInUse(this.orderService.orderDto.id);
  }

  clearPending() {
    this.orderService.clearPending(this.orderService.orderDto.id);
  }

  onChangeArsLabel(){
    this.orderService.orderDto.arsLabel = this.arsLabel;
    this._arsLabelService.arsLabelDto.arsLabel = this.arsLabel;
    this._arsLabelService.isArsLabelChanged = true;
  }
  createReship(){
    localStorage.setItem('reshipReturnId', JSON.stringify(this.orderService.orderDto.id));
    this._router.navigateByUrl('/order-entry/reship');
  }
  ngOnDestroy(): void {
    this.orderIsReadySubscription?.unsubscribe();
    this.configIsReadySubscription?.unsubscribe();
  }
}
