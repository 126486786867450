import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Mode } from '../enums/mode.enum';
import { CashInquirySearchResultsDto } from '../dtos/cash-inquiry-search-results-dto.model';
import { ConfigService } from './config.service';
import { ModeService } from './mode.service';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root'
})

export class CashinquiryService {
  private readonly _httpClient: HttpClient;
  private readonly _configService: ConfigService;
  private readonly _modeService: ModeService;
  gotCashInquirySearchResultsEmitter = new Subject<CashInquirySearchResultsDto>();
  error = new Subject();
  cashInquiries: CashInquirySearchResultsDto | null = null;
  filterObj: any;
  swalAlert = new SwalAlert();

  constructor(httpClient: HttpClient, configService: ConfigService, modeService: ModeService) {
    this._httpClient = httpClient;
    this._configService = configService;
    this._modeService = modeService;
  }

  searchCashInquirys(errorMode: Mode, filtersObj: any, overrideWait: boolean = false): void {
    if (this.cashInquiries) {
      this._modeService.mode = Mode.View;
      this.gotCashInquirySearchResultsEmitter.next(this.cashInquiries);
      return;
    }

    this._modeService.mode = overrideWait ? Mode.View : Mode.CashInquiryWait;

    this._httpClient.post<CashInquirySearchResultsDto>(`${this._configService.accountingApiUrl}/SearchCashInquiry`, filtersObj)
      .subscribe((data: CashInquirySearchResultsDto) => {
        this.cashInquiries = data;
        this.filterObj = filtersObj;
        this._modeService.mode = Mode.View;
        this.gotCashInquirySearchResultsEmitter.next(data);
      }, (error: any) => {
        this._modeService.mode = errorMode;
        console.log(error);
        this.swalAlert.alert('An error occurred while getting list of cash inquiries.  Please try again.');
      });
  }
}
