import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderNoteDto } from '../dtos/order-note-dto.model';
import { ConfigService } from './config.service';
import { ErrorHandlingService } from './error-handling.service';
import { ModeService } from './mode.service';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root'
})

export class OrderNotesService {
  orderNotesDto: OrderNoteDto[] = [];
  swalAlert = new SwalAlert();

  constructor(
    private readonly httpClient: HttpClient, 
    private readonly configService: ConfigService,
    private readonly modeService: ModeService,
    private readonly errorHandlingService: ErrorHandlingService) {
  }

  async getOrderNotes(orderId: number): Promise<OrderNoteDto[]> {
    return new Promise(resolve => {
      const waitText = 'Loading notes.'
      this.modeService.addToWaitList(waitText);

      this.httpClient.get<OrderNoteDto[]>(`${this.configService.apiUrl}/Order/GetOrderNotes/${orderId}`).subscribe({
        next: (data: OrderNoteDto[]) => {
          this.modeService.removeFromWaitList(waitText);
          resolve(data);
        },
        error: async (error: HttpErrorResponse) => {
          this.modeService.removeFromWaitList(waitText);

          const errorMessage = this.errorHandlingService.getErrorMessage(error);
          if (!errorMessage.includes("System.ArgumentException: Unable to find order notes")) {
            await this.swalAlert.alert(errorMessage);
          } 
          
          resolve([]);
        }
      });
    });
  }
}
