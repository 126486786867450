import { PremiumDto } from "./premium-dto";
import { ProductDto } from "./product-dto.model";
import { TierDto } from "./tier-dto.model";

export class ItemOfferDto {
    offerItemKey = 0;
    discountAmount = 0;
    percentDiscount = false;
    discountAndPremium = false;
    premiums: PremiumDto[] = [];
    allPremiums = false;
    repeatPremium = false;
    premiumQualifyAmount: number | null = 0;
    status = '';
    script = '';
    expirationDate: Date | null = null;
    tiers: TierDto[] = [];
    products: ProductDto[] = [];
    description = '';
    onlinePercentOff = 0;
    onlineDollarOff = 0;
    onlineDiscountAndPremium = false;

}

