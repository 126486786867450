import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IUITKColumnState, UITKSortDirection, UITKTableDataSource, UITKTableSortDirective, IUITKPaginationConfig, 
  UITKHeadingLevel,IUITKPaginationEntriesPerPage, IUITKPaginationEvent, PAGINATION_EVENTS } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { InvoiceDetailsDto } from '../../dtos/invoice-details-dto.model';
import { AccountsReceivableService } from '../../services/accounts-receivable.service';
import { InvoicesService } from '../../services/invoices.service';
import { InvoiceEditComponent } from 'src/app/components/accounts-receivable/invoice-edit/invoice-edit.component';
import { AuthorizationService } from '../../services/authorization.service';
import { FilterBarComponent } from '../filter-bar/filter-bar.component';

@Component({
  selector: 'app-invoice-results',
  templateUrl: './invoice-results.component.html',
  styleUrls: ['./invoice-results.component.css'],
  providers:[InvoiceEditComponent]
})
  
export class InvoiceResultsComponent implements OnInit {
  @Input() invoiceNumber = '';
  @ViewChild('sortTable') uitkTableSort!: UITKTableSortDirective;
  @ViewChild('filterBar') filterBar!: FilterBarComponent;

  currentPageSize: number = 500;
  paginationConfig: IUITKPaginationConfig = {
    id: 'pagination-id',
    description: 'Pagination',
    currentPageNumber: 1,
    displayingPagesTextHeadingLevel: UITKHeadingLevel.h2,
    nextButtonLabel: '>>',
    previousButtonLabel: '<<',
  };

  entriesPerPage: IUITKPaginationEntriesPerPage = {
    pageSizeOptions: [{ value: 500, selected: true }],
  };

  totalRecords = 1;

  constructor(public accountsReceivableService: AccountsReceivableService,
    public invoicesService: InvoicesService,
    public invoiceEditComponent: InvoiceEditComponent,
    private readonly authorizationService: AuthorizationService) { }
  
  getInvoicesSubscription: Subscription | undefined;
  getReloadSubscription: Subscription | undefined;
  getSelectedInvoicesSubscription: Subscription | undefined;
  dataSource = new UITKTableDataSource<InvoiceDetailsDto>([]);
  filteredDataSource = new UITKTableDataSource<InvoiceDetailsDto>([]);
  data: InvoiceDetailsDto[] = [];
  selectedOrderRecord: InvoiceDetailsDto = new InvoiceDetailsDto;
  selectedInvoices: InvoiceDetailsDto[] = [];
  selectedInvoicesOti: { invoiceNumber: string; originalTransactionId: string }[] =[] ;
  selectedOrder = 0;
  allowCheckAll = false;
  enableCreditMemoCheckbox= false;
  selectAllInvoices: boolean = false;
  gridFilters: { fieldName: string, filterValue: string, filterApplied: boolean, columnName: string;  }[] = [];
  gridSort: { sortColumn: string, sortDirection: UITKSortDirection, currentPageSize: number } = { sortColumn: '', sortDirection: UITKSortDirection.ASC, currentPageSize: 500 };
  tableHeader = [
    { name: 'Invoice #', id: 'invoiceNumber' },
    { name: 'Invoice Date', id: 'invoiceDate' },
    { name: 'Order #', id: 'orderNumber' },
    { name: 'PO #', id: 'poNumber' },
    { name: 'Invoice Amt', id: 'invoiceAmount' },
    { name: 'Paid Amt', id: 'paidAmount' },
    { name: 'Balance', id: 'balance' },
    { name: 'Paid Date', id: 'paidDate' },
    { name: 'Type', id: 'type' },
    { name: 'Terms', id: 'paymentTerms' },
    { name: 'Method', id: 'paymentMethod' },
    { name: 'CC Token', id: 'tokenId' },
    { name: 'CC Last 4', id: 'lastFour' },
    { name: 'Exp', id: 'expirationDate' },
    { name: 'Auth Code', id: 'authorizationCode' },
    { name: 'Tracking #', id: 'trackingNumber' },
    { name: 'Ship to Name', id: 'shipToContactName' },
    { name: 'Ship to ID', id: 'shipToContactId' },
    { name: 'Order By Name', id: 'orderByContactName' },
    { name: 'Order By ID', id: 'orderByContactId' },
  ];

  expandableTableHeader = [
    { name: 'Item', id: 'producNumber' },
    { name: 'Description', id: 'description' },
    { name: 'Unit Price', id: 'unitPrice' },
    { name: 'Inv Qty', id: 'invQuantity' },
    { name: 'Disc %', id: 'discount' },
    { name: 'Tax Amt', id: 'tax' },
    { name: 'Ext Price', id: 'extendedPrice' }
  ];

  ngOnInit(): void {
   
    this.getInvoicesSubscription = this.accountsReceivableService.getInvoicesEmitter.
      subscribe((data: InvoiceDetailsDto[]) => {
        for(const row of data) {
          row.isSelected = false;
        }
        this.dataSource.data = [...data];
        this.filteredDataSource.data = [...data];
        this.selectedInvoices = [];
        this.selectedInvoicesOti = [];
        this.allowCheckAll = this.filteredDataSource.data.some(item => item.invoiceNumber !== 'Pending' && item.status !== 'V');
        this.tableHeader.forEach(header => {
          this.uitkTableSort.setDirection({ column: header.id, direction: UITKSortDirection.UNSORTED });
        });
        this.uitkTableSort.setDirection({ column: 'invoiceDate', direction: UITKSortDirection.DESC });
        this.gridSort.sortColumn = 'invoiceDate';
        this.gridSort.sortDirection = UITKSortDirection.DESC;
        this.gridSort.currentPageSize = this.currentPageSize;
        if (this.filterBar)
        {
          this.filterBar.allData=this.filteredDataSource.data;
          this.filterBar.applyFilters();
        }
        this.totalRecords = this.dataSource.data.length;
        this.filteredDataSource.data = this.dataSource.data.slice(0,this.currentPageSize);
      });

    this.getInvoicesSubscription = this.invoicesService.getInvoicesEmitterForOrderId.
      subscribe((data: InvoiceDetailsDto[]) => {
        for(const row of data) {
          row.isSelected = false;
        } 
        this.dataSource.data = data;
        this.filteredDataSource.data = data;

        this.allowCheckAll = this.filteredDataSource.data.some(item => item.invoiceNumber !== 'Pending' && item.status !== 'V');

        this.selectedInvoices = [];
        this.selectedInvoicesOti = [];
        this.tableHeader.forEach(header => {
          this.uitkTableSort.setDirection({ column: header.id, direction: UITKSortDirection.UNSORTED });
        });
        this.uitkTableSort.setDirection({ column: 'invoiceDate', direction: UITKSortDirection.DESC });
      });

    this.getReloadSubscription = this.accountsReceivableService.getReloadEmitter.
      subscribe((data: boolean) => {
        if (data) {
          this.accountsReceivableService.reInvoiceLoad();
        }
      });

    this.getSelectedInvoicesSubscription = this.accountsReceivableService.getSelectedInvoicesEmitter.
      subscribe((invoices: InvoiceDetailsDto[]) => {
        if (this.selectedInvoices !== invoices && invoices.length === 0) {
          this.selectAllInvoices = false;
          this.selectAll();
        }
      });

      if(this.authorizationService.hasResource("arinquiryinvoicecreditmemocheckbox"))
      {
        this.enableCreditMemoCheckbox=true;
      }
  }

  onPaginationChange(event: any) {
    const pageEvent: IUITKPaginationEvent = event;
    this.paginationConfig.totalPagesCount = Math.ceil(pageEvent.totalRecords / pageEvent.entriesPerPage);

    switch (pageEvent.event) {

      case PAGINATION_EVENTS.ENTRIES_PER_PAGE:
        if (this.paginationConfig.totalPagesCount < this.paginationConfig.currentPageNumber) {
          this.paginationConfig.currentPageNumber = this.paginationConfig.totalPagesCount;
        }
        break;
      case PAGINATION_EVENTS.PREVIOUS_PAGE:
        this.paginationConfig.currentPageNumber = pageEvent.state.currentPageNumber;
        if (this.paginationConfig.currentPageNumber == 1) {
          this.filteredDataSource.data = this.dataSource.data.slice(0, this.currentPageSize);
        }
        else {
          this.filteredDataSource.data = this.dataSource.data.slice(this.currentPageSize * (this.paginationConfig.currentPageNumber - 1), this.currentPageSize * (this.paginationConfig.currentPageNumber));
        }
        break;
      case PAGINATION_EVENTS.NEXT_PAGE:
        this.paginationConfig.currentPageNumber = pageEvent.state.currentPageNumber;
        if (this.paginationConfig.currentPageNumber == this.paginationConfig.totalPagesCount) {
          this.filteredDataSource.data = this.dataSource.data.slice(this.currentPageSize * (this.paginationConfig.currentPageNumber - 1), pageEvent.totalRecords);
        }
        else {
          this.filteredDataSource.data = this.dataSource.data.slice(this.currentPageSize * (this.paginationConfig.currentPageNumber - 1), this.currentPageSize * (this.paginationConfig.currentPageNumber));
        }
        break;
      case PAGINATION_EVENTS.GO_TO_PAGE:
        if (this.paginationConfig.currentPageNumber == 1) {
          this.filteredDataSource.data = this.dataSource.data.slice(0, this.currentPageSize);
        }
        else if (this.paginationConfig.currentPageNumber == this.paginationConfig.totalPagesCount) {
          this.filteredDataSource.data = this.dataSource.data.slice(this.currentPageSize * (this.paginationConfig.currentPageNumber - 1), pageEvent.totalRecords);
        }
        else {
          this.filteredDataSource.data = this.dataSource.data.slice(this.currentPageSize * (this.paginationConfig.currentPageNumber - 1), this.currentPageSize * (this.paginationConfig.currentPageNumber));
        }
        this.paginationConfig.currentPageNumber = pageEvent.state.currentPageNumber;
        break;
    }
  }

  onSortChange(event: IUITKColumnState): void {
    this.gridSort.sortColumn = event.column;
    this.gridSort.sortDirection = event.direction;
    this.gridSort.currentPageSize = this.currentPageSize;
    this.filteredDataSource.data = this.dataSource.sortData(this.filteredDataSource.data, event.direction, event.column).slice(0, this.currentPageSize);
  }

  updateInvoiceSelectedList(data: InvoiceDetailsDto) {
    
    if (data.isSelected) {
      const selected =  { invoiceNumber: data.invoiceNumber, originalTransactionId: data.originalTransactionId};
      this.selectedInvoices.push(data);
      this.selectedInvoicesOti.push(selected);
    }
    else {
      this.selectedInvoices = this.selectedInvoices.filter(invoice => invoice.invoiceNumber !== data.invoiceNumber);
      this.selectedInvoicesOti = this.selectedInvoicesOti.filter(i => i.invoiceNumber !== data.invoiceNumber);
    }
    this.invoicesService.hasCCMemo =  this.selectedInvoicesOti.some(i => i.originalTransactionId?.startsWith('C'));
    if (this.selectedInvoices.length > 0) {
      const invoiceNumberVar = this.selectedInvoices[0];
      this.selectedOrderRecord = this.filteredDataSource.data.filter(i => i.invoiceNumber === invoiceNumberVar.invoiceNumber)[0]
      this.selectedOrder = this.selectedOrderRecord.orderNumber;
    }
    else {
      this.selectedOrder = 0;
    }

    this.selectAllInvoices = this.filteredDataSource.data.length === this.selectedInvoices.length ? true : false;
   
    this.accountsReceivableService.updateSelectedOrder(this.selectedOrder);
    this.invoiceEditComponent.disableEditButton(this.selectedInvoices);
  }

  selectAll() {
    this.selectedInvoices = [];

    for (let i = 0; i < this.filteredDataSource.data.length; i++) {
      if (this.selectAllInvoices && this.filteredDataSource.data[i].invoiceNumber !== 'Pending' && this.filteredDataSource.data[i].status !== 'V'
          && ((this.filteredDataSource.data[i].invoiceAmount < 0 && this.enableCreditMemoCheckbox) || this.filteredDataSource.data[i].invoiceAmount >= 0)  ) {
        this.filteredDataSource.data[i].isSelected = true;
      } else if (!this.selectAllInvoices) {
        this.filteredDataSource.data[i].isSelected = false;
      }

      if (this.filteredDataSource.data[i].isSelected === true) {
        this.selectedInvoices.push(this.filteredDataSource.data[i]);
      }
    }
    this.invoicesService.hasCCMemo =  this.selectedInvoicesOti.some(i => i.originalTransactionId?.startsWith('C'));
    if (this.selectedInvoices.length > 0) {
      const invoiceNumberVar = this.selectedInvoices[0];
      this.selectedOrderRecord = this.filteredDataSource.data.filter(i => i.invoiceNumber === invoiceNumberVar.invoiceNumber)[0]
      this.selectedOrder = this.selectedOrderRecord.orderNumber;
    }
    else {
      this.selectedOrder = 0;
    }

    this.accountsReceivableService.updateSelectedInvoicesList(this.selectedInvoices);
    this.accountsReceivableService.updateSelectedOrder(this.selectedOrder);
    this.invoiceEditComponent.disableEditButton(this.selectedInvoices);
  }

  openUpsTracking(trackingNumber: string): void {
    const url = `https://www.ups.com/${trackingNumber}`;
    window.open(url, "_blank");
  }

  ngOnDestroy() {
    this.getSelectedInvoicesSubscription?.unsubscribe();
    this.getReloadSubscription?.unsubscribe();
    this.getInvoicesSubscription?.unsubscribe();
  }
}
