<div class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkId="amaKeyuitkId" [(showModal)]="dialog.show" [closeBtn]=false style="width:fit-content">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Edit AMA Key
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
      <div class="row">
          <div class="col-lg-12">
            <table style="width: 100%;">
              <th scope="col"></th>
              <tr>
                  <td style="width: 95%;">
                    <uitk-form-field>
                      <input id="amaKeyId" uitkInput [(ngModel)]="amaKey" appEnableResource="OrderInquiryAdditionalActionsAMAKeyButton"  maxlength="50"  [disabled]= "orderService.orderDto.orderStatus === 'V'"/>
                    </uitk-form-field>
                  </td>
                  <td style="width: 5%;">
                    <button id="copy" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs uitk-icon uitk-icon-copy sm-icon amacopybutton"
                    (click)="copyAmaKey()" title="Copy"> </button>
                  </td>
              </tr>
          </table>
          </div>
      </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
      <button id="save" type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" [disabled]= "orderService.orderDto.orderStatus === 'V'"
          (click)="saveAmaKey()" appEnableResource="OrderInquiryAdditionalActionsAMAKeyButton">Save Changes</button>
      <button id="cancel" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
          (click)="closeDialog()">Cancel</button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>