import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UITKTableDataSource } from '@uitk/angular';
import { RecentQuotesOrOrdersDto } from '../../dtos/recent-quotes-or-orders-dto.model';
import { ModeService } from '../../services/mode.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-recent-quotes-or-orders-dialog',
  templateUrl: './recent-quotes-or-orders-dialog.component.html',
  styleUrls: ['./recent-quotes-or-orders-dialog.component.css']
})

export class RecentQuotesOrOrdersDialogComponent implements OnInit {
  @Input() recentQuotesOrOrdersDialog = { show: false };
  tableHeaders = ['Quote/Order#', 'Type', 'Order By Contact', 'Ship To Contact', 'Entry Date', 'Sub Total', 'Status', 'PO Number'];
  recentQuotesOrOrders = new UITKTableDataSource<RecentQuotesOrOrdersDto>([]);
  recentQuoteOrOrderDetailsDialog = { show: false };
  recentQuoteOrOrder = new RecentQuotesOrOrdersDto();
  highlightRow: number | undefined;

  constructor(private readonly orderService: OrderService, readonly modeService: ModeService, private readonly activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getRecentQuotesOrOrders();
  }

  async getRecentQuotesOrOrders(): Promise<void> {
    this.recentQuotesOrOrders.data = [];
    const result = await this.orderService.getRecentQuotesOrOrders(this.orderService.orderDto.billToDto.id);

    if (result) {
      this.recentQuotesOrOrders.data = result;

      this.recentQuotesOrOrders.data.forEach(element => {
        element.subTotal = element.recentQuoteOrOrderDetails.reduce((sum, item) => sum + item.extendedPrice, 0);
      });

      this.recentQuotesOrOrders.data = [...this.recentQuotesOrOrders.data];
    }

    setTimeout(() => {
      if (document.getElementById(`viewRecentQuotesOrOrders_closeBtn`)) {
        document.getElementById(`viewRecentQuotesOrOrders_closeBtn`)?.focus();
      }
    });
  }

  async getRecentQuoteOrOrderDetails(recentQuoteOrOrder: RecentQuotesOrOrdersDto, index: number): Promise<void> {
    this.highlightRow = index;
    this.recentQuoteOrOrder = recentQuoteOrOrder;
    this.recentQuoteOrOrderDetailsDialog.show = true;
  }

  async focusOrderType(): Promise<void> {
    this.activatedRoute.queryParams.subscribe(() => {
      if (!this.activatedRoute.snapshot.queryParams.orderId) {
        setTimeout(() => {
          if (document.getElementById(`contactId`)) {
            document.getElementById(`contactId`)?.focus();
          }
        });
      }
      else {
        setTimeout(() => {
          if (document.getElementById(`editBtn`)) {
            document.getElementById(`editBtn`)?.focus();
          }
        });
      }
    });
  }
}
