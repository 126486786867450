import Swal, { SweetAlertResult } from 'sweetalert2';
export class SwalAlert {

    async alert(message: string): Promise<SweetAlertResult<any> | null> {
        return new Promise(resolve => {
            setTimeout(() => {
                Swal.fire({
                    position: 'top',
                    text: message,
                    backdrop: true,
                    allowOutsideClick: false
                }).then((data) => {
                    resolve(data);
                })
            }, 100);
        });
    }
}
