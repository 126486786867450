import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { ShipToDto } from 'src/app/dtos/ship-to-dto.model';
import { ApprovalService } from 'src/app/services/approval.service';
import { Mode } from '../../../enums/mode.enum';
import { SourceType } from '../../../enums/source-type.enum';
import { Dialog } from '../../../helpers/dialog';
import { BillToDto } from '../../../dtos/bill-to-dto.model';
import { ContactDto } from '../../../dtos/contact-dto.model';
import { OrderByDto } from '../../../dtos/order-by-dto.model';
import { AuthorizationService } from '../../../services/authorization.service';
import { BillToService } from '../../../services/bill-to.service';
import { ConfigService } from '../../../services/config.service';
import { ContactService } from '../../../services/contact.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';

@Component({
  selector: 'app-account-information',
  templateUrl: './account-information.component.html',
  styleUrls: ['./account-information.component.css']
})

export class AccountInformationComponent implements OnInit {
  private readonly _configService: ConfigService;
  private readonly _billToService: BillToService;
  private readonly _authorizationService: AuthorizationService;
  private readonly _contactService: ContactService;
  private readonly _approvalService: ApprovalService;
  
  @Output() getContactList = new EventEmitter<any>();
  disableAccountAddLink = false;
  currentYear = new Date().getFullYear();
  stateList: IUITKSelectItemProps[] = [];
  state!: IUITKSelectItemProps | null;
  contact: ContactDto | null = null;
  Mode = Mode;
  Accountsearch=new Dialog<string>();
  contactDialogModal = new Dialog<string>();
  accountSearchLookupPopup = { show: false };
  specialities: string = '';
  billToIsReadySubscription: Subscription | undefined;
  loading = false;
  visibleTextValue = '';
  accountSearchKeyword = '';
  accountIdChanged = false;
  recentQuotesOrOrdersDialog = { show: false };
  showItemSearchDialog = new Dialog<string>();
  AccountSearchDialog=false;

  constructor(
    public orderService: OrderService,
    public modeService: ModeService,
    configService: ConfigService,
    billToService: BillToService,
    authorizationService: AuthorizationService,
    contactService: ContactService,
    approvalService: ApprovalService
  ) {
    this._configService = configService;
    this._billToService = billToService;
    this._authorizationService = authorizationService;
    this._contactService = contactService;
    this._approvalService = approvalService;
  }

  ngOnInit(): void {
    this.orderService.groupAccount='';
    this.orderService.salesPersonDisabled = true;
    this.disableAccountAddLink = !this._authorizationService.hasResource("OrderEntryAccountAddHyperlink");

    this.billToIsReadySubscription = this._billToService.billToIsReady.subscribe(() => {
      this.fillAccountInformationDetails(this._billToService.billToDto);
      console.log(this.orderService.orderDto.billToDto.groupAccountId,"this.orderService.orderDto.billToDto.groupAccountId");
      if(this.orderService.orderDto.billToDto.groupAccountId==null){
        this.orderService.groupAccount='';
      }
      else{
      //  this._billToService.getBillToInformationAsync(parseInt(this.orderService.orderDto.billToDto.groupAccountId), this.currentYear);
         console.log(this.orderService.orderDto.billToDto.company,"this.orderService.orderDto.billToDto.company1");
          this.orderService.groupAccount=this.orderService.orderDto.billToDto.groupAccountId+"-"
          +this.orderService.orderDto.billToDto.groupAccountName;
          console.log(this.orderService.orderDto.billToDto.groupAccountName,"this.orderService.orderDto.billToDto.groupAccountId");
         }
      this.orderService.orderDto.orderDetailDtos.forEach(a => a.salespersonCode = this.orderService.orderDto.accountOwnerCode);
    });
  }
  
  accountIdNgModelChange(accountId: number): void {
    this.orderService.orderDto.billToDto.id = accountId;
    this.accountIdChanged = true;
  }

  async AccountSearch() {
    await this.showItemSearchDialog.openAsync();
        //this.AccountSearchDialog=true;
  }

  async onAccountIdChange(event: any) {
    if (this.orderService.orderDto.billToDto.id === null || this.orderService.orderDto.billToDto.id === 0) {
      this.orderService.orderDto.billToDto = new BillToDto();
      this.accountSearchKeyword = '';
      this.orderService.groupAccount='';
      this.orderService.selectedAccount = '';
      this._contactService.contactSearchKeyword = '';
      this.orderService.orderDto.orderByDto = new OrderByDto();
      this.orderService.shiptoIsSelected = false;
      this.orderService.orderDto.shipToDto = new ShipToDto();
      this.orderService.orderDto.selectedsalesPersonName = '';
      this.orderService.orderDto.selectedslaesPersonNameEmail='';
      this.orderService.salesPersonDisabled = true;
      this.orderService.orderDto.orderByContactBinding = { id: '', label: '', value: '' };
      this.orderService.disableAccountEditViewRefreshLinks = true;
 
    }
    else if (this.orderService.orderDto.billToDto.id) {
      await this._billToService.getBillToInformationAsync(this.orderService.orderDto.billToDto.id, this.currentYear);
      this._billToService.reloadBillToDto();
      this._contactService.contactSearchKeyword = '';
      if(this.orderService.orderDto.accountOwnerCode !== '' && this.orderService.orderDto.accountOwnerCode !== undefined && this.orderService.orderDto.accountOwnerCode !== null && this.orderService.orderDto.selectedsalesPersonName === null ) 
      {
      this.orderService.orderDto.accountOwnerName = this._configService.getFullNameFromUserCode(this.orderService.orderDto.accountOwnerCode);
      this.orderService.orderDto.selectedsalesPersonName = this.orderService.orderDto.accountOwnerName;
      this.orderService.orderDto.selectedslaesPersonNameEmail=this.orderService.orderDto.email;
      }
      if(this.accountIdChanged){
        this.orderService.orderDto.orderByDto = new OrderByDto();
        this.orderService.shiptoIsSelected = false;
        this.orderService.orderDto.shipToDto = new ShipToDto();
        this.accountIdChanged = false;
      }
      if (this._billToService.billToDto.defaultPaymentTerms) {
        this.orderService.orderDto.paymentTerms = this._billToService.billToDto.defaultPaymentTerms;
      }
      if (this.orderService.orderDto.billToDto !== null) {
        this.fillAccountInformationDetails(this.orderService.orderDto.billToDto);
        this.orderService.salesPersonDisabled = false;
        this.orderService.disableAccountEditViewRefreshLinks = false;
      }
      else {
        this.orderService.orderDto.billToDto = new BillToDto();
        this.orderService.orderDto.orderByDto = new OrderByDto();
        this.orderService.salesPersonDisabled = true;
        this.orderService.disableAccountEditViewRefreshLinks = true;
      }
     // this._approvalService.refreshApprovalRequirementsSection();
      this.orderService.recalculateFields(this.orderService.orderDto);
      this.orderService.salesSearchKeyword = '';
      this.orderService.salesPersonDisabled = false;
    }
  }

  navigateToSFEditAccount() {
    window.open(
      `${this._configService.salesforceUrl}/apex/Accountform?id=${this.orderService.orderDto.billToDto.salesforceId}`
    );
  }

  navigateToSFAddAccount() {
    window.open(
      `${this._configService.salesforceUrl}/apex/Accountform?retURL=%2F001%2Fo&save_new=1&sfdc.override=1`
    );
  }
   navigateToSFViewAccount(salesforceAccountId: string) {
    window.open(`${this._configService.salesforceUrl}/apex/ViewAccount?id=${salesforceAccountId}`);
  }
  async refreshAccountDetails(): Promise<void> {
    await this._billToService.getBillToInformationAsync(this.orderService.orderDto.billToDto.id, this.currentYear);
  }
  
  async accountChangeBR(statusCode: string, sourceType: SourceType): Promise<void> {
    console.log('statusCode=' + statusCode);
    if (statusCode === 'BAD' || statusCode === 'BP' || statusCode === 'DEL' || statusCode === 'MGD' || statusCode === 'MRG') {
      this.orderService.sourceType = sourceType;
      console.log("before showing dialog");

      await this.contactDialogModal.openAsync();
    }
  }

  showAccountSearchLookupPopup(): void {
    if (this.accountSearchKeyword.trim() === '') {
      this.orderService.selectedAccount = '';
      this.accountSearchLookupPopup.show = true;
    }
    else{
      this.onAccountChangeClick(this.accountSearchKeyword);
    }
  }

  bindSelectedAccount(account: BillToDto): void {
    this.orderService.orderDto.billToDto = account;
    this.orderService.orderDto.accountOwnerCode = this.orderService.orderDto.billToDto.accountOwnerCode ?? this._configService.defaultAccountOwnerCode;
    this.fillAccountInformationDetails(account);
    this.orderService.disableAccountEditViewRefreshLinks = false;
  }

  async fillAccountInformationDetails(account: BillToDto): Promise<void> {
    this.accountSearchKeyword = this.orderService.orderDto.billToDto.company;
    this.specialities = '';

    if (this.orderService.orderDto.billToDto.specialties.length > 1) {
      this.specialities = this.orderService.orderDto.billToDto.specialties.join(', ');  
    }

    this._contactService.triggerGetContactList.next();
    //await this._contactService.getContactsAsync(account.id);
 
    await this.accountChangeBR(account.statusCode, SourceType.OrderByAccountChange);
    this.orderService.setPaymentMethodBR();
    this.orderService.recalculateFields(this.orderService.orderDto);
    
    if (this.orderService.orderDto.billToDto.id > 0 && this.modeService.mode === Mode.Edit && this.orderService.orderDto.id <= 0) {
      this.recentQuotesOrOrdersDialog.show = true;
    }
    else {
      this._approvalService.approvalsList = [];
      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    }
  }
  async onAccountChange(evt:any){
    this.accountSearchKeyword = evt.target.value;
  }
  async onAccountChangeClick(evt:any){
    this.visibleTextValue = 'Loading Records';
    this.loading = true;
    this.accountSearchKeyword= evt;
    await this._configService.getAccountSearchDetails("Name:"+this.accountSearchKeyword)
      .then((data: BillToDto[]) => {
        this.loading = false;
        if(data.length === 1 && data[0].company?.trim().toLowerCase() === this.accountSearchKeyword.trim().toLowerCase()){
          this.bindSelectedAccount(data[0]);
          this.orderService.disableAccountEditViewRefreshLinks = false;
        }else if(data.length > 0){
          this.orderService.selectedAccount = this.accountSearchKeyword;
          this.accountSearchLookupPopup.show = true;
        }
      });
  }
  async onAccountNameEnter(evt: any) {
    this.accountSearchKeyword = evt.target.value;
    this.showAccountSearchLookupPopup();
  }
  onAccountNameBlur() {
    setTimeout(() => {
      if (document.getElementById(`contactId`)) {
        document.getElementById(`contactId`)?.focus();
      }
    });
  }
}
