import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { OrderDetailsGridComponent } from '../../components/order-entry/order-details-grid/order-details-grid.component';
import { OrderEntryFooterComponent } from '../../components/order-entry/order-entry-footer/order-entry-footer.component';
import { OrderService } from "../../services/order.service";
import { ScrollToDirective } from '../../directives/scrollTo.directive';
import { Mode } from '../../enums/mode.enum';
import { SourceType } from '../../enums/source-type.enum';
import { Dialog } from '../../helpers/dialog';
import { ApprovalDto } from '../../dtos/approval-dto.model';
import { OrderApprovalInputDto } from '../../dtos/order-approval-input-dto.model';
import { SaveOrderExceptionDto } from '../../dtos/save-order-exception-dto.model';
import { ApprovalService } from '../../services/approval.service';
import { ConfigService } from '../../services/config.service';
import { ModeService } from '../../services/mode.service';
import { SwalAlert } from '../../helpers/alert';

@Component({
  selector: 'app-order-approval',
  templateUrl: './order-approval.component.html',
  styleUrls: ['./order-approval.component.css']
})
  
export class OrderApprovalComponent implements OnInit {
  private readonly _approvalService: ApprovalService;
  private readonly _configService: ConfigService;
  private readonly _modeService: ModeService;
  private readonly _elementRef: ElementRef;
  private readonly _orderDetailsGridComponent: OrderDetailsGridComponent;
  private readonly _orderEntryFooterComponent: OrderEntryFooterComponent;
  myDialogHeader = 'Summary and Approval';
  @Input() orderApprovalDialog !: Dialog<string>;
  splApprovalDetails: ApprovalDto[] = [];
  approvalSectionPanelClass = 'uitk-l-grid-open';
  collapsibleApprovalSectionPanel = true;
  openApprovalSectionPanel = false;
  selectedBusinessCategory: IUITKSelectItemProps | undefined;
  selectedBusinessSubCategory: IUITKSelectItemProps | undefined;
  selectedCompetitor: IUITKSelectItemProps | undefined;
  isBidSituation: IUITKSelectItemProps | undefined;
  knowCompetitorsOffer: IUITKSelectItemProps | undefined;
  areTheirIssues: IUITKSelectItemProps | undefined;
  orderApprovalInputDto: OrderApprovalInputDto = new OrderApprovalInputDto();  
  saveOrderExceptionDto = new SaveOrderExceptionDto();
  businessCategoryList: IUITKSelectItemProps[] = [];
  businessSubCategoryList: IUITKSelectItemProps[] = [];
  competitorList: IUITKSelectItemProps[] = [];
  yesOrNoList: IUITKSelectItemProps[] = [
    { id: '1', label: 'Yes', value: 'Yes' },
    { id: '2', label: 'No', value: 'No' }
  ];
  tableHeader = [
    { id: 1, name: 'Approval Levels' },
    { id: 2, name: 'Approver' },
    { id: 3, name: 'Threshold' }
  ];
  @ViewChild(ScrollToDirective) dir:any;
  updatedThresholds: boolean = false;
  swalAlert = new SwalAlert();

  constructor(
    approvalService: ApprovalService,
    public orderService: OrderService,
    configService: ConfigService,
    modeService: ModeService,
    elementRef: ElementRef,
    orderDetailsGridComponent: OrderDetailsGridComponent,
    orderEntryFooterComponent: OrderEntryFooterComponent 
  ) {
    this._approvalService = approvalService;
    this._configService = configService;
    this._modeService = modeService;
    this._elementRef = elementRef;
    this._orderDetailsGridComponent = orderDetailsGridComponent;
    this._orderEntryFooterComponent = orderEntryFooterComponent;
  }

  async ngOnInit(): Promise<void> {
    this.businessCategoryList = this._configService.getPickList("BUSCTGY");
    this.competitorList = this._configService.getPickList("COMPETITOR");
    this.businessSubCategoryList = this._configService.getPickList("SUBCTGY");
    const accountOwnerCode = this.orderService.orderDto?.accountOwnerCode ?? this.orderService.orderDto.billToDto.accountOwnerCode;
    const printRepCode = this.orderService.orderDto.billToDto.printRepCode?? '';
    this._approvalService.getApprovals(printRepCode ?? '', accountOwnerCode ?? '', this.orderService.orderDto.billToDto.accountTypeCode, this.orderService.orderDto.orderDetailDtos.filter(x => x.orderDetailStatus !== 'V')[0].productDto.salesOrganization === 'Optum', this.orderService.orderDto.orderType ?? '')
    .subscribe((data: ApprovalDto[]) => {
      data.filter(a => a.approvalLevel === 'Submitter')[0].approver = data
      .filter(a => a.approvalLevel === 'Submitter')[0].approver === '' 
      ? this._configService.getFullNameFromUserCode(accountOwnerCode)
       : data.filter(a => a.approvalLevel === 'Submitter')[0].approver;
       this.splApprovalDetails = data;
       if(!this.updatedThresholds){
        this.updateApprovalThresholds();
       }                  
    });
    this.orderApprovalInputDto = await this._approvalService.refreshApprovalFields(this.orderService.orderDto);
  } 

  updateApprovalThresholds(){
    let length = this.splApprovalDetails.length;
    for(let i=0;i<length-1;i++){
      this.splApprovalDetails[i].threshold = this.splApprovalDetails[i+1]?.threshold ?? 0.00;
    }
    if(length > 0){
      this.splApprovalDetails[length-1].threshold = 0.00
    }    
    this.updatedThresholds = true;
  }

  setPanelForApprovalSection(): void {
    const eleGridOpen = 'uitk-l-grid-open';
    if (this.approvalSectionPanelClass === eleGridOpen) {
      this.approvalSectionPanelClass = 'uitk-l-grid1-close';
    }
    else {
      this.approvalSectionPanelClass = eleGridOpen;
    }
  }

  async saveOrderException(): Promise<void> {
    if (this.orderService.orderDto.multiYearDtos.length > 0 || this.orderService.sourceType === SourceType.OrderEntryMutliyear) {
      this.orderService.orderDto.orderStatus = 'D';
    }
    else {
      this.orderService.orderDto.orderStatus = 'P';
    }
    this.orderApprovalDialog.close('Save');

    if (await this.orderService.saveOrderAsync() === "Success") {
      this.saveOrderExceptionDto.orderId = this.orderService.orderDto.id;
      this.saveOrderExceptionDto.competitorCode = this.selectedCompetitor?.value ?? null;
      this.saveOrderExceptionDto.businessCategoryCode = this.selectedBusinessCategory?.value ?? null;
      this.saveOrderExceptionDto.subCategoryCode = this.selectedBusinessSubCategory?.value ?? null;
      this.saveOrderExceptionDto.areTheirIssues = this.getSelectedValue(this.areTheirIssues?.value);
      this.saveOrderExceptionDto.knowCompetitorsOffer = this.getSelectedValue(this.knowCompetitorsOffer?.value);
      this.saveOrderExceptionDto.isBidSituation = this.getSelectedValue(this.isBidSituation?.value);
      this.saveOrderExceptionDto.finalMultiYearSave = (this.orderService.orderDto.multiYearDtos.length > 0 && this.orderService.sourceType === SourceType.OrderEntrySave);

      if (this.saveOrderExceptionDto.orderId !== 0) {
        this._approvalService.saveOrderException(this.saveOrderExceptionDto).subscribe(() => {
        }, error => {
          console.log(error);
          this.swalAlert.alert('An error occurred while saving Approval information. Please try again.');
        });
      }

      await this._orderEntryFooterComponent.saveConfirmationDialog.openAsync();
      await this._orderEntryFooterComponent.afterSave();

      switch (this.orderService.orderEntryFunctionType) {
        case 'Save&Clone':
          this._modeService.mode = Mode.Edit;

          if (document.getElementById(`contactId-shipTo-textbox`)) {
            setTimeout(() => {
              document.getElementById(`contactId-shipTo-textbox`)?.focus();
              (document.getElementById(`contactId-shipTo-textbox`) as HTMLInputElement)?.select();
            }, 1000);
          }
          break;
        case 'MultiYear':
          this.orderService.addYear(true);
          break;
      }
    }
  }

  closeDialog() {
    this.orderApprovalDialog.close('Cancel');
     if(this.orderService.orderDto.ccExpirationDate === "0199" && this.orderService.orderDto.ccLastFour ==="0000"){
       this.orderService.orderDto.ccExpirationDate = "";
       this.orderService.orderDto.ccLastFour = "";
     }
  }

  disableSave(): boolean {
    if (this.selectedCompetitor?.value === 'OTHER' && (this.saveOrderExceptionDto.otherCompetitor === '' || this.saveOrderExceptionDto.otherCompetitor === null)) {
      return true;
    }
    if (this.isBidSituation?.label === 'Yes' || this.knowCompetitorsOffer?.label === 'Yes' || this.areTheirIssues?.label === 'Yes') {
      if (this.saveOrderExceptionDto.comments === '' || this.saveOrderExceptionDto.comments === null) {
        return true;
      }
    }
    return false;
  }

  afterApprovalPopupHides(): void {
    this._modeService.mode = Mode.Edit;

    if (!this.orderService.orderDto.orderDetailDtos.some(o => o.productId === 0)) {
      this._orderDetailsGridComponent.addNewLineItem();  
    }

    if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`)) {
      this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`).focus();
    }
    this.dir!.target = "#divLeftOrderDetailsGrid";
    this.dir?.scrollToPosition();    
  }
  
  getSelectedValue(element: string| undefined) : boolean | null{
    if(element === 'Yes'){
      return true;
    }
    else if(element === 'No'){
      return false;
    }
    else{
      return null;
    }
  }
}
