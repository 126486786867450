<div *ngIf="dialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" [closeBtn]=false>
  <uitk-dialog-header>
    <div cdkDragHandle>
      <h4 class="modal-title pull-left">
        Save Future Orders
      </h4>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <span>
      Changes to future orders must be applied manually.
    </span>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="stopFOSave()" #saveFutureOrderBtn>
      OK
    </button>
  </uitk-dialog-actions>

  <!-- <uitk-dialog-header>
    <h4 class="modal-title pull-left">
      Save Future Orders
    </h4>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <span>
      Do you want to apply these changes to future orders?
    </span>
    <span *ngIf="itemsRestrictedFromMUDealsList.length > 0">
      <br>
      Items {{ itemsRestrictedFromMUDeals }} will not be added to future orders.
    </span>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="allowFOSave()" #saveFutureOrderBtn>
      Yes
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="stopFOSave()">
      No
    </button>
  </uitk-dialog-actions> -->
</uitk-dialog>
  </div>
</div>
