import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order-entry-mini',
  templateUrl: './order-entry-mini.component.html',
  styleUrls: ['./order-entry-mini.component.css']
})

export class OrderEntryMiniComponent implements OnInit {
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _orderService: OrderService;
  private readonly _router: Router;
  @ViewChild('accountIdInput') accountIdInput!: ElementRef<HTMLInputElement>;
  accountId = '';
  accountSearch = '';

  constructor(
    activatedRoute: ActivatedRoute,
    orderService: OrderService,
    router: Router) {
    this._activatedRoute = activatedRoute;
    this._orderService = orderService;
    this._router = router;

    this._activatedRoute.params.subscribe(() => {
      setTimeout(() => this.accountIdInput.nativeElement.focus());
    });
  }

  ngOnInit(): void {
    setTimeout(() => this.accountIdInput.nativeElement.focus());
  }

  async enterOrder() {
    const orderId: number | null = await this._orderService.addOrder(+this.accountId);
    
    if (orderId) {
      this._router.navigate(['/order', orderId]);
    }
  }
}
