import { TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { IUITKSelectItemPropsDto } from 'src/app/dtos/iuitkselectitemprops-dto.model';
import * as states from 'src/assets/json/states_USA.json';
import { Mode } from '../../enums/mode.enum';
import { OrderDto } from '../../dtos/order-dto.model';
import { OrderSearchFilterDto } from '../../dtos/order-search-filter-dto.model';
import { OrderSearchFilter } from '../../dtos/order-search-filter.model';
import { OrderSearchInputDto } from '../../dtos/order-search-input-dto.model';
import { AuthorizationService } from '../../services/authorization.service';
import { ConfigService } from '../../services/config.service';
import { ModeService } from '../../services/mode.service';
import { OrderService } from '../../services/order.service';
import JSZip from 'jszip';  
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.css']
})

export class OrderSearchComponent implements OnInit, OnDestroy {
  private readonly _configService: ConfigService;
  private readonly _titleCasePipe: TitleCasePipe;
  private readonly _authorizationService: AuthorizationService;
  @ViewChild('orderNumberInput') orderNumberInput: HTMLInputElement | undefined;
  configIsReadySubscription: Subscription | undefined;
  orderSearchFilterDto: OrderSearchFilterDto = new OrderSearchFilterDto();
  Mode = Mode;
  showError = false;
  panelBGOpen = "uitk-l-grid-open";
  panelBG = this.panelBGOpen;
  showResults = false;
  stateList: IUITKSelectItemProps[] = [];
  countries: IUITKSelectItemProps[] = [];
  orderTypeList: IUITKSelectItemProps[] = [];
  orderStatusList: IUITKSelectItemProps[] = [];
  orderSearchResultsData: any[] = []; // Initialize the 'orderSearchResultsData' property

  constructor(
    public modeService: ModeService,
    public orderService: OrderService,
    configService: ConfigService,
    titleCasePipe: TitleCasePipe,
    authorizationService: AuthorizationService
  ) {
    this._configService = configService;
    this._titleCasePipe = titleCasePipe;
    this._authorizationService = authorizationService;
  }

  ngOnInit(): void {
    this.stateList = JSON.parse(JSON.stringify((states as any).default));

    this.stateList.forEach(element => {
      element.disabled = false;
    });

    this.configIsReadySubscription = this._configService.configIsReady.subscribe(() => {
      this.countries = [...this._configService.reference!.countryDtos]
        .sort((a, b) => a.countryName < b.countryName ? -1 : 1)
        .map(c => ({ id: c.alpha2Code, label: this._titleCasePipe.transform(c.countryName), value: c.alpha2Code }));
      this.orderTypeList = this._configService.getPickList('ORDERTYPE');
      this.orderStatusList = this._configService.getPickList('ORDERSTATUS');

       if (this._configService.orderEntryFeature && !this._authorizationService.hasResource('OrderEntryMenu')) {
         this.orderStatusList.filter(item => item.id === 'D')[0].disabled = true;
         this.orderStatusList.filter(item => item.id === 'P')[0].disabled = true;
         this.orderStatusList.filter(item => item.id === 'M')[0].disabled = true;
         this.orderStatusList.filter(item => item.id === 'R')[0].disabled = true;
         this.orderTypeList.filter(item=> item.id === 'Q')[0].disabled = true;
       }

      if (Object.keys(localStorage).indexOf('backToSearchResults') > -1) {
        if (localStorage.getItem('backToSearchResults') === 'true') {
          this.fillOrderSearchFilters();
        }
      }
    });

    this._configService.loadConfigurations();
    this.orderNumberInput?.focus();
  }

  fillOrderSearchFilters() {
    if (!(Object.keys(localStorage).indexOf('searchOperator') > -1)) {
      return;
    }
    const searchFilters = JSON.parse(localStorage.getItem('searchOperator') as string) as OrderSearchInputDto;
    if (searchFilters.filters.length < 0) {
      return;
    }
    localStorage.setItem('backToSearchResults', 'false');
    this.orderSearchFilterDto.orderNumber = this.getFiltersSearchValue(searchFilters.filters, 'OrderNumber');
    this.orderSearchFilterDto.orderType = this.orderTypeList.filter(o => o.value === this.getFiltersSearchValue(searchFilters.filters, 'OrderType'));
    this.orderSearchFilterDto.orderStatus = this.orderStatusList.filter(o => o.value === this.getFiltersSearchValue(searchFilters.filters, 'OrderStatus'));
    this.orderSearchFilterDto.accountName = this.getFiltersSearchValue(searchFilters.filters, 'AccountName');
    this.orderSearchFilterDto.accountNumber = this.getFiltersSearchValue(searchFilters.filters, 'AccountNumber');
    this.orderSearchFilterDto.webReferenceNumber = this.getFiltersSearchValue(searchFilters.filters, 'WebReferenceNumber');
    this.orderSearchFilterDto.poNumber = this.getFiltersSearchValue(searchFilters.filters, 'PONumber');
    this.orderSearchFilterDto.creditCardLastFour = this.getFiltersSearchValue(searchFilters.filters, 'CreditCardLastFour');
    this.orderSearchFilterDto.trackingNumber = this.getFiltersSearchValue(searchFilters.filters, 'TrackingNumber');
    this.orderSearchFilterDto.enteredBy = this.getFiltersSearchValue(searchFilters.filters, 'EnteredBy');
    this.orderSearchFilterDto.streetAddress = this.getFiltersSearchValue(searchFilters.filters, 'StreetAddress');
    this.orderSearchFilterDto.city = this.getFiltersSearchValue(searchFilters.filters, 'City');
    this.orderSearchFilterDto.zipCode = this.getFiltersSearchValue(searchFilters.filters, 'ZipCode');
    this.orderSearchFilterDto.country = this.countries.filter(c => c.value === this.getFiltersSearchValue(searchFilters.filters, 'Country'))[0];

    this.setSearchFilterState(this.orderSearchFilterDto.country, searchFilters.filters);
    if (!(Object.keys(localStorage).indexOf('searchOrderResults') > -1)) {
      return;
    }
    const orderSearchResults: any = JSON.parse(localStorage.getItem('searchOrderResults')!).orderSearchResults;
    return this.searchResults(orderSearchResults, searchFilters);

  }
  setSearchFilterState(country: IUITKSelectItemProps | undefined, filters: OrderSearchFilter[]) {
    if (typeof country !== 'undefined') {
      if (country.value === 'US') {
        this.orderSearchFilterDto.stateUS = this.stateList.filter(s => s.value === this.getFiltersSearchValue(filters, 'State'))[0];
      }
      else {
        this.orderSearchFilterDto.stateNonUS = this.getFiltersSearchValue(filters, 'State');
      }
    }
  }
  searchResults(orderSearchResults: any, searchFilters: OrderSearchInputDto){
    if(orderSearchResults.length !== 1) {
      this.orderService.orders = null;
      this.orderService.orderDto = new OrderDto();
      this.orderService.searchOrders(Mode.View, searchFilters);
      this.showResults = true;
    }
    else{
      if (orderSearchResults[0].orderType === 'R' || orderSearchResults[0].orderType === 'A') {
        return ;
      }
      let count = this.clearLocalStorage();
      if(count && count === '1'){
        return;
      }
      this.search();
    }
    return;
  }
  clearLocalStorage() {
    let count = null;
    if(Object.keys(localStorage).indexOf('backToSearchResultsCount') > -1){
      count = localStorage.getItem('backToSearchResultsCount') as string;
      localStorage.removeItem('backToSearchResultsCount');
    }
    return count;
  }
  getFiltersSearchValue(filters: OrderSearchFilter[], searchField: string) {
    return filters.filter(f => f.searchField === searchField)[0]?.searchValue ?? '';
  }

  clearSearch() {
    this.orderSearchFilterDto = new OrderSearchFilterDto();
    this.orderStatusList.filter(o => o.label === 'Open')[0].disabled = false;
     
    this.orderTypeList.forEach(element => {
      element.disabled = false;
    });

    this.orderService.orders = null;
    this.showResults = false;
  }

  search() {
    this._configService.resetIdleTimeout();
    this.showResults = false;
    this.modeService.mode = Mode.Wait;

     if (!this._authorizationService.hasResource('OrderEntryMenu')) {
       this.orderStatusList.filter(item => item.id === 'D')[0].disabled = true;
       this.orderStatusList.filter(item => item.id === 'P')[0].disabled = true;
       this.orderStatusList.filter(item => item.id === 'M')[0].disabled = true;
       this.orderStatusList.filter(item => item.id === 'R')[0].disabled = true;
       this.orderTypeList.filter(item=> item.id === 'Q')[0].disabled = true;
     }

    let orderStatusSearch = this.orderSearchFilterDto.orderStatus?.map(x => x.value).join(',') ?? '';
    
    if (!this._authorizationService.hasResource('OrderEntryMenu') && orderStatusSearch === '') {
      orderStatusSearch += "H,C,O,V";
    }

    const startsWith='starts with';
    let filter = [
      { searchField: 'OrderNumber', searchOperator: 'equals', searchValue: this.orderSearchFilterDto.orderNumber },
      { searchField: 'OrderType', searchOperator: 'in', searchValue: this.orderSearchFilterDto.orderType?.map(x => x.value).join(',') ?? '' },
      { searchField: 'OrderStatus', searchOperator: 'in', searchValue: this.orderSearchFilterDto.orderStatus?.map(x => x.value).join(',') ?? ''},
      { searchField: 'AccountName', searchOperator: startsWith, searchValue: this.orderSearchFilterDto.accountName },
      { searchField: 'AccountNumber', searchOperator: startsWith, searchValue: this.orderSearchFilterDto.accountNumber },
      { searchField: 'WebReferenceNumber', searchOperator: startsWith, searchValue: this.orderSearchFilterDto.webReferenceNumber },
      { searchField: 'PONumber', searchOperator: startsWith, searchValue: this.orderSearchFilterDto.poNumber },
      { searchField: 'CreditCardLastFour', searchOperator: 'equals', searchValue: this.orderSearchFilterDto.creditCardLastFour },
      { searchField: 'TrackingNumber', searchOperator: startsWith, searchValue: this.orderSearchFilterDto.trackingNumber },
      { searchField: 'EnteredBy', searchOperator: startsWith, searchValue: this.orderSearchFilterDto.enteredBy },
      { searchField: 'StreetAddress', searchOperator: startsWith, searchValue: this.orderSearchFilterDto.streetAddress },
      { searchField: 'City', searchOperator: startsWith, searchValue: this.orderSearchFilterDto.city },
      {
        searchField: 'State', searchOperator: 'equals', searchValue: this.orderSearchFilterDto.country?.value === 'US' ?
          (this.orderSearchFilterDto.stateUS?.value ?? '') : this.orderSearchFilterDto.stateNonUS
      },
      { searchField: 'ZipCode', searchOperator: startsWith, searchValue: this.orderSearchFilterDto.zipCode },
      { searchField: 'Country', searchOperator: 'equals', searchValue: this.orderSearchFilterDto.country?.value ?? '' }
    ];

    const searchOperator = { filters: filter.filter(x => x.searchValue !== ''), pageNumber: 1, pageSize: 200, sort: "OrderDate descending" };
    this.orderService.orders = null;
    this.orderService.orderDto = new OrderDto();
    this.orderService.searchOrders(Mode.View, searchOperator);
    this.showResults = true
  }

  clearState() {
    this.orderSearchFilterDto.stateUS = null;
    this.orderSearchFilterDto.stateNonUS = '';
  }

  setPanelBGHeight() {
    if (this.panelBG === this.panelBGOpen) {
      this.panelBG = 'uitk-l-grid1-close';
    }
    else {
      this.panelBG = this.panelBGOpen;
    }
  }

  onChangeOrderStatus(event: any): void {
    this.orderSearchFilterDto.orderStatus = event;
  }
 

  ngOnDestroy() {
    this.configIsReadySubscription?.unsubscribe();
  }
}