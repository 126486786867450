export class FeeAnalyzerDto
{
  company = '';
  specialty = '';
  description = '';
  zip = '';
  geoZip = '';
  includeDataFile = false;
  productType = '';
  completedDate: Date | null = null;
}
