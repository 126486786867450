import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  IUITKPaginationConfig, IUITKPaginationEntriesPerPage, IUITKPaginationEvent, PAGINATION_EVENTS,
  UITKHeadingLevel, UITKSortDirection, UITKTableDataSource
} from '@uitk/angular';
import { UITKTableSortDirective } from '@uitk/angular/components/table/features/sort/table-sort.component';
import { UITKTableComponent } from '@uitk/angular/components/table/table.component';
import { IUITKColumnState } from '@uitk/angular/components/table/table.interfaces';
import { Subscription } from 'rxjs';
import { Mode } from '../../enums/mode.enum';
import { CashInquirySearchResultsDto } from '../../dtos/cash-inquiry-search-results-dto.model';
import { CashInquirySearchResults } from '../../dtos/cash-inquiry-search-results.model';
import { CashinquiryService } from '../../services/cashinquiry.service';
import { ConfigService } from '../../services/config.service';
import { ModeService } from '../../services/mode.service';

@Component({
  selector: 'app-cash-inquiry-search-results',
  templateUrl: './cash-inquiry-search-results.component.html',
  styleUrls: ['./cash-inquiry-search-results.component.css']
})

export class CashInquirySearchResultsComponent implements OnInit {
  private readonly _cashInquiryService: CashinquiryService;  
  private readonly _configService: ConfigService;
  private readonly _router: Router;
  tableHeader = [
    {
      name: 'Ref #', id: 'ReferenceNumber'
    },
    {
      name: 'Paid Amt', id: 'PaidAmount'
    },
    {
      name: 'Account ID', id: 'AccountId'
    },
    {
      name: 'Paid Date', id: 'PaidDate'
    },
    {
      name: 'Account Name', id: 'AccountName'
    },
    {
      name: 'Invoice #', id: 'InvoiceNumber'
    },
    {
      name: 'Invoice Date', id: 'InvoiceDate'
    },
    {
      name: 'Order #', id: 'OrderId'
    },
    {
      name: 'PO #', id: 'PONumber'
    },
    {
      name: 'Session ID', id: 'SessionId'
    },
    {
      name: 'User Name', id: 'UserId'
    }
  ];
  @Input() collapedHeader = false;
  @ViewChild('sortTable') uitkTableSort!: UITKTableSortDirective;
  @ViewChild('rootTable') uitkTable!: UITKTableComponent<CashInquirySearchResults[]>;
  cashInquiries = new UITKTableDataSource<CashInquirySearchResults>([]);
  gotCashInquirySearchResultsSubscription: Subscription | undefined;
  Mode= Mode;
  TotalRecords = 1;
  CurrentPage = 1;
  currentPageSize: number = 5;

  paginationConfig: IUITKPaginationConfig = {
    id: 'pagination-id',
    description: 'Pagination',
    currentPageNumber: 1,
    displayingPagesTextHeadingLevel: UITKHeadingLevel.h2,
    nextButtonLabel: '>>',
    previousButtonLabel: '<<',
  };

  entriesPerPage: IUITKPaginationEntriesPerPage = {
    pageSizeOptions: [{ value: 50, selected: true }, { value: 100 }, { value: 150 }, { value: 200 }],
  };

  constructor(
    cashInquiryService: CashinquiryService,
    public modeService: ModeService,
    configService: ConfigService,
    router: Router) {
    this._cashInquiryService = cashInquiryService;
    this._configService = configService;
    this._router = router;
  }
 
  ngOnInit(): void {
    this.filter();
}
  ngAfterViewInit() {
    this.uitkTableSort.setDirection({ column: 'PaidDate', direction: UITKSortDirection.DESC });
    this.cashInquiries.sort = this.uitkTableSort;
}

  onSortChange(event: IUITKColumnState): void {
    if (event.direction === -1) {
      this._cashInquiryService.filterObj.Sort = event.column + " descending";
    }
    else {
      this._cashInquiryService.filterObj.Sort = event.column;
    }
    this._cashInquiryService.cashInquiries = null;
    this._cashInquiryService.searchCashInquirys(Mode.View, this._cashInquiryService.filterObj, true);
  }

  filter(): void {
    this.gotCashInquirySearchResultsSubscription=this._cashInquiryService.gotCashInquirySearchResultsEmitter
    .subscribe((data:CashInquirySearchResultsDto)=>{
      this.cashInquiries.data=data.cashInquiryDetailDtos;
      this.TotalRecords=data.totalRecords;
      this.CurrentPage=data.currentPage;
  });
  }
  onPaginationChange(event: any) {
    const pageEvent: IUITKPaginationEvent = event;

    switch (pageEvent.event) {

      case PAGINATION_EVENTS.ENTRIES_PER_PAGE:
        this.paginationConfig.totalPagesCount = Math.ceil(pageEvent.totalRecords / pageEvent.entriesPerPage);
        if (this.paginationConfig.totalPagesCount < this.paginationConfig.currentPageNumber) {
          this.paginationConfig.currentPageNumber = this.paginationConfig.totalPagesCount;
        }
        this.currentPageSize = pageEvent.entriesPerPage;
        this._cashInquiryService.filterObj.pageNumber = this.paginationConfig.currentPageNumber;
        this._cashInquiryService.filterObj.pageSize = pageEvent.entriesPerPage;
        this._cashInquiryService.cashInquiries = null;
        this._cashInquiryService.searchCashInquirys(Mode.View, this._cashInquiryService.filterObj, true);
        break;
      case PAGINATION_EVENTS.PREVIOUS_PAGE:
      case PAGINATION_EVENTS.NEXT_PAGE:
      case PAGINATION_EVENTS.GO_TO_PAGE:
        this.paginationConfig.currentPageNumber = pageEvent.state.currentPageNumber;
        this._cashInquiryService.filterObj.pageNumber = this.paginationConfig.currentPageNumber;
        this._cashInquiryService.filterObj.pageSize = pageEvent.entriesPerPage;
        this._cashInquiryService.cashInquiries = null;
        this._cashInquiryService.searchCashInquirys(Mode.View, this._cashInquiryService.filterObj, true);
        break;
    }
  }

  navigateToARInquiry(accountId: number): void {
    this._router.navigate([]).then(() => { 
      window.open('/accountreceivable/arinquiry/' + accountId, '_blank'); 
    });
  }

  navigateToARInquiryInvoice(accountId: number, invoiceId: number): void {
    this._router.navigate([]).then(() => { 
      window.open(`/accountreceivable/arinquiry/${accountId}/${invoiceId}`, '_blank'); 
    });
  }

  ngOnDestroy() {
    this.gotCashInquirySearchResultsSubscription?.unsubscribe();
  }
}
