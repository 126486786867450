export class OrderNoteDto {
  noteText = '';
  userCode = '';
  orderType = '';
  noteType = '';
  id = 0;
  orderId = 0;
  systemNote = '';
  createdDate: Date | null = null;

  // Properties required by SPA
  isTruncated = false;
  orderTypeText = '';
  userName = '';
  noteTypeText = '';
  isEditEnabled=false;
  priceChangeProductId = 0;
}
