<span *ngIf="!gridFilters[gridFilterIndex] || !gridFilters[gridFilterIndex].filterApplied" class="uitk-icon uitk-icon-filter sm-icon gridIcons" role="button" (click)="openFilter($event)"></span>
<span *ngIf="gridFilters[gridFilterIndex] && gridFilters[gridFilterIndex].filterApplied" role="button" (click)="openFilter($event)"><img alt="" src="assets/images/filled-filter.png"/></span>

<ul class="dropdown-menu" [ngStyle]="{'display': openFilterMenu ? 'block' : 'none'}" (click)="$event.stopPropagation()">
  <li class="mt-2">
    <uitk-form-field>
      <input uitkInput id="filterTextBox" class="textbox" type="text" [(ngModel)]="filterItem" name="filterTextBox" 
      (keyup.enter)="applyFilter($event, filterTextBox.value)" 
      (keypress)="$event.stopPropagation()" 
      (keydown)="$event.stopPropagation()" 
      (input)="filterChanged($event)" #filterTextBox />
    </uitk-form-field>
  </li>
  <div style="height:95px; overflow-y: scroll" id="filterList" name="filterList" #filterList>
    <li style="font-weight: lighter" class="mx-2" *ngFor="let item of distinctItems">
      <div class="bg-orange color-dark" *ngIf="filterItem && item.toLowerCase().indexOf(filterTextBox.value.toLowerCase()) === 0" 
        (click)="clickItem($event, item)">{{ item }}</div>
      <div *ngIf="!filterItem || item.indexOf(filterTextBox.value) !== 0"
        (click)="clickItem($event, item)">{{ item }}</div>
    </li>
  </div>
  <li><hr class="dropdown-divider"></li>
  <li>
    <uitk-dialog-actions>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="applyFilter($event, filterTextBox.value)">
          Apply
      </button>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="clearFilter($event)">
          Clear
      </button>
    </uitk-dialog-actions>
  </li>
</ul> 