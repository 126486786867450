import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUITKSelectItemProps, UITKTableDataSource, UITKSortDirection } from '@uitk/angular';
import { Mode } from '../../../../enums/mode.enum';
import { ProductDto } from '../../../../dtos/product-dto.model';
import { ConfigService } from '../../../../services/config.service';
import { ModeService } from '../../../../services/mode.service';
import { OrderService } from '../../../../services/order.service';


import { IUITKColumnState } from '@uitk/angular';
import { ItemSearchCatalogDto } from '../../../../dtos/item-search-catalog-dto-model';
import { AuthorizationService } from '../../../../services/authorization.service';
@Component({
  selector: 'app-item-search-catalog',
  templateUrl: './item-search-catalog.component.html',
  styleUrls: ['./item-search-catalog.component.css']
})

export class ItemSearchCatalogComponent  implements OnInit {
  private readonly _configService: ConfigService;
  @Input('dialog') dialog = { show: false };
  @Output() productAdded: EventEmitter<number> = new EventEmitter();
  Mode = Mode;
  ProductsList: ProductDto[] = [];
  ItemSearchDialog = { show: false };
  showResultsGrid = { show: false };
  defaultLabel = '-Please Select';
  defaultLabelFlag = false;
  tableHeader = [
    { name: 'Item #', id: 'itemNumber' },
    { name: 'Item Description', id: 'itemDescription' },
    { name: 'List Price', id: 'listPrice' },
    { name: 'Floor Price', id: 'floorPrice' },
    { name: 'Sales Cost', id: 'salesCost' },
    { name: 'On Hand Qty', id: 'onHand' },
    { name: 'Available Date', id: 'availableDate' },
    { name: 'ISBN', id: 'isbn' },
    { name: 'Edition Yr', id: 'editionYear' },
    { name: 'Acronym', id: 'acronym' }
  ];
  acronymList: IUITKSelectItemProps[] = [];
  filteredAcronymList: IUITKSelectItemProps[] = [];
  editionYrList: IUITKSelectItemProps[] = [];
  productStatusList: IUITKSelectItemProps[] = [];
  editionYr: IUITKSelectItemProps | null = null;
  productStatus: IUITKSelectItemProps | null = null;
  acronym: IUITKSelectItemProps | null = null;
  itemNumber: string = '';
  isbn: String | null = null;
  itemDescription: String = '';
  userRole: string | null = '';
  isSAorOMUser = false;
  currentSortOrder!: IUITKColumnState;
  itemSearchCatalogDto: ItemSearchCatalogDto = new ItemSearchCatalogDto();
  constructor(public modeService: ModeService, configService: ConfigService, public orderService: OrderService,
    private readonly authorizationService: AuthorizationService ) {
    this._configService = configService;
  }

  ngOnInit(): void {
    this.acronymList = this._configService.getPickList('ACRODESC');
    this.filteredAcronymList = this._configService.getPickList('ACRODESC');
    this.editionYrList = this._configService.getPickList('EDITIONYR');
    this.productStatusList = this._configService.getPickList('PRDTYPE');
    if(this.productStatusList.length > 0){
      this.productStatus = this.productStatusList.find(x => x.value === 'A') ?? null;
    }
    this.showResultsGrid.show = false;
    this.ItemSearchDialog.show = true;
    
    if (!this.authorizationService.hasResource('OrderEntryItemCatalogSearchInactive')){
      this.productStatusList.forEach(x => {
        if(x.value !== 'A'){
          x.disabled = true;
        }
      })
    }
  }
  
getColumnLabel(id: string): string {
  return this.tableHeader?.find((header) => {
      return header?.name ===id ;
  })?.name || " ";
}

  closeDialog() {
    this.showResultsGrid.show = false;
    this.ItemSearchDialog.show = false;
  }
  clear() {
    this.itemNumber = '';
    this.isbn = null;
    this.acronym = null;
    this.itemDescription = '';
    this.editionYr = null;
    this.productStatus = null;
    this.filteredAcronymList = this.acronymList;
    this.showResultsGrid.show = false;
  }

  addItem(productCode: any) {
    this.productAdded.emit(productCode);
    console.log("selected product code", this.productAdded);
    this.dialog.show = false;
  }
  setItemSearchCatalogDto(): void {
    this.itemSearchCatalogDto.itemNumber = this.itemNumber === '' ? null : this.itemNumber;
    this.itemSearchCatalogDto.isbn = this.isbn === '' ? null : this.isbn;
    this.itemSearchCatalogDto.editionYear = this.editionYr ?  Number(this.editionYr.label) : null;
    this.itemSearchCatalogDto.itemDescription = this.itemDescription === '' ? null : this.itemDescription;
    this.itemSearchCatalogDto.acronym = this.acronym?.value.toString() ?? null;
    this.itemSearchCatalogDto.productStatus = this.productStatus?.value ?? null;  
  }
  sortSearchResults(): void {
    if (this.currentSortOrder) {
      this.onSortChange(this.currentSortOrder);
    }
    else {
      this.ProductsList.sort((a, b) => 0 - (a.productCode > b.productCode ? -1 : 1))
    }
  }
  async search() {
    this.showResultsGrid.show = false;    
    this.itemSearchCatalogDto = new ItemSearchCatalogDto();
    if(this.productStatus?.value === null || (this.productStatus && this.productStatus.value === 'A')){
      this.ProductsList = this._configService.reference!.productDtos
      .filter(p => (this.itemNumber.toLowerCase() === '' || p.productCode.toLowerCase().includes(this.itemNumber.toLowerCase())) &&
        (this.isbn === null || p.isbn?.trim().toLowerCase().includes(this.isbn?.trim().toLowerCase())) &&
        (this.editionYr === null || p.editionYear === Number(this.editionYr?.label)) &&
        (this.itemDescription === '' || p.description.toLowerCase().includes(this.itemDescription.toLowerCase())) &&
        (this.acronym === null || p.acronym?.toLowerCase() === this.acronym.value.toString().toLowerCase()));

        this.showResultsGrid.show = true;
    }
    else {
      this.setItemSearchCatalogDto();    
      await this._configService.getIemSearchCatalogProductsAsync(this.itemSearchCatalogDto).then(x => {
        this.ProductsList = x ?? [];
        this.showResultsGrid.show = true;
      });
    }   
    this.sortSearchResults();
  }

  itemDescriptionBlur(itemDescription: any): void {
    this.ProductsList = this._configService.reference!.productDtos
      .filter(p => (this.itemDescription === '' || p.description.toLowerCase().includes(this.itemDescription.toLowerCase())) && p.statusCode === 'A');
    console.log("Product acronym list", this.ProductsList);

    if (itemDescription !== '') {
      this.filteredAcronymList = this.acronymList.filter(item => this.ProductsList.some(p => p.acronym.toLowerCase() === item.value.toString()
        .toLowerCase() && p.statusCode === 'A'));
    } else {
      this.filteredAcronymList = this.acronymList;
    }
  }
  onSortChangeAvailableDate(event: IUITKColumnState): void {
   this.ProductsList.sort((a, b) => 0 - (this.availableDateValue(a.availableDate) > this.availableDateValue(b.availableDate)? event.direction * -1 : event.direction * 1));
  }
  availableDateValue(availableDate: Date | null){
    if(availableDate){
      return new Date(availableDate);
    }
    return 0;
  }
  onSortChangeForItemNumberAndAcronym(event: IUITKColumnState, sortField: string): void {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    switch (event.direction) {
      case 1:
        this.ProductsList.sort((a: any, b: any) => collator.compare(a[sortField], b[sortField]));
        break;
      case -1:
        this.ProductsList.sort((a: any, b: any) => collator.compare(b[sortField], a[sortField]));
        break;
    }
  }
  onSortChange(event: IUITKColumnState): void {
    this.currentSortOrder = event;
    switch (event.column) {
      case 'itemNumber':
        this.onSortChangeForItemNumberAndAcronym(event, 'productCode');
        break;
      case 'itemDescription':
        this.ProductsList.sort((a, b) => 0 - (a.description.toLowerCase() > b.description.toLowerCase() ? event.direction * -1 : event.direction * 1));
        break;
      case 'availableDate':
        this.onSortChangeAvailableDate(event);
        break;
      case 'acronym':
        this.onSortChangeForItemNumberAndAcronym(event, 'acronym');
        break;
      case 'isbn':
        this.ProductsList.sort((a, b) => 0 - ((a.isbn ?? '') > (b.isbn ?? '') ? event.direction * -1 : event.direction * 1));
        break;
      default:
        this.ProductsList.sort((a: any, b: any) => 0 - ((a[event.column] ?? 0) > (b[event.column] ?? 0) ? event.direction * -1 : event.direction * 1));
        this.ProductsList = [...this.ProductsList];

    }
    this.ProductsList = [...this.ProductsList];

  }

}
