<div *ngIf = "dialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" uitkId="voidOrderDialog" triggerElementId="voidOrder"
  [closeBtn]=false>
  <uitk-dialog-header [hidden]="modeService.mode !== Mode.View && modeService.mode !== Mode.Edit">
    <div cdkDragHandle>
      {{(orderService.orderDto.orderType ==='R'||orderService.orderDto.orderType ==='A')? 'Void Return'
      :(orderService.orderDto.orderType ==='Q'? 'Void Quote': 'Void Order')}}
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content [hidden]="modeService.mode !== Mode.View && modeService.mode !== Mode.Edit">
    <div *ngIf="orderService.orderDto.multiYearCurrentYear" class="uitk-l-grid__row">
      <div class="uitk-l-grid__col--12">
        <h5 style="color:red">Warning! This {{orderService.orderDto.orderType ==='Q'? 'Quote': 'Order'}} is part of a multi-year deal and
          future {{orderService.orderDto.orderType ==='Q'? 'quotes': 'orders'}} will also be voided.</h5>
        <br />
      </div>
    </div>

    <div *ngIf="!isAnyShipped && (orderService.orderDto.orderType!='Q') " class="uitk-l-grid__row">
      <div class="uitk-l-grid__col--12">
        <div class="demo-l-grid-col-content" style="color:red">
          <!-- {{orderService.orderDto.orderType ==='R'? 'Are you sure you want to VOID this entire Return' :(orderService.orderDto.orderType ==='Q'? 'Do you want to continue ?': 'Are you sure you want to VOID this entire Sales Order?')}}</div>  -->
          Are you sure you want to VOID this entire  {{(orderService.orderDto.orderType ==='R'||orderService.orderDto.orderType ==='A')? ' Return' : (orderService.orderDto.orderType ==='Q'? 'Quote': 'Sales Order')}}?</div>
        <br />
      </div>
    </div>
    <div *ngIf="isAnyShipped && (orderService.orderDto.orderType!='Q') " class="uitk-l-grid__row">
      <div class="uitk-l-grid__col--12">
        <div class="demo-l-grid-col-content" style="color:red">
          Are you sure you want to Close every open item on this {{(orderService.orderDto.orderType ==='R'||orderService.orderDto.orderType ==='A')? ' Return' : (orderService.orderDto.orderType ==='Q'? 'Quote': 'Sales Order')}}?</div>
        <br />
      </div>
    </div>
    <div class="uitk-l-grid__row">
      <div class="uitk-l-grid__col--2">
        <label uitkLabel>Void Reason</label>
      </div>
      <div class="uitk-l-grid__col--10">
        <uitk-form-field>
          <uitk-dropdown uitkId="singleSelect-template" [enableSelectFlag]="false" [items]="voidOrderList"
            [(ngModel)]="voidOrderValue" [required]="true" name="requiredSelect" #requiredSelect="ngModel"
            (onChange)="voidOnChange()">
          </uitk-dropdown>
          <uitk-form-field-error
            *ngIf="(requiredSelect?.errors?.required && requiredSelect?.touched) || showVoidModalError">
            required
          </uitk-form-field-error>
        </uitk-form-field>
      </div>
    </div>
    <br>
    <div class="uitk-l-grid__row">
      <div class="uitk-l-grid__col--2">

        <label uitkLabel>Void Note</label>

      </div>
      <div class="uitk-l-grid__col--10">

        <div *ngIf="voidProductCode"
          class="ng-tns-c54-2 uitk-c-notification__container uitk-c-notification__container--warning ng-trigger ng-trigger-fadeInOut ng-star-inserted">
          <div class="uitk-c-notification__content-container ng-tns-c54-2">
            <div class="uitk-c-notification__icon ng-tns-c54-2">
              <span class="ng-tns-c54-1 uitk-icon uitk-icon-info_filled ng-star-inserted"></span>
            </div>
            <div class="uitk-c-notification__content ng-tns-c54-2" id="warningDemoId_content">
              <span class="ng-tns-c54-2" id="voidNoteInfo"> "Voided items {{voidProductCode}}" will appear in the void
                note.</span>
            </div>
          </div>
        </div>
        <uitk-form-field>
          <textarea uitkTextarea [(ngModel)]="voidReason" name="textField" uitkId="my-basic-template-textarea"
            aria-describedby="my-basic-template-textarea-ht" placeholder="Additonal Note"></textarea>
        </uitk-form-field>
      </div>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions [hidden]="modeService.mode !== Mode.View && modeService.mode !== Mode.Edit">
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveVoidOrder()">
      Save
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeVoidOrderDialog()">
      Cancel
    </button>
  </uitk-dialog-actions>
  <uitk-dialog-content [hidden]="modeService.mode !== Mode.Wait">
    <div id="loadingSpinner" class="uitk-l-grid">

      <uitk-loading-indicator uitkId="spinner" [visibleText]="visibleTextValue" spinnerSize="large">
      </uitk-loading-indicator>
    </div>
  </uitk-dialog-content>
</uitk-dialog>
<uitk-dialog [(showModal)]="voidDepositDialogModal.show" uitkId="voidOrderDialog" triggerElementId="voidOrder"
  [closeBtn]=false>
  <uitk-dialog-header [hidden]="modeService.mode !== Mode.View && modeService.mode !== Mode.Edit">
    Void/Close {{orderService.orderDto.orderType ==='R'? ' Return' : ' Order'}} with a deposit
  </uitk-dialog-header>
  <uitk-dialog-content [hidden]="modeService.mode !== Mode.View && modeService.mode !== Mode.Edit">
    There is a deposit on this {{orderService.orderDto.orderType ==='R'? ' Return' : ' Order'}}. The open deposit amount will be posted as Open Cash on the Account.
  </uitk-dialog-content>
  <uitk-dialog-actions [hidden]="modeService.mode !== Mode.View && modeService.mode !== Mode.Edit">

    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeVoidDepositDialog()" appAutoFocus>
      OK
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
<uitk-dialog [(showModal)]="voidAuthorizedDialogModal.show" uitkId="voidOrderDialog" triggerElementId="voidOrder"
  [closeBtn]=false>
  <uitk-dialog-header [hidden]="modeService.mode !== Mode.View && modeService.mode !== Mode.Edit">
    Already Authorized
  </uitk-dialog-header>
  <uitk-dialog-content [hidden]="modeService.mode !== Mode.View && modeService.mode !== Mode.Edit">
    This Credit Card has already been Pre-Authorized. The charges will be reversed after a few days.
  </uitk-dialog-content>
  <uitk-dialog-actions [hidden]="modeService.mode !== Mode.View && modeService.mode !== Mode.Edit">
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeVoidDepositDialog()" #authorizationDialogOk>
      OK
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
</div>
</div>
