import { Component, Input, ViewChild } from '@angular/core';
import { ScrollToDirective } from '../../directives/scrollTo.directive';
import { Dialog } from '../../helpers/dialog';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-confirm-additional-contacts',
  templateUrl: './confirm-additional-contacts.component.html',
  styleUrls: ['./confirm-additional-contacts.component.css']
})
export class ConfirmAdditionalContactsComponent {
  @Input() dialog!: Dialog<string>;
  @ViewChild(ScrollToDirective) dir:any;
  constructor(public orderService:OrderService) { }

  closeAdditionalContactsPopup() {
    this.dialog.close('No');
  }

  openSelectContacts() {
    this.dialog.close('Yes');   
  }
  closeModal(){
    this.dir!.target = "#divLeftOrderDetailsGrid";
    this.dir?.scrollToPosition();
  }
}
