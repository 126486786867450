import {
  Component,
  EventEmitter, OnDestroy, OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { IUITKRadioGroupItem } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { RoleDto } from '../../../dtos/role-dto.model';
import { RoleResourceDto } from '../../../dtos/role-resource-dto.model';
import { AuthorizationService } from '../../../services/authorization.service';
import { ConfigService } from '../../../services/config.service';

@Component({
  selector: 'app-impersonation',
  templateUrl: './impersonation.component.html',
  styleUrls: ['./impersonation.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
  
export class ImpersonationComponent implements OnInit, OnDestroy {
  impersonateDialogModal = {
    show: false,
  };

  items: IUITKRadioGroupItem[] = [];
  @Output() closeEvent = new EventEmitter<any>();
  configIsReadySubscription: Subscription | undefined;
  rolesList: RoleDto[] = [];
  impersonateValue: string = '';
  resources: string[] = [];

  constructor(
    private readonly authorizationService: AuthorizationService,
    private readonly configService: ConfigService
  ) { }

  ngOnInit(): void {
    this.configIsReadySubscription = this.configService.configIsReady.subscribe(
      () => {
        this.impersonateDialogModal.show = true;

        this.authorizationService
          .getRoles(`${this.configService.apiUrl}`)
          .subscribe((data) => {
            this.rolesList = data;

            for (let i = 0; i < this.rolesList.length; i++) {
              const item: any = {
                label: this.rolesList[i].description,
                value: this.rolesList[i].role,
              };

              this.items.push(item);
            }
          });
      }
    );

    this.configService.loadConfigurations();
  }

  impersonate(selectedValue: any) {
    this.authorizationService
      .getRoleResources(`${this.configService.apiUrl}`, selectedValue.value)
      .subscribe((data: RoleResourceDto[]) => {
        for (let i = 0; i < data.length; i++) {
          this.resources.push(data[i].resource.toLowerCase());
        }

        console.log(JSON.stringify(this.resources));
        localStorage.setItem('resources', JSON.stringify(this.resources));
        localStorage.setItem('impersonateType', selectedValue.label);
        this.closeImpersonateDialog();
        localStorage.setItem('IsImpersonated', 'true');
        location.reload();
        console.log(JSON.stringify(localStorage.getItem('resources')));
      });
  }

  closeImpersonateDialog() {
    this.impersonateDialogModal.show = false;
    this.closeEvent.emit();
  }

  ngOnDestroy() {
    this.configIsReadySubscription?.unsubscribe();
  }
}
