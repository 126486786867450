import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BillToService } from '../../../services/bill-to.service';
import { ApprovalDto } from '../../../dtos/approval-dto.model';
import { ApprovalService } from '../../../services/approval.service';
import { ConfigService } from '../../../services/config.service';
import { OrderService } from '../../../services/order.service';

@Component({
  selector: 'app-approval-requirements',
  templateUrl: './approval-requirements.component.html',
  styleUrls: ['./approval-requirements.component.css']
})

export class ApprovalRequirementsComponent implements OnInit, OnDestroy {
  _approvalService: ApprovalService;
  private readonly _orderService: OrderService;
  private readonly _configService: ConfigService;
  private readonly _billToService: BillToService;
  orderIsReadySubscription: Subscription | undefined;
  billToIsReadySubscription: Subscription | undefined;
  approvalsList: ApprovalDto[] = [];
  approvalsTableHeader = [
    { name: 'Approval Levels', id: 'approvalLevel', align: 'left' },
    { name: 'Approver', id: 'approver', align: 'left' },
    { name: 'Threshold', id: 'threshold', align: 'right' }
  ];

  constructor(approvalService: ApprovalService, orderService: OrderService, configService: ConfigService, billToService: BillToService) {
    this._approvalService = approvalService;
    this._orderService = orderService;
    this._configService = configService;
    this._billToService = billToService;
  }

  ngOnInit(): void {
    this.orderIsReadySubscription = this._orderService.orderIsReady.subscribe(() => {
      this._approvalService.refreshApprovalRequirementsSection();
    });
    if (this._orderService.orderDto.id > 0 && this._orderService.orderDto.billToDto.accountOwnerCode && !this._approvalService.showApprovalHistoryOnQuote && this._orderService.orderDto.orderStatus !== 'W') {
      this._approvalService.refreshApprovalRequirementsSection();
    }
  }

  ngOnDestroy(): void {
    this.billToIsReadySubscription?.unsubscribe();
    this.orderIsReadySubscription?.unsubscribe();
  }
}
