import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IUITKColumnState, IUITKSelectItemProps, UITKSortDirection, UITKTableDataSource, UITKTableSortDirective } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Mode } from '../../../enums/mode.enum';
import { OrderNoteDto } from '../../../dtos/order-note-dto.model';
import { RemoveHoldDto } from '../../../dtos/remove-hold-dto';
import { DeclinedCCDto, RetryCCDto } from '../../../dtos/retry-cc-dto';
import { tableHeader } from '../../../dtos/table-header';
import { WorklistQueueDto } from '../../../dtos/worklist-queue-dto';
import { ConfigService } from '../../../services/config.service';
import { ModeService } from '../../../services/mode.service';
import { WorklistQueueService } from '../../../services/worklistqueue-service';
import { EmailService } from '../../../services/email.service';
import { SwalAlert } from '../../../helpers/alert';
import { WorklistNoteDto } from '../../../dtos/worklist-note-dto.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-worklist-queue-search-results',
  templateUrl: './worklist-queue-search-results.component.html',
  styleUrls: ['./worklist-queue-search-results.component.css']
})

export class WorklistQueueSearchResultsComponent implements OnInit, OnDestroy {
  @ViewChild('sortTable') uitkTableSort!: UITKTableSortDirective;
  @Input() onChangeValue: any;
  @Input() resourceTypeValue: any;
  @Input() collapsedHeader = false;
  @Input() worklistNoteDto: WorklistNoteDto = new WorklistNoteDto();
  notesData: WorklistNoteDto[] = [];
  isDeclinedCC: boolean = false;

  holdReasonDialogModal = {
    show: false,
  };
  emailDeclinedDialog = {
    show: false,
  }
  declinedDialog = {
    header: 'Success',
    content:'Email Sent successfully',
    icon:'uitk-icon uitk-icon-checkmark_filled md-icon'
  }
  Mode = Mode;
  holdReasonPickList: IUITKSelectItemProps[] = []
  openAddNotePopup = false;
  openViewNotePopup = false;
  hasNotes = false;
  chkAll = true;
  userName: string = '';
  createdDate: string | null = null;
  followUpDate: string = '';
  orderidConcade: string = '';
  worklistQueueResult: Subscription | undefined;
  resultDataSource = new UITKTableDataSource<WorklistQueueDto>([]);
  filteredResultDataSource = new UITKTableDataSource<WorklistQueueDto>([]);
  configIsReadySubscription: Subscription | undefined;
  tableHeader : tableHeader[] = [
    { id: '', name: '' },
    { id: 'hasWorkListNotes', name: 'Notes' },
    { id: 'ccLastFour', name: '' },
    { id: 'filterType', name: 'Type' },
    { id: 'accountId', name: 'Account ID' },
    { id: 'orderNumber', name: 'Order #' },
    { id: 'orderDate', name: 'Order Date' },
    { id: 'webReferenceNumber', name: 'Web Ref' },
    { id: 'isPORequired', name: 'PO Req', dataType : "boolean" },
    { id: 'poNumber', name: 'PO #' },
    { id: 'openAmount', name: 'Open Amt' },
    { id: 'accountOwner', name: 'Account Owner' },
    { id: 'enteredBy', name: 'Entered By User' },
    { id: 'accountName', name: 'Account Name' },
    { id: 'holdReason', name: 'Hold Reason' },
    { id: 'creditLimitDescription', name: 'Credit Limit' },
    { id: 'paymentPlan', name: 'Payment Plan' },
    { id: 'lastProcessDate', name: 'Last Process Date' },
    { id: 'isMultiYear', name: 'MY' }
  ];
  orderNumber = 0;
  selectedOnHoldRecords: WorklistQueueDto[] = [];;
  removeHoldOrderIds: RemoveHoldDto = new RemoveHoldDto();
  holdOrder: RemoveHoldDto = new RemoveHoldDto();
  orderNoteTextDto: OrderNoteDto = new OrderNoteDto();
  selectedOnHold: number[] = []; selectAllOnHold: boolean = false;
  declinedCCOrders: number[] = []; 
  selectedItem: string = '';
  noteText: string = '';
  holdReason: IUITKSelectItemProps | undefined;
  displayResults = false;
  gridFilters: { fieldName: string, filterValue: string, filterApplied: boolean,columnName: string; dataTye: string  }[] = [];
  totalOpenAmount:number = 0;
  swalAlert = new SwalAlert();
  worklistNotesData: WorklistNoteDto[] = [];
  constructor(
    private readonly worklistQueueService: WorklistQueueService,
    public configService: ConfigService,
    public modeService: ModeService,
    public emailService: EmailService,
    private readonly datePipe: DatePipe,
    private readonly router: Router) { }

  ngOnInit(): void {
    this.modeService.mode = Mode.Wait;
    this.worklistNotesData = [];
    this.configIsReadySubscription = this.configService.configIsReady.subscribe(() => {
      this.displayResults = false;
      this.holdReasonPickList = this.configService.getPickList("RELHREASON");
      this.refreshFilterData();
      this.isDeclinedCC = false;

      this.worklistQueueService.getWorkListQueueData().subscribe((result: WorklistQueueDto[]) => {
        let worklistQueueItems: WorklistQueueDto[] = [];
        if (this.resourceTypeValue === 'All') {
          worklistQueueItems = result;
        }
        else {
          worklistQueueItems = result.filter(w => w.salesOrganization === this.resourceTypeValue);
        }


        const orderIds = worklistQueueItems.map(o => o.orderNumber);
        const filteredOrderIds = worklistQueueItems.filter(({ orderNumber }, index) => !orderIds.includes(orderNumber, index + 1));
        worklistQueueItems = filteredOrderIds;

        if (this.onChangeValue === 'All') {
          this.resultDataSource.data = [...worklistQueueItems];
          this.filteredResultDataSource.data = [...this.resultDataSource.data];
        }
        else {
          this.resultDataSource.data = worklistQueueItems.filter(data => data.filterType === this.onChangeValue);
          this.filteredResultDataSource.data = [...this.resultDataSource.data];
        }
        this.declinedCCOrders = this.filteredResultDataSource.data.filter(o => o.filterType === 'Declined CC' && o.hasWorkListNotes === true).map(o => o.orderNumber);
        if (this.declinedCCOrders.length > 0) {
          this.getWorklistNotes()
      }

        this.selectAllOnHold = false;
        this.selectedOnHold = [];
        this.modeService.mode = Mode.View;
        this.totalOpenAmount = this.filteredResultDataSource.data.reduce((sum, current) => sum + current.openAmount, 0);
        this.displayResults = true;
      }, error => {
        console.log(error);
        this.swalAlert.alert('Not able to fetch Worklist Queue Items');
        this.displayResults = true; //to display no records found on error
      });
    });
    
    this.configService.loadConfigurations();
  }
  getWorklistNotes() {
    this.worklistQueueService.getWorklistNotes(this.declinedCCOrders).subscribe(data => {
      data.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())
      this.worklistNotesData = data;
      this.worklistNotesData.forEach(element => console.log('element.orderId :' + element.orderId + 'element.noteText' + element.noteText));
      this.filteredResultDataSource.data.forEach(element => {
        element.workListNotes = this.worklistNotesData.filter(o => o.orderId === element.orderNumber).map(o => o.noteText) ?? []; //.join(' ');
        console.log('workListNotes length=' + element.workListNotes.length + ' OrderId=' + element.orderNumber);
      });
    });
  }
  navigateAddNote(worklistQueueDto: WorklistQueueDto) {
    this.hasNotes = worklistQueueDto.hasWorkListNotes;
    this.orderNumber = worklistQueueDto.orderNumber;
    this.worklistQueueService.selectedWorkListQueueDto = worklistQueueDto
    this.openViewNotePopup = true;
    this.isDeclinedCC = worklistQueueDto.filterType === 'Declined CC';
  }

  closeEvent() {
    this.openAddNotePopup = false;
    this.openViewNotePopup = false;
    if (this.isDeclinedCC) {
      this.getWorklistNotes()
    }
  }

  ngAfterViewInit() {
    this.uitkTableSort.setDirection({ column: 'orderDate', direction: UITKSortDirection.DESC });
    this.filteredResultDataSource.sort = this.uitkTableSort;
  }

  onSortChange(event: IUITKColumnState): void {
    this.filteredResultDataSource.data = this.filteredResultDataSource.sortData(this.filteredResultDataSource.data, event.direction, event.column);
  }

  navigateToSFViewAccount(sfId: string) {
    window.open(
      `${this.configService.salesforceUrl}/apex/ViewAccount?id=${sfId}`
    );
  }

  selectAll() {
    this.selectedOnHoldRecords = [];
    this.selectedOnHold = [];
    for (let i = 0; i < this.filteredResultDataSource.data.length; i++) {
      this.selectedOnHoldRecords.push(this.filteredResultDataSource.data[i]);
    }
    for (let i = 0; i < this.filteredResultDataSource.data.length; i++) {
      this.filteredResultDataSource.data[i].isSelected = this.selectAllOnHold;

      if (this.filteredResultDataSource.data[i].isSelected === true) {
        this.selectedOnHold.push(this.filteredResultDataSource.data[i].orderNumber);
      }
    }

  }
  openHoldReasonDialog() {
    this.holdReasonDialogModal.show = true;
    this.modeService.mode = Mode.Wait;
  }

  selectOnHoldRecord(event: any) {
    this.selectAllOnHold = false;
    if (event.isSelected === true) {
      this.selectedOnHold.push(event.orderNumber);
      if (this.filteredResultDataSource.data.length === this.selectedOnHold.length)
      {
        this.selectAllOnHold = true;
      }
    }
    else {
      const index: number = this.selectedOnHold.indexOf(event.orderNumber);
      
      if (index !== -1) {
        this.selectedOnHold.splice(index, 1);
      }
    }
  }

  refreshData() {
    this.modeService.mode = Mode.Wait;
    this.resultDataSource.data = [];
    this.refreshFilterData();
    this.filteredResultDataSource.data = [];
    this.ngOnInit();
    this.selectAllOnHold = false;
    this.selectedOnHold = [];
  }
  refreshFilterData(){
    const resetData =  {
      "filterValue": '',
      "filterApplied" :false,
      "columnName" :''
    }
    this.gridFilters.forEach((filters,index) => {

      this.gridFilters[index] = {...filters,...resetData

      };
    });
  }
  saveReason() {
    const currentUser = this.configService.getMyMSId();
    for (let i = 0; i < this.selectedOnHold.length; i++) {
      this.orderidConcade += this.selectedOnHold[i] + ",";
    }
    this.holdOrder = new RemoveHoldDto();
    this.orderidConcade = this.orderidConcade.slice(0, -1);
    let orderArr = this.orderidConcade.split(',');
    for (let j = 0; j < orderArr.length; j++)
    {
      this.holdOrder.orderIds.push(parseInt(orderArr[j]));
    }
    this.holdOrder.orderNoteDto.noteText = this.holdReason?.label + (this.noteText?('- ' + this.noteText):"");
    this.holdOrder.orderNoteDto.createdDate = new Date();
    this.holdOrder.orderNoteDto.userCode = this.configService.getMyUserCode();
    this.removeHoldOrderIds = new RemoveHoldDto();
    this.removeHoldOrderIds.orderIds = this.holdOrder.orderIds;
    this.removeHoldOrderIds.orderNoteDto = this.holdOrder.orderNoteDto;
    this.worklistQueueService.saveRemoveHoldReason(this.removeHoldOrderIds, currentUser).subscribe(() => {
      this.configService.loadConfigurations();
      this.resultDataSource.data = [];
      this.filteredResultDataSource.data = [];
      this.holdReason = undefined;
      this.removeHoldOrderIds.orderIds = [];
      this.holdOrder.orderIds = [];
      orderArr = [];
      this.ngOnInit();
      this.selectAll();
      this.noteText = '';
    });
    this.holdReasonDialogModal.show = false;
    orderArr = [];
    this.modeService.mode = Mode.View;
    this.orderidConcade = '';
  }

  navigateToOrder(orderId: number): void {
    localStorage.setItem('navigateOrigin', 'worklistQueue');
    const orderNumbers = this.filteredResultDataSource.data.map(item => item.orderNumber);
    localStorage.setItem('navigateOrderList', JSON.stringify(orderNumbers));
    localStorage.setItem('navigatePosition', orderNumbers.indexOf(orderId).toString());
    localStorage.setItem('filterTypeWorkListQueue', this.onChangeValue);
    localStorage.setItem('resourceTypeWorklistQueue', this.resourceTypeValue);
    this.router.navigate(['/order', orderId]);
  }

  cancelDialog() {
    this.selectAll();
    this.holdReason = undefined;
    this.holdReasonDialogModal.show = false;
    this.noteText = '';
    this.modeService.mode = Mode.View;
  }

  retryCC()
  {
    const retryCC = new RetryCCDto();
    retryCC.orderIds=this.selectedOnHold;
    retryCC.userName=this.configService.getMyMSId();
    this.worklistQueueService.saveRetryCC(retryCC).subscribe(() => {
      this.configService.loadConfigurations();
      this.resultDataSource.data = [];
      this.filteredResultDataSource.data = [];
      this.holdReason = undefined;
      this.ngOnInit();
      this.selectAll();
      this.noteText = '';
    });
  }
  saveDeclinedCCWorklistNote(orderId: number): void {

    if (this.worklistQueueService.selectedWorkListQueueDto) {
      this.worklistQueueService.selectedWorkListQueueDto.hasWorkListNotes = true;
    }
    const worklistNote: WorklistNoteDto = new WorklistNoteDto();
    this.followUpDate = new DatePipe('en-US').transform(this.followUpDate, 'MM/dd/yyyy') ?? '';
    worklistNote.orderId = orderId;
    this.createdDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    worklistNote.followUpDate.setDate(worklistNote.createdDate.getDate() + 7);
    worklistNote.userCode = this.userName === 'bypass' ? 'ATMN' : this.configService.getMyUserCode();
    if (this.worklistQueueService.emailType == "1st Request") {
      worklistNote.noteText = "Sent 1st Request " + this.createdDate;
    }
    else {
      worklistNote.noteText = "Sent 2nd Request " + this.createdDate;
    }
    this.worklistQueueService.saveWorklistNote(worklistNote).subscribe(() => {
      /*this.hideDialog();*/
    }, error => {
      console.log(error);
      this.swalAlert.alert('An error occured while saving the worklist note');
    });
  }

  SendDeclinedEmail(){
    let declinedCCDto = new DeclinedCCDto();
    declinedCCDto.orderIds = this.selectedOnHold;
    declinedCCDto.userName = this.configService.getMyMSId();
    declinedCCDto.userCode = this.userName === 'bypass' ? 'ATMN' : this.configService.getMyUserCode();
    declinedCCDto.emailType = this.worklistQueueService.emailType;
    this.emailService.sendDeclinedCCEmail(declinedCCDto).subscribe(() =>{
           this.declinedDialog = {
            header: 'Success',
            content:'Email Sent successfully',
            icon:'uitk-icon uitk-icon-checkmark_filled md-icon'
      }
      this.selectedOnHold.forEach(element => {
        this.saveDeclinedCCWorklistNote(element);
      });
           this.emailDeclinedDialog.show = true;     
    }, error =>{
      this.declinedDialog = {
        header: 'Failure',
        content:'Some error occured while sending email',
        icon:'uitk-icon uitk-icon-checkmark_filled md-icon'
      }
       this.emailDeclinedDialog.show = true;   
    });
  }

  navigateToARInquiry(accountId: number): void {
    this.router.navigate([]).then(() => {
      window.open('/accountreceivable/arinquiry/' + accountId, '_blank');
    });
  }

  okEmailDeclinedDialog(){
    this.emailDeclinedDialog.show = false;   
  }

  ngOnDestroy(): void {
    this.configIsReadySubscription?.unsubscribe();
  }
}
