import { Component, OnInit } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular/forms/select/select-item.interface';
import { Subscription } from 'rxjs';
import { Mode } from '../../enums/mode.enum';
import { SearchInvoiceByCCSearchDto } from '../../dtos/searchinvoice-by-cc-search-dto.model';
import { ConfigService } from '../../services/config.service';
import { ModeService } from '../../services/mode.service';
import { SearchInvoiceByCCService } from '../../services/search-invoice-bycc.service';

@Component({
  selector: 'app-searchinvoice-by-cc',
  templateUrl: './searchinvoice-by-cc.component.html',
  styleUrls: ['./searchinvoice-by-cc.component.css']
})
  
export class SearchinvoiceByCCComponent implements OnInit {
  configIsReadySubscription: Subscription | undefined;
  collapsiblePanel = true;
  openPanel = true;
  panelBackgroundOpen = "uitk-l-grid-open";
  panelBackground = this.panelBackgroundOpen;
  defaultLabel = "- Please Select";
  showResults=false;
  showccLast4Error=false;
  payMethodList: IUITKSelectItemProps[] = [];
  ccPayMethodList: IUITKSelectItemProps[] = [];
  payMethod: IUITKSelectItemProps | null=null ;
  ccLast4='';
  Mode= Mode;
  isDeclined = false;
  
  constructor(public configService: ConfigService,public modeService: ModeService,
    public searchInvoiceByCCService: SearchInvoiceByCCService) { }

  ngOnInit(): void {
    this.ccPayMethodList = this.configService.getPickList("PMETH").filter(pl => ['D', 'X', 'M', 'V'].indexOf(pl.value) >= 0);
    this.payMethodList = this.ccPayMethodList;

    const searchFilters: any = JSON.parse(localStorage.getItem('searchOperator')!)
    this.payMethod = this.payMethodList.filter(c => c.value === this.getFiltersSearchValue(searchFilters.filters, 'payMethod'))[0];
  }

  getFiltersSearchValue(filters: any, searchField: string) {
    if (filters.findIndex((f: { searchField: string; }) => f.searchField === searchField) > -1) {
      return filters.filter((f: { searchField: string; }) => f.searchField === searchField)[0].searchValue;
    }
    else {
      return '';
    }
  }

  setPanelBGHeight() {
    if (this.panelBackground === this.panelBackgroundOpen) {
      this.panelBackground = "uitk-l-grid1-close";
    }
    else {
      this.panelBackground = this.panelBackgroundOpen;
    }
  }

  OnChangeCCLast4()
  {
    this.showccLast4Error=false;
  }

  OnSearchClick() {
    this.configService.resetIdleTimeout();
    if(this.ccLast4==='')
    {
      this.showccLast4Error = true;
    }
    else
    {
      this.showResults=false
      this.modeService.mode = Mode.InvoiceByCCWait;
      let searchOperator = new SearchInvoiceByCCSearchDto();
      searchOperator.CCType=this.payMethod?.value==null ? "":this.payMethod?.label;
      searchOperator.ccLast4 = this.ccLast4;
      searchOperator.isDeclined = this.isDeclined;
      this.searchInvoiceByCCService.Invoices = null;
      this.searchInvoiceByCCService.searchInvoices(Mode.View, searchOperator);
      this.showResults=true
    }
  }
  clearInput()
  {
   this.isDeclined=false;
   this.ccLast4='';
  this.payMethod=null;
  this.showResults=false;
  }
}
