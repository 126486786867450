<div class="footer" id="additionalActions">
  <div class="container-fluid bg-light">
    <div class="row">
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-3 align-self-center" uitkLabel>Ship Via</label>
          <div class="col-lg-5 no-lp no-rp">
            <select #mySelect id="shipViaDD"
              [(ngModel)]="orderService.orderDto.shipmentMethod" (change)="onShipViaChange(mySelect.value)"
              class="form-select" aria-label="select"
              [disabled]="modeService.mode !== Mode.Edit ||  isShipViaDisabled">
              <option *ngFor="let list of shippingMethods" value={{list.deliveryCode}}>{{list.deliveryCode}}
              </option>
            </select>
          </div>
          <div id="rushCheckbox" class="col-lg-3 no-lp no-rp">
             <uitk-form-field>
              <input  uitkCheckbox id="rush" type="checkbox" [(ngModel)]="orderService.orderDto.isRush"
                appEnableResource="OrderFooterRushCheckBox"
                
                [(ngModel)]="orderService.orderDto.isRush" 
                [disabled]="modeService.mode !== Mode.Edit
                || orderService.orderDto.shipmentMethod === 'UPS-AK/HI' || orderService.orderDto.shipmentMethod === 'UPS-INT'
                || orderService.orderDto.shipmentMethod==='UPS-ONE' || orderService.orderDto.shipmentMethod==='UPS-TWO'" />
              <label uitkCheckboxLabel id="rushLabel" for="rush" bbLogTable="OrderHeader" bbLogColumn="IsRush" [bbLogPrimaryId]="orderService.orderDto.id" style="color: black">Rush</label>
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-3 align-self-center" uitkLabel [ngStyle]="{ 'color': orderService.orderDto.billToDto.isPORequired ? 'red' : 'black', 'background-color': orderService.orderDto.billToDto.isPORequired ? 'yellow' : 'white' }">PO #</label>
          <div class="col-lg-9"  bbLogTable="OrderHeader" bbLogColumn="PONumber" [bbLogPrimaryId]="orderService.orderDto.id">
            <uitk-form-field>
              <input id="poNumber" uitkInput [(ngModel)]="orderService.orderDto.poNumber" maxlength="75"

                 [disabled]="modeService.mode !== Mode.Edit || isOrderFooterPODisabled"
                 />
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-4 align-self-center" uitkLabel>Payment Plans</label>
          <div class="col-lg-8">
            <select id="paymentPlansDD" [(ngModel)]="orderService.orderDto.paymentPlan" class="form-select"
              aria-label="select"
              [disabled]="modeService.mode !== Mode.Edit || isOrderFooterPaymentPlanDisabled" (change)="onChangePaymentPlanValue()">
              <option value="" selected>Select</option>
              <option *ngFor="let list of paymentPlansList" [ngValue]="list.value" [disabled]="list.disabled">
                {{list.label}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-4 align-self-center" uitkLabel>Tax Rate</label>
          <div class="col-lg-8">
            <input id="maxTaxRate" uitkInput class="textbox" disabled type="text" value={{orderService.orderDto.maxTaxRate}} />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-3 align-self-center" uitkLabel>Method</label>
          <div class="col-lg-9 no-lp"
          bbLogTable="OrderHeader" bbLogColumn="PaymentMethod" [bbLogPrimaryId]="orderService.orderDto.id">

            <select id="methodDD" [(ngModel)]="orderService.orderDto.paymentMethod"

              (change)="callCashReceiptBR();onChangePaymentMethod();" class="form-select" aria-label="select"
              appEnableResource="OrderInquiryFooterMethodField"
              [disabled]="modeService.mode !== Mode.Edit||(orderService.orderDto.paymentMethod==='C'&&!this.hasResourceEditPaymentMethodC)">
              <option value="null" selected>Select</option>
              <option *ngFor="let list of methodList" [ngValue]="list.value" [disabled]="list.disabled">{{list.label}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-3 align-self-center" uitkLabel>Terms</label>
          <div class="col-lg-9"  bbLogTable="OrderHeader" bbLogColumn="PaymentTerms" [bbLogPrimaryId]="orderService.orderDto.id">
            <select *ngIf="orderService.orderDto.paymentTerms==='Credit Card'" id="termsDD"

              appEnableResource="OrderFooterTermsDropDown" [(ngModel)]="orderService.orderDto.paymentTerms"
              class="form-select" aria-label="select" [disabled]="true">
              <option value="null" selected>Select</option>
              <option *ngFor="let list of termsList" [ngValue]="list.label">{{list.label}}</option>
            </select>
            <select *ngIf="orderService.orderDto.paymentTerms!=='Credit Card'" id="termsDD"
              appEnableResource="OrderFooterTermsDropDown" [(ngModel)]="orderService.orderDto.paymentTerms"
              class="form-select" aria-label="select" [disabled]="modeService.mode !== Mode.Edit  || disablePaymentTerms">
              <option value="null" selected>Select</option>
              <option *ngFor="let list of termsListWithoutCreditCard" [ngValue]="list.label">{{list.label}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-4 align-self-center" id="ppEligibleAmtOELabel" uitkLabel>PP Eligible
            Amt</label>
          <div class="col-lg-8">
            <uitk-form-field>
              <input id="ppEligibleAmtOE" type="text" [(customModel)]="orderService.orderDto.eligibleAmount" uitkInput
                disabled appPriceFormat />
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-4 align-self-center" uitkLabel>Total Tax</label>
          <div class="col-lg-8">
            <uitk-form-field><input id="totaltax" uitkInput value="{{ orderService.orderDto.totalTax | number:'1.2-2' }}" disabled />
            </uitk-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <div class="row">
          <label *ngIf="modeService.mode === Mode.Edit" class="col-lg-3 align-self-center" uitkLabel>CC#/Token</label>
          <label *ngIf="modeService.mode !== Mode.Edit" class="col-lg-3 align-self-center" uitkLabel>Token</label>
          <div class="col-lg-9 no-lp" bbLogTable="OrderHeader" bbLogColumn="CCToken" [bbLogPrimaryId]="orderService.orderDto.id">
            <uitk-form-field>
              <input id="tokenInput" uitkInput class="textbox" type="text" (ngModelChange)="onNgModelTokenChange($event)"
                name="tokenInp" #tokenInp="ngModel" [(ngModel)]="orderService.orderDto.ccToken"
                [disabled]="modeService.mode !== Mode.Edit || (orderService.orderDto.ccToken?.startsWith('Processing') || false) || modeDisabled"
                (input)="tokenChange()" />
              <uitk-form-field-error
                *ngIf="!modeDisabled && orderService.orderDto.ccToken!==' ' && orderService.orderDto.ccToken?.length!==6 && tokenInp.touched">
                Token must be 6 digits
              </uitk-form-field-error>
              <uitk-form-field-error *ngIf="(tokenInp?.errors?.required && tokenInp.touched) || creditCardRequired">
                Token is required
              </uitk-form-field-error>
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-3 align-self-center" uitkLabel>Exp</label>
          <div class="col-lg-9"  bbLogTable="OrderHeader" bbLogColumn="CCExpirationDate" [bbLogPrimaryId]="orderService.orderDto.id">
            <uitk-form-field>
              <input id="ccExp" uitkInput [(ngModel)]="expirationDate"
                appEnableResource="OrderFooterExpTextBox"
             (change)="expirationdate()" name="expDateInp" #expDateInp="ngModel" [pattern]=expDatePattern
                (input)="expDateChange(expirationDate)"
                value="{{expirationDate}}"
                maxlength="4" [disabled]="modeService.mode !== Mode.Edit || modeDisabled" />
              <uitk-form-field-error *ngIf="expirationDate!=='' && (isPastDate)">
                Credit Card is expired
              </uitk-form-field-error>
              <uitk-form-field-error
                *ngIf="(expirationDate!=='' && expDateInp?.errors?.pattern) || showExpDatePatternError">
                Should be valid MMYY format
              </uitk-form-field-error>
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-4 align-self-center" uitkLabel>CC Last 4</label>
          <div class="col-lg-8" bbLogTable="OrderHeader" bbLogColumn="CCLastFour" [bbLogPrimaryId]="orderService.orderDto.id">
            <uitk-form-field>
              <input id="last4ofCCInput" uitkInput [(ngModel)]="orderService.orderDto.ccLastFour" class="textbox"
               type="text" maxlength="4" [disabled]="modeService.mode !== Mode.Edit || modeDisabled" />
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-4 align-self-center" uitkLabel>SO Total</label>
          <div class="col-lg-8">
            <uitk-form-field><input id="soTotal" uitkInput
                value="{{ orderService.orderDto.shippedAmount + orderService.orderDto.openAmount + orderService.orderDto.totalTax | number:'1.2-2' }}"
                disabled /></uitk-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <div class="row">
          <label class="col-lg-3 align-self-center"  [ngClass]="{ 'highlight': orderService.orderDto.ccAuthorizationCode === 'D' }" uitkLabel>CC Auth</label>
          <div class="col-lg-9"  bbLogTable="OrderHeader" bbLogColumn="CCAuthorizationCode" [bbLogPrimaryId]="orderService.orderDto.id">
            <select id="ccAuthDD" [(ngModel)]="orderService.orderDto.ccAuthorizationCode" class="form-select" (change)="onChangeCCAuthorization()"

            aria-label="select" [disabled]="modeService.mode !== Mode.Edit ||modeDisabled || isCCAuthDisabled">
              <option value="null" selected>Select</option>
              <option *ngFor="let suit of ccAuthList" [ngValue]="suit.value" [disabled]="suit.disabled">{{suit.label}}</option>
            </select>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
