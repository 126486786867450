import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ScrollToDirective } from 'src/app/directives/scrollTo.directive';
import { ApprovalDto } from 'src/app/dtos/approval-dto.model';
import { CheckRenewalDto } from 'src/app/dtos/check-renewal-dto.model';
import { EmailInputDto } from 'src/app/dtos/email-input-dto.model';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { EmailService } from 'src/app/services/email.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { Mode } from '../../../enums/mode.enum';
import { SourceType } from '../../../enums/source-type.enum';
import { Dialog } from '../../../helpers/dialog';
import { ItemDetailDto } from '../../../dtos/item-details-dto.model';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { OrderOfferInputDto } from '../../../dtos/order-offer-input-dto.model';
import { OrderOfferType } from '../../../dtos/order-offer-type.enum';
import { ProductDto } from '../../../dtos/product-dto.model';
import { TierDto } from '../../../dtos/tier-dto.model';
import { ApprovalService } from '../../../services/approval.service';
import { ConfigService } from '../../../services/config.service';
import { ContactService } from '../../../services/contact.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';
import { ShippinghandlingService } from '../../../services/shippinghandling.service';
import { TaxService } from '../../../services/tax.service';
import { SubscriptionPriceCalculationComponent } from '../../order-inquiry/subscription-price-calculation/subscription-price-calculation.component';
import { OrderDetailsGridComponent } from '../order-details-grid/order-details-grid.component';
import { ShipToDto } from 'src/app/dtos/ship-to-dto.model';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-order-entry-footer',
  templateUrl: './order-entry-footer.component.html',
  styleUrls: ['./order-entry-footer.component.css'],
  providers: [OrderDetailsGridComponent, SubscriptionPriceCalculationComponent]
})

export class OrderEntryFooterComponent implements OnInit, OnDestroy {
  private readonly _router: Router;
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _approvalService: ApprovalService;
  private readonly _configService: ConfigService;
  private readonly _contactService: ContactService;
  private readonly _orderDetailsGridComponent: OrderDetailsGridComponent;
  private readonly _shippinghandlingService: ShippinghandlingService;
  private readonly _taxService: TaxService;
  private readonly _emailService: EmailService;
  private readonly _elementRef: ElementRef;
  private readonly _authorizationService: AuthorizationService;

  isDisableOMANDSAStatus: boolean= true;
  disableMultiYearBtn = true;
  disableCalculateBtn = false;
  saveAndClone = false;
  orderLevelOffersOthersDialog = new Dialog<string>();
  orderLevelTierOfferDialog = new Dialog<string>();
  Mode = Mode;
  getOrderOfferResultsSubscription: Subscription | undefined;
  saveConfirmationDialog = new Dialog<void>();
  cancelConfirmationModel = { show: false };
  showOrderLevelApprovalPopup = new Dialog<string>();
  requireEmailDialog = new Dialog<string>();
  blankShipToEmailDialog = { show: false };
  blankorInvalidShipToEmailDialog = { show: false };
  invalidEmailDialogForElectronicProducts = { show: false };
  electronicProductCodesOnly: string = '';
  eAlertProductCodesOnly: string = '';
  productType: string = '';
  functionType: string = '';
  forceSaveAction: boolean = false;
  noOfSaveClicks: number = 0;
  onTaxErrorSubscription: Subscription | undefined;
  callEditSubscription: Subscription | undefined;
  contactDialogModal = new Dialog<string>();
  userRole: string | null = '';
  changesPresentInTheCart = false;
  orderOfferQualificationDialog = new Dialog<string>();
  associatedPremiumsList = '';
  offerType = '';
  targetEle = '';
  triggerEleToFocus= '';
  itemsRestrictedList: string[] = [];  
  private readonly contactRequiredMsg = "Contact Id is required";
  private readonly bothElectricAndEAlertTxt = "Both Electronic and E-Alert";
  voidOrderDialog = new Dialog<string>();
  @Input() newMyOrder = '';
  isOrderInRejectedStatus: boolean = false;
  multiYearPreviousYearOrder: boolean = false;
  @Output() addedPremiumShippingItem = new EventEmitter();
  swalAlert = new SwalAlert();
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    //return !this.orderLocked;
    if (this.modeService.mode === Mode.Edit) {
      this.allowOfCancel();
    }
  }
  constructor(public orderService: OrderService,
    public pricingService: PricingService,
    shippinghandlingService: ShippinghandlingService,
    public modeService: ModeService,
    router: Router,
    orderDetailsGridComponent: OrderDetailsGridComponent,
    activatedRoute: ActivatedRoute,
    approvalService: ApprovalService,
    configService: ConfigService,
    contactService: ContactService,
    taxService: TaxService,
    emailService: EmailService,
    elementRef: ElementRef,
    authorizationService: AuthorizationService,
    private readonly subscriptionService: SubscriptionService
    ) {
    this._router = router;
    this._activatedRoute = activatedRoute;
    this._approvalService = approvalService;
    this._configService = configService;
    this._contactService = contactService;
    this._orderDetailsGridComponent = orderDetailsGridComponent;
    this._shippinghandlingService = shippinghandlingService;
    this._taxService = taxService;
    this._emailService = emailService;
    this._elementRef = elementRef;
    this._authorizationService = authorizationService;
  }

  @ViewChild(ScrollToDirective) dir:any;
  ngOnInit(): void {
    this.userRole = localStorage.getItem('rolename');

    this.callEditSubscription = this.orderService.onTriggerEdit.subscribe(async() => {
      await this.edit();
      this.orderService.resolveEdit();
    });
     if (this._authorizationService.hasResource('OrderEntryFooterVoid')) {

       this.isDisableOMANDSAStatus = false;

     }

  }

  async afterSave() {
    this.orderService.orderDto.hasUserSeenPromoForm = false;
    this.orderService.orderDto.promotionOfferPopupButton = '';
    this.orderService.orderDto.orderOfferQualificationsChangedPopupButton = '';
    this.orderService.orderDto.tierDiscountType = '';
    this._approvalService.showApprovalHistoryOnQuote = this.orderService.orderDto.orderType === "Q" && (this.orderService.orderDto.orderStatus === " " || this.orderService.orderDto.orderStatus === "P" ||this.orderService.orderDto.orderStatus === "M" ||this.orderService.orderDto.orderStatus === "R" ) ;

    if (this.orderService.sourceType === SourceType.OrderEntrySave && this.orderService.orderDto.multiYearDtos.length >0  && this.orderService.orderDto.orderType === ' ' && (this.orderService.orderDto.orderStatus === 'D' || this.orderService.orderDto.orderStatus === ' ')) {
      await this.orderService.getOrderInformationAsync(this.orderService.orderDto.id);
    }

    if (this.orderService.orderDto.orderType !== 'Q' && this.orderService.orderDto.orderStatus !== 'P'
      && this.orderService.orderDto.orderStatus !== 'D' && this.orderService.orderDto.orderStatus !== 'M' && this.saveAndClone === false) {
      this._router.navigate(['/order/' + this.orderService.orderDto.id]);
    }
    else {
      if ((this.orderService.orderDto.orderType === 'Q' || this.orderService.orderDto.orderStatus === 'P' ||
        this.orderService.orderDto.orderStatus === 'D' || this.orderService.orderDto.orderStatus === 'M') && !this.saveAndClone) {
        this._router.navigate(['.'], { relativeTo: this._activatedRoute, queryParams: { orderId: this.orderService.orderDto.id } });
        await this.orderService.getOrderInformationAsync(this.orderService.orderDto.id);
        this.callGetOrderApprovalHistory();

        setTimeout(() => {
          this.modeService.mode = Mode.View;
        }, 1000);
      }

      if (this.saveAndClone) {
        await this.createSaveAndCloneRecord();
        this._orderDetailsGridComponent.addNewLineItem();
        this._approvalService.refreshApprovalFields(this.orderService.orderDto);
        setTimeout(() => {
          this.modeService.mode = Mode.Edit;  
        }, 1000);
      }
    }
  }

  callGetOrderApprovalHistory(){
    if (this.orderService.orderDto.orderStatus === 'P') {
      this._approvalService.getOrderApprovalHistory(this.orderService.orderDto);
    }
  }


  async validationForConfigSetItems(): Promise<boolean> {
    for(let i = 0; i<this.orderService.orderDto.orderDetailDtos.length; i++){
      const configSetDtos = this._configService.reference?.productDtos.filter(p => p.productCode.trim() === this.orderService.orderDto.orderDetailDtos[i].productCode.trim() && p.classCode !== 'SH')[0]?.configSetDtos?.length??0;
      const configSetExist = configSetDtos>0;
    if(!this.orderService.orderDto.orderDetailDtos[i].configSetSaved && configSetExist && this.orderService.orderDto.id <= 0){
      await this.swalAlert.alert('All configuration must be complete prior to saving the cart.');
      return false;
    }
    }
    return true;
  }
  async validationsForCartItems(): Promise<boolean> {
    for(let i = 0; i < this.orderService.orderDto.orderDetailDtos.length; i++){
      let orderDetail = this.orderService.orderDto.orderDetailDtos[i];
      let productCode = orderDetail.productCode.toUpperCase().trim();
      let productDto = this._configService.reference?.productDtos.filter(p => p.productCode === productCode)[0];
      if(!productDto || (orderDetail.orderDetailStatus!= 'V' && productDto?.statusCode === 'I')){
        await this.swalAlert.alert(`This item ${productCode} is inactive or does not exist`);
        await this.edit();
        this._orderDetailsGridComponent.deleteItem(orderDetail);
        this._orderDetailsGridComponent.sumOrderQuantity();
        setTimeout(() => {
          if (document.getElementById(`productCode--${this.orderService.orderDto.orderDetailDtos.findIndex(x=>x.productId === 0)}`)) {
            document.getElementById(`productCode--${this.orderService.orderDto.orderDetailDtos.findIndex(x=>x.productId === 0)}`)?.focus();
          }
        });
        this._approvalService.refreshApprovalFields(this.orderService.orderDto);

        return false;
      }
      if (productDto.isOutOfStock) {
        let message = "Product "+productCode +" is out of stock";
        if (productDto.outOfStockNote !== null && productDto.outOfStockNote.length > 0) {
          message = productDto.outOfStockNote;
        }
        await this.swalAlert.alert(message);
      }
    }
    return true;
  }

  onModalClosed(event:any){
    this.dir.target = "#divLeftOrderDetailsGrid";
    this.dir.scrollToPosition();
  }

  async saveBtnClick(): Promise<boolean> {
    this.functionType = 'Save';
    this.populateDefaultValues();

    if(!await this.multiYearSaveValidation()){
      return false;
    }
    if(!await this.validationsBeforeSave()){
      return false;
    }

    this.orderService.recalculateFields(this.orderService.orderDto);
    this.isCartChanged();
    
    const bypassApproval = (this.orderService.orderDto.id > 0 && this.orderService.orderDto.orderStatus === ' ' && !this.changesPresentInTheCart || (this.orderService.orderDto.webReferenceNumber != null && this._authorizationService.hasResource("AllowWebOrderApprovalBypass")));
    this.orderService.orderDto.byPassApproval = bypassApproval;
    this.orderService.orderDto.finalMultiYearSave = this.orderService.orderDto.multiYearDtos.length > 0 ? true : false;
    this.orderService.sourceType = SourceType.OrderEntrySave;
    this.removeEmptyRowFromCart();
    this.triggerEleToFocus="saveBtn";
    this.orderService.shippingDialogModalTriggerEle = "saveBtn";
    if (!this.forceSaveAction) {
      if (await this.showPopupForRequestSTCEmailForElectronicEAlertProducts()) {
        return false;
      }
    }
    
    this.forceSaveAction = false;

    if ((await this._approvalService.refreshApprovalFields(this.orderService.orderDto)).approvalRequired === 'Yes' && !bypassApproval) {
      this.orderService.orderEntryFunctionType = 'Save';
      await this.openApprovalPopupIfRequired();
    }
    else {
      this.setOrderStatusForMultiYearSave();
      if (await this.orderService.saveOrderAsync() === 'Success') {
        await this.saveConfirmationDialog.openAsync();
        await this.afterSave();
      }
    }
    return true;
  }

  isCartChanged(){
    if (this.orderService.openAmountBackUp >= this.orderService.orderDto.openAmount) {   
      this.changesPresentInTheCart = false;
    }
    else {
      this.changesPresentInTheCart = true;
    }
  }

  setOrderStatusForMultiYearSave(){
    if (this.orderService.orderDto.finalMultiYearSave) {
      this.orderService.orderDto.orderStatus = 'D';
    }
    else {
    this.orderService.orderDto.orderStatus = ' ';
    }
  }

  async validateDuplicateRenewals(): Promise<boolean> {
    // Find any subscriptions already renewed for the same start date.

    const checkRenewalDtos: CheckRenewalDto[] = this.orderService.orderDto.orderDetailDtos
      .filter(od => od.orderDetailStatus !== 'V' && od.subscriptionDto && od.subscriptionDto.subscriptionNumber)
      .map(od => ({ 
        accountId: this.orderService.orderDto.billToDto.id, 
        subscriptionNumber: od.subscriptionDto?.subscriptionNumber ?? 0,
        startDate: od.subscriptionDto?.startDate ?? new Date(),
        orderDetailId: od.id
        }));
    const duplicates = await this.subscriptionService.verifySubscriptionPriorRenewal(checkRenewalDtos);

    if (duplicates === null) {
      // An error occured while getting duplicates.
      return false;
    } else if (duplicates.length > 0) {
      // I found duplicates if the code reaches this point.
      for(const duplicate of duplicates) {

        await this.swalAlert.alert(`Item ${duplicates[0].productCode} has already been renewed on order ID ${duplicates[0].orderId} and will be removed from this order.`);
        
        const orderDetail = this.orderService.orderDto.orderDetailDtos.filter(od => od.id === duplicate.orderDetailId)[0];
        orderDetail.isSubscriptionRenewedOnDifferentOrder = true;
        this.orderService.deleteOrVoidOrderDetail(orderDetail);
      }
      
      if (this.orderService.orderDto.orderDetailDtos
          .filter(item => item.orderDetailStatus !== 'V' 
          && item.productCode !== '0' 
          && item.productCode !== '').length === 0) {
        return false;
      }

      return true;
    } else {
      // No duplicates found if the code reaches this point.
      return true;
    }
  }

  async saveCloneBtnClick() {
    const saveCloneTxt = "Save&Clone";
    const saveCloneBtn = "saveCloneBtn";
    this.functionType = saveCloneTxt;
    this.populateDefaultValues();
    
    if(!await this.validationsBeforeSave()){
      return;
    }

    this.saveAndClone = true;
    this.orderService.sourceType = SourceType.OrderEntrySaveAndClone;
    this.removeEmptyRowFromCart();
    this.triggerEleToFocus = saveCloneBtn;
    this.orderService.shippingDialogModalTriggerEle = saveCloneBtn;
    if (!this.forceSaveAction) {
      if (await this.showPopupForRequestSTCEmailForElectronicEAlertProducts()) {
        this.saveAndClone = false;
        return;
      }
    }

    this.forceSaveAction = false;

    if ((await this._approvalService.refreshApprovalFields(this.orderService.orderDto)).approvalRequired === 'Yes') {
      this.orderService.orderEntryFunctionType = saveCloneTxt;
      await this.openApprovalPopupIfRequired();
    }
    else {
      this.orderService.orderDto.orderStatus = ' ';

      if (await this.orderService.saveOrderAsync() === 'Success') {
        await this.saveConfirmationDialog.openAsync();
        await this.afterSave();
        this.modeService.mode = Mode.Edit;
        this.orderService.orderDto.shipToDto=new ShipToDto();
       this.orderService.orderDto.shipToContactBinding= { id: '', label: '', value: '' };
       this.orderService.shiptoIsSelected=false;

        if (document.getElementById(`shipToContactName-dropdown`)) {
          setTimeout(() => {
            document.getElementById(`shipToContactName-dropdown`)?.focus();
            (document.getElementById(`shipToContactName-dropdown`) as HTMLInputElement)?.select();
          }, 1000);
        }
      } else {
        this.saveAndClone = false;
      }
    }
  }

  async multiYearBtnClick() {
    this.functionType = 'MultiYear';
    this.populateDefaultValues();
    this.orderService.sourceType = SourceType.OrderEntryMutliyear;
    const itemsRestrictedFromMUDeals = this.orderService.orderDto.orderDetailDtos
      .filter(item => !item.productDto.allowMultiYear)
      .reduce((list, item) => list ? (list + ', ' + item.productCode) : item.productCode, '');
    this.itemsRestrictedList = itemsRestrictedFromMUDeals === '' ? [] : itemsRestrictedFromMUDeals.split(',')
    this.itemsRestrictedList.pop();
    if (this.itemsRestrictedList.length !== 0) {
      const message = this.itemsRestrictedList.length === 1
        ? `Item ${this.itemsRestrictedList[0]} does not allow multiyear orders. The item will be removed from the next year's order.`
        : `Items ${this.itemsRestrictedList.join(', ')} do not allow multiyear orders. The items will be removed from the next year's order.`;
      await this.swalAlert.alert(message);
    }  

    if(!await this.validationsBeforeSave()){
      return;
    }

    this.removeEmptyRowFromCart();
    this.orderService.shippingDialogModalTriggerEle = "multiYearBtn";
    this.triggerEleToFocus = "multiYearBtn";
    if (!this.forceSaveAction) {
      if (await this.showPopupForRequestSTCEmailForElectronicEAlertProducts()) {
        return;
      }
    }

    this.forceSaveAction = false;
    
    if ((await this._approvalService.refreshApprovalFields(this.orderService.orderDto)).approvalRequired === 'Yes') {
      this.orderService.orderEntryFunctionType = 'MultiYear';
      await this.openApprovalPopupIfRequired();
    }
    else {
      this.orderService.orderDto.orderStatus = 'D';

      if (await this.orderService.saveOrderAsync() === 'Success') {
        await this.saveConfirmationDialog.openAsync();
        await this.afterSave();
        this.orderService.addYear(true);
      }
    }
  }

  async saveAsDraftBtnClick(): Promise<void> {
    this.functionType = 'SaveAsDraft';
    this.populateDefaultValues();

    const isOrderTypeChangedFromQuoteToOrder = await this.orderTypeChangedFromQuoteToOrderValidations();

    if (!isOrderTypeChangedFromQuoteToOrder) {
      return;
    }

    if(!this.validationForConfigSetItems()){
      return;
    }

    await this.validateDuplicateRenewals();

    if (!await this.showOrderLevelOffersPopup()) {
      return;
    }


    if (!await this.validationForSaveAsDraft()) {
      return;
    }
    
    await this.orderService.validateShippingAndHandlingBR();
    if (!this.orderService.validateShipping) {
      return;
    }
 

    const showOrderLevelOffersPopupResult = await this.showOrderLevelOffersPopup();

    if (!showOrderLevelOffersPopupResult) {
      return;
    }
    
 
    this.orderService.orderDto.orderStatus = "D";
    if(this.orderService.orderDto.orderType === "E"){
      this.orderService.orderDto.orderType = " ";
    }
    this.orderService.orderDto.exceptionReasonCode = null;
    this.orderService.orderDto.holdReasonCode = null;
    this.orderService.orderDto.quoteReasonCode = null;
   
    this.orderService.sourceType = SourceType.OrderEntrySaveAsDraft;
    this.removeEmptyRowFromCart();
    this.forceSaveAction = false;
    
    if (await this.orderService.saveOrderAsync() === 'Success') {
      await this.saveConfirmationDialog.openAsync();
      await this.afterSave();
    }
  }

  

  async calculateBtnClick() {
    this.functionType = 'Calculate';
    this.populateDefaultValues();

    if(!this.validationForConfigSetItems()){
      return;
    }

    if (!await this.validationsForRequiredFields()) {
      return;
    }

    const showOrderLevelOffersPopupResult = await this.showOrderLevelOffersPopup();

    if (!showOrderLevelOffersPopupResult) {
      return;
    }
    
    await this.orderService.validateShippingAndHandlingBRForCalculateButton();
    
    if (!this.orderService.validateShipping) {
      return;
    }
    
    await this._taxService.calculateTax(SourceType.OrderEntryCalculate, this.orderService.orderDto);
    this.orderService.recalculateFields(this.orderService.orderDto);
  }

  async cancelBtnClick() {
    this.cancelConfirmationModel.show = true;
  }
  
  async convertQuoteBtnClick() {
    this.orderService.orderDto.orderType=' ';
    this.orderService.orderDto.orderDate= new Date();
    this.orderService.orderDto.enteredBy = this._configService.getMyMSId().toUpperCase().replace('MS\\', '');
    if (this.orderService.orderDto.multiYearCurrentYear) {
      const minimumQuoteYear = Math.min(...this.orderService.orderDto.multiYearDtos.filter(item => item.orderType === 'Q').map(item => item.yearOfOrder));
      const firstQuoteIdInDeal = this.orderService.orderDto.multiYearDtos.filter(item => item.yearOfOrder === minimumQuoteYear)[0]?.orderId;

      if (firstQuoteIdInDeal !== this.orderService.orderDto.id) {
        await this.swalAlert.alert('The first quote in a multi-year deal must be converted first.  Press OK to be redirected to the first quote in the multi-year deal.');
        window.open(`order/${firstQuoteIdInDeal}`);
        return;
      }
    }

    if (!this.orderService.orderDto.multiYearCurrentYear || confirm('Are you sure you wish to convert this and the future quotes as well?')) {
      
      if (!await this.validationsForCartItems()) {
        this.orderService.orderTypeChangedFromQuoteToOrder = true;
        return;
      }
      this.orderService.setLockOrder(this.orderService.orderDto.id, this._configService.getMyMSId(), false).subscribe(
        (data) => {
          this.modeService.removeFromWaitList('Converting Quote');
        }, async (error) => {
          this.modeService.removeFromWaitList('Converting Quote');
          await this.swalAlert.alert('Order is already in use and it is locked.');
        });
      
      if(!await this.saveBtnClick()){
        this.orderService.unlockOrder(this.orderService.orderDto.id).subscribe((msg: string) => {
          this.modeService.mode = Mode.View;
        }, async error => {
          this.modeService.mode = Mode.View;
          console.log(error);
          await this.swalAlert.alert('An error occurred while unlocking the order.  Please try again.');
        });
      }
    }
  }

  allowOfCancel() {
    if (this.orderService.orderDto.id > 0) {
      if(this.orderService.orderDto.multiYearCurrentYear !== null && this.newMyOrder === 'true'){
          this.orderService.voidNewSavedMYOrder();
      }
      this.orderService.unlockOrder(this.orderService.orderDto.id).subscribe((msg: string) => {
        this.redirectUserToPointOfEntry();
        return;
      }, error => {
        this.swalAlert.alert('An error occurred while unlocking the order.  Please try again.');
      });
    }
    else {
      this.redirectUserToPointOfEntry();
    }
  }
  redirectUserToPointOfEntry() {
    this.modeService.mode = Mode.View;
    this.cancelConfirmationModel.show = false;
    if (window.close() === undefined) {
      this._router.navigate([]).then(() => {
        location.reload();
      });
    }
    else {
      window.close();
    }
  }

  stopOfCancel() {
    this.cancelConfirmationModel.show = false;
    return;
  }

  async openApprovalPopupIfRequired(): Promise<void> {
    if(await this.orderService.validateOrder()){
      await this.showOrderLevelApprovalPopup.openAsync();
    }
  }

  async edit(): Promise<void> {
    this.orderService.isOrderInRejectedStatus = false;
    if (this.orderService.orderDto.orderStatus === 'P' || this.orderService.orderDto.orderStatus === 'M') {
      return;
    }
    if(this.orderService.orderDto.multiYearDtos?.length > 0){
      await this.orderService.getOrderInformationAsync(this.orderService.orderDto.id);
    }
    this.modeService.mode = Mode.Edit;
    setTimeout(() => {
      if (document.getElementById(`order-type-select-dropdown`)) {
        document.getElementById(`order-type-select-dropdown`)?.focus();
      }
    });
    return new Promise(resolve => {
    this.orderService.setLockOrder(this.orderService.orderDto.id, this._configService.getMyMSId().toUpperCase().replace('MS\\', '')).subscribe(
      async () => {
        await this.recalculateFieldsOnEdit();
        if(this.orderService.orderDto.multiYearDtos?.length > 0 &&
          this.orderService.orderDto.multiYearDtos[this.orderService.orderDto.multiYearDtos.length - 1].yearOfOrder !== this.orderService.orderDto.multiYearCurrentYear){
            this.multiYearPreviousYearOrder = true;
      }

        this.addNewLineItem();
        resolve();
      }, async error => {
        this.modeService.mode = Mode.View;
        await this.swalAlert.alert('Order is already in use and it is locked.');
        resolve();
    });
    });

  }
  async recalculateFieldsOnEdit(){
    if (this.orderService.orderDto.orderStatus === 'R') {        
      this.isOrderInRejectedStatus = true;                    
      this.orderService.orderDto.orderStatus = 'W';
      await this._approvalService.refreshApprovalRequirementsSection();
    }
    if (this.orderService.orderDto.orderType === 'Q' || this.orderService.orderDto.orderStatus === 'D') {
      await this._shippinghandlingService.recalculateShippingAndHandlingAsync(this.orderService.orderDto);
      this.orderService.recalculateFields(this.orderService.orderDto);
    }
  }
  async bindOrderOfferDto(): Promise<void> {
    const orderOfferInputDto = new OrderOfferInputDto();
    orderOfferInputDto.promotionCode = this.orderService.orderDto.promotionCode!;

    this.orderService.orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V' && o.promotionEntryCode !== 'OLP').forEach(item => {
      const itemDetailDto = new ItemDetailDto();
      itemDetailDto.productId = item.productDto.id;
      itemDetailDto.quantity = item.orderQuantity;
      itemDetailDto.extendedPrice = item.extendedPrice;
      itemDetailDto.classCode = item.classCode ?? '';

      if (itemDetailDto.productId !== 0) {
        orderOfferInputDto.itemDetails.push(itemDetailDto);
      }
    });

    this.orderService.orderDto.orderOfferDto = await this.orderService.buildOrderOfferAsync(orderOfferInputDto);

    this.orderService.orderDto.orderOfferDto.premiums.forEach(element => {
      const productDto = this._configService.reference?.productDtos.filter(p => p.productCode === element.productCode)[0];

      if (productDto?.classCode === 'SH') {
        this._shippinghandlingService.premiumShippingItem = element;
        this.addedPremiumShippingItem.emit();
      }
    });
      
    if (this.orderService.orderDto.orderOfferDto) {
      if (this.orderService.orderDto.orderOfferQualificationsChangedPopupButton === '' &&
        (this.orderService.orderDto.promotionOfferPopupButton === '' || this.orderService.orderDto.promotionOfferPopupButton === 'Return To Cart')) {
        await this.changePromotionEntryCodeToOLTIfProductQualifies();
      }
      else if (this.orderService.orderDto.orderOfferQualificationsChangedPopupButton !== '' && this.orderService.orderDto.promotionOfferPopupButton === 'Accept Promo Offer') {
        await this.changePromotionEntryCodeToOLTIfProductQualifies();
      }
    }
  }

  async changePromotionEntryCodeToOLTIfProductQualifies(): Promise<void> {
    this.orderService.orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V').forEach(orderDetail => {
      const productQualifies = this.orderService.orderDto.orderOfferDto?.productIds.some(productId => productId === orderDetail.productId);

      if (productQualifies) {
        orderDetail.promotionEntryCode = 'OLT';
      }
    });
  }
  async showOrderLevelOffersPopup(): Promise<boolean> {

    if (!(this.orderService.orderDto.offerInfoDto?.orderLevelOffer)) {
      return true;
    }
    await this.bindOrderOfferDto();

    if (this.orderService.orderDto.id > 0) {
      this.orderService.orderDto.hasUserSeenPromoForm = this.orderService.orderDto.orderStatus !== 'D' ? true : false;
    }
    if (!(this.orderService.orderDto.orderDetailDtos.some(o => o.promotionEntryCode === 'OLT' && o.orderDetailStatus !== 'V'))) {
      return true;
    }


    if (this.orderService.orderDto.orderOfferDto?.offerType === OrderOfferType.Other) {
      return await this.openOrderLevelOffersOthersDialog();
    }
    else if (this.orderService.orderDto.orderOfferDto?.offerType === OrderOfferType.Tier) {
      return await this.openOrderLevelTierOfferDialog();
    }
    else {
      return true;
    }
  }

  getMinimumFromValueForTier(tierDto: TierDto[]): number {
    return [...tierDto].sort((a, b) => 0 - (a.from > b.from ? -1 : 1))[0].from;
  }

  async validationsForRequiredFields(): Promise<boolean> {
    if (this.orderService.orderDto.billToDto.id === 0) {
      await this.displayAlert("Account Id is required",`accountId`);
      return false;
    }
    if (this.orderService.orderDto.orderByDto.contactId === 0) {
      await this.displayAlert(this.contactRequiredMsg,`contactId`);
      return false;
    }
    if (this.orderService.orderDto.shipToDto.contactId === 0) {
      await this.displayAlert(this.contactRequiredMsg,`contactId-shipTo-textbox`);
      return false;
    } 

    if (!this.orderService.orderDto.sourceCode) {
      await this.displayAlert("A valid Source Code is Required",`source-Code-Select-dropdown`);
      return false;
    }

    if (!this.orderService.orderDto.paymentPlan) {
      await this.displayAlert("Payment Plan is Required",`paymentPlanDDOE`);
      return false;
    }

    if (!this.orderService.orderDto.paymentMethod) {
      await this.displayAlert("Payment Method is Required",`methodDD`);
      return false;
    }

    if (!this.orderService.orderDto.promotionCode) {
      await this.displayAlert("Promo Code is Required",`txtPromoCode`);
      return false;
    }

    if(!await this.validatePONumber()){
      return false;
    }

    const orderDetailPresent = this.orderService.orderDto.orderDetailDtos.find(i => i.productId !== 0 && i.orderDetailStatus != 'V') ? true : false;
    if (!orderDetailPresent) {
      await this.displayAlert("Cart must contain at least one item",`productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}` );
      return false;
    }
    return true;
  }


  populateDefaultValues(): void {
    if (this.orderService.orderDto.shipmentMethod === null || this.orderService.orderDto.shipmentMethod === undefined) {
      this.orderService.orderDto.shipmentMethod = "UPS";
    }

    if (this.orderService.orderDto.paymentTerms === null || this.orderService.orderDto.paymentTerms === undefined) {
      this.orderService.orderDto.paymentTerms = "Net 15 Days";
    }
    
    if (this.orderService.orderDto.glCode === null || this.orderService.orderDto.glCode === undefined) {
      this.orderService.orderDto.glCode = '2DM';
    }
    
    if (this.orderService.orderDto.enteredBy === null || this.orderService.orderDto.enteredBy === undefined) {
      this.orderService.orderDto.enteredByBinding = this._configService.getMyFullName().toUpperCase();
      this.orderService.orderDto.enteredBy = this._configService.getMyMSId().toUpperCase().replace('MS\\', '');
      this.orderService.orderDto.enteredByBindingEmail=this._configService.getUserEmailFromUserLogin(this.orderService.orderDto.enteredBy);
      console.log(this.orderService.orderDto.enteredByBindingEmail+""+this.orderService.orderDto.enteredBy,"this.orderService.orderDto.enteredByBindingEmail");
    }

    if (this.orderService.orderDto.additionalReturnInformation !== null) {
      this.orderService.orderDto.additionalReturnInformation.note.additionalNotes = this.orderService.orderDto.additionalReturnInformation.note.additionalNotes === "" ?
        "sample-text" : this.orderService.orderDto.additionalReturnInformation.note.additionalNotes;
    }
    
    this.orderService.orderDto.orderType = this.orderService.orderDto.orderType === "" ? " " : this.orderService.orderDto.orderType;
    this.orderService.orderDto.orderByDto.contactId = parseInt(this.orderService.orderDto.orderByDto.contactId.toString());
  }

  async createSaveAndCloneRecord(): Promise<void> {
    this.saveAndClone = false;
    this.orderService.orderDto.id = 0;
    this.orderService.orderDto.orderStatus = '';
    this.orderService.orderDto.holdReasonCode = null;
    this.orderService.orderDto.exceptionReasonCode = null;
    this.orderService.orderDto.saveAndClone = true;
    this.orderService.orderDto.dataFileAccessDtos = [];

    if(this.orderService.orderDto.orderType === "E"){
      this.orderService.orderDto.orderType = " ";
    }
    
    const productsWithConfig = this.getAndRemoveConfigItemsInOrder();
    
    this.orderService.orderDto.orderDetailDtos.forEach((element, idx) => {
      element.id = this.orderService.getNextOrderDetailId();
    });

    if (this.orderService.orderDto.promotionCode && this.orderService.orderDto.sourceCode !== this.orderService.orderDto.promotionCode) {
      await this._orderDetailsGridComponent.getOfferInfo();
    }

    if(productsWithConfig.length > 0){
      const items = productsWithConfig.join(',');
      await this.swalAlert.alert(`Item(s) ${items} must be manually added to the cart and configured`);
    }

    if (this.orderService.orderDto.orderDetailDtos.length > 0) {
      this.orderService.orderDto.amaKey = '';
      this.orderService.getKeyCodeFromService();
    }
    this.orderService.onSaveAndClone.next(true);
  }

  getAndRemoveConfigItemsInOrder(){ 
    const productsWithConfigs : string[] = []; 
    for(let i = this.orderService.orderDto.orderDetailDtos.length -1; i >= 0; i--){
      const configSetDtos = this._configService.reference?.productDtos.filter(p => p.productCode.trim() === this.orderService.orderDto.orderDetailDtos[i].productCode.trim() && p.classCode !== 'SH')[0]?.configSetDtos?.length??0;
      if(configSetDtos > 0){
        productsWithConfigs.push(this.orderService.orderDto.orderDetailDtos[i].productCode);
        this.orderService.orderDto.orderDetailDtos.splice(i,1);
      }
      //check if it's a subitem
      else if(this.orderService.orderDto.orderDetailDtos[i].parentOrderDetailProductCode){
        const parentConfigSetLength = this._configService.reference?.productDtos.filter(p => p.productCode.trim() === this.orderService.orderDto.orderDetailDtos[i].parentOrderDetailProductCode?.trim())[0]?.configSetDtos?.length??0;
        if(parentConfigSetLength > 0){
          this.orderService.orderDto.orderDetailDtos.splice(i,1);
        }
      }
    }
    return productsWithConfigs;
  }

  async showPopupForRequestSTCEmailForElectronicEAlertProducts(): Promise<boolean> {
    const leftOrderDetailGrid = "#divLeftOrderDetailsGrid";
    const electronicLineItems: ProductDto[] | undefined = this._configService.reference?.productDtos
      .filter(od => this.orderService.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus === ' ')
        .map(obj => obj.productCode).includes(od.productCode) && (od.isElectronic === true || od.hasElectronicUpdates === true));
    const emailAlertLineItems: ProductDto[] | undefined = this._configService.reference?.productDtos
      .filter(od => this.orderService.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus === ' ')
        .map(obj => obj.productCode).includes(od.productCode) && od.isElectronic === false && od.hasElectronicUpdates === false && od.emailAlert === true);

    if (!this.orderService.orderDto.shipToDto.email) {
      if ((electronicLineItems !== undefined && electronicLineItems.length > 0) && (emailAlertLineItems!.length === 0)) {
        this.electronicProductCodesOnly = electronicLineItems.map(item => item.productCode + ' - ' + item.description)
          .filter((value, index, self) => self.indexOf(value) === index).join(', ');
        this.productType = 'Electronic';
        this.targetEle = leftOrderDetailGrid;
        this.showRequireEmailDialog();
        return true;
      }
      else if ((electronicLineItems!.length === 0) && (emailAlertLineItems !== undefined && emailAlertLineItems.length > 0)) {
        this.eAlertProductCodesOnly = emailAlertLineItems.map(item => item.productCode + ' - ' + item.description)
          .filter((value, index, self) => self.indexOf(value) === index).join(', ');
        this.productType = 'E-Alert';
        this.targetEle = leftOrderDetailGrid;
        this.showRequireEmailDialog();
        return true;
      }
      else if ((electronicLineItems !== undefined && electronicLineItems.length > 0) && (emailAlertLineItems !== undefined && emailAlertLineItems.length > 0)) {
        this.electronicProductCodesOnly = electronicLineItems.map(item => item.productCode + ' - ' + item.description)
          .filter((value, index, self) => self.indexOf(value) === index).join(', ');
        this.eAlertProductCodesOnly = emailAlertLineItems.map(item => item.productCode + ' - ' + item.description)
          .filter((value, index, self) => self.indexOf(value) === index).join(', ');
        this.productType = this.bothElectricAndEAlertTxt;
        this.targetEle = leftOrderDetailGrid;
        this.validateOnNoSaveClicks();

        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  validateOnNoSaveClicks(){
    if (this.noOfSaveClicks > 0) {
      this.blankorInvalidShipToEmailDialog.show = true;
      this.noOfSaveClicks = 0;
    }
    else {
      this.showRequireEmailDialog();
    }
  }

  async showRequireEmailDialog(): Promise<void> {
    const response = await this.requireEmailDialog.openAsync();
    if (response === 'OkWithoutEmail') {
      this.blankShipToEmailDialog.show = true;
    } else if (response === 'OkWithEmail') {
      this.functionTypeToBeCalled({ functionType: this.functionType, forceSaveAction: false });
    } else if (response === 'OkWithoutEmailForElectronic') {
      this.invalidEmailDialogForElectronicProducts.show = true;
    } else if (response === 'Cancel') {
      this.addNewLineItem();
    }
  }

  addNewLineItem(): void {
    if (this.modeService.mode === Mode.Edit && this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0).length === 0) {
      const orderDetail = new OrderDetailDto();
      orderDetail.id = this.orderService.getNextOrderDetailId();
      orderDetail.productCode = '';
      orderDetail.discount = 0;
      orderDetail.unitPrice = 0;
      orderDetail.orderDetailStatus = ' ';
      orderDetail.itemEditable = true;
      orderDetail.requiredDate = new Date();
      orderDetail.enteredDate = new Date();
      orderDetail.productDto = new ProductDto();
      this.orderService.orderDto.orderDetailDtos.push(orderDetail);
      this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
      this.orderService.orderDto.totalQuantity = this.orderService.orderDto.orderDetailDtos.map(o => o.orderQuantity).reduce((prev, next) => prev + next, 0);
    }
  }

  yesButtonClick(): void {
    this.blankShipToEmailDialog.show = false;
    this.functionTypeToBeCalled({ functionType: this.functionType, forceSaveAction: this.productType === this.bothElectricAndEAlertTxt ? false : true });
  }

  async noButtonClick(): Promise<void> {
    this.blankShipToEmailDialog.show = false;
    this.showRequireEmailDialog();
  }

  blankorInvalidShipToEmailDialogOkButtonClick(): void {
    this.blankorInvalidShipToEmailDialog.show = false;
    this.addNewLineItem();
  }
  invalidEmailDialogOk(): void {
    this.invalidEmailDialogForElectronicProducts.show = false;   
    this.addNewLineItem();
  }

  accountContactChangeBR(): boolean {
    const systemAdminText = "System Administrator";
    const orderMgmtText = "Order Management";
    console.log('this.userRole' + this.userRole);
    console.log('this.orderService.orderDto.shipToDto.statusCode' + this.orderService.orderDto.shipToDto.statusCode);
    console.log('this.orderService.orderDto.orderByDto.statusCode' + this.orderService.orderDto.orderByDto.statusCode);
    console.log('this.orderService.orderDto.billToDto.statusCode' + this.orderService.orderDto.billToDto.statusCode);
    if (((this.userRole === systemAdminText || this.userRole === orderMgmtText)
      && (this.orderService.orderDto.orderByDto.statusCode === 'DEL' || this.orderService.orderDto.orderByDto.statusCode === 'MGD'))
      || ((this.userRole !== systemAdminText && this.userRole !== orderMgmtText)
      && (this.orderService.orderDto.orderByDto.statusCode === 'BP' || this.orderService.orderDto.orderByDto.statusCode === 'BAD' || this.orderService.orderDto.orderByDto.statusCode === 'DEL' || this.orderService.orderDto.orderByDto.statusCode === 'MGD'))) {
      this.orderService.sourceType = SourceType.OrderByContactChange;
      this.contactDialogModal.openAsync();
      return false;
    }
    else if (((this.userRole === systemAdminText || this.userRole === orderMgmtText)
      && (this.orderService.orderDto.shipToDto.statusCode === 'DEL' || this.orderService.orderDto.shipToDto.statusCode === 'MGD'))
      || ((this.userRole !== systemAdminText && this.userRole !== orderMgmtText)
        && (this.orderService.orderDto.shipToDto.statusCode === 'BP' || this.orderService.orderDto.shipToDto.statusCode === 'BAD' || this.orderService.orderDto.shipToDto.statusCode === 'DEL' || this.orderService.orderDto.shipToDto.statusCode === 'MGD'))) {
      this.orderService.sourceType = SourceType.ShipToContactChange;

      this.contactDialogModal.openAsync();
      return false;
    }
    else if (this.isAccountChange(systemAdminText,orderMgmtText)) {
      this.orderService.sourceType = SourceType.OrderByAccountChange;
      this.contactDialogModal.openAsync();
      return false;
    }
    else {
      return true;
    }
  }

  isAccountChange(systemAdminText:string,orderMgmtText:string){
    return (((this.userRole === systemAdminText || this.userRole === orderMgmtText)
    && (this.orderService.orderDto.billToDto.statusCode === 'DEL' || this.orderService.orderDto.billToDto.statusCode === 'MGD'))
    || ((this.userRole !== systemAdminText && this.userRole !== orderMgmtText)
      && (this.orderService.orderDto.billToDto.statusCode === 'BP' || this.orderService.orderDto.billToDto.statusCode === 'BAD' || this.orderService.orderDto.billToDto.statusCode === 'DEL' || this.orderService.orderDto.billToDto.statusCode === 'MGD')));
  }

  async functionTypeToBeCalled(event: any): Promise<void> {
    this.forceSaveAction = event.forceSaveAction;

    switch (event.functionType) {
      case 'Save':
        if (this.productType === this.bothElectricAndEAlertTxt) {
          this.noOfSaveClicks++;
        }

        if(!await this.saveBtnClick()){
          return;
        }
        break;
      case 'Save&Clone':
        if (this.productType === this.bothElectricAndEAlertTxt) {
          this.noOfSaveClicks++;
        }

        this.saveCloneBtnClick();
        break;
      case 'MultiYear':
        if (this.productType === this.bothElectricAndEAlertTxt) {
          this.noOfSaveClicks++;
        }

        this.multiYearBtnClick();
        break;
    }
  }

  cfdBtnClick(): void {
    this._router.navigate(['/quote-cfd-form']);
  }

  ngOnDestroy(): void {
    this.callEditSubscription?.unsubscribe();
    this.onTaxErrorSubscription?.unsubscribe();
  }
  async validationForSaveAsDraft()
  {
    if (this.orderService.orderDto.billToDto.id === 0) {
      await this.swalAlert.alert("Account Id is required");
      setTimeout(() => {
        if (document.getElementById(`accountId`)) {
          document.getElementById(`accountId`)?.focus();
        }
      });
      return false;
    }

    if (this.orderService.orderDto.orderByDto.contactId === 0) {
      await this.swalAlert.alert(this.contactRequiredMsg);
      this.setFocusToContactId();
      return false;
    }

    if (this.orderService.orderDto.shipToDto.contactId === 0) {
      await this.swalAlert.alert(this.contactRequiredMsg);
      setTimeout(() => {
        if (document.getElementById(`contactId-shipTo-textbox`)) {
          document.getElementById(`contactId-shipTo-textbox`)?.focus();
        }
      });
      return false;
    }

    if (!this.orderService.orderDto.sourceCode) {
      await this.swalAlert.alert("A valid Source Code is Required");
      setTimeout(() => {
        if (document.getElementById(`sourceCodeOE`)) {
          const htmlUL = document.getElementById(`sourceCodeOE`) as HTMLElement;
          const child = htmlUL.childNodes[0] as HTMLElement;
          child.focus();
        }
      });
      return false;
    }

    if (!this.orderService.orderDto.promotionCode) {
      await this.swalAlert.alert("A valid Promo Code is Required");
      this.setFocusToPromoCode();
      return false;
    }

    return true;
  }

  setFocusToPromoCode(){
    setTimeout(() => {
      if (document.getElementById(`txtPromoCode`)) {
        document.getElementById(`txtPromoCode`)?.focus();
      }
    });
  }

  setFocusToContactId(){
    setTimeout(() => {
      if (document.getElementById(`contactId`)) {
        document.getElementById(`contactId`)?.focus();
      }
    });
  }

  async creditCardExpireDateValidation(): Promise<boolean> {
    if (this.orderService.orderDto.paymentTerms !== 'Credit Card') {
      return true;
    }
    if (this.orderService.orderDto.ccExpirationDate) {
      if(!await this.checkCreditCardExpirationDate(this.orderService.orderDto.ccExpirationDate)){
        return false;
      }
    }
    else {
      if(this.orderService.orderDto.ccToken && this.orderService.orderDto.ccToken.length === 6){
        this.orderService.orderDto.ccExpirationDate = "0199";
        this.orderService.orderDto.ccLastFour = "0000";
      }else{
        await this.swalAlert.alert('Enter credit card expiration date');
        return false;
      }       
    }

    return true;
  }

  removeEmptyRowFromCart(): void{
    if (this.orderService.orderDto.orderDetailDtos.find(i => i.productId === 0)) {
      const orderDetailDto = this.orderService.orderDto.orderDetailDtos.filter(i => i.productId === 0)[0];
      const index = this.orderService.orderDto.orderDetailDtos.indexOf(orderDetailDto);
      this.orderService.orderDto.orderDetailDtos.splice(index, 1);
    }
  }

  async orderTypeChangedFromQuoteToOrderValidations(): Promise<boolean> {
    if (this.orderService.orderTypeChangedFromQuoteToOrder && this.orderService.orderDto.id > 0) {
      this.orderService.orderDto.orderType = ' ';
      this.orderService.orderDto.orderDate = new Date();
      this.orderService.orderDto.enteredBy = this._configService.getMyMSId().toUpperCase().replace('MS\\', '');

      Object.keys(this.orderService.orderDto.orderDetailDtos).forEach(o => {
        this.orderService.orderDto.orderDetailDtos[Number(o)].enteredDate = new Date();
      });
    }
    return true;
  }
  async multiYearSaveValidation(){
    if ((this.orderService.orderDto.orderStatus === 'D' || this.orderService.orderDto.orderStatus === 'R' || this.orderService.isOrderInRejectedStatus)  && this.orderService.orderDto.multiYearDtos?.length > 0){
      if(this.orderService.orderDto.multiYearDtos[this.orderService.orderDto.multiYearDtos.length - 1].yearOfOrder !== this.orderService.orderDto.multiYearCurrentYear){
        const lastYearOrderStatus = this.orderService.orderDto.multiYearDtos[this.orderService.orderDto.multiYearDtos.length - 1].orderStatus;
        if (lastYearOrderStatus === 'D' || lastYearOrderStatus === 'R') {
          await this.swalAlert.alert('You must be on the last year of a multi-year deal to save as an order.');
          return false;
        }
      } else if(this.orderService.orderDto.multiYearDtos.filter(x => x.orderId !== this.orderService.orderDto.id && x.orderStatus === 'R').length > 0){ 
          await this.swalAlert.alert('Please edit and Save as Draft the previous year orders in this multi-year deal before you Save the final order.');
          return false;        
      }               
    }
    return true;
  }
  async validationsBeforeSave() {
    const isOrderTypeChangedFromQuoteToOrder = await this.orderTypeChangedFromQuoteToOrderValidations();

    if (!isOrderTypeChangedFromQuoteToOrder) {
      return false;
    }

    if (!await this.validationForConfigSetItems()) {
      return false;
    }

    if (!await this.validateDuplicateRenewals()) {
      return false;
    }

    if (!await this.creditCardExpireDateValidation()) {
      return false;
    }

    if (!await this.orderService.paymentPlanBR()) {
      return false;
    }

    const showOrderLevelOffersPopupResult = await this.showOrderLevelOffersPopup();

    if (!showOrderLevelOffersPopupResult) {
      return false;
    }

    if (!await this.validationsForRequiredFields()) {
      return false;
    }

    if (!this.accountContactChangeBR()) {
      return false;
    }
    return true;
  }
  async openOrderLevelOffersOthersDialog(): Promise<boolean> {
    if (this.orderService.orderDto.orderOfferDto?.tiers) {
      this.orderService.orderDto.minimumPromotionQuantity = this.getMinimumFromValueForTier(this.orderService.orderDto.orderOfferDto.tiers);
    }
    if (this.orderService.orderDto.hasUserSeenPromoForm) {
      return true;
    }
    const result = await this.orderLevelOffersOthersDialog.openAsync(); 
    if (result === 'Yes') {
      return true;
    }
    return false;
  }
  async openOrderLevelTierOfferDialog(): Promise<boolean>{
    if (this.orderService.orderDto.orderOfferDto?.tiers) {
      this.orderService.orderDto.minimumPromotionPrice = this.getMinimumFromValueForTier(this.orderService.orderDto.orderOfferDto.tiers);
    }
    if (this.orderService.orderDto.hasUserSeenPromoForm) {
      return true;
    }
    const result = await this.orderLevelTierOfferDialog.openAsync();
    if (result === 'Yes') {
      return true;  
    }
    return false;  
  }
  async displayAlert(message: string, ele: string) {
    await this.swalAlert.alert(message);
    setTimeout(() => {
      if (document.getElementById(ele)) {
        document.getElementById(ele)?.focus();
      }
    });
  }
  async validatePONumber(): Promise<boolean> {
    if (this.orderService.orderDto.orderType !== 'Q' && this.orderService.orderDto.billToDto.isPORequired && !this.orderService.orderDto.poNumber &&
      (this.orderService.orderDto.paymentMethod === 'N' || this.orderService.orderDto.paymentMethod === 'S' || this.orderService.orderDto.paymentMethod === 'I')) {
      if (!this._authorizationService.hasResource("OrderEntryByPassPORequiredAlert")) {
        await this.displayAlert("PO number is Required", `poNumberOE`);
        return false;
      }
    }
    return true;
  }
  async checkCreditCardExpirationDate(ccExpirationDate: string): Promise<boolean> {
    let maxExpectedShipDate: Date;
    let minExpectedShipDate: Date;
    if (this.orderService.orderDto.orderDetailDtos.some(o => (o.productDto.availableDate !== null || o.requiredDate) && o.orderDetailStatus !== 'V')) {
      maxExpectedShipDate = this.setMaxExpectedDateWhenNoAvailableDate();
      minExpectedShipDate = this.setMaxExpectedDateWhenNoAvailableDateAndShiipingItems();
      if (!await this.checkMaxRequiredDate(maxExpectedShipDate, minExpectedShipDate, ccExpirationDate)) {
        return false;
      }
    }
    return true;
  }

  setMaxExpectedDateWhenNoAvailableDate(): Date {
    if (this.orderService.orderDto.orderDetailDtos.some(a => a.productDto.availableDate !== null)) {
      return new Date(this.orderService.orderDto.orderDetailDtos
        .filter(o => o.productCode !== '' && o.orderDetailStatus !== 'V' && o.classCode !== 'SH')
        .reduce((a, b) => a.productDto.availableDate! > b.productDto.availableDate! ? a : b).productDto.availableDate!);
    }
    else {
      return new Date();
    }
  }
  setMaxExpectedDateWhenNoAvailableDateAndShiipingItems(): Date {
    if (this.orderService.orderDto.orderDetailDtos.some(a => a.productDto.availableDate !== null && a.classCode !== 'SH')) {
      return new Date(this.orderService.orderDto.orderDetailDtos
        .filter(o => o.productCode !== '' && o.orderDetailStatus !== 'V' && o.classCode !== 'SH')
        .reduce((a, b) => a.productDto.availableDate! < b.productDto.availableDate! ? a : b).productDto.availableDate!);
    }
    else {
      return new Date();
    }
  }
  async checkMaxRequiredDate(maxExpectedShipDate: Date, minExpectedShipDate: Date, ccExpirationDate: string): Promise<boolean> {
    const maxRequiredDate = new Date(this.orderService.orderDto.orderDetailDtos
      .filter(o => o.productCode !== '' && o.orderDetailStatus !== 'V')
      .reduce((a, b) => a.requiredDate > b.requiredDate ? a : b).requiredDate);
    const maxDate = maxExpectedShipDate > maxRequiredDate ? maxExpectedShipDate : maxRequiredDate;
    const year = (maxDate.getFullYear() / 100 | 0) + ccExpirationDate.substring(2, 4);
    const month = ccExpirationDate.substring(0, 2);

    if (new Date(Number(year), Number(month), 1) < maxDate && new Date(Number(year), Number(month), 1) < minExpectedShipDate) {
      await this.swalAlert.alert('This credit card will expire before the anticipated shipment date of all items on this order. ' +
        'Ask the customer for a credit card that will expire after the maximum anticipated shipment date or change the payment method to an Invoice.');
      return false;
    }
    return true;
  }
}
