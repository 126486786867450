import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Mode } from '../../../enums/mode.enum';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { ProductDto } from '../../../dtos/product-dto.model';
import { AuthorizationService } from '../../../services/authorization.service';
import { ConfigService } from '../../../services/config.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';
import { SubscriptionPriceCalculationComponent } from '../subscription-price-calculation/subscription-price-calculation.component';
import { SwalAlert } from '../../../helpers/alert';
import { AccountPricingDto } from '../../../dtos/account-pricing-dto.model';

@Component({
  selector: 'app-order-grid',
  templateUrl: './order-grid.component.html',
  styleUrls: ['./order-grid.component.css']
})
  
export class OrderGridComponent implements OnInit {
  private readonly _configService: ConfigService;
  private readonly _authorizationService: AuthorizationService;
  private readonly _pricingService: PricingService;
  private readonly _elementRef: ElementRef;
  @ViewChild("subPriceCalculation") subsPriceCalucation!: SubscriptionPriceCalculationComponent;
  @ViewChild("orderQuantity") orderQuantity!: ElementRef<HTMLInputElement>;
  @ViewChild("orderNoteOkBtn") orderNoteOkBtn!: ElementRef<HTMLInputElement>;
  @ViewChild("dupWebAppOkBtn") dupWebAppOkBtn!: ElementRef<HTMLInputElement>;
  @ViewChild("dupItemOkBtn") dupItemOkBtn!: ElementRef<HTMLInputElement>;
  @ViewChild("quantityCheckOkBtn") quantityCheckOkBtn!: ElementRef<HTMLInputElement>;
  @Output() addedShippingItem = new EventEmitter();
  @Output() adjustedLineItem = new EventEmitter<OrderDetailDto>();
  @Output() changePayMeth = new EventEmitter<any>();
  orderDetailDtoBackupForSub: OrderDetailDto = new OrderDetailDto();
  myNotificationWarningContent = "This item is inactive or does not exist. Please enter a new item #";
  dupNotificationForWebApps = "You can only add ONE line item for the same Web Fulfilled product per order";
  addOnWarningContent = "Add-ons cannot be added as an item #";
  addPermissionContent = "You do not have permission to add this item. Please contact Order Management";
  visibleTextValue = this.modeService.mode === Mode.Wait ? "" : "Saving Data";
  showItemSearchDialog = { show: false };
  columns = [
    { title: '', start: 0, width: 40, frozen: true },
    { title: '', start: 0, width: 40, frozen: true },
    { title: 'Item', start: 80, width: 140, frozen: true },
    { title: 'Description', start: 220, width: 350, frozen: true },
    { title: 'Order Qty', start: 570, width: 100, frozen: true },
    { title: 'List Price', start: 670, width: 100, frozen: true },
    { title: 'Discount', frozen: false },
    { title: 'Unit Price', frozen: false },
    { title: 'Ext Price', frozen: false },
    { title: 'Req Date', frozen: false },
    { title: 'Status', frozen: false },
    { title: 'Ship Qty', frozen: false },
    { title: 'Ship Date', frozen: false },
    { title: 'Term', frozen: false },
    { title: 'PP Date', frozen: false },
    { title: 'Date Voided/Edited', frozen: false },
    { title: 'Void Qty', frozen: false },
    { title: 'Void Reason', frozen: false },
    { title: 'P Sales Id', frozen: false },
    { title: 'S Sales Id', frozen: false },
    { title: 'PP Eligible', frozen: false },
    { title: 'Tax Amount', frozen: false },
  ];

  Mode = Mode;
  selectedOrderDetail: OrderDetailDto = new OrderDetailDto();
  selectedOrderDetailDto: any;
  validationPassed = true;
  isFromItemSearch=false;
  currentOrderDetailId: any;
  setFocusToQuantityAfterPopup = false;
  previousOrderQuantity = 0;
  havePermission = false;
  isAddOn = false;
  maxDiscount: number = 0.0;
  selectedType: string = '';
  shippingProductCodes: string = '';
  configIsReadySubscription: Subscription | undefined;
  quantityCheckModal = { show: false }
  voidItemDialog = { show: false }
  itemComment = { show: false, text: '' }
  dupWebApps = { show: false }
  dupProduct = { show: false }
  showNoItemExists = { show: false }
  voidDepositDialogModal = { show: false };
  oldUnitPrice = 0.00;
  oldOrderQuantity = 1;
  internationalAdressMessage='Shipping Address entered requires Ship Via to be UPS International';
  swalAlert = new SwalAlert();

  constructor(
    public orderService: OrderService,
    public modeService: ModeService,
    configService: ConfigService,
    authorizationService: AuthorizationService,
    pricingService: PricingService,
    elementRef: ElementRef
  ) {
    this._configService = configService;
    this._authorizationService = authorizationService;
    this._pricingService = pricingService;
    this._elementRef = elementRef;
   }

  ngOnInit(): void {
    this.configIsReadySubscription = this._configService.configIsReady.subscribe(() => {
      this.shippingProductCodes = Array.prototype.map.call(this._configService.reference?.productDtos.filter(p => p.classCode === "SH" && p.productCode !== "0052"),
        function (item) {
          return item.productCode;
        }).join(",");
   });
   this.isFromItemSearch=false;
  }

  async onDeleteClick(record: any) {
    const productDto = this._configService.reference?.productDtos?.filter(p => p.id === record.productId)[0];

    if ((this._authorizationService.hasResource('TucsonAllowPrintProducts') && !this._authorizationService.hasResource('TucsonAllowElectronicProducts'))) {
      if (productDto?.salesOrganization === 'Optum') {
        await this.swalAlert.alert(`You are not authorized to delete the item ${record.productCode}.`);
        return;
      }
    }
    else if (!this._authorizationService.hasResource('TucsonAllowPrintProducts') && this._authorizationService.hasResource('TucsonAllowElectronicProducts')) {
      if (productDto?.salesOrganization === 'Shutterfly') {
        await this.swalAlert.alert(`You are not authorized to delete the item ${record.productCode}.`);
        return;
      }
    }

    this.visibleTextValue = "Deleting a Line Item";
    this.selectedOrderDetail = record;
    this.voidDepositDialogModal.show = this.selectedOrderDetail.productCode==='0010' && this.selectedOrderDetail.orderDetailStatus===' ';
    if(this.voidDepositDialogModal.show)
    {
      return;
    }
    this.voidItemDialog.show = true;
    const productItem = this._configService.reference?.productDtos.filter(p => p.productCode === this.selectedOrderDetail.productCode)[0];
    if ((this.selectedOrderDetail.productCode.toUpperCase().includes('PF') ||
      this.selectedOrderDetail.productCode.toUpperCase().includes('PRO')) &&
      productItem?.requiresAmaKey === true)
    {
      this._configService.updateKeyCodeTakenDate(this._configService.keyCodeDto.id,this._configService.keyCodeDto.keyCode).subscribe(response => {
        this._configService.keyCodeDto.keyCode='';
        this._configService.keyCodeDto.id=0;
      });
    }
  }

  closeVoidDepositDialog(): void{

    if(this.voidDepositDialogModal.show===true){
      this.voidDepositDialogModal.show = false;
      this.voidItemDialog.show = true;
    }
  }

  add() {
    const orderDetail = new OrderDetailDto();
    orderDetail.orderDetailStatus = ' ';
    orderDetail.itemEditable = true;
    orderDetail.requiredDate = new Date(new Date().toDateString());
    orderDetail.enteredDate = new Date();
    orderDetail.id = this.orderService.getNextOrderDetailId();

    this.orderService.orderDto.orderDetailDtos.unshift(orderDetail);
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
    if(this.orderService.orderDtoBackup.orderStatus!=='V' && this.orderService.orderDto.orderStatus === 'V'){
      this.orderService.orderDto.orderStatus = ' ';
      this.orderService.orderDto.voidReasonCode = null;
    }
    setTimeout(() => {
      if (this._elementRef.nativeElement.querySelector(`input[name=productCode]`)) {
        this._elementRef.nativeElement.querySelector(`input[name=productCode]`).focus();
      }
    });

  }

  addCheckOrCashRow() {
    const orderDetail = this.orderService.createNewOrderDetail(this.orderService.orderDto, '0010');
    orderDetail.orderDetailStatus = ' ';
    orderDetail.orderQuantity = 1;
    orderDetail.openQuantity = 1;
    orderDetail.itemEditable = true;
    this.orderService.orderDto.orderDetailDtos.unshift(orderDetail);
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];

    setTimeout(() => {
      if (this._elementRef.nativeElement.querySelector(`input[name=productCode]`)) {
        this._elementRef.nativeElement.querySelector(`input[name=productCode]`).focus();
      }
    });

    this.orderService.clickConfiguration(orderDetail);
    this.adjustedLineItem.emit();
    this.setButtonFocus();
  }

  recordPreviousQuantity(orderDetailDto: OrderDetailDto): void {
    // Record the curent record, used by other events.
    this.orderDetailDtoBackupForSub = orderDetailDto;

    this.selectedOrderDetail = orderDetailDto;
    // Record the previous quantity in case we have to roll it back.
    this.previousOrderQuantity = orderDetailDto.orderQuantity;

    if (orderDetailDto.orderDetailStatus === 'C') {
      orderDetailDto.orderQuantity = 0;
      setTimeout(() => {
        const inputElement = document.getElementById(`orderQuantity-${this.selectedOrderDetail.id}`) as HTMLInputElement;
        if (inputElement) {
          inputElement.setSelectionRange(0, inputElement.value.length);
        }
      });
    }
  }

  openItemSearchDialog() {
    this.showItemSearchDialog.show = true;
    this.isFromItemSearch = true;
  }

  async addProduct(event: any): Promise<void> {
    console.log("Order grid componnet");
    this.modeService.mode = Mode.Edit;
    const newOrderDetail = this.orderService.createNewOrderDetail(this.orderService.orderDto, event);
          newOrderDetail.orderQuantity = 1;
          newOrderDetail.openQuantity = 1;
          newOrderDetail.orderDetailStatus = ' ';
          newOrderDetail.itemEditable = false;
          this.orderService.orderDto.orderDetailDtos.unshift(newOrderDetail);
    const orderDetail=this.orderService.orderDto.orderDetailDtos[0];
    this.isFromItemSearch=true;

   await this.onChangeProductCode(null,orderDetail.productCode,orderDetail);
  }

  async onChangeProductCode(e:any,productCode: string, orderDetail: OrderDetailDto): Promise<void> {
    if(!productCode)
    {
      productCode=e.srcElement.value;
    }
    this.validationPassed = true;
    this.currentOrderDetailId = orderDetail.id;
    orderDetail.productCode = orderDetail.productCode.toUpperCase().trim();
    const showSalesOrderNote = this._configService.reference?.productDtos.filter(p => p.productCode === productCode.toUpperCase());
   await this.checkfunctions(showSalesOrderNote,orderDetail,productCode);
    const product = this._configService.reference?.productDtos.filter(p => p.productCode === productCode.toUpperCase() && p.statusCode !== 'I')[0];
    const addOn = this._configService.reference?.productDtos.filter(p => p.productCode === productCode.toUpperCase() && p.inventoryType !== 'V')[0];
    
    if(!this.checkIfItemExist(productCode)){
      return;
    }
    if(!await this.checkIfCouponAllowed(product)){
      return;
    }

    if (product?.requiresAmaKey === true && !this._authorizationService.hasResource('OrderInquiryAdditionalActionsAMAKeyButton')) {
      this.showNoItemExists.show = true;
      this.havePermission = true;
      orderDetail.productCode = '';
      return;
    }
    await this.checkproductCode(product,orderDetail,showSalesOrderNote,productCode,addOn);
    const allowedShipToCountries = ['US', 'PR', 'VI', 'GU', 'MP', 'AS'];
    const allowedIndiaAndPhilipines = ['IN', 'PH'];
    if (orderDetail.productDto.salesOrganization === 'Shutterfly'  && !allowedShipToCountries.includes(this.orderService.orderDto.shipToDto.country)) {
      if (this._authorizationService.hasResource('AllowShiptoToForeignCountries')) {
        if (!allowedIndiaAndPhilipines.includes(this.orderService.orderDto.shipToDto.country)) {
          this.swalAlert.alert('Shipments to foreign countries of print product is no longer allowed');
          this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
          return;
        }
      } else {
        this.swalAlert.alert('Shipments to foreign countries of print product is no longer allowed');
        this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
        return;
      }
    }
  }
  async checkproductCode(product: ProductDto | undefined, orderDetail: OrderDetailDto, showSalesOrderNote: ProductDto[] | undefined, productCode: string, addOn: ProductDto | undefined) {
    if (!product) {
      
      this.showNoItemExists.show = true;
      this.havePermission = false;
      this.isAddOn = false;
      this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
      await this.swalAlert.alert(`This item ${orderDetail.productCode} is invalid or does not exist. Please enter a new item #`);
      return;
    }
    await this.checkIfProductCart(product, orderDetail,showSalesOrderNote, productCode,addOn);
    await this.MaxQuality(orderDetail,productCode,product);
    await this.updateOrderDetail(orderDetail,productCode,product);
  }
  async checkIfProductCart(product: ProductDto, orderDetail: OrderDetailDto, showSalesOrderNote: ProductDto[] | undefined, productCode: string, addOn: ProductDto | undefined) {
    if(!await this.checkIfProductAllowedInCart(product, orderDetail)){
      return;
    }

    if(showSalesOrderNote){
      this.displaySalesOrderNote(showSalesOrderNote, productCode);
    }

    if (orderDetail.productCode !== '0010') {
      if (!(await this.inventoryCheckBR(orderDetail, product))) {
        this.validationPassed = false;
        orderDetail.productCode = '';
        return;
      }
    }
    if(!this.checkIfAddOn(addOn,orderDetail)){
      return;
    }
  }
  
  async checkfunctions(showSalesOrderNote:ProductDto[]| undefined,orderDetail:OrderDetailDto,productCode:string) {
    if(!await this.checkIfValidItem(showSalesOrderNote, orderDetail)){
      return;
    }

    if (productCode !== '0010' && localStorage.getItem('rolename') === 'Custom Read Only') {
      this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
      return;
    }

    if(!await this.checkIfDespositExists(orderDetail)){
      return;
    }
  }
  async MaxQuality(orderDetail: OrderDetailDto, productCode: string, product: ProductDto) {
    orderDetail.productDto.configSetDtos=product?.configSetDtos;
    orderDetail.isMaxQuantityOne = product.hasMaxQuantityOne;
    // Launch the configuration only if hasMaxQuantityOne
    if (product.hasMaxQuantityOne) {
      await this.hasMaxQuantityOneBR(orderDetail);
    }

    this.maxDiscount = product.maxDiscountPercent;

    if (orderDetail.productDescription !== '') {
      return;
    }

    if (!this._configService.reference) {
      return;
    }

    if (productCode.trim() === '') {
      return;
    }
  }
  async updateOrderDetail(orderDetail:OrderDetailDto,productCode:string,product:ProductDto) {
    await this.checkCashReceiptBr(orderDetail);
    orderDetail.productDescription = product.description;
    orderDetail.productId = product.id;
    orderDetail.openQuantity = 1;
    orderDetail.discount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, product.id);
    orderDetail.listPrice = product.listPrice;
    orderDetail.requiredDate = new Date(new Date().toDateString());
    orderDetail.orderDetailStatus = ' ';
    orderDetail.shippedQuantity = 0;
    orderDetail.voidedQuantity = 0;
    orderDetail.accountOwnerCode = this.orderService.orderDto.billToDto.accountOwnerCode;
    orderDetail.salespersonCode = '000';
    orderDetail.classCode = product.classCode;
    orderDetail.glCode = product.glCode;
    orderDetail.isPhysical = product.isPhysical;
    orderDetail.isElectronic = product.isElectronic;
    orderDetail.isWebFulfilled = product.isWebFulfilled;
    orderDetail.productDto = product;
    this.checkIfCustomPricingApplicable(orderDetail);
    this._pricingService.calculateUnitPrice(orderDetail);
    orderDetail.orderQuantity = orderDetail.openQuantity + orderDetail.shippedQuantity;
    this._pricingService.calculateExtendedPrice(orderDetail);
    orderDetail.itemEditable = false;
    this.orderService.updateAmaKeyButtonMode();
    if (productCode.toUpperCase() === '0010') {
      this.changePayMeth.emit();
    }
    if (orderDetail.productDto.classCode === 'SH') {
      this.addedShippingItem.emit();
    }
    else {
      this.adjustedLineItem.emit(orderDetail);
    }
    await this.checkInternationAddress(orderDetail);

    const productCodeKeyCode = this._configService.reference?.productDtos.filter(p => p.productCode === productCode.toUpperCase())[0];
    if (productCodeKeyCode != null) {
      this.orderService.getKeyCodeFromService();
    }
    this.orderService.recalculateFields(this.orderService.orderDto);
  }

  setButtonFocus(): void {
    setTimeout(() => {
      if(this.dupProduct.show){
        this.dupItemOkBtn.nativeElement.focus();
      }       
    }, 0);
    setTimeout(() => {
      if (this.itemComment.show){
        this.orderNoteOkBtn.nativeElement.focus();
      }       
    }, 0);
  }
    

  checkIfCustomPricingApplicable(orderDetail: OrderDetailDto) {
    if (orderDetail.productDto.allowCustomPrice || orderDetail.productDto.allowCustomStandard) {
      orderDetail.listPrice = 0;
      orderDetail.discount = 0;
    }
  }

  stopDup() {
    if (this.orderService.orderDto.orderDetailDtos.filter(i => i.id === this.currentOrderDetailId).length > 0) {
      this.orderService.orderDto.orderDetailDtos.filter(i => i.id === this.currentOrderDetailId)[0].productCode = "";
      const orderDetail = this.orderService.orderDto.orderDetailDtos.filter(i => i.id === this.currentOrderDetailId)[0];
      orderDetail.productDescription = "";
      orderDetail.openQuantity = 0;
      orderDetail.discount = 0;
      orderDetail.listPrice = 0;
      orderDetail.requiredDate = new Date(new Date().toDateString())
      orderDetail.orderDetailStatus = ' ';
      orderDetail.shippedQuantity = 0;
      orderDetail.voidedQuantity = 0;
      orderDetail.accountOwnerCode = "";
      orderDetail.salespersonCode = '000';
      orderDetail.classCode = "";
      orderDetail.glCode = "";
      orderDetail.orderQuantity = 0;
      orderDetail.itemEditable = true;
      orderDetail.unitPrice = 0;
      orderDetail.extendedPrice = 0;

      this.orderService.orderDto.orderDetailDtos.splice(this.orderService.orderDto.orderDetailDtos.findIndex(i => i.id === this.currentOrderDetailId), 1);
      this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos]
      this.orderService.recalculateFields(this.orderService.orderDto);
      this.dupProduct.show = false;
      this.showItemSearchDialog.show = this.isFromItemSearch;
      return;
    } else {
      this.dupProduct.show = false;
    }
    this.showItemSearchDialog.show = this.isFromItemSearch;
    return;
  }

  async checkIfValidItem(showSalesOrderNote: ProductDto[]| undefined, orderDetail: OrderDetailDto): Promise<boolean>{
    if (showSalesOrderNote && !showSalesOrderNote.length) {
      this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
      await this.swalAlert.alert(`This item ${orderDetail.productCode} is invalid or does not exist. Please enter a new item #`);
      return false;
    }
    return true;
  }
  checkIfAddOn(addOn: ProductDto | undefined, orderDetail: OrderDetailDto): boolean{
    if (!addOn) {
      this.showNoItemExists.show = true;
      this.isAddOn = true;
      this.havePermission = false;
      orderDetail.productCode = '';
      return false;
    }
    return true;
  }
  async checkIfDespositExists(orderDetail: OrderDetailDto) : Promise<boolean>{
    if (this.orderService.orderDto.orderDetailDtos.some(item => item.productCode === '0010' &&
      item.orderDetailStatus === ' ' && item.id > 0) && orderDetail.productCode === '0010') {
      this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
      await this.swalAlert.alert('A deposit has already been entered on the order.  Use the config set to add or adjust deposits.');
      return false;
    }
    return true;
  }
  async checkCashReceiptBr(orderDetail: OrderDetailDto){
    const cashReceiptBR = this.orderService.cashReceiptBR(orderDetail.productCode, this.orderService.orderDto.paymentMethod, this.orderService.orderDto.paymentPlan);

    if (cashReceiptBR === true) {
      await this.swalAlert.alert('Cash Receipts are not allowed on a payment plan');
    }
  }
  displaySalesOrderNote(showSalesOrderNote: ProductDto[], productCode: string) {
    if (showSalesOrderNote.length > 0 && showSalesOrderNote[0].showSalesOrderNote) {
      const salesOrderNote = this._configService.reference?.productDtos.filter(p => p.productCode === productCode.toUpperCase())[0].salesOrderNote;

      if (salesOrderNote != null && salesOrderNote.length > 0) {
        this.itemComment.show = true;
        this.itemComment.text = salesOrderNote;

        setTimeout(() => {
          this.orderNoteOkBtn.nativeElement.focus();
        }, 0);

        this.setFocusToQuantityAfterPopup = true;
      }
    }
  }
  async checkIfCouponAllowed(product: ProductDto | undefined): Promise<boolean> {
    if (product?.classCode === 'XS' && this.orderService.orderDto.paymentPlan !== 'OneTime') {
      await this.swalAlert.alert("Coupons are not allowed on an order that is setup with a Payment Plan. The coupon item has been removed from the order.");
      const index = this.orderService.orderDto.orderDetailDtos.findIndex(x => x.productCode === product.productCode);
      this.orderService.orderDto.orderDetailDtos.splice(index, 1);
      this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos]
      this.orderService.recalculateFields(this.orderService.orderDto);
      return false;
    }
    return true;
  }
  checkIfItemExist(productCode: string): boolean{
    const isShipping = this._configService.reference?.productDtos.some(p => p.productCode === productCode.toUpperCase() && p.classCode === 'SH');
    const doesWebAppAlreadyExist = this.orderService.orderDto.orderDetailDtos
      .filter(o =>
        o.orderDetailStatus !== 'V'
        && o.productDto.inventoryType === 'S'
        && o.productCode.toUpperCase() === productCode.toUpperCase()).length > 0;

    if (doesWebAppAlreadyExist) {
      this.dupWebApps.show = true;
      setTimeout(() => {
        this.dupWebAppOkBtn.nativeElement.focus();
      }, 0);
      return false;
    }

    const isExists = (this.orderService.orderDto.orderDetailDtos.filter(o => o.productCode.toUpperCase() === productCode.toUpperCase() && o.orderDetailStatus !== 'V').length > 1);
    if (isExists && !isShipping) {
      this.dupProduct.show = true;
      setTimeout(() => {
        this.dupItemOkBtn.nativeElement.focus();
      }, 0);
    }
    return true;
  }
  async checkIfProductAllowedInCart(product: ProductDto, orderDetail: OrderDetailDto): Promise<boolean> {
    if ((this._authorizationService.hasResource('TucsonAllowPrintProducts') && !this._authorizationService.hasResource('TucsonAllowElectronicProducts'))) {
      if (product.salesOrganization === 'Optum') {
        this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
        await this.swalAlert.alert(`You are not authorized to add item ${orderDetail.productCode} to the cart.`);
        return false;
      }
    }
    else if (!this._authorizationService.hasResource('TucsonAllowPrintProducts') && this._authorizationService.hasResource('TucsonAllowElectronicProducts')) {
      if (product.salesOrganization === 'Shutterfly') {
        this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
        await this.swalAlert.alert(`You are not authorized to add item ${orderDetail.productCode} to the cart.`);
        return false;
      }
    }
    switch (this.orderService.orderDto.orderDetailDtos[1].productDto.salesOrganization) {
      case 'Optum':
        this.orderService.orderDto.salesOrganization = 'Optum';

        if (product.salesOrganization === 'Shutterfly') {
          this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
          await this.swalAlert.alert(`You cannot add print product to the cart.`);
          return false;
        }

        break;
    
      case "Shutterfly":
        this.orderService.orderDto.salesOrganization = 'Shutterfly';    
          
        if (product.salesOrganization === 'Optum') {
          this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
          await this.swalAlert.alert(`You cannot add electronic product to the cart.`);
          return false;
        }

        break;
    }
    return true;
  }
  async hasMaxQuantityOneBR(orderDetail: OrderDetailDto) {
    if (this.orderService.orderDto.shipToDto.country !== 'US') {
      const nonAllowableInternationalCities: string[] = ['APO', 'FPO'];

      if (nonAllowableInternationalCities.indexOf(this.orderService.orderDto.shipToDto.city) === -1 &&
        this.orderService.orderDto.shipmentMethod === 'UPS-INT' && orderDetail.productCode === '0052') {
        this.orderService.clickConfiguration(orderDetail);
        this.adjustedLineItem.emit();
        this.setButtonFocus();
      }
      else {
        if (!orderDetail.productDto.exemptShipping && orderDetail.productDto.classCode === 'SH' && orderDetail.productDto.productCode !== '0052') {
          await this.swalAlert.alert(this.internationalAdressMessage);
          this.stopDup();
        }
      }
    }
    else {
      this.orderService.clickConfiguration(orderDetail);
      this.adjustedLineItem.emit();
      this.setButtonFocus();
    }
  }
  async checkInternationAddress(orderDetail: OrderDetailDto){
    if (this.orderService.orderDto.shipToDto.country !== 'US') {
      const nonAllowableInternationalCities: string[] = ['APO', 'FPO'];

      if (nonAllowableInternationalCities.indexOf(this.orderService.orderDto.shipToDto.city) === -1 &&
        this.orderService.orderDto.shipmentMethod === 'UPS-INT' && orderDetail.productCode === '0052') {
        this.orderService.clickConfiguration(orderDetail);
        this.adjustedLineItem.emit();
        this.setButtonFocus();
      }
      else {
        if (!orderDetail.productDto.exemptShipping && orderDetail.productDto.classCode === 'SH' && orderDetail.productDto.productCode !== '0052') {
          await this.swalAlert.alert(this.internationalAdressMessage);
          this.stopDup();
        }
      }
    }
    else {
      this.modeService.nextFocus = this.orderQuantity.nativeElement;
    }
  }
  async onChangeOrderQuantity(orderDetail: OrderDetailDto): Promise<void> {
    this.selectedOrderDetailDto = orderDetail;
    const productDto = this._configService.reference?.productDtos?.filter(p => p.id === orderDetail.productId)[0];
    const totalShippableItems = this.orderService.orderDto.orderDetailDtos
    .filter(m => m.isPhysical && !m.productDto.exemptShipping && m.orderDetailStatus === ' ')
    .reduce((sum, item) => sum + item.orderQuantity, 0);

    if (!productDto) {
      this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
      await this.swalAlert.alert(`This item ${orderDetail.productCode} is invalid or does not exist. Please enter a new item #`);
      return;
    }
    if(!await this.onChangeOrderQuantityCheckIfItemAllowed(productDto, orderDetail)){
      return;
    }

    if (orderDetail.orderQuantity === 0) {
      orderDetail.orderQuantity = this.previousOrderQuantity;
      orderDetail.openQuantity = this.previousOrderQuantity;
      await this.swalAlert.alert('Zero quantity is not allowed.')
      return;
    }

    if (await this.maxOrderQuantityOneBR(orderDetail, productDto)) {
      return;
    }

    this.selectedOrderDetailDto.openQuantity = this.selectedOrderDetailDto.orderQuantity;

    this.adjustedLineItem.emit(orderDetail);
    this.recalculateWebCfaLicensesBR(orderDetail);

    if ((orderDetail.orderQuantity >= 200 ||totalShippableItems >= 200) && !this.selectedType && this.orderService.orderDto.shipmentMethod &&
      this.orderService.orderDto.shipmentMethod.toUpperCase() !== 'DONOTSHIP') {
      if (!this.orderService.orderDto.systemComments ||
        (!this.orderService.orderDto.systemComments.toLowerCase().includes('customer has a dock and can receive truck shipments')
         && (!this.orderService.orderDto.systemComments.toLowerCase().includes('no dock available, truck with lift gate and hand truck is required')
        && !this.orderService.orderDto.systemComments.toLowerCase().includes('do not ship')))) {
        this.quantityCheckModal.show = true;
        setTimeout(() => {
          this.quantityCheckOkBtn.nativeElement.focus();
        }, 0);
        this.proceedNext();
        return
      } else {
        this.proceedNext();
        return
      }
    }
    this.proceedNext();
  }

  async onChangeOrderQuantityCheckIfItemAllowed(productDto: ProductDto, orderDetail: OrderDetailDto): Promise<boolean> {
    if ((this._authorizationService.hasResource('TucsonAllowPrintProducts') && !this._authorizationService.hasResource('TucsonAllowElectronicProducts'))) {
      if (productDto.salesOrganization === 'Optum') {
        await this.swalAlert.alert(`You are not authorized to modify the item ${orderDetail.productCode}.`);
        this.orderService.orderDto.orderDetailDtos
          .filter(o => o.id === orderDetail.id)[0].orderQuantity = orderDetail.openQuantity + orderDetail.shippedQuantity;
        return false;
      }
    }
    else if (!this._authorizationService.hasResource('TucsonAllowPrintProducts') && this._authorizationService.hasResource('TucsonAllowElectronicProducts')) {
      if (productDto.salesOrganization === 'Shutterfly') {
        await this.swalAlert.alert(`You are not authorized to modify the item ${orderDetail.productCode}.`);
        this.orderService.orderDto.orderDetailDtos
          .filter(o => o.id === orderDetail.id)[0].orderQuantity = orderDetail.openQuantity + orderDetail.shippedQuantity;
        return false;
      }
    }
    return true;
  }
  async onChangeUnitPrice(orderDetail: OrderDetailDto) {
    const productDto = this._configService.reference?.productDtos?.filter(p => p.id === orderDetail.productId)[0];

    if(!await this.onChangeUnitPriceCheckIfItemAllowed(productDto, orderDetail)){
      return;
    }
    
    if (orderDetail.classCode === 'XS') {
      orderDetail.unitPrice *= -1;
    }
    if (orderDetail.productDto.allowCustomPrice) {
      orderDetail.listPrice = this._pricingService.calculateListPricing(this.orderService.orderDto, orderDetail) || orderDetail.listPrice;
    }

    if (orderDetail.listPrice === 0) {
      orderDetail.listPrice = orderDetail.unitPrice;
    }
    if (orderDetail.productDto.allowCustomStandard) {
      orderDetail.listPrice = orderDetail.unitPrice;
    }

    if(orderDetail.productDto.multiUserPricingDtos.length == 0 || !this.orderService.isChangedTier){
      orderDetail.discount = this._pricingService.round((100 - orderDetail.unitPrice / orderDetail.listPrice * 100), 10);
      orderDetail.discount = this._pricingService.calculateMaxDiscount(orderDetail.discount, orderDetail.productId);
    }
    
    if(this.orderService.isChangedTier){
      orderDetail.discount = this._pricingService.round((100 - orderDetail.unitPrice / orderDetail.listPrice * 100), 10);
      orderDetail.discount = this._pricingService.calculateMaxDiscount(orderDetail.discount, orderDetail.productId);
    }

    this._pricingService.calculateUnitPrice(orderDetail);
    this._pricingService.calculateExtendedPrice(orderDetail);
    this.orderService.recalculateFields(this.orderService.orderDto);
  }

  async onChangeUnitPriceCheckIfItemAllowed(productDto: ProductDto | undefined, orderDetail: OrderDetailDto): Promise<boolean>{
    if ((this._authorizationService.hasResource('TucsonAllowPrintProducts') && !this._authorizationService.hasResource('TucsonAllowElectronicProducts'))) {
      if (productDto?.salesOrganization === 'Optum') {
        await this.swalAlert.alert(`You are not authorized to modify the item ${orderDetail.productCode}.`);
        this._pricingService.calculateUnitPrice(orderDetail);
        this._pricingService.calculateExtendedPrice(orderDetail);
        this.orderService.recalculateFields(this.orderService.orderDto);
        return false;
      }
    }
    else if (!this._authorizationService.hasResource('TucsonAllowPrintProducts') && this._authorizationService.hasResource('TucsonAllowElectronicProducts')) {
      if (productDto?.salesOrganization === 'Shutterfly') {
        await this.swalAlert.alert(`You are not authorized to modify the item ${orderDetail.productCode}.`);
        this._pricingService.calculateUnitPrice(orderDetail);
        this._pricingService.calculateExtendedPrice(orderDetail);
        this.orderService.recalculateFields(this.orderService.orderDto);
        return false;
      }
    }
    return true;
  }
  async onChangeDiscount(orderDetail: OrderDetailDto) {
    const productDto = this._configService.reference?.productDtos?.filter(p => p.id === orderDetail.productId)[0];

    if ((this._authorizationService.hasResource('TucsonAllowPrintProducts') && !this._authorizationService.hasResource('TucsonAllowElectronicProducts'))) {
      if (productDto?.salesOrganization === 'Optum') {
        await this.swalAlert.alert(`You are not authorized to modify the item ${orderDetail.productCode}.`);
        orderDetail.discount = this.orderService.orderDtoBackup.orderDetailDtos
          .filter(o => o.id === orderDetail.id)[0].discount;
        this._pricingService.calculateUnitPrice(orderDetail);
        this._pricingService.calculateExtendedPrice(orderDetail);
        this.orderService.recalculateFields(this.orderService.orderDto);
        return;
      }
    }
    else if (!this._authorizationService.hasResource('TucsonAllowPrintProducts') && this._authorizationService.hasResource('TucsonAllowElectronicProducts')) {
      if (productDto?.salesOrganization === 'Shutterfly') {
        await this.swalAlert.alert(`You are not authorized to modify the item ${orderDetail.productCode}.`);
        orderDetail.discount = this.orderService.orderDtoBackup.orderDetailDtos
          .filter(o => o.id === orderDetail.id)[0].discount;
        this._pricingService.calculateUnitPrice(orderDetail);
        this._pricingService.calculateExtendedPrice(orderDetail);
        this.orderService.recalculateFields(this.orderService.orderDto);
        return;
      }
    }

    orderDetail.discount = this._pricingService.calculateMaxDiscount(orderDetail.discount, orderDetail.productId);
    this._pricingService.calculateUnitPrice(orderDetail);
    this._pricingService.calculateExtendedPrice(orderDetail);
    this.orderService.recalculateFields(this.orderService.orderDto);
  }

  async onChangeListPrice(orderDetail: OrderDetailDto) {
    const productDto = this._configService.reference?.productDtos?.filter(p => p.id === orderDetail.productId)[0];

    if ((this._authorizationService.hasResource('TucsonAllowPrintProducts') && !this._authorizationService.hasResource('TucsonAllowElectronicProducts'))) {
      if (productDto?.salesOrganization === 'Optum') {
        await this.swalAlert.alert(`You are not authorized to modify the item ${orderDetail.productCode}.`);
        orderDetail.listPrice = this.orderService.orderDtoBackup.orderDetailDtos
          .filter(o => o.id === orderDetail.id)[0].listPrice;
      }
    }
    else if (!this._authorizationService.hasResource('TucsonAllowPrintProducts') && this._authorizationService.hasResource('TucsonAllowElectronicProducts')) {
      if (productDto?.salesOrganization === 'Shutterfly') {
        await this.swalAlert.alert(`You are not authorized to modify the item ${orderDetail.productCode}.`);
        orderDetail.listPrice = this.orderService.orderDtoBackup.orderDetailDtos
          .filter(o => o.id === orderDetail.id)[0].listPrice;
      }
    }

    this._pricingService.calculateUnitPrice(orderDetail);
    this._pricingService.calculateExtendedPrice(orderDetail);
    this.orderService.recalculateFields(this.orderService.orderDto);
  }

  onChangeRequiredDate(e: any, orderDetail: OrderDetailDto) {
    orderDetail.requiredDate = new Date(e.srcElement.value);
    this.orderService.orderDto.orderDetailDtos.filter(o => o.parentOrderDetailId === orderDetail.id && o.orderDetailStatus === ' ')
    .forEach(item =>{
      item.requiredDate=orderDetail.requiredDate;
    });
    this.adjustedLineItem.emit(orderDetail);
  }

  closeNoItemDialog() {
    this.showNoItemExists.show = false;
  }

  async closeItemDialog() {
    this.dupWebApps.show = false;
    this.itemComment.show = false;
    this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(o => o.productId !== 0)
    this.orderService.recalculateFields(this.orderService.orderDto);
    this.modeService.mode = Mode.View;

    if (this._authorizationService.hasResource('OrderInquiryEditButton')) {
      await this.orderService.accountContactValidationBR();
      this.modeService.mode = Mode.Edit;
      this.orderService.orderDto.yearPanelButtonsDisable = true;
    }

    setTimeout(() => {
      if(this.dupProduct.show)
      {
        this.dupItemOkBtn.nativeElement.focus();
      }
    }, 0);
    if (this.setFocusToQuantityAfterPopup) {
      this.setFocusToQuantityAfterPopup = false;
      this.orderQuantity.nativeElement.focus();
    }
  }

  onVoidLineItem() {
    const orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(e => e['productCode'] === '0010' && e.orderDetailStatus !== 'V');
    if (!orderDetailDtos && this.orderService.orderDto.paymentMethod === 'C') {
      this.orderService.orderDto.paymentMethod = 'I';
    }

    this.adjustedLineItem.emit(this.selectedOrderDetail);
  }

  allowDup() {
    setTimeout(() => {
      this.orderQuantity.nativeElement.focus();
    },0);
    this.dupProduct.show = false;
    return;
  }

  onClickYes() {
    this.quantityCheckModal.show = false;
    this.selectedType = 'Y';
    this.orderService.orderDto.hasDock = true;
    this.proceedNext()
  }

  onClickNo() {
    this.quantityCheckModal.show = false;
    this.selectedType = 'N';
    this.orderService.orderDto.hasNoDock = true;
  }

  proceedNext() {
    if (this.selectedOrderDetailDto.orderDetailStatus === ' ') {
      this.selectedOrderDetailDto.openQuantity = this.selectedOrderDetailDto.orderQuantity - this.selectedOrderDetailDto.shippedQuantity;
      this.calculatePricing(this.selectedOrderDetailDto);
    }
    else if (this.selectedOrderDetailDto.orderDetailStatus === 'C') {
      // This used to use the actual backup.  This code need to be altered, as it shouldn't need the orderDtoBackup
      const orderDtoBackup = this.orderService.orderDto;

      const unsavedOrderDto = [...orderDtoBackup.orderDetailDtos];
      this.subsPriceCalucation.showNormalDialog(this.selectedOrderDetailDto.productId, unsavedOrderDto);

      // Revert the quantity back to what it was originally on the main item.
      this.selectedOrderDetailDto.orderQuantity = this.previousOrderQuantity;
      this.selectedOrderDetailDto.openQuantity = this.previousOrderQuantity - this.selectedOrderDetailDto.shippedQuantity;
    }

    if (this.selectedOrderDetailDto.productId === 262) {
      this.orderService.orderDto.paymentMethod = 'C';
    }
  }

  calculatePricing(orderDetail: OrderDetailDto) {
    const newListPrice = this._pricingService.calculateListPricing(this.orderService.orderDto, orderDetail) || orderDetail.listPrice;

    const listPriceChanged = orderDetail.listPrice !== newListPrice;
    this.oldOrderQuantity = orderDetail.id > 0 ? this.previousOrderQuantity : this.oldOrderQuantity;
    const oldTier = orderDetail.productDto.multiUserPricingDtos.filter(item => this.oldOrderQuantity >= item.startRange && this.oldOrderQuantity <= item.endRange)[0];
    const newTier = orderDetail.productDto.multiUserPricingDtos.filter(item => orderDetail.orderQuantity >= item.startRange && orderDetail.orderQuantity <= item.endRange)[0];
    const changedTier = oldTier!== newTier;
    const isNewLineItem = orderDetail.id < 0;
    orderDetail.listPrice=newListPrice;
    this.orderService.isChangedTier = changedTier;

    if(!isNewLineItem && !changedTier && listPriceChanged ||(orderDetail.unitPrice !== this.oldUnitPrice && orderDetail.orderQuantity === this.oldOrderQuantity))
    {
      this.onChangeUnitPrice(orderDetail);
    }
    
    this.oldUnitPrice = orderDetail.unitPrice;
    this.oldOrderQuantity = orderDetail.orderQuantity;
    this._pricingService.calculateExtendedPrice(orderDetail);
    this.orderService.recalculateFields(this.orderService.orderDto);
  }

  onOrderQuantityBlur(orderDetail: OrderDetailDto): void {
    if (orderDetail.orderDetailStatus === 'C' && orderDetail.orderQuantity === 0) {
      setTimeout(() => orderDetail.orderQuantity = this.previousOrderQuantity);
    }

    if (orderDetail.id <= 0 && orderDetail.orderQuantity !== 0 && !orderDetail.configSetSaved && this.validationPassed) {
      if (this.orderService.orderDto.shipToDto.country !== 'US') {
        const nonAllowableInternationalCities: string[] = ['APO', 'FPO'];

        if (nonAllowableInternationalCities.indexOf(this.orderService.orderDto.shipToDto.city) === -1 &&
          this.orderService.orderDto.shipmentMethod === 'UPS-INT' && orderDetail.productCode === '0052') {
          this.orderService.clickConfiguration(orderDetail);
          this.adjustedLineItem.emit();
          this.setButtonFocus();
        }
        else {         
          this.checkIsInternationalProduct(orderDetail);
        }
      }
      else {
        this.orderService.clickConfiguration(orderDetail);
        this.adjustedLineItem.emit();
        this.setButtonFocus();
      }
    }
  }
  checkIsInternationalProduct(orderDetail:OrderDetailDto){
    if (!orderDetail.productDto.exemptShipping && orderDetail.productDto.classCode === 'SH' && orderDetail.productDto.productCode !== '0052') {
      this.swalAlert.alert(this.internationalAdressMessage);
      this.stopDup();
    }
  }

  async maxOrderQuantityOneBR(orderDetail: OrderDetailDto, productDto: ProductDto): Promise<boolean> {
    if (orderDetail.orderQuantity > 1) {
      if (productDto?.hasMaxQuantityOne) {
        orderDetail.orderQuantity = 1;
        orderDetail.openQuantity = 1;
        await this.swalAlert.alert("The order quantity for this item must equal 1");
        return true;
      }
    }
    return false;
  }

  async inventoryCheckBR(orderDetail: OrderDetailDto, productDto: ProductDto): Promise<boolean> {
    let result = true
    if (productDto.isOutOfStock) {
      let message = "Product is out of stock";
      if (productDto.outOfStockNote !== null && productDto.outOfStockNote.length > 0) {
        message = productDto.outOfStockNote;
      }
      await this.swalAlert.alert(message);
      result = false;
    }
    return result
  }

  recalculateWebCfaLicensesBR(orderDetail: OrderDetailDto): void {
    if (!orderDetail.productDto.configSetDtos.some(item => item.configSet === 'CFAWEB')) {
      return;
    }

    // Adjust the licenses when the main item qwuantity changes.
    const licenseOrderDetail = this.orderService.orderDto.orderDetailDtos.filter(item => item.parentOrderDetailId === orderDetail.id && item.orderDetailStatus === ' ')[0];
    if (licenseOrderDetail) {
      licenseOrderDetail.orderQuantity = orderDetail.orderQuantity * (orderDetail.cfaWebDtos.length - 1);
      licenseOrderDetail.openQuantity = orderDetail.orderQuantity * (orderDetail.cfaWebDtos.length - 1);
    }
  }
  getdatafile(orderDetail: OrderDetailDto){
    if (orderDetail.productDto?.configSetDtos?.some(config => config.configSet === 'DATAFILE')) {
      return { 'background-color': '#9BC2E6' };
    }
    else {
      return { 'background-color': '#FFFFFF' };

    }
  }
  getdiscount(orderDetail: OrderDetailDto) {
    if (orderDetail.productId !== 0 && this.orderService.orderDto.billToDto.specifiedPriceList) {
      const priceDto = this.orderService.orderDto.billToDto.accountPricingDtos?.filter((priceObj) =>
        priceObj.productId === orderDetail.productId
      );

      if (priceDto) {
        const result = this.setBackGroundColorWhenPriceDtoExists(orderDetail, priceDto);
        if (result != "") {
          return result;
        }
      }
      if (orderDetail.productDto?.productCode != '' && orderDetail.listPrice == 0 && !(orderDetail.productDto?.allowCustomStandard || orderDetail.productDto?.allowCustomPrice)) {
        return { 'background-color': '#EFEFEE' };
      }
    }
    if (orderDetail.promotionEntryCode === 'ILT' || orderDetail.promotionEntryCode === 'ILP' || orderDetail.promotionEntryCode === 'OLT' || orderDetail.promotionEntryCode === 'OLP') {
      return { 'background-color': '#FBBDBD' }//pink
    }
    return { 'background-color': '#FFF' }
  }

  setBackGroundColorWhenPriceDtoExists(orderDetail: OrderDetailDto, priceDto: AccountPricingDto[]): any{
    if(orderDetail.promotionEntryCode === 'ILT' || orderDetail.promotionEntryCode === 'ILP' 
    || orderDetail.promotionEntryCode === 'OLT' || orderDetail.promotionEntryCode === 'OLP'){
      if(orderDetail?.discount != priceDto[0]?.discount){
        return { 'background-color': '#FBBDBD' }//pink
      }
      if(orderDetail?.discount === priceDto[0]?.discount){
        return { 'background-color': '#A9D08E' }//green
      }
    } 
    if (priceDto.length > 0 && orderDetail?.discount === priceDto[0]?.discount) {
      return { 'background-color': '#A9D08E' }//green
    }
  }
}
