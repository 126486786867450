<div class="container-fluid px-0" style="overflow-x: hidden;">
  <div class="row gx-0">
    <div class="col-12">
      <div class="container-fluid px-4 my-3">
        <div class="row gx-0 mt-4">
          <div class="col">
            <div class="container-fluid mx-4 my-3">
              <div class="row">
                <div class="col">
                  <app-cfd-form-account-info [enteredByUserRole]="enteredByUserRole" ></app-cfd-form-account-info>
                </div>
              </div>
              <hr class="hr-width">
              <div class="row">
                <div class="col">
                  <app-cfd-form-field-selection-section></app-cfd-form-field-selection-section>
                </div>
              </div>
              <hr class="hr-width">
              <div class="row">
                <div class="col">
                  <app-cfd-form-footer-section [enteredByUserRole]="enteredByUserRole"></app-cfd-form-footer-section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
