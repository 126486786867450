import { Component, Input, OnInit } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { InvoiceDetailsDto } from 'src/app/dtos/invoice-details-dto.model';
import { InvoicePdfEmailInputDto } from 'src/app/dtos/invoice-pdf-email-input-dto.model';
import { AccountsReceivableService } from 'src/app/services/accounts-receivable.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ConfigService } from 'src/app/services/config.service';
import { Dialog } from 'src/app/helpers/dialog';

@Component({
  selector: 'app-select-template',
  templateUrl: './select-template.component.html',
  styleUrls: ['./select-template.component.css']
})
export class SelectTemplateComponent implements OnInit{
 private readonly _configService: ConfigService;
  configIsReadySubscription: Subscription | undefined;
  InvoicePdfEmailInputDto = new InvoicePdfEmailInputDto();
  orderIsReadySubscription: Subscription | undefined;
  selectedInvoicesList: InvoiceDetailsDto[] = [];
  getSelectedInvoicesSubscription: Subscription | undefined;
 
  @Input() showTemplateEmailDialog = new Dialog<string>();
  @Input() templateUrl=new Dialog<void>();
  loggedInUserEmail:string|undefined;
  loggedinName: any; 
  private readonly _authorizationService: AuthorizationService;
  email = '';
  
  templateType: IUITKSelectItemProps[] = [
      { id:'Generic',label: 'Generic', value: 'Generic' },
      {  id: 'CreditHold',label: 'Credit Hold', value: 'CreditHold' },
      { id: 'DeclinedCC', label: 'Declined CC', value: 'DeclinedCC' },
      {  id: 'DisputedCharge',label: 'Disputed Charge', value: 'DisputedChange' },
      {  id: 'Taxinvoice',label: 'Tax invoice', value: 'Taxinvoice' },
  ];

  

  constructor(
    public accountsReceivableService: AccountsReceivableService,
    configService: ConfigService,
    public authorizationService: AuthorizationService){
    this._configService = configService;
    this._authorizationService = authorizationService;
  }
  ngOnInit(): void {
    if (!this._authorizationService.hasResource("ARInquirySelectTemplateDD")) {
      this._configService.disablePickListOption(this.templateType, "CreditHold");
      this._configService.disablePickListOption(this.templateType, "DeclinedCC");
      this._configService.disablePickListOption(this.templateType, "DisputedChange");
      this._configService.disablePickListOption(this.templateType, "Taxinvoice");
      
    }   
  }

  onChangeTemplate(event: any){
    
    this.accountsReceivableService.selecedTemplatedValue=event.value.label;
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
    this.loggedInUserEmail=currentUser.profile.email;
    this.loggedinName=currentUser.profile.name;
    if(this.accountsReceivableService.selecedTemplatedValue==="Generic"){
      this.accountsReceivableService.headerForTemplate="Invoices Attached Email";
      this.accountsReceivableService.EmailSubject="Account ID "+this.accountsReceivableService.accountIdForTemplate+"-"+"Invoice(s) Attached";
      this.accountsReceivableService.EmailBody = "Attached is the invoice copy that you requested." + '<br><br>' + "Pay by check or ACH online at : https://billpay.optum.com Login using your 5-digit zip code and the 10-digit Customer Number located at the top of the invoices." + '<br>' + "For product information, online ordering & credit card payments go to: https://optumcoding.com " + '<br><br>' + "Regards," + '<br>' +this.loggedinName+"<br>"+this.loggedInUserEmail;
     
    }
    else if(this.accountsReceivableService.selecedTemplatedValue==="Credit Hold"){
      this.accountsReceivableService.headerForTemplate="Credit Hold Email";
      this.accountsReceivableService.EmailSubject="Past Due Invoice(s) -" +" Account ID"+this.accountsReceivableService.accountIdForTemplate + " -"+" Credit Hold";
      this.accountsReceivableService.EmailBody = "The attached invoice(s) are seriously past due.Your account is currently on Credit Hold and no further orders will ship until the past due balance is resolved. Please submit the attached invoice(s) for payment to avoid further collection activity and to avoid any subscription disruption." + '<br><br>' + "Pay by check or ACH online at: https://billpay.optum.com Login using your 5-digit zip code and the 10-digit Customer Number located at the top of the invoices." + '<br>' + "For product information, online ordering & credit card payments go to https://optumcoding.com." + '<br><br>' + "Thank you for your cooperation in resolving this matter." + '<br><br>' + "Regards,"+"<br>"+this.loggedinName+"<br>"+this.loggedInUserEmail;
  
    }
    else if(this.accountsReceivableService.selecedTemplatedValue==="Declined CC"){
      this.accountsReceivableService.headerForTemplate="Declined Credit Card Email";
      this.accountsReceivableService.EmailSubject="Past Due Invoice(s)-" +"C# "+this.accountsReceivableService.accountIdForTemplate;
       this.accountsReceivableService.EmailBody = 
       "The attached invoice(s) are seriously past due.The credit card that you provided at the time of the order has declined. Please contact our Customer Service department at 1-800-464-3649 to pay with a different credit card, or visit our bill pay website below to submit payment by electronic check / ACH.Please remit payment promptly to avoid further collection action." + '<br><br>' + "Pay by check or ACH online at: https://billpay.optum.com Login using your 5-digit zip code and the 10-digit Customer Number located at the top of the invoices." + '<br>' + "For product information, online ordering & credit card payments go to https://optumcoding.com." + '<br><br>' + "Thank you for your cooperation in resolving this matter." + '<br><br>' + "Regards,"+"<br>"+this.loggedinName+"</br>"+this.loggedInUserEmail;
   
    }
    else if(this.accountsReceivableService.selecedTemplatedValue==="Disputed Charge"){
      this.accountsReceivableService.EmailSubject="Account ID "+this.accountsReceivableService.accountIdForTemplate+ "–"+"Disputed Charge(s)";
      this.accountsReceivableService.headerForTemplate="Disputed Charge(s) Email";
      this.accountsReceivableService.EmailBody = 
      "The attached invoice(s) are seriously past due. The charges with your credit card company were disputed. Please contact our Customer Service department at 1-800-464-3649 to pay with a different credit card, or visit our bill pay website below to submit payment by electronic check / ACH. Please remit payment promptly to avoid further collection action." + '<br><br>' + "Pay by check or ACH online at:https://billpay.optum.com Login using your 5-digit zip code and the 10-digit Customer Number located at the top of the invoices." + '<br>' + "For product information, online ordering & credit card payments go to https://optumcoding.com." + '<br><br>' + "Thank you for your cooperation in resolving this matter." + '<br><br>' + "Regards,"+"<br>"+this.loggedinName+"<br>"+this.loggedInUserEmail;
    }
    else if(this.accountsReceivableService.selecedTemplatedValue==="Tax invoice"){
      this.accountsReceivableService.EmailSubject="Account ID "+this.accountsReceivableService.accountIdForTemplate+ "–"+"Tax Invoices";
      this.accountsReceivableService.headerForTemplate="Tax Invoices Email";
      this.accountsReceivableService.EmailBody = "The attached invoice(s) are past due for sales tax. Please remit payment promptly to avoid further collection action." + '<strong>' + "Tax is charged based on the shipping address where the product is sent." + '</strong><br><br>' + "If you are tax exempt, please forward a copy of your tax exempt certificate for the state the product was shipped to. (A certificate is required for each state that you are exempt in)" + '<br><br>' + "Pay by check or ACH online at: https://billpay.optum.com Login using your 5-digit zip code and the 10-digit Customer Number located at the top of the invoices." + '<br>' + "For product information, online ordering & credit card payments go to https://optumcoding.com." + '<br><br>' + "Thank you for your cooperation in resolving this matter." + '<br><br>' + "Regards,"+"<br>"+this.loggedinName+"<br>"+this.loggedInUserEmail;

    }    
    this.showTemplateEmailDialog.close('openTemplateDialog');
  }
}
