import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';

import { Router } from '@angular/router';
@Component({
  selector: 'post-logout',
  template: `<div></div>`,
})
export class PostLogout implements OnInit {
  public logEvent = {
    FileName: 'PostLogout',
    MethodName: '',
    Message: '',
    LogType: 'UI',
    logLevel: '',
  };
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.authenticationService.completeLogout().then(async (user: any) => {
      localStorage.removeItem('username');
      this.logEvent.MethodName = 'NgOnInit';
      this.logEvent.Message = 'Cleared Session Storage';
      this.router.navigate(['/'], { replaceUrl: true });
    });
  }
}
