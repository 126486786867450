<div *ngIf="dialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" [closeBtn]=false style="width: fit-content;">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Cannot add this Line
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    You can only add ONE line item for the same Web Fulfilled product per order
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button id="btnAllowSingleItem" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
      (click)="closeAllowSingleItemPopup()" appAutoFocus>
      Ok
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>
