<div class = "overlayForPopups" *ngIf="showTemplateEmailDialog.show">
  <div cdkDrag>
<uitk-dialog [(showModal)]="showTemplateEmailDialog.show" uitkId="showSelectedTemplate" style="height: 68%;">
    <uitk-dialog-content>
        <div class="uitk-l-grid__row">
            <div class="row mt-4">
                <div class="col-sm-12">
                  <h5>Select Template:</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12" style="margin-top: 12px;">
                  <uitk-form-field>
                    <uitk-dropdown style="width:219px;margin-left: 12%;
                    "uitkId="emailTemplateDropdown" [items]="templateType"  (onChange)="onChangeTemplate($event)" 
                    ></uitk-dropdown>
                  </uitk-form-field>
                </div>
              </div>
        </div>
        
    </uitk-dialog-content>
</uitk-dialog>
  </div>
</div>