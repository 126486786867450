import { Component, Input, OnInit, NgModule } from '@angular/core';
import { UITKTableDataSource } from '@uitk/angular';
import { ModeService } from '../../services/mode.service';
import { Mode } from '../../enums/mode.enum';
import { UITKDialogModule } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { BBLogService } from '../../services/bb-log.service';

@Component({
  selector: 'app-log-dialog',
  templateUrl: './log-dialog.component.html',
  styleUrls: ['./log-dialog.component.css']
})
export class LogDialogComponent implements OnInit {
  bbLogIsReadySubscription: Subscription | undefined;
  logDialog = { show: false };
  columnName = "OrderStatus";
  tableName = "OrderHeader";
  Mode = Mode;
  spinnerText = "Loading Data";
  tableHeader = [
    { name: 'User Name', id: 'userName' },
    { name: 'Date Changed', id: 'changeDate' },
    { name: 'Old Value', id: 'oldValue' },
    { name: 'New Value', id: 'newValue' }
  ];


  constructor(public modeService: ModeService,public bbLogService:BBLogService) { }

  ngOnInit(): void {
    this.bbLogIsReadySubscription = this.bbLogService.bbLogIsReady.subscribe(() => {
      this.logDialog.show=true;
    });
  }

  closeDialog(){
    this.logDialog.show=false;
  }

  ngOnDestroy() {
    this.bbLogIsReadySubscription?.unsubscribe();
  }
}
