<div *ngIf="dialog.show" class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog [(showModal)]="dialog.show" [closeBtn]="false" uitkId="OrderEntryErrorReasonDialog">
    <uitk-dialog-content id="oeeReasonDialogContent">
        <div class="uitk-l-grid">
            <div class="uitk-l-grid__row">
                <div class="uitk-l-grid__col">
                        <uitk-form-field>
                            <uitk-fieldset id="oeeReasons">
                                <span uitkLabel>Order Entry Error Reason</span>
                                <hr>
                                <uitk-radio-group id="my-basic-radio-group" 
                                    [itemList]="orderEntryErrorReasons" [(ngModel)]="oeeReason" (ngModelChange)="changeOEEReason(oeeReason.value)">
                                </uitk-radio-group>
                            </uitk-fieldset>
                        </uitk-form-field>
                        <uitk-form-field id="otherOEEReason">
                            <input [hidden] = "!displayFreeFormTextBox" type="text" uitkInput [(ngModel)] = "otherOEEReason"
                           name="otherOEEReason">
                        </uitk-form-field>
                </div>
            </div>
        </div>
    </uitk-dialog-content>
    <uitk-dialog-actions>
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="saveOEEReason()" [disabled] = "!oeeReason">
            Ok
        </button>
        <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeDialog()">
            Cancel
        </button>
    </uitk-dialog-actions>
</uitk-dialog>
    </div>
</div>