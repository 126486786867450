<div *ngIf="orderOfferQualificationDialog.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="orderOfferQualificationDialog.show" uitkId="orderOfferQualificationDialog" [closeBtn]=false>
  <uitk-dialog-header>
    <div cdkDragHandle>
      <h4 class="modal-title pull-left">
        Order Offer Qualification Changed
      </h4>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <p>Offer qualification for this {{ offerType }} have changed. The {{ offerType }} has premium(s) associated with it.
    </p>
    <p>The premiums to be removed are:<br>
      {{ associatedPremiumsList }}
    </p>
    <p>Do you want to remove the associated premium(s)?</p>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="deletePremiums()"
      #quantityCheckOkBtn appAutoFocus>
      Yes
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeOrderOfferQualification()">
      No
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>