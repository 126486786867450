Total Records : {{totalRecords}}
<p>The number of selected invoices : {{selectedInvoices.length}}</p>
<app-filter-bar [allData]="dataSource.data" [(filteredData)]="filteredDataSource.data" [(gridFilters)]="gridFilters" [gridSort]="gridSort" #filterBar ></app-filter-bar>

<uitk-pagination [paginationConfig]="paginationConfig" [entriesPerPage]="entriesPerPage"
                    [enableGoToPage]="true" [totalRecordsCount]="totalRecords"
                    (onPaginationChange)="onPaginationChange($event)">

  <div class="uitk-c-table--responsive" uitkTableContainer [sticky]="true" style="min-height: 400px; max-height:800px;">
    <table uitkTable [dataSource]="filteredDataSource" class="uitk-c-table" aria-describedby="order-data"
          id="order-results-table" aria-labelledby="table-paginate">
      <thead class="uitk-c-table-expandable-row__thead">
        <tr uitkTableSort #sortTable="uitkTableSort" style="text-align: left">
          <th id="moreHeader" style="position: sticky; left: 0px; background-color: #ffffff; padding-inline: 4px;
    border-right: none;" class="freeze">More</th>
          <th id="chkBox" class="freeze" style="left:49px; position: sticky;left: 0px; background-color: #ffffff;border-right: 1px solid #D0D0CE;" scope="col">
            <app-grid-filter [columnName]="'Selected'"  [fieldName]="'isSelected'" [allData]="dataSource.data" [(filteredData)]="filteredDataSource.data" [(gridFilters)]="gridFilters" [dataType]="'boolean'" [gridSort]="gridSort"></app-grid-filter>
            <input type="checkbox" [disabled]="!allowCheckAll" class="checkBoxInGrid" style="position: relative; height: 20px; width: 20px;" name="selectallCheckBox" [(ngModel)]="selectAllInvoices" (ngModelChange)="selectAll()" />
          </th>
          <th *ngFor="let headerName of tableHeader" [column]="headerName.id" uitkTableSortColumn (sortChange)="onSortChange($event)" scope="col">
            <app-grid-filter [columnName]="headerName.name"  [fieldName]="headerName.id" [allData]="dataSource.data" [(filteredData)]="filteredDataSource.data" [(gridFilters)]="gridFilters" [gridSort]="gridSort"></app-grid-filter>
            {{ headerName.name }}
          </th>
        </tr>
      </thead>
      <tbody id="row-data" class="uitk-c-table__body">
        <ng-container *uitkTableRowRenderer="let data">
          <ng-container *uitkTableRowContext>
            <tr uitkTableExpandableRow style="position: sticky; left: 0px; background-color: #ffffff;" class="freeze" [expandableContent]="expandableContent"
                expandableA11yText="details for {{data.invoiceNumber}}"
                [ngStyle]="{ 'background-color':
                  data.status === 'V' ? 'lightgray' :
                  data.invoiceNumber === invoiceNumber ? 'peachpuff' :
                  data.balance < 0 ? 'skyblue' :
                  (data.type === 'Return' && data.balance === 0) ? 'orchid' :
                  'white' }"
                >
              <td *ngIf="enableCreditMemoCheckbox ||  data.invoiceAmount >= 0" id="chkBox" class="freeze" style="left:49px; position: sticky;left: 0px; background-color: #ffffff;border-right: 1px solid #D0D0CE;">
                <input type="checkbox" class="checkBoxInGrid" style="position: relative; height: 20px; width: 20px;" name="selectRowCheckBox"
                [disabled]="data.invoiceNumber === 'Pending' || data.status === 'V'"
                [(ngModel)]=data.isSelected (ngModelChange)="updateInvoiceSelectedList(data)" />
              </td>
              <td *ngIf="!enableCreditMemoCheckbox && data.invoiceAmount < 0" id="chkBox" class="freeze" style="left:49px; position: sticky;left: 0px; background-color: #ffffff;border-right: 1px solid #D0D0CE;">
                <input type="checkbox" class="checkBoxInGrid" style="position: relative; height: 20px; width: 20px;" name="CreditMemoRowCheckBox"
                [disabled]="true" />
              </td>
              <td>{{ data.invoiceNumber }}</td>
              <td>{{ data.invoiceDate | date: 'MM/dd/yyyy' }}</td>
              <td><a href="#" target="_blank" class="link" [routerLink]="['/order', data.orderNumber]">{{ data.orderNumber }}</a></td>
              <td bbLogTable="Invoice" bbLogColumn="PONumber" [bbLogPrimaryId]="data.invoiceNumber">{{ data.poNumber }}</td>
              <td>{{ data.invoiceAmount | currency }}</td>
              <td>{{ data.paidAmount | currency }}</td>
              <td>{{ (data.status === 'V' ? 0 : data.balance) | currency }}</td>
              <td>{{ data.paidDate == '1900-01-01T00:00:00' ? '' : data.paidDate | date: 'MM/dd/yyyy' }}</td>
              <td>{{ data.type }}</td>
              <td bbLogTable="Invoice" bbLogColumn="PaymentTerms" [bbLogPrimaryId]="data.invoiceNumber">{{ data.paymentTerms }}</td>
              <td bbLogTable="Invoice" bbLogColumn="PaymentMethod" [bbLogPrimaryId]="data.invoiceNumber">{{ data.paymentMethod }}</td>
              <td bbLogTable="Invoice" bbLogColumn="CCToken" [bbLogPrimaryId]="data.invoiceNumber" >{{ data.tokenId }}</td>
              <td bbLogTable="Invoice" bbLogColumn="CCLastFour" [bbLogPrimaryId]="data.invoiceNumber">{{ data.lastFour }}</td>
              <td bbLogTable="Invoice" bbLogColumn="CCExpirationDate" [bbLogPrimaryId]="data.invoiceNumber">{{ data.expirationDate }}</td>
              <td bbLogTable="Invoice" bbLogColumn="CCAuthorizationCode" [bbLogPrimaryId]="data.invoiceNumber">{{ data.authorizationCode }}</td>
              <td *ngIf="!data.trackingNumber?.startsWith('1Z')">{{ data.trackingNumber }}</td>
              <td *ngIf="data.trackingNumber?.startsWith('1Z')"><a class="link" (click)="openUpsTracking(data.trackingNumber)">{{ data.trackingNumber }}</a></td>
              <td>{{ data.shipToContactName }}</td>
              <td>{{ data.shipToContactId }}</td>
              <td>{{ data.orderByContactName }}</td>
              <td>{{ data.orderByContactId }}</td>
            </tr>
            <ng-template #expandableContent>
              <tr id="expandableContent" uitkExpandableContent [closeBtn]="false">
                <div class="uitk-c-table--responsive fixedDiv" style="width: 75%; position: sticky; left: 0px;">
                  <table uitkTable [dataSource]="data.invoiceDetails" class="uitk-c-table" aria-describedby="return-data" id="return-table"
                        aria-describedby="row-data" #rootTable="uitkTable">

                    <thead class="uitk-c-table__head">
                      <tr>
                        <th *ngFor="let headerName of expandableTableHeader" scope="col">
                          {{ headerName.name }}
                        </th>
                      </tr>
                    </thead>
                    <tbody id="row-data" class="uitk-c-table__body">
                      <ng-container *uitkTableRowBody="let return">
                        <tr class="uitk-c-table__row">
                          <td>{{ return.item }}</td>
                          <td id="description">{{ return.description }}</td>
                          <td>{{ return.unitPrice | currency }}</td>
                          <td>{{ return.invoiceQuantity }}</td>
                          <td>{{ return.discount/100 | percent:'1.3-3' }}</td>
                          <td>{{ return.taxAmount | currency }}</td>
                          <td>{{ return.extendedPrice | currency }}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </tr>
            </ng-template>
          </ng-container>
        </ng-container>
        <div *ngIf="dataSource.data.length === 0" style="text-align: center;">No records found</div>
      </tbody>
    </table>
  </div>
</uitk-pagination>
