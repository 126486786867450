import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { WorklistQueueSearchResultsComponent } from '../../components/worklist/worklist-queue-search-results/worklist-queue-search-results.component';
import { AuthorizationService } from '../../services/authorization.service';
import { ConfigService } from '../../services/config.service';
import { WorklistQueueService } from '../../services/worklistqueue-service';

@Component({
  selector: 'app-worklist-queue',
  templateUrl: './worklist-queue.component.html',
  styleUrls: ['./worklist-queue.component.css']
})
export class WorkListQueueComponent implements OnInit, OnDestroy {
  collapsiblePanel = true;
  @ViewChild(WorklistQueueSearchResultsComponent) worklistQueueSearchResultsComponent: WorklistQueueSearchResultsComponent | undefined;
  collapsedHeader = false;
  openPanel = true;
  panelBGOpen = 'uitk-l-grid-open';
  panelBG = this.panelBGOpen;
  filterTypeList: IUITKSelectItemProps[] = [];
  filterType: IUITKSelectItemProps | undefined;
  onChangeValue = '';
  configIsReadySubscription: Subscription = new Subscription;
  resourceTypeList: IUITKSelectItemProps[] = [{
    id: 'electronic', label: 'Electronic', value: 'Electronic', disabled: false
  }, {
    id: 'print', label: 'Print', value: 'Print', disabled: false
    },
    {
      id: 'all', label: 'All', value: 'All', disabled: false
    }];
  resourceType!: IUITKSelectItemProps | null;
  resourceTypeValue = '';
  emailTypeList: IUITKSelectItemProps[] = [{
    id: '1stRequest', label: '1st Request', value: '1st Request', disabled: false
  }, {
    id: '2ndRequest', label: '2nd Request', value: '2nd Request', disabled: false
  }];
  emailType!: IUITKSelectItemProps | null;
  emailTypeValue = '';

  constructor(private readonly configService: ConfigService, private readonly worklistQueueService: WorklistQueueService,
    private readonly authorizationService: AuthorizationService) {

  }

  ngOnInit(): void {
    this.configIsReadySubscription = this.configService.configIsReady.subscribe(() => {
      this.filterTypeList = this.configService.getPickList('WLQORDERTYPE');

      if (this.onChangeValue === '' || this.onChangeValue === null) {
        this.filterType = this.filterTypeList.filter(o => o.value === 'On Hold')[0];
        this.onChangeValue = this.filterType.value;
      }
      else {
        this.filterType = this.filterTypeList.filter(o => o.value === this.filterType?.value)[0];
        this.onChangeValue = this.filterType.value;
      }

      if (this.resourceTypeValue === '' || this.resourceTypeValue === null) {
        this.setResourceTypeWhenBlankOrNull();
      }
      else {
        this.setResourceType();
      }
      this.emailType = this.emailTypeList.filter(o => o.value === '1st Request')[0];
      this.worklistQueueService.emailType = this.emailType.value;

      const localStorageFilterType = localStorage.getItem('filterTypeWorkListQueue');
      const localStorageResourceType = localStorage.getItem('resourceTypeWorklistQueue');

      if (localStorageFilterType) {
        this.onChangeValue = localStorageFilterType;
        this.filterType = this.filterTypeList.filter(o => o.value === this.onChangeValue)[0];
      }

      if (localStorageResourceType) {
        this.setResourceTypeFromLocalStorage(localStorageResourceType);
      }

      if ('impersonateType' in localStorage) {
        if (localStorage.getItem('impersonateType') === 'Print Operations') {
          this.resourceTypeList.filter(r => r.value === 'Electronic')[0].disabled = true;
          this.resourceTypeList.filter(r => r.value === 'All')[0].disabled = true;
        }
      }
    });

    this.configService.loadConfigurations();
  }

  setPanelBGHeight(): void {
    this.collapsedHeader = !this.collapsedHeader;

    if (this.panelBG === this.panelBGOpen) {
      this.panelBG = 'uitk-l-grid1-close';
    }
    else {
      this.panelBG = this.panelBGOpen;
    }
  }

  onChangeFilterType(event: any): void {
    localStorage.removeItem('filterTypeWorkListQueue');
    this.onChangeValue = event.value;
    this.worklistQueueSearchResultsComponent?.ngOnInit();
  }

  onChangeResourceType(event: any): void {
    localStorage.removeItem('resourceTypeWorklistQueue');
    this.resourceTypeValue = event.value;
    this.worklistQueueSearchResultsComponent?.ngOnInit();
  }
  onChangeEmailType(event: any): void {
    localStorage.removeItem('emailTypeWorklistQueue');
    this.emailTypeValue = event.value;
    this.worklistQueueService.emailType = event.value;
  }

  setResourceTypeWhenBlankOrNull(): void {
    if (this.authorizationService.hasResource('TucsonAllowAllProducts')) {
      this.resourceType = this.resourceTypeList.filter(r => r.value === 'All')[0];
      this.resourceTypeValue = 'All';
    }
    else if (this.authorizationService.hasResource('TucsonAllowPrintProducts') && !this.authorizationService.hasResource('TucsonAllowElectronicProducts')) {
      this.resourceType = this.resourceTypeList.filter(r => r.value === 'Print')[0];
      this.resourceTypeValue = 'Shutterfly';
      this.resourceTypeList.filter(r => r.value === 'Electronic')[0].disabled = true;
      this.resourceTypeList.filter(r => r.value === 'All')[0].disabled = true;
    }
    else if (!this.authorizationService.hasResource('TucsonAllowPrintProducts') && this.authorizationService.hasResource('TucsonAllowElectronicProducts')) {
      this.resourceType = this.resourceTypeList.filter(r => r.value === 'Electronic')[0];
      this.resourceTypeValue = 'Optum';
    }
    else if (this.authorizationService.hasResource('TucsonAllowPrintProducts') && this.authorizationService.hasResource('TucsonAllowElectronicProducts')) {
      this.resourceType = this.resourceTypeList.filter(r => r.value === 'Electronic')[0];
      this.resourceTypeValue = 'Optum';
    }
    else {
      this.resourceType = null;
      this.resourceTypeValue = '';
    }
  }
  setResourceType(){
    this.resourceType = this.resourceTypeList.filter(r => r.value === this.resourceType?.value)[0];
    this.resourceTypeValue = this.resourceType.value === 'All' ? 'All': this.resourceType.value === 'Electronic' ? 'Optum' : 'Shutterfly';
  }
  setResourceTypeFromLocalStorage(localStorageResourceType: string): void {
    this.resourceTypeValue = localStorageResourceType;
    if (this.resourceTypeValue === 'All') {
      this.resourceTypeList.filter(r => r.value === 'All')[0];
    }
    else {
      this.resourceType = this.resourceTypeList.filter(r => r.value === (this.resourceTypeValue === 'Optum' ? 'Electronic' : 'Print'))[0];
    }
  }
  ngOnDestroy(): void {
    this.configIsReadySubscription.unsubscribe();
  }
}
