export class InvoiceEditDto {
  invoiceNumber = '';
  poNumber = '';
  token = '';
  paymentMethod = '';
  expirationDate = '';
  paymentTerms = '';
  ccLastFour = '';
  changePONumber = false;
  changeCreditCard = false;
  changePaymentTerms = false;
  userLogin = '';
  orderNumber = 0;
  invoiceDate = new Date();
  balance = 0;
  isSelected = false;
}
