import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CcTokentRequestDto } from '../dtos/cc-token-request-dto.model';
import { CcTokenResponseDto } from '../dtos/cc-token-response-dto.model';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class TokenizeService {
  error = new Subject();
  private readonly _configService: ConfigService;
  private readonly _httpClient: HttpClient;

  constructor(configService: ConfigService, httpClient: HttpClient) {
    this._configService = configService;
    this._httpClient = httpClient;
  }

  getCcToken(payload: CcTokentRequestDto): Observable<CcTokenResponseDto> {
    return this._httpClient.post<CcTokenResponseDto>(`${this._configService.ccTokenUrl}`, payload);
  }
}
