import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ApprovalService } from 'src/app/services/approval.service';
import { ConfigService } from 'src/app/services/config.service';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-approve-reject-with-comments',
  templateUrl: './approve-reject-with-comments.component.html',
  styleUrls: ['./approve-reject-with-comments.component.css']
})

export class ApproveRejectWithCommentsComponent implements OnInit {
  private readonly _activatedroute: ActivatedRoute;
  private readonly _router: Router;
  approveRejectWithCommentsDialog = { show: false };
  configIsReadySubscription: Subscription | undefined;
  getOrderApprovalIsReadySubscription: Subscription | undefined;
  getPriceCatalogApprovalIsReadySubscription: Subscription | undefined;
  orderApprovalId = 0;
  accountOwnerCode = '';
  comments = '';
  userCode = '';
  rejectConfirmationDialog = { show: false };
  approvalConfirmationDialog = { show: false };
  isPriceCatalogApproval = false;
  swalAlert = new SwalAlert();

  constructor(
    activatedroute: ActivatedRoute,
    public configService: ConfigService,
    public approvalService: ApprovalService,
    router: Router) {
    this._activatedroute = activatedroute;
    this._router = router;
  }

  ngOnInit(): void {
    localStorage.setItem('approvalFromEmail', 'true');
    this.isPriceCatalogApproval = this._activatedroute.snapshot.url[0]?.path.includes('pc');
    this.configIsReadySubscription = this.configService.configIsReady.pipe(first()).subscribe(() => {
      if (this._activatedroute.snapshot.params.id) {
        this.orderApprovalId = this._activatedroute.snapshot.params.id;
        this._activatedroute.queryParams.subscribe(params => {
          this.userCode = params.approver;
        });
        if(this.isPriceCatalogApproval){
          this.approvalService.getPriceCatalogApproval(this.orderApprovalId);
        }
        else{
        this.approvalService.getOrderApproval(this.orderApprovalId);
        }
      }
    });
    this.configService.loadConfigurations();
    if(this.isPriceCatalogApproval){
      this.priceCatalogApprovalRequest();
    }
    else{
      this.orderApprovalRequest();
    }
  }

  orderApprovalRequest(){
    this.getOrderApprovalIsReadySubscription = this.approvalService.orderApprovalIsReady.subscribe(() => {
      if (this.approvalService.orderApprovalDto.isAlreadyApproved) {
        // TODO: Redirect user to page letting them know the approval was already completed.
        this.swalAlert.alert('Order has previously been approved/rejected');
        return;
      }
      if (this.approvalService.orderApprovalDto.isMultiYearInProcess) {
        this.swalAlert.alert('Last year in this order has not been submitted, please wait 5 minutes and try again.');
      }
      else {
        this.approvalService.approvalDto.id = this.approvalService.orderApprovalDto.id;
        const userCode = this.configService.getUserCodeFromUserLogin(this.userCode);
        if (userCode) {
          this.approvalService.approvalDto.approvedOrRejectedBy = this.configService.getFullNameFromUserCode(userCode);
        }
        this.approveRejectWithCommentsDialog.show = true;
      }
    });

  }

  priceCatalogApprovalRequest(){
    this.getPriceCatalogApprovalIsReadySubscription = this.approvalService.priceCatalogApprovalIsReady.subscribe(() => { 
      if (this.approvalService.priceCatalogApprovalDto.isAlreadyApproved) {
        // TODO: Redirect user to page letting them know the approval was already completed.
        this.swalAlert.alert('Order has previously been approved/rejected');
        return;
      }
      this.approvalService.approvalDto.id = this.approvalService.priceCatalogApprovalDto.id;
      const userCode = this.configService.getUserCodeFromUserLogin(this.userCode);
        if (userCode) {
          this.approvalService.approvalDto.approvedOrRejectedBy = this.configService.getFullNameFromUserCode(userCode);
        }
        this.approveRejectWithCommentsDialog.show = true;  
    });
  }

  approveWithComments(): void {
    if(this.isPriceCatalogApproval){
      return;
    }
    localStorage.removeItem('approvalFromEmail');
    this.approveRejectWithCommentsDialog.show = false;
    this.approvalService.approvalDto.approvedOrRejectedStatus = 'A';
    this.approvalService.recordApproval().subscribe(() => {
      this.approvalConfirmationDialog.show = true;
      setTimeout(() => {
        if (document.getElementById(`approvalConfirmationCloseBtn`)) {
          document.getElementById(`approvalConfirmationCloseBtn`)?.focus();
        }
      });
    }, error => {
      console.log(error);
      this.swalAlert.alert('An error occurred while updating approvalDto. Please try again.');
    });


  }

  rejectWithComments(): void {
    if(this.isPriceCatalogApproval){
      return;
    }
    localStorage.removeItem('approvalFromEmail');
    this.approveRejectWithCommentsDialog.show = false;
    this.approvalService.approvalDto.approvedOrRejectedStatus = 'R';
    this.approvalService.recordApproval().subscribe(() => {
      this.rejectConfirmationDialog.show = true;
      setTimeout(() => {
        if (document.getElementById(`rejectionConfirmationCloseBtn`)) {
          document.getElementById(`rejectionConfirmationCloseBtn`)?.focus();
        }
      });
    }, error => {
      console.log(error);
      this.swalAlert.alert('An error occurred while updating approvalDto. Please try again.');
    });

  }

  closeDialog(): void {
    localStorage.removeItem('approvalFromEmail');
    this.approveRejectWithCommentsDialog.show = false;
    window.close();
    return;
  }

  closeRejectionDialog(): void {
    this.rejectConfirmationDialog.show = false;
    window.opener.location.reload();
    window.close();
    return;
  }

  closeAprrovalDialog(): void {
    this.approvalConfirmationDialog.show = false;
    window.opener.location.reload();
    window.close();
    return;
  }
}
