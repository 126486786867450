<div [ngClass]="panelBG" class="panel">
  <uitk-panel uitkId="orderSearchPanel" [collapsible]="collapsiblePanel" [open]="openPanel"
    (openChange)="setPanelBGHeight()">
    <uitk-panel-header-title>
      <h2>Worklist Queue</h2>
    </uitk-panel-header-title>
    <uitk-panel-content>
      <div class="uitk-l-grid__row">
        <div class="uitk-l-grid__col--3">
          <div style="display: flex;justify-content: space-around;">
            <label uitkLabel>Type</label>
            <uitk-form-field style="margin-right: 75px; width: 200px; margin-top: -8px;">
              <uitk-dropdown class="d1" uitkId="filterType" [items]="filterTypeList" [enableSelectFlag]="false"
                             (ngModelChange)="onChangeFilterType($event)" [(ngModel)]="filterType">
              </uitk-dropdown>
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__col--3">
          <div style="display: flex;justify-content: space-around;">
            <label uitkLabel>Resource Type</label>
            <uitk-form-field style="margin-right: 75px; width: 200px; margin-top: -8px;">
              <uitk-dropdown class="d1" uitkId="resourceType" [items]="resourceTypeList" [enableSelectFlag]="false"
                             [(ngModel)]="resourceType" (ngModelChange)="onChangeResourceType($event)">
              </uitk-dropdown>
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__col--3" *ngIf="onChangeValue =='Declined CC'">
          <div style="display: flex;justify-content: space-around;">
            <label uitkLabel>Email Request</label>
            <uitk-form-field style="margin-right: 75px; width: 200px; margin-top: -8px;">
              <uitk-dropdown class="d1" uitkId="emailType" [items]="emailTypeList" [enableSelectFlag]="false"
                             [(ngModel)]="emailType" (ngModelChange)="onChangeEmailType($event)">
              </uitk-dropdown>
            </uitk-form-field>
          </div>
        </div>
      </div>
    </uitk-panel-content>
  </uitk-panel>
</div>
<app-worklist-queue-search-results [onChangeValue]="onChangeValue" [resourceTypeValue]="resourceTypeValue"
  [collapsedHeader]="collapsedHeader"></app-worklist-queue-search-results>
