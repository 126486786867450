import { Component, Input} from '@angular/core';
import { Dialog } from 'src/app/helpers/dialog';

@Component({
  selector: 'app-subscription-trial-pop-up',
  templateUrl: './subscription-trial-pop-up.component.html',
  styleUrls: ['./subscription-trial-pop-up.component.css']
})
export class SubscriptionTrialPopUpComponent {

  @Input() SubscriptionDialog!: Dialog<string>;
 
   async ok()
  {
    this.SubscriptionDialog.close('Ok');
  }
}
