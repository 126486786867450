import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { InvoiceEditDto } from '../dtos/invoice-edit-dto.model';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})

export class InvoiceEditService {
  private readonly _configService: ConfigService;
  private readonly _httpClient: HttpClient;
  error = new Subject();

  constructor(configService: ConfigService, httpClient: HttpClient) {
    this._configService = configService;
    this._httpClient = httpClient;
  }

  updateInvoiceDetails(accountId: string, invoiceDetailsToBeUpdated: InvoiceEditDto) {
    return this._httpClient.put<InvoiceEditDto>(`${this._configService.accountingApiUrl}/invoiceDetails/${accountId}`, invoiceDetailsToBeUpdated);      
  }
}
