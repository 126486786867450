<uitk-dialog [(showModal)]="approveRejectWithCommentsDialog.show"
             uitkId="approveRejectWithCommentsDialog"
             triggerElementId="myDialog-1_openBtn2"
             [closeBtn]="false">
  <uitk-dialog-header>
    Approve/Reject Approval Request
  </uitk-dialog-header>

  <uitk-dialog-content *ngIf="!this.isPriceCatalogApproval">
    <div class="uitk-l-grid__row mt-3">
      <div class="col-3 d-flex">
        <label uitkLabel>Quote/Order # :</label>
      </div>
      <div class="col-6 d-flex">
        <span>
          {{approvalService.orderApprovalDto.orderId}}
        </span>
      </div>
    </div>
    <div class="uitk-l-grid__row mt-3">
      <div class="col-3 d-flex">
        <label uitkLabel>Owner :</label>
      </div>
      <div class="col-6 d-flex">
        <span>
          {{approvalService.orderApprovalDto.accountOwner}}
        </span>
      </div>
    </div>
    <div class="uitk-l-grid__row mt-3">
      <div class="col-3 d-flex">
        <label uitkLabel class="align-self-center label">Comments :</label>
      </div>
      <div class="uitk-l-grid__col">
        <uitk-form-field>
          <textarea uitkTextarea style="height:150px;" name="txtBoxComments"
                    uitkId="txtBoxComments-textarea"
                    [(ngModel)]="approvalService.approvalDto.comments" autofocus></textarea>
        </uitk-form-field>
      </div>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-content *ngIf="this.isPriceCatalogApproval">
    <div class="uitk-l-grid__row mt-3">
      <div class="col-3 d-flex">
        <label uitkLabel>Price Catalog # :</label>
      </div>
      <div class="col-6 d-flex">
        <span>
          {{approvalService.priceCatalogApprovalDto.id}}
        </span>
      </div>
    </div>

    <div class="uitk-l-grid__row mt-3">
      <div class="col-3 d-flex">
        <label uitkLabel class="align-self-center label">Comments :</label>
      </div>
      <div class="uitk-l-grid__col">
        <uitk-form-field>
          <textarea uitkTextarea style="height:150px;" name="txtBoxComments"
                    uitkId="txtBoxComments-textarea"
                    [(ngModel)]="approvalService.approvalDto.comments" autofocus></textarea>
        </uitk-form-field>
      </div>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="approveWithComments()">
      Approve
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="rejectWithComments()">
      Reject
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeDialog()">
      Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
<uitk-dialog [(showModal)]="rejectConfirmationDialog.show"
             uitkId="rejectConfirmationDialog"
             triggerElementId="myDialog-1_openBtn2"
             [closeBtn]="false">
  <uitk-dialog-header>
  </uitk-dialog-header>

  <uitk-dialog-content>
    <div class="d-flex">
      <h3>Rejection complete</h3>
      <span class="uitk-icon uitk-icon-checkmark_bold md-icon" style="padding-left: 0.25rem"></span>
    </div>
  </uitk-dialog-content>

  <uitk-dialog-actions>
    <button id="rejectionConfirmationCloseBtn"class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="closeRejectionDialog()" autofocus>
      Close
    </button>
  </uitk-dialog-actions>
</uitk-dialog>

<uitk-dialog [(showModal)]="approvalConfirmationDialog.show"
             uitkId="approvalConfirmationDialog"
             triggerElementId="myDialog-1_openBtn2"
             [closeBtn]="false">
  <uitk-dialog-header>
  </uitk-dialog-header>

  <uitk-dialog-content>
    <div class="d-flex">
      <h3>Approval complete</h3>
      <span class="uitk-icon uitk-icon-checkmark_bold md-icon" style="padding-left: 0.25rem"></span>
    </div>
  </uitk-dialog-content>

  <uitk-dialog-actions>    
    <button id ="approvalConfirmationCloseBtn"class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="closeAprrovalDialog()" autofocus>
      Close
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
