<div class="footer">
  <div>
    <strong>Payment options to confirm order?</strong>&nbsp;
    <div class="row">
      <div class=col-2 d-flex>
        <uitk-form-field>
          <input uitkCheckbox id="poRequired" type="checkbox" disabled [(ngModel)]="poRequired" />
          <label uitkCheckboxLabel id="poRequiredLabel" for="poRequired">
            PO Required
          </label>
        </uitk-form-field>
      </div>
      <div class=col-2 d-flex>
        <uitk-form-field>
          <input uitkCheckbox id="prePayRequired" type="checkbox" disabled [(ngModel)]="prePayRequired" />
          <label uitkCheckboxLabel id="prePayRequiredLabel" for="prePayRequired">
            Pre-pay Required
          </label>
        </uitk-form-field>
      </div>
      <div class=col-2 d-flex>
        <uitk-form-field>
          <input uitkCheckbox id="standard" type="checkbox" disabled [(ngModel)]="standard" />
          <label uitkCheckboxLabel id="standardLabel" for="standard">
            Standard
          </label>
        </uitk-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-1">
        <strong><label style="margin-top: 11px;">Formats</label></strong>
      </div>
      <div class="col-lg-11">
        <form [formGroup]="profileForm">
          <uitk-form-field>
            <uitk-fieldset>
              <uitk-radio-group uitkId="my-basic-radio-group" formControlName="radioGroup" [itemList]="items"
                [(ngModel)]="formatType" (change)="onChangeFormatType()">
              </uitk-radio-group>
            </uitk-fieldset>
          </uitk-form-field>
        </form>
      </div>
    </div>
    <div class="mt-4">
      <button id="generateBtn" type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
        (click)="generate()">
        Generate
      </button>
      <button id="previewBtn" type="button"
        class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
        style="margin-left: 88px;" (click)="preview()" [disabled]="disablePreviewButton">
        Preview
      </button>
      <button id="cancelBtn" type="button"
        class="uitk-c-button uitk-c-button--alternative-ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
        style="float: right; margin-right: 60px;" (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
<div *ngIf="quoteCfdFormEmail.show">
  <app-quote-cfd-form-email [dialog]="quoteCfdFormEmail" [enteredByUserRole]="enteredByUserRole"></app-quote-cfd-form-email>
</div>
<div id="divQuoteCFDPdf" style="display: none;">
  <app-quote-cfd-pdf [enteredByUserRole]="enteredByUserRole"></app-quote-cfd-pdf>
</div>