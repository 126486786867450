<button id="editBtn" class="uitk-c-button uitk-c-button--alternative-ghost uitk-u-margin--xxs" *appShowResource="'ARInquiryEditButton'" (click)="showEditDialog()" [disabled]="true">
            Edit</button>
<div class = "overlayForPopups" *ngIf="editDialogModal.show">
  <div cdkDrag>
        <uitk-dialog
            [(showModal)]="editDialogModal.show"
            uitkId="editInvoice"
            id="editInvoice"
            triggerElementId="editBtn"
            [closeBtn]=false >
            <uitk-dialog-header><div cdkDragHandle>Edit</div></uitk-dialog-header>
            <uitk-dialog-content>
                <p><strong><span>{{invoiceCnt}}</span>
                  <span> Invoices Selected</span></strong></p>
                  <table>
                    <tr>
                      <td><label uitkLabel class="label" class="label">Change PO #</label></td>
                      <td>
                        <uitk-form-field>
                          <uitk-fieldset>
                           <uitk-radio-group uitkId="poRadioGrp" id="poRadioGrp"  appEnableResource="EditInvoiceChangePOFields" name ="poRadioGrp" [(ngModel)]="selPO" [itemList]="poItems" (ngModelChange)="poItemSelectionChange()"> </uitk-radio-group>
                          </uitk-fieldset>
                        </uitk-form-field>
                      </td>
                      <td colspan=2></td>
                    </tr>
                    <tr *ngIf="selPO?.value ==true">
                      <td *ngIf="selPO?.value ==true"><label uitkLabel class="label">PO #</label></td>
                      <td *ngIf="selPO?.value ==true">
                        <uitk-form-field>
                          <input id="poNumber" uitkInput [(ngModel)]="poNumber" name="poNumberInput" #poNumberInput="ngModel" [required]="true"/>
                        </uitk-form-field>
                      </td>
                     <td colspan=2></td>
                    </tr>
                    <tr><td colspan =4><hr></td></tr>
                    <tr>
                      <td><label uitkLabel class="label">Change Payment / Token</label></td>
                      <td>
                        <uitk-form-field>
                          <uitk-fieldset>
                           <uitk-radio-group uitkId="chgPayToken" id="chgPayToken" appEnableResource="EditInvoiceChangePaymentTokenRadios" name ="chgPayToken" [(ngModel)]="selChgPayToken" [itemList]="chgPayTokenItems" (ngModelChange)="PopultateDefaultPaymentToken()"> </uitk-radio-group>
                          </uitk-fieldset>
                        </uitk-form-field>
                      </td>
                      <td colspan=2></td>
                    </tr>
                    <tr *ngIf="selChgPayToken?.value == 'Yes'">
                      <td><label uitkLabel class="label">Payment / Token</label></td>
                      <td colspan=3>
                       <uitk-form-field>
                          <uitk-fieldset>
                           <uitk-radio-group uitkId="PayToken" id="PayToken"name ="PayToken" [(ngModel)]="selPaymentToken" [itemList]="payTokenItems"  (ngModelChange)="PaymentTokenChange()"> </uitk-radio-group>
                          </uitk-fieldset>
                        </uitk-form-field>
                      </td>
                    </tr>
                    <tr *ngIf="selChgPayToken?.value == 'Yes'">
                      <td><label uitkLabel class="label">Payment Method</label></td>
                      <td>
                        <div *ngIf="selPaymentToken?.value === 'cc'">
                          <uitk-form-field>
                            <uitk-select [disabled]="selPaymentToken?.value ==='cc'" uitkId="cpayMethod" name="cpayMethodInput" appEnableResource="EditInvoicePaymentMethodField" [defaultLabel]="defaultLabel" [defaultLabelFlag]="defaultLabelFlag" [itemList]="payMethodList" [(ngModel)]="payMethod"></uitk-select>
                          </uitk-form-field>
                        </div>
                        <div *ngIf="selPaymentToken?.value === 'tk'">
                          <uitk-form-field>
                            <uitk-select [required]="(ccNumber || '').length > 0 || selPaymentToken?.value ==='tk'" [disabled]="selPaymentToken?.value ==='cc'" uitkId="payMethod" name="payMethodInput" #payMethodInput="ngModel"  appEnableResource="EditInvoicePaymentMethodField" [defaultLabel]="defaultLabel" [defaultLabelFlag]="defaultLabelFlag" [itemList]="payMethodList" [(ngModel)]="payMethod"
                                         (change)="payMethodChange(payMethod)" [showError]="(payMethodInput?.errors?.required && payMethodInput?.touched) || showPayMethodError"></uitk-select>
                            <uitk-form-field-error *ngIf="(payMethodInput?.errors?.required && payMethodInput?.touched) || showPayMethodError">
                              Payment Method is required
                            </uitk-form-field-error>
                          </uitk-form-field>
                          </div>
                          <div *ngIf="selPaymentToken?.value ==='pt'">
                            <uitk-form-field>
                              <uitk-select uitkId="ipayMethod" name="ipayMethodInput" #ipayMethodInput="ngModel" [required]="pTerms == null" appEnableResource="EditInvoicePaymentMethodField" [defaultLabel]="defaultLabel" [defaultLabelFlag]="defaultLabelFlag" [itemList]="iPayMethodList" [(ngModel)]="ipayMethod"
                                           (change)="ipayMethodChange(ipayMethod)" [showError]="(ipayMethodInput?.errors?.required && ipayMethodInput?.touched)! || showiPayMethodError"></uitk-select>
                              <uitk-form-field-error *ngIf="(ipayMethodInput?.errors?.required && ipayMethodInput?.touched) || showiPayMethodError">
                                Payment Method is required
                              </uitk-form-field-error>
                            </uitk-form-field>
                          </div>
                      </td>
                      <td>
                        <label uitkLabel class="label" *ngIf="selPaymentToken?.value =='cc'">Credit Card Number</label>
                        <label uitkLabel class="label" *ngIf="selPaymentToken?.value =='tk'">Token</label>
                        <label uitkLabel class="label" *ngIf="selPaymentToken?.value =='pt'">Payment Terms</label>
                      </td>
                      <td>
                        <div *ngIf="selPaymentToken?.value =='cc'">
                          <uitk-form-field>
                            <input id="ccNumber" placeholder="Use key pad" appEnableResource="EditInvoiceCCNumberField" uitkInput [(ngModel)]="ccNumber" #ccNumInput="ngModel" name="ccNumber" (ngModelChange)="onCCChange($event)" [required]="true" />
                            <uitk-form-field-error *ngIf="(ccNumInput?.errors?.required && ccNumInput.touched) ||showCCNumberError">
                              CC Number is required
                            </uitk-form-field-error>
                          </uitk-form-field>
                        </div>
                        <div *ngIf="selPaymentToken?.value =='tk'">
                          <uitk-form-field>
                            <input id="token" uitkInput [(ngModel)]="token" appEnableResource="EditInvoiceTokenField" name="tokenInput" [required]="true" #tokenInput="ngModel" maxlength="6" [pattern]=tokenPattern (input)="tokenChange(token)" />
                            <uitk-form-field-error *ngIf="(tokenInput?.errors?.required && tokenInput.touched) || showTokenError">
                              Token is required
                            </uitk-form-field-error>
                            <uitk-form-field-error *ngIf="(tokenInput?.errors?.pattern) || showTokenPatternError">
                              Token should be Alphanumeric characters
                            </uitk-form-field-error>
                            <uitk-form-field-error *ngIf="token?.length !== 6 && tokenInput.touched">
                              Token should be 6 digits
                            </uitk-form-field-error>
                          </uitk-form-field>
                        </div>
                        <div *ngIf="selPaymentToken?.value =='pt'">
                          <uitk-form-field>
                            <uitk-select id="pTerms" name="pTermsInput" #pTermsInput="ngModel" appEnableResource="EditInvoicePaymentTermsField" [required]="ipayMethod == undefined" [defaultLabel]="defaultLabel" [itemList]="pTermsList" [(ngModel)]="pTerms"
                                         (change)="pTermsChange(pTerms)" [showError]="(pTermsInput?.errors?.required && pTermsInput?.touched)! || showPTermsError"></uitk-select>
                            <uitk-form-field-error *ngIf="(pTermsInput?.errors?.required && pTermsInput?.touched) || showPTermsError">
                              Payment Terms is required
                            </uitk-form-field-error>
                        </uitk-form-field>
                        </div>
                      </td>
                    </tr>
                    <tr  *ngIf="selChgPayToken?.value == 'Yes'">
                      <td><label uitkLabel class="label" *ngIf="selPaymentToken?.value !='pt'">Expiration Date</label></td>
                      <td>
                        <div *ngIf="selPaymentToken?.value !='pt'">
                          <uitk-form-field>
                            <input [disabled]="selPaymentToken?.value ==='cc'" [required]="selPaymentToken?.value ==='cc' && (ccNumber || '').length > 0" id="expDate" uitkInput [(ngModel)]="expDate" appEnableResource="EditInvoiceExpirationDateField" name="expDate" maxlength="4" name="expDateInput" #expDateInput="ngModel" [pattern]=expDatePattern (input)="expDateChange(expDate)" />
                            <uitk-form-field-error *ngIf="(expDateInput?.errors?.required && expDateInput.touched)">
                              Expiration Date is required
                            </uitk-form-field-error>
                            <uitk-form-field-error *ngIf="(isPastDate)">
                              Credit Card has expired
                            </uitk-form-field-error>
                            <uitk-form-field-error *ngIf="(expDateInput?.errors?.pattern) || showExpDatePatternError">
                              Should be valid MMYY format
                            </uitk-form-field-error>
                          </uitk-form-field>
                        </div>
                      </td>
                      <td>
                        <label uitkLabel class="label" *ngIf="selPaymentToken?.value =='tk'">Last Four Digits</label>
                      </td>
                      <td>
                        <div *ngIf="selPaymentToken?.value =='tk'">
                          <uitk-form-field>
                            <input id="fourDigits" uitkInput [(ngModel)]="fourDigits" appEnableResource="EditInvoiceLastFourDigitsField" name="fourDigits" maxlength="4" name="fourDigitsInput" #fourDigitsInput="ngModel" [pattern]=fourDigitsPattern (input)="fourDigitsChange(fourDigits)"/>
                            <uitk-form-field-error *ngIf="(fourDigitsInput?.errors?.pattern) || showFourDigitsPatternError">
                              Only Numbers allowed
                            </uitk-form-field-error>
                          </uitk-form-field>
                        </div>
                      </td>
                    </tr>

            </table>
        </uitk-dialog-content>

        <uitk-dialog-actions>
            <button id="saveBtn" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" *appShowResource="'EditInvoiceSaveButton'" (click)="saveInvoice()">
                Save
            </button>
            <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeDialog()">
                Cancel
            </button>
         </uitk-dialog-actions>
        </uitk-dialog>
  </div>
</div>

<div class = "overlayForPopups" *ngIf="openAndFutureInvoicesUpdatePopup.show">
  <div cdkDrag>
<uitk-dialog [(showModal)]="openAndFutureInvoicesUpdatePopup.show" uitkId="openAndFutureInvoicesUpdatePopup"
  id="openAndFutureInvoicesUpdatePopup" triggerElementId="saveBtn" [closeBtn]=false>
  <uitk-dialog-content>
    <div>
      Do you want to update all open and future invoices for this order?
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="updatePaymentDetailsForOpenAndFutureInvoices()" #yesBtn>
      Yes
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
      (click)="updatePaymentDetailsForSelectedInvoices()">
      No
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>