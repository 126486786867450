import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { OrderDetailDto } from '../../dtos/order-detail-dto.model';
import { OrderNoteDto } from '../../dtos/order-note-dto.model';
import { ConfigService } from '../../services/config.service';
import { OrderService } from '../../services/order.service';
import { PricingService } from '../../services/pricing.service';

@Component({
  selector: 'app-void-item-dialog',
  templateUrl: './void-item-dialog.component.html',
  styleUrls: ['./void-item-dialog.component.css']
})
  
export class VoidItemDialogComponent implements OnInit, OnDestroy {
  @Input('dialog') dialog = { show: false };
  @Input('selectedOrder') selectedOrder: OrderDetailDto = new OrderDetailDto();
  @Output() dialogClosed = new EventEmitter<any>();
  voidOrderList: IUITKSelectItemProps[] = [];
  voidOrderValue: any;
  showVoidModalError = false;
  voidReason = '';
  orderIsReadySubscription: Subscription | undefined;

  constructor(
    public orderService: OrderService,
    private readonly configService: ConfigService,
    private readonly pricingService: PricingService) { }

  ngOnInit(): void {
    this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
      this.populateVoidOrderList();
    });
    this.populateVoidOrderList();
  }

  populateVoidOrderList()
  {
    if(this.orderService.orderDto.orderType ==='R')
    {
      this.voidOrderList = this.configService.getPickList("RVOIDREASON");
    }
    else
    {
      this.voidOrderList = this.configService.getPickList("VOIDREASON");
    }
  }

  voidOnChange() {
    this.showVoidModalError = false;
  }

  onClick_ConfirmDeleteOrder() {
    const index = this.orderService.orderDto.orderDetailDtos.findIndex(i => i.id === this.selectedOrder.id);
    if (this.selectedOrder.id > 0 && this.selectedOrder.productCode === '0010') {
      this.voidDeletedLineItem();

      this.onDeletePopupClose();
    }
    else if (this.selectedOrder.id <= 0) {
      this.orderService.orderDto.orderDetailDtos.splice(index, 1);
      this.orderService.orderDto.orderDetailDtos.filter(i => i.parentOrderDetailId === this.selectedOrder.id).forEach(orderDetail => {
        const indexToDelete = this.orderService.orderDto.orderDetailDtos.findIndex(i => i.parentOrderDetailId === this.selectedOrder.id);
        this.orderService.orderDto.orderDetailDtos.splice(indexToDelete, 1);
      });
      this.orderService.orderDto.orderDetailDtos=[...this.orderService.orderDto.orderDetailDtos];
      this.orderService.recalculateFields(this.orderService.orderDto);
      this.orderService.updateAmaKeyButtonMode();
      this.onDeletePopupClose();
    }
    else if (this.selectedOrder.id > 0) {
      this.voidDeletedLineItem();
    }
  }
  voidUnshippedOpenItems(orderDetail: OrderDetailDto): void{
    orderDetail.lastOrderDetailStatus= orderDetail.orderDetailStatus;
    orderDetail.orderDetailStatus = 'V';
    orderDetail.voidedQuantity = orderDetail.openQuantity;
    orderDetail.openQuantity = 0;
    orderDetail.voidDate = new Date(Date.parse(Date()));
    orderDetail.voidReasonCode = this.voidOrderValue.value;
    orderDetail.extendedPrice = 0;
    orderDetail.taxAmount = 0;
    orderDetail.taxRate = 0;
    orderDetail.orderQuantity = orderDetail.openQuantity + orderDetail.shippedQuantity;
    if (orderDetail.subscriptionDto !== null && 'A,W,F,S'.indexOf(orderDetail.subscriptionDto.subscriptionStatus) > -1) {
      orderDetail.subscriptionDto.subscriptionStatus = 'V';
    }
  }
  voidPartiallyShippedOpenItems(orderDetail: OrderDetailDto, newOrderDetail: OrderDetailDto[], tempShippedQuantity: number): void{
    if (tempShippedQuantity > 0) {
      newOrderDetail.push(Object.assign({}, orderDetail));
      orderDetail.lastOrderDetailStatus= orderDetail.orderDetailStatus;
      orderDetail.orderDetailStatus = 'C';
      orderDetail.openQuantity = 0;
      orderDetail.orderQuantity = orderDetail.openQuantity + orderDetail.shippedQuantity;
      orderDetail.extendedPrice = isNaN((orderDetail.shippedQuantity + orderDetail.openQuantity) *
        this.pricingService.round((orderDetail.listPrice * (100 - orderDetail.discount) / 100), 2)) ? 0 :
        (orderDetail.shippedQuantity + orderDetail.openQuantity) *
        this.pricingService.round((orderDetail.listPrice * (100 - orderDetail.discount) / 100), 2);
    }
    else {
      orderDetail.lastOrderDetailStatus= orderDetail.orderDetailStatus;
      orderDetail.orderDetailStatus = 'V';
      orderDetail.voidedQuantity = orderDetail.openQuantity;
      orderDetail.openQuantity = 0;
      orderDetail.orderQuantity = 0;
      orderDetail.voidDate = new Date(Date.parse(Date()));
      orderDetail.voidReasonCode = this.voidOrderValue.value;
      orderDetail.extendedPrice = 0;
      orderDetail.taxAmount = 0;
      orderDetail.taxRate = 0;
    }
  }
  voidDeletedLineItem() {
    if (this.voidOrderValue.value === null) {
      this.showVoidModalError = true;
    }
    else {
      const newOrderDetail: OrderDetailDto[] = [];
      this.orderService.orderDto.orderDetailDtos.filter(i => i.id === this.selectedOrder.id || i.parentOrderDetailId===this.selectedOrder.id).forEach(orderDetail => {
        let tempOpenQuantity=orderDetail.openQuantity;
        let tempShippedQuantity=orderDetail.shippedQuantity;
        if(this.orderService.orderDto.orderType === "R")
        {
           tempOpenQuantity=orderDetail.openQuantity*-1;
           tempShippedQuantity=orderDetail.shippedQuantity*-1;
        }
        if (orderDetail.orderDetailStatus === ' ' && orderDetail.shipDate === null) {
          this.voidUnshippedOpenItems(orderDetail);
        }
        else if (orderDetail.orderDetailStatus === ' ' && orderDetail.shipDate !== null && tempOpenQuantity > 0) {
          this.voidPartiallyShippedOpenItems(orderDetail, newOrderDetail, tempShippedQuantity);
        }
      });
      newOrderDetail.forEach(item => {
        item.id = 0;
        item.voidedQuantity = item.openQuantity;
        item.openQuantity = 0;
        item.shippedQuantity = 0;
        item.extendedPrice = 0;
        item.taxAmount = 0;
        item.taxRate = 0;
        item.orderQuantity = item.openQuantity + item.shippedQuantity;
        item.lastOrderDetailStatus= item.orderDetailStatus;
        item.orderDetailStatus = 'V';
        item.shipDate = null;
        item.voidDate = new Date(Date.parse(Date()));
        item.voidReasonCode = this.voidOrderValue.value;
        this.orderService.orderDto.orderDetailDtos.push(item);
        this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
      });
      const systemNote = new OrderNoteDto();
      systemNote.id = -1;
      systemNote.createdDate = new Date();
      systemNote.userCode =  this.configService.getMyUserCode();
      systemNote.userName = this.configService.getMyFullName();
      systemNote.orderId = this.orderService.orderDto.id;
      systemNote.noteType = "VO";
      systemNote.systemNote="Voided Items " + this.selectedOrder.productCode;
      systemNote.noteText=this.voidReason;
      if(this.orderService.orderDto.orderNotes ==  null)
      {
        const orderNotes: OrderNoteDto[]=[];
        orderNotes.push(systemNote)
        this.orderService.orderDto.orderNotes=orderNotes;
      }
      else
      {
        this.orderService.orderDto.orderNotes.push(systemNote);
      }
      this.orderService.updateAmaKeyButtonMode();
      this.orderService.recalculateFields(this.orderService.orderDto);
      this.onDeletePopupClose();
    }
  }

  onDeletePopupClose() {
    this.voidReason = '';
    this.voidOrderValue = null;
    this.showVoidModalError = false;
    this.dialog.show = false;
    this.dialogClosed.emit();
  }

  ngOnDestroy(): void {
    this.orderIsReadySubscription?.unsubscribe();
  }
}
