<div id="returnDetailsMain" class="uitk-l-grid p-0" style="background-color: white;padding-bottom: 0px;">
  <div class="p-2">
  <div class="row ps-2">
    <div class="col d-flex">
      <h5 id="h5HeaderReturnInquiry" #header>Return Inquiry:</h5>
    </div>
    <div class="col d-flex justify-content-center">
      <b>Return Date: {{ orderService.orderDto.orderDate | date:'MM/dd/yyyy' }}</b>
    </div>
    <div class="col">
    </div>
  </div>
  <div id="returnDetailsChild" class="pt-0">
    <div class="uitk-l-grid__row">
      <label uitkLabel>Account ID</label>
      <div class="uitk-l-grid__col">
        <a target="_blank" class="link" style="text-decoration: none;" (click)="navigateToARInquiry(orderService.orderDto.billToDto.id)">{{orderService.orderDto.billToDto.id}}</a>
      </div>
      <div style="width:30px"></div>
      <label uitkLabel>Acct Type</label>
      <div class="uitk-l-grid__col">
        {{ orderService.orderDto.billToDto.accountTypeCode }}
       <span *ngIf="orderService.orderDto.billToDto.accountOwnerName"> -  <a target="_blank" [href]="'mailto:'+ orderService.orderDto.billToDto.accountOwneremail">{{ orderService.orderDto.billToDto.accountOwnerName }}</a></span>
      
      </div>
      <div style="width:30px"></div>
      <label uitkLabel>Credit Limit</label>
      <div class="uitk-l-grid__col" style="display:flex;font-weight: bold;" 
      [ngClass]="{'highlight': orderService.orderDto.billToDto.creditLimit < 0 }">
        {{ orderService.orderDto.billToDto.creditLimitDescription }}
      </div>
    </div>
    <div class="uitk-l-grid__row">
      <label uitkLabel>Type</label>
      <div class="uitk-l-grid__col">
        <uitk-form-field>
          <uitk-dropdown uitkId="soType" #singleSelect="ngModel" [items]="orderTypeList" [(ngModel)]="orderService.orderDto.orderTypeBinding"
          appEnableResource="ReturnInquiryType" [disabled]="modeService.mode !== Mode.Edit || orderService.orderDto.isPartiallyShipped" (onChange)="onChangeReturnType($event)"
          ></uitk-dropdown>
        </uitk-form-field>
      </div>
      <div style="width:30px"></div>
      <label uitkLabel>Return #</label>
      <div class="uitk-l-grid__col">
        {{orderService.orderDto.id}}
      </div>
      <div style="width:30px"></div>
      <label uitkLabel>Open Amt</label>
      <div class="uitk-l-grid__col" style="display:flex;">
        {{ orderService.orderDto.openAmount | currency }}
      </div>
    </div>
    <div class="uitk-l-grid__row">
      <label uitkLabel>Original SO #</label>
      <div class="uitk-l-grid__col" style="display:flex;">
        <a target="_blank" class="link" style="text-decoration: none;" (click)="navigateToOrderInquiry(orderService.orderDto.originalOrderId)">{{orderService.orderDto.originalOrderId}}</a>
      </div>
      <div style="width:30px"></div>
      <label uitkLabel>Status</label>
      <div class="uitk-l-grid__col">
        <uitk-select uitkId="soStatus" [itemList]="orderStatusList" [(ngModel)]="orderService.orderDto.orderStatusBinding" [disabled]="true"></uitk-select>
      </div>
      <div style="width:30px"></div>
      <label uitkLabel>Shipped Amt</label>
      <div class="uitk-l-grid__col">
        {{ orderService.orderDto.shippedAmount | currency }}
      </div>
    </div>
  </div>
  <div class="row ps-2">
    <div class="col-8">
      <div class="row">
        <label class="col-2" uitkLabel>Return Code</label>
        <div class="col-7">
          <uitk-form-field>
            <uitk-dropdown class="d1" uitkId="returnCode" [items]="returnCodeList" [enableSelectFlag]="true" [(ngModel)]="returnCode" [disabled]="modeService.mode !== Mode.Edit"  (onChange)="checkIfOEEReason($event)"></uitk-dropdown>
          </uitk-form-field>
        </div>
        <div class="col-3"></div>
      </div>
    </div>
    <div class="col-4 mt-2">
      <div class="row">
        <label class="col-4" uitkLabel>Entered By</label>
        <div class="col-8">
          <a target="_blank" [href]="'mailto:'+ orderService.orderDto.enteredByBindingEmail">{{orderService.orderDto.enteredBy}}</a>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="row d-flex ps-2">
    <div class="col-lg-1 d-flex justify-content-center">
      <label uitkLabel>Product</label>
    </div>
      <div class="col-lg-10">
      <button *ngIf="Impersonating.includes(orderService.impersonateTypes)" id="add" type="button"
        class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="add()"
        (callAddMethod)="modeService.mode === Mode.Edit?add():''" appEnableResource="ReturnInquiryAddItemButton"
        [disabled]="modeService.mode !== Mode.Edit">
        <u>A</u>dd
      </button>
       <button *ngIf="!Impersonating.includes(orderService.impersonateTypes)" id="add" type="button"
        class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="add()"
        (callAddMethod)="modeService.mode === Mode.Edit?add():''" hotkeyCommand="ctrl+a"
        appEnableResource="ReturnInquiryAddItemButton" [disabled]="modeService.mode !== Mode.Edit">
        <u>A</u>dd
      </button>
      <button id="edit" type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="edit()"
        style="font-weight: 400;" (callEditMethod)="modeService.mode === Mode.View?edit():''" hotkeyCommand="ctrl+e"
        appEnableResource="ReturnInquiryEditButton"
        [disabled]="modeService.mode !== Mode.View || orderService.orderDto.orderStatus == 'V' || (orderService.orderDto.orderStatus == 'C' && !orderService.orderDto.hasActiveSubscriptions) || orderService.orderDto.pendingDate !== null">
        Edit
      </button>
      <button id="revert" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="revert()"
        (callRevertMethod)="modeService.mode === Mode.Edit?revert():''" hotkeyCommand="ctrl+r"
        [disabled]="modeService.mode !== Mode.Edit" appEnableResource="ReturnInquiryRevertButton">
        <u>R</u>evert
      </button>
    </div>
    <div class="col-sm-1">
      <button id="save" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="save()"
        [disabled]="modeService.mode !== Mode.Edit" appEnableResource="ReturnInquirySaveButton">
        Save
      </button>
    </div>
  </div>
</div>
  <div class="row flex-grow-1 px-0 mx-0" style="margin-left: -4px;">
    <div class="col-12" style="position: relative !important; width: 100%; z-index: 1; min-height: 400px;">
      <div style="overflow-x: auto; overflow-y: auto; width: 98%; position: absolute; height: 100%;">
        <div id="loadingSpinner" class="uitk-l-grid" *ngIf="loading">
          <uitk-loading-indicator uitkId="spinner" [visibleText]="visibleTextValue" spinnerSize="large">
          </uitk-loading-indicator>
        </div>
        <div *ngIf="!loading">
          <table uitkTable [dataSource]="orderService.orderDto.orderDetailDtos" aria-describedby="return-data" style="width: auto; white-space: nowrap;">
            <thead class="uitk-c-table__head stickyHeader">
              <tr style="text-align: left">
                <th scope="col" class="freeze"></th>
                <th scope="col" class="freeze" style="left:50px;">Item	</th>
                <th scope="col" class="freeze" style="left:190px;">Description</th>
                <th scope="col" class="freeze" style="left:340px;">Return Qty</th>
                <th scope="col" class="freeze" style="left:440px;">List Price</th>
                <th *ngFor="let headerName of tableHeader" scope="col">
                  {{ headerName }}
                </th>
              </tr>
            </thead>
            <tbody class="uitk-c-table__body">
              <ng-container *uitkTableRowBody="let returnDetailDto">
                <tr *ngIf="returnDetailDto" class="uitk-c-table__row">
                  <td class="freeze" style="left:0px;background-color: white;">
                    <span class="uitk-icon uitk-icon-trash_delete gridIcons"
                          (click)="onDeleteClick(returnDetailDto)" appEnableResource="ReturnInquiryGridDeleteicon"
                    [customDisabled]="returnDetailDto.orderDetailStatus === 'C' || returnDetailDto.orderDetailStatus === 'V' || modeService.mode !== Mode.Edit" ></span>
                  </td>
                  <td class="freeze" style="left:50px;background-color: white;">
                    <uitk-form-field>
                      <input type="text" id="productCode-{{ returnDetailDto.id }}" uitkInput
                      [ngStyle]="{'width': returnDetailDto.parentOrderDetailId === null && returnDetailDto.productCode.indexOf('V') !== 0 ? '120px' : '105px',
                      'margin-left': returnDetailDto.parentOrderDetailId === null && returnDetailDto.productCode.indexOf('V') !== 0 ? '0px' : '15px',
                      'background-color':getdatafile(returnDetailDto)

                    }"
                      [(ngModel)]="returnDetailDto.productCode" name="myBasicInput"
                      (change)="onChangeProductCode($event, returnDetailDto)" [disabled]="modeService.mode !== Mode.Edit || !returnDetailDto.itemEditable || returnDetailDto.orderDetailStatus === 'V'"
                           
                             >
                    </uitk-form-field>
                  </td>
                  <td class="freeze" style="left:190px;background-color: white;min-width:150px">{{ returnDetailDto.productDescription.replace(' - R', '') }} {{ returnDetailDto.originalOrderDetailDto !== null ? ' - ' : '' }} {{ returnDetailDto.originalOrderDetailDto?.productCode }}</td>
                  <td class="freeze" style="left:340px; background-color: white;">
                    <uitk-form-field>
                      <input type="text" id="orderQuantity-{{ returnDetailDto.id }}" uitkInput
                             [(customModel)]="returnDetailDto.orderQuantity" name="myBasicInput" style="width: 80px;" [disabled]="modeService.mode !== Mode.Edit || returnDetailDto.disableQuantity"
                             (change)="onChangeOrderQuantity(returnDetailDto)" appEnableResource="ReturnInquiryReturnQuantity"
                             appQuantityFormat #returnQuantity>
                    </uitk-form-field>
                  </td>
                  <td class="freeze" style="left:440px;background-color: white;">{{ returnDetailDto.listPrice | currency }}</td>
                  <td>
                    <uitk-form-field>
                      <input type="text" id="discount-{{ returnDetailDto.id }}" uitkInput (change)="onChangeDiscount(returnDetailDto)"
                             [(customModel)]="returnDetailDto.discount" name="myBasicInput" style="width: 100px;" [disabled]="returnDetailDto.id > 0" appDiscountFormat
                             [ngStyle]="getdiscount(returnDetailDto)">
                    </uitk-form-field>
                  </td>
                  <td>
                    <uitk-form-field>
                      <input type="text" id="unitPrice-{{ returnDetailDto.unitPrice }}" uitkInput appPriceFormat  (change)="onChangeUnitPrice(returnDetailDto)"
                             [(customModel)]="returnDetailDto.unitPrice" name="myBasicInput" style="width: 80px;" [disabled]="returnDetailDto.id > 0">
                    </uitk-form-field>
                  </td>
                  <td>{{ returnDetailDto.extendedPrice | currency }}</td>
                  <td>
                    <uitk-form-field>
                      <input type="text" id="requiredDate-{{ returnDetailDto.id }}" uitkInput
                             [ngModel]="returnDetailDto.requiredDate | date:'MM/dd/yyyy'" name="reqDate" style="width: 120px;"
                             placeholder="MM/DD/YYYY" [disabled]=!returnDetailDto.itemEditable />
                    </uitk-form-field>
                  </td>
                  <td>{{ returnDetailDto.orderDetailStatus === ' ' ? 'O' : returnDetailDto.orderDetailStatus }}</td>
                  <td>{{ returnDetailDto.shippedQuantity }}</td>
                  <td>{{ returnDetailDto.shipDate | date:'MM/dd/yyyy' }}</td>
                  <td>{{ returnDetailDto.subscriptionDto?.term }}</td>
                  <td>{{ returnDetailDto.pendingDate | date:'MM/dd/yyyy' }}</td>
                  <td>{{ returnDetailDto.orderDetailStatus === 'V' ? (returnDetailDto.voidDate | date:'MM/dd/yyyy') : returnDetailDto.modifiedDate | date:'MM/dd/yyyy' }}</td>
                  <td>{{ returnDetailDto.voidedQuantity }}</td>
                  <td>{{ returnDetailDto.voidReasonDescription }}</td>
                  <td class="px-2" [ngbTooltip]="accountOwnerFullName" container="body" placement="left">{{ returnDetailDto.accountOwnerCode }}</td>
                  <ng-template #accountOwnerFullName><div style="color: white !important">{{ returnDetailDto.accountOwnerFullName }}</div></ng-template>
                  <td class="px-2" [ngbTooltip]="salesPersonFullName" container="body" placement="left">{{ returnDetailDto.salespersonCode }}</td>
                  <ng-template #salesPersonFullName><div style="color: white !important">{{ returnDetailDto.salespersonFullName }}</div></ng-template>
                  <td>{{ returnDetailDto.allowPaymentPlan? 'Yes': 'No'  }}</td>
                  <td>{{ returnDetailDto.taxAmount | currency  }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>     
    </div>
  </div>
  <div class="row px-0 mx-0" style="margin-top: 5px;">
    <div class="col-12">
      <app-return-additional-actions [iTaxRate]="taxRate" [iTotalTax]="totalTax" [arsLabel] ="orderService.orderDto.arsLabel"></app-return-additional-actions>
    </div>    
  </div>
  <div *ngIf="dupProduct.show" class = "overlayForPopups">
    <div cdkDrag>
  <uitk-dialog [(showModal)]="dupProduct.show" [closeBtn]=false>
    <uitk-dialog-header>
      <div cdkDragHandle>
        <h4 class="modal-title pull-left">
          Duplicate
        </h4>
      </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
      <span>
        This item has already been entered in the cart. Continue entering the duplicate item?
      </span>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="allowDup()" #allowDupYesBtn>
        Yes
      </button>
      <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="stopDup()">
        No
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
    </div>
  </div>
  <div *ngIf="showNoItemExists.show" class = "overlayForPopups">
    <div cdkDrag>
  <uitk-dialog [(showModal)]="showNoItemExists.show"
               uitkId="noItemDialog"
               triggerElementId="noItem"
               [closeBtn]=false>
    <uitk-dialog-header>
      <div cdkDragHandle>
        Error Message
      </div>
    </uitk-dialog-header>
    <uitk-dialog-content *ngIf="!isAddOn && !havePermission">
      {{ myNotificationWarningContent }}
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="isAddOn && !havePermission">
      {{ addOnWarningContent }}
    </uitk-dialog-content>
    <uitk-dialog-content *ngIf="havePermission">
      {{ addPermissionContent }}
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <div class="text-center">
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="closeNoItemDialog()" #noItemOkBtn>Ok</button>
      </div>
    </uitk-dialog-actions>
  </uitk-dialog>
    </div>
  </div>
  <app-void-item-dialog [dialog]="voidItemDialog" [selectedOrder]="selectedOrder"></app-void-item-dialog>

  <app-order-entry-error-reason-dialog [oeeReasondialog]="orderEntryErrorReasonDialog"></app-order-entry-error-reason-dialog>

</div>
