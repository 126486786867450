import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, SimpleChange } from '@angular/core';

@Directive({
  selector: '[allowNegativePriceFormat]'
})
export class AllowNegativePriceFormatDirective {
  // Currently unused in this example.
  @Input() appPriceFormat: string = '';
  // This is used to get the value of the variable bound to the control.
  @Input() customModel: number = 0;
  // This is used to set the value of the variable bound to the control.
  @Output() customModelChange = new EventEmitter<number>();

  // Format the price as a currency $1,234.56
  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly currencyPipe: CurrencyPipe
  ) {
  }

  ngOnChanges(change: {customModel: SimpleChange}) {
    // If the value is changed programmatically, we want to set the value of the input element.
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', this.currencyPipe.transform(change.customModel ? change.customModel.currentValue : 0) ?? '');
  }

  // This fires when they enter the input element.
  @HostListener('focus') focusEvent(): void {
    // When entering the control, we need to remove the formatting.
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', this.removeFormatting(this.elementRef.nativeElement.value));
    this.elementRef.nativeElement.setSelectionRange(0, this.elementRef.nativeElement.value.length);
  }

  // This fires when they change the input element.
  @HostListener('change') changeEvent(): void {
    this.customModelChange.next(+this.removeFormatting(this.elementRef.nativeElement.value));
  }

  // This fires when they leave the input element.
  @HostListener('blur') blurEvent(): void {
    if (this.elementRef.nativeElement.value.indexOf('$') === -1) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', this.currencyPipe.transform(this.customModel) ?? '');
    }
  }

  // Override the keypress event to prevent invalid characters.
  @HostListener('keypress', ['$event']) keyPressEvent(event: KeyboardEvent): void {
    if (event.key.match(/[^0-9.-]/g)) {
      event.preventDefault();
    }
  }

  // Override the paste event to prevent invalid characters.
  @HostListener('paste', ['$event']) pasteEvent(event: ClipboardEvent): void {
    const clipboard = event.clipboardData?.getData('text');
    if (!clipboard) {
      return;
    }

    const adjustedClipboard = clipboard.replace(/[^0-9.]/g, '');
    const startRange = (this.elementRef.nativeElement as HTMLInputElement).selectionStart ?? 0;
    const endRange = (this.elementRef.nativeElement as HTMLInputElement).selectionEnd ?? 0;

    const currentValue: string = this.elementRef.nativeElement.value;
    const newValue = currentValue.substr(0, startRange) + adjustedClipboard + currentValue.substr(endRange);

    this.renderer.setProperty(this.elementRef.nativeElement, 'value', newValue);
    this.renderer.setProperty(this.elementRef.nativeElement, 'selectionStart', startRange + adjustedClipboard.length);
    this.renderer.setProperty(this.elementRef.nativeElement, 'selectionEnd', startRange + adjustedClipboard.length);

    event.preventDefault();
  }

  removeFormatting(value: string): string {
    return (+value.replace('$', '').replace(',', '')).toFixed(2);
  }
}
