import { Directive, HostListener, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Directive({
  selector: '[appTabTitle]'
})
export class TabTitleDirective {
  @Input('appTabTitle') appTabTitle = 'Tucson';
  constructor(private titleService: Title) { }
  @HostListener('click') onClick(title:string): void { 
    this.appTabTitle = title?title: this.appTabTitle;
    this.titleService.setTitle(this.appTabTitle || 'Tucson');
  }
}
