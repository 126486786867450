<div class="uitk-l-grid">
  <div class="uitk-l-grid__row">
    <div class="uitk-l-grid__col--12">
      <div uitkTableCaption>
        <h3 id="table-caption" class="headerTextClass">Items to Approve</h3>
      </div>
      <div uitkTableContainer>
        <table uitkTable [dataSource]="approvalService.itemsToApprove" id="basic-table">
          <thead>
            <tr>
              <th *ngFor="let headerName of tableHeader" scope="col">
                {{ headerName }}
              </th>
            </tr>
          </thead>
          <tbody id="approval-data">
            <ng-container *uitkTableRowRenderer="let approvalRequest">
              <tr>
                <td><a class="link" target="_blank"
                    (click)="navigateToApproveRejectScreen(approvalRequest.id, approvalRequest.type)">Approve/Reject</a></td>
                <td><a class="link" target="_blank"
                    (click)="navigateRelatedTo(approvalRequest.type == 'SPL' ? approvalRequest.splName : approvalRequest.relatedTo, approvalRequest.type)">{{ approvalRequest.type === 'SPL'? approvalRequest.splName : approvalRequest.relatedTo}}</a>
                </td>
                <td>
                  {{ approvalRequest.type === 'S' ? 'SPL' : approvalRequest.type === ' ' ? 'Order' : approvalRequest.type === 'Q' ? 'Quote': approvalRequest.type === 'R' ? 'Return': approvalRequest.type === 'A' ? 'Authorization': approvalRequest.type}}
                </td>
                <td>{{ approvalRequest.recentApprover }}</td>
                <td>{{ approvalRequest.dateSubmitted | date: "short" }}</td>
              </tr>
            </ng-container>
            <div *ngIf="approvalService.itemsToApprove.length === 0 && !modeService.isWaiting && dataRetrieved">No
              records found</div>
          </tbody>
        </table>
      </div>
      <div *ngIf="modeService.isWaiting" class="container-fluid">
        <div class="row">
          <div class="col d-flex justify-content-center">
            <div id="loadingIndicator">
              <uitk-loading-indicator uitkId="spinner" spinnerSize="large">
              </uitk-loading-indicator>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col d-flex justify-content-center">
            <h5>
              {{modeService.waitDescription}}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
