import { Component, OnInit, ViewChild } from '@angular/core';
import { ModeService } from '../../services/mode.service';
import { Mode } from '../../enums/mode.enum';
import { SubscriptionSearchDto } from '../../dtos/subscription-search-dto.model';
import { SubscriptionService } from '../../services/subscription.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../services/config.service';
import { Subscription } from 'rxjs';
import { SubscriptionSearchResultsComponent } from '../subscription-search-results/subscription-search-results.component';

@Component({
  selector: 'app-subscription-search',
  templateUrl: './subscription-search.component.html',
  styleUrls: ['./subscription-search.component.css'],
})
  
export class SubscriptionSearchComponent implements OnInit {
  private readonly _activateRoute: ActivatedRoute;
  private readonly _configService: ConfigService;
  @ViewChild(SubscriptionSearchResultsComponent) subscriptionSearchResultsComponent: SubscriptionSearchResultsComponent | undefined;
  collapsiblePanel = true;
  openPanel = true;
  collapsedHeader = false;
  Mode = Mode;
  panelBackgroundOpen = "uitk-l-grid-open";
  panelBackground = this.panelBackgroundOpen;
  accountNumber = '';
  contactId = '';
  orderId = '';
  subscriptionNumber = '';
  itemNumber = '';
  showResults = false;
  configIsReadySubscription: Subscription | undefined;

  constructor(public modeService: ModeService,
    public subscriptionSearchService: SubscriptionService,
    activateRoute: ActivatedRoute,
    configService: ConfigService) {
    this._activateRoute = activateRoute;
    this._configService = configService;
  }

  ngOnInit(): void {
    const orderId = localStorage.getItem('subscriptionOrderId');
    if (this._activateRoute.snapshot.params.accountId || orderId) {
      this.configIsReadySubscription = this._configService.configIsReady.subscribe(
        () => {
          if(this._activateRoute.snapshot.params.accountId){
            this.accountNumber = this._activateRoute.snapshot.params.accountId;
            this.subscriptionNumber = this._activateRoute.snapshot.params.subId;
            this.itemNumber = this._activateRoute.snapshot.params.itemId;
  
            this.search();
          }
          else if(orderId){
            this.orderId = orderId;
            this.search();
            localStorage.removeItem('subscriptionOrderId');
          }
        });
    }
  }

  setPanelBGHeight() {
    this.collapsedHeader = !this.collapsedHeader;
    if (this.panelBackground === this.panelBackgroundOpen) {
      this.panelBackground = "uitk-l-grid1-close";
    }
    else {
      this.panelBackground = this.panelBackgroundOpen;
    }
  }

  setMode() {
    if (this.accountNumber || this.contactId) {
      this.modeService.mode = Mode.Edit;
    }
    else {
      this.modeService.mode = Mode.Disable;
      this.subscriptionNumber = '';
      this.itemNumber = '';
    }
  }

  clearSearch() {
    this.accountNumber = '';
    this.contactId = '';
    this.orderId = '';
    this.subscriptionNumber = '';
    this.itemNumber = '';
    this.setMode();
    this.subscriptionSearchService.subscriptions = null;
    this.showResults = false;
    this.subscriptionSearchResultsComponent?.clearFilters();
  }

  async search() {
    this._configService.resetIdleTimeout();
    this.showResults = false
    this.modeService.mode = Mode.SubscriptionWait;
    let searchOperator = new SubscriptionSearchDto();
    searchOperator.accountId = +this.accountNumber;
    searchOperator.contactId = +this.contactId;
    searchOperator.orderId = +this.orderId;
    searchOperator.subscriptionNumber = +this.subscriptionNumber;
    searchOperator.productCode = this.itemNumber;
    this.subscriptionSearchResultsComponent?.clearFilters();

    await this.subscriptionSearchService.searchSubscriptions(Mode.View, searchOperator);

    // Auto populate the account number and contact numbers.
    if (this.subscriptionSearchService.subscriptions !== null) {
      if (this.subscriptionSearchService.subscriptions.subscriptionSearchResults.length > 0) {
        this.accountNumber = this.subscriptionSearchService.subscriptions.subscriptionSearchResults[0].accountId.toString();
        if (this.orderId !== '') {
          this.contactId = this.subscriptionSearchService.subscriptions.subscriptionSearchResults[0].contactId?.toString() ?? '';
        }
      }
    }

    this.showResults = true;
  }



  clearSubNumAndItemNum(): void {
    if (!this.accountNumber && !this.contactId && !this.orderId) {
      this.subscriptionNumber = '';
      this.itemNumber = '';
    }
  }
}
