<div [ngClass]="panelBackground" class="panel">
    <uitk-panel uitkId="orderSearchPanel" [collapsible]="collapsiblePanel" [open]="openPanel"
                (openChange)="setPanelBGHeight()">
      <uitk-panel-header-title>
        <h2>Tokenize</h2>
      </uitk-panel-header-title>
      <uitk-panel-content>
        <uitk-banner-notification uitkId="demoId" variant="warning" [show]="true">
            <h1 uitkBannerHeading>
                Use keypad to enter the CC number.
            </h1>
          </uitk-banner-notification>
          <br>
        <div class="uitk-l-grid__row">
          <div class="uitk-l-grid__col">
            <div class="uitk-l-grid__col--3">
              <label uitkLabel>CC Type</label>
            </div>
            <div class="uitk-l-grid__col--6">
              <uitk-form-field>
                <uitk-select [disabled]="true" uitkId="payMethod" name="payMethodInput" #poNumberInput="ngModel" [defaultLabel]="defaultLabel"
                             [defaultLabelFlag]="true" [itemList]="payMethodList" [(ngModel)]="payMethod"></uitk-select>
              </uitk-form-field>
            </div>
          </div>
          <div class="uitk-l-grid__col uitk-u-flex__align--center">
            <div class="uitk-l-grid__col--3">
              <label uitkLabel>CC#</label>
            </div>
            <div class="uitk-l-grid__col--6">
              <uitk-form-field>
                <input appAutoFocus id="ccNumber" placeholder="Use key pad" uitkInput [(ngModel)]="ccNumber" #ccNumInput="ngModel" #ccInput
                       name="ccNumber" (ngModelChange)="onCCChange($event)" [required]="true" />
                <uitk-form-field-error *ngIf="showTokenError">
                  Please enter a valid input
                </uitk-form-field-error>
              </uitk-form-field>
            </div>
          </div>
          <div class="uitk-l-grid__col uitk-u-flex__align--right">
            <div class="uitk-l-grid__col--3">
              <label uitkLabel>Exp Date</label>
            </div>
            <div class="uitk-l-grid__col--6">
              <uitk-form-field>
                <input uitkInput id="subscriptionNumber" disabled class="textbox" type="text" value="" [(ngModel)] ="expDate" />
              </uitk-form-field>
            </div>
          </div>
        </div>
        <div class="uitk-l-grid__row ">
          <div class="uitk-l-grid__col">
            <div class="uitk-l-grid__col--3">
              <label uitkLabel>Token</label>
            </div>
            <div class="uitk-l-grid__col--6 ">
              <uitk-form-field>
                <input disabled uitkInput id="itemNumber" class="textbox" type="text" value="" [(ngModel)]="token"/>
              </uitk-form-field>
            </div>
          </div>
          <div class="uitk-l-grid__col"></div>
          <div class="uitk-l-grid__col"></div>
        </div>
        <div id="searchDiv" class="uitk-l-grid__row ">
            <div class="uitk-u-flex__align--left">
              <button id="searchBtn" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--lg" (click)="clear()">
                Clear Data
              </button>
            </div>
          </div>
      </uitk-panel-content>
    </uitk-panel>
  </div>
  
  
