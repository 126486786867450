import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { InvoiceDetailsDto } from '../dtos/invoice-details-dto.model';
import { ConfigService } from './config.service';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root'
})

export class InvoicesService {
  hasCCMemo = false;
  getInvoicesEmitterForOrderId = new Subject<InvoiceDetailsDto[]>();
  invoiceResults: InvoiceDetailsDto[] | undefined;
  invoiceResultsWithFilter: InvoiceDetailsDto[] = [];
  configIsReadySubscription: Subscription | undefined;
  private readonly _httpClient: HttpClient;
  private readonly _configService: ConfigService;
  invoiceDetailsCache!: InvoiceDetailsDto[] | null;
  invoiceDetailsCachedObservable!: Observable<InvoiceDetailsDto[]> | null;
  swalAlert = new SwalAlert();

  constructor(httpClient: HttpClient, configService: ConfigService) {
    this._httpClient = httpClient;
    this._configService = configService;
  }

  getInvoicesWithOrderId(orderId: number, selectedVal: string): void {
    this.invoiceResultsWithFilter = [];
    let observable: Observable<InvoiceDetailsDto[]>;

    if (this.invoiceDetailsCache) {
      observable = of(this.invoiceDetailsCache);
    }
    else if (this.invoiceDetailsCachedObservable) {
      observable = this.invoiceDetailsCachedObservable;
    }
    else {
      this.invoiceDetailsCachedObservable = this._httpClient.get<InvoiceDetailsDto[]>(`${this._configService.accountingApiUrl}/invoices/${orderId}`)
        .pipe(
          tap((response: InvoiceDetailsDto[]) => {
            this.invoiceDetailsCache = response;
          }),
          share(),
          finalize(() => {
            this.invoiceDetailsCache = null;
            this.invoiceDetailsCachedObservable = null;
          })
        )

      observable = this.invoiceDetailsCachedObservable;
    }

    observable.subscribe((data: InvoiceDetailsDto[]) => {
      this.invoiceResults = data;

      if (selectedVal === 'All') {
        this.invoiceResultsWithFilter = [...this.invoiceResults];
      }
      else {
        this.invoiceResults.forEach(element => {
          if (element.balance !== 0) {
            this.invoiceResultsWithFilter.push(element);
          }
        });
      }

      this.getInvoicesEmitterForOrderId.next(this.invoiceResultsWithFilter);
    }, (error: any) => {
      console.log(error);
      this.swalAlert.alert('An error occurred while getting the invoice details.');
    });
  }
}
