import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { ItemDetailDto } from 'src/app/dtos/item-details-dto.model';
import { OfferDto } from 'src/app/dtos/offer-dto.model';
import { OpportunityDto } from 'src/app/dtos/opportunity-dto.model';
import { OrderDto } from 'src/app/dtos/order-dto.model';
import { OrderOfferInputDto } from 'src/app/dtos/order-offer-input-dto.model';
import { OrderOfferType } from 'src/app/dtos/order-offer-type.enum';
import { TierDto } from 'src/app/dtos/tier-dto.model';
import { ShippinghandlingService } from '../../../services/shippinghandling.service';
import { Mode } from '../../../enums/mode.enum';
import { Dialog } from '../../../helpers/dialog';
import { ItemOfferDto } from '../../../dtos/item-offer-dto';
import { ItemOfferInputDto } from '../../../dtos/item-offer-input-dto.model';
import { KeyCodeDto } from '../../../dtos/key-code-dto.model';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { ProductDto } from '../../../dtos/product-dto.model';
import { ApprovalService } from '../../../services/approval.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { ConfigService } from '../../../services/config.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';
import { SubscriptionPriceCalculationComponent } from '../../order-inquiry/subscription-price-calculation/subscription-price-calculation.component';
import { SwalAlert } from '../../../helpers/alert';
import { AccountPricingDto } from 'src/app/dtos/account-pricing-dto.model';

@Component({
  selector: 'app-order-details-grid',
  templateUrl: './order-details-grid.component.html',
  styleUrls: ['./order-details-grid.component.css'],
  providers: [SubscriptionPriceCalculationComponent]
})

export class OrderDetailsGridComponent implements OnInit {
  private readonly _configService: ConfigService;
  public readonly _pricingService: PricingService;
  private readonly _authorizationService: AuthorizationService;
  private readonly _elementRef: ElementRef;
  private readonly _activatedroute: ActivatedRoute;
  private readonly _subscriptionPriceCalucationComponent: SubscriptionPriceCalculationComponent;
  private readonly _approvalService: ApprovalService;
  private readonly _shippinghandlingService: ShippinghandlingService;
  columns = [
    { title: '', start: 0, width: 40, frozen: true },
    { title: '#', start: 0, width: 40, frozen: true },
    { title: 'Item #', start: 120, width: 140, frozen: true },
    { title: 'Item Description', start: 260, width: 350, frozen: true },
    { title: 'Order Qty', start: 610, width: 100, frozen: true },
    { title: 'List Price', start: 0, width: 100, frozen: false },
    { title: 'Discount', frozen: false },
    { title: 'Unit Price', frozen: false },
    { title: 'Ext Price', frozen: false },
    { title: 'Req Date', frozen: false },
    { title: 'AP $', frozen: false },
    { title: 'AP %', frozen: false },
    { title: 'FP $', frozen: false },
    { title: 'Tax Amt', frozen: false },
    { title: 'Avail Date', frozen: false },
    { title: 'Term', frozen: false },
    { title: 'On Hand', frozen: false }
  ];

  orderQuantitySum = 0;
  @ViewChild("orderNoteOkBtn") orderNoteOkBtn!: ElementRef<HTMLInputElement>;
  @ViewChild("dupWebAppOkBtn") dupWebAppOkBtn!: ElementRef<HTMLInputElement>;
  @ViewChild("dupItemOkBtn") dupItemOkBtn!: ElementRef<HTMLInputElement>;
  @ViewChild("quantityCheckOkBtn") quantityCheckOkBtn!: ElementRef<HTMLInputElement>;
  @Output() addedShippingItem = new EventEmitter();
  @Output() adjustedLineItem = new EventEmitter<OrderDetailDto>();
  @Output() changePayMeth = new EventEmitter<any>();
  @Output() changePromoCode = new EventEmitter<any>();
  @Output() addedPremiumShippingItem = new EventEmitter();
 
  get filteredOrderDetails(): OrderDetailDto[] {
    return this.orderService.orderDto.orderDetailDtos.filter(item => item.voidReasonCode !== 'D');
  }

  orderDetailDtoBackupForSub: OrderDetailDto = new OrderDetailDto();
  myNotificationWarningContent = "This item is inactive or does not exist. Please enter a new item #";
  dupNotificationForWebApps = "You can only add ONE line item for the same Web Fulfilled product per order";
  addOnWarningContent = "Add-ons cannot be added as an item #";
  addPermissionContent = "You do not have permission to add this item. Please contact Order Management";
  visibleTextValue = this.modeService.mode === Mode.Wait ? "" : "Saving Data";
  Mode = Mode;
  configSetDialog = new Dialog<string>();
  orderOfferQualificationDialog = new Dialog<string>();
  itemChangedPopup = new Dialog<string>();
  selectedOrderDetail: OrderDetailDto = new OrderDetailDto();
  selectedOrderDetailDto: any;
  configurationOrderDetail: OrderDetailDto = new OrderDetailDto();
  currentOrderDetailId: any;
  setFocusToQuantityAfterPopup = false;
  previousOrderQuantity = 0;
  havePermission = false;
  isAddOn = false;
  maxDiscount: number = 0.0;
  selectedType: string = '';
  shippingProductCodes: string = '';
  quantityCheckModal = { show: false }
  voidItemDialog = { show: false }
  itemComment = { show: false, text: '' }
  dupWebApps = { show: false }
  dupProduct = { show: false }
  showNoItemExists = { show: false }
  voidDepositDialogModal = { show: false };
  showItemLevelOffers = new Dialog<void>();
  associatedPremiumsList = '';
  oldUnitPrice = 0.00;
  oldOrderQuantity = 1;
  requiredDateValidationMessage = '';
  showRequiredDateValidation = false;
  requiredDateValidationIndex = 0;
  orderIsReadySubscription: Subscription | undefined;
  configIsReadySubscription: Subscription | undefined;
  sourceCodeList: IUITKSelectItemProps[] = [];
  leadSourceList: IUITKSelectItemProps[] = [];
  sourceCode: IUITKSelectItemProps | null | undefined;
  productId: number = 0;
  promoCode: string = '';
  showItemSearchDialog = { show: false };
  itemSearchDialog = false;
  itemOfferDto: ItemOfferDto = new ItemOfferDto();
  keyCodeDto: KeyCodeDto = new KeyCodeDto();
  configSetParentOrderDetailId: number = 0;
  offerType = '';
  productCode = '';
  productType = '';
  opportunityId = '';
  triggerElement = "cancel";
  swalAlert = new SwalAlert();

  constructor(configService: ConfigService,
    public orderService: OrderService,
    pricingService: PricingService,
    authorizationService: AuthorizationService,
    public modeService: ModeService,
    elementRef: ElementRef,
    activatedroute: ActivatedRoute,
    subscriptionPriceCalucationComponent: SubscriptionPriceCalculationComponent,
    approvalService: ApprovalService, 
    shippinghandlingService: ShippinghandlingService) {
    this._configService = configService;
    this._pricingService = pricingService;
    this._authorizationService = authorizationService;
    this._elementRef = elementRef;
    this._activatedroute = activatedroute;
    this._subscriptionPriceCalucationComponent = subscriptionPriceCalucationComponent;
    this._approvalService = approvalService;
    this._shippinghandlingService = shippinghandlingService;
  }

  ngOnInit(): void {
    this.addNewLineItem();
    this.triggerElement = 'cancel';
    this._activatedroute.queryParams.subscribe(params => {
      this.orderService.orderDto.promotionCode = params.promoCode;
      this.orderService.orderDto.sourceCode = params.leadSource;
      this.opportunityId = params.OppId;
      this.configIsReadySubscription = this._configService.configIsReady.subscribe(() => {

        if (params.OppProd == 'Yes') {
          this.addOpportunitiesProduct();
        }
        this.shippingProductCodes = Array.prototype.map.call(this._configService.reference?.productDtos
          .filter(p => p.classCode === 'SH' && p.productCode !== '0052'), function (item) {
            return item.productCode;
          }).join(',');
        this.sourceCodeList = this._configService.getPickList('SOURCE');
        this.leadSourceList = this._configService.getPickList('LEADSOURCE');
        this.sourceCode = null;
        if(this.orderService.orderDto.sourceCode)
        {
          const sourceCode = this.leadSourceList.filter(i => i.value.toLowerCase() === this.orderService.orderDto.sourceCode?.toLowerCase())[0];
          if (sourceCode) {
            this.sourceCode = { id: sourceCode.id ?? '', label: sourceCode.label ?? '', value: sourceCode.label ?? '' };
          }
        }
        this.configIsReadySubscription?.unsubscribe();
      });
    });

    this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
      this.sourceCode = { id: this.orderService.orderDto.sourceCode ?? '', label: this.orderService.orderDto.sourceCode ?? '', value: this.orderService.orderDto.sourceCode ?? '' };

      if (this.orderService.orderDto.orderDetailDtos.length > 0 && this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0).length === 0
        && this.modeService.mode === Mode.Edit) {
        this.addNewLineItem();
        this.sumOrderQuantity();
      }

      this.enableOrDisableMuliyearButton();
      
      if (this.orderService.orderDto.promotionCode && this.orderService.orderDto.sourceCode !== this.orderService.orderDto.promotionCode) {
        this.getOfferInfo();
      }

      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      
    });
  }

  addOpportunitiesProduct() {
    this.orderService.getOpportunitiesProducts(this.opportunityId).subscribe(
      async (data: OpportunityDto) => {
        if(data.excludedItems.length > 0){
          let items = data.excludedItems.split(";");
          let itemsToDisplay = "";
          items.forEach(element => {

            let productDto = this._configService.reference?.productDtos?.filter(item => item.id === Number(element))[0];
           if(productDto?.productCode !== undefined){
            itemsToDisplay += productDto?.productCode +", ";
           }
            
          });
          itemsToDisplay= itemsToDisplay.substring(0, itemsToDisplay.length-2);
         await this.swalAlert.alert(`Item(s) ${itemsToDisplay} must be manually added to the cart and configured`); 
        }
        if (data.opportunityProducts.length > 0) {

     
          data.opportunityProducts.forEach(item => {
            const newOrderDetail = this.orderService.createNewOrderDetail(this.orderService.orderDto, item.productCode);
            newOrderDetail.orderQuantity = item.quantity;
            newOrderDetail.openQuantity = item.quantity;
            newOrderDetail.discount = item.discount;
            newOrderDetail.orderDetailStatus = ' ';
            newOrderDetail.itemEditable = false;
            this.orderService.orderDto.orderDetailDtos.push(newOrderDetail);
            this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
          });
          if(this.orderService.orderDto.orderDetailDtos.some(item => item.productDto.salesOrganization !== '')){
           this.orderService.orderDto.salesOrganization = this.orderService.orderDto.orderDetailDtos.filter(item => item.productDto.salesOrganization !== '')[0].productDto.salesOrganization;
          }
          this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item.productId !== 0);
          this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
          this.addNewLineItem();
        }
      }
    );
  }

  sumOrderQuantity(): void {
    this.orderService.orderDto.totalQuantity = this.orderService.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus !== 'V')
      .map(o => o.orderQuantity).reduce((prev, next) => prev + next, 0);
  }

  onChangePromoCode() {
    if (this.orderService.orderDto.billToDto.id === 0 || this.orderService.orderDto.billToDto.id === null) {
      this.swalAlert.alert("Account information details should be populated before entering promo code");
      this.orderService.orderDto.promotionCode = null;

      setTimeout(() => {
        (document.getElementById("accountId") as HTMLElement).focus();
      });
    
      return;
    }


    if (this.orderService.orderDto.sourceCode?.trim() !== this.orderService.orderDto.promotionCode?.trim() &&
      this.orderService.orderDto.promotionCode?.trim() !== '') {
      this.changePromoCode.emit();
      this.getOfferInfo();
    }

 
    const orderDetail = this.filteredOrderDetails[0];
    
    if (this.filteredOrderDetails.length <= 1) {
      setTimeout(() => {
        if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`)) {
          this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`).focus();
        }
      });
    }
    else if (orderDetail.productDto?.hasMaxQuantityOne && orderDetail.productDto?.classCode !== 'SH') {
      this.clickConfiguration(orderDetail);
    }
    else {
      setTimeout(() => {
        if (this._elementRef.nativeElement.querySelector(`input[id=orderQuantity-${orderDetail.id}]`)) {
          this._elementRef.nativeElement.querySelector(`input[id=orderQuantity-${orderDetail.id}]`).focus();
        }
      });
    }
  }

  async getOfferInfo() {
    const data = await this.orderService.getOfferInfoAsync(this.orderService.orderDto.promotionCode ?? '');
    this.orderService.orderDto.offerInfoDto = data;
    this.orderService.orderDto.offerInfoDto!.promoCode = this.orderService.orderDto.promotionCode!;

    this.orderService.getOfferStatus(this.orderService.orderDto.promotionCode).subscribe((offerDto: OfferDto) => {
      if (offerDto.promotionHtml) {
        this.orderService.orderDto.promotionHtml = offerDto.promotionHtml;
      }
    });
  }

  onChangeSourceCode(event: any) {
    if (this.orderService.orderDto.billToDto.id === 0 || this.orderService.orderDto.billToDto.id === null) {
      this.sourceCode = null;
      this.swalAlert.alert('Account information details should be populated before selecting source code');

      setTimeout(() => {
        (document.getElementById("accountId") as HTMLElement).focus();
      });

      return;
    }

    if (this.orderService.orderDto.promotionCode === '' || this.orderService.orderDto.promotionCode === null || this.orderService.orderDto.promotionCode === undefined
      || (this.orderService.orderDto.promotionCode === this.orderService.orderDto.sourceCode || !this.orderService.orderDto.sourceCode)) {
      if (!event || event.value === "") {
        this.orderService.orderDto.promotionCode = event.value;
      }
      else {
        this.orderService.orderDto.promotionCode = event.label;
      }     
      this.orderService.orderDto.promotionHtml = '';
    }
    if (!event || event.value === "") {
      this.orderService.orderDto.sourceCode = event === null ? event : event.value;
    }
    else {
      this.orderService.orderDto.sourceCode = event.label;
    }
  }

  onChangeListCode(): void {
    if (this.orderService.orderDto.billToDto.id === 0 || this.orderService.orderDto.billToDto.id === null) {
      this.swalAlert.alert('Account information details should be populated before entering list code');
      this.orderService.orderDto.listCode = null;

      setTimeout(() => {
        (document.getElementById("accountId") as HTMLElement).focus();
      });

      return;
    }

    if (this.orderService.orderDto.listCode) {
      this.orderService.orderDto.promotionCode = this.orderService.orderDto.listCode
      
    }

    this.changePromoCode.emit();
  }

  async addProductCodeFromDialog(event: any): Promise<void> {
    const len = this.orderService.orderDto.orderDetailDtos.length;

    if (this.orderService.orderDto.orderDetailDtos[len - 1].productId === 0) {
      this.orderService.orderDto.orderDetailDtos.splice(len - 1, 1);
    }

    const newOrderDetail = this.orderService.createNewOrderDetail(this.orderService.orderDto, event);
    newOrderDetail.orderQuantity = 1;
    newOrderDetail.openQuantity = 1;
    newOrderDetail.orderDetailStatus = ' ';
    newOrderDetail.itemEditable = false;
    this.orderService.orderDto.orderDetailDtos.push(newOrderDetail);
    const orderDetail = this.orderService.orderDto.orderDetailDtos[len - 1];
    await this.onChangeProductCode(null, newOrderDetail.productCode, orderDetail, len);
    this.sumOrderQuantity();
  }

  itemSearch() {
    this.showItemSearchDialog.show = true;
    this.itemSearchDialog = this.showItemSearchDialog.show;
  }

  async onChangeProductCode(e: any, productCode: string, orderDetail: OrderDetailDto, index: number): Promise<void> {
    if (!this._configService.reference) {
      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.sumOrderQuantity();
      return;
    }

    if (this.orderService.orderDto.billToDto.id === 0 || this.orderService.orderDto.billToDto.id === null) {
      this.resetProductCode(productCode,orderDetail);
      return;
    }

    productCode=this.getProductCode(e,productCode);
  
    orderDetail.productDto = this._configService.reference?.productDtos.filter(p => p.productCode === productCode.toUpperCase())[0];   
    if(!await this.handleProductChecks(e,orderDetail,productCode,index)){
      return;
    } 
    this.currentOrderDetailId = orderDetail.id;
    orderDetail.productCode = orderDetail.productCode.toUpperCase().trim();
    const allowedShipToCountries = ['US', 'PR', 'VI', 'GU', 'MP', 'AS'];
    const allowedIndiaAndPhilipines = ['IN', 'PH'];
    if (orderDetail.productDto.salesOrganization === 'Shutterfly' && !allowedShipToCountries.includes(this.orderService.orderDto.shipToDto.country)) {
      if (this._authorizationService.hasResource('AllowShiptoToForeignCountries')) {
        if (!allowedIndiaAndPhilipines.includes(this.orderService.orderDto.shipToDto.country)) {
          await this.swalAlert.alert('Shipments to foreign countries of print product is no longer allowed');
          this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
          this.addNewLineItem();
          this._approvalService.refreshApprovalFields(this.orderService.orderDto);
          this.orderService.recalculateFields(this.orderService.orderDto);
          setTimeout(() => {
            if (document.querySelector('.order-summary-section')) {
              let d = document.querySelector('.order-summary-section');
              d?.scrollIntoView({behavior: 'smooth', inline: 'nearest', block: 'end'});
            }
          }, 500);
          return;
        }
      } else {
        await this.swalAlert.alert('Shipments to foreign countries of print product is no longer allowed');
        this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
        this.addNewLineItem();
        this._approvalService.refreshApprovalFields(this.orderService.orderDto);
        this.orderService.recalculateFields(this.orderService.orderDto);
        setTimeout(() => {
          if (document.querySelector('.order-summary-section')) {
            let d = document.querySelector('.order-summary-section');
            d?.scrollIntoView({behavior: 'smooth', inline: 'nearest', block: 'end'});
          }
        }, 500);
        return;
      }
    }
    
  }
  getProductCode(e: any, productCode: string):string{
    if (!productCode) {
      productCode = e.srcElement.value;
    }
    return productCode;
  }
  async handleProductChecks(e: any, orderDetail: OrderDetailDto, productCode: string, index: number): Promise<boolean> {
    if (!orderDetail.productDto) { 
      await this.handleInvalidProductCode(orderDetail);
      return false;
    }

    if (this.orderService.orderDto.orderDetailDtos.some(item => item.productCode === '0010' && item.orderDetailStatus === ' ' && item.id > 0)
      && orderDetail.productCode === '0010') {
      await this.handleDuplicateDeposit(orderDetail);
      return false;
    }

    const product = orderDetail.productDto?.statusCode !== 'I';
    const addOn = orderDetail.productDto?.inventoryType !== 'V' && orderDetail.productDto?.inventoryType !== 'G';
    const isShipping = orderDetail.productDto?.classCode === 'SH';
    const doesWebAppAlreadyExist = this.resultWebAppAlreadyExist(e,orderDetail,productCode);
    this.checkProduct(product,orderDetail);
    
    await this.checkhasResource(orderDetail);
    await this.validateSalesOrganization(orderDetail);

    this.ischeckdoesWebAppAlreadyExist(doesWebAppAlreadyExist,orderDetail);
   
    
    await this.checkshowSalesOrderNote(productCode,isShipping,orderDetail);
    this.checkaddOn(addOn,orderDetail,productCode,index);
    return true;
  }
  checkProductCode(index:number,orderDetail: OrderDetailDto, productCode: string) {
    if (productCode.toUpperCase() === '0010') {
      this.changePayMeth.emit();
    }

    if (orderDetail.productDto?.classCode === 'SH') {
      this.addedShippingItem.emit();
    }
    else {
      this.adjustedLineItem.emit(orderDetail);
    }
    this.setTimeoutOrderQuality(orderDetail);
   
    this.orderService.recalculateFields(this.orderService.orderDto);
    this.sumOrderQuantity();
    this.productCodeExist(index,orderDetail);
    this.enableOrDisableMuliyearButton();
  }
  async cashReceiptBR(orderDetail: OrderDetailDto,index: number,productCode: string) {
    this.maxDiscount = orderDetail.productDto?.maxDiscountPercent;
    const cashReceiptBR = this.orderService.cashReceiptBR(orderDetail.productCode, this.orderService.orderDto.paymentMethod, this.orderService.orderDto.paymentPlan);

    if (cashReceiptBR === true) {
      await this.swalAlert.alert('Cash Receipts are not allowed on a payment plan');
    }

    orderDetail.productDescription = orderDetail.productDto?.description;
    orderDetail.productId = orderDetail.productDto?.id;
    orderDetail.openQuantity = 1;
    orderDetail.discount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, orderDetail.productDto?.id);
    
    if (!this.orderService.orderDto.hasUserSeenPromoForm || this.orderService.orderDto.promotionOfferPopupButton !== 'Accept Promo Offer') {
      orderDetail.discountBeforeApplyingPromoOffer = orderDetail.discount;  
    }
    //update the order DetailDto
    this.updatetheorderDetail(orderDetail);
    this.checktheOfferInfoDto(orderDetail);
   
    if (orderDetail.productDto !== null) {
      this.orderService.getKeyCodeFromService();
    }

    this.orderService.paymentPlanOrderEntryBR();
    this.checkProductCode(index,orderDetail,productCode);
    
  }
  isExistsItemCommentshow(productCode: string,isShipping: boolean) {
    const isExists = (this.orderService.orderDto.orderDetailDtos.filter(o => o.productCode.toUpperCase() === productCode.toUpperCase() && o.orderDetailStatus !== 'V').length > 1);

    if (isExists && !isShipping) {
      this.dupProduct.show = true;
      if (!this.itemComment.show) {
        setTimeout(() => {
          this.dupItemOkBtn.nativeElement.focus();
        }, 60);
      }
    }     
  }
  async checkhasResource(orderDetail: OrderDetailDto) {
    if (this._authorizationService.hasResource('TucsonAllowPrintProducts') && !this._authorizationService.hasResource('TucsonAllowElectronicProducts')) {
      await this.isUnauthorizedProduct(orderDetail);
     
    }
    else if (!this._authorizationService.hasResource('TucsonAllowPrintProducts') && this._authorizationService.hasResource('TucsonAllowElectronicProducts')) {
      await this.isnotUnauthorizedProduct(orderDetail);
    }
  }
  async checkPaymenttypeAndClassCode(orderDetail: OrderDetailDto) {
    if (orderDetail.productDto?.classCode === 'XS' && this.orderService.orderDto.paymentPlan !== 'OneTime') {
      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.sumOrderQuantity();
      await this.swalAlert.alert("Coupons are not allowed on an order that is setup with a Payment Plan. The coupon item has been removed from the order.");
      this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item.productDto.classCode !== 'XS');
      this.addNewLineItem();

      setTimeout(() => {
        if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${orderDetail.id}]`)) {
          this._elementRef.nativeElement.querySelector(`input[id=productCode-${orderDetail.id}]`).focus();
        }
      });

      return;
    }
  }
  async checkrequiresAmaKey(orderDetail: OrderDetailDto) {
    if (orderDetail.productDto?.requiresAmaKey === true && !this._authorizationService.hasResource('OrderInquiryAdditionalActionsAMAKeyButton')) {
      this.showNoItemExists.show = true;
      this.havePermission = true;
      orderDetail.productCode = '';
      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.sumOrderQuantity();

      setTimeout(() => {
        if (this._elementRef.nativeElement.querySelector(`button[id=btnShowNoItemExistsOk]`)) {
          this._elementRef.nativeElement.querySelector(`button[id=btnShowNoItemExistsOk]`).focus();
        }
      });

      return;
    }

    if (orderDetail.productCode !== '0010') {
      if (!(await this.inventoryCheckBR(orderDetail.productDto))) {
        orderDetail.orderQuantity = 1;

        setTimeout(() => {
          if (this._elementRef.nativeElement.querySelector(`input[id=orderQuantity-${orderDetail.id}]`)) {
            this._elementRef.nativeElement.querySelector(`input[id=orderQuantity-${orderDetail.id}]`).focus();
          }
        }, 500);
      }
    }

  }
  async checkaddOn(addOn: boolean, orderDetail: OrderDetailDto,productCode:string,index:number) {
    if (!addOn) {
      this.showNoItemExists.show = true;
      this.isAddOn = true;
      this.havePermission = false;
      this.deleteItem(orderDetail);
      this.addNewLineItem();
      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.sumOrderQuantity();

      setTimeout(() => {
        if (this._elementRef.nativeElement.querySelector(`button[id=btnShowNoItemExistsOk]`)) {
          this._elementRef.nativeElement.querySelector(`button[id=btnShowNoItemExistsOk]`).focus();
        }
      });

      return;
    }
    orderDetail.productDto.configSetDtos = [...orderDetail.productDto.configSetDtos];
    orderDetail.isMaxQuantityOne = orderDetail.productDto?.hasMaxQuantityOne;

    if (orderDetail.productDto?.hasMaxQuantityOne && orderDetail.productDto?.classCode !== 'SH' && orderDetail.productDto?.classCode !== 'SH') {
      this.clickConfiguration(orderDetail);
    }

    if (productCode.trim() === '') {
      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.sumOrderQuantity();
      return;
    }
    await this.cashReceiptBR(orderDetail,index,productCode);
   
  }
  productCodeExist(index:number,orderDetail: OrderDetailDto) {
    if (this.orderService.orderDto.orderDetailDtos[index - 1].productCode) {
      const lastOrderDetailProductCode = this.orderService.orderDto.orderDetailDtos[this.orderService.orderDto.orderDetailDtos.length - 1].productCode;
      if(lastOrderDetailProductCode !== '0' && lastOrderDetailProductCode !== ''){
        this.addNewLineItem(); 
      }        
      this._approvalService.refreshApprovalFields(this.orderService.orderDto);

      setTimeout(() => {
        if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${orderDetail.id}]`)) {
          this._elementRef.nativeElement.querySelector(`input[id=productCode-${orderDetail.id}]`).disabled = true;
        }
      });
    }
  }
  resultWebAppAlreadyExist(e:any,orderDetail: OrderDetailDto,productCode:string):boolean {
    return  this.showItemSearchDialog.show ? this.orderService.orderDto.orderDetailDtos
    .filter(o => o !== orderDetail && o.orderDetailStatus !== 'V' && o.productDto.inventoryType === 'S' && o.productCode.toUpperCase() === productCode.toUpperCase()).length > 1
    : this.orderService.orderDto.orderDetailDtos.filter(o => o !== orderDetail && o.orderDetailStatus !== 'V' && o.productDto.inventoryType === 'S'
      && o.productCode.toUpperCase() === e.srcElement.value.toUpperCase()).length > 0;
  }
  checkProduct(product: boolean,orderDetail: OrderDetailDto) {
    if (!product) {
      this.showNoItemExists.show = true;
      this.havePermission = false;
      this.isAddOn = false;
      this.deleteItem(orderDetail);
      this.addNewLineItem();
      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.sumOrderQuantity();

      setTimeout(() => {
        if (this._elementRef.nativeElement.querySelector(`button[id=btnShowNoItemExistsOk]`)) {
          this._elementRef.nativeElement.querySelector(`button[id=btnShowNoItemExistsOk]`).focus();
        }
      });
      return;
    }
  }
  setTimeoutOrderQuality(orderDetail: OrderDetailDto) {
    setTimeout(() => {
      if (this._elementRef.nativeElement.querySelector(`input[id=orderQuantity-${orderDetail.id}]`)) {
        this._elementRef.nativeElement.querySelector(`input[id=orderQuantity-${orderDetail.id}]`).focus();
      }
    }, 500);
  }
  async checktheOfferInfoDto(orderDetail: OrderDetailDto) {
    if (this.orderService.orderDto.offerInfoDto) {
      if (this.orderService.orderDto.offerInfoDto.orderLevelOffer) {
        const orderOfferInputDto = new OrderOfferInputDto();
        orderOfferInputDto.promotionCode = this.orderService.orderDto.promotionCode!;
        const itemDetailDto = new ItemDetailDto();
        itemDetailDto.productId = orderDetail.productDto?.id;
        itemDetailDto.quantity = orderDetail.orderQuantity;
        itemDetailDto.extendedPrice = orderDetail.extendedPrice;
        itemDetailDto.classCode = orderDetail.classCode ?? '';

        if (itemDetailDto.productId !== 0) {
          orderOfferInputDto.itemDetails.push(itemDetailDto);
        }

        this.orderService.orderDto.orderOfferDto = await this.orderService.buildOrderOfferAsync(orderOfferInputDto);
        const productQualifies = this.orderService.orderDto.orderOfferDto?.productIds.some(productId => productId === orderDetail.productId);

        if (productQualifies) {
          orderDetail.promotionEntryCode = 'OLT';
        }
        
       await this.checkForOfferQualification();
      }
      else {
        if (this.orderService.orderDto.offerInfoDto?.productIds.includes(orderDetail.productId)) {
          orderDetail.promotionEntryCode = 'ILT';
          this.offerType = 'item';
        }
      }
    }
  }
  updatetheorderDetail(orderDetail: OrderDetailDto) {
    orderDetail.listPrice = orderDetail.productDto?.listPrice;
     orderDetail.requiredDate = new Date(new Date().toDateString());
     orderDetail.orderDetailStatus = ' ';
     orderDetail.shippedQuantity = 0;
     orderDetail.voidedQuantity = 0;
     orderDetail.accountOwnerCode = this.orderService.orderDto.billToDto.accountOwnerCode;
     orderDetail.salespersonCode = this._configService.getPickList('SALESPERSON').filter(a => a.label === this.orderService.orderDto.selectedsalesPersonName)[0]?.id;
     orderDetail.classCode = orderDetail.productDto?.classCode;
     orderDetail.glCode = orderDetail.productDto?.glCode;
     orderDetail.isPhysical = orderDetail.productDto?.isPhysical;
     orderDetail.isElectronic = orderDetail.productDto?.isElectronic;
     orderDetail.isWebFulfilled = orderDetail.productDto?.isWebFulfilled;
     this.checkIfCustomPricingApplicable(orderDetail);
     this._pricingService.calculateUnitPrice(orderDetail);
     orderDetail.orderQuantity = orderDetail.openQuantity + orderDetail.shippedQuantity;
     this._pricingService.calculateExtendedPrice(orderDetail);
     orderDetail.itemEditable = false;
     this.orderService.updateAmaKeyButtonMode();
     this.productId = orderDetail.productId;
   }
  async checkshowSalesOrderNote(productCode:string,isShipping: boolean,orderDetail:OrderDetailDto) {
    const showSalesOrderNote = this._configService.reference?.productDtos.some(p => p.productCode === productCode.toUpperCase() && p.showSalesOrderNote);
    if (showSalesOrderNote) {
      const salesOrderNote = this._configService.reference?.productDtos.filter(p => p.productCode === productCode.toUpperCase())[0].salesOrderNote;

      if (salesOrderNote !== null && salesOrderNote!.length > 0) {
        this.itemComment.show = true
        this.itemComment.text = salesOrderNote!;
        this.setFocusToQuantityAfterPopup = true;

        setTimeout(() => {
          this.orderNoteOkBtn.nativeElement.focus();
        }, 60);
      }
    }
    this.isExistsItemCommentshow(productCode,isShipping);
   
    await this.checkPaymenttypeAndClassCode(orderDetail);
   
    await this.checkrequiresAmaKey(orderDetail);
    
  }
  ischeckdoesWebAppAlreadyExist(doesWebAppAlreadyExist: boolean,orderDetail: OrderDetailDto) {
    if (doesWebAppAlreadyExist) {
      this.dupWebApps.show = true;
      this.setFocusToQuantityAfterPopup = true;

      if (this.showItemSearchDialog.show) {
        this.stopDup();
      }
      else {
        orderDetail.productCode = '';
      }

      setTimeout(() => {
        this.dupWebAppOkBtn.nativeElement.focus();
      });

      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.sumOrderQuantity();
      return;
    }
  }
  async validateSalesOrganization(orderDetail: OrderDetailDto) {
    if (orderDetail.productDto?.description === '') {
      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.sumOrderQuantity();
      return;
    }
    switch (this.orderService.orderDto.orderDetailDtos[0].productDto.salesOrganization) {
      case 'Optum':
        this.orderService.orderDto.salesOrganization = 'Optum';

        if (orderDetail.productDto.salesOrganization === 'Shutterfly') {
          this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
          await this.swalAlert.alert(`You cannot add print product to the cart.`);
          this.addNewLineItem();

          setTimeout(() => {
            const newLineItemId = this.orderService.orderDto.orderDetailDtos[this.orderService.orderDto.orderDetailDtos.length - 1].id;

            if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${newLineItemId}]`)) {
              this._elementRef.nativeElement.querySelector(`input[id=productCode-${newLineItemId}]`).focus();
            }
          });

          this._approvalService.refreshApprovalFields(this.orderService.orderDto);
          this.sumOrderQuantity();
          return;
        }

        break;
      case "Shutterfly":
        this.orderService.orderDto.salesOrganization = 'Shutterfly';

        if (orderDetail.productDto.salesOrganization === 'Optum') {
          this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
          await this.swalAlert.alert(`You cannot add electronic product to the cart.`);
          this.addNewLineItem();

          setTimeout(() => {
            const newLineItemId = this.orderService.orderDto.orderDetailDtos[this.orderService.orderDto.orderDetailDtos.length - 1].id;

            if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${newLineItemId}]`)) {
              this._elementRef.nativeElement.querySelector(`input[id=productCode-${newLineItemId}]`).focus();
            }
          });

          this._approvalService.refreshApprovalFields(this.orderService.orderDto);
          this.sumOrderQuantity();
          return;
        }

        break;
    }
  }
  async isnotUnauthorizedProduct(orderDetail: OrderDetailDto) {
    if (orderDetail.productDto.salesOrganization === 'Shutterfly') {
        
      await this.swalAlert.alert(`You are not authorized to add item ${orderDetail.productCode} to the cart.`);
      const index = this.orderService.orderDto.orderDetailDtos.findIndex(o => o.id === orderDetail.id);

      if (index > -1) {
        this.orderService.orderDto.orderDetailDtos.splice(index, 1);
        this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
        this.addNewLineItem();
      }

      setTimeout(() => {
        const newLineItemId = this.orderService.orderDto.orderDetailDtos[this.orderService.orderDto.orderDetailDtos.length - 1].id;

        if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${newLineItemId}]`)) {
          this._elementRef.nativeElement.querySelector(`input[id=productCode-${newLineItemId}]`).focus();
        }
      });

      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.sumOrderQuantity();
      return;
    }
  }
  
  async handleDuplicateDeposit(orderDetail: OrderDetailDto) {
    this.deleteItem(orderDetail);
    this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    this.sumOrderQuantity();
    await this.swalAlert.alert('A deposit has already been entered on the order.  Use the config set to add or adjust deposits.');
  }
  async handleInvalidProductCode(orderDetail: OrderDetailDto) {
    await this.swalAlert.alert(`This item ${orderDetail.productCode} is invalid or does not exist. Please enter a new item #`);
      orderDetail.productCode = '';
      
      setTimeout(() => {
        if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${orderDetail.id}]`)) {
          this._elementRef.nativeElement.querySelector(`input[id=productCode-${orderDetail.id}]`).focus();
        }
      });
      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.sumOrderQuantity(); 
  }
  async isUnauthorizedProduct(orderDetail: OrderDetailDto) {
    if (orderDetail.productDto.salesOrganization === 'Optum') {
      await this.swalAlert.alert(`You are not authorized to add item ${orderDetail.productCode} to the cart.`);
      const index = this.orderService.orderDto.orderDetailDtos.findIndex(o => o.id === orderDetail.id);

      if (index > -1) {
        this.orderService.orderDto.orderDetailDtos.splice(index, 1);
        this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
        this.addNewLineItem();
      }

      setTimeout(() => {
        const newLineItemId = this.orderService.orderDto.orderDetailDtos[this.orderService.orderDto.orderDetailDtos.length - 1].id;

        if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${newLineItemId}]`)) {
          this._elementRef.nativeElement.querySelector(`input[id=productCode-${newLineItemId}]`).focus();
        }
      });

      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.sumOrderQuantity();
      return;
    }
  }
  resetProductCode(productCode:string,orderDetail:OrderDetailDto) {
    this.swalAlert.alert('Account information details should be populated before entering item#');
    productCode = '';
    orderDetail.productCode = '';
    const orderDetailDtosLength = this.orderService.orderDto.orderDetailDtos.length;

    if (this.orderService.orderDto.orderDetailDtos[orderDetailDtosLength - 1].productCode === '') {
      this.orderService.orderDto.orderDetailDtos.splice(orderDetailDtosLength - 1, 1);
    }

    if (this.orderService.orderDto.orderDetailDtos.length === 0) {
      this.addNewLineItem();
    }

    setTimeout(() => {
      (document.getElementById("accountId") as HTMLElement).focus();
    });

    this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    this.sumOrderQuantity();
  }
  
  async checkForItemLevelOfferQualification(orderDetail: OrderDetailDto){
    const itemOfferInputDto: ItemOfferInputDto = new ItemOfferInputDto();
    itemOfferInputDto.productId = orderDetail.productId;
    itemOfferInputDto.promotionCode = this.orderService.orderDto.promotionCode!;
    itemOfferInputDto.quantity = orderDetail.orderQuantity;
    
    this.itemOfferDto = await this.orderService.buildItemOfferAsync(itemOfferInputDto);
    let splDiscount = this._pricingService.getSplDiscount(this.orderService.orderDto, orderDetail.productId) ?? 0;
    if (this.itemOfferDto.percentDiscount) {
      if (this.itemOfferDto.discountAmount > splDiscount) {
        orderDetail.discount = this.itemOfferDto.discountAmount;
      }
      else {
        orderDetail.discount = splDiscount;
      }    
      this._pricingService.calculateUnitPrice(orderDetail);
    }
    else {
      let multiUserListPrice = orderDetail.productDto?.multiUserPricingDtos?.filter(item => orderDetail.orderQuantity >= item.startRange && orderDetail.orderQuantity <= item.endRange)[0].listPrice;
      orderDetail.listPrice = orderDetail.productDto?.multiUserPricingDtos.length > 0 ? multiUserListPrice : orderDetail.listPrice;
      let discount = this._pricingService.round((100 - (orderDetail.listPrice - this.itemOfferDto.discountAmount) / orderDetail.listPrice * 100), 10);
      if (discount > splDiscount) {
        orderDetail.unitPrice = orderDetail.listPrice - this.itemOfferDto.discountAmount;
        this._pricingService.calculateDiscount(orderDetail);
      }
      else {
        orderDetail.discount = splDiscount;
        this._pricingService.calculateUnitPrice(orderDetail);
      }
    }
    this._pricingService.calculateExtendedPrice(orderDetail);
  }
  async onChangeOrderQuantity(orderDetail: OrderDetailDto): Promise<void> {
    const currentOrderDetail: OrderDetailDto = this.orderService.orderDto.orderDetailDtos.filter(i => i.id === orderDetail.id)[0];
    if (this.orderService.orderDto.offerInfoDto?.productIds.includes(orderDetail.productId)) {
      if(orderDetail.id > 0 || (orderDetail.id < 0 && orderDetail.configSetSaved) && orderDetail.promotionEntryCode === 'ILT'){
        await this.checkForItemLevelOfferQualification(orderDetail);
      }
    }
    orderDetail.discount = currentOrderDetail.discount;
    this.selectedOrderDetailDto = orderDetail;
    const productDto = this._configService.reference?.productDtos?.filter(p => p.id === orderDetail.productId)[0];

    if (!productDto) {
      await this.handleInvalidProduct(orderDetail);
      return;
    }

    if (orderDetail.orderQuantity === 0) {
      await this.handleZeroQuantity(orderDetail);
      return;
    }

    if (await this.maxOrderQuantityOneBR(orderDetail, productDto)) {
      this.finalizeOrderQuantityChange();
      return;
    }
    if (this.orderService.orderDto.offerInfoDto?.orderLevelOffer) {
      if (orderDetail && orderDetail.promotionEntryCode === 'OLT' || this.orderService.orderDto.orderDetailDtos.some(o => o.promotionEntryCode === 'OLP')) {
        this._pricingService.calculateExtendedPrice(orderDetail);
        await this.checkForOfferQualification();
      }
    }

    this.selectedOrderDetailDto.openQuantity = this.selectedOrderDetailDto.orderQuantity;
    this.adjustedLineItem.emit(orderDetail);
    this.recalculateWebCfaLicensesBR(orderDetail);
    this.handleDockProductsQuantity();

  }
  handleDockProductsQuantity() {
    const dockProductsQuantity = this.calculateDockProductsQuantity()
    if (dockProductsQuantity >= 200) {
      this.checksystemComments();
    }
    else {
      if (this.orderService.orderDto.systemComments?.toLowerCase().includes('customer has a dock and can receive truck shipments')
      ||this.orderService.orderDto.systemComments?.toLowerCase().includes('no dock available, truck with lift gate and hand truck is required')
    ) {
        this.orderService.noDock = true;
        this.orderService.updateSystemComments();
      }
    }
    
    this.proceedNext();
    this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    this.sumOrderQuantity();
  }
  checksystemComments() {
    if (!this.orderService.orderDto.systemComments ||
      ((!this.orderService.orderDto.systemComments.toLowerCase().includes('customer has a dock and can receive truck shipments') &&
      this.orderService.orderDto.systemComments?.toLowerCase().includes('no dock available, truck with lift gate and hand truck is required')))) {
      this.quantityCheckModal.show = true;

      setTimeout(() => {
        this.quantityCheckOkBtn.nativeElement.focus();
      });

      this.proceedNext();
      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.sumOrderQuantity();
      return;
    } else {
      this.proceedNext();
      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
      this.sumOrderQuantity();
      return;
    }
  }
  calculateDockProductsQuantity(): number {
    return  this.orderService.orderDto.orderDetailDtos
    .filter(p => p.isPhysical && p.productDto.exemptShipping === false)
    .map(o => o.orderQuantity).reduce((prev, next) => prev + next, 0)
  }
  finalizeOrderQuantityChange() {
      this._approvalService.refreshApprovalFields(this.orderService.orderDto);
        this.sumOrderQuantity();
  }
  async handleZeroQuantity(orderDetail: OrderDetailDto) {
    orderDetail.orderQuantity = this.previousOrderQuantity;
    orderDetail.openQuantity = this.previousOrderQuantity;
    this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    this.sumOrderQuantity();
    await this.swalAlert.alert('Zero quantity is not allowed.')
    return;
  }
  async handleInvalidProduct(orderDetail: OrderDetailDto) {
    this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
    this.addNewLineItem();
    this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    this.sumOrderQuantity();
    await this.swalAlert.alert(`This item ${orderDetail.productCode} is invalid or does not exist. Please enter a new item #`);
    return;
  }
  onProductCodeBlur(orderDetail: OrderDetailDto): void {
    let productDto = this._configService.reference?.productDtos.filter(p => p.productCode === orderDetail.productCode.toUpperCase())[0];
    if (orderDetail.productCode.trim() === '') {
      setTimeout(() => {
        if (document.getElementById(`poNumberOE`)) {
          document.getElementById(`poNumberOE`)?.focus();
        }

      });
    }
    else if (!productDto) {
      setTimeout(() => {
        if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${orderDetail.id}]`)) {
          this._elementRef.nativeElement.querySelector(`input[id=productCode-${orderDetail.id}]`).focus();
        }
      });
    }
    else {
      setTimeout(() => {
        if (document.getElementById(`orderQuantity-${orderDetail.id}`)) {
          document.getElementById(`orderQuantity-${orderDetail.id}`)?.focus();
        }

      });
    }
  }

  onOrderQuantityBlur(orderDetail: OrderDetailDto): void {
    if (orderDetail.orderDetailStatus === 'C' && orderDetail.orderQuantity === 0) {
      setTimeout(() => orderDetail.orderQuantity = this.previousOrderQuantity);
    }
    if (orderDetail.id <= 0 && orderDetail.orderQuantity !== 0 && !orderDetail.configSetSaved){
      if (orderDetail.productDto?.classCode !== 'SH') {
        this.clickConfiguration(orderDetail);
      }
    }
    if (orderDetail.id > 0 && orderDetail.configSetExist) {
      this.clickConfiguration(orderDetail);
    }

    if (!orderDetail.configSetExist) {
      this.loadItemLevelOffersIfAny(orderDetail);
    }

    this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    this.sumOrderQuantity();
  }

  async loadItemLevelOffersIfAny(orderDetail: OrderDetailDto) {
    //item-level-offer popup will populated when item in grid matches to an item in offerInfo item aaray
    if (this.orderService.orderDto.offerInfoDto?.productIds.includes(orderDetail.productId)) {
      const itemOfferInputDto: ItemOfferInputDto = new ItemOfferInputDto();
      itemOfferInputDto.productId = orderDetail.productId;
      itemOfferInputDto.promotionCode = this.orderService.orderDto.promotionCode!;
      itemOfferInputDto.quantity = orderDetail.orderQuantity;

      this.itemOfferDto = await this.orderService.buildItemOfferAsync(itemOfferInputDto);
      this.itemOfferDto.premiums.forEach(element => {
        const productDto = this._configService.reference?.productDtos.filter(p => p.productCode === element.productCode)[0];    
        if(productDto?.classCode === 'SH'){
          this._shippinghandlingService.premiumShippingItem = element;
          this.addedPremiumShippingItem.emit();
        }  
      });
      
      this.selectedOrderDetail = orderDetail;    
      this.showItemLevelOffers.openAsync();
    }
  }
 
  adjustedLineItemDiscount(adjustedOrderDetail: OrderDetailDto | null) {
    if (adjustedOrderDetail) {
      this.onChangeDiscount(adjustedOrderDetail);
    }
  }

  recordPreviousQuantity(orderDetailDto: OrderDetailDto): void {
    // Record the curent record, used by other events.
    this.orderDetailDtoBackupForSub = orderDetailDto;
    this.selectedOrderDetail = orderDetailDto;
    // Record the previous quantity in case we have to roll it back.
    this.previousOrderQuantity = orderDetailDto.orderQuantity;
    this.oldOrderQuantity = orderDetailDto.id > 0 ? this.previousOrderQuantity : this.oldOrderQuantity;
    if (orderDetailDto.orderDetailStatus === 'C') {
      orderDetailDto.orderQuantity = 0;

      setTimeout(() => {
        const inputElement = document.getElementById(`orderQuantity-${this.selectedOrderDetail.id}`) as HTMLInputElement;

        if (inputElement) {
          inputElement.setSelectionRange(0, inputElement.value.length);
        }
      });
    }
  }

  async onChangeListPrice(orderDetail: OrderDetailDto): Promise<void> {
    if (this.orderService.orderDto.offerInfoDto?.orderLevelOffer) {
      if (orderDetail && orderDetail.promotionEntryCode === 'OLT' || this.orderService.orderDto.orderDetailDtos.some(o => o.promotionEntryCode === 'OLP')) {
        if (orderDetail.promotionEntryCode === 'OLP') {
          this.productCode = orderDetail.productCode;
          this.productType = 'Premium';
          await this.itemChangedPopup.openAsync();
        }

        orderDetail.promotionEntryCode = null;
        this._pricingService.calculateUnitPrice(orderDetail);
        this._pricingService.calculateExtendedPrice(orderDetail);
        this.orderService.recalculateFields(this.orderService.orderDto);
        this._approvalService.refreshApprovalFields(this.orderService.orderDto);
        await this.checkForOfferQualification();
      }
    }

    this._pricingService.calculateUnitPrice(orderDetail);
    this._pricingService.calculateExtendedPrice(orderDetail);
    this.orderService.recalculateFields(this.orderService.orderDto);
    this._approvalService.refreshApprovalFields(this.orderService.orderDto);
  }

  async displayItemChangedPopup(orderDetail: OrderDetailDto): Promise<void>{
    let associatedPremiumsList = this.orderService.orderDto.orderDetailDtos.filter(o => o.promotionOrderDetailId === orderDetail.id);
    if (orderDetail.promotionEntryCode === 'ILT' || orderDetail.promotionEntryCode === 'ILP') {
      if (associatedPremiumsList.length > 0) {
      }
      if (orderDetail.promotionEntryCode === 'ILT') {
        for (const orderDetailPremium of associatedPremiumsList) {
          orderDetailPremium.promotionOrderDetailId = null;
          orderDetailPremium.promotionEntryCode = null;
        }
        orderDetail.promotionEntryCode = null;
      }
      else {
        orderDetail.promotionOrderDetailId = null;
        orderDetail.promotionEntryCode = null;
      }
    }
  }

  async onChangeDiscount(orderDetail: OrderDetailDto): Promise<void> {
    this.productCode = orderDetail.productCode;
    
    if (orderDetail.promotionEntryCode === 'ILT') {
      this.productType = "Promotion";
    }
    else if (orderDetail.promotionEntryCode === 'ILP') {
      this.productType = "Premium";
    }
    
    this.displayItemChangedPopup(orderDetail);
    orderDetail.discount = this._pricingService.calculateMaxDiscount(orderDetail.discount, orderDetail.productId);

    if (!this.orderService.orderDto.hasUserSeenPromoForm || this.orderService.orderDto.promotionOfferPopupButton !== 'Accept Promo Offer') {
      orderDetail.discountBeforeApplyingPromoOffer = orderDetail.discount;
    }

    if (this.orderService.orderDto.offerInfoDto?.orderLevelOffer) {
      if (orderDetail && orderDetail.promotionEntryCode === 'OLT' || this.orderService.orderDto.orderDetailDtos.some(o => o.promotionEntryCode === 'OLP')) {
        if (orderDetail.promotionEntryCode === 'OLP') {
          this.productCode = orderDetail.productCode;
          this.productType = 'Premium';
          await this.itemChangedPopup.openAsync();
        }
        
        orderDetail.promotionEntryCode = null;
        this._pricingService.calculateUnitPrice(orderDetail);
        this._pricingService.calculateExtendedPrice(orderDetail);
        this.orderService.recalculateFields(this.orderService.orderDto);
        this._approvalService.refreshApprovalFields(this.orderService.orderDto);
        await this.checkForOfferQualification();
      }
    }

    this._pricingService.calculateUnitPrice(orderDetail);
    this._pricingService.calculateExtendedPrice(orderDetail);
    this.orderService.recalculateFields(this.orderService.orderDto);
    this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    this._approvalService.showApprovalHistoryOnQuote = false;
  }

  setNullToPromotionsObject(orderDetail:OrderDetailDto){
    if (orderDetail.promotionEntryCode === 'ILT') {
      for (const orderDetailPremium of this.orderService.orderDto.orderDetailDtos.filter(o => o.promotionOrderDetailId === orderDetail.id)) {
        orderDetailPremium.promotionOrderDetailId = null;
        orderDetailPremium.promotionEntryCode = null;
        orderDetail.promotionEntryCode = null;
      }
    }
    else {
      orderDetail.promotionOrderDetailId = null;
      orderDetail.promotionEntryCode = null;
    }
  }

  async onChangeUnitPrice(orderDetail: OrderDetailDto,directCallFlag:Boolean = false): Promise<void>  {
    this.productCode = orderDetail.productCode;

    if (orderDetail.promotionEntryCode === 'ILT') {
      this.productType = "Promotion";
    }
    else if (orderDetail.promotionEntryCode === 'ILP') {
      this.productType = "Premium";
    }
    
    this.displayItemChangedPopup(orderDetail);
    this.adjustUnitPriceForClassCodeXS(orderDetail);
    this.checkItIsCustomProduct(orderDetail);
    this.setListPriceIfZero(orderDetail);

    if (this.shouldRecalculateDiscount(orderDetail, directCallFlag)) {
        this.calculateAndSetDiscount(orderDetail);
    }

    if (this.orderService.orderDto.offerInfoDto?.orderLevelOffer) {
        await this.handleOrderLevelOffer(orderDetail);
    }

    this.finalizePricing(orderDetail);
  }

  checkItIsCustomProduct(orderDetail:OrderDetailDto){
    if (orderDetail.productDto?.allowCustomPrice) {
      orderDetail.listPrice = this._pricingService.calculateListPricing(this.orderService.orderDto, orderDetail) || orderDetail.listPrice;
    }
    if (orderDetail.productDto?.allowCustomStandard) {
      orderDetail.listPrice = orderDetail.unitPrice;
    }
  }
  onChangeRequiredDate(e: any, orderDetail: OrderDetailDto, index: number): void {
    if (e.srcElement.value === '') {
      this.showRequiredDateValidation = true;
      this.requiredDateValidationMessage = 'Enter Required Date';
      this.requiredDateValidationIndex = index;
      this.setFocusToRequireDate(orderDetail);

      return;
    }
    else {
      if (!this.isValidDate(e.srcElement.value)) {
        this.showRequiredDateValidation = true;
        this.requiredDateValidationMessage = 'Enter valid Required Date';
        this.requiredDateValidationIndex = index;
        this.setFocusToRequireDate(orderDetail);

        return;
      }

      if (new Date(e.srcElement.value).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
        this.showRequiredDateValidation = true;
        this.requiredDateValidationMessage = 'Required Date should not be past date';
        this.requiredDateValidationIndex = index;
        this.setFocusToRequireDate(orderDetail);

        return;
      }

      this.showRequiredDateValidation = false;
      this.requiredDateValidationMessage = '';
      this.requiredDateValidationIndex = 0;
    }

    orderDetail.requiredDate = new Date(e.srcElement.value);

    this.orderService.orderDto.orderDetailDtos.filter(o => o.parentOrderDetailId === orderDetail.id && o.orderDetailStatus === ' ')
      .forEach(item => {
        item.requiredDate = orderDetail.requiredDate;
      });

    this.adjustedLineItem.emit(orderDetail);
  }

  setFocusToRequireDate(orderDetail:OrderDetailDto){
    setTimeout(() => {
      if (this._elementRef.nativeElement.querySelector(`input[id=requiredDate-${orderDetail.id}]`)) {
        this._elementRef.nativeElement.querySelector(`input[id=requiredDate-${orderDetail.id}]`).focus();
      }
    });
  }

  isValidDate(date: string): boolean {
    const parts = date.split('/');

    if (parts.length < 3) {
      return false;
    }
    else {
      const month = parseInt(parts[0]);
      const day = parseInt(parts[1]);
      const year = parseInt(parts[2]);
      
      if (isNaN(month) || isNaN(day) || isNaN(year)) {
        return false;
      }

      if (month > 12 || month < 1) {
        return false;
      }

      if (day < 1 || year < 1) {
        return false;
      }
      this.monthdaycheck(month,day,year);
     
      this.isMonthchek(month,day,year);

      return true;
    }
  }
  monthdaycheck(month: number, day: number, year: number):boolean{
    if ((month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12) && day > 31) {
      return false;
    }

    if ((month === 4 || month === 6 || month === 9 || month === 11) && day > 30) {
      return false;
    }
    return true;
  }

  isMonthchek(month:any,day:any,year:any):boolean{
    if (month === 2) {
      if (((year % 4) === 0 && (year % 100) !== 0) || ((year % 400) === 0 && (year % 100) === 0)) {
        if (day > 29) {
          return false;
        }
      } else {
        if (day > 28) {
          return false;
        }
      }
    }
    return true;
  }

  addNewLineItem(): void {
    const orderDetail = new OrderDetailDto();
    orderDetail.id = this.orderService.getNextOrderDetailId();
    orderDetail.productCode = '';
    orderDetail.orderDetailStatus = ' ';
    orderDetail.itemEditable = true;
    orderDetail.requiredDate = new Date();
    orderDetail.enteredDate = new Date();
    orderDetail.productDto = new ProductDto();
    this.oldOrderQuantity = 1;
    this.orderService.orderDto.orderDetailDtos.push(orderDetail);
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
    this.sumOrderQuantity();
  }

  async inventoryCheckBR(productDto: ProductDto): Promise<boolean> {
    let result = true

    if (productDto.isOutOfStock) {
      let message = "Product is out of stock";

      if (productDto.outOfStockNote !== null && productDto.outOfStockNote.length > 0) {
        message = productDto.outOfStockNote;
      }

      await this.swalAlert.alert(message);
      result = false;
    }

    return result
  }

  async clickConfiguration(record: OrderDetailDto, isEdit = false) {
    if (!this._configService.reference) {
      return;
    }

    if (record.productDto.configSetDtos.length === 0) {
      return;
    }

    this.configurationOrderDetail = record;
    this.configSetParentOrderDetailId = record.id;

    if (isEdit) {
      localStorage.setItem('isEditMode', 'true');
    }

    const result = await this.configSetDialog.openAsync();

    if (result === 'Save') {
      this.handleSaveAction(record);
      

    } else if (result === 'Cancel') {
      const fromPage = localStorage.getItem('from');

      if (fromPage && fromPage === 'depositComponent') {
        localStorage.removeItem('isEditMode');
        localStorage.removeItem('openCashDtos');
        this.orderService.orderDto.orderType = this.orderService.orderTypeBackUp;
        this.orderService.orderDto.orderTypeBinding = this.orderService.orderTypeList.filter(x=>x.id===this.orderService.orderDto.orderType)[0];
        this.orderService.orderTypeChangedFromQuoteToOrder = false;
      }

      this.sumOrderQuantity();
      this.setTimeout();
    }
  }
  handleSaveAction(record:OrderDetailDto) {
    const fromPage = localStorage.getItem('from');

      if (fromPage && fromPage === 'depositComponent') {
        const openCashDtos = localStorage.getItem('openCashDtos');
        let selectedOpenCashDtos = [];

        if (openCashDtos) {
          selectedOpenCashDtos = JSON.parse(openCashDtos);
        }

        selectedOpenCashDtos = selectedOpenCashDtos.filter((e: any) => e['isSelected'] === true);

        if (!selectedOpenCashDtos.length) {
          this.orderService.orderDto.paymentMethod = 'I';
        }
      }
      this.setTimeout();
      this.itemLevelofferpopu(record);
      
  }
  setTimeout(){
    setTimeout(() => {
      if (this.dupProduct.show) {
        this.dupItemOkBtn.nativeElement.focus();
      }
      if (this.itemComment.show) {
        this.orderNoteOkBtn.nativeElement.focus();
      }
      this.checkconfigSet();
     
    }); 
  }
  checkconfigSet() {
    if (!this._elementRef.nativeElement.querySelector(`input[id=listPrice-${this.configSetParentOrderDetailId}]`).disabled) {
      this._elementRef.nativeElement.querySelector(`input[id=listPrice-${this.configSetParentOrderDetailId}]`).focus();
    }
    else if (!this._elementRef.nativeElement.querySelector(`input[id=discount-${this.configSetParentOrderDetailId}]`).disabled) {
      this._elementRef.nativeElement.querySelector(`input[id=discount-${this.configSetParentOrderDetailId}]`).focus();
    }
    else if (!this._elementRef.nativeElement.querySelector(`input[id=unitPrice-${this.configSetParentOrderDetailId}]`).disabled) {
      this._elementRef.nativeElement.querySelector(`input[id=unitPrice-${this.configSetParentOrderDetailId}]`).focus();
    }
    else {
      if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`)) {
        this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`).focus();
      }
    }
  }

  itemLevelofferpopu(record: OrderDetailDto) {
    //item-level-offer popup will populated when item in grid matches to an item in offerInfo item array
    const currentOrderDetail: OrderDetailDto = this.orderService.orderDto.orderDetailDtos.filter(i => i.id === record.id)[0];
    this.loadItemLevelOffersIfAny(currentOrderDetail);
    this.adjustedLineItem.emit();
    this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    this.sumOrderQuantity();
  }

  proceedNext() {
    if (this.selectedOrderDetailDto.orderDetailStatus === ' ') {
      this.selectedOrderDetailDto.openQuantity = this.selectedOrderDetailDto.orderQuantity - this.selectedOrderDetailDto.shippedQuantity;
      this.calculatePricing(this.selectedOrderDetailDto);
    }
    else if (this.selectedOrderDetailDto.orderDetailStatus === 'C') {
      // This used to use the actual backup.  This code need to be altered, as it shouldn't need the orderDtoBackup
      const orderDtoBackup = this.orderService.orderDto;
      const unsavedOrderDto = [...orderDtoBackup.orderDetailDtos];
      this._subscriptionPriceCalucationComponent.showNormalDialog(this.selectedOrderDetailDto.productId, unsavedOrderDto);
      // Revert the quantity back to what it was originally on the main item.
      this.selectedOrderDetailDto.orderQuantity = this.previousOrderQuantity;
      this.selectedOrderDetailDto.openQuantity = this.previousOrderQuantity - this.selectedOrderDetailDto.shippedQuantity;
    }

    if (this.selectedOrderDetailDto.productId === 262) {
      this.orderService.orderDto.paymentMethod = 'C';
    }
  }

  calculatePricing(orderDetail: OrderDetailDto): void {
    const newListPrice = this._pricingService.calculateListPricing(this.orderService.orderDto, orderDetail) || orderDetail.listPrice;
    const listPriceChanged = orderDetail.listPrice !== newListPrice;    
    const oldTier = orderDetail.productDto?.multiUserPricingDtos.filter(item => this.oldOrderQuantity >= item.startRange && this.oldOrderQuantity <= item.endRange)[0];
    const newTier = orderDetail.productDto?.multiUserPricingDtos.filter(item => orderDetail.orderQuantity >= item.startRange && orderDetail.orderQuantity <= item.endRange)[0];
    const changedTier = oldTier !== newTier;
    const isNewLineItem = orderDetail.id < 0;
    orderDetail.listPrice = newListPrice;
    this.orderService.isChangedTier = changedTier;
    if (!isNewLineItem && !changedTier && listPriceChanged || ((this._pricingService.round((orderDetail.unitPrice), 2)) !==  (this._pricingService.round((this.oldUnitPrice), 2)) && orderDetail.orderQuantity === this.oldOrderQuantity)) {
      if (orderDetail.promotionEntryCode !== 'OLT' && orderDetail.promotionEntryCode !== 'OLP' && orderDetail.promotionEntryCode !== 'ILT' && orderDetail.promotionEntryCode !== 'ILP') {
        this.onChangeUnitPrice(orderDetail, false);  
      }
    }

    this.oldUnitPrice = orderDetail.unitPrice;
    this.oldOrderQuantity = orderDetail.orderQuantity;
    this._pricingService.calculateExtendedPrice(orderDetail);
    this.orderService.recalculateFields(this.orderService.orderDto);
  }

  async maxOrderQuantityOneBR(orderDetail: OrderDetailDto, productDto: ProductDto): Promise<boolean> {
    if (orderDetail.orderQuantity > 1) {
      if (productDto.hasMaxQuantityOne) {
        orderDetail.orderQuantity = 1;
        orderDetail.openQuantity = 1;
        await this.swalAlert.alert("The order quantity for this item must equal 1");
        return true;
      }
    }

    return false;
  }

  stopDup() {
    if (this.orderService.orderDto.orderDetailDtos.filter(i => i.id === this.currentOrderDetailId).length > 0) {
      this.orderService.orderDto.orderDetailDtos.filter(i => i.id === this.currentOrderDetailId)[0].productCode = "";
      const orderDetail = this.orderService.orderDto.orderDetailDtos.filter(i => i.id === this.currentOrderDetailId)[0];
      orderDetail.productDescription = "";
      orderDetail.openQuantity = 0;
      orderDetail.discount = 0;
      orderDetail.listPrice = 0;
      orderDetail.requiredDate = new Date(new Date().toDateString());
      orderDetail.orderDetailStatus = ' ';
      orderDetail.shippedQuantity = 0;
      orderDetail.voidedQuantity = 0;
      orderDetail.accountOwnerCode = "";
      orderDetail.classCode = "";
      orderDetail.glCode = "";
      orderDetail.orderQuantity = 0;
      orderDetail.itemEditable = true;
      orderDetail.unitPrice = 0;
      orderDetail.extendedPrice = 0;
      this.orderService.orderDto.orderDetailDtos.splice(this.orderService.orderDto.orderDetailDtos.findIndex(o => o.id === this.currentOrderDetailId), 1);
      this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
      this.orderService.recalculateFields(this.orderService.orderDto);
      this.dupProduct.show = false;
    } else {
      this.dupProduct.show = false;
    }

    setTimeout(() => {
      if (this.itemComment.show) {
        this.orderNoteOkBtn.nativeElement.focus();
      }
      else if (this.dupProduct.show) {
        this.dupItemOkBtn.nativeElement.focus();
      }
      else if (this.showNoItemExists.show) {
        if (this._elementRef.nativeElement.querySelector(`button[id=btnShowNoItemExistsOk]`)) {
          this._elementRef.nativeElement.querySelector(`button[id=btnShowNoItemExistsOk]`).focus();
        }
      }
      else if (this.configSetDialog.show) {
        if (this._elementRef.nativeElement.querySelector(`button[id=configSetSavebtn]`)) {
          this._elementRef.nativeElement.querySelector(`button[id=configSetSavebtn]`).focus();
        }
      }
      else if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`)) {
        this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`).focus();
      }
    });
    this.triggerElement = "cancel";
    return;
  }

  recalculateWebCfaLicensesBR(orderDetail: OrderDetailDto): void {
    if (!orderDetail.productDto?.configSetDtos.some(item => item.configSet === 'CFAWEB')) {
      return;
    }

    // Adjust the licenses when the main item qwuantity changes.
    const licenseOrderDetail = this.orderService.orderDto.orderDetailDtos.filter(item => item.parentOrderDetailId === orderDetail.id && item.orderDetailStatus === ' ')[0];

    if (licenseOrderDetail) {
      licenseOrderDetail.orderQuantity = orderDetail.orderQuantity * (orderDetail.cfaWebDtos.length - 1);
      licenseOrderDetail.openQuantity = orderDetail.orderQuantity * (orderDetail.cfaWebDtos.length - 1);
    }
  }

  checkIfCustomPricingApplicable(orderDetail: OrderDetailDto) {
    if (orderDetail.productDto?.allowCustomPrice || orderDetail.productDto?.allowCustomStandard) {
      orderDetail.listPrice = 0;
      orderDetail.discount = 0;
    }
  }

  closeNoItemDialog() {
    this.showNoItemExists.show = false;

    setTimeout(() => {
      if (this.itemComment.show) {
        this.orderNoteOkBtn.nativeElement.focus();
      }
      else if (this.dupProduct.show) {
        this.dupItemOkBtn.nativeElement.focus();
      }
      else if (this.configSetDialog.show) {
        if (this._elementRef.nativeElement.querySelector(`button[id=configSetSavebtn]`)) {
          this._elementRef.nativeElement.querySelector(`button[id=configSetSavebtn]`).focus();
        }
      } else if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`)) {
        this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`).focus();
      }
    });
  }

  async closeItemDialog() {
    this.orderService.recalculateFields(this.orderService.orderDto);

    if (this._authorizationService.hasResource('OrderInquiryEditButton')) {
      await this.orderService.accountContactValidationBR();
      this.orderService.orderDto.yearPanelButtonsDisable = true;
    }

    if (this.setFocusToQuantityAfterPopup) {
      this.setFocusToQuantityAfterPopup = false;

      if (this.itemComment.show) {
        this.itemComment.show = false;
      }

      this.setFocusOnOpenedPopup();

      if (this.dupWebApps.show) {
        this.dupWebApps.show = false;
        this.orderService.orderDto.orderDetailDtos.filter(o => o.id === this.currentOrderDetailId)[0].productCode = '';

        setTimeout(() => {
          if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.currentOrderDetailId}]`)) {
            this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.currentOrderDetailId}]`).focus();
          }
        });
      }
    }
  }
  setFocusOnOpenedPopup(dupFlag?: boolean) {
    setTimeout(() => {
      if (this.itemComment.show) {
        this.orderNoteOkBtn.nativeElement.focus();
      }
      else if (this.dupProduct.show) {
        this.dupItemOkBtn.nativeElement.focus();
      }
      else if (this.showNoItemExists.show) {
        if (this._elementRef.nativeElement.querySelector(`button[id=btnShowNoItemExistsOk]`)) {
          this._elementRef.nativeElement.querySelector(`button[id=btnShowNoItemExistsOk]`).focus();
        }
      }
      else if (this.configSetDialog.show) {
        if (this._elementRef.nativeElement.querySelector(`button[id=configSetSavebtn]`)) {
          this._elementRef.nativeElement.querySelector(`button[id=configSetSavebtn]`).focus();
        }
      } else if (this._elementRef.nativeElement.querySelector(`input[id=orderQuantity-${this.currentOrderDetailId}]`)) {
        this._elementRef.nativeElement.querySelector(`input[id=orderQuantity-${this.currentOrderDetailId}]`).focus();
      }
      if (dupFlag) {
        if (this.triggerElement == 'cancel'){
          this.stopDup();
        }
        this.triggerElement = 'cancel';
      }
    });
  }

  allowDup() {
    this.setFocusOnOpenedPopup();
    this.dupProduct.show = false;
    this.triggerElement = "allow";
    return;
  }

  onClickYes() {
    this.quantityCheckModal.show = false;
    this.selectedType = 'Y';
    this.orderService.orderDto.hasNoDock = false;
    this.orderService.orderDto.hasDock = true;
    this.orderService.updateSystemComments();
    this.proceedNext()
  }

  onClickNo() {
    this.quantityCheckModal.show = false;
    this.orderService.orderDto.hasDock = false;
    this.orderService.orderDto.hasNoDock = true;
    this.orderService.updateSystemComments();
    this.selectedType = 'N';
  }

  deleteAllItems(): void {
    this.orderService.orderDto.orderDetailDtos.forEach(element => {
      const productItem = this._configService.reference?.productDtos.filter(p => p.productCode === element?.productCode)[0];

      if (productItem?.requiresAmaKey) {
        this._configService.updateKeyCodeTakenDate(this._configService.keyCodeDto.id, this._configService.keyCodeDto.keyCode).subscribe(() => {
          this.keyCodeDto.keyCode = '';
          this.keyCodeDto.id = 0;
          this._configService.keyCodeCache = null;
          this._configService.referenceCachedObservable = null;
          this.orderService.orderDto.amaKey = '';
        });
      }

      this.deleteItem(element);
    });

    this.addNewLineItem();
    this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    this.enableOrDisableMuliyearButton();

    this.orderService.orderDto.orderStatus = 'D';
    this.orderService.orderDto.shipmentMethod = 'UPS';
    this.orderService.orderDto.isRush = false;
    setTimeout(() => {
      if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos[0].id}]`)) {
        this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos[0].id}]`).focus();
      }
    });
  }

  async deleteItem(orderDetail: OrderDetailDto): Promise<void> {
    if (orderDetail.productDto?.requiresAmaKey) {
       this.updateAmaKey();
    }
    this.handleShippingInconsistency(orderDetail);
    await this.handlePromotions(orderDetail);
    await this.handleAssociatedItems(orderDetail);
  
    this.finalizeDeletion();
   
  }
  finalizeDeletion() {
    this.sumOrderQuantity();
    this.enableOrDisableMuliyearButton();

    setTimeout(() => {
      if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`)) {
        this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`).focus();
      }
    });

    this.orderService.paymentPlanOrderEntryBR();
    this.orderService.updateAmaKeyButtonMode();
    this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    this.adjustedLineItem.emit();
    this.orderService.recalculateFields(this.orderService.orderDto);
  }
  async handleAssociatedItems(orderDetail: OrderDetailDto) {
    for (const orderDetailPremium of this.orderService.orderDto.orderDetailDtos.filter(o => o.parentOrderDetailId === orderDetail.id)) {
      await this.deleteItem(orderDetailPremium);
    }

    if (orderDetail.id <= 0) {
      this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
    } else {
      orderDetail.orderDetailStatus = 'V';
      orderDetail.voidReasonCode = 'D';
      orderDetail.promotionEntryCode = null;
      orderDetail.promotionOrderDetailId = null;
      orderDetail.taxAmount=0;
      orderDetail.taxRate=0;
    }

  }
  async handlePromotions(orderDetail: OrderDetailDto) {
    if (orderDetail && orderDetail.promotionEntryCode === 'ILT') {
      this.offerType = 'item';
      await this.handleILT(orderDetail); 
    }
    else if(orderDetail && orderDetail.promotionEntryCode === 'OLT' || this.orderService.orderDto.orderDetailDtos.some(o => o.promotionEntryCode === 'OLP')) {
      await this.handleOLT(orderDetail);
    }
  }
  async handleOLT(orderDetail: OrderDetailDto) {
    if (orderDetail.id <= 0) {
      this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetail);
    } else {
      orderDetail.orderDetailStatus = 'V';
      orderDetail.voidReasonCode = 'D';
      orderDetail.promotionEntryCode = null;
      orderDetail.promotionOrderDetailId = null;
      orderDetail.taxAmount=0;
      orderDetail.taxRate=0;
    }

    await this.checkForOfferQualification();
  }
  async handleILT(orderDetail: OrderDetailDto) {
    this.associatedPremiumsList = this.orderService.orderDto.orderDetailDtos.filter(o => o.promotionOrderDetailId === orderDetail.id)
    .map(p => p.productCode).toString();
  if (this.associatedPremiumsList) {
    const result = await this.orderOfferQualificationDialog.openAsync();

    if (result === 'Close') {
      for (const orderDetailPremium of this.orderService.orderDto.orderDetailDtos.filter(o => o.promotionOrderDetailId === orderDetail.id)) {
        orderDetailPremium.promotionOrderDetailId = null;
        orderDetailPremium.promotionEntryCode = null;
      }
    }

    if (result === 'Delete') {
      for (const orderDetailPremium of this.orderService.orderDto.orderDetailDtos.filter(o => o.promotionOrderDetailId === orderDetail.id)) {
        this.deleteItem(orderDetailPremium);
      }
    }}

  }
  handleShippingInconsistency(orderDetail: OrderDetailDto) {
    const isShipping = orderDetail.productDto?.classCode === 'SH';
    if(isShipping && this.orderService.isShippingInconsistent){
      this.orderService.isShippingInconsistent = false;
      if(this.orderService.orderDto.orderDetailDtos.findIndex(x => x.productId === 0) === -1){
        this.addNewLineItem();
      }
    }
  }
  updateAmaKey() {
    this._configService.updateKeyCodeTakenDate(this._configService.keyCodeDto.id, this._configService.keyCodeDto.keyCode).subscribe(() => {
      this.keyCodeDto.keyCode = '';
      this.keyCodeDto.id = 0;
      this._configService.keyCodeCache = null;
      this._configService.referenceCachedObservable = null;
      this.orderService.orderDto.amaKey = '';
    });
  }

  add() {
    const orderDetail = new OrderDetailDto();
    orderDetail.orderDetailStatus = ' ';
    orderDetail.itemEditable = true;
    orderDetail.requiredDate = new Date(new Date().toDateString());
    orderDetail.enteredDate = new Date();
    orderDetail.id = this.orderService.getNextOrderDetailId();
    this.orderService.orderDto.orderDetailDtos.push(orderDetail);
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];

    setTimeout(() => {
      if (this._elementRef.nativeElement.querySelector(`input[name=productCode]`)) {
        this._elementRef.nativeElement.querySelector(`input[name=productCode]`).focus();
      }
    });
  }

  enableOrDisableMuliyearButton() {
    if (this.orderService.orderDto.orderDetailDtos.some(item => item.productDto.allowMultiYear === true)) {
      this.orderService.disableMultiYearBtn = false;
    }
    else {
      this.orderService.disableMultiYearBtn = true;
    }
  }

  async checkForOfferQualification(): Promise<void> {
    await this.getOfferInfo();

    if (this.orderService.orderDto.offerInfoDto?.orderLevelOffer && (this.orderService.orderDto.promotionOfferPopupButton === 'Accept Promo Offer' || this.orderService.orderDto.id > 0)) {
      this.offerType = 'order';
      this.associatedPremiumsList = this.orderService.orderDto.orderDetailDtos.filter(o => o.promotionEntryCode === 'OLP' && o.orderDetailStatus !== 'V')
        .map(p => p.productCode + " " + p.productDescription).toString();
        const orderOfferInputDto = this.createOrderOfferInputDto(this.orderService.orderDto);

      this.orderService.orderDto.orderOfferDto = await this.orderService.buildOrderOfferAsync(orderOfferInputDto);

      if (this.orderService.orderDto.orderOfferDto?.offerType === OrderOfferType.Tier) {
        await this.handleTierOffer(this.orderService.orderDto);  
      }
      else if (this.orderService.orderDto.orderOfferDto?.offerType === OrderOfferType.Other) {
        await this.handleOtherOffer(this.orderService.orderDto);
       
      }
    }
  }
  async handleOtherOffer(orderDto: OrderDto) {
    const quantity = orderDto.orderDetailDtos.filter(o => o.promotionEntryCode === 'OLT' && o.orderDetailStatus !== 'V')
    .reduce((sum, item) => sum + item.orderQuantity, 0);

  if (orderDto.orderOfferDto?.tiers) {
    orderDto.minimumPromotionQuantity = this.getMinimumFromValueForTier(orderDto.orderOfferDto.tiers);
  }
  if (quantity >= 0 && quantity < orderDto.minimumPromotionQuantity) {
    await this.orderOfferQualificationProcess();
  }
  else {
    if (orderDto.orderOfferDto?.discountAndPremium === false) {
      if (orderDto.tierDiscountType === 'orderdiscount') {
        await this.applyPromoDiscountForQualifiedItems();
      }
    }
    else {
      await this.applyPromoDiscountForQualifiedItems();
    }
  }
  }
  async handleTierOffer(orderDto: OrderDto) {
    const extendedPrice = orderDto.orderDetailDtos.filter(o => o.promotionEntryCode === 'OLT' && o.orderDetailStatus !== 'V')
    .reduce((sum, item) => sum + item.extendedPrice, 0);

  if (orderDto.orderOfferDto?.tiers) {
    this.orderService.orderDto.minimumPromotionPrice = this.getMinimumFromValueForTier(orderDto.orderOfferDto.tiers);
  }

  if (extendedPrice >= 0 && extendedPrice < orderDto.minimumPromotionPrice) {
    await this.orderOfferQualificationProcess();
  }
  else {
    if (orderDto.orderOfferDto?.discountAndPremium === false) {
      if (orderDto.tierDiscountType === 'orderdiscount') {
        await this.applyPromoDiscountForQualifiedItems();  
      }
    }
    else {
      await this.applyPromoDiscountForQualifiedItems();  
    }
  }
  }
  createOrderOfferInputDto(orderDto: OrderDto) {
    const orderOfferInputDto = new OrderOfferInputDto();
      
    orderOfferInputDto.promotionCode = orderDto.promotionCode!;
    orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V' && o.promotionEntryCode !== 'OLP').forEach(item => {
      const itemDetailDto = new ItemDetailDto();
      itemDetailDto.productId = item.productDto.id;
      itemDetailDto.quantity = item.orderQuantity;
      itemDetailDto.extendedPrice = item.extendedPrice;
      itemDetailDto.classCode = item.classCode ?? '';

      if (itemDetailDto.productId !== 0) {
        orderOfferInputDto.itemDetails.push(itemDetailDto);
      }
    });
     return orderOfferInputDto;
  }
  checkResultClose(result:string) {
    if (result === 'Close') {
      for (const orderDetailPremium of this.orderService.orderDto.orderDetailDtos.filter(o => (o.promotionEntryCode === 'OLT' || o.promotionEntryCode === 'OLP')
        && o.orderDetailStatus !== 'V')) {
        if (orderDetailPremium.promotionEntryCode === 'OLT') {
          orderDetailPremium.discount = orderDetailPremium.discountBeforeApplyingPromoOffer;
        }          
        
        orderDetailPremium.promotionEntryCode = null;
        orderDetailPremium.promotionOrderDetailId = null;
        orderDetailPremium.openQuantity = orderDetailPremium.orderQuantity;
      }
    }
  }

  async orderOfferQualificationProcess(): Promise<void> {
    if (this.orderService.orderDto.orderDetailDtos.some(o => o.promotionEntryCode === 'OLP' && o.orderDetailStatus !== 'V')) {
      const result = await this.orderOfferQualificationDialog.openAsync();
       this.checkResultClose(result);
       this.checkResultDelete(result);
    }
    else {
      for (const orderDetailPremium of this.orderService.orderDto.orderDetailDtos.filter(o => o.promotionEntryCode === 'OLT' && o.orderDetailStatus !== 'V')) {
        this.resetPromotion(orderDetailPremium);      
      }
    }
    this.orderService.orderDto.hasUserSeenPromoForm = false;
    this.orderService.orderDto.promotionOfferPopupButton = '';
    await this.getOfferInfo();
    this.orderService.recalculateFields(this.orderService.orderDto);
    this._approvalService.refreshApprovalFields(this.orderService.orderDto);
    this.sumOrderQuantity();
  }
  resetPromotion(orderDetailPremium: OrderDetailDto) {
    orderDetailPremium.openQuantity = orderDetailPremium.orderQuantity;
    orderDetailPremium.discount = orderDetailPremium.discountBeforeApplyingPromoOffer;
    orderDetailPremium.promotionEntryCode = null;
    orderDetailPremium.promotionOrderDetailId = null;
  }
  checkResultDelete(result: string) {
    if (result === 'Delete') {
      for (const orderDetailPremium of this.orderService.orderDto.orderDetailDtos.filter(o => o.promotionEntryCode === 'OLP' && o.orderDetailStatus !== 'V')) {
        if (orderDetailPremium.id <= 0) {
          this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item !== orderDetailPremium);
        } else {
          orderDetailPremium.orderDetailStatus = 'V';
          orderDetailPremium.voidReasonCode = 'D';
          orderDetailPremium.promotionEntryCode = null;
          orderDetailPremium.promotionOrderDetailId = null;
          orderDetailPremium.taxAmount=0;
          orderDetailPremium.taxRate=0;
        }
      }
      for (const orderDetailPremium of this.orderService.orderDto.orderDetailDtos.filter(o => o.promotionEntryCode === 'OLT' && o.orderDetailStatus !== 'V')) {
        this.resetPromotion(orderDetailPremium);
      }

      this.enableOrDisableMuliyearButton();
      this.orderService.paymentPlanOrderEntryBR();
      this.orderService.updateAmaKeyButtonMode();
      this.adjustedLineItem.emit();

      setTimeout(() => {
        if (this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`)) {
          this._elementRef.nativeElement.querySelector(`input[id=productCode-${this.orderService.orderDto.orderDetailDtos.filter(o => o.productId === 0)[0].id}]`).focus();
        }
      });
    }
  }
  

  getMinimumFromValueForTier(tierDto: TierDto[]): number {
    return [...tierDto].sort((a, b) => 0 - (a.from > b.from ? -1 : 1))[0].from;
  }

  async applyPromoDiscountForQualifiedItems(): Promise<void> {
    this.orderService.orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V' && o.promotionEntryCode === 'OLT').forEach(element => {
      if (element.productCode !== '') {
        const productQualifies = this.orderService.orderDto.orderOfferDto?.productIds.some(productId => productId === element.productId);

        if (productQualifies) {
          const splDiscount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, element.productId);
          element.discount = splDiscount > this.orderService.orderDto.orderOfferDto?.percentDiscount! ? splDiscount :
            this.orderService.orderDto.orderOfferDto?.percentDiscount!;
          this._pricingService.calculateUnitPrice(element);
          this._pricingService.calculateExtendedPrice(element);
        }
      }
    });
  }

  getdatafile(orderDetail: OrderDetailDto) {
    if (orderDetail.productDto?.configSetDtos?.some(config => config.configSet === 'DATAFILE')) {
      return { 'background-color': '#9BC2E6' };
    }
    else {
      return { 'background-color': '#FFFFFF' };
    }
  }

  getdiscount(orderDetail: OrderDetailDto) {
    if (orderDetail.productId!==0 && this.orderService.orderDto.billToDto.specifiedPriceList ) {
      const priceDto = this.orderService.orderDto.billToDto.accountPricingDtos?.filter((priceObj) =>
        priceObj.productId === orderDetail.productId
      );

      if (priceDto) {
        const result = this.setBackGroundColorWhenPriceDtoExists(orderDetail, priceDto);
        if (result != "") {
          return result;
        }
      }
      if (orderDetail.productDto?.productCode != '' && orderDetail.listPrice == 0 && !(orderDetail.productDto?.allowCustomStandard || orderDetail.productDto?.allowCustomPrice)) {
        return { 'background-color': '#EFEFEE' };
      }
    }
    if (orderDetail.promotionEntryCode === 'ILT' || orderDetail.promotionEntryCode === 'ILP' || orderDetail.promotionEntryCode === 'OLT' || orderDetail.promotionEntryCode === 'OLP') {
      return { 'background-color': '#FBBDBD' }//pink
    }
    return { 'background-color': '#FFF' }
  }
  setBackGroundColorWhenPriceDtoExists(orderDetail: OrderDetailDto, priceDto: AccountPricingDto[]): any{
    if(orderDetail.promotionEntryCode === 'ILT' || orderDetail.promotionEntryCode === 'ILP' 
    || orderDetail.promotionEntryCode === 'OLT' || orderDetail.promotionEntryCode === 'OLP'){
      if(orderDetail?.discount != priceDto[0]?.discount){
        return { 'background-color': '#FBBDBD' }//pink
      }
      if(orderDetail?.discount === priceDto[0]?.discount){
        return { 'background-color': '#A9D08E' }//green
      }
    } 
    if (priceDto.length > 0 && orderDetail?.discount === priceDto[0]?.discount) {
      return { 'background-color': '#A9D08E' }//green
    }
  }

  getUnitPriceBackgroundcolor(orderDetail: OrderDetailDto) {
    let listPriceForQuantity1 = 0;

    if (orderDetail.productDto.multiUserPricingDtos && orderDetail.productDto.multiUserPricingDtos.length > 0) {
      listPriceForQuantity1 = orderDetail.productDto.multiUserPricingDtos.filter(o => o.startRange === 1)[0].listPrice;
    }
    
    if (orderDetail.unitPrice > (listPriceForQuantity1 === 0 ? orderDetail.listPrice : listPriceForQuantity1)) {
      return { 'background-color': '#F3A575' };
    }
    else if (orderDetail.unitPrice < orderDetail.productDto?.floorPrice && orderDetail.unitPrice < (orderDetail.productDto?.floorPrice ?? 0) && orderDetail.productDto?.classCode !== 'SH' && orderDetail.productDto?.classCode !== 'XS' && !orderDetail.productDto?.allowCustomStandard && !orderDetail.productDto?.allowCustomPrice && orderDetail.productDto?.productCode !== '7825' &&
      orderDetail.productDto?.productCode !== 'CS23' && orderDetail.promotionEntryCode !== 'ILP' && orderDetail.promotionEntryCode !== 'OLT'
      && orderDetail.promotionEntryCode !== 'OLP' && orderDetail.productDto?.productCode !== '0010' && orderDetail.productId !== 0 &&
      (orderDetail.discount !== this._pricingService.getSplDiscount(this.orderService.orderDto, orderDetail.productId) || this.orderService.orderDto.billToDto.accountPricingDtos?.length == 0)) {
      return { 'background-color': '#FFFF00' };
    }
    else if(orderDetail.productDto?.productCode != '' && orderDetail.listPrice == 0 && !(orderDetail.productDto?.allowCustomStandard || orderDetail.productDto?.allowCustomPrice)){
      return { 'background-color': '#EFEFEE' };
    }
    else {
      return { 'background-color': '#FFF' };
    }
  }
  addCheckOrCashRow() {
    const orderDetail = this.orderService.createNewOrderDetail(this.orderService.orderDto, '0010');
    orderDetail.orderDetailStatus = ' ';
    orderDetail.orderQuantity = 1;
    orderDetail.openQuantity = 1;
    orderDetail.itemEditable = true;
    this.orderService.orderDto.orderDetailDtos.unshift(orderDetail);
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
    setTimeout(() => {
      if (this._elementRef.nativeElement.querySelector(`input[name=productCode]`)) {
        this._elementRef.nativeElement.querySelector(`input[name=productCode]`).focus();
      }
    });
    this.clickConfiguration(orderDetail);
    this.adjustedLineItem.emit();
  }


adjustUnitPriceForClassCodeXS(orderDetail: OrderDetailDto): void {
  if (orderDetail.classCode === 'XS') {
      orderDetail.unitPrice *= -1;
  }
}

setListPriceIfZero(orderDetail: OrderDetailDto): void {
  if (orderDetail.listPrice === 0) {
      orderDetail.listPrice = orderDetail.unitPrice;
  }
}

shouldRecalculateDiscount(orderDetail: OrderDetailDto, directCallFlag: Boolean){
  return orderDetail.productDto?.multiUserPricingDtos.length == 0 || !this.orderService.isChangedTier || directCallFlag;
}

calculateAndSetDiscount(orderDetail: OrderDetailDto): void {
  orderDetail.discount = this._pricingService.round((100 - (orderDetail.unitPrice / orderDetail.listPrice * 100)), 10);
  orderDetail.discount = this._pricingService.calculateMaxDiscount(orderDetail.discount, orderDetail.productId);

  if (!this.orderService.orderDto.hasUserSeenPromoForm || this.orderService.orderDto.promotionOfferPopupButton !== 'Accept Promo Offer') {
      orderDetail.discountBeforeApplyingPromoOffer = orderDetail.discount;
  }
}

async handleOrderLevelOffer(orderDetail: OrderDetailDto): Promise<void> {
  if (orderDetail.promotionEntryCode === 'OLT' || this.orderService.orderDto.orderDetailDtos.some(o => o.promotionEntryCode === 'OLP')) {
      if (orderDetail.promotionEntryCode === 'OLP') {
          this.productCode = orderDetail.productCode;
          this.productType = 'Premium';
          await this.itemChangedPopup.openAsync();
      }

      orderDetail.promotionEntryCode = null;
      this.recalculateAndRefresh(orderDetail);
      await this.checkForOfferQualification();
  }
}

recalculateAndRefresh(orderDetail: OrderDetailDto): void {
  this._pricingService.calculateUnitPrice(orderDetail);
  this._pricingService.calculateExtendedPrice(orderDetail);
  this.orderService.recalculateFields(this.orderService.orderDto);
  this._approvalService.refreshApprovalFields(this.orderService.orderDto);
}

finalizePricing(orderDetail: OrderDetailDto): void {
  this.recalculateAndRefresh(orderDetail);
  this._approvalService.showApprovalHistoryOnQuote = false;
}
}
