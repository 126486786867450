import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  private readonly _configService: ConfigService;
  environment: string | undefined;
  apiUrl: string | undefined;

  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  ngOnInit(): void {
    this.apiUrl = this._configService.apiUrl;
    this.environment = this._configService.environment;
  }

}
