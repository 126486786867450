<div [ngClass]="panelBackground" class="panel">
  <uitk-panel uitkId="invoicePanel" [collapsible]="collapsiblePanel" [open]="openPanel"
              (openChange)="setPanelBGHeight()">
    <uitk-panel-header-title>
      <h2>Invoices</h2>
    </uitk-panel-header-title>
    <uitk-panel-content>
      <div id="invoicesFilterDic" class="uitk-l-grid__row ">
        <div class="uitk-l-grid__col">
          <label uitkLabel class="labelsAR">View Invoices</label>
          <uitk-dropdown id="singleselect-template"
                         #singleSelect="ngModel"
                         [items]="invoicesDropdown"
                         [enableSelectFlag]="true"
                         name="template-example"
                         [(ngModel)]="invoiceDropDownSelectedValue"
                         (onChange)="onChangeInvoices($event)"
                         style="padding: 2%; width: 300px;">

          </uitk-dropdown>
        </div>
      </div>
    </uitk-panel-content>
  </uitk-panel>
</div>
<div class="col-12" style="padding-top: 400px" [hidden]="!modeService.isWaiting">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div id="loadingIndicator">
          <uitk-loading-indicator uitkId="spinner" spinnerSize="large">
          </uitk-loading-indicator>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col d-flex justify-content-center">
        <h5>
          {{modeService.waitDescription}}
        </h5>
      </div>
    </div>
  </div>
</div>
<div [hidden]="modeService.isWaiting">
  <h2 style="padding-left:2%;">Invoices</h2>
  <div style="padding-left:1%;">
    <button id="sendBtn" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--m"
            *appShowResource="'ARInquirySendSelectedToPdfButton'" (click)="showTemplate()" [disabled]="(this.invoiceService.hasCCMemo && !sendCCPdfAccess) || this.accountsReceivableService.selectedOrder === 0">
      Send Selected to PDF
    </button>
    <app-invoice-edit [accountId]="accountId" (invoiceEdited)="reload()"></app-invoice-edit>
  </div>

  <div style="padding-left:2%; padding-right:2%;">
    <app-invoice-results></app-invoice-results>
  </div>
</div>

<!-- Dialogs -->

<div *ngIf="showSelectedTemplate.show || showTemplateEmail.show" [hidden]="modeService.isWaiting">
  <app-select-template [showTemplateEmailDialog]="showSelectedTemplate"></app-select-template>

  <app-selected-template-email [templateUrl]="showTemplateEmail"></app-selected-template-email>
</div>

