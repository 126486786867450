import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { browserRefresh } from '../../app.component';
import { Mode } from '../../enums/mode.enum';
import { SourceType } from '../../enums/source-type.enum';
import { ApprovalDto } from '../../dtos/approval-dto.model';
import { OrderApprovalInputDto } from '../../dtos/order-approval-input-dto.model';
import { OrderDetailDto } from '../../dtos/order-detail-dto.model';
import { OrderDto } from '../../dtos/order-dto.model';
import { ApprovalService } from '../../services/approval.service';
import { BillToService } from '../../services/bill-to.service';
import { ConfigService } from '../../services/config.service';
import { ContactService } from '../../services/contact.service';
import { ModeService } from '../../services/mode.service';
import { OrderService } from '../../services/order.service';
import { PricingService } from '../../services/pricing.service';
import { ShippinghandlingService } from '../../services/shippinghandling.service';
import { TaxService } from '../../services/tax.service';
import { OrderFooterComponent } from '../order-inquiry/order-footer/order-footer.component';
import { OrderGridComponent } from '../order-inquiry/order-grid/order-grid.component';
import { OrderComponent } from '../order-inquiry/order/order.component';
import { AccountInformationComponent } from './account-information/account-information.component';
import { OrderByOrderEntryComponent } from './order-by-order-entry/order-by-order-entry.component';
import { OrderDetailsGridComponent } from './order-details-grid/order-details-grid.component';
import { ShipToOrderEntryComponent } from './ship-to-order-entry/ship-to-order-entry.component';
import { Title } from "@angular/platform-browser";
import { SwalAlert } from '../../helpers/alert';
import { Dialog } from 'src/app/helpers/dialog';
@Component({
  selector: 'app-order-entry',
  templateUrl: './order-entry.component.html',
  styleUrls: ['./order-entry.component.css'],
  providers: [OrderComponent, OrderByOrderEntryComponent, AccountInformationComponent, ShipToOrderEntryComponent]
})

export class OrderEntryComponent implements OnInit {
  private readonly _activatedroute: ActivatedRoute;
  private readonly _billToService: BillToService;
  private readonly _configService: ConfigService;
  private readonly _contactService: ContactService;
  private readonly _pricingService: PricingService;
  private readonly _shippinghandlingService: ShippinghandlingService;
  private readonly _taxService: TaxService;
  private readonly _orderComponent: OrderComponent;
  private readonly _route: Router;
  private readonly _titleService: Title;
  private readonly _modeService: ModeService;
  public approvalService: ApprovalService;
  @ViewChild(OrderComponent) orderHeader: OrderComponent | undefined;
  @ViewChild('orderGrid') orderGrid: OrderGridComponent | undefined;
  @Output() getAllContactList = new EventEmitter<any>();
  @ViewChild(OrderFooterComponent) orderFooterComponent: OrderFooterComponent | undefined;
  @ViewChild(OrderDetailsGridComponent) orderDetailsGridComponent: OrderDetailsGridComponent | undefined;
  verifyItemDialog : boolean = false;
  accountId = 0;
  contactId = 0;
  orderId = 0;
  leadIdDisplay: string | null = null;
  oppurtunityIdDisplay: string | null = null;
  orderType = '';
  currentYear = new Date().getFullYear();
  configIsReadySubscription: Subscription | undefined;
  billToIsReadySubscription: Subscription | undefined;
  enableShippingChargeWarning = false;
  showPackingSlipNotesDialog = { show: false };
  showViewMultiYearOrdersPopup = { show: false };
  newMYOrder = '';
  orderEntryBrowserRefresh = false;
  recentQuotesOrOrdersDialog = { show: false };
  swalAlert = new SwalAlert();
  isReship = false;
  reshipOrderDetail = new OrderDetailDto();
  configSetDialog = new Dialog<string>();
  
  constructor(activatedroute: ActivatedRoute,
    billToService: BillToService,
    configService: ConfigService,
    public modeService: ModeService,
    contactService: ContactService,
    public orderService: OrderService,
    pricingService: PricingService,
    shippinghandlingService: ShippinghandlingService,
    taxService: TaxService,
    orderComponent: OrderComponent,
    private readonly orderByOrderEntryComponent: OrderByOrderEntryComponent,
    public route: Router,
    titleService: Title,
    approvalService: ApprovalService) {
    this._activatedroute = activatedroute;
    this._billToService = billToService;
    this._configService = configService;
    this._contactService = contactService;
    this._pricingService = pricingService;
    this._shippinghandlingService = shippinghandlingService;
    this._taxService = taxService;
    this._orderComponent = orderComponent;
    this._route = route;
    this._titleService = titleService;
    this.approvalService = approvalService;
    this._modeService = modeService;
  }

  ngOnInit(): void {
    //#region To handle browser refresh button for orderentry draft
    this.orderEntryBrowserRefresh = !browserRefresh;

    if (this.orderEntryBrowserRefresh) {
      this.refreshBrowser();
    }
    //#endregion

    this._activatedroute.queryParams.subscribe(params => {
      if (!this._activatedroute.snapshot.queryParams.orderId) {
        this.clearPage();
        this.orderDetailsGridComponent?.addNewLineItem();  
      }
      this.orderId = params.orderId;
      this.accountId = params.accountId;
      this.contactId = params.contactId;
      this.orderType = params.orderType;
      this.leadIdDisplay = params.leadId;
      this.oppurtunityIdDisplay = params.OppId
      this.newMYOrder = params.NewMYOrder;   
      if(this.orderId === undefined && localStorage.getItem('reshipReturnId') !== ''){
        this.orderId = parseInt(localStorage.getItem('reshipReturnId') as string);
        this.orderService.orderDto.id = this.orderId;
        this.isReship = true;
      }
    });

    this.clearPage();

    this.orderService.orderDto = new OrderDto();
    this.orderService.orderDto.orderType = this.orderType === 'Q' ? 'Q' : ' ';
    this.orderService.orderDto.leadId = this.leadIdDisplay;
    this.orderService.orderDto.opportunityId = this.oppurtunityIdDisplay;
    this.orderService.orderDto.selectedsalesPersonName = null;

    this._configService.loadConfigurations();
    this.configIsReadySubscription = this._configService.configIsReady.subscribe(async () => {
      const isReship = this._route.url.includes('reship');
      if(isReship){
          await this.setReshipData();
      }
      else if (this.orderId !== undefined && this.orderId !== null && this.orderId > 0) {
        this.modeService.mode = Mode.View;
        await this.navigateToCorrectScreen();
      }
      else {
        this.modeService.mode = Mode.Edit;
      }

      if (this.accountId) {
        await this.loadBillToAndContactDtos();
      }
      else if (this.contactId) {
        this.orderService.orderDto.orderByDto.contactId = this.contactId;
        this.onContactIdDetect()
      }

      // Once I get the order and the contacts, I need to set the selectedShipToContact object.
      this.orderService.selectedShipToContact = this._contactService.contactDtos?.filter(item => item.contactId === this.orderService.orderDto.shipToDto.contactId)[0] ?? null;
      this.focusOnAccountId();
      this.approvalService.getOrderApprovalHistory(this.orderService.orderDto);
      this.approvalService.showApprovalHistoryOnQuote = this.orderService.orderDto.orderType === "Q" && (this.orderService.orderDto.orderStatus === " " || this.orderService.orderDto.orderStatus === "P" ||this.orderService.orderDto.orderStatus === "M" ||this.orderService.orderDto.orderStatus === "R" ) ;
    });
  }

  clearPage(): void {
    this.orderService.shiptoIsSelected = false;
    this.orderService.orderDto = new OrderDto();
    this.orderService.isSnapshot = false;
    this.approvalService.approvalsList = [] as ApprovalDto[];
    this.approvalService.approvalsList.length = 0;
    this.approvalService.orderApprovalInputDto = new OrderApprovalInputDto();
    this.orderService.orderDto.orderType = this._activatedroute.snapshot.queryParams.orderType === 'Q' ? 'Q' : ' ';
    this.orderService.orderDto.leadId = this._activatedroute.snapshot.queryParams.leadId;
    this.orderService.orderDto.opportunityId = this._activatedroute.snapshot.queryParams.OppId;
    this.modeService.mode = Mode.Edit;
    this._contactService.contactSearchKeyword = '';
    if(this.orderDetailsGridComponent) {
       this.orderDetailsGridComponent.sourceCode = null;
    }  
    this._titleService.setTitle("Order Entry"); 
  }
  async adjustedLineItem(orderDetailDto: OrderDetailDto | null) {
    this.approvalService.showApprovalHistoryOnQuote = false;
    await this.changeShippingAndHandlingBR('ItemAdjusted', orderDetailDto);
    this._orderComponent?.recalculateMultiYearVisible();
  }
  async validateIfShippingItemExist() : Promise<OrderDetailDto[]>{
    let shippingAndHandlingItems;
    shippingAndHandlingItems = this.orderService.orderDto.orderDetailDtos
      .filter(item => item.orderDetailStatus === ' ' && item.productDto.classCode === 'SH'
        && item.productCode !== this.orderService.orderDto.orderDetailDtos[0].productCode);
    if (shippingAndHandlingItems.length > 1) {
      await this.swalAlert.alert('Multiple Ship items cannot be added. Please select another item');
      this.orderService.deleteOrVoidOrderDetail(shippingAndHandlingItems.slice(-1)[0]);
    }

    const duplicateShippingAndHandlingItems = this.orderService.orderDto.orderDetailDtos
      .filter(item => item.orderDetailStatus === ' ' && item.productDto.classCode === 'SH'
        && item.productCode === this.orderService.orderDto.orderDetailDtos[0].productCode);
    if (duplicateShippingAndHandlingItems.length > 1) {
      await this.swalAlert.alert('Cannot add a duplicate open shipping item to an order');
      this.orderService.deleteOrVoidOrderDetail(duplicateShippingAndHandlingItems.slice(-1)[0]);
    }
    return shippingAndHandlingItems;
  }
  async shippingAndHandlingBROnChangeContactAddress(shippingOrderDetail: OrderDetailDto, isInternational: boolean, isPoBox: boolean, isUsps: boolean, isAlaskaHawaii: boolean) {

    let productCode = shippingOrderDetail ? shippingOrderDetail.productCode : '0053';
    let shipViaChanged = false;
    if (isInternational && isPoBox) {
      await this.swalAlert.alert('A PO Box cannot be used when shipping to a foreign address.');
    }

    if (this.orderService.orderDto.shipmentMethod === 'DONOTSHIP' || this.orderService.orderDto.shipmentMethod === 'MISC') {
      // Do nothing in this situation.
    } else if (this.orderService.orderDto.shipmentMethod !== 'USPS' && isUsps) {
      productCode = '0053';
      await this.orderService.reproductOrderDetail(shippingOrderDetail, productCode);
      this.updateItemDiscount(shippingOrderDetail);
      shipViaChanged = true;
      await this.swalAlert.alert(`Ship via has been updated to USPS.`);
    } else if (this.orderService.orderDto.shipmentMethod !== 'UPS-INT' && shippingOrderDetail.productCode !== '0052' && isInternational) {
      productCode = '0052';
      await this.orderService.reproductOrderDetail(shippingOrderDetail, productCode);
      this.updateItemDiscount(shippingOrderDetail);
      shipViaChanged = true;
      await this.swalAlert.alert(`Ship via has been updated to UPS-INT.`);
      this.orderService.editWasTriggered = false;
    } else if (this.orderService.orderDto.shipmentMethod !== 'UPS-AK/HI' && shippingOrderDetail.productCode !== '0045' && isAlaskaHawaii) {
      productCode = '0045';
      await this.orderService.reproductOrderDetail(shippingOrderDetail, productCode);
      this.updateItemDiscount(shippingOrderDetail);
      shipViaChanged = true;
      await this.swalAlert.alert(`Ship via has been updated to UPS-AK/HI.`);
    } 
    else{
      [productCode, shipViaChanged] = await this.shippingAndHandlingBROnChangeContactAddressContinuation(isInternational, isUsps, isAlaskaHawaii, shippingOrderDetail, productCode, shipViaChanged);
    }
    this.setShipmentMethod(productCode, isUsps);
    this.setRushCheckBox(shipViaChanged);

    this._shippinghandlingService.inProcess = false;
    await this._shippinghandlingService.recalculateShippingAndHandlingAsync(this.orderService.orderDto);
    this.orderService.recalculateFields(this.orderService.orderDto);
  }
  async shippingAndHandlingBROnChangeContactAddressContinuation(isInternational: boolean, isUsps: boolean, isAlaskaHawaii: boolean, shippingOrderDetail: OrderDetailDto, productCode:string, shipViaChanged: boolean): Promise<[string, boolean]> {
    if (this.orderService.orderDto.shipmentMethod === 'UPS-INT' && shippingOrderDetail.productCode === '0052' && !isInternational) {
      productCode = '0053';
      await this.orderService.reproductOrderDetail(shippingOrderDetail, productCode);
      this.updateItemDiscount(shippingOrderDetail);
      shipViaChanged = true;
      await this.swalAlert.alert(`Ship via has been updated to UPS Ground.`);
    } else if (this.orderService.orderDto.shipmentMethod === 'UPS-AK/HI' && shippingOrderDetail.productCode === '0045' && !isAlaskaHawaii) {
      productCode = '0053';
      await this.orderService.reproductOrderDetail(shippingOrderDetail, productCode);
      this.orderService.orderDto.shipmentMethod = 'UPS';
      this.updateItemDiscount(shippingOrderDetail);
      shipViaChanged = true;
      await this.swalAlert.alert(`Ship via has been updated from UPS-AK/HI to UPS Ground.`);
    } else if (this.orderService.orderDto.shipmentMethod === 'USPS' && !isUsps) {
      productCode = '0053';
      await this.orderService.reproductOrderDetail(shippingOrderDetail, productCode);
      this.updateItemDiscount(shippingOrderDetail);
      shipViaChanged = true;
      await this.swalAlert.alert(`Ship via has been updated from USPS to UPS Ground.`);
    }
    return [productCode, shipViaChanged];
  }
  async shippingAndHandlingBROnChangeItemAdjusted(shippingOrderDetail: OrderDetailDto, hasOpenPhysicalItems: boolean, isUsps: boolean){
    if (shippingOrderDetail && !hasOpenPhysicalItems) {
      this.orderService.deleteOrVoidOrderDetail(shippingOrderDetail);
      this.orderService.recalculateFields(this.orderService.orderDto);
      this.setShipmentMethod('0053', isUsps);
      this.setRushCheckBox(true);
    }
    else if (this.orderService.orderDto.shipmentMethod === 'DONOTSHIP' || this.orderService.orderDto.shipmentMethod === 'MISC') {
      // DO NOTHING
    }
  }
  async shippingAndHandlingBROnChangeShippingItemAdded(shippingOrderDetail: OrderDetailDto, hasOpenPhysicalItems: boolean, isUsps: boolean): Promise<boolean>{
    const shippingItemCount = this.orderService.orderDto.orderDetailDtos.filter(item => item.productDto.classCode === 'SH' && item.orderDetailStatus === ' ').length;

    if (shippingItemCount > 1) {
      await this.swalAlert.alert('Multiple ship items cannot be added. Please select another item.');
      this.orderService.deleteOrVoidOrderDetail(shippingOrderDetail);
      this.orderService.recalculateFields(this.orderService.orderDto);
      return false;
    }

    if (!hasOpenPhysicalItems) {
      await this.swalAlert.alert('There are no open shippable items on the order.');
      this.orderService.deleteOrVoidOrderDetail(shippingOrderDetail);
      this.orderService.recalculateFields(this.orderService.orderDto);
      return false;
    }

    this.setShipmentMethod(shippingOrderDetail?.productCode, isUsps);
    this.setRushCheckBox(false);

    this._shippinghandlingService.inProcess = false;
    await this._shippinghandlingService.recalculateShippingAndHandlingAsync(this.orderService.orderDto);
    this.orderService.recalculateFields(this.orderService.orderDto);
    return true;
  }
  async shippingAndHandlingBROnChangeShipVia(isPoBox: boolean, isApoFpo: boolean, isInternational: boolean, isUsps: boolean, isAlaskaHawaii: boolean, shippingOrderDetail: OrderDetailDto) {
    let productCode = '';

    if (this.orderService.orderDto.shipmentMethod === 'MISC') {
      this.showPackingSlipNotesDialog.show = true;
    } else if (this.orderService.orderDto.shipmentMethod === 'DONOTSHIP') {
      // DO NOTHING
    } else if (this.orderService.orderDto.shipmentMethod === 'USPS' && isPoBox) {
      productCode = '0053';
    }
    else if (this.orderService.orderDto.shipmentMethod !== 'UPS-AK/HI' && isAlaskaHawaii) {
      await this.swalAlert.alert('UPS-AK/HI must be used when shipping to Alaska or Hawaii.');
      this.orderService.orderDto.shipmentMethod = 'UPS-AK/HI';
    }
    else if (this.orderService.orderDto.shipmentMethod === 'USPS' && !isPoBox && !isApoFpo) {
      await this.swalAlert.alert('USPS can only be used when shipping to a PO Box or APO/FPO.');
      this.orderService.orderDto.shipmentMethod = this.orderService.orderDto.lastShipmentMethod;
    }
    else if ((this.orderService.orderDto.shipmentMethod === 'UPS' || this.orderService.orderDto.shipmentMethod === 'UPS-ONE'
      || this.orderService.orderDto.shipmentMethod === 'UPS-TWO') && !isPoBox && isApoFpo && isUsps) {
      await this.swalAlert.alert('USPS can only be used when shipping to APO/FPO.');
      this.orderService.orderDto.shipmentMethod = this.orderService.orderDto.lastShipmentMethod;
    }
    else{
      productCode = await this.shippingAndHandlingBROnChangeShipViaContinuation(isInternational, isAlaskaHawaii, productCode);
    }
    this.setRushCheckBox(true);
    this.orderService.updateSystemComments();
    if (productCode) {
      await this.orderService.reproductOrderDetail(shippingOrderDetail, productCode)
    }
    await this._shippinghandlingService.recalculateShippingAndHandlingAsync(this.orderService.orderDto);
    this.orderService.recalculateFields(this.orderService.orderDto);

  }
  async shippingAndHandlingBROnChangeShipViaContinuation(isInternational: boolean, isAlaskaHawaii: boolean, productCode: string): Promise<string>{
    if (this.orderService.orderDto.shipmentMethod === 'UPS-INT' && !isInternational) {
      await this.swalAlert.alert('UPS-INT can only be used when shipping internationally.');
      this.orderService.orderDto.shipmentMethod = this.orderService.orderDto.lastShipmentMethod;
    } else if (this.orderService.orderDto.shipmentMethod === 'UPS-AK/HI' && !isAlaskaHawaii) {
      await this.swalAlert.alert('UPS-AK/HI can only be used when shipping to Alaska or Hawaii.');
      this.orderService.orderDto.shipmentMethod = this.orderService.orderDto.lastShipmentMethod;
    }
    else if (this.orderService.orderDto.shipmentMethod !== 'UPS-INT' && isInternational) {
      await this.swalAlert.alert('UPS-INT must be used when shipping internationally.');
      this.orderService.orderDto.shipmentMethod = this.orderService.orderDto.lastShipmentMethod;
    }
    else{
      productCode = this.setProductCodeOnShipViaChange();
    }

    if (this.orderService.orderDto.shipmentMethod !== 'MISC') {
      this.orderService.orderDto.lastShipmentMethod = this.orderService.orderDto.shipmentMethod;
    }
    return productCode;
  }
  setProductCodeOnShipViaChange(): string{
    let productCode = '';
    if (this.orderService.orderDto.shipmentMethod === 'UPS-ONE') {
      productCode = '0050';
    } else if (this.orderService.orderDto.shipmentMethod === 'UPS-TWO') {
      productCode = '0051';
    } else if (this.orderService.orderDto.shipmentMethod === 'UPS') {
      productCode = '0053';
    } else if (this.orderService.orderDto.shipmentMethod === 'USPS') {
      productCode = '0053';
    }
    else if (this.orderService.orderDto.shipmentMethod === 'UPS-INT') {
      productCode = '0052';
    }
    return productCode;
  }
  async shippingAndHandlingBRPremiumShippingItemAdded(isUsps: boolean){
    this.setShipmentMethod(this._shippinghandlingService.premiumShippingItem.productCode, isUsps);
    this.setRushCheckBox(false);
    await this._shippinghandlingService.recalculateShippingAndHandlingAsync(this.orderService.orderDto);
    this.orderService.recalculateFields(this.orderService.orderDto);
  }
  getVariables() : [boolean, boolean, boolean, boolean, boolean]{
    const isAlaskaHawaii = this.orderService.orderDto.shipToDto.country === 'US'
      && (this.orderService.orderDto.shipToDto.state?.trim() === 'AK' || this.orderService.orderDto.shipToDto.state?.trim() === 'HI');
    const domesticStates = 'AL,AK,AZ,AR,CA,CO,CT,DE,DC,FL,GA,HI,ID,IL,IN,IA,KS,KY,LA,ME,MD,MA,MI,MN,MS,MO,MT,NE,NV,NH,NJ,NM,NY,NC,ND,' +
      'OH,OK,OR,PA,RI,SC,SD,TN,TX,UT,VT,VA,WA,WV,WI,WY';
    const isApoFpo = (this.orderService.orderDto.shipToDto.city?.toUpperCase()?.substring(0, 3) === 'APO'
      || this.orderService.orderDto.shipToDto.city?.toUpperCase()?.substring(0, 3) === 'FPO');
    const isInternational = (this.orderService.orderDto.shipToDto.country !== 'US'
      || domesticStates.indexOf(this.orderService.orderDto.shipToDto.state?.trim()) === -1) && !isApoFpo;
    const isPoBox = this.orderService.orderDto.shipToDto.street.replace('.', '').replace(' ', '').toUpperCase().includes('POBOX');
    const isUsps = (isApoFpo || isPoBox) && !isInternational;
    
    return [isAlaskaHawaii, isApoFpo,isInternational,isPoBox, isUsps];
  }
  async changeShippingAndHandlingBR(change: string, orderDetail: OrderDetailDto | null) {
    this.orderService.sourceType = SourceType.OrderEntry;
    // Remove duplicate open shipping and handling charges if they exist.
    let shippingAndHandlingItems;
    this.enableShippingChargeWarning = false;
    do {
      shippingAndHandlingItems = await this.validateIfShippingItemExist();
    } while (shippingAndHandlingItems.length > 1);
    // Setup variables to help with if conditions
    const [isAlaskaHawaii, isApoFpo, isInternational, isPoBox, isUsps] = this.getVariables();

    const hasOpenPhysicalItems = this.orderService.orderDto.orderDetailDtos.some(item => item.productDto.isPhysical && item.orderDetailStatus === ' ');
    const shippingOrderDetail = this.orderService.orderDto.orderDetailDtos.filter(item => item.productDto.classCode === 'SH' && item.orderDetailStatus !== 'V').slice(-1)[0];

    if (this.orderService.orderDto.lastShipmentMethod === null || this.orderService.orderDto.lastShipmentMethod === undefined) {
      this.orderService.orderDto.lastShipmentMethod = this.orderService.orderDto.shipmentMethod;
    }
    if (change === 'ContactAddress' && shippingOrderDetail) {
      await this.shippingAndHandlingBROnChangeContactAddress(shippingOrderDetail, isInternational, isPoBox, isUsps, isAlaskaHawaii);
    }
    else if (change === 'ItemAdjusted') {
      // If the user removed or voided the last shippable item, delete the open shipping charge.
      await this.shippingAndHandlingBROnChangeItemAdjusted(shippingOrderDetail, hasOpenPhysicalItems, isUsps);
    }
    else if (change === 'ShippingItemAdded') {
      if(!await this.shippingAndHandlingBROnChangeShippingItemAdded(shippingOrderDetail, hasOpenPhysicalItems, isUsps)){
        return;
      }
    }
    else if (change === 'ShipVia') {
      await this.shippingAndHandlingBROnChangeShipVia(isPoBox, isApoFpo, isInternational, isUsps, isAlaskaHawaii, shippingOrderDetail);
    }
    else if (change === 'PremiumShippingItemAdded') {
      await this.shippingAndHandlingBRPremiumShippingItemAdded(isUsps);
    }
    this.orderService.isShippingError = false;
    if (this._shippinghandlingService.isError) {
      this.orderService.isShippingError = true;
    }
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];    
  }

  setShipmentMethod(productCode: string, isUsps: boolean): void {
    if (this.orderService.orderDto.shipmentMethod === 'DONOTSHIP' || this.orderService.orderDto.shipmentMethod === 'MISC') {
      return;
    }

    if (!productCode) {
      this.orderService.orderDto.shipmentMethod = 'UPS';
    } else if (productCode === '0045') {
      this.orderService.orderDto.shipmentMethod = 'UPS-AK/HI';
    } else if (productCode === '0050') {
      this.orderService.orderDto.shipmentMethod = 'UPS-ONE';
    } else if (productCode === '0051') {
      this.orderService.orderDto.shipmentMethod = 'UPS-TWO';
    } else if (productCode === '0053' && isUsps) {
      this.orderService.orderDto.shipmentMethod = 'USPS';
    } else if (productCode === '0052') {
      this.orderService.orderDto.shipmentMethod = 'UPS-INT';
    } else {
      this.orderService.orderDto.shipmentMethod = 'UPS';
    }
    this.orderService.orderDto.lastShipmentMethod = this.orderService.orderDto.shipmentMethod;
  }


  setRushCheckBox(shipViaChanged: boolean) {
    if (this.orderService.orderDto.shipmentMethod === 'UPS-AK/HI'
      || this.orderService.orderDto.shipmentMethod === 'UPS-ONE'
      || this.orderService.orderDto.shipmentMethod === 'UPS-TWO'
      || this.orderService.orderDto.shipmentMethod === 'UPS-INT') {
      this.orderService.orderDto.isRush = true;
    } else if (shipViaChanged) {
      this.orderService.orderDto.isRush = false;
    }
  }
  revertShipViaValue() {
    this.orderService.orderDto.shipmentMethod = this.orderService.orderDto.lastShipmentMethod;
  }

  savePackingSlipNotes() {
    this.orderService.orderDto.lastShipmentMethod = this.orderService.orderDto.shipmentMethod;
  }

  changePaymentMethode() {
    this.orderFooterComponent?.onPaymentMethChange();
  }
  onAddCheckOrCashRow() {
    this.orderDetailsGridComponent?.addCheckOrCashRow();
  }
  updateItemDiscount(orderDetail: OrderDetailDto) {
    const newDiscount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, orderDetail.productId);
    if (newDiscount > orderDetail.discount) {
      orderDetail.discount = newDiscount;
    }
  }
  async onChangeShipToAddress(zipChanged: boolean) {
    if (zipChanged) {
      await this._taxService.calculateTax(SourceType.OrderEntryShipTozipChanged,this.orderService.orderDto);
      this.orderService.recalculateFields(this.orderService.orderDto);
    }
  }
  async onContactIdDetect() {

    this.orderService.orderDto.billToDto.id = await this.orderService.getAccountIdAsync(this.orderService.orderDto.orderByDto.contactId);
    if (this.orderService.orderDto.billToDto.id) {

      await this._billToService.getBillToInformationAsync(this.orderService.orderDto.billToDto.id, this.currentYear);
      this._billToService.reloadBillToDto();
      await this._contactService.getContactsAsync(this.orderService.orderDto.billToDto.id);
      this.orderService.recalculateFields(this.orderService.orderDto);
    }
  }

  changePromoCode(event:any) {
    this._orderComponent?.onChangePromoCode(event);
  }

  showMultiYearOrders(): void {
    this.showViewMultiYearOrdersPopup.show = true;
  }
  refreshBrowser(){
    if ('orderEntryOrderDto' in localStorage && 'isEditMode' in localStorage) {
      const orderDto: OrderDto = JSON.parse(localStorage.getItem('orderEntryOrderDto')!);
      const isEditMode: string = localStorage.getItem('isEditMode')!;
      if (orderDto.id > 0 && orderDto.orderStatus === 'D' && isEditMode !== 'true') {
        localStorage.removeItem('orderEntryOrderDto');
        localStorage.removeItem('isEditMode');
      }
    }
  }
  async setReshipData(): Promise<void> {
    const reshipReturnId = parseInt(localStorage.getItem('reshipReturnId') as string);
    await this.orderService.getOrderInformationAsync(reshipReturnId);
    this.orderService.orderDto.id = 0;
    this.orderService.orderDto.orderStatus = 'W';
    this.orderService.orderDto.orderType = '';
    this.orderService.orderDto.promotionCode = 'CSRSH';
    this.orderService.orderDto.sourceCode = 'CSRSH';
    this.orderService.orderDto.enteredBy = this._configService.getMyMSId().toUpperCase().replace('MS\\', '');
    this.orderService.orderDto.orderNotes = [];
    this.orderService.orderDto.originalOrderId = null;
    this.orderService.orderDto.promotionHtml = '';
    if (this.orderDetailsGridComponent) {
      this.orderDetailsGridComponent.sourceCode = { id: this.orderService.orderDto.sourceCode ?? '', label: this.orderService.orderDto.sourceCode ?? '', value: this.orderService.orderDto.sourceCode ?? '' };
    }

    if (this.reshipOrderDetails.length === 0) {
      this.updateOrderDetailForReshipItems();
    }
    this.orderService.recalculateFields(this.orderService.orderDto);
    localStorage.setItem('reshipReturnId', "")
  }
  reshipOrderDetails: OrderDetailDto[] = [];
  updateOrderDetailForReshipItems() {
    let x = 0;
    this.reshipOrderDetails = [];
    this.orderService.orderDto.orderDetailDtos.forEach(element => {
      if (element.productCode !== '' || element.productId != 0)
        this.addOrderDetail(element);

    });
    this.orderService.orderDto.orderDetailDtos = [...this.reshipOrderDetails];
    this.orderDetailsGridComponent?.addNewLineItem();
  }
  async addOrderDetail(item: OrderDetailDto): Promise<void> {
    const product = this._configService.reference?.productDtos.filter(p => p.id === item.productId)[0];
    if (!product) {
      this.swalAlert.alert("Item is inactive or does not exist");
      return;
    }
    const newOrderDetail = this.orderService.createNewOrderDetail(this.orderService.orderDto, product.productCode);
    newOrderDetail.listPrice = product.listPrice;
    newOrderDetail.discount = item.discount;
    newOrderDetail.openQuantity = Math.abs(item.orderQuantity);
    newOrderDetail.shippedQuantity = 0;
    this._pricingService.calculateOpenAmount(this.orderService.orderDto);
    this.reshipOrderDetails.push(newOrderDetail);
    this.reshipOrderDetails = [...this.reshipOrderDetails];
    await this.openConfigSetAsync(newOrderDetail, true);
  }

  async openConfigSetAsync(orderDetail: OrderDetailDto, isEdit: boolean = false) {
    if (orderDetail.productDto.configSetDtos.length === 0) {
      return;
    }

    if (isEdit) {
      localStorage.setItem('isEditMode', 'true');
    }

    this.reshipOrderDetail = orderDetail;
    const shippingOrderDetail = orderDetail.productDto.classCode === 'SH';
    if (shippingOrderDetail) {
      this.orderService.configurationOrderDetail = this.reshipOrderDetail;
      await this.orderService.shippingDialogModal.openAsync();
    }
    else {
      const result = await this.configSetDialog.openAsync();

      if (result === 'Cancel') {
        this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item.id !== this.reshipOrderDetail.id);
        this.orderService.recalculateFields(this.orderService.orderDto);
      }
    }
  }

  triggerdEditFromURL(){
    if (window.history.length === 1) {
      setTimeout(async () => {
        if (this.modeService.mode === Mode.View) {
          this.orderService.triggerEditMethod();
          if(!this.verifyItemDialog){
            this.orderService.flagforitemdialog=false;
            await this.orderService.reviewAndUpdateRequiredDate();
            this.verifyItemDialog = true;
            this.orderService.flagforitemdialog=true;
          }
        }
      }, 3000);
    }  
  }
  enableAccountHyperLink() {
    if (this.accountId && this.contactId){
      this.orderService.orderDto.billToDto.enableAccountLinks = true;
      this._contactService.gotContacts.next();
  }
    else {
      this.orderService.orderDto.billToDto.enableAccountLinks = false;
      this.orderService.orderDto.billToDto.isAccountFlow = true;
    }

    if ((this.leadIdDisplay && this.accountId) || (this.oppurtunityIdDisplay && this.accountId) || (this.leadIdDisplay && this.accountId && this.oppurtunityIdDisplay)) {
      this.orderService.orderDto.billToDto.enableAccountLinks = true;
    }
    else if (this.leadIdDisplay || this.oppurtunityIdDisplay || (this.leadIdDisplay && this.oppurtunityIdDisplay)) {
      this.orderService.orderDto.billToDto.enableAccountLinks = false;
    }
  }
  async loadBillToAndContactDtos(){
    this.orderService.orderDto.billToDto.id = this.accountId;
    await this._billToService.getBillToInformationAsync(this.accountId, this.currentYear);
    this._billToService.reloadBillToDto();
    this._contactService.contactDtos = await this._contactService.getContactsAsync(this.accountId);
    if (this._contactService.contactDtos) {
      this._contactService.shipToContactList = this._contactService.convertContactsToUitkProps(this._contactService.contactDtos);
      this._contactService.orderByContactList = this._contactService.convertContactsToUitkProps(this._contactService.contactDtos);
      this._contactService.shipToContacts = this._contactService.convertContactsToArray(this._contactService.contactDtos);
    }

    if (this.contactId) {
      this.orderService.orderDto.orderByDto.contactId = this.contactId;
    }

    this.billToIsReadySubscription = this._billToService.billToIsReady.subscribe(() => {
      this.orderService.orderDto.billToDto = this._billToService.billToDto;
      this.enableAccountHyperLink();
    });
  }
  async redirectToSnapshotScreen(){
    if(await this.orderService.getOrderSnapshotAsync(this.orderId) !== null){
      this._route.navigate(['/order-entry/']);
      this.clearPage();
      this._configService.loadConfigurations();
    }
  }
  redirectToOrderOrReturnScreen(){
    if (this.orderService.orderDto.orderType === 'E' || (this.orderService.orderDto.orderType === ' ' && ' ,H'.indexOf(this.orderService.orderDto.orderStatus) > -1)) {
      this._route.navigate(['/order/' + this.orderService.orderDto.id]);
    }
    else if (this.orderService.orderDto.orderType === 'R' || this.orderService.orderDto.orderType === 'A') {
      this._route.navigate(['/return/' + this.orderService.orderDto.id]);
    }
    else {
      if (this.orderService.orderDto.billToDto.id && this.orderService.orderDto.billToDto.id > 0 && this._modeService.mode === Mode.Edit && this.orderService.orderDto.id <= 0) {
        this.recentQuotesOrOrdersDialog.show = true;
      }
    }
  }
  async navigateToCorrectScreen(){
    if(this._activatedroute.snapshot.url[1]?.path === 'snapshot'){
      this.redirectToSnapshotScreen();
    }
    else{
      await this.orderService.getOrderInformationAsync(this.orderId);
      this.redirectToOrderOrReturnScreen();
    }
    if (this._activatedroute.snapshot.queryParams.EditMode === 'true') {
      this.triggerdEditFromURL();
    }      
  }
  focusOnAccountId(){
    if (!this.orderService.orderDto.billToDto.id || this.orderService.orderDto.billToDto.id === 0) {
      setTimeout(() => {
        if (document.getElementById(`order-type-select-dropdown`)) {
          document.getElementById(`order-type-select-dropdown`)?.focus();
        }
      });  
    }  
  }
  ngOnDestroy() {
    this.configIsReadySubscription?.unsubscribe();
    this.billToIsReadySubscription?.unsubscribe();
  }

 getContactListForAccount(){
    this.getAllContactList.emit();
  }

  @HostListener('change', ['$event'])
  onChange(e: any) {
    this.orderService.orderDto.isOrderEntryContentChanged = true;
  }

  @HostListener('input', ['$event'])
  onInput(e: any) {
    this.orderService.orderDto.isOrderEntryContentChanged = true;
  }

  //#region To handle browser refresh button for orderentry draft
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    localStorage.setItem('orderEntryOrderDto', JSON.stringify(this.orderService.orderDto));
    localStorage.setItem('isEditMode', this.modeService.mode === Mode.Edit ? 'true' : 'false');
  }
  //#endregion
}
