<div class="container-fluid px-0 bg-lightgrey">
  <div class="row gx-0">
    <div class="col-12">
      <div class="container-fluid px-4 my-3">
        <div class="row bg-light gx-0 mt-4">
          <div class="col">
            <div class="container-fluid px-4 my-3">
              <div class="row">
                <div class="col">
                  <h5 id="caption" class="headerTextClass">View Promotions:</h5>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col d-flex">
                  <uitk-form-field>
                    <input style="width: 200px" uitkInput id="promotion-code-textbox" class="textbox"
                      (keydown.enter)="clickSearchPromotion()" [(ngModel)]="promotionCode" placeholder="Enter Promotion Code"
                      type="text" appTextFormat />                      
                  </uitk-form-field>
                  <button style="width: 120px" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs mx-3"
                    (click)="clickSearchPromotion()">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Spinner -->
<div class="col-12" style="padding-top: 400px" [hidden]="!modeService.isWaiting">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div id="loadingIndicator">
          <uitk-loading-indicator uitkId="spinner" spinnerSize="large">
          </uitk-loading-indicator>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col d-flex justify-content-center" >
        <h5>
          {{modeService.waitDescription}}
        </h5>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid px-4 mb-4" [hidden]="modeService.isWaiting">
  <div class="row mt-3">
    <div class="col">
      <h5>Campaign</h5>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-sm-4">
      <div class="row">
        <label class="col-lg-4" uitkLabel>Campaign</label>
        <div class="col-lg-8">
          {{ campaignDto.campaignId }}
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="row">
        <label class="col-lg-4" uitkLabel>Description</label>
        <div class="col-lg-8">
          {{ campaignDto.description }}
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="row">
        <label class="col-lg-4" uitkLabel>Budgeted Start Date</label>
        <div class="col-lg-8">
          {{ campaignDto.budgetedStartDate | date: 'MM/dd/yyyy' }}
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-sm-4">
      <div class="row">
        <label class="col-lg-4" uitkLabel>Status</label>
        <div class="col-lg-8">
          {{ campaignDto.status === 'A' ? 'Active' : 'Inactive' }}
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="row">
        <label class="col-lg-4" uitkLabel>Setup Date</label>
        <div class="col-lg-8">
          {{ campaignDto.setupDate | date: 'MM/dd/yyyy' }}
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="row">
        <label class="col-lg-4" uitkLabel>Entered By</label>
        <div class="col-lg-8">
          {{ campaignDto.enteredBy }}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <hr>
    </div>
  </div>
  <div class="row">
    <div class="col-2 border border-top-0 border-bottom-0 border-start-0 border-end-2 border-dark">
      <div *ngIf="campaignDto.itemLevelOffers.length > 0">
        <div class="row">
          <div class="col"><strong>Item Level Offers</strong><br><hr></div>
        </div>
        <div *ngFor="let itemOffer of campaignDto.itemLevelOffers" class="row">
          <div class="col">
            <span><a (click)="selectedItemOffer = itemOffer; selectedOrderOffer = null;" class="link">{{ itemOffer.offerItemKey }}</a> - {{ itemOffer.status === 'A' ? 'Active' : 'Inactive' }}</span><br>
            {{ itemOffer.description }}<br>
            <hr>
          </div>
        </div>
      </div>
      <div *ngIf="campaignDto.orderLevelOffer" class="row">
        <div class="row">
          <div class="col"><strong>Order Level Offers</strong><br><hr></div>
        </div>
        <div class="row">
          <div class="col">
            <span><a (click)="selectedOrderOffer = campaignDto.orderLevelOffer; selectedItemOffer = null;" class="link">{{ campaignDto.orderLevelOffer.offerOrderKey }}</a> - {{ campaignDto.orderLevelOffer.status === 'A' ? 'Active' : 'Inactive' }}</span><br>
            {{ campaignDto.orderLevelOffer.description }}<br>
            <hr>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedItemOffer" class="col-10">
      <div class="container-fluid">
        <div class="row">
          <div class="col"><h5>Item Level Offer</h5></div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-3">
            <div class="row">
              <label class="col-lg-4" uitkLabel>Offer Id</label>
              <div class="col-lg-8">
                {{ selectedItemOffer.offerItemKey }}
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="row">
              <label class="col-lg-4" uitkLabel>Expiration Date</label>
              <div class="col-lg-8">
                {{ selectedItemOffer.expirationDate ? (selectedItemOffer.expirationDate | date: 'MM/dd/yyyy') : 'Does not Expire' }}
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="row">
              <label class="col-lg-4" uitkLabel>Offer Type</label>
              <div class="col-lg-8">
                {{ selectedItemOffer.discountAndPremium ? 'Discount and Premium(s)' : 'Discount or Premium(s)' }}
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <label class="col-lg-1" uitkLabel>Script</label>
          <div class="col-lg-11">
            <div [innerHtml]="selectedItemOffer.script | safeHtml"></div>
          </div>
        </div>
        <div class="row mt-5">
          <label class="col" uitkLabel><h5>Products</h5></label>
        </div>
        <div *ngIf="selectedItemOffer.products.length > 0" class="row mt-2">
          <div class="col-8">
            <div class="overflow-auto max-height-300">
              <table class="table">
                <thead>
                  <tr class="position-sticky top-0 z-index-1 bg-light">
                    <th>Product Code</th>
                    <th>Description</th> 
                    <th class="text-end">List Price</th>
                    <th class="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of selectedItemOffer.products">
                    <td>{{product.productCode}}</td>
                    <td>{{product.description}}</td>
                    <td class="text-end">{{ product.listPrice | currency }}</td>
                    <td class="text-center">{{ product.statusCode === 'A' ? 'Active' : 'Inactive' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="selectedItemOffer.products.length === 0" class="col-lg-3">No Products Found</div>
        <div class="row mt-5">
          <label class="col" uitkLabel><h5>Discounts</h5></label>
        </div>
        <div *ngIf="selectedItemOffer.tiers.length > 0" class="row mt-2">
          <div class="col-3">
            <table class="table">
              <thead>
                <tr>
                  <th>Tier</th>
                  <th class="text-center">From Qty</th>
                  <th class="text-center">To Qty</th>
                  <th class="text-center">Percent Off</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let tier of selectedItemOffer.tiers; let i = index">
                  <td>{{i+1}}</td>
                  <td class="text-center">{{tier.from}}</td>
                  <td class="text-center">{{tier.to}}</td>
                  <td class="text-center">{{tier.discount/100 | percent}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="selectedItemOffer.tiers.length === 0" class="col-lg-3">Offer has no Discounts</div>
        <div class="row mt-5">
          <label class="col" uitkLabel><h5>Premiums</h5></label>
        </div>
        <div *ngIf="selectedItemOffer.premiums.length > 0" class="row mt-3">
          <div class="col-sm-3">
            <div class="row">
              <label class="col-lg-6" uitkLabel>Repeat Premium?</label>
              <div class="col-lg-1">
                {{selectedItemOffer.repeatPremiums ? 'No' : 'Yes'}}
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="row">
              <label class="col-lg-10" uitkLabel>Number of Items to Qualify for Premiums</label>
              <div class="col-lg-1">
                {{selectedItemOffer.premiumQualifyAmount}}
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="row">
              <label class="col-lg-10" uitkLabel>Number of Premiums to Select</label>
              <div class="col-lg-1">
                {{selectedItemOffer.allPremiums ? 'All' : 'One' }}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedItemOffer.premiums.length > 0" class="row mt-2">
          <div class="col-10">
            <div class="overflow-auto max-height-300">
              <table class="table">
                <thead>
                  <tr class="position-sticky top-0 z-index-1 bg-light">
                    <th>Product Code</th>
                    <th>Description</th>
                    <th class="text-end">List Price</th>
                    <th class="text-end">Discount</th>
                    <th class="text-end">New Price</th>
                    <th class="text-center">Quantity</th>
                    <th class="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let premium of selectedItemOffer.premiums">
                    <td>{{premium.productCode}}</td>
                    <td>{{premium.description}}</td>
                    <td class="text-end">{{premium.listPrice | currency}}</td>
                    <td class="text-end">{{premium.discount/100 | percent}}</td>
                    <td class="text-end">{{premium.quantity * (premium.listPrice * (100 - premium.discount)/100)  | currency}}</td>
                    <td class="text-center">{{premium.quantity}}</td>
                    <td class="text-center">{{premium.statusCode === 'A' ? 'Active' : 'Inactive'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="selectedItemOffer.premiums.length === 0" class="col-lg-3">Offer has no Premiums</div>
        <div class="row mt-5">
          <label class="col" uitkLabel><h5>Additional On Line Discounts</h5></label>
        </div>
        <div *ngIf="selectedItemOffer.onlinePercentOff + selectedItemOffer.onlineDollarOff === 0" class="col-lg-6">
          <div class="row">
            <div class="col-sm-6">
              {{ 'None' }}
            </div>
          </div>
        </div>
        <div *ngIf="selectedItemOffer.onlinePercentOff + selectedItemOffer.onlineDollarOff > 0" class="row mt-2">
          <div class="col-sm-2">
            <div class="row">
              <label class="col-lg-4" uitkLabel>Discount</label>
              <div *ngIf="selectedItemOffer.onlinePercentOff > 0" class="col-lg-4">{{ selectedItemOffer.onlinePercentOff/100 | percent }}</div>
              <div *ngIf="selectedItemOffer.onlineDollarOff > 0" class="col-lg-4">{{ selectedItemOffer.onlineDollarOff | currency }}</div>            
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <label class="col-sm-4" uitkLabel>Discount and Premium</label>
              <div class="col-sm-6">
                {{selectedItemOffer.onlineDiscountAndPremium ? 'Yes' : 'Select either Discount or Premium'}}
              </div>
            </div>
          </div>
        </div>
     </div>
    </div>

    <div *ngIf="selectedOrderOffer" class="col-10">
      <div class="container-fluid">
        <div class="row">
          <div class="col"><h5>Order Level Offer</h5></div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-3">
            <div class="row">
              <label class="col-lg-4" uitkLabel>Offer Id</label>
              <div class="col-lg-8">
                {{ selectedOrderOffer.offerOrderKey }}
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="row">
              <label class="col-lg-4" uitkLabel>Expiration Date</label>
              <div class="col-lg-8">
                {{ selectedOrderOffer.expirationDate ? (selectedOrderOffer.expirationDate | date: 'MM/dd/yyyy') : 'Does not Expire' }}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <label class="col-lg-3" uitkLabel>Offer Type</label>
              <div *ngIf="selectedOrderOffer.offerType === 1" class="col-lg-8">
                {{ 'Tier Offer based on Dollar Amount' }}
              </div>
              <div *ngIf="selectedOrderOffer.offerType !== 1" class="col-lg-8">
                {{ 'Tier Offer based on Quantity Amount' }}
              </div>
          </div>
          </div>
          <div class="row mt-3">
            <label class="col-lg-1" uitkLabel>Script</label>
            <div class="col-lg-11">
              <div [innerHtml]="selectedOrderOffer.script | safeHtml">
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <label class="col" uitkLabel><h5>Products</h5></label>
        </div>
        <div *ngIf="selectedOrderOffer.products.length > 0" class="row mt-2">
          <div class="col-8">
            <div class="overflow-auto max-height-300">
              <table class="table">
                <thead>
                  <tr class="position-sticky top-0 z-index-1 bg-light">
                    <th>Product Code</th>
                    <th>Description</th> 
                    <th class="text-end">List Price</th>
                    <th class="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of selectedOrderOffer.products">
                    <td>{{product.productCode}}</td>
                    <td>{{product.description}}</td>
                    <td class="text-end">{{ product.listPrice | currency }}</td>
                    <td class="text-center">{{ product.statusCode === 'A' ? 'Active' : 'Inactive' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="selectedOrderOffer.products.length === 0" class="row mt-2">
          <div class="col-6">
            {{ "All products on the order qualify" }}
          </div>
        </div>
        <div class="row mt-5">
          <label class="col" uitkLabel><h5>Discounts</h5></label>
        </div>
        <div *ngIf="selectedOrderOffer.tiers.length > 0" class="row mt-1">
          <div class="col-6">
            <table class="table">
              <thead>
                <tr>
                  <th>Tier</th>
                  <th *ngIf="selectedOrderOffer.offerType === 1" class="text-center">Dollar Amt From</th>
                  <th *ngIf="selectedOrderOffer.offerType === 1" class="text-center">Dollar Amt To</th>
                  <th *ngIf="selectedOrderOffer.offerType !== 1" class="text-center">Quantity Amt From</th>
                  <th *ngIf="selectedOrderOffer.offerType !== 1" class="text-center">Quantity Amt To</th>
                  <th class="text-center">Percent Off</th>
                  <th class="text-center">Discount and Premium</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let tier of selectedOrderOffer.tiers; let i = index">
                  <td>{{ i+1 }}</td>
                  <td class="text-center">{{ tier.from | number }}</td>
                  <td class="text-center">{{ tier.to | number }}</td>
                  <td class="text-center">{{ tier.discount/100 | percent }}</td>
                  <td class="text-center">{{ tier.discountAndPremium }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="selectedOrderOffer.tiers.length === 0" class="col-lg-3">Offer has no Discounts</div>
        <div class="row mt-5">
          <label class="col" uitkLabel><h5>Premiums</h5></label>
        </div>
        <div *ngIf="selectedOrderOffer.premiums.length > 0" class="row mt-3">
          <div class="col-sm-3">
            <div class="row">
              <label class="col-lg-10" uitkLabel>Number of Premiums to Select</label>
              <div class="col-lg-1">
                {{selectedOrderOffer.allPremiums ? 'All' : 'One' }}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedOrderOffer.premiums.length > 0" class="row mt-3">
          <div class="col-10">
            <div class="overflow-auto max-height-300">
              <table class="table">
                <thead>
                  <tr class="position-sticky top-0 z-index-1 bg-light">
                    <th>Product Code</th>
                    <th>Description</th>
                    <th class="text-end">List Price</th>
                    <th class="text-end">Discount</th>
                    <th class="text-end">New Price</th>
                    <th class="text-center">Quantity</th>
                    <th class="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let premium of selectedOrderOffer.premiums">
                    <td>{{premium.productCode}}</td>
                    <td>{{premium.description}}</td>
                    <td class="text-end">{{premium.listPrice | currency}}</td>
                    <td class="text-end">{{premium.discount/100 | percent}}</td>
                    <td class="text-end">{{premium.quantity * (premium.listPrice * (100 - premium.discount)/100)  | currency}}</td>
                    <td class="text-center">{{premium.quantity}}</td>
                    <td class="text-center">{{ premium.statusCode === 'A' ? 'Active' : 'Inactive' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="selectedOrderOffer.premiums.length === 0" class="col-lg-3">Offer has no Premiums</div>
        <div class="row mt-5">
          <label class="col" uitkLabel><h5>Additional On Line Discounts</h5></label>
        </div>
        <div *ngIf="selectedOrderOffer.onlinePercentOff + selectedOrderOffer.onlineDollarOff === 0" class="col-lg-6">
          <div class="row">
            <div class="col-sm-6">
              {{ 'None' }}
            </div>
          </div>
        </div>
        <div *ngIf="selectedOrderOffer.onlinePercentOff + selectedOrderOffer.onlineDollarOff > 0" class="row mt-2">
          <div class="col-sm-2">
            <div class="row">
              <label class="col-lg-4" uitkLabel>Discount</label>
              <div *ngIf="selectedOrderOffer.onlinePercentOff > 0" class="col-lg-4">{{ selectedOrderOffer.onlinePercentOff/100 | percent }}</div>
              <div *ngIf="selectedOrderOffer.onlineDollarOff > 0" class="col-lg-4">{{ selectedOrderOffer.onlineDollarOff | currency }}</div>            
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <label class="col-sm-4" uitkLabel>Discount and Premium</label>
              <div class="col-sm-6">
                {{selectedOrderOffer.onlineDiscountAndPremium ? 'Yes' : 'Select either Discount or Premium'}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
