import { Directive, HostListener, ElementRef, OnInit, Renderer2, Input, SimpleChange } from '@angular/core';

@Directive({
  selector: '[scrollToTarget]'
})
export class ScrollToDirective {
  @Input() target!: string;
  @HostListener('click')
  onClick() {
      if(this.target){
       this.scrollToPosition();
      }   
  }
  @HostListener('focus') focusEvent(): void {
    if (this.target) {
      const targetElement = document.querySelector(this.target) as HTMLElement;
      setTimeout(() => {
        if(targetElement){
          targetElement.scrollIntoView();
        } 
      })
    }
  }
  
  scrollToPosition(){
    const targetElement = document.querySelector(this.target) as HTMLElement;
        setTimeout(()=>{
          if(targetElement){
            targetElement.scrollIntoView();
          } 
        });
  }
}
