import { Component, Input } from '@angular/core';
import { ConfigService } from '../../../../services/config.service';
import { OrderService } from '../../../../services/order.service';

@Component({
  selector: 'app-cfd-form-account-info',
  templateUrl: './cfd-form-account-info.component.html',
  styleUrls: ['./cfd-form-account-info.component.css']
})

export class CfdFormAccountInfoComponent {

  private readonly _configService: ConfigService;
  @Input() enteredByUserRole : string | null = null;
  constructor(public orderService: OrderService, configService: ConfigService) {
    this._configService = configService;
  }

  generateFullName(firstName: string | null, lastName: string | null): string {
    return [firstName, lastName].filter(Boolean).join(' ');
  }

  generateCityStateZip(city: string | null, state: string | null | undefined, zip: string | null): string {
    if(this.orderService.orderDto?.paymentMethod === 'S'){
      zip = this.orderService?.selectedShipToContact?.mailingStreet ? this.orderService.selectedShipToContact?.mailingZip
        : this.orderService?.selectedShipToContact?.street ? this.orderService.selectedShipToContact?.zip
          : this.orderService.orderDto?.shipToDto?.zip;
      state = this.orderService?.selectedShipToContact?.mailingStreet ? this.orderService?.selectedShipToContact?.mailingStateBinding?.value
        : this.orderService?.selectedShipToContact?.street ? this.orderService?.selectedShipToContact?.stateBinding?.value
          : this.orderService.orderDto?.shipToDto?.stateBinding?.value;
      city = this.getCityDetails();
    }

    return [[city, state].filter(Boolean).join(', '), zip].filter(Boolean).join(' ');
  }

  getCityDetails(){
    return this.orderService?.selectedShipToContact?.mailingStreet ? this.orderService.selectedShipToContact?.mailingCity
      : this.orderService?.selectedShipToContact?.street ? this.orderService.selectedShipToContact?.city
        : this.orderService.orderDto?.shipToDto?.city;
  }
  getClientManagerName(): string {
    if (this.orderService.orderDto.salesOrganization === 'Shutterfly') {
      if (this.orderService.orderDto.billToDto.accountTypeCode === 'O') {
        return this.enteredByUserRole === "SystemAdministrator" || this.enteredByUserRole === "OrderManagement" ? "Melissa Werth" : this._configService.getMyFullName();
      }
      else {
        return this.orderService.orderDto.billToDto.printRepCode? this._configService.getFullNameFromUserCode(this.orderService.orderDto.billToDto.printRepCode):'';
      }   
    }
    return this._configService.getFullNameFromUserCode(this.orderService.orderDto.billToDto.accountOwnerCode);
  }
}
