import { Component, OnInit, ViewChild, ViewEncapsulation,Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IUITKColumnState, UITKSortDirection, UITKTableComponent, UITKTableDataSource, UITKTableSortDirective } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Mode } from 'src/app/enums/mode.enum';
import { ModeService } from 'src/app/services/mode.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SubscriptionSearchResult } from '../../dtos/subscription-search-result.model';
@Component({
  selector: 'app-subscription-history',
  templateUrl: './subscription-history.component.html',
  styleUrls: ['./subscription-history.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SubscriptionHistoryComponent implements OnInit {
  private readonly _subscriptionService: SubscriptionService;
  @Input() subscriptionSearchResult: SubscriptionSearchResult = new SubscriptionSearchResult();
  @Input() dialog = { show: false };
  @ViewChild('sortTable') uitkTableSort!: UITKTableSortDirective;
  @ViewChild('rootTable') uitkTable!: UITKTableComponent<SubscriptionSearchResult[]>;
  tableHeader = [
    { name: 'Sub #', id: 'subscriptionNumber' },
    { name: 'Item', id: 'productCode' },
    { name: 'Description', id: 'productDescription' },
    { name: 'Qty', id: 'quantity' },
    { name: 'Start Date', id: 'startDate' },
    { name: 'Term', id: 'term' },
    { name: 'Exp Date', id: 'expirationDate' },
    { name: 'Sub Status', id: 'subscriptionStatus' },
    { name: 'Sub Type', id: 'subscriptionType' },
    { name: 'Order #', id: 'salesOrderNumber' },
    { name: 'Upd Only', id: 'updateOnly' },
  ];

  selectedSubscription: SubscriptionSearchResult = new SubscriptionSearchResult();
  dataSource = new UITKTableDataSource<SubscriptionSearchResult>([]);
  subscriptionHistoryIsReadySubscription: Subscription | undefined;
  sortColumn: string = 'startDate';
  Mode = Mode;
  subscriptionEditDialog = { show: false };

  constructor(subscriptionService: SubscriptionService, public modeService: ModeService) {
    this._subscriptionService = subscriptionService;
  }
  
  profileForm = new UntypedFormGroup({
    checkbox: new UntypedFormControl(),
  });

  ngOnInit(): void {
    this.subscriptionHistoryIsReadySubscription = this._subscriptionService.subscriptionHistoryIsReady.subscribe(() => {
      this.dataSource.data = [...this._subscriptionService.subscriptionHistoryDto];
      this.modeService.mode = Mode.View;
    });

    this.showNormalDialog();
  }
  getSubscriptionHistoryRecords() {
    this._subscriptionService.getSubscriptionHistory(this.subscriptionSearchResult.accountId, this.subscriptionSearchResult.subscriptionNumber);
  }

  showNormalDialog() {
    this.getSubscriptionHistoryRecords();
    this.uitkTableSort.setDirection({ column: this.sortColumn, direction: UITKSortDirection.UNSORTED });
    this.uitkTableSort.setDirection({ column: 'startDate', direction: UITKSortDirection.DESC });

  }
  closeDialog() {
    this.dialog.show = false;
  }
  onSortChange(event: IUITKColumnState): void {
    this.sortColumn = event.column;
    this.dataSource.data = this.dataSource.sortData(this.dataSource.data, event.direction, event.column);
  }
  ngOnDestroy() {
    this.subscriptionHistoryIsReadySubscription?.unsubscribe();
  }
}
