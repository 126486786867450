export class DepositDto {
  action = '';
  id = 0;
  oti = '';
  referenceNumber = '';
  orderId = 0;
  reservedAmount = 0.0;
  accountId = 0;
  userCode = '';
}
