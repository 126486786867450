import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { IUITKColumnState, IUITKSelectItemProps, UITKSortDirection, UITKTableDataSource, UITKTableSortDirective, 
  IUITKPaginationConfig, UITKHeadingLevel, IUITKPaginationEntriesPerPage, PAGINATION_EVENTS, IUITKPaginationEvent } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { AccountBalanceDto } from '../../../dtos/account-balance-dto-model';
import { BillToDto } from 'src/app/dtos/bill-to-dto.model';
import { ConfigService } from 'src/app/services/config.service';
import { ModeService } from 'src/app/services/mode.service';
import { AccountResultDto } from '../../../dtos/account-result-dto.model';
import { AccountsReceivableResultsDto } from '../../../dtos/accounts-receivable-results-dto.model';
import { InvoiceDetailsDto } from '../../../dtos/invoice-details-dto.model';
import { OpenReceivableDto } from '../../../dtos/open-receivable-dto.model';
import { PaymentDetailDto } from '../../../dtos/payment-detail-dto.model';
import { AccountsReceivableService } from '../../../services/accounts-receivable.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { InvoicesService } from '../../../services/invoices.service';
import { InvoiceEditComponent } from '../invoice-edit/invoice-edit.component';
import { Dialog } from 'src/app/helpers/dialog';

@Component({
  selector: 'app-accounts-receivable-results',
  templateUrl: './accounts-receivable-results.component.html',
  styleUrls: ['./accounts-receivable-results.component.css']
})
export class AccountsReceivableResultsComponent implements OnInit, OnDestroy {
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _authorizationService: AuthorizationService;
  private readonly _titleService: Title;
  private readonly _modeService: ModeService;
  @ViewChild('sortTable') uitkTableSort!: UITKTableSortDirective;
  @ViewChild(InvoiceEditComponent) invoiceEditComponent: InvoiceEditComponent | undefined;
  @ViewChild('sortPaymentTable') uitkPaymentTableSort!: UITKTableSortDirective;
  @Input() invoiceNumber = '';
  @Output() invoiceEdited = new EventEmitter<null>();

  currentPageSize: number = 500;
  paginationConfig: IUITKPaginationConfig = {
    id: 'pagination-id',
    description: 'Pagination',
    currentPageNumber: 1,
    displayingPagesTextHeadingLevel: UITKHeadingLevel.h2,
    nextButtonLabel: '>>',
    previousButtonLabel: '<<',
  };

  entriesPerPage: IUITKPaginationEntriesPerPage = {
    pageSizeOptions: [{ value: 500, selected: true }],
  };

  totalRecords = 1;

  constructor(
    activatedRoute: ActivatedRoute,
    public accountsReceivableService: AccountsReceivableService,
    authorizationService: AuthorizationService,
    public invoiceService: InvoicesService,
    public configService: ConfigService,
    titleService: Title,
    modeService: ModeService
  ) { 
    this._activatedRoute = activatedRoute;
    this._authorizationService = authorizationService;
    this._titleService = titleService;
    this._modeService = modeService;
  }

  defaultLabel = 'Please Select';
  defaultLabelFlag = false;
  getAccountsReceivableResultsSubscription: Subscription | undefined;
  getSelectedInvoicesSubscription: Subscription | undefined;
  accountInfo: AccountResultDto = new AccountResultDto();
  accountFields: BillToDto[] = [];
  accountFieldsSingle: BillToDto = new BillToDto();
  accountBalanceDto: AccountBalanceDto = new AccountBalanceDto();
  accountManager: any;
  accountManagerEmail:string | undefined;
  dataSource = new UITKTableDataSource<InvoiceDetailsDto>([]);
  data: InvoiceDetailsDto[] = [];
  openReceivablesDataSource = new UITKTableDataSource<OpenReceivableDto>([]);
  filteredOpenReceivablesDataSource = new UITKTableDataSource<OpenReceivableDto>([]);
  getPaymentsSubscription: Subscription | undefined;
  paymentDetailsDataSource = new UITKTableDataSource<PaymentDetailDto>([]);
  filteredPaymentDetailsDataSource = new UITKTableDataSource<PaymentDetailDto>([]);
  isDataFound = true;
  selectedInvoices: InvoiceDetailsDto[] = [];
  openSendInvoicePDFPopup: boolean = false;
  sendInvoicePdfDialog = { show: false };
  showSelectedTemplate=new Dialog<string>();
  showTemplateEmail=new Dialog<void>();
  sendCCPdfAccess = false;
  tabLabel='Account Receivable';
  creditDescription = '';
  openReceivablesGridFilters: { fieldName: string, filterValue: string, filterApplied: boolean, columnName: string }[] = [];
  paymentGridFilters: { fieldName: string, filterValue: string, filterApplied: boolean, columnName: string }[] = [];
  invoicesGridFilters: { fieldName: string, filterValue: string }[] = [];

  openReceivablesHeader = [
    { name: 'Invoice #', id: 'invoiceNumber' },
    { name: 'Invoice Date', id: 'invoiceDate' },
    { name: 'Ref #', id: 'referenceNumber' },
    { name: 'Order #', id: 'orderNumber' },
    { name: 'Invoice Amt', id: 'invoiceAmount' },
    { name: 'Paid Amt', id: 'paidAmount' },
    { name: 'Balance', id: 'balance' },
    { name: 'Paid Date', id: 'paidDate' },
    { name: 'Type', id: 'type' },
    { name: 'PO #', id: 'poNumber' },
  ];
  paymentsHeader = [
    { name: 'Invoice #', id: 'invoiceNumber' },
    { name: 'Invoice Date', id: 'invoiceDate' },
    { name: 'Method', id: 'paymentMethod' },
    { name: 'Ref #', id: 'referenceNumber' },
    { name: 'Order #', id: 'orderId' },
    { name: 'PO #', id: 'poNumber' },
    { name: 'Paid Date', id: 'paidDate' },
    { name: 'Paid Amt', id: 'paidAmount' },
    { name: 'Type', id: 'paymentType' },
  ];
  list: IUITKSelectItemProps[] = [
    { id: '1', label: 'All', value: '1' },
    { id: '2', label: 'Open', value: '2' },
    { id: '3', label: 'None', value: '3' }
  ];
  @Input() cmFilter = { id: '1', label: 'All', value: '1' };
  @Input() invoiceFilter = { id: '1', label: 'All', value: '1' };

  selectedTab: number = 0;

  ngOnInit(): void {
    if (this._activatedRoute.snapshot.params.invoiceId) {
      this.selectedTab = 2;
    }

    this.filter();
    this.getSelectedInvoicesSubscription = this.accountsReceivableService.getSelectedInvoicesEmitter.
      subscribe((invoices: InvoiceDetailsDto[]) => {
        this.selectedInvoices = invoices;
        this.accountsReceivableService.accountIdForTemplate=this.accountInfo.accountId;
      });    

    this.sendCCPdfAccess = this._authorizationService.hasResource('ARInquirySendSelectedToPdfButtonCCMemo');
  }

  onSortChange(event: IUITKColumnState): void {
    this.filteredOpenReceivablesDataSource.data = this.filteredOpenReceivablesDataSource.sortData(this.filteredOpenReceivablesDataSource.data, event.direction, event.column);
  }

  onPaginationChange(event: any) {
    const pageEvent: IUITKPaginationEvent = event;
    this.paginationConfig.totalPagesCount = Math.ceil(pageEvent.totalRecords / pageEvent.entriesPerPage);

    switch (pageEvent.event) {

      case PAGINATION_EVENTS.ENTRIES_PER_PAGE:
        if (this.paginationConfig.totalPagesCount < this.paginationConfig.currentPageNumber) {
          this.paginationConfig.currentPageNumber = this.paginationConfig.totalPagesCount;
        }
        break;
      case PAGINATION_EVENTS.PREVIOUS_PAGE:
        this.paginationConfig.currentPageNumber = pageEvent.state.currentPageNumber;
        if (this.paginationConfig.currentPageNumber == 1) {
          this.filteredPaymentDetailsDataSource.data = this.paymentDetailsDataSource.data.slice(0, this.currentPageSize);
        }
        else {
          this.filteredPaymentDetailsDataSource.data = this.paymentDetailsDataSource.data.slice(this.currentPageSize * (this.paginationConfig.currentPageNumber - 1), this.currentPageSize * (this.paginationConfig.currentPageNumber));
        }
        break;
      case PAGINATION_EVENTS.NEXT_PAGE:
        this.paginationConfig.currentPageNumber = pageEvent.state.currentPageNumber;
        if (this.paginationConfig.currentPageNumber == this.paginationConfig.totalPagesCount) {
          this.filteredPaymentDetailsDataSource.data = this.paymentDetailsDataSource.data.slice(this.currentPageSize * (this.paginationConfig.currentPageNumber - 1), pageEvent.totalRecords);
        }
        else {
          this.filteredPaymentDetailsDataSource.data = this.paymentDetailsDataSource.data.slice(this.currentPageSize * (this.paginationConfig.currentPageNumber - 1), this.currentPageSize * (this.paginationConfig.currentPageNumber));
        }
        break;
      case PAGINATION_EVENTS.GO_TO_PAGE:
        if (this.paginationConfig.currentPageNumber == 1) {
          this.filteredPaymentDetailsDataSource.data = this.paymentDetailsDataSource.data.slice(0, this.currentPageSize);
        }
        else if (this.paginationConfig.currentPageNumber == this.paginationConfig.totalPagesCount) {
          this.filteredPaymentDetailsDataSource.data = this.paymentDetailsDataSource.data.slice(this.currentPageSize * (this.paginationConfig.currentPageNumber - 1), pageEvent.totalRecords);
        }
        else {
          this.filteredPaymentDetailsDataSource.data = this.paymentDetailsDataSource.data.slice(this.currentPageSize * (this.paginationConfig.currentPageNumber - 1), this.currentPageSize * (this.paginationConfig.currentPageNumber));
        }
        this.paginationConfig.currentPageNumber = pageEvent.state.currentPageNumber;
        break;
    }
  }

  onPaymentSortChange(event: IUITKColumnState): void {
    //this.filteredPaymentDetailsDataSource.data = this.filteredPaymentDetailsDataSource.sortData(this.filteredPaymentDetailsDataSource.data, event.direction, event.column);
    this.filteredPaymentDetailsDataSource.data = this.paymentDetailsDataSource.sortData(this.paymentDetailsDataSource.data, event.direction, event.column).slice(0, this.currentPageSize);
  }

  reload() {
    this.invoiceEdited.emit();
  }

  async filter(): Promise<void> {
    this.getAccountsReceivableResultsSubscription = this.accountsReceivableService.getAccountsReceivableResultsEmitter
      .subscribe(async (data: AccountsReceivableResultsDto) => {
        this.accountInfo = data.accountResultDto;
        this.openReceivablesDataSource.data = data.accountsReceivableResults;
        this.filteredOpenReceivablesDataSource.data = data.accountsReceivableResults;
        this.isDataFound = true;
        if (data.accountResultDto.accountId === 0) {
          this.isDataFound = false;
        }
        else{
          this._modeService.addToWaitList("Getting account details");
          this.accountFields = await this.configService.getAccountSearchDetails(this.accountInfo.accountName);
          this.accountFieldsSingle = this.accountFields.filter(a => a.id === this.accountInfo.accountId)[0];
          if(this.accountFieldsSingle?.creditLimit == 1000){
            this.creditDescription = '$1,000';
          }
          else if(this.accountFieldsSingle?.creditLimit == 10000){
            this.creditDescription = '$10,000';
          }
          else if(this.accountFieldsSingle == null){
            this._modeService.removeFromWaitList("Getting account details");
          }
          this.accountManager = this.configService.getFullNameFromUserCode(this.accountFieldsSingle?.accountOwnerCode);
          this.accountManagerEmail=this.configService.getUserEmailFromUserCode(this.accountFieldsSingle?.accountOwnerCode);
          this.tabLabel = this.accountInfo.accountId !== 0 ? 'A/R: ' + this.accountInfo.accountId.toString() : 'A/R Inquiry';
          this._titleService.setTitle(this.tabLabel);
          this.openReceivablesHeader.forEach(header => {
            this.uitkTableSort.setDirection({ column: header.id, direction: UITKSortDirection.UNSORTED });
          });
          this.uitkTableSort.setDirection({ column: 'invoiceDate', direction: UITKSortDirection.ASC });
          this._modeService.removeFromWaitList("Getting account details");          
        }
        
      });
    this.getPaymentsSubscription = this.accountsReceivableService.getPaymentsEmitter
      .subscribe((data: PaymentDetailDto[]) => {
        this.paymentDetailsDataSource.data = data;
        this.filteredPaymentDetailsDataSource.data = data;
        this.calculateAccountBalance();  
        this.paymentsHeader.forEach(header => {
          this.uitkPaymentTableSort.setDirection({ column: header.id, direction: UITKSortDirection.UNSORTED });
        });
        this.uitkPaymentTableSort.setDirection({ column: 'paidDate', direction: UITKSortDirection.DESC });
        this.totalRecords = this.paymentDetailsDataSource.data.length;
        this.filteredPaymentDetailsDataSource.data = this.paymentDetailsDataSource.data.slice(0,this.currentPageSize);
      });

  }

  onInvFilterChange() {
      this.accountsReceivableService.filterInvoices(this.invoiceFilter.id, this.cmFilter.id);
  }

  showSendInvoicePdf(): void {
    this.sendInvoicePdfDialog.show = true;
  }
  async showSelectedTeaplate():Promise<void> {
  
    
  const result=  await this.showSelectedTemplate.openAsync();
  if(result==='openTemplateDialog'){
   await this.showTemplateEmail.openAsync();
  }
}

  scrollToTop(e: Event){  
    e.stopPropagation();
    let d = document.querySelector('.uitk-c-table__body tr:first-child');
    d?.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});

    setTimeout(() => {
      let a = document.getElementsByClassName('uitk-c-table--responsive')[0];
      a.scrollTop = 0;
    },500);
  }

  calculateAccountBalance(){
    this.accountsReceivableService.getAccountBalanceDetails(this.accountInfo.accountId).then(x => {
      this.accountBalanceDto = x ?? new AccountBalanceDto();
    });
  }
  navigateToSFViewAccount(salesforceId: string): void {
    window.open(`${this.configService.salesforceUrl}/apex/ViewAccount?id=${salesforceId}`);
  }

  ngOnDestroy() {
    this.getAccountsReceivableResultsSubscription?.unsubscribe();
    this.getPaymentsSubscription?.unsubscribe();
    this.getSelectedInvoicesSubscription?.unsubscribe();
  }
}
