import { Component, Input, OnInit } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { ConfigService } from '../../services/config.service';
import { OrderService } from '../../services/order.service';
import { AuthorizationService } from '../../services/authorization.service';

@Component({
  selector: 'app-exception-reason',
  templateUrl: './exception-reason.component.html',
  styleUrls: ['./exception-reason.component.css']
})
export class ExceptionReasonComponent implements OnInit {
  @Input() dialog = { show: false };
  exceptionReasons: IUITKSelectItemProps[] = [];
  exceptionReason: IUITKSelectItemProps | null = null;
  private readonly _authorizationService: AuthorizationService;

  constructor(
    private readonly configService: ConfigService,
    private readonly orderService: OrderService,
    authorizationService: AuthorizationService
  ) { 
    this._authorizationService = authorizationService;
  }

  ngOnInit(): void {
    this.exceptionReasons = this.configService.getPickList('EREASON');
    if (!this._authorizationService.hasResource('AllowOrderChangeToException'))
      this.exceptionReasons = this.exceptionReasons.filter(item => item.id !== 'E');
  }

  ok() {
    this.orderService.orderDto.orderType = 'E';
    this.orderService.orderDto.exceptionReasonCode = this.exceptionReason?.value ?? null;
    this.orderService.recalculateFields(this.orderService.orderDto);
    this.dialog.show = false;
  }

  cancel() {
    this.orderService.orderDto.orderType = ' ';
    this.orderService.recalculateFields(this.orderService.orderDto);
    this.dialog.show = false;
  }
}
