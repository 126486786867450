export class AdditionalReturnInfoDto {
  isSelectedProducts: boolean = true;
  invoiceCreditMethods: InvoiceCreditMethodDto[] = [];
  note: AddtionalReturnInfo_NoteDto = new AddtionalReturnInfo_NoteDto();
}

export class InvoiceCreditMethodDto {
  invoiceNumber = 0;
  invoiceAmount = 0;
  totalBalance = 0;
  creditMethod: string = "";
  invoiceCreditMethodDetails: InvoiceCreditMethod_DetailsDto[] = [];
}

export class InvoiceCreditMethod_DetailsDto {
  item: string = "";
  return: number = 0;
  balance = 0;
}

export class AddtionalReturnInfo_NoteDto {
  userId: string = "";
  type: string = "";
  systemNotes: string = "";
  additionalNotes: string = "";
}


