import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ConfigService } from '../../services/config.service';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '../../services/authorization.service';
import { RoleDto } from '../../dtos/role-dto.model';
import { Mode } from '../../enums/mode.enum';
import { ModeService } from '../../services/mode.service';
import { Router } from '@angular/router';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild("yesBtn") yesBtn!: ElementRef<HTMLInputElement>;
  isLoggedIn: any = false;
  loggedUserName: any = '';
  openImpersonatePopup = false;
  isImpersonated: boolean = false;
  impersonateType: string = '';
  displayImpersonateOption: boolean = true;
  leavingImpersonateDialogModal = {
    show: false
  };
  roleName: string = '';
  msId: string = '';
  configIsReadySubscription: Subscription | undefined;
  Mode = Mode;
  orderEntryScreenChangeMessage = 'Any changes will be lost if you leave this page without saving. Do you want to continue?';

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly authorizationService: AuthorizationService,
    public readonly configService: ConfigService,
    public modeService: ModeService,
    private readonly router: Router,
    private readonly orderService: OrderService
  ) {

  }

  ngOnInit(): void {
    this.configIsReadySubscription = this.configService.configIsReady.subscribe(() => {
      const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);

      if (currentUser != null) {
        this.isLoggedIn = true;
        this.loggedUserName = currentUser.profile.name;
        this.msId = this.configService.getMyMSId();
        this.getRoleBasedOnUserId(currentUser);
      }
      else {
        this.authenticationService
          .isLoggedIn()
          .then((isloggedin) => {
            this.isLoggedIn = isloggedin;
            setTimeout(() => {
              if (localStorage.getItem('isByPass') === 'true') {
                this.loggedUserName = 'Automated User';
                this.roleName = 'System Administrator';
                this.msId = 'bypass';
              }
              else {
                this.loggedUserName = sessionStorage.getItem('username');
              }
            }, 500);

            this.handleImpersonation();
          })
          .catch((error) => { console.log(error) });
      }
    });

    this.configService.loadConfigurations();
  }
  getRoleBasedOnUserId(currentUser: any){
    this.authorizationService.getRoleBasedOnUserId(this.configService.apiUrl, this.configService.getMyMSId()).subscribe((data: RoleDto) => {
      if (data !== null || data !== undefined) {
        this.roleName = data.role;
        this.handleImpersonation();

        if (this.impersonateType === '') {
          localStorage.setItem('rolename', data.description);
        }
        else {
          localStorage.setItem('rolename', this.impersonateType);
        }
      }
    });
  }
  handleImpersonation(): void {
    if (localStorage.getItem('impersonateType') !== null && localStorage.getItem('impersonateType') !== '') {
      this.isImpersonated = true;
      this.impersonateType = localStorage.getItem('impersonateType') as string;
      this.orderService.impersonateTypes=localStorage.getItem('impersonateType') as string;

      if (this.impersonateType === 'System Administrator' || this.impersonateType === 'Order Management') {
        this.displayImpersonateOption = true;
      }
      else {
        this.displayImpersonateOption = false;
      }
    }
    else {
      this.displayImpersonateOption = true;
    }
  }

  handleEventEmitter(item: any) {
    localStorage.clear();
    this.authenticationService.logout();
  }

  preventEvent(event: any) {
    return false;
  }

  closeEvent() {
    this.openImpersonatePopup = false;
  }

  openImpersonateModal() {
    this.openImpersonatePopup = true;
  }

  showLeaveImpersonationPopUp() {
    this.leavingImpersonateDialogModal.show = true;
    setTimeout(() => {
      this.yesBtn.nativeElement.focus();
    }, 0);
  }

  hideLeaveImpersonateDialog() {
    this.leavingImpersonateDialogModal.show = false;
  }

  clearImpersonation() {
    this.isImpersonated = false;
    this.impersonateType = '';
    localStorage.setItem('impersonateType', '');
    localStorage.setItem('isReloaded', 'false');
    this.authorizationService.getAuthorization(this.configService.apiUrl);
  }

  ngOnDestroy(): void {
    this.configIsReadySubscription?.unsubscribe();
  }
}
