import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IUITKRadioGroupItem } from '@uitk/angular';
import { Dialog } from '../../../../helpers/dialog';
import { GeneratePdfService } from '../../../../services/generate-pdf.service';
import { OrderService } from '../../../../services/order.service';
import { SwalAlert } from '../../../../helpers/alert';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-cfd-form-footer-section',
  templateUrl: './cfd-form-footer-section.component.html',
  styleUrls: ['./cfd-form-footer-section.component.css']
})

export class CFDFormFooterSectionComponent implements OnInit {
  private readonly _orderService: OrderService;
  private readonly _generatePdfService: GeneratePdfService;
  private readonly _router: Router;
  private readonly _activatedRoute: ActivatedRoute;
  @Input() enteredByUserRole : string | null = null;
  prePayRequired = false;
  poRequired = false;
  standard = false;
  quoteCfdFormEmail = new Dialog<string>();
  disablePreviewButton = false;
  profileForm = new UntypedFormGroup({
    radioGroup: new UntypedFormControl(''),
  });
  items: IUITKRadioGroupItem[] = [
    {
      label: 'PDF',
      value: 'pdf',
    },
    {
      label: 'Email with PDF',
      value: 'emailwithpdf',
    },
  ];
  formatType: any;
  msId = '';
  swalAlert = new SwalAlert();

  constructor(public orderService: OrderService,
    private _configService: ConfigService,
    generatePdfService: GeneratePdfService,
    router: Router,
    activatedRoute: ActivatedRoute) {
    this._orderService = orderService;
    this._generatePdfService = generatePdfService;
    this._router = router;
    this._activatedRoute = activatedRoute;
  }

  ngOnInit(): void {
    if (this._orderService.orderDto.billToDto.isPORequired) {
      this.poRequired = true;
    } else if (this._orderService.orderDto.billToDto.creditLimit !== 10000 && this._orderService.orderDto.billToDto.creditLimit !== 1000) {
      this.prePayRequired = true;
    } else {
      this.standard = true;
    }

    this.msId = this._configService.getMyMSId();
  }

  async onChangeFormatType(): Promise<void> {
    this.disablePreviewButton = this.formatType.label === 'Email with PDF' ? true : false;
  }

  async generate(): Promise<void> {
    if (!this.formatType) {
      await this.swalAlert.alert("Please select a format option");
      return;
    }
   
    this.formatType.label === 'PDF' ? await this.downloadQuoteCFDPdf() : await this.quoteCfdFormEmail.openAsync();
  }

  async preview(): Promise<void> {
    if (!this.formatType) {
      await this.swalAlert.alert("Please select a format option");
      return;
    }

    document.getElementById('divQuoteCFDPdf')!.style.display = 'inline-block';
  }

  async downloadQuoteCFDPdf(): Promise<void> {
    document.getElementById('divQuoteCFDPdf')!.style.display = 'inline-block';
    const quoteCFDPdf = await this._generatePdfService.generateQuoteCFDPdf();
    document.getElementById('divQuoteCFDPdf')!.style.width = this._generatePdfService.getPDFDivWidthBasedOnBrowserZoomLevel(Math.round(window.devicePixelRatio * 100));
    let srcWidth = document.getElementById('divQuoteCFDPdf')?.scrollWidth;
    if (srcWidth == null) {
      srcWidth = 1;
    }
    let pWidth = quoteCFDPdf.internal.pageSize.width;
    let margin = 30;
    let scale = (pWidth - margin * 2) / srcWidth;
    quoteCFDPdf.html(document.getElementById('divQuoteCFDPdf')!, {
      html2canvas: {
        scrollX: 0,
        scrollY: 0,
        scale: window.matchMedia('screen and (max-width: 1200px)').matches ? 1 : scale,
      },
      x: 0,
      y: 0,
      margin: [75, 35, 35, 35],
      autoPaging: 'text',
      callback: (pdf) => {
       document.getElementById('divQuoteCFDPdf')!.style.display = 'none';

       document.getElementById('divQuoteCFDPdf')!.style.display = 'none';

       let fileName = this._orderService.saveCfd(pdf, this.msId);

        pdf.save(fileName);
      }
    });
  }

  async cancel(): Promise<void> {
    if (this._orderService.orderDto.id !== 0) {
      this._router.navigate(['/order-entry'], { relativeTo: this._activatedRoute, queryParams: { orderId: this._orderService.orderDto.id } });
    }
    else {
      this._router.navigate(['/order-entry']);
    }
  }
}
