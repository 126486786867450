<div class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkid='ship-and-process' [(showModal)]="dialog.show" [closeBtn]="false" 
  triggerElementId="ship-and-process-button" id="ship-and-process-id">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Ship and Process
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content style="width: 97% !important">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col">
          <p>Select items to ship and process.</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <table uitkTable [dataSource]="orderDetailDtos" id="productactivation-table"
            class="uitk-c-table" aria-describedby="shipToAdd-data">
            <thead class="uitk-c-table__head">
              <ng-container>
                <tr>
                  <th scope="col" class="py-0 px-2 align-middle">
                    <uitk-form-field>
                      <input type="checkbox" id="select-all-checkbox" style="width: 100px !important;" [(ngModel)]="allRowsSelected" uitkCheckbox
                        (change)="selectAllRows($event)" />
                      <label uitkCheckboxLabel id="select-all-label" for="select-all-checkbox" style="width: 10px !important;"></label>
                    </uitk-form-field>
                  </th>
                  <th scope="col" class="py-0 align-middle">
                    Item
                  </th>
                  <th scope="col" class="py-0 align-middle">
                    Description
                  </th>
                  <th scope="col" class="py-0 align-middle">
                    Quantity
                  </th>
                </tr>
              </ng-container>
            </thead>
            <tbody id="itemgrid-data" class="uitk-c-table__body">
              <ng-container *uitkTableRowBody="let orderDetailDto">
                <tr class="uitk-c-table__row">
                  <td class="py-1 px-2 align-middle">
                    <uitk-form-field>
                      <input type="checkbox" id="grid-check-box-{{ orderDetailDto.id }}" style="width: 100px !important;" uitkCheckbox
                        (change)="onRowChecked(orderDetailDto)" [(ngModel)]="orderDetailDto.isSelected" />
                      <label uitkCheckboxLabel id="select-item-label-{{ orderDetailDto.id }}" for="grid-check-box-{{ orderDetailDto.id }}" style="width: 10px !important;"></label>
                    </uitk-form-field>
                  </td>
                  <td class="py-1 align-middle">{{ orderDetailDto.productCode }}</td>
                  <td class="py-1 align-middle">{{ orderDetailDto.productDescription }}</td>
                  <td class="py-1 align-middle">
                    <uitk-form-field>
                      <input type="text" id="orderQuantity-{{ orderDetailDto.id }}" uitkInput
                        [(customModel)]="orderDetailDto.shipAndProcessQuantity" name="ship-and-process-quantity" style="width: 80px;"
                        (blur)="onQuantityChange(orderDetailDto)"
                        appQuantityFormat>
                    </uitk-form-field>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex">
          <span class="align-self-center mx-3">Tracking Number</span>
          <uitk-form-field class="mb-1 mt-2">
            <input type="text" id="trackingNumberInput" uitkInput [(ngModel)]="trackingNumber" name="trackingNumberInput" style="width: 450px;">
          </uitk-form-field>
        </div>
      </div>
    </div>
  </uitk-dialog-content>

  <uitk-dialog-actions>

    <button id="ship-and-process-button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="shipAndProcess()">
      Ship and Process
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="dialog.show = false">
        Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>