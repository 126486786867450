<div class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkid='generate-packing-slip' [(showModal)]="dialog.show" [closeBtn]="false"
             triggerElementId="generate-packing-slip-button" id="generate-packing-slip-id">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Generate Packing Slip
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content style="width: 97% !important">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-12 d-flex">
          <span class="col-2 align-self-center mx-3">Ship Date</span>
          <uitk-form-field class="mb-1 mt-2">
            <input type="text" id="shipDateInput" uitkInput [ngModel]="shipDate | date:'MM/dd/yyyy'" name="shipDateInput" style="width: 450px;"
                   (change)="onChangeShipDate($event)" placeholder="MM/dd/yyyy" />
          </uitk-form-field>
        </div>
      </div>
      <div class="row mt-3" style="overflow: auto">
        <div class="col">
          <table uitkTable [dataSource]="orderDetailDtos" id="packing-slip-table"
                 class="uitk-c-table" aria-describedby="packing-slip-data">
            <thead class="uitk-c-table__head">
              <ng-container>
                <tr>
                  <th scope="col" class="py-0 px-2 align-middle">
                    <uitk-form-field>
                      <input type="checkbox" id="select-all-checkbox" style="width: 100px !important;" [(ngModel)]="allRowsSelected" uitkCheckbox
                             (change)="selectAllRows($event)" />
                      <label uitkCheckboxLabel id="select-all-label" for="select-all-checkbox" style="width: 10px !important;"></label>
                    </uitk-form-field>
                  </th>
                  <th scope="col" class="py-0 align-middle">
                    Shipped Qty
                  </th>
                  <th scope="col" class="py-0 align-middle">
                    Backordered Qty
                  </th>
                  <th scope="col" class="py-0 align-middle">
                    Ship Date
                  </th>
                  <th scope="col" class="py-0 align-middle">
                    Item
                  </th>
                  <th scope="col" class="py-0 align-middle">
                    Description
                  </th>
                </tr>
              </ng-container>
            </thead>
            <tbody id="itemgrid-data" class="uitk-c-table__body">
              <ng-container *uitkTableRowBody="let orderDetailDto">
                <tr class="uitk-c-table__row">
                  <td class="py-1 px-2 align-middle">
                    <uitk-form-field>
                      <input type="checkbox" id="grid-check-box-{{ orderDetailDto.id }}" style="width: 100px !important;" uitkCheckbox
                             (change)="onRowChecked()" [(ngModel)]="orderDetailDto.isSelected" />
                      <label uitkCheckboxLabel id="select-item-label-{{ orderDetailDto.id }}" for="grid-check-box-{{ orderDetailDto.id }}" style="width: 10px !important;"></label>
                    </uitk-form-field>
                  </td>
                  <td class="py-1 align-middle">
                    <uitk-form-field>
                      <input type="text" id="packingSlipShipQuantity-{{ orderDetailDto.id }}" uitkInput
                             [(customModel)]="orderDetailDto.packingSlipShipQuantity" name="packing-slip-ship-quantity" style="width: 80px;"
                             (blur)="onPackingSlipShipQuantityChange(orderDetailDto)"
                             appQuantityFormat>
                    </uitk-form-field>
                  </td>
                  <td class="py-1 align-middle">
                    <uitk-form-field>
                      <input type="text" id="packingSlipBackorderQuantity-{{ orderDetailDto.id }}" uitkInput
                             [(customModel)]="orderDetailDto.packingSlipBackorderQuantity" name="packing-slip-Backorder-quantity" style="width: 80px;"
                             (blur)="onPackingSlipBackorderQuantityChange(orderDetailDto)"
                             appQuantityFormat>
                    </uitk-form-field>
                  </td>
                  <td class="py-1 align-middle">{{ orderDetailDto.shipDate | date:'MM/dd/yyyy'}}</td>
                  <td class="py-1 align-middle">{{ orderDetailDto.productCode }}</td>
                  <td class="py-1 align-middle">{{ orderDetailDto.productDto.description }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex">
          <span class="col-2 align-self-center mx-3">Email Address</span>
          <uitk-form-field class="mb-1 mt-2">
            <input type="text" id="emailAddressInput" uitkInput [(ngModel)]="emailAddress" name="emailAddressInput" style="width: 450px;"
                   placeholder="youremail@domain.com" required>
          </uitk-form-field>
        </div>
      </div>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button id="ship-and-process-button"
            class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
            (click)="generatePackingSlip()"
            [disabled]="emailAddress == ''">
      Send Email
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="dialog.show = false">
      Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>