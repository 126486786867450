<div class="">
  <div class="row mt-3">
    <div class="col-2 d-flex">
      <span
        style="font-size: 24px; font-family: Arial-BoldMT, Arial Bold, Arial, sans-serif; font-weight: 700;font-style: normal">
        Account Information
      </span>
    </div>   
    <div class="col-10 d-flex">
      <div class="uitk-icon uitk-icon-add sm-icon align-self-center mx-2">
        <a class="link" target="_blank" (click)="navigateToSFAddAccount()"
        [ngClass]="{'disabled': (disableAccountAddLink || modeService.mode !== Mode.Edit ? true : false)}" tabindex="-1">Add</a>
      </div>
      <div class="uitk-icon uitk-icon-refresh sm-icon align-self-center mx-3">
        <a class="link" target="_blank" (click)="refreshAccountDetails()"
        [ngClass]="{'disabled': (orderService.orderDto.billToDto.id <= 0 || modeService.mode !== Mode.Edit ? true : false)}">
          Refresh
        </a>
      </div>
      <div class="uitk-icon uitk-icon-edit sm-icon align-self-center mx-3">
        <a class="link" target="_blank"
        (click)="navigateToSFEditAccount()"
        [ngClass]="{'disabled': (orderService.orderDto.billToDto.id <= 0 || modeService.mode !== Mode.Edit ? true : false)}">
          Edit
        </a>
      </div>
      <div class="uitk-icon uitk-icon-search sm-icon searchIcon align-self-center">
        <a class="link" target="_blank"
        (click)="AccountSearch()">
        Account Search
        </a>
      </div>
    </div>
    <!-- <div class="col-4">
    </div> -->
  </div>
  <div class="row mt-3">
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Account ID</label>
      </div>
      <div class="col-7 d-flex accountIdMainSec">
        <div class="idSection">
          <div class="idInnerSec">
            <uitk-form-field>
              <input uitkInput id="accountId" class="textbox" type="number"
                [disabled]="orderService.orderDto.billToDto.enableAccountLinks || modeService.mode !== Mode.Edit"
                (focusout)="onAccountIdChange($event)"
                [ngModel]="orderService.orderDto.billToDto.id === 0 ? '' : orderService.orderDto.billToDto.id"
                (ngModelChange)="accountIdNgModelChange($event)" />
            </uitk-form-field>
          </div>
         <div class="idInnerSecRight">
          <button name="accountInfo-viewBtn" id="accountViewBtn"
          class="uitk-c-button uitk-c-button--ghost uitk-icon uitk-icon-view px-0 py-1 mt-1" type="button"
          (click)="navigateToSFViewAccount(this.orderService.orderDto.billToDto.salesforceId)"
          [disabled]="orderService.disableAccountEditViewRefreshLinks|| modeService.mode !== Mode.Edit"></button>
         </div>         
      </div>
      <!-- <div class="col-3 d-flex mx-4">
        <button name="accountInfo-viewBtn" id="accountViewBtn"
          class="uitk-c-button uitk-c-button--ghost uitk-icon uitk-icon-view px-0 py-1 mt-1" type="button"
          (click)="navigateToSFViewAccount(this.orderService.orderDto.billToDto.salesforceId)"
          [disabled]="orderService.disableAccountEditViewRefreshLinks|| modeService.mode !== Mode.Edit"></button>
      </div> -->
      </div>
    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Account Name</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="accountName" class="textbox accountNameMargins" type="text"
            [(ngModel)]="orderService.orderDto.billToDto.company" (change)="onAccountChange($event)"
            [disabled]="orderService.orderDto.billToDto.enableAccountLinks || modeService.mode !== Mode.Edit"
            (keydown.Tab)="onAccountNameBlur()" (keydown.enter)="onAccountNameEnter($event)" />

        </uitk-form-field>
        <!-- <span class="uitk-icon uitk-icon-search md-icon accountNameLookup"
          [class.disabled]="orderService.orderDto.billToDto.isAccountFlow || orderService.orderDto.billToDto.enableAccountLinks || modeService.mode !== Mode.Edit"
          id="spnAccountNameLookUp" (click)="showAccountSearchLookupPopup()">
        </span> -->
        <div id="loadingSpinner" class="uitk-l-grid" *ngIf="loading"
          style="float:right;width: 30px;margin-right: -15px;margin-bottom:-60px">
          <uitk-loading-indicator uitkId="spinner" spinnerSize="small">
          </uitk-loading-indicator>
        </div>
      </div>
    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Group ID</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="groupId" disabled class="textbox mx-3" type="text"
           [(ngModel)]="orderService.orderDto.billToDto.id === 0 ? '' : orderService.groupAccount" />
        </uitk-form-field>
      </div>

    </div>
  </div>
  <div class="row  mt-3">
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Account Type</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="accountType" disabled class="textbox mx-3" type="text "
            [ngModel]="orderService.orderDto.billToDto.accountTypeDescription"
            [ngClass]="orderService.orderDto.billToDto.accountTypeCode === 'C' ? 'accounttypeC' : (orderService.orderDto.billToDto.accountTypeCode === 'H' ? 'accounttypeH' : 'accounttype')" />        </uitk-form-field>
      </div>

    </div>
    <div class="col-4 d-flex">

      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Account Status</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="accountStatus" disabled class="textbox mx-3" type="text "
            [ngModel]="orderService.orderDto.billToDto.accountStatusDescription" />
        </uitk-form-field>
      </div>

    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Credit Limit</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="creditLimit" class="textbox mx-3 fw-bold" disabled type="text"
            [ngClass]="orderService.orderDto.billToDto.creditLimit<0?'creditLimitNegative':'creditLimitPositive'"
            [(ngModel)]=" orderService.orderDto.billToDto.creditLimitDescription">
        </uitk-form-field>
      </div>
      <!--uitkInput id="creditLimit" class="textbox mx-3 fw-bold" 
        disabled type="text" [ngStyle]="{ 'color': (orderService.orderDto.billToDto.creditLimit < 0 ?
         'red' : 'black'), 'background-color':(orderService.orderDto.billToDto.creditLimit < 0 ? 'yellow' : '') }"
        [ngClass]="orderService.orderDto.billToDto.creditLimit<0?'creditLimitNegative':'creditLimitPositive'"-->

    </div>
  </div>
  <div class="row  mt-3">
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label"
          [ngClass]="{'hilightPoFiled': orderService.orderDto.billToDto.isPORequired}" uitkLabel>PO Required</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkCheckbox id="poRequired" class="mx-3" disabled type="checkbox"
            [(ngModel)]="orderService.orderDto.billToDto.isPORequired" />
          <label uitkCheckboxLabel class="mx-3"></label>
        </uitk-form-field>
      </div>

    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Speciality</label>
      </div>
      <div class="col-7 d-flex" *ngIf="this.orderService.isSnapshot">
        <uitk-form-field>
          <input uitkInput id="Speciality" class="textbox mx-3" type="text" disabled />
        </uitk-form-field>
      </div>
      <div class="col-7 d-flex" *ngIf="!this.orderService.isSnapshot">
        <uitk-form-field *ngIf="specialities === ''">
          <input uitkInput id="Speciality" class="textbox mx-3" type="text" disabled
            [(ngModel)]="orderService.orderDto.billToDto.specialties[0]" />
        </uitk-form-field>
        <uitk-tooltip uitkId="my-basic-input-tooltip" [tooltipContent]="specialities" tooltipPlacement="top"
          *ngIf="orderService.orderDto.billToDto.specialties.length > 1 && specialities !== ''">
          <uitk-form-field>
            <input uitkInput id="Speciality" class="textbox mx-3" type="text" disabled
              [(ngModel)]="orderService.orderDto.billToDto.specialties[0]" />
          </uitk-form-field>
        </uitk-tooltip>
      </div>
    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkCheckboxLabel>Specified Price List</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkCheckbox id="chkSpecifiedPriceList" type="checkbox"
            [(ngModel)]="orderService.orderDto.billToDto.specifiedPriceList" disabled />
          <label uitkCheckboxLabel class="mx-3"></label>
        </uitk-form-field>
      </div>
    </div>
  </div>

  <div class="row  mt-3">
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Bill To Department</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="txtBillToDepartment" disabled class="textbox mx-3" type="text" [ngModel]="
              orderService.orderDto?.paymentMethod === 'S' ?
                (orderService?.selectedShipToContact?.mailingStreet ? orderService.selectedShipToContact?.mailingDepartment
                : orderService?.selectedShipToContact?.street ? orderService.selectedShipToContact?.department
                : orderService.orderDto?.shipToDto?.department)
              : orderService.orderDto?.billToDto?.department" />
        </uitk-form-field>
      </div>

    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Bill To Address</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="txtBillToAddress" disabled class="textbox mx-3" type="text" [ngModel]="
            orderService.orderDto?.paymentMethod === 'S' ?
              (orderService?.selectedShipToContact?.mailingStreet ? orderService.selectedShipToContact?.mailingStreet
              : orderService?.selectedShipToContact?.street ? orderService.selectedShipToContact?.street
              : orderService.orderDto?.shipToDto?.street)
            : orderService.orderDto?.billToDto?.street" />
        </uitk-form-field>
      </div>

    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Bill To City </label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="txtBillToZipCode" disabled class="textbox mx-3" type="text" [ngModel]=" orderService.orderDto?.paymentMethod === 'S' ?
                 (orderService?.selectedShipToContact?.mailingStreet ? orderService.selectedShipToContact?.mailingCity
                 : orderService?.selectedShipToContact?.street ? orderService.selectedShipToContact?.city
                 : orderService.orderDto?.shipToDto?.city)
               : orderService.orderDto?.billToDto?.city" />
        </uitk-form-field>
      </div>

    </div>
  </div>
  <div class="row  mt-3" >
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Bill To State</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field class="mx-3" id="drpBillToState" class="w-100">
          <uitk-dropdown name="drpBillToState" [disabled]="true" [items]="stateList" class="billTypeSelect mx-3 w-100" [ngModel]="
            orderService.orderDto?.paymentMethod === 'S' ?
              (orderService?.selectedShipToContact?.mailingStreet ? orderService?.selectedShipToContact?.mailingStateBinding
              : orderService?.selectedShipToContact?.street ? orderService?.selectedShipToContact?.stateBinding
              : orderService.orderDto?.shipToDto?.stateBinding)
            : orderService.orderDto.billToDto.selectedState
            ">
          </uitk-dropdown>
        </uitk-form-field>
      </div>

    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Bill To Zip Code</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field>
          <input uitkInput id="txtBillToCity" disabled class="textbox mx-3" type="text" [ngModel]="orderService.orderDto?.paymentMethod === 'S' ?
              (orderService?.selectedShipToContact?.mailingStreet ? orderService.selectedShipToContact?.mailingZip
              : orderService?.selectedShipToContact?.street ? orderService.selectedShipToContact?.zip
              : orderService.orderDto?.shipToDto?.zip)
            : orderService.orderDto?.billToDto?.zip" />
        </uitk-form-field>
      </div>

    </div>
    <div class="col-4 d-flex">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Bill To Country</label>
      </div>
      <div class="col-7 d-flex">
        <uitk-form-field class="">
          <input uitkInput id="txtBillToCountry" disabled class="textbox mx-3" type="text" [ngModel]="
              orderService.orderDto?.paymentMethod === 'S' ?
                (orderService?.selectedShipToContact?.mailingStreet ? orderService.selectedShipToContact?.mailingCountry
                : orderService?.selectedShipToContact?.street ? orderService.selectedShipToContact?.country
                : orderService.orderDto?.shipToDto?.country)
              : orderService.orderDto?.billToDto?.country"> />
        </uitk-form-field>
      </div>

    </div>
  </div>
</div>
<div *ngIf="contactDialogModal.show">
  <app-contact-change-popup [contactDialogModal]="contactDialogModal"></app-contact-change-popup>
</div>
<div *ngIf="accountSearchLookupPopup.show">
  <app-account-search-lookup [accountSearchLookupDialog]="accountSearchLookupPopup"
    (selectedAccount)="bindSelectedAccount($event)">
  </app-account-search-lookup>
</div>
<div *ngIf="showItemSearchDialog.show">
  <app-advanced-account-search [AccountSearch]="showItemSearchDialog"
    >
  </app-advanced-account-search>
</div>
<div *ngIf="recentQuotesOrOrdersDialog.show">
  <app-recent-quotes-or-orders-dialog [recentQuotesOrOrdersDialog]="recentQuotesOrOrdersDialog">
  </app-recent-quotes-or-orders-dialog>
</div>
