
import { ItemOfferDto } from "./item-offer-dto.model";
import { OrderOfferDto } from "./order-offer-dto.model";

export class CampaignDto {
    campaignId = '';
    description = '';
    status = '';
    budgetedStartDate = new Date();
    setupDate = new Date();
    enteredBy = '';
    orderLevelOffer: OrderOfferDto | null = null;
    itemLevelOffers: ItemOfferDto[] = [];
}
