<div *ngIf="dialog.show" class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog uitkid='subscriptionHistory' [(showModal)]="dialog.show" [closeBtn]=true>
    <uitk-dialog-header>
        <div cdkDragHandle>
            Subscription History
        </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
        <div class="uitk-c-table--responsive">
            <table uitkTable [dataSource]="dataSource" class="uitk-c-table" id="subscription-history-table">
                <thead class="uitk-c-table__head">
                    <tr uitkTableSort #sortTable="uitkTableSort">
                        <th></th>
                        <th uitkTableSortColumn [column]="headerName.id" (sortChange)="onSortChange($event)"
                            *ngFor="let headerName of tableHeader" scope="col">
                            {{ headerName.name }}
                        </th>
                    </tr>
                </thead>
                <tbody id="row-data" class="uitk-c-table__body">
                    <ng-container *uitkTableRowBody="let subscription">
                        <tr class="uitk-c-table__row">
                            <td><button id="editBtn" (click)="selectedSubscription = subscription; subscriptionEditDialog.show = true" title="Edit subscription" class="uitk-icon uitk-icon-edit sm-icon orange" style="cursor: pointer; background-color:white; border: none;" type="button"></button></td>
                            <td [ngStyle]="{'font-weight': subscription.subscriptionStatus === 'A' ? 'bold' : 'normal' }">{{ subscription.subscriptionNumber }}</td>
                            <td [ngStyle]="{'font-weight': subscription.subscriptionStatus === 'A' ? 'bold' : 'normal' }">{{ subscription.productCode }}</td>
                            <td [ngStyle]="{'font-weight': subscription.subscriptionStatus === 'A' ? 'bold' : 'normal' }">{{ subscription.productDescription }}</td>
                            <td [ngStyle]="{'font-weight': subscription.subscriptionStatus === 'A' ? 'bold' : 'normal' }">{{ subscription.quantity }}</td>
                            <td [ngStyle]="{'font-weight': subscription.subscriptionStatus === 'A' ? 'bold' : 'normal' }">{{ subscription.startDate |date: 'MM/dd/yyyy' }}</td>
                            <td [ngStyle]="{'font-weight': subscription.subscriptionStatus === 'A' ? 'bold' : 'normal' }">{{ subscription.term }}</td>
                            <td [ngStyle]="{'font-weight': subscription.subscriptionStatus === 'A' ? 'bold' : 'normal' }">{{ subscription.expirationDate |date: 'MM/dd/yyyy'}}</td>
                            <td [ngStyle]="{'font-weight': subscription.subscriptionStatus === 'A' ? 'bold' : 'normal' }">{{ subscription.subscriptionStatus }}</td>
                            <td [ngStyle]="{'font-weight': subscription.subscriptionStatus === 'A' ? 'bold' : 'normal' }">{{ subscription.subscriptionType }}</td>
                            <td [ngStyle]="{'font-weight': subscription.subscriptionStatus === 'A' ? 'bold' : 'normal' }"><a href="#" [routerLink]="['/order', subscription.salesOrderNumber]"
                                    target="_blank">{{ subscription.salesOrderNumber }}</a></td>
                            <td id="updateOnlyCheckbox">
                                <input type="checkbox" id="updateOnly" disabled [checked]="subscription.updateOnly">
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </uitk-dialog-content>
</uitk-dialog>
    </div>
</div>

<!-- Dialogs -->
<div *ngIf="subscriptionEditDialog.show">
    <app-subscription-edit [dialog]="subscriptionEditDialog" [subscriptionSearchResult]="selectedSubscription" ></app-subscription-edit>
</div>
