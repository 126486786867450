import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountResultDto } from 'src/app/dtos/account-result-dto.model';
import { InvoiceDetailsDto } from 'src/app/dtos/invoice-details-dto.model';
import { InvoicePdfEmailInputDto } from 'src/app/dtos/invoice-pdf-email-input-dto.model';
import { Dialog } from 'src/app/helpers/dialog';
import { AccountingService } from 'src/app/services/accounting.service';
import { AccountsReceivableService } from 'src/app/services/accounts-receivable.service';
import { ModeService } from 'src/app/services/mode.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-selected-template-email',
  templateUrl: './selected-template-email.component.html',
  styleUrls: ['./selected-template-email.component.css']
})
export class SelectedTeplateEmailComponent implements OnInit {

  private readonly _orderService: OrderService;
  orderIsReadySubscription: Subscription | undefined;
  getSelectedInvoicesSubscription: Subscription | undefined;
  selectedInvoicesList: InvoiceDetailsDto[] = [];
  email = '';

  loading = false;
  
  visibleTextValue = '';

  emailPattern = /^^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](\s?)(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
 
  disableBillTo = true;
  disableOrderBy = true;
  disableShipTo = true;
  disableloogedInUser=false;

  billToContactcheckbox: boolean = false;
  orderByContactcheckbox: boolean = false;
  shipToContactcheckbox: boolean = false;
  accountInfo: AccountResultDto = new AccountResultDto();
  LoggedInUsercheckbox:boolean=false;
  
  loggedinName:any;
  loggedInUserEmail:any;
  CCrecipientsloggedInUserEmail:any;
  @Input() showTemplateEmailDialog = new Dialog<string>();
  @Input() templateUrl=new Dialog<void>();
  

  constructor(
    public accountsReceivableService: AccountsReceivableService,
    orderService: OrderService,
    public accountingService: AccountingService,
    public modeService: ModeService) {
    this._orderService = orderService;
  }

  isNotificationVisible = false;
  notificationVariant: any = '';
  emailNotificationMessage = '';

  

  async ngOnInit(): Promise<void> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
    this.loggedInUserEmail=currentUser.profile.email;
    this.CCrecipientsloggedInUserEmail=currentUser.profile.email;
    this.loggedinName=currentUser.profile.name;
    this.getSelectedInvoicesSubscription = this.accountsReceivableService.getSelectedInvoicesEmitter.
      subscribe((invoices: InvoiceDetailsDto[]) => {
        this.selectedInvoicesList = invoices;
      });
    this.clickSendSelectedToPDF();

  }
  clickSendSelectedToPDF() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
    this._orderService.getOrderInformation(this.accountsReceivableService.selectedOrder);
    this.orderIsReadySubscription = this._orderService.orderIsReady.subscribe(() => {
      this.email = '';
      let loggedInUserToIds=currentUser.profile.email;

      this.accountsReceivableService.getSelectedInvoicesEmitter.
        subscribe((invoices: InvoiceDetailsDto[]) => {
          const distinctOrderByIds = [...new Set(invoices.map(item => item.orderByContactId))];
          const distinctShipToIds = [...new Set(invoices.map(item => item.shipToContactId))];
       

          if (this._orderService.orderDto.billToDto.email !== null && this._orderService.orderDto.billToDto.email !== '') {
            this.billToContactcheckbox = true;
            this.email = this._orderService.orderDto.billToDto.email;
            this.disableBillTo = false;
          }
          else {
            this.disableBillTo = true;
          }
          this.disableloogedInUser=loggedInUserToIds.length > 1|| currentUser.profile.email;
          this.disableOrderBy = distinctOrderByIds.length > 1 || !this._orderService.orderDto.orderByDto.email;

          this.disableShipTo = distinctShipToIds.length > 1 || !this._orderService.orderDto.shipToDto.email;
        });
    });
  }

  displayNotification(isNotificationVisible: boolean, emailNotificationMessage: string, isSuccess: boolean = false) {
    this.isNotificationVisible = isNotificationVisible;
    this.emailNotificationMessage = emailNotificationMessage;

    if (typeof isSuccess !== 'undefined') {
      this.notificationVariant = isSuccess ? 'success' : 'error';
    }
  }
  changeEmail() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
    let emailIds: string[] = [];
    this.email = '';
    emailIds.push(this.billToContactcheckbox === true ? this._orderService.orderDto.billToDto.email || '' : '');
    emailIds.push(this.orderByContactcheckbox === true ? this._orderService.orderDto.orderByDto.email || '' : '');
    emailIds.push(this.shipToContactcheckbox === true ? this._orderService.orderDto.shipToDto.email || '' : '');
    emailIds.push(this.LoggedInUsercheckbox==true?currentUser.profile.email||'':'');
    emailIds = [...new Set(emailIds)];
    this.email = emailIds.filter(e => e).join(';');
  }
  cancelDialog() {
  this.templateUrl.close();
  }
  sendPdf() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
    this.loading = true;
    this.visibleTextValue = 'Sending Email';
    const invoicePdfEmailInputDto = new InvoicePdfEmailInputDto();
    invoicePdfEmailInputDto.emailAddress = this.email;
    invoicePdfEmailInputDto.ccRecipients=this.CCrecipientsloggedInUserEmail;
    invoicePdfEmailInputDto.subject = this.accountsReceivableService.EmailSubject;
    invoicePdfEmailInputDto.body = this.accountsReceivableService.EmailBody;
   
    invoicePdfEmailInputDto.LoggedInUserEmail=currentUser.profile.email;

    this.selectedInvoicesList.forEach(item => {
      invoicePdfEmailInputDto.invoiceIds.push(Number(item.invoiceNumber));
    });
    const waitListMessage = "Sending PDF";
    this.modeService.addToWaitList(waitListMessage);
    console.log(invoicePdfEmailInputDto,"invoicePdfEmailInputDtoinvoicePdfEmailInputDto");
    this.accountingService.emailInvoicePdfs(invoicePdfEmailInputDto)
      .subscribe(() => {
        this.modeService.removeFromWaitList(waitListMessage);
        this.displayNotification(true, 'Mail sent successfully', true);
        this.selectedInvoicesList = [];
        setTimeout(() => {
          this.displayNotification(false, '');
          this.cancelDialog();
        }, 2000);
      }, error => {
        this.modeService.removeFromWaitList(waitListMessage);
        console.log(error);

        setTimeout(() => {
          this.displayNotification(true, 'An error occurred while sending an email', false);
        }, 2000);
      });
  }
  onEmailBodyChange(event: any){
    this.accountsReceivableService.EmailBody = event.target.innerHTML;
  }
  onChangeTemplate(event: any){
    console.log(this.accountsReceivableService.selecedTemplatedValue,"this.accountsReceivableService.selecedTemplatedValue33");
  this.showTemplateEmailDialog.close('openTemplateDialog');
}


}
