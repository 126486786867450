<div *ngIf="itemChangedPopup.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="itemChangedPopup.show" uitkId="itemChangedPopup" [closeBtn]=false>
    <uitk-dialog-header>
      <div cdkDragHandle>
        <div *ngIf="productType === 'Promotion'">
          <h4 class="modal-title pull-left">
            Promotion Item Changed
          </h4>
        </div>
        <div *ngIf="productType === 'Premium'">
          <h4 class="modal-title pull-left">
            Premium Item Changed
          </h4>
        </div>
      </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
      <div *ngIf="productType === 'Promotion'">
        <p>The discount on promotion item {{productCode}} has changed.<br>Item {{productCode}} and any associated premiums are no longer considered part of the promotion.
        </p>
      </div>
      <div *ngIf="productType === 'Premium'">
        <p>The discount on premium item {{productCode}} has changed.<br>Item {{productCode}} is no longer considered part of the promotion.
        </p>
      </div>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="deletePremiums()"
        #quantityCheckOkBtn appAutoFocus>
        Ok
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
  </div>
</div>