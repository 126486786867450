<div class="css-t7viak">
    <div class="uitk-l-grid">
        <div class="uitk-l-grid__row">
            <div class="uitk-l-grid__col--12">
              <div class="uitk-c-table__caption">
                <h3 id="table-paginate" class="caption">Order Search Results </h3>
                Total Records : {{totalRecords}}
                <br>

                <button type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
                (click)="generateCFD()">Generate CFD</button>  

            </div>
                <uitk-pagination [paginationConfig]="paginationConfig" [entriesPerPage]="entriesPerPage"
                    [enableGoToPage]="true" [totalRecordsCount]="totalRecords"
                    (onPaginationChange)="onPaginationChange($event)">

                    <div class="uitk-c-table--responsive" class="tableFontSize" uitkTableContainer [sticky]="true"style="max-height:800px;">
                        <table uitkTable [dataSource]="orderSearchResults" class="uitk-c-table" aria-describedby="order-data"
                            id="order-results-table" aria-labelledby="table-paginate" aria-describedby="row-data"
                            #rootTable="uitkTable">

                            <thead class="uitk-c-table__head">
                                <tr uitkTableSort #sortTable="uitkTableSort">
                                   
                                    <th *ngFor="let headerName of tableHeader" scope="col" uitkTableSortColumn
                                        (sortChange)="onSortChange($event)" [id]="headerName.id" [column]="headerName.id">
                                        {{ headerName.name }}
                                    </th>
                                    <th>  
                                        <input type="checkbox" [(ngModel)]="selectAll" (change)="toggleAll()" />  
                                    </th>  
                                </tr>
                            </thead>
                            <tbody id="row-data" class="uitk-c-table__body">
                                <ng-container *uitkTableRowBody="let order">
                                  <tr class="uitk-c-table__row" >
                                    <td id="accountName"><a target="_blank" (click)="navigateToSFViewAccount(order.salesforceId)" class="link">{{ order.accountName }}</a></td>
                                    <td><a target="_blank" (click)="navigateToARInquiry(order.accountNumber)" class="link">{{ order.accountNumber }}</a></td>
                                    <td>
                                        <div *ngIf="order.company !== order.accountName">{{ order.company }}<br></div>
                                        <div *ngIf="order.department">{{ order.department }}<br></div>
                                        {{ order.streetAddress }}<br>
                                        {{ order.city }}, {{ order.state }},
                                        {{ order.zipCode }}<br>
                                        <div *ngIf="order.country !== 'UNITED STATES'">{{ order.country }}</div>
                                    </td>
                                    <td>{{ order.webReferenceNumber }}</td>
                                    <td id="poNumber" class="poNumberColumnWidth">{{ order.poNumber }}</td>
                                    <td>
                                      <a href="#" *ngIf="(order.orderType === 'Q'||order.orderStatus === 'D'||order.orderStatus === 'P'|| order.orderStatus === 'R' || order.orderStatus === 'M') && (order.orderType !== 'R' && order.orderType !== 'A')" [routerLink]="['/order-entry']" [queryParams]="{orderId: order.orderNumber}">{{ order.orderNumber }}</a>
                                      <a href="#" *ngIf="order.orderType !== 'R' && order.orderType !== 'A' && order.orderType !== 'Q' && order.orderStatus !== 'D' && order.orderStatus !== 'P' &&  order.orderStatus !== 'R' && order.orderStatus !== 'M'" [routerLink]="['/order', order.orderNumber]">{{ order.orderNumber }}</a>
                                      <a href="#" *ngIf="order.orderType === 'R' || order.orderType === 'A'" [routerLink]="['/return', order.orderNumber]">{{ order.orderNumber }}</a>
                                    </td>
                                    <td class="centerOrderTypeColumn">{{ order.orderType }}</td>
                                    <td class="centerOrderStatusColumn">{{ order.orderStatus }}</td>
                                    <td>{{ order.totalOrderDollars | currency }}</td>
                                    <td>{{ order.shippedDollars | currency }}</td>
                                    <td>{{ order.openDollars | currency }}</td>
                                    <td>{{ order.orderDate | date: 'MM/dd/yyyy' }}</td>
                                    <td *ngIf="order.trackingNumber?.startsWith('1Z')" id="trackingNumber"><a target="_blank" class="link" style="text-decoration: none;" (click)="openUpsTracking(order.trackingNumber)">{{order.trackingNumber}}</a></td>
                                    <td *ngIf="!order.trackingNumber?.startsWith('1Z')" id="trackingNumber">{{order.trackingNumber}}</td>
                                    <td>{{ order.shipToContactName }}</td>
                                    <td>{{ order.shipToContactId }}</td>
                                    <td>{{ order.orderByContactName }}</td>
                                    <td>{{ order.orderByContactId }}</td>
                                    <td>{{ order.enteredBy | titlecase}}</td>
                                    <td><input type="checkbox" [(ngModel)]="order.cfd" (change)="updateSelectAllState()" /></td>   
 
                                </tr>
                                </ng-container>
                                <div *ngIf="orderSearchResults.data.length === 0">No records found</div>
                            </tbody>
                        </table>
                    </div>
                </uitk-pagination>
            </div>
        </div>
    </div>
</div>
 



  <div *ngIf="quoteCfdFormEmail.show" class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog uitkId="quoteCdfForm" [(showModal)]="quoteCfdFormEmail.show" [closeBtn]="false">
    <uitk-dialog-header>
        <div cdkDragHandle>
            <div style="border-bottom: 1px solid; text-align: center; background-color: #f2f2f2;">
                <label style=" font-size: 20px;">CFD Email</label>
            </div>
        </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
        <div class="row mx-3">
            <div class="row mt-2">
                <label class="col-lg-3 align-self-center" uitkLabel>From: </label>
                <div class="col-lg-9">
                    <uitk-form-field>
                        <input uitkInput id="emailfrom" class="textbox" type="text" [(ngModel)]="cfdEmailDto.emailFrom" />
                    </uitk-form-field>
                </div>
            </div>
            <div class="row mt-2">
                <label class="col-lg-3 align-self-center" uitkLabel>TO Recipients: </label>
                <div class="col-lg-9">
                    <uitk-form-field id="toRecipients">
                        <input uitkInput #toRecipients="ngModel" class="textbox" type="email" [pattern]="emailPattern"
                            value="" [(ngModel)]="cfdEmailDto.toRecipients" />
                        <uitk-form-field-error *ngIf="toRecipients?.errors?.pattern && toRecipients?.touched">
                            Invalid Email
                        </uitk-form-field-error>
                    </uitk-form-field>
                </div>
            </div>
            <div class="row mt-2">
                <label class="col-lg-3 align-self-center" uitkLabel>CC Recipients: </label>
                <div class="col-lg-9">
                    <uitk-form-field id="ccRecipients">
                        <input uitkInput #ccRecipients="ngModel" class="textbox" type="email" [pattern]="emailPattern"
                            value="" [(ngModel)]="cfdEmailDto.ccRecipients" />
                        <uitk-form-field-error *ngIf="ccRecipients?.errors?.pattern && ccRecipients?.touched">
                            Invalid Email
                        </uitk-form-field-error>
                    </uitk-form-field>
                </div>
            </div>
            <div class="row mt-2">
                <label class="col-lg-3 align-self-center" uitkLabel>BCC Recipients: </label>
                <div class="col-lg-9">
                    <uitk-form-field id="`bccRecipients">
                        <input uitkInput #bccRecipients="ngModel" class="textbox" type="email" [pattern]="emailPattern"
                            value="" [(ngModel)]="cfdEmailDto.bccRecipients" />
                        <uitk-form-field-error *ngIf="bccRecipients?.errors?.pattern && bccRecipients?.touched">
                            Invalid Email
                        </uitk-form-field-error>
                    </uitk-form-field>
                </div>
            </div>
            <div class="row mt-2">
                <label class="col-lg-3 align-self-center" uitkLabel>Subject: </label>
                <div class="col-lg-9">
                    <uitk-form-field>
                        <input uitkInput id="emailsubject" class="textbox" type="text" value=""
                        [(ngModel)]="cfdEmailDto.subject" />
                    </uitk-form-field>
                </div>
            </div>
             <div class="row mt-2">
                <label class="col-lg-3 align-self-center" uitkLabel>Attachments: </label>
                <div class="col-lg-9">
                    <a class="link" target="_blank" style="color:#316bbe;" (click)="previewQuoteCFDPdf()"><strong>PDF (click to view)</strong></a>
                </div>
            </div>  
             <div class="row mt-4">
                <label class="col-lg-3 align-self-center" uitkLabel>Email Body: </label>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <textarea uitkTextarea style="height:300px; padding: 18px;" [(ngModel)]="cfdEmailDto.emailBody"></textarea>
                </div>
            </div>
        </div>
    </uitk-dialog-content>
    <uitk-dialog-actions style="width: fit-content;">
        <div class="mx-4" style="margin-bottom: 20px;">
            <button id="back" type="button"
                class="uitk-c-button uitk-c-button--alternative-ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
                 (click)="cancelDialog()">Back</button>

            <button id="sendEmail" type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
                (click)="sendEmail()">Send Email</button>
        </div>
    </uitk-dialog-actions>
</uitk-dialog>
    </div>
</div>
<div id="divQuoteCFDPdf" style="display: none;">
    <app-quote-cfd-pdf [enteredByUserRole]="enteredByUserRole" [disclaimerType]="disclaimerType"></app-quote-cfd-pdf>
  </div> 