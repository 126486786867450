import { AfterViewChecked, ChangeDetectorRef, Component,EventEmitter,ElementRef, Input, OnInit, Output, ViewChild,ViewEncapsulation} from '@angular/core';
import { IUITKRadioGroupItem, IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { SwalAlert } from '../../../helpers/alert';
import { InvoiceDetailsDto } from '../../../dtos/invoice-details-dto.model';
import { InvoiceEditDto } from '../../../dtos/invoice-edit-dto.model';
import { AccountsReceivableService } from '../../../services/accounts-receivable.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { ConfigService } from '../../../services/config.service';
import { InvoiceEditService } from '../../../services/invoice-edit.service';
import { InvoicesService } from '../../../services/invoices.service';
import { TokenizeService } from '../../../services/tokenize.service';

@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html',
  styleUrls: ['./invoice-edit.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
  
export class InvoiceEditComponent implements OnInit, AfterViewChecked {
  @ViewChild("yesBtn") yesBtn!: ElementRef<HTMLInputElement>;
  @Input() accountId: number = 0;
  @Output() invoiceEdited = new EventEmitter<null>();
  getSelectedInvoicesSubscription: Subscription | undefined;
  selInvoiceList: InvoiceDetailsDto [] = [];
  invoiceError: Subscription | undefined;
  editDialogModal = { show: false };
  selPO: any;
  accPterm: string = '';
  poNumber: string = '';
  selPaymentToken!: IUITKRadioGroupItem;
  selChgPayToken: any;
  ccNumber: string = '';
  token: string = '';
  pTerms: IUITKSelectItemProps | null = null;
  expDate: string = '';
  fourDigits: string = '';
  defaultLabel = "- Please Select";
  defaultLabelFlag = true;
  invoiceCnt: number = 0;
  date: any;
  isPastDate: boolean = false;
  changeCC = false;
  changePT = false;
  showCCNumberError = false;
  payMethod: IUITKSelectItemProps | undefined;
  ipayMethod: IUITKSelectItemProps | undefined;
  poItems: IUITKRadioGroupItem[] = [
    {
      label: "Yes",
      value: true,
      disabled: false,
    },
    {
      label: "No",
      value: false,
      disabled: false,
    },
  ];
  chgPayTokenItems: IUITKRadioGroupItem[] = [
    {
      label: "Yes",
      value: "Yes",
      disabled: false,
    },
    {
      label: "No",
      value: "No",
      disabled: false,
    },
  ];
  payTokenItems: IUITKRadioGroupItem[] = [
    {
      label: "Enter a Credit Card",
      value: "cc",
      disabled: !this.authorizationService.hasResource("EditInvoiceEnterACreditCardRadio"),
    },
    {
      label: "Enter a Token",
      value: "tk",
      disabled: !this.authorizationService.hasResource("EditInvoiceEnterATokenRadio"),
    },
    {
      label: "Modify the Payment Terms",
      value: "pt",
      disabled: !this.authorizationService.hasResource("EditInvoiceModifyThePaymentTermsRadio"),
    },
  ];
  isSaveSuccess = false;
  payMethodList: IUITKSelectItemProps[] = [];
  ccPayMethodList: IUITKSelectItemProps[] = [];
  iPayMethodList: IUITKSelectItemProps[] = [];
  pTermsList: IUITKSelectItemProps[] = [];
  configIsReadySubscription: Subscription | undefined;
  showPayMethodError = false;
  showiPayMethodError = false;
  showTokenError = false;
  showPTermsError = false;
  showFourDigitsPatternError = false;
  showExpDatePatternError = false;
  showTokenPatternError = false;
  fourDigitsPattern = "^[0-9]+$";
  expDatePattern = /^((0[1-9])|(1[0-2]))(\d{2})$/;
  tokenPattern = "^[a-zA-Z0-9]+$";
  invoiceDetailsToBeUpdated: InvoiceEditDto[] = [];
  openAndFutureInvoicesUpdatePopup = {
    show: false
  };
  swalAlert = new SwalAlert();

  constructor(public configService: ConfigService, public invoiceEditService: InvoiceEditService, public accountsReceivableService: AccountsReceivableService,
    public tokenService: TokenizeService, private authorizationService: AuthorizationService, private cdRef: ChangeDetectorRef,public invoicesService: InvoicesService) {
    this.poNumber = "";
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }


  ngOnInit(): void {
    this.selPO = this.poItems[1];
    this.selChgPayToken = this.chgPayTokenItems[1];
    this.selPaymentToken = this.payTokenItems[0];
    this.payMethodList = this.ccPayMethodList;
    this.ccNumber = '';
    this.invoiceError = this.invoiceEditService.error.subscribe(() => {
      this.swalAlert.alert('Unable to update invoice.  Please try again.');
    });
  }

  onCCChange(encryptedCCStr: any): void {
    if ((encryptedCCStr.length === 157 || encryptedCCStr.length === 158 || encryptedCCStr.length === 177 || encryptedCCStr.length === 178) && encryptedCCStr.endsWith('03')) {
      this.tokenService.getCcToken({ encryptedData: this.ccNumber }).subscribe((data) => {
        this.token = data.token;
      })
      const firstSet = this.ccNumber.split("?")[0].split("=");
      this.expDate = firstSet[1].substr(2, 2) + firstSet[1].substr(0, 2);
      const secondSet = firstSet[0].split(";");
      this.ccNumber = secondSet[1];
      this.fourDigits = secondSet[1].substr(secondSet[1].length - 4);

      switch (this.ccNumber.substr(0, 1)) {
        case "3":
          this.payMethod = this.payMethodList.find(pmeth => pmeth.label === "American Express");
          break;
        case "4":
          this.payMethod = this.payMethodList.find(pmeth => pmeth.label === "Visa");
          break;
        case "5":
          this.payMethod = this.payMethodList.find(pmeth => pmeth.label === "MasterCard");
          break;
        case "6":
          this.payMethod = this.payMethodList.find(pmeth => pmeth.label === "Discover");
          break;
        default:
          break;
      }
      setTimeout(() => {
        this.token = 'Processing, please wait ...';
      });
      setTimeout(() => {
        this.tokenService.getCcToken({ encryptedData: encryptedCCStr }).subscribe((data) => {
          this.token = data.token;
        })
      }, 2000);
    }
    else {
      this.showTokenError = true;
      this.ccNumber = ''
    }

  }


  disableEditButton(invoices: InvoiceDetailsDto[]) {
    this.selInvoiceList = invoices;
    this.accountsReceivableService.updateSelectedInvoicesList(this.selInvoiceList);
    this.invoiceCnt = this.selInvoiceList.length;
    if (this.selInvoiceList.length > 0 && !(localStorage.getItem('rolename') === "Customer Service" && this.invoicesService.hasCCMemo)) {
      document.getElementById('editBtn')?.removeAttribute('disabled');
    }
    else {
      document.getElementById('editBtn')?.setAttribute('disabled', 'disabled');
    }
  }




  getDropDownValues() {
    this.ccPayMethodList = this.configService.getPickList("PMETH").filter(pl => ['D', 'X', 'M', 'V'].indexOf(pl.value) >= 0);
    this.iPayMethodList = this.configService.getPickList("IPMETH");
    this.pTermsList = this.configService.getPickList("IPTERMS").sort(
      function (a, b) {
        return parseInt(a.id) - parseInt(b.id);
      });
    if (!this.authorizationService.hasResource("OrderInquiryPaymentMethodSOrN")) {
      this.configService.disablePickListOption(this.iPayMethodList, "ShipTo");

      this.configService.disablePickListOption(this.iPayMethodList, "NoInvoice");
    }
  }

  showEditDialog() {
    this.editDialogModal.show = true;
    this.accountsReceivableService.getSelectedInvoicesEmitter.
      subscribe((invoices: InvoiceDetailsDto[]) => {
        this.selPO = this.poItems[1];
        this.selChgPayToken = this.chgPayTokenItems[1];
        this.selPaymentToken = this.payTokenItems[0];
        this.invoiceCnt = invoices.length;
        this.selInvoiceList = invoices;
        this.getDropDownValues();
        this.payMethodList = this.ccPayMethodList;
        this.ccNumber = '';
        this.poNumber = "";
        this.pTerms = null;
        this.ipayMethod = undefined;
        this.showCCNumberError = false;
        this.getAccountPTerm();
      });
  }

  saveInvoice() {
    this.invoiceDetailsToBeUpdated = [];
    this.validateInput();
    const currentUser = this.configService.getMyUserCode();

    if (this.showPayMethodError === false && this.showTokenError === false
      && this.showPTermsError === false && this.isPastDate === false && this.showFourDigitsPatternError === false
      && this.showExpDatePatternError === false && this.showTokenPatternError === false) {
      this.editDialogModal.show = false;

      this.selInvoiceList.forEach(element => {
        const invoiceDetailsDto = new InvoiceEditDto();
        invoiceDetailsDto.invoiceNumber = element.invoiceNumber;
        invoiceDetailsDto.changePONumber = this.selPO.value;
        invoiceDetailsDto.userLogin = this.configService.getMyMSId();
        invoiceDetailsDto.orderNumber = element.orderNumber;
        invoiceDetailsDto.invoiceDate = element.invoiceDate;
        invoiceDetailsDto.balance = element.balance;
        invoiceDetailsDto.isSelected = true;

        if (this.selPO.value === true) {
          invoiceDetailsDto.poNumber = this.poNumber;
        }


        switch (true) {
          case ((this.selPaymentToken.value === "cc" || this.selPaymentToken.value === "tk") && (this.selChgPayToken.value === "Yes")):
            invoiceDetailsDto.changeCreditCard = true;
            invoiceDetailsDto.changePaymentTerms = false;
            invoiceDetailsDto.expirationDate = this.expDate.trim() === "" ? "0199" : this.expDate;
            invoiceDetailsDto.ccLastFour = this.fourDigits.trim() === "" ? "0000" : this.fourDigits;
            invoiceDetailsDto.token = this.token;
            invoiceDetailsDto.paymentMethod = this.payMethod?.label.replace(' ', '') ?? '';
            break;
          case ((this.selPaymentToken.value === "pt") && (this.selChgPayToken.value === "Yes")):
            invoiceDetailsDto.changePaymentTerms = true;
            invoiceDetailsDto.changeCreditCard = false;
            invoiceDetailsDto.paymentTerms = this.pTerms?.value ?? '';
            invoiceDetailsDto.paymentMethod = this.ipayMethod?.value ?? '';
            invoiceDetailsDto.expirationDate = "";
            invoiceDetailsDto.ccLastFour = "";
            invoiceDetailsDto.token = "";
            break;
          default:
            invoiceDetailsDto.changeCreditCard = false;
            invoiceDetailsDto.changePaymentTerms = false;
            break;
        }

        invoiceDetailsDto.userLogin = currentUser;
        this.invoiceDetailsToBeUpdated.push(invoiceDetailsDto);
      });

      if ((this.selPaymentToken.value === "cc" || this.selPaymentToken.value === "tk") && (this.selChgPayToken.value === "Yes")) {
        const uniqueOrderIds = [...new Set(this.invoiceDetailsToBeUpdated.map(i => i.orderNumber))];
        
        uniqueOrderIds.forEach(element => {
          const pendingOrOpenInvoicesList = this.invoicesService.invoiceResults?.filter(i => i.orderNumber === element &&
            (i.invoiceNumber === 'Pending' || i.balance > 0));

          pendingOrOpenInvoicesList?.forEach(element => {
            const invoiceDetailsDto = new InvoiceEditDto();

            if (this.selPO.value === true) {
              invoiceDetailsDto.poNumber = this.poNumber;
            }

            invoiceDetailsDto.invoiceNumber = element.invoiceNumber;
            invoiceDetailsDto.changePONumber = this.selPO.value;
            invoiceDetailsDto.userLogin = this.configService.getMyMSId();
            invoiceDetailsDto.orderNumber = element.orderNumber;
            invoiceDetailsDto.invoiceDate = element.invoiceDate;
            invoiceDetailsDto.balance = element.balance;
            invoiceDetailsDto.changeCreditCard = true;
            invoiceDetailsDto.changePaymentTerms = false;
            invoiceDetailsDto.expirationDate = this.expDate.trim() === "" ? "0199" : this.expDate;
            invoiceDetailsDto.ccLastFour = this.fourDigits.trim() === "" ? "0000" : this.fourDigits;
            invoiceDetailsDto.token = this.token;
            invoiceDetailsDto.paymentMethod = this.payMethod?.label.replace(' ', '') ?? '';
            invoiceDetailsDto.userLogin = currentUser;
            this.invoiceDetailsToBeUpdated.push(invoiceDetailsDto);
          });
        });
        
        const isOpenOrFutureInvoicesExist = this.invoiceDetailsToBeUpdated.some(i => (i.invoiceNumber === 'Pending' || i.balance > 0));

        if (isOpenOrFutureInvoicesExist) {
          this.invoiceDetailsToBeUpdated = this.invoiceDetailsToBeUpdated.filter(i => (i.invoiceNumber === 'Pending' || i.balance > 0));
          this.openAndFutureInvoicesUpdatePopup.show = true;

          setTimeout(() => {
            this.yesBtn.nativeElement.focus();
          }, 500);
        }
        else {
          this.updatePaymentDetailsForOpenAndFutureInvoices();  
        }
      }
      else {
        this.updatePaymentDetailsForOpenAndFutureInvoices();
      }
    }
  }


  updatePaymentDetailsForOpenAndFutureInvoices(): void {
    this.openAndFutureInvoicesUpdatePopup.show = false;
    
    this.invoiceDetailsToBeUpdated.forEach(invoice => {
      this.invoiceEditService.updateInvoiceDetails(this.accountId.toString(), invoice).subscribe(() => {
        this.invoiceEdited.emit();
      }, (error: any) => {
        console.log(error);
        this.swalAlert.alert('An error occurred while updating the invoice details');
      });
    });

    this.isSaveSuccess = true;
    this.selInvoiceList = [];
    this.invoiceDetailsToBeUpdated = [];
    this.accountsReceivableService.updateSelectedInvoicesList(this.selInvoiceList, true);
  }

  updatePaymentDetailsForSelectedInvoices(): void{
    this.openAndFutureInvoicesUpdatePopup.show = false;
    this.invoiceDetailsToBeUpdated = this.invoiceDetailsToBeUpdated.filter(i => i.isSelected);

    this.invoiceDetailsToBeUpdated.forEach(invoice => {
      this.invoiceEditService.updateInvoiceDetails(this.accountId.toString(), invoice).subscribe(() => {
        this.invoiceEdited.emit();
      }, (error: any) => {
        console.log(error);
        this.swalAlert.alert('An error occurred while updating the invoice details');
      });  
    });
    
    this.isSaveSuccess = true;
    this.selInvoiceList = [];
    this.invoiceDetailsToBeUpdated = [];
    this.accountsReceivableService.updateSelectedInvoicesList(this.selInvoiceList, true);
  }

  getAccountPTerm() {
    this.accountsReceivableService.getAccountsReceivableResultsEmitter.subscribe(ar => {
      this.accPterm =
        this.pTermsList.find(o => o.label.toLowerCase().trim() === ar.accountResultDto.paymentTerms.toLowerCase().trim())?.value!;
    })
  }

  validateInput() {
    this.showPayMethodError = false;
    this.showTokenError = false;
    this.showPTermsError = false;
    this.isPastDate = false;
    this.showFourDigitsPatternError = false;
    this.showExpDatePatternError = false;
    this.showTokenPatternError = false;

    if ((this.selChgPayToken.value === "Yes") && (this.selPaymentToken?.value === "cc" || this.selPaymentToken?.value === "tk")) {

      if (this.payMethod === undefined)
      {
        this.showPayMethodError = true;
      }
      this.tokenChange(this.token);
      this.expDateChange(this.expDate);
      this.fourDigitsChange(this.fourDigits)
    }
    if ((this.selChgPayToken.value === "Yes") && (this.selPaymentToken?.value === "pt")) {
      if (this.pTerms === null && this.ipayMethod === undefined) {
        this.showPTermsError = true;
        this.showiPayMethodError = true;
      }
      else {
        this.showPTermsError = false;
        this.showiPayMethodError = false;
      }
    }
    if(this.selPaymentToken?.value === "cc" ){
      if(!this.ccNumber){
        this.showCCNumberError = true;
      }
    }
    else{
      this.showCCNumberError = true;
    }
  }
  closeDialog() {
    this.editDialogModal.show = false;
  }
  poItemSelectionChange() {
    if (this.selPO.value === true) {
      this.poNumber = "";
    }
  }
  tokenChange(token: string) {
    if (token !== ""){
      this.showTokenError = false;
    }      
    else{
      this.showTokenError = true;
    }     

    if (token !== "" && token !== null && !new RegExp(this.tokenPattern).test(token)){
      this.showTokenPatternError = true;
    }      
    else{
      this.showTokenPatternError = false;
    }
  }
  pTermsChange(pTerms: IUITKSelectItemProps | null) {
    if (pTerms !== null) {
      this.showiPayMethodError = false;
      this.showPTermsError = false;
    }
  }
  payMethodChange(payMethod: IUITKSelectItemProps | undefined) {
    if (payMethod != null){
      this.showPayMethodError = false;
    }
  }
  ipayMethodChange(ipayMethod: IUITKSelectItemProps | undefined) {
    if (ipayMethod !== null) {
      this.showiPayMethodError = false;
      this.showPTermsError = false;
    }
  }
  expDateChange(expDate: string) {
    if (expDate !== "" && expDate !== null && !new RegExp(this.expDatePattern).test(expDate)){
      this.showExpDatePatternError = true;
    }      
    else{
      this.showExpDatePatternError = false;
    }     


    if (expDate !== "" && expDate !== null && expDate.length === 4) {

      this.isPastDate = false;
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = parseInt(new Date().getFullYear().toString().substr(2, 2));
      const expYear = parseInt(this.expDate.substr(2, 2));
      const expMonth = parseInt(this.expDate.substr(0, 2));
      if (expYear < currentYear) {
        this.isPastDate = true;
      }
      else if (expYear === currentYear && expMonth < currentMonth) {
        this.isPastDate = true;
      }
      else {
        this.isPastDate = false;
      }
    }
    else
    {
      this.isPastDate = false;
    }
  }

  fourDigitsChange(fourDigits: string) {
    if (fourDigits !== "" && fourDigits !== null && !new RegExp(this.fourDigitsPattern).test(fourDigits)){
      this.showFourDigitsPatternError = true;
    }    
    else{
      this.showFourDigitsPatternError = false;
    }
  }

  PopultateDefaultPaymentToken() {
    if (this.selChgPayToken.value === 'Yes') {
      this.selPaymentToken = this.payTokenItems[0];
      this.selPaymentToken.value = 'cc';
      if (this.ccPayMethodList.length <= 0){
        this.getDropDownValues();
      }

      this.payMethodList = this.ccPayMethodList;
      this.showPayMethodError = false;
      this.showTokenError = false;
      this.showPTermsError = false;
      this.isPastDate = false;
      this.showFourDigitsPatternError = false;
      this.showExpDatePatternError = false;
      this.showTokenPatternError = false;
      this.pTerms = null;
      this.token = '';
      this.expDate = '';
      this.ccNumber = '';
      this.fourDigits = '';
      this.payMethod = undefined;
    }
  }

  PaymentTokenChange() {
    if (this.ccPayMethodList.length <= 0 || this.iPayMethodList.length <= 0){
      this.getDropDownValues();
    }      

    if ((this.selPaymentToken.value === 'cc') || (this.selPaymentToken.value === 'tk')) {
      this.payMethodList = this.ccPayMethodList;
      this.changeCC = true;
    }
    else {
      this.payMethodList = this.iPayMethodList;
      this.changePT = true;
    }

    this.showPayMethodError = false;
    this.showTokenError = false;
    this.showPTermsError = false;
    this.isPastDate = false;
    this.showFourDigitsPatternError = false;
    this.showExpDatePatternError = false;
    this.showTokenPatternError = false;
    this.showCCNumberError = false;
  }

  ngOnDestroy() {
    this.configIsReadySubscription?.unsubscribe();
    this.invoiceError?.unsubscribe();
    this.getSelectedInvoicesSubscription?.unsubscribe();
  }
}
