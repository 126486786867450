import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { Mode } from '../enums/mode.enum';
import { ShipmentHistoryDto } from '../dtos/shipment-history-dto.model';
import { ConfigService } from './config.service';
import { ModeService } from './mode.service';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {
  shipmentHistoryDto: ShipmentHistoryDto[] = [];
  shipmentHistoryIsReady = new Subject();
  private readonly _httpClient: HttpClient;
  private readonly _modeService: ModeService;
  private readonly _configService: ConfigService;
  shipmentHistoryCache!: ShipmentHistoryDto[] | null;
  shipmentHistoryCachedObservable!: Observable<ShipmentHistoryDto[]> | null;
  swalAlert = new SwalAlert();

  constructor(httpClient: HttpClient, modeService: ModeService, configService: ConfigService) {
    this._httpClient = httpClient;
    this._modeService = modeService;
    this._configService = configService;
  }

  getShipmentHistory(accountId: number, subscriptionNumber: number): void {
    this._modeService.mode = Mode.Wait;
    let observable: Observable<ShipmentHistoryDto[]>;

    if (this.shipmentHistoryCache) {
      observable = of(this.shipmentHistoryCache);
    }
    else if (this.shipmentHistoryCachedObservable) {
      observable = this.shipmentHistoryCachedObservable;
    }
    else {
      this.shipmentHistoryCachedObservable = this._httpClient
        .get<ShipmentHistoryDto[]>(`${this._configService.apiUrl}/Subscription/GetShipmentHistory?accountId=${accountId}&subscriptionNumber=${subscriptionNumber}`)
        .pipe(
          tap((response: ShipmentHistoryDto[]) => {
            this.shipmentHistoryCache = response;
          }),
          share(),
          finalize(() => {
            this.shipmentHistoryCache = null;
            this.shipmentHistoryCachedObservable = null;
          })
        );

      observable = this.shipmentHistoryCachedObservable;
    }

    observable.subscribe((data: ShipmentHistoryDto[]) => {
      this.shipmentHistoryDto = data;
      this._modeService.mode = Mode.View;
      this.shipmentHistoryIsReady.next();
    }, (error: any) => {
      console.log(error);
      this._modeService.mode = Mode.View;
      this.swalAlert.alert('An error occurred while getting shipment history details. Please try again.');
    });
  }
}
