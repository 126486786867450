<div class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkId="viewSalespersonData" [(showModal)]="dialog.show" [closeBtn]="true" triggerElementId="sales-person-textbox" (onModalHidden)="closeModal()">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Salesperson Search
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div class="row">
      <uitk-form-field>
        <input appAutoFocus uitkInput id="sales-person-textbox" class="textbox mx-1" type="text" [(ngModel)]="salesPersonSearchKeyword"
          style="width: 250px;"/>
      </uitk-form-field>
      <span class="uitk-icon uitk-icon-search salesPersonLookUp" (click)="showSalesPersonDetails()" style="width: 30px;"></span>
    </div>
    <div class="row mt-3">
      <div class="uitk-c-table--responsive container">
        <table uitkTable [dataSource]="salesPersonData" id="sales-person-details-table" class="uitk-c-table"
          aria-describedby="sales-person-details-data">
          <thead class="uitk-c-table__head">
            <tr>
              <th *ngFor="let headerName of tableHeader" scope="col">
                {{ headerName}}
              </th>
            </tr>
          </thead>
          <tbody id="sales-person-details-data" class="uitk-c-table__body">
            <ng-container *uitkTableRowBody="let data">
              <tr class="uitk-c-table__row">
                <td style="cursor: pointer;" (click)="selectSalesPerson($event)">{{ data }}</td>
              </tr>
            </ng-container>
            <div *ngIf="salesPersonData.data.length === 0 && isDataFound">No records found</div>
          </tbody>
        </table>
      </div>
    </div>
  </uitk-dialog-content>
</uitk-dialog>
  </div>
</div>