import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IUITKSelectItemProps } from '@uitk/angular';
import { ConfigService } from '../../services/config.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-return-entry',
  templateUrl: './return-entry.component.html',
  styleUrls: ['./return-entry.component.css'],
})
  
export class ReturnEntryComponent implements OnInit {
  private readonly _router: Router;
  private readonly _configService: ConfigService;
  hideReturnsPopUp: boolean = false;
  @Output() newItemEvent = new EventEmitter<boolean>();
  orderTypeList: IUITKSelectItemProps[] = [];
  orderStatusList: IUITKSelectItemProps[] = [];
  orderStatusFilterList:IUITKSelectItemProps[] = [];

  constructor(router: Router, public orderService: OrderService, configService: ConfigService) {
    this._router = router;
    this._configService = configService;
  }

  ngOnInit(): void {
    this.hideReturnsPopUp = false;
    this.orderService.orderDto.orderType = 'R';
    this.orderTypeList = this._configService.getPickList('ORDERTYPE');
    this.orderService.orderDto.orderTypeBinding = this.orderTypeList.filter(x=>x.id==='R')[0];
    this.orderStatusList = this._configService.getPickList('ORDERSTATUS');

    this.orderStatusFilterList =  this.orderStatusList.filter(x=>(x.id===' '||x.id==='H'));
    this.orderService.orderDto.orderStatusBinding = this.orderStatusFilterList.filter(x=>x.id===' ')[0];
    this.orderService.orderDto.orderStatus = ' ';
    this.orderService.orderDto.originalOrderId = this.orderService.orderDto.id;
    this.orderService.orderDto.enteredBy = this._configService.getMyMSId().toUpperCase().replace('MS\\', '');
    this.orderService.orderDto.enteredByBindingEmail=this._configService.getUserEmailFromUserLogin(this.orderService.orderDto.enteredBy);
  }

  navigateToOrderInquiry(orderId: number | null) {
    this._router.navigate(['/order/' + orderId]);
  }
}
