import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ApprovalService } from 'src/app/services/approval.service';
import { ConfigService } from 'src/app/services/config.service';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-approval-confirmation',
  templateUrl: './approval-confirmation.component.html',
  styleUrls: ['./approval-confirmation.component.css']
})

export class ApprovalConfirmationComponent implements OnInit {
  private readonly _activatedroute: ActivatedRoute
  private readonly _router: Router
  approvalConfirmationDialog = { show: false };
  configIsReadySubscription: Subscription | undefined;
  getOrderApprovalIsReadySubscription: Subscription | undefined;
  orderApprovalId = 0;
  userCode = '';
  swalAlert = new SwalAlert();

  constructor(activatedroute: ActivatedRoute,
    public configService: ConfigService,
    public approvalService: ApprovalService,
    router: Router) {
    this._activatedroute = activatedroute;
    this._router = router;
  }

  ngOnInit(): void {
    localStorage.setItem('approvalFromEmail', 'true');
    this.configIsReadySubscription = this.configService.configIsReady.pipe(first()).subscribe(async () => {
      if (this._activatedroute.snapshot.params.id) {
        this.orderApprovalId = this._activatedroute.snapshot.params.id;
        this._activatedroute.queryParams.subscribe(params => {
          this.userCode = params.approver;
        });
        this.approvalService.getOrderApproval(this.orderApprovalId);
        this.configIsReadySubscription?.unsubscribe();
      }
    });
    this.configService.loadConfigurations();
    this.getOrderApprovalIsReadySubscription = this.approvalService.orderApprovalIsReady.subscribe(() => {
      if (this.approvalService.orderApprovalDto.isAlreadyApproved) {
        // TODO: Redirect user to page letting them know the approval was already completed.
        this.swalAlert.alert('Order has previously been approved/rejected');
        return;
      }
      if (this.approvalService.orderApprovalDto.isMultiYearInProcess) {
        this.swalAlert.alert('Last year in this order has not been submitted, please wait 5 minutes and try again.');
      }
      else {
        this.approvalService.approvalDto.id = this.approvalService.orderApprovalDto.id;
        const userCode = this.configService.getUserCodeFromUserLogin(this.userCode);
        if (userCode) {
          this.approvalService.approvalDto.approvedOrRejectedBy = this.configService.getFullNameFromUserCode(userCode);
        }
        this.approvalService.approvalDto.approvedOrRejectedStatus = 'A';
        this.approvalService.recordApproval().subscribe(() => {
          this.approvalConfirmationDialog.show = true;
        }, error => {
          console.log(error);
          this.swalAlert.alert('An error occurred while updating approvalDto. Please try again.');
        });
      }
    });

  }

  closeDialog(): void {
    this.approvalConfirmationDialog.show = false;
    localStorage.removeItem('approvalFromEmail');
    window.close();
    return;
  }
}
