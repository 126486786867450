import { Component, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Subscription } from 'rxjs';
import { AuthenticationService } from './services/authentication.service';
import { AuthorizationService } from './services/authorization.service';
import { ConfigService } from './services/config.service';
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnDestroy {
  isLoggedIn: Boolean | undefined = false;
  idleState = 'Not Started';
  lastPing?: Date | null;
  openIdlePopup = false;
  idlePopUpDialogModal = {
    show: false,
  };
  browserRefreshSubscription: Subscription | undefined;

  constructor(private readonly idle: Idle,
    private readonly keepAlive: Keepalive,
    private readonly authenticationService: AuthenticationService,
    private readonly authorizationService: AuthorizationService,
    private readonly configService: ConfigService,
    private readonly router: Router) {
    this.configService.configIsReady.subscribe(() => {
      this.idle.setIdle(this.configService.idleInterval);//idleInterval should be in seconds
      this.idle.setTimeout(this.configService.timeoutInterval);//timeoutInterval should be in seconds
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      this.idle.onIdleStart.subscribe(() => {
        this.idleState = "You've gone idle!";
        this.openIdlePopup = true;
        this.idlePopUpDialogModal.show = true;
      });

      this.idle.onIdleEnd.subscribe(() => {
        this.reset();
      });

      this.idle.onTimeoutWarning.subscribe((countDown: number) => {
        this.idleState = 'You will time out in ' + countDown + ' seconds!';
      });

      this.idle.onTimeout.subscribe(() => {
        this.openIdlePopup = false;
        this.idlePopUpDialogModal.show = false;
        this.idleState = 'Timed Out!';
        this.idle.stop();
        this.idle.onIdleStart.observers.length = 0;
        this.idle.onIdleEnd.observers.length = 0;
        this.idle.onTimeoutWarning.observers.length = 0;
        this.idle.onTimeout.observers.length = 0;
        this.authenticationService.logout();
      });

      this.keepAlive.interval(15);

      this.keepAlive.onPing.subscribe(() => {
        this.lastPing = new Date();
      });

      this.authenticationService
        .isLoggedIn()
        .then((loggedIn: Boolean | undefined) => {
          this.isLoggedIn = loggedIn;
          this.stay();
          this.authorizationService.getAuthorization(this.configService.apiUrl);

          if (!loggedIn && localStorage.getItem('approvalFromEmail') === '') {
            this.idle.stop();
            this.authenticationService.login();
          }

          //#region To handle browser refresh button for orderentry draft
          this.browserRefreshSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
              browserRefresh = router.navigated;
            }
          });
          //#endregion
        });
    });

    this.configService.loadConfigurations();
  }

  reset(): void {
    this.idle.watch();
    this.idleState = 'No longer idle.';
    this.lastPing = null;
  }

  hideIdlePopup(): void {
    this.openIdlePopup = false;
    this.idlePopUpDialogModal.show = false;
  }

  stay(): void {
    this.reset();
    this.openIdlePopup = false;
    this.idlePopUpDialogModal.show = false;
  }

  signOut(): void {
    this.openIdlePopup = false;
    this.idlePopUpDialogModal.show = false;
    this.authenticationService.logout();
  }

  onActivate() {
    window.scroll(0, 0);
  }

  ngOnDestroy(): void {
    this.browserRefreshSubscription?.unsubscribe();
  }
}
