import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { AuthorizationDto } from 'src/app/dtos/authorization-dto.model';
import { ConfirmAuthorizationDto } from '../dtos/confirm-authorization-dto.model';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})

export class ConfirmAuthorizationService {
  private readonly _httpClient: HttpClient;
  private readonly _configService: ConfigService;
  authorizationsCache!: AuthorizationDto[] | null;
  authorizationsCachedObservable!: Observable<AuthorizationDto[]> | null;

  constructor(httpClient: HttpClient, configService: ConfigService) {
    this._httpClient = httpClient;
    this._configService = configService;
  }

  getAuthorizations(): Observable<AuthorizationDto[]> {
    let observable: Observable<AuthorizationDto[]>;

    if (this.authorizationsCache) {
      observable = of(this.authorizationsCache);
    }
    else if (this.authorizationsCachedObservable) {
      observable = this.authorizationsCachedObservable;
    }
    else {
      this.authorizationsCachedObservable = this._httpClient.get<AuthorizationDto[]>(`${this._configService.apiUrl}/Order/GetOpenAuthorizations`)
        .pipe(
          tap((response: AuthorizationDto[]) => {
            this.authorizationsCache = response;
          }),
          share(),
          finalize(() => {
            this.authorizationsCache = null;
            this.authorizationsCachedObservable = null;
          })
        );

      observable = this.authorizationsCachedObservable;
    }

    return observable;
  }

  confirmAuthorizations(confirmAuthorizationDto: ConfirmAuthorizationDto): Observable<ConfirmAuthorizationDto> {
    return this._httpClient.post<ConfirmAuthorizationDto>(`${this._configService.apiUrl}/Order/ConfirmAuthorizations`, confirmAuthorizationDto);
  }
}
