<div class = "overlayForPopups">
<div cdkDrag>
<uitk-dialog uitkId="notesAction" [(showModal)]="dialog.show" [closeBtn]="true" (onModalHidden)="closeDialog()" >

  <uitk-dialog-header>
    <div cdkDragHandle>
      Notes
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div id="loadingSpinner" class="uitk-l-grid" [hidden]="modeService.mode !== Mode.OrderNotesWait">
      <uitk-loading-indicator uitkId="spinner" [visibleText]="'Processing...'" spinnerSize="large">
      </uitk-loading-indicator>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <button id="addNotesButton" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs row-one"
            (click)="showAddNew()" [hidden]="isAddEditNote" [disabled]="(orderService.orderDto.orderStatus === 'V' || orderService.orderDto.orderType === 'A') && !orderService.orderDto.isReturnNote" *appShowResource="'OrderInquiryAdditionalActionsNotesButtonAddNoteButton'">
              + Add Note
          </button>
        </div>
      </div>
    </div>

    <div class="container-fluid" [hidden]="!isAddEditNote">
      <div class="row">
        <div class="col-lg-6">
          <div class="row">
            <label class="col-lg-4" uitkLabel>Created Date</label>
            <div class="col-lg-8">
              <uitk-form-field>
                <input uitkInput class="textbox" id="createdDate" type="text" disabled  [value]="selectedNote.createdDate | date: 'MM/dd/yyyy'"/>
              </uitk-form-field>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row">
            <label class="col-lg-4" uitkLabel>User Name</label>
            <div class="col-lg-8">
              <uitk-form-field>
                <input uitkInput class="textbox" id="username" type="text" disabled [(ngModel)]="selectedNote.userName" />
              </uitk-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-6">
          <div class="row">
            <label class="col-lg-4" uitkLabel>Type</label>
            <div class="col-lg-8">
              <uitk-form-field>
                <input uitkInput class="textbox" type="text"  disabled [value]="selectedNote.orderType === 'R' || selectedNote.orderType === 'A' ? 'Return Note' : 'Order Note'" />
              </uitk-form-field>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row">
            <label class="col-lg-4" uitkLabel>Note Type</label>
            <div class="col-lg-8">
              <uitk-form-field>
                <uitk-select uitkId="type" id="noteTypeDropDown" *ngIf="!isEditNote" [itemList]="noteTypeList" [(ngModel)]="noteType"
                             [disabled]="selectedNote.userCode !== myUserCode && selectedNote.orderType !== 'R' && selectedNote.orderType !== 'A'">
                </uitk-select>
                <input uitkInput id="noteTypeInput" class="textbox" type="text" *ngIf="isEditNote" disabled [value]="selectedNote.noteTypeText" />
              </uitk-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12">
          <div class="row">
            <label class="col-lg-2" uitkLabel>System Notes</label>
            <div class="col-lg-10">
              <uitk-form-field>
                <input id="systemNotes" class="systemNote" uitkInput
                [disabled]="!this.isAuthorized"
                [(ngModel)]="selectedNote.systemNote" />
              </uitk-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12">
          <div class="row">
            <label class="col-lg-2" uitkLabel>Notes</label>
            <div class="col-lg-10">
              <uitk-form-field>
                <textarea uitkTextarea id="notestext" [textLimit]="4000"
                name="notesTextInput" [disabled]="!this.isAuthorized"
                #notesTextInput="ngModel" [required]="true" [(ngModel)]="selectedNote.noteText" (input)="noteTextChange()"></textarea>
                <uitk-form-field-error *ngIf="showNotesError">
                  Notes is required
                </uitk-form-field-error>
              </uitk-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-xs-12">
          <div class="actionButtons">
            <uitk-dialog-actions>
              <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" [disabled]="selectedNote.noteText === '' || (noteType === null && !isEditNote)" (click)="saveNote()">
                Save
              </button>
              <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="cancelDialog()">
                Cancel
              </button>
            </uitk-dialog-actions>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-3" [hidden]="!this.orderService.orderDto.orderNotes">
      <div *ngIf="(this.orderService.orderDto.orderNotes?.length || 0) === 0">No records found</div>
      <div class="row mt-4" *ngFor="let note of this.orderService.orderDto.orderNotes" id="notesdiv">
        <div class="continer-fluid">
          <!-- Header for each note -->
          <div class="row">
            <div class="col-md-3">
              <b>{{ note.createdDate | date: 'MM/dd/yyyy hh:mm:ss a' }}</b><br>
              <span class="userName">{{ note.userName }}</span>
            </div>
            <div class="col-md-9">
              <div class="float-end">
                <b>{{ note.orderType === 'R' || note.orderType === 'A' ? 'Return Note' : 'Order Note' }}</b>
                <span id="orderId" *ngIf="note.orderId === orderService.orderDto.id">&nbsp;
                  {{ note.orderId }}
                </span>
                <span id="orderId" *ngIf="note.orderId !== orderService.orderDto.id">&nbsp;
                  <a href="#" target="_blank" *ngIf="note.orderType !== 'R' && note.orderType !== 'A'" [routerLink]="['/order', note.orderId]">{{ note.orderId }}</a>
                  <a href="#" target="_blank" *ngIf="note.orderType === 'R' || note.orderType === 'A'" [routerLink]="['/return', note.orderId]">{{ note.orderId }}</a>
                </span>
                <span  [hidden]="note.orderId !== orderService.orderDto.id && (modeService.mode !== Mode.Edit || !orderService.orderDto.isReturnNote) || !note.isEditEnabled" class="uitk-icon uitk-icon-edit sm-icon " id="editIcon">
                  <a class="link" (click)="onEdit(note)">Edit</a>
                </span><br>

                <span class="userName">{{ note.noteTypeText }}</span>
              </div>
            </div>
          </div>
          <!-- Text for each note -->
          <div class="row mt-2" *ngIf="note.systemNote">
            <div class="col-12-md">
              <b>System Note:</b> {{ note.systemNote }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="textdisable" id="notesText-{{note.id}}" [line-truncation]="numOfLines" (click)="setLines(note)" [disabled]="note.isTruncated" [options]="{ellipsis:'(⋯)'}">
              <b>Note:</b> {{ note.noteText }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </uitk-dialog-content>
</uitk-dialog>
</div>
</div>