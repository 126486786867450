import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { Mode } from '../enums/mode.enum';
import { RoleDto } from '../dtos/role-dto.model';
import { RoleResourceDto } from '../dtos/role-resource-dto.model';
import { ModeService } from './mode.service';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  isReloaded = 'true';
  private readonly _httpClient: HttpClient;
  private readonly _modeService: ModeService;
  private readonly _router: Router;
  resourcesCache!: string[] | null;
  resourcesCachedObservable!: Observable<string[]> | null;
  rolesCache!: RoleDto[] | null;
  rolesCachedObservable!: Observable<RoleDto[]> | null;
  roleResourcesCache!: RoleResourceDto[] | null;
  roleResourcesCachedObservable!: Observable<RoleResourceDto[]> | null;
  roleBasedOnUserIdCache!: RoleDto | null;
  roleBasedOnUserIdCachedObservable!: Observable<RoleDto> | null;
  swalAlert = new SwalAlert();

  constructor(httpClient: HttpClient, modeService: ModeService, router: Router) {
    this._httpClient = httpClient;
    this._modeService = modeService;
    this._router = router;
  }

  hasResource(resource: string): boolean {
    let isResourceAvailable: boolean = false;
    const resources = localStorage.getItem('resources') as any;

    if (localStorage.getItem('isByPass') === 'true') {
      return true;
    }

    if (resources != null) {
      isResourceAvailable = JSON.parse(resources).indexOf(resource.toLowerCase()) > -1;
    }

    return isResourceAvailable;
  }

  getAuthorization(apiUrl: string): void {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);

    if (currentUser != null) {
      const loggedInUser = this.getMyMSId();

      this.getRoleBasedOnUserId(apiUrl, loggedInUser).subscribe((data: RoleDto) => {
        if ((data !== null || data !== undefined) && data?.role !== null) {
          let roleName = '';
          
          if ('impersonateType' in localStorage) {
            roleName = localStorage.getItem('impersonateType') === '' ? data.role : localStorage.getItem('impersonateType')!.replace(/ /g, '');
          }
          else {
            roleName = data.role;
          }

          this.getRoleResources(apiUrl, roleName)
            .subscribe((data: RoleResourceDto[]) => {
              let resources: string[] = [];

              for (let i = 0; i < data.length; i++) {
                resources.push(data[i].resource.toLowerCase());
              }

              localStorage.setItem('resources', JSON.stringify(resources));
              this.setLocalStorageIsImpersonatedIsReloaded();
            });
        }
        else {
          this.getResources(apiUrl, loggedInUser).subscribe(
            (data: string[]) => {
              if (localStorage.getItem('impersonateType') === null || localStorage.getItem('impersonateType') === '') {
                localStorage.setItem('resources', JSON.stringify(data));
              }

              this.setLocalStorageIsImpersonatedIsReloaded();
            }, (error: any) => {
              this._modeService.mode = Mode.Initialize;
              console.log(error);
              this.swalAlert.alert('Unable to get Authorization details.');
            });
        }
      }, (error: any) => {
        this._modeService.mode = Mode.Initialize;
        console.log(error);
        this.swalAlert.alert('Unable to get Authorization details.');
      });
    }
  }

  setLocalStorageIsImpersonatedIsReloaded(): void{
    if (localStorage.getItem('IsImpersonated') === 'true') {
      this._router.navigate(['/']);
      localStorage.setItem('IsImpersonated', 'false');
    }

    if (localStorage.getItem('isReloaded') === 'false') {
      localStorage.setItem('isReloaded', 'true');
      location.reload();
    }
  }

  // TODO: This is a duplicate of the code in the config service.  We should remove one of the duplicates.
  getMyMSId(): string {
    if (localStorage.getItem('isByPass') === 'true') {
      return 'bypass';
    }
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);

    if (currentUser?.profile?.identity_provider === 'uhg-ms-id') {
      return currentUser?.profile?.preferred_username?.replace('oma-', '') ?? 'Unknown';
    } else {
      return currentUser?.profile?.identity_provider_identity?.replace('oma-', '') ?? 'Unknown';
    }
  }

  private getResources(apiUrl: string, loggedInUser: string): Observable<string[]> {
    let observable: Observable<string[]>;

    if (this.resourcesCache) {
      observable = of(this.resourcesCache);
    }
    else if (this.resourcesCachedObservable) {
      observable = this.resourcesCachedObservable;
    }
    else {
      this.resourcesCachedObservable = this._httpClient.get<string[]>(`${apiUrl}/Authorization/GetResources/${loggedInUser}`)
        .pipe(
          tap((response: string[]) => {
            this.resourcesCache = response;
          }),
          share(),
          finalize(() => {
            this.resourcesCache = null;
            this.resourcesCachedObservable = null;
          })
        );

      observable = this.resourcesCachedObservable;
    }

    return observable;
  }

  getRoles(apiUrl: string): Observable<RoleDto[]> {
    let observable: Observable<RoleDto[]>;

    if (this.rolesCache) {
      observable = of(this.rolesCache);
    }
    else if (this.rolesCachedObservable) {
      observable = this.rolesCachedObservable;
    }
    else {
      this.rolesCachedObservable = this._httpClient.get<any>(`${apiUrl}/authorization/roles`)
        .pipe(
          tap((response: RoleDto[]) => {
            this.rolesCache = response;
          }),
          share(),
          finalize(() => {
            this.rolesCache = null;
            this.rolesCachedObservable = null;
          })
        );

      observable = this.rolesCachedObservable;
    }

    return observable;
  }

  getRoleResources(apiUrl: string, role: string): Observable<RoleResourceDto[]> {
    let observable: Observable<RoleResourceDto[]>

    if (this.roleResourcesCache) {
      observable = of(this.roleResourcesCache);
    }
    else if (this.roleResourcesCachedObservable) {
      observable = this.roleResourcesCachedObservable;
    }
    else {
      this.roleResourcesCachedObservable = this._httpClient.get<any>(`${apiUrl}/authorization/roleResources/${role}`)
        .pipe(
          tap((response: RoleResourceDto[]) => {
            this.roleResourcesCache = response;
          }),
          share(),
          finalize(() => {
            this.roleResourcesCache = null;
            this.roleResourcesCachedObservable = null;
          })
        );

      observable = this.roleResourcesCachedObservable;
    }

    return observable;
  }

  getRoleBasedOnUserId(apiUrl: string, userId: string): Observable<RoleDto> {
    let observable: Observable<RoleDto>;

    if (this.roleBasedOnUserIdCache) {
      observable = of(this.roleBasedOnUserIdCache);
    }
    else if (this.roleBasedOnUserIdCachedObservable) {
      observable = this.roleBasedOnUserIdCachedObservable;
    }
    else {
      this.roleBasedOnUserIdCachedObservable = this._httpClient.get<RoleDto>(`${apiUrl}/authorization/rolebasedonuserid/${userId}`)
        .pipe(
          tap((response: RoleDto) => {
            this.roleBasedOnUserIdCache = response;
          }),
          share(),
          finalize(() => {
            this.roleBasedOnUserIdCache = null;
            this.roleBasedOnUserIdCachedObservable = null;
          })
        );

      observable = this.roleBasedOnUserIdCachedObservable;
    }

    return observable;
  }
}
