import { Component, Input, OnInit } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { SourceCodeDto } from '../../../dtos/source-code-dto.model';
import { MaintenanceService } from '../../../services/maintenance.service';
import { Dialog } from '../../../helpers/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-source-code-action-popup',
  templateUrl: './source-code-action-popup.component.html',
  styleUrls: ['./source-code-action-popup.component.css']
})
export class SourceCodeActionPopupComponent implements OnInit {

  readonly _maintenanceService: MaintenanceService;
  @Input() editSourceCodePopup!: Dialog<SourceCodeDto | null>;
  @Input() deleteSourceCodePopup!: Dialog<SourceCodeDto | null>;
  @Input() sourceCodeDetails! : SourceCodeDto;

  savedSourceCode: SourceCodeDto | HttpErrorResponse = new SourceCodeDto();
  source = new SourceCodeDto();
  sourceCode: string | null = null;
  description: string | null = null;
  glCode: string | null = null;
  statusCode: string | null = null;

  constructor(maintenanceService: MaintenanceService) {
    this._maintenanceService = maintenanceService;
  }

  ngOnInit(): void {
    Object.assign(this.source, this.sourceCodeDetails);
    this.source.isDefault = true;
    this.source.glCode = this.sourceCodeDetails.glCode?? '2DM';
  }

  async saveSourceCode(): Promise<void> {
    console.log('this.source ', this.source);
    this.savedSourceCode = await this._maintenanceService.saveSourceCode(this.source);
    this.editSourceCodePopup.close(this.source);
  }
  cancelSourceCode(): void {
    this.editSourceCodePopup.close(null);
  }
  async deleteSourceCode(): Promise<void> {
    this.source.statusCode = 'I';
    this.savedSourceCode = await this._maintenanceService.saveSourceCode(this.source);
    this.deleteSourceCodePopup.close(this.source);
  }
  cancelDeleteSourceCode(): void {
    this.deleteSourceCodePopup.close(null);
  }
}
