import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { ConfigService } from './config.service';
import { WorklistNoteDto } from '../dtos/worklist-note-dto.model';
import { WorklistQueueDto } from '../dtos/worklist-queue-dto';
import { RemoveHoldDto } from 'src/app/dtos/remove-hold-dto';
import { tap, share, finalize } from 'rxjs/operators';
import { RetryCCDto } from '../dtos/retry-cc-dto';

@Injectable({
  providedIn: 'root'
})

export class WorklistQueueService {
  worklistIsReady = new Subject();
  worklistNoteDto: WorklistNoteDto = new WorklistNoteDto();
  removeHoldReason: RemoveHoldDto = new RemoveHoldDto();
  selectedWorkListQueueDto: WorklistQueueDto | null = null;
  private readonly _httpClient: HttpClient;
  private readonly _configService: ConfigService;
  worklistQueueCache!: WorklistQueueDto[] | null;
  worklistQueueCachedObservable!: Observable<WorklistQueueDto[]> | null;
  worklistNotesCache!: WorklistNoteDto[] | null;
  worklistNotesCachedObservable!: Observable<WorklistNoteDto[]> | null;
  worklistsNotesCache!: WorklistNoteDto[] | null;
  worklistsNotesCachedObservable!: Observable<WorklistNoteDto[]> | null;
  emailType = '';

  constructor(httpClient: HttpClient, configService: ConfigService) {
    this._httpClient = httpClient;
    this._configService = configService;
  }

  getWorkListQueueData(): Observable<WorklistQueueDto[]> {
    let observable: Observable<WorklistQueueDto[]>;

    if (this.worklistQueueCache) {
      observable = of(this.worklistQueueCache);
    }
    else if (this.worklistQueueCachedObservable) {
      observable = this.worklistQueueCachedObservable;
    }
    else {
      this.worklistQueueCachedObservable = this._httpClient.get<WorklistQueueDto[]>(`${this._configService.apiUrl}/worklist`)
        .pipe(
          tap((response: WorklistQueueDto[]) => {
            this.worklistQueueCache = response;
          }),
          share(),
          finalize(() => {
            this.worklistQueueCache = null;
            this.worklistQueueCachedObservable = null;
          })
        );

      observable = this.worklistQueueCachedObservable;
    }

    return observable;
  }

  saveWorklistNote(worklistNote: WorklistNoteDto): Observable<WorklistNoteDto> {   
      return this._httpClient.post<WorklistNoteDto>(`${this._configService.apiUrl}/worklist/saveworklistqueuenotes`, worklistNote);
  }

  updateWorklistNote(worklistNote: WorklistNoteDto): Observable<WorklistNoteDto> {
    return this._httpClient.put<WorklistNoteDto>(`${this._configService.apiUrl}/worklist/updateworklistqueuenotes`, worklistNote);
  }

  deleteWorklistNote(id:number){
    return this._httpClient.delete(`${this._configService.apiUrl}/worklist/deleteworklistqueuenotes/${id}`);
  }

  getWorklistNote(orderId: number): Observable<WorklistNoteDto[]> {
    let observable: Observable<WorklistNoteDto[]>;

    if (this.worklistNotesCache) {
      observable = of(this.worklistNotesCache);
    }
    else if (this.worklistNotesCachedObservable) {
      observable = this.worklistNotesCachedObservable;
    }
    else {
      this.worklistNotesCachedObservable = this._httpClient.get<WorklistNoteDto[]>(`${this._configService.apiUrl}/worklist/GetWorklistNotes?orderId=${orderId}`)
        .pipe(
          tap((response: WorklistNoteDto[]) => {
            this.worklistNotesCache = response;
          }),
          share(),
          finalize(() => {
            this.worklistNotesCache = null;
            this.worklistNotesCachedObservable = null;
          })
        );

      observable = this.worklistNotesCachedObservable;
    }

    return observable;
  }

  getWorklistNotes(orderIds: number[]): Observable<WorklistNoteDto[]> {
    let observable: Observable<WorklistNoteDto[]>;

    if (this.worklistsNotesCache) {
      observable = of(this.worklistsNotesCache);
    }
    else if (this.worklistsNotesCachedObservable) {
      observable = this.worklistsNotesCachedObservable;
    }
    else {
      this.worklistsNotesCachedObservable = this._httpClient.post<WorklistNoteDto[]>(`${this._configService.apiUrl}/worklist/GetWorklistNotes`, orderIds)
        .pipe(
          tap((response: WorklistNoteDto[]) => {
            this.worklistsNotesCache = response;
          }),
          share(),
          finalize(() => {
            this.worklistsNotesCache = null;
            this.worklistsNotesCachedObservable = null;
          })
        );

      observable = this.worklistsNotesCachedObservable;
    }

    return observable;
  }
  saveRemoveHoldReason(removeHoldDto: RemoveHoldDto, userId: string) {
    return this._httpClient.post(`${this._configService.apiUrl}/worklist/RemoveHold?userLogin=${userId}`, removeHoldDto);
  }

  saveRetryCC(retryCCDto: RetryCCDto) {
    return this._httpClient.post(`${this._configService.apiUrl}/worklist/RetryCC`, retryCCDto);
  }
}
