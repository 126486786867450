import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as states from 'src/assets/json/states_USA.json';
import { SourceType } from '../enums/source-type.enum';
import { OrderDto } from '../dtos/order-dto.model';
import { TaxInputDto } from '../dtos/tax-input.dto.model';
import { TaxOutputDto } from '../dtos/tax-output.dto.model';
import { ConfigService } from './config.service';
import { ModeService } from './mode.service';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root'
})
export class TaxService {
  //private readonly _httpClient: HttpClient;
  //private readonly _configService: ConfigService;

  taxInputDto: TaxInputDto[] = [];
  taxOutputDto: TaxOutputDto[] = [];

  stateList: { id: string, label: string, value: string, disabled: boolean, taxable: boolean }[] = [];
  swalAlert = new SwalAlert();

  constructor(
    private readonly httpClient: HttpClient, 
    private readonly configService: ConfigService, 
    public modeService: ModeService, ) {
  }

  async getTax(taxInputDto: TaxInputDto[]): Promise<TaxOutputDto[]> {
     return this.httpClient.post<TaxOutputDto[]>(`${this.configService.taxApiUrl}/Tax/CalculateTax`, taxInputDto).toPromise();
  }

  async calculateTax(sourceType: SourceType,orderDto: OrderDto): Promise<boolean> {
    console.log('btnType=' + sourceType);
    let isSuccess : boolean = false;
    this.modeService.isWaiting = true;
    this.taxOutputDto = [];
    const calculateTaxText = "Calculating Tax";
    this.createTaxInputDto(orderDto);

    const taxOutput: TaxOutputDto[] = [];
    const taxInput: TaxInputDto[] = [];

    this.taxInputDto.forEach((item) => {
      const isTaxable = this.checkShippingState(item.state);
      const isExtendedPriceZero = orderDto.orderDetailDtos.find(c => c.id === item.orderDetailId)?.extendedPrice;
      console.log("isTaxable-" + isTaxable + "isExtendedPriceZero=" + isExtendedPriceZero);
      if (!isTaxable || isExtendedPriceZero === 0) {
        taxOutput.push({ orderDetailId: item.orderDetailId, taxAmount: 0.0 });
      } else {
        taxInput.push(item);
      }
    });
    if (taxInput.length > 0) {
      this.modeService.addToWaitList(calculateTaxText);
      await this.getTax(taxInput).then(data => {
        console.log('receivied response from taxapi');
        this.modeService.removeFromWaitList(calculateTaxText);
        this.taxOutputDto = data;
        this.taxOutputDto.push(...taxOutput);
        this.setTaxAmount(orderDto);
        this.modeService.isWaiting = false;
        isSuccess = true;        
      }, error => {
        this.modeService.removeFromWaitList(calculateTaxText);
        console.log('receivied response from taxapi');
        isSuccess = false;
        if (sourceType === SourceType.OrderEntryCalculate || sourceType === SourceType.OrderInquiryCalculateTax ||
          sourceType === SourceType.OrderEntryShipTozipChanged || sourceType === SourceType.OrderInquiryShipTozipChanged){
          this.showPopupForErrorInCalculateTax(error.error.Message.toString(), sourceType);
          }
        else {
          isSuccess = this.showPopupForErrorOnSaving(error, sourceType);
        }
        this.modeService.isWaiting = false;
        
      });
    } else {
      this.taxOutputDto.push(...taxOutput);
      this.setTaxAmount(orderDto);
      
      this.modeService.isWaiting = false;
      isSuccess = true;
    }
    console.log('tax calculation completed');
    return isSuccess;
  }

  showPopupForErrorOnSaving(error: any, sourceType: SourceType): boolean {
    let isSuccess = false;
    const isGenericError = this.showPopupForErrorOnSaving_CalculateTax(error.error.Message.toString(), sourceType);
    if (isGenericError){
      isSuccess = true;
    }
    else {
      if (sourceType !== SourceType.OrderInquirySave && sourceType !== SourceType.OrderEntrySaveAsDraft){
        isSuccess = false;
      }
      else{
        isSuccess = true;
      }
    }
    return isSuccess;
  }
  showPopupForErrorInCalculateTax(errorMsg: string, sourceType: SourceType) {
    if (errorMsg.indexOf("No tax areas were found during the lookup") !== -1) {
      this.swalAlert.alert('Address errors are preventing tax from being calculated on open items.');
    }
    else if (errorMsg.indexOf("Vertex failure to standardize Addres") !== -1) {
      this.swalAlert.alert('Unable to Standardize Address');
    }
    else if (errorMsg.indexOf("cannot be blank.") !== -1) {
      this.swalAlert.alert(errorMsg.split('(')[0]);
    }
    else if (errorMsg.indexOf("Error getting Tax Class") !== -1) {
      if (sourceType === SourceType.OrderInquiryCalculateTax || sourceType === SourceType.OrderInquiryShipTozipChanged){       
        this.swalAlert.alert('Input details to Calculate Tax cannot be blank');
      }
      else{
        this.swalAlert.alert(errorMsg);
      }
    }
    else {
      this.swalAlert.alert('Error getting tax for open items.');
    }
  }

  showPopupForErrorOnSaving_CalculateTax(errorMsg: string, sourceType: SourceType): boolean {
    let isGenerericError = false;
    if (errorMsg.indexOf("No tax areas were found during the lookup") !== -1) {
      isGenerericError = false;
      this.showPopupForAddressErrorsOnSaving_CalculateTax(sourceType);
    }
    else if (errorMsg.indexOf("Vertex failure to standardize Addres") !== -1) {
      isGenerericError = true;
      this.swalAlert.alert('Unable to Standardize Address');
    }
    else if (errorMsg.indexOf("cannot be blank.") !== -1) {
      isGenerericError = false;
      this.swalAlert.alert(errorMsg.split('(')[0]);
    }
    else if (errorMsg.indexOf("Error getting Tax Class") !== -1) {
      isGenerericError = false;
      if (sourceType === SourceType.OrderInquirySave){
        this.swalAlert.alert('Input details to Calculate Tax cannot be blank');
      }
      else
      {
        this.swalAlert.alert(errorMsg);
      }
    }
    else {
      isGenerericError = false;
      if (sourceType === SourceType.OrderInquirySave || sourceType === SourceType.OrderEntrySaveAsDraft){
        this.swalAlert.alert('Error getting tax for open items. Tax will be calculated during invoicing.');
      }
      else
      {
        this.swalAlert.alert('Error getting tax for open items.');
      }
    }
    return isGenerericError;
  }
  showPopupForAddressErrorsOnSaving_CalculateTax(sourceType: SourceType){
    if (sourceType === SourceType.OrderInquirySave || sourceType === SourceType.OrderEntrySaveAsDraft){
      this.swalAlert.alert('Address errors are preventing tax from being calculated on open items. Tax will be calculated during invoicing.');
    }
      
    else{
      this.swalAlert.alert('Address errors are preventing tax from being calculated on open items.');
    }
  }
  setTaxAmount(orderDto: OrderDto) {
    this.taxOutputDto.forEach(item => {
      const index = orderDto.orderDetailDtos.findIndex(i => i.id === item.orderDetailId);

      if (index > -1) {
        orderDto.orderDetailDtos[index].taxAmount = item.taxAmount;
        orderDto.orderDetailDtos[index].taxRate = isNaN(Math.floor(((item.taxAmount / orderDto.orderDetailDtos[index].extendedPrice) * 100) * 1000) / 1000) ? 0 :
          Math.floor(((item.taxAmount / orderDto.orderDetailDtos[index].extendedPrice) * 100) * 1000) / 1000;
        orderDto.orderDetailDtos = [...orderDto.orderDetailDtos];
      }
    });

   
  }

  checkShippingState(state: string) {
    this.stateList = JSON.parse(JSON.stringify((states as any).default));
    if (state === null){
      return false;
    }
    const stateData = this.stateList.find(s => s.value === state.trim());
    if (stateData !== undefined) {
      return stateData.taxable;
    } else {
      return false;
    }
  }

  createTaxInputDto(orderDto: OrderDto) {
    if (!this.configService.reference) {
      return;
    }
    this.taxInputDto = [];
    const orderDetails = orderDto.orderDetailDtos.filter(item => item.orderDetailStatus === ' ');
    for (const item of orderDetails) {
      const taxInput: TaxInputDto = new TaxInputDto();
      
      // Added for the purpose of empty line in order-entry
      if (!item.productDto) {
        continue;
      }
      
      // Coupon items skipped from calculating tax.
      // if (item.productDto.classCode === 'XS') {
      //   continue; 
      // }
      
      taxInput.taxClass = item.productDto.taxClassCode;
      taxInput.orderId = orderDto.id;
      taxInput.orderDetailId = item.id;
      taxInput.productDescription = item.productDescription;
      taxInput.company = orderDto.shipToDto.company?? '';
      taxInput.street = orderDto.shipToDto.street;
      taxInput.city = orderDto.shipToDto.city;
      taxInput.state = orderDto.shipToDto.state;
      taxInput.zip = orderDto.shipToDto.zip;
      taxInput.country = orderDto.shipToDto.country;
      taxInput.accountId = orderDto.billToDto.id;
      taxInput.price = item.extendedPrice;
      taxInput.productCode = item.productCode;
      this.taxInputDto.push(taxInput);
    }
  }
}
