export class DataFileDtos {
  dataFileLicenseDtos: DataFileDto[] = [];
  dataFileNonLicenseDtos: DataFileDto[] = [];
}

export class DataFileDto {
  addonProductId: number = 0;
  isLicense: boolean = false;
  listPrice: number = 0;
  productCode: string = '';
  description: string = '';
  isMaxParentQuantity: boolean = false;

  // Properties for UI purpose
  id = 0;
  isSelected: boolean = false;
  orderQuantity: number = 1;
  discount: number = 0;
  unitPrice:number = 0;
  extendedPrice = 0.00;
  voidedQuantity = 0;
  allowCustomPrice = false;
  allowCustomStandard = false;
}
