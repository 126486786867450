import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Directive({
  selector: '[appTextFormat]'
})
export class TextFormatDirective {
  // Currently unused in this example.
  @Input() appTextFormat: string = '';

  // Selected content when a user enters the field, and removes special characters.
  constructor(
  private readonly elementRef: ElementRef,
  private readonly renderer: Renderer2,
  private clipboard: Clipboard
  ) {
  }

  // This fires when they enter the input element.
  @HostListener('focus') focusEvent(): void {
    // When entering the control, we need to remove the formatting.
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', this.removeFormatting(this.elementRef.nativeElement.value));
    this.elementRef.nativeElement.setSelectionRange(0, this.elementRef.nativeElement.value.length);
  }

  // Override the keypress event to prevent invalid characters.
  @HostListener('keypress', ['$event']) keyPressEvent(event: KeyboardEvent): void {
    if (event.key.match(/[^a-zA-Z0-9 \`\~\!\@\#\$\%\^\&\*\(\)\-\_\=\+\{\}\[\]\:\;\"\'\<\,\>\.\?\/\|\\]/g)) {
      event.preventDefault();
    }
  }

  // Override the paste event to prevent invalid characters.
  @HostListener('paste', ['$event']) pasteEvent(event: ClipboardEvent): void {
    const clipboard = event.clipboardData?.getData('text');
    if (!clipboard) {
      return;
    }

    const adjustedClipboard = this.removeFormatting(clipboard);

    this.clipboard.copy(adjustedClipboard)
  }

  removeFormatting(value: string): string {
    return value.replace(/[^a-zA-Z0-9 \`\~\!\@\#\$\%\^\&\*\(\)\-\_\=\+\{\}\[\]\:\;\"\'\<\,\>\.\?\/\|\\]/g, '');
  }
}
