import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { ModeService } from '../services/mode.service';

@Directive({
  selector: '[hotkeyCommand]'
})
export class HotkeyDirective implements OnInit {
  @Input() hotkeyCommand: string = '';
  @Output() callSearchMethod= new EventEmitter<any>();
  @Output() callEditMethod= new EventEmitter<any>();
  @Output() callSaveMethod= new EventEmitter<any>();
  @Output() callAddMethod=  new EventEmitter<any>();
  @Output() callRevertMethod=new EventEmitter<any>();

  constructor(private readonly hotkeysService: HotkeysService, public modeService : ModeService) { }

  ngOnInit() {
    const isMac = navigator.platform.includes('Mac');
    const command = isMac ?  this.hotkeyCommand.replace('ctrl','command') : this.hotkeyCommand;
    if(this.hotkeyCommand === 'ctrl+f')
    {
      this.hotkeysService.add(
        new Hotkey( command,(): boolean => {
          this.callSearchMethod.next();
          return false;
        },
        ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
        'search' // shortcut name
        )
      );
     }
     if(this.hotkeyCommand === 'ctrl+e'){
      this.hotkeysService.add(
        new Hotkey( command,(): boolean => {
          this.callEditMethod.next();
            return false;
          },
          ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
          'edit' // shortcut name
        )
      );
     }

    if(this.hotkeyCommand === 'ctrl+s'){
      this.hotkeysService.add(
        new Hotkey( command,(): boolean => {
          this.callSaveMethod.next();
            return false;
          },
          ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
          'save' // shortcut name
        )
      );
     }

     if(this.hotkeyCommand === 'ctrl+a'){
      this.hotkeysService.add(
        new Hotkey( command,(): boolean => {
          this.callAddMethod.next();
            return false;
          },
          ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
          'add' // shortcut name
        )
      );
     }

     if(this.hotkeyCommand === 'ctrl+r'){
      this.hotkeysService.add(
        new Hotkey( command,(): boolean => {
          this.callRevertMethod.next();
            return false;
          },
          ['INPUT', 'TEXTAREA', 'SELECT'], // allow shortcut execution in these html elements
          'Revert' // shortcut name
        )
      );
     }
  }
  // search() {
  //   // alert('hotkey press !!!');
  //   this.modeService.mode = Mode.Wait;
  // }

}
