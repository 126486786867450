import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { OrderDetailDto } from 'src/app/dtos/order-detail-dto.model';
import { ConfigService } from 'src/app/services/config.service';
import { CptDto } from '../../../dtos/cpt-dto.model';

@Component({
  selector: 'app-cpt',
  templateUrl: './cpt.component.html',
  styleUrls: ['./cpt.component.css']
})
export class CptComponent implements OnInit {
  private readonly _configService: ConfigService;
  @Input() orderDetailDto: OrderDetailDto = new OrderDetailDto();
  @Output() enableSave = new EventEmitter<boolean>();
  licensingModelList: IUITKSelectItemProps[] = [];
  selectedLicensingModel: IUITKSelectItemProps = { id: '', label: '', value: '' };
  systemTypeList: IUITKSelectItemProps[] = [];
  selectedSystemType: IUITKSelectItemProps | undefined;
  showError=false;

  constructor(configService: ConfigService) { 
    this._configService = configService;
  }

  ngOnInit(): void {
    this.licensingModelList = this._configService.getPickList('LICENSINGMODEL');
    this.enableLicensingOptions();
    this.systemTypeList = this._configService.getPickList('SYSTEMTYPE');
    this.selectedLicensingModel = {
      id: this.orderDetailDto.cptDto?.licensingModel ?? '',
      label: this.orderDetailDto.cptDto?.licensingModel ?? '',
      value: this.orderDetailDto.cptDto?.licensingModel ?? ''
    };
    this.selectedSystemType = {
      id: this.orderDetailDto.cptDto?.systemType ?? '',
      label: this.orderDetailDto.cptDto?.systemType ?? '',
      value: this.orderDetailDto.cptDto?.systemType ?? ''
    };
  }

  onChangeLicensingModel(event: any) {
    this.orderDetailDto.cptDto = new CptDto();
    if(event.value === '')
    {
      this.orderDetailDto.cptDto = null;
    }
    else
    {
      this.orderDetailDto.cptDto.licensingModel = event.value.value;
    }

  }

  onChangeSystemType(event:any)
  {
    if(this.orderDetailDto.cptDto != null)
    {
        this.orderDetailDto.cptDto.systemType = event.value.value;
    }
  }

  enableLicensingOptions()
  {
    this.licensingModelList.forEach(item=>{
      item.disabled=true;
      if((this.orderDetailDto.productDto.acronym === 'HPAMAL' && item.label === "Health Plan") || (this.orderDetailDto.productDto.acronym === 'CPTLAB' && item.label === "Lab Test Model")
       || ((this.orderDetailDto.productDto.acronym === 'XSCPT' || this.orderDetailDto.productDto.acronym === 'XCPT')
       && ( item.label === "Counting Users" || item.label === "Provider User Proxy")))
      {
          item.disabled=false;
      }     
    })
  }

  save(): boolean {

    if (!this.validateRenewalOnSave()) {
      return false;
    }

    return true;
  }

  validateRenewalOnSave(): boolean {
    this.showError = false;
    if(this.orderDetailDto.cptDto == null)
    {
      this.showError=true;
      return false;
    }

    if(this.orderDetailDto.cptDto.licensingModel === "Counting Users")
    {
        if(this.orderDetailDto.cptDto.countingUsers < 1)
        {
          this.showError=true;
          return false;
        }
    }
    else if(this.orderDetailDto.cptDto.licensingModel === "Provider User Proxy")
    {        
        return this.validateUserProxy(this.orderDetailDto);
    }
    else if(this.orderDetailDto.cptDto.licensingModel === "Health Plan")
    {
        if(this.orderDetailDto.cptDto.members < 1)
        {
          this.showError=true;
          return false;
        }
    }
    else if(this.orderDetailDto.cptDto.licensingModel === "Lab Test Model")
    {
        if(this.orderDetailDto.cptDto.labTests < 1)
        {
          this.showError=true;
          return false;
        }
    }
    return true;
  }

  validateUserProxy(orderDetailDto:any){
    if(orderDetailDto.cptDto.users < 1 || !orderDetailDto.cptDto.systemType || orderDetailDto.cptDto.beds < 1)
    {
      this.showError=true;
      return false;
    }
    if(orderDetailDto.cptDto.systemType === 'Other' && !orderDetailDto.cptDto.otherSystemType)
    {
      this.showError=true;
      return false;
    }
    return true;
  }

}
