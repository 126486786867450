import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IUITKProgressBarConfig, IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import * as states from '../../../../assets/json/states_USA.json';
import { Dialog } from 'src/app/helpers/dialog';
import { Mode } from '../../../enums/mode.enum';
import { SourceType } from '../../../enums/source-type.enum';
import { AddYearDto } from '../../../dtos/add-year-dto';
import { KeyCodeDto } from '../../../dtos/keycode-dto.model';
import { OfferDto } from '../../../dtos/offer-dto.model';
import { OrderDto } from '../../../dtos/order-dto.model';
import { ProductDto } from '../../../dtos/product-dto.model';
import { AuthorizationService } from '../../../services/authorization.service';
import { ConfigService } from '../../../services/config.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';
import { TaxService } from '../../../services/tax.service';
import { InvoicesService } from '../../../services/invoices.service';
import { InvoiceEditDto } from '../../../dtos/invoice-edit-dto.model';
import { AccountingService } from '../../../services/accounting.service';
import { InvoiceEditService } from '../../../services/invoice-edit.service';
import { SwalAlert } from '../../../helpers/alert';
import { InvoicesListDto } from '../../../dtos/invoices-list-dto.model';
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit, OnDestroy {
  private readonly _datePipe: DatePipe;
  private readonly _taxService: TaxService;
  private readonly _router: Router;
  private readonly _configService: ConfigService;
  private readonly _pricingService: PricingService;
  private readonly _authorizationService: AuthorizationService;
  private readonly _invoiceService: InvoicesService;
  private readonly _accountingService: AccountingService;
  @Output('addLineItem') addLineItem = new EventEmitter();
  @HostListener('window:unload', ['$event'])
  @Output('addingProductCodeFromSearchDialog') addingProductCodeFromSearchDialog = new EventEmitter();
  unloadHandler(event: any) {
    if (this.modeService.mode === Mode.Edit) {
      this.revert();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    if (this.modeService.mode === Mode.Edit) {
      this.revert();
    }
  }
  @Input() enableEditMode = false;

  orderIsReadySubscription: Subscription | undefined;
  configIsReadySubscription: Subscription | undefined;
  callSaveSubscription: Subscription | undefined;
  callEditSubscription: Subscription | undefined;
  callRevertSubscription: Subscription | undefined;
  productDto = new ProductDto();
  offerDto = new OfferDto();
  allowCustomStandard = false;
  
  orderDateToolTipContent = '';
  keyCodeDto = new KeyCodeDto();
  Mode = Mode;
  visibleTextValue = '';

  userRevertedMYOrder = false;
  multiYearButtonDisabled = true;
  viewAddMYButtonVisible = false;
  multiYearButtonVisible = false;
  addYearButtonDisabled = false;
  disableWebAppOrder = false;
  unlockOrder = false;
  orderLocked = false;
  confirmAdditionalShipToDialogModal = {
    show: false
  }
  selectContacts = false;
  
  showSendEmailDialog = { show: false };
  holdReasonDialog = { show: false };
  exceptionReasonDialog = { show: false };
  quoteReasonDialog = { show: false };
  updateInvoicesFromOrderDialog = new Dialog<string>();

  mxDate: Date = new Date();
  isActive = false;
  lastYear: any;
  nextyearbtnVisible: boolean = false;
  msId: string = '';
  disableOrderType: boolean = true;
  disableWebRef: boolean = true;
  disableOrderStatus: boolean = true;
  disableSourceCode: boolean = true;
  disableListCode: boolean = true;
  disablePromoCode: boolean = true;
  disableSalesPerson: boolean = true;
  isDisableOMANDSAStatus: boolean= true;
  isEditDisabled = false;
  orderTypeList: IUITKSelectItemProps[] = [];
  ordesInquiryImpersonating: string[]=['Custom Read Only'];
  orderStatusList: IUITKSelectItemProps[] =
    [
      { id: 'C', label: 'Closed', value: 'C', disabled: true },
      { id: 'H', label: 'Hold', value: 'H' },
      { id: ' ', label: 'Open', value: ' ', disabled: true },
      { id: 'H', label: 'Void', value: 'V', disabled: true },
    ];
  invoiceDetailsToBeUpdated: InvoiceEditDto[] = [];
  voidOrderList: IUITKSelectItemProps[] = [];
  sourceCodeList: IUITKSelectItemProps[] = [];
  sourceCode: IUITKSelectItemProps | undefined;
  stateList: { value: string, taxable: boolean }[] = [];
  lastShipmentMethod: string | null = null;
  webReviewedText: string = 'Web Reviewed';
  ReviewedDateChange: Date | null = null;
  paymentMethod = '';
  resolveEdit: any;
  calculatingTaxMessage='Calculating Tax';
  static interval: any;
  barModel: IUITKProgressBarConfig = {
    progress: 20,
    showProgressInPercentage: true,
    showProgressText: true,
    hasError: false,
    errorText: 'Error',
    completedText: 'Complete',
    infoText: 'Uploading your documents...',
    completedSubtext: 'Your documents have sucessfully uploaded.',
    errorSubtext: 'Processing error message.',
    showCancelLink: true,
  };
  swalAlert = new SwalAlert();

  constructor(
    public modeService: ModeService,
    datePipe: DatePipe,
    public orderService: OrderService,
    taxService: TaxService,
    router: Router,
    configService: ConfigService,
    pricingService: PricingService,
    authorizationService: AuthorizationService,
    invoicesService: InvoicesService,
    accountingService: AccountingService,
    public invoiceEditService: InvoiceEditService
  ) {
    this._datePipe = datePipe;
    this._taxService = taxService;
    this._router = router;
    this._configService = configService;
    this._pricingService = pricingService;
    this._authorizationService = authorizationService;
    this._invoiceService = invoicesService;
    this._accountingService = accountingService;
  }

  ngOnInit(): void {
    this.callSaveSubscription = this.orderService.onTriggerSave.subscribe(() => {
      this.save();
    });

    this.callEditSubscription = this.orderService.onTriggerEdit.subscribe(async () => {
      await this.edit(true);
      this.orderService.resolveEdit();
    });

    this.callRevertSubscription = this.orderService.onTriggerRevert.subscribe(async () => {
      await this.revert();
    });

    this.stateList = (states as any).default;

    this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
      this.navigateToCorrectScreen();
      this.orderService.orderDto.orderDetailDtos.forEach(element => {
        element.productCode = element.productCode.trim();
      });

      this.orderDateToolTipContent = this._datePipe.transform(this.orderService.orderDto.enteredDate, 'MM/dd/yyyy h:mm a') ?? '';
      this.voidOrderDialogModal.show = false;
      this.recalculateMultiYearVisible();
      this.voidOrderList = this._configService.getPickList("VOIDREASON");
      this.sourceCodeList = this._configService.getPickList('SOURCE');
      this.orderTypeList = this._configService.getPickList('ORDERTYPE');
      this.sourceCode = { id: this.orderService.orderDto.sourceCode ?? '', label: this.orderService.orderDto.sourceCode ?? '', value: this.orderService.orderDto.sourceCode ?? '' };
      this.modeService.mode = Mode.View;
      this.disableEditButton();
      this.ReviewedDateChange = this.orderService.orderDto.reviewedDate;
      this.orderService.paymentMethodInDb = this.orderService.orderDto.paymentMethod;
      if (window.history.length === 1 && this.enableEditMode === true) {
       this.editAndUpdateRequiredDate();
      }
      this.orderService.recalculateFields(this.orderService.orderDto);
      this.lastShipmentMethod = this.orderService.orderDto.shipmentMethod;
      if (this._authorizationService.hasResource('OrderHeaderPromoDetails')) {
        this.disablePromoCode = false;
      }

      if (this._authorizationService.hasResource('OrderHeaderPromoCode')) {
        this.disablePromoCode = false;
      }
    });
     if (this._authorizationService.hasResource('OrderEntryFooterVoid')) {
       this.isDisableOMANDSAStatus = false;
     }
  }
  navigateToCorrectScreen() {
    if (this.orderService.orderDto.orderType === 'R' || this.orderService.orderDto.orderType === 'A') {
      this._router.navigate(['/return', this.orderService.orderDto.id]);
    }
    if (this.orderService.orderDto.orderType === 'Q' || this.orderService.orderDto.orderStatus === 'D' || this.orderService.orderDto.orderStatus === 'P' || this.orderService.orderDto.orderStatus === 'R' || this.orderService.orderDto.orderStatus === 'M') {
      this._router.navigate(['/order-entry'], {
        queryParams: {
          orderId: this.orderService.orderDto.id
        }
      });
    }
  }
  disableEditButton(){
    this.isEditDisabled = (this.modeService.mode !== Mode.View || this.orderService.orderDto.orderStatus === 'V' ||
        (this.orderService.orderDto.orderStatus === 'C' && !this.orderService.orderDto.hasActiveSubscriptions) ||
        !this._authorizationService.hasResource("OrderInquiryEditButton")) ? true : false;
  }
  private editAndUpdateRequiredDate(){
    setTimeout(async () => {
      if (this.modeService.mode === Mode.View) {
        this.edit();
        await this.orderService.reviewAndUpdateRequiredDate();
      }
    }, 2000);
  }

  recalculateMultiYearVisible() {
    let maxRequiredDate: Date = new Date(this.orderService.orderDto.orderDetailDtos.filter(m => m.requiredDate !== null)
      .map(m => new Date(m.requiredDate as Date)).reduce((a, b) => a > b ? a : b));
    if (this.orderService.orderDto.multiYearCurrentYear !== null) {
      maxRequiredDate = new Date(this.orderService.orderDto.multiYearDtos.map(m => m.orderDate).reduce((a, b) => a > b ? a : b));
    }

    const currentDate: Date = new Date();
    const diffDays = Math.floor((currentDate.valueOf() - maxRequiredDate.valueOf()) / (1000 * 60 * 60 * 24));
   
    if (diffDays <= 365 && this.orderService.orderDto.multiYearCurrentYear !== null) {
      this.multiYearButtonVisible = false;
      this.multiYearButtonDisabled = false;
      this.viewAddMYButtonVisible = true;
      this.addYearButtonDisabled = false;
    }
    else if (diffDays > 365 && this.orderService.orderDto.multiYearCurrentYear !== null) {
      this.multiYearButtonVisible = false;
      this.multiYearButtonDisabled = false;
      this.viewAddMYButtonVisible = true;
      this.addYearButtonDisabled = false;
    }
    else if (diffDays <= 365 && this.orderService.orderDto.multiYearCurrentYear === null) {
      this.multiYearButtonVisible = true;
      this.viewAddMYButtonVisible = false;
      this.multiYearButtonDisabled = false;
      this.addYearButtonDisabled = false;
    }
    else if (diffDays > 365 && this.orderService.orderDto.multiYearCurrentYear === null) {
      this.multiYearButtonVisible = true;
      this.viewAddMYButtonVisible = false;
      this.multiYearButtonDisabled = true;
      this.addYearButtonDisabled = false;
    }
    this.checkOrderStatusAndModeView();
   
  }

  checkOrderStatusAndModeView(){
    if (this.orderService.orderDto.orderStatus === 'V') {
      this.multiYearButtonDisabled = true;
    }

    if (this.modeService.mode === Mode.View) {
      this.orderService.orderDto.yearPanelButtonsDisable = false;
    }
  }

  toggleShow() {
    this.multiYearButtonVisible = false;
    this.viewAddMYButtonVisible = false;
  }

  async edit(bypassPending: boolean = false): Promise<void> {
    this._configService.resetIdleTimeout();
    //Allowing edit when displayShipToLinkSaveButton is true to avoid Order lock issue when saving Ship To Contacts with no Edit access
    if (!this._authorizationService.hasResource('OrderInquiryEditButton') && (!this.orderService.displayShipToLinkSaveButton)) {
      await this.orderService.accountContactValidationBR();
      return;
    }
      
      if (this.isActive) {
        this.modeService.mode = Mode.View;
        return;
      }
      this.checkResources();

      this.orderService.orderDto.yearPanelButtonsDisable = true;
      const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);

      if (localStorage.getItem('isByPass') === 'true') {
        this.msId = 'byPass'
      }
      
      if (currentUser != null) {
        this.msId = this._configService.getMyMSId();
      }
    return new Promise(resolve => {

      const editOrderTxt = "Editing order";
      this.modeService.addToWaitList(editOrderTxt);
      this.orderService.setLockOrder(this.orderService.orderDto.id, this.msId, bypassPending).subscribe(
        (data: OrderDto) => {
          this.modeService.removeFromWaitList(editOrderTxt);
          // Create backup copy so I can revert if needed.

          const contactBinding = this.orderService.orderDto.orderByContactBinding;
          const shipToContactBinding = this.orderService.orderDto.shipToContactBinding;
          const orderNotes = this.orderService.orderDto.orderNotes;
          const systemComments = this.orderService.orderDto.systemComments;
          const freeFormComments = this.orderService.orderDto.freeFormComments;

          this.orderService.orderDto = data;

          this.orderService.orderDto.lastShipmentMethod = this.orderService.orderDto.shipmentMethod;
          this.orderService.orderDto.orderByContactBinding = contactBinding;
          this.orderService.orderDto.shipToContactBinding = shipToContactBinding;
          this.orderService.orderDto.orderNotes = orderNotes;
          this.orderService.orderDto.systemComments = systemComments;
          this.orderService.orderDto.freeFormComments = freeFormComments;

          this.orderService.orderDtoBackup = JSON.parse(JSON.stringify(this.orderService.orderDto)) as OrderDto;
          this.orderService.orderDtoBackup.inUseBy = null;
          this.orderService.orderDtoBackup.inUseDate = null;

          this.modeService.mode = Mode.Edit;
          this.orderService.orderDto.yearPanelButtonsDisable = true;
          this.orderLocked = true;
          this.orderService.recalculateFields(this.orderService.orderDto);
          this.setOrderTypeList();     
          resolve();
        }, async error => {
          this.modeService.removeFromWaitList('Editing order');
          this.modeService.mode = Mode.View;
          await this.swalAlert.alert('Order is already in use and it is locked.');
          resolve();
        });
    });
  }

  setOrderTypeList(){
    if (this._configService.orderEntryFeature && (this.orderService.orderDto.orderType === 'E' || this.orderService.orderDto.orderType === ' ')) {
      this.orderTypeList = [
        { id: 'E', label: 'Exception', value: 'E' },
        { id: ' ', label: 'Order', value: ' ' },
      ];
    } else if (!this._configService.orderEntryFeature && (this.orderService.orderDto.orderType === 'Q' || this.orderService.orderDto.orderType === ' ')) {
      this.orderTypeList = [
        { id: 'Q', label: 'Quote', value: 'Q' },
        { id: ' ', label: 'Order', value: ' ' },
      ];
    } else {
      this.orderTypeList = [
        { id: 'R', label: 'Return', value: 'R' },
        { id: 'A', label: 'Authorization', value: 'A' },
      ];
    }
  }

  checkResources(){   
    this.modeService.mode = Mode.Edit;
    if ((this.orderService.orderDto.orderType === 'Q' && this._authorizationService.hasResource('OrderHeaderType')) ||
      (this.orderService.orderDto.orderType === ' ' && this._authorizationService.hasResource('OrderHeaderTypeOToQ'))
      && this._authorizationService.hasResource('AllowOrderChangeToException'))
    {
      this.disableOrderType = false;
    }
    else
    {
      this.disableOrderType = true;
    } 
    
    this.checkOrderHeaderResource();
  }

  checkOrderHeaderResource(){
    if (this._authorizationService.hasResource('OrderHeaderStatus')) {
      this.disableOrderStatus = false;
    }

    if (this._authorizationService.hasResource('OrderHeaderSourceCode')) {
      this.disableSourceCode = false;
    }

    if (this._authorizationService.hasResource('OrderHeaderListCode')) {
      this.disableListCode = false;
    }

    if (this._authorizationService.hasResource('OrderHeaderPromoDetails')) {
      this.disablePromoCode = false;
    }

    if (this._authorizationService.hasResource('OrderHeaderPromoCode')) {
      this.disablePromoCode = false;
    }

    if (this._authorizationService.hasResource('OrderHeaderWebRef')) {
      this.disableWebRef = false;
    }

    if (this._authorizationService.hasResource('OrderHeaderSalesperson')) {
      this.disableSalesPerson = false;
    }
  }

  itemSearch() {
    this.addingProductCodeFromSearchDialog.emit();
  }

  onChangeSourceCode(event: any) {

    if (this.orderService.orderDto.promotionCode === '' || (this.orderService.orderDto.promotionCode === this.orderService.orderDto.sourceCode)) {
      if (event.value === "") {
        this.orderService.orderDto.promotionCode = event.value;
      }
      else {
        this.orderService.orderDto.promotionCode = event.value.label;
      }
      this.orderService.orderDto.promotionHtml = '';
    }
    if (event.value === "") {
      this.orderService.orderDto.sourceCode = event.value;
    }
    else {
      this.orderService.orderDto.sourceCode = event.value.label;
    }
  }
  onClickSave() {
    this.orderService.skipFutureOrderSave = false;
    const allowedShipToCountries = ['US', 'PR', 'VI', 'GU', 'MP', 'AS'];
    const allowedIndiaAndPhilipines = ['IN', 'PH'];
    const printProductExists = this.orderService.orderDto.orderDetailDtos.find(x => x.productDto.salesOrganization === 'Shutterfly' && x.orderDetailStatus === ' ');
    if(!allowedShipToCountries.includes(this.orderService.orderDto.shipToDto.country) && printProductExists){
      if(!this._authorizationService.hasResource('AllowShiptoToForeignCountries')){
        this.swalAlert.alert("Shipping of print products to a foreign address is not allowed. Please remove/void the print products or change your shipping address to a non-foreign one.");
        return;
      }
      else if(!allowedIndiaAndPhilipines.includes(this.orderService.orderDto.shipToDto.country)){
        this.swalAlert.alert("Shipping of print products to a foreign address is not allowed. Please remove/void the print products or change your shipping address to a non-foreign one.");
       return;
      }
    }
    this.save();
  }

  async onChangePromoCode(event: any) {
    if (this.orderService.orderDto.orderDetailDtos.some(o => o.promotionEntryCode === 'ILT' || o.promotionEntryCode === 'OLT')) {
      this.orderService.orderDto.orderDetailDtos.filter(o => o.promotionEntryCode === 'ILT' || o.promotionEntryCode === 'OLT').forEach(i => {
        i.discount = i.discountBeforeApplyingPromoOffer;
        i.promotionEntryCode = null;
        i.promotionOrderDetailId = null;
        this._pricingService.calculateUnitPrice(i);
        this._pricingService.calculateExtendedPrice(i);
      });
      
      if (this.orderService.orderDto.orderDetailDtos.some(o => o.promotionEntryCode === 'ILP' || o.promotionEntryCode === 'OLP')) {
        await this.swalAlert.alert("Premiums related to previous promo code will be removed from cart.");
        this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(o => o.promotionEntryCode !== 'ILP' && o.promotionEntryCode !== 'OLP');
      }
    }

    this.sourceCodeList = this._configService.getPickList('SOURCE');
    
    if (this.orderService.orderDto.sourceCode?.trim() !== this.orderService.orderDto.promotionCode?.trim() && this.orderService.orderDto.promotionCode?.trim() !== '') {
      this.orderService.getOfferStatus(this.orderService.orderDto.promotionCode).subscribe(
        (data: OfferDto) => {
          this.orderService.offerDto = data;
          this.validateOfferDto();

          if (this.orderService.orderDto.promotionHtml !== '') {
            this.orderService.orderDto.hasUserSeenPromoForm = false;
            this.orderService.orderDto.promotionOfferPopupButton = '';
            this.orderService.orderDto.orderOfferQualificationsChangedPopupButton = '';
            this.orderService.orderDto.tierDiscountType = '';
          }
        }, error => {
          this.swalAlert.alert('Not able to fetch offer details');
        });
    }
  }

  validateOfferDto(){
    if (this.orderService.offerDto.promotionHtml) {
      this.orderService.orderDto.promotionHtml = this.orderService.offerDto.promotionHtml;
    }
    const validSourceCode = this.sourceCodeList.some(ele => ele.value === this.orderService.orderDto.promotionCode);
    if (this.orderService.offerDto.status === 'Inactive' && !validSourceCode) {
      this.swalAlert.alert('You have entered an invalid Promotion Code. Please correct');
      this.orderService.orderDto.promotionCode = '';
      this.orderService.orderDto.promotionHtml = '';
    }
    else if (this.orderService.offerDto.status === 'Active' && this.orderService.offerDto.expireDate === null) {      
      this.checkIsUrlIncludesOrderEntry();
    }
    else if (this.orderService.offerDto.status === 'Active' && this.orderService.offerDto.expireDate !== null &&
      (Math.floor(new Date(this.orderService.offerDto.expireDate).valueOf() - new Date().valueOf()) >= 0)) {
      this.checkIsUrlIncludesOrderEntry();
    }
    else if (this.orderService.offerDto.status === 'Active' && this.orderService.offerDto.expireDate !== null &&
      (Math.floor(new Date(this.orderService.offerDto.expireDate).valueOf() - new Date().valueOf()) < 0)) {
      this.checkConfirmDialog();
    }
  }

  checkIsUrlIncludesOrderEntry(){
    if (!this._router.url.includes('/order-entry')) {
      this.swalAlert.alert('Promotional discounts need to be applied manually. Discount information is available by clicking on information icon '+
        'next to promo code field.Please update any future orders as well.');
    }
  }

  checkConfirmDialog(){
    if (!confirm("The promotion code entered has expired. No discounts will be applied. Do you want to continue with this Promotion Code?")) {
      this.orderService.orderDto.promotionCode = '';
      this.orderService.orderDto.promotionHtml = '';
    }
  }

  //getEligibleAmount(): number {
  //  let eligibleAmount = this.orderService.orderDto.orderDetailDtos
  //    .filter(item => item.productDto.allowPaymentPlan)
  //    .reduce((sum, item) => sum + item.extendedPrice, 0);
  //  return eligibleAmount;
  //}
  
  async save() {
    this.orderService.sourceType = SourceType.OrderInquirySave;
    const error = await this.orderService.saveOrderAsync();

    if (error === 'Validation Error') {
      return;
    } else if (error === 'Success') {
      this.recalculateMultiYearVisible();

      // Close the window if the user reverted / voided a new year of a MY order.
      this.closeWindow(); 
     
    } else {
      if ((error as HttpErrorResponse).error?.Message?.includes('Order lock was cleared')) {
        await this.revert();
      }
    }
    
    this.orderLocked = false;
    this.orderService.isClosedOrderSaveNotes = false;
  }
  closeWindow() {
    if (this.enableEditMode && this.userRevertedMYOrder) {
      window.close();
    }
    // Display send email dialog if the users added an item or added quantity to the cart.     
   this.displaySendEmailDialog();
  }
  async displaySendEmailDialog() {
    if (this.orderService.orderDtoBackup.orderDetailDtos.length <= 0){
      this.orderService.orderDtoBackup = JSON.parse(JSON.stringify(this.orderService.orderDto)) as OrderDto;
    }        
    this.checkEmailDialog();

    this.voidOrderDialogModal.show = false;
    this.sourceCode = { id: this.orderService.orderDto.sourceCode ?? '', label: this.orderService.orderDto.sourceCode ?? '', value: this.orderService.orderDto.sourceCode ?? '' };
    this.paymentMethod = this.orderService.orderDto.paymentMethod!;
    const invoices = await this._accountingService.getInvoices(this.orderService.orderDto.originalOrderId == null ? this.orderService.orderDto.id : this.orderService.orderDto.originalOrderId)
    if (invoices) {
      this.getInvoiceDetailsToBeUpdated(invoices);  
      await this.updateInvoiceDetails();
    }
  }

  checkEmailDialog(){
    let addedItem = false;
    let addedQuantity = false;
    for (const orderDetailDto of this.orderService.orderDto.orderDetailDtos) {
      const backupOrderDetailDto = this.orderService.orderDtoBackup.orderDetailDtos.filter(item => item.id === orderDetailDto.id)[0];

      if (backupOrderDetailDto) {
        if (orderDetailDto.orderQuantity > backupOrderDetailDto.openQuantity + backupOrderDetailDto.shippedQuantity) {
          addedQuantity = true;
        }
      }
      else {
        addedItem = true;
      }
    }

    if ((addedItem || addedQuantity) && this._authorizationService.hasResource('OrderInquiryAdditionalActionsSendEmailDialog')) {
      this.showSendEmailDialog.show = true;
    }
  }

  voidNewMYOrder() {
    this.userRevertedMYOrder = true;

    for (const orderDetail of this.orderService.orderDto.orderDetailDtos) {
      orderDetail.orderDetailStatus = 'V';
      orderDetail.voidedQuantity = orderDetail.openQuantity;
      orderDetail.openQuantity = 0;
      orderDetail.voidDate = new Date(Date.parse(Date()));
      orderDetail.voidReasonCode = '9';
      orderDetail.extendedPrice = 0;
      orderDetail.taxAmount = 0;
      orderDetail.taxRate = 0;
      orderDetail.orderQuantity = 0;
      if (orderDetail.subscriptionDto !== null) {
        orderDetail.subscriptionDto.subscriptionStatus = 'V';
      }
    }

    this.orderService.orderDto.openAmount = 0;
    this.orderService.orderDto.orderStatus = 'V';
    this.orderService.orderDto.voidReasonCode = '9';
    this.orderService.orderDto.voidDate = new Date(Date.parse(Date()));
    this.orderService.saveOrderToDatabase(this.orderService.orderDto);
  }

  async revert() {
    // If the user just added a year to a MY order, then clicked revert, we will void the order.
    if (this.enableEditMode) {
      this.voidNewMYOrder();
      return;
    }

    this.orderService.orderDto = this.orderService.orderDtoBackup;
    this.recalculateMultiYearVisible();
    this.orderService.recalculateFields(this.orderService.orderDto);
    this.sourceCode = { id: this.orderService.orderDto.sourceCode ?? '', label: this.orderService.orderDto.sourceCode ?? '', value: this.orderService.orderDto.sourceCode ?? '' };
    this.orderService.updateAmaKeyButtonMode();
    if (this._configService.keyCodeDto.keyCode !== '')
    {
      this._configService.updateKeyCodeTakenDate(this._configService.keyCodeDto.id,this._configService.keyCodeDto.keyCode).subscribe(response => {
        this._configService.keyCodeDto.keyCode='';
        this._configService.keyCodeDto.id=0;
      });;
    }
    this.modeService.mode = Mode.View;
    this.orderService.unlockOrder(this.orderService.orderDto.id).subscribe((msg: string) => {
      this.modeService.mode = Mode.View;
      this.unlockOrder = true;
      this.orderLocked = false;
    }, async error => {
      this.modeService.mode = Mode.View;
      console.log(error);
      await this.swalAlert.alert('An error occurred while unlocking the order.  Please try again.');
    });
  }

  addMultiYear() {
    const addingYearMessage = 'Adding Year';
    this.modeService.addToWaitList(addingYearMessage);
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
    if(currentUser != null)
    {
      this.msId = this._configService.getMyMSId();
    }
    this.orderService.addYearOrder(this.orderService.orderDto.id, this.msId)
      .subscribe((addYear: AddYearDto) => {
        this.modeService.removeFromWaitList(addingYearMessage);
        if(addYear.missingProductCodes.length > 0) {
          this.swalAlert.alert('Order Management has been contacted to have the Next Edition Year Product created for products ' + addYear.missingProductCodes.join(','));
        }
        window.open(`order/${addYear.id}?EditMode=true`);
        location.reload();
      }, error => {
        console.log(error);
        this.modeService.removeFromWaitList(addingYearMessage);
        this.swalAlert.alert('Error adding order to MY deal.');
      });
  }

  gotoOrder(order: any) {
    this.orderService.orderDto.id = order.orderId;
    this._router.navigate(['/order', order.orderId]);
  }
  normalDialogModal = {
    show: false,
  };

  showNormalDialog() {
    this.barModel.progress = 20;
    this.normalDialogModal.show = true;
  }

  closeDialog() {
    this.barModel.progress = 99;
    this.normalDialogModal.show = false;
  }

  promoCodeHtmlContent: string = '';
  promoCodeDialogModal = {
    show: false
  }
  openPromoCodeDetailsPopup() {
    this.promoCodeDialogModal.show = true;
    this.promoCodeHtmlContent = this.orderService.orderDto.promotionHtml;
  }
  closePromoCodeDetailsPopup() {
    this.promoCodeDialogModal.show = false;
  }

  voidOrderDialogModal = {
    show: false,
  };

  showVoidOrderDailog() {
    this.voidOrderDialogModal.show = true;

  }

  async onChangeShipToAddress(zipChanged: boolean) {
    if (zipChanged) {
      await this._taxService.calculateTax(SourceType.OrderInquiryShipTozipChanged,this.orderService.orderDto);
      this.orderService.recalculateFields(this.orderService.orderDto);
    }
  }

  webReviewed() {
    this.msId = this._configService.getMyMSId();
    this.orderService.setWebReviewed(this.orderService.orderDto.id, this.msId).subscribe((msg: string) => {
      this.orderService.getOrderInformation(this.orderService.orderDto.id);
      const emialId=this._configService.getUserEmailFromUserCode(this.orderService.orderDto.billToDto.accountOwnerCode);
      console.log(emialId);
    }, error => {
      console.log(error);
      this.swalAlert.alert('An error occurred while updating order header table. Please try again.');
    });
  }

  async calculateTax() {
    console.log("inside tax method");
    this.visibleTextValue = "Calculating Tax";
    await this._taxService.calculateTax(SourceType.OrderInquiryCalculateTax,this.orderService.orderDto);
    this.orderService.recalculateFields(this.orderService.orderDto);
  }

  checkShippingState(state: string) {
    if (state === null)
    {
      return false;
    }
    const stateData = this.stateList.find(s => s.value === state.trim());
    if (stateData !== undefined) {
      if (stateData.taxable) {
        return true;
      }
      else {
        return false;;
      }
    }
    else {
      return false;
    }
  }

  onChangeOrderStatus(event: { value: IUITKSelectItemProps, originalEvent: PointerEvent }): void {
    const oldStatus = this.orderService.orderDto.orderStatus;
    this.orderService.orderDto.orderStatus = event.value.id;

    if (this.orderService.orderDto.orderStatus === 'V') {
      this.orderService.orderDto.holdReasonCode = null;
    }

    // If the user picked "Select", revert back to old value.
    if (!event.value.id) {
      setTimeout(() => {
        this.orderService.orderDto.orderStatus = oldStatus;
        this.orderService.recalculateFields(this.orderService.orderDto);
      });
      // If the user picks hold, have them select a hold reason.
    } else if (oldStatus !== 'H' && event.value.id === 'H') {
      this.holdReasonDialog.show = true;
    }
  }

  onChangeOrderType(event: { value: IUITKSelectItemProps, originalEvent: PointerEvent }) {
    const oldType = this.orderService.orderDto.orderType;
    this.orderService.orderDto.orderType = event.value.id;

    // If the user picked "Select", revert back to old value.
    if (!event.value.id) {
      setTimeout(() => {
        this.orderService.orderDto.orderType = oldType;
        this.orderService.recalculateFields(this.orderService.orderDto);
      });
    } else if (oldType !== 'E' && event.value.id === 'E') {
      // If the user picks exception, have them select a exception reason.
      this.exceptionReasonDialog.show = true;
    } else if (oldType !== 'Q' && event.value.id === 'Q') {
      // If the user picks quote, have them select a quote reason.
      this.quoteReasonDialog.show = true;
    }
  }

  navigateToARInquiry(accountId: number): void {
    this._router.navigate([]).then(() => {
      window.open('/accountreceivable/arinquiry/' + accountId, '_blank');
    });
  }
  getInvoiceDetailsToBeUpdated(invoices: InvoicesListDto[]){
    this.invoiceDetailsToBeUpdated = [];
    invoices.filter(i => (i.invoiceNumber === 'Pending' || i.balance > 0)).forEach(element => {
      const invoiceDetailsDto = new InvoiceEditDto();

      invoiceDetailsDto.invoiceNumber = element.invoiceNumber;
      invoiceDetailsDto.poNumber = this.orderService.orderDto.poNumber ?? '';
      invoiceDetailsDto.userLogin = this._configService.getMyMSId();
      invoiceDetailsDto.orderNumber = element.orderNumber;
      invoiceDetailsDto.invoiceDate = element.invoiceDate;
      invoiceDetailsDto.balance = element.balance;
      if (this.orderService.orderDto.paymentTerms === 'Credit Card') {
        invoiceDetailsDto.changeCreditCard = true;
      }
      else {
        invoiceDetailsDto.changeCreditCard = false;
      }
      invoiceDetailsDto.changePaymentTerms = false;
      invoiceDetailsDto.expirationDate = this.orderService.orderDto.ccExpirationDate?.trim() ??  "0199";
      invoiceDetailsDto.ccLastFour = this.orderService.orderDto.ccLastFour ?.trim() ?? "0000";
      invoiceDetailsDto.token = this.orderService.orderDto.ccToken ?? '';
      invoiceDetailsDto.paymentMethod = this._configService.getPickList('PMETH').filter(item => item.value === this.orderService.orderDto.paymentMethod)[0]?.label.replace(' ', '') ?? '';
      this.invoiceDetailsToBeUpdated.push(invoiceDetailsDto); 
    });
  }
  async updateInvoiceDetails() {
    if (this.invoiceDetailsToBeUpdated && (this.orderService.orderDto.paymentMethod !== this.orderService.orderDtoBackup.paymentMethod || this.orderService.orderDto.ccLastFour !== this.orderService.orderDtoBackup.ccLastFour || this.orderService.orderDto.ccExpirationDate !== this.orderService.orderDtoBackup.ccExpirationDate || this.orderService.orderDto.ccToken !== this.orderService.orderDtoBackup.ccToken)
      && (this.orderService.orderDtoBackup.paymentPlan === "Monthly" || this.orderService.orderDtoBackup.paymentPlan === "Quarterly")) {
      const invoicesRespond = await this.updateInvoicesFromOrderDialog.openAsync();
      if (invoicesRespond === "Yes") {
        this.invoiceDetailsToBeUpdated.forEach(invoice => {
          this.invoiceEditService.updateInvoiceDetails(this.orderService.orderDto.billToDto.id.toString(), invoice).subscribe(() => {
            console.log(invoice);
          }, async (error: any) => {
            console.log(error);
            await this.swalAlert.alert('An error occurred while updating the invoice details');
          });
        });

        this.invoiceDetailsToBeUpdated = [];
      }
    }
  }
  ngOnDestroy() {
    this.orderIsReadySubscription?.unsubscribe();
    this.configIsReadySubscription?.unsubscribe();
    this.callEditSubscription?.unsubscribe();
    this.callSaveSubscription?.unsubscribe();
    this.callRevertSubscription?.unsubscribe();
  }
}

