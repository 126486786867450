import { Component, Input, OnInit } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Mode } from '../../enums/mode.enum';
import { ContactDto } from '../../dtos/contact-dto.model';
import { ConfigService } from '../../services/config.service';
import { ContactService } from '../../services/contact.service';
import { ModeService } from '../../services/mode.service';
import { OrderService } from "../../services/order.service";
import { AuthorizationService } from '../../services/authorization.service';

@Component({
  selector: 'app-order-by',
  templateUrl: './order-by.component.html',
  styleUrls: ['./order-by.component.css']
})
  
export class OrderByComponent implements OnInit {
  visibleTextValue = "Refreshing Data";
  private readonly orderContactTxt = 'Getting order by contacts';
  orderIsReadySubscription: Subscription | undefined;
  gotContactsSubscription: Subscription | undefined;
  contactList: IUITKSelectItemProps[] = [];
  contacts: ContactDto[] = [];
  activeStatus: string[] = ['A', 'BAD', 'BP'];
  isOrderByDisabled: boolean = true;
  Mode = Mode;
  @Input() isnavigateFromBatchReturn: boolean = false;
  constructor(
    private readonly configService: ConfigService,
    private readonly contactService: ContactService,
    public orderService: OrderService,
    public modeService: ModeService,
    private readonly authorizationService: AuthorizationService) { }

  ngOnInit(): void {
    //Below condition is to check for naviagation from Batch Return
    if (this.isnavigateFromBatchReturn === false) {
      this.gotContactsSubscription = this.contactService.gotContacts.subscribe(data => {
        this.contacts = data.filter(c => this.activeStatus.includes(c.statusCode.trim()) || c.contactId === this.orderService.orderDto.orderByDto.contactId);
        this.contactList = this.contactService.convertContactsToUitkProps(this.contacts);
        this.orderService.orderDto.orderByContactBinding = this.contactList.filter(x => x.id === this.orderService.orderDto.orderByDto.contactId.toString())[0];
        this.setSelectedValue(this.orderService.orderDto.orderByContactBinding);
        this.updateOrderBySectionAfterContactDetailsAreReady();
      });

      this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
        this.contactService.getContacts(this.orderService.orderDto.billToDto.id, this.orderContactTxt);
        if ((this.orderService.orderDto.orderType === 'R' || this.orderService.orderDto.orderType === 'A') &&
          this.authorizationService.hasResource('ReturnEntryOrderByContactDropDown')) {
          this.isOrderByDisabled = false;
        }
    
        if ((this.orderService.orderDto.orderType === ' ' || this.orderService.orderDto.orderType === 'E' ||
          this.orderService.orderDto.orderType === 'Q') && this.authorizationService.hasResource('OrderByContactDropDown')) {
          this.isOrderByDisabled = false;
        }
        //Commented out below lines to load the data without loader waititng for indefinite time
        //  this.modeService.mode = Mode.OrderByWait;
      });
    }
    

  

  }

  setSelectedValue(newOrderByContact: IUITKSelectItemProps) {
    if (!newOrderByContact) {
      return;
    }

    const selectedContact = this.contacts.filter(x => x.contactId.toString() === newOrderByContact.id)[0];
    const orderByDto = this.orderService.orderDto.orderByDto;
    if (!orderByDto || !selectedContact) {
      return;
    }
    
    this.orderService.orderDto.orderByDto.firstName = selectedContact.firstName!;
    this.orderService.orderDto.orderByDto.lastName = selectedContact.lastName!;
    this.orderService.orderDto.orderByDto.contactId = selectedContact.contactId;
    this.orderService.orderDto.orderByDto.allowInformationalEmails = selectedContact.allowInformationalEmails;
    this.orderService.orderDto.orderByDto.allowPromotionalEmails = selectedContact.allowPromotionalEmails;
    this.orderService.orderDto.orderByDto.email = selectedContact.email;
    this.orderService.orderDto.orderByDto.phone = selectedContact.phone;
    this.orderService.orderDto.orderByDto.salesforceId = selectedContact.salesforceId;
  }

  navigateToSFNewOrEditContact(salesforceContactId: string) {
    window.open(`${this.configService.salesforceUrl}/apex/NewContactPage?id=${salesforceContactId}`);
  }

  navigateToSFViewContact(salesforceContactId: string) {
    window.open(`${this.configService.salesforceUrl}/apex/ViewContact?id=${salesforceContactId}`);
  }

  refreshOrderByContactList() {
    this.contactService.clearContacts();    
    this.contactService.getContacts(this.orderService.orderDto.billToDto.id, this.orderContactTxt);
  }
  getOrderData() {
    this.gotContactsSubscription = this.contactService.gotContacts.subscribe(data => {
      this.contacts = data.filter(c => this.activeStatus.includes(c.statusCode.trim()) || c.contactId === this.orderService.orderDto.orderByDto.contactId);
      this.contactList = this.contactService.convertContactsToUitkProps(this.contacts);
      this.orderService.orderDto.orderByContactBinding = this.contactList.filter(x => x.id === this.orderService.orderDto.orderByDto.contactId.toString())[0];
      this.setSelectedValue(this.orderService.orderDto.orderByContactBinding);
    });

    this.contactService.getContacts(this.orderService.orderDto.billToDto.id, this.orderContactTxt);
  }

  updateOrderBySectionAfterContactDetailsAreReady(): void {
    this.orderService.orderDto.orderByDto.firstName = this.contactService.contactDtos!.filter(c => c.contactId === this.orderService.orderDto.orderByDto.contactId)[0].firstName!;
    this.orderService.orderDto.orderByDto.lastName = this.contactService.contactDtos!.filter(c => c.contactId === this.orderService.orderDto.orderByDto.contactId)[0].lastName!;
    this.orderService.orderDto.orderByDto.email = this.contactService.contactDtos!.filter(c => c.contactId === this.orderService.orderDto.orderByDto.contactId)[0].email;
    this.orderService.orderDto.orderByDto.phone = this.contactService.contactDtos!.filter(c => c.contactId === this.orderService.orderDto.orderByDto.contactId)[0].phone;
    this.orderService.orderDto.orderByDto.allowInformationalEmails = this.contactService.contactDtos!
      .filter(c => c.contactId === this.orderService.orderDto.orderByDto.contactId)[0].allowInformationalEmails;
    this.orderService.orderDto.orderByDto.allowPromotionalEmails = this.contactService.contactDtos!
      .filter(c => c.contactId === this.orderService.orderDto.orderByDto.contactId)[0].allowPromotionalEmails;
    this.orderService.orderDto.orderByDto.statusCode = this.contactService.contactDtos!.filter(c => c.contactId === this.orderService.orderDto.orderByDto.contactId)[0].statusCode;
  }

  ngOnDestroy() {
    this.orderIsReadySubscription?.unsubscribe();
    this.gotContactsSubscription?.unsubscribe();
  }
}
