import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Mode } from '../../../enums/mode.enum';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { OrderDto } from '../../../dtos/order-dto.model';
import { ProductDto } from '../../../dtos/product-dto.model';
import { ARSLabelService } from '../../../services/ars-label.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { ConfigService } from '../../../services/config.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';
import { PricingService } from '../../../services/pricing.service';
import { SwalAlert } from '../../../helpers/alert';
import { AccountPricingDto } from 'src/app/dtos/account-pricing-dto.model';


@Component({
  selector: 'app-return',
  templateUrl: './return.component.html',
  styleUrls: ['./return.component.css'],
  encapsulation: ViewEncapsulation.None,
})
  
export class ReturnComponent implements OnInit,AfterViewInit {
  private readonly _router: Router;
  private readonly _pricingService: PricingService;
  private readonly _configService: ConfigService;
  private readonly _elementRef: ElementRef;
  private readonly _authorizationService: AuthorizationService;
  private readonly _arsLabelService: ARSLabelService;
  @ViewChild("returnQuantity") returnQuantity!: ElementRef<HTMLInputElement>;  
  @HostListener('window:unload', ['$event'])
  unloadHandler(event: any) {
    if (this.modeService.mode === Mode.Edit) {
      this.customRevert();     
    }
  }
 
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    if (this.modeService.mode === Mode.Edit) {
      this.customRevert(); 
    }
  }

  Mode = Mode;
  openDeletePopup = false;
  @ViewChild("allowDupYesBtn") allowDupYesBtn!: ElementRef<HTMLInputElement>;
  @ViewChild("noItemOkBtn") noItemOkBtn!: ElementRef<HTMLInputElement>;
  @ViewChild("header") headerele!:ElementRef<HTMLDivElement>;
  @Input() batchReturn: any;
  @Input() returnDtoList: OrderDto[] = [];
  @Input() isnavigateFromBatchReturn: boolean = false;
  orderIsReadySubscription: Subscription | undefined;
  configIsReadySubscription: Subscription | undefined;
  callEditSubscription: Subscription | undefined;
  callRevertSubscription: Subscription | undefined;
  callSaveSubscription: Subscription | undefined;
  callRevertReturnCodeSubscription: Subscription | undefined;
  returnCode: IUITKSelectItemProps | undefined;
  hideAdditionalActions: string = '';

  voidItemDialog = {
    show: false
  }
  dupProduct = {
    show: false
  }
  showNoItemExists = {
    show: false
  }
  orderEntryErrorReasonDialog = {
    show: false
  }
  orderTypeList: IUITKSelectItemProps[] = [];
  orderStatusList: IUITKSelectItemProps[] = [];
  returnCodeList: IUITKSelectItemProps[] = [];
  tableHeader = [
    'Discount', 'Unit Price', 'Ext Price', 'Req Date', 'Status', 'Ship Qty', 'Ship Date', 'Term', 'PP Date', 'Date Voided/Edited',
    'Void Qty', 'Void Reason', 'P Sales Id', 'S Sales Id', 'Payment Plan', 'Tax Amount',];
  msId: string = '';
  orderDtoBackup: OrderDto = new OrderDto();
  orderLocked = false;
  unlockOrder = false;
  selectedOrder: OrderDetailDto = new OrderDetailDto();
  visibleTextValue = this.modeService.mode === Mode.Wait ? "" : "Saving Data";
  loading = false;
  taxRate = 0;
  totalTax = 0;
  myNotificationWarningContent = "This item is inactive or does not exist. Please enter a new item #";
  addOnWarningContent = "Add-ons cannot be added as an item #";
  addPermissionContent = "You do not have permission to add this item. Please contact Order Management";
  havePermission = false;
  isAddOn = false;
  currentOrderDetailId: any;
  swalAlert = new SwalAlert();
  Impersonating: string[]=['Custom Read Only','Customer Service','Lead Management User','Medallion sales Manager','Optum Marketing User','Outbound Medallion','Product Manager','Product Specialist','Read Only','Small Account External',
   'Small Account Internal  Manager','Small Account Internal User ','Software Executive','Technical Support','Medallion User'];
  constructor(
    public modeService: ModeService,
    router: Router,
    public orderService: OrderService,
    pricingService: PricingService,
    configService: ConfigService,
    elementRef: ElementRef,
    authorizationService: AuthorizationService,
    arsLabelService: ARSLabelService
  ) {
    this._router = router;
    this._pricingService = pricingService;
    this._configService = configService;
    this._elementRef = elementRef;
    this._authorizationService = authorizationService;
    this._arsLabelService = arsLabelService;
  }

  ngOnInit() {
    this.configIsReadySubscription = this._configService.configIsReady.subscribe(() => {
      this.orderTypeList = this._configService.getPickList('ORDERTYPE').filter(item => item.value === 'R' || item.value === 'A');
      this.orderStatusList = this._configService.getPickList('ORDERSTATUS');
      this.returnCodeList = this._configService.getPickList('RSOURCE');
      this.returnCode = { id: this.orderService.orderDto.returnCode ?? '', label: this.orderService.orderDto.returnCode ?? '', value: this.orderService.orderDto.returnCode ?? '' };
      this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
        this.navigateToCorrectWindow();
        this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];

        this.orderService.orderDto.orderDetailDtos.forEach(x => {
          x.originalOrderDetailDto = this.orderService.orderDto.originalOrderDto?.orderDetailDtos.filter(item => item.id === x.originalOrderDetailId)[0] ?? null;
         this.setOrderAndReturnQuantities(x);
          x.disableQuantity = x.orderDetailStatus === ' ' ? false : true;
        });
        const returnCodeToBind = this._configService.getPickList('RSOURCE').filter(s=>s.value.trim() === (this.orderService.orderDto?.returnCode?.trim() ?? ''))[0];
        this.returnCode = { id: returnCodeToBind.id ?? '', label: returnCodeToBind.label ?? '', value: returnCodeToBind.value ?? '' };
        this.calculateTaxes();
        this.orderService.orderDto.isReturnNote = true;
        this.orderService.recalculateFields(this.orderService.orderDto);
      });
    });
    this._configService.loadConfigurations();

    this.callEditSubscription = this.orderService.onReturnTriggerEdit.subscribe(() => {
      this.edit(true);
    });
    this.callRevertSubscription = this.orderService.onReturnTriggerRevert.subscribe(() => {
      this.revert();
    });
    this.callSaveSubscription = this.orderService.onReturnTriggerSave.subscribe(() => {
      this.save();
    });
    this.callRevertReturnCodeSubscription = this.orderService.revertReturnCode.subscribe(() => {
      this.revertReturnCode();
    });
  }
  navigateToCorrectWindow(): void {
    if (this.orderService.orderDto.orderType === ' ' || this.orderService.orderDto.orderType === 'E') {
      this._router.navigate(['/order', this.orderService.orderDto.id]);
    }
    if (this.orderService.orderDto.orderType === 'Q' || this.orderService.orderDto.orderStatus === 'D' || (this.orderService.orderDto.orderStatus === 'R' && (this.orderService.orderDto.orderType != 'A' && this.orderService.orderDto.orderType != 'R')) || this.orderService.orderDto.orderStatus === 'M') {
      this._router.navigate(['/order-entry'], {
        queryParams: {
          orderId: this.orderService.orderDto.id
        }
      });
    }
  }
  ngAfterViewInit(): void {
    setTimeout(()=>{
   this.headerele.nativeElement.scrollIntoView({
     behavior: "smooth"
   });
  },2000);}

  customRevert(){
    this.revert();
  }

  setOrderAndReturnQuantities(x:any){
    if (x.originalOrderDetailDto) {
      x.orderQuantity = x.openQuantity + x.shippedQuantity;
      x.originalOrderDetailDto.orderQuantity = x.originalOrderDetailDto.openQuantity + x.originalOrderDetailDto.shippedQuantity;
      x.originalOrderDetailDto.returnedQuantity -= x?.orderQuantity ?? 0;
    }
  }
  add() {
    const orderDetail = new OrderDetailDto();
    orderDetail.orderDetailStatus = ' ';
    orderDetail.itemEditable = true;
    orderDetail.requiredDate = new Date(new Date().toDateString());
    orderDetail.enteredDate = new Date();
    orderDetail.id = this.orderService.getNextOrderDetailId();
    this.orderService.orderDto.orderDetailDtos.unshift(orderDetail);

    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];

    setTimeout(() => {
      if (this._elementRef.nativeElement.querySelector(`input[name=myBasicInput]`)) {
        this._elementRef.nativeElement.querySelector(`input[name=myBasicInput]`).focus();
      }
    });

  }

  edit(bypassPending: boolean = false) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);

    if (currentUser != null) {
      this.msId = this._configService.getMyMSId();
    }

    this.modeService.mode = Mode.Wait;
    this.orderService.setLockOrder(this.orderService.orderDto.id, this.msId, bypassPending).subscribe(
      (data: any) => {
        // Create backup copy so I can revert if needed.
        const contactBinding = this.orderService.orderDto.orderByContactBinding;
        const shipToContactBinding = this.orderService.orderDto.shipToContactBinding;
        const orderNotes = this.orderService.orderDto.orderNotes;
        const systemComments = this.orderService.orderDto.systemComments;
        const freeFormComments = this.orderService.orderDto.freeFormComments;
        this.orderService.orderDto = data;

        this.orderService.orderDto.orderDetailDtos.forEach(x => {
          x.originalOrderDetailDto = this.orderService.orderDto.originalOrderDto?.orderDetailDtos.filter(item => item.id === x.originalOrderDetailId)[0] ?? null;
          if (x.originalOrderDetailDto) {
            x.orderQuantity = x.openQuantity + x.shippedQuantity;
            x.originalOrderDetailDto.orderQuantity = x.originalOrderDetailDto.openQuantity + x.originalOrderDetailDto.shippedQuantity;
            x.originalOrderDetailDto.returnedQuantity -= x?.orderQuantity ?? 0;
          }
          x.disableQuantity =  x.orderDetailStatus === ' ' ? false : true;
        });
        this.orderService.orderDto.orderByContactBinding = contactBinding;
        this.orderService.orderDto.shipToContactBinding = shipToContactBinding;
        this.orderService.orderDto.orderNotes = orderNotes;
        this.orderService.orderDto.systemComments = systemComments;
        this.orderService.orderDto.freeFormComments = freeFormComments;
        this.orderService.orderDto.disableAddReturnToBatchButton=this.orderService.getAddReturnToBatchButtonDisabled(this.orderService.orderDto);
        this.orderService.orderDtoBackup = JSON.parse(JSON.stringify(this.orderService.orderDto)) as OrderDto;
        this.orderService.orderDtoBackup.inUseBy = null;
        this.orderService.orderDtoBackup.inUseDate = null;
        this.modeService.mode = Mode.Edit;
        this.orderLocked = true;

        this.orderService.recalculateFields(this.orderService.orderDto);
      }, error => {
        this.modeService.mode = Mode.View;
        this.swalAlert.alert('Return is already in use and it is locked.');
      });
  }

  revert() {
    this.orderService.orderDto = this.orderService.orderDtoBackup;
    this.revertReturnCode();
    this.orderService.recalculateFields(this.orderService.orderDto);
    this.modeService.mode = Mode.View;
    this.orderService.unlockOrder(this.orderService.orderDto.id).subscribe((msg: string) => {
      this.modeService.mode = Mode.View;
      this.unlockOrder = true;
      this.orderLocked = false;
    }, error => {
      this.modeService.mode = Mode.View;
      console.log(error);
      this.swalAlert.alert('An error occurred while unlock the order.Please try again.');
    });
  }
  revertReturnCode():void{
    const returnCodeToBind=this._configService.getPickList('RSOURCE').filter(s=>s.value.includes(this.orderService.orderDto.returnCode??''))[0];
    this.returnCode = { id: returnCodeToBind.id ?? '', label: returnCodeToBind.label ?? '', value: returnCodeToBind.value ?? '' };
  }

  async save(): Promise<void> {
    this._configService.resetIdleTimeout();
    // TODO: This code needs to calculate the subscription quantity.
    // this.orderService.changeSubscriptionQuantityBR(this.originalOrderDto, this.orderService.orderDto);

    if (!await this.returnQuantityValidateBR())
    {
      return;
    }

    this.loading = true;
    this.visibleTextValue = "Saving Order";
    this.modeService.mode = Mode.View;

    if(this.returnCode?.value !== undefined)
    {
      this.orderService.orderDto.returnCode=this.returnCode.value;
    }

    if (this.orderService.orderDto.originalOrderDto != null) {
      this.orderService.changeSubscriptionQuantityBR('Return Inquiry', this.orderService.orderDto.originalOrderDto, this.orderService.orderDto);
    }
    this.orderService.saveOrderToDatabase(this.orderService.orderDto);
    if(this._arsLabelService.isArsSaveCalled || this._arsLabelService.isArsLabelChanged){
      this._arsLabelService.saveARSLabelDetails(this._arsLabelService.arsLabelDto).subscribe(async () => {
        await this.swalAlert.alert('ARS Label details saved successfully');
      }, (error: any) => {
        console.log(error);
        this.swalAlert.alert('An error occurred while saving ars label details');
      });
    }
    this.orderLocked = false;
    this.loading = false;
  }

  async onChangeProductCode(e: any, orderDetail: OrderDetailDto): Promise<void> {
    this.currentOrderDetailId = orderDetail.id;
    orderDetail.productCode = orderDetail.productCode.toUpperCase();

    let product: ProductDto | null = this._configService.reference?.productDtos.filter(p => p.productCode === e.srcElement.value.toUpperCase())[0] ?? null;

    // Make API call to get the product if it isn't available in the references.
    if (!product) {
      product = await this._configService.getProduct(orderDetail.productCode);
      if (!product) {
        this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(o => o !== orderDetail);
        return;
      }
      this._configService.reference?.productDtos.push(product);
    }

    // Prevent inactive items from being returned if the user doesn't have permissions.
    const returnInactiveItems = this._authorizationService.hasResource('ReturnInactiveItems');
    if (product.statusCode === 'I' && !returnInactiveItems) {
      await this.swalAlert.alert('Inactive items cannot be returned.');
      this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(o => o !== orderDetail);
      return;
    }

    this.isAddOn = product.inventoryType === 'V';

    const isExists = this.orderService.orderDto.orderDetailDtos.filter(o => o.orderDetailStatus !== 'V' &&
      o.productCode.toUpperCase() === e.srcElement.value.toUpperCase()).length > 1;
    if (isExists) {
      this.dupProduct.show = true;
      setTimeout(() => {
        this.allowDupYesBtn.nativeElement.focus();
      }, 0);
    }

    if (this.isAddOn) {
      this.showNoItemExists.show = true;
      this.havePermission = false;
      orderDetail.productCode = '';
      setTimeout(() => {
        this.noItemOkBtn.nativeElement.focus();
      }, 0);
      return;
    }

    if (orderDetail.productDescription !== '') {
      return;
    }

    if (!this._configService.reference) {
      return;
    }

    if (e.srcElement.value.trim() === '') {
      return;
    }

    const cashReceiptBR = this.orderService.cashReceiptBR(orderDetail.productCode, this.orderService.orderDto.paymentMethod, this.orderService.orderDto.paymentPlan);

    if (cashReceiptBR === true) {
      await this.swalAlert.alert('Cash Receipts are not allowed on a payment plan');
    }

    orderDetail.productDto = product;
    orderDetail.productDescription = product.description;
    orderDetail.productId = product.id;
    orderDetail.openQuantity = -1;
    orderDetail.discount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, product.id);
    orderDetail.listPrice = product.listPrice;
    orderDetail.requiredDate = new Date(new Date().toDateString());
    orderDetail.orderDetailStatus = ' ';
    orderDetail.disableQuantity = false;
    orderDetail.shippedQuantity = 0;
    orderDetail.voidedQuantity = 0;
    orderDetail.accountOwnerCode = this.orderService.orderDto.billToDto.accountOwnerCode;
    orderDetail.salespersonCode = '000';
    orderDetail.classCode = product.classCode;
    orderDetail.glCode = product.glCode;
    orderDetail.isPhysical = product.isPhysical;
    orderDetail.isElectronic = product.isElectronic;
    orderDetail.isWebFulfilled = product.isWebFulfilled;
    this._pricingService.calculateUnitPrice(orderDetail);
    orderDetail.orderQuantity = orderDetail.openQuantity + orderDetail.shippedQuantity;
    this._pricingService.calculateExtendedPrice(orderDetail);
    orderDetail.itemEditable = false;
    this.orderService.recalculateFields(this.orderService.orderDto);
  }

  onChangeOrderQuantity(orderDetailDto: OrderDetailDto) {
    if (orderDetailDto.orderQuantity > 0) {
      orderDetailDto.orderQuantity *= -1;
      orderDetailDto.openQuantity = orderDetailDto.orderQuantity - orderDetailDto.shippedQuantity;
    }

    // Revert back to shipped quantity if input validation error
    if (orderDetailDto.originalOrderDetailDto) {
      if (orderDetailDto.orderQuantity === 0 ||
        -orderDetailDto.orderQuantity > orderDetailDto.originalOrderDetailDto.shippedQuantity + orderDetailDto.originalOrderDetailDto.returnedQuantity) {
        orderDetailDto.orderQuantity = -orderDetailDto.originalOrderDetailDto.shippedQuantity - orderDetailDto.originalOrderDetailDto.returnedQuantity;
        orderDetailDto.openQuantity = -orderDetailDto.originalOrderDetailDto.shippedQuantity - orderDetailDto.originalOrderDetailDto.returnedQuantity;
      }

      const originalOrderQuantity = orderDetailDto.originalOrderDetailDto.orderQuantity;
      const originalTaxAmount = orderDetailDto.originalOrderDetailDto.taxAmount;

      orderDetailDto.taxAmount = this._pricingService.round(((orderDetailDto.orderQuantity / originalOrderQuantity) * originalTaxAmount), 2);
    }

    this.orderService.recalculateFields(this.orderService.orderDto);
    this.calculateTaxes();
  }

  onChangeUnitPrice(orderDetail: OrderDetailDto) {
    if (orderDetail.productCode === '0070' || orderDetail.productCode === '0070E' || orderDetail.productCode === '0048' || orderDetail.productCode === '0060') {
      orderDetail.listPrice = orderDetail.unitPrice;
    }
    orderDetail.discount = this._pricingService.round((100 - orderDetail.unitPrice / orderDetail.listPrice * 100), 10);
    this._pricingService.calculateUnitPrice(orderDetail);
    this._pricingService.calculateExtendedPrice(orderDetail);
    this.orderService.recalculateFields(this.orderService.orderDto);
    this.calculateTaxes();
  }
  calculateTaxes() {
    this.taxRate = this.orderService.orderDto.orderDetailDtos.map(o => o.taxRate).reduce((a, b) => Math.max(a, b));
    this.totalTax = this.orderService.orderDto.orderDetailDtos.map(o => o.taxAmount).reduce((a, b) => a + b) +
    this.orderService.orderDto.orderDetailDtos.filter(item => item.productCode === '0048').reduce((sum, item) => sum + item.extendedPrice, 0);
  }

  onDeleteClick(record: any) {
    this.selectedOrder = record;
    this.voidItemDialog.show = true;
  }

  async returnQuantityValidateBR(): Promise<boolean> {
    if (!this.orderService.orderDto.originalOrderDto)
    {
      await this.swalAlert.alert("Data error getting original order.");
      return false;
    }

    for (const mainLineItem of this.orderService.orderDto.originalOrderDto.orderDetailDtos) {
      // Calculate the total main item quantity and the vLine item quantity to ensure the vLine items are returned before the main item.
      const returnedMainItem = this.orderService.orderDto.orderDetailDtos
        .filter(m => m.originalOrderDetailId === mainLineItem.id)[0];
      const returnedMainItemQuantity = this.orderService.orderDto.orderDetailDtos
        .filter(m => m.originalOrderDetailId === mainLineItem.id)
        .reduce((sum, item) => sum + item.orderQuantity, 0);
      const originalVLineItemQuantity = this.orderService.orderDto.originalOrderDto.orderDetailDtos
        .filter(m => m.productCode === 'V' + mainLineItem.productCode && m.orderDetailStatus !== 'V')
        .reduce((sum, item) => sum + item.orderQuantity + item.returnedQuantity, 0);
      const returnedVLineItemQuantity = this.orderService.orderDto.orderDetailDtos
        .filter(m => m.productCode === 'V' + mainLineItem.productCode)
        .reduce((sum, item) => sum + item.orderQuantity, 0);

      const originalLicenseLineItemQuantity = this.orderService.orderDto.originalOrderDto.orderDetailDtos
        .filter(m =>  m.parentOrderDetailId === mainLineItem.id && m.orderDetailStatus !== 'V' && m.isLicense)
        .reduce((sum, item) => sum + item.orderQuantity + item.returnedQuantity, 0 );

      const returnedLicenseLineItemQuantity = this.orderService.orderDto.orderDetailDtos
        .filter(m =>  m.parentOrderDetailId === returnedMainItem?.id && m.orderDetailStatus !== 'V' && m.isLicense)
        .reduce((sum, item) => sum + item.orderQuantity, 0);


      if (returnedMainItemQuantity < 0 && originalVLineItemQuantity + returnedVLineItemQuantity + originalLicenseLineItemQuantity +returnedLicenseLineItemQuantity !== 0) {
        await this.swalAlert.alert(`All V line items including license items need to be returned before the main item ${mainLineItem.productCode} is returned.`);
        return false;
      }

      // Ensure the each distinct total addon quantity is not greater than the total main item quantity including vLine items.
      const totalMainQuantity = mainLineItem.orderQuantity + mainLineItem.returnedQuantity + returnedMainItemQuantity +
        originalVLineItemQuantity + returnedVLineItemQuantity + originalLicenseLineItemQuantity + returnedLicenseLineItemQuantity;
      const distictAddonProductCodes = [...new Set(this.orderService.orderDto.originalOrderDto.orderDetailDtos
        .filter(item => item.parentOrderDetailId === mainLineItem.id && !item.isLicense).map(item => item.productCode))];

      for (const addonProductCode of distictAddonProductCodes) {
        const originalAddonQuantity = this.orderService.orderDto.originalOrderDto.orderDetailDtos
          .filter(m => m.productCode === addonProductCode && m.parentOrderDetailId === mainLineItem.id && m.orderDetailStatus !== 'V')
          .reduce((sum, item) => sum + item.orderQuantity + item.returnedQuantity, 0);
        
        
        if(!await this.checkParentAndMainQuantity(addonProductCode,originalAddonQuantity,mainLineItem,totalMainQuantity,returnedMainItem)){
          return false;
        }
      }
    }
    return true;
  }

  async checkParentAndMainQuantity(addonProductCode:any,originalAddonQuantity:any,mainLineItem:any,totalMainQuantity:any,returnedMainItem:any): Promise<boolean> {
    const returnedAddonQuantity = this.orderService.orderDto.orderDetailDtos
          .filter(m => m.productCode === addonProductCode && m.parentOrderDetailId === (returnedMainItem?.id ?? 0))
          .reduce((sum, item) => sum + item.orderQuantity, 0);
    const isMaxParentQuantity = this.orderService.orderDto.orderDetailDtos
    .filter(m => m.productCode === addonProductCode && m.originalOrderDetailDto?.parentOrderDetailId === mainLineItem.id)[0]?.isMaxParentQuantity ?? true;
    const totalAddonQuantity = originalAddonQuantity + returnedAddonQuantity;

    if (totalMainQuantity === 0 && totalAddonQuantity > 0) {
      await this.swalAlert.alert(`All ${addonProductCode} must be returned when ${mainLineItem.productCode} is fully returned.`);
      return false;
    }

    if (isMaxParentQuantity && totalAddonQuantity > totalMainQuantity) {
      await this.swalAlert.alert(`${mainLineItem.productCode} plus additional licenses has quantity of ${totalMainQuantity}.  ${addonProductCode} with quantity of 
      ${totalAddonQuantity} cannot exceed the total main item quantity.`);
      return false;
    }
    return true;
  }

  onChangeReturnType(event: {value: IUITKSelectItemProps, originalEvent: PointerEvent }) {
    const oldType = this.orderService.orderDto.orderType;
    this.orderService.orderDto.orderType = event.value.id;

    // If the user picked "Select", revert back to old value.
    if (!event.value.id) {
      setTimeout(() => {
        this.orderService.orderDto.orderType = oldType;
        this.orderService.recalculateFields(this.orderService.orderDto);
      });
    }
  }

  closeNoItemDialog() {
    this.showNoItemExists.show = false;
  }
  allowDup() {
    setTimeout(() => {
      this.returnQuantity.nativeElement.focus();
    });
    this.dupProduct.show = false;
    return;
  }
  stopDup() {
    this.orderService.orderDto.orderDetailDtos.filter(i => i.id === this.currentOrderDetailId)[0].productCode = "";
    const orderDetail = this.orderService.orderDto.orderDetailDtos.filter(i => i.id === this.currentOrderDetailId)[0];
    orderDetail.productDescription = "";
    orderDetail.openQuantity = 0;
    orderDetail.discount = 0;
    orderDetail.listPrice = 0;
    orderDetail.requiredDate = new Date(new Date().toDateString());
    orderDetail.orderDetailStatus = ' ';
    orderDetail.shippedQuantity = 0;
    orderDetail.voidedQuantity = 0;
    orderDetail.accountOwnerCode = "";
    orderDetail.salespersonCode = '000';
    orderDetail.classCode = "";
    orderDetail.glCode = "";
    orderDetail.orderQuantity = 0;
    orderDetail.itemEditable = true;
    orderDetail.unitPrice = 0;
    orderDetail.extendedPrice = 0;

    this.orderService.orderDto.orderDetailDtos.splice(this.orderService.orderDto.orderDetailDtos.findIndex(i => i.id === this.currentOrderDetailId), 1);
    this.dupProduct.show = false;
    return;
  }

  navigateToOrderInquiry(orderId: number | null) {
    this._router.navigate([]).then(() => {
      window.open('/order/' + orderId, '_blank');
    });
  }

  onChangeDiscount(orderDetail: OrderDetailDto) {
    this._pricingService.calculateUnitPrice(orderDetail);
    this._pricingService.calculateExtendedPrice(orderDetail);
    this.orderService.recalculateFields(this.orderService.orderDto);
  }

  checkIfOEEReason(event: {value: IUITKSelectItemProps, originalEvent: PointerEvent }): void {
    const oldType = this.returnCode;
    if (this.returnCode) {
      this.returnCode.id = event.value.id;
    }
    this.orderEntryErrorReasonDialog.show = event.value.value?.trim() === 'OEE'? true: false;
    // If the user picked "Select", revert back to old value.
    if (!event.value.id) {
      setTimeout(() => {
        this.returnCode = oldType;
      });
    }
  }

  navigateToARInquiry(accountId: number): void {
    this._router.navigate([]).then(() => {
      window.open('/accountreceivable/arinquiry/' + accountId, '_blank');
    });
  }

  ngOnDestroy() {
    this.orderIsReadySubscription?.unsubscribe();
    this.configIsReadySubscription?.unsubscribe();
    this.callRevertReturnCodeSubscription?.unsubscribe();
  }
  // <!-- "{'width': returnDetailDto.parentOrderDetailId === null && returnDetailDto.productCode.indexOf('V') !== 0 ? '120px' : '105px',  -->
  // <!-- 'margin-left': returnDetailDto.parentOrd/erDetailId === null && returnDetailDto.productCode.indexOf('V') !== 0 ? '0px' : '15px'}" -->
  
  getdatafile(orderDetail: OrderDetailDto){
    if (orderDetail.productDto?.configSetDtos?.some(config => config.configSet === 'DATAFILE')) {
      return  '#9BC2E6' ;
    }
    else {
      return '#FFFFFF' ;

    }
  }
  getdiscount(orderDetail: OrderDetailDto) {
    
    if (orderDetail.productId!==0 && this.orderService.orderDto.billToDto.specifiedPriceList ) {
      const priceDto = this.orderService.orderDto.billToDto.accountPricingDtos?.filter((priceObj) =>
        priceObj.productId === orderDetail.productId
      );
      if(priceDto){
        const result = this.setBackGroundColorWhenPriceDtoExists(orderDetail, priceDto);
        if( result!= ""){
          return result;
        }
      }   
      if (orderDetail.productDto?.productCode != '' && orderDetail.listPrice == 0 && !(orderDetail.productDto?.allowCustomStandard || orderDetail.productDto?.allowCustomPrice)) {
        return { 'background-color': '#EFEFEE' };
      }
    }
    if (orderDetail.promotionEntryCode === 'ILT' || orderDetail.promotionEntryCode === 'ILP' || orderDetail.promotionEntryCode === 'OLT' || orderDetail.promotionEntryCode === 'OLP') {
      return { 'background-color': '#FBBDBD' }//pink
    }
    return { 'background-color': '#FFF' }

  }

  setBackGroundColorWhenPriceDtoExists(orderDetail: OrderDetailDto, priceDto: AccountPricingDto[]): any {
    if((orderDetail.promotionEntryCode === 'ILT' || orderDetail.promotionEntryCode === 'ILP'
    || orderDetail.promotionEntryCode === 'OLT' || orderDetail.promotionEntryCode === 'OLP')){
      if(orderDetail?.discount === priceDto[0]?.discount){
        return { 'background-color': '#A9D08E' }//green
      }
      if(orderDetail?.discount != priceDto[0]?.discount){
        return { 'background-color': '#FBBDBD' }//pink
      }
    }
    if (priceDto.length > 0 && orderDetail?.discount === priceDto[0]?.discount) {
      return { 'background-color': '#A9D08E' }//green
    }
    return "";
  }

}
