import { AuthorizationLineDto } from "./Authorization-line-dto.model";
import { AuthorizationNoteDto } from "./authorization-note-dto.model";
export class AuthorizationDto {
  id= 0;
  accountId= 0;
  amount= 0;
  authorizationDate= new Date();
  orderId=0;
  company= "";
  returnCode= '';
  medCode=0;
  enteredBy= "";
  notes: AuthorizationNoteDto[] = [];
  cmFlag= "";
  accountType="";
  paymentTerms="";
  paymentMethod="";
  ccToken = "";
  ccLastFour="";
  ccExpirationDate=new Date();
  salesforceId = '';
  authorizationLines: AuthorizationLineDto[] = [];
  enteredByEmail='';
  // The following properties are used only for binding to the UI
  isSelected = false;
  noteSort = '';
}
