<div class="footer-container">
  <div>
    <button type="button"
      class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs"
      (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"
      style="background-color: #002677; color: white !important; font-weight: bold; border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;">
      Additional Actions
    </button>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed"
      style="box-sizing: border-box; border-width: 1px; border-style: solid; border-color: rgba(208, 208, 206, 1);">
      <div>
        <div style="display:flex">
          <div style="flex: 1 0 calc(15% - 5px);margin:8px 0px 0px 0px;transform: translateY(-10%);">
            <div class="uitk-l-grid__row">
              <button id="NotesButton" type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs row-one"
                *appShowResource="'ReturnEntryAdditionalActionsNotesButton'"
                (click)="notesDialog.show = true">
                Notes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="additionalFotter" class="uitk-l-grid--max-width footer">
    <div class="uitk-l-grid__row">
      <div class="uitk-l-grid__col--9" style="padding-right: 32px;">
        <div class="uitk-l-grid__row">
          <div class="uitk-l-grid__col--4" style="display:flex;">
            <label uitkLabel style="width: 80px !important;">Method</label>
            <select  id="returnActionsMethod" [(ngModel)]="orderService.orderDto.paymentMethod" class="form-select"
              aria-label="select" disabled >
              <option value="null" selected>Select</option>
              <option *ngFor="let list of methodList" value={{list.value}}>{{list.label}}</option>
            </select>
          </div>
          <div class="uitk-l-grid__col--4" style="display:flex;">
            <label uitkLabel style="width: 80px !important;">Terms</label>
            <select  id="returnActionsTerms" [(ngModel)]="orderService.orderDto.paymentTerms" class="form-select"
              aria-label="select" disabled>
              <option value="null" selected>Select</option>
              <option *ngFor="let list of termsList" value={{list.label}}>{{list.label}}</option>
            </select>
          </div>
          <div class="uitk-l-grid__col--4" style="display:flex;">
            <label uitkLabel style="width: 133px;">PO#</label>
            <uitk-form-field>
              <input uitkInput id="returnActionsPONumber" class="textbox" type="text"
                [(ngModel)]="orderService.orderDto.poNumber" style="width: 150px;" appEnableResource="ReturnEntryFooterPOTextBox" />
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__row">
          <div class="uitk-l-grid__col--4" style="display:flex;">
            <label uitkLabel style="width: 80px !important;">Token</label>
            <uitk-form-field>
              <input uitkInput id="returnActionsToken" class="textbox" type="text"
                [(ngModel)]="orderService.orderDto.ccToken" style="width: 150px;" disabled />
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__col--4" style="display:flex;">
            <label uitkLabel style="width: 80px !important;">Exp</label>
            <uitk-form-field>
              <input uitkInput id="returnActionsExp" class="textbox" type="text"
                [(ngModel)]="orderService.orderDto.ccExpirationDate" style="width: 150px;" disabled />
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__col--4" style="display:flex;">
            <label uitkLabel style="width: 133px;">CC Last 4</label>
            <uitk-form-field>
              <input uitkInput id="returnActionsCCLastFour" class="textbox" type="text"
                [(ngModel)]="orderService.orderDto.ccLastFour" style="width: 150px;" disabled />
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__row" style="padding-top:6px">
          <div class="uitk-l-grid__col--4" style="display:flex;">
            <label uitkLabel style="width: 80px !important;">ARS Label</label>
            <uitk-form-field>
              <input uitkInput id="returnActionsArsLabel" class="textbox" type="text"
                [(ngModel)]="orderService.orderDto.arsLabel" style="width: 150px;" disabled />
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__col--4" style="display:flex;">
            <label uitkLabel style="width: 80px !important;">Payment Plans</label>
            <uitk-form-field>
              <input uitkInput id="returnActionsPaymentPlan" class="textbox" type="text"
                [(ngModel)]="orderService.orderDto.paymentPlan" style="width: 150px;" disabled />
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__col--4">
          </div>
        </div>
      </div>
      <div class="uitk-l-grid__col--3">
        <div class="uitk-l-grid__row" style="border-left: 4px solid gray;padding-bottom: 5px;">
          <div class="uitk-l-grid__row">
            <label uitkLabel style="padding-left: 13px;width: 100px;">Tax Rate</label>
            <uitk-form-field>
              <input uitkInput id="returnActionsTaxRate" class="textbox" type="text" [ngModel]="taxRate"
                style="width: 100px;" disabled />
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__row">
            <label uitkLabel style="padding-left: 13px;width: 100px;">Total Tax</label>
            <uitk-form-field>
              <input uitkInput id="returnActionsTotalTax" class="textbox" type="text"
                [ngModel]="totalTax| number:'1.2-2'" style="width: 100px;" disabled />
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__row">
            <label uitkLabel style="padding-left: 13px;width: 100px;">SO Total</label>
            <uitk-form-field>
              <input uitkInput id="returnActionsSOTotal" class="textbox" type="text"
                [ngModel]="orderService.orderDto.shippedAmount + orderService.orderDto.openAmount + totalTax | number:'1.2-2'"
                style="width: 100px;" disabled />
            </uitk-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notesDialog.show">
  <app-order-notes [dialog]="notesDialog"></app-order-notes>
</div>
