import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Dialog } from '../../helpers/dialog';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-apply-changes-to-future-dialog',
  templateUrl: './apply-changes-to-future-dialog.component.html',
  styleUrls: ['./apply-changes-to-future-dialog.component.css']
})
export class ApplyChangesToFutureDialogComponent implements OnInit {
  @ViewChild("saveFutureOrderBtn") saveFutureOrderBtn!: ElementRef<HTMLInputElement>;
  @Input() dialog!: Dialog<string>;

  itemsRestrictedFromMUDeals = '';
  itemsRestrictedFromMUDealsList: string[] = [];

  constructor(private readonly orderService: OrderService) { }

  ngOnInit(): void {
    this.itemsRestrictedFromMUDeals = this.orderService.orderDto.orderDetailDtos
      .filter(item => !item.productDto.allowMultiYear)
      .reduce((list, item) => list ? (list + ', ' + item.productCode) : item.productCode, '');
    this.itemsRestrictedFromMUDealsList = this.itemsRestrictedFromMUDeals === '' ? [] : this.itemsRestrictedFromMUDeals.split(',')

    setTimeout(() => {
      this.saveFutureOrderBtn.nativeElement.focus();
    });
  }

  allowFOSave(): void {
    this.dialog.close('Yes');
  }

  stopFOSave(): void {
    this.dialog.close('No');
  }
}
