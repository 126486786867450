<div class="container-fluid px-0">
  <div class="row mt-3">
    <div class="col-12 d-flex">
      <span class="approvalHistoryHeader">
        Approval History
      </span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-9 d-flex">
      <div class="col-2 d-flex">
        <label class="align-self-center label" uitkLabel>Approval Required</label>
      </div>
      <div class="col-10 d-flex">
        <label class="align-self-center light-weight-label"
          uitkLabel>{{ _approvalService.orderApprovalInputDto.approvalRequired }}</label>
      </div>
    </div>
    <div class="col-3 d-flex">
      <div class="col-4 d-flex">
        <label class="align-self-center label" uitkLabel>Allowed %</label>
      </div>
      <div class="col-8 d-flex">
        <uitk-form-field>
          <input uitkInput id="approval-history-allowed-percentage-textbox" class="textbox text-end" type="text"
            [ngModel]="_approvalService.orderApprovalInputDto.allowedPercentage / 100 | percent:'1.2-2'"
            (ngModelChange)="_approvalService.orderApprovalInputDto.allowedPercentage=$event" style="width: 165px;"
            disabled />
        </uitk-form-field>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-9 d-flex">
      <div class="col-2 d-flex">
        <label class="align-self-center label" uitkLabel>Below Floor Price</label>
      </div>
      <div class="col-10 d-flex">
        <uitk-form-field>
          <input uitkInput id="approval-history-below-floor-price-textbox" class="textbox" type="text"
            [(ngModel)]="_approvalService.orderApprovalInputDto.itemsBelowFP" style="width: 325px;" disabled />
        </uitk-form-field>
      </div>
    </div>
    <div class="col-3 d-flex">
      <div class="col-4 d-flex">
        <label class="align-self-center label" uitkLabel>Total Ext Price</label>
      </div>
      <div class="col-8 d-flex">
        <uitk-form-field>
          <input uitkInput id="approval-history-total-ext-price-textbox" class="textbox text-end" type="text"
            [ngModel]="_approvalService.orderApprovalInputDto.totalExtendedPrice | currency:'USD':'symbol':'1.2-2'"
            (ngModelChange)="_approvalService.orderApprovalInputDto.totalExtendedPrice=$event" style="width: 165px;" disabled/>
        </uitk-form-field>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-9 d-flex">
      <div class="col-2 d-flex">
        <label class="align-self-center label" uitkLabel>Over List Price</label>
      </div>
      <div class="col-10 d-flex">
        <uitk-form-field>
          <input uitkInput id="approval-history-over-list-price-textbox" class="textbox" type="text"
            [(ngModel)]="_approvalService.orderApprovalInputDto.itemsAboveLP" style="width: 325px;" disabled/>
        </uitk-form-field>
      </div>
    </div>
    <div class="col-3 d-flex">
      <div class="col-4 d-flex">
        <label class="align-self-center label" uitkLabel>Margin</label>
      </div>
      <div class="col-8 d-flex">
        <uitk-form-field>
          <input uitkInput id="approval-history-total-ext-price-textbox" class="textbox text-end" type="text"
            [ngModel]="_approvalService.orderApprovalInputDto.margin | currency:'USD':'symbol':'1.2-2'"
            (ngModelChange)="_approvalService.orderApprovalInputDto.margin=$event" style="width: 165px;" disabled/>
        </uitk-form-field>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-11 d-flex">
      <div class="divColumns table-responsive" id="approval-history-table-div" style="width: 100%;">
        <table id="approval-history-table-grid"
          style="border-right: 0px; border-left: 0px; width: 100%; white-space: nowrap;">
          <thead id="approval-history-table-grid-header">
            <tr style="height: 60px; border-bottom: 2px solid #002677;">
              <th scope="col" style="width: 15%;">
                Approval Levels
              </th>
              <th scope="col" style="width: 15%;">
                Approver
              </th>
              <th scope="col" style="width: 15%;">
                Approved By
              </th>
              <th scope="col" style="width: 15%;">
                Status
              </th>
              <th scope="col" style="width: 10%;">
                Date
              </th>
              <th scope="col">
                Comments
              </th>
            </tr>
          </thead>
          <tbody id="approval-history-table-grid-body">
            <tr *ngFor="let data of _approvalService.orderApprovalHistoryDto" style="border-bottom: 1px solid #d0d0ce;">
              <!-- Approval Levels -->
              <td class="align-middle">
                <label class="align-self-center label" uitkLabel>{{ data.approvalLevel }}</label>
              </td>
              <!-- Approver -->
              <td class="align-middle">
                <label class="align-self-center light-weight-label" uitkLabel>{{ data.approver }}</label>
              </td>
              <!-- Approved By -->
              <td class="align-middle">
                <label class="align-self-center light-weight-label" uitkLabel>{{ data.approvedOrRejectedBy }}</label>
              </td>
              <!-- Status -->
              <td class="align-middle">
                <label class="align-self-center light-weight-label"
                  uitkLabel>{{ data.approvedOrRejectedStatus !== null ? data.approvedOrRejectedStatus === 'A' ? 'Approved' : 'Rejected' : 'Pending Approval' }}</label>
              </td>
              <!-- Date -->
              <td class="align-middle">
                <label class="align-self-center light-weight-label"
                  uitkLabel>{{ data.approvedOrRejectedDate | date: 'MM/dd/yyyy' }}</label>
              </td>
              <!-- Comments -->
              <td class="align-middle">
                {{ data.comments }}
              </td>
            </tr>
            <tr *ngIf="_approvalService.orderApprovalHistoryDto.length === 0">
              <td colspan="6" class="text-center">
                No Records Found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
