import { Component, Input, OnInit } from '@angular/core';
import { Dialog } from '../../helpers/dialog';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order-offer-qualification',
  templateUrl: './order-offer-qualification.component.html',
  styleUrls: ['./order-offer-qualification.component.css']
})
  
export class OrderOfferQualificationComponent {
  @Input() orderOfferQualificationDialog!: Dialog<string>;
  @Input() associatedPremiumsList!: string;
  @Input() offerType!: string;

  constructor(private readonly orderService: OrderService) { }

  deletePremiums(): void {
    this.orderService.orderDto.orderOfferQualificationsChangedPopupButton = 'Yes';
    this.orderOfferQualificationDialog.close("Delete");
  }

  closeOrderOfferQualification(): void {
    this.orderService.orderDto.orderOfferQualificationsChangedPopupButton = 'No';
    this.orderOfferQualificationDialog.close("Close");
  }
}
