import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderSearchResult } from '../../../dtos/order-search-result.model';

@Component({
  selector: 'app-return-search-results',
  templateUrl: './return-search-results.component.html',
  styleUrls: ['./return-search-results.component.css']
})
  
export class ReturnSearchResultsComponent implements OnInit {
  private readonly _activatedRoute: ActivatedRoute;
  index = 0;
  currentindex = 0;
  totalRecords = 0;
  totalcountresult: any;
  tempOrderSearchResults: OrderSearchResult[] = [];
  constructor(activatedRoute: ActivatedRoute) {
    this._activatedRoute = activatedRoute;
  }

  ngOnInit(): void {
    this.getCurrentIndex(this._activatedRoute.snapshot.params.returnId);
  }

  getCurrentIndex(val: number) {
    const selectedOrderId = val;
    this.totalcountresult = localStorage.getItem('searchOrderResults');
    this.tempOrderSearchResults = JSON.parse(this.totalcountresult).orderSearchResults;
    this.totalRecords =  JSON.parse(this.totalcountresult).totalRecords;
    let searchOperator:any = localStorage.getItem('searchOperator');
    searchOperator = JSON.parse(searchOperator);
    this.index = this.tempOrderSearchResults.findIndex(o => o.orderNumber === Number(selectedOrderId));

    if(this.tempOrderSearchResults.length <= searchOperator.pageSize) {
      this.currentindex = ((searchOperator.pageNumber - 1) * searchOperator.pageSize) + this.index + 1;
    }
    else {
      this.currentindex = (searchOperator.pageNumber * searchOperator.pageSize) - (this.tempOrderSearchResults.length - this.index) + 1;
    }
  }

}
