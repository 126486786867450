import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ConfigService } from 'src/app/services/config.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-pro-forma-invoice',
  templateUrl: './pro-forma-invoice.component.html',
  styleUrls: ['./pro-forma-invoice.component.css']
})
export class ProFormaInvoiceComponent implements OnInit {
  private readonly _configService: ConfigService;
  configIsReadySubscription!: Subscription;
  contentSolutionsClientManagerName = '';
  contentSolutionsClientManagerPhone = 'default';
  contentSolutionsClientManagerEmail = '';
  contentSolutionsClientManagerFax = 'default';
  today = '';
  @Input() enteredByUserRole: string | null = null;
  disclaimerType = '';

  constructor(public orderService: OrderService, configService: ConfigService,private authorizationService: AuthorizationService) {
    this._configService = configService;
  }

  ngOnInit(): void {
    console.log("this.orderService.orderDto.billToDto.isPORequired",this.orderService.orderDto.billToDto.isPORequired);
    if (this.orderService.orderDto.billToDto.isPORequired) {
    
      this.disclaimerType = 'porequired';
    } else if (this.orderService.orderDto.billToDto.creditLimit !== 10000 && this.orderService.orderDto.billToDto.creditLimit !== 1000) {
      this.disclaimerType = 'prepayrequired';
    } else {
      this.disclaimerType = 'standard';
    }
    this._configService.loadConfigurations();
    this.configIsReadySubscription = this._configService.configIsReady.subscribe(() => {
      this.contentSolutionsClientManagerEmail = this.orderService.orderDto.billToDto.accountTypeCode === 'O' ? this._configService.getEmailFromCustomerService('O') :
        this._configService.getUserEmailFromUserCode(this.orderService.orderDto.billToDto.accountOwnerCode);

      this.contentSolutionsClientManagerPhone = this.orderService.orderDto.billToDto.accountTypeCode === 'O' ? this._configService.getPhoneFromCustomerService('O') :
        this._configService.getUserPhoneFromUserCode(this.orderService.orderDto.billToDto.accountOwnerCode);


      this.contentSolutionsClientManagerFax = this.orderService.orderDto.billToDto.accountTypeCode === 'O' ? this._configService.getFaxFromCustomerService('O') :
        this._configService.getFaxFromSalesforce(this.orderService.orderDto.billToDto.accountOwnerCode);
    });
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); 
    let yyyy = today.getFullYear();
    this.today = mm + '/' + dd + '/' + yyyy;
  }
  generateFullName(firstName: string | null, lastName: string | null): string {
    return [firstName, lastName].filter(Boolean).join(' ');
  }

  generateCityStateZip(city: string | null, state: string | null | undefined, zip: string | null): string {
    if (this.orderService.orderDto?.paymentMethod === 'S') {
      zip = this.orderService?.selectedShipToContact?.mailingStreet ? this.orderService.selectedShipToContact?.mailingZip
        : this.orderService?.selectedShipToContact?.street ? this.orderService.selectedShipToContact?.zip
          : this.orderService.orderDto?.shipToDto?.zip;
      state = this.orderService?.selectedShipToContact?.mailingStreet ? this.orderService?.selectedShipToContact?.mailingState
        : this.orderService?.selectedShipToContact?.street ? this.orderService?.selectedShipToContact?.state
          : this.orderService.orderDto?.shipToDto?.state;
      city = this.getCityDetails();
    }

    return [[city, state].filter(Boolean).join(', '), zip].filter(Boolean).join(' ');
  }

  getFax()
  {
    this.contentSolutionsClientManagerEmail = this.orderService.orderDto.billToDto.accountTypeCode === 'O' ? this._configService.getEmailFromCustomerService('O') :
        this._configService.getUserEmailFromUserCode(this.orderService.orderDto.billToDto.accountOwnerCode);

      this.contentSolutionsClientManagerPhone = this.orderService.orderDto.billToDto.accountTypeCode === 'O' ? this._configService.getPhoneFromCustomerService('O') :
        this._configService.getUserPhoneFromUserCode(this.orderService.orderDto.billToDto.accountOwnerCode);


      this.contentSolutionsClientManagerFax = this.orderService.orderDto.billToDto.accountTypeCode === 'O' ? this._configService.getFaxFromCustomerService('O') :
        this._configService.getFaxFromSalesforce(this.orderService.orderDto.billToDto.accountOwnerCode);
  }

  getCityDetails() {
    return this.orderService?.selectedShipToContact?.mailingStreet ? this.orderService.selectedShipToContact?.mailingCity
      : this.orderService?.selectedShipToContact?.street ? this.orderService.selectedShipToContact?.city
        : this.orderService.orderDto?.shipToDto?.city;
  }

  getNonTaxableSubTotal(): number {
    return this.orderService.orderDto.orderDetailDtos.filter(o => o.taxAmount === 0).map(o => o.extendedPrice).reduce((a, b) => a + b, 0);
  }

  getTaxableSubtotal(): number {
    return this.orderService.orderDto.orderDetailDtos.filter(o => o.taxAmount > 0).map(o => o.extendedPrice).reduce((a, b) => a + b, 0);
  }

  getEstimatedTax(): number {
    return this.orderService.orderDto.orderDetailDtos.map(o => o.taxAmount).reduce((a, b) => a + b, 0);
  }

  getEstimatedAmountDue(): number {
    const nonTaxableSubTotal = this.getNonTaxableSubTotal();
    const taxableSubTotal = this.getTaxableSubtotal();
    const estimatedTax = this.getEstimatedTax();
    return nonTaxableSubTotal + taxableSubTotal + estimatedTax;
  }

  getClientManagerName(): string {
    if (this.orderService.orderDto.billToDto.accountTypeCode === 'O') {
      return this.authorizationService.hasResource('OverrideSmallAccountClientManagerName') ? "Melissa Werth" : this._configService.getMyFullName();
    }
    else {
      return this._configService.getFullNameFromUserCode(this.orderService.orderDto.billToDto.accountOwnerCode);
    }
  }

}
