import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IUITKRadioGroupItem } from '@uitk/angular';
import { Mode } from '../../enums/mode.enum';
import { ConfigService } from '../../services/config.service';
import { ModeService } from '../../services/mode.service';
import { ScrollToDirective } from '../../directives/scrollTo.directive';
import { AddressStandardization } from '../../dtos/address-standardization-dto.model';
import { ShipToDto } from '../../dtos/ship-to-dto.model';
import { ZipCodeLookupDto } from '../../dtos/zip-code-lookup-dto';
import { AuthorizationService } from '../../services/authorization.service';
import { OrderService } from '../../services/order.service';
import { ShippinghandlingService } from '../../services/shippinghandling.service';
import { StandardizationService } from '../../services/standardization.service';
import { SwalAlert } from '../../helpers/alert';
import { ApprovalService } from 'src/app/services/approval.service';

@Component({
  selector: 'app-changeship-toaddress',
  templateUrl: './changeship-toaddress.component.html',
  styleUrls: ['./changeship-toaddress.component.css'],
  encapsulation: ViewEncapsulation.None
})
  
export class ChangeshipToaddressComponent implements OnInit {
  private readonly _approvalService: ApprovalService;
  private readonly _elementRef: ElementRef;
  @Output() newItemEvent = new EventEmitter<boolean>();
  @Output() addressChanged = new EventEmitter<boolean>();
  tableHeader = [
    'Select', 'City', 'State', 'Country'
  ];

  @Input()
  shipToDto: ShipToDto = new ShipToDto();
  @Input() disableAddButton = false;
  @ViewChild(ScrollToDirective) dir:any;
  myRadioGroupSelecteditem: any;
  Mode = Mode;
  shipToAddressData: ZipCodeLookupDto[] = [];
  addressEntered: AddressStandardization = new AddressStandardization();
  addressStandardized: AddressStandardization = new AddressStandardization();
  isNewBtnEnabled = false;
  zipCodeValue = '';
  swalAlert = new SwalAlert();
  items: IUITKRadioGroupItem[] = [
    {
      label: 'Address You Entered',
      value: 'AddressYouEntered',
      disabled: !this.authorizationService.hasResource("ShipToEditAddressAddressEnteredRadioButton"),

    },
    {
      label: 'Standardized Address',
      value: 'StandardizedAddress',
      disabled: !this.authorizationService.hasResource("ShipToEditAddressStandardizedAddressRadioButton"),
    },
  ];

  profileForm = new UntypedFormGroup({
    radioGroup: new UntypedFormControl('')

  });

  companyName: any;
  departmentName: any;
  addLine1: any;
  zipCode: any;
  radionameval: any;
  selectedItem: any = '';
  myDefaultButtonText: string = 'Send';
  IsChangeShiptoAdd = true;
  showZipCodeTable = false;
  showZipCodeTableError = false;


  normalDialogModal = { show: false };
  warningDialogModal = { show: false };

  showNormalDialog() {
    this.normalDialogModal.show = true;
    this.companyName = this.shipToDto.company;
    this.addLine1 = this.shipToDto.street;
    this.departmentName = this.shipToDto.department;
    this.zipCode = this.shipToDto.zip;
    let zipCodeInput = '';
    if (this.zipCode) {
      zipCodeInput = this.zipCode.split('-')[0];
    }

    this.configService.getLookupZipCode(zipCodeInput).subscribe(result => {
      this.shipToAddressData = result;
      this.zipCodeValue = this.zipCode;
      this.setDefaultshipToAddress();
      this.showZipCodeTable = true;

      this.orderService.triggerEditMethod();
    });
  }

  saveDialog() {
    this.normalDialogModal.show = false;
    this.IsChangeShiptoAdd = true;
    this.companyName = '';
    this.addLine1 = '';
    this.zipCode = '';
    this.departmentName = '';
    this.addressStandardized = new AddressStandardization();
    this.addressEntered = new AddressStandardization();
    this.showZipCodeTable = false;

    if (this.orderService.editWasTriggered) {
      setTimeout(() => {
        this.orderService.triggerSaveMethod(false);
      });
    }
  }

  cancelDialog() {
    this.normalDialogModal.show = false;
    this.IsChangeShiptoAdd = true;
    this.companyName = '';
    this.addLine1 = '';
    this.zipCode = '';
    this.departmentName = '';
    this.addressStandardized = new AddressStandardization();
    this.addressEntered = new AddressStandardization();
    this.showZipCodeTable = false;

    if (this.orderService.editWasTriggered) {
      setTimeout(() => {
        this.orderService.triggerRevertMethod();
      });
    }
  }

  constructor(
    private readonly configService: ConfigService,
    private readonly standardizationService: StandardizationService,
    private readonly shippinghandlingService: ShippinghandlingService,
    private readonly authorizationService: AuthorizationService,
    public orderService: OrderService,
    public modeService: ModeService,
    approvalService: ApprovalService,
    elementRef: ElementRef) {
      this._approvalService = approvalService;
      this._elementRef = elementRef;
     }

  ngOnInit(): void {
    this.myRadioGroupSelecteditem = this.items[1];
  }

  next() {
    if (this.addLine1 && this.zipCode) {
      this.myRadioGroupSelecteditem = this.items[1];
      this.items[1].disabled = false;
      this.addressEntered.street = this.addLine1;
      this.addressEntered.department = this.departmentName;
      const selected = this.shipToAddressData.filter(m => m.checked);
      if (selected.length > 0) {
        this.addressEntered.city = selected[0].subCity;
        this.addressEntered.state = selected[0].state;
        this.addressEntered.country = selected[0].country;
        this.addressEntered.zip = this.zipCode;
        this.standardizationService.standardizeAddress(this.addressEntered)
          .subscribe(data => {
            this.addressStandardized = data;
            this.addressStandardized.zip = this.addressStandardized.zip.substring(0, 5) + "-" + this.addressStandardized.zip.substring(5, 9)
            this.IsChangeShiptoAdd = false;
          }, error => {
            console.log(error);
            this.addressStandardized = new AddressStandardization();
            this.myRadioGroupSelecteditem = this.items[0];
            this.items[1].disabled = true;
            this.IsChangeShiptoAdd = false;
            if (error.error[Object.keys(error.error)[1]].indexOf('Invalid House/Building Number') !== -1) 
            {
              this.swalAlert.alert('Unable to standardize address - Invalid House/Building Number.');
            }
            else if (error.error[Object.keys(error.error)[1]].indexOf('Missing House/Building Number') !== -1) 
            {
              this.swalAlert.alert('Unable to standardize address - Missing House/Building Number.');
            }
            else if (error.error[Object.keys(error.error)[1]].indexOf('Invalid Zip Code') !== -1) 
            {
              this.swalAlert.alert('Unable to standardize address - Invalid Zip Code.');
            }
            else {
              this.swalAlert.alert('Unable to standardize address.');
            }
          });
      }
      else {
        this.showZipCodeTableError = true;
      }
    }
  }

  previous() {
    this.IsChangeShiptoAdd = true;
  }

  onChangezipCode(event: any) {
    this.shipToAddressData = [];
    if (event.srcElement.value.trim() !== '') {
      const zipCode = event.srcElement.value.trim().split('-')[0];
      this.configService.getLookupZipCode(zipCode).subscribe(result => {
        result.forEach(element => {
          if (element.subCity.toUpperCase() ==='APO') {
            element.subCity = element.subCity.toUpperCase();
          }
          if (element.subCity.toUpperCase() === 'FPO') {
            element.subCity = element.subCity.toUpperCase();
          }
        });
        this.shipToAddressData = result;
        this.shipToAddressData.forEach(m => {
          m.checked = false;
        });
        this.zipCodeValue = zipCode;
        this.setDefaultshipToAddress();
        this.showZipCodeTable = true;
      });
    }
  }

  onSelectionChange(subCity: string) {
    this.shipToAddressData.forEach(element => {
      element.checked = false;
      if (element.subCity === subCity) {
        element.checked = true;
        this.showZipCodeTableError = false;
      }
    });
  }

  async saveShipToAddress() {
    let selectedAddress;

    if (this.myRadioGroupSelecteditem.value === "AddressYouEntered") {
      selectedAddress = this.addressEntered;

    }
    else {
      selectedAddress = this.addressStandardized;
    }
    this.orderService.orderDto.shipToDto.company = this.shipToDto.company = this.companyName;
    this.orderService.orderDto.shipToDto.department = this.shipToDto.department = selectedAddress.department;
    this.orderService.orderDto.shipToDto.street = this.shipToDto.street = selectedAddress.street;
    this.orderService.orderDto.shipToDto.city = this.shipToDto.city = selectedAddress.city;
    this.orderService.orderDto.shipToDto.state = this.shipToDto.state = selectedAddress.state;
    this.orderService.orderDto.shipToDto.country = this.shipToDto.country = selectedAddress.country;
    const zipChanged = this.orderService.orderDto.shipToDto.zip !== selectedAddress.zip;
    this.orderService.orderDto.shipToDto.zip = this.shipToDto.zip = selectedAddress.zip;

    await this.shippinghandlingService.recalculateShippingAndHandlingAsync(this.orderService.orderDto);
    this.orderService.recalculateFields(this.orderService.orderDto);
    this.warningDialogModal.show = true;
    this.addressChanged.emit(zipChanged);
    this.saveDialog();
  }

  async closeWarningDialog() {
    this.warningDialogModal.show = false;
    const allowedShipToCountries = ['US', 'PR', 'VI', 'GU', 'MP', 'AS'];
    const allowedIndiaAndPhilipines = ['IN', 'PH'];
    if (!allowedShipToCountries.includes(this.orderService.orderDto.shipToDto.country) && this.orderService.orderDto.orderDetailDtos.length > 1) {
      if (this.authorizationService.hasResource('AllowShiptoToForeignCountries')) {
        if (!allowedIndiaAndPhilipines.includes(this.orderService.orderDto.shipToDto.country)) {
          if (this.orderService.orderDto.id !== 0 && (this.orderService.orderDto.orderStatus === 'H' || this.orderService.orderDto.orderStatus === ' ')) {
            this.swalAlert.alert('Shipping of print products to a foreign address is not allowed. Please remove/void the print products or change your shipping address to a non-foreign one.');
            return;
          }
          else {
            await this.swalAlert.alert('Shipments to foreign countries of print product is no longer allowed');
            this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item.productDto.salesOrganization !== 'Shutterfly');
            this._approvalService.refreshApprovalFields(this.orderService.orderDto);
            this.orderService.recalculateFields(this.orderService.orderDto);
            setTimeout(() => {
              if (document.querySelector('.order-summary-section')) {
                let d = document.querySelector('.order-summary-section');
                d?.scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: 'end' });
              }
            }, 500);
            return;
          }
        }
      } else {
        if (this.orderService.orderDto.id !== 0 && (this.orderService.orderDto.orderStatus === 'H' || this.orderService.orderDto.orderStatus === ' ')) {
          this.swalAlert.alert('Shipping of print products to a foreign address is not allowed. Please remove/void the print products or change your shipping address to a non-foreign one.');
          return;
        }
        else {
          await this.swalAlert.alert('Shipments to foreign countries of print product is no longer allowed');
          this.orderService.orderDto.orderDetailDtos = this.orderService.orderDto.orderDetailDtos.filter(item => item.productDto.salesOrganization !== 'Shutterfly');
          this._approvalService.refreshApprovalFields(this.orderService.orderDto);
          this.orderService.recalculateFields(this.orderService.orderDto);
          console.log("q", document.querySelector('.order-summary-section'));
          setTimeout(() => {
            if (document.querySelector('.order-summary-section')) {
              let d = document.querySelector('.order-summary-section');
              d?.scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: 'end' });
            }
          }, 500);
          return;
        }
      }
    }
  }
  closeModal(eleId:any){
    this.dir.target=eleId;
    this.dir.scrollToPosition();
  }
  newZipCode(){
    window.open(`${this.configService.salesforceUrl}/apex/NewZipCode?zip=` + this.zipCodeValue);
  }
  setDefaultshipToAddress() {
    if (this.shipToAddressData.length > 0) {
      this.shipToAddressData.forEach(m => {
        if (m.subCity === this.shipToDto.city) {
          m.checked = true;
        }
      });
      if (this.shipToAddressData.filter(m => m.checked).length === 0) {
        this.shipToAddressData[0].checked = true;
      }
    }
    else{
      if(this.authorizationService.hasResource("OrderInquiryNewZipCodeBtnEnable")){ 
        this.isNewBtnEnabled = true;
      }
      else{
        this.swalAlert.alert("Zip code is invalid, verify it was entered correctly.  If the zip code is valid send to ordermanagement@optum.com to have the zip code set up.");
      }
    }
  }
}
