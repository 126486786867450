import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ApprovalRequestsDto } from 'src/app/dtos/approval-requests-dto';
import { ApprovalService } from '../../services/approval.service';
import { ConfigService } from '../../services/config.service';
import { ModeService } from '../../services/mode.service';

@Component({
  selector: 'app-view-approval-requests',
  templateUrl: './view-approval-requests.component.html',
  styleUrls: ['./view-approval-requests.component.css']
})

export class ViewApprovalRequestsComponent implements OnInit, OnDestroy {
  configIsReadySubscription: Subscription | undefined;
  dataRetrieved = false;
  tableHeader = ['Action', 'Related To', 'Type', 'Most Recent Approver', 'Date Submitted'];

  constructor(public approvalService: ApprovalService,
    private readonly configService: ConfigService,
    public modeService: ModeService,
    private readonly router: Router) { }

  ngOnInit(): void {
    this.configIsReadySubscription = this.configService.configIsReady.pipe(first()).subscribe(async () => {
     await this.approvalService.getItemsToApproveAsync('ms\\' + this.configService.getMyMSId());
     await this.approvalService.getSplItemsToApproveAsync('ms\\' + this.configService.getMyMSId());
     await this.approvalService.getPriceCatalogItemsToApproveAsync('ms\\' +  this.configService.getMyMSId());
      this.dataRetrieved = true;
    });
    this.configService.loadConfigurations();
  }

  navigateToApproveRejectScreen(approvalRequestId: number, type: string): void {
    switch (type) {
      case ' ':
      case 'Q':
        this.router.navigate([]).then(() => {
          window.open('/approve-or-reject-withcomments/' + approvalRequestId + '?approver=' + this.configService.getMyMSId(), '_blank');
        });
        break;
      case 'R':
      case 'A':
        window.open('/approve-or-reject-withcomments/' + approvalRequestId + '?approver=' + this.configService.getMyMSId(), '_blank');
        break;
      case 'SPL':
        window.open(this.configService.priceMasterSPAUrl+ 'approve-or-reject-withcomments/'+ approvalRequestId + '?approver=' + this.configService.getMyMSId(), '_blank');
        break;
      case 'PC':
        window.open(this.configService.productMasterSPAUrl +'/approve-or-reject-withcomments/' + approvalRequestId + '?approver=' + this.configService.getMyMSId(), '_blank');
        break;

    }
  }

  navigateRelatedTo(relatedTo: string, type: string): void {
    console.log("the type is", type);
    switch (type) {
      case ' ':
      case 'Q':
        this.router.navigate([]).then(() => {
          window.open('/order-entry?orderId=' + relatedTo, '_blank');
        });
        break;
      case 'R':
      case 'A':  
        this.router.navigate([]).then(() => {
          window.open('/return/' + relatedTo + "/", '_blank');
        });
        break;
      case 'SPL':
        this.router.navigate([]).then(() => {
          window.open(this.configService.priceMasterSPAUrl + 'spl/' + relatedTo.replace('SPL_',''), '_blank');
        });

        break;
      
      case 'PC':
        this.router.navigate([]).then(() => {
          window.open(this.configService.productMasterSPAUrl + '/view/' + relatedTo, '_blank');
        });

        break;
    }
  }

  ngOnDestroy(): void {
    this.configIsReadySubscription?.unsubscribe();
  }
}
