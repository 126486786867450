import { Component, Input, OnInit, Output ,EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { Dialog } from 'src/app/helpers/dialog';
import { AccountingService } from 'src/app/services/accounting.service';


@Component({
  selector: 'app-account-comment-update',
  templateUrl: './account-comment-update.component.html',
  styleUrls: ['./account-comment-update.component.css']
})
export class AccountCommentUpdateComponent implements OnInit {
  @Input() searchList:any;
  @Input() editDialog!: Dialog<any | null>;
  @Input() isEdit:boolean = false; 
  @Output() errorvalidation = new EventEmitter<any>();
  @ViewChild('comments') commentsTxtArea: ElementRef | undefined;
  updateObj:any = {
    id:'',
    accountId:'',
    comments:''
  }
  constructor(private accountService:AccountingService) { }

  ngOnInit(): void {
    Object.assign(this.updateObj, this.searchList);
    if(this.updateObj?.accountId !== ''){
      setTimeout(()=>{
        this.commentsTxtArea?.nativeElement.focus();
      });
    }else{
      setTimeout(()=>{
      document.getElementById('account-id-textbox')?.focus();
      });
    }
  }
  
  cancelAccount(){    
    this.editDialog.close(null);
  }
  saveAccount(){
    if(this.isEdit){
      this.accountService.updateAccountComments(this.updateObj).subscribe((res)=>{    
        this.editDialog.close(this.updateObj);
      },err=>{
        this.errorvalidation.emit({closeDialog:false,error:err,accountId:this.updateObj.accountId});
      });
    }else{
      if(this.updateObj.accountId !== ''){
        this.updateObj.accountId = parseInt(this.updateObj.accountId);
        this.accountService.addAccountComments(this.updateObj).subscribe((res)=>{          
          this.editDialog.close(res);
        },err=>{
          this.errorvalidation.emit({closeDialog:false,error:err,accountId:this.updateObj.accountId});
        })
      }     
    }
    
  }

}
