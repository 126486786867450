import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { Mode } from '../../../enums/mode.enum';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { ShippingDto } from '../../../dtos/shipping-dto.model';
import { AuthorizationService } from '../../../services/authorization.service';
import { ConfigService } from '../../../services/config.service';
import { ModeService } from '../../../services/mode.service';
import { OrderService } from '../../../services/order.service';
import { TokenizeService } from '../../../services/tokenize.service';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-order-footer',
  templateUrl: './order-footer.component.html',
  styleUrls: ['./order-footer.component.css']
})

export class OrderFooterComponent implements OnInit, OnDestroy {
  private readonly _configService: ConfigService;
  private readonly _authorizationService: AuthorizationService;
  private readonly _tokenService: TokenizeService;
  @Output() addCheckOrCashRow = new EventEmitter<any>();
  @Output() changedShipVia = new EventEmitter<any>();
  configIsReadySubscription: Subscription | undefined;
  modeDisabled = true;
  isCCAuthDisabled = true;
  creditCardRequired = false;
  maxTaxRate = 0;
  isShipViaDisabled: boolean = false;
  isOrderFooterPODisabled : boolean = false;
  isOrderFooterPaymentPlanDisabled: boolean =false;
  paymentPlansList: IUITKSelectItemProps[] = []
  shippingMethods: ShippingDto[] = [];
  Mode = Mode;
  methodList: IUITKSelectItemProps[] = []
  ccAuthList: IUITKSelectItemProps[] = []
  termsList: IUITKSelectItemProps[] = []
  termsListWithoutCreditCard: IUITKSelectItemProps[] = []
  isPaymentPlan = false;
  orderIsReadySubscription: Subscription | undefined;
  expirationDate = "";
  expDatePattern = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;
  showExpDatePatternError = false;
  isPastDate: boolean = false;
  returnsData: OrderDetailDto[] = [];
  orderPaymentPlanBinding: string = '';
  orderPaymentMethodBinding: string | null = '';
  orderPaymentPlan: string = '';
  orderPaymentTerms = '';
  disablePaymentTerms = false;
  hasResourcePaymentMethodSOrN = false;
  hasResourcePaymentMethodC = false;
  hasResourceEditPaymentMethodC = false;
  private readonly creditCardText = 'Credit Card';
  swalAlert = new SwalAlert();

  constructor(
    public orderService: OrderService,
    public modeService: ModeService,
    configService: ConfigService,
    authorizationService: AuthorizationService,
    tokenService: TokenizeService
  ) {
    this._configService = configService;
    this._authorizationService = authorizationService;
    this._tokenService = tokenService;
  }

  ngOnInit(): void {
    this.configIsReadySubscription = this._configService.configIsReady.subscribe(() => {
      if (!this._configService.reference) {
        throw new Error('References are not loaded.');
      }
      this.hasResourcePaymentMethodSOrN = this._authorizationService.hasResource('OrderInquiryPaymentMethodSOrN');
      this.hasResourcePaymentMethodC = this._authorizationService.hasResource('OrderInquiryPaymentMethodC');
      this.hasResourceEditPaymentMethodC = this._authorizationService.hasResource('OrderInquiryEditPaymentMethodC');
      this.shippingMethods = this._configService.reference.shippingDtos;
      this.methodList = this._configService.getPickList("PMETH");
      this.ccAuthList = this._configService.getPickList("CCAUTH");
      this.paymentPlansList = this._configService.getPickList("PAYMNTPLAN");
      this.termsList = this._configService.getPickList("PTERMS").sort((a, b) => {
        return parseInt(a.value.slice(3, 6)) < parseInt(b.value.slice(3, 6)) ? -1 : 1
      });
      this.termsListWithoutCreditCard = this._configService.getPickList("PTERMS")
        .filter(x => x.value !== 'CC').sort((a, b) => {
          return parseInt(a.value.slice(3, 6)) < parseInt(b.value.slice(3, 6)) ? -1 : 1
        });
      if (!this._authorizationService.hasResource("OrderInquiryPaymentMethodSOrN")) {
        this._configService.disablePickListOption(this.methodList, "S");
        this._configService.disablePickListOption(this.methodList, "N");
      }

      if (!this._authorizationService.hasResource("OrderInquiryPaymentMethodC")) {
        this._configService.disablePickListOption(this.methodList, "C");
      }

      if (!this._authorizationService.hasResource("OrderInquiryPaymentMethodCreditCard")) {
        this._configService.disablePickListOption(this.methodList, "V");
        this._configService.disablePickListOption(this.methodList, "M");
        this._configService.disablePickListOption(this.methodList, "D");
        this._configService.disablePickListOption(this.methodList, "X");
      }

      if (!this._authorizationService.hasResource("OrderInquiryPaymentMethodI")) {
        this._configService.disablePickListOption(this.methodList, "I");
      }

      this.disablePaymentTerms = !this._authorizationService.hasResource("OrderFooterTermsDropDown");
      this.isShipViaDisabled = !this._authorizationService.hasResource("OrderFooterShipViaDropDown");
      this.isOrderFooterPODisabled = !this._authorizationService.hasResource("OrderFooterPOTextBox");
      this.isOrderFooterPaymentPlanDisabled = !this._authorizationService.hasResource("OrderFooterPaymentPlansDropDown");


    });
    this.orderIsReadySubscription = this.orderService.orderIsReady.subscribe(() => {
      this.orderPaymentPlanBinding = this.orderService.orderDto.paymentPlan;
      this.orderPaymentMethodBinding = this.orderService.orderDto.paymentMethod;
      this.orderPaymentPlan = this.orderService.orderDto.paymentPlan;
      this.orderService.orderDto.lastShipmentMethod = this.orderService.orderDto.shipmentMethod;
      if (this.orderService.orderDto.paymentTerms)
      {
        this.orderPaymentTerms = this.orderService.orderDto.paymentTerms;
      }
      this.returnsData = JSON.parse(JSON.stringify(this.orderService.orderDto.orderDetailDtos));
      this.modeDisabled = (this.orderService.orderDto.paymentMethod === 'V'
        || this.orderService.orderDto.paymentMethod === 'M'
        || this.orderService.orderDto.paymentMethod === 'D'
        || this.orderService.orderDto.paymentMethod === 'X') ? false : true;
      if (!this._authorizationService.hasResource("OrderFooterCCAuthDAuthorizedOption")){
        this._configService.disablePickListOption(this.ccAuthList, "A");
      }        
      this._configService.disablePickListOption(this.ccAuthList, "D");
      if (this._authorizationService.hasResource("OrderFooterCCAuthD")){
          if(this.orderService.orderDto.ccAuthorizationCode==='A'){
            this.isCCAuthDisabled = true;
          }
          else{
            this.isCCAuthDisabled = false;
          }
      }
      this.orderService.recalculateFields(this.orderService.orderDto);

      if (this.orderService.orderDto.billToDto.accountTypeCode === "C"||this.orderService.orderDto.billToDto.accountTypeCode === "H") {
        this._configService.disablePickListOption(this.paymentPlansList, "Monthly");
        this._configService.disablePickListOption(this.paymentPlansList, "Quarterly");
      }

      if (this.orderService.orderDto.ccExpirationDate && this.orderService.orderDto.ccExpirationDate !== '') {
        this.expirationDate = this.orderService.orderDto.ccExpirationDate.substring(0, 2) + '/' + this.orderService.orderDto.ccExpirationDate.substring(2, 5);
      }
    });
    this._configService.loadConfigurations();
  }

  async onChangePaymentPlanValue() {
    await this.callCashReceiptBR();
    await this.orderService.paymentPlanBR();
  }

  async callCashReceiptBR() {
    const cash = this.orderService.cashReceiptBR('', this.orderService.orderDto.paymentMethod, this.orderPaymentPlanBinding);
    if (cash === true) {
      await this.swalAlert.alert('Cash Receipts are not allowed on a payment plan');
    }
  }

  onShipViaChange(selected: string) {
    setTimeout(() => this.changedShipVia.emit());
  }
  onChangeIsRush(event: any): void {
    this.orderService.orderDto.isRush = event.value;
  }
  async onChangePaymentMethod(): Promise<void> {
    if (!this._authorizationService.hasResource("OrderFooterCCAuthDAuthorizedOption")) {
      this._configService.disablePickListOption(this.ccAuthList, "A");
    }

    this._configService.disablePickListOption(this.ccAuthList, "D");
    
    if (!this._authorizationService.hasResource("OrderFooterCCAuthD")) {
      this.isCCAuthDisabled = true;
    }
    else {
      this.isCCAuthDisabled = false;
    }

    if ((this.orderService.orderDto.paymentPlan === "Monthly" || this.orderService.orderDto.paymentPlan === "Quarterly")
      && (this.orderService.orderDto.paymentMethod === "C" || this.orderService.orderDto.paymentMethod === "N")) {
      await this.orderService.paymentPlanBR();
    }
    this.setPTermsOnChangePMethod();
    if (this.orderService.orderDto.paymentMethod === 'C') {
      this.addCheckOrCashRow.emit();
    }

    if ((((this.orderService.orderDto.paymentMethod === 'V'
      || this.orderService.orderDto.paymentMethod === 'M'
      || this.orderService.orderDto.paymentMethod === 'D'
      || this.orderService.orderDto.paymentMethod === 'X') && this.orderService.orderDto.ccAuthorizationCode === 'N') ||
      this.orderService.orderDto.paymentMethod === 'I') && this.orderService.orderDto.orderStatus === 'H') {
        if(this.orderService.orderDto.holdReasonCode==='1')//credit card is declined 
        {
          this.orderService.orderDto.orderStatus = ' ';
          this.orderService.orderDto.holdReasonCode = null;
        }     
      this.orderService.recalculateFields(this.orderService.orderDto);
    }
  }

  setPTermsOnChangePMethod() {
    if (this.orderService.orderDto.paymentMethod === 'V'
      || this.orderService.orderDto.paymentMethod === 'M'
      || this.orderService.orderDto.paymentMethod === 'D'
      || this.orderService.orderDto.paymentMethod === 'X') {
      this.orderService.orderDto.paymentTerms = this.creditCardText;
      this.orderService.orderDto.ccAuthorizationCode = 'N';
      this.modeDisabled = false;
    }
    else {
      this.orderService.orderDto.paymentTerms = this.orderPaymentTerms;
      
      if (this.orderService.orderDto.paymentTerms === this.creditCardText) {
        this.orderService.orderDto.paymentTerms = this.orderService.orderDto.billToDto.defaultPaymentTerms;
      }
      
      this.orderService.orderDto.ccToken = null;
      this.orderService.orderDto.ccExpirationDate = null;
      this.expirationDate = '';
      this.orderService.orderDto.ccLastFour = null;
      this.orderService.orderDto.ccAuthorizationCode = null;
      this.modeDisabled = true;
    }
  }
  expirationdate() {

    if(this.orderService.orderDto.holdReasonCode==='1')//credit card is declined 
        {
          this.orderService.orderDto.orderStatus = ' ';
          this.orderService.orderDto.holdReasonCode = null;
        }
        
    if (this.expirationDate.includes('/')) {
      this.orderService.orderDto.ccExpirationDate = this.expirationDate.replace("/", "");
    }
    else {
      if (this.expirationDate === '')
      {
        this.orderService.orderDto.ccExpirationDate = null;
      }
      else
      {
        this.orderService.orderDto.ccExpirationDate = this.expirationDate;
        this.expirationDate = this.orderService.orderDto.ccExpirationDate.substring(0, 2) + '/' + this.orderService.orderDto.ccExpirationDate.substring(2, 5);
      }
    }
  }

  expDateChange(expirationDate: string) {
    if (expirationDate !== "" && expirationDate !== null && !new RegExp(this.expDatePattern).test(expirationDate)) {
      this.showExpDatePatternError = true;
    } else {
      this.showExpDatePatternError = false;
    }

    if (expirationDate !== "" && expirationDate !== null && expirationDate.length === 4) {
      this.isPastDate = false;
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = parseInt(new Date().getFullYear().toString().substring(2, 4));
      const expYear = parseInt(expirationDate.substring(2, 4));
      const expMonth = parseInt(expirationDate.substring(0, 2));
      if (expYear < currentYear) {
        this.isPastDate = true;
      }
      else if (expYear === currentYear && expMonth < currentMonth) {
        this.isPastDate = true;
      }
      else {
        this.isPastDate = false;
      }
    }
    else {
      this.isPastDate = false;
    }
  }

  onChangeCCAuthorization() {
    if (this.orderService.orderDto.ccAuthorizationCode === 'N' && this.orderService.orderDto.orderStatus === 'H') {
      this.orderService.orderDto.orderStatus = ' ';
      this.orderService.orderDto.holdReasonCode = null;
      this.orderService.recalculateFields(this.orderService.orderDto);
    }
  }

  tokenChange() {

    if(this.orderService.orderDto.holdReasonCode==='1')//credit card is declined 
        {
          this.orderService.orderDto.orderStatus = ' ';
          this.orderService.orderDto.holdReasonCode = null;
        }

    if (this.orderService.orderDto.ccToken !== "")
    {
      this.creditCardRequired = false;
    }
    else
    {
      this.creditCardRequired = true;
    }


  }

  async onNgModelTokenChange(encryptedCCString: string): Promise<boolean> {

    if ((encryptedCCString.length === 157 || encryptedCCString.length === 158 || encryptedCCString.length === 177 || encryptedCCString.length === 178)
      && encryptedCCString.endsWith('03')) {
      const equalSign = encryptedCCString.indexOf('=');
      this.orderService.orderDto.ccLastFour = encryptedCCString.substring(equalSign - 4, equalSign);
      const expirationDate = encryptedCCString.substring(equalSign + 1, equalSign + 5);
      const firstSet = encryptedCCString.split("?")[0].split("=");
      const secondSet = firstSet[0].split(";");
      const ccNumber = secondSet[1];

      switch (ccNumber.substring(0, 1)) {
        case "3":
          this.orderService.orderDto.paymentMethod = this.methodList.find(pmeth => pmeth.label === "American Express")?.value!;
          break;
        case "4":
          this.orderService.orderDto.paymentMethod = this.methodList.find(pmeth => pmeth.label === "Visa")?.value!;
          break;
        case "5":
          this.orderService.orderDto.paymentMethod = this.methodList.find(pmeth => pmeth.label === "MasterCard")?.value!;
          break;
        case "6":
          this.orderService.orderDto.paymentMethod = this.methodList.find(pmeth => pmeth.label === "Discover")?.value!;
          break;
        default:
          break;
      }
      await this.onChangePaymentMethod();
      setTimeout(() => {
        this.orderService.orderDto.ccToken = 'Processing, please wait ...';
      });
      setTimeout(() => {
        this._tokenService.getCcToken({ encryptedData: encryptedCCString }).subscribe((data) => {
          if (data.token !== null) {
            this.orderService.orderDto.ccToken = data.token;
            this.orderService.orderDto.ccExpirationDate = expirationDate.substring(2, 4) + expirationDate.substring(0, 2);
            this.expirationDate = expirationDate.substring(2, 4) + '/' + expirationDate.substring(0, 2);
          }
          else {
            this.orderService.orderDto.ccExpirationDate = null;
            this.orderService.orderDto.ccLastFour = "";
            this.expirationDate = '';
            this.creditCardRequired = true;
            this.orderService.orderDto.ccToken = "";
            this.swalAlert.alert('There was an error in fetching the token');
          }
        })
      });
    } else if (encryptedCCString.length === 6) {
      if (this.orderService.orderDto.paymentMethod === 'V' ||
        this.orderService.orderDto.paymentMethod === 'M' ||
        this.orderService.orderDto.paymentMethod === 'D' ||
        this.orderService.orderDto.paymentMethod === 'X') {
        this.orderService.orderDto.paymentTerms = this.creditCardText;
        this.orderService.orderDto.ccAuthorizationCode = 'N';
      }
    }

    return true;
  }

  onPaymentMethChange() {
    this.orderService.orderDto.paymentMethod = 'C';
  }
  ngOnDestroy(): void {
    this.configIsReadySubscription?.unsubscribe();
    this.orderIsReadySubscription?.unsubscribe();
  }
}
