export class Dialog<T> {
  show = false;
  resolve!: (value: T) => void;

  async openAsync(): Promise<T> {
    const result = await new Promise(resolve => {
      this.show = true;
      this.resolve = resolve;
    });
    return result as T;
  }

  close(result: T): void {
    this.show = false;
    this.resolve(result);
  }
}
