<uitk-dialog uitkId="SubscriptionDialog" id="SubscriptionDialog" [(showModal)]="SubscriptionDialog.show"
    [closeBtn]="false">
    <uitk-dialog-content style="overflow-x: hidden;">
        <uitk-dialog-header>
            Terms greater than 2 are not allowed on trials, the subscription type has been changed to a new
            subscription.
        </uitk-dialog-header>
        <div class="row mt-3">
            <div class="col-12 d-flex justify-content-center" style="padding-top: 70px;">
                <button type="button" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"
                    (click)="ok()">
                    Ok
                </button>
            </div>
        </div>
    </uitk-dialog-content>
</uitk-dialog>