<div class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog uitkId="viewMultiYearOrders" [(showModal)]="dialog.show" [closeBtn]="true">
  <uitk-dialog-header>
    <div cdkDragHandle>
      MultiYear Orders
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div class="row mt-3">
      <div class="uitk-c-table--responsive container">
        <table uitkTable [dataSource]="multiYearOrdersData" id="multi-year-orders-table" class="uitk-c-table"
          aria-describedby="multi-year-orders-data">
          <thead class="uitk-c-table__head">
            <tr>
              <th *ngFor="let headerName of tableHeader" scope="col">
                {{ headerName}}
              </th>
            </tr>
          </thead>
          <tbody id="multi-year-orders-data" class="uitk-c-table__body">
            <ng-container *uitkTableRowBody="let data">
              <tr class="uitk-c-table__row">
                <td>
                  <a target="_blank" [routerLink]="!isSnapshot?['/order-entry']: ['/order-entry/snapshot']"
                    [queryParams]="{orderId: data.orderId}">{{ data.orderId }}</a>
                </td>
                <td>{{ data.yearOfOrder }} </td>
                <td>{{ getOrderStatus(data.orderStatus) }}</td>
              </tr>
            </ng-container>
            <div *ngIf="this.multiYearOrdersData.length === 0">No records found</div>
          </tbody>
        </table>
      </div>
    </div>
  </uitk-dialog-content>
</uitk-dialog>
  </div>
</div>
