<div *ngIf="impersonateDialogModal.show" class = "overlayForPopups">
  <div cdkDrag>
<uitk-dialog [(showModal)]="impersonateDialogModal.show" id="impersonateDialog" uitkId="impersonateDialog" [closeBtn]=false
  triggerElementId="impersonateDialog_openBtn">
  <uitk-dialog-header style="letter-spacing: 0.3px; font-size: 20px; line-height: 48px;">
    <div cdkDragHandle>
      Select the Profile you would like to impersonate
      <hr>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div>
      <uitk-radio-group [itemList]="items" id="rdlist" [(ngModel)]="impersonateValue"></uitk-radio-group>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" [disabled]="!impersonateValue" (click)="impersonate(impersonateValue)" appAutoFocus>
      Impersonate
    </button>
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="closeImpersonateDialog()">
      Cancel
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
  </div>
</div>