import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUITKSelectItemProps } from '@uitk/angular';
import { Subscription } from 'rxjs';
import { OrderDetailDto } from '../../../dtos/order-detail-dto.model';
import { ProductDto } from '../../../dtos/product-dto.model';
import { ConfigService } from '../../../services/config.service';
import { OrderService } from '../../../services/order.service';
import { FeeAnalyzerDto } from '../../../dtos/fee-analyzer-dto.model';
import { GeozipDto } from '../../../dtos/geozip-dto.model';
import { GeozipGrid } from '../../../dtos/geozip-grid.model';
import { SpecialitiesDto } from '../../../dtos/specialities-dto.model';
import { SwalAlert } from '../../../helpers/alert';

@Component({
  selector: 'app-fee-analyzer',
  templateUrl: './fee-analyzer.component.html',
  styleUrls: ['./fee-analyzer.component.css']
})



export class FeeAnalyzerComponent implements OnInit {
  private readonly INSURANCE_COMPANY: string = 'Insurance Company';
  private readonly HMO_IPA_PHO_PPO_IDN: string = 'HMO, IPA, PHO, PPO, IDN';
  private readonly CONSULTANT_FOR_PAYER_INSURANCE_COMPANY_FRA: string = 'Consultant for Payer/Insurance CompanyFRA';
  private readonly _configService: ConfigService;
  configIsReadySubscription: Subscription | undefined;
  @Input() orderDetail: OrderDetailDto = new OrderDetailDto();
  @Output() closeEvent = new EventEmitter<any>();
  @Output() enableSave = new EventEmitter<boolean>();
  @Input() productDto: ProductDto = new ProductDto();
  feeAnalyzerDto: FeeAnalyzerDto = new FeeAnalyzerDto();
  geoZipDtoIsReadySubscription: Subscription | undefined;
  geoZipDto: GeozipDto = new GeozipDto();
  geoZipGridData: GeozipGrid[] = [];
  index: number = 0;
  modalItem: GeozipGrid = new GeozipGrid();
  getSpecialitiesIsReadySubscription: Subscription | undefined;
  specialityinfoList: SpecialitiesDto[] = [];
  specialityDescriptionList: IUITKSelectItemProps[] = [];
  selectedSpecialityInDropDown: IUITKSelectItemProps | undefined = { id: 'Select', label: 'Select', value: 'Select' };
  hasWrongZipVal = false;
  hasWrongSpeciality = false;
  zipCodePattern = /^\d+$/;
  orgInfoText: string = 'What type of organization will be using this information?';
  companyNameText: string = 'Account name as it appears on products';
  companyName: string = '';
  resellerText: string = 'To whom are you reselling?';
  geoZips: string = 'GeoZips';
  speciality: string = 'Specialty';
  hasnonReseller = false;
  hasReseller = false;
  resellerSelected = false;
  payerSelected = false;
  zipCodeAdded = false;
  organizationAdded = false;
  organizationName = '';
  zip: string = 'Zip Code';
  selectedItem: string = '';
  codeType: string = '';
  zipCode: string = '';
  specialityVal: string = '';

  geoZipVal: string = '';
  organizationInfoList = [
    { label: 'Physician - Clinic, Multiple Physician Organization', value: 'Clinic, Multiple Physician Organization' },
    { label: 'Physician - Single Physician', value: 'Single Physician' },
    { label: 'Physician - Consultant for Physician', value: 'Consultant for Physician' },
    { label: 'Physician - Billing Company for Physician', value: 'Billing Company for Physician' },
    { label: 'Physician - Managememnt Services Organization (MSO)', value: 'Managememnt Services Organization (MSO)FRA' },
    { label: 'Payer - Insurance Company', value: this.INSURANCE_COMPANY },
    { label: 'Payer - HMO, IPA, PHO, PPO, IDN', value: this.HMO_IPA_PHO_PPO_IDN },
    { label: 'Payer - Consultant for Payer/Insurance Company', value: this.CONSULTANT_FOR_PAYER_INSURANCE_COMPANY_FRA },
    { label: 'Hospital - Hospital', value: 'Hospital' },
    { label: 'Hospital - Physician Hosp. Org. (PHO)', value: 'Physician Hosp. Org. (PHO)' },
    { label: 'Hospital - Consultant for Hospital', value: 'Consultant for Hospital' },
    { label: 'Reseller', value: 'Reseller' },

  ];
  organizationInfo = { id: 'Select', label: 'Select', value: 'Select' };

  tableHeader = [
    { name: 'Action', id: 'action' },
    { name: 'Speciality', id: 'speciality' },
    { name: 'Zip Code', id: 'zipcode' },
    { name: 'GeoZip', id: 'geozip' },
  ];
  swalAlert = new SwalAlert();

  constructor(configService: ConfigService, public orderService: OrderService) {
    this._configService = configService;
  }

  ngOnInit(): void {
    this.enableSave.emit((this.orderDetail.feeAnalyzerDto?.geoZip ?? '') !== '');
    this.organizationInfoList
        .sort((a, b) => a.label < b.label ? -1 : 1);

    this.orderDetail.productDto.configSetDtos.forEach(element => {
      if (element.configSet.includes('CFA_DEN') || element.configSet.includes('CFA_MED')) {
        this.codeType = `${element.configSet.substring(4, 7)}`;
      }
      else {
        this.codeType = "MED";
      }
    });

    this.companyName = this.orderDetail.feeAnalyzerDto?.company || '';
    if (this.companyName === '') {
      if (this.orderService.orderDto.shipToDto.company !== '' && this.orderService.orderDto.shipToDto.company !== null) {
        this.companyName = this.orderService.orderDto.shipToDto.company;
      }
      else {
        this.companyName = this.orderService.orderDto.billToDto.company;
      }
    }
    this.zipCode = this.orderDetail.feeAnalyzerDto?.zip || '';
    this.getZipCode();
   
    this.setupDataOnDtoReady();   
    this._configService.getSpecialitiesDetails(this.orderDetail.productId);
  }

  getZipCode(){
    if (this.zipCode === '') {
      if (this.orderService.orderDto.shipToDto.zip !== '' && this.orderService.orderDto.shipToDto.zip !== null) {
        this.zipCode = `${this.orderService.orderDto.shipToDto.zip.substring(0, 5)}`;
      }
      else {
        this.zipCode = `${this.orderService.orderDto.billToDto.zip.substring(0, 5)}`;
      }
    }
  }

  setupDataOnDtoReady(){
    if (this.orderDetail.shipDate !== null) {
      this.hasnonReseller = true;
      this.hasReseller = true;
    }

    if (this.orderDetail.feeAnalyzerDto !== null) {
      if (this.orderDetail.feeAnalyzerDto.zip !== '') {
        this.organizationAdded = true;
      }
    }
    this.geoZipDtoIsReadySubscription = this._configService.geoZipDtoIsReady.subscribe(() => {
      this.geoZipDto = this._configService.geoZipDto;
      const modalItem = new GeozipGrid();
      this.index++
      modalItem.id = this.index;
      modalItem.zipCode = this.zipCode;

      modalItem.speciality = this.selectedSpecialityInDropDown?.label ?? '';
      modalItem.geoZip = this.geoZipDto.geozip;
      this.geoZipGridData.push(modalItem);
      this.geoZipGridData = [...this.geoZipGridData];
      this.zipCodeAdded = true;

      if (this.orderDetail.feeAnalyzerDto !== null) {
        this.feeAnalyzerDto.includeDataFile = this.orderDetail.feeAnalyzerDto.includeDataFile;
        this.feeAnalyzerDto.productType = this.orderDetail.feeAnalyzerDto.productType;
      }

      this.feeAnalyzerDto.zip = this.zipCode;
      this.feeAnalyzerDto.specialty = this.selectedSpecialityInDropDown?.id ?? '';
      this.feeAnalyzerDto.description = this.selectedSpecialityInDropDown?.label ?? '';
      this.feeAnalyzerDto.geoZip = this.geoZipDto.geozip;
      this.feeAnalyzerDto.company = this.companyName;
      this.enableSave.emit(true);

    }, error => {
      this.swalAlert.alert('Error occurred while getting geozip');
    });


    this.getSpecialitiesIsReadySubscription = this._configService.getSpecialitiesDtoIsReady.subscribe(data => {

      this.specialityinfoList = [...this._configService.specialtiesDto];
      this.specialityDescriptionList = this._configService.convertSpecialitiesToUitkProps(this.specialityinfoList);
      if (this.orderDetail.feeAnalyzerDto !== null) {
        const picklistVal = this.orderDetail.feeAnalyzerDto.specialty;
        this.selectedSpecialityInDropDown = this.specialityDescriptionList.filter(x => x.id === picklistVal)[0] ?? { id: 'Select', label: 'Select', value: 'Select' };

        const modalItem = new GeozipGrid();
        modalItem.id = 0;
        modalItem.zipCode = this.orderDetail.feeAnalyzerDto.zip;
        modalItem.speciality = this.selectedSpecialityInDropDown?.label;
        modalItem.geoZip = this.orderDetail.feeAnalyzerDto.geoZip;
        this.feeAnalyzerDto = this.orderDetail.feeAnalyzerDto;

        this.geoZipGridData.push(modalItem);
        this.geoZipGridData = [...this.geoZipGridData];
        this.zipCodeAdded = true;

      }
    });
  }
  clickadd(): void {
    if (this.selectedSpecialityInDropDown?.label === 'Select') {
      this.hasWrongSpeciality = true;
    }
    else {
      this.hasWrongSpeciality = false;
    }
    if ((this.zipCode.length === 3 || this.zipCode.length === 5) && new RegExp(this.zipCodePattern).test(this.zipCode)) {
      this.hasWrongZipVal = false;
    }
    else {
      this.hasWrongZipVal = true;
    }
    if (this.hasWrongZipVal === false && this.hasWrongSpeciality === false) {
      this._configService.getGeoZipDetails(this.codeType, this.zipCode);
    }

  }
  onDeleteClick(record: any): void {
    const deleteRecordIndex = this.geoZipGridData.findIndex(i => i.id === record.id)
    this.geoZipGridData.splice(deleteRecordIndex, 1);
    this.geoZipGridData = [...this.geoZipGridData];
    this.zipCodeAdded = false;
    this.feeAnalyzerDto = new FeeAnalyzerDto();
    this.enableSave.emit(false);
  }

  onChangeOrganizationCode(event: any) {

    this.selectedItem = event.value.value;
    if (event.value === '') {
      this.hasReseller = false;
      this.hasnonReseller = false;
      this.resellerSelected = false;
      this.payerSelected = false;
    }
    else {
      this.organizationName = this.selectedItem;

      if (this.selectedItem === 'Reseller') {
        this.hasnonReseller = false;
        this.hasReseller = true;
        this.resellerSelected = true;
        this.payerSelected = false;

      }
      else if (this.selectedItem === this.INSURANCE_COMPANY 
        || this.selectedItem === this.HMO_IPA_PHO_PPO_IDN 
        || this.selectedItem === this.CONSULTANT_FOR_PAYER_INSURANCE_COMPANY_FRA) {
        this.payerSelected = true;
        this.resellerSelected = false;
        this.hasnonReseller = false;

      }
      else if (this.selectedItem !== 'Reseller' && this.selectedItem !== 'none' && event.value !== '' && this.selectedItem !== this.INSURANCE_COMPANY 
      && this.selectedItem !== this.HMO_IPA_PHO_PPO_IDN && this.selectedItem !== this.CONSULTANT_FOR_PAYER_INSURANCE_COMPANY_FRA) {
        this.hasReseller = false;
        this.hasnonReseller = true;
        this.resellerSelected = false;
        this.payerSelected = false;
      }
    }
  }

  onChangeSpecialityVal(event: any) {
    if (event.value !== '') {
      this.specialityVal = event.value.id;
      this.selectedSpecialityInDropDown = this.specialityDescriptionList.filter(x => x.id === this.specialityVal)[0];
      this.feeAnalyzerDto.specialty = this.selectedSpecialityInDropDown.id;
      this.feeAnalyzerDto.description = this.selectedSpecialityInDropDown.label;
    }
  }

  save(): boolean {
    if (this.zipCode ==='') {
      this.swalAlert.alert('FeeAnalyzer must contain a geozip before saving.');
      return false;
    }

    if(this.orderDetail.productDto.configSetDtos.some(c=>c.configSet.startsWith('CFA_MED'))){
      this.feeAnalyzerDto.productType='M';
    }
    else {
      this.feeAnalyzerDto.productType='D';
    }

    const index = this.orderService.orderDto.orderDetailDtos.findIndex(i => i.id === this.orderDetail.id);
    if (index > -1) {
      this.orderService.orderDto.orderDetailDtos[index].feeAnalyzerDto = this.feeAnalyzerDto;
    }
    else {
      const tempOrderDetailDto: OrderDetailDto = JSON.parse(JSON.stringify(this.orderDetail));
      tempOrderDetailDto.id = this.orderService.getNextOrderDetailId();
      tempOrderDetailDto.feeAnalyzerDto = this.feeAnalyzerDto;
      tempOrderDetailDto.orderId = this.orderService.orderDto.id;
      this.orderService.orderDto.orderDetailDtos.unshift(tempOrderDetailDto);

      this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
    }

    return true;
  }

  ngOnDestroy() {
    this.configIsReadySubscription?.unsubscribe();
    this.geoZipDtoIsReadySubscription?.unsubscribe();
    this.getSpecialitiesIsReadySubscription?.unsubscribe();
  }
}
