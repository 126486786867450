<div class="row mt-3">
  <div class="uitk-c-panel__header" style="display: -webkit-inline-box;">
    <div class="col-12 d-flex">
      <span
        style="font-size: 24px; font-family: Arial-BoldMT, Arial Bold, Arial, sans-serif; font-weight: 700;font-style: normal">
        Approval Requirements
      </span>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-6 me-5" d-flex>
    <div class="row">
      <div class="col-4 d-flex">
        <label class="align-self-center label" uitkLabel>Approval Required</label>
      </div>
      <div class="col-8 d-flex">
        <span class="align-self-center">{{ _approvalService.orderApprovalInputDto.approvalRequired }}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-3">
        <div class="row">
          <div class="col-7 d-flex">
            <label class="align-self-center label me-0 pe-0" uitkLabel>Allowed %</label>
          </div>
          <div class="col-5 container-fluid px-1">
            <uitk-form-field>
              <input uitkInput id="allowedPercentage" class="textbox mx-1 px-1 text-end" type="text"
                name="allowedPercentage"
                [ngModel]="_approvalService.orderApprovalInputDto.allowedPercentage / 100 | percent:'1.2-2'"
                (ngModelChange)="_approvalService.orderApprovalInputDto.allowedPercentage=$event" disabled />
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-5">
        <div class="row">
          <div class="col-5 pe-1 d-flex">
            <label class="align-self-center label" uitkLabel>Total Ext Price</label>
          </div>
          <div class="col-7 container-fluid  px-1">
            <uitk-form-field>
              <input uitkInput id="totalExtPrice" class="textbox mx-1 text-end" type="text" name="totalExtPrice"
                [ngModel]="_approvalService.orderApprovalInputDto.totalExtendedPrice | currency:'USD':'symbol':'1.2-2'"
                (ngModelChange)="_approvalService.orderApprovalInputDto.totalExtendedPrice=$event" tabindex="-1" disabled/>
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-4 d-flex">
            <label class="align-self-center label" uitkLabel>Margin</label>
            
          </div>
          <!-- <p>1234{{_approvalService.orderApprovalInputDto.margin}}</p> -->
          <div class="col-8 container-fluid px-1">
  
            <uitk-form-field>
              <input uitkInput id="margin" class="textbox mx-1 text-end" type="text" name="margin"
                [ngModel]="_approvalService.orderApprovalInputDto.margin | currency:'USD':'symbol':'1.2-2'"
                (ngModelChange)="_approvalService.orderApprovalInputDto.margin=$event" 
                [ngClass]="_approvalService.orderApprovalInputDto.margin<0 ?'marginnegative':'marginpositive'" disabled/>
            </uitk-form-field>
            
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3 mx-3">
      <div class="col">&nbsp;&nbsp;<br /></div>
    </div>
    <div class="row mt-3">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Below Floor Price</label>
      </div>
      <div class="col-9 container-fluid">
        <uitk-form-field>
          <input uitkInput id="belowFloorPrice" [(ngModel)]="_approvalService.orderApprovalInputDto.itemsBelowFP"
            class="textbox mx-3" type="text" name="belowFloorPrice" tabindex="-1" disabled/>
        </uitk-form-field>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-3 d-flex">
        <label class="align-self-center label" uitkLabel>Over List Price</label>
      </div>
      <div class="col-9 container-fluid">
        <uitk-form-field>
          <input uitkInput id="overListPrice" [(ngModel)]="_approvalService.orderApprovalInputDto.itemsAboveLP"
            class="textbox mx-3" type="text" name="overListPrice" tabindex="-1" disabled/>
        </uitk-form-field>
      </div>
    </div>
  </div>
  <div class="col-5 me-5 pe-5" d-flex>
    <div uitkTableContainer>
      <table uitkTable [dataSource]="_approvalService.approvalsList" id="approval-requirements-approvals-table">
        <thead>
          <tr>
            <th *ngFor="let headerName of approvalsTableHeader" scope="col" id={{headerName.id}}
              [style.text-align]="headerName.align">
              {{ headerName.name }}
            </th>
          </tr>
        </thead>
        <tbody id="approval-requirements-approvals-table-body">
          <ng-container *uitkTableRowRenderer="let approval">
            <tr>
              <td><strong>{{ approval.approvalLevel }}</strong></td>
              <td>{{ approval.approver }}</td>
              <td class="text-end">{{ approval.threshold / 100 | percent:'1.2-2' }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
