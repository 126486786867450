import { Component, HostListener, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { OrderDetailDto } from "src/app/dtos/order-detail-dto.model";
import { ModeService } from 'src/app/services/mode.service';
import { OrderService } from "src/app/services/order.service";
import { PricingService } from "src/app/services/pricing.service";
import { ShippinghandlingService } from "src/app/services/shippinghandling.service";
import { OrderGridComponent } from "./order-grid/order-grid.component";
import { OrderComponent } from "./order/order.component";
import { OrderFooterComponent } from "./order-footer/order-footer.component";
import { Title } from "@angular/platform-browser";
import { SourceType } from "../../enums/source-type.enum";
import { SwalAlert } from '../../helpers/alert';
@Component({
  selector: 'app-order-inquiry',
  templateUrl: './order-inquiry.component.html',
  styleUrls: ['./order-inquiry.component.css']
})
export class OrderInquiryComponent {
  private readonly _route: ActivatedRoute;
  private readonly _pricingService: PricingService;
  private readonly _shippinghandlingService: ShippinghandlingService;
  private readonly _titleService: Title;
  @ViewChild('orderHeader') orderHeader: OrderComponent | undefined;
  @ViewChild('orderGrid') orderGrid: OrderGridComponent | undefined;
  @ViewChild(OrderFooterComponent) orderFooterComponent: OrderFooterComponent | undefined;
  swalAlert = new SwalAlert();
  @HostListener('window:scroll', [])
  @HostListener('window:resize', [])
  onResize() {
    if(window.innerHeight - 135 + window.scrollY < 880){
      this.rightPanelHeight = window.innerHeight - 135 + window.scrollY;
    }
  }
  ngAfterViewInit(){
    this.rightPanelHeight = window.innerHeight - 135 + window.scrollY;
  }
  rightPanelHeight = window.innerHeight - 135 + window.scrollY;
  scrollY = 0;
  enableEditMode = false;
  showPackingSlipNotesDialog = { show: false }
  enableShippingChargeWarning = false;

  constructor(
    public modeService: ModeService,
    route: ActivatedRoute,
    public orderService: OrderService,
    pricingService: PricingService,
    shippinghandlingService: ShippinghandlingService,
    titleService: Title
  ) {
    this._route = route;
    this._pricingService = pricingService;
    this._shippinghandlingService = shippinghandlingService;
    this._titleService = titleService;

    this._route.queryParams.subscribe(params => {
      console.log('params.EditMode' + params.EditMode);
      if (params.EditMode === 'true') {
        this.enableEditMode = true;
        console.log("this.enableEditMode" + this.enableEditMode);
      }
      this._titleService.setTitle("Order: "+this._route.snapshot.params.orderId);
      if(this._route.snapshot.routeConfig?.path === "order/:orderId"){
          document.querySelectorAll('.uitk-c-global-nav__panel-trigger').forEach(ele=>{
            if(ele.classList.contains('uitk-c-global-nav__panel-trigger--selected') && ele.innerHTML.trim() !== 'Orders'){
              ele.classList.remove('uitk-c-nav__panel-trigger--selected','uitk-c-global-nav__panel-trigger--selected');
            }
            if(ele.innerHTML.trim() == 'Orders'){
              ele.classList.add('uitk-c-nav__panel-trigger--selected','uitk-c-global-nav__panel-trigger--selected');
            }
          });
      }
    });
  }

  adjustedLineItem(orderDetailDto: OrderDetailDto | null) {
    this.changeShippingAndHandlingBR('ItemAdjusted', orderDetailDto);
    this.orderHeader?.recalculateMultiYearVisible();
  }
  async validateIfShippingItemExist(): Promise<OrderDetailDto[]> {
    let shippingAndHandlingItems;
    shippingAndHandlingItems = this.orderService.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus === ' ' &&
      item.productDto.classCode === 'SH' && item.productCode !== this.orderService.orderDto.orderDetailDtos[0].productCode);
    if (shippingAndHandlingItems.length > 1) {
      await this.swalAlert.alert('Multiple Ship items cannot be added. Please select another item');
      this.orderService.deleteOrVoidOrderDetail(shippingAndHandlingItems[0]);
    }

    const duplicateShippingAndHandlingItems = this.orderService.orderDto.orderDetailDtos.filter(item => item.orderDetailStatus === ' ' &&
      item.productDto.classCode === 'SH' && item.productCode === this.orderService.orderDto.orderDetailDtos[0].productCode);
    if (duplicateShippingAndHandlingItems.length > 1) {
      await this.swalAlert.alert('Cannot add a duplicate open shipping item to an order');
      this.orderService.deleteOrVoidOrderDetail(duplicateShippingAndHandlingItems[0]);
    }
    return shippingAndHandlingItems;
  }
  getVariables(): [boolean, boolean, boolean, boolean, boolean] {
    const isAlaskaHawaii = this.orderService.orderDto.shipToDto.country === 'US' &&
      (this.orderService.orderDto.shipToDto.state?.trim() === 'AK' ||
        this.orderService.orderDto.shipToDto.state?.trim() === 'HI');
    const domesticStates = 'AL,AK,AZ,AR,CA,CO,CT,DE,DC,FL,GA,HI,ID,IL,IN,IA,KS,KY,LA,ME,MD,MA,MI,MN,MS,MO,MT,' +
      'NE,NV,NH,NJ,NM,NY,NC,ND,OH,OK,OR,PA,RI,SC,SD,TN,TX,UT,VT,VA,WA,WV,WI,WY';
    const isApoFpo = (this.orderService.orderDto.shipToDto.city?.toUpperCase()?.substring(0, 3) === 'APO' ||
      this.orderService.orderDto.shipToDto.city?.toUpperCase()?.substring(0, 3) === 'FPO');
    const isInternational = (this.orderService.orderDto.shipToDto?.country !== 'US' ||
      domesticStates.indexOf(this.orderService.orderDto.shipToDto.state?.trim()) === -1) && !isApoFpo;;
    const isPoBox = this.orderService.orderDto.shipToDto.street?.replace('.', '')?.replace(' ', '')?.toUpperCase()?.includes('POBOX') ?? false;
    const isUsps = (isApoFpo || isPoBox) && !isInternational;
    return [isAlaskaHawaii, isApoFpo, isInternational, isPoBox, isUsps];
  }

  async shippingAndHandlingBROnChangeContactAddress(shippingOrderDetail: OrderDetailDto, isInternational: boolean, isPoBox: boolean, isUsps: boolean, isAlaskaHawaii: boolean) {
    let productCode = shippingOrderDetail ? shippingOrderDetail.productCode : '0053';
    let shipViaChanged = false;

    if (isInternational && isPoBox) {
      await this.swalAlert.alert('A PO Box cannot be used when shipping to a foreign address.');
    }

    if (this.orderService.orderDto.shipmentMethod === 'DONOTSHIP' || this.orderService.orderDto.shipmentMethod === 'MISC') {
      // Do nothing in this situation.
    }  else if (shippingOrderDetail && this.orderService.orderDto.shipmentMethod !== 'USPS' && isUsps) {
      productCode = '0053';
      await this.orderService.reproductOrderDetail(shippingOrderDetail, productCode);
      this.updateItemDiscount(shippingOrderDetail);
      shipViaChanged = true;
      await this.swalAlert.alert(`Ship via has been updated to USPS.`);
    } else if (shippingOrderDetail && this.orderService.orderDto.shipmentMethod !== 'UPS-INT' && shippingOrderDetail.productCode !== '0052' && isInternational) {
      productCode = '0052';
      await this.orderService.reproductOrderDetail(shippingOrderDetail, productCode);
      this.updateItemDiscount(shippingOrderDetail);
      shipViaChanged = true;
      await this.swalAlert.alert(`Ship via has been updated to UPS-INT.`);
      // Launch the config set for international shipping
      this.orderService.clickConfiguration(shippingOrderDetail);
      //Stoping the order to perform Save while shipping configset is opened
      this.orderService.editWasTriggered = false;
    } else if (shippingOrderDetail && this.orderService.orderDto.shipmentMethod !== 'UPS-AK/HI' && shippingOrderDetail.productCode !== '0045' && isAlaskaHawaii) {
      productCode = '0045';
      await this.orderService.reproductOrderDetail(shippingOrderDetail, productCode);
      this.updateItemDiscount(shippingOrderDetail);
      shipViaChanged = true;
      await this.swalAlert.alert(`Ship via has been updated to UPS-AK/HI.`);
    } else {
      [productCode, shipViaChanged] = await this.shippingAndHandlingBROnChangeContactAddressContinuation(isInternational, isUsps, isAlaskaHawaii, shippingOrderDetail, productCode, shipViaChanged);
    }

    this.orderService.setShipmentMethod(productCode, isUsps);
    this.orderService.setRushCheckBox(shipViaChanged);

    this._shippinghandlingService.inProcess = false;
    await this._shippinghandlingService.recalculateShippingAndHandlingAsync(this.orderService.orderDto);
    this.orderService.recalculateFields(this.orderService.orderDto);
  }
  async shippingAndHandlingBROnChangeContactAddressContinuation(isInternational: boolean, isUsps: boolean, isAlaskaHawaii: boolean, shippingOrderDetail: OrderDetailDto, productCode: string, shipViaChanged: boolean): Promise<[string, boolean]> {
    if (shippingOrderDetail && this.orderService.orderDto.shipmentMethod === 'UPS-INT' && shippingOrderDetail.productCode === '0052' && !isInternational) {
      productCode = '0053';
      await this.orderService.reproductOrderDetail(shippingOrderDetail, productCode);
      this.updateItemDiscount(shippingOrderDetail);
      shipViaChanged = true;
      await this.swalAlert.alert(`Ship via has been updated to UPS Ground.`);
    } else if (shippingOrderDetail && this.orderService.orderDto.shipmentMethod === 'UPS-AK/HI' && shippingOrderDetail.productCode === '0045' && !isAlaskaHawaii) {
      productCode = '0053';
      await this.orderService.reproductOrderDetail(shippingOrderDetail, productCode);
      this.orderService.orderDto.shipmentMethod = 'UPS';
      this.updateItemDiscount(shippingOrderDetail);
      shipViaChanged = true;
      await this.swalAlert.alert(`Ship via has been updated from UPS-AK/HI to UPS Ground.`);
    } else if (shippingOrderDetail && this.orderService.orderDto.shipmentMethod === 'USPS' && !isUsps) {
      productCode = '0053';
      await this.orderService.reproductOrderDetail(shippingOrderDetail, productCode);
      this.updateItemDiscount(shippingOrderDetail);
      shipViaChanged = true;
      await this.swalAlert.alert(`Ship via has been updated from USPS to UPS Ground.`);
    }
    return [productCode, shipViaChanged];
  }
  async shippingAndHandlingBROnChangeItemAdjusted(shippingOrderDetail: OrderDetailDto, hasOpenPhysicalItems: boolean, isUsps: boolean, isInternational: boolean, isAlaskaHawaii: boolean, orderDetail: OrderDetailDto | null) {
    if (shippingOrderDetail && !hasOpenPhysicalItems) {
      this.orderService.deleteOrVoidOrderDetail(shippingOrderDetail);
      this.orderService.recalculateFields(this.orderService.orderDto);
      this.orderService.setShipmentMethod('0053', isUsps);
    }
    else if (this.orderService.orderDto.shipmentMethod === 'DONOTSHIP' || this.orderService.orderDto.shipmentMethod === 'MISC') {
      // DO NOTHING
    }else if (!shippingOrderDetail && hasOpenPhysicalItems && (this.orderService.orderDto.billToDto.accountTypeCode !== 'C')) {
      await this.checkProductIsUsps(isUsps, isInternational, isAlaskaHawaii, orderDetail);
     
    } else if (shippingOrderDetail && orderDetail && orderDetail.isPhysical) {
      await this.checkShippingOrderDetail(); 
    }
  }
  async checkShippingOrderDetail() {
    if (this.orderService.orderDto.shipmentMethod === 'UPS-INT') {
      await this.swalAlert.alert('Update international shipping and handling charges manually.');
    } else {
      await this._shippinghandlingService.recalculateShippingAndHandlingAsync(this.orderService.orderDto);
      this.orderService.recalculateFields(this.orderService.orderDto);

    }
  }
  async checkProductIsUsps(isUsps: boolean, isInternational: boolean, isAlaskaHawaii: boolean, orderDetail: OrderDetailDto | null) {
    let productCode = '';
    if (isUsps) {
      productCode = '0053';
    } else if (isInternational) {
      productCode = '0052';
    } else if (isAlaskaHawaii) {
      productCode = '0045';
    } else {
      productCode = '0053';
    }

    this.orderService.setShipmentMethod(productCode, isUsps);
    this.orderService.setRushCheckBox(false);
    await this.updatenewOrderDetail(productCode);
   
  }
  async updatenewOrderDetail(productCode: string) {
    const newOrderDetail = this.orderService.createNewOrderDetail(this.orderService.orderDto, productCode);
    newOrderDetail.orderQuantity = 1;
    newOrderDetail.openQuantity = 1;
    newOrderDetail.orderDetailStatus = ' ';
    newOrderDetail.itemEditable = false;
    newOrderDetail.discount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, newOrderDetail.productDto.id);

    this.orderService.orderDto.orderDetailDtos.splice(1, 0, newOrderDetail);
    if (this.orderService.orderDto.shipmentMethod === 'UPS-INT') {
      await this.swalAlert.alert('Manually calculation of international shipping charges is required.');
    } else {
      await this._shippinghandlingService.recalculateShippingAndHandlingAsync(this.orderService.orderDto);
      this.orderService.recalculateFields(this.orderService.orderDto);
    }
  }
  async shippingAndHandlingBROnChangeShippingItemAdded(shippingOrderDetail: OrderDetailDto, hasOpenPhysicalItems: boolean, isUsps: boolean): Promise<boolean> {

    const shippingItemCount = this.orderService.orderDto.orderDetailDtos.filter(item => item.productDto.classCode === 'SH' && item.orderDetailStatus === ' ').length;

    if (shippingItemCount > 1) {
      await this.swalAlert.alert('Multiple ship items cannot be added. Please select another item.');
      this.orderService.deleteOrVoidOrderDetail(shippingOrderDetail);
      this.orderService.recalculateFields(this.orderService.orderDto);
      return false;
    }

    if (!hasOpenPhysicalItems) {
      await this.swalAlert.alert('There are no open shippable items on the order.');
      this.orderService.deleteOrVoidOrderDetail(shippingOrderDetail);
      this.orderService.recalculateFields(this.orderService.orderDto);
      return false;
    }

    this.orderService.setShipmentMethod(shippingOrderDetail?.productCode, isUsps);
    this.orderService.setRushCheckBox(false);
    return true;
  }
  async shippingAndHandlingBROnChangeShipVia(isPoBox: boolean, isApoFpo: boolean, isInternational: boolean, isUsps: boolean, isAlaskaHawaii: boolean, shippingOrderDetail: OrderDetailDto, hasOpenPhysicalItems: boolean) : Promise<boolean>{
    let productCode = '';
    if (shippingOrderDetail?.orderDetailStatus === 'C')
      return false;
    if (this.orderService.orderDto.shipmentMethod === 'MISC') {
      this.showPackingSlipNotesDialog.show = true;
    } else if (this.orderService.orderDto.shipmentMethod === 'DONOTSHIP') {
      // DO NOTHING
    } else if (this.orderService.orderDto.shipmentMethod === 'USPS' && isPoBox) {
      productCode = '0053';
    }
    else{
     productCode = await this.checkBROnChangeShipVia(isPoBox, isApoFpo, isInternational, isUsps, isAlaskaHawaii, productCode);
    }
  
    this.checkShipmethod(productCode, shippingOrderDetail, hasOpenPhysicalItems);
    return true;
  }
  async checkBROnChangeShipVia(isPoBox: boolean, isApoFpo: boolean, isInternational: boolean, isUsps: boolean, isAlaskaHawaii: boolean, productCode: string) {
    if (this.orderService.orderDto.shipmentMethod !== 'UPS-AK/HI' && isAlaskaHawaii) {
     
      await this.swalAlert.alert('UPS-AK/HI must be used when shipping to Alaska or Hawaii.');
      this.orderService.orderDto.shipmentMethod = 'UPS-AK/HI';
    }
    else if (this.orderService.orderDto.shipmentMethod === 'USPS' && !isPoBox && !isApoFpo) {
      await this.swalAlert.alert('USPS can only be used when shipping to a PO Box or APO/FPO.');
      this.orderService.orderDto.shipmentMethod = this.orderService.orderDto.lastShipmentMethod;
    }
    else {
      productCode = await this.shippingAndHandlingBROnChangeShipViaContinuation(isInternational, isAlaskaHawaii, productCode, isPoBox, isApoFpo, isUsps);
    }
    return productCode;
  }
  async checkShipmethod(productCode: string, shippingOrderDetail: OrderDetailDto, hasOpenPhysicalItems: boolean) {
    if (!shippingOrderDetail) {
      if (hasOpenPhysicalItems && productCode !== '') {
        const newOrderDetail = this.orderService.createNewOrderDetail(this.orderService.orderDto, productCode);
        newOrderDetail.orderQuantity = 1;
        newOrderDetail.openQuantity = 1;
        newOrderDetail.orderDetailStatus = ' ';
        newOrderDetail.itemEditable = false;
        this.orderService.orderDto.orderDetailDtos.push(newOrderDetail);
        shippingOrderDetail = newOrderDetail;
      }
      
      else {
        return false;
      }
    }

    this.orderService.setRushCheckBox(true);
    if (productCode) {
      await this.orderService.reproductOrderDetail(shippingOrderDetail, productCode);
      this.updateItemDiscount(shippingOrderDetail);
    }
    await this._shippinghandlingService.recalculateShippingAndHandlingAsync(this.orderService.orderDto);
    this.orderService.recalculateFields(this.orderService.orderDto);
    return true;
  }
  async shippingAndHandlingBROnChangeShipViaContinuation(isInternational: boolean, isAlaskaHawaii: boolean, productCode: string, isPoBox: boolean, isApoFpo: boolean, isUsps: boolean): Promise<string> {
    if ((this.orderService.orderDto.shipmentMethod === 'UPS' ||
      this.orderService.orderDto.shipmentMethod === 'UPS-ONE' ||
      this.orderService.orderDto.shipmentMethod === 'UPS-TWO') && !isPoBox && isApoFpo && isUsps) {
      await this.swalAlert.alert('USPS can only be used when shipping to APO/FPO.');
      this.orderService.orderDto.shipmentMethod = this.orderService.orderDto.lastShipmentMethod;
    }
    if (this.orderService.orderDto.shipmentMethod === 'UPS-INT' && !isInternational) {
      await this.swalAlert.alert('UPS-INT can only be used when shipping internationally.');
      this.orderService.orderDto.shipmentMethod = this.orderService.orderDto.lastShipmentMethod;
    } else if (this.orderService.orderDto.shipmentMethod === 'UPS-AK/HI' && !isAlaskaHawaii) {
      await this.swalAlert.alert('UPS-AK/HI can only be used when shipping to Alaska or Hawaii.');
      this.orderService.orderDto.shipmentMethod = this.orderService.orderDto.lastShipmentMethod;
    }
    else if (this.orderService.orderDto.shipmentMethod !== 'UPS-INT' && isInternational) {
      await this.swalAlert.alert('UPS-INT must be used when shipping internationally.');
      this.orderService.orderDto.shipmentMethod = this.orderService.orderDto.lastShipmentMethod;
    }
    else {
      productCode = this.setProductCodeOnShipViaChange();
    }

    if (this.orderService.orderDto.shipmentMethod !== 'MISC') {
      this.orderService.orderDto.lastShipmentMethod = this.orderService.orderDto.shipmentMethod;
    }
    return productCode;
  }

  setProductCodeOnShipViaChange(): string{
    let productCode = '';
    if (this.orderService.orderDto.shipmentMethod === 'UPS-ONE') {
      productCode = '0050';
    } else if (this.orderService.orderDto.shipmentMethod === 'UPS-TWO') {
      productCode = '0051';
    } else if (this.orderService.orderDto.shipmentMethod === 'UPS') {
      productCode = '0053';
    } else if (this.orderService.orderDto.shipmentMethod === 'USPS') {
      productCode = '0053';
    }
    else if (this.orderService.orderDto.shipmentMethod === 'UPS-INT') {
      productCode = '0052';
    }
    return productCode;
  }
  async changeShippingAndHandlingBR(change: string, orderDetail: OrderDetailDto | null) {
    this.orderService.sourceType = SourceType.OrderInquiry;
    // Remove duplicate open shipping and handling charges if they exist.
    let shippingAndHandlingItems;
    this.enableShippingChargeWarning = false;
    do {
      shippingAndHandlingItems = await this.validateIfShippingItemExist();
    } while (shippingAndHandlingItems.length > 1)
    // Setup variables to help with if conditions
    const [isAlaskaHawaii, isApoFpo, isInternational, isPoBox, isUsps] = this.getVariables()
    const hasOpenPhysicalItems = this.orderService.orderDto.orderDetailDtos.some(item => item.isPhysical && item.orderDetailStatus === ' ');
    let shippingOrderDetail = this.orderService.orderDto.orderDetailDtos.filter(item => item.productDto.classCode === 'SH' && item.orderDetailStatus !== 'V')[0];

    if (this.orderService.orderDto.lastShipmentMethod === null || this.orderService.orderDto.lastShipmentMethod === undefined) {
      this.orderService.orderDto.lastShipmentMethod = this.orderService.orderDto.shipmentMethod;
    }
    if (change === 'ContactAddress') {
      await this.shippingAndHandlingBROnChangeContactAddress(shippingOrderDetail, isInternational, isPoBox, isUsps, isAlaskaHawaii);
    } 
    else if (change === 'ItemAdjusted') {
      await this.shippingAndHandlingBROnChangeItemAdjusted(shippingOrderDetail, hasOpenPhysicalItems, isUsps, isInternational, isAlaskaHawaii, orderDetail);
    } 
    else if (change === 'ShippingItemAdded') {
      if(!await this.shippingAndHandlingBROnChangeShippingItemAdded(shippingOrderDetail, hasOpenPhysicalItems, isUsps)){
        return;
      }
    } else if (change === 'ShipVia') {
      if(!await this.shippingAndHandlingBROnChangeShipVia(isPoBox, isApoFpo, isInternational, isUsps, isAlaskaHawaii, shippingOrderDetail, hasOpenPhysicalItems)){
        return;
      }
    }
    this.orderService.isShippingError = false;
    if (this._shippinghandlingService.isError) {
      this.orderService.isShippingError = true;
    }
    this.orderService.orderDto.orderDetailDtos = [...this.orderService.orderDto.orderDetailDtos];
  }

  revertShipViaValue() {
    this.orderService.orderDto.shipmentMethod = this.orderService.orderDto.lastShipmentMethod;
  }

  savePackingSlipNotes() {
    this.orderService.orderDto.lastShipmentMethod = this.orderService.orderDto.shipmentMethod;
  }

  changePaymentMethode() {
    this.orderFooterComponent?.onPaymentMethChange();
  }
  updateItemDiscount(orderDetail: OrderDetailDto) {
    const newDiscount = this._pricingService.calculateAccountDiscount(this.orderService.orderDto.billToDto, orderDetail.productId);
    if (newDiscount > orderDetail.discount) {
      orderDetail.discount = newDiscount;
    }
  }
}
