export class TaxInputDto {
  orderId = 0;
  orderDetailId = 0;
  taxClass = '';
  productDescription = '';
  price = 0.00;
  accountId = 0;
  company = '';
  street = '';
  city = '';
  state = '';
  zip = '';
  country = '';
  productCode = '';
}
