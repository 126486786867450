<div class = "overlayForPopups">
    <div cdkDrag>
<uitk-dialog uitkId="showItemSearchDialog" id="AccountSearchDialog" [(showModal)]="AccountSearch.show" [closeBtn]="false">
  
    <uitk-dialog-content id="ContactDialog1">       
        <div class="container-fluid">
        <uitk-page-notification
        uitkId="error-msg-example"
        [(visible)]="visible"
        pageNotificationType="error"
        triggerElementId="error-msg-trigger"
        animationType="fadeAnimation"
        >
            <span>{{ myNotificationErrorContent }}</span>
        </uitk-page-notification>     
       <form #accountForm="ngForm">
        <uitk-panels [isAccordion]="true" [headingLevel]="headingLevel.h2">
            <uitk-panel uitkId="TestPanel1" [open]="isPanelOpen" (openChange)="onOpenChange($event)">
                <uitk-panel-header-title>
                    <div cdkDragHandle>
                    <div class="row mt-3" uitkPanelTitleText>
                        <div class="col-6 d-flex">                           
                                <uitk-dialog-header>
                                    <h3><b>Account Search</b></h3>
                                </uitk-dialog-header>                            
                        </div>
                        <div class="col-6 d-flex justify-content-end">
                            <div class="col-7 d-flex justify-content-end" style="font-size: 18px;">
                                <span class="uitk-icon uitk-icon-add sm-icon" id="addLink"></span>
                                <span class="link" id="addlink1">
                                    <a href="javascript:void(0)" (click)="navigateToSFAddAccount()"> Add </a>
                                 </span>&nbsp;&nbsp;
                                 <button type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs"
                                (click)="closepopup()">Close</button>                    
                            </div>
                        </div>
                    </div>
                </div>
                </uitk-panel-header-title>
                <uitk-panel-content>
                    <div>
                        <div class="row formRow">
                            <div class="col-6 d-flex contentStart">
                                <div class="col-3" >
                                    <label class="align-self-center label" uitkLabel style="font-size: 18px;">Account ID</label>
                                </div>
                                <div class="col-7">
                                    <uitk-form-field>
                                        <input uitkInput  id="accountid" name="accountid" class="textbox" type="number"  [attr.tabindex]="21" [(ngModel)]="accountSearchObj.accountId"/>
                                    </uitk-form-field>
                                </div>
                            </div>
                            <div class="col-6 d-flex stateSection contentEnd">
                                <div class="col-3">
                                    <label class="align-self-center label" uitkLabel style="font-size: 18px;">State</label>
                                </div>
                                <div class="col-7">                       
                                    <div id="stateDropDown" *ngIf="(accountSearchObj.country && accountSearchObj.country?.value !== 'US');else stateDropDown">
                                        <uitk-form-field>
                                            <input uitkInput id="state"  class="textbox" type="text" [(ngModel)]="accountSearchObj.stateNonUS" tabindex="25" minlength="2" name="stateInput" #stateInput="ngModel" pattern="^[A-Z a-z.\-]*$"/>
                                            <uitk-form-field-error *ngIf="(stateInput?.errors?.minlength && stateInput?.touched)">
                                                Minimun 2 Characters required
                                            </uitk-form-field-error>
                                            <uitk-form-field-error *ngIf="stateInput?.errors?.pattern">
                                                {{getCityErrorMessage(stateInput)}}
                                             </uitk-form-field-error>
                                        </uitk-form-field>
                                    </div>
                                    <ng-template #stateDropDown>
                                        <div id="stateDropDown">
                                            <uitk-form-field>
                                            <uitk-select uitkId="state" name="state" class="verticaltab" [itemList]="stateList" [(ngModel)]="accountSearchObj.stateUS" tabindex="25" placeholder="Select"></uitk-select>
                                            </uitk-form-field>
                                        </div>
                                    </ng-template>
                                </div>                    
                            </div>
                        </div>
                        <div class="row formRow">
                            <div class="col-6 d-flex contentStart">
                                <div class="col-3">
                                    <label uitkLabel style="font-size: 18px;">Account Name</label>
                                </div>
                                <div class="col-7">
                                    <uitk-form-field>
                                        <input uitkInput id="accountname"  class="textbox" type="text" minlength="3" [(ngModel)]="accountSearchObj.accountName" name="accountNameInput"  [attr.tabindex]="22" #accountNameInput="ngModel" pattern="^[A-Z a-z 0-9,.&\/\-\'\(\)\*\@#+\:\[\]\`]*$"/>
                                        <uitk-form-field-error *ngIf="(accountNameInput?.errors?.minlength && accountNameInput?.touched)">
                                            Minimun 3 Characters required
                                        </uitk-form-field-error>
                                        <uitk-form-field-error *ngIf="accountNameInput?.errors?.pattern">
                                             {{getAccountNameErrorMessage(accountNameInput)}}                                   
                                         </uitk-form-field-error>                                
                                    </uitk-form-field>
                                </div>
                            </div>
                            <div class="col-6 d-flex contentEnd">
                                <div class="col-3">
                                    <label uitkLabel style="font-size: 18px;">Zip Code</label>
                                </div>
                                <div class="col-7">
                                    <uitk-form-field>
                                        <input uitkInput id="zipcode"  class="textbox" type="text" minlength="3"  [(ngModel)]="accountSearchObj.zipCode"  [attr.tabindex]="26" name="zipInput" #zipInput="ngModel" pattern="^[0-9\-]*$"/>
                                        <uitk-form-field-error *ngIf="(zipInput?.errors?.minlength && zipInput?.touched)">
                                            Minimun 3 Characters required
                                        </uitk-form-field-error>
                                        <uitk-form-field-error *ngIf="zipInput?.errors?.pattern">
                                            {{getZipErrorMessage(zipInput)}}
                                         </uitk-form-field-error>
                                    </uitk-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row formRow">
                            <div class="col-6 d-flex contentStart">
                                <div class="col-3">
                                    <label class="align-self-center label" style="font-size: 18px;">Street Address</label>
                                </div>
                                <div class="col-7">
                                    <uitk-form-field>
                                        <input uitkInput id="street" class="textbox" type="text" minlength="3" [(ngModel)]="accountSearchObj.streetAddress" name="streetInput"  [attr.tabindex]="23" #streetInput="ngModel" pattern="^[A-Z a-z 0-9 #.,/]*$"/>
                                        <uitk-form-field-error *ngIf="(streetInput?.errors?.minlength && streetInput?.touched)">
                                            Minimun 3 Characters required
                                        </uitk-form-field-error>
                                        <uitk-form-field-error *ngIf="streetInput?.errors?.pattern">
                                            {{getStreetErrorMessage(streetInput)}}
                                         </uitk-form-field-error>
                                    </uitk-form-field>
                                    
                                </div>
                            </div>
                            <div class="col-6 d-flex countrySection contentEnd">
                                <div class="col-3">
                                    <label uitkLabel style="font-size: 18px;">Country</label>
                                </div>
                                <div class="col-7">                        
                                    <uitk-form-field>
                                        <uitk-select uitkId="country"  name="country" class="verticaltab" [itemList]="countries"  [attr.tabindex]="27" [(ngModel)]="accountSearchObj.country"></uitk-select>
                                    </uitk-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row formRow">
                              <div class="col-6 d-flex contentStart">
                                <div class="col-3">
                                    <label uitkLabel style="font-size: 18px;">City</label>
                                </div>
                                <div class="col-7">
                                    <uitk-form-field>
                                        <input uitkInput id="city"  class="textbox" type="text" minlength="3" [(ngModel)]="accountSearchObj.city" name="[cityInput]"  [attr.tabindex]="24" #cityInput="ngModel" pattern="^[A-Z a-z.\-]*$"/>
                                        <uitk-form-field-error *ngIf="(cityInput?.errors?.minlength && cityInput?.touched)">
                                            Minimun 3 Characters required
                                        </uitk-form-field-error>
                                        <uitk-form-field-error *ngIf="cityInput?.errors?.pattern">
                                            {{getCityErrorMessage(cityInput)}}
                                        </uitk-form-field-error>
                                    </uitk-form-field>
                                </div>
                            </div>
                            <div class="col-6 d-flex contentEnd">
                                <div class="col-3">
                                    <label class="align-self-center label" uitkLabel style="font-size: 18px;">Phone</label>
                                </div>
                                <div class="col-7 phoneSection">
                                    <uitk-form-field>
                                        <input uitkInput id="phone"  class="textbox" type="text"  minlength="3" [(ngModel)]="accountSearchObj.phone"  [attr.tabindex]="28" name="phoneInput" #phoneInput="ngModel" pattern="^[0-9\/\-]*$"/>
                                        <uitk-form-field-error *ngIf="(phoneInput?.errors?.minlength && phoneInput?.touched)">
                                            Minimun 3 Characters required
                                        </uitk-form-field-error>
                                        <uitk-form-field-error *ngIf="phoneInput?.errors?.pattern">
                                            {{getPhoneErrorMessage(phoneInput)}}
                                         </uitk-form-field-error>
                                    </uitk-form-field>
                                </div>
                                
                            </div>
                        </div>
                    
                        <div class="row mt-1"></div>
                        <div class="row mt-3">
                            <div class="col-12 d-flex justify-content-center">
                                <button type="button" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="search(accountForm)" [attr.tabindex]="29">Search</button>
                                <a href="javascript:void(0)" class="clearBtn" (click)="clearForm()" [attr.tabindex]="30">Clear</a>
                            </div>
                        </div>
                    </div>
                </uitk-panel-content>
            </uitk-panel>
        </uitk-panels>
        </form>
     <div class="row mt-1"></div>
     <div id="contactTable" >
        <div class="row mt-3">
            <div class="uitk-c-table--responsive container" [ngClass]="isPanelOpen ? 'initialHeight':'maxHeight'">
                <table uitkTable id="account-search-details-table" class="uitk-c-table"
                    aria-describedby="account-search-details-data"  [dataSource]="searchList">
                    <thead class="uitk-c-table__head">
                        <tr class="headerMainColumns">
                            <th scope="col" colspan="4" style="width:32%;text-align: center;border-right: 1px solid #002677;">
                               Account
                            </th>
                            <th scope="col" colspan="3" style="width:32%;text-align: center;border-right: 1px solid #002677;">
                                Contact
                             </th>
                             <th scope="col" colspan="2" style="width:36%;text-align: center">
                                Search
                             </th>
                        </tr>
                        <tr uitkTableSort #sortAccountSearchTable="uitkTableSort" class="headerSubColumns">
                            <th *ngFor="let headerName of tableHeader" scope="col" uitkTableSortColumn
                            [column]="headerName.id"  (sortChange)="onSortChange($event)" [style.width]="headerName.width">
                                {{ headerName.name}}                              
                            </th>

                        </tr>
                    </thead>
                    <tbody id="account-search-details-data" class="uitk-c-table__body">
                        <tr *ngIf="showLoaderFlag" class="uitk-c-table__row">
                            <td colspan="7">
                                <app-loader> </app-loader>
                            </td>
                            
                        </tr>                       
                        <ng-container *uitkTableRowBody="let row">
                            <tr class="uitk-c-table__row" (click)="onAccountIdClick(row.accountId)">
                                <td>
                                    {{row.accountStatus}}
                                </td>
                                <td class="accountIdLink">
                                    <a href="javascript:void(0)">{{row.accountId}}</a>
                                </td>
                                <td class="textTransform">
                                    {{row.accountName}}
                                </td>
                                <td class="textTransform">
                                    {{row.accountType}}
                                </td>
                                <td>
                                    {{row.contactStatus}}
                                </td>
                                <td>
                                    {{row.contactId}}
                                </td>
                                <td class="textTransform">
                                    {{row.contactName}}
                                </td>
                                <td class="textTransform">
                                    {{row.foundText}}
                                </td>
                                <td class="textTransform">
                                    {{row.location}}
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="searchList?.data?.length === 0 && !showLoaderFlag">
                            <tr>
                                <td colspan="9">
                                    <div>No Results found</div>
                                </td>
                            </tr>
                        </ng-container>  
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    </div>  
    </uitk-dialog-content>
</uitk-dialog>
    </div>
</div>
