<div class="footer fixed-bottom py-3">
  <button id="editBtn" type="button" (click)="edit()"
          class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
          [disabled]="orderService.orderDto.orderStatus==='P' ||orderService.orderDto.orderStatus === 'M'|| modeService.mode === Mode.Edit || modeService.isWaiting">
    Edit
  </button>
  <button id="saveBtn" type="button"
    class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
    [disabled]="modeService.mode !== Mode.Edit || modeService.isWaiting || multiYearPreviousYearOrder" (click)="saveBtnClick()">
    Save
  </button>
  <button id="saveCloneBtn" type="button"
    class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
    [disabled]="modeService.mode !== Mode.Edit || modeService.isWaiting || orderService.orderDto.multiYearDtos.length > 0" (click)="saveCloneBtnClick()">
    Save & Clone
  </button>
  <button id="multiYearBtn" type="button"
    class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
    [disabled]="modeService.mode !== Mode.Edit || modeService.isWaiting || orderService.disableMultiYearBtn === true || multiYearPreviousYearOrder" (click)="multiYearBtnClick()">
    Multi Year
  </button>
  <button id="saveAsDraftBtn" type="button"
    class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
    [disabled]="modeService.mode !== Mode.Edit || modeService.isWaiting" (click)="saveAsDraftBtnClick()">
    Save as Draft
  </button>
  <button id="calculateBtn" type="button"
    class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
    [disabled]="disableCalculateBtn || modeService.mode !== Mode.Edit || modeService.isWaiting" (click)="calculateBtnClick()">
    Calculate
  </button>
  <button id="cfdBtn" type="button"
  class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="cfdBtnClick()" 
    [disabled]="(orderService.orderDto.id <= 0 || orderService.orderDto.orderType !== 'Q' || orderService.orderDto.orderStatus !== ' ' || modeService.mode === Mode.Edit || modeService.isWaiting)">
    CFD
  </button>
  <button id="cancelBtn" type="button"
    class="uitk-c-button uitk-c-button--alternative-ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
    [disabled]="modeService.mode !== Mode.Edit || modeService.isWaiting" (click)="cancelBtnClick()">
    Cancel
  </button>
  <button id="convertQuotebtn" type="button"
    class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
    appEnableResource="OrderEntryFooterConvertQuote"
    [disabled]="orderService.orderDto.orderStatus==='P' ||orderService.orderDto.orderStatus === 'M'||orderService.orderDto.orderStatus==='D'||orderService.orderDto.orderStatus==='V'||orderService.orderDto.orderStatus==='R'|| modeService.mode === Mode.Edit || modeService.isWaiting || orderService.orderDto.orderType !== 'Q'" (click)="convertQuoteBtnClick()">
    Convert Quote
  </button>
  <button id="VoidOrderButton" type="button"
  class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
   
  [disabled]="orderService.orderEntryFlag || orderService.orderDto.orderStatus==='V' " (click)="voidOrderDialog.show = true"> 

   Void
</button> 
</div>
<div *ngIf="voidOrderDialog.show">
  <app-void-order [dialog]="voidOrderDialog"></app-void-order> 
</div>

<div *ngIf="orderLevelOffersOthersDialog.show">
  <app-order-level-offers-others [orderLevelOffersOthersDialog]="orderLevelOffersOthersDialog"></app-order-level-offers-others>
</div>

<div *ngIf="orderLevelTierOfferDialog.show">
  <app-order-level-offers-tiers [orderLevelTierOfferDialog]="orderLevelTierOfferDialog"></app-order-level-offers-tiers>
</div>

<div *ngIf="saveConfirmationDialog.show">
  <app-save-confirmation-dialog [dialog]="saveConfirmationDialog"></app-save-confirmation-dialog>
</div>

<div *ngIf="cancelConfirmationModel.show">
  <div class = "overlayForPopups">
  <div cdkDrag>
  <uitk-dialog [(showModal)]="cancelConfirmationModel.show" uitkId="cancelConfirmationModel-dialog" [closeBtn]=false
    style="width:fit-content;" triggerElementId="cancelBtn" (onModalHidden)="onModalClosed($event)">
    <uitk-dialog-content>
      <p style="padding: 10px;">Do you want to cancel this order?</p>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xs" (click)="allowOfCancel()" appAutoFocus>
        Yes
      </button>
      <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="stopOfCancel()">
        No
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
</div>
</div>
</div>

<div *ngIf="showOrderLevelApprovalPopup.show">
  <app-order-approval [orderApprovalDialog]="showOrderLevelApprovalPopup"></app-order-approval>
</div>

<div *ngIf="requireEmailDialog.show">
  <app-require-email-dialog [dialog]="requireEmailDialog" [productType]="productType" 
    [electronicProductCodesOnly]="electronicProductCodesOnly" [eAlertProductCodesOnly]="eAlertProductCodesOnly" [targetEleToScroll]="targetEle" [triggerEleToFocus]="triggerEleToFocus">
  </app-require-email-dialog>
</div>
<div *ngIf="invalidEmailDialogForElectronicProducts.show">
  <div class = "overlayForPopups">
    <div cdkDrag>
  <uitk-dialog id="invalidEmailDialogForElectronicProducts" uitkId="invalidEmailDialogForElectronicProducts" [(showModal)]="invalidEmailDialogForElectronicProducts.show"
  [closeBtn]="false" [triggerElementId]="triggerEleToFocus" (onModalHidden)="onModalClosed($event)">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Ship To Contact Email Invalid or Blank
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div class="row mt-3">
      <div class="col-12 d-flex">
        <span>
          These items require that the Ship To Contact has a valid email: {{ electronicProductCodesOnly }}
        </span>
      </div>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button id="btnOk" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="invalidEmailDialogOk()" scrollToTarget target="#divLeftOrderDetailsGrid" appAutoFocus>
      Ok
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
    </div>
  </div>
</div>
<div *ngIf="blankShipToEmailDialog.show">
  <div class = "overlayForPopups">
    <div cdkDrag>
  <uitk-dialog id="blankShipToEmailDialog" uitkId="blankShipToEmailDialog" [(showModal)]="blankShipToEmailDialog.show" [closeBtn]="false" (onModalHidden)="onModalClosed($event)">
    <uitk-dialog-header>
      <div cdkDragHandle>
        E-Alert: Blank Ship To Contact Email
      </div>
    </uitk-dialog-header>
    <uitk-dialog-content>
      <div class="row mt-3">
        <div class="col-12 d-flex">
          <span>
            <strong>E-Alerts</strong> for "{{ eAlertProductCodesOnly }}" will not be received if no email address is entered. Are you sure you
            want to leave the Ship To Contact email blank?
          </span>
        </div>
      </div>
    </uitk-dialog-content>
    <uitk-dialog-actions>
      <button id="btnYes" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="yesButtonClick()" appAutoFocus>
        Yes
      </button>
      <button id="btnNo" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" (click)="noButtonClick()">
        No
      </button>
    </uitk-dialog-actions>
  </uitk-dialog>
    </div>
  </div>
</div>
<div *ngIf="blankorInvalidShipToEmailDialog.show">
  <div class = "overlayForPopups">
    <div cdkDrag>
  <uitk-dialog id="blankorInvalidShipToEmailDialog" uitkId="blankorInvalidShipToEmailDialog" [(showModal)]="blankorInvalidShipToEmailDialog.show"
  [closeBtn]="false" (onModalHidden)="onModalClosed($event)">
  <uitk-dialog-header>
    <div cdkDragHandle>
      Ship To Contact Email Invalid or Blank
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div class="row mt-3">
      <div class="col-12 d-flex">
        <span>
          This item {{ electronicProductCodesOnly }} requires the ship to contact have a valid email address. You will not be allowed to submit
          this order.
        </span>
      </div>
    </div>
  </uitk-dialog-content>
  <uitk-dialog-actions>
    <button id="btnOk" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" (click)="blankorInvalidShipToEmailDialogOkButtonClick()" appAutoFocus>
      Ok
    </button>
  </uitk-dialog-actions>
</uitk-dialog>
    </div>
  </div>
</div>

<div *ngIf="contactDialogModal.show">
  <app-contact-change-popup [contactDialogModal]="contactDialogModal"></app-contact-change-popup>
</div>

<div *ngIf="orderService.confirmAdditionalContactsDialog.show">
  <app-confirm-additional-contacts [dialog]="orderService.confirmAdditionalContactsDialog"></app-confirm-additional-contacts>
</div>

<div *ngIf="orderService.selectContactsDialog.show">
  <app-select-contacts [dialog]="orderService.selectContactsDialog"></app-select-contacts>
</div>

<div *ngIf="orderService.confirmationEmailDialog.show">
  <app-confirmation-emails [confirmationEmailDialog]="orderService.confirmationEmailDialog" [orderDto]="this.orderService.orderDto"></app-confirmation-emails>
</div>

<div *ngIf="orderService.applyChangesToFutureDialog.show">
  <app-apply-changes-to-future-dialog [dialog]="orderService.applyChangesToFutureDialog"></app-apply-changes-to-future-dialog>
</div>

<div *ngIf="orderService.couponItemCheck.show">
  <app-coupon [dialog]="orderService.couponItemCheck"></app-coupon>
</div>

<div *ngIf="orderOfferQualificationDialog.show">
  <app-order-offer-qualification [orderOfferQualificationDialog]="orderOfferQualificationDialog"
    [associatedPremiumsList]="associatedPremiumsList" [offerType]="offerType">
  </app-order-offer-qualification>
</div>