import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { InvoiceLineItemDto } from '../dtos/invoice-line-item-dto.model';
import { InvoicesListDto } from '../dtos/invoices-list-dto.model';
import { OpenCashDto } from '../dtos/open-cash-dto.model';
import { ReservationDto } from '../dtos/reservation-dto.model';
import { ConfigService } from './config.service';
import { AccountCommentsDto } from '../dtos/account-comment-dto-model';
import { DepositDto } from '../dtos/deposits-dto.model';
import { InvoicePdfEmailInputDto } from '../dtos/invoice-pdf-email-input-dto.model';
import { ModeService } from './mode.service';
import { SwalAlert } from '../helpers/alert';

@Injectable({
  providedIn: 'root',
})

export class AccountingService {
  private openCashCache!: OpenCashDto[] | null;
  private openCashCachedObservable!: Observable<OpenCashDto[]> | null;
  private invoiceLineItemsCache!: InvoiceLineItemDto[] | null;
  private invoiceLineItemsCachedObservable!: Observable<InvoiceLineItemDto[]> | null;
  private accountsCommentsCache!: AccountCommentsDto[] | null;
  private accountsCommentsCachedObservable!: Observable<AccountCommentsDto[]> | null;
  private invoicesListCache!: InvoicesListDto[] | null;
  private invoicesListCachedObservable!: Observable<InvoicesListDto[]> | null;
  depositResultDtos: DepositDto[] = [];
  swalAlert = new SwalAlert();

  constructor(
    private readonly httpClient: HttpClient, 
    private readonly configService: ConfigService,
    private readonly modeService: ModeService) {
  }

  getOpenCash(accountId: number): Observable<OpenCashDto[]> {
    let observable: Observable<OpenCashDto[]>;

    if (this.openCashCache) {
      observable = of(this.openCashCache);
    }
    else if (this.openCashCachedObservable) {
      observable = this.openCashCachedObservable;
    }
    else {
      this.openCashCachedObservable = this.httpClient.get<OpenCashDto[]>(`${this.configService.accountingApiUrl}/opencash/${accountId}`)
        .pipe(
          tap((response: OpenCashDto[]) => {
            this.openCashCache = response;
          }),
          share(),
          finalize(() => {
            this.openCashCache = null;
            this.openCashCachedObservable = null;
          })
        );

      observable = this.openCashCachedObservable;
    }

    return observable;
  }

  reserveOpenCash(reservationDtos: ReservationDto[]) {
    return this.httpClient.post(`${this.configService.accountingApiUrl}/ReserveOpenCash`, reservationDtos);
  }

  async applyDeposits(depositDtos: DepositDto[]): Promise<DepositDto[] | null> {
    const applyDiscText = "Applying Deposits"
    return new Promise(resolve => {
      this.modeService.addToWaitList(applyDiscText);
      this.httpClient.post<DepositDto[]>(`${this.configService.accountingApiUrl}/ApplyDeposits`, depositDtos).subscribe({
        next: data => {
          this.modeService.removeFromWaitList(applyDiscText);
          resolve(data);
        },
        error: async (e) => {
          this.modeService.removeFromWaitList(applyDiscText);
          await this.swalAlert.alert("Error applying deposits");
          resolve(null);
        }
      });
    });    
  }

  getInvoiceLineItems(orderId: number): Observable<InvoiceLineItemDto[]> {
    let observable: Observable<InvoiceLineItemDto[]>;

    if (this.invoiceLineItemsCache) {
      observable = of(this.invoiceLineItemsCache);
    }
    else if (this.invoiceLineItemsCachedObservable) {
      observable = this.invoiceLineItemsCachedObservable;
    }
    else {
      let invoiceLineItemsurl= this.configService.enablePeoplesoftApi ? `${this.configService.peoplesoftApiUrl}Accounting/GetInvoiceLineItems?orderId=${orderId}`:`${this.configService.accountingApiUrl}/invoiceLineItems/${orderId}`;
      this.invoiceLineItemsCachedObservable = this.httpClient.get<InvoiceLineItemDto[]>(invoiceLineItemsurl)
        .pipe(
          tap((response: InvoiceLineItemDto[]) => {
            this.invoiceLineItemsCache = response;
          }),
          share(),
          finalize(() => {
            this.invoiceLineItemsCache = null;
            this.invoiceLineItemsCachedObservable = null;
          })
        );

      observable = this.invoiceLineItemsCachedObservable;
    }

    return observable;
  }

  async getInvoices(orderId: number): Promise<InvoicesListDto[] | null> {
    return new Promise(resolve => {
      const waitingFor = 'Getting open invoices.';
      this.modeService.addToWaitList(waitingFor);
      let url = this.configService.enablePeoplesoftApi ? `${this.configService.peoplesoftApiUrl}/Accounting/GetInvoices?orderId=${orderId}` : `${this.configService.accountingApiUrl}/invoices/${orderId}`; 
      this.httpClient.get<InvoicesListDto[]>(url).subscribe({
        next: returnData => {
          this.modeService.removeFromWaitList(waitingFor);
          resolve(returnData);
        },
        error: async e => {
          console.log(e);
          this.modeService.removeFromWaitList(waitingFor);
          await this.swalAlert.alert('Error getting open invoices.');
          resolve(null);
        }
      });
    });   
  }

  emailInvoicePdfs(invoicePdfEmailInputDto: InvoicePdfEmailInputDto) {
    return this.httpClient.post(`${this.configService.accountingApiUrl}/sendinvoicepdfs`, invoicePdfEmailInputDto);
  }

  getAllAccountComments(accountId:any): Observable<AccountCommentsDto[]> {
    let observable: Observable<AccountCommentsDto[]>;

    if (this.accountsCommentsCache) {
      observable = of(this.accountsCommentsCache);
    }
    else if (this.accountsCommentsCachedObservable) {
      observable = this.accountsCommentsCachedObservable;
    }
    else {
      this.accountsCommentsCachedObservable = this.httpClient.get<AccountCommentsDto[]>(`${this.configService.apiUrl}/Maintenance/GetComments/${accountId}`)
        .pipe(
          tap((response: AccountCommentsDto[]) => {
            this.accountsCommentsCache = response;
          }),
          share(),
          finalize(() => {
            this.accountsCommentsCache = null;
            this.accountsCommentsCachedObservable = null;
          })
        );

      observable = this.accountsCommentsCachedObservable;
    }

    return observable;
  }

  deleteAccountComments(commentId:number){
    return this.httpClient.delete(`${this.configService.apiUrl}/Maintenance/DeleteComments/${commentId}`);
  }

  updateAccountComments(accountObj:AccountCommentsDto){
    return this.httpClient.put(`${this.configService.apiUrl}/Maintenance/UpdateComment`,accountObj);
  }

  addAccountComments(accountObj:AccountCommentsDto){
    return this.httpClient.post(`${this.configService.apiUrl}/Maintenance/AddComment`,accountObj);
  }

}
