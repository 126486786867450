import { Directive, ElementRef, OnInit } from "@angular/core";

@Directive({
    selector: '[appAutoFocus]'
})
export class AutofocusDirective implements OnInit {
    constructor(private readonly elementRef: ElementRef){};

    ngOnInit(): void {
        setTimeout(() => {
             this.elementRef.nativeElement.focus();
           }, 0);
    }
    ngAfterViewInit(): void {
       setTimeout(() => {
        this.elementRef.nativeElement.focus();
       }, 1000);
    }
}